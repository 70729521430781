/**
 * @Author: Lovin
 * @Date:   2018-07-05T15:31:32+05:30
 * @Project: Ezyprocure
 * @Filename: supplier.manage-buyer-identification-code.controller.js
 * @Last modified by:   Lovin
 * @Last modified time: 2018-07-05T16:15:02+05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerIdentificationCodeController', BuyerIdentificationCodeController);

  BuyerIdentificationCodeController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'pagingParams', 'BuyerSupplierMappingService', 'paginationConstants', 'NotificationService'];

  function BuyerIdentificationCodeController($scope, $rootScope, $state, $stateParams, pagingParams, BuyerSupplierMappingService, paginationConstants, NotificationService) {
    var vm = this;

    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.getAllSupplierBuyerMappings = getAllSupplierBuyerMappings;
    vm.updateBuyerIdentificationCode = updateBuyerIdentificationCode;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    /**
    * [sort description]
    * @return {[type]} [description]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    vm.getAllSupplierBuyerMappings();


    /**
    * [getAllSupplierBuyerMappings Get list of buyer supplier mappings]
    */
    function getAllSupplierBuyerMappings(searchText) {
      if (!searchText) {
        vm.searchText = '';
      }
      BuyerSupplierMappingService.getAllMappedSupplierBuyers({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.searchText
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.buyerSupplierMappings = response.data;
      },function(error){
      });
    }
/**
 * [updateBuyerIdentificationCode updates buyer identification code]
 * @param {[type]} buyerSupplierMapping
 */
    function updateBuyerIdentificationCode(buyerSupplierMapping) {
      BuyerSupplierMappingService.updateBuyerIdentificationCode(buyerSupplierMapping).then(function(response) {
        NotificationService.success({
          title: 'Buyer Identification Code',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        vm.getAllSupplierBuyerMappings();
      }, function(error) {
        vm.getAllSupplierBuyerMappings();
        NotificationService.error({
          title: 'Buyer Identification Code Updation',
          error: error
        });
      });
    }

  }
})();
