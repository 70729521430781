/**
 * @Author: Ajay
 * @Date:   03-Aug-2017 09:58 +05:30
 * @Project: Ezyprocure
 * @Filename: global.config.constants.js
 * @Last modified by:   Ajay
 * @Last modified time: 12-Oct-2018 12:51 +05:30
 */



"use strict";
angular.module('ezyprocureApp')
.constant('GLOBAL_CONFIG_CONSTANTS', {
  "SUMD_CONSTANTS": {
    "QUANTITY_DOES_NOT_MATCH": {
      "reason": "QUANTITY_DOES_NOT_MATCH",
      "color": "yellow",
      "row_class": "alert-yellow",
      "label_class": "bg-yellow"
    },
    "DELIVERY_DATE_DOES_NOT_MATCH": {
      "reason": "DELIVERY_DATE_DOES_NOT_MATCH",
      "color": "blue",
      "row_class": "alert-info",
      "label_class": "bg-info"
    },
    "QUANTITY_AND_DELIVERY_DATE_DOES_NOT_MATCH": {
      "reason": "QUANTITY_AND_DELIVERY_DATE_DOES_NOT_MATCH",
      "color": "red",
      "row_class": "alert-danger",
      "label_class": "bg-danger"
    },
    "QUALITY_DOES_NOT_MATCH": {
      "reason": "QUALITY_DOES_NOT_MATCH",
      "color": "orange",
      "row_class": "alert-warning",
      "label_class": "bg-orange"
    }
  },
  "OCR_INVOICE_STATUS": {
    "LABEL_CLASS": {
      "ACCEPTED": "label-success",
      "ADDED": "label-warning",
      "AMOUNT_MATCH": "label-success",
      "AMOUNT_MISMATCH": "label-danger",
      "INVALID_IMAGE": "label-danger",
      "OCR_ERROR": "label-danger",
      "NO_INVOICE_UPLOADED": "label-warning",
      "REJECTED": "label-danger",
      "PENDING": "label-warning"
    }
  },
  "PRICE_UPDATE_STATUS": {
    "LABEL_CLASS": {
      "PENDING": "label-warning",
      "ACCEPTED": "label-success",
      "REJECTED": "label-danger",
      "SUPPLIER_PRICE_UPDATE": "label-primary"
    }
  },
  "PURCHASE_ORDER_STATUS": {
    "LABEL_CLASS": {
      "PENDING": "label-warning",
      "PROCESSED": "label-success",
      "PO_CANCELLED": "label-danger",
      "REJECTED": "label-danger",
      "PENDING_CANCELLATION": "label-warning"
    }
  },
  "eGIRO_FUND_TRANSFER": {
    "LABEL_CLASS": {
      "PENDING": "label-warning",
      "PROCESSED": "label-success",
      "PO_CANCELLED": "label-danger",
      "REJECTED": "label-danger",
      "PENDING_CANCELLATION": "label-warning",
      "TRANSACTION_REJECTED": "label-danger",
      "IN_PROGRESS": "label-info",
      "PAID": "label-success",
      "COLLECTION_FAILED": "label-danger",
      "REMITTANCE_FAILED": "label-danger",
      "FAILED": "label-danger",
      "PROCESSING": "label-warning", 
      "GIRO_UPLOADED": "label-success",
      "UPLOAD_INPROGRESS": "label-warning",
      "UPLOAD_ACCEPTED": "label-success",
      "COLLECTION_REJECTED":"label-danger"
    }
  },
  "PAYMENT_AXS_STATUS": {
    "LABEL_CLASS": {
      "UNPAID": "label-warning",
      "PAID": "label-success",
      "BSF_FAILED": "label-danger",
      "FAILED": "label-danger"
    }
  },
  "PROCESS_ORDER_STATUS": {
    "LABEL_CLASS": {
      "PENDING": "label-warning",
      "SUPPLIER_APPROVED": "label-success",
      "SUPPLIER_DEMAND_MODIFIED": "label-warning",
      "SUPPLIER_UNABLE_TO_MEET_DEMANDS": "label-warning",
      "BUYER_APPROVED": "label-success",
      "BUYER_REJECTED": "label-danger",
      "REQUIREMENT_PENDING_PO": "label-warning",
      "BUYER_CANCELLED_PR": "label-danger",
      "SUPPLIER_REJECTED_PR": "label-danger",
      "ADHOC_PO_ON_BOARD": "label-primary",
      "ADHOC_PO_OFF_BOARD": "label-primary",
      "ADHOC_PO_ON_BOARD_PENDING": "label-warning",
      "PURCHASE_REQUEST": "label-primary",
      "DEVIATION_PENDING":"label-warning",
      "BUYER_REJECTED_PENDING":"label-danger"
    }
  },
  "INVOICE_STATUS": {
    "LABEL_CLASS": {
      "INVOICED": "label-success",
      "UNINVOICED": "label-warning",
      "PROCESSED": "label-success",
      "PENDING": "label-warning",
      "REJECTED": "label-danger"
    }
  },
  "OCR_VERIFIED": {
    "LABEL_CLASS": {
      "YES": "label-success",
      "NO": "label-danger"
    }
  },
  "EPD_APPROVAL_STATUS": {
    "LABEL_CLASS": {
      "PENDING": "label-warning",
      "APPROVED": "label-success",
      "REJECTED": "label-danger"
    }
  },
  "PAYMENT_APPROVAL_STATUS": {
    "LABEL_CLASS": {
      "PENDING": "label-warning",
      "APPROVED": "label-success",
      "REJECTED": "label-danger",
      "NA": "label-default"
    }
  },
  "PAYMENT_STATUS": {
    "LABEL_CLASS": {
      "PENDING_APPROVAL": "label-warning",
      "PENDING_CUSTOM_APPROVAL": "label-warning",
      "PAID": "label-success",
      "UNPAID": "label-warning",
      "FAILED": "label-danger",
      "CANCELLED": "label-danger"
    }
  }
});
