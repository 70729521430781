/*
* @Author: Gayathri.J.L
* @Date: 2018-12-26 11:46:35
* @Project: Ezyprocure
* @Filename: buyer.order-template.service.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-02-07T16:56:45+05:30
*/

(function () {
  'use strict';

  angular.module('ezyprocureApp').factory('BuyerOrderTemplateService', BuyerOrderTemplateService);

  BuyerOrderTemplateService.$inject = ['$http', '$q', 'API_CONSTANTS','RESOURCE_URL_CONSTANTS'];

  function BuyerOrderTemplateService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var BuyerOrderTemplateService = {
      createOrderTemplate: createOrderTemplate,
      getAllOrderTemplates: getAllOrderTemplates,
      deleteOrderTemplateById: deleteOrderTemplateById,
      editOrderTemplate: editOrderTemplate,
      getOrderTemplateById: getOrderTemplateById,
      getAllItems: getAllItems,
      bulkUpdateOrderTemplate: bulkUpdateOrderTemplate,
      getSelectedSuppliersNames: getSelectedSuppliersNames,
      checkSupplierLength: checkSupplierLength,
      getAllOrderTemplateByOutlets: getAllOrderTemplateByOutlets,
      getSelectedSupplierVisibilityType: getSelectedSupplierVisibilityType
    };
    return BuyerOrderTemplateService;

    // create new order template
    function createOrderTemplate(orderTemplateList) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ORDER_TEMPLATES + '/v2', orderTemplateList).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    // get all order templates
    function getAllOrderTemplates(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ORDER_TEMPLATES,
        method: "GET",
        params:params
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    // get order template by Id
    function getOrderTemplateById(templateId) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ORDER_TEMPLATES +'/' + templateId).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    // delete order template by id
    function deleteOrderTemplateById(templateId) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ORDER_TEMPLATES + '/' + templateId).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getAllItems Gets all items]
     * @param {Object} requestDTO - [Supplier Id List, business Id List, purchaseOrderTypeId]
     * @param {Object} queryParams
     */
    function getAllItems(requestDTO,queryParams) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ITEM_BUSINESS_MAPPING + '/common-items',
        method: "POST",
        data: requestDTO,
        params:queryParams
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [editOrderTemplate edit order template]
    * @param  {[List]}  data [selected item list]
    * @return {[Object]}     [details of edited order template]
    */
    function editOrderTemplate(data) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ORDER_TEMPLATES,
        method: "PUT",
        data: data,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [bulkUpdateOrderTemplate upload csv data for template]
    */
    function bulkUpdateOrderTemplate(uploadData) {
      var deferred = $q.defer();
      $http({
      url:API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion +  RESOURCE_URL_CONSTANTS.ORDER_TEMPLATES +'/upload',
      method:"POST",
      data:uploadData
      }).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

   /**
    * [getSelectedSuppliersNames gets supplier names in list format]
    * @param  {[List]} itemList [items array]
    * @return {[List]}          [string - supplier names separated by comma]
    */
    function getSelectedSuppliersNames(itemList){
      var supplierNames = _.join(_.sortedUniq(_.map(itemList, 'supplier.companyName')), ',');
      return supplierNames;
    }

/**
 * [checkSupplierLength check supplier length]
 * @param  {[type]} itemList [item list containing suppliers]
 * @return {[type]}          [supplier length]
 */
    function checkSupplierLength(itemList){
      var supplierList = _.uniq(_.map(itemList, 'supplier.companyName'));
      return supplierList.length;
    }

    /**
     * [getAllOrderTemplateByOutlets Gets all order templates based on the business outlets]
     * @param {*} businessOutletIdList
     */
    function getAllOrderTemplateByOutlets(businessOutletIdList) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ORDER_TEMPLATES + '/buyer-business-mapped',
        method: "POST",
        params:businessOutletIdList
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

     /**
    * [getSelectedSupplierVisibilityType gets supplier visibility type]
    * @param  {[List]} itemList [items array]
    * @return {[List]}          [string - visibilityType]
    */
   function getSelectedSupplierVisibilityType(itemList){
    var suppliervisibilityType = _.join(_.sortedUniq(_.map(itemList, 'supplier.visibilityType')), ',');
    return suppliervisibilityType;
  }

  }
})();
