/**
* @Author: Ajay
* @Date:   27-May-2017 18:27 +05:30
* @Project: Ezyprocure
* @Filename: supplier.adhoc-credit-note.tax-invoice-preview.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-06-25T14:49:15+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierCreateAdhocTaxInvoicePreviewController', SupplierCreateAdhocTaxInvoicePreviewController);

  SupplierCreateAdhocTaxInvoicePreviewController.$inject = ['$scope', '$rootScope', '$state', 'NotificationService', '$mdDialog', '$filter', '$stateParams', 'pagingParams', 'paginationConstants', 'SupplierReturnOrderService', 'SupplierAdhocCreditNoteService', 'GLOBAL_CONSTANTS', 'Auth'];

  function SupplierCreateAdhocTaxInvoicePreviewController($scope, $rootScope, $state, NotificationService, $mdDialog, $filter, $stateParams, pagingParams, paginationConstants, SupplierReturnOrderService, SupplierAdhocCreditNoteService, GLOBAL_CONSTANTS, Auth) {

    var vm = this;
    vm.init = init;
    vm.purchaseOrderNumber = $stateParams.purchaseOrderNumber;
    vm.deliveryDate = $stateParams.deliveryDate;
    vm.createAdHocCreditNotes = createAdHocCreditNotes;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.defaultDateTimeFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_TIME_FORMAT;
    vm.accessForPriceDisplay = Auth.hasPermission('accessForPriceDisplay');

    /**
     * [init loads initially to ger adhoc credit details from service]
     */
    function init() {
      if(SupplierAdhocCreditNoteService.getAdhocCreditNoteItems()){
        vm.purchaseOrderDetails = SupplierAdhocCreditNoteService.getAdhocCreditNoteItems();
      }else {
        $state.go('supplier.reports.invoice-report.adhoc-credit-note', {
          purchaseOrderNumber: vm.purchaseOrderNumber,
          deliveryDate: vm.deliveryDate
        }, {
          reload: false
        });
      }
    }

    vm.init();

    /**
     * [getAdHocCreditNoteDTO creates adhoc credit note DTO]
     * @param  {[object]} purchaseOrderDetails [all PO details]
     * @return {[object]}                      [list of adhoc credit notes]
     */
    function getAdHocCreditNoteDTO(purchaseOrderDetails) {
      var adHocCreditNoteDTO = [];
      var adHocCreditNoteDTOs ={};
      _.forEach(purchaseOrderDetails.items, function(item, key) {
        if (!item.freeItem) {
          adHocCreditNoteDTO.push({
            adHocCreditNotes: {
              quantity: item.adhocCreditQuantity,
              weight: item.adhocCreditWeight,
              amount: item.adhocCreditNoteAmount
            },
            purchaseOrderId: item.purchaseOrderId,
          });
        }
      });
      adHocCreditNoteDTOs.adHocCreditNoteDTOS = adHocCreditNoteDTO;
      return adHocCreditNoteDTOs;
    }

    /**
     * [createAdHocCreditNotes creates adhoc credit note]
     */
    function createAdHocCreditNotes() {
      var adHocCreditNoteDTOs = getAdHocCreditNoteDTO(vm.purchaseOrderDetails);
      adHocCreditNoteDTOs.deliveryCharge = vm.purchaseOrderDetails.invoice.modifiedDeliveryCharge;
      adHocCreditNoteDTOs.invoiceSubTotal = vm.purchaseOrderDetails.invoice.invoiceSubTotal;
      adHocCreditNoteDTOs.invoiceTotal = vm.purchaseOrderDetails.invoice.invoiceTotal;
      adHocCreditNoteDTOs.addedGST = vm.purchaseOrderDetails.invoice.addedGst;
      adHocCreditNoteDTOs.grandTotal = vm.purchaseOrderDetails.invoice.grandTotal;
      SupplierAdhocCreditNoteService.createAdHocCreditNote(adHocCreditNoteDTOs)
      .then(function(response) {
        NotificationService.success({
          title: 'Adhoc Credit Note',
          message: 'alertMessage.CREATED_SUCCESSFULLY'
        });
        $state.go('supplier.reports.invoice-report.adhoc-credit-note.preview', {purchaseOrderNumber:vm.purchaseOrderNumber,'deliveryDate':vm.deliveryDate});
      }, function(error) {
        NotificationService.error({
          title: 'Adhoc Credit Note Creation',
          error: error
        });
      });
    }

  }
})();
