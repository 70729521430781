/**
* @Author: Ajay
* @Date:   20-Mar-2017 14:43 +05:30
* @Project: Ezyprocure
* @Filename: supplier.promotion.edit.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 29-Dec-2017 12:56 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierPromotionEditController', SupplierPromotionEditController);

  SupplierPromotionEditController.$inject = ['$scope', '$state', '$stateParams', '$filter', '$q', '$mdDialog', 'PromotionService', 'SupplierItemService', 'NotificationService', 'paginationConstants', 'SupplierDataService', 'BuyerSupplierMappingService', 'BuyerBusinessService', 'SupplierItemPriceUpdateService'];

  function SupplierPromotionEditController($scope, $state, $stateParams, $filter, $q, $mdDialog, PromotionService, SupplierItemService, NotificationService, paginationConstants, SupplierDataService, BuyerSupplierMappingService, BuyerBusinessService, SupplierItemPriceUpdateService) {

    var vm = this;
    vm.promotionId = $stateParams.promotionId;

    vm.promotionDTO = {};
    vm.itemSearchQuery = {};
    vm.getSupplierItemPromotionById = getSupplierItemPromotionById;
    vm.itemChanged = itemChanged;
    vm.searchForItems = searchForItems;
    vm.update = update;
    vm.setPromotionStartDateWithTime = setPromotionStartDateWithTime;
    vm.setPromotionEndDateWithTime = setPromotionEndDateWithTime;
    vm.getAllBuyersBusinessOutlets = getAllBuyersBusinessOutlets;
    vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
    vm.init = init;
    vm.deselectAll = deselectAll;
    vm.selectAll = selectAll;
    vm.getItemPricePerOutlet = getItemPricePerOutlet;
    vm.showPricePerBO = showPricePerBO;
    vm.getPricePerBusiness = getPricePerBusiness;
    vm.cancel = cancel;


    vm.init();

    function getItemPricePerOutlet(event){
      SupplierItemPriceUpdateService.getItemPricePerOutlet({
        'itemId': vm.promotionDTO.promotionItem.id,
        'businessIdList': vm.promotionDTO.businessIdList
      }).then(function(response){
        vm.outletList = response.data;
        vm.showPricePerBO(event);
      }, function(error){
        NotificationService.error({
          title: 'Item Promotion',
          error: error
        });
      })
    }

    /**
     * [showPrice details SHows the detailed Price of BO's]
     * @param {Object} event
     */
    function showPricePerBO(event) {
      $mdDialog.show({
        templateUrl: 'app/supplier-modules/item-management/promotions/supplier.promotion.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function getPricePerBusiness(eachOutlet){
      return eachOutlet.price ? eachOutlet.price : eachOutlet.items.price;
    }

    /**
     * [cancel Cancels the modal]
     */
    function cancel() {
      $mdDialog.cancel();
    }

    /**
    * [getSupplierItemPromotionById getSupplierItemPromotionById]
    * @return {[Object]} [promotionDTO]
    */
    function getSupplierItemPromotionById() {
      PromotionService.getSupplierItemPromotionById(vm.promotionId).then(function(response) {
        vm.promotionDTO = response;
        vm.getAllSupplierMappedBuyers();
        vm.getAllBuyersBusinessOutlets();
        vm.oldEffectiveStartDate = new Date(response.effectiveStartDate);
        vm.selectedItem = response.freeItem;

      }, function(error) {
      });
    }

    function init(){
      vm.getSupplierItemPromotionById();
    }
    /**
    * [searchForItems searchForItems]
    * @param  {[type]} queryType [queryType]
    * @param  {[type]} queryText [queryText]
    * @return {[List]}           [Search result items]
    */
    function searchForItems(queryType, queryText) {
      var deferred = $q.defer();
      if (!queryText) {
        return;
      } else {
        vm.itemSearchQuery.itemType = 'all';
        vm.itemSearchQuery.queryField = queryType;
        vm.itemSearchQuery.queryText = queryText;
        SupplierItemService.searchAllItems(vm.itemSearchQuery)
        .then(function(response) {
          deferred.resolve($filter('orderBy')(response.data, 'itemName'));
        }, function(error) {
          deferred.reject(error);
        });
      }
      return deferred.promise;
    }

    /**
    * [itemChanged itemChanged]
    * @param  {[Object]} selectedItem [selectedItem]
    * @return {[type]}              [description]
    */
    function itemChanged(selectedItem) {
      if (selectedItem)
        vm.promotionDTO.freeItem = angular.copy(selectedItem);
    }

    /**
     * [setPromotionStartDate Update promotion start date with time]
     */
    function setPromotionStartDateWithTime() {
      vm.promotionDTO.effectiveStartDate = PromotionService.getPromotionStartDateWithTime(vm.promotionDTO.effectiveStartDate);
    }

    /**
     * [setPromotionEndDate Update promotion end date with time]
     */
    function setPromotionEndDateWithTime() {
      vm.promotionDTO.effectiveEndDate = PromotionService.getPromotionEndDateWithTime(vm.promotionDTO.effectiveEndDate);
    }

    /**
    * [update update]
    * @return {[type]} [description]
    */
    function update() {
      vm.promotionDTO.brandIdList = null;
      PromotionService.updateSupplierItemPromotion(vm.promotionDTO).then(function(response) {
        $scope.$emit('ezyprocureApp:promotionUpdate', response);
        NotificationService.success({
          title: 'Item Promotion',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        $state.go('supplier.items.promotions', {
          reload: false
        });
      }, function(error) {
        NotificationService.error({
          title: 'Item Promotion Updation',
          error: error
        });
      });
    }

    //get all business outlets of the selected buyer
    function getAllBuyersBusinessOutlets() {
        if ( vm.promotionDTO.buyerIdList.length<1) {
          return false;
        }
        var params = {
          page: 0,
          size: paginationConstants.maxLimit,
          groupBy: 'buyer',
          supplierId: SupplierDataService.getSupplierProfile().supplier.id,
        };

        BuyerBusinessService.getAllBuyersBusinessOutlets(vm.promotionDTO.buyerIdList, params).then(function(response) {
          vm.businessOutlets = response.data;
        }, function(error) {
        });
      }

      //Get all buyers mapped to the supplier
    function getAllSupplierMappedBuyers() {
        var params = {
          page: 0,
          size: paginationConstants.maxLimit,
        };
        if(vm.buyers)
          return false;
        var supplierId = SupplierDataService.getSupplierProfile().supplier.id;
        BuyerSupplierMappingService.getAllSupplierMappedBuyers(supplierId, params).then(function(response){
          vm.buyers = response.data;
        },function(error){
        });
      }

      /**
     * [selectAll Selects all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets or brands]
     */
    function selectAll(dropdown) {
        switch (dropdown) {
          case 'BUYERS' :
            vm.promotionDTO.buyerIdList = _.map(vm.buyers, _.property('id'));
            vm.getAllBuyersBusinessOutlets();
            break;
          case 'BUSINESSOUTLETS' :
            vm.promotionDTO.businessIdList = _.map(_.flatten(_.without(_.map(vm.businessOutlets, _.property('businessDTOList')), undefined, null)), _.property('businessId'));
              break;
          }
      }

      /**
       * [deselectAll Deselect all from the list]
       * @param  {[type]} dropdown [Active dropdown - outlets or brands]
       */
      function deselectAll(dropdown) {
        switch (dropdown) {
          case 'BUYERS' :
            vm.promotionDTO.buyerIdList = [];
            break;
          case 'BUSINESSOUTLETS' :
            vm.promotionDTO.businessIdList = [];
            break;
        }
      }

  }
})();
