/**
 * @Author: Jayalekshmi 
 * @Date: 2019-01-03 10:40:23 
 * @Project: Ezyprocure
 * @Filename: sats.sr.confirm.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-06-26 11:27:04
 */



(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .controller('SatsSRConfirmController', SatsSRConfirmController);
  
    SatsSRConfirmController.$inject = ['$filter','GLOBAL_CONSTANTS','$q', '$state', '$stateParams', 'SatsManagementService','pagingParams', 'CsvUploadService', 'NotificationService', 'UtilService','paginationConstants', 'CSVExportService', 'EXPORT_CONSTANTS', 'Auth'];
  
    function SatsSRConfirmController($filter, GLOBAL_CONSTANTS,$q , $state, $stateParams, SatsManagementService, pagingParams, CsvUploadService, NotificationService, UtilService, paginationConstants, CSVExportService, EXPORT_CONSTANTS, Auth) {
      var vm = this;
      vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
      vm.predicate = pagingParams.predicate;
      vm.reverse = pagingParams.ascending;
      vm.srJobId = $stateParams.srJobId;
      vm.filter = {};
      vm.loadAll = loadAll;
      vm.todaysDate = new Date();
      vm.query = {
        limit: paginationConstants.itemsPerPage,
        page: 1
      };
      vm.userRole = Auth.getUserRole();
      vm.limitOptions = paginationConstants.limitOptions;
      vm.uploadJobSR = uploadJobSR;
      vm.confirmSRJob = confirmSRJob;
      vm.resetValues = resetValues;

      //CSV 
      vm.downloadCSVSRData = downloadCSVSRData;
      vm.SRCSVHeader = EXPORT_CONSTANTS.CSV.supplierSRCSVHeader;
      vm.getDateFormat = getDateFormat;

      /**
       * [getDateFormat gets the date string and converts]
       * @param {dateString} dateString 
       */
      function getDateFormat(dateString){
        var formatedDate = SatsManagementService.getDateFormat(dateString);
        return formatedDate;
      }
      
      /**
      * [loadAll Gets SR Job Details]
      */
      function loadAll() {
        getJobDetailsById();
      }

      /**
       * [getJobDetailsById Gets SR details]
       */
      function getJobDetailsById() {
        SatsManagementService.getJobDetailsById(vm.srJobId,{
          page: 0,
          size: paginationConstants.maxLimit,
        }).then(function(response){
          vm.srDetails = response.data;
          vm.totalItems = response.headers('X-Total-Count');
        },function(error){
          NotificationService.error({
            title: 'global.sats.jobConfirmation',
            error: error
          });
        });
      }

      /**
       * [resetValues Resets the csv data and error record once click on browse button]
       */
      function resetValues(){
        vm.itemDetails = [];
        vm.recordError = [];
      }

      /**
       * [confirmSRJob Confirms the Job SR]
       */
      function confirmSRJob(){
          SatsManagementService.confirmSRJob(vm.files[0].lfFile)
          .then(function(response){
            NotificationService.success({
              'title': 'SR Job Confirmation',
              'message': 'alertMessage.SUCCESSFULLY_CONFIRMED'
            });
            $state.go(vm.userRole.pretty+'.sats', {
              reload: false
            });
          },function(error){
            if(error.data.message == 'error.validation'){
              vm.recordError = error.data;
            }
            NotificationService.error({
              title: 'error.validation',
              error: error
            });
          })
      }

      /**
       * [downloadCSVSRData Downloads the SR csv item details]
       */
      function downloadCSVSRData() {
        var deferred = $q.defer();
        if (vm.srDetails.lineItems.length < 1) {
          NotificationService.simpleErrorToast({
            title: 'global.table.noRecordsFound',
            message: 'alertMessage.SEARCH_AND_SUBMIT'
          });
          return deferred.promise;
        }
        return CSVExportService.getWarehouseSRCSVData(vm.srDetails);
      }

      /**
       * [uploadJobSR Uploads Job SR ]
       */
      function uploadJobSR(){
        vm.recordError = [];
        var csvFile = vm.files[0];
        if (!csvFile) {
          NotificationService.simpleErrorToast({
            title: 'alertMessage.INVALID_INPUT',
            message: 'global.pleaseChooseACsvFile'
          });
          return false;
        }
        vm.isCsvFile = CsvUploadService.checkFileType(csvFile.lfFile);
        if (vm.isCsvFile) {
          UtilService.csvToJson(csvFile.lfDataUrl)
          .then(function (response) {
            vm.itemDetails = response;
            if(vm.itemDetails.length <1){
              NotificationService.simpleErrorToast({
                title: 'alertMessage.INVALID_INPUT',
                message: 'alertMessage.FILL_CSV'
              });
            }
          })
         }
      }
      vm.loadAll();
    }
  })();
  