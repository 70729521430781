/**
 * @Author: Ajay
 * @Date:   04-Dec-2017 10:19 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.item-mapping.service.js
 * @Last modified by:   sneha
 * @Last modified time: 2018-06-29T14:05:34+05:30
 */



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BuyerItemMapService', BuyerItemMapService);

  BuyerItemMapService.$inject = ['$http', '$q', 'API_CONSTANTS'];
  function BuyerItemMapService($http, $q, API_CONSTANTS) {

    var BuyerItemMapService = {
      getItemBuyerMappingsByItemId: getItemBuyerMappingsByItemId,
      updateItemBuyerMapping: updateItemBuyerMapping,
      getAllMappedItems: getAllMappedItems,
      deleteMapping: deleteMapping,
      createNewItemBuyerOutletMapping: createNewItemBuyerOutletMapping,
      getAdhocItemBuyerMappingsByItemId: getAdhocItemBuyerMappingsByItemId,
      updateAdhocItemBuyerMapping: updateAdhocItemBuyerMapping
    };

    return BuyerItemMapService;

    /* --  AdminBuyer CRUID operations  -- */

    //Get All Item - Buyer Mapping Lists By ItemId
    function getItemBuyerMappingsByItemId(urlParams,itemId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'item/mappings/'+ itemId,
        method: "GET",
        params: urlParams,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update Admin supplier
    function updateItemBuyerMapping(itemBuyerMapping) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'item/mappings/', itemBuyerMapping).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get all supplier items which are mapped to a buyer. pagination and search query as parameter.
    function getAllMappedItems(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'items/mappings',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Delete a buyer-item mapping using mapping id
    function deleteMapping(mappingId,businessOutlets) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'item/mappings/' + mappingId +'/' +businessOutlets,
        method: "DELETE",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Create new mapping between items and buyer/business outlet
    function createNewItemBuyerOutletMapping(mappingObject) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyer/items/mapping', mappingObject).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    // getAdhocItemBuyerMappingsByItemId gets details by id
    function getAdhocItemBuyerMappingsByItemId(urlParams,itemId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'item/business/mappings/adhoc/'+ itemId,
        method: "GET",
        params: urlParams
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update Admin supplier
    function updateAdhocItemBuyerMapping(itemBuyerMapping) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'item/mappings/business/adhoc/update', itemBuyerMapping).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
