/*
 * @Author: Jayalekshmi
 * @Date: 2019-11-15 10:38:51
 * @Project: Ezyprocure
 * @Filename: buyer.stock-transfer.service.js
 * @Last Modified by: Lovin Chacko
 * @Last Modified time: 2020-11-30 10:52:11
 */


(function() {
    'use strict';

    angular.module('ezyprocureApp').factory('BuyerStockTransferService', BuyerStockTransferService);

    BuyerStockTransferService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
    function BuyerStockTransferService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

      var buyerStockTransferService = {
        getAllStockTransfers : getAllStockTransfers,
        createStockTransfer: createStockTransfer,
        getStockTransferDetailsById: getStockTransferDetailsById,
        updateStockTransferDetails: updateStockTransferDetails,
        searchItems: searchItems,
        searchUoms: searchUoms,
        sequenceGenerator: sequenceGenerator,
        printStockTransfer: printStockTransfer,
        deleteStockTransfer: deleteStockTransfer
      };

      return buyerStockTransferService;

      /**
      * [getAllStockTransfers get all stock transfers]
      * @param  {[type]} queryParams [Query Params]
      * @return {[type]}            [description]
      */
      function getAllStockTransfers(queryParams) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/'+ API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.TRANSFER_REQUEST,
          method: "GET",
          params: queryParams
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }


      /**
      * [createStockTransfer create Stock transfer]
      * @param  {[type]} stockTransfer [Stock transfer]
      * @return {[type]}          [description]
      */
      function createStockTransfer(stockTransfer) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/'+ API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.TRANSFER_REQUEST,
          method: "POST",
          data: stockTransfer,
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      /**
      * [getStockTransferDetailsById get stock transfer details by stockId id]
      * @param  {[type]} stockId [stockId]
      * @return {[type]}        [description]
      */
      function getStockTransferDetailsById(stockId) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/'+ API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.TRANSFER_REQUEST + stockId,
          method: "GET",
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      /**
      * [updateStockTransferDetails Updates the stocktransfer details]
      * @param  {[type]} stocktransfer [stocktransfer details]
      * @return {[type]}             [description]
      */
      function updateStockTransferDetails(stocktransfer) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/'+ API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.TRANSFER_REQUEST,
          method: "PUT",
          data: stocktransfer,
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

       /**
      * [deleteStockTransfer Deletes stock transfer ]
      */
     function deleteStockTransfer(id) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/'+ API_CONSTANTS.baseVersion + 'transfer-request/' + id,
        method: "DELETE",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

      /**
      * [searchItems search items]
      * @param  {[type]} stockId [stockId Id]
      * @return {[type]}        [description]
      */
     function searchItems(queryParams) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/'+ API_CONSTANTS.baseVersion + 'stock-transfer/'+ RESOURCE_URL_CONSTANTS.ITEM_BUYER_MAPPINGS,
        method: "GET",
        params: queryParams
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }


      /**
      * [searchUoms search uoms]
      * @param  {[String]} queryParams [search Text]
      */
     function searchUoms(queryParams) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/'+ API_CONSTANTS.baseVersion + 'stock-transfer-uom',
        method: "GET",
        params: queryParams
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [sequenceGenerator Generates a unique sequence number]
     * @param {Object} documentType
     */
    function sequenceGenerator(){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase +'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.TRANSFER_REQUEST + '/sequence',
        method: "GET"
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
      * [printStockTransfer Gets the pdf]
      * @param  {[type]} id [Id of the stock transfer]
      * @return {[type]}            [description]
      */
     function printStockTransfer(id) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/'+ API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.TRANSFER_REQUEST + 'print/'+ id,
        method: "GET"
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
  })();
