/**
 * @Author: Jayalekshmi 
 * @Date: 2019-01-03 10:40:23 
 * @Project: Ezyprocure
 * @Filename: sats.po.add.controller.js
 * @Last modified by: Jayalekshmi
 * @Last modified time: 2019-02-07 12:27:09
 */



(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .controller('SatsPoAddController', SatsPoAddController);
  
    SatsPoAddController.$inject = ['$state','pagingParams', 'NotificationService', 'Auth', 'SatsManagementService', 'BuyerSupplierMappingService','GLOBAL_CONSTANTS'];
  
    function SatsPoAddController($state, pagingParams, NotificationService, Auth, SatsManagementService, BuyerSupplierMappingService, GLOBAL_CONSTANTS) {
      var vm = this;
      vm.predicate = pagingParams.predicate;
      vm.reverse = pagingParams.ascending;
      vm.filter = {};
      vm.todaysDate = new Date();
      vm.userRole = Auth.getUserRole();
      vm.uploadAttachments = uploadAttachments;
      vm.createPOJob = createPOJob;
      vm.uploadPOAttachments = uploadPOAttachments;
      vm.poJob = {};
      vm.sequenceGenerator = sequenceGenerator;
      vm.documentType = 'PO';
      vm.getAllSupplierWithSatsAccess = getAllSupplierWithSatsAccess;
      vm.statusList = GLOBAL_CONSTANTS.SATS_STATUS;
      vm.poJob.status = 'RELEASED';

      vm.sequenceGenerator();

      /**
       * [getAllSupplierWithSatsAccess Gets All suppliers with sats access]
       */
      function getAllSupplierWithSatsAccess() {
        if(vm.suppliers)
          return false;
        BuyerSupplierMappingService.getAllSupplierWithSatsAccess()
        .then(function(response){
          vm.suppliers = response.data;
        },function(error){
        });
      }

      /**
       * [sequenceGenerator Generates unique sequence number]
       */
      function sequenceGenerator(){
        SatsManagementService.sequenceGenerator({'documentType':vm.documentType})
        .then(function(response){
          vm.poJob.jobPoNo = response.data.sequence;
        },function(error){
          NotificationService.error({
            title: 'global.sats.title.'+vm.userRole.pretty,
            error: error
          });
        });
      }

      /**
       * [createPOJob Creates Job PO]
       */
      function createPOJob(){
        SatsManagementService.createPOJob({
          "container": vm.poJob.container,
          "documentNumber": vm.poJob.documentNo,
          "remarks": vm.poJob.remarks,
          "warehouse": vm.poJob.warehouse,
          "documentDate": vm.poJob.documentDate
        }).then(function(response){
          vm.job = response.data;
          vm.uploadAttachments();
        },function(error){
          NotificationService.error({
            title: 'global.sats.title.'+vm.userRole.pretty,
            error: error
          });
        })
      }

      /**
       * [uploadAttachments Validates the uploaded attachments ]
       */
      function uploadAttachments() {
        vm.errorList = null;
        var csvFile = _.isEmpty(vm.files);
        if (csvFile) {
          NotificationService.simpleErrorToast({
            title: 'alertMessage.INVALID_INPUT',
            message: 'global.pleaseChooseAValidFile'
          });
          return false;
        }
        if(SatsManagementService.checkFileType(vm.files)){
          vm.uploadPOAttachments(vm.files);
        }else{
          NotificationService.simpleErrorToast({
            title: 'alertMessage.INVALID_INPUT',
            message: 'global.pleaseChooseAValidFile'
          });
        }
      }
  
      /**
       * [uploadPOAttachments Uploads the attachments to S3.]
       * @param {Object} files 
       */
      function uploadPOAttachments(files) {
          SatsManagementService.uploadPOAttachments(files,vm.job.id)
          .then(function (response) {
              vm.files = [];
              NotificationService.success({
                title: 'global.sats.title.'+vm.userRole.pretty,
                message: 'Job Created Succesfully'
              });
              $state.go(vm.userRole.pretty+'.sats', {
                reload: true
              });
          }, function (error) {
            vm.translatedError = error.data;
            NotificationService.error({
              title: 'global.sats.title.'+vm.userRole.pretty,
              error: error
            });
          });  
      }
    }
  })();
  