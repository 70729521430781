/**
* @Author: Sandhiya
* @Project: Ezyprocure
* @Filename: admin-calendar-state.js
* @Last modified by:   Sandhiya
* @Last modified time: 17-SEP-2017 14:23 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('admin.calendar', {
      parent: 'admin',
      url: '/calendar?page&sort&search',
      data: {
        authorities: ['ROLE_ADMIN'],
        pageTitle: 'ezyprocureApp.itemGroup.home.title'
      },
      views: {
        'content@': {
          templateUrl: 'app/admin-modules/manage-calendar/admin-calendar.html',
          controller: 'AdminCalendarController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('supplierCalendar');
          return $translate.refresh();
        }]
      }
    })

    .state('admin.calendar.bulk-upload', {
          parent: 'admin.calendar',
          url: '/bulk-upload',
          data: {
            authorities: ['ROLE_ADMIN']
          },
          views: {
            'content@': {
              templateUrl: 'app/admin-modules/manage-calendar/bulk-upload/manage-calendars.bulk-upload.html',
              controller: 'ManageCalendarBulkUploadController',
              controllerAs: 'vm'
            }
          },
          resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart('buyer');
              $translatePartialLoader.addPart('global');
              $translatePartialLoader.addPart('user-management');
              return $translate.refresh();
            }]
          }
        });

  }

})();
