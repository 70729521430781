/**
* @Author: Mufsir
* @Date:   14-Dec-2016 12:54 +05:30
* @Project: Ezyprocure
* @Filename: language.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 11-Apr-2018 11:59 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .factory('LanguageService', LanguageService);

  LanguageService.$inject = ['$q', '$http', '$translate', 'LANGUAGES', 'API_CONSTANTS'];

  function LanguageService($q, $http, $translate, LANGUAGES, API_CONSTANTS) {
    var service = {
      getAll: getAll,
      getCurrent: getCurrent,
      update: update
    };

    return service;

    //Get all languages used in the app
    function getAll() {
      var deferred = $q.defer();
      deferred.resolve(LANGUAGES);
      return deferred.promise;
    }

    //Get current language
    function getCurrent() {
      var deferred = $q.defer();
      var language = $translate.storage().get('NG_TRANSLATE_LANG_KEY');
      deferred.resolve(language);
      return deferred.promise;
    }

    function update(account) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'account/language',
        data: account,
        method: "POST",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    
  }
})();
