/**
 * @Author: Ajay
 * @Date:   29-Aug-2018 11:56 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.payment-approval-request.details.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 25-Sep-2018 15:12 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerPaymentApprovalRequestDetailsController', BuyerPaymentApprovalRequestDetailsController);

  BuyerPaymentApprovalRequestDetailsController.$inject = ['$scope', '$state', '$stateParams', '$window', '$mdDialog', '$timeout', '$mdMedia', 'GLOBAL_CONSTANTS', 'toastr', 'BuyerPaymentApprovalRequestService', 'BuyerDataService', 'PromotionService', 'paginationConstants', 'NotificationService', 'GLOBAL_CONFIG_CONSTANTS', 'pagingParams'];

  function BuyerPaymentApprovalRequestDetailsController($scope, $state, $stateParams, $window, $mdDialog, $timeout, $mdMedia, GLOBAL_CONSTANTS, toastr, BuyerPaymentApprovalRequestService, BuyerDataService, PromotionService, paginationConstants, NotificationService, GLOBAL_CONFIG_CONSTANTS, pagingParams) {
    var vm = this;
    vm.isMobile = $mdMedia('xs');
    vm.paymentApprovalStatus = $stateParams.paymentApprovalStatus;
    vm.paymentApprovalRequestNumber = $stateParams.paymentApprovalRequestNumber;
    vm.pasColorConstant = GLOBAL_CONFIG_CONSTANTS.PAYMENT_APPROVAL_STATUS.LABEL_CLASS;
    vm.psColorConstant = GLOBAL_CONFIG_CONSTANTS.PAYMENT_STATUS.LABEL_CLASS;

    vm.init = init;
    vm.loadAll = loadAll;
    vm.calculateSum=calculateSum;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;

    vm.getPaymentApprovers  = getPaymentApprovers;
    vm.refreshApprovalStatus = refreshApprovalStatus;
    vm.approvePaymentApprovalRequest = approvePaymentApprovalRequest;
    vm.rejectPaymentApprovalRequest = rejectPaymentApprovalRequest;
    vm.deletePaymentApprovalRequestbyPaymentId = deletePaymentApprovalRequestbyPaymentId;
    vm.showApprovalActions = showApprovalActions;
    vm.editQtyDate = false;
    vm.approvalId = 0;
    vm.buyerId = BuyerDataService.getBuyerProfile().buyerUser.id;
    vm.todaysDate = new Date();
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.groupLabels = {
      "GROUP_1":"GROUP A",
      "GROUP_2":"GROUP B",
      "GROUP_3":"GROUP C",
      "GROUP_4":"GROUP D",
      "GROUP_5":"GROUP E"
    }
    vm.showRejectModal = showRejectModal;
    vm.cancel = cancel;
    // 2FA changes
    vm.otp = undefined;
    vm.isOtpValid = true;
    vm.counterMilliSeconds = undefined;
    vm.counterSeconds = undefined;
    vm.counterMinutes = undefined;
    vm.tempPaymentApprovalRequestNumber = undefined;
    vm.validateOTP = validateOTP;
    vm.resendOTP = resendOTP;

    // timer for otp resend
    function counter() {
      vm.stopped = $timeout(function () {
        vm.counterMilliSeconds -= 1000;
        if (vm.counterMilliSeconds) {
          var seconds = vm.counterMilliSeconds / 1000;
          vm.counterMinutes = Math.floor(seconds / 60);
          if(vm.counterMinutes > 0) {
            vm.counterSeconds = seconds - (vm.counterMinutes * 60);
          }
          else {
            vm.counterSeconds = seconds;
          }
          counter();
        }
        else {
          vm.counterSeconds = 0;
          vm.counterMinutes = 0;
          stopCounter();
        }
      }, 1000);
    };

    function stopCounter(){
      vm.isOtpValid = false;
      $timeout.cancel(vm.stopped);
    } 

    function showOtpPrompt() {
      // clear otp
      vm.otp = undefined;
      vm.isOtpValid = true;
      vm.counterSeconds = 0;
      vm.counterMinutes = 0;
      vm.counterMilliSeconds = GLOBAL_CONSTANTS.OTP_EXPIRY_MINUTES * 60 * 1000;
      counter();
      // show prompt
      $mdDialog.show({
        templateUrl: 'app/core/common-templates/otp-templates/otp-capture.tmpl.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function validateOTP() {
      if (!vm.otp) {
        toastr.error('Invalid OTP!', 'Please check the OTP entered');
        return;
      }

      if (vm.otp && (vm.otp.length < 5 || vm.otp.length > 8)) {
        toastr.error('Invalid OTP!', 'Please check the OTP entered');
        return;
      }
      approvePaymentApprovalRequest(undefined, vm.otp);
    }

    function resendOTP() {
      approvePaymentApprovalRequest();
    }

    // To refresh ApprovalStatus if user hits old url*
    function refreshApprovalStatus(newApprovalStatus) {
      //newApprovalStatus = newApprovalStatus.toLowerCase();
      if (newApprovalStatus !== vm.paymentApprovalStatus) {
        vm.paymentApprovalStatus = newApprovalStatus;
        $state.go('buyer.payment-approval-request.details', {
          paymentApprovalStatus: vm.paymentApprovalStatus,
          paymentApprovalRequestNumber: vm.paymentApprovalRequestNumber
        }, {
          reload: false
        });
      }
    }

    function init() {
      vm.loadAll();
      vm.getPaymentApprovers();
    }

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
     * [calculateSum shows the sum of key in List]
     */
     function calculateSum(paymentApprovalRequestDetailsList, key){
       var sum=_.sumBy(paymentApprovalRequestDetailsList, key)
         return sum;
     }

    //Get Payment Approval Request details by approval Request Number
    function loadAll() {
      BuyerPaymentApprovalRequestService.getPaymentApprovalRequest(vm.paymentApprovalRequestNumber, {
        page: vm.query.page - 1,
        size: vm.query.limit
      }).then(function(response) {
        vm.paymentApprovalRequestDetails = response.data;
        vm.refreshApprovalStatus(response.data.paymentApprovalStatus);
      }, function(error) {
      });
    }

    //Get Payment Approvers details by approval Request Number
    function getPaymentApprovers() {
      BuyerPaymentApprovalRequestService.getPaymentApprovers(vm.paymentApprovalRequestNumber, {
        page: 0,
        size: paginationConstants.maxLimit,
        sort: sort()
      }).then(function(response) {
        vm.approverList = response.data;
      }, function(error) {
      });
    }

    //Approve Payment Approval Request to Accepted/Rejected by approval Request Number
    function approvePaymentApprovalRequest(paymentApprovalRequestNumber, otp) {
      if(paymentApprovalRequestNumber) {
        vm.tempPaymentApprovalRequestNumber = paymentApprovalRequestNumber;
      }
      BuyerPaymentApprovalRequestService.approvePaymentApprovalRequest(paymentApprovalRequestNumber || vm.tempPaymentApprovalRequestNumber, otp).then(function(response) {
        if (response.headers('authorization-otp') == 'OTP_VERIFICATION_REQUIRED') {
          showOtpPrompt();
        }
        else if(GLOBAL_CONSTANTS.OTP_EXCEPTIONS.includes(response.headers('authorization-otp'))) {
          toastr.error('Invalid OTP', 'AUTHENTICATION FAILED');
        }
        else {
          NotificationService.success({
            title: 'global.menu.entities.paymentApprovalRequest',
            message: 'alertMessage.APPROVED_SUCCESSFULLY'
          });
          $mdDialog.hide();
          $state.go('buyer.payment-approval-request', {reload: false});
          vm.loadAll();
        }
      }, function(error) {
        NotificationService.error({
          title: 'Payment Approval Request Approving',
          error: error
        });
      });
    }

    function cancel() {
      $mdDialog.cancel();
    }

    //Show Modal for entering the reason for Reject Payment
    function showRejectModal() {
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/payment-approval-request/details/buyer.payment-approval-request-reject-tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        clickOutsideToClose: true
      });
    }

    //Reject Payment Approval Request to Accepted/Rejected by approval Request Number
    function rejectPaymentApprovalRequest(rejectRemark) {
      BuyerPaymentApprovalRequestService.rejectPaymentApprovalRequest(vm.paymentApprovalRequestNumber, rejectRemark).then(function(response) {
        NotificationService.success({
          title: 'global.menu.entities.paymentApprovalRequest',
          message: 'alertMessage.REJECTED_SUCCESSFULLY'
        });
        vm.cancel();
        $state.go('buyer.payment-approval-request', {reload: false});
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'Payment Approval Request Rejection',
          error: error
        });
      });
    }

    function deletePaymentApprovalRequestbyPaymentId(paymentId) {
      BuyerPaymentApprovalRequestService.deletePaymentApprovalRequestbyPaymentId(vm.paymentApprovalRequestNumber, paymentId).
      then(function(response) {
        NotificationService.success({
          title: 'global.menu.entities.paymentApprovalRequest',
          message: 'Removed successfully from payment approval!'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'Payment Approval Request Deletion',
          error: error
        });
      });
    }

    /*
    Show Approval Actions buttons only if
    1. paymentApprovalStatus is PENDING
    2. Logged in buyer User ID is same as approvalNeededFrom.id
    3. paymentApprovalRequestedBuyerUser.id is not same as approvalNeededFrom.id
    */
    function showApprovalActions() {
      return (vm.paymentApprovalStatus === 'PENDING' &&
      vm.buyerId === vm.paymentApprovalRequestDetails.approvalNeededFrom.id &&
      vm.paymentApprovalRequestDetails.paymentApprovalRequestedBuyerUser.id !== vm.paymentApprovalRequestDetails.approvalNeededFrom.id
      );

    }

  }
})();
