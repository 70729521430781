/**
* @Author: Mufsir
* @Date:   14-Dec-2016 12:54 +05:30
* @Project: Ezyprocure
* @Filename: reset.finish.controller.js
* @Last modified by:   Mufsir
* @Last modified time: 10-May-2017 16:52 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('ResetFinishController', ResetFinishController);

  ResetFinishController.$inject = ['$stateParams', '$timeout', 'Auth'];

  function ResetFinishController($stateParams, $timeout, Auth) {
    var vm = this;

    vm.keyMissing = angular.isUndefined($stateParams.key);
    vm.confirmPassword = null;
    vm.doNotMatch = null;
    vm.error = null;
    vm.finishReset = finishReset;
    vm.resetAccount = {};
    vm.success = false;

    function finishReset() {
      vm.doNotMatch = null;
      vm.error = null;
      vm.errorData = null;
      //check if new password and confirm password matches
      if (vm.resetAccount.password !== vm.confirmPassword) {
        vm.doNotMatch = 'ERROR';
      } else {
        //call service to reset password
        Auth.resetPasswordFinish({
          key: $stateParams.key,
          newPassword: vm.resetAccount.password
        }).then(function(response) {
          vm.success = true;
        }).catch(function(error) {
          vm.success = false;
          vm.errorData = error.headers('X-ezyprocureApp-error');
        });
      }
    }
  }
})();
