/**
 * @Author: Ajay
 * @Date:   27-Dec-2017 16:11 +05:30
 * @Project: Ezyprocure
 * @Filename: ezypayment.buyer.epd-approval-request.controller.js
 * @Last modified by:   Allan
 * @Last modified time: 27-August-2018 11:12 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzypaymentBuyerEpdApprovalRequestController', EzypaymentBuyerEpdApprovalRequestController);

  EzypaymentBuyerEpdApprovalRequestController.$inject = ['$scope', '$rootScope', '$filter', '$state', '$stateParams', 'NotificationService', '$mdDialog', 'pagingParams', 'EpdApprovalRequestService', 'paginationConstants', 'GLOBAL_CONFIG_CONSTANTS'];

  function EzypaymentBuyerEpdApprovalRequestController($scope, $rootScope, $filter, $state, $stateParams, NotificationService, $mdDialog, pagingParams, EpdApprovalRequestService, paginationConstants, GLOBAL_CONFIG_CONSTANTS) {
    var vm = this;
    vm.init = init;
    vm.predicate = 'epdApprovalSequenceNumber';
    vm.reverse = pagingParams.ascending;
    vm.loadAll = loadAll;
    vm.showEpdApprovalRequestRejectModal = showEpdApprovalRequestRejectModal;
    vm.cancel = cancel;
    vm.approveEPDApprovalRequest = approveEPDApprovalRequest;
    vm.rejectEPDApprovalRequest = rejectEPDApprovalRequest;
    vm.searchText = '';
    vm.epdApprovalStatusClass = GLOBAL_CONFIG_CONSTANTS.EPD_APPROVAL_STATUS.LABEL_CLASS;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    function init() {
      loadAll();
    }
    /**
     * [sort to sort by PO date]
     */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    //Get all Price update requests
    function loadAll(search) {
      if (!search) {
        vm.searchText = '';
      }
      EpdApprovalRequestService.getAllEPDApprovalRequests({
        page: vm.query.page - 1,
        size: vm.query.limit,
        query: vm.searchText,
        epdApprovalStatus: 'PENDING',
        sort: sort()
      }).then(function(response) {
        vm.epdApprovalRequests = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      }, function(error) {
        NotificationService.error({
          title: 'EPD Approval Request Listing',
          error: error
        });
      });
    }

    //Show Epd Approval Request reject modal
    function showEpdApprovalRequestRejectModal(event, epdApprovalRequest) {
      vm.selectedEpdApprovalRequestItem = epdApprovalRequest;
      $mdDialog.show({
        templateUrl: 'app/ezypayment-modules/buyer-modules/epd-approval-request/ezypayment.buyer.epd-approval-request.reject.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function approveEPDApprovalRequest(epdApprovalRequest) {
      EpdApprovalRequestService.approveEPDApprovalRequest(epdApprovalRequest)
      .then(function(response) {
        vm.cancel();
        NotificationService.success({
          title: 'Epd Approval Request',
          message: 'Approved successfully'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'Epd Approval Request Approving',
          error: error
        });
      });
    }

    function rejectEPDApprovalRequest(epdApprovalRequest) {
      EpdApprovalRequestService.rejectEPDApprovalRequest(epdApprovalRequest)
      .then(function(response) {
        vm.cancel();
        NotificationService.success({
          title: 'Epd Approval Request',
          message: 'Rejected successfully'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'Epd Approval Request Rejecting',
          error: error
        });
      });
    }

    function cancel() {
      $mdDialog.cancel();
    }
  }
})();
