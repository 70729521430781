/*
 * @Author: Jayalekshmi 
 * @Date: 2019-06-14 12:13:48 
 * @Project: Ezyprocure
 * @Filename: supplier.buyer-delivery-condition-mapping.service.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-06-14 12:38:58
 */



 (function() {
  'use strict';

  angular.module('ezyprocureApp').factory('SupplierBuyerDeliveryConditionMapService', SupplierBuyerDeliveryConditionMapService);

  SupplierBuyerDeliveryConditionMapService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  function SupplierBuyerDeliveryConditionMapService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var supplierBuyerDeliveryConditionMapService = {
      mapBuyerDeliveryConditionMapping: mapBuyerDeliveryConditionMapping,
      unMapBuyerDeliveryConditionMapping: unMapBuyerDeliveryConditionMapping,
      getAllMappedBuyerDeliveryCondition: getAllMappedBuyerDeliveryCondition
    };

    return supplierBuyerDeliveryConditionMapService;

    //Create a new Buyer and Delivery Condition mapping
    function mapBuyerDeliveryConditionMapping(mapping) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYER_DELIVERY_CONDITIONS, mapping).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    // Soft Delete existing Buyer and Delivery Condition mapping
    function unMapBuyerDeliveryConditionMapping(mapping) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYER_DELIVERY_CONDITIONS+'mappings/unmap', mapping).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    // Gets all mapped buyer delvery conditions
    function getAllMappedBuyerDeliveryCondition(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYER_DELIVERY_CONDITIONS,
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
