/*
 * @Author: Lovin 
 * @Date: 2020-07-23 19:32:15 
 * @Last Modified by:   Lovin 
 * @Last Modified time: 2020-07-23 19:32:15 
 */

(function () {
    'use strict';

    angular.module('ezyprocureApp').factory('Redirect', Redirect);
    Redirect.$inject = ['$location', 'API_CONSTANTS'];

    function Redirect($location, API_CONSTANTS) {
        var service = {
            getHostMapping: getHostMapping,
            isEzyApHost: isEzyApHost
        };
        return service;

        function getHostMapping() {
            var host = $location.$$host;
            return API_CONSTANTS.hostToLandingPages[host];
        }

        function isEzyApHost() {
            var host = $location.$$host;
            return !!API_CONSTANTS.hostToLandingPages[host];
        }
    }
})();
