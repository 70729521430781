/**
* @Author: sneha
* @Date:   2019-01-24T17:23:21+05:30
* @Project: Ezyprocure
* @Filename: sftp-details.add.controller.js
* @Last modified by:   Jayalekshmi
* @Last modified time: 2019-02-22T08:43:19+05:30
*/

(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SFTPDetailsAddController', SFTPDetailsAddController);

  SFTPDetailsAddController.$inject = ['$scope', '$state', 'BuyerDataService', 'Auth', 'paginationConstants', 'NotificationService', 'BuyerSupplierMappingService', 'SupplierDataService', 'SupplierSFTPDetailsService', 'GLOBAL_CONSTANTS', '$stateParams'];

  function SFTPDetailsAddController($scope, $state, BuyerDataService, Auth, paginationConstants, NotificationService, BuyerSupplierMappingService, SupplierDataService, SupplierSFTPDetailsService, GLOBAL_CONSTANTS, $stateParams) {
    var vm = this;
    vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
    vm.addSFTPDetails = addSFTPDetails;
    vm.confirmPassword = null;
    vm.userRole = Auth.getUserRole();
    vm.supplierId = vm.userRole.pretty === 'supplier'? SupplierDataService.getSupplierProfile().supplier.id : null;
    vm.buyerId = vm.userRole.pretty === 'buyer'? BuyerDataService.getBuyerProfile().buyerUser.buyer.id: null;
    vm.fileTypes = GLOBAL_CONSTANTS.FILE_TYPES;
    vm.module=$stateParams.module;
    vm.redirectToHome=redirectToHome;
    vm.redirectToSftpDetails=redirectToSftpDetails;
    vm.getAllBuyerMappedSuppliers = getAllBuyerMappedSuppliers;

     /**
      * [getAllBuyerMappedSuppliers get all suppliers mapped to the logged in buyer]
      * @return {[type]} [description]
      */
     function getAllBuyerMappedSuppliers(){
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
      };
      if(vm.suppliers){
        return false;
      }
      BuyerSupplierMappingService.getAllBuyerMappedSuppliers(vm.buyerId, params).then(function(response) {
        vm.suppliers = response.data;
      }, function(error) {
      });
    }

    /**
    * [getAllSupplierMappedBuyers get all buyer mapped to logged in supplier]
    * @return {[type]} [description]
    */
    function getAllSupplierMappedBuyers(){
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
      };
      if(vm.buyers)
      return false;
      BuyerSupplierMappingService.getAllSupplierMappedBuyers(vm.supplierId, params).then(function(response) {
        vm.buyers = response.data;
      }, function(error) {
      });
    }

    /**
    * [addSFTPDetails add sftp details]
    */
    function addSFTPDetails(){
      if(vm.sftpDetails.password !== vm.confirmPassword){
        NotificationService.simpleErrorToast({
          title: 'global.navbar.SFTPDetails',
          message: 'alertMessage.PASSWORDS_DOES_NOT_MATCH'
        });
      }
      else{
        vm.sftpDetails.fileType = 'PURCHASE_ORDER';
        vm.userRole.pretty === 'supplier'? vm.sftpDetails.supplierId = vm.supplierId: vm.sftpDetails.buyerId = vm.buyerId;
        vm.sftpDetails.deleted = false;
        vm.sftpDetails.module = vm.module;
        vm.sftpDetails.accountType = vm.userRole.pretty.toUpperCase();
        SupplierSFTPDetailsService.createSFTPServer(vm.sftpDetails).then(function(response) {
          vm.sftpDetails = response.data;
          vm.module=='PEPPOL'? $state.go('ezyap.supplier.sftp-details', {
            reload: false
          }):
          $state.go(vm.userRole.pretty + '.tools.sftp-details', {
            reload: false
          });
        }, function(error) {
          NotificationService.error({
            title: 'global.navbar.SFTPDetails',
            error: error
          });
        });
      }
    }

    /**
     * [redirectToHome Redirects to home]
     */
    function redirectToHome(){
      switch(vm.module){
        case 'PEPPOL':
          $state.go('ezyap.'+ vm.userRole.pretty +'.dashboard');
          break;
        case 'EZYPROCURE':
          $state.go(vm.userRole.pretty + '.dashboard');
          break;      
      }
    }

    /**
     * [redirectToSftpDetails Redirects to SFTP details page]
     */
    function redirectToSftpDetails(){
      switch(vm.module){
        case 'PEPPOL':
          $state.go('ezyap.'+ vm.userRole.pretty +'.sftp-details');
          break;
        case 'EZYPROCURE':
          $state.go(vm.userRole.pretty + '.tools.sftp-details');      
          break;      
      }
    }
  }
})();
