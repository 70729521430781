/**
* @Author: Allan
* @Date:   07-June-2018 16:15 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.cards.controller.js
* @Last modified by:   Allan
* @Last modified time: 17-October-2018 14:45 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzypaymentCardsController', EzypaymentCardsController);

  EzypaymentCardsController.$inject = ['$stateParams', 'CardsService', 'NotificationService', '$mdDialog', 'GLOBAL_CONSTANTS', 'paginationConstants'];

  function EzypaymentCardsController($stateParams ,CardsService, NotificationService, $mdDialog, GLOBAL_CONSTANTS, paginationConstants) {

    var vm = this;

    vm.getAllCards = getAllCards;
    vm.deleteCard = deleteCard;
    vm.showConfirm = showConfirm;
    vm.deleteSupplierCard = deleteSupplierCard;
    vm.init = init;
    vm.cardType = GLOBAL_CONSTANTS.CREDIT_CARD.TYPES;
    vm.getAllSupplierCards = getAllSupplierCards;
    vm.buyerQuery = {
      limit : paginationConstants.itemsPerPage,
      page : 1
    }
    vm.supplierQuery = {
      limit : paginationConstants.itemsPerPage,
      page : 1
    }
    vm.limitOptions = paginationConstants.limitOptions;
    vm.initiallyActiveTab = $stateParams.initiallyActiveTab;

    function init() {
      vm.getAllCards();
      vm.getAllSupplierCards();
    }

    /**
     * [getAllSupplierCards Gets all supliers credit cards]
     */
    function getAllSupplierCards(){
      CardsService.getAllSupplierCards({
        type: 'viewCards',
        page: vm.supplierQuery.page - 1,
        size: vm.supplierQuery.limit,
        query: ''
      }).then(function(response){
        vm.supplierCards = response.data;
        vm.totalSupplierCards = response.headers('X-Total-Count');
      }, function(error){
        NotificationService.error({
          title: 'Credit Card Details',
          error: error
        });
      })
    }

    /**
     * [getAllCards Gets all buyer credit cards]
     */
    function getAllCards() {
      CardsService.getAllCards({
        type: 'viewCards',
        page: vm.buyerQuery.page - 1,
        size: vm.buyerQuery.limit,
        query: ''
      }).then(function(response){
        vm.cards = response.data;
        vm.totalBuyerCards = response.headers('X-Total-Count');
      },function(error) {
        NotificationService.error({
          title: 'Credit Card Details',
          error: error
        });
      });
    }

    function deleteCard(brandId, creditCardId) {
      CardsService.deleteCard(brandId, creditCardId).then(function(response){
        NotificationService.success({
          title: response.data.status,
          message: response.data.message
        });
        vm.getAllCards();
      },function(error) {
        NotificationService.error({
          title: 'Delete Credit Card',
          error: error
        });
      });
    }

    // Deletes the supplier card by card id and supplier id
    function deleteSupplierCard(supplierId, creditCardId) {
      CardsService.deleteSupplierCard(supplierId, creditCardId).then(function(response){
        NotificationService.success({
          title: response.data.status,
          message: response.data.message
        });
        vm.getAllSupplierCards();
      },function(error) {
        NotificationService.error({
          title: 'Delete Supplier Credit Card',
          error: error
        });
      });
    }

    // Shows modal before deleting the card
    function showConfirm (type, userRoleId, id, ev) {
      var confirm = $mdDialog.confirm()
      .title('Are you sure you want to delete this Card Detail?')
      .textContent('This action could not be reverted back.')
      .targetEvent(ev)
      .ok('Delete')
      .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        if(type === 'SUPPLIER_CARD'){
          vm.deleteSupplierCard(userRoleId, id);
        }else if(type === 'BUYER_CARD'){
          deleteCard(userRoleId, id);
        }
      }, function() {
      });
    };

  }
})();
