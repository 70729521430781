/**
 * @Author: Ajay
 * @Date:   15-Oct-2018 15:59 +05:30
 * @Project: Ezyprocure
 * @Filename: idle.handler.js
 * @Last modified by:   Ajay
 * @Last modified time: 15-Oct-2018 17:10 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .factory('idleHandler', idleHandler);

  idleHandler.$inject = ['$rootScope', 'toastr', 'Auth', 'Principal', 'Idle'];

  function idleHandler($rootScope, toastr, Auth, Principal, Idle) {
    return {
      initialize: initialize
    };

    function initialize() {

      /* Idle Service :-
      Idle is used to watch for user events to bubble up to document.body. 
      Once you start watching for idleness, a timeout will start. 
      If that timeout expires, it will broadcast the IdleStart event on the root scope 
      and enter the warning phase. During the warning phase, the IdleWarn event will 
      be broadcast every second, counting down to zero. 
      When the countdown hits zero, the IdleTimeout event is broadcast, indicating the 
      user's session has expired. If at any time the user triggers a watched event, it will 
      reset the idle and warning timeouts and states and broadcast the IdleEnd event on the 
      root scope. If the user keeps clicking, scrolling, etc. within the idle duration, 
      they can ostensibly stay logged in forever.
      */

      var idleStart = $rootScope.$on('IdleStart', function() {
        // the user appears to have gone idle
        //toastr.info('You are idle now!', 'Ng Idle : IdleStart event');
      });

      var idleWarn = $rootScope.$on('IdleWarn', function(e, countdown) {
        // follows after the IdleStart event, but includes a countdown until the user is considered timed out
        // the countdown arg is the number of seconds remaining until then.
        // you can change the title or display a warning dialog from here.
        // you can let them resume their session by calling Idle.watch()
        toastr.warning("You'll be logged out in " +countdown +" seconds", 'Ng Idle : IdleWarn event');
      });

      var idleEnd = $rootScope.$on('IdleEnd', function() {
        toastr.success('Idle Ended, Your now Active!!!!', 'Ng Idle : IdleEnd event');
        //console.info("Idle Ended, Your now Active!!!! :)");
        // the user has come back from AFK and is doing stuff.
        //if you are warning them, you can use this to hide the dialog
      });

      var idleTimeout = $rootScope.$on('IdleTimeout', function() {
        // the user has timed out (meaning idleDuration + timeout has passed without any activity)
        // this is where you'd log them
        toastr.error("You were idle too long. Normally you'd be logged out, but in this demo just do anything and you'll be reset.", 'Ng Idle : IdleTimeout event');
        Auth.logout();
      });

      /* 
      Keepalive Service :-
      `Keepalive` is used to periodically broadcast the Keepalive event on the root scope. 
      You can listen on this event and respond however you like. 
      Usually, you'd make an HTTP request to some endpoint to keep the user's session alive. 
      As such, it can be configured to make an $http request for you. 
      */

      var keepalive = $rootScope.$on('Keepalive', function() {
        //toastr.info("Do something to keep the user's session alive", "Ng Idle : Keepalive event");
        // do something to keep the user's session alive
      });

      $rootScope.$on('$destroy', function() {
        if(angular.isDefined(idleStart) && idleStart !== null){
          idleStart();
        }
        if(angular.isDefined(idleWarn) && idleWarn !== null){
          idleWarn();
        }
        if(angular.isDefined(idleEnd) && idleEnd !== null){
          idleEnd();
        }
        if(angular.isDefined(idleTimeout) && idleTimeout !== null){
          idleTimeout();
        }
        if(angular.isDefined(keepalive) && keepalive !== null){
          keepalive();
        }
      });

    }

  }
})();
