/**
* @Author: Ajay
* @Date:   27-Apr-2017 12:48 +05:30
* @Project: Ezyprocure
* @Filename: supplier.adhoc-invoice.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 27-Jun-2017 12:39 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('SupplierAdhocInvoiceService', SupplierAdhocInvoiceService);

  SupplierAdhocInvoiceService.$inject = ['$http', '$q', 'API_CONSTANTS'];

  function SupplierAdhocInvoiceService($http, $q, API_CONSTANTS) {

    var supplierAdhocInvoiceService = {
      setAdhocInvoiceFilter: setAdhocInvoiceFilter,
      getAdhocInvoiceFilter: getAdhocInvoiceFilter,
      createNewAdhocInvoice: createNewAdhocInvoice
    };

    var adhocInvoiceFilter;

    return supplierAdhocInvoiceService;

    /**
     * [getAdhocInvoiceFilter retrive adhoc invoice filter]
     * @return {[object]} [adhoc invoice object containing buyer list and business outlets]
     */
    function getAdhocInvoiceFilter() {
      return adhocInvoiceFilter ? adhocInvoiceFilter : null;
    }

    /**
     * [setAdhocInvoiceFilter store adhoc invoice filter]
     * @param {[object]} adhocInvoiceFilterParam [adhoc invoice object containing buyer list and business outlets]
     */
    function setAdhocInvoiceFilter(adhocInvoiceFilterParam) {
      adhocInvoiceFilter = adhocInvoiceFilterParam;
    }

    /**
     * [createNewAdhocInvoice create a new adhoc invoice]
     * @param  {[List]} adhocInvoiceDTO [list of cartItems]
     * @param  {[Integer]} buyerId         [buyerId]
     * @return {[type]}                 [description]
     */
    function createNewAdhocInvoice(adhocInvoiceDTO) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'invoices/adhoc-invoice/',
        data: adhocInvoiceDTO,
        method: "POST",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }






  }
})();
