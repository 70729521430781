/**
* @Author: Ajay
* @Date:   28-Mar-2017 12:35 +05:30
* @Project: Ezyprocure
* @Filename: buyer.email.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 10-Nov-2017 10:46 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerEmailController', BuyerEmailController);

  BuyerEmailController.$inject = ['$scope', '$state', '$stateParams', 'NotificationService', 'BuyerDataService', 'BuyerEmailService', 'BuyerSupplierMappingService', 'GLOBAL_CONSTANTS'];

  function BuyerEmailController($scope, $state, $stateParams, NotificationService, BuyerDataService, BuyerEmailService, BuyerSupplierMappingService, GLOBAL_CONSTANTS) {

    var vm = this;
    vm.init = init;
    vm.getAllBuyerMappedSuppliers = getAllBuyerMappedSuppliers;
    vm.populateSelectedEmails = populateSelectedEmails;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.sendEmail = sendEmail;

    vm.subjectLimit = GLOBAL_CONSTANTS.emailConfiguration.SUBJECT_SIZE_LIMIT;
    vm.bodyLimit = GLOBAL_CONSTANTS.emailConfiguration.BODY_SIZE_LIMIT;

    /**
     * [getAllBuyerMappedSuppliers Get all supplier mapped buyers]
     * @return {[list]} [List of buyers]
     */
    function getAllBuyerMappedSuppliers() {
      if (vm.suppliers) return false;
      var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      BuyerSupplierMappingService.getAllBuyerMappedSupplierList(buyerId).then(function(response) {
        vm.suppliers = response.data;
      }, function(error) {
      });
    }


    /**
     * [init Load initial data]
     */
    function init() {
      vm.buyerName  = BuyerDataService.getBuyerProfile().buyerUser.buyer.companyName;
      vm.emailDTO = {
        recipients: [],
        subject: '',
        body: ''
      };
    }

    /**
     * [selectAll Select all buyers from the list and create separate string array of emails]
     */
    function selectAll() {
      vm.supplierEmailList = _.map(vm.suppliers, _.property('email'));
    }

    /**
     * [deselectAll Deselect all suppliers from the list]
     */
    function deselectAll() {
      vm.supplierEmailList = [];
    }

    /**
     * [populateSelectedEmails Populate selected emails and union it to md-chips]
     */
    function populateSelectedEmails() {
      vm.emailDTO.recipients = _.union(vm.emailDTO.recipients, vm.supplierEmailList);
    }

    /**
     * [sendEmail Send email to recipients]
     */
    function sendEmail() {
      BuyerEmailService.sendEmailToSupplier(vm.emailDTO).then(function(response) {
        NotificationService.success({
          title: 'Buyer - Email to Supplier',
          message: 'Email sent successfully'
        });
      }, function(error) {
        NotificationService.error({
          title: 'Buyer - Email to Supplier',
          error: error
        });
      });
    }

  }
})();
