/**
* @Author: Ajay
* @Date:   08-Mar-2017 15:58 +05:30
* @Project: Ezyprocure
* @Filename: manage-buyers.add.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 06-Nov-2017 14:54 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('ManageBuyerAddController', ManageBuyerAddController);

  ManageBuyerAddController.$inject = ['$scope', '$state', '$stateParams', 'Auth', 'ManageBuyerService', 'ImageUploadService', 'EzyCompanyService', 'GLOBAL_CONSTANTS', 'NotificationService'];

  function ManageBuyerAddController($scope, $state, $stateParams, Auth, ManageBuyerService, ImageUploadService, EzyCompanyService, GLOBAL_CONSTANTS, NotificationService) {
    var vm = this;

    vm.buyer = {};
    vm.save = save;
    vm.setCompanyLogo = setCompanyLogo;
    vm.uploadLogo = uploadLogo;
    vm.userRole = Auth.getUserRole();
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.createIdentifier = createIdentifier;
    vm.customDutyTypes = GLOBAL_CONSTANTS.CUSTOM_DUTY_TYPE;

    function save() {
      // Call Admin buyer service with buyer object as parameter
      ManageBuyerService.createAdminBuyer(vm.buyer).then(function(response) {
        $scope.$emit('ezyprocureApp:supplierUpdate', response);
        NotificationService.success({
          title: 'Admin Buyer',
          message: 'alertMessage.CREATED_SUCCESSFULLY'
        });
        vm.buyerDetail = response;
        if(vm.userRole.pretty == 'admin' && vm.ezyCompanyDetails){
          vm.createIdentifier();
        }
        $state.go(vm.userRole.pretty + '.buyers', {
          reload: false
        });
      }, function(error) {
        NotificationService.error({
          title: 'Admin Buyer Creation',
          error: error
        });
      });
    }

    /**
     * [createIdentifier Creates the Identifier details]
     */
    function createIdentifier() {
      EzyCompanyService.createIdentifier(
        {ezpCompanyId:vm.buyerDetail.id,
         identifier:vm.ezyCompanyDetails.identifier,
         isoCode:vm.ezyCompanyDetails.isoCode,
         userRole:'ROLE_BUYER',
         id:''}).then(function(response) {
      }, function(error) {
        NotificationService.simpleErrorToast({
          title: 'Admin Buyer Creation',
          message: error.data.message
        });
      });
    }
  

    /**IMAGE UPLOAD**/

    function setCompanyLogo(flowFile) {
      ImageUploadService.getImageBase64(flowFile.file, function(base64Img) {
        vm.uploadLogo(base64Img);
      });
    }

    function uploadLogo(base64Img) {
      vm.uploadingInProgress = true;
      ImageUploadService.uploadImage(base64Img).
      then(function(responseData) {
        vm.buyer.companyLogo = responseData.url;
        $scope.addBuyerForm.$pristine = false;
        NotificationService.success({
          title: 'Buyer Company logo',
          message: 'alertMessage.UPLOADED_SUCCESSFULLY'
        });
        vm.uploadingInProgress = false;
      }, function(error) {
        vm.uploadingInProgress = false;
        NotificationService.error({
          title: 'Buyer Company logo Upload',
          error: error
        });
      });
    }

  }
})();
