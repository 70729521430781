/**
* @Author: Ajay
* @Date:   20-Mar-2017 11:56 +05:30
* @Project: Ezyprocure
* @Filename: supplier.price-update-history.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-06-25T13:33:11+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierPriceUpdateHistoryController', SupplierPriceUpdateHistoryController);

  SupplierPriceUpdateHistoryController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', 'pagingParams', '$stateParams', 'toastr', '$mdDialog', 'UTCDateService', 'BuyerSupplierMappingService', 'BrandManagementService', 'SupplierDataService', 'PriceUpdateHistoryService', 'NotificationService', 'ItemGroupService', 'paginationConstants', 'CSVExportService', 'EXPORT_CONSTANTS', 'GLOBAL_CONFIG_CONSTANTS', 'GLOBAL_CONSTANTS', 'Auth', 'BuyerBusinessService'];

  function SupplierPriceUpdateHistoryController($scope, $rootScope, $q, $filter, $state, pagingParams, $stateParams, toastr, $mdDialog, UTCDateService, BuyerSupplierMappingService, BrandManagementService, SupplierDataService, PriceUpdateHistoryService, NotificationService, ItemGroupService, paginationConstants, CSVExportService, EXPORT_CONSTANTS, GLOBAL_CONFIG_CONSTANTS, GLOBAL_CONSTANTS, Auth, BuyerBusinessService) {
    var vm = this;
    vm.init = init;
    vm.todaysDate = new Date();
    vm.loadAll = loadAll;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.priceUpdateStatusClass = GLOBAL_CONFIG_CONSTANTS.PRICE_UPDATE_STATUS.LABEL_CLASS;
    vm.loadItemGroups=loadItemGroups;
    vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
    vm.getAllBrandsOfBuyerList = getAllBrandsOfBuyerList;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.priceUpdate = {
      brandIdList: [],
      buyerIdList: [],
      businessIdList: []
    }

    //Function Declaration
    vm.getPriceUpdateHistoryCSVData = getPriceUpdateHistoryCSVData;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.accessForPriceDisplay = Auth.hasPermission('accessForPriceDisplay');
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.resetFilters = resetFilters;

    function init(){
      if(vm.accessForPriceDisplay)
      //Header
       vm.priceUpdateReportHeader = EXPORT_CONSTANTS.CSV.SupplierPriceUpdateReport;
      else {
        vm.priceUpdateReportHeader = _.without(EXPORT_CONSTANTS.CSV.SupplierPriceUpdateReport, 'Previous Price' , 'Updated Price', 'Currency');
      }
    }

    function getAllBuyerBusinessOutlets(){
     if (!vm.priceUpdate.brandIdList || !vm.priceUpdate.buyerIdList) return false;
      var itemTerminationDTO = {
        'brandIdList': vm.priceUpdate.brandIdList,
        'buyerIdList': vm.priceUpdate.buyerIdList
      }
      var params = {
        page: 0,
        size: paginationConstants.maxLimit
      }
      BuyerBusinessService.getAllBrandMappedsBusinessOutlets(itemTerminationDTO, params).then(function (response) {
        vm.brandMappedBusinessOutlets = response.data;
      }, function (error) {
      });
    }

    // get all buyers mapped by supplier
    function getAllSupplierMappedBuyers() {
      vm.priceUpdate.brandIdList = [];
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
      };
      if(vm.buyers)
        return false;
      var supplierId = SupplierDataService.getSupplierProfile().supplier.id;
      BuyerSupplierMappingService.getAllSupplierMappedBuyers(supplierId, params).then(function(response){
        vm.buyers = response.data;
      },function(error){
      });
    }

     /**
     * [getAllBrandsOfBuyerList Gets all brands of selected buyers]
     * @param {Array} buyerIdList
     */
    function getAllBrandsOfBuyerList(buyerIdList){
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        query:'',
        buyerId : buyerIdList
      };
      BrandManagementService.getAllBrandsOfBuyerList(params)
      .then(function(response){
        vm.brands = response;
      },function(error){
        NotificationService.error({
          title: 'Brand Listing',
          error: error
        });
      });
    }

    /**
     * [selectAll Selects all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets or brands]
     */
    function selectAll(dropdown) {
      switch (dropdown) {
        case 'BRANDS':
          vm.priceUpdate.brandIdList = _.map(vm.brands, _.property('id'));
          break;
        case 'BUYERS':
          vm.priceUpdate.buyerIdList = _.map(vm.buyers, _.property('id'));
           break;
        case 'BUSINESSOUTLETS':
          vm.priceUpdate.businessIdList = _.map(vm.brandMappedBusinessOutlets, _.property('id'));
          break;
      }
    }


    /**
     * [deselectAll Deselect all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets or brands]
     */
    function deselectAll(dropdown) {
      switch (dropdown) {
        case 'BRANDS':
          vm.priceUpdate.brandIdList = [];
          break;
        case 'BUYERS':
          vm.priceUpdate.buyerIdList = [];
          break;
        case 'BUSINESSOUTLETS':
          vm.priceUpdate.businessIdList = [];
          break;
      }
    }

    //Getting CSV DATA
    function getPriceUpdateHistoryCSVData(){
      var deferred = $q.defer();
      if (!vm.priceUpdateItems) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getSupplierPriceUpdateHistoryReportCSV(vm.priceUpdateItems);
    }
    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function loadItemGroups() {
      if(vm.itemGroups)
        return false;
      ItemGroupService.getAllItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.itemGroups = response.data;
      }, function(error) {
      });
    }

    /**
    * [loadAll loads all price update history]
    */
    function loadAll() {
      PriceUpdateHistoryService.getAllPriceUpdatesHistory({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        fromDate: !vm.fromDate ? '' : UTCDateService.utcFromDate(vm.fromDate),
        toDate: !vm.toDate ? '' : UTCDateService.utcToDate(vm.toDate),
        supplierId: '',
        buyerIdList: vm.priceUpdate.buyerIdList ? vm.priceUpdate.buyerIdList : '',
        brandIdList : vm.priceUpdate.brandIdList ? vm.priceUpdate.brandIdList : '',
        itemGroupId: !vm.itemGroupId ? '' : vm.itemGroupId,
        businessIdList: vm.priceUpdate.businessIdList ? vm.priceUpdate.businessIdList : '',
        itemName: vm.itemName ? vm.itemName : '',
        itemCode: vm.itemCode ? vm.itemCode : ''
      }).then(function(response){
        vm.priceUpdateItems = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      },function(error){
        NotificationService.error({
          title: 'Price Updates History',
          error: error
        });
      });
    }
    function resetFilters() {
        vm.filter = {};
        vm.fromDate = vm.todaysDate;
        vm.toDate = vm.todaysDate;
        vm.itemCode = null;
        vm.itemName = null;
        vm.itemGroupId = null;
        vm.priceUpdate.businessIdList = [];
        vm.priceUpdate.brandIdList = [];
        vm.priceUpdate.buyerIdList = [];
        vm.priceUpdateItems = [];
    }
  }
})();
