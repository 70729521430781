(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .factory('PasswordResetFinish', PasswordResetFinish);

  PasswordResetFinish.$inject = ['$resource', 'API_CONSTANTS'];

  function PasswordResetFinish($resource, API_CONSTANTS) {
    var service = $resource('api/' + API_CONSTANTS.baseVersion + 'account/password/reset/finish', {}, {});

    return service;
  }
})();
