/*
 * @Author: Ajay 
 * @Date: 2019-02-18 08:28:58 
 * @Project: Ezyprocure
 * @Filename: ezyap.buyer.dashboard.state.js
 * @Last Modified by: Ajay
 * @Last Modified time: 2019-02-19 15:42:22
 */

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('ezyap.buyer.dashboard', {
      parent: 'ezyap.buyer',
      url: '/dashboard',
      data: {
        authorities: ['ROLE_BUYER']
      },
      views: {
        'content@': {
          templateUrl: 'app/ezyap-modules/buyer-modules/dashboard/ezyap.buyer.dashboard.html',
          controller: 'EzyapBuyerDashboardController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }],
        hasModuleAccess: ['Auth', '$q', '$state', function (Auth, $q, $state) {
          if (Auth.hasPermission('accessForPEPPOL') || Auth.hasPermission('accessForOnlyEzyAP')) {
            return true;
          } else {
            return $q.reject('Unauthorized');
          }
        }]
      }
    })
  }
})();
