/*
 * @Author: Gayathri
 * @Date: 2018-12-26 11:46:35
 * @Project: Ezyprocure
 * @Filename: buyer.order-template.bulk-upload.controller.js
 * @Last Modified by: Lovin chacko
 * @Last Modified time: 2020-04-01 09:06:22
 */
(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('OrderTemplateItemBulkUploadController', OrderTemplateItemBulkUploadController);

  OrderTemplateItemBulkUploadController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'toastr', 'CsvUploadService', 'paginationConstants', 'NotificationService','BuyerOrderTemplateService','S3_URL_CONSTANTS'];

  function OrderTemplateItemBulkUploadController($scope, $rootScope, $state, $stateParams, toastr, CsvUploadService, paginationConstants, NotificationService, BuyerOrderTemplateService,S3_URL_CONSTANTS) {
    var vm = this;
    vm.init = init;
    vm.getCSVBase64 = getCSVBase64;
    vm.itemBulkUploadCSV = itemBulkUploadCSV;
    vm.ORDER_TEMPLATE_CSV = S3_URL_CONSTANTS.ORDER_TEMPLATE_CSV;

    function init() {
      vm.csvUploadData = {};
    }
    
    /**
     * [getCSVBase64 Converts the uploaded csv to base64]
     */
    function getCSVBase64() {
      vm.errorList = null;
      var csvFile = vm.files[0];
      if (!csvFile) {
        NotificationService.error({
          title: 'alertMessage.PLEASE_UPLOAD_CSV_FILE',
          error: error
        });
        return false;
      }
      CsvUploadService.getFileBase64(csvFile.lfFile, function(base64CSV) {
        vm.csvUploadData.encodedFile = base64CSV;
        vm.itemBulkUploadCSV(vm.csvUploadData);
      });
    }
    
    /**
     * [itemBulkUploadCSV Bulk Upload Template Items using CSV]
     */
    function itemBulkUploadCSV(uploadData) {
      BuyerOrderTemplateService.bulkUpdateOrderTemplate(uploadData)
      .then(function(response) {
        vm.files = [];
        NotificationService.success({
          title: 'global.bulkOrderTemplateItem',
          message: 'alertMessage.UPLOADED_SUCCESSFULLY'
        });
        $state.go('buyer.tools.order-template', {
          reload: false
        });
      }, function(error) {
        vm.recordError = error.data;
        NotificationService.error({
          title: 'global.bulkOrderTemplateItem',
          error: error
        });
      });
    }

  }
})();
