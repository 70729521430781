/**
 * @Author: Jayalekshmi
 * @Date:   2018-06-28T13:51:21+05:30
 * @Filename: buyer.brand-supplier-mapping.controller.js
 * @Last modified by:   Jayalekshmi
 * @Last modified time: 2018-06-28T16:00:33+05:30
 */



(function() {
    'use strict';

    angular
    .module('ezyprocureApp')
    .controller('BuyerBrandSupplierMapController', BuyerBrandSupplierMapController);

    BuyerBrandSupplierMapController.$inject = ['$scope', '$rootScope' ,'$state', '$stateParams', 'toastr', 'pagingParams', 'BuyerDataService', 'paginationConstants', 'NotificationService', 'BrandSupplierMapService'];

    function BuyerBrandSupplierMapController($scope, $rootScope, $state, $stateParams, toastr, pagingParams, BuyerDataService, paginationConstants, NotificationService, BrandSupplierMapService) {
      var vm = this;
      vm.predicate = pagingParams.predicate;
      vm.reverse = pagingParams.ascending;
      vm.sort = sort;
      vm.loadAll = loadAll;
      vm.brandSupplierMappingSearch = '';
      vm.deleteBrandSupplierMapping = deleteBrandSupplierMapping;
      vm.supplierSort = ["supplier.companyName,asc"];
      vm.mapping = {};
     

      vm.query = {
        limit: paginationConstants.itemsPerPage,
        page: 1
      };
      vm.limitOptions = paginationConstants.limitOptions;

      function sort() {
        var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
        return result;
      }

      /**
       * [loadAll Load All supplier which are mapped]
       * @param  {[String]} search [Search parameter]
       */
      function loadAll(search) {
        if (!search) {
          vm.brandSupplierMappingSearch = '';
        }
        BrandSupplierMapService.getAllBrandSupplierMappings({
          page: vm.query.page - 1,
          size: vm.query.limit,
          sort: sort(),
          query: vm.brandSupplierMappingSearch,
        }).then(function(response){
          vm.totalItems = response.headers('X-Total-Count');
           vm.brandSupplierMappings = response.data;
        },function(error){
          NotificationService.error({
            title: 'Mapped Brand - Supplier Listing',
            error: error
          });
        });
      }

      vm.loadAll();

      /**
       * [deleteBrandSupplierMapping Softdeletes selected buyer and supplier]
       * @param  {[Integer]} mappingId [Id of the mapping]
       */
      function deleteBrandSupplierMapping(mappingId) {
        BrandSupplierMapService.deleteBrandSupplierMapping(mappingId).then(function(response){
          NotificationService.success({
            title: 'Brand - Supplier',
            message: 'Mapping deleted successfully'
          });
          vm.loadAll();
        },function(error) {
          NotificationService.error({
            title: 'Brand - Supplier - Delete Mapping',
            error: error
          });
        });
      }
    }
})();
