/**
* @Author: Abhay
* @Date:   26-Aug-2020 08:48 IST
* @Project: Ezyprocure
* @Filename: touch-handler.directive.js
* @Last modified by:   Abhay
* @Last modified time: 26-Aug-2020 08:48 IST
*/

(function () {
    'use strict';

    angular
        .module('ezyprocureApp')
        .directive('longPress', longPress);

    longPress.$inject = ['$mdMedia'];

    function longPress($mdMedia) {
        var directive = {
            restrict: 'A',
            link: linkFunction
        };

        return directive;

        function linkFunction(scope, element, attrs) {
            var isMobile = $mdMedia('xs');
            if (isMobile) {
                // touch events
                element.bind('touchstart', startAction);
                element.bind('touchend', endAction);
                element.bind('touchmove', endAction);
                element.bind('touchcancel', endAction);
            }
            else {
                // mouse events
                element.bind('mousedown', startAction);
                element.bind('mouseup', endAction);
                element.bind('mouseout', cancelAction);
            }

            function startAction() {
                scope.$apply(function () {
                    scope.$eval(attrs.longPress)
                });
            }

            function endAction() {
                scope.$apply(function () {
                    scope.$eval(attrs.longPressEnd)
                });
            }

            function cancelAction() {
                scope.$apply(function () {
                    scope.$eval(attrs.longPressCancel)
                });
            }
        }
    }
})();