/**
* @Author: Ajay
* @Date:   24-Feb-2017 12:40 +05:30
* @Project: Ezyprocure
* @Filename: supplier.item.mapping.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2018-06-29T12:49:48+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierItemMappingController', SupplierItemMappingController);

  SupplierItemMappingController.$inject = ['$scope', '$state', '$stateParams', '$element', 'NotificationService', 'pagingParams', 'SupplierDataService', 'SupplierItemService', 'BuyerSupplierMappingService', 'BuyerItemOutletMapService', 'BuyerBusinessService', 'SupplierItemMapService', 'BrandManagementService', 'paginationConstants'];

  function SupplierItemMappingController ($scope, $state, $stateParams, $element, NotificationService, pagingParams, SupplierDataService, SupplierItemService, BuyerSupplierMappingService, BuyerItemOutletMapService, BuyerBusinessService, SupplierItemMapService, BrandManagementService, paginationConstants) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.allSelected = false;
    vm.sort = sort;
    vm.loadAll = loadAll;
    vm.deleteMapping = deleteMapping;
    vm.init = init;
    vm.mappedItemSearch = '';
    vm.deleteMappingByIds = deleteMappingByIds;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.mappedItems = [];
    vm.limitOptions = paginationConstants.limitOptions;
    vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
    vm.getAllBrandsOfBuyerList = getAllBrandsOfBuyerList;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.itemDTOObj = {
      buyerIdList: [],
      brandIdList: []
    }
    vm.buyerBrandFilter = buyerBrandFilter;
    vm.checkAllRows = checkAllRows;
    vm.getSelectedRows = getSelectedRows;
    vm.getSelectedItemsDetailList = getSelectedItemsDetailList;
    vm.unmapMultipleItems = unmapMultipleItems;

    function checkAllRows() {
      vm.mappedItems.forEach(function (item) {
        item.isSelected = vm.allSelected;
      });
    }

    function getSelectedRows() {
      if (vm.mappedItems && vm.mappedItems.length > 0) {
        var selectedIdList = vm.mappedItems.filter(function(item) {
          return item.isSelected;
        });
      }
      return selectedIdList || [];
    }

    function getSelectedItemsDetailList() {
      var selectedItemsDetailList = [];
      vm.mappedItems.forEach(function (item) {
        if(item.isSelected) {
          var itemDetail = {
            buyerId: item.buyerId,
            itemBusinessMappingId: item.itemBusinessMappingId,
            itemId: item.id,
            userType: 'SUPPLIER'
          }
          selectedItemsDetailList.push(itemDetail);
        }
      });
      return selectedItemsDetailList;
    }

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
    * [items filterd by buyer and brand]
    */
    function buyerBrandFilter(){
      SupplierItemMapService.getAllMappedItems({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        buyerIdList : vm.itemDTOObj.buyerIdList,
        brandIdList : vm.itemDTOObj.brandIdList,
        query: vm.mappedItemSearch,
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.mappedItems = response.data;
      },function(error){
        NotificationService.error({
          title: 'Mapped Items Listing',
          error: error
        });
      });
  }

     /**
     * [selectAll Selects all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, buyers or brands]
     */
    function selectAll(dropdown) {
      switch (dropdown) {
        case 'BUYERS':
          vm.itemDTOObj.buyerIdList = _.map(vm.buyers, _.property('id'));
          break;
        case 'BRANDS':
          vm.itemDTOObj.brandIdList = _.map(vm.brands, _.property('id'));
          break;
      }

    }

    /**
     * [deselectAll Deselect all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, buyers or brands]
     */
    function deselectAll(dropdown) {
      switch (dropdown) {
        case 'BUYERS':
          vm.itemDTOObj.buyerIdList = [];
          vm.itemDTOObj.brandIdList = [];
          break;
        case 'BRANDS':
          vm.itemDTOObj.brandIdList = [];
          break;
      }
    }

    // Load All supplier items which are mapped
    function loadAll (search) {
      if (!search) {
        vm.mappedItemSearch = '';
      }
      SupplierItemMapService.getAllMappedItems({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        buyerIdList: vm.itemDTOObj.buyerIdList,
        brandIdList: vm.itemDTOObj.brandIdList,
        query: vm.mappedItemSearch,

      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.mappedItems = response.data;
      },function(error){
        NotificationService.error({
          title: 'Mapped Items Listing',
          error: error
        });
      });
    }

    //delete a mapping
    function deleteMapping(itemBusinessMappingId) {
      BuyerItemOutletMapService.deleteMappingByItemBusinessMappingId(itemBusinessMappingId).then(function(response){
        NotificationService.success({
          title: 'Item Mapping',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      },function(error){
        NotificationService.error({
          title: 'Item Mapping Deletion',
          error: error
        });
      });
    }

    /**
     * [deleteMappingByIds by ids ]
     * @param {*} itemId
     */
    function deleteMappingByIds(itemBusinessMappingId,buyerId, itemId) {
      BuyerItemOutletMapService.deleteMappingByItemBusinessMappingIds(itemBusinessMappingId, buyerId, itemId, 'SUPPLIER').then(function(response){
        NotificationService.success({
          title: 'Item Mapping',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      },function(error){
        NotificationService.error({
          title: 'Item Mapping Deletion',
          error: error
        });
      });
    }

    function unmapMultipleItems() {
      var itemDetailList = getSelectedItemsDetailList();
      BuyerItemOutletMapService.unmapMultipleItems(itemDetailList).then(function(response){
        NotificationService.success({
          title: 'Item Mapping',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      },function(error){
        NotificationService.error({
          title: 'Item Mapping Deletion',
          error: error
        });
      });
    }

    /**
     * [get all buyers mapped with supplier]
     */
    function getAllSupplierMappedBuyers() {
      vm.itemDTOObj.brandIdList = [];
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
      };
      if(vm.buyers)
        return false;
      var supplierId = SupplierDataService.getSupplierProfile().supplier.id;
      BuyerSupplierMappingService.getAllSupplierMappedBuyers(supplierId, params).then(function(response) {
        vm.buyers = response.data;
      }, function(error) {
      });
    }

    /**
     * [getAllBrandsOfBuyerList Gets all brands of selected buyers]
     * @param {Array} buyerIdList
     */
    function getAllBrandsOfBuyerList(){
      if(vm.itemDTOObj.buyerIdList.length == 0) {
        NotificationService.simpleErrorToast({
          title: 'Brand Listing',
          message: 'alertMessage.PLEASE_SELECT_ANY_BUYER'
        });
        return false;
      }
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        query:'',
        buyerId : vm.itemDTOObj.buyerIdList
      };
      BrandManagementService.getAllBrandsOfBuyerList(params)
      .then(function(response){
        vm.brands = response;
      },function(error){
        NotificationService.error({
          title: 'Brand Listing',
          error: error
        });
      });
    }

    function init() {
      vm.loadAll();
    }

    vm.init();

  }
})();
