/*
 * @Author: Jayalekshmi 
 * @Date: 2020-03-04 15:22:44 
 * @Project: Ezyprocure
 * @Filename: admin.amendments.state.js
 * @Last Modified By : Jayalekshmi
 * @Last Modified time: 2020-03-05 14:56:44
 */

(function() {
	'use strict';

	angular.module('ezyprocureApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider.state('admin.amendments', {
			parent: 'admin',
			url: '/amendments',
			data: {
				authorities: [ 'ROLE_ADMIN' ],
				pageTitle: 'ezyprocureApp.itemGroup.home.title'
			},
			views: {
				'content@': {
					templateUrl: 'app/admin-modules/amendmets/admin.amendments.html',
					controller: 'AdminAmendmentsController',
					controllerAs: 'vm'
				}
			},
			params: {
				page: {
					value: '1',
					squash: true
				},
				sort: {
					value: 'id,asc',
					squash: true
				},
				search: null
			},
			resolve: {
				pagingParams: [
					'$stateParams',
					'PaginationUtil',
					function($stateParams, PaginationUtil) {
						return {
							page: PaginationUtil.parsePage($stateParams.page),
							sort: $stateParams.sort,
							predicate: PaginationUtil.parsePredicate($stateParams.sort),
							ascending: PaginationUtil.parseAscending($stateParams.sort),
							search: $stateParams.search
						};
					}
				],
				translatePartialLoader: [
					'$translate',
					'$translatePartialLoader',
					function($translate, $translatePartialLoader) {
						$translatePartialLoader.addPart('global');
						return $translate.refresh();
					}
				]
			}
		});
	}
})();
