/**
* @Author: Ajay
* @Date:   14-Dec-2016 12:54 +05:30
* @Project: Ezyprocure
* @Filename: http.config.js
* @Last modified by:   Ajay
* @Last modified time: 27-Mar-2017 18:55 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(httpConfig);

  httpConfig.$inject = ['$urlRouterProvider', '$httpProvider', 'httpRequestInterceptorCacheBusterProvider', '$urlMatcherFactoryProvider'];

  function httpConfig($urlRouterProvider, $httpProvider, httpRequestInterceptorCacheBusterProvider, $urlMatcherFactoryProvider) {
    //Cache everything except rest api requests
    httpRequestInterceptorCacheBusterProvider.setMatchlist([/.*api.*/, /.*protected.*/], true);

    $urlRouterProvider.otherwise('/');

    $httpProvider.interceptors.push('errorHandlerInterceptor');
    $httpProvider.interceptors.push('authExpiredInterceptor');
    $httpProvider.interceptors.push('authInterceptor');
    $httpProvider.interceptors.push('notificationInterceptor');
    // jhipster-needle-angularjs-add-interceptor JHipster will add new application http interceptor here

    // Disable strict URL matching mode
    $urlMatcherFactoryProvider.type('boolean', {
      name : 'boolean',
      decode: function(val) { return val === true || val === 'true'; },
      encode: function(val) { return val ? 1 : 0; },
      equals: function(a, b) { return this.is(a) && a === b; },
      is: function(val) { return [true,false,0,1].indexOf(val) >= 0; },
      pattern: /bool|true|0|1/
    });
  }
})();
