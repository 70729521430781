/**
* @Author: Allan
* @Project: Ezyprocure
* @Filename: ezypayment.admin.account-details.controller.js
* @Last modified by:   lovin
* @Last modified time: 2018-11-01 23+05:30
*/


(function(){
    'use strict';
    angular
    .module('ezyprocureApp')
    .controller('EzypaymentAccountDetailsController', EzypaymentAccountDetailsController);
  
    EzypaymentAccountDetailsController.$inject = ['$state','$stateParams' ,'$scope', 'Auth', 'paginationConstants','SupplierDataService','BuyerDataService', 'NotificationService', 'BrandManagementService', '$mdDialog', 'GLOBAL_CONSTANTS', 'EzypaymentAccountDetailsService']
  
    function EzypaymentAccountDetailsController($state,$stateParams ,$scope,Auth, paginationConstants,SupplierDataService,BuyerDataService, NotificationService, BrandManagementService, $mdDialog, GLOBAL_CONSTANTS, EzypaymentAccountDetailsService){
  
      var vm = this;
      vm.userRole = Auth.getUserRole();
      vm.query = {
        limit: paginationConstants.itemsPerPage,
        page: 1
      };
      vm.limitOptions = paginationConstants.limitOptions;
      vm.isEditable = true;
      vm.supplierMerchantDetails = [];
      vm.getAllAccountDetail = getAllAccountDetail;
      vm.init = init;
      vm.initialActive = $stateParams.initialActive;
      vm.deleteAccountDetail = deleteAccountDetail;
      vm.userRole = Auth.getUserRole();
      vm.loadAll = loadAll;
  
      vm.init();
  
      function init(){
        vm.getAllAccountDetail();
      }

      function loadAll(search){
        if(!vm.searchText){
          vm.searchText = '';
        }
        vm.init();
      }
      /**
       * [deleteAccountDetail Deletes Selected Account Detail]
       */
      function deleteAccountDetail(brandId) {
        EzypaymentAccountDetailsService.deleteAccountDetail(brandId).then(function(response){
          NotificationService.success({
            title: ' Account Detail',
            message: 'alertMessage.DELETED_SUCCESSFULLY'
          });
          vm.init();
        },
        function(error) {
          NotificationService.error({
            title: ' Account Detail',
            error: error
          });
        }); 
      }
      
      /**
       * [getAllAccountDetail Gets all list of All Account Detail]
       */
      function getAllAccountDetail(){
        var supplierId =vm.userRole.pretty == 'supplier'? SupplierDataService.getSupplierProfile().supplier.id: null;
        var buyerId = vm.userRole.pretty == 'buyer'? BuyerDataService.getBuyerProfile().buyerUser.buyer.id: null;
        EzypaymentAccountDetailsService.getAllAccountDetail( {
          page: vm.query.page - 1,
          size: vm.query.limit,
          supplierId: supplierId,
          buyerId: buyerId,
          query: vm.searchText,
        }).then(function(response){
          vm.totalItems = response.headers('X-Total-Count');
          vm.accountDetails = response.data;
        });
      }
       
    }
  })();
  