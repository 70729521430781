/**
* @Author: Ajay
* @Date:   27-Dec-2017 16:11 +05:30
* @Project: Ezyprocure
* @Filename: supplier.logs-history.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 12-Oct-2018 15:00 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierLogsHistoryController', SupplierLogsHistoryController);

  SupplierLogsHistoryController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', '$stateParams', 'toastr', '$mdDialog', 'pagingParams',  'UTCDateService', 'NotificationService', 'SupplierPriceUpdateHistoryService', 'SupplierUserManagementService', 'CSVExportService', 'paginationConstants', 'EXPORT_CONSTANTS', 'GLOBAL_CONSTANTS'];

  function SupplierLogsHistoryController($scope, $rootScope, $q, $filter, $state, $stateParams, toastr, $mdDialog, pagingParams, UTCDateService, NotificationService, SupplierPriceUpdateHistoryService, SupplierUserManagementService, CSVExportService, paginationConstants,  EXPORT_CONSTANTS, GLOBAL_CONSTANTS ) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.todaysDate = new Date();
    vm.loadAll = loadAll;
    vm.getAllSupplierUsers = getAllSupplierUsers;
    vm.SelectedUser = null;
    vm.selectedTransactionType = null;
    vm.getAllSupplierUsers=getAllSupplierUsers;
    vm.rePostTransaction=rePostTransaction;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };

    vm.limitOptions = paginationConstants.limitOptions;
    //Function Declaration
    vm.getAuditLogHistoryCSVData = getAuditLogHistoryCSVData;
    vm.defaultDateTimeFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_TIME_FORMAT;
    vm.resetFilters = resetFilters;

    //Getting CSV DATA
    function getAuditLogHistoryCSVData(){
      var deferred = $q.defer();
      if (!vm.priceUpdateItems) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getBuyerAuditLogHistoryReportCSV(vm.priceUpdateItems, vm.selectedTransactionType);
    }

    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }
    vm.transactionTypes = GLOBAL_CONSTANTS.TRANSACTION_TYPES_SUPPLIER;

    function getAllSupplierUsers(){
      if(vm.supplierUsers)
        return false;
      SupplierUserManagementService.getAllSupplierUsers({
        page: 0,
        size: paginationConstants.maxLimit,
        sort: sort(),
        query: ''
      }).then(function(response){
        vm.supplierUsers = response.data;
      },function(error){
      });
    }
    /**
    * [loadAll loads all price update history]
    */
    function loadAll() {
      SupplierPriceUpdateHistoryService.getAuditTransactionData({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        fromDate: !vm.fromDate ? '' : UTCDateService.utcFromDate(vm.fromDate),
        toDate: !vm.toDate ? '' : UTCDateService.utcToDate(vm.toDate),
        userId: !vm.SelectedUser ? '' : vm.SelectedUser,
        transactionId: !vm.transactionId ? '' : vm.transactionId,
        transactionType: !vm.selectedTransactionType ? '' : vm.selectedTransactionType,
        query:''
      }).then(function(response) {
        // CSV Header
        var supplierAuditLogReportHeader = EXPORT_CONSTANTS.CSV.BuyerAuditLogReport;
        // Header changes for selectedTransactionType of Purchase Request Item Approval
        vm.auditLogReportHeader = (vm.selectedTransactionType!=='Purchase Request Item Approval')? (supplierAuditLogReportHeader) : (supplierAuditLogReportHeader.concat('purchaseRequestItemApproval'));
        vm.priceUpdateItems = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      }, function(error) {
        NotificationService.error({
          title: 'Audit Transaction Data',
          error: error
        });
      });
    }
    /**
    * [Repost to SATS API for Failed EXPORT_TRANSACTION_DETAILS type]
    */
    function rePostTransaction(transactionDetails){
    SupplierPriceUpdateHistoryService.rePostTransactionData({
        type: transactionDetails[8]=='NA'?'PO':'PR',
        transactionId: transactionDetails[0]
    }).then(function(response) {
        NotificationService.success({
          title: 'Processing Started',
          message: 'Success'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'Audit Transaction Data',
          error: error
        });
      });
    }

    function resetFilters() {
        vm.filter = {};
        vm.fromDate = vm.todaysDate;
        vm.toDate = vm.todaysDate;
        vm.SelectedUser = null;
        vm.transactionId = null;
        vm.selectedTransactionType = vm.transactionTypes[0].value;
        vm.priceUpdateItems = [];
    }

  }
})();
