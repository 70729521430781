/**
* @Author: Ajay
* @Date:   14-Dec-2016 12:54 +05:30
* @Project: Ezyprocure
* @Filename: translation-storage.provider.js
 * @Last modified by:   Ajay
 * @Last modified time: 01-Aug-2018 15:31 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .factory('translationStorageProvider', translationStorageProvider);

  translationStorageProvider.$inject = ['$cookies', '$log', 'LANGUAGES', 'GLOBAL_CONSTANTS'];

  function translationStorageProvider($cookies, $log, LANGUAGES, GLOBAL_CONSTANTS) {
    return {
      get: get,
      put: put
    };

    // Get language details
    function get(name) {
      // If no language details available reset to english
      if (LANGUAGES.indexOf($cookies.getObject(name)) === -1) {
        var defaultLanguage = GLOBAL_CONSTANTS.DEFAULT_LANGUAGE;
        $log.info('Resetting invalid cookie language "' + $cookies.getObject(name) + '" to preferred language '+defaultLanguage);
        $cookies.putObject(name, defaultLanguage, GLOBAL_CONSTANTS.COOKIE_OPTIONS);
      }
      return $cookies.getObject(name);
    }

    function put(name, value) {
      $cookies.putObject(name, value, GLOBAL_CONSTANTS.COOKIE_OPTIONS);
    }
  }
})();
