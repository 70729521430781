/**
* @Author: Abhay
* @Date:   05-February-2021 16:15 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.odbc-initiate-payment.controller.js
* @Last modified by:   Abhay
* @Last modified time: 05-February-2021 16:15 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzypaymentOdbcInitiatePaymentController', EzypaymentOdbcInitiatePaymentController);

  EzypaymentOdbcInitiatePaymentController.$inject = ['$mdDialog', '$state','$filter', '$timeout', 'EzypaymentInitiatePaymentService', 'paginationConstants', 'toastr', 'BuyerOCRService',  'SupplierDataService', 'BuyerSupplierMappingService','UTCDateService', 'NotificationService', 'GLOBAL_CONSTANTS', 'DuePaymentInvoicesService', 'InvoiceService'];

  function EzypaymentOdbcInitiatePaymentController($mdDialog , $state, $filter, $timeout, EzypaymentInitiatePaymentService, paginationConstants, toastr, BuyerOCRService, SupplierDataService, BuyerSupplierMappingService, UTCDateService, NotificationService, GLOBAL_CONSTANTS, DuePaymentInvoicesService, InvoiceService) {

    var vm = this;
    vm.filter = {};
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.selectAllRows = false;
    vm.checkAllRows = checkAllRows;
    vm.init = init;
    vm.getDuePaymentSummary = getDuePaymentSummary;
    vm.getSelectedInvoices = getSelectedInvoices;
    vm.getSelectedInvoiceIds = getSelectedInvoiceIds;
    vm.getInitiatePayment = getInitiatePayment;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.paymentMethods = GLOBAL_CONSTANTS.PAYMENT_METHODS;
    vm.checkPaymentMethod = checkPaymentMethod;
    vm.externalPayments = externalPayments;
    vm.getSelectedPOs = getSelectedPOs;
    vm.getSelectedPOIds = getSelectedPOIds;
    vm.cancel = cancel;
    vm.getSelectedInvoiceIdsforExternal=getSelectedInvoiceIdsforExternal;
    vm.filter.paymentMethod = vm.paymentMethods[0].value;
    vm.sipRequest = sipRequest;
    vm.buyerSelectionChanged = buyerSelectionChanged;
    vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
    vm.getSelectedPOsForSIPApproval = getSelectedPOsForSIPApproval;
    vm.types = GLOBAL_CONSTANTS.SIP_EZYPAYMENT_INVOICE_TYPES;
    vm.approvalStatuses = GLOBAL_CONSTANTS.SIP_EXTERNAL_APPROVAL_STATUSES;
    vm.filter.type = 'INTERNAL';
    vm.confirmRemoveExternalInvoiceModal=confirmRemoveExternalInvoiceModal;
    vm.removeExternalInvoice=removeExternalInvoice;
    var selectedInvoiceIds="";
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.filter.buyerIdList=[];
    // 2FA changes
    vm.otp = undefined;
    vm.isOtpValid = true;
    vm.counterMilliSeconds = undefined;
    vm.counterSeconds = undefined;
    vm.counterMinutes = undefined;
    vm.validateOTP = validateOTP;
    vm.resendOTP = resendOTP;

    function init(){
      vm.getInitiatePayment();
    }

    // timer for otp resend
    function counter() {
      vm.stopped = $timeout(function () {
        vm.counterMilliSeconds -= 1000;
        if (vm.counterMilliSeconds) {
          var seconds = vm.counterMilliSeconds / 1000;
          vm.counterMinutes = Math.floor(seconds / 60);
          if(vm.counterMinutes > 0) {
            vm.counterSeconds = seconds - (vm.counterMinutes * 60);
          }
          else {
            vm.counterSeconds = seconds;
          }
          counter();
        }
        else {
          vm.counterSeconds = 0;
          vm.counterMinutes = 0;
          stopCounter();
        }
      }, 1000);
    };

    function stopCounter(){
      vm.isOtpValid = false;
      $timeout.cancel(vm.stopped);
    } 

    function showOtpPrompt() {
      // clear otp
      vm.otp = undefined;
      vm.isOtpValid = true;
      vm.counterSeconds = 0;
      vm.counterMinutes = 0;
      vm.counterMilliSeconds = GLOBAL_CONSTANTS.OTP_EXPIRY_MINUTES * 60 * 1000;
      counter();
      // show prompt
      $mdDialog.show({
        templateUrl: 'app/core/common-templates/otp-templates/otp-capture.tmpl.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function validateOTP() {
      if (!vm.otp) {
        toastr.error('Invalid OTP!', 'Please check the OTP entered');
        return;
      }

      if (vm.otp && (vm.otp.length < 5 || vm.otp.length > 8)) {
        toastr.error('Invalid OTP!', 'Please check the OTP entered');
        return;
      }
      externalPayments(vm.otp);
    }

    function resendOTP() {
      externalPayments();
    }

    function checkAllRows() {
      vm.selectAllRows ? vm.selectAllRows = true : vm.selectAllRows = false;
      angular.forEach(vm.initiatePayment, function(invoice) {
        invoice.selected = vm.selectAllRows;
      });
    }

    function removeExternalInvoice(invoiceId){
          vm.cancel();
          DuePaymentInvoicesService.removeExternalInvoice(invoiceId).then(function(response) {
            NotificationService.success({
              title: 'External Invoice',
              message: 'alertMessage.DELETED_SUCCESSFULLY'
            });
            vm.getInitiatePayment();
          }, function(error) {
            NotificationService.error({
              title: 'External Invoice',
              error: error
            });
          });
        }

        function confirmRemoveExternalInvoiceModal(event, invoiceId) {
            var confirm = $mdDialog.confirm()
            .title($filter('translate')('alertMessage.REMOVE_INVOICE_CONFIRMATION'))
            .targetEvent(event)
            .ok('Delete')
            .cancel('Cancel');

            $mdDialog.show(confirm).then(function() {
              removeExternalInvoice(invoiceId);
            }, function() {});
          }

    function getInitiatePayment(){
      if(vm.filter.type=='INTERNAL'){
        vm.filter.invoiceApprovalRequestStatus = '';
      }
      if(vm.filter.type=="EXTERNALSIP"){
      DuePaymentInvoicesService.getExternalInvoices({
          page: vm.query.page - 1,
          size: vm.query.limit,
          poNumber: !vm.filter.poNumber ? '': vm.filter.poNumber,
          fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
          toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
          brandIds: !vm.filter.brandIds ? '' : String(vm.filter.brandIds),
          supplierId: !vm.filter.supplierIds ? '' : String(vm.filter.supplierIds),
          invoiceApprovalRequestStatus : !vm.filter.invoiceApprovalRequestStatus ? '': vm.filter.invoiceApprovalRequestStatus,
          paymentStatus: "'UNPAID','FAILED','VOID','CANCELLED'",
          kycStatus: 'SUCCESSFUL',
          paymentMethod: vm.filter.paymentMethod,
          type: !vm.filter.type ? '': vm.filter.type,
          sort: sort()
            }).then(function(response){
             vm.initiatePayment = response.data;
             vm.totalItems = response.headers('X-Total-Count');
            },function(error) {
              NotificationService.error({
                title: 'Fetching External Invoices',
                error: error
              });
            });
      }else{
      BuyerOCRService.getAllPurchaseOrders({
        page: vm.query.page - 1,
        size: vm.query.limit,
        query: !vm.filter.poNumber ? '': vm.filter.poNumber,
        invoiceNumber: !vm.filter.invoiceNumber ? '' : vm.filter.invoiceNumber,
        fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
        toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
        brandIds: !vm.filter.brandIds ? '' : String(vm.filter.brandIds),
        buyerIds:!vm.filter.buyerIdList?[]:vm.filter.buyerIdList,
        businessOutletIds: !vm.filter.businessOutletIds ? '' : vm.filter.businessOutletIds,
        supplierId: '',
        paymentStatus: "'UNPAID','FAILED','VOID','CANCELLED'",
        kycStatus: 'SUCCESSFUL',
        module: 'supplierPayment',
        invoiceStatus: 'PROCESSED',
        ocrInvoiceStatus: '',
        ocrAcceptanceStatus: '',
        geoExported: '',
        paymentMethod: vm.filter.paymentMethod,
        hardcopyInvoiceNumber: !vm.filter.hardcopyInvoiceNumber ? '' : vm.filter.hardcopyInvoiceNumber,
        type: !vm.filter.type ? '': vm.filter.type,
        invoiceApprovalRequestStatus : !vm.filter.invoiceApprovalRequestStatus ? '': vm.filter.invoiceApprovalRequestStatus,
        sort: sort(),
      }).then(function(response){
        vm.initiatePayment = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      },function(error){
        NotificationService.error({
          title: 'Fetching Invoices',
          error: error
        });
      });
      }
    }

    function getDuePaymentSummary(){
      var selectedInvoices = vm.getSelectedInvoices(vm.initiatePayment);
      if(vm.filter.type=="EXTERNALSIP"){
      selectedInvoiceIds = vm.getSelectedInvoiceIdsforExternal(selectedInvoices);
      }else{
      selectedInvoiceIds = vm.getSelectedInvoiceIds(selectedInvoices);
      }
      if(selectedInvoiceIds.length > 0){
        EzypaymentInitiatePaymentService.setSelectedInvoiceIds(selectedInvoiceIds);
        $state.go('ezypayment.supplier.odbc-initiate-payment.details',{'type':vm.filter.type,'selectedInvoiceIds':selectedInvoiceIds});
      } else {
        toastr.error('Please select invoices to pay.', 'Fetching Invoice Details');
      }
    }

    function getSelectedInvoices(invoices) {
      var selectedInvoices = _.filter(invoices, function(invoice) {
        return invoice.selected === true;
      });
      return selectedInvoices;
    }

    function getSelectedInvoiceIds(selectedInvoices) {
      var invoiceIdList = _.map(selectedInvoices, _.property('purchaseOrder.id'));
      return invoiceIdList;
    }
    function getSelectedInvoiceIdsforExternal(selectedInvoices) {
          var invoiceIdList = _.map(selectedInvoices, _.property('id'));
          return invoiceIdList;
        }

    //select all buyers mapped to the supplier
    function selectAll(dropdown){
      switch(dropdown){
        case 'BUYERS':
          vm.filter.buyerIdList = _.map(vm.buyers, _.property('id'));
        break;
      }
    }

    //deselect all buyers
    function deselectAll(dropdown){
      switch(dropdown){
        case 'BUYERS':
        vm.filter.buyerIdList = [];
      }
    }

    //Get all buyers mapped to the supplier
      function getAllSupplierMappedBuyers() {
        if (vm.buyers && !vm.filter.buyerSelectionChanged)
          return false;
        vm.filter.buyerSelectionChanged = false;
        var params = {
          page: 0,
          size: paginationConstants.maxLimit,
        };
        var supplierId = SupplierDataService.getSupplierProfile().supplier.id;
        BuyerSupplierMappingService.getAllSupplierMappedBuyers(supplierId, params).then(function (response) {
          vm.buyers = response.data;
          vm.filter.selectedBuyerIdList = [];
        }, function (error) {
        });
      }

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
     * [confirmAndPay Show the modal to enter the remarks]
     * @param {*} event
     */
    function confirmAndPay(event){
      var selectedPOs = vm.getSelectedPOs(vm.initiatePayment);
      var selectedPOIds = vm.getSelectedPOIds(selectedPOs);
      if(selectedPOIds.length <= 0){
        NotificationService.simpleErrorToast({
          title: 'global.fetchingInvoiceDetails',
          message: 'global.pleaseSelectInvoiceToPay'
        });
        return false;
      }
      $mdDialog.show({
        templateUrl: 'app/common-modules/ezypayment/ezypayment.payment.confirm-pay.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    /**
     * [checkPaymentMethod Decides whether to goto details page or show popup]
     */
    function checkPaymentMethod() {
      var selectedPOs = vm.getSelectedPOs(vm.initiatePayment);
      var sipStatus = true;
      var selectedPOSipStatus = [];
      // gets the selected invoices with sip status true

      selectedPOSipStatus = _.find(selectedPOs, ['purchaseOrder.invoice.type', 'SIP_INVOICE']);

      if(selectedPOSipStatus){
        _.map(selectedPOs, function(po){
          if(po.invoiceApprovalRequestStatus !== 'APPROVED'){
            sipStatus = false;
          }
        });
        if(!sipStatus){
          toastr.error('Please select Approved invoices.', 'Approval Request');
          return false;
        }
      }
      vm.filter.paymentMethod === 'EZYPAY' ? vm.getDuePaymentSummary() : confirmAndPay();
    }

    /**
     * [externalPayments Pays external payment]
     */
    function externalPayments(otp) {
      var selectedPOs = vm.getSelectedPOs(vm.initiatePayment);
      var selectedPOIds = vm.getSelectedPOIds(selectedPOs);
      if(selectedPOIds.length > 0){
        vm.externalPaymentDetails ={
          purchaseOrderIdList :selectedPOIds ? selectedPOIds : [],
          remarks:vm.remarks,
          paymentMethod: vm.filter.paymentMethod
        }
        DuePaymentInvoicesService.externalConfirmPayment(vm.externalPaymentDetails, otp)
        .then(function(response){
          if (response.headers('authorization-otp') == 'OTP_VERIFICATION_REQUIRED') {
            showOtpPrompt();
          }
          else if(GLOBAL_CONSTANTS.OTP_EXCEPTIONS.includes(response.headers('authorization-otp'))) {
            toastr.error('Invalid OTP', 'AUTHENTICATION FAILED');
          }
          else {
            NotificationService.success({
              title: 'global.paymentForInvoices',
              message: 'global.successfullyMadePayments'
            });
            $mdDialog.hide();
            vm.cancel();
            vm.init();
          }
        },function(error) {
          NotificationService.error({
            title: 'global.paymentForInvoices',
            error: error
          });
          vm.cancel();
        });

      } else {
        NotificationService.simpleErrorToast({
          title: 'global.fetchingInvoiceDetails',
          message: 'global.pleaseSelectInvoiceToPay'
        });
      }
    }

    function buyerSelectionChanged() {
          vm.filter.buyerSelectionChanged = true;
          vm.filter.brandIdList = [];
          vm.filter.businessOutletIdList = [];
        }

    /**
     * [getSelectedPOs Filters selected POs]
     * @param {*} poItems
     */
    function getSelectedPOs(poItems) {
      var selectedPOs = _.filter(poItems, function(poItem) {
        return poItem.selected === true;
      });
      return selectedPOs;
    }

    /**
     * [getSelectedPOsForSIPApproval Filters selected POs]
     * @param {*} poItems
     */
    function getSelectedPOsForSIPApproval(poItems) {
      var selectedPOs = _.filter(poItems, function (po) {
      if (po.selected == true && po.purchaseOrder.invoice.type == 'SIP_INVOICE') {
        return po;
      }
      else if (po.selected == true && po.purchaseOrder.invoice.type != 'SIP_INVOICE') {
        NotificationService.simpleErrorToast({
          title: 'global.buttons.sipRequest',
          message: 'alertMessage.NOT_VALID_FOR_SIP_APPROVAL_REQUEST'
        });
      }
      });
      return selectedPOs;
    }

    /**
     * [getSelectedPOIds Filters selected PO ids]
     * @param {*} selectedPOs
     */
    function getSelectedPOIds(selectedPOs) {
      var purchaseOrderIdList = _.map(selectedPOs, _.property('purchaseOrder.id'));
      return purchaseOrderIdList;
    }

    function cancel () {
      $mdDialog.cancel();
    }

    function sipRequest(){
      var invoiceIdList = [];
      var selectedPOs = vm.getSelectedPOsForSIPApproval(vm.initiatePayment);
      invoiceIdList = InvoiceService.getInvoiceIds(selectedPOs);
      if(invoiceIdList.length < 0){
        toastr.error('Please select invoices.', 'Approval Request');
        return false;
      }
      EzypaymentInitiatePaymentService.sipRequest(invoiceIdList)
      .then(function(response){
        NotificationService.success({
          title: 'global.buttons.sipRequest',
          message: 'alertMessage.SIP_REQUEST_SENT'
        });
        vm.init();
      }, function(error){
        NotificationService.error({
          title: 'global.buttons.sipRequest',
          error: error
        });
      });
    }
  }
})();
