/**
 * @Author: Ajay
 * @Date:   07-Aug-2018 20:43 +05:30
 * @Project: Ezyprocure
 * @Filename: item-buyer-mapping.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 08-Aug-2018 14:40 +05:30
 */



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('ItemBuyerMappingService', ItemBuyerMappingService);

  ItemBuyerMappingService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function ItemBuyerMappingService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var itemBuyerMappingService = {
      getAllItemBuyerMappings: getAllItemBuyerMappings,
      getItemBuyerMapping: getItemBuyerMapping,
      createItemBuyerMapping: createItemBuyerMapping,
      updateItemBuyerMapping: updateItemBuyerMapping,
      bulkUpdateItemBuyerMapping: bulkUpdateItemBuyerMapping,
      deleteItemBuyerMapping: deleteItemBuyerMapping,
      getAllItemBuyerMappingCsv: getAllItemBuyerMappingCsv,
      getParentItems: getParentItems,
      getSubCategoryList: getSubCategoryList,
      exportToInventory: exportToInventory,
      updateFlagForERPExport: updateFlagForERPExport,
      changeDefaultSupplier: changeDefaultSupplier
    };

    return itemBuyerMappingService;

    /**
     * get subcategory list from ezyinventory
     */
    function getSubCategoryList() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.ezyinventoryUrl + 'api/sub-categories',
        method: "GET"
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /* --  Item Buyer Map CRUD operations  -- */

    /**
     * [getAllItemBuyerMappings description]
     * @param  {[type]} urlParams [description]
     * @return {[type]}           [description]
     */
    function getAllItemBuyerMappings(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ITEM_BUYER_MAPPINGS,
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getParentItems description]
     * @param  {[type]} urlParams [description]
     * @return {[type]}           [description]
     */
    function getParentItems(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion +  'item-buyer-mapping/parent-item',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getAllItemBuyerMappingCsv description]
     * @param {[object]} urlparams [description]
     */
    function getAllItemBuyerMappingCsv(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ITEM_BUYER_MAPPINGS + 'bulk',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getItemBuyerMapping description]
     * @param  {[type]} itemBuyerMappingId [description]
     * @return {[type]}                    [description]
     */
    function getItemBuyerMapping(itemBuyerMappingId) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ITEM_BUYER_MAPPINGS + itemBuyerMappingId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [createItemBuyerMapping description]
     * @param  {[type]} mapping [description]
     * @return {[type]}         [description]
     */
    function createItemBuyerMapping(mapping) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ITEM_BUYER_MAPPINGS,
        method: "POST",
        data: mapping
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [updateItemBuyerMapping description]
     * @param  {[type]} mapping [description]
     * @return {[type]}         [description]
     */
    function updateItemBuyerMapping(mapping) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ITEM_BUYER_MAPPINGS,
        method: "PUT",
        data: mapping
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [bulkUpdateItemBuyerMapping description]
     * @param  {[type]} uploadData [description]
     * @return {[type]}            [description]
     */
    function bulkUpdateItemBuyerMapping(uploadData) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ITEM_BUYER_MAPPINGS + 'upload', uploadData).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [deleteItemBuyerMapping description]
     * @param  {[type]} mapping [description]
     * @return {[type]}         [description]
     */
    function deleteItemBuyerMapping(mapping) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ITEM_BUYER_MAPPINGS + mapping.id).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get All Item Buyer Mappings By Loggedin Buyer
        function getAllItemBuyerMappingsByBuyer(itemListQuery, params) {
          var deferred = $q.defer();
          $http({
            url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buy-now/items',
            method: "GET",
            params:params,
          }).then(function(data) {
            deferred.resolve(data);
          }).catch(function(message) {
            deferred.reject(message);
          });
          return deferred.promise;
        }

        //export selected items in inventory
        function exportToInventory(selectedItems){
            var selectedItemArray = [];
            var selectedItemsData = {};
            for(var i=0; i<selectedItems.length; i++){
                var data = selectedItems[i].items;
                var buyerItemCode = selectedItems[i].buyerItemCode;
                var buyerItemName = selectedItems[i].buyerItemName;
                selectedItemsData.ezyprocureId = data.id;
                selectedItemsData.supplierId = data.supplier.id;
                selectedItemsData.itemCode = buyerItemCode || data.itemCode ;
                selectedItemsData.itemName = buyerItemName || data.itemName;
                selectedItemsData.batchManagement = selectedItems[i].batchManagement;
                if(selectedItems[i].inventoryUom){
                    selectedItemsData.uom = selectedItems[i].inventoryUom;
                } else {
                    selectedItemsData.uom = data.uom.name;
                }
                selectedItemsData.reorderLevel = selectedItems[i].reorderLevel;
                selectedItemsData.reorderQty = selectedItems[i].reorderQty;
                selectedItemsData.subCategoryId = selectedItems[i].subCategoryId;
                selectedItemsData.col1 = selectedItems[i].col1;
                selectedItemsData.col2 = selectedItems[i].col2;
                selectedItemsData.col3 = selectedItems[i].col3;
                selectedItemsData.col4 = selectedItems[i].col4;
                selectedItemsData.col5 = selectedItems[i].col5;
                selectedItemsData.col6 = selectedItems[i].col6;
                var temp = angular.copy(selectedItemsData);
                selectedItemArray.push(temp);
            }
            var deferred = $q.defer();
            $http({
                url: API_CONSTANTS.ezyinventoryUrl + 'api/ezyprocure-item',
                method: "POST",
                data:selectedItemArray
            }).then(function(data) {
                deferred.resolve(data);
            }).catch(function(message) {
                deferred.reject(message);
            });
            return deferred.promise;
        }

        function updateFlagForERPExport(exportedItemsIds){
            var deferred = $q.defer();
            $http({
                url : API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ITEM_BUYER_MAPPINGS + 'update-flag',
                method: "PUT",
                data:exportedItemsIds
            }).then(function(data) {
                deferred.resolve(data);
            }).catch(function(message) {
                deferred.reject(message);
            });
            return deferred.promise;
        }

       function changeDefaultSupplier(defaultSupplierData, mappingId){
        var deferred = $q.defer();
        $http({
            url : API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ITEM_BUYER_MAPPINGS + mappingId + '/default-supplier?buyerItemCode='+defaultSupplierData.buyerItemCode+'&defaultSupplier='+defaultSupplierData.defaultSupplier,
            method: "PUT",
            //data:defaultSupplierData
        }).then(function(data) {
            deferred.resolve(data);
        }).catch(function(message) {
            deferred.reject(message);
        });
        return deferred.promise;
       }

  }
})();
