/**
* @Author: Ajay
* @Date:   25-May-2017 12:23 +05:30
* @Project: Ezyprocure
* @Filename: supplier.reports.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 01-Jun-2017 13:18 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('supplier.reports', {
      abstract: true,
      parent: 'supplier',
      url: '/reports',
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('promotion');
          return $translate.refresh();
        }],
        hasModuleAccess: ['Auth','$q', '$state', function(Auth, $q, $state) {
          if(Auth.hasPermission('accessForReports')){
            return true;
          }else {
            return $q.reject('Unauthorized');
          }
        }]
      }
    });
  }
})();
