/**
* @Author: hareesh
* @Date:   13-Aug-2017 09:11 +05:30
* @Project: Ezyprocure
* @Filename: supplier.price-update-movement.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 12-Oct-2018 14:42 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierPriceUpdateMovementController', SupplierPriceUpdateMovementController);

  SupplierPriceUpdateMovementController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', 'pagingParams', '$stateParams', 'NotificationService', 'BrandManagementService', '$mdDialog', 'UTCDateService','SupplierPriceUpdateHistoryService', 'ItemGroupService', 'paginationConstants', 'CSVExportService', 'EXPORT_CONSTANTS', 'SupplierDataService', 'BuyerSupplierMappingService', 'GLOBAL_CONSTANTS'];

  function SupplierPriceUpdateMovementController($scope, $rootScope, $q, $filter, $state, pagingParams, $stateParams, NotificationService, BrandManagementService, $mdDialog, UTCDateService, SupplierPriceUpdateHistoryService, ItemGroupService, paginationConstants, CSVExportService, EXPORT_CONSTANTS,SupplierDataService, BuyerSupplierMappingService, GLOBAL_CONSTANTS) {
    var vm = this;
    vm.todaysDate = new Date();
    vm.loadAll = loadAll;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
    vm.itemGroupId = null;
    vm.buyerIdList = null;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.lineChart=lineChart;
    vm.loadItemGroups = loadItemGroups;
    //Header
    vm.priceUpdateMovementHeader = EXPORT_CONSTANTS.CSV.priceUpdateMovementReport;
    //Function Declaration
    vm.getPriceUpdateMovementCSVData = getPriceUpdateMovementCSVData;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.getAllBrandsOfBuyerList = getAllBrandsOfBuyerList;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.resetFilters = resetFilters;

    function getAllSupplierMappedBuyers() {
      vm.brandIdList = [];
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
      };
      if(vm.buyers)
        return false;
      var supplierId = SupplierDataService.getSupplierProfile().supplier.id;
      BuyerSupplierMappingService.getAllSupplierMappedBuyers(supplierId, params).then(function(response){
        vm.buyers = response.data;
      },function(error){
      });
    }

    /**
     * [getAllBrandsOfBuyerList Gets all brands of selected buyers]
     * @param {Array} buyerIdList
     */
    function getAllBrandsOfBuyerList(buyerIdList){
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        query:'',
        buyerId : buyerIdList
      };
      BrandManagementService.getAllBrandsOfBuyerList(params)
      .then(function(response){
        vm.brands = response;
      },function(error){
        NotificationService.error({
          title: 'Brand Listing',
          error: error
        });
      });
    }

    /**
     * [selectAll Selects all from the list]
     * @param  {[type]} dropdown [Active dropdown -  brands]
     */
    function selectAll(dropdown) {
      switch (dropdown) {
        case 'BRANDS':
          vm.brandIdList = _.map(vm.brands, _.property('id'));
          break;
      }
    }

    /**
     * [deselectAll Deselect all from the list]
     * @param  {[type]} dropdown [Active dropdown - brands]
     */
    function deselectAll(dropdown) {
      switch (dropdown) {
        case 'BRANDS':
          vm.brandIdList = [];
          break;
      }
    }

    //Getting CSV DATA
    function getPriceUpdateMovementCSVData(){
      var deferred = $q.defer();
      if (!vm.priceUpdateItems) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getPriceUpdateMovementCSV(vm.priceUpdateItems);
    }
    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function loadItemGroups() {
      if(vm.itemGroups)
        return false;
      ItemGroupService.getAllItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.itemGroups = response.data;
      }, function(error) {
      });
    }
    function lineChart() {
      var priceUpdateItem=vm.priceUpdateItems;
      var arr2;
      var arr3=[];
      for (priceUpdateItem in vm.priceUpdateItems) {
        arr2= (vm.priceUpdateItems[priceUpdateItem]);
        arr3.push( [arr2[1],  $filter('date')(arr2[3] , "MMM d, y" ),arr2[2]]);
      }
      if(arr3.length==0){}
      else{
        // Options
        var options = {
          fontName: 'Roboto',
          height: 400,
          curveType: 'function',
          fontSize: 12,
          chartArea: {
            left: '5%',
            width: '90%',
            height: 350
          },
          pointSize: 4,
          tooltip: {
            textStyle: {
              fontName: 'Roboto',
              fontSize: 13
            }
          },
          vAxis: {
            title: 'Performance',
            titleTextStyle: {
              fontSize: 13,
              italic: false
            },
            gridlines:{
              color: '#e5e5e5',
              count: 10
            },
            minValue: 0
          },
          legend: {
            position: 'top',
            alignment: 'center',
            textStyle: {
              fontSize: 12
            }
          }
        };

        var yearWiseData = google.visualization.arrayToDataTable(getPivotArray(arr3, 1, 0, 2));
        var chart = new google.charts.Line(document.getElementById('columnchart_year'));
        chart.draw(yearWiseData, options);
      }
    }


    function getPivotArray(dataArray, rowIndex, colIndex, dataIndex) {
      var result = {}, ret = [];
      var newCols = [];
      for (var i = 0; i < dataArray.length; i++) {

        if (!result[dataArray[i][rowIndex]]) {
          result[dataArray[i][rowIndex]] = {};
        }
        result[dataArray[i][rowIndex]][dataArray[i][colIndex]] = dataArray[i][dataIndex];


        if (newCols.indexOf(dataArray[i][colIndex]) == -1) {
          newCols.push(dataArray[i][colIndex]);
        }
      }

      newCols.sort();
      var item = [];

      //Add Header Row
      item.push('Performance');
      item.push.apply(item, newCols);
      ret.push(item);


      //Add content
      for (var key in result) {
        item = [];
        item.push(key);
        for (var i = 0; i < newCols.length; i++) {
          item.push(result[key][newCols[i]] || 0);
        }
        ret.push(item);
      }
      return ret;
    }
    google.charts.load('current', {packages: ["line"]});
    google.charts.setOnLoadCallback(lineChart);
    /**
    * [loadAll loads all price update history]
    */
    function loadAll() {
      SupplierPriceUpdateHistoryService.getPriceUpdateMovement({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        fromDate: !vm.fromDate ? '' : UTCDateService.utcFromDate(vm.fromDate),
        toDate: !vm.toDate ? '' : UTCDateService.utcToDate(vm.toDate),
        query: !vm.newMappingObject.buyerIdList ? '' : vm.newMappingObject.buyerIdList,
        supplierId: '',
        buyerId: !vm.newMappingObject.buyerIdList ? '' : vm.newMappingObject.buyerIdList,
        itemName: !vm.itemName ? '' : vm.itemName,
        itemGroupId: !vm.itemGroupId ? '' : vm.itemGroupId,
        brandIdList: !vm.brandIdList ? '' : vm.brandIdList
      }).then(function(response){
        vm.priceUpdateItems = response.data;
        vm.totalItems = response.headers('X-Total-Count');
        vm.lineChart=lineChart();
      },function(error){
      });
    }
    function resetFilters() {
        vm.fromDate         = vm.todaysDate;
        vm.toDate           = vm.todaysDate;
        vm.itemName         = null;
        vm.itemGroupId      = null;
        vm.newMappingObject.buyerIdList = [];
        vm.brandIdList = [];
        vm.priceUpdateItems = null;
        document.getElementById('columnchart_year').innerHTML = '';
    }
  }
})();
