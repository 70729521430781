/**
* @Author: Vaibhavi
* @Date:   25-July-2018 16:15 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.buyer.upload-payment.service.js
 * @Last modified by:   Allan
 * @Last modified time: 27-August-2018 13:27 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BuyerUploadPaymentService', BuyerUploadPaymentService);

  BuyerUploadPaymentService.$inject = ['$http', '$q', 'API_CONSTANTS'];

  function BuyerUploadPaymentService($http, $q, API_CONSTANTS) {

    var buyerUploadPaymentService = {
      paymentUploadCSV: paymentUploadCSV
    };

    return buyerUploadPaymentService;

   function paymentUploadCSV(uploadData,loginType) {
         var deferred = $q.defer();
         $http({
           url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyers/payment-upload',
           method: "POST",
           data: uploadData,
           params: loginType
         }).then(function(data) {
           deferred.resolve(data.data);
         }).catch(function(message) {
           deferred.reject(message);
         });
         return deferred.promise;
       }

  }
})();
