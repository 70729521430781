/**
* @Author: Abhay
* @Date:   28-April-2021 16:03 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.axs-pay-bills-fines.service.js
* @Last modified by:   Abhay
* @Last modified time: 28-April-2021 16:03 +05:30
*/

 (function () {
    'use strict';

    angular.module('ezyprocureApp').factory('AxsPayBillsFinesService', AxsPayBillsFinesService);

    AxsPayBillsFinesService.$inject = ['$window', '$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS', 'GLOBAL_CONSTANTS'];

    function AxsPayBillsFinesService($window, $http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS, GLOBAL_CONSTANTS) {

        var axsPayBillsFinesService = {
            axsRedirect: axsRedirect,
            axsBrandValidation : axsBrandValidation
        };

        function axsRedirect(params) {
            var deferred = $q.defer();
            $http({
                url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'axs/redirect/test',
                method: "GET",
                params: params
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (message) {
                deferred.reject(message);
            });
            return deferred.promise;
        }

        function axsBrandValidation(params) {
            var deferred = $q.defer();
            $http({
                url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'axs/brand/validation',
                method: "GET",
                params: params
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (message) {
                deferred.reject(message);
            });
            return deferred.promise;
        }

        return axsPayBillsFinesService;

    }
})();
