/**
 * @Author: Elsa
 * @Date:   2017-05-27T11:54:55+05:30
 * @Project: Ezyprocure
 * @Filename: supplier.adhoc-credit-note.service.js
 * @Last modified by:   Elsa
 * @Last modified time: 2017-05-28T13:45:16+05:30
 */



 (function() {
   'use strict';

   angular.module('ezyprocureApp').factory('SupplierAdhocCreditNoteService', SupplierAdhocCreditNoteService);

   SupplierAdhocCreditNoteService.$inject = ['$http', '$q', 'API_CONSTANTS'];

   function SupplierAdhocCreditNoteService($http, $q, API_CONSTANTS) {

     var supplierAdhocCreditNoteService = {
       setAdhocCreditNoteItems: setAdhocCreditNoteItems,
       getAdhocCreditNoteItems: getAdhocCreditNoteItems,
       createAdHocCreditNote: createAdHocCreditNote
     };

     var adhocCreditNoteItems;

     return supplierAdhocCreditNoteService;

     /**
      * [setPriceUpdateFilter description]
      * @param {[type]} adhocCreditNoteItems [description]
      */
     function setAdhocCreditNoteItems(adhocCreditNoteItemDetails) {
       adhocCreditNoteItems = adhocCreditNoteItemDetails;
     }

     /**
      * [getAdhocCreditNoteItems description]
      * @return {[type]} [description]
      */
     function getAdhocCreditNoteItems() {
       return adhocCreditNoteItems ? adhocCreditNoteItems : null;
     }

     function createAdHocCreditNote(adHocCreditNoteDTO) {
       var deferred = $q.defer();
       $http({
         url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'ad-hoc-credit-notes',
         method: "PUT",
         data: adHocCreditNoteDTO
       }).then(function(data) {
         deferred.resolve(data);
       }).catch(function(message) {
         deferred.reject(message);
       });
       return deferred.promise;
     }


   }
 })();
