/*
 * @Author: Jayalekshmi 
 * @Date: 2019-08-27 19:17:00 
 * @Filename: ezypayment.merchant-details.supplier-merchent-details.edit.controller.js
 * @Last Modified by:   Jayalekshmi 
 * @Last Modified time: 2019-08-27 19:17:00 
 */


(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .controller('EzyPaymentSupplierMerchantDetailsEditController', EzyPaymentSupplierMerchantDetailsEditController);
  
    EzyPaymentSupplierMerchantDetailsEditController.$inject = ['$state', '$stateParams', 'EzypaymentAdminManageMerchantDetailsService', 'GLOBAL_CONSTANTS', 'NotificationService'];
  
    function EzyPaymentSupplierMerchantDetailsEditController($state, $stateParams, EzypaymentAdminManageMerchantDetailsService, GLOBAL_CONSTANTS, NotificationService) {
  
      var vm = this;
      vm.supplierMerchantId = $stateParams.id;
      vm.update = update;
      vm.gatewayTypes = GLOBAL_CONSTANTS.GATEWAY_TYPES;
      vm.init = init;
      vm.getAllCountryCurrencies = getAllCountryCurrencies;
      vm.getSupplierMerchantIdDetailById = getSupplierMerchantIdDetailById;
      vm.loadAllAdminSuppliers = loadAllAdminSuppliers;
      vm.init();

      function init(){
        vm.getAllCountryCurrencies();
        vm.getSupplierMerchantIdDetailById();
      }
  
      /**
       * [Update Update the supplier merchant details]
       */
      function update(){
        vm.merchantDetails.brand = null;
        EzypaymentAdminManageMerchantDetailsService.updateSupplierMerchantIdDetail(vm.merchantDetails).then(function(response) {
          NotificationService.success({
            title: 'Update Supplier Merchant Detail',
            message: 'alertMessage.MERCHANT_DETAIL_UPDATED'
          });
          $state.go('ezypayment.admin.merchant-management',{reload: false, initialActive : true})
        }, function(error) {
          NotificationService.error({
            title: 'Update Supplier Merchant Detail',
            error: error
          });
        });
      }

      /**
       * [getAllCountryCurrencies gets all country currencies]
       */
      function getAllCountryCurrencies(){
        EzypaymentAdminManageMerchantDetailsService.getAllCountryCurrencies().then(function(response){
          vm.currencies = response.data;
        },function(error){
        });
      }

      /**
       * [getSupplierMerchantIdDetailById Gets the details of suppier merchant by id]
       */
      function getSupplierMerchantIdDetailById(){
        EzypaymentAdminManageMerchantDetailsService.getSupplierMerchantIdDetailById(vm.supplierMerchantId).then(function(response){
          vm.merchantDetails = response.data;
        },function(error){
        });
      }

      /**
       * [loadAllAdminSuppliers gets all country currencies]
       */
      function loadAllAdminSuppliers() {
        var params = {
          visibilityType:'ALLOCATED'
        };
        ManageSupplierService.getAllAdminSuppliers(params).then(function(response){
          vm.suppliers = response.data;
        },function(error){
        });
      } 
    }
  })();
  