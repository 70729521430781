/**
* @Author: Fenn Saji
* @Date:   27-Feb-2020
* @Project: Ezyprocure
* @Filename: add-card.controller.js
*/



(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('AddCardMpgsAccountController', AddCardMpgsAccountController);

  AddCardMpgsAccountController.$inject = ['$rootScope', '$timeout', '$window', '$stateParams', '$state', '$scope', '$mdDialog', '$filter', 'Auth', 'CardsService', 'NotificationService', 'toastr', 'GLOBAL_CONSTANTS', 'SubscriptionPlan', 'BuyerAccountService', 'SupplierAccountService', 'AuthServerProvider', 'Principal'];

  function AddCardMpgsAccountController($rootScope, $timeout, $window, $stateParams, $state, $scope, $mdDialog, $filter, Auth, CardsService, NotificationService, toastr, GLOBAL_CONSTANTS, SubscriptionPlan, BuyerAccountService, SupplierAccountService, AuthServerProvider, Principal) {
    var vm = this;
    vm.onInit = onInit;
    vm.type = $stateParams.type;
    vm.months = GLOBAL_CONSTANTS.CREDIT_CARD.MONTHS;
    vm.years = GLOBAL_CONSTANTS.CREDIT_CARD.YEAR;
    vm.submitForm = submitForm;
    vm.savedCards = savedCards;
    vm.addCardModal = addCardModal;
    vm.cancel = cancel;
    vm.getSessionId = getSessionId;
    vm.cardInfoForm = {
      "batchPaymentReference": "string",
      "brandIds": [],
      "brandTokens": [],
      "buyer": null,
      "buyerUser": null,
      "buyerUserId": null,
      "cardCycle": "string",
      "cardMonth": "",
      "cardNumber": "",
      "cardSecurityCode": "",
      "cardType": "string",
      "cardYear": "",
      "currencyId": "USD",
      "paidInvoicesList": null,
      "paymentApprovalId": "string",
      "sessionId": "",
      "supplierId": null
    }

    $window.addEventListener('unload', function(evt) {
      vm.reloadConfirm = '';
    });

    $window.addEventListener('beforeunload', function (evt) {
      if (vm.reloadConfirm == 'payment') {
        evt.preventDefault();
        var message = 'All Data will be lost';
        if (typeof evt == 'undefined') {
          evt = window.event;
        }
        if (evt) {
          evt.returnValue = message;
        }
        return message;
      } else {
        return;
      }
    });

    $scope.$on('$locationChangeStart', function (event, next, current) {
      if (vm.reloadConfirm == 'payment') {
        var answer = confirm("Are you sure you want to navigate away from this page");
        if (!answer) {
          event.preventDefault();
        } else {
          vm.reloadConfirm = null;
        }
      }
    });

    function onInit() {
      setTimeout(function () {
        vm.reloadConfirm = 'payment';
      }, 0);
      // if ($stateParams.userId == undefined || $stateParams.type == undefined) {        
      //   cancel();
      // }
      $rootScope.transactionFailed = $stateParams.transactionFailed;
      $rootScope.updateOutlets = $stateParams.updateOutlets;
      if ($rootScope.transactionFailed == true) {
        $rootScope.transactionPaymentId = $stateParams.transactionPaymentId;
        vm.transactionAmount = $stateParams.transactionAmount;
        vm.transactioncurrencyCode = $stateParams.transactioncurrencyCode;
      }
      if($rootScope.updateOutlets){
        $rootScope.numberOfOutlets = $stateParams.numberOfOutlets;
        $rootScope.OutletUserPlanId = $stateParams.OutletUserPlanId;
        SubscriptionPlan.getAmountByOutlets({userPlanId: $rootScope.OutletUserPlanId, outlet: $rootScope.numberOfOutlets})
          .then(function(res) {
            $rootScope.outletsAmount = res.payableAmount;
            vm.outletsUpdateMessage = $filter('translate')('global.changeOutletPayment', {amount: $rootScope.outletsAmount, outlets: $rootScope.numberOfOutlets});
          });
      }
      var authJWT = AuthServerProvider.getCbToken();
      var decAuthJWT = jwt_decode(authJWT);
      vm.userId = decAuthJWT.uid;
      vm.userRole = decAuthJWT.auth === 'ROLE_BUYER' ? 'buyer' : 'supplier';
      vm.planType = $stateParams.planType;
      if (vm.userRole === 'buyer') {
        BuyerAccountService.getCbBuyerProfile()
          .then(function (buyerInfo) {
            vm.companyId = buyerInfo.buyerUser.buyer.id;
            vm.country = buyerInfo.buyerUser.user.userCountry;
            getMerchantId();
          });
      } else if (vm.userRole === 'supplier') {
        SupplierAccountService.getCbSupplierProfile()
          .then(function (supplierInfo) {
            vm.companyId = supplierInfo.supplier.id;
            vm.country = supplierInfo.user.userCountry;
            getMerchantId();
          });
      }
    }

    function getMerchantId() {
      var countryCode = (vm.country == 'SG'? 'SG': 'US');
      SubscriptionPlan.getMerchantId(countryCode)
        .then(function (response) {
          vm.creditCard = { merchantId: response.merchantId };
          configurePaymentSession();
        })
    }

    function cancel() {
      vm.reloadConfirm = '';
      $state.go('subscription');
    }

    /**
    * [configurePaymentSession configure Payment session object for adding card]
    * @return {[type]} [description]
    */
    function configurePaymentSession() {
      vm.MPGSScriptURL = CardsService.getMPGSScript(vm.creditCard.merchantId);  //get url for MPGS script to enable hosted session

      //set script for MPGS hosted session for saving card details
      $.getScript(vm.MPGSScriptURL, function () {
        if (self === top) {
          var antiClickjack = document.getElementById("antiClickjack");
          antiClickjack.parentNode.removeChild(antiClickjack);
        } else {
          top.location = self.location;
        }

        //configuring PaymentSession object
        PaymentSession.configure({
          fields: {
            // ATTACH HOSTED FIELDS TO YOUR PAYMENT PAGE FOR A CREDIT CARD
            card: {
              number: "#card-number",
              securityCode: "#security-code",
              expiryMonth: "#expiry-month",
              expiryYear: "#expiry-year"
            }
          },
          //SPECIFY YOUR MITIGATION OPTION HERE
          frameEmbeddingMitigation: ["javascript"],
          callbacks: {
            initialized: function (response) {
              // HANDLE INITIALIZATION RESPONSE
            },
            formSessionUpdate: function (response) {
              // HANDLE RESPONSE FOR UPDATE SESSION
              if (response.status) {
                if (response.status === "ok") {
                  vm.cardInfoForm.sessionId = response.session.id;
                  submitForm();

                } else if (response.status === "fields_in_error") {
                  NotificationService.simpleErrorToast({
                    title: 'Add Credit Card',
                    message: 'alertMessage.MPGS.SESSION_UPDATE_FAILED_WITH_FIELD_ERRORS'
                  });
                } else if (response.status === "request_timeout") {
                  toastr.error('Session update failed with request timeout!', response.errors.message);

                } else if (response.status === "system_error") {
                  toastr.error('Session update failed with system error!', response.errors.message);
                }
              } else {
                toastr.error('Session update failed!', response);
              }
            }
          }
        });
      });
    }

    function getSessionId() {
      PaymentSession.updateSessionFromForm('card');
    }

    /**
     * Save Credit card
     *
     */
    function submitForm() {
      var object;
      if($rootScope.updateOutlets) {
        object = {
          amount: $rootScope.outletsAmount,
          cardHolderName: vm.cardHolderName,
          outlet:  $rootScope.numberOfOutlets,
          sessionId: vm.cardInfoForm.sessionId,
          tokenId: null,
          userPlanId: $rootScope.OutletUserPlanId
        }
        return SubscriptionPlan.updateOutletsAndPay(object)
          .then(function() {
            $rootScope.updateOutlets = false;
            Principal.setUserVerifiedCB(false);
            $state.go(vm.userRole + '.dashboard');
          });
      }
      if ($rootScope.transactionFailed == true) {
        object = {
          sessionId: vm.cardInfoForm.sessionId,
          userId: vm.userId,
          paymentId: $rootScope.transactionPaymentId,
          companyId: vm.companyId,
          buyer: vm.userRole === 'supplier' ? false : true,
          cardHolderName: vm.cardHolderName
        }
      } else {
        object = {
          sessionId: vm.cardInfoForm.sessionId,
          userId: vm.userId,
          planDetailsDTO: $rootScope.subscriptionPlanRequest,
          companyId: vm.companyId,
          buyer: vm.userRole === 'supplier' ? false : true,
          cardHolderName: vm.cardHolderName
        }
      }
      SubscriptionPlan.addPlanAndPayment(object)
        .then(function (res) {
          if(!res){
            return toastr.error($filter('translate')('alertMessage.PLAN_EXISTS'), 'Error');
          }
          if (res && res.length != 0) {
            var success = res.map(function (e) {
              return e.paymentFailure;
            })
              .includes(true);
            if (!success) {
              toastr.success('Saved Successfully');
              Principal.setUserVerifiedCB(false);
              vm.reloadConfirm = '';
              $state.go(vm.userRole + '.dashboard');
            } else {
              toastr.error($filter('translate')('alertMessage.PAYMENT_FAILED'), 'Error');
            }
          } else {
            toastr.error($filter('translate')('alertMessage.PAYMENT_FAILED'), 'Error');
          }
        }).catch(function () {
          toastr.error('Something went Wrong', 'Error');
        });
    }

    function savedCards() {
      vm.reloadConfirm = '';
      if (vm.userRole === 'buyer') {
        $state.go('buyer.listcards', { userId: vm.userId, params: $stateParams, fromPage: 'buyer.addcard' });
      } else {
        $state.go('supplier.listcards', { userId: vm.userId, params: $stateParams, fromPage: 'supplier.addcard' });
      }
    }
    function addCardModal(ev, id) {
      var confirm = $mdDialog.confirm()
        .title($filter('translate')('alertMessage.SUBMIT_CARD_PAYMENT'))
        .textContent('  ')
        .targetEvent(ev)
        .ok('Ok')
        .cancel('Cancel');

      $mdDialog.show(confirm).then(function () {
        getSessionId();
      }, function () { });
    }
    /**
    * [showMPGSErrorNotification description]
    * @param  {[type]} errorMessage [display error messages for MPGS validation messages]
    * @return {[type]}              [description]
    */
    function showMPGSErrorNotification(errorMessage) {
      NotificationService.simpleErrorToast({
        title: 'Add Credit Card',
        message: errorMessage
      });
    }
  }
})();
