/**
* @Author: Ajay
* @Date:   21-Apr-2017 11:37 +05:30
* @Project: Ezyprocure
* @Filename: buyer.invoice-report.state.js
 * @Last modified by:   Elsa
 * @Last modified time: 2017-05-30T14:05:26+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('buyer.reports.invoice-report', {
      parent: 'buyer.reports',
      url: '/invoice-report',
      data: {
        authorities: ['ROLE_BUYER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/reports/invoice-report/buyer.invoice-report.html',
          controller: 'BuyerInvoiceReportController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,desc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('invoice');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('buyerBusiness');
          $translatePartialLoader.addPart('processOrder');
          $translatePartialLoader.addPart('purchaseOrder');
          $translatePartialLoader.addPart('invoiceStatus');
          return $translate.refresh();
        }]
      }
    })
    .state('buyer.reports.invoice-report.individual-details', {
      parent: 'buyer.reports.invoice-report',
      url: '/individual-details/:purchaseOrderNumber/:deliveryDate/:status/:id',

      data: {
        authorities: ['ROLE_BUYER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/reports/invoice-report/individual-details/buyer.invoice-report.individual-details.html',
          controller: 'BuyerInvoiceReportIndividualDetailsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('invoice');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('buyerBusiness');
          $translatePartialLoader.addPart('processOrder');
          $translatePartialLoader.addPart('purchaseOrder');
          return $translate.refresh();
        }]
      }
    })

    .state('buyer.reports.invoice-report.consolidated-details', {
      parent: 'buyer.reports.invoice-report',
      url: '/consolidated-details/:fromDate/:toDate',

      data: {
        authorities: ['ROLE_BUYER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/reports/invoice-report/consolidated-details/buyer.invoice-report.consolidated-details.html',
          controller: 'BuyerInvoiceReportConsolidatedDetailsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('invoice');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('buyerBusiness');
          $translatePartialLoader.addPart('processOrder');
          $translatePartialLoader.addPart('purchaseOrder');
          return $translate.refresh();
        }]
      }
    });
  }

})();
