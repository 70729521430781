/**
 * @Author: Ajay
 * @Date:   21-Apr-2017 17:54 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.user-roles.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 10-Nov-2017 12:27 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerUserRoleController', BuyerUserRoleController);

  BuyerUserRoleController.$inject = ['$scope','$mdDialog', 'pagingParams', 'UserRoleService', 'paginationConstants', 'NotificationService'];

  function BuyerUserRoleController($scope, $mdDialog, pagingParams, UserRoleService, paginationConstants, NotificationService) {
    var vm = this;

    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.loadAll = loadAll;
    vm.showConfirm = showConfirm;
    vm.cancel = cancel;
    vm.showAddUserRole = showAddUserRole;
    vm.addUserRole = addUserRole;
    vm.showEditUserRole = showEditUserRole;
    vm.updateUserRole = updateUserRole;
    vm.restictAdminBuyer = restictAdminBuyer;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function loadAll (search) {
      if (!search) {
        vm.searchText = '';
      }
      UserRoleService.getAllUserRoles({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.searchText
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.userRoles = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'User Role Listing',
          error: error
        });
      });
    }

    vm.loadAll();

    function deleteBuyerUserRole(userRoleId) {
      UserRoleService.deleteUserRole(userRoleId).then(function(response) {
        NotificationService.success({
          title: 'User Role',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'User Role Deletion',
          error: error
        });
      });
    }

    function showConfirm (ev, userRole) {
      var confirm = $mdDialog.confirm()
      .title('Are you sure you want to delete User Role - ' + userRole.name + ' ? ')
      .textContent('This action could not be reverted back.')
      .targetEvent(ev)
      .ok('Delete')
      .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        deleteBuyerUserRole(userRole.id);
      }, function() {});
    }

    function cancel () {
      $mdDialog.cancel();
    }

    //Add Buyer User Role Starts here

    function showAddUserRole (event) {
      vm.adminBuyererror = false;
      vm.newUserRole = {};
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/tools/user-roles/buyer.user-role.add.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function addUserRole (newUserRole) {
      UserRoleService.createUserRole(newUserRole).then(function(response) {
        $scope.$emit('ezyprocureApp:userRoleUpdate', response);
        vm.cancel();
        NotificationService.success({
          title: 'User Role',
          message: 'alertMessage.CREATED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'User Role Creation',
          error: error
        });
      });
    }

    //Add User Role Ends here


    //Edit Buyer User Role Starts here

    function showEditUserRole (event, oldUserRole) {
      vm.adminBuyererror = false;
      vm.editUserRole = angular.copy(oldUserRole);
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/tools/user-roles/buyer.user-role.edit.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function updateUserRole (oldUserRole) {
      UserRoleService.updateUserRole(oldUserRole).then(function(response) {
        $scope.$emit('ezyprocureApp:userRoleUpdate', response);
        vm.cancel();
        NotificationService.success({
          title: 'User Role',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'User Role Updation',
          error: error
        });
      });
    }

    //Edit Buyer User Role Ends here

    /**
     * Restrict Admin_Buyer
     */
    function restictAdminBuyer (newUserRole) {
       vm.adminBuyererror = newUserRole && (newUserRole.toLowerCase() == 'admin_buyer' || newUserRole.toLowerCase() == 'admin_supplier');
    }

  }


})();
