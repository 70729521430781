/**
 * @Author: Caroline
 * @Date:   07-Apr-2020 13:30 +05:30
 * @Project: Ezyprocure
 * @Filename: ezypayment.supplier.upload-payment.controller.js
 * @Last modified by:   Caroline
 * @Last modified time: 07-Apr-2020 13:30 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('ezypayment.supplier.upload-payment', {
      parent: 'ezypayment.supplier',
      url: '/upload-payment',
      data: {
        authorities: ['ROLE_SUPPLIER']
      },
      views: {
        'content@': {
          templateUrl: 'app/ezypayment-modules/supplier-modules/upload-payment/ezypayment.supplier.upload-payment.html',
          controller: 'EzypaymentSupplierPaymentUploadController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return true;
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('externalPayment');
          return $translate.refresh();
        }]
      }
    })
  }
})();
