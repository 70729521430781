/**
 * @Author: hareesh
 * @Date:   09-Aug-2017 12:53 +05:30
 * @Project: Ezyprocure
 * @Filename: supplier.purchase-request.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 09-Nov-2018 12:15 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('supplier.reports.purchase-request', {
      parent: 'supplier.reports',
      url: '/purchase-request',
      data: {
        authorities: ['ROLE_SUPPLIER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/reports/purchase-request/supplier.purchase-request.html',
          controller: 'SupplierPurchaseRequestController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,desc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('user-management');
          $translatePartialLoader.addPart('purchaseOrder');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('invoiceOCR');
          $translatePartialLoader.addPart('processOrderStatus');
          return $translate.refresh();
        }]
      }
    })
    .state('supplier.reports.purchase-request.individual-details', {
      parent: 'supplier.reports.purchase-request',
      url: '/individual-details/:requirementNumber',

      data: {
        authorities: ['ROLE_SUPPLIER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/reports/purchase-request/individual-details/supplier.purchase-request.individual-details.html',
          controller: 'SupplierPurchaseRequestIndividualDetailsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('purchaseOrder');
          $translatePartialLoader.addPart('processOrderStatus');
          $translatePartialLoader.addPart('purchaseOrderStatus');
          $translatePartialLoader.addPart('invoiceStatus');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('invoiceOCR');
          return $translate.refresh();
        }]
      }
    })

    .state('supplier.reports.purchase-request.consolidated-details', {
      parent: 'supplier.reports.purchase-request',
      url: '/consolidated-details/:fromDate/:toDate/:businessOutletId/:prNumber/:prStatus/:itemName/:buyerIdList/:itemGroupId',

      data: {
        authorities: ['ROLE_SUPPLIER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/reports/purchase-request/consolidated-details/supplier.purchase-request.consolidated-details.html',
          controller: 'SupplierPurchaseRequestConsolidatedDetailsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('purchaseOrder');
          $translatePartialLoader.addPart('processOrderStatus');
          $translatePartialLoader.addPart('invoiceOCR');
          return $translate.refresh();
        }]
      }
    });
  }

})();
