/**
* @Author: Ajay
* @Date:   14-Dec-2016 12:54 +05:30
* @Project: Ezyprocure
* @Filename: translation.config.js
* @Last modified by:   Ajay
* @Last modified time: 27-Mar-2017 18:55 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(translationConfig);

  translationConfig.$inject = ['$translateProvider', 'tmhDynamicLocaleProvider'];

  function translationConfig($translateProvider, tmhDynamicLocaleProvider) {
    // Initialize angular-translate
    $translateProvider.useLoader('$translatePartialLoader', {
      urlTemplate: 'i18n/{lang}/{part}.json'
    });

    $translateProvider.preferredLanguage('en');
    $translateProvider.useStorage('translationStorageProvider');
    $translateProvider.useSanitizeValueStrategy('escaped');
    $translateProvider.addInterpolation('$translateMessageFormatInterpolation');

    tmhDynamicLocaleProvider.localeLocationPattern('i18n/angular-locale_{{locale}}.js');
    tmhDynamicLocaleProvider.useCookieStorage();
    tmhDynamicLocaleProvider.storageKey('NG_TRANSLATE_LANG_KEY');
  }
})();
