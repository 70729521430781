/**
 * @Author: Ajay
 * @Date:   18-Oct-2017 11:32 +05:30
 * @Project: Ezyprocure
 * @Filename: supplier.item.mapping.add.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 24-Apr-2018 12:07 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierItemMappingAddController', SupplierItemMappingAddController);

  SupplierItemMappingAddController.$inject = ['$scope', '$state', '$stateParams', '$element', 'toastr', 'pagingParams', 'SupplierDataService', 'BrandManagementService','SupplierItemService', 'BuyerSupplierMappingService', 'BuyerBusinessService', 'SupplierItemMapService', 'paginationConstants', 'NotificationService'];

  function SupplierItemMappingAddController ($scope, $state, $stateParams, $element, toastr, pagingParams, SupplierDataService, BrandManagementService, SupplierItemService, BuyerSupplierMappingService, BuyerBusinessService, SupplierItemMapService, paginationConstants, NotificationService) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.sort = sort;
    vm.buyerSelectionChanged = buyerSelectionChanged;
    vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
    vm.getAllBuyersBusinessOutlets = getAllBuyersBusinessOutlets;
    vm.getAllItemBuyerPrice = getAllItemBuyerPrice;
    vm.checkAll = checkAll;
    vm.getSelectedItemIdList = getSelectedItemIdList;
    vm.addNewMaping = addNewMaping;
    vm.itemSearchText = '';
    vm.clearBulkUploadWarnings = clearBulkUploadWarnings;
    vm.getAllBrandsOfBuyerList = getAllBrandsOfBuyerList;
    vm.getAllBrandMappedsBusinessOutlets = getAllBrandMappedsBusinessOutlets;
    vm.brandAndOutletClear = brandAndOutletClear;
    vm.newMappingObject = {
      buyerIdList: [],
      brandIdList: [],
      businessIdList: [],
      itemBusinessMappingDTO: [],

    };

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.limitOptions = paginationConstants.limitOptions;
    vm.validateMOQ = validateMOQ;

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }


    /**
     * [getAllBrandsOfBuyerList Gets all brands of selected buyers]
     * @param {Array} buyerIdList
     */
    function getAllBrandsOfBuyerList(buyerIdList){
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        query:'',
        buyerId : buyerIdList
      };
      BrandManagementService.getAllBrandsOfBuyerList(params)
      .then(function(response){
        vm.brands = response;
      },function(error){
        NotificationService.error({
          title: 'Brand Listing',
          error: error
        });
      });
    }

    /**
     * [selectAll Selects all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, buyers or brands]
     */
    function selectAll(dropdown) {
      switch (dropdown) {
        case 'BUSINESSOUTLETS' :
          vm.newMappingObject.businessIdList = _.map(vm.brandMappedBusinessOutlets, _.property('id'));
            break;
        case 'BRANDS':
          vm.newMappingObject.brandIdList = _.map(vm.brands, _.property('id'));
          break;
      }
    }

    /**
     * [deselectAll Deselect all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, buyers or brands]
     */
    function deselectAll(dropdown) {
      switch (dropdown) {
        case 'BUSINESSOUTLETS' :
          vm.newMappingObject.businessIdList = [];
          break;
        case 'BRANDS':
          vm.newMappingObject.brandIdList = [];
          break;
      }
    }

    //Get all buyers mapped to the supplier
    function getAllSupplierMappedBuyers() {
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
      };
      if(vm.buyers)
        return false;
      var supplierId = SupplierDataService.getSupplierProfile().supplier.id;
      BuyerSupplierMappingService.getAllSupplierMappedBuyers(supplierId, params).then(function(response){
        vm.buyers = response.data;
      },function(error){
      });
    }

    function getAllBrandMappedsBusinessOutlets(brandIdList) {
      if (!brandIdList || brandIdList.length === 0) return false;
      var itemTerminationDTO = {
        'brandIdList': brandIdList,
        'buyerIdList': [vm.newMappingObject.buyerIdList]
      }
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        supplierId: SupplierDataService.getSupplierProfile().supplier.id
      }
      BuyerBusinessService.getAllBrandMappedsBusinessOutlets(itemTerminationDTO, params).then(function(response) {
        vm.brandMappedBusinessOutlets = response.data;
      }, function(error) {
      });
    }


    //get all business outlets of the selected buyer
    function getAllBuyersBusinessOutlets (buyerId) {
      if(!buyerId) return false;
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        groupBy:'buyer'
      };
      BuyerBusinessService.getAllBuyersBusinessOutlets(vm.newMappingObject.buyerIdList,params).then(function(response){
        vm.buyerBusinessOutlets = response.data;
        vm.newMappingObject.businessIdList = [];
      },function(error){
      });
    }

     /**
    * [clear brand and Outlet]
    */
   function brandAndOutletClear(brands){
    if(brands == 'BRANDS'){
      vm.newMappingObject.businessIdList = [];
      return false
    }
    vm.newMappingObject.businessIdList = [];
    vm.newMappingObject.brandIdList = [];
   }

    // Load All items with buyer mapped price
    function getAllItemBuyerPrice(buyerId) {
      if(!buyerId) return false;
      SupplierItemMapService.getAllMappedItemsByPrice({
        page: 0,
        size: paginationConstants.maxLimit,
        buyerId: buyerId,
        brandIdList: vm.newMappingObject.brandIdList,
        businessOutletList :vm.newMappingObject.businessIdList,
        query: ''
      }).then(function(response){
        vm.items = response.data;
        vm.selectAll = false;
      },function(error){
      });
    }

    function checkAll() {
      vm.selectAll ? vm.selectAll = true : vm.selectAll = false;
      angular.forEach(vm.items, function(item) {
        item.selected = vm.selectAll;
      });
    }

    function getSelectedItemIdList(items) {
      var pickedItems = _.map(_.filter(items, {'selected': true}), _.partialRight(_.pick, ['id', 'itemCode', 'itemName', 'price','maximumAllocation','buyerLimited', 'moq', 'multiplierFactor']));
      return pickedItems;
    }

    //add new mapping
    function addNewMaping() {
      vm.bulkUploadWarnings =null;
      vm.newMappingObject.itemBusinessMappingDTO = vm.getSelectedItemIdList(vm.items);
      vm.newMappingObject.buyerIdList = [vm.newMappingObject.buyerIdList];
      SupplierItemMapService.createNewItemBuyerOutletMapping(vm.newMappingObject).then(function(response){
        if(response.data.data.length > 0) {
          vm.bulkUploadWarnings = response.data.data;
          toastr.warning('Completed with some errors!', 'Buyer/Outlet Mapping');
        }
        else {
          NotificationService.success({
            title: 'Item-Buyer/Outlet',
            message: 'Mapped successfully'
          });
        }
      },function(error){
        NotificationService.error({
          title: 'Item-Buyer/Outlet Mapping',
          error: error
        });
      });
    }

    function clearBulkUploadWarnings() {
      vm.bulkUploadWarnings = null;
    }

    function buyerSelectionChanged(buyerId) {

      vm.getAllItemBuyerPrice(buyerId);
    }

    /**
     * [validateMOQ Validates the MOQ with Multiplier]
     */
    function validateMOQ(item, index){
      if(item.multiplierFactor > 0){
        if (((item.moq*100) % (item.multiplierFactor*100))/100 != 0) {
          $scope.buyerItemMappingForm['moq_'+index].$setTouched();
          $scope.buyerItemMappingForm['moq_'+index].$setValidity("moq", false);
        }else{
         $scope.buyerItemMappingForm['moq_'+index].$setValidity("moq", true);
        }
      }
    }

  }
})();
