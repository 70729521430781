/**
 * @Author: Ajay
 * @Date:   29-Jun-2018 11:49 +05:30
 * @Project: Ezyprocure
 * @Filename: ezypayment.admin.brand-supplier-payment-model-mapping.add.controller.js
 * @Last modified by:   Allan
 * @Last modified time: 09-October-2018 11:00 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzypaymentAdminBrandSupplierPaymentModelMapAddController', EzypaymentAdminBrandSupplierPaymentModelMapAddController);

  EzypaymentAdminBrandSupplierPaymentModelMapAddController.$inject = ['$scope', '$rootScope' ,'$state', '$stateParams', 'toastr', 'BrandManagementService', 'EzypaymentBrandSupplierPaymentModelMapService', 'BrandSupplierMapService', 'ManageBuyerService', 'paginationConstants', 'NotificationService', 'GLOBAL_CONSTANTS'];

  function EzypaymentAdminBrandSupplierPaymentModelMapAddController($scope, $rootScope, $state, $stateParams, toastr, BrandManagementService, EzypaymentBrandSupplierPaymentModelMapService, BrandSupplierMapService, ManageBuyerService, paginationConstants, NotificationService, GLOBAL_CONSTANTS) {
    var vm = this;
    vm.getAllAdminBuyers = getAllAdminBuyers;
    vm.getAllBrandsByBuyerId = getAllBrandsByBuyerId;
    vm.getAllBrandMappedSuppliers = getAllBrandMappedSuppliers;
    vm.buyerSelectionChanged = buyerSelectionChanged;
    vm.brandSelectionChanged = brandSelectionChanged;
    vm.createBrandSupplierPaymentModelMapping = createBrandSupplierPaymentModelMapping;
    vm.paymentModels = GLOBAL_CONSTANTS.PAYMENT_MODELS;
    vm.mapping = {
      buyerId: undefined,
      brand: {},
      supplierIdList: [],
      creditTerms: GLOBAL_CONSTANTS.DEFAULT_CREDIT_TERM
    };
    vm.selectOrDeselectAll=selectOrDeselectAll;

    /**
     * [getAllAdminBuyers Get list of all admin buyers]
     */
    function getAllAdminBuyers() {
      if(vm.buyers)
        return false;
      ManageBuyerService.getAllAdminBuyers().then(function(response) {
        vm.buyers = response.data;
      }, function(error) {
      });
    }

    /**
     * [getAllBrandsByBuyerId Get list of all brands of selected buyer]
     */
    function getAllBrandsByBuyerId(){
      if(vm.brands)
        return false;
      BrandManagementService.getAllBrandsByBuyerId({
        query: '',
        buyerId : vm.mapping.buyerId
      }).then(function(response){
        vm.brands = response.data;
      });
    }

    /**
     * [getAllBrandMappedSuppliers Get list of mapped suppliers to the selected brand]
     */
    function getAllBrandMappedSuppliers() {
      if(vm.suppliers)
        return false;
      BrandSupplierMapService.getAllBrandMappedSuppliers({
        brandId: vm.mapping.brand.id,
        kycStatus: 'SUCCESSFUL',
        page: 0,
        size: paginationConstants.maxLimit
      }).then(function(response){
        vm.suppliers = response.data;
      },function(error){
      });
    }

    function buyerSelectionChanged() {
      vm.brands = undefined;
      vm.mapping.brand.id = undefined;
      vm.suppliers = undefined;
      vm.mapping.supplierIdList= undefined;
      vm.translatedError = undefined;
    }

    function brandSelectionChanged() {
      vm.suppliers = undefined;
      vm.mapping.supplierIdList = undefined;
      vm.translatedError = undefined;
    }

    function createBrandSupplierPaymentModelMapping() {
      vm.translatedError = undefined;
      EzypaymentBrandSupplierPaymentModelMapService.createBrandSupplierPaymentModelMapping(vm.mapping).then(function(response){
        $scope.$emit('ezyprocureApp:bsMapped', vm.mapping);
        if(response.responseList.length>0){
            vm.translatedError=response.responseList;
            toastr.warning('Completed with some errors!', 'Brand Supplier Payment Model Mapping');
        }else{
            NotificationService.success({
              title: 'Brand Supplier Payment Model',
              message: 'Mapped successfully'
            });
            $state.go('ezypayment.admin.brand-supplier-payment-model-mapping', {
              reload: false
            });
        }
      },function(error) {
        if(error.data.responseList.length>0){
          vm.translatedError=error.data;
          toastr.warning('Completed with some errors!', 'Brand Supplier Payment Model Mapping');
          return
      }
          NotificationService.error({
           title: 'Mapping Brand Supplier Payment Model',
           error: error
          });
      });
    }

    function selectOrDeselectAll(type){
      if(type==='SELECT'){
        vm.mapping.supplierIdList=_.map(vm.suppliers, _.property('id'));
      }else if(type==='DESELECT'){
        vm.mapping.supplierIdList=[]
      }
    }

  }
})();
