/**
* @Author: Allan
* @Date:   09-October-2018 19:03 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.admin.state.js
* @Last modified by:   Allan
* @Last modified time: 09-October-2018 19:03 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('ezypayment.admin', {
      parent: 'ezypayment',
      url: '/admin',
      data: {
        authorities: ['ROLE_ADMIN']
      },
      views: {
        'navbar@': {
          templateUrl: 'app/ezypayment-modules/admin-modules/common/ezypayment.admin.navbar.html',
          controller: 'EzyPaymentAdminNavbarController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    })
  }
})();
