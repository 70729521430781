/**
 * @Author: Jayalekshmi
 * @Date: 2019-01-03 10:40:23
 * @Project: Ezyprocure
 * @Filename: sats.po.controller.js
 * @Last modified by:   jayalekshmir
 * @Last modified time: 2019-02-12T10:03:19+05:30
 */



(function() {
    'use strict';

    angular
    .module('ezyprocureApp')
    .controller('SatsPOController', SatsPOController);

    SatsPOController.$inject = ['UtilService', 'SatsManagementService', 'NotificationService', 'UTCDateService', 'BuyerSupplierMappingService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'Auth', 'EXPORT_CONSTANTS', '$q','CSVExportService', '$mdDialog'];

    function SatsPOController(UtilService, SatsManagementService, NotificationService, UTCDateService, BuyerSupplierMappingService, GLOBAL_CONSTANTS, paginationConstants, Auth, EXPORT_CONSTANTS, $q, CSVExportService, $mdDialog) {

      var vm = this;
      vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;      
      vm.loadAll = loadAll;
      vm.todaysDate = new Date();
      vm.query = {
        limit: paginationConstants.itemsPerPage,
        page: 1
      };
      vm.poFilter = {};
      vm.userRole = Auth.getUserRole();
      vm.limitOptions = paginationConstants.limitOptions;
      vm.getAllJobs = getAllJobs;
      vm.getAllSupplierWithSatsAccess = getAllSupplierWithSatsAccess;
      vm.statusList = GLOBAL_CONSTANTS.SATS_STATUS;
      vm.documentType = 'PO';
      vm.poFilter.fromDate = UTCDateService.utcFromDate(vm.todaysDate);
      vm.poFilter.toDate = UTCDateService.utcToDate(vm.todaysDate);
      vm.reset = reset;
      vm.showConfirm = showConfirm;
      vm.deleteJob = deleteJob;

      // CSV
      vm.buyerPODataHeader = EXPORT_CONSTANTS.CSV.buyerPODataHeader;
      vm.getBuyerPOCSVData = getBuyerPOCSVData;

      /**
       * [getBuyerPOCSVData Gets the data in a csv format]
       */
      function getBuyerPOCSVData() {
        var deferred = $q.defer();
        if (vm.poJobList.length<1) {
          NotificationService.simpleErrorToast({
            title: 'global.table.noRecordsFound',
            message: 'alertMessage.SEARCH_AND_SUBMIT'
          });
          return deferred.promise;
        }
        return CSVExportService.getBuyerPOCSVData(vm.poJobList);
      }

      /**
       * [getAllSupplierWithSatsAccess Gets all suppliers have sats access]
       */
      function getAllSupplierWithSatsAccess() {
        if(vm.suppliers)
          return false;
        BuyerSupplierMappingService.getAllSupplierWithSatsAccess()
        .then(function(response){
          vm.suppliers = response.data;
        },function(error){
        });
      }

      vm.loadAll();

      /**
      * [loadAll PO Job lists]
      */
      function loadAll() {
        var isValidDateRange = UtilService.isValidDateRange(vm.poFilter.fromDate, vm.poFilter.toDate);
        if(!isValidDateRange){
          NotificationService.simpleErrorToast({
            title: 'global.table.noRecordsFound',
            message: 'alertMessage.SELECT_VALID_DATE_RANGE'
          });
          return false;
        }
        vm.getAllJobs();
      }

      /**
       * [getAllJobs Gets all PO Jobs]
       */
      function getAllJobs(){
        vm.poFilter.fromDate = !(vm.poFilter.fromDate)?null: UTCDateService.utcFromDate(vm.poFilter.fromDate);
        vm.poFilter.toDate = !(vm.poFilter.toDate)?null: UTCDateService.utcToDate(vm.poFilter.toDate);
        vm.poFilter.page = vm.query.page-1;
        vm.poFilter.size = vm.query.limit;
        SatsManagementService.getAllJobs(vm.documentType, vm.poFilter)
        .then(function(response){
          vm.poJobList = response.data;
          vm.totalPOItems = response.headers('X-Total-Count');
        } ,function(error){
          NotificationService.error({
            'title': 'global.sats.title.'+vm.userRole.pretty,
            'error':error
          });
        });
      }
      
      /**
       * [reset reset po filter]
       */
      function reset(){
        vm.poFilter.fromDate = UTCDateService.utcFromDate(vm.todaysDate);
        vm.poFilter.toDate = UTCDateService.utcToDate(vm.todaysDate);
        vm.poFilter.warehouse = "";
        vm.poFilter.status = "";
        vm.poFilter.container = "";
        vm.getAllJobs();
      }

      /**
       * [deleteJob Deletes specific job]
       * @param {*} jobId 
       */
      function deleteJob(jobId) {
        SatsManagementService.deleteJob([jobId]).then(function(response) {
          NotificationService.success({
            title: 'PO Job',
            message: 'alertMessage.DELETED_SUCCESSFULLY'
          });
          vm.loadAll();
        }, function(error) {
          NotificationService.error({
            title: 'Job Deletion',
            error: error
          });
        });
      }
  
      /**
       * [showConfirm Confirmation box for deletion]
       * @param {*} ev 
       * @param {*} poJob 
       */
      function showConfirm (ev, poJob) {
        if(poJob.status=='POSTED'){
          NotificationService.simpleErrorToast({
            title: 'alertMessage.ERR_VALIDATION',
            message: 'error.CAN_NOT_DELETE_POSTED_JOB'
          });
          return false;
        }
        var confirm = $mdDialog.confirm()
        .title('Are you sure you want to delete Job - ' + poJob.documentNumber + ' ? ')
        .textContent('This action could not be reverted back.')
        .targetEvent(ev)
        .ok('Delete')
        .cancel('Cancel');
  
        $mdDialog.show(confirm).then(function() {
          vm.deleteJob(poJob.id);
        }, function() {});
      }
  
      function cancel () {
        $mdDialog.cancel();
      }
    }
  })();
