/**
* @Author: Ajay
* @Date:   17-Jan-2017 17:22 +05:30
* @Project: Ezyprocure
* @Filename: admin.item-group.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 01-Feb-2018 15:28 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('ItemGroupService', ItemGroupService);

  ItemGroupService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function ItemGroupService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var itemGroupService = {
      getAllItemGroups: getAllItemGroups,
      getItemGroup: getItemGroup,
      createItemGroup: createItemGroup,
      updateItemGroup: updateItemGroup,
      deleteItemGroup: deleteItemGroup,
      getAllGroupedItemGroups: getAllGroupedItemGroups
    };

    return itemGroupService;

    /* --   ItemGroup CRUID operations  -- */

    //Get All  itemGroups
    function getAllItemGroups(urlParams) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ITEMGROUP_RESOURCE,
        method: "GET",
        params: urlParams,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get itemGroup details using itemGroup id
    function getItemGroup(itemGroupId) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ITEMGROUP_RESOURCE + itemGroupId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Create a new  itemGroup
    function createItemGroup(itemGroup) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ITEMGROUP_RESOURCE, itemGroup).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update  itemGroup
    function updateItemGroup(itemGroup) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ITEMGROUP_RESOURCE, itemGroup).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Soft Delete  itemGroup using itemGroupId
    function deleteItemGroup(itemGroupId) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ITEMGROUP_RESOURCE + itemGroupId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get All Grouped Item Groups
    function getAllGroupedItemGroups(mainItemGroupIdList, urlParams) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ITEMGROUP_RESOURCE + 'main-item-groups',
        method: "PUT",
        params: urlParams,
        data: mainItemGroupIdList
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
