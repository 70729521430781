/**
 * @Author: Fenn
 * @Date:   29-Apr-2020 19:49 +05:30
 * @Filename: myplans.service.js
 */


(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .factory('MyPlansService', MyPlansService);

  MyPlansService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS', 'AuthServerProvider'];

  function MyPlansService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS, AuthServerProvider) {
    var service = {
      fetchOldPlans:fetchOldPlans,
      fetchCurrentFuturePlans:fetchCurrentFuturePlans,
      unsubscribePlanById: unsubscribePlanById
    };

    return service;

    function fetchOldPlans(request) {
      var cbAuthToken = AuthServerProvider.getCbToken();
      var deferred = $q.defer();
      $http.post(
        API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.MY_PAST_PLANS, request,
        {
          headers: { Authorization: 'Bearer ' + cbAuthToken }
        }
      ).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function fetchCurrentFuturePlans(companyId, isBuyer) {
      var cbAuthToken = AuthServerProvider.getCbToken();
      var deferred = $q.defer();
      $http.get(
        API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.FUTURECURRENTPLANS + 'companyId=' + companyId + '&isBuyer=' + isBuyer,
        {
          headers: { Authorization: 'Bearer ' + cbAuthToken }
        }
      ).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function unsubscribePlanById(request) {
      var cbAuthToken = AuthServerProvider.getCbToken();
      var deferred = $q.defer();
      $http.put(
        API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.UNSUBSCRIBE_PLAN, request,
        {
          headers: { Authorization: 'Bearer ' + cbAuthToken }
        }
      ).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }


  }
})();
