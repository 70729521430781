/*
 * @Author: Jayalekshmi 
 * @Date: 2019-09-02 13:04:13 
 * @Project: Ezyprocure
 * @Filename: ezyap.manage-delivery-details.edit.controller.js
 * @Last Modified by: Lovin
 * @Last Modified time: 2020-02-03 12:13:00
 */

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('EzyapEditDeliveryDetailsController', EzyapEditDeliveryDetailsController);

  EzyapEditDeliveryDetailsController.$inject = ['NotificationService', 'CsvUploadService', 'UtilService', 'UploadInvoiceService', 'ManageBuyerInfoService', 'S3_URL_CONSTANTS', 'toastr', '$state', '$filter', 'ManageDeliveryDetailsService', '$stateParams', 'EZYAP_CONSTANTS', 'Auth'];

  function EzyapEditDeliveryDetailsController(NotificationService, CsvUploadService, UtilService, UploadInvoiceService, ManageBuyerInfoService, S3_URL_CONSTANTS, toastr, $state, $filter, ManageDeliveryDetailsService, $stateParams, EZYAP_CONSTANTS, Auth) {

    var vm = this;
    vm.getDeliveryDetailsById = getDeliveryDetailsById;
    vm.editDeliveryDetails = editDeliveryDetails;
    vm.deliveryDetailsId = $stateParams.id;
    vm.getBuyerInformation = getBuyerInformation;
    vm.countryCodes = EZYAP_CONSTANTS.ezyap.countryCodes;
    vm.init = init;
    vm.userRole = Auth.getUserRole();

    function init() {
      vm.getDeliveryDetailsById();
      vm.getBuyerInformation();
    }

    /**
     * [edit buyer information]
     */
    function editDeliveryDetails() {
      ManageDeliveryDetailsService.editDeliveryDetails({
        address: {
          additionalStreetName: vm.deliveryDetails.address.additionalStreetName ? vm.deliveryDetails.address.additionalStreetName : '',
          cityName: vm.deliveryDetails.address.cityName ? vm.deliveryDetails.address.cityName : '',
          companyId: vm.deliveryDetails.address.companyId ? vm.deliveryDetails.address.companyId : '',
          contactEmail: vm.deliveryDetails.address.contactEmail ? vm.deliveryDetails.address.contactEmail : '',
          contactTelephone: vm.deliveryDetails.address.contactTelephone ? vm.deliveryDetails.address.contactTelephone : '',
          contactName: vm.deliveryDetails.address.contactName ? vm.deliveryDetails.address.contactName : '',
          countryCode: vm.deliveryDetails.address.countryCode,
          organisationName: vm.deliveryDetails.address.organisationName,
          postalZone: vm.deliveryDetails.address.postalZone ? vm.deliveryDetails.address.postalZone : '',
          streetName: vm.deliveryDetails.address.streetName ? vm.deliveryDetails.address.streetName : '',
          id: vm.deliveryDetails.address.id,
        }, buyerInformation: { id: vm.buyerInfoId }, id: vm.deliveryDetailsId,
        createdDate: vm.deliveryDetails.createdDate

      }).
        then(function (responseData) {
          $state.go('ezyap.' + vm.userRole.pretty + '.manage-delivery-details');
          NotificationService.success({
            title: 'Delivery Details',
            message: 'alertMessage.UPDATED_SUCCESSFULLY'
          });
        }, function (error) {
          NotificationService.error({
            title: 'Delivery Details',
            error: error
          });
        });
    }

    /**
    * get all buyer information
    */
    function getBuyerInformation() {
      if (vm.buyerDetails) {
        return false;
      }
      ManageBuyerInfoService.getBuyerInformation({ query: "" }).
        then(function (response) {
          vm.buyerDetails = response.data;
        }, function (error) {
          NotificationService.error({
            title: '',
            error: error
          });
        });
    }

    /**
     * [get delivery details by id]
     * @param {Integer} deliveryDetailsId [Delivery details id]
     */
    function getDeliveryDetailsById() {
      ManageDeliveryDetailsService.getDeliveryDetailsById(vm.deliveryDetailsId).
        then(function (response) {
          vm.deliveryDetails = response.data;
        }, function (error) {
          NotificationService.error({
            title: 'Delivery datails',
            error: error
          });
        });
    }
  }
})();
