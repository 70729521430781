/**
* @Author: sneha
* @Date:   15-Mar-2018 17:04 +05:30
* @Project: Ezyprocure
* @Filename: supplier.stock-report.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 12-Oct-2018 15:01 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierStockReportController', SupplierStockReportController);

  SupplierStockReportController.$inject = ['$scope', '$rootScope','$q', '$filter', '$state', '$stateParams', 'pagingParams', 'toastr', '$mdDialog', 'UTCDateService', 'NotificationService', 'BusinessSupplierMapService', 'paginationConstants', 'StockReportsService', 'GLOBAL_CONSTANTS', 'ReportService', 'EXPORT_CONSTANTS', 'CSVExportService', 'PDFExportService'];

  function SupplierStockReportController($scope, $rootScope, $q, $filter, $state, $stateParams, pagingParams, toastr, $mdDialog, UTCDateService, NotificationService, BusinessSupplierMapService, paginationConstants, StockReportsService, GLOBAL_CONSTANTS, ReportService, EXPORT_CONSTANTS, CSVExportService, PDFExportService) {
    var vm = this;
    vm.filter = {};
    vm.todaysDate = new Date();
    vm.filter.fromDate = vm.todaysDate;
    vm.filter.toDate = vm.todaysDate;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.query = {
      limit: paginationConstants.maxLimit,
      page: 1
    };
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.getStockReports = getStockReports;
    vm.stockListViews  = GLOBAL_CONSTANTS.STOCK_LIST_VIEWS;
    vm.filter.listView = vm.stockListViews[0].value;
    vm.setList = setList;
    vm.stockprStatus  = GLOBAL_CONSTANTS.STOCK_PR_STATUS;
    vm.filter.prStatus = vm.stockprStatus[0].value;
    vm.setprStatus = setPRStatus;
    vm.StockReportHeader = EXPORT_CONSTANTS.CSV.supplierStockReport;
    vm.getStockReportsCSVData = getStockReportsCSVData;
    vm.getStockReportPDF=getStockReportPDF;
    vm.resetFilters = resetFilters;

    /**
    * [sort to sort by  date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

/**
 * [getAllBuyerBusinessOutlets get business outlets]
 */
    function getAllBuyerBusinessOutlets() {
      if(vm.businessOutlets)
        return false;
      BusinessSupplierMapService.getBuyerBusinessOutlets({
        page: 0,
        size: paginationConstants.maxLimit,
        sort: '',
      }).then(function(response) {
        vm.businessOutlets = response.data;
      }, function(error) {
      });
    }

    /**
    * [selectAll Select all business outlets from the list]
    */
    function selectAll() {
      vm.filter.buyerBusinessIdList = _.map(vm.businessOutlets, _.property('id'));
    }

    /**
    * [deselectAll Deselect all business outlets from the list]
    */
    function deselectAll() {
      vm.filter.buyerBusinessIdList = [];
    }


/**
 * [setList empty stockList object on change of dropdown]
 */
    function setList(){
      vm.stockLists = {};
    }

    /**
     * [setPRStatus empty stockprstatus object on change of dropdown]
     */
    function setPRStatus(){
        vm.stockLists = {};
    }


    /**
    * [getStockReports  gets Stock report details]
    */
    function getStockReports() {
      vm.businessReportRequestDTO= {
        buyerBusinessIdList: !vm.filter.buyerBusinessIdList ? [] : vm.filter.buyerBusinessIdList ,
        //If dates are selected, they are converted to UTC format
        fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
        toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
        query: !vm.filter.itemName ? '' : vm.filter.itemName,
        itemCode: !vm.filter.itemCode ? '' : vm.filter.itemCode,
        purchaseOrderStatus: !vm.filter.prStatus ? '' : vm.filter.prStatus
      };
      StockReportsService.getAllStockReports(vm.businessReportRequestDTO,{
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        viewType: vm.filter.listView||"CONSOLIDATED_VIEW",
        //purchaseOrderStatus: vm.filter.prStatus
      }).then(function(response){
        vm.fromDate =  moment(vm.filter.fromDate).format("DD-MM-YYYY");
        vm.toDate =  moment(vm.filter.toDate).format("DD-MM-YYYY");
        vm.stockLists = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      },function(error){
        NotificationService.error({
          title: 'Stock Needed Report',
          error: error
        });
      });
    }

    //Getting CSV DATA
    function getStockReportsCSVData(){
      var deferred = $q.defer();
      if (!vm.stockLists) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      else{
        if(vm.filter.listView === 'BREAKDOWN_BY_OUTLET'){
          return CSVExportService.getSupplierStockReportByOutletsCSV(vm.stockLists);
        }
        else if(vm.filter.listView === 'CONSOLIDATED_VIEW'){
          return CSVExportService.getSupplierStockReportByItemsCSV(vm.stockLists);
        }
      }
    }
    /**
    * [getStockReportPDF  gets Stock report PDF]
    */
    function getStockReportPDF() {
      vm.businessReportRequestDTO= {
        buyerBusinessIdList: !vm.filter.buyerBusinessIdList ? [] : vm.filter.buyerBusinessIdList ,
        //If dates are selected, they are converted to UTC format
        fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
        toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
        query: !vm.filter.itemName ? '' : vm.filter.itemName,
        itemCode: !vm.filter.itemCode ? '' : vm.filter.itemCode,
        purchaseOrderStatus: !vm.filter.prStatus ? '' : vm.filter.prStatus
      };
      PDFExportService.printStockReportsPDF(vm.businessReportRequestDTO,{
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        viewType: vm.filter.listView,
        // purchaseOrderStatus: vm.filter.prStatus
      }).then(function(response){
        vm.fromDate =  moment(vm.filter.fromDate).format("DD-MM-YYYY");
        vm.toDate =  moment(vm.filter.toDate).format("DD-MM-YYYY");
        downloadURI(response.data.message, "Stock_Details");
        NotificationService.success({
          title: 'Stock Needed Report PDF',
          message: 'Exported successfully'
        });
      },function(error){
        NotificationService.error({
          title: 'Stock Needed Report',
          error: error
        });
      });
    }

    function downloadURI(uri, name)
    {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = name;
      link.href = uri;
      link.click();
    }

    function resetFilters() {
        vm.filter = {};
        vm.filter.fromDate = vm.todaysDate;
        vm.filter.toDate = vm.todaysDate;
        vm.filter.buyerBusinessIdList = [];
        vm.filter.listView = 'BREAKDOWN_BY_OUTLET';
        vm.filter.prStatus = 'ALL';
        vm.stockLists = [];
    }

  }
})();
