/**
* @Author: Ajay
* @Date:   21-Mar-2017 19:15 +05:30
* @Project: Ezyprocure
* @Filename: manage-banks.list.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 10-Nov-2017 11:16 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('ManageBankListController', ManageBankListController);

  ManageBankListController.$inject = ['$scope', '$rootScope', '$state', 'NotificationService', '$mdDialog', 'Auth', 'ManageBankService', 'pagingParams', 'paginationConstants'];

  function ManageBankListController($scope, $rootScope, $state, NotificationService, $mdDialog, Auth, ManageBankService, pagingParams, paginationConstants) {
    var vm = this;

    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.searchText = '';
    vm.loadAll = loadAll;
    vm.userRole = Auth.getUserRole();

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function loadAll(search) {
      if (!search) {
        vm.searchText = '';
      }
      ManageBankService.getAllAdminBanksWithUserDetails({
        page: vm.query.page - 1,
        size: vm.query.limit,
        search: vm.searchText,
        sort: sort()
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.banks = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'Admin Bank Listing',
          error: error
        });
      });
    }

    vm.loadAll();

    function deleteAdminBank(bankId) {
      ManageBankService.deleteAdminBank(bankId).then(function(response) {
        NotificationService.success({
          title: 'Admin Bank',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'Admin Bank Deletion',
          error: error
        });
      });
    }

    vm.showConfirm = function(ev, bank) {
      var confirm = $mdDialog.confirm()
      .title('Are you sure you want to delete Admin Bank?')
      .textContent('This action could not be reverted back.')
      .targetEvent(ev)
      .ok('Delete')
      .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        deleteAdminBank(bank.id);
      }, function() {});
    };

  }
})();
