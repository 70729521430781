/**
 * @Author: Ajay
 * @Date:   2017-11-01T12:54:05+05:30
 * @Filename: notification.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 14-Dec-2017 16:23 +05:30
 */



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('NotificationService', NotificationService);

  NotificationService.$inject = ['toastr', '$filter', '$translate'];
  function NotificationService(toastr, $filter, $translate) {

    var NotificationService = {
      success: success,
      error: error,
      simpleErrorToast: simpleErrorToast,
      getSuccessMessage: getSuccessMessage,
      getErrorMessage: getErrorMessage,
      getToastrOptions: getToastrOptions
    };

    return NotificationService;


    /* --  Notification Service  -- */

    function getToastrOptions() {
      var toastrOptions = {
        progressBar: false,
        tapToDismiss: true,
        timeOut: 5000,
        closeButton: true,
        positionClass: 'toast-top-right'
      };
      return toastrOptions;
    }

    function getSuccessMessage(successMessage) {
      return $filter('translate')(successMessage);
    }

    function getErrorMessage(errorData) {
      var errorMessage;
      if(errorData.error.status === -1){
        errorMessage = $filter('translate')('error.noInternetConnection');
      }else if(errorData.error.data.approvalerror){
        errorMessage = errorData.error.data.approvalerror;
      }else {
        errorMessage = errorData.error.headers('X-ezyprocureApp-error') ? errorData.error.headers('X-ezyprocureApp-error') : errorData.error.data.message;
      }
      return $filter('translate')(errorMessage);
    }

    //show success  based on data
    function success(data) {
      var toastrDetails = {
        title: $filter('translate')(data.title),
        message: this.getSuccessMessage(data.message)
      };
      toastr.success(toastrDetails.message, toastrDetails.title, this.getToastrOptions());
    }

    //show error  based on data
    function error(data) {
      var toastrDetails = {
        title: $filter('translate')(data.title),
        message: this.getErrorMessage(data)
      };
      toastr.error(toastrDetails.message, toastrDetails.title, this.getToastrOptions());
    }

    function simpleErrorToast(data) {
      var message, toastrDetails;
      $translate(data.message, data.params).then(function(msg) {
        message = msg;
        toastrDetails = {
          title: $filter('translate')(data.title),
          message: message
        };
        toastr.error(toastrDetails.message, toastrDetails.title, NotificationService.getToastrOptions());
      });
    }

  }
})();
