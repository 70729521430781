/**
 * @Author: Vaibhavi
 * @Date:   10-Jul-2018 13:30 +05:30
 * @Project: Ezyprocure
 * @Filename: ezypayment.buyer.upload-payment.controller.js
 * @Last modified by:   Allan
 * @Last modified time: 27-Aug-2018 18:52 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzypaymentPaymentUploadController', EzypaymentPaymentUploadController);

  EzypaymentPaymentUploadController.$inject = ['$scope', '$rootScope', '$state', 'toastr', '$mdDialog', 'BuyerUploadPaymentService', 'CsvUploadService', 'NotificationService', 'UtilService', 'S3_URL_CONSTANTS'];

  function EzypaymentPaymentUploadController($scope, $rootScope, $state, toastr, $mdDialog, BuyerUploadPaymentService, CsvUploadService, NotificationService, UtilService, S3_URL_CONSTANTS){
    var vm = this;
    vm.getCSVBase64 = getCSVBase64;
    vm.paymentUploadCSV = paymentUploadCSV;
    vm.previewPaymentUpload=previewPaymentUpload;
    vm.clearCSVUpload=clearCSVUpload;
    vm.PAYMENT_UPLOAD_CSV = S3_URL_CONSTANTS.PAYMENT_UPLOAD_CSV;
    var loginType='Buyer';

    function getCSVBase64() {
      var csvFile = vm.files[0];
      vm.recordError= {};
      if (!csvFile) {
        toastr.error('Please upload a .csv file!', 'CSV File Required');
        return false;
      }
      CsvUploadService.getFileBase64(csvFile.lfFile, function(base64CSV) {
        vm.csvUploadData = {
          encodedCsvFile: base64CSV
        };
        vm.paymentUploadCSV(vm.csvUploadData);
      });
    }

    function paymentUploadCSV(uploadData) {
      BuyerUploadPaymentService.paymentUploadCSV(uploadData,{loginType:loginType}).then(function(response) {
        clearCSVUpload();
        NotificationService.success({
        title: 'Payment File Uploaded successfully',
        message: response.message
      });
      }, function(error) {
        vm.recordError = error.data;
        NotificationService.error({
          title: 'Payment File Upload',
          error: error
        });
      });
    }

    /**
     * [previewPaymentUpload Preview of Payment Upload using CSV]
     */
    function previewPaymentUpload(){
      var csvFile = vm.files[0];
      vm.isCsvFile = CsvUploadService.checkFileType(csvFile.lfFile);
      if (vm.isCsvFile) {
        UtilService.csvToJson(csvFile.lfDataUrl)
        .then(function (response) {
          vm.paymentUploadItems = response;
        })
      }
    }

    /**
     * [clearCSVUpload resets the file upload and the preview table
     */
    function clearCSVUpload(){
      vm.files=[];
      vm.paymentUploadItems=[];
      vm.recordError=null;
    }

  }

})();
