/**
* @Author: Abhay
* @Date:   28-April-2021 16:03 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.axs-transaction-details.controller.js
 * @Last modified by:   Abhay
 * @Last modified time: 28-April-2021 16:03 +05:30
*/

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('AxsTransactionDetailController', AxsTransactionDetailController);

  AxsTransactionDetailController.$inject = ['$stateParams', '$state', 'paginationConstants', '$sce', '$mdDialog', '$filter', '$timeout', 'toastr', 'Auth', 'CardsService', 'BuyerDataService', 'BuyerSupplierMappingService', 'BrandManagementService', 'AxsTransactionDetailService', 'GLOBAL_CONSTANTS', 'NotificationService', 'GLOBAL_CONFIG_CONSTANTS'];

  function AxsTransactionDetailController($stateParams, $state, paginationConstants, $sce, $mdDialog, $filter, $timeout, toastr, Auth, CardsService, BuyerDataService, BuyerSupplierMappingService, BrandManagementService, AxsTransactionDetailService, GLOBAL_CONSTANTS, NotificationService, GLOBAL_CONFIG_CONSTANTS) {
    var vm = this;
    vm.init = init;
    vm.transactionResponse = $stateParams.referenceNumber;
    vm.transactionSuccess = $stateParams.transactionFlag === "1";
    vm.transactionsList = [];
    vm.transactionDetails = [];
    vm.showSummary = false;
    vm.axsSummaryUrl = trustAsResourceUrl('https://e-station2.axs.com.sg/wSDK/v1_2/merchantSummary.php');
    vm.summaryParams = {
      merchantId: "",
      merchantKeyId: "",
      sessionIdEnc: "",
      userId: "",
      hashValue: "",
      axskeyId: "",
      txnRef: "",
      txnAmount: "",
      popupMsg: ""
    }
    vm.userRole = Auth.getUserRole().pretty;
    vm.getPaymentDetails = getPaymentDetails;
    vm.openDialog = openDialog;
    vm.closeDialog = closeDialog;
    // 2FA changes
    vm.otp = undefined;
    vm.isOtpValid = true;
    vm.counterMilliSeconds = undefined;
    vm.counterSeconds = undefined;
    vm.counterMinutes = undefined;
    vm.validateOTP = validateOTP;
    vm.resendOTP = resendOTP;
    vm.paymentStatusClass = GLOBAL_CONFIG_CONSTANTS.PAYMENT_AXS_STATUS.LABEL_CLASS;

    init();

    function init() {
      vm.transactionResponse && vm.transactionSuccess ? getPaymentDetails() : handleParamErrors();
    }

    function openDialog(ev) {
      $mdDialog.show({
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        templateUrl: 'app/common-modules/transaction-summary-report/axs-transaction-summary-report-detail.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true,
      }).then(function (answer) {

      }, function () {

      });
    }

    function closeDialog() {
      $mdDialog.hide();
    }

    function trustAsResourceUrl(url) {
      return $sce.trustAsResourceUrl(url);
    }

    function getPaymentDetails(otp) {
      var params = {
        referenceSequenceNo: vm.transactionResponse
      }
      AxsTransactionDetailService.getPaymentDetails(params, otp).then(function(response) {
        processTransactionDetailResponse(response);
      },
      function(error) {
        toastr.error('Internal server error');
      });
    }

    function checkPaymentStatus(referenceNumber, status) {
      status != 'PAID' && getSummaryDetails(referenceNumber);
    }

    function getSummaryDetails(referenceNumber) {
      var params = {
        transactionReferenceNo: referenceNumber
      }
      AxsTransactionDetailService.axsSummary(params).then(function(response) {
        enableSummaryForm(response);
      },
      function(error) {
        toastr.error('Internal server error');
      });
    }

    function enableSummaryForm(response) {
      vm.showSummary = true;
      toastr.error('Payment Failed!');
      Object.keys(vm.summaryParams).forEach(function(key) {
        vm.summaryParams[key] = response.data[key] || "";
      });
      $timeout(function() {
        vm.axsSummaryForm.commit();
      }, 4000);
    }

    function axsTransactionUpdate(referenceNumber) {
      var params = {
        transactionReferenceNo: referenceNumber
      }
      AxsTransactionDetailService.axsUpdate(params).then(function(response) {
        toastr.success('AXS updated');
      },
      function(error) {
        toastr.error('Internal server error');
      });
    }

    function handleParamErrors() {
      !vm.transactionSuccess && vm.transactionResponse ? displayMessage() : toastr.error('No param found!');
    }

    function displayMessage() {
      $mdDialog.show(
        $mdDialog.alert()
          .clickOutsideToClose(false)
          .title('Transaction error')
          .textContent(vm.transactionResponse)
          .ariaLabel('Error Dialog')
          .ok('Close')
      );
    }

    function resendOTP() {
      getPaymentDetails();
    }

    function validateOTP() {
      if (!vm.otp) {
        toastr.error('Invalid OTP!', 'Please check the OTP entered');
        return;
      }

      if (vm.otp && (vm.otp.length < 5 || vm.otp.length > 8)) {
        toastr.error('Invalid OTP!', 'Please check the OTP entered');
        return;
      }
      getPaymentDetails(vm.otp);
    }

    // timer for otp resend
    function counter() {
      vm.stopped = $timeout(function () {
        vm.counterMilliSeconds -= 1000;
        if (vm.counterMilliSeconds) {
          var seconds = vm.counterMilliSeconds / 1000;
          vm.counterMinutes = Math.floor(seconds / 60);
          vm.counterSeconds = vm.counterMinutes > 0 ? seconds - (vm.counterMinutes * 60) : seconds;
          counter();
        }
        else {
          vm.counterSeconds = 0;
          vm.counterMinutes = 0;
          stopCounter();
        }
      }, 1000);
    };

    function stopCounter(){
      vm.isOtpValid = false;
      $timeout.cancel(vm.stopped);
    }

    function showOtpPrompt() {
      // clear otp
      vm.otp = undefined;
      vm.isOtpValid = true;
      vm.counterSeconds = 0;
      vm.counterMinutes = 0;
      vm.counterMilliSeconds = GLOBAL_CONSTANTS.OTP_EXPIRY_MINUTES * 60 * 1000;
      counter();
      // show prompt
      $mdDialog.show({
        templateUrl: 'app/core/common-templates/otp-templates/otp-capture.tmpl.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: false
      });
    }

    function processTransactionDetailResponse(response) {
      switch(true) {
        case response.headers('authorization-otp') == 'OTP_VERIFICATION_REQUIRED': {
          showOtpPrompt();
        }
        break;
        case GLOBAL_CONSTANTS.OTP_EXCEPTIONS.includes(response.headers('authorization-otp')): {
          toastr.error('Invalid OTP', 'AUTHENTICATION FAILED');
        }
        break;
        default: {
          $mdDialog.hide();
          vm.transactionsList = Array.isArray(response.data) ? response.data: [response.data];
          if(vm.transactionsList[0].errorMessage){
            toastr.error(vm.transactionsList[0].errorMessage, 'ERROR');
          }
          vm.transactionDetails = response.data.axsTransactionDetailList || [];
          checkPaymentStatus(response.data.transactionReferenceNumber, response.data.paymentDTO.paymentStatus);
        }
      }
    }
  }
})();
