/**
 * @Author: Jayalekshmi 
 * @Date: 2019-01-03 10:40:23 
 * @Project: Ezyprocure
 * @Filename: sats.to.add.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-07-05 15:44:30
 */



(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .controller('SatsToAddController', SatsToAddController);
  
    SatsToAddController.$inject = ['$state', 'CsvUploadService', 'UtilService', 'SatsManagementService', 'NotificationService', 'Auth'];
  
    function SatsToAddController($state, CsvUploadService, UtilService, SatsManagementService, NotificationService, Auth) {
      var vm = this;
      vm.userRole = Auth.getUserRole();
      vm.uploadJobTO = uploadJobTO;
      vm.resetValues = resetValues;
      vm.createTOJob = createTOJob;
      vm.getDateFormat = getDateFormat;

      /**
       * [getDateFormat gets the date string and converts]
       * @param {dateString} dateString 
       */
      function getDateFormat(dateString){
        var formatedDate = SatsManagementService.getDateFormat(dateString);
        return formatedDate;
      }

      /**
       * [createTOJob Creates Job TO]
       */
      function createTOJob(){
        SatsManagementService.createTOJob(vm.files[0].lfFile)
        .then(function(response){
          NotificationService.success({
            title: 'global.sats.title.'+vm.userRole.pretty,
            message: 'Job Created Succesfully'
          });
          $state.go(vm.userRole.pretty+'.sats', {
            reload: true
          });
        }, function(error){
          if(error.data.message == 'error.validation'){
            vm.recordError = error.data;
          }
          NotificationService.error({
            title: 'global.sats.title.'+vm.userRole.pretty,
            error: error
          });
        })
      }

      /**
       * [uploadJobTO Uploads Job TO]
       */
      function uploadJobTO(){
        vm.recordError = [];
        var csvFile = vm.files[0];
        if (!csvFile) {
          NotificationService.simpleErrorToast({
            title: 'alertMessage.INVALID_INPUT',
            message: 'global.pleaseChooseACsvFile'
          });
          return false;
        }
        vm.isCsvFile = CsvUploadService.checkFileType(csvFile.lfFile);
        if (vm.isCsvFile) {
          UtilService.csvToJson(csvFile.lfDataUrl)
          .then(function (response) {
            vm.toItems = response;
            if(vm.toItems.length <1){
              NotificationService.simpleErrorToast({
                title: 'alertMessage.INVALID_INPUT',
                message: 'alertMessage.FILL_CSV'
              });
            }
          })
        } 
      }

      /**
       * [resetValues Resets the error and csv datas on click of browse button]
       */
      function resetValues(){
        vm.toItems = [];
        vm.recordError = [];
      }
    }
  })();
  