/**
 * @Author: Ajay
 * @Date:   24-Oct-2017 12:55 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.adhoc-po-form.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 11-Jan-2018 12:31 +05:30
 */



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BuyerAdhocPOFormService', BuyerAdhocPOFormService);

  BuyerAdhocPOFormService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function BuyerAdhocPOFormService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var buyerAdhocPOFormService = {
      setAdhocPOFormFilter: setAdhocPOFormFilter,
      getAdhocPOFormFilter: getAdhocPOFormFilter,
      clearAdhocPOFormFilter: clearAdhocPOFormFilter,
      createNewAdhocPOForm: createNewAdhocPOForm,
      createNewAdhocPOFormCSV: createNewAdhocPOFormCSV,
      gstConfigDetails: gstConfigDetails
    };

    var adhocPOFormFilter;

    return buyerAdhocPOFormService;

    /**
     * [getAdhocPOFormFilter retrive adhoc invoice filter]
     * @return {[object]} [adhoc invoice object containing buyer list and business outlets]
     */
    function getAdhocPOFormFilter() {
      return adhocPOFormFilter ? adhocPOFormFilter : null;
    }

    /**
     * [setAdhocPOFormFilter store adhoc invoice filter]
     * @param {[object]} adhocPOFormFilterParam [adhoc invoice object containing buyer list and business outlets]
     */
    function setAdhocPOFormFilter(adhocPOFormFilterParam) {
      adhocPOFormFilter = adhocPOFormFilterParam;
    }

    /**
     * [clearAdhocPOFormFilter clear adhoc invoice filter]
     */
    function clearAdhocPOFormFilter() {
      adhocPOFormFilter = null;
    }

    /**
     * [createNewAdhocPOForm create a new Adhoc PO Form]
     * @param  {[List]} adhocPOFormDTO [Adhoc PO Form DTO]
     * @return {[type]}                 [description]
     */
    function createNewAdhocPOForm(adhocPOFormDTO) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ADHOC_PO + 'form',
        data: adhocPOFormDTO,
        method: "POST",
      }).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function gstConfigDetails() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/common/config/all',
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }


    /**
     * [createNewAdhocPOForm create a new Adhoc PO Form]
     * @param  {[List]} adhocPOFormDTO [Adhoc PO Form DTO]
     * @return {[type]}                 [description]
     */
    function createNewAdhocPOFormCSV(adhocPOFormDTO) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ADHOC_PO +'form/csv-upload',
        data: adhocPOFormDTO,
        method: "POST",
      }).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
