/**
 * @Author: Ajay
 * @Date:   04-Dec-2017 10:19 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.item-mapping.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2018-06-29T12:54:39+05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerItemMappingController', BuyerItemMappingController);

  BuyerItemMappingController.$inject = ['$scope', '$state', '$stateParams', 'toastr', 'pagingParams', 'BuyerDataService', 'BuyerItemService', 'BuyerSupplierMappingService', 'BuyerBusinessService', 'BuyerItemMapService', 'paginationConstants', 'NotificationService', 'BuyerItemOutletMapService'];

  function BuyerItemMappingController ($scope, $state, $stateParams, toastr, pagingParams, BuyerDataService, BuyerItemService, BuyerSupplierMappingService, BuyerBusinessService, BuyerItemMapService, paginationConstants, NotificationService, BuyerItemOutletMapService) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.allSelected = false;
    vm.sort = sort;
    vm.loadAll = loadAll;
    vm.deleteMapping = deleteMapping;
    vm.mappedItemSearch = '';
    vm.deleteMappingByIds = deleteMappingByIds;
    vm.checkAllRows = checkAllRows;
    vm.getSelectedRows = getSelectedRows;
    vm.getSelectedItemsDetailList = getSelectedItemsDetailList;
    vm.unmapMultipleItems = unmapMultipleItems;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    function checkAllRows() {
      vm.mappedItems.forEach(function (item) {
        item.isSelected = vm.allSelected;
      });
    }

    function getSelectedRows() {
      if (vm.mappedItems && vm.mappedItems.length > 0) {
        var selectedIdList = vm.mappedItems.filter(function(item) {
          return item.isSelected;
        });
      }
      return selectedIdList || [];
    }

    function getSelectedItemsDetailList() {
      var selectedItemsDetailList = [];
      var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      vm.mappedItems.forEach(function (item) {
        if(item.isSelected) {
          var itemDetail = {
            buyerId: buyerId,
            itemBusinessMappingId: item.itemBusinessMappingId,
            itemId: item.id,
            userType: 'BUYER'
          }
          selectedItemsDetailList.push(itemDetail);
        }
      });
      return selectedItemsDetailList;
    }

    function unmapMultipleItems() {
      var itemDetailList = getSelectedItemsDetailList();
      BuyerItemOutletMapService.unmapMultipleItems(itemDetailList).then(function(response){
        NotificationService.success({
          title: 'Item Mapping',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      },function(error){
        NotificationService.error({
          title: 'Item Mapping Deletion',
          error: error
        });
      });
    }

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    // Load All supplier items which are mapped
    function loadAll (search) {
      if (!search) {
        vm.mappedItemSearch = '';
      }
      BuyerItemMapService.getAllMappedItems({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.mappedItemSearch,
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.mappedItems = response.data;
      },function(error){
      });
    }

    //delete a mapping
    function deleteMapping(itemBusinessMappingId) {
      BuyerItemOutletMapService.deleteMappingByItemBusinessMappingId(itemBusinessMappingId).then(function(response){
        NotificationService.success({
          title: 'Item Mapping',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      },function(error){
        NotificationService.error({
          title: 'Item Mapping Deletion',
          error: error
        });
      });
    }

    /**
     * [deleteMappingByIds by ids ]
     * @param {*} itemId 
     */
    function deleteMappingByIds(itemBusinessMappingId, itemId) {
      var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      BuyerItemOutletMapService.deleteMappingByItemBusinessMappingIds(itemBusinessMappingId, buyerId, itemId, 'BUYER').then(function(response){
        NotificationService.success({
          title: 'Item Mapping',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      },function(error){
        NotificationService.error({
          title: 'Item Mapping Deletion',
          error: error
        });
      });
    }

    vm.loadAll();

  }
})();
