/**
* @Author: Ajay
* @Date:   31-Jan-2017 10:45 +05:30
* @Project: Ezyprocure
* @Filename: supplier.item.mapping.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 18-Oct-2017 16:39 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('SupplierItemMapService', SupplierItemMapService);

  SupplierItemMapService.$inject = ['$http', '$q', 'API_CONSTANTS'];
  function SupplierItemMapService($http, $q, API_CONSTANTS) {

    var supplierItemMapService = {
      getItemBuyerMappingsByItemId: getItemBuyerMappingsByItemId,
      updateItemBuyerMapping: updateItemBuyerMapping,
      getAllMappedItems: getAllMappedItems,
      deleteMapping: deleteMapping,
      createNewItemBuyerOutletMapping: createNewItemBuyerOutletMapping,
      createNewItemSupplierCsvMapping: createNewItemSupplierCsvMapping,
      getAllMappedItemsByPrice : getAllMappedItemsByPrice,
      getAllMappedItemsPerOutlet : getAllMappedItemsPerOutlet,
      updateItemBuyerMappingPerOutlet : updateItemBuyerMappingPerOutlet,
      updateAllItemBuyerMappingPerItem:updateAllItemBuyerMappingPerItem
    };

    return supplierItemMapService;

    /* --  AdminBuyer CRUID operations  -- */

    //Get All Item - Buyer Mapping Lists By ItemId
    function getItemBuyerMappingsByItemId(urlParams,itemId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'item/mappings/'+ itemId,
        method: "GET",
        params: urlParams,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update Admin supplier
    function updateItemBuyerMapping(itemBuyerMapping) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'item/mappings/business', itemBuyerMapping).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get all supplier items which are mapped to a buyer. pagination and search query as parameter.
    function getAllMappedItems(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'item/mappings',
        method: "GET",
        params: params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    // get all items with buyer mapped price
    function getAllMappedItemsByPrice(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'item/mappings/add/mapping',
        method: "GET",
        params: params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    //Delete a buyer-item mapping using mapping id
    function deleteMapping(mappingId,busoutlets) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'item/mappings/' + mappingId +'/' +busoutlets,
        method: "DELETE",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Create new mapping between items and buyer/business outlet
    function createNewItemBuyerOutletMapping(mappingObject) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'supplier/items/mapping', mappingObject).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //create item mapping with buyer and business outlet
    function createNewItemSupplierCsvMapping(mappingData) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'supplier/items/mapping/upload', mappingData).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get all supplier items which are mapped to a buyer. pagination and item Id as parameter.
    function getAllMappedItemsPerOutlet(params, itemId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'item/business/mappings/v2/'+ itemId,
        method: "GET",
        params: params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

      //Update the item allocation per outlet
    function updateItemBuyerMappingPerOutlet(itemBuyerMapping) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'item/mappings/business/v2', itemBuyerMapping).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

      //Update all the item allocation per outlet
      function updateAllItemBuyerMappingPerItem(itemBuyerMapping) {
        var deferred = $q.defer();
        $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'item/mappings/business/v2/all', itemBuyerMapping).then(function(data) {
          deferred.resolve(data.data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
  }
})();
