/**
 * @Author: Ajay
 * @Date:   15-Oct-2018 14:41 +05:30
 * @Project: Ezyprocure
 * @Filename: md-date.config.js
 * @Last modified by:   Ajay
 * @Last modified time: 15-Oct-2018 15:04 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(mdDateServiceConfig);

  mdDateServiceConfig.$inject = ['$mdDateLocaleProvider'];

  function mdDateServiceConfig($mdDateLocaleProvider) {

    $mdDateLocaleProvider.formatDate = function(date) {
      return moment(date).format('DD/MM/YYYY');
    };
    $mdDateLocaleProvider.parseDate = function(dateString) {
      var m = moment(dateString, 'DD/MM/YYYY', true);
      return m.isValid() ? m.toDate() : new Date(NaN);
    };
    function getCookie(name)
    {
      var re = new RegExp(name + "=([^;]+)");
      var value = re.exec(document.cookie);
      return (value != null) ? unescape(value[1]) : null;
    }
    var cookie  = getCookie('NG_TRANSLATE_LANG_KEY');
    if(cookie == '"zh-cn"'){
      $mdDateLocaleProvider.shortDays = [
        "周 日",
        "周 一",
        "周 二",
        "周 三",
        "周 四",
        "周 五",
        "周 六"
      ];
    }
   
  }
})();
