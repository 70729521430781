/**
* @Author: Ajay
* @Date:   23-Apr-2017 20:57 +05:30
* @Project: Ezyprocure
* @Filename: decimal.filter.js
 * @Last modified by:   Ajay
 * @Last modified time: 26-Mar-2018 13:14 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .filter('titleCase', titleCase);

  function titleCase() {
    return titleCase;
    function titleCase(input) {
      input = input || '';
      return input.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    }
  }
})();
