/*
 * @Author: Ajay
 * @Date: 2019-02-11 11:38:17
 * @Project: Ezyprocure
 * @Filename: ezyap.buyer-information.service.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2020-01-28 11:03:51
 */

(function () {
  'use strict';

  angular.module('ezyprocureApp').factory('ManageBuyerInfoService', ManageBuyerInfoService);

  ManageBuyerInfoService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function ManageBuyerInfoService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var ManageBuyerInfoService = {
      addBuyerInformation: addBuyerInformation,
      getBuyerInformation: getBuyerInformation,
      getBuyerInformationById: getBuyerInformationById,
      deleteBuyerInformationById: deleteBuyerInformationById,
      editBuyerInformation: editBuyerInformation,
      searchAllPeppolUsers: searchAllPeppolUsers
    };
    return ManageBuyerInfoService;

    /**
     * [add buyer information]
     * @param {Object} data [buyer information data]
     */
    function addBuyerInformation(data) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/buyer-information',
        method: "PUT",
        data: data
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [edit buyer information]
     * @param {Object} data [buyer information data]
     */
    function editBuyerInformation(data) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/buyer-information',
        method: "POST",
        data: data
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [get buyer information]
     * @param {String} params [search text]
     */
    function getBuyerInformation(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/buyer-information',
        method: "GET",
        params: params
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [get individual buyer information by id]
     * @param {Integer} id [buyer information id]
     */
    function getBuyerInformationById(id) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/buyer-information/' + id,
        method: "GET"
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [delete buyer information by id ]
     * @param {Integer} id [buyer information id]
     */
    function deleteBuyerInformationById(id) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/buyer-information/' + id,
        method: "DELETE",
        params: id
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [searchAllPeppolUsers Searchs all peppol users]
     */
    function searchAllPeppolUsers(searchQuery){
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/customer-informaiton/all',
          method: 'GET',
          params: searchQuery
        }).then(function(data){
          deferred.resolve(data);
        }).catch(function(message){
          deferred.reject(message)
        });
        return deferred.promise;
    }
  }
})();
