/**
 * @Author: Jayalekshmi R
 * @Date:   2018-05-29T17:59:19+05:30
 * @Project: Ezyprocure
 * @Filename: buyer.purchase-trends.controller.js
 * @Last modified by:   Shibi
 * @Last modified time: 01-July-2020 10:30
 */


(function () {
    'use strict';

    angular
        .module('ezyprocureApp')
        .controller('BuyerPurchaseTrendsController', BuyerPurchaseTrendsController);

    BuyerPurchaseTrendsController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', '$stateParams', 'toastr', 'pagingParams', 'UTCDateService', 'BusinessReportService', 'paginationConstants', 'ReportService', 'CSVExportService', 'EXPORT_CONSTANTS', 'BuyerBusinessUserMapService', 'GLOBAL_CONSTANTS', 'NotificationService'];

    function BuyerPurchaseTrendsController($scope, $rootScope, $q, $filter, $state, $stateParams, toastr, pagingParams, UTCDateService, BusinessReportService, paginationConstants, ReportService, CSVExportService, EXPORT_CONSTANTS, BuyerBusinessUserMapService, GLOBAL_CONSTANTS, NotificationService) {
        var vm = this;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.todaysDate = new Date();
        vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
        vm.loadAll = loadAll;
        vm.getAllSuppliers = getAllSuppliers;
        vm.query = {
            page: 1
        };
        vm.filter = {
            buyerBusinessIdList: [],
            supplierIdList: []
        };
        vm.selectAll = selectAll;
        vm.deselectAll = deselectAll;
        vm.getPurchaseTrendCSVData = getPurchaseTrendCSVData;
        vm.getPurchaseTrendCSVHeaderMonthly = EXPORT_CONSTANTS.CSV.PurchaseTrendsMonthly;
        vm.getPurchaseTrendCSVHeaderQuarter = EXPORT_CONSTANTS.CSV.PurchaseTrendsQuarter;
        vm.validDateFilter = validDateFilter;
        vm.getMonths = getMonths;
        vm.getOutlets = getOutlets;
        vm.sortByMonth = sortByMonth;
        vm.reportViewTypes = GLOBAL_CONSTANTS.PURCHASE_TREND_VIEW_TYPES;
        vm.selected = 'Quarter';
        vm.getQuarterSum = getQuarterSum;
        vm.getMonthInSelectedOrder = getMonthInSelectedOrder;
        vm.getYears = getYears;
        vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
        vm.graphDataGenerator = graphDataGenerator;
        vm.getQuarterIndex = getQuarterIndex;
        vm.monthfilterdateRangeDifference = monthfilterdateRangeDifference;
        vm.quarterfilterdateRangeDifference = quarterfilterdateRangeDifference;
        vm.montDiffSameYearDateRange = montDiffSameYearDateRange;
        vm.xAxis = [];
        vm.resetFilters = resetFilters;

        /**
        * [getAllBuyerBusinessOutlets Gets all business outlets of buyer]
        */
        function getAllBuyerBusinessOutlets() {
            if (vm.businessOutlets)
                return false;
            vm.businessOutlets = null;
            BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser().
                then(function (response) {
                    vm.businessOutlets = response;
                    //if only one business outlet set it as default
                    if (vm.businessOutlets.length === 1) {
                        vm.businessId = vm.businessOutlets[0].id;
                    }
                }, function (error) {
                });
        }

        /**
        * [getTopPurchaseItemsCSVData Gets CSV data]
        */
        function getPurchaseTrendCSVData() {
            var deferred = $q.defer();
            if (!vm.purchaseTrendGraphItems) {
                NotificationService.simpleErrorToast({
                    title: 'global.table.noRecordsFound',
                    message: 'alertMessage.SEARCH_AND_SUBMIT'
                });
                return deferred.promise;
            }
            var dateRange = vm.fromDate.getDate() + '/' + (vm.fromDate.getMonth() + 1) + '/' + vm.fromDate.getFullYear() + '-' + vm.toDate.getDate() + '/' + (vm.toDate.getMonth() + 1) + '/' + vm.toDate.getFullYear();
            return CSVExportService.getPurchaseTrendCSV(vm.purchaseTrendGraphItems, dateRange, vm.selected);
        }

        /**
        * [getAllAllocatedSuppliersForBusiness Get all allocated suppliers of buyer business]
        */
        function getAllSuppliers() {
            if (vm.supplierList) {
                return false;
            }
            ReportService.getAllSuppliers({
                mainItemGroupIdList: [],
                itemGroupIdList: []
            }, {
                page: 0,
                size: paginationConstants.maxLimit
            }).then(function (response) {
                vm.supplierList = response.data;
            }, function (error) {
            });
        }

        /**
        * [selectAll Selects all from the list]
        * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, mainItemGroups, itemGroups or glGroups]
        */
        function selectAll(dropdown) {
            switch (dropdown) {
                case 'OUTLETS':
                    vm.filter.buyerBusinessIdList = _.map(vm.businessOutlets, _.property('id'));
                    break;
                case 'SUPPLIERS':
                    vm.filter.supplierIdList = _.map(vm.supplierList, _.property('id'));
                    break;
            }
        }

        /**
        * [deselectAll Deselect all from the list]
        * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, mainItemGroups, itemGroups or glGroups]
        */
        function deselectAll(dropdown) {
            switch (dropdown) {
                case 'OUTLETS':
                    vm.filter.buyerBusinessIdList = [];
                    break;
                case 'SUPPLIERS':
                    vm.filter.supplierIdList = [];
                    break;
            }
        }

        /**
         * [validDateFilter description]
         * @return {[type]} [description]
         */
        function validDateFilter() {
            var validSelection = true;
            var sameMonth = moment(vm.fromDate).isSame(vm.toDate, 'month');
            var diff = moment(vm.toDate).diff(moment(vm.fromDate), 'years');
            if (moment(vm.toDate).isBefore(vm.fromDate)) {
                validSelection = false;
                toastr.error('Please select valid date range.', 'Purchase Trends');
            }
            if (sameMonth === false && diff >= 1) {
                validSelection = false;
                toastr.error('Please select valid date range max to one year.', 'Purchase Trends');
            } else if (diff > 1) {
                validSelection = false;
                toastr.error('Please select valid date range.', 'Purchase Trends');
            }
            return validSelection;
        }
        /**
         * [getMonths- Gets the months]
         */
        function getMonths() {
            vm.getMonthInSelectedOrder();
            vm.monthNames = [];
            for (var i = 0; i < vm.outlets.length; i++) {
                _.map(vm.monthArray, function (month) {
                    vm.monthNames.push(month);
                });
            }
        }

        /**
         * [getOutlets - Gets the outlets]
         */
        function getOutlets() {
            vm.outletnames = [];
            _.map(vm.outlets, function (outlet) {
                _.map(_.times(vm.limit, function () { return (outlet); }), function (each) {
                    vm.outletnames.push(each);
                });
            });
        }

        /**
         * [getMonthInSelectedOrder Arranges the month selected order]
         */
        function getMonthInSelectedOrder() {
            vm.monthArray = [];
            var currentMonth    = moment(vm.fromDate).month();
            var fromdate        = moment(vm.fromDate).month();
            var fromdateYear    = moment(vm.fromDate).year();
            var todateYear      = moment(vm.toDate).year();
            var todate          = moment(vm.toDate).month();
            (fromdate === todate) ? vm.limit = 13 : vm.limit = 12;
            (fromdateYear === todateYear) ? vm.limit += -1 : vm.limit += -1;
            for (var i = 0; i < vm.limit; i++) {
                if (currentMonth < 12) {
                    currentMonth = currentMonth;
                }
                else {
                    currentMonth = 0;
                }
                vm.monthArray.push(vm.months[currentMonth]);
                currentMonth += 1;
            }
        }


        /**
         * [getYears Gets the Year in order]
         */
        function getYears() {
            var start = moment(vm.fromDate).year();
            var month = moment(vm.fromDate).month();
            vm.selectedYears = [];
            for (var i = 0; i < vm.limit; i++) {
                vm.selectedYears.push(start);
                month++;
                if (month == 12) {
                    start++;
                }
            }
            vm.years = [];
            for (var i = 0; i < vm.outlets.length; i++) {
                _.map(vm.selectedYears, function (year) {
                    vm.years.push(year);
                });
            }
        }

        /**
         * [sortByMonth - Sorts the array of month in chronological order]
         * @param {[type]} month
         */
        function sortByMonth(month) {
            var months = GLOBAL_CONSTANTS.MONTHS;
            month.sort(function (a, b) {
                return months.indexOf(a)
                    - months.indexOf(b);
            });
        }

        /**
         * [getQuarterSum -Calculates the qurter amount of the business outlet.]
         */
        function getQuarterSum() {
            vm.quarterSum = [];
            var amountArray = [];
            vm.groupedArray = _.chunk(vm.months, 3);
            _.map(vm.outlets, function (outlet, i) {
                for (var j = 0; j < vm.groupedArray.length; j++) {
                    amountArray = _.map(vm.groupedArray[j], function (month) { return vm.purchaseTrendItems[vm.selectedYears[i]][outlet][month]; });
                    var sum = _.reduce(amountArray, function (sum, n) {
                        return sum + n;
                    }, 0);
                    vm.quarterSum.push(sum);
                }
            });
        }

        /**
        * [loadAll loads all price update history]
        */
        function loadAll() {
            if (!vm.validDateFilter()) {
                return false;
            }
            BusinessReportService.getPurchaseTrends({
                fromDate: !vm.fromDate ? '' : UTCDateService.utcFromDate(vm.fromDate),
                toDate: !vm.toDate ? '' : UTCDateService.utcToDate(vm.toDate),
                buyerBusinessIdList: !vm.filter.buyerBusinessIdList ? [] : vm.filter.buyerBusinessIdList,
                supplierIdList: !vm.filter.supplierIdList ? [] : vm.filter.supplierIdList,
                page: vm.query.page - 1,
            }).then(function (response) {
                vm.purchaseTrendItems = response.data;
                if (Object.keys(vm.purchaseTrendItems).length == 0) {
                    vm.purchaseTrendItems = null;
                    return false
                }
                // Stores the years from the response
                vm.dataYears = Object.keys(vm.purchaseTrendItems);
                // Stores the outlets list from the response
                vm.outlets = Object.keys(vm.purchaseTrendItems[vm.dataYears[0]]);
                // Stores the months from the response
                vm.months = Object.keys(vm.purchaseTrendItems[vm.dataYears[0]][vm.outlets[0]]);
                vm.sortByMonth(vm.months);
                vm.getMonths();
                vm.getOutlets();
                vm.getYears();
                // finds the quarter values for the months
                /* vm.getQuarterSum();
                var chunkedQurter = _.chunk(vm.quarterSum,4);
                vm.quarterVal = [];
                var json = {};
                for(var index=0;index<vm.outlets.length;index++){
                  json[vm.outlets[index]] = chunkedQurter[index];
                }
                vm.quarterVal.push(json);
               */
                vm.totalItems = response.headers('X-Total-Count');
                vm.outletArray = vm.outlets; // ["outlet1", "outlet2"]
                vm.purchaseTrendGraphItems = {};
                vm.purchaseTrendGraphData = {};
                vm.currentYear = moment(vm.fromDate).year();//2020;
                vm.toYear = moment(vm.toDate).year(); // 2021;
                vm.currentMonthIndex = moment(vm.fromDate).month(); //5; // june
                vm.toMonthIndex = moment(vm.toDate).month(); // 4; // may
                // vm.currentQuarterIndex = vm.getQuarterIndex(vm.currentMonthIndex);
                // vm.toQuarterIndex = vm.getQuarterIndex(vm.toMonthIndex);
                var monthValue = {};
                vm.outletArray.forEach(function (outletname) {
                    vm.purchaseTrendGraphItems[outletname] = {};
                    vm.dataYears.forEach(function (year) {
                        vm.purchaseTrendGraphItems[outletname][year] = [];
                        vm.monthArray.forEach(function (month, key) {
                            if ((vm.currentMonthIndex == vm.toMonthIndex) && (vm.currentYear == vm.toYear)) {
                                vm.purchaseTrendGraphItems[outletname][year] = [];
                                monthValue = { 'month': month, 'value': vm.purchaseTrendItems[year][outletname][month] };
                            }
                            else {
                                monthValue = { 'month': month, 'value': vm.purchaseTrendItems[year][outletname][month] };
                            }
                            vm.purchaseTrendGraphItems[outletname][year].push(monthValue);
                        })
                    })
                })
                if (vm.currentYear != vm.toYear) {
                    vm.purchaseTrendGraphItems = vm.monthfilterdateRangeDifference();
                }
                if ((vm.currentMonthIndex != vm.toMonthIndex) && (vm.currentYear == vm.toYear)) {
                    vm.purchaseTrendGraphItems = vm.montDiffSameYearDateRange();
                }
                if (vm.selected == "Quarter") {
                    vm.purchaseTrendGraphItems = vm.quarterfilterdateRangeDifference();
                    vm.quarterVal = vm.purchaseTrendGraphItems;
                }
                vm.options = {
                    chart: {
                        type: 'lineChart',
                        height: 550,
                        margin: {
                            top: 20,
                            right: 20,
                            bottom: 40,
                            left: 80
                        },
                        x: function (d) { return d.x; },
                        y: function (d) { return d.y; },
                        xAxis: {
                            axisLabel: $filter('translate')('global.table.' + vm.selected),
                            showMaxMin: false,
                            tickFormat: function (d) {
                                return vm.xAxis[d];
                            }
                        },
                        yAxis: {
                            axisLabel: $filter('translate')('global.table.value'),
                            axisLabelDistance: -20,
                            tickFormat: function (d) {
                                return d3.format('.2f')(d);
                            }
                        }
                    },
                    title: {
                        enable: true,
                        text: $filter('translate')('global.navbar.purchaseTrends')
                    }
                };
                vm.xAxis = [];
                vm.data = graphDataGenerator();

            }, function (error) {
                NotificationService.error({
                    title: 'Fetching Purchase trends',
                    error: error
                });
            });
        }


        function monthfilterdateRangeDifference() {
            var monthValue1 = {};
            var nextI = 0;
            _.map(vm.purchaseTrendGraphItems, function (outletList, mainkey) {
                vm.purchaseTrendGraphData[mainkey] = {};
                _.map(outletList, function (yearloop, key) {
                    // vm.purchaseTrendGraphData[mainkey][key] = [];
                    if (!vm.purchaseTrendGraphData[mainkey][key]) {
                        vm.purchaseTrendGraphData[mainkey][key] = [];
                    }
                    if (key < vm.toYear) {
                        for (var i = 0; i < yearloop.length; i++) {
                            if (yearloop[i].month != "December") {
                                monthValue1 = { 'month': yearloop[i].month, 'value': yearloop[i].value };
                                vm.purchaseTrendGraphData[mainkey][key].push(monthValue1);
                            }
                            else {
                                if (yearloop[i].month == "December") {
                                    monthValue1 = { 'month': yearloop[i].month, 'value': yearloop[i].value };
                                    vm.purchaseTrendGraphData[mainkey][key].push(monthValue1);
                                }
                                nextI = i + 1;
                                break;
                            }
                        }

                    }
                    else {
                        for (var i = nextI; i < yearloop.length; i++) {
                            monthValue1 = { 'month': yearloop[i].month, 'value': yearloop[i].value };
                            vm.purchaseTrendGraphData[mainkey][key].push(monthValue1);
                        }
                    }
                })
            })
            return vm.purchaseTrendGraphData;
        }

        function quarterfilterdateRangeDifference() {
            var monthValue1 = {};
            var nextI = 0;
            var pYear = vm.currentYear;
            var pQuarterI = vm.currentQuarterIndex;
            var pId = 1;
            _.map(vm.purchaseTrendGraphItems, function (outletList, mainkey) {
                vm.purchaseTrendGraphData[mainkey] = {};
                _.map(outletList, function (yearloop, key) {
                    if (!vm.purchaseTrendGraphData[mainkey][key]) {
                        vm.purchaseTrendGraphData[mainkey][key] = [];
                    }
                    for (var i = 0; i < yearloop.length; i++) {
                        var newmonthValue = yearloop[i].month;
                        var quartermonthIndex = vm.getQuarterIndex(GLOBAL_CONSTANTS.MONTHS.indexOf(newmonthValue));
                        var quartermonthValue = GLOBAL_CONSTANTS.QUARTERS[quartermonthIndex];

                        monthValue1 = { 'q': quartermonthValue, 'value': yearloop[i].value };
                        var existingQuarter = vm.purchaseTrendGraphData[mainkey][key].find(function (qval) {
                            return qval.q == quartermonthValue;
                        })
                        if (existingQuarter) {
                            existingQuarter.value += yearloop[i].value;
                        }
                        else {
                            vm.purchaseTrendGraphData[mainkey][key].push(monthValue1);
                        }
                    }

                })

            })
            return vm.purchaseTrendGraphData;
        }

        function montDiffSameYearDateRange(){
            var monthValue1 = {};
            var nextI = 0;
            _.map(vm.purchaseTrendGraphItems, function (outletList, mainkey) {
                vm.purchaseTrendGraphData[mainkey] = {};
                _.map(outletList, function (yearloop, key) {
                    // vm.purchaseTrendGraphData[mainkey][key] = [];
                    if (!vm.purchaseTrendGraphData[mainkey][key]) {
                        vm.purchaseTrendGraphData[mainkey][key] = [];
                    }
                    if (key <= vm.toYear) {
                        for (var i = 0; i < yearloop.length; i++) {
                            if (yearloop[i].month != "December") {
                                monthValue1 = { 'month': yearloop[i].month, 'value': yearloop[i].value };
                                vm.purchaseTrendGraphData[mainkey][key].push(monthValue1);
                            }
                            else {
                                if (yearloop[i].month == "December") {
                                    monthValue1 = { 'month': yearloop[i].month, 'value': yearloop[i].value };
                                    vm.purchaseTrendGraphData[mainkey][key].push(monthValue1);
                                }
                                nextI = i + 1;
                                break;
                            }
                        }

                    }
                    else {
                        for (var i = nextI; i < yearloop.length; i++) {
                            monthValue1 = { 'month': yearloop[i].month, 'value': yearloop[i].value };
                            vm.purchaseTrendGraphData[mainkey][key].push(monthValue1);
                        }
                    }
                })
            })
            return vm.purchaseTrendGraphData;
        }

        function getQuarterIndex(monthIndex) {
            if (monthIndex < 3)
                return 0;
            else if (monthIndex < 6)
                return 1;
            else if (monthIndex < 9)
                return 2;
            else
                return 3;
        }

        function graphDataGenerator() {
            var graphData = [];
            vm.outletArray.forEach(function (outlet) {
                var outletLine = {
                    values: coordinatesGenerator(vm.purchaseTrendGraphItems[outlet]),
                    key: outlet
                };
                graphData.push(outletLine);
            });
            return graphData;
        }

        function coordinatesGenerator(outletData) {
            var years = Object.keys(outletData);
            var coordinates = [];
            var x = 0;
            years.forEach(function (year) {
                var monthsOrQuaters = outletData[year];
                monthsOrQuaters.forEach(function (data) {
                    if (vm.selected == 'Month') {
                        var coordinate = { x: x, y: data.value };
                        coordinates.push(coordinate);
                        vm.xAxis.push(data.month + " " + year);
                        // if (vm.xAxis.length <= monthsOrQuaters.length * years.length) {
                        //     vm.xAxis.push(data.month + " " + year);
                        // }
                    }
                    else {
                        var coordinate = { x: x, y: data.value };
                        coordinates.push(coordinate);
                        vm.xAxis.push(data.q + " " + year);
                        // if (vm.xAxis.length <= monthsOrQuaters.length * years.length) {
                        //     vm.xAxis.push(data.q + " " + year);
                        // }
                    }
                    ++x;
                });
            });
            return coordinates;
        }
        function resetFilters() {
            vm.filter = {}
            vm.fromDate = vm.todaysDate;
            vm.toDate = vm.todaysDate;
            vm.selected = 'Quarter';
            vm.purchaseTrendItems = null;
        }
    }
})();
