(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .factory('Password', Password);

  Password.$inject = ['$resource', 'API_CONSTANTS'];

  function Password($resource, API_CONSTANTS) {
    //change password
    var service = $resource('api/' + API_CONSTANTS.baseVersion + 'account/password', {}, {});

    return service;
  }
})();
