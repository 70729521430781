/**
 * @Author: sneha
 * @Date:   15-Mar-2018 17:05 +05:30
 * @Project: Ezyprocure
 * @Filename: supplier.stock-report.state.js
 * @Last modified by:   sneha
 * @Last modified time: 06-Apr-2018 09:40 +05:30
 */




 (function() {
   'use strict';

   angular
   .module('ezyprocureApp')
   .config(stateConfig);

   stateConfig.$inject = ['$stateProvider'];

   function stateConfig($stateProvider) {
     $stateProvider
     .state('supplier.reports.stock-report', {
       parent: 'supplier.reports',
       url: '/stock-report',
       data: {
         authorities: ['ROLE_SUPPLIER'],
       },
       views: {
         'content@': {
           templateUrl: 'app/supplier-modules/reports/stock-report/supplier.stock-report.html',
           controller: 'SupplierStockReportController',
           controllerAs: 'vm'
         }
       },
       params: {
         page: {
           value: '1',
           squash: true
         },
         sort: {
           value: 'id,desc',
           squash: true
         },
         search: null
       },
       resolve: {
         pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
           return {
             page: PaginationUtil.parsePage($stateParams.page),
             sort: $stateParams.sort,
             predicate: PaginationUtil.parsePredicate($stateParams.sort),
             ascending: PaginationUtil.parseAscending($stateParams.sort),
             search: $stateParams.search
           };
         }],
         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
           $translatePartialLoader.addPart('global');
           $translatePartialLoader.addPart('supplier');
           $translatePartialLoader.addPart('items');
           return $translate.refresh();
         }]
       }
     })
   }
 })();
