/**
 * @Author: Ajay
 * @Date:   13-Apr-2017 12:23 +05:30
 * @Filename: purchase-order.service.js
 * @Last modified by:   Lovin
 * @Last modified time: 2020-05-26T09:21:57+05:30
 */



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('PurchaseOrderService', PurchaseOrderService);

  PurchaseOrderService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS', '$localStorage'];
  function PurchaseOrderService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS, $localStorage) {

    var purchaseOrderService = {
      getAllPurchaseOrders : getAllPurchaseOrders,
      getAllPurchaseRequests: getAllPurchaseRequests,
      getAllPurchaseOrdersForAdmin : getAllPurchaseOrdersForAdmin,
      getPurchaseOrderByPurchaseOrderNumber: getPurchaseOrderByPurchaseOrderNumber,
      getPurchaseOrderByPaymentId: getPurchaseOrderByPaymentId,
      getConsolidatedPOMetaData: getConsolidatedPOMetaData,
      getConsolidatedPODetails: getConsolidatedPODetails,
      getConsolidatedPRDetails : getConsolidatedPRDetails,
      getGRNDetails: getGRNDetails,
      getGRNByPONumber: getGRNByPONumber,
      getPurchaseOrderByPurchaseOrderId: getPurchaseOrderByPurchaseOrderId,
      processGoodReturn: processGoodReturn,
      getDetailedDataOfPurchaseOrders: getDetailedDataOfPurchaseOrders,
      setUpdateWeight_grn: setUpdateWeight_grn,
      getGRNByPONumberAll: getGRNByPONumberAll,
      updateGeoExported: updateGeoExported,
      getAllItemPrice : getAllItemPrice,
      getCalculatedPOItem : getCalculatedPOItem,
      createPurchaseRequestCsv : createPurchaseRequestCsv,
      createSalesOrderCsv: createSalesOrderCsv,
      getPOExportListing: getPOExportListing,
      updateAccPacExported: updateAccPacExported,
      updateMyobExported: updateMyobExported,
      getPOJurnalExportListing: getPOJurnalExportListing,
      setPurchaseOrderRequestParams:setPurchaseOrderRequestParams,
      getPurchaseOrderRequestParams:getPurchaseOrderRequestParams,
      getConsolidatedGrnMetaData : getConsolidatedGrnMetaData,
      getReorderLevel : getReorderLevel,
      getAllLocations: getAllLocations,
      exportToEzyfinance: exportToEzyfinance
    };
    var productList = [];
    return purchaseOrderService;

     /**
     * [exportToEzyfinance export to ezyfinance]
     * @return {[object]}        [success/error message]
     */
    function exportToEzyfinance(poDto){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.ezyfinanceUrl + 'api/' + 'push/po',
        method: "POST",
        headers:{
          'externalApi':'EZPSG'
        },
        data:poDto,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getAllPurchaseOrders gets PO list]
     * @param  {[type]} params [filter params]
     * @return {[object]}        [success/error message]
     */
    function getAllPurchaseOrders(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE,
        method: "GET",
        params:params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getReorderLevel(){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.ezyinventoryUrl + 'api/' + API_CONSTANTS.baseVersion + 'dashboard-reorderlist',
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getAllLocations() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.ezyinventoryUrl + 'api/' + API_CONSTANTS.baseVersion + 'all-locations',
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getAllPurchaseRequests(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'purchase-requests',
        method: "GET",
        params: params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getAllItemPrice(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + '/item-price',
        method: "GET",
        params:params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getAllPurchaseOrdersForAdmin gets PO list]
     * @param  {[type]} params [filter params]
     * @return {[object]}        [success/error message]
     */
    function getAllPurchaseOrdersForAdmin(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE +'all',
        method: "GET",
        params:params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getPurchaseOrderByPurchaseOrderNumber get PurchaseOrder details by PurchaseOrder number]
     * @param  {[number]} purchaseOrderNumber [Purchase Order Number]
     * @return {[object]}          [success/error message]
     */
    function getPurchaseOrderByPurchaseOrderNumber(purchaseOrderNumber,params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion +RESOURCE_URL_CONSTANTS.PROCESS_ORDER_RESOURCE + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE +'details/'+purchaseOrderNumber,
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getPurchaseOrderByPaymentId get PaymentId details by PaymentId number]
     * @param  {[number]} paymentId [PaymentId Number]
     * @return {[object]}          [success/error message]
     */
     function getPurchaseOrderByPaymentId(paymentId,params) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/payment/transaction-summary-report/credit-note-detail/'+paymentId,
          method: "GET",
          params: params,
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

    /**
     * [getConsolidatedPOMetaData gets consolidated PO Details]
     * @param  {[type]} params [filter params]
     * @return {[object]}        [success/error message]
     */
    function getConsolidatedPOMetaData(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE +'consolidated-view/metadata',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getConsolidatedPODetails gets consolidated PO Details]
     * @param  {[type]} params [filter params]
     * @return {[object]}        [success/error message]
     */
    function getConsolidatedPODetails(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE +'consolidated-view',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getConsolidatedPRDetails gets consolidated PR Details]
     * @param  {[type]} params [filter params]
     * @return {[object]}        [success/error message]
     */
    function getConsolidatedPRDetails(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'purchase-request/consolidated-view',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getGRNDetails get GRN details]
     * @param  {[string]} params [filter params]
     * @return {[string]}        [message]
     */
    function getGRNDetails(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE +'good-receipt-status',
        method: "GET",
        params:params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getGRNByPONumber gets GRN by purchaseOrder Number]
     * @param  {[number]} purchaseOrderNumber [PO Number]
     * @return {[string]}          [message]
     */
    function getGRNByPONumber(purchaseOrderNumber, params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE +'good-receipt-status/'+purchaseOrderNumber,
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getPurchaseOrderByPurchaseOrderNumber get PurchaseOrder details by PurchaseOrder number]
     * @param  {[number]} purchaseOrderNumber [Purchase Order Number]
     * @return {[object]}          [success/error message]
     */
    function getPurchaseOrderByPurchaseOrderId(purchaseOrderId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE + purchaseOrderId,
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function processGoodReturn(returnOrderDTO) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'goods-return-notices', returnOrderDTO).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getDetailedDataOfPurchaseOrders gets PO list with detailed data]
     * @param  {[type]} params [filter params]
     * @return {[object]}        [success/error message]
     */
    function getDetailedDataOfPurchaseOrders(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'invoices/batch',
        method: "GET",
        params:params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [setUpdateWeight_grn Save Good receiving Orders]
    * @return {[string]}                [Success and error messages]
    */
    function setUpdateWeight_grn(goodReceiveDTO) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE + 'good-receipt-status/weightUpdate', goodReceiveDTO).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getGRNByPONumber gets GRN by purchaseOrder Number]
     * @param  {[number]} purchaseOrderNumber [PO Number]
     * @return {[string]}          [message]
     */
    function getGRNByPONumberAll(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE +'good-receipt-status-all',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function updateAccPacExported(purchaseOrderIdList) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE +'acc-pac-exported',
        method: "PUT",
        data: purchaseOrderIdList
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }


    function updateGeoExported(purchaseOrderIdList) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE +'geo-exported',
        method: "PUT",
        data: purchaseOrderIdList
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function updateMyobExported(purchaseOrderIdList) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE +'myob-exported',
        method: "PUT",
        data: purchaseOrderIdList
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getCalculatedPOItem(item) {
      //Skip calculations for free item
      if(!item.freeItem) {
        if(item.uom.weightRequired) {

          if(item.uom.moqPrice === true){
            item.poAmount = Math.round(((item.weight/item.moq) * item.price)*1000)/1000;
            item.acceptAmount =  Math.round(((item.acceptedWeight/item.moq)*item.price)*1000)/1000;
          }
          else
          {
            item.poAmount = Math.round((item.weight * item.price)*1000)/1000;
            item.acceptAmount = Math.round((item.acceptedWeight*item.price)*1000)/1000;
          }
        }else {
          if(item.uom.moqPrice === true){
            item.poAmount = Math.round(((item.quantity/item.moq) * item.price)*1000)/1000;
            item.acceptAmount = Math.round(((item.acceptedQuantity/item.moq)*item.price)*1000)/1000;
          }
          else
          {
            item.poAmount =  Math.round((item.quantity * item.price)*1000)/1000;
            item.acceptAmount = Math.round((item.acceptedQuantity*item.price)*1000)/1000;
          }
        }
      }
      return item;
    }


     /**
     * [Creates purchase Request from CSV]
     */
    function createPurchaseRequestCsv(csvData) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'cart/upload', csvData).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [Creates purchase Request from CSV]
    */
   function createSalesOrderCsv(csvData) {
     var deferred = $q.defer();
     $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'sales-order/upload', csvData).then(function(data) {
       deferred.resolve(data);
     }).catch(function(message) {
       deferred.reject(message);
     });
     return deferred.promise;
   }

   function getPOExportListing(params, poListingDTO, listingType){
     var deferred = $q.defer();
     $http({
       url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE +'csv-export/'+listingType,
       method: "POST",
       params: params,
       data: poListingDTO,
     }).then(function(data) {
       deferred.resolve(data);
     }).catch(function(message) {
       deferred.reject(message);
     });
     return deferred.promise;
   }

   function getPOJurnalExportListing(params, poListingDTO){
     var deferred = $q.defer();
     $http({
       url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE +'jurnal-export',
       method: "POST",
       params: params,
       data: poListingDTO,
     }).then(function(data) {
       deferred.resolve(data);
     }).catch(function(message) {
       deferred.reject(message);
     });
     return deferred.promise;
   }
  //set an object in local memory to use in consolidated view api
   function setPurchaseOrderRequestParams(purchaseOrderObject){
    $localStorage["purchase-order-info"] = angular.toJson(purchaseOrderObject);
    return true
   };

   //gets the object from local memory for consolidated view
   function getPurchaseOrderRequestParams(){
    return angular.fromJson($localStorage["purchase-order-info"]);
   };

   /**
    *[Get the grn consolidated grand total]
    * @param {Object} grnDetails
    */
   function getConsolidatedGrnMetaData(grnDetails) {
    var deferred = $q.defer();
    $http({
      url: API_CONSTANTS.serviceBase + 'api/good-receipt-status/consolidated-view/metadata',
      method: "POST",
      data: grnDetails,
    }).then(function(data) {
      deferred.resolve(data);
    }).catch(function(message) {
      deferred.reject(message);
    });
    return deferred.promise;
  }
 }
})();
