/**
* @Author: Ajay
* @Date:   17-Jan-2017 17:22 +05:30
* @Project: Ezyprocure
* @Filename: admin.uom.service.js
 * @Last modified by:   sneha
 * @Last modified time: 2018-09-04T12:28:45+05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('UomService', UomService);

  UomService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function UomService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var uomService = {
      getAllUoms: getAllUoms,
      getUom: getUom,
      createUom: createUom,
      updateUom: updateUom,
      deleteUom: deleteUom,
      getQueryBasedUoms: getQueryBasedUoms
    };

    return uomService;

    /* --  Uom CRUID operations  -- */

    //Get All  uoms
    function getAllUoms(urlParams) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.UOM_RESOURCE,
        method: "GET",
        params: urlParams,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get uom details using uom id
    function getUom(uomId) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.UOM_RESOURCE + uomId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Create a new  uom
    function createUom(uom) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.UOM_RESOURCE, uom).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update  uom
    function updateUom(uom) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.UOM_RESOURCE, uom).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Soft Delete  uom using uomId
    function deleteUom(uomId) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.UOM_RESOURCE + uomId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get uom list using supplier ids and item-group ids
    function getQueryBasedUoms(data) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.UOM_RESOURCE + 'supplier-item-group',
        method: "POST",
        data: data
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
