/**
 * @Author: Lovin
 * @Date:   2018-07-05T16:36:43+05:30
 * @Project: Ezyprocure
 * @Filename: buyer.place-your-order.controller.js
 * @Last modified by:   Lovin
 * @Last modified time: 2018-07-05T16:36:56+05:30
 */



  (function () {
      'use strict';

      angular
        .module('ezyprocureApp')
        .controller('PlaceYourOrderCsvUploadController', PlaceYourOrderCsvUploadController);

      PlaceYourOrderCsvUploadController.$inject = ['$scope', '$q', '$rootScope', '$state', '$stateParams', 'toastr', 'CsvUploadService', 'BuyerDataService', 'NotificationService',  'CSVExportService','UtilService', 'PurchaseOrderService', 'S3_URL_CONSTANTS', 'EXPORT_CONSTANTS'];

      function PlaceYourOrderCsvUploadController($scope, $q, $rootScope, $state, $stateParams, toastr, CsvUploadService, BuyerDataService, NotificationService, CSVExportService, UtilService, PurchaseOrderService, S3_URL_CONSTANTS, EXPORT_CONSTANTS) {
        var vm = this;

        vm.init = init;
        vm.getCSVBase64 = getCSVBase64;
        vm.purchaseRequestUploadCSV = purchaseRequestUploadCSV;
        vm.itemUploadCSV = itemUploadCSV;
        vm.resetValues = resetValues;
        vm.PURCHASE_REQUEST_UPLOAD_CSV = S3_URL_CONSTANTS.PURCHASE_REQUEST_UPLOAD_CSV;
        vm.getExportReorderLevelReport = getExportReorderLevelReport;
        vm.accessControls = BuyerDataService.getBuyerProfile().accessDTO;
        vm.exportReorderLevel = EXPORT_CONSTANTS.CSV.ReorderLevelReport;


        function init() {
          vm.csvUploadData = {};
        }

        function getExportReorderLevelReport(){
          var deferred = $q.defer();
          PurchaseOrderService.getReorderLevel().then(function(response){
            vm.reorderLevel = response.data;
            var csvData = CSVExportService.getExportReorderLevelReport(vm.reorderLevel);
           return deferred.resolve(csvData);
          },
          function(error){
            NotificationService.error({
              title: 'Export Reorder Level Report',
              error: error
            });
          });
          return deferred.promise;
        }

        function getCSVBase64() {
          vm.errorList = null;
          var csvFile = vm.files[0];
          if (!csvFile) {
            toastr.error('Please upload a .csv file!', 'CSV File Required');
            return false;
          }
          CsvUploadService.getFileBase64(csvFile.lfFile, function (base64CSV) {
            vm.csvUploadData = base64CSV;
            vm.purchaseRequestUploadCSV(vm.csvUploadData);
          });
        }

        function purchaseRequestUploadCSV(uploadData) {
          PurchaseOrderService.createPurchaseRequestCsv(uploadData)
            .then(function (response) {
              vm.files = [];
              NotificationService.success({
                title: 'global.navbar.purchaseRequest',
                message: 'alertMessage.UPLOADED_SUCCESSFULLY'
              });
              $state.go('buyer.cart-checkout', {
                reload: false
              });
            }, function (error) {
              vm.recordError = error.data;
              var message = error.data.message;
              NotificationService.simpleErrorToast({
                title: 'Cannot Place Order',
                message:  message
              });
            });
        }

        function itemUploadCSV() {
          var csvFile = vm.files[0]
          vm.isCsvFile = CsvUploadService.checkFileType(csvFile.lfFile);
          if (vm.isCsvFile) {
            UtilService.csvToJson(csvFile.lfDataUrl)
            .then(function (response) {
              vm.poItems = response;
            })
           }
        }

        function resetValues(){
          vm.poItems = [];
          vm.recordError = [];
        }
    }

})();
