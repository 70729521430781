/**
* @Author: Abhay
* @Date:   09-Feb-2021 17:35 +05:30
* @Project: Ezyprocure
* @Filename: admin.release-notes-master.service.js
* @Last modified by:   Abhay
* @Last modified time: 09-Feb-2021 17:35 +05:30
*/


(function () {
    'use strict';

    angular.module('ezyprocureApp').factory('ReleaseNotesMasterService', ReleaseNotesMasterService);

    ReleaseNotesMasterService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

    function ReleaseNotesMasterService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

        var releaseNotesMasterService = {
            getAllReleaseNotes: getAllReleaseNotes,
            getActiveReleaseNotes: getActiveReleaseNotes,
            submitNewReleaseNote: submitNewReleaseNote,
            addReleaseNotes: addReleaseNotes,
            deleteReleaseNotes: deleteReleaseNotes,
            getAllReleaseNotesType:getAllReleaseNotesType
        };

        return releaseNotesMasterService;

        function getAllReleaseNotes(params) {
            var deferred = $q.defer();
            $http({
                url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.RELEASE_NOTES,
                method: "GET",
                params: params,
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (message) {
                deferred.reject(message);
            });
            return deferred.promise;
        }

        function getActiveReleaseNotes(params) {
            var deferred = $q.defer();
            $http({
                url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.RELEASE_NOTES + 'active/',
                method: "GET",
                params: params,
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (message) {
                deferred.reject(message);
            });
            return deferred.promise;
        }

        function submitNewReleaseNote(releaseId,isActive) {
            var deferred = $q.defer();
            $http({
                url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.RELEASE_NOTES + 'active/',
                method: "POST",
                params: {"releaseId" : releaseId,"isActive":isActive},
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (message) {
                deferred.reject(message);
            });
            return deferred.promise;
        }

        function addReleaseNotes(data) {
            var deferred = $q.defer();
            $http({
                url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.RELEASE_NOTES,
                method: "POST",
                data: data
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (message) {
                deferred.reject(message);
            });
            return deferred.promise;
        }

        function deleteReleaseNotes(releaseId) {
            var deferred = $q.defer();
            $http({
                url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.RELEASE_NOTES,
                method: "DELETE",
                params: {"releaseId" : releaseId}
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (message) {
                deferred.reject(message);
            });
            return deferred.promise;
        }


        function getAllReleaseNotesType() {
            var deferred = $q.defer();
            $http({
                url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.RELEASE_NOTES,
                method: "GET",
                params: {type:"RELEASE_NOTE"},
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (message) {
                deferred.reject(message);
            });
            return deferred.promise;
        }
    }
})();
