/*
 * @Author: Ajay 
 * @Date: 2019-03-28 09:01:57 
 * @Project: Ezyprocure
 * @Filename: ezyap.ezycompany.service.js
 * @Last Modified by: Lovin C
 * @Last Modified time: 2021-09-27 15:18:36
 */

(function () {
  'use strict';

  angular.module('ezyprocureApp').factory('EzyCompanyService', EzyCompanyService);

  EzyCompanyService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  function EzyCompanyService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {
    var EzyCompanyService = {
      updateIdentifier: updateIdentifier,
      getIdentifier: getIdentifier,
      createIdentifier: createIdentifier,
      getBuyerBusinessIdentifier : getBuyerBusinessIdentifier,
      createBuyerBusinessIdentifier : createBuyerBusinessIdentifier,
      updateBuyerBusinessIdentifier : updateBuyerBusinessIdentifier,
      deleteIsoCodeAndIdentfier: deleteIsoCodeAndIdentfier,
      initiateSMPRegistration: initiateSMPRegistration
    }
    return EzyCompanyService;

    /**
     * [Update the EzyCompany details]
     * @param {Object} ezyCompanyDetails [details for ezyCompany]
     */
    function updateIdentifier(ezyCompanyDetails) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api'+ RESOURCE_URL_CONSTANTS.EZY_COMPANY_DETAILS_RESOURCE,
        method: "POST",
        data: ezyCompanyDetails
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [deleteIsoCodeAndIdentfier delete iso code and identifier]
     */    
    function deleteIsoCodeAndIdentfier(id) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/ezy-company-details/' + id).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    
    /**
     * [get EzyCompany Details]
     * @param {Integer} companyId [Id of EzyCompany]
     * @param {String} userRole [Role of the user]
     */
    function getIdentifier(companyId, userRole) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api'+ RESOURCE_URL_CONSTANTS.EZY_COMPANY_DETAILS_RESOURCE + '/' + companyId + '/' + userRole,
        method: "GET"
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    
    /**
     * [Add the details for EzyCompany]
     * @param {Object} ezyCompanyDetails [details for ezyCompany]
     */
    function createIdentifier(ezyCompanyDetails) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api' + RESOURCE_URL_CONSTANTS.EZY_COMPANY_DETAILS_RESOURCE,
        method: "PUT",
        data: ezyCompanyDetails
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    
    /**
     * [createBuyerBusinessIdentifier Creates the identifier based on the buyer and businessoutlets]
     * @param {Object} identifierDetails [details for ezyCompany]
     */
    function createBuyerBusinessIdentifier(identifierDetails) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api' + RESOURCE_URL_CONSTANTS.BUSINESS_KEY_MAPPING_RESOURCE,
        method: "POST",
        data: identifierDetails
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [updateBuyerBusinessIdentifier Updates the identifier based on the buyer and businessoutlets]
     * @param {Object} identifierDetails [details for ezyCompany]
     */
    function updateBuyerBusinessIdentifier(identifierDetails) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api' + RESOURCE_URL_CONSTANTS.BUSINESS_KEY_MAPPING_RESOURCE,
        method: "PUT",
        data: identifierDetails
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getBuyerBusinessIdentifier gets the identifier based on the buyer and businessoutlets]
     * @param {Integer} businessId 
     * @param {Integer} buyerId 
     */
    function getBuyerBusinessIdentifier(buyerId, businessId){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api'+ RESOURCE_URL_CONSTANTS.BUSINESS_KEY_MAPPING_RESOURCE + '/' + businessId + '/' + buyerId,
        method: "GET"
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    
    function initiateSMPRegistration(data){
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/' + API_CONSTANTS.baseVersion + 'smp/participants', data).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    
  }
})();
