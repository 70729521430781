/**
* @Author: Abhay
* @Date:   12-Jan-2021 16:03 +05:30
* @Project: Ezyprocure
* @Filename: release-notes.controller.js
 * @Last modified by:   Abhay
 * @Last modified time: 12-Jan-2021 16:03 +05:30
*/



(function() {
    'use strict';

    angular
    .module('ezyprocureApp')
    .controller('ReleaseNotesController', ReleaseNotesController);

    ReleaseNotesController.$inject = ['$stateParams', '$scope', '$timeout', '$state', 'Auth', 'ReleaseNotesMasterService', 'ManageBuyerService', 'ManageSupplierService', 'AdminManageLimitsService', 'NotificationService'];

    function ReleaseNotesController($stateParams, $scope, $timeout, $state, Auth, ReleaseNotesMasterService, ManageBuyerService, ManageSupplierService, AdminManageLimitsService, NotificationService) {

      var vm = this;
      vm.userRole = Auth.getUserRole();
      vm.releaseList = [];
      vm.getReleaseNotesList = getReleaseNotesList;

      getReleaseNotesList();

      function getReleaseNotesList() {
        // ReleaseNotesMasterService.getAllReleaseNotes().then(function (response) {
        //   vm.releaseList = response.data;
        // },
        //   function (error) {

        //   });
        ReleaseNotesMasterService.getAllReleaseNotesType().then(function (response) {
            vm.releaseList = response.data;
          },
            function (error) {

            });
      }



    }
  })();
