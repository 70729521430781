/**
* @Author: Ajay
* @Date:   27-Apr-2017 12:48 +05:30
* @Project: Ezyprocure
* @Filename: supplier.adhoc-invoice.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 24-Apr-2018 12:16 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierAdhocInvoiceController', SupplierAdhocInvoiceController);

  SupplierAdhocInvoiceController.$inject = ['$scope', '$state', '$element', 'toastr', 'SupplierDataService', 'BuyerSupplierMappingService', 'BuyerBusinessService', 'SupplierAdhocInvoiceService', 'paginationConstants'];

  function SupplierAdhocInvoiceController($scope, $state, $element, toastr, SupplierDataService, BuyerSupplierMappingService, BuyerBusinessService, SupplierAdhocInvoiceService, paginationConstants) {
    var vm = this;
    vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
    vm.getAllBuyersBusinessOutlets = getAllBuyersBusinessOutlets;
    vm.adhocInvoiceFilter = {
      itemType : 'BUYER'
    };
    vm.proceedToAdhocInvoice = proceedToAdhocInvoice;

    //Get all buyers mapped to the supplier
    function getAllSupplierMappedBuyers() {
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
      };
      if(vm.buyers)
        return false;
      var supplierId = SupplierDataService.getSupplierProfile().supplier.id;
      BuyerSupplierMappingService.getAllSupplierMappedBuyers(supplierId, params).then(function(response) {
        vm.buyers = response.data;
        vm.adhocInvoiceFilter.selectedBuyer = {};
      }, function(error) {
      });
    }

    //get all business outlets of the selected buyer
    function getAllBuyersBusinessOutlets() {
      vm.adhocInvoiceFilter.buyerIdList = [];
      if (!vm.adhocInvoiceFilter.selectedBuyer) {
        return false;
      }
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        groupBy: 'buyer'
      };
      vm.adhocInvoiceFilter.buyerIdList[0] = vm.adhocInvoiceFilter.selectedBuyer.id;
      BuyerBusinessService.getAllBuyersBusinessOutlets(vm.adhocInvoiceFilter.buyerIdList, params).then(function(response) {
        vm.buyerBusinessOutlets = response.data;
        vm.adhocInvoiceFilter.selectedBusiness = {};
      }, function(error) {
      });
    }


    /**
     * [proceedToAdhocInvoice Proceed to item selection page]
     */
    function proceedToAdhocInvoice() {
      vm.adhocInvoiceFilter.businessIdList = [];
      if(vm.adhocInvoiceFilter.itemType === 'BUYER') {
        vm.adhocInvoiceFilter.businessIdList[0] = vm.adhocInvoiceFilter.selectedBusiness.businessId;
      }
      //Save adhoc invoice filter
      SupplierAdhocInvoiceService.setAdhocInvoiceFilter(vm.adhocInvoiceFilter);
      $state.go('supplier.tools.adhoc-invoice.item-selection');
    }

  }
})();
