/**
* @Author: Mufsir
* @Date:   14-Dec-2016 12:54 +05:30
* @Project: Ezyprocure
* @Filename: reset.request.controller.js
* @Last modified by:
* @Last modified time: 09-May-2017 21:00 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('RequestResetController', RequestResetController);

  RequestResetController.$inject = ['$timeout', 'Auth'];

  function RequestResetController($timeout, Auth) {
    var vm = this;
    vm.error = null;
    vm.requestReset = requestReset;
    vm.resetAccount = {};
    vm.success = false;

    function requestReset() {
      vm.error = null;
      vm.errorData = null;
      Auth.resetPasswordInit(vm.resetAccount.username)
      .then(function(response) {
        vm.success = true;
      }).catch(function(error) {
        vm.success = false;
        vm.errorData = error.headers('X-ezyprocureApp-error');
      });
    }
  }
})();
