(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('BuyerSupplierEditController', BuyerSupplierEditController);
  BuyerSupplierEditController.$inject = ['$scope', '$rootScope', '$translate', '$state', '$stateParams', '$mdDialog', 'Auth', 'ManageSupplierService', 'ManageBuyerService', 'AuthServerProvider', 'BuyerDataService', 'SupplierDataService', 'ImageUploadService', 'TIME_CONSTANTS', 'GLOBAL_CONSTANTS', 'NotificationService', 'PdfUploadService', 'toastr', 'BankService', 'paginationConstants', 'EzyCompanyService', 'BuyerSupplierMappingTableService'];
  function BuyerSupplierEditController($scope, $rootScope, $translate, $state, $stateParams, $mdDialog, Auth, ManageSupplierService, ManageBuyerService, AuthServerProvider, BuyerDataService, SupplierDataService, ImageUploadService, TIME_CONSTANTS, GLOBAL_CONSTANTS, NotificationService, PdfUploadService, toastr, BankService, paginationConstants, EzyCompanyService, BuyerSupplierMappingTableService) {
    var vm = this;
    vm.status;
    vm.id = $stateParams.Id;
    vm.listCode = $stateParams.value;
    vm.paymentTermsOptions = {};
    vm.paymentTerms = {};
    vm.paymentTerms.BANK_TRANSFER = {};
    vm.paymentTerms.PAYPAL = {};
    vm.paymentTerms.CHEQUE = {};
    vm.paymentTerms.OTHER_PAYMENT = {};

    vm.buyerId = null;
    vm.supplierId = null;
    vm.cccSelected;
    vm.requestedTransactionalCurrency;
    vm.currencyList = [];
    vm.visibility = $stateParams.visibility;
    vm.requestId = $stateParams.requestId;
    vm.relate = $stateParams.relate;
    vm.checkPaymentTerms = checkPaymentTerms;
    vm.updateSupplierActivatedKey = updateSupplierActivatedKey;
    vm.getSupplierdata = getSupplierdata;
    vm.populatePaymentTerms = populatePaymentTerms;
    vm.updateIdentifier = updateIdentifier;
    vm.deliveryConditions = {};
    vm.save = save;
    vm.enableDisableBuyerSupplier = enableDisableBuyerSupplier;
    vm.createIdentifier = createIdentifier;
    vm.populateDeliveryConditions = populateDeliveryConditions;
    vm.getIdentifier = getIdentifier;
    vm.cancelDetails = cancelDetails;
    vm.closeModal = closeModal;
    vm.dialogBox = dialogBox;
    vm.buyerSupplierMapping = buyerSupplierMapping;
    vm.userRole = Auth.getUserRole();
    vm.selectedBrands = [];
    vm.role = Auth.getUserRole().ugly;
    vm.cancelBrandMapping = cancelBrandMapping;
    vm.getSupplierdata();

    function getSupplierdata() {
     
      if (vm.role == 'ROLE_BUYER') {
        supplierDetail();

        vm.userId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
        // activateDeactivateBuyerSupplier(vm.userId, vm.id)
        if (vm.listCode != 1) {
          dialogBox();
        }
      }
      else if (vm.role == 'ROLE_SUPPLIER') {
        buyerDetail();
        vm.visibility = 'ALLOCATED';
        vm.userId = vm.id;
        vm.id = SupplierDataService.getSupplierProfile().supplier.id;
        // activateDeactivateBuyerSupplier(vm.userId, vm.id)
        if (vm.listCode != 1) {
          dialogBox();
        }
      }
    }

    function cancelBrandMapping(){
      BuyerSupplierMappingTableService.buyerSupplierMapping(vm.userId, vm.id, vm.userRole.pretty,{brandMapping: false}).then(function (response) {
        closeModal();
        $state.go('' + vm.userRole.pretty + '.mapping');
      },
      function(error){});
    }

    function buyerSupplierMapping() {
      var params = {
        brands: _.map(vm.selectedBrands, _.property('id')),
        brandMapping: true
      }; 
      BuyerSupplierMappingTableService.buyerSupplierMapping(vm.userId, vm.id, vm.userRole.pretty,params).then(function (response) {
        closeModal();
        toastr.success('Mapped Successfully');
        $state.go('' + vm.userRole.pretty + '.mapping');
      }, function(error){
       toastr.error($translate.instant(error.data.responseList[0].responseType),$translate.instant(error.data.message));      });
    }

    function dialogBox() {
      if (vm.role == 'ROLE_BUYER')
        getSelectedBrandsBySupplier();
      $mdDialog.show({
        templateUrl: 'app/common-modules/buyer-supplier-mapping-table/edit/map-request.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: false
      });
    }

    function closeModal() {
      $mdDialog.cancel();
    }

    function getSelectedBrandsBySupplier(){
       var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id
      BuyerSupplierMappingTableService.getSelectedBrands(buyerId).then(function(response){
        vm.selectedBrands = response;
      },
      function(error){
        toastr.error($translate.instant(error.data.responseList[0].responseType),$translate.instant(error.data.message));     
      });

    }

    function activateDeactivateBuyerSupplier(buyid, suppId) {
      vm.buyerId = buyid;
      vm.supplierId = suppId;
      ManageSupplierService.enableDisabledBuyerSupplier(buyid, suppId).then(function (response) {
        vm.status = response.data;
      }, function (error) {
        NotificationService.error({
          title: 'Supplier Updation',
          error: error
        });
      });
    }
    function supplierDetail() {
      ManageSupplierService.getAdminSupplier(vm.id).
        then(function (response) {

          if (response) {
            vm.supplier = response;
            vm.populateDeliveryConditions();
            vm.populatePaymentTerms();
            if (vm.supplier.accessForPEPPOL) {
              vm.getIdentifier();
            }
          }
        }, function (error) {
        });
    }
    function buyerDetail() {
      ManageBuyerService.getAllocatedBuyerDetails(vm.id).
        then(function (response) {
          if (response) {
            vm.supplier = response;
            vm.populateDeliveryConditions();
            vm.populatePaymentTerms();
            if (vm.supplier.accessForPEPPOL) {
              vm.getIdentifier();
            }
          }
        }, function (error) {
        });
    }
    function cancelDetails() {
      if (vm.role == 'ROLE_BUYER') {
        $state.go('buyer.mapping')
      }
      else if (vm.role == 'ROLE_SUPPLIER') {
        $state.go('supplier.mapping')
      }
    }
    function updateIdentifier() {
      EzyCompanyService.updateIdentifier(
        {
          ezpCompanyId: vm.ezyCompanyDetails.ezpCompanyId,
          identifier: vm.ezyCompanyDetails.identifier,
          isoCode: vm.ezyCompanyDetails.isoCode,
          userRole: 'ROLE_SUPPLIER',
          id: vm.ezyCompanyDetails.id
        }).then(function (response) {
        }, function (error) {
          NotificationService.simpleErrorToast({
            title: 'Admin Supplier Updation',
            message: error.data.message
          });
        });
    }
    //populate payment terms in the form
    function populatePaymentTerms() {
      vm.paymentTermsList = angular.copy(vm.supplier.paymentTermsList);
      _.forEach(vm.paymentTermsList, function (paymentTerm) {
        var key = angular.copy(paymentTerm.paymentType);
        if (paymentTerm.active === true) {
          vm.paymentTermsOptions[key] = true;
          vm.paymentTerms[key] = paymentTerm;
        }
      });
    }
    //Populate delivery conditions in the form
    function populateDeliveryConditions() {
      if (vm.supplier.orderTimeForToday) {
        vm.deliveryConditions.today = true;
      }
      if (vm.supplier.orderTimeForFriday) {
        vm.deliveryConditions.friday = true;
      }
      if (vm.supplier.orderTimeForSaturday) {
        vm.deliveryConditions.saturday = true;
      }
      if (vm.supplier.minimumOrderForFreeDelivery) {
        vm.deliveryConditions.minimum = true;
      }
    }
    /**
      * [getIdentifier Gets supplier identifier details]
      */
    function getIdentifier() {
      EzyCompanyService.getIdentifier(vm.supplierId, 'ROLE_SUPPLIER').then(function (response) {
        vm.ezyCompanyDetails = response.data;
        vm.ezyCompanyDetailsId = vm.ezyCompanyDetails.id;
      }, function (error) {
        NotificationService.simpleErrorToast({
          title: 'Admin Supplier Updation',
          message: error.data.message
        });
      });
    }
    //check payment terms
    function checkPaymentTerms() {
      vm.supplier.paymentTermsList = [];
      _.forEach(vm.paymentTerms, function (paymentTerm, key) {
        paymentTerm.paymentType = key;
        if (typeof paymentTerm.active === "boolean") {
          vm.supplier.paymentTermsList.push(paymentTerm);
        }
      });
    }
    function createIdentifier() {
      EzyCompanyService.createIdentifier(
        {
          ezpCompanyId: vm.supplierId,
          identifier: vm.ezyCompanyDetails.identifier,
          isoCode: vm.ezyCompanyDetails.isoCode,
          userRole: 'ROLE_SUPPLIER',
          id: ''
        }).then(function (response) {
        }, function (error) {
          NotificationService.simpleErrorToast({
            title: 'Admin Supplier Updation',
            message: error.data.message
          });
        });
    }

    function save() {
      checkPaymentTerms();
      if (vm.supplier.accessForPEPPOL) {
        if (vm.ezyCompanyDetailsId) {
          vm.updateIdentifier();
        }
        else {
          vm.createIdentifier();
        }
      }
      // Call Admin supplier service with supplier object as parameter to update
      ManageSupplierService.updateAdminSupplier(vm.supplier).then(function (response) {
        $scope.$emit('ezyprocureApp:supplierUpdate', response);
        NotificationService.success({
          title: 'Admin Supplier',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        $state.go('buyer.mapping');
      }, function (error) {
        NotificationService.error({
          title: 'Admin Supplier Updation',
          error: error
        });
      });
    }
    function enableDisableBuyerSupplier(Id, id, isActivate) {
      ManageSupplierService.enableDisableActivatedKey(Id, id, isActivate).then(function (response) {
        if (response.data.isActive == true) {
          NotificationService.success({
            title: 'Activated successfully',
            // message: 'Activated'
          });
        }
        else {
          NotificationService.success({
            title: 'Deactivated successfully',
            // message: 'Activated'
          });
        }
      }, function (error) {
        NotificationService.error({
          title: 'Supplier Updation',
          error: error
        });
      });
    }
    function updateSupplierActivatedKey(Id, isActivate) {
      if (vm.role == 'ROLE_BUYER') {
        var buyid = BuyerDataService.getCbBuyerProfile().buyerUser.buyer.id;
        vm.enableDisableBuyerSupplier(buyid, Id, isActivate)
      }
      else if (vm.role == 'ROLE_SUPPLIER') {
        var suppid = SupplierDataService.getCbSupplierProfile().supplier.id;
        vm.enableDisableBuyerSupplier(Id, suppid, isActivate)
      }

    }

  }
})();
