/**
* @Author: Akhil Jose
* @Date:   29-Jan-2020
* @Project: Ezyprocure
* @Filename: subscription.plan.controller.js
*/



(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('SubscriptionController', SubscriptionController);

  SubscriptionController.$inject = ['$rootScope', '$scope', '$state', '$stateParams', '$localStorage', '$translate',
    'toastr', 'EZYAP_CONSTANTS', 'SubscriptionPlan', 'BuyerAccountService', 'SupplierAccountService',
    'AuthServerProvider', 'Principal', '$mdDialog', 'BuyerDataService', 'MyPlansService',
    'SupplierDataService', '$filter'];

  function SubscriptionController($rootScope, $scope, $state, $stateParams, $localStorage, $translate,
    toastr, EZYAP_CONSTANTS, SubscriptionPlan, BuyerAccountService, SupplierAccountService,
    AuthServerProvider, Principal, $mdDialog, BuyerDataService, MyPlansService,
    SupplierDataService, $filter) {
    var vm = this;

    vm.selectedDomesticPlan = {
      "active": true,
      "id": -1,
      "planId": -1,
      "userId": -1,
      "name": null,
      "type": null,
      "planType": null,
      "currency": null,
      "userLogin": null,
      "valid_from": null,
      "valid_to": null,
      "planValue": null,
      "promoCode": null,
      "value": null
    };
    vm.selectedCrossborderPlan = {
      "active": true,
      "id": -1,
      "planId": -1,
      "userId": -1,
      "name": null,
      "type": null,
      "currency": null,
      "userLogin": null,
      "valid_from": null,
      "valid_to": null,
      "planValue": null,
      "promoCode": null,
      "value": null
    };

    vm.applyDomPromo = applyDomPromo;
    vm.applyCbPromo = applyCbPromo;
    vm.cancelPromoCode = cancelPromoCode;

    vm.domesticCountries = EZYAP_CONSTANTS.ezyap.domesticCountries;
    vm.isDomestic = false;

    vm.onInit = onInit;
    vm.outletSize = 1;

    vm.selectPlan = selectPlan;
    vm.planActive = JSON.parse($localStorage["valid-user-plan"] || "{}");
    $rootScope.planColors = [
      {
        selBgColor: 'monthly-dom-bg',
        selTextColor: 'monthly-dom-color'
      },
      {
        selBgColor: 'yearly-dom-bg',
        selTextColor: 'yearly-dom-color'
      },
      {
        selBgColor: 'monthly-cb-bg',
        selTextColor: 'monthly-cb-color'
      },
      {
        selBgColor: 'yearly-cb-bg',
        selTextColor: 'yearly-cb-color'
      },
      {
        selBgColor: 'pertrans-cb-bg',
        selTextColor: 'pertrans-cb-color'
      },
      {
        selBgColor: 'grant-cb-bg',
        selTextColor: 'grant-cb-color'
      }
    ];

    vm.changeOutlet = changeOutlet;

    $scope.$on('changedSelectedPlan', function (event, data) {
      var plan = data.plan;
      var selected = data.select;
      if (plan.type === 'DOMESTIC') {
        if(vm.limitDomPlans){
          toastr.error($filter('translate')('alertMessage.DOM_PLAN_EXCEEDS'), 'Error');
        } else { 
          changedDomesticPlan(plan, !selected);
        }
      } else if (plan.type === 'CROSSBORDER') {
        if (plan.planType == 'transaction' && vm.hasTransactionPlan == true) {
          toastr.error($filter('translate')('alertMessage.TRANS_LIMIT'), 'Error');
        } else if(vm.limitCbPlans){
          toastr.error($filter('translate')('alertMessage.CB_PLAN_EXCEEDS'), 'Error');
        } else { 
          changedCrossborderPlan(plan, !selected);
        }
      }
    });

    function onInit() {
      if (!$stateParams.userId || !$stateParams.country) {
        $state.go('login');
      }
      $rootScope.planColorAvail = JSON.parse(JSON.stringify($rootScope.planColors));

      var authJWT = AuthServerProvider.getCbToken();
      var decAuthJWT = jwt_decode(authJWT);
      vm.userId = decAuthJWT.uid;
      vm.userType = decAuthJWT.auth === 'ROLE_BUYER' ? 'buyer' : 'supplier';
      getPlansBasedOnCountry();
    }

    function getPlansBasedOnCountry() {
      if (vm.userType === 'buyer') {
        BuyerAccountService.getCbBuyerProfile()
          .then(function (buyerInfo) {
            vm.country = buyerInfo.buyerUser.user.userCountry;
            vm.isDomestic = vm.domesticCountries.includes(vm.country);
            fetchAllPlans()
              .then(function () {
                vm.isBuyer = true;
                vm.companyId = BuyerDataService.getCbBuyerProfile().buyerUser.buyer.id;
                fetchCurrentFuturePlans();
              })
          });
      } else if (vm.userType === 'supplier') {
        SupplierAccountService.getCbSupplierProfile()
          .then(function (supplierInfo) {
            vm.country = supplierInfo.user.userCountry;
            vm.isDomestic = vm.domesticCountries.includes(vm.country);
            fetchAllPlans()
              .then(function () {
                vm.isBuyer = false;
                vm.companyId = SupplierDataService.getCbSupplierProfile().supplier.id;
                fetchCurrentFuturePlans();
              })
          });
      }
    }

    function fetchAllPlans(type) {
      return SubscriptionPlan.fetchSubscriptionPlans(vm.country, vm.outletSize)
        .then(function (plans) {
          if (!type) {
            vm.crossborderPlans = plans.filter(function (p) { return p.type === 'CROSSBORDER' });
            vm.domesticPlans = plans.filter(function (p) { return p.type === 'DOMESTIC' });
          } else if (type == 'dom') {
            vm.domesticPlans = plans.filter(function (p) { return p.type === 'DOMESTIC' });
          } else if (type == 'cb') {
            vm.crossborderPlans = plans.filter(function (p) { return p.type === 'CROSSBORDER' });
          }
          return;
        }).catch(function () {
          toastr.error('Something went Wrong', 'Error');
        });
    }

    function changeOutlet(size) {
      if (size >= 1) {
        vm.outletSize = size;
        if (vm.domPromoApplied) {
          applyDomPromo(vm.domesticPromo)
        } else {
          fetchAllPlans();
        }
      }
    }

    function fetchCurrentFuturePlans() {
      vm.limitDomPlans = false;
      vm.limitCbPlans = false;
      vm.hasTransactionPlan = false;
      MyPlansService.fetchCurrentFuturePlans(vm.companyId, vm.isBuyer)
        .then(function (response) {
          if (response) {
            vm.currentDomPlans = response.CURRENT.filter(function (p) {
              return p.plan.type == 'DOMESTIC';
            });
            vm.currentCBPlans = response.CURRENT.filter(function (p) {
              if(p.plan.planType == 'transaction') {
                vm.hasTransactionPlan = true;
              }
              return p.plan.type == 'CROSSBORDER';
            });
            vm.futureDomPlans = response.FUTURE.filter(function (p) {
              return p.plan.type == 'DOMESTIC';
            });
            vm.futureCBPlans = response.FUTURE.filter(function (p) {
              return p.plan.type == 'CROSSBORDER';
            });
            if (vm.currentCBPlans && vm.currentCBPlans.length != 0
              && vm.futureCBPlans && vm.futureCBPlans.length != 0){
              vm.limitCbPlans = true;
            }
            if (vm.currentDomPlans && vm.currentDomPlans.length != 0
              && vm.futureDomPlans && vm.futureDomPlans.length != 0){
              vm.limitDomPlans = true;
            }
          }
        }).catch(function () {
          toastr.error('Something went wrong', 'Server Error');
        });
    }

    function changedDomesticPlan(plan, selected) {
      if (selected) {
        var validTo = null;
        var validFrom = null;
        vm.selectedDomesticPlan = {
          "active": true,
          "id": -1,
          "planId": plan.id,
          "userId": vm.userId,
          "name": plan.name,
          "type": plan.type,
          "planType": plan.planType,
          "currency": plan.currency,
          "userLogin": null,
          "valid_from": validFrom,
          "valid_to": validTo,
          "planValue": null,
          "promoCode": null,
          "value": plan.value
        };
      } else {
        vm.selectedDomesticPlan = {
          "active": true,
          "id": -1,
          "planId": -1,
          "userId": -1,
          "name": null,
          "type": null,
          "planType": null,
          "currency": null,
          "userLogin": null,
          "valid_from": null,
          "valid_to": null,
          "planValue": null,
          "promoCode": null,
          "value": null
        };
      }
      $scope.$broadcast('selectPlan', { plan: plan, select: selected });
    }

    function changedCrossborderPlan(plan, selected) {
      if (selected) {
        var validTo = null;
        var validFrom = null;
        vm.selectedCrossborderPlan = {
          "active": true,
          "id": -1,
          "planId": plan.id,
          "userId": vm.userId,
          "name": plan.name,
          "type": plan.type,
          "currency": plan.currency,
          "userLogin": null,
          "valid_from": validFrom,
          "valid_to": validTo,
          "planValue": null,
          "promoCode": null,
          "value": plan.value
        };
      } else {
        vm.selectedCrossborderPlan = {
          "active": true,
          "id": -1,
          "planId": -1,
          "userId": -1,
          "name": null,
          "type": null,
          "currency": null,
          "userLogin": null,
          "valid_from": null,
          "valid_to": null,
          "planValue": null,
          "promoCode": null,
          "value": null
        };
      }
      $scope.$broadcast('selectPlan', { plan: plan, select: selected });
    }

    function applyDomPromo(promo) {
      SubscriptionPlan.applyPromo(promo, vm.outletSize, vm.country, vm.userId)
        .then(function (promocodes) {
          var transactionLimit = [];
          if (promocodes &&
            (promocodes.length !== 0 ||
              (promocodes.promocodePlan && promocodes.promocodePlan.length !== 0))) {
            if (promocodes.promocodePlan && promocodes.promocodePlan.length !== 0) {
              transactionLimit = promocodes.promocodeTransactional;
              promocodes = promocodes.promocodePlan;
            }
            var domExist = promocodes.map(function (e) {
              return e.plan.type;
            }).includes('DOMESTIC')
            if (domExist) {
              vm.selectedDomesticPlan = {
                "active": true,
                "id": -1,
                "planId": -1,
                "userId": -1,
                "name": null,
                "type": null,
                "planType": null,
                "currency": null,
                "userLogin": null,
                "valid_from": null,
                "valid_to": null,
                "planValue": null,
                "promoCode": null,
                "value": null
              };
              vm.domesticPlans = [];
            } else {
              toastr.error('No plans available for this promocode', 'Promocode Error')
              return;
            }
          } else {
            toastr.error('No plans available for this promocode', 'Promocode Error')
            return;
          }
          vm.domPromoId = promocodes[0].promoCode.id;
          vm.domPromoApplied = true;
          vm.domPromoDesc = promocodes[0].promoCode.description;
          promocodes.forEach(function (promocode) {
            if (promocode.plan.type === 'DOMESTIC') {
              vm.domesticPlans.push(Object.assign(promocode.plan, { transactionLimit: transactionLimit }));
            }
            if (promocode.promoCode.subscriptionType === 'subscription') {
              var value = promocode.plan.value;
              if (promocode.promoCode.promoCodeChargeType === 'discount') {
                value = (promocode.plan.value - (promocode.plan.value * promocode.promoCode.promoCodeCharge / 100))* vm.outletSize;;
              } else {
                value = promocode.promoCode.promoCodeCharge* vm.outletSize;;
              }
              setPlanValueById('DOM', promocode.plan.id, value);
            }
          });
        })
        .catch(function (err) {
          toastr.error('Invalid promo code', 'Promocode Error')
        });
    }

    function applyCbPromo(promo) {
      SubscriptionPlan.applyPromo(promo, 1, vm.country, vm.userId)
        .then(function (promocodes) {
          var transactionLimit = [];
          if (promocodes &&
            (promocodes.length !== 0 ||
              (promocodes.promocodePlan && promocodes.promocodePlan.length !== 0))) {
            if (promocodes.promocodePlan && promocodes.promocodePlan.length !== 0) {
              transactionLimit = promocodes.promocodeTransactional;
              promocodes = promocodes.promocodePlan;
            }
            var cbExist = promocodes.map(function (e) {
              return e.plan.type;
            }).includes('CROSSBORDER')
            if (cbExist) {
              vm.crossborderPlans = [];
            }
            else {
              toastr.error('No plans available for this promocode', 'Promocode Error')
              return;
            }
          } else {
            toastr.error('No plans available for this promocode', 'Promocode Error')
            return;
          }
          vm.cbPromoId = promocodes[0].promoCode.id;
          vm.cbPromoApplied = true;
          vm.cbPromoDesc = promocodes[0].promoCode.description;
          promocodes.forEach(function (promocode) {
            if (promocode.plan.type === 'CROSSBORDER') {
              vm.crossborderPlans.push(Object.assign(promocode.plan, { transactionLimit: transactionLimit }));
            }
            if (promocode.promoCode.subscriptionType === 'subscription') {
              var value = promocode.plan.value;
              if (promocode.promoCode.promoCodeChargeType === 'discount') {
                value = (promocode.plan.value - (promocode.plan.value * promocode.promoCode.promoCodeCharge / 100));
              } else if (promocode.promoCode.promoCodeChargeType === 'amount') {
                value = promocode.promoCode.promoCodeCharge;
              }
              setPlanValueById('CB', promocode.plan.id, value);
            }
          });
        })
        .catch(function (err) {
          toastr.error('Invalid promo code', 'Promocode Error')
        });
    }

    function setPlanValueById(type, id, value) {
      if (type === 'DOM') {
        var index = vm.domesticPlans.findIndex(function (domPlan) {
          if (domPlan.id === id) {
            return true;
          }
          return false;
        });
        vm.domesticPlans[index].value = value;
      } else if (type === 'CB') {
        var index = vm.crossborderPlans.findIndex(function (cbPlan) {
          if (cbPlan.id === id) {
            return true;
          }
          return false;
        });
        vm.crossborderPlans[index].value = value;
      }
    }

    vm.checkIfCBSelected = function () {
      if (vm.selectedDomesticPlan.planId == -1 && vm.selectedCrossborderPlan.planId == -1) {
        return toastr.error('Select atleast one of the plan', 'Validation Error');
      }
      $mdDialog.show({
        templateUrl: 'app/common-modules/plans/recharge/modals/plan-confirm.modal.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        clickOutsideToClose: true
      });
    }

    vm.cancel = function () {
      $mdDialog.cancel();
    }

    function selectPlan() {
      $mdDialog.cancel();
      if (vm.selectedDomesticPlan.planId == -1 && vm.selectedCrossborderPlan.planId == -1) {
        return toastr.error('Select atleast one of the plan', 'Validation Error');
      }
      if (vm.userType === 'buyer' && vm.selectedDomesticPlan && vm.selectedDomesticPlan.id) {
        vm.selectedDomesticPlan.value = vm.selectedDomesticPlan.value * vm.outletSize;
      }
      var request = [];
      if (vm.selectedDomesticPlan.planId != -1) {
        // if(vm.domPromoApplied)
        //   vm.selectedDomesticPlan.promoCode = vm.domesticPromo;
        request.push({
          outlets: vm.outletSize,
          planId: vm.selectedDomesticPlan.planId,
          promoCodeId: vm.domPromoId || null
        });
      }
      if (vm.selectedCrossborderPlan.planId != -1) {
        // if(vm.cbPromoApplied)
        //   vm.selectedCrossborderPlan.promoCode = vm.crossBorderPromo;
        request.push({
          outlets: 1,
          planId: vm.selectedCrossborderPlan.planId,
          promoCodeId: vm.cbPromoId || null
        });
      }
      $rootScope.subscriptionPlanRequest = request;
      $mdDialog.cancel();
      $rootScope.subscriptionPlanRequest = request;
      if (vm.userType === 'buyer') {
        $state.go('buyer.addcard', {
          country: vm.country,
          userId: vm.userId,
          planType: vm.selectedCrossborderPlan.name || ''
        });
      } else {
        $state.go('supplier.addcard', {
          country: vm.country,
          userId: vm.userId,
          planType: vm.selectedCrossborderPlan.name || ''
        });
      }
    }

    function cancelPromoCode(type) {
      fetchAllPlans(type)
        .then(function () {
          if (type == 'cb') {
            vm.cbPromoApplied = false;
            vm.crossBorderPromo = '';
            vm.cbPromoId = null;
          } else {
            vm.domPromoApplied = false;
            vm.domesticPromo = '';
            vm.domPromoId = null;
            vm.selectedDomesticPlan = {
              "active": true,
              "id": -1,
              "planId": -1,
              "userId": -1,
              "name": null,
              "type": null,
              "planType": null,
              "currency": null,
              "userLogin": null,
              "valid_from": null,
              "valid_to": null,
              "planValue": null,
              "promoCode": null,
              "value": null
            };
          }
        });
    }
  }
})();
