/**
* @Author: Mufsir
* @Date:   17-May-2017 15:31 +05:30
* @Project: Ezyprocure
 * @Last modified by:   sneha
 * @Last modified time: 29-Nov-2017 12:43 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('supplier.reports.purchase-order.preview', {
      parent: 'supplier.reports.purchase-order',
      url: '/preview/:poNumber/:type/:deliveryDate',
      data: {
        authorities: ['ROLE_SUPPLIER'],
        pageTitle: 'Purchase Order Preview'
      },
      views: {
        'content@': {
          templateUrl: 'app/common-modules/reports-preview/purchase-order-report.preview.html',
          controller: 'SupplierPurchaseOrderIndividualDetailsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('invoice');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('buyerBusiness');
          $translatePartialLoader.addPart('processOrder');
          return $translate.refresh();
        }]
      }
    });
  }
})();
