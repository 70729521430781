/*
 * @Author: Abhay
 * @Date: 2021-11-29 11:48:02
 * @Last Modified by: Abhay
 * @Last Modified time: 2021-11-13 14:50:04
 */



(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('AddInvoicePreviewController', AddInvoicePreviewController);

  AddInvoicePreviewController.$inject = ['$state', 'toastr', '$mdDialog', '$timeout', 'pagingParams', 'NotificationService', 'paginationConstants', 'BuyerPayeeService', 'Auth', 'GLOBAL_CONSTANTS', '$stateParams', 'BankService', 'BuyerDataService', 'BrandManagementService', 'CustomPaymentsService'];

  function AddInvoicePreviewController($state, toastr, $mdDialog, $timeout, pagingParams, NotificationService, paginationConstants, BuyerPayeeService, Auth, GLOBAL_CONSTANTS, $stateParams, BankService, BuyerDataService, BrandManagementService, CustomPaymentsService) {

    var vm = this;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.brandIds = [];
    vm.loadInvoiceData = loadInvoiceData;
    vm.calculateSum = calculateSum;
    vm.confirmAndPay = confirmAndPay;
    vm.init = init;
    // 2FA changes
    vm.otp = undefined;
    vm.isOtpValid = true;
    vm.counterMilliSeconds = undefined;
    vm.counterSeconds = undefined;
    vm.counterMinutes = undefined;
    vm.validateOTP = validateOTP;
    vm.resendOTP = resendOTP;

    function init() {
      loadInvoiceData();
      getPurposes();
    }

    function getPurposes() {
      CustomPaymentsService.getPurposes().then(function (response) {
        vm.purposes = response.data;
      }, function (error) {
        NotificationService.error({
          title: 'Purposes',
          error: error
        });
      });
    }

    function getAllCards() {
      CustomPaymentsService.getAllCards({
        brandIds: vm.brandIds,
        page: 0,
        size: paginationConstants.maxLimit
      }).then(function (response) {
        vm.invoiceData = assignCards(response);
        selectFirstCard();
      }, function (error) {
        NotificationService.error({
          title: 'Fetching Token By Cards',
          error: error
        });
      });
    }

    function calculateSum(duePaymentSummaryDetailsList, key){
      var sum = 0;
      if(duePaymentSummaryDetailsList && duePaymentSummaryDetailsList.length > 0) {
        duePaymentSummaryDetailsList.forEach( function (invoice) {
          sum += Number(Number(invoice[key]).toFixed(2));
        });
      }
      return sum;
    }

    function assignCards(cardsResponse) {
      return vm.invoiceData.map(function (data) {
        data.cards = cardsResponse.data.filter(function (card) {
          return card.brand.id == data.brandId;
        });
        return data;
      });
    }

    function selectFirstCard() {
      vm.invoiceData.forEach(function (invoice) {
        invoice.tokenId = invoice.cards[0] ? invoice.cards[0].id : undefined;
      });
    }

    function setBrandIds(invoices) {
      invoices.forEach(function (invoice) {
        vm.brandIds.push(invoice.brandId);
      });
    }

    function validatePurpose() {
      var isValid = true;
      vm.invoiceData.forEach(function (invoice) {
        invoice.paidInvoicesList.forEach(function (paidInvoice) {
          if (!paidInvoice.purposeCode) {
            isValid = false;
          }
        })
      });
      return isValid;
    }

    function confirmAndPay(otp) {
      if(!validatePurpose()) {
        toastr.error('Missing Purpose', 'Validation Error');
        return;
      }
      console.log('confirmAndPay 0');
      CustomPaymentsService.confirmAndPay(vm.invoiceData, otp).then(function (response) {
        if(response.headers('authorization-otp') == 'OTP_VERIFICATION_REQUIRED') {
          console.log('confirmAndPay 1');
          showOtpPrompt();
        }
        else if(GLOBAL_CONSTANTS.OTP_EXCEPTIONS.includes(response.headers('authorization-otp'))) {
          console.log('confirmAndPay 2');
          toastr.error('Invalid OTP', 'AUTHENTICATION FAILED');
        }
        else {
          console.log('confirmAndPay 3');
          toastr.success('Your payment is in progress');
          vm.reportData = response.data;
          CustomPaymentsService.setReportData(vm.reportData);
          $mdDialog.hide();
          $state.go('ezypayment.buyer.custom-invoice-payments.add-invoice.report');
        }
      },
        function (error) {
          NotificationService.error({
            title: 'Confirm and Pay',
            error: error
          });
        });
    }

    function loadInvoiceData() {
      var invData = CustomPaymentsService.getInvoiceData();
      setBrandIds(invData);
      var requestBody = invData.map(function (data) {
        var previewData = {
          supplierId: data.supplierId,
          brandId: data.brandId,
          custRefNumber: data.custRefNumber,
          purposeCode: data.purposeCode,
          invoiceNumber: data.invoiceNumber,
          invoiceDate: data.invoiceDate,
          amount: data.amount,
          invoiceDoc: data.invoiceDoc,
          otherDoc: data.otherDoc
        }
        return previewData;
      });
      CustomPaymentsService.addInvoiceNext(requestBody).then(function (response) {
        vm.invoiceData = response.data;
        var invoiceD = []
        vm.invoiceData.forEach( function (invoice) {
            var paidD = [];
            invoice.paidInvoicesList.forEach( function (paidInv) {
                if(paidInv.purposeCode === null) {
                    paidInv.purposeCode = "9311";
                }
                paidD.push(paidInv);
            });
            invoice.paidInvoicesList = paidD;
            invoiceD.push(invoice);
        });
        vm.invoiceData = invoiceD;
        console.log(vm.invoiceData);
        getAllCards();
      },
        function (error) {
          NotificationService.error({
            title: 'Add Invoice',
            error: error
          });
        });
    }

     // timer for otp resend
     function counter() {
      vm.stopped = $timeout(function () {
        vm.counterMilliSeconds -= 1000;
        if (vm.counterMilliSeconds) {
          var seconds = vm.counterMilliSeconds / 1000;
          vm.counterMinutes = Math.floor(seconds / 60);
          if(vm.counterMinutes > 0) {
            vm.counterSeconds = seconds - (vm.counterMinutes * 60);
          }
          else {
            vm.counterSeconds = seconds;
          }
          counter();
        }
        else {
          vm.counterSeconds = 0;
          vm.counterMinutes = 0;
          stopCounter();
        }
      }, 1000);
    };

    function stopCounter(){
      vm.isOtpValid = false;
      $timeout.cancel(vm.stopped);
    }

    function showOtpPrompt() {
      // clear otp
      vm.otp = undefined;
      vm.isOtpValid = true;
      vm.counterSeconds = 0;
      vm.counterMinutes = 0;
      vm.counterMilliSeconds = GLOBAL_CONSTANTS.OTP_EXPIRY_MINUTES * 60 * 1000;
      counter();
      // show prompt
      $mdDialog.show({
        templateUrl: 'app/core/common-templates/otp-templates/otp-capture.tmpl.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function validateOTP() {
      if (!vm.otp) {
        toastr.error('Invalid OTP!', 'Please check the OTP entered');
        return;
      }

      if (vm.otp && (vm.otp.length < 5 || vm.otp.length > 8)) {
        toastr.error('Invalid OTP!', 'Please check the OTP entered');
        return;
      }
      confirmAndPay(vm.otp);
    }

    function resendOTP() {
      confirmAndPay();
    }

  }
})();
