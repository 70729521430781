/*
 * @Author: Jayalekshmi 
 * @Date: 2020-01-17 10:35:31 
 * @File: showDecimal.filter.js
 * @Last Modified by: Lovin
 * @Last Modified time: 2020-07-13 13:08:27
 */

(function() {
    'use strict';

    angular
    .module('ezyprocureApp')
    .filter('formatText', formatText);

    function formatText() {
      return formatText;

      function formatText(str){
        return _.startCase(_.toLower(str))
      }
    }
  })();

