/**
 * @Author: Ajay
 * @Date:   22-Dec-2017 14:32 +05:30
 * @Project: Ezyprocure
 * @Filename: business-reports.service.js
 * @Last modified by:   sneha
 * @Last modified time: 2018-08-28T11:25:24+05:30
 */



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BusinessReportService', BusinessReportService);

  BusinessReportService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  function BusinessReportService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var businessReportService = {
      getGoodReceivedData: getGoodReceivedData,
      getFoodCostData: getFoodCostData,
      getPriceListingReport: getPriceListingReport,
      getSupplierPriceListingReport: getSupplierPriceListingReport,
      getNewItemLogReport: getNewItemLogReport,
      getCosting:getCosting,
      getPurchaseTrends:getPurchaseTrends,
      getBudgetForecast:getBudgetForecast,
      getBudgetForecastQuarterly:getBudgetForecastQuarterly,
      getDemandForecast: getDemandForecast,
      getDemandForecastQuarter:getDemandForecastQuarter,
      getTopSuppliers:getTopSuppliers,
      getTotalPurchaseVolume:getTotalPurchaseVolume,
      getGRVarianceReport:getGRVarianceReport,
      getSupplierPerformanceReport:getSupplierPerformanceReport,
      getPriceTrendsReportsByDay: getPriceTrendsReportsByDay,
      getPriceTrendsReportsByMonth: getPriceTrendsReportsByMonth,
      getPriceTrendsReportsByQuarter: getPriceTrendsReportsByQuarter,
      getPriceTrendsRawDataReports: getPriceTrendsRawDataReports,
      itemNameService:itemNameService
    };
    return businessReportService;

    function getGoodReceivedData(data, params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUSINESS_REPORTS_RESOURCE +'good-receive-data',
        method: "POST",
        data: data,
        params: params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function itemNameService(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion  +'item-buyer-mappings',
        method: "get",
        params: params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }


    function getFoodCostData(data, params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUSINESS_REPORTS_RESOURCE +'food-cost-data',
        method: "POST",
        data: data,
        params: params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getPriceListingReport(data, params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUSINESS_REPORTS_RESOURCE +'price-listing',
        method: "POST",
        data: data,
        params: params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    function getTopSuppliers(data,params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUSINESS_REPORTS_RESOURCE + 'top-suppliers',
        method: "POST",
        data:data,
        params:params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    function getCosting(data,params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUSINESS_REPORTS_RESOURCE + 'costing',
        method: "POST",
        data:data,
        params:params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    function getTotalPurchaseVolume(data) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUSINESS_REPORTS_RESOURCE + 'total-purchase',
        method: "POST",
        data:data
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getSupplierPriceListingReport(data, params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUSINESS_REPORTS_RESOURCE +'supplier/price-listing',
        method: "POST",
        data: data,
        params: params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getNewItemLogReport(data, params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUSINESS_REPORTS_RESOURCE +'new-item-log',
        method: "POST",
        data: data,
        params: params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getPurchaseTrends(data) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUSINESS_REPORTS_RESOURCE +'purchase-trend',
        method: "POST",
        data:data,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getBudgetForecast(data) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUSINESS_REPORTS_RESOURCE +'purchase-trend-budget',
          method: "POST",
          data:data,
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      function getBudgetForecastQuarterly(data) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUSINESS_REPORTS_RESOURCE +'purchase-trend-budget-quarterly',
          method: "POST",
          data:data,
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

    function getDemandForecast(data) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUSINESS_REPORTS_RESOURCE + 'demand-forecasting-version',
        method: "POST",
        data: data,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    function getDemandForecastQuarter(data) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUSINESS_REPORTS_RESOURCE + 'demand-forecasting-version-quarterly',
        method: "POST",
        data: data,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getSupplierPerformanceReport(data, params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUSINESS_REPORTS_RESOURCE +'supplier-performance',
        method: "POST",
        data: data,
        params: params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  function getGRVarianceReport(data, params) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUSINESS_REPORTS_RESOURCE +'gr-variance',
          method: "POST",
          data:data,
          params: params
           }).then(function(data) {
                  deferred.resolve(data);
                }).catch(function(message) {
                  deferred.reject(message);
         });
        return deferred.promise;
      }
    function getPriceTrendsReportsByDay(data,params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUSINESS_REPORTS_RESOURCE +'price-trend/item-name',
        method: "POST",
        data:data,
        params:params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  function getPriceTrendsReportsByMonth(data,params) {
    var deferred = $q.defer();
    $http({
      url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUSINESS_REPORTS_RESOURCE +'price-trend/monthly',
      method: "POST",
      data:data,
      params:params,
    }).then(function(data) {
      deferred.resolve(data);
    }).catch(function(message) {
      deferred.reject(message);
    });
    return deferred.promise;
  }
  function getPriceTrendsReportsByQuarter(data,params) {
    var deferred = $q.defer();
    $http({
      url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUSINESS_REPORTS_RESOURCE +'price-trend/quarterly',
      method: "POST",
      data:data,
      params:params,
    }).then(function(data) {
      deferred.resolve(data);
    }).catch(function(message) {
      deferred.reject(message);
    });
    return deferred.promise;
  }

  function getPriceTrendsRawDataReports(data,params) {
    var deferred = $q.defer();
    $http({
      url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUSINESS_REPORTS_RESOURCE +'price-trend/raw-data',
      method: "POST",
      data:data,
      params:params,
    }).then(function(data) {
      deferred.resolve(data);
    }).catch(function(message) {
      deferred.reject(message);
    });
    return deferred.promise;
  }

}
})();
