/**
 * @Author: Ajay
 * @Date:   15-May-2018 13:04 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.items-for-approval.service.js
 * @Last modified by:   Vaibhavi
 * @Last modified time: 09-Jul-2018 15:06 +05:30
 */



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BuyerItemsForApprovalService', BuyerItemsForApprovalService);

  BuyerItemsForApprovalService.$inject = ['$http', '$q', 'API_CONSTANTS'];

  function BuyerItemsForApprovalService($http, $q, API_CONSTANTS) {

    var buyerItemsForApprovalService = {
      getItemBuyerMappingByItemId: getItemBuyerMappingByItemId,
      getItemsForApprovalBySupplier: getItemsForApprovalBySupplier,
      updateItemsForApproval: updateItemsForApproval,
      updateItemBusinessMappingWithStatusApproval: updateItemBusinessMappingWithStatusApproval,
      getPendingItemsForApproval: getPendingItemsForApproval,
      getApprovedItemsForApproval: getApprovedItemsForApproval,
      getRejectedItemsForApproval: getRejectedItemsForApproval,
      updateItemApprovalWithStatus: updateItemApprovalWithStatus,
      updateItemBusinessMappingApprovalStatus:updateItemBusinessMappingApprovalStatus
    };

    return buyerItemsForApprovalService;

    function getItemBuyerMappingByItemId() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'manage-items-approval/suppliers',
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get All Item under the selected buyer or item
    function getItemsForApprovalBySupplier(supplierId, params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'manage-items-approval/suppliers/' + supplierId,
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update updateItemsForApproval
    function updateItemsForApproval(itemForApproval) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'items-for-approvals/', itemForApproval).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function updateItemBusinessMappingWithStatusApproval(ItemBusinessDTO){
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'itemBusiness-mappings-statusUpdate/', ItemBusinessDTO).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function updateItemApprovalWithStatus(ItemBusinessDTO){
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'item-business-mappings-status-update/', ItemBusinessDTO).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getPendingItemsForApproval(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'manageItems-approval/suppliers',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getApprovedItemsForApproval(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'manageItems-approved-List/suppliers',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getRejectedItemsForApproval(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'manageItems-rejected-List/suppliers',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function updateItemBusinessMappingApprovalStatus(params){
      var deferred = $q.defer();
      $http({
        url:API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'item-business-mappings/approvalStatus/',
        method:"PUT",
        params:params
      })
      .then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }

})();
