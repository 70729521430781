/**
* @Author: hareesh
* @Date:   10-Mar-2017 05:57 +05:30
* @Project: Ezyprocure
* @Filename: buyer.total-purchase-volume.state.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-03-21T11:55:29+05:30
*/



(function() {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('BuyerTotalPurchaseVolumeController', BuyerTotalPurchaseVolumeController);

  BuyerTotalPurchaseVolumeController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', '$stateParams', 'NotificationService','pagingParams', 'UTCDateService','BusinessReportService','GlSpecificItemGroupService', 'paginationConstants', 'CSVExportService', 'EXPORT_CONSTANTS', 'BuyerBusinessUserMapService', 'GLOBAL_CONSTANTS'];

  function BuyerTotalPurchaseVolumeController($scope, $rootScope, $q, $filter, $state, $stateParams, NotificationService, pagingParams, UTCDateService, BusinessReportService, GlSpecificItemGroupService, paginationConstants, CSVExportService, EXPORT_CONSTANTS, BuyerBusinessUserMapService, GLOBAL_CONSTANTS) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.todaysDate = new Date();
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.loadAll = loadAll;
    vm.multiBarChart=multiBarChart;
    vm.query = {
      page: 1
    };
    vm.filter = {
      buyerBusinessIdList: [],
      glSpecificItemGroupIdList: []
    };
    vm.loadAllGlSpecificItemGroups = loadAllGlSpecificItemGroups;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.getSelectedBusinessOutletsNames = getSelectedBusinessOutletsNames;
    vm.getGlgroupColumnData = getGlgroupColumnData;
    vm.getTotalperBO = getTotalperBO;
    vm.csvHeader = csvHeader;
    vm.getTotalPurchaseVolumeCSVData = getTotalPurchaseVolumeCSVData;
    vm.totalGl = 0;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.fromDate = vm.todaysDate;
    vm.toDate = vm.todaysDate;
    var getTotalPurchaseItems = {};
    vm.init = init();
     //filter reset
     vm.resetFilters = resetFilters;
    /**
    * [getAllBuyerBusinessOutlets Gets all business outlets of buyer]
    */
     function init() {
      var totalPurchaseItems = localStorage.getItem("total_purchase_filter");
      if(totalPurchaseItems){
        getTotalPurchaseItems = JSON.parse(totalPurchaseItems);
        vm.fromDate = getTotalPurchaseItems.fromDate ? getTotalPurchaseItems.fromDate : "";
        vm.toDate = getTotalPurchaseItems.toDate ? getTotalPurchaseItems.toDate : "";
        vm.filter.buyerBusinessIdList = getTotalPurchaseItems.buyerBusinessIdList;
        vm.filter.glSpecificItemGroupIdList = getTotalPurchaseItems.glSpecificItemGroupIdList;
        if(getTotalPurchaseItems.buyerBusinessIdList){
          getAllBuyerBusinessOutlets();
        }
        if(getTotalPurchaseItems.glSpecificItemGroupIdList){
          loadAllGlSpecificItemGroups();
        }
        vm.loadAll();
      }
     }

    function getAllBuyerBusinessOutlets() {
      if(vm.businessOutlets)
        return false;
      vm.businessOutlets = null;
      BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser().
        then(function(response){
          vm.businessOutlets = response;
          //if only one business outlet set it as default
          if(vm.businessOutlets.length === 1) {
            vm.businessId = vm.businessOutlets[0].id;
          }
        },function(error){
        });
    }

    /**
    * [csvHeader Creates CSV header]
    */
    function csvHeader(){
      var header =  EXPORT_CONSTANTS.CSV.totalPurchaseVolume;
      var glgroups = vm.getGlgroupColumnData();
      for(var k=0;k<glgroups.length;k++){
        header.push(glgroups[k]);
      }
      header.push("Total_Cost_Per_Bo");
      vm.totalPurchaseVolumeHeaader = header;
      return vm.totalPurchaseVolumeHeaader;
    }

    /**
    * [getTotalPurchaseVolumeCSVData Gets CSV data]
    */
    function getTotalPurchaseVolumeCSVData(){
      var deferred = $q.defer();
      if (!vm.totalPurchaseVolume) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      var dateRange = (new Date(vm.fromDate)).getDate() +'/' + ( new Date(vm.fromDate).getMonth() + 1 ) +'/' + (new Date(vm.fromDate)).getFullYear() + '-' +(new Date(vm.toDate)).getDate() +'/' + ( new Date(vm.toDate).getMonth() + 1 ) +'/' + (new Date(vm.toDate)).getFullYear();
      return CSVExportService.getTotalPurchaseVolumeCSV(vm.totalPurchaseVolume, dateRange, vm.getGlgroupColumnData(), vm.totalPerBo, vm.totalGl);
    }

    /**
    * [loadAllGlSpecificItemGroups Get all allocated Gl specific item group]
    */
    function loadAllGlSpecificItemGroups() {
      if(vm.glGroups)
        return false;
      GlSpecificItemGroupService.getAllGlSpecificItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.glGroups = response.data;
      }, function(error) {
      });
    }

    /**
    * [selectAll Selects all from the list]
    * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, mainItemGroups, itemGroups or glGroups]
    */
    function selectAll(dropdown) {
      switch (dropdown) {
      case 'OUTLETS':
        vm.filter.buyerBusinessIdList = _.map(vm.businessOutlets, _.property('id'));
        break;
      case 'GLGROUPS':
        vm.filter.glSpecificItemGroupIdList = _.map(vm.glGroups, _.property('id'));
        break;
      }
    }

    /**
    * [deselectAll Deselect all from the list]
    * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, mainItemGroups, itemGroups or glGroups]
    */
    function deselectAll(dropdown) {
      switch (dropdown) {
      case 'OUTLETS':
        vm.filter.buyerBusinessIdList = [];
        break;
      case 'GLGROUPS':
        vm.filter.glSpecificItemGroupIdList = [];
        break;
      }
    }

    /**
    * [Gets selected business outlet names]
    */
    function getSelectedBusinessOutletsNames(){
      vm.outletnames = [];
      _.filter(vm.businessOutlets,function(outlet){
        _.filter(vm.filter.buyerBusinessIdList, function(id){
          outlet.id === id ? vm.outletnames.push(outlet.businessOutletName):'';
        });
      });
    }

    /**
    * [Draws the multibar chart]
    */
    function multiBarChart(outletList) {
      var index = outletList.indexOf("Total");
      var exclude_tot = outletList;
      if(index !==-1){
      exclude_tot.splice(index,1);
 }
      vm.options = {
        chart: {
          type: 'multiBarChart',
          height: 800,
          margin : {
            top: 100,
            right: 20,
            bottom: 200,
            left: 80
          },
          clipEdge: true,
          duration: 500,
          stacked: true,
          rotateLabels:35,
          reduceXTicks:false,
          xAxis: {
            axisLabel: 'Business Outlets',
            axisLabelDistance:160,
            showMaxMin: false,
            tickFormat: function(d){
              return exclude_tot[d];
            }
          },
          yAxis: {
            axisLabel: '',
            axisLabelDistance: -20,
            tickFormat: function(d){
              return d3.format('.2f')(d);
            }
          },
          zoom: {
            enabled: true,
            scaleExtent: [1, 10],
            useFixedDomain: false,
            useNiceScale: false,
            horizontalOff: false,
            verticalOff: true,
            unzoomEventType: 'dblclick.zoom'
          }
        }
      };

      vm.data = generateData();

      /*
      * [Data Generator]
      */
      function generateData() {
        var glgroups = vm.getGlgroupColumnData();
        return streamLayers(glgroups.length,vm.rowOutlets.length).map(function(data, i) {
          return {
            key: glgroups[i],
            values: data
          };
        });
      }

      /**
      * [Inspired by Lee Byron's data generator.]
      * @param {Integer} n [Number of glgroups]
      * @param {Integer} m [Number of Outlets]
      */
      function streamLayers(n, m) {
        return d3.range(n).map(function(val) {
          var a = [],i;
          var glgroups = vm.getGlgroupColumnData();
          for (i = 0; i < m; i++){
            if(vm.rowOutlets[i]!=='Total'){
              if(vm.totalPurchaseVolume[vm.rowOutlets[i]][glgroups[val]])
                a.push(vm.totalPurchaseVolume[vm.rowOutlets[i]][glgroups[val]]);
              else
                a.push(0);
            }
          }
          return a.map(streamIndex);
        });
      }

      /**
      * [Returns the graph data]
      */
      function streamIndex(d, i) {
        return {x: i, y: Math.max(0, d)};
      }
    }

    /**
    * [getGlgroupColumnData Colomn header for the table group]
    */
    function getGlgroupColumnData(){
      vm.glgroupArr = [];
      vm.glgroups = _.map(vm.rowOutlets, function(each){
        _.map(Object.keys(vm.totalPurchaseVolume[each]), function(i){
          vm.glgroupArr.push(i);
        });
      });
      return _.uniqBy(_.compact(vm.glgroupArr));
    }

    /**
    * [getTotalperBO Calculates total per gl and its sum]
    */
    function getTotalperBO(){
      vm.totalGl = 0;
      var index = vm.rowOutlets.indexOf("Total");
      var row = vm.rowOutlets;
      if(index !== -1){
        row.splice(index,1);
      }
      var totalPerBoArray = null;
      vm.totalPerBo = [];
      _.map(row, function(each){
        totalPerBoArray = _.reduce(Object.values(vm.totalPurchaseVolume[each]), function(sum, n) {
          return (sum+n);
        }, 0);
        vm.totalPerBo.push(totalPerBoArray);
      });
      vm.totalGl = _.reduce(vm.totalPerBo, function(sum, n) {
        return (sum+n);
      }, 0);
    }

    function setLocalStorageItems(){
      var setItemList = {
        fromDate: !vm.fromDate ? '' : UTCDateService.utcFromDate(new Date(vm.fromDate)),
        toDate: !vm.toDate ? '' : UTCDateService.utcToDate(new Date(vm.toDate)),
        buyerBusinessIdList: !vm.filter.buyerBusinessIdList ? [] : vm.filter.buyerBusinessIdList,
        glSpecificItemGroupIdList: !vm.filter.glSpecificItemGroupIdList ? []: vm.filter.glSpecificItemGroupIdList,
      }

      localStorage.setItem('total_purchase_filter',JSON.stringify(setItemList));
    }
    /**
    * [loadAll loads all total purchase items]
    */
    function loadAll() {
      vm.getSelectedBusinessOutletsNames();
      setLocalStorageItems();
      BusinessReportService.getTotalPurchaseVolume({
        fromDate: !vm.fromDate ? '' : UTCDateService.utcFromDate(new Date(vm.fromDate)),
        toDate: !vm.toDate ? '' : UTCDateService.utcToDate(new Date(vm.toDate)),
        buyerBusinessIdList: !vm.filter.buyerBusinessIdList ? [] : vm.filter.buyerBusinessIdList,
        glSpecificItemGroupIdList: !vm.filter.glSpecificItemGroupIdList ? []: vm.filter.glSpecificItemGroupIdList,
      }).then(function(response) {
        vm.totalPurchaseVolume = response.data;
        vm.loadAllGlSpecificItemGroups();
        vm.glgroupArr = _.map(vm.glGroups, _.property('name'));
        vm.rowOutlets = _.orderBy(Object.keys(vm.totalPurchaseVolume), [], ['asc']);
        vm.getTotalperBO();
        vm.totalItems = response.headers('X-Total-Count');
        vm.multiBarChart(vm.rowOutlets);
      }, function(error) {
      });
    }
    // filter reset
    function resetFilters() {
        vm.fromDate = vm.todaysDate;
        vm.toDate = vm.todaysDate;
        vm.filter.buyerBusinessIdList = '';
        vm.filter.glSpecificItemGroupIdList = '';
        vm.totalPurchaseVolume = null;
        localStorage.removeItem("total_purchase_filter");
        //vm.options = {};
       // vm.data = [];
    }
  }
})();
