/**
* @Author: Ajay
* @Date:   18-May-2017 15:17 +05:30
* @Project: Ezyprocure
* @Filename: buyer.gl-mapping.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 07-Nov-2017 15:33 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('GlMappingController', GlMappingController);

  GlMappingController.$inject = ['$scope', '$rootScope', '$state', 'toastr', '$mdDialog', 'EXPORT_CONSTANTS', 'CSVExportService', 'GlMappingService', 'pagingParams', 'PurchaseOrderTypeService', 'GlSpecificItemGroupService', 'GlCodeService', 'ItemGroupService', 'paginationConstants', 'NotificationService'];

  function GlMappingController($scope, $rootScope, $state, toastr, $mdDialog, EXPORT_CONSTANTS, CSVExportService, GlMappingService, pagingParams, PurchaseOrderTypeService, GlSpecificItemGroupService, GlCodeService, ItemGroupService, paginationConstants, NotificationService) {
    var vm = this;
    vm.init = init;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.sort = sort;
    vm.loadAll = loadAll;
    vm.loadPurchaseOrderTypes = loadPurchaseOrderTypes;
    vm.loadItemGroups = loadItemGroups;
    vm.loadGlSpecificItemGroups = loadGlSpecificItemGroups;
    vm.loadGlCodes = loadGlCodes;
    vm.cancel = cancel;
    vm.deleteGlMapping = deleteGlMapping;
    vm.isGlMapped = isGlMapped;
    vm.generateGlCodeMappingCsv = generateGlCodeMappingCsv;
    vm.glMapping = {
      glCodes:{},
      glMapping:{},
      purchaseOrderTypes:{},
      glSpecificItemGroups:{}
    };
    vm.reload = reload;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.glCodeMappingCsvHeader = EXPORT_CONSTANTS.CSV.GlCodeMappingCsvExportHeader;

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function generateGlCodeMappingCsv() {
      if (!vm.totalItems) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return;
      }
      return CSVExportService.getGlCodeMappingsCsv(vm.glMappings);
    }

    // Load All gl mappings for logged in buyer
    function loadAll () {
      var glMappingObject = {
        glCodeId: (!vm.glMapping.glCodes) ? '':vm.glMapping.glCodes.id,
        glSpecificItemGroupId: (!vm.glMapping.glSpecificItemGroups) ? '':vm.glMapping.glSpecificItemGroups.id,
        itemGroupId: (!vm.glMapping.itemGroup) ? '':vm.glMapping.itemGroup.id,
        purchaseOrderTypeId: (!vm.glMapping.purchaseOrderTypes) ? '':vm.glMapping.purchaseOrderTypes.id
       } ;
      GlMappingService.getAllGlMappings({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
      },glMappingObject).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.glMappings = response.data;
      },function(error){
        NotificationService.error({
          title: 'GL Mapping Listing',
          error: error
        });
      });
    }

    function init() {
      vm.loadPurchaseOrderTypes();
      vm.loadItemGroups();
      vm.loadGlSpecificItemGroups();
      vm.loadGlCodes();
      vm.loadAll();
    }

    vm.init();

    /**
     * loadPurchaseOrderTypes gets all the Purchase Order Types
     */
    function loadPurchaseOrderTypes() {
      PurchaseOrderTypeService.getAllPurchaseOrderTypes({
        page: 0,
        size: paginationConstants.maxLimit
      }).then(function(response) {
        vm.purchaseOrderTypes = response.data;
      }, function(error) {
      });
    }

    /**
     * loadItemGroups gets all Item Groups
     */
    function loadItemGroups() {
      ItemGroupService.getAllItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.itemGroups = response.data;
      }, function(error) {
      });
    }

    /**
     * loadGlSpecificItemGroups gets all Gl Specific Item Groups
     */
    function loadGlSpecificItemGroups() {
      GlSpecificItemGroupService.getAllGlSpecificItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query:''
      }).then(function(response) {
        vm.glSpecificItemGroups = response.data;
      }, function(error) {
      });
    }

    /**
     *  loadGlCodes gets all Gl-Codes
     */
    function loadGlCodes() {
      GlCodeService.getAllGlCodes({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.glCodes = response.data;
      }, function(error) {
      });
    }

   /**
    * cancel used to close the modal
    */
    function cancel() {
      $mdDialog.cancel();
    }

    /**
     * isGlMapped returns true when the Gl Mapping exists
     * @param {*} glMapping
     */
    function isGlMapped(glMapping) {
      if(!glMapping.purchaseOrderTypes.id || !glMapping.itemGroup.id || !glMapping.glCodes.id || !glMapping.glSpecificItemGroups.id){
        return false; }
      GlMappingService.isGlMapped({
          purchaseOrderTypeId: vm.glMapping.purchaseOrderTypes.id,
          glSpecificItemGroupId: vm.glMapping.glSpecificItemGroups.id,
          itemGroupId: vm.glMapping.itemGroup.id,
          glCodeId: vm.glMapping.glCodes.id
      }).then(function (response) {
          vm.isAlreadyMapped = response;
      }, function (error) {
          NotificationService.error({
              title: 'GL Mapping isGlMapped',
              error: error
          });
      });
  }

  /**
   * deleteGlMapping unmaps the selected gl code mapping
   * @param {*} glMapping
   */
    function deleteGlMapping(glMapping) {
      GlMappingService.deleteGLMapping({
        purchaseOrderTypeId: glMapping.purchaseOrderTypes.id,
        glSpecificItemGroupId: glMapping.glSpecificItemGroups.id,
        itemGroupId: glMapping.itemGroup.id,
        glCodeId: glMapping.glCodes.id
      }).then(function(response) {
        $scope.$emit('ezyprocureApp:bsUnmapped', vm.glMapping);
        NotificationService.success({
          title: 'GL Mapping',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        vm.errorMessage = error.message ? error.message :'';
        if(vm.errorMessage){
          NotificationService.simpleErrorToast({
            title: 'GL Mapping Deletion',
            message: vm.errorMessage
          });
        }else{
          NotificationService.error({
            title: 'GL Mapping Deletion',
            error: error
          });
        }
      });
    }
    function reload(){
        vm.glMapping.purchaseOrderTypes.id = '';
        vm.glMapping.itemGroup.id = '';
        vm.glMapping.glCodes.id = '';
        vm.glMapping.glSpecificItemGroups.id = '';
        vm.loadAll();
    }
  }
})();
