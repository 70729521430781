/*
 * @Author: Jayalekshmi 
 * @Date: 2019-06-14 12:18:08 
 * @Project: Ezyprocure
 * @Filename: supplier.delivery-conditions.state.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-06-14 12:40:28
 */


 
(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .config(stateConfig);
  
    stateConfig.$inject = ['$stateProvider'];
  
    function stateConfig($stateProvider) {
      $stateProvider
      .state('supplier.tools.managedeliveryconditions', {
        parent: 'supplier.tools',
        url: '/deliveryconditions?page&sort&search',
        data: {
          authorities: ['ROLE_SUPPLIER'],
          pageTitle: 'Delivery Conditions'
        },
        views: {
          'content@': {
            templateUrl: 'app/supplier-modules/tools/manage-delivery-conditions/supplier.delivery-conditions.html',
            controller: 'SupplierDeliveryConditionController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,asc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
          }]
        }
      })
      .state('supplier.tools.managedeliveryconditions.add', {
        parent: 'supplier.tools.managedeliveryconditions',
        url: '/add',
        data: {
          authorities: ['ROLE_SUPPLIER'],
          pageTitle: 'Delivery Conditions'
        },
        views: {
          'content@': {
            templateUrl: 'app/supplier-modules/tools/manage-delivery-conditions/add/supplier.delivery-conditions.add.html',
            controller: 'SupplierAddDeliveryConditionController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,asc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('supplier');

            
            return $translate.refresh();
          }]
        }
      })
      .state('supplier.tools.managedeliveryconditions.edit', {
        parent: 'supplier.tools.managedeliveryconditions',
        url: '/edit/:deliverConditionId',
        data: {
          authorities: ['ROLE_SUPPLIER'],
          pageTitle: 'Delivery Conditions'
        },
        views: {
          'content@': {
            templateUrl: 'app/supplier-modules/tools/manage-delivery-conditions/edit/supplier.delivery-conditions.edit.html',
            controller: 'SupplierEditDeliveryConditionController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,asc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('supplier');
            return $translate.refresh();
          }]
        }
      });
    }
  
  })();
  