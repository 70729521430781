/**
* @Author: Ajay
* @Date:   20-Jan-2017 19:36 +05:30
* @Project: Ezyprocure
* @Filename: supplier.user-management.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 10-Nov-2017 11:37 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierUserListController', SupplierUserListController);

  SupplierUserListController.$inject = ['$scope', '$rootScope', '$state', 'NotificationService', '$mdDialog', 'SupplierUserManagementService', 'pagingParams', 'paginationConstants'];

  function SupplierUserListController ($scope, $rootScope, $state, NotificationService, $mdDialog, SupplierUserManagementService, pagingParams, paginationConstants) {
    var vm = this;

    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.deleteSupplierUser = deleteSupplierUser;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    //Sorting of supplier users
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    //Load all supplier users
    vm.loadAll = function(search) {
      if (!search) {
        vm.searchText = '';
      }
      SupplierUserManagementService.getAllSupplierUsers({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.searchText
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.supplierUsers = response.data;
      },function(error){
        NotificationService.error({
          title: 'Supplier User Listing',
          error: error
        });
      });
    };

    // Delete a supplier user. Function accepts supplierUserId as param
    function deleteSupplierUser(supplierUserId) {
      SupplierUserManagementService.deleteSupplierUser(supplierUserId).then(function(response){
        NotificationService.success({
          title: 'Supplier User',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      },function(error){
        NotificationService.error({
          title: 'Supplier User Deletion',
          error: error
        });
      });
    }

    vm.showConfirm = function(ev, supplierUser) {
      var confirm = $mdDialog.confirm()
            .title('Are you sure you want to delete this Supplier User?')
            .textContent('This action could not be reverted back.')
            .targetEvent(ev)
            .ok('Delete')
            .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        deleteSupplierUser(supplierUser.supplierUserId);
      }, function() {
      });
    };

    vm.loadAll();

  }
})();
