/**
 * @Author: Ajay
 * @Date:   24-Apr-2018 13:49 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.manage-approval-level.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 03-May-2018 11:57 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerManageApprovalLevelController', BuyerManageApprovalLevelController);

  BuyerManageApprovalLevelController.$inject = ['$scope', '$state', '$element', 'toastr', 'NotificationService', 'BuyerBusinessService', 'paginationConstants', 'BuyerManageApprovalLevelService'];

  function BuyerManageApprovalLevelController($scope, $state, $element, toastr, NotificationService, BuyerBusinessService, paginationConstants, BuyerManageApprovalLevelService) {
    var vm = this;
    vm.getAllBuyerBusinesses = getAllBuyerBusinesses;
    vm.proceedToManageApprovalLevelSelection = proceedToManageApprovalLevelSelection;

    //Load all buyer business outlets
    function getAllBuyerBusinesses() {
      if(vm.businessOutlets)
        return false;
      BuyerBusinessService.getAllBuyerBusinesses({
        page: 0,
        size: paginationConstants.maxLimit,
        query: '',
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.businessOutlets = response.data;
      },function(error){
        NotificationService.error({
          title: 'Business Outlet Listing',
          error: error
        });
      });
    }

    /**
     * [proceedToManageApprovalLevelSelection Proceed to level selection page]
     */
    function proceedToManageApprovalLevelSelection() {
      vm.approvalLevelFilter.buyerBusinessId = vm.approvalLevelFilter.selectedBusiness.id;
      BuyerManageApprovalLevelService.setApprovalLevelFilter(vm.approvalLevelFilter);
      $state.go('buyer.tools.manage-approval-level.level-selection',{});
    }

  }
})();
