/**
* @author: Abhay
* @date:   29-Mar-2021 15:05 +05:30
* @Project: Ezyprocure
* @Filename: admin.manage-xborder-consents.controller.js
* @Last modified by:   Abhay
* @Last modified time: 29-Mar-2021 15:05 +05:30
*/



(function () {
    'use strict';
  
    angular
      .module('ezyprocureApp')
      .controller('CrossBorderConsentsController', CrossBorderConsentsController);
  
    CrossBorderConsentsController.$inject = ['$scope', '$rootScope', '$state', '$q', '$mdDialog', 'toastr', 'GLOBAL_CONSTANTS', 'EXPORT_CONSTANTS', 'pagingParams', 'CSVExportService', 'UTCDateService', 'CrossBorderConsentsService', 'paginationConstants', 'NotificationService'];
  
    function CrossBorderConsentsController($scope, $rootScope, $state, $q, $mdDialog, toastr, GLOBAL_CONSTANTS, EXPORT_CONSTANTS, pagingParams, CSVExportService, UTCDateService, CrossBorderConsentsService, paginationConstants, NotificationService) {
      var vm = this;
      
      vm.predicate = pagingParams.predicate;
      vm.reverse = pagingParams.ascending;
      vm.query = {
        limit: paginationConstants.itemsPerPage,
        page: 1
      };
      vm.limitOptions = paginationConstants.limitOptions;
      vm.defaultDateTimeFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_TIME_FORMAT;
      vm.todaysDate = new Date();
      vm.filters = {
        fromDate: new Date(vm.todaysDate.getFullYear(), vm.todaysDate.getMonth(), 1, 0, 0, 0),
        toDate: vm.todaysDate,
        opsStatus: "OPEN"
      }
      vm.opsStatusList = GLOBAL_CONSTANTS.OPS_STATUS;
      vm.crossBorderConsentsHeader = EXPORT_CONSTANTS.CSV.crossBorderConsentsCsv;
      vm.consentsList = [];
      vm.sort = sort;
      vm.getAllConsents = getAllConsents;
      vm.updateOpsStatus = updateOpsStatus;
      vm.getConsentsListCsv = getConsentsListCsv;
    
      getAllConsents();
  
      function getAllConsents() {
        var params = {
          fromDate: !vm.filters.fromDate ? '' : UTCDateService.utcFromDate(vm.filters.fromDate),
          toDate: !vm.filters.toDate ? '' : UTCDateService.utcToDate(vm.filters.toDate),
          opsStatus: vm.filters.opsStatus,
          page: vm.query.page - 1,
          size: vm.query.limit,
          sort: sort()
        };
        CrossBorderConsentsService.getAllConsents(params).then(function (response) {
          vm.consentsList = response.data;
          vm.totalItems = response.headers('X-Total-Count');
        },
          function (error) {
            toastr.error('Internal Server Error', '500');
          });
      }

      function getConsentsListCsv() {
        var deferred = $q.defer();
        if (!vm.consentsList || !vm.consentsList.length) {
          NotificationService.simpleErrorToast({
            title: 'global.table.noRecordsFound',
            message: 'alertMessage.SEARCH_AND_SUBMIT'
          });
          return deferred.promise;
        }
        return CSVExportService.getConsentsListCsv(vm.consentsList);
      }

      function sort() {
        var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
        return result;
      }
  
      function updateOpsStatus(consent) {
        var params = {
          id: consent.id,
          opsStatus: consent.opsStatus
        }
        CrossBorderConsentsService.updateOpsStatus(params).then(function (response) {
          NotificationService.success({
            title: 'OPS Status',
            message: 'alertMessage.UPDATED_SUCCESSFULLY'
          });
          getAllConsents();
        },
          function (error) {
            toastr.error('Internal Server Error', '500');
          });
      }
  
    }
  
  
  })();
  