/*
 * @Author: Lovin Chacko 
 * @Date: 2021-01-06 14:36:45 
 * @Last Modified by: Lovin Chacko
 * @Last Modified time: 2021-02-03 16:29:50
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzypaymentAdminAXSPayments', EzypaymentAdminAXSPayments);

  EzypaymentAdminAXSPayments.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', 'toastr','pagingParams', 'UTCDateService', 'NotificationService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'BuyerBusinessService', 'ManageBuyerService', 'EzypaymentAdminStatementOfAccountService', 'BrandManagementService', 'ManageSupplierService', '$window', 'EXPORT_CONSTANTS', 'CSVExportService', 'EzypaymentAdminManageMerchantDetailsService', '$stateParams', 'EzypaymentAdminAXSPaymentsService'];

  function EzypaymentAdminAXSPayments($scope, $rootScope, $q, $filter, $state, toastr, pagingParams, UTCDateService, NotificationService, GLOBAL_CONSTANTS, paginationConstants, BuyerBusinessService, ManageBuyerService, EzypaymentAdminStatementOfAccountService, BrandManagementService, ManageSupplierService, $window, EXPORT_CONSTANTS, CSVExportService, EzypaymentAdminManageMerchantDetailsService, $stateParams, EzypaymentAdminAXSPaymentsService) {

    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.todaysDate = new Date();
    vm.loadAll = loadAll;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.listViews = GLOBAL_CONSTANTS.LIST_VIEWS;
    vm.limitOptions = paginationConstants.limitOptions;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.defaultDateTimeFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_TIME_FORMAT;
    vm.getAllBuyersBusinessOutlets = getAllBuyersBusinessOutlets;
    vm.loadDropdowns = loadDropdowns;
    vm.getAllBuyersMappedToPayment = getAllBuyersMappedToPayment;
    vm.getAllSuppliersMappedToPayment = getAllSuppliersMappedToPayment;
    vm.getAllBrandsOfBuyerList = getAllBrandsOfBuyerList;
    vm.filter = {};
    vm.checkAllRows = checkAllRows;
    vm.generateGiro = generateGiro;
    vm.getAx2ReportCsv = getAx2ReportCsv;
    vm.ax2PaymentCsvHeader = EXPORT_CONSTANTS.CSV.ax2PaymentCsvHeader;
    vm.readGiroResponseFiles = readGiroResponseFiles;
    vm.getAllCountryCurrencies = getAllCountryCurrencies;
    vm.chooseCurrency = $stateParams.currency;
    vm.transactionStatus = GLOBAL_CONSTANTS.TRANSACTION_STATUSES;
    vm.giroType =  GLOBAL_CONSTANTS.GIRO_TYPE;
    vm.payType =  GLOBAL_CONSTANTS.PAY_TYPE;
    vm.generateProcess = generateProcess;
    vm.check = false;
    vm.axsFinalResponse = axsFinalResponse;

    function axsFinalResponse(){
      EzypaymentAdminAXSPaymentsService.axsFinalResponse().then(function(){
        NotificationService.success({
          title: 'AXS Final Response',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
      },
      function(error){
        NotificationService.error({
          title: 'AXS Final Response',
          error: error
        });
      });
    }

    function generateProcess(){
      EzypaymentAdminAXSPaymentsService.generateProcess().then(function(){
        NotificationService.success({
          title: 'Generate Process',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        loadAll();
      },
      function(error){
        NotificationService.error({
          title: 'AXS Final Response',
          error: error
        });
      });
    }

    function getAx2ReportCsv(){
      var deferred = $q.defer();
      vm.selectedReports = _.filter(vm.nFrndsReports, _.matches({ 'selected': true }));
      if (!vm.nFrndsReports) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.reject;
      }else {
        var getStatementOfAccountReportCSVData = (vm.selectedReports.length <1)? vm.nFrndsReports: vm.selectedReports;
        return CSVExportService.getAx2PaymentsCsv(getStatementOfAccountReportCSVData);
      }
    }

   /**
    * [generateGiro creates GIRO files]
    * @param {Array} supplierIdList
    */
   function generateGiro(uploadType){
    vm.selectedReports =_.map( _.filter(vm.nFrndsReports, _.matches({ 'selected': true })), _.property('id'));

    var giroListDto = {
      giroList: vm.selectedReports,
      uploadHostToHost:uploadType,
      fast:vm.fast
    }
    EzypaymentAdminAXSPaymentsService.createGiroFile(giroListDto)
    .then(function(response){
      if(response){
        _.each(response, function(url){
            $window.open(url);
        });
      }
      NotificationService.success({
        title: 'Generate GIRO file',
        message: 'GIRO file is successfully generated'
      });
      vm.loadAll();
    },function(error){
      if(error.status == 503) {
        NotificationService.error({
          title: 'File upload failed into UOB server',
          error: error
        });
      }
      else {
        NotificationService.error({
          title: 'Could not generate GIRO',
          error: error
        });
      }
    });
   }

    /**
    * [selectAllInvoices select all Pending Process Orders]
    */
    function checkAllRows() {
      angular.forEach(vm.nFrndsReports, function (item) {
        item.selected = vm.selectAllRows;
      });
    }

    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
     * [getAllBuyers Gets all buyers mapped with the payment model]
     */
    function getAllBuyersMappedToPayment(){
      if(vm.buyers){ return false}
      ManageBuyerService.getAllAdminBuyersWithUserDetails({
        page: 0,
        size: paginationConstants.maxLimit,
        search: '',
        sort: sort()
      }).then(function(response) {
        vm.buyers = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'Buyer Listing',
          error: error
        });
      });
    }

    function getAllSuppliersMappedToPayment(){
      if(vm.suppliers){return false}
      ManageSupplierService.getAllAdminSuppliersWithUserDetails({
        page: 0,
        size: paginationConstants.maxLimit,
        search: '',
        visibilityType: 'ALLOCATED',
        sort: sort()
      }).then(function(response){
        vm.suppliers = response.data;
      },function(error){
        NotificationService.error({
          title: 'Supplier Listing',
          error: error
        });
      });
    }

    /**
     * [selectAll Selects all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, buyers or brands]
     */
    function selectAll(dropdown) {
      switch (dropdown) {
        case 'BUYERS':
          vm.filter.buyerIdList = _.map(vm.buyers, _.property('id'));
          break;
        case 'BRANDS':
          vm.filter.brandIdList = _.map(vm.brands, _.property('id'));
          break;
        case 'PAY_TYPE':
          vm.filter.payType = _.map(vm.payType, _.property('value'));
          break;
      }
    }

    /**
     * [deselectAll Deselect all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, buyers or brands]
     */
    function deselectAll(dropdown) {
      switch (dropdown) {
        case 'BUYERS':
          vm.filter.buyerIdList = [];
          break;
        case 'BRANDS':
          vm.filter.brandIdList = [];
          break;
        case 'PAY_TYPE':
          vm.filter.payType = [];
          break;
      }
    }

    /**
    * [getAllBuyersBusinessOutlets gets all businessoutlets for selected buyers]
    * @param {List} buyerIdList
    */
    function getAllBuyersBusinessOutlets(buyerIdList) {
      if (!buyerIdList || buyerIdList.length === 0) return false;
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        groupBy: 'buyer'
      };
      BuyerBusinessService.getAllBuyersBusinessOutlets(buyerIdList, params).then(function(response) {
        vm.buyerBusinessOutlets = response.data;
        vm.filter.businessOutletIdList = [];
      }, function(error) {
        NotificationService.error({
          title: 'Business Outlet Listing',
          error: error
        });
      });
    }
    /**
     * [getAllBrandsOfBuyerList Gets all brands of selected buyers]
     * @param {Array} buyerIdList
     */
    function getAllBrandsOfBuyerList(buyerIdList){
      if (buyerIdList && buyerIdList.length > 0) {
        var params = {
          page: 0,
          size: paginationConstants.maxLimit,
          query: '',
          buyerId: buyerIdList,
          sort: sort()
        };

        BrandManagementService.getAllBrandsOfBuyerList(params)
          .then(function (response) {
            vm.brands = response;
          }, function (error) {
            NotificationService.error({
              title: 'Brand Listing',
              error: error
            });
          });
      }
      else {
        vm.brands = [];
        vm.filter.brandIdList = [];
      }
    }

    /**
     * [Read Giro Response Files]
     */
    function readGiroResponseFiles(){
      EzypaymentAdminAXSPaymentsService.readGiroResponseFiles()
      .then(function(response){
        NotificationService.success({
          title: 'global.buttons.readGiroResponseFiles',
          message: 'alertMessage.COMPLETED_SUCCESSFULLY'
        });
        loadAll();
      },function(error){
        NotificationService.error({
          title: 'global.buttons.readGiroResponseFiles',
          error: error
        });
      });
    }

    /**
    * [loadDropdowns The function executes when the dropdown closes]
    */
    function loadDropdowns(){
      vm.getAllBuyersBusinessOutlets(vm.filter.buyerIdList);
      vm.getAllBrandsOfBuyerList(vm.filter.buyerIdList)
    }

   
    function loadAll() {
      EzypaymentAdminAXSPaymentsService.getAllStatementOfAccountReports({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        currency: vm.filter.currency,
        buyerIdList: !vm.filter.buyerIdList ? []: vm.filter.buyerIdList,
        brandIdList: !vm.filter.brandIdList ? []: vm.filter.brandIdList,
        fromDate: !vm.filter.fromDate ? UTCDateService.utcFromDate(vm.todaysDate) : UTCDateService.utcFromDate(vm.filter.fromDate),
        toDate: !vm.filter.toDate ? UTCDateService.utcToDate(vm.todaysDate) : UTCDateService.utcToDate(vm.filter.toDate),
        giroFileName: vm.filter.giroFileName,
        transactionStatus: !vm.filter.transactionStatus ? "" : vm.filter.transactionStatus,
        giroStatus:(vm.filter.giroStatus === 'ALL') ? '' : vm.filter.giroStatus ,
        giroType: vm.filter.giroType == 'ALL' ? "" : vm.filter.giroType,
        payType: !vm.filter.payType ? []: vm.filter.payType
       }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.nFrndsReports = response.data;
      },function(error){
        NotificationService.error({
          title: 'AXS Payments',
          error: error
        });
      });
    }

    /**
     * [getAllCountryCurrencies gets all country currencies]
     */
    function getAllCountryCurrencies(){
      EzypaymentAdminManageMerchantDetailsService.getAllCountryCurrencies().then(function(response){
        vm.currencies = response.data;
        if(vm.chooseCurrency == "USD"){
          vm.filter.currency = "USD";
        }
        else{
        vm.filter.currency=vm.currencies[0].currency;
      }
      vm.loadAll();
      },function(error){
      });
    }

    vm.getAllCountryCurrencies();
  }
})();
