/**
 * @Author: Ajay
 * @Date:   05-May-2017 11:36 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.dashboard.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 01-Jun-2017 12:35 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('buyer.dashboard', {
      parent: 'buyer',
      url: '/tabIndex=:tabIndex',
      data: {
        authorities: ['ROLE_BUYER']
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/dashboard/buyer.dashboard.html',
          controller: 'BuyerDashboardController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('buyerDashboard');
          $translatePartialLoader.addPart('itemApprovalStatus');
          $translatePartialLoader.addPart('paymentApprovalStatus');
          return $translate.refresh();
        }]
      }
    });
  }
})();
