/**
* @Author: Ajay
* @Date:   07-Mar-2017 15:17 +05:30
* @Project: Ezyprocure
* @Filename: supplier.email.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 10-Nov-2017 11:21 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierEmailController', SupplierEmailController);

  SupplierEmailController.$inject = ['$scope', '$state', '$stateParams', 'NotificationService', 'SupplierEmailService', 'BuyerSupplierMappingService', 'SupplierDataService', 'GLOBAL_CONSTANTS'];

  function SupplierEmailController($scope, $state, $stateParams, NotificationService, SupplierEmailService, BuyerSupplierMappingService, SupplierDataService, GLOBAL_CONSTANTS) {

    var vm = this;
    vm.init = init;
    vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
    vm.populateSelectedEmails = populateSelectedEmails;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.sendEmail = sendEmail;

    vm.subjectLimit = GLOBAL_CONSTANTS.emailConfiguration.SUBJECT_SIZE_LIMIT;
    vm.bodyLimit = GLOBAL_CONSTANTS.emailConfiguration.BODY_SIZE_LIMIT;

    /**
     * [getAllSupplierMappedBuyers Get all supplier mapped buyers]
     * @return {[list]} [List of buyers]
     */
    function getAllSupplierMappedBuyers() {
      if (vm.buyers) return false;
      var supplierId = vm.currentSupplierProfile.supplier.id;
      BuyerSupplierMappingService.getAllSupplierMappedBuyerList(supplierId).then(function(response) {
        vm.buyers = response.data;
      }, function(error) {
      });
    }


    /**
     * [init Load initial data]
     */
    function init() {
      vm.currentSupplierProfile = SupplierDataService.getSupplierProfile();
      vm.supplierName = vm.currentSupplierProfile.supplier.companyName;
      vm.emailDTO = {
        recipients: [],
        subject: '',
        body: ''
      };
    }

    /**
     * [selectAll Select all buyers from the list and create separate string array of emails]
     */
    function selectAll() {
      vm.buyerEmailList = _.map(vm.buyers, _.property('email'));
    }

    /**
     * [deselectAll Deselect all buyers from the list]
     */
    function deselectAll() {
      vm.buyerEmailList = [];
    }

    /**
     * [populateSelectedEmails Populate selected emails and union it to md-chips]
     */
    function populateSelectedEmails() {
      vm.emailDTO.recipients = _.union(vm.emailDTO.recipients, vm.buyerEmailList);
    }

    /**
     * [sendEmail Send email to recipients]
     */
    function sendEmail() {
      SupplierEmailService.sendEmailToBuyer(vm.emailDTO).then(function(response) {
        NotificationService.success({
          title: 'Supplier - Email to Buyer',
          message: 'alertMessage.SENT_SUCCESSFULLY'
        });
      }, function(error) {
        NotificationService.error({
          title: 'Supplier - Email to Buyer',
          error: error
        });
      });
    }

  }
})();
