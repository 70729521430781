/**
 * @Author: sneha
 * @Date:   23-Mar-2018 15:48 +05:30
 * @Project: Ezyprocure
 * @Filename: supplier.stock-report.service.js
 * @Last modified by:   sneha
 * @Last modified time: 06-Apr-2018 09:39 +05:30
 */




 (function() {
   'use strict';

   angular.module('ezyprocureApp').factory('StockReportsService', StockReportsService);

   StockReportsService.$inject = ['$http', '$q', 'API_CONSTANTS'];
   function StockReportsService($http, $q, API_CONSTANTS) {

     var stockReportsService = {
       getAllStockReports : getAllStockReports,
     };
     return stockReportsService;

     /**
      * [getAllPurchaseOrders gets PO list]
      * @param  {[type]} params [filter params]
      * @return {[object]}        [success/error message]
      */
     function getAllStockReports(businessReportRequestDTO, params) {
       var deferred = $q.defer();
       $http({
         url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'stock-details',
         method: "POST",
         data: businessReportRequestDTO,
         params:params,
       }).then(function (data) {
         deferred.resolve(data);
       }).catch(function (message) {
         deferred.reject(message);
       });
       return deferred.promise;
     }

   }
 })();
