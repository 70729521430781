/**
 * @Author: Elsa
 * @Date:   10-Mar-2017 12:51 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.goods-return-notice.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 12-Oct-2018 14:59 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerGoodsReturnNoticeController', BuyerGoodsReturnNoticeController);

  BuyerGoodsReturnNoticeController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', '$stateParams', 'toastr', '$mdDialog','pagingParams', 'UTCDateService', 'NotificationService', 'BuyerDataService', 'PurchaseOrderService', 'ItemGroupService', 'BuyerSupplierMappingService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'BuyerBuyNowService', 'CSVExportService', 'EXPORT_CONSTANTS'];

  function BuyerGoodsReturnNoticeController($scope, $rootScope, $q, $filter, $state, $stateParams, toastr, $mdDialog,pagingParams, UTCDateService, NotificationService, BuyerDataService, PurchaseOrderService, ItemGroupService, BuyerSupplierMappingService, GLOBAL_CONSTANTS, paginationConstants, BuyerBuyNowService, CSVExportService, EXPORT_CONSTANTS) {

    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.todaysDate = new Date();
    vm.loadAll = loadAll;
    vm.loadItemGroups=loadItemGroups;
    vm.getAllAllocatedSuppliersForBusiness = getAllAllocatedSuppliersForBusiness;
    vm.filter = {};
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.debitnoteStatuses = GLOBAL_CONSTANTS.DEBIT_NOTE_STATUSES;
    //Header
    vm.GoodsReturnNoticeHeader = EXPORT_CONSTANTS.CSV.BuyerGoodsReturnNoticeReport;
    //Function Declaration
    vm.getGoodsReturnDataCSV = getGoodsReturnDataCSV;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    //filter reset
    vm.resetFilters = resetFilters;
    //Getting CSV DATA
    function getGoodsReturnDataCSV(){
      var deferred = $q.defer();
      if (!vm.goodsReturnNotes) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getBuyerGoodsReturnNoticeReportCSV(vm.goodsReturnNotes);
    }

    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function loadItemGroups() {
      if(vm.itemGroups)
        return false;
      ItemGroupService.getAllItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.itemGroups = response.data;
      }, function(error) {
      });
    }

    /**
    * [getAllAllocatedSuppliersForBusiness Get all allocated suppliers of buyer business]
    */
    function getAllAllocatedSuppliersForBusiness() {
      if(vm.supplierList)
        return false;
      var params = {'businessId': !vm.filter.businessOutletId ? '' : vm.filter.businessOutletId};
      BuyerBuyNowService.getAllAllocatedSuppliersForBusiness(params).then(function(response){
        vm.supplierList = $filter('orderBy')(response.data, 'companyName');
        //if only one allocated supplier set it as default
        if(vm.supplierList.length === 1) {
          vm.filter.supplierId = vm.supplierList[0].id;
        }
      },function(error){
      });
    }

    /**
    * [loadAll Load All Receiving Order data]
    * @param  {[string]} search [search text]
    */
    function loadAll() {
      PurchaseOrderService.getAllPurchaseOrders({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: '',
        supplierId: !vm.filter.supplierId ? '' : vm.filter.supplierId,
        debitNoteStatus: vm.filter.debitnoteStatus,
        grnNumber: !vm.filter.grnNumber ? '': vm.filter.grnNumber,
        buyerIdList: '',
        buyerName: '',
        poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber,
        purchaseOrderStatus: '' ,
        invoiceStatus: '',
        invoiceNumber: '',
        businessOutletId: '' ,
        businessOutletName: '' ,
        creditNoteStatus: '' ,
        supplierVisibilityType: '',
        //If dates are selected, they are converted to UTC format
        poFromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
        poToDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
        deliveryFromDate: '',
        deliveryToDate: '',
        grnFromDate : '',
        grnToDate : '',
        itemGroupId: !vm.filter.itemGroupId ? '' :vm.filter.itemGroupId,
        xeroExported: '',
        module : 0
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.goodsReturnNotes = response.data;
      },function(error){
        NotificationService.error({
          title: 'Goods Return Notice Details',
          error: error
        });
      });
    }
    // filter reset
    function resetFilters() {
    vm.filter.poNumber = '';
    vm.filter.debitnoteStatus = "ALL";
    vm.filter.fromDate = vm.todaysDate;
    vm.filter.toDate = vm.todaysDate;
    vm.filter.supplierId = '';
    vm.filter.itemGroupId = '';
    vm.goodsReturnNotes = [];
    }
  }
})();
