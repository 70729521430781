/**
* @Author: Jayalekshmi R
* @Date:   2018-06-11T15:32:51+05:30
* @Project: Ezyprocure
* @Filename: buyer.brands.controller.js
 * @Last modified by: Jayalekshmi R
 * @Last modified time: 2018-06-14T15:49:53+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BrandListController', BrandListController);

  BrandListController.$inject = ['$scope', '$rootScope','$stateParams', '$state', 'toastr', 'NotificationService', '$mdDialog', 'BrandManagementService', 'pagingParams', 'paginationConstants', 'BuyerDataService', 'Auth'];

  function BrandListController($scope, $rootScope, $stateParams, $state, toastr, NotificationService, $mdDialog, BrandManagementService, pagingParams, paginationConstants, BuyerDataService, Auth) {
    var vm = this;

    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.deleteBrand = deleteBrand;
    vm.loadAll = loadAll;
    vm.showConfirm = showConfirm;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.deleteIsocodeAndIdentifier = deleteIsocodeAndIdentifier;
    vm.getBrandIsoCodeAndIdentifierById = getBrandIsoCodeAndIdentifierById;

    /**
     * [Sorts the Brands]
     */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
     * [Loads all brands for the Buyer.]
     * @param {String} search
     */
    function loadAll(search) {
      var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      if (!search) {
        vm.searchText = '';
      }
      BrandManagementService.getAllBrandsByBuyerId({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.searchText,
        buyerId : buyerId
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.brands = response.data;
      },function(error){
        NotificationService.error({
          title: 'Brand Listing',
          error: error
        });
      });
    }

    /**
     * [deleteBrand - Deletes a brand.]
     * @param {id} brandId
     */
    function deleteBrand(brandId) {
     if(Auth.hasPermission('accessForPEPPOL'))
        vm.getBrandIsoCodeAndIdentifierById(brandId);
      BrandManagementService.deleteBrand(brandId).then(function(response){
        NotificationService.success({
          title: 'Buyer User',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
        if(Auth.hasPermission('accessForPEPPOL'))
           vm.deleteIsocodeAndIdentifier();
      },function(error){
        NotificationService.error({
          title: 'Brand Deletion',
          error: error
        });
      });
    }

    /**
     * [deleteIsocodeAndIdentifier - Deletes ISO code and Identifier.]
     * @param {id} brandId
     */
    function deleteIsocodeAndIdentifier(){
      BrandManagementService.deleteBrandIsoCodeAndIdentifier(vm.ezyCompanyDetails.id).then(function(response){
        NotificationService.success({
          title: 'global.IsoCodeAndIdentifier',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
      },
      function(error){
        NotificationService.error({
          title: 'global.IsoCodeAndIdentifier',
          error: error
        });
      });
    }

    function getBrandIsoCodeAndIdentifierById(brandId){
      if(!Auth.hasPermission('accessForPEPPOL')) return;
      var params = {
        'ezyBrandId': brandId
      }
      BrandManagementService.getBrandIsoCodeAndIdentifier(params).then(function(response){
        vm.ezyCompanyDetails = response.data;
      },
      function(error){
        NotificationService.error({
          title: 'global.IsoCodeAndIdentifier',
          error: error
        });
      });
    }

    /**
     * [Shows confirmation and then deletes the brand]
     * @param {Object} event
     * @param {Object} brand
     */
    function showConfirm(event, brand) {
      var confirm = $mdDialog.confirm()
        .title('Are you sure you want to delete this Brand?')
        .textContent('This action cannot be reverted back.')
        .targetEvent(event)
        .ok('Delete')
        .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        vm.deleteBrand(brand.id);
      }, function() {
      });
    }
    vm.loadAll();
  }
})();
