/**
* @Author: Ajay
* @Date:   21-Apr-2017 17:54 +05:30
* @Project: Ezyprocure
* @Filename: image.upload.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 17-Jul-2017 11:27 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('ImageUploadService', ImageUploadService);

  ImageUploadService.$inject = ['$http', '$q', 'toastr', 'API_CONSTANTS', 'GLOBAL_CONSTANTS'];

  function ImageUploadService($http, $q, toastr, API_CONSTANTS, GLOBAL_CONSTANTS) {

    var imageUploadService = {
      checkFileType: checkFileType,
      checkFileSize: checkFileSize,
      getImageBase64: getImageBase64,
      uploadImage: uploadImage,
    };

    return imageUploadService;

    function checkFileType(attType) {
      var validImageType = (/(image\/)/g).test(attType) && (attType !== "image/gif");
      if (!validImageType) {
        toastr.error('Please upload .jpg, .jpeg or .png image files!', 'Invalid Image File Type');
        return false;
      } else return true;
    }

    function checkFileSize(fileSize) {
      if (fileSize > GLOBAL_CONSTANTS.IMAGE_UPLOAD.FILE_SIZE_LIMIT) {
        toastr.error('Please upload image files size below 2MB!', 'Image Upload File Size Error');
        return false;
      } else return true;
    }

    function getImageBase64(inputFile, callback) {
      if (this.checkFileType(inputFile.type) && this.checkFileSize(inputFile.size)) {
        var base64;
        var fileReader = new FileReader();
        fileReader.onload = function(event) {
          base64 = event.target.result;
          callback(base64);
        };
        fileReader.readAsDataURL(inputFile);
      }
    }

    //Upload Image - Function receives a base64Img as parameter
    function uploadImage(base64Img) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'image', base64Img).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
