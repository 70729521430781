/**
* @Author: Ajay
* @Date:   25-Jun-2018 10:25 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.supplier.state.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-07-22T13:58:18+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('ezypayment.supplier', {
      parent: 'app',
      url: '/ezypayment/supplier',
      data: {
        authorities: ['ROLE_SUPPLIER']
      },
      views: {
        'navbar@': {
          templateUrl: 'app/ezypayment-modules/supplier-modules/common/ezypayment.supplier.navbar.html',
          controller: 'EzyPaymentSupplierNavbarController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('brand');
          $translatePartialLoader.addPart('supplierDashboard');
          return $translate.refresh();
        }],
        hasModuleAccess: ['Auth','$q', '$state', function(Auth, $q, $state) {
          if(Auth.hasPermission('accessForPayViaUOBVPS')){
            return true;
          }else {
            return $q.reject('Unauthorized');
          }
        }]
      }
    });
  }
})();
