/**
* @Author: Ajay
* @Date:   21-Jan-2017 13:34 +05:30
* @Project: Ezyprocure
* @Filename: supplier.user-management.service.js
* @Last modified by:   Ajay
* @Last modified time: 05-Apr-2017 17:07 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('SupplierUserManagementService', SupplierUserManagementService);

  SupplierUserManagementService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  function SupplierUserManagementService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var supplierUserManagementService = {
      getAllSupplierUsers: getAllSupplierUsers,
      getSupplierUser: getSupplierUser,
      createSupplierUser: createSupplierUser,
      updateSupplierUser: updateSupplierUser,
      deleteSupplierUser: deleteSupplierUser,
      updateSupplierUserActivatedKey: updateSupplierUserActivatedKey
    };
    return supplierUserManagementService;

    /* --  Supplier User CRUID operations  -- */

    //Get All Supplier Users of logged in supplier
    function getAllSupplierUsers(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.SUPPLIER_USER_RESOURCE,
        method: "GET",
        params:params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get supplier user details using supplieruser id
    function getSupplierUser(supplierUserId) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.SUPPLIER_USER_RESOURCE + supplierUserId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Create a new supplier user
    function createSupplierUser(supplierUser) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.SUPPLIER_USER_RESOURCE, supplierUser).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update a supplier user
    function updateSupplierUser(supplierUser) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.SUPPLIER_USER_RESOURCE, supplierUser).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Soft Delete a supplier user details using supplieruser id
    function deleteSupplierUser(supplierUserId) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.SUPPLIER_USER_RESOURCE + supplierUserId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update a supplier user
    function updateSupplierUserActivatedKey(supplierUserId, params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.SUPPLIER_USER_RESOURCE + supplierUserId + '/account',
        method: "PUT",
        params: params,
      }).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
