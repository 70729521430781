/**
* @Author: Ajay
* @Date:   06-Jan-2017 16:03 +05:30
* @Project: Ezyprocure
* @Filename: message.constants.js
* @Last modified by:   Ajay
* @Last modified time: 27-Mar-2017 18:04 +05:30
*/



"use strict";
angular.module('ezyprocureApp')
.constant('MESSAGE_CONSTANTS', {
  "SUCCESS": {
    "ADDED_SUCCESSFULLY" : 'Added Successfully',
    "UPDATED_SUCCESSFULLY" : 'Updated Successfully',
    "SAVED_SUCCESSFULLY" : 'Saved Successfully',
    "DELETED_SUCCESSFULLY" : 'Deleted Successfully',
    "MAPPED_SUCCESSFULLY" : 'Mapped Successfully',
    "FILE_UPLOADED_SUCCESSFULLY" : 'File Uploaded Successfully',
    "PASSWORD_CHANGED_SUCCESSFULLY" : 'Password Changed Successfully',
  },
  "ERROR": {
    "USERNAME_EXIST" : 'Username already exists',
    "USER_EXIST" : "User already exists",
    "EMAIL_EXIST" : 'Email already exists',
    "COMPANY_NAME_EXIST" : 'Company name already exists',
    "SUPPLIER_NOT_FOUND" : 'Supplier not found',
    "BUYER_NOT_FOUND" : 'Buyer not Found',
    "CANNOT_DECREASE_LIMIT" : 'Limit cannot be decreased further',
    "MAPPING_EXIST" : "Mapping already exists",
    "MAPPING_NOT_FOUND" : 'Mapping not found',
    "LIMIT_REACHED" : 'Limit reached',
    "ITEM_GROUP_EXIST" : 'Item group already exists',
    "ITEM_GROUP_NOT_FOUND" : "Item group not found",
    "UOM_EXIST" : 'UOM already exists',
    "UOM_NOT_FOUND" : 'UOM not found',
    "INCORRECT_PASSWORD": "Incorrect password",
    "SAME_AS_CURRENT_PASSWORD": "Same as current password",
    "EMAIL_SENT": "Email was sent",
    "EMAIL_NOT_REGISTERED": "Email address not registered",
  }
});
