/*
 * @Author: Ajay 
 * @Date: 2019-02-11 11:33:48 
 * @Project: Ezyprocure
 * @Filename: ezyap.supplier.navbar.controller.js
 * @Last Modified by: Lovin
 * @Last Modified time: 2020-07-23 12:37:11
 */

(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzyapSupplierNavbarController', EzyapSupplierNavbarController);

  EzyapSupplierNavbarController.$inject = ['$rootScope', '$window', '$stateParams', '$timeout', '$state', 'Auth', 'SupplierAccountService', 'SupplierDataService', 'Redirect'];

  function EzyapSupplierNavbarController($rootScope, $window, $stateParams, $timeout, $state, Auth, SupplierAccountService, SupplierDataService, Redirect) {
    var vm = this;

    vm.getSupplierProfile = getSupplierProfile;
    vm.ezyap = Redirect.getHostMapping();
    $rootScope.back = back;

    // Gets the supplier profile
    function getSupplierProfile () {
      SupplierAccountService.getSupplierProfile().
      then(function(response) {
        if (response) {
          vm.supplierProfile = response;
          SupplierDataService.setSupplierProfile(vm.supplierProfile);
        }
      }, function(error) {
      });
    }

    vm.getSupplierProfile();

    function back() {
      $window.history.back();
    }

    vm.logout = function() {
      Auth.logout();
    };
  }
})();
