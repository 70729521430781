/*
 * @Author: Ajay 
 * @Date: 2019-04-16 11:23:48 
 * @Project: Ezyprocure
 * @Filename: ezypayment.admin.visa-payment.service.js
 * @Last Modified by: Ajay
 * @Last Modified time: 2019-05-08 16:54:10
 */

(function () {
  'use strict';

  angular.module('ezyprocureApp').factory('EzypaymentAdminVISAPaymentService', EzypaymentAdminVISAPaymentService);

  EzypaymentAdminVISAPaymentService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  function EzypaymentAdminVISAPaymentService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var EzypaymentAdminVISAPaymentService = {
      addPayee: addPayee,
      addPayeeKYC: addPayeeKYC,
      payment: payment,
      taxConsolidatedInvoice : taxConsolidatedInvoice,
      remittanceAdvise : remittanceAdvise,
      triggerNFriendsJob: triggerNFriendsJob,
      triggerJobNfriendsPayee: triggerJobNfriendsPayee,
      triggerJobNfriendsPayment: triggerJobNfriendsPayment,
      triggerJobNfriendsKyc: triggerJobNfriendsKyc
    };

    return EzypaymentAdminVISAPaymentService;

    function triggerNFriendsJob() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/payment/nfriends-sftp-job-manual-trigger',
        method: "GET",
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function triggerJobNfriendsPayee() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/payment/nfriends-sftp-payee-job-manual-trigger',
        method: "GET",
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function triggerJobNfriendsPayment() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/payment/nfriends-sftp-payment-job-manual-trigger',
        method: "GET",
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function triggerJobNfriendsKyc() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/payment/nfriends-sftp-job-KYC-trigger',
        method: "GET",
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [addPayee Adds Payee]
    */
    function addPayee() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/payment/visa-addpayee-manual-trigger',
        method: "GET",
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [addPayeeKYC Adds Payee KYC]
     */
    function addPayeeKYC() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/payment/visa-kyc-file-manual-trigger',
        method: "GET",
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [payment generates payment]
     */
    function payment() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/payment/visa-payment-file-manual-trigger',
        method: "GET"
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [taxConsolidatedInvoice generates Invoice]
     */
    function taxConsolidatedInvoice(month, year) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/tax-invoice/consolidated/' + month + "/" + year,
         method: "GET"
        }).then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

    /**
     * [remittanceAdvise generates Invoice]
     */
    function remittanceAdvise() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/remittance-advise/payment',
       method: "GET"
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
