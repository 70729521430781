/**
 * @Author: sneha
 * @Date:   2019-10-01T12:01:28+05:30
 * @Project: Ezyprocure
 * @Filename: ezypayment.sip-upload-invoices.service.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-10-01T17:27:30+05:30
 */




 (function() {
   'use strict';

   angular.module('ezyprocureApp').factory('EzypaymentSupplierSIPUploadInvoices', EzypaymentSupplierSIPUploadInvoices);

   EzypaymentSupplierSIPUploadInvoices.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

   function EzypaymentSupplierSIPUploadInvoices($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS, CardsService) {

     var EzypaymentSupplierSIPUploadInvoices = {
       uploadSipInvoices: uploadSipInvoices
     };

     return EzypaymentSupplierSIPUploadInvoices;

     /**
      * [uploadSipInvoices Uploads the sip invoice csv]
      * @param {*} file 
      */
     function uploadSipInvoices(file){
       var invoiceCsvFormData = new FormData();
       invoiceCsvFormData.append('file', file);
       var deferred = $q.defer();
       $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'sip/invoices/upload', invoiceCsvFormData,   {
         transformRequest: angular.identity,
         headers: {'Content-Type': undefined}
       }).then(function(data) {
         deferred.resolve(data);
       }).catch(function(message) {
         deferred.reject(message);
       });
       return deferred.promise;
     }

   }
 })();
