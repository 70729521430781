/**
* @Author: Mufsir
* @Date:   27-Dec-2016 15:40 +05:30
* @Project: Ezyprocure
* @Filename: admin.manage-limits.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 06-Nov-2017 14:41 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('AdminManageLimitsController', AdminManageLimitsController);

  AdminManageLimitsController.$inject = ['$stateParams', '$scope', '$timeout', '$state', 'ManageBuyerService', 'ManageSupplierService', 'AdminManageLimitsService', 'NotificationService'];

  function AdminManageLimitsController($stateParams, $scope, $timeout, $state, ManageBuyerService, ManageSupplierService, AdminManageLimitsService, NotificationService) {

    var vm = this;
    vm.userType;
    vm.loadAllAdminBuyers = loadAllAdminBuyers;
    vm.loadAllAdminSuppliers = loadAllAdminSuppliers;
    vm.init = init;
    vm.userTypeChanged = userTypeChanged;
    vm.selectedSupplierChanged = selectedSupplierChanged;
    vm.selectedBuyerChanged = selectedBuyerChanged;
    vm.updateLimit = updateLimit;

    //Fetch all Buyers
    function loadAllAdminBuyers() {
      ManageBuyerService.getAllAdminBuyers().then(function(response) {
        vm.buyers = response.data;
      }, function(error) {
      });
    }

    //Fetch all Suppliers
    function loadAllAdminSuppliers() {
      var params = {
        visibilityType: 'all'
      };
      ManageSupplierService.getAllAdminSuppliers(params).then(function(response) {
        vm.suppliers = [];
        _.forEach(response.data, function(supplier){
          if(supplier.visibilityType != 'ADHOC_OFF_BOARD_SUPPLIER'){
            vm.suppliers.push(supplier);
          }
         });
      }, function(error) {
      });
    }

    //Initialize user type checkbox to buyer
    function init() {
      vm.userType = 'buyer';
      vm.loadAllAdminBuyers();
    }

    //fetch buyer or supplier when selection changed
    function userTypeChanged() {
      vm.searchText = '';
      vm.currentLimit = null;
      vm.selectedBuyer = null;
      vm.selectedSupplier = null;
      if (vm.userType === 'buyer' && angular.isUndefined(vm.buyers)) {
        vm.loadAllAdminBuyers();
      } else if (vm.userType === 'supplier' && angular.isUndefined(vm.suppliers)) {
        vm.loadAllAdminSuppliers();
      }
    }

    //change limit when supplier changed
    function selectedSupplierChanged(supplier) {
      vm.currentLimit = supplier.buyersLimit;
    }

    //change limit when buyer changed
    function selectedBuyerChanged(buyer) {
      vm.currentLimit = buyer.suppliersLimit;
    }

    //function to update limit
    function updateLimit() {
      vm.limitData = {};
      vm.limitData.limit = vm.count;

      //update supplier limit for buyer
      if (vm.userType === 'buyer') {
        vm.limitData.id = vm.selectedBuyer.id;
        AdminManageLimitsService.updateSupplierLimitForBuyer(vm.limitData).then(function(response) {
          NotificationService.success({
            title: 'Supplier limit for buyer',
            message: 'alertMessage.UPDATED_SUCCESSFULLY'
          });
          vm.searchText = null;
          vm.count = '';
          vm.loadAllAdminBuyers();

          //reset form after updating
          if ($scope.manageLimitsForms) {
            $scope.manageLimitsForms.$setPristine();
            $scope.manageLimitsForms.$setUntouched();
          }
        }, function(error) {
          NotificationService.error({
            title: 'Update supplier limit for buyer',
            error: error
          });
        });
      }

      //update buyer limit for supplier
      else if (vm.userType === 'supplier') {
        vm.limitData.id = vm.selectedSupplier.id;
        AdminManageLimitsService.updateBuyerLimitForSupplier(vm.limitData).then(function(response) {
          NotificationService.success({
            title: 'Buyer limit for supplier',
            message: 'alertMessage.UPDATED_SUCCESSFULLY'
          });
          vm.searchText = null;
          vm.count = '';
          vm.loadAllAdminSuppliers();

          //reset form after updating
          if ($scope.manageLimitsForms) {
            $scope.manageLimitsForms.$setPristine();
            $scope.manageLimitsForms.$setUntouched();
          }
        }, function(error) {
          NotificationService.error({
            title: 'Update buyer limit for supplier',
            error: error
          });
        });
      }
    }

    vm.init();


  }
})();
