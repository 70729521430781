/**
 * @Author: Abhay
 * @Date:   06-Jan-2021 12:32 +05:30
 * @Project: Ezyprocure
 * @Filename: ezypayment.card-tokenize.state.js
 * @Last modified by:   Abhay
 * @Last modified time: 06-Jan-2021 12:32 +05:30
 */



(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .config(stateConfig);
  
    stateConfig.$inject = ['$stateProvider'];
  
    function stateConfig($stateProvider) {
      $stateProvider
      .state('ezypayment.buyer.card-tokenization', {
        parent: 'ezypayment.buyer',
        url: '/confirm-detail/:userrole',
        data: {
          authorities: ['ROLE_BUYER']
        },
        views: {
          'content@': {
            templateUrl: 'app/ezypayment-modules/buyer-modules/card-tokenize/ezypayment.card-tokenize.html',
            controller: 'CardTokenizeController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
          }]
        }
      })
      .state('ezypayment.buyer.transaction-response', {
        parent: 'ezypayment.buyer',
        url: '/transaction-response',
        data: {
          authorities: ['ROLE_BUYER']
        },
        views: {
          'content@': {
            templateUrl: 'app/ezypayment-modules/buyer-modules/card-tokenize/ezypayment.card-tokenize.transaction-response.html',
            controller: 'CardTokenizeResponseController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
          }]
        }
      })
    }
  
  })();
  