/**
 * @Author: lovinc
 * @Date:   2018-08-27T10:38:06+05:30
 * @Project: Ezyprocure
 * @Filename: buyer.item-business-mapping.state.js
 * @Last modified by:   lovinc
 * @Last modified time: 2018-08-29T13:19:35+05:30
 */



(function() {
    'use strict';

    angular
    .module('ezyprocureApp')
    .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
      $stateProvider

      .state('buyer.item-management.item-business-mapping', {
        parent: 'buyer.item-management',
        url: '/mapping',
        data: {
          authorities: ['ROLE_BUYER'],
          pageTitle: 'Item Buyer Mapping'
        },
        views: {
          'content@': {
            templateUrl: 'app/buyer-modules/item-management/item-business-mapping/buyer.item-business-mapping.html',
            controller: 'BuyerItemOutletMappingController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,asc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('supplier');
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('itemBuyerMapping');
            $translatePartialLoader.addPart('items');
            $translatePartialLoader.addPart('brand');
            return $translate.refresh();
          }],
          hasModuleAccess: ['Auth','$q', '$state', function(Auth, $q, $state) {
            if(Auth.hasPermission('accessForItemBuyerMapping')){
              return true;
            }else {
              return $q.reject('Unauthorized');
            }
          }]
        }
      })
      .state('buyer.item-management.item-business-mapping.add', {
        parent: 'buyer.item-management.item-business-mapping',
        url: '/add',
        data: {
          authorities: ['ROLE_BUYER'],
          pageTitle: 'Add new Item Mapping'
        },
        views: {
          'content@': {
            templateUrl: 'app/buyer-modules/item-management/item-business-mapping/add/buyer.item-business-mapping.add.html',
            controller: 'AddBuyerItemOutletMappingController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('supplier');
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('itemBuyerMapping');
            $translatePartialLoader.addPart('items');
            return $translate.refresh();
          }]
        }
      });
    }
  })();
