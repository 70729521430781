/**
* @Author: Allan
* @Date:   24-Feb-2017 12:40 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.supplier.navbar.controller.js
 * @Last modified by:   allan
 * @Last modified time: 27-August-2018 15:30 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzyPaymentSupplierNavbarController', EzypaymentSupplierNavbarController);

  EzypaymentSupplierNavbarController.$inject = ['$rootScope', '$window', '$stateParams', '$timeout', '$state', 'Auth', 'SupplierAccountService', 'SupplierDataService'];

  function EzypaymentSupplierNavbarController ($rootScope, $window, $stateParams, $timeout, $state, Auth, SupplierAccountService, SupplierDataService) {
    var vm = this;

    vm.getSupplierProfile = getSupplierProfile;
    $rootScope.back = back;

    function getSupplierProfile () {
      SupplierAccountService.getSupplierProfile().
      then(function(response) {
        if (response) {
          vm.supplierProfile = response;
          SupplierDataService.setSupplierProfile(vm.supplierProfile);
        }
      }, function(error) {
      });
    }

    vm.getSupplierProfile();

    function back() {
      $window.history.back();
    }

    vm.logout = function() {
      Auth.logout();
    };
  }
})();
