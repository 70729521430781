/*
 * @Author: Jayalekshmi
 * @Date: 2019-11-20 10:26:07
 * @Project: Ezyprocure
 * @Filename: buyer.stock-transfer.add.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-12-04 12:33:46
 */

(function(){
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerStockTransferAddController', BuyerStockTransferAddController);

  BuyerStockTransferAddController.$inject = ['$state', '$filter', '$q', 'ItemBuyerMappingService', 'BuyerDataService', 'paginationConstants', 'BuyerStockTransferService', 'BuyerBusinessService', 'NotificationService'];

  function BuyerStockTransferAddController($state, $filter, $q, ItemBuyerMappingService, BuyerDataService, paginationConstants, BuyerStockTransferService, BuyerBusinessService, NotificationService){

    var vm = this;
    vm.addNewStockTransferRow = addNewStockTransferRow;
    vm.deleteStockTransferRow = deleteStockTransferRow;
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.save = save;
    vm.itemChanged = itemChanged;
    vm.searchForItems = searchForItems;
    vm.getStockTransferUoms = getStockTransferUoms;
    vm.buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
    vm.stockTransferFormDTO = {};
    vm.items = [{}];
    vm.todaysDate = new Date();
    vm.getDocumentNumber = getDocumentNumber;
    vm.calculateRequestedCost = calculateRequestedCost;
    vm.calculateTransferredCost = calculateTransferredCost;
    vm.getDocumentNumber();
    vm.accessForStockTransferCostingView = BuyerDataService.getBuyerProfile().accessDTO.accessForStockTransferCostingView;

    /**
     * [getDocumentNumber Gets the document number]
     */
    function getDocumentNumber(){
      BuyerStockTransferService.sequenceGenerator()
      .then(function(response){
        vm.stockTransferFormDTO.documentNumber = response.data.sequence;
      }, function(error){
        NotificationService.error({
          title: 'Stock Transfer',
          error: error
        });
      })
     }

     /**
      * [Saves the stock transfer]
      */
     function save(){
      vm.stockTransferFormDTO.items = _.map(vm.items, function(item){
        return {
          "itemId":item.itemId,
          "quantityTransferred":item.requestedQuantity ? item.requestedQuantity : null,
          "quantityReceived": item.quantityTransferred ? item.quantityTransferred : null,
          "cost": item.requestedCost ? item.requestedCost : null,
          "receivedCost": item.transferCost ? item.transferCost : null,
          "uom": item.uom
        }
      });
      BuyerStockTransferService.createStockTransfer(vm.stockTransferFormDTO)
      .then(function(response){
        $state.go('buyer.tools.stock-transfer',{reload:false})
        NotificationService.success({
          title: 'Stock Transfer',
          message: 'Successfully Created'
        });
      },function(error){
        NotificationService.error({
          title: 'Stock Transfer',
          error: error
        });
      })
     }

     /**
      * [addNewStockTransferRow Adds new row]
      */
    function addNewStockTransferRow() {
      vm.items.push({});
    }

    /**
     * [deleteStockTransferRow Deletes the specific row]
     * @param {Integer} index
     */
    function deleteStockTransferRow(index) {
      vm.items.splice(index, 1);
    }

    /**
    * [getAllBuyerBusinessOutlets Get list of Business outlets of the logged in buyer]
    */
    function getAllBuyerBusinessOutlets() {
      if(vm.businessOutlets)
        return false;
      BuyerBusinessService.getAllBuyerBusinesses({
        query: '',
        page: 0,
        size: paginationConstants.maxLimit
      }).then(function(response){
        vm.businessOutlets = response.data;
      },function(error){
      });
    }

    /**
     * [itemChanged Item changed]
     * @param {Object} stockItem
     * @param {Integer} index
     */
    function itemChanged(stockItem) {
      var temp = angular.copy(stockItem);
      stockItem.itemName = temp.selectedItem.items.itemName;
      stockItem.itemCode = temp.selectedItem.buyerItemCode ? temp.selectedItem.buyerItemCode : temp.selectedItem.items.itemCode;
      stockItem.itemId = temp.selectedItem.items.id;
      stockItem.uom = temp.selectedItem.inventoryUom || temp.selectedItem.buyerUom || temp.selectedItem.items.uom.name;
    }


    /**
     * [searchForItems Search items ]
     */
    function searchForItems(queryText) {
      var deferred = $q.defer();
      if(!queryText) {
        return false;
      }
      else {
        BuyerStockTransferService.searchItems({
          query: queryText,
        }).then(function(response){
          deferred.resolve($filter('orderBy')(response.data, 'itemName'));
        },function(error){
          deferred.reject(error);
        });
      }
      return deferred.promise;
    }

    /**
     * [searchForUoms Search Uoms ]
     */
    function getStockTransferUoms(queryText) {
      var deferred = $q.defer();
      if(!queryText) {
        return false;
      }
      else {
        BuyerStockTransferService.searchUoms({
          query: queryText,
        }).then(function(response){
          deferred.resolve($filter('orderBy')(response.data, 'name'));
        },function(error){
          deferred.reject(error);
        });
      }
      return deferred.promise;
    }

    /**
    * [calculateRequestedCost RequestedCost changed]
    * @param {Object} stockItem
    * @param {Integer} index
    */
    function calculateRequestedCost(stockItem){
    var temp = angular.copy(stockItem);
    stockItem.requestedCost = (temp.requestedQuantity * temp.selectedItem.price);
    }

    function calculateTransferredCost(stockItem){
    var temp = angular.copy(stockItem);
    stockItem.transferCost = temp.quantityTransferred * temp.selectedItem.price;
    }

  }
})();
