/**
* @Author: Lovin
* @Date:   27-feb-2020 13:10 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.ezyap.admin.navbar.controller.js
* @Last modified by: Lovin
* @Last modified time: 27-feb-2020 13:10 +05:30
*/



(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('EzyaptAdminNavbarController', EzyaptAdminNavbarController);

  EzyaptAdminNavbarController.$inject = ['$rootScope', '$window', 'Auth', 'Redirect'];

  function EzyaptAdminNavbarController($rootScope, $window, Auth, Redirect) {
    var vm = this;
    vm.logout = logout;
    vm.ezyap = Redirect.getHostMapping();
    $rootScope.back = back;

    function logout() {
      Auth.logout();
    }

    function back() {
      $window.history.back();
    }

  }
})();
