/**
* @Author: sneha
* @Date:   19-Apr-2018 11:59 +05:30
* @Project: Ezyprocure
* @Filename: supplier.picking-list.service.js
 * @Last modified by:   sneha
 * @Last modified time: 10-May-2018 22:28 +05:30
*/


(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('SupplierPickingListService', SupplierPickingListService);

  SupplierPickingListService.$inject = ['$http', '$q', 'API_CONSTANTS'];

  function SupplierPickingListService($http, $q, API_CONSTANTS) {

    var supplierPickingListService = {
      addItemsToList: addItemsToList,
      getListItems: getListItems,
      deselectItems: deselectItems,
      createPickingList: createPickingList,
      getAllPickingList: getAllPickingList,
      getPickingListById: getPickingListById,
      deletePickingListById: deletePickingListById,
      editPickingList: editPickingList,
      printPickingList: printPickingList
    };
    var templateItemList={};

    return supplierPickingListService;

    // get selected items from item-select page
    function getSelectedItems(items){
      var itemIds = [];
      //Fetch all ids of supplierItem in vm.supplierItems where supplierItem.selected = true
      var selectedItems = _.filter(items, {
        'selected': true
      });
      itemIds = _.map(selectedItems,  function(item){
        return {
          'id' : item.id,
          'itemName': item.itemName,
          'selected': true
        }
      })
      return itemIds;
    }

    // add selected items to picking list object
    function addItemsToList(itemsList) {
      templateItemList.pickingList = itemsList.pickingList ? itemsList.pickingList  : {};
      templateItemList.items = getSelectedItems(itemsList.items ? itemsList.items : itemsList);
      return templateItemList;
    }

    // get picking list object
    function getListItems(){
      return templateItemList;
    }

    // deselect all supplier items
    function deselectItems(supplierItems){
      var supplierItemsList = supplierItems;
      _.map(supplierItemsList, function(supplierItem) {
        supplierItem.selected = false;
      });
      templateItemList = {};
    }

    // create new picking list template
    function createPickingList(pickingList) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + "picking-lists", pickingList).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    // get all picking list templates
    function getAllPickingList(params){
      templateItemList={};
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'picking-lists',
        method: "GET",
        params: params
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    // get picking list template by Id
    function getPickingListById(templateId){
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'picking-lists/' + templateId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    // delete picking list template by id
    function deletePickingListById(templateId) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'picking-lists/' + templateId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    // edit picking list template
    function editPickingList(pickingList) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + "picking-lists", pickingList).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //print picking list for individual process order
    function printPickingList(data, params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + "picking-lists/print",
        method: "POST",
        data: data,
        params:params,
      }).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
