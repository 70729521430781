/*
 * @Author: Lovin C 
 * @Date: 2021-07-29 15:29:59 
 * @Last Modified by: Lovin C
 * @Last Modified time: 2021-09-01 10:56:02
 */

(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('ezypayment.supplier.collections', {
      parent: 'ezypayment.supplier',
      url: '/collections',
      data: {
        authorities: ['ROLE_SUPPLIER']
      },
      views: {
        'content@': {
          templateUrl: 'app/ezypayment-modules/supplier-modules/collections/ezypayment.supplier.collections.html',
          controller: 'EzypaymentCollectionsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('invoiceOCR');
          $translatePartialLoader.addPart('externalPayment');
          $translatePartialLoader.addPart('invoice');
          return $translate.refresh();
        }],
        hasModuleAccess: ['Auth','$q', '$state', function(Auth, $q, $state) {
          if(Auth.hasPermission('accessForPayViaUOBVPS')){
            return true;
          }else {
            return $q.reject('Unauthorized');
          }
        }]
      }
    })
    .state('ezypayment.supplier.collections.details', {
      parent: 'ezypayment.supplier.collections',
      url: '/details?type&selectedInvoiceIds?ocbcPaymentType',
      data: {
        authorities: ['ROLE_SUPPLIER']
      },
      views: {
        'content@': {
          templateUrl: 'app/ezypayment-modules/supplier-modules/collections/details/ezypayment.supplier.collections.details.html',
          controller: 'EzypaymentCollectionsDetailsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return true;
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    })
    .state('ezypayment.supplier.collections.details.transaction-report', {
      parent: 'ezypayment.supplier.collections.details',
      url: '/transaction-report',
      data: {
        authorities: ['ROLE_SUPPLIER']
      },
      views: {
        'content@': {
          templateUrl: 'app/ezypayment-modules/supplier-modules/collections/details/ezypayment.supplier.collections.details.transaction-report.html',
          controller: 'EzypaymentCollectionsDetailsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return true;
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    })
  }
})();
