/*
 * @Author: Lovin C 
 * @Date: 2021-07-29 15:28:24 
 * @Last Modified by: Lovin C
 * @Last Modified time: 2021-08-16 12:12:32
 */

(function () {
  'use strict';

  angular.module('ezyprocureApp').factory('PayInvoicesService', PayInvoicesService);

  PayInvoicesService.$inject = ['$http', '$q', 'API_CONSTANTS'];

  function PayInvoicesService($http, $q, API_CONSTANTS) {

    var PayInvoicesService = {
      setSelectedPOIds: setSelectedPOIds,
      getSelectedPOIds: getSelectedPOIds,
      setPaymentResponse: setPaymentResponse,
      getPaymentResponse: getPaymentResponse,
      setInvoiceType: setInvoiceType,
      getInvoiceType: getInvoiceType,
      getOcbcDuePaymentSummary: getOcbcDuePaymentSummary,
      getDDAReference: getDDAReference,
      ocbcConfirmPayment: ocbcConfirmPayment,
      getExternalInvoices: getExternalInvoices,
      getExternalDuePaymentSummary: getExternalDuePaymentSummary,
      ddaValidation: ddaValidation
    };

    var selectedPOIds = null;
    var paymentResponse = null;
    var invoiceType = null;
    return PayInvoicesService;

    function ddaValidation(params, data) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'ocbc/dda/reference/validate',
        method: "POST",
        params: params,
        data: data
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getExternalInvoices(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'external-payments-invoices/',
        method: "GET",
        params: params
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });

      return deferred.promise;
    }

    function getExternalDuePaymentSummary(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'ocbc/external-payments-invoices/due-payment-summary',
        method: "GET",
        params: params,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });

      return deferred.promise;
    }

    function getOcbcDuePaymentSummary(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'ocbc/fast/collection/due-payment-summary',
        method: "GET",
        params: params,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function setSelectedPOIds(params) {
      selectedPOIds = params;
    }

    function getSelectedPOIds() {
      return selectedPOIds ? selectedPOIds : null;
    }

    function ocbcConfirmPayment(params, duePaymentSummaryDetails, otp) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'ocbc/fast/transaction/collection',
        method: "POST",
        data: duePaymentSummaryDetails,
        params: params,
        headers: { 'authorization-otp' : otp }
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function setPaymentResponse(params) {
      paymentResponse = params;
    }

    function getPaymentResponse() {
      return paymentResponse ? paymentResponse : null;
    }

    function setInvoiceType(params) {
      invoiceType = params;
    }

    function getInvoiceType() {
      return invoiceType ? invoiceType : null;
    }

    function getDDAReference(brandId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'ocbc/dda/reference',
        method: "GET",
        params: brandId
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
