/**
 * @Author: Hareesh
 * @Date:   08-Sep-2017 10:12 +05:30
 * @Filename: supplier.purchase-request.consolidated-details.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-06-24T15:12:02+05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierPurchaseRequestConsolidatedDetailsController', SupplierPurchaseRequestConsolidatedDetailsController);

  SupplierPurchaseRequestConsolidatedDetailsController.$inject = ['$scope', '$rootScope', '$filter', '$state', '$stateParams', 'PurchaseOrderService', 'UTCDateService', 'PDFExportService', 'EXPORT_CONSTANTS', 'CSVExportService', 'pagingParams', 'NotificationService', 'GLOBAL_CONFIG_CONSTANTS', 'Auth'];

  function SupplierPurchaseRequestConsolidatedDetailsController($scope, $rootScope, $filter, $state, $stateParams, PurchaseOrderService, UTCDateService, PDFExportService, EXPORT_CONSTANTS, CSVExportService, pagingParams, NotificationService, GLOBAL_CONFIG_CONSTANTS, Auth) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.loadAll = loadAll;
    vm.fromDate = new Date($stateParams.fromDate);
    vm.toDate = new Date($stateParams.toDate);
    vm.prStatusClass = GLOBAL_CONFIG_CONSTANTS.PROCESS_ORDER_STATUS.LABEL_CLASS;
    vm.accessForPriceDisplay = Auth.hasPermission('accessForPriceDisplay');

    function loadAll() {
      PurchaseOrderService.getConsolidatedPRDetails({
        fromDate: !vm.fromDate ? '' : UTCDateService.utcFromDate(vm.fromDate),
        toDate: !vm.toDate ? '' : UTCDateService.utcToDate(vm.toDate),
        reportType: "PR",
        businessOutletId: $stateParams.businessOutletId,
        itemGroupId: $stateParams.itemGroupId,
        prNumber: $stateParams.prNumber,
        buyerIdList: $stateParams.buyerIdList,
        query: $stateParams.itemName,
        purchaseRequestStatus: $stateParams.prStatus
      }).then(function(response){
        vm.consolidatedPRDetails = response.data;
      },function(error){
        NotificationService.error({
          title: 'Fetching Consolidated PR Details',
          error: error
        });
      });
    }

    vm.loadAll();

  }
})();
