/**
* @Author: Allan
* @Project: Ezyprocure
* @Filename: ezypayment.admin.manage-merchant-details.state.js
* @Last modified by:   Allan
* @Last modified time: 2018-10-09T15:56:02+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('ezypayment.admin.merchant-management', {
      parent: 'ezypayment.admin',
      url: '/merchant-management',
      data: {
        authorities: ['ROLE_ADMIN']
      },
      views: {
        'content@': {
          templateUrl: 'app/ezypayment-modules/admin-modules/manage-merchant-details/ezypayment.admin.manage-merchant-details.html',
          controller: 'EzypaymentAdminManageMerchantDetailsController',
          controllerAs: 'vm'
        }
      },
      params: {
        initialActive : false,
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('brand');
          $translatePartialLoader.addPart('merchantDetails');
          return $translate.refresh();
        }]
      }
    })
    .state('ezypayment.admin.merchant-management.supplier-merchant-details-add', {
      parent: 'ezypayment.admin.merchant-management',
      url: '/supplier-merchant-details-add',
      data: {
        authorities: ['ROLE_ADMIN']
      },
      views: {
        'content@': {
          templateUrl: 'app/ezypayment-modules/admin-modules/manage-merchant-details/add/ezypayment.manage-merchant-details.supplier-merchant-details.add.html',
          controller: 'EzyPaymentSupplierMerchantDetailsAddController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    })
    .state('ezypayment.admin.merchant-management.supplier-merchant-details-edit', {
      parent: 'ezypayment.admin.merchant-management',
      url: '/supplier-merchant-details-edit/:id',
      data: {
        authorities: ['ROLE_ADMIN']
      },
      views: {
        'content@': {
          templateUrl: 'app/ezypayment-modules/admin-modules/manage-merchant-details/edit/ezypayment.manage-merchant-details.supplier-merchant-details.edit.html',
          controller: 'EzyPaymentSupplierMerchantDetailsEditController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    })
    .state('ezypayment.admin.merchant-management.edit', {
      parent: 'ezypayment.admin.merchant-management',
      url: '/edit/:brandId',
      data: {
        authorities: ['ROLE_ADMIN']
      },
      views: {
        'content@': {
          templateUrl: 'app/ezypayment-modules/admin-modules/manage-merchant-details/edit/ezypayment.manage-merchant-details.edit.html',
          controller: 'EzyPaymentMerchantDetailsEditController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    })
    .state('ezypayment.admin.merchant-management.cybersource-form', {
      parent: 'ezypayment.admin.merchant-management',
      url: '/form/:user/:id',
      data: {
        authorities: ['ROLE_ADMIN']
      },
      views: {
        'content@': {
          templateUrl: 'app/ezypayment-modules/admin-modules/manage-merchant-details/templates/cybersource/ezypayment.admin.manage-merchant-details.cybersource-form.tmpl.html',
          controller: 'EzypaymentAdminManageCybersourceMerchantDetailsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    });
  }

})();
