/**
* @Author: Mufsir
* @Date:   15-Dec-2016 18:34 +05:30
* @Project: Ezyprocure
* @Filename: pagination-util.service.js
* @Last modified by:   Ajay
* @Last modified time: 27-Mar-2017 18:02 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .factory('PaginationUtil', PaginationUtil);

  function PaginationUtil() {

    var service = {
      parseAscending: parseAscending,
      parsePage: parsePage,
      parsePredicate: parsePredicate
    };

    return service;

    function parseAscending(sort) {
      var sortArray = sort.split(',');
      if (sortArray.length > 1) {
        return sort.split(',').slice(-1)[0] === 'asc';
      } else {
        // default to true if no sort defined
        return true;
      }
    }

    // query params are strings, and need to be parsed
    function parsePage(page) {
      return parseInt(page);
    }

    // sort can be in the format `id,asc` or `id`
    function parsePredicate(sort) {
      var sortArray = sort.split(',');
      if (sortArray.length > 1) {
        sortArray.pop();
      }
      return sortArray.join(',');
    }
  }
})();
