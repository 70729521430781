/**
* @Author: Abhay
* @Date:   03-Aug-2020 17:11 IST
* @Project: Ezyprocure
* @Filename: buyer.adhoc-items-promotions.controller.js
* @Last modified by:   Abhay
* @Last modified time: 03-Aug-2020 17:11 IST
*/

(function () {
    'use strict';

    angular
        .module('ezyprocureApp')
        .controller('BuyerAdhocPromotionsController', BuyerAdhocPromotionsController);

    BuyerAdhocPromotionsController.$inject = ['$scope', '$rootScope', '$state', 'NotificationService', '$mdDialog', 'PromotionService', 'AdhocPromotionService', 'BrandManagementService', 'pagingParams', 'paginationConstants', 'GLOBAL_CONSTANTS'];

    function BuyerAdhocPromotionsController($scope, $rootScope, $state, NotificationService, $mdDialog, PromotionService, AdhocPromotionService, BrandManagementService, pagingParams, paginationConstants, GLOBAL_CONSTANTS) {
        var vm = this;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.query = {
            limit: paginationConstants.itemsPerPage,
            page: 1
        };
        vm.limitOptions = paginationConstants.limitOptions;
        vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;

        vm.loadAll = loadAll;
        vm.showConfirm = showConfirm;

        vm.loadAll();

        /**
         * @param {string} search 
         * load promotion items
         */
        function loadAll(search) {
            if (!search) {
                vm.searchText = '';
            }
            AdhocPromotionService.getAdhocSupplierItems({
                page: vm.query.page - 1,
                size: vm.query.limit,
                sort: sort(),
                searchTerm: vm.searchText,
                startDate: '',
                endDate: ''
            }).then(function (response) {
                vm.totalItems = response.headers('X-Total-Count');
                vm.adhocPromotions = response.data;
            }, function (error) {
            });
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            return result;
        }

        /**
        * [showConfirm showConfirmPrompt]
        * @param  {[type]} ev                [description]
        * @param  {[type]} supplierPromotion [description]
        * @return {[type]}                   [description]
        */
        function showConfirm(ev, supplierPromotion) {
            var confirm = $mdDialog.confirm()
                .title('Are you sure you want to delete Adhoc Promotion?')
                .textContent('This action could not be reverted back.')
                .targetEvent(ev)
                .ok('Delete')
                .cancel('Cancel');

            $mdDialog.show(confirm).then(function () {
                deleteAdhocSupplierItemPromotion(supplierPromotion.promotionId);
            }, function () { });
        }

        function deleteAdhocSupplierItemPromotion(promotionId) {
            PromotionService.deleteSupplierItemPromotion(promotionId).then(function (response) {
                NotificationService.success({
                    title: 'Promotion',
                    message: 'alertMessage.DELETED_SUCCESSFULLY'
                });
                vm.loadAll();
            }, function (error) {
                NotificationService.error({
                    title: 'Promotion Deletion',
                    error: error
                });
            });
        }
    }
})();