/**
* @Author: Allan
* @Date:   21-September-2018 16:15 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.sip-authorization-request.service.js
* @Last modified by:   Allan
* @Last modified time: 21-September-2018 14:45 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('EzypaymentSipAuthorizationRequestService', EzypaymentSipAuthorizationRequestService);

  EzypaymentSipAuthorizationRequestService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function EzypaymentSipAuthorizationRequestService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var EzypaymentSipAuthorizationRequestService = {
      getSipRequests: getSipRequests,
      approveSipRequest: approveSipRequest
    };

    return EzypaymentSipAuthorizationRequestService;

    function getSipRequests(eAuthRequestFilterDTO) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'e-authorization-requests/list',
        method: "POST",
        data: eAuthRequestFilterDTO,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function approveSipRequest(params, id){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'e-authorization-requests/' + id,
        method: "PUT",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }


  }
})();
