/*
 * @Author: Abhay 
 * @Date: 2021-11-29 11:48:02 
 * @Last Modified by: Abhay
 * @Last Modified time: 2021-11-13 14:50:04
 */



(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('CustomPaymentsReportController', CustomPaymentsReportController);

  CustomPaymentsReportController.$inject = ['$state', 'toastr', '$mdDialog', 'pagingParams', 'NotificationService', 'paginationConstants', 'BuyerPayeeService', 'Auth', 'GLOBAL_CONSTANTS', '$stateParams', 'BankService', 'BuyerDataService', 'BrandManagementService', 'CustomPaymentsService'];

  function CustomPaymentsReportController($state, toastr, $mdDialog, pagingParams, NotificationService, paginationConstants, BuyerPayeeService, Auth, GLOBAL_CONSTANTS, $stateParams, BankService, BuyerDataService, BrandManagementService, CustomPaymentsService) {

    var vm = this;
    vm.getreportData = [];
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.loadTransactionReport = loadTransactionReport;

    function loadTransactionReport() {
      vm.getreportData = CustomPaymentsService.getReportData();
    }
    
  }
})();
