/**
* @Author: Ajay
* @Date:   15-Feb-2018 14:15 +05:30
* @Project: Ezyprocure
* @Filename: has-any-authority.directive.js
 * @Last modified by:   Ajay
 * @Last modified time: 26-Mar-2018 14:17 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .directive('hasAnyAuthority', hasAnyAuthority);

  hasAnyAuthority.$inject = ['Principal'];

  function hasAnyAuthority(Principal) {
    var directive = {
      restrict: 'A',
      link: linkFunc
    };

    return directive;

    function linkFunc(scope, element, attrs) {
      var authorities = attrs.hasAnyAuthority.replace(/\s+/g, '').split(',');

      var setVisible = function() {
        element.removeClass('hidden');
      },
      setHidden = function() {
        element.addClass('hidden');
      },
      defineVisibility = function(reset) {
        var result;
        if (reset) {
          setVisible();
        }

        result = Principal.hasAnyAuthority(authorities);
        if (result) {
          setVisible();
        } else {
          setHidden();
        }
      };

      if (authorities.length > 0) {
        defineVisibility(true);

        scope.$watch(function() {
          return Principal.isAuthenticated();
        }, function() {
          defineVisibility(true);
        });
      }
    }
  }
})();
