/*
 * @Author: Lovin C 
 * @Date: 2021-11-03 11:12:42 
 * @Last Modified by: Lovin C
 * @Last Modified time: 2021-11-03 14:42:56
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('ezypayment.buyer.custom-manage-cards', {
      parent: 'ezypayment.buyer',
      url: '/custom-manage-cards',
            data: {
              authorities: ['ROLE_BUYER']
            },
      views: {
        'content@': {
          templateUrl: 'app/ezypayment-modules/buyer-modules/custom-add-cards/ezypayment.buyer.custom-manage-cards.html',
          controller: 'EzypaymentCustomManageCardsController',
          controllerAs: 'vm'
        }
      },
      params:{
        initiallyActiveTab : false
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return true;
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    })
    .state('ezypayment.buyer.custom-manage-cards.add', {
      parent: 'ezypayment.buyer.custom-manage-cards',
      url: '/add',
            data: {
              authorities: ['ROLE_BUYER']
            },
      views: {
        'content@': {
          templateUrl: 'app/ezypayment-modules/buyer-modules/custom-add-cards/add/ezypayment.custom-add-card.html',
          controller: 'CustomCardAddController',
          controllerAs: 'vm'
        }
      },
      params:{
        initiallyActiveTab : false
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return true;
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    })

  }
})();
