/**
* @Author: Ajay
* @Date:   06-Apr-2017 10:21 +05:30
* @Project: Ezyprocure
* @Filename: buyer.purchase-order.individual-details.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 25-Jan-2018 15:12 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerTopSuppliersDetailsController', BuyerTopSuppliersDetailsController);

  BuyerTopSuppliersDetailsController.$inject = ['$scope', '$rootScope', '$filter', '$state', '$stateParams', 'toastr', '$mdDialog', 'pagingParams', 'BuyerDataService', 'UTCDateService', 'BuyerPriceUpdateHistoryService', 'BuyerSupplierMappingService', 'paginationConstants', 'BuyerBuyNowService', 'CSVExportService', 'EXPORT_CONSTANTS'];

  function BuyerTopSuppliersDetailsController($scope, $rootScope, $filter, $state, $stateParams, toastr, $mdDialog, pagingParams, BuyerDataService, UTCDateService, BuyerPriceUpdateHistoryService, BuyerSupplierMappingService, paginationConstants, BuyerBuyNowService, CSVExportService, EXPORT_CONSTANTS) {
    var vm = this;
    vm.init = init;
    vm.supplierId = $stateParams.supplierId;
    vm.fromDate = $stateParams.fromDate;
    vm.toDate = $stateParams.toDate;
    vm.businessOutletId = $stateParams.businessOutletId;

    function init(){
      loadAll();
    }

    /**
     * [loadAll Gets Purchase Order Details by PO number]
     */
    function loadAll() {
        BuyerPriceUpdateHistoryService.getTopSuppliersDetails(vm.supplierId,vm.fromDate,vm.toDate,vm.businessOutletId).then(function(response){
        vm.indDetails = response.data;
        },function(error){
      });
    }

  }
})();
