/**
 * @Author: Ajay
 * @Date:   24-Jan-2018 16:25 +05:30
 * @Project: Ezyprocure
 * @Filename: admin.main-item-group.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 25-Jan-2018 12:02 +05:30
 */



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('MainItemGroupService', MainItemGroupService);

  MainItemGroupService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function MainItemGroupService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var mainItemGroupService = {
      getAllMainItemGroups: getAllMainItemGroups,
      getMainItemGroup: getMainItemGroup,
      createMainItemGroup: createMainItemGroup,
      updateMainItemGroup: updateMainItemGroup,
      deleteMainItemGroup: deleteMainItemGroup
    };

    return mainItemGroupService;

    /* --  ItemGroup CRUID operations  -- */

    //Get All mainItemGroups
    function getAllMainItemGroups(urlParams) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.MAIN_ITEMGROUP_RESOURCE,
        method: "GET",
        params: urlParams,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get mainItemGroup details using mainItemGroup id
    function getMainItemGroup(mainItemGroupId) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.MAIN_ITEMGROUP_RESOURCE + mainItemGroupId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Create a new mainItemGroup
    function createMainItemGroup(mainItemGroup) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.MAIN_ITEMGROUP_RESOURCE, mainItemGroup).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update mainItemGroup
    function updateMainItemGroup(mainItemGroup) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.MAIN_ITEMGROUP_RESOURCE, mainItemGroup).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Soft Delete mainItemGroup using mainItemGroupId
    function deleteMainItemGroup(mainItemGroupId) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.MAIN_ITEMGROUP_RESOURCE + mainItemGroupId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
