/**
 * @Author: Ajay
 * @Date:   29-Aug-2018 11:56 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.manage-payment-approval-level.level-selection.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 30-Aug-2018 18:42 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerManagePaymentApprovalLevelSelectionController', BuyerManagePaymentApprovalLevelSelectionController);

  BuyerManagePaymentApprovalLevelSelectionController.$inject = ['$scope', '$state', '$filter', '$q', '$mdDialog', 'GLOBAL_CONSTANTS', 'BuyerManagePaymentApprovalLevelService', 'NotificationService', 'BrandBuyerUserMapService', 'paginationConstants', 'BuyerUserManagementService'];

  function BuyerManagePaymentApprovalLevelSelectionController($scope, $state, $filter, $q, $mdDialog, GLOBAL_CONSTANTS, BuyerManagePaymentApprovalLevelService, NotificationService, BrandBuyerUserMapService, paginationConstants, BuyerUserManagementService) {
    var vm = this;
    vm.init = init;
    vm.getAllBrandUserMappingsForBrand = getAllBrandUserMappingsForBrand;
    vm.getAllBuyerUserPaymentApprovers = getAllBuyerUserPaymentApprovers;
    vm.searchText = '';
    vm.managePaymentApprovalLevelDTO = {};
    vm.managePaymentApprovalLevelDTO.mappingLevels = [];
    vm.approvalLevelFilter = {};
    vm.getBrandBuyerUserMappingForPaymentApproval = getBrandBuyerUserMappingForPaymentApproval;
    vm.savePaymentApprovalLevel = savePaymentApprovalLevel;
    vm.addRow = addRow;
    vm.showConfirm = showConfirm;
    vm.groups = GLOBAL_CONSTANTS.paymentApprovalGroups;
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.groupLabels = {
      "GROUP_1":"GROUP A",
      "GROUP_2":"GROUP B",
      "GROUP_3":"GROUP C",
      "GROUP_4":"GROUP D",
      "GROUP_5":"GROUP E"
    }

    function init() {
      vm.approvalLevelFilter = BuyerManagePaymentApprovalLevelService.getApprovalLevelFilter();
      if(vm.approvalLevelFilter === null) {
        $state.go('ezypayment.buyer.manage-payment-approval-level');
      }
      else {
        vm.brandId = vm.approvalLevelFilter.brandId;
        vm.getAllBrandUserMappingsForBrand();
      }
    }


    function getAllBrandUserMappingsForBrand() {
      BrandBuyerUserMapService.getAllBrandUserMappingsForBrand({
        query: '',
      },vm.brandId).then(function(response){
        vm.brandBuyerUserMappings = response.data;
        vm.addApprovalInProgress = false;
      },function(error){
        NotificationService.error({
          title: 'Buyer User - Business Mapping Listing',
          error: error
        });
      });
    }

    function maxApprovalLevel() {
      var maxApprovalLevel = 1;
      if(vm.brandBuyerUserMappings.length > 0){
        maxApprovalLevel = _.maxBy(vm.brandBuyerUserMappings, 'approvalLevel').approvalLevel+1;
      }
      return maxApprovalLevel;
    }

    function addRow() {
      vm.approvalLevels = _.range(1, maxApprovalLevel()+1, 1);
      vm.brandBuyerUserMappings.push({
        id: null,
        brand:{
          id: vm.brandId
        },
        type: 'DRAFT',
        approvalLevel: maxApprovalLevel()
      });
      vm.addApprovalInProgress = true;
    }

    //BuyerUsers Dropdown API
    function getAllBuyerUserPaymentApprovers(approvalLevel){
      BuyerUserManagementService.getAllBuyerUserPaymentApprovers({
        page: 0,
        size: paginationConstants.maxLimit,
        query: '',
        brandId: vm.brandId,
        approvalLevel: approvalLevel
      }).then(function(response) {
        vm.buyerUsers = response.data;
      },function(error){
      });
    }

    function getBrandBuyerUserMappingForPaymentApproval(mapping,index) {
      if (!vm.brandId || !mapping.approvalLevel || !mapping.buyerUserIdList)
        return false;
      BrandBuyerUserMapService.getBrandBuyerUserMappingForPaymentApproval({
        brandId: vm.brandId,
        approvalLevel: mapping.approvalLevel,
        buyerUserId: mapping.buyerUserIdList[0],
      }).then(function(response) {
          if(response.data){
            // vm.brandBuyerUserMappings[index] = response.data;
            vm.brandBuyerUserMappings[index].approvalLevel = response.data.approvalLevel;
            vm.brandBuyerUserMappings[index].minimumAmount = response.data.minimumAmount;
            vm.brandBuyerUserMappings[index].maximumAmount = response.data.maximumAmount;
            vm.brandBuyerUserMappings[index].groups = response.data.groups;
            vm.brandBuyerUserMappings[index].approvalNeeded = response.data.approvalNeeded;
            vm.brandBuyerUserMappings[index].type = "DRAFT";
          }
          //for now temp
            vm.brandBuyerUserMappings[index].brand.contactPersonName = mapping.buyerUserIdList.toString();
        },function(error) {}
      );
    }

    function savePaymentApprovalLevel(brandBuyerUserMappings) {
      //for now temp
      brandBuyerUserMappings = _.omit(brandBuyerUserMappings, ["buyerUserIdList"]);
      BrandBuyerUserMapService.savePaymentApprovalLevel(vm.brandId,brandBuyerUserMappings)
      .then(function(response) {
        NotificationService.success({
          title: 'global.userAccessTypes.paymentApprovalLevel',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        vm.getAllBrandUserMappingsForBrand();
      }, function(error) {
        NotificationService.error({
          title: 'Manage Payment Approval Level request',
          error: error
        });
      });
    }

    function deleteAllPaymentApprovalLevels(brandId) {
      BrandBuyerUserMapService.deleteAllPaymentApprovalLevels(brandId).then(function(response) {
        NotificationService.success({
          title: 'global.userAccessTypes.paymentApprovalLevel',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.getAllBrandUserMappingsForBrand();
      }, function(error) {
        NotificationService.error({
          title: 'Payment Approval Level Deletion',
          error: error
        });
      });
    }

    function showConfirm(ev, brandId) {
      var confirm = $mdDialog.confirm()
      .title('Are you sure you want to delete all payment approval levels of this brand ?')
      .textContent('This action could not be reverted back.')
      .targetEvent(ev)
      .ok('Delete')
      .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        deleteAllPaymentApprovalLevels(brandId);
      }, function() {});
    }

  }
})();
