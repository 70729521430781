/**
 * @Author: Ajay
 * @Date:   18-Jul-2018 15:19 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.payment-approval-request.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 13-Sep-2018 10:32 +05:30
 */



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BuyerPaymentApprovalRequestService', BuyerPaymentApprovalRequestService);

  BuyerPaymentApprovalRequestService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function BuyerPaymentApprovalRequestService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var buyerPaymentApprovalRequestService = {
      getAllPaymentApprovalRequest: getAllPaymentApprovalRequest,
      getPaymentApprovalRequest: getPaymentApprovalRequest,
      getPaymentApprovers: getPaymentApprovers,
      approvePaymentApprovalRequest: approvePaymentApprovalRequest,
      rejectPaymentApprovalRequest: rejectPaymentApprovalRequest,
      deletePaymentApprovalRequest: deletePaymentApprovalRequest,
      deletePaymentApprovalRequestbyPaymentId: deletePaymentApprovalRequestbyPaymentId
    };

    return buyerPaymentApprovalRequestService;

    //Get All Payment Approval Requests
    function getAllPaymentApprovalRequest(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PAYMENT_APPROVAL_REQUESTS_RESOURCE,
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get Payment Approval Request details by approval Request Number
    function getPaymentApprovalRequest(approvalRequestNumber, params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PAYMENT_APPROVAL_REQUESTS_RESOURCE + approvalRequestNumber,
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get Payment Approvers for payment approval request number
    function getPaymentApprovers(approvalRequestNumber, params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PAYMENT_APPROVAL_REQUESTS_RESOURCE + approvalRequestNumber + '/approver-list',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Approve Payment Approval Request to Accepted/Rejected by approval Request Number
    function approvePaymentApprovalRequest(approvalRequestNumber, otp) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PAYMENT_APPROVAL_REQUESTS_RESOURCE + approvalRequestNumber + '/approve',
        method: "PUT",
        headers: { 'authorization-otp' : otp }
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Reject Payment Approval Request to Accepted/Rejected by approval Request Number
    function rejectPaymentApprovalRequest(approvalRequestNumber, rejectRemark) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PAYMENT_APPROVAL_REQUESTS_RESOURCE + approvalRequestNumber +'/reject',
        params: {"remark": rejectRemark},
        method: "PUT"
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Delete Payment Approval Request details by approval Request Number
    function deletePaymentApprovalRequest(approvalRequestNumber) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PAYMENT_APPROVAL_REQUESTS_RESOURCE + approvalRequestNumber,
        method: "DELETE",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function deletePaymentApprovalRequestbyPaymentId(approvalRequestNumber, paymentId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PAYMENT_APPROVAL_REQUESTS_RESOURCE + approvalRequestNumber +'/remove/' + paymentId,
        method: "DELETE",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
