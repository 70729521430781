/**
 * @Author: Sneha
 * @Date:   2018-09-19T11:19:54+05:30
 * @Project: Ezyprocure
 * @Filename: ezypayment.add-cards.state.js
 * @Last modified by:   Sneha
 * @Last modified time: 2018-09-19T11:27:23+05:30
 */


 (function() {
   'use strict';

   angular
   .module('ezyprocureApp')
   .config(stateConfig);

   stateConfig.$inject = ['$stateProvider'];

   function stateConfig($stateProvider) {
     $stateProvider
     .state('ezypayment.buyer.manage-cards.choose-brands', {
       parent: 'ezypayment.buyer.manage-cards',
       url: '/choose-brands/:userRole',
             data: {
               authorities: ['ROLE_BUYER']
             },
       views: {
         'content@': {
           templateUrl: 'app/ezypayment-modules/buyer-modules/manage-cards/add-cards/ezypayment.manage-cards.choose-brands.html',
           controller: 'EzypaymentChooseBrandsController',
           controllerAs: 'vm'
         }
       },
       resolve: {
         pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
           return true;
         }],
         translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
           $translatePartialLoader.addPart('global');
           return $translate.refresh();
         }]
       }
     })

     .state('ezypayment.buyer.manage-cards.add-card', {
       parent: 'ezypayment.buyer.manage-cards.choose-brands',
       url: '/add-card',
       data: {
         authorities: ['ROLE_BUYER']
       },
       views: {
         'content@': {
           templateUrl: 'app/ezypayment-modules/buyer-modules/manage-cards/add-cards/ezypayment.manage-cards.add-card.html',
           controller: 'EzypaymentAddCardController',
           controllerAs: 'vm'
         }
       },
       resolve: {
         pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
           return true;
         }],
         translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
           $translatePartialLoader.addPart('global');
           return $translate.refresh();
         }]
       }
     })

   }
 })();
