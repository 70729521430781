/*
 * @Author: Jayalekshmi 
 * @Date: 2018-11-14 13:15:15 
 * @Project: Ezyprocure
 * @Filename: buyer.adhoc-supplier-buyer-mapping.add.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2018-11-28 18:11:36
 */




(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .controller('BuyerAdhocSupplierMappingAddController', BuyerAdhocSupplierMappingAddController);
  
    BuyerAdhocSupplierMappingAddController.$inject = ['$scope', '$state', 'GLOBAL_CONSTANTS', 'paginationConstants', 'NotificationService', 'ManageSupplierService', 'BuyerAdhocSupplierMappingService'];
  
    function BuyerAdhocSupplierMappingAddController($scope, $state, GLOBAL_CONSTANTS, paginationConstants, NotificationService, ManageSupplierService, BuyerAdhocSupplierMappingService) {
      var vm = this;
      vm.getAllAdhocSuppliers = getAllAdhocSuppliers;
      vm.createAdhocSupplierBuyerMapping = createAdhocSupplierBuyerMapping;
      vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
  
      /**
       * createAdhocSupplierBuyerMapping Creates Mapping for selected adhoc supplier and buyer
       */
      function createAdhocSupplierBuyerMapping() {
        BuyerAdhocSupplierMappingService.createAdhocSupplierBuyerMapping(vm.adhocSupplierMapping).then(function(response){
          NotificationService.success({
          title: 'global.navbar.adhocSupplierBuyerMapping',
          message: 'alertMessage.CREATED_SUCCESSFULLY'
          });
          $state.go('buyer.adhoc-tools.adhoc-supplier-buyer-mapping', {
            reload: false
          });
        },function(error) {
          NotificationService.error({
            title: 'global.navbar.adhocSupplierBuyerMapping',
            error: error
          });
        });
      }
      
      /**
       * [getAllAdhocSuppliers gets all adhoc suppliers]
       */
      function getAllAdhocSuppliers() {
        if(vm.suppliers){
          return false
        }
        ManageSupplierService.getAllAdhocSuppliers({
          page: 0,
          size: paginationConstants.maxLimit,
          sort: vm.supplierSort
        }).then(function(response){
          vm.suppliers = response.data;
        },function(error){});
      }
    }
  })();
  