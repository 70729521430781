/**
* @Author: Ajay
* @Date:   28-Mar-2017 12:35 +05:30
* @Project: Ezyprocure
* @Filename: buyer.email.service.js
* @Last modified by:   Ajay
* @Last modified time: 28-Mar-2017 12:40 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BuyerEmailService', BuyerEmailService);

  BuyerEmailService.$inject = ['$http', '$q', 'API_CONSTANTS'];

  function BuyerEmailService($http, $q, API_CONSTANTS) {

    var buyerEmailService = {
      sendEmailToSupplier: sendEmailToSupplier
    };

    return buyerEmailService;

    /**
     * Buyer Email APIs
     */

    /**
     * [sendEmailToSupplier Send Email to buyer]
     * @param  {[type]} emailDTO [Email object with recipients[],subject,body]
     * @return {[JSON]}          [message to be displayed]
     */
    function sendEmailToSupplier(emailDTO) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyers/email-supplier',
        data: emailDTO,
        method: "PUT",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
