/**
* @Author: Ajay
* @Date:   27-Dec-2017 16:11 +05:30
* @Project: Ezyprocure
* @Filename: buyer.logs-history.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 12-Oct-2018 14:44 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerLogsHistoryController', BuyerLogsHistoryController);

  BuyerLogsHistoryController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', '$stateParams', 'NotificationService', '$mdDialog', 'pagingParams', 'BuyerDataService', 'UTCDateService', 'BuyerPriceUpdateHistoryService','BuyerUserManagementService', 'BuyerSupplierMappingService', 'paginationConstants', 'BuyerBuyNowService', 'CSVExportService', 'EXPORT_CONSTANTS', 'GLOBAL_CONSTANTS'];

  function BuyerLogsHistoryController($scope, $rootScope, $q, $filter, $state, $stateParams, NotificationService, $mdDialog, pagingParams, BuyerDataService, UTCDateService, BuyerPriceUpdateHistoryService, BuyerUserManagementService,  BuyerSupplierMappingService, paginationConstants, BuyerBuyNowService, CSVExportService, EXPORT_CONSTANTS, GLOBAL_CONSTANTS) {
    var vm = this;
    vm.predicate = JSON.parse(localStorage.getItem("logs-history-predicate")) || pagingParams.predicate;
    vm.reverse = JSON.parse(localStorage.getItem("logs-history-reverse")) || pagingParams.ascending;
    vm.todaysDate = new Date();
    vm.loadAll = loadAll;
    vm.supplier = '';
    vm.getAllBuyerUsers = getAllBuyerUsers;
    vm.SelectedUser = null;
    vm.selectedTransactionType = null;
    vm.transactionTypes = GLOBAL_CONSTANTS.TRANSACTION_TYPES_BUYER;
    vm.defaultDateTimeFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_TIME_FORMAT;

    vm.query = JSON.parse(localStorage.getItem("logs-history-paginate")) || {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };

    vm.limitOptions = paginationConstants.limitOptions;
    //Function Declaration
    vm.getAuditLogHistoryCSVData = getAuditLogHistoryCSVData;
    //filter reset
    vm.resetFilters = resetFilters;
    vm.filter = JSON.parse(localStorage.getItem("logs-history-filter")) || {
        fromDate: vm.todaysDate,
        toDate: vm.todaysDate,
        selectedTransactionType: vm.transactionTypes[0].value
    }
    vm.init = init();

    function init() {
        if(vm.filter.SelectedUser) {
            vm.getAllBuyerUsers()
        }
        vm.loadAll();
    }
    //Getting CSV DATA
    function getAuditLogHistoryCSVData(){
      var deferred = $q.defer();
      if (!vm.priceUpdateItems) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getBuyerAuditLogHistoryReportCSV(vm.priceUpdateItems, vm.filter.selectedTransactionType);
    }

    /**
    * [sort to sort by PO date]
    */
    function sort() {
      /*var result = [ 'CreatedDateTime , ' + (vm.reverse ? 'asc' : 'desc')];*/
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function getAllBuyerUsers(){
      if(vm.buyerUsers)
        return false;
      BuyerUserManagementService.getAllBuyerUsers({
        page: 0,
        size: paginationConstants.maxLimit,
        sort: sort(),
        query: ''
      }).then(function(response){
        vm.buyerUsers = response.data;
      },function(error){
      });
    }

    /**
    * [loadAll loads all price update history]
    */
    function loadAll() {
        localStorage.setItem("logs-history-filter", JSON.stringify(vm.filter));
        localStorage.setItem("logs-history-predicate", JSON.stringify(vm.predicate));
        localStorage.setItem("logs-history-reverse", JSON.stringify(vm.reverse));
        localStorage.setItem("logs-history-paginate", JSON.stringify(vm.query));
      BuyerPriceUpdateHistoryService.getAuditTransactionData({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(new Date(vm.filter.fromDate)),
        toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(new Date(vm.filter.toDate)),
        userId: !vm.filter.SelectedUser ? '' : vm.filter.SelectedUser,
        transactionId: !vm.filter.transactionId ? '' : vm.filter.transactionId,
        transactionType: !vm.filter.selectedTransactionType ? '' : vm.filter.selectedTransactionType,
        query:''
      }).then(function(response) {
        // CSV Header
        var buyerAuditLogReportHeader = EXPORT_CONSTANTS.CSV.BuyerAuditLogReport;
        // Header changes for selectedTransactionType of Purchase Request Item Approval
        vm.auditLogReportHeader = (vm.filter.selectedTransactionType!=='Purchase Request Item Approval')? (buyerAuditLogReportHeader) : (buyerAuditLogReportHeader.concat('prNumber'));
        vm.priceUpdateItems = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      }, function(error) {
      });
    }
    // filter reset
    function resetFilters() {
        vm.filter.fromDate = vm.todaysDate;
        vm.filter.toDate = vm.todaysDate;
        vm.filter.SelectedUser = '';
        vm.filter.transactionId = '';
        vm.filter.selectedTransactionType = vm.transactionTypes[0].value;
        vm.priceUpdateItems = [];
        localStorage.setItem("logs-history-filter", JSON.stringify(vm.filter));
    }
  }
})();
