/**
* @Author: Ajay
* @Date:   31-Jan-2017 10:45 +05:30
* @Project: Ezyprocure
* @Filename: buyer.business.service.js
* @Last modified by:   Mufsir
* @Last modified time: 05-Jun-2017 18:19 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BuyerBusinessService', BuyerBusinessService);

  BuyerBusinessService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS', 'AuthServerProvider'];
  function BuyerBusinessService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS, AuthServerProvider) {

    var buyerBusinessService = {
      getAllBuyerBusinesses: getAllBuyerBusinesses,
      getBuyerBusiness: getBuyerBusiness,
      createBuyerBusiness: createBuyerBusiness,
      updateBuyerBusiness: updateBuyerBusiness,
      deleteBuyerBusiness: deleteBuyerBusiness,
      getAllBuyerBusinessOutlets: getAllBuyerBusinessOutlets,
      getAllBuyersBusinessOutlets: getAllBuyersBusinessOutlets,
      getAllBrandMappedsBusinessOutlets: getAllBrandMappedsBusinessOutlets,
      getOutletsData: getOutletsData,
      initMappings: initMappings
    };
    return buyerBusinessService;

    /* --  Buyer Business outlet CRUID operations  -- */
    //get all outlets mapped with brands
    function getAllBrandMappedsBusinessOutlets(brandWithOutletDTO, params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYER_BUSINESS_RESOURCE+'brand-list/outlets',
        method: "POST",
        data:brandWithOutletDTO,
        params:params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    
    //create outlet mappings for all suppliers, and all supplier items
    function initMappings(outletId){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYER_BUSINESS_RESOURCE+'init-mappings/' + outletId,
        method: "POST",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get All Business outlets of logged in buyer
    function getAllBuyerBusinesses(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYER_BUSINESS_RESOURCE,
        method: "GET",
        params:params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get business outlet details using outlet id
    function getBuyerBusiness(businessOutletId) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYER_BUSINESS_RESOURCE + businessOutletId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Create a new buyer business outlet
    function createBuyerBusiness(buyerBusiness) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYER_BUSINESS_RESOURCE, buyerBusiness).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update a buyer business outlet
    function updateBuyerBusiness(buyerBusiness) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYER_BUSINESS_RESOURCE, buyerBusiness).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Soft Delete a business outlet using outlet id
    function deleteBuyerBusiness(businessOutletId) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYER_BUSINESS_RESOURCE + businessOutletId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get All Business outlets of logged in buyer
    function getAllBuyerBusinessOutlets(buyerId, params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyer/'+buyerId+'/businessOutlets',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get All Business outlets of list of buyers groupby buyer
    function getAllBuyersBusinessOutlets(buyerIdList,params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyers/outlets',
        method: "PUT",
        params: params,
        data: buyerIdList,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getOutletsData(buyerId, userId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.OUTLET_COUNT + buyerId + '/' + userId,
        method: "POST",
        data: {}
      }).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
