/**
 * @Author: Ajay
 * @Date:   15-Oct-2018 13:32 +05:30
 * @Project: Ezyprocure
 * @Filename: cfp-loading-bar.config.js
 * @Last modified by:   Ajay
 * @Last modified time: 15-Oct-2018 15:04 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(cfpLoadingBarServiceConfig);

  cfpLoadingBarServiceConfig.$inject = ['cfpLoadingBarProvider'];

  function cfpLoadingBarServiceConfig(cfpLoadingBarProvider) {

    //cfpLoadingBarProvider.includeBar = false;
    cfpLoadingBarProvider.includeSpinner = false;
  }
})();
