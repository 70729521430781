/**
 * @Author: jayalekshmir
 * @Date:   2018-05-29T17:59:47+05:30
 * @Last modified by:   Jayalekshmi R
 * @Last modified time: 2018-05-29T18:40:01+05:30
 */

(function(){
  'use strict';
  angular
    .module('ezyprocureApp')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider){
    $stateProvider
      .state('buyer.business-charts.purchase-trends',{
        parent: 'buyer.business-charts',
        url: '/purchase-trends',
        data: {
          authorities: ['ROLE_BUYER'],
        },
        views: {
          'content@': {
            templateUrl: 'app/buyer-modules/business-charts/purchase-trends/buyer.purchase-trends.html',
            controller: 'BuyerPurchaseTrendsController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,desc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('buyer');
            $translatePartialLoader.addPart('priceUpdates');
            $translatePartialLoader.addPart('items');
            $translatePartialLoader.addPart('user-management');
            return $translate.refresh();
          }]
        }
      });
  }

})();
