


(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('EzypaymentEddaAccountMappingController', EzypaymentEddaAccountMappingController);

  EzypaymentEddaAccountMappingController.$inject = ['$stateParams', 'CardsService', 'NotificationService', '$mdDialog', 'EddaAccountMappingService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'pagingParams'];

  function EzypaymentEddaAccountMappingController($stateParams, CardsService, NotificationService, $mdDialog, EddaAccountMappingService, GLOBAL_CONSTANTS, paginationConstants, pagingParams) {

    var vm = this;
    vm.showConfirm = showConfirm;
    vm.init = init;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.limitOptions = paginationConstants.limitOptions;
    vm.deleteDDAReference = deleteDDAReference;
    vm.getAllEddaAccountMappings = getAllEddaAccountMappings;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;


    function init() {
      getAllEddaAccountMappings();
      getSupplierEddaAccountMappings();
    }

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function getSupplierEddaAccountMappings() {
      EddaAccountMappingService.getSupplierEddaAccountMappings().then(function (response) {
        vm.supplierEDDADetails = response.data;
        vm.count = response.headers('X-Total-Count');
      },
        function (error) {
          NotificationService.error({
            title: 'eDDA Account Mapping',
            error: error
          });
        });
    }

    function getAllEddaAccountMappings() {
      var params = {
        sort: sort(),
        page: vm.query.page - 1,
        size: vm.query.limit,
      }
      EddaAccountMappingService.getEddaAccountMappings(params).then(function (response) {
        vm.eDDADetails = response.data;
        vm.totalCount = response.headers('X-Total-Count');
      },
        function (error) {

        });
    }

    function deleteDDAReference(id) {
      EddaAccountMappingService.deleteDDAReference(id).then(function (response) {
        NotificationService.success({
          title: 'eDDA Account Mappings',
          message: 'Deleted Successfully'
        });
        vm.init();
      },
        function (error) {
          NotificationService.error({
            title: 'eDDA Account Mappings',
            error: error
          });
        })
    }


    // Shows modal before deleting the card
    function showConfirm(id, ev) {
      var confirm = $mdDialog.confirm()
        .title('Are you sure you want to delete this DDA Mandate Authorization?')
        .textContent('This action could not be reverted back.')
        .targetEvent(ev)
        .ok('Delete')
        .cancel('Cancel');
      $mdDialog.show(confirm).then(function () {
        vm.deleteDDAReference(id);
      }, function () {
      });
    };

  }
})();
