/**
 * @Author: Jayalekshmi
 * @Date:   2018-07-12 13:00:30
 * @Filename: transaction-summary-report.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 12-Oct-2018 14:43 +05:30
 */



(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('TransactionSummaryReportController', TransactionSummaryReportController);

  TransactionSummaryReportController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', '$mdDialog', 'toastr', 'pagingParams', 'EzypaymentAdminAXSPaymentsService', 'UTCDateService', 'NotificationService', 'CallbackResourceService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'CSVExportService', 'XeroResourceService', 'EXPORT_CONSTANTS', 'SupplierDataService', 'BuyerSupplierMappingService', 'SupplierTransactionSummaryReportService', 'Auth', 'BuyerDataService', 'BrandManagementService', 'BuyerBusinessUserMapService', 'CardsService', 'PurchaseOrderService'];

  function TransactionSummaryReportController($scope, $rootScope, $q, $filter, $state, $mdDialog, toastr, pagingParams, EzypaymentAdminAXSPaymentsService, UTCDateService, NotificationService, CallbackResourceService, GLOBAL_CONSTANTS, paginationConstants, CSVExportService, XeroResourceService, EXPORT_CONSTANTS, SupplierDataService, BuyerSupplierMappingService, SupplierTransactionSummaryReportService, Auth, BuyerDataService, BrandManagementService, BuyerBusinessUserMapService, CardsService, PurchaseOrderService) {

    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = true;
    vm.todaysDate = new Date();
    vm.loadAll = loadAll;
    vm.cancelPopup = cancelPopup;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.listViews = GLOBAL_CONSTANTS.LIST_VIEWS;
    vm.groupBy = ['Brand'];
    vm.limitOptions = paginationConstants.limitOptions;
    vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
    vm.userRole = Auth.getUserRole();
    vm.userRole.pretty === 'supplier' ? vm.groupBy.push('Buyer') : vm.groupBy.push('Supplier');
    vm.filter = {};
    vm.selectedRow = {};
    vm.showPrompt = showPrompt;
    vm.getAllBusinessOutletsOfBrands = getAllBusinessOutletsOfBrands;
    vm.transactionSummaryReportHeader = vm.userRole.pretty === 'buyer' ? EXPORT_CONSTANTS.CSV.transactionSummaryReportBuyer : EXPORT_CONSTANTS.CSV.transactionSummaryReportSupplier;
    vm.nfrndsTransactionSummaryReportHeader = EXPORT_CONSTANTS.CSV.transactionSummaryReportNfrnds;
    vm.transactionSummaryReportDataCSV = transactionSummaryReportDataCSV;
    vm.nfrndsTransactionSummaryReportDataCSV = nfrndsTransactionSummaryReportDataCSV;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.getAllBuyerMappedSupplierList = getAllBuyerMappedSupplierList;
    vm.getAllBuyerBrands = getAllBuyerBrands;
    vm.getAllBrandsOfBuyerList = getAllBrandsOfBuyerList;
    vm.onGetBrands = onGetBrands;
    vm.brandSelectionChanged = brandSelectionChanged;
    vm.buyerSelectionChanged = buyerSelectionChanged;
    vm.getAllCards = getAllCards;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.paymentStatus = GLOBAL_CONSTANTS.PAYMENT_STATUSES;
    vm.transactionStatus = GLOBAL_CONSTANTS.TRANSACTION_STATUSES;
    vm.paymentMethods = GLOBAL_CONSTANTS.PAYMENT_METHODS;
    vm.paymentList = [];
    vm.transactionDetails = [];
    vm.transactionStatusList = [];
    vm.paymentMethodsList = ['EZYPAY'];
    vm.calculateSum = calculateSum;
    vm.connectToXero = connectToXero;
    vm.disconnectToXero = disconnectToXero;
    vm.exportToXero = exportToXero;
    vm.exportToXeroAccount = exportToXeroAccount;
    vm.closeModal = closeModal;
    vm.getOraganization = getOraganization;
    vm.getSelectedPOs = getSelectedPOs;
    vm.checkAllRows = checkAllRows;
    vm.getViewDetails = getViewDetails;
    vm.closeDialog = closeDialog;
    vm.downloadEodFiles = downloadEodFiles;

    function downloadEodFiles(urlString) {
      if(urlString === 'NA' || !urlString) { return; }
      var urls = urlString.split(',');
      vm.downloadInterval = setInterval(processUrls, 300, urls);
    }

    function processUrls(urls) {
      var url = urls.pop();
      download(url);
      if (urls.length === 0) {
        clearInterval(vm.downloadInterval);
      }
    }

    function download(url) {
      var a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('download', undefined);
      a.setAttribute('target', '_blank');
      a.click();
    }

    function getViewDetails(transaction, ev) {
      var params = {
        paymentIds: String([transaction.paymentIds])
      };
      EzypaymentAdminAXSPaymentsService.getTransactionViewDetails(params)
        .then(
          function (response) {
            vm.transactionDetails = response.data;
            openDialog(ev);
          },
          function (error) {
            toastr.error("Internal Server Error");
          });
    }

    function cancelPopup() {
        $mdDialog.cancel();
    }

    function showPrompt(index) {
        PurchaseOrderService.getPurchaseOrderByPaymentId(index.paymentIds, {
            deliveryDate: !vm.deliveryDate ? '' : vm.deliveryDate,
          }).then(function(response){
            vm.selectedRow = response.data;
            // show prompt
            $mdDialog.show({
              templateUrl: 'app/core/common-templates/statement-view-popup.tmpl.html',
              controller: function () {
                return vm;
              },
              controllerAs: 'vm',
              targetEvent: event,
              clickOutsideToClose: true
            });
          },function(error){
            NotificationService.error({
              title: 'Credit Note',
              error: error
            });
          });
      }

    function openDialog(ev) {
      $mdDialog.show({
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        templateUrl: 'app/common-modules/transaction-summary-report/axs-transaction-summary-report-detail.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true,
      }).then(function (answer) {

      }, function () {

      });
    }

    function closeDialog() {
      $mdDialog.hide();
    }

    function connectToXero() {
      var moduleNumber;
     vm.userRole.pretty == 'buyer' ? moduleNumber = 4 :  moduleNumber = 5;
      CallbackResourceService.redirectUrl({
        module: moduleNumber,
      }).then(function (response) {
      }, function (error) {
        NotificationService.error({
          title: 'global.buttons.connectXERO',
          error: error
        });
      });
    }

    disableConnectToXeroBtn();

    function disableConnectToXeroBtn() {
      CallbackResourceService.getAuthTimeout(
      ).then(function (response) {
        vm.xero = response;
        vm.organisationName = vm.xero.errors;
        if (window.location.href.indexOf("?") > -1) {
          var code = window.location.href.split('code=')[1].split('&scope=')[0];
          var scope = window.location.href.split('scope=')[1].split('&session_state=')[0];
          var session_state = window.location.href.split('session_state=')[1].split('&state')[0];
          var state = window.location.href.split('state=')[1];
          CallbackResourceService.xeroCallback({ code: code, scope: scope, sessionState: session_state,state: state })
            .then(function (response) {
              vm.xero = response;
              vm.organisationdata = response.data.data;
              if(vm.organisationdata.length == 1){
                vm.tenantId = vm.organisationdata[0].tenantId;
                vm.getOraganization();
              } else {
                $mdDialog.show({
                  templateUrl: 'app/core/common-templates/xero-multiple-organization.html',
                  controller: function() {
                    return vm;
                  },
                  controllerAs: 'vm',
                  targetEvent: event,
                  clickOutsideToClose: true
                });
              }
              if (!vm.organisationName) {
                vm.organisationName = vm.xero.errors;
              }
              code = null;
              scope = null;
              session_state=null;
              state=null;
            }, function (error) {
              vm.xero = CallbackResourceService.getXeroConnectionParams();
              NotificationService.error({
                title: 'global.buttons.connectXERO',
                error: error
              });
            });
        }
      }, function (error) {
        NotificationService.error({
          title: 'global.buttons.connectXERO',
          error: error
        });
      });
    }

    function getOraganization(){
      closeModal();
      var params ={
        tenantId: vm.tenantId
      };
      CallbackResourceService.xeroConnect(params).then(function(response){
        vm.xero = response;
        vm.organisationName = vm.xero.data.responseList[0].reason;
        NotificationService.success({
          title: 'global.buttons.connectXERO',
          message: 'alertMessage.XERO.SUCCESS'
        });
      }, function(error){
        NotificationService.error({
          title: 'global.buttons.connectXERO',
          error: error
        });
      });
     }

    /**
    * [getTokenExpiry check the token expiry]
    */
    function getTokenExpiry() {
      CallbackResourceService.getTokenExpiry(
      ).then(function (response) {
        vm.xero = response;
      }, function (error) {
        NotificationService.error({
          title: 'global.buttons.connectXERO',
          error: error
        });
      });
    }

    /**
    * [getSelectedPOs return all sected pos]
    */
    function getSelectedPOs(poItems) {
      var selectedPOs = _.filter(vm.transactionSummaryReports, function (poItem) {
        return poItem.selected === true;
      });
      return selectedPOs;
    }

    /**
    * [exportToXeroAccount set account type and reference]
    */
    function exportToXeroAccount() {
      var paymentStatusList = [];
      var selectedPOs = getSelectedPOs(vm.transactionSummaryReports);
      if(selectedPOs.length > 0){
      _.map(selectedPOs, function (eachRequest) {
          paymentStatusList.push(eachRequest.paymentStatus);
      });
      paymentStatusList = _.uniq(paymentStatusList, function (eachRequest) {
        return eachRequest;
      });
      if (paymentStatusList.length == 1 && paymentStatusList[0] == 'PAID') {
        $mdDialog.show({
          templateUrl: 'app/common-modules/transaction-summary-report/transaction-summary-report.modal.tmpl.html',
          controller: function () {
            return vm;
          },
          controllerAs: 'vm',
          targetEvent: event,
          clickOutsideToClose: true
        });
        if (vm.accounts) {
          return false;
        }
        SupplierTransactionSummaryReportService.getXeroAccountType().then(function (response) {
          vm.accounts = response.data;
          vm.newAccountsArray = [];
          Object.keys(vm.accounts).map(function (key, index) {
            vm.newAccountsArray.push({
              "name": key,
              "value": vm.accounts[key]
            });
          });
        }, function (error) {
          NotificationService.error({
            title: 'ACCOUNt TYPE',
            error: error
          });
        });
      }
      else {
        NotificationService.simpleErrorToast({
          title: 'global.buttons.exportXERO',
          message: 'alertMessage.INVOICE_STATUS_SHOULD_BE_PAID'
        });
        vm.loadAll();
      }

    }
    else {
      NotificationService.simpleErrorToast({
        title: 'global.buttons.exportXERO',
        message: 'alertMessage.PLEASE_SELECT_INVOICES'
      });
    }
    }

   /**
  * [closeModal description]
  * @return {[type]} [description]
  */
    function closeModal() {
      $mdDialog.hide();
    }

    /**
    * [exportToXero exports invoices to xero]
    */
    function exportToXero() {
      vm.closeModal();
      var xeroInvoiceDTO = [];
      var selectedPOs = vm.getSelectedPOs(vm.transactionSummaryReports)
      xeroInvoiceDTO = _.map(selectedPOs, function (eachRequest) {
        return {
          'hardCopyInvoiceNumber':eachRequest.invoiceNumber,
          'paidAmount': eachRequest.totalAmountPayable ,
          'paidDate':(eachRequest.transactionTimeStamp).split('T')[0],
          'paymentAccountId': vm.paymentAccountId,
          'purchaseOrderNumber': eachRequest.poNumber,
          'reference': vm.xero.reference
        };
      });
      XeroResourceService.exportInvoiceToXeroAccount(xeroInvoiceDTO
      ).then(function (response) {
        vm.message = response.headers('X-ezyprocureApp-alert')
        NotificationService.success({
          title: 'global.buttons.exportXERO',
          message: vm.message
        });
        setTimeout(function () {
          vm.init();
        }, 60000);
      }, function (error) {
        NotificationService.error({
          title: 'global.buttons.exportXERO',
          error: error
        });
        getTokenExpiry();
        disableConnectToXeroBtn();
      });
      vm.loadAll();
    }

    function disconnectToXero() {
      vm.organisationName = "";
      CallbackResourceService.disconnectToXero().then(function (response) {
        vm.xero = response.xero;
        NotificationService.success({
          title: response.data.status,
          message: response.data.message
        });
      }, function (error) {
        NotificationService.error({
          title: 'global.buttons.disconnectXERO',
          error: error
        });
      });
    }

    /**
     * [calculateSum shows the sum of key in list]
     */
    function calculateSum(transactionSummaryReportsList, key) {
      transactionSummaryReportsList =  _.map(transactionSummaryReportsList, function(elements){
        elements[key] = Number($filter('decimalNumber')(elements[key], 2));
          return elements;
      });
      var sum = _.sumBy(transactionSummaryReportsList, key)
      return sum;
    }

    function buyerSelectionChanged() {
      vm.filter.buyerSelectionChanged = true;
      vm.filter.brandIdList = [];
      vm.filter.businessOutletIdList = [];
    }

    function brandSelectionChanged() {
      vm.filter.brandSelectionChanged = true;
      vm.filter.businessOutletIdList = [];
    }

    //Getting CSV DATA
    function transactionSummaryReportDataCSV() {
      var deferred = $q.defer();
      if (!vm.transactionSummaryReports) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getTransactionSummaryReportsCSV(vm.transactionSummaryReports, vm.userRole.pretty);
    }

    function nfrndsTransactionSummaryReportDataCSV() {
      var deferred = $q.defer();
      if (!vm.transactionSummaryReports) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getNfrndsTransactionSummaryReportsCSV(vm.transactionSummaryReports, vm.userRole.pretty);
    }

    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
     * [onGetBrands Loads required brands according to the supplier or buyer]
     */
    function onGetBrands() {
      vm.userRole.pretty === 'supplier' ? vm.getAllBrandsOfBuyerList(vm.filter.buyerIdList) : vm.getAllBuyerBrands();
    }

    /**
     * [getAllBuyerBrands gets all brands of logged in buyer]
     */
    function getAllBuyerBrands() {
      var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      if (vm.brands && !vm.filter.brandSelectionChanged)
        return false;
      vm.filter.brandSelectionChanged = false;
      BrandManagementService.getAllBrandsByBuyerId({
        query: '',
        page: 0,
        size: paginationConstants.maxLimit,
        buyerId: buyerId
      }).then(function (response) {
        vm.brands = response.data;
        vm.filter.supplierIdList = [];
      }, function (error) {
      });
    }

    /**
   * [getAllBrandsOfBuyerList Gets all brands of selected buyers]
   * @param {Array} buyerIdList
   */
    function getAllBrandsOfBuyerList(buyerIdList) {

      if (!buyerIdList || buyerIdList.length === 0 && !vm.filter.brandSelectionChanged) {
        toastr.error("Please select atleast one buyer", "No Buyer Selected");
        return false
      };
      vm.filter.brandSelectionChanged = false;
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        query: '',
        buyerId: buyerIdList,
        sort: sort()
      };
      BrandManagementService.getAllBrandsOfBuyerList(params)
        .then(function (response) {
          vm.brands = response;
        }, function (error) {
          NotificationService.error({
            title: 'Brand Listing',
            error: error
          });
        });
    }

    //Get all buyers mapped to the supplier
    function getAllSupplierMappedBuyers() {
      if (vm.buyers && !vm.filter.buyerSelectionChanged)
        return false;
      vm.filter.buyerSelectionChanged = false;
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
      };
      var supplierId = SupplierDataService.getSupplierProfile().supplier.id;
      BuyerSupplierMappingService.getAllSupplierMappedBuyers(supplierId, params).then(function (response) {
        vm.buyers = response.data;
        vm.filter.selectedBuyerIdList = [];
      }, function (error) {
      });
    }

    //Get all suppliers mapped to the buyer
    function getAllBuyerMappedSupplierList() {
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        brandIds: !vm.filter.brandIdList ? '' : vm.filter.brandIdList,
      };
      var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      BuyerSupplierMappingService.getAllBuyerMappedSupplierListWithParam(buyerId, params).then(function (response) {
        vm.suppliers = response.data;
      }, function (error) {
      });
    }
    //get all business outlets of the selected brands
    function getAllBusinessOutletsOfBrands() {
      if (!vm.filter.brandIdList) {
        toastr.error("Please select at least one brand", "No Brand Selected")
        return false;
      }
      BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser({
        brandIds: !vm.filter.brandIdList ? [] : vm.filter.brandIdList
      })
        .then(function (response) {
          vm.buyerBusinessOutlets = response;
        })
    }

    /**
     * [selectAll Selects all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, buyers or brands]
     */
    function selectAll(dropdown) {
      switch (dropdown) {
        case 'OUTLETS':
          vm.filter.businessOutletIdList = _.map(vm.buyerBusinessOutlets, _.property('id'));
          break;
        case 'BUYERS':
          vm.filter.buyerIdList = _.map(vm.buyers, _.property('id'));
          break;
        case 'BRANDS':
          vm.filter.brandIdList = _.map(vm.brands, _.property('id'));
          break;
        case 'SUPPLIERS':
          vm.filter.supplierIdList = _.map(vm.suppliers, _.property('supplierId'));
          break;
        case 'MAPPEDCARDS':
          vm.filter.cardIdList = _.map(vm.cardIdList, _.property('id'));
          break;
        case 'PAYMENT_STATUS':
          vm.paymentList = _.map(vm.paymentStatus, _.property('value'));
          break;
        case 'TRANSACTION_STATUS':
          vm.transactionStatusList = _.map(vm.transactionStatus, _.property('value'));
          break;
        case 'PAYMENT_CHANNEL':
          vm.paymentMethodsList = _.map(vm.paymentMethods, _.property('value'));

      }

    }

    /**
     * [deselectAll Deselect all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, buyers or brands]
     */
    function deselectAll(dropdown) {
      switch (dropdown) {
        case 'OUTLETS':
          vm.filter.businessOutletIdList = [];
          break;
        case 'BUYERS':
          vm.filter.buyerIdList = [];
          break;
        case 'BRANDS':
          vm.filter.brandIdList = [];
          break;
        case 'SUPPLIERS':
          vm.filter.supplierIdList = [];
          break;
        case 'MAPPEDCARDS':
          vm.filter.cardIdList = [];
          break;
        case 'PAYMENT_STATUS':
          vm.paymentList = [];
          break;
        case 'TRANSACTION_STATUS':
          vm.transactionStatusList = [];
        case 'PAYMENT_CHANNEL':
          vm.paymentMethodsList = [];
      }
    }

    /**
    * [loadAll Load All Receiving Order data]
    * @param  {[string]} search [search text]
    */
    function loadAll(search) {
      if (!search) {
        vm.searchParam = '';
      }
      var params = {
        paymentStatusList: !vm.paymentList ? [] : vm.paymentList,
        transactionStatusList: !vm.transactionStatusList ? [] : vm.transactionStatusList,
        paymentMethodList: !vm.paymentMethodsList ? [] : vm.paymentMethodsList,
        buyerIdList: [],
        supplierIdList: [],
        cardIdList: [],
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.searchParam,
        poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber,
        invoiceNumber: !vm.filter.invoiceNumber ? '' : vm.filter.invoiceNumber,
        brandIdList: !vm.filter.brandIdList ? [] : vm.filter.brandIdList,
        businessOutletIdList: !vm.filter.businessOutletIdList ? [] : vm.filter.businessOutletIdList,
        fromDate: !vm.filter.fromDate ? UTCDateService.utcFromDate(vm.todaysDate) : UTCDateService.utcFromDate(vm.filter.fromDate),
        toDate: !vm.filter.toDate ? UTCDateService.utcToDate(vm.todaysDate) : UTCDateService.utcToDate(vm.filter.toDate),
        // groupBy:!vm.filter.groupBy ? '': vm.filter.groupBy
      };
      vm.userRole.pretty === 'supplier' ? params['buyerIdList'] = !vm.filter.buyerIdList ? [] : vm.filter.buyerIdList : params['supplierIdList'] = !vm.filter.supplierIdList ? [] : vm.filter.supplierIdList;
      vm.userRole.pretty === 'buyer' ? params['cardIdList'] = !vm.filter.cardIdList ? [] : vm.filter.cardIdList : [];
      SupplierTransactionSummaryReportService.getAllTransactionSummaryReports(params).then(function (response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.transactionSummaryReports = response.data;
        var showDownload;
        vm.transactionSummaryReports.forEach(function(element){
            if(element.invoiceType === 'AXS_INVOICE'){
                if(element.transactionStatus.startsWith('http')){
                element.showDownload = true;
                }
                else{
                    element.showDownload = false;
                }
            }
        })
      }, function (error) {
        NotificationService.error({
          title: 'Transaction Summary Report',
          error: error
        });
      });
    }

    // select all
    function checkAllRows() {
      angular.forEach(vm.transactionSummaryReports, function (each) {
        each.selected = vm.selectAllRows;
      });
    }

    //Get all Cards of Buyer
    function getAllCards() {
      if (vm.mappedCards)
        return false;
    var params = {
        type: 'viewCards'
      };
      if(vm.userRole.pretty === 'buyer') {
          params.reportFilter = true;
      }
      CardsService.getAllCards(params).then(function (response) {
        vm.cardIdList = response.data;
      }, function (error) {
        NotificationService.error({
          title: 'Credit Card Details',
          error: error
        });
      });
    }

    vm.loadAll();
  }
})();
