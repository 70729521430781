/**
* @Author: Ajay
* @Date:   06-Mar-2017 15:47 +05:30
* @Project: Ezyprocure
* @Filename: supplier.process-order.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 09-Nov-2018 13:21 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('SupplierProcessOrderService', SupplierProcessOrderService);

  SupplierProcessOrderService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  function SupplierProcessOrderService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var SupplierProcessOrderService = {
      getAllProcessOrdersByStatus: getAllProcessOrdersByStatus,
      getProcessOrderByRequirementNumber: getProcessOrderByRequirementNumber,
      supplierAcceptOrder: supplierAcceptOrder,
      getReject_PR: getReject_PR,
      supplierPOAddItemDeviation:  supplierPOAddItemDeviation,
      deleteItemDeviation: deleteItemDeviation,
      generateMassSupplierProcessOrder: generateMassSupplierProcessOrder,
      getPurchaseOrdersByRequirementNumber: getPurchaseOrdersByRequirementNumber,
      editPriceOnPR: editPriceOnPR,
      checkForDuplicatePOs: checkForDuplicatePOs
    };

    return SupplierProcessOrderService;

    /* --  Supplier Process Order  APIs-- */
    
    //edit price on each item
    function editPriceOnPR(priceUpdateDTO){
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'supplier/price-updates/pr', priceUpdateDTO).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get All ProcessOrders by status
    function getAllProcessOrdersByStatus(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers/'+RESOURCE_URL_CONSTANTS.PROCESS_ORDER_RESOURCE,
        method: "GET",
        params:params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get ProcessOrders by requirementNumber
    function getProcessOrderByRequirementNumber(requirementNumber, outletName) {
      var deferred = $q.defer();
      var params = {
        "outletName": outletName
      };
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PROCESS_ORDER_RESOURCE + requirementNumber + '/details',
        method: "GET",
        params: params,
      }).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    // Reject_PR processOrderNumber input
        function getReject_PR(requirementNumber) {
              var deferred = $q.defer();
              $http({
                url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'supplier-reject-process-order/'+requirementNumber,
                method: "PUT"
              }).then(function(data) {
                deferred.resolve(data);
              }).catch(function(message) {
                deferred.reject(message);
              });
              return deferred.promise;
        }

    //Supplier Accept Order
    function supplierAcceptOrder(acceptOrderDTO) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers/accept-order', acceptOrderDTO).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //check for duplicate POs
    function checkForDuplicatePOs(acceptOrderDTO) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers/accept-order/validate', acceptOrderDTO).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Supplier Add Item Deviation
    function supplierPOAddItemDeviation(acceptOrderDTO) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PROCESS_ORDER_RESOURCE+ 'add-item', acceptOrderDTO).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get ProcessOrders by requirementNumber
    function deleteItemDeviation(poId) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PROCESS_ORDER_RESOURCE + poId ).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function generateMassSupplierProcessOrder(IdList){
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers/bulk/accept-order', IdList).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get PurchaseOrders By RequirementNumber
    function getPurchaseOrdersByRequirementNumber(requirementNumber) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PROCESS_ORDER_RESOURCE + requirementNumber + '/' +RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
