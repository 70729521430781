/**
* @Author: Ajay
* @Date:   14-Dec-2016 12:54 +05:30
* @Project: Ezyprocure
* @Filename: errorhandler.interceptor.js
* @Last modified by:   Ajay
* @Last modified time: 27-Mar-2017 18:57 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .factory('errorHandlerInterceptor', errorHandlerInterceptor);

  errorHandlerInterceptor.$inject = ['$q', '$rootScope'];

  function errorHandlerInterceptor ($q, $rootScope) {
    var service = {
      responseError: responseError
    };

    return service;

    function responseError (response) {
      // Emit http errors
      if (!(response.status === 401 && (response.data === '' || (response.data.path && response.data.path.indexOf('/api/account') === 0 )))) {
        $rootScope.$emit('ezyprocureApp.httpError', response);
      }
      return $q.reject(response);
    }
  }
})();
