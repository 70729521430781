/**
* @Author: sneha
* @Date:   2019-02-11T11:44:53+05:30
* @Project: Ezyprocure
* @Filename: supplier.sftp-details.service.js
* @Last modified by:   sneha
* @Last modified time: 2019-02-21T11:13:08+05:30
*/

(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('SupplierSFTPDetailsService', SupplierSFTPDetailsService);

  SupplierSFTPDetailsService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  function SupplierSFTPDetailsService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var SupplierSFTPDetailsService = {
      getAllSFTPDetails : getAllSFTPDetails,
      createSFTPServer: createSFTPServer,
      getSFTPDetailsById: getSFTPDetailsById,
      editSFTPDetails: editSFTPDetails
    };

    return SupplierSFTPDetailsService;

    /**
    * [getAllSFTPDetails get all sftp details by supplierId]
    * @param  {[type]} queryParams [Query Params]
    * @param {[object]} userInfo [User Info]
    * @return {[type]}            [description]
    */
    function getAllSFTPDetails(queryParams, userInfo) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.itemImageBulkUploadServiceBase + 'SFTP_Integrations/api/' + RESOURCE_URL_CONSTANTS.SFTP_SERVERS + 'mapped',
        method: "POST",
        params: queryParams,
        data: userInfo
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }


    /**
    * [createSFTPServer create sftp details]
    * @param  {[type]} SFTPData [sftp details]
    * @return {[type]}          [description]
    */
    function createSFTPServer(SFTPData) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.itemImageBulkUploadServiceBase + 'SFTP_Integrations/api/' + RESOURCE_URL_CONSTANTS.SFTP_SERVERS,
        method: "POST",
        data: SFTPData,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [getSFTPDetailsById get sftp details by sftp id]
    * @param  {[type]} sftpId [sftp Id]
    * @return {[type]}        [description]
    */
    function getSFTPDetailsById(sftpId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.itemImageBulkUploadServiceBase + 'SFTP_Integrations/api/' + RESOURCE_URL_CONSTANTS.SFTP_SERVERS + sftpId,
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [editSFTPDetails edit sftp details]
    * @param  {[type]} sftpDetails [sftp details]
    * @return {[type]}             [description]
    */
    function editSFTPDetails(sftpDetails) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.itemImageBulkUploadServiceBase + 'SFTP_Integrations/api/' + RESOURCE_URL_CONSTANTS.SFTP_SERVERS,
        method: "PUT",
        data: sftpDetails,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }


  }
})();
