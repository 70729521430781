/**
* @Author: Ajay
* @Date:   05-Apr-2017 16:58 +05:30
* @Project: Ezyprocure
* @Filename: buyer.promotions.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 01-Jun-2017 12:49 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('buyer.promotions', {
      parent: 'buyer',
      url: '/promotions?page&sort&search',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'Promotions'
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/purchase-management/promotions/buyer.promotions.html',
          controller: 'BuyerPromotionsController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,desc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('promotion');
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }],
        hasModuleAccess: ['Auth','$q', '$state', function(Auth, $q, $state) {
          if(Auth.hasPermission('accessForPromotion')){
            return true;
          }else {
            return $q.reject('Unauthorized');
          }
        }]
      }
    });
  }
})();
