/**
 * @Author: Ajay
 * @Date:   18-Jul-2018 15:49 +05:30
 * @Project: Ezyprocure
 * @Filename: manage-epd.list.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 20-Jul-2018 15:25 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('ManageEPDListController', ManageEPDListController);

  ManageEPDListController.$inject = ['$scope', '$rootScope', '$state', 'NotificationService', '$mdDialog', 'Auth', 'EpdApprovalRequestService', 'pagingParams', 'paginationConstants', 'GLOBAL_CONFIG_CONSTANTS'];

  function ManageEPDListController($scope, $rootScope, $state, NotificationService, $mdDialog, Auth, EpdApprovalRequestService, pagingParams, paginationConstants, GLOBAL_CONFIG_CONSTANTS) {
    var vm = this;

    vm.predicate = 'epdApprovalSequenceNumber';
    vm.reverse = pagingParams.ascending;
    vm.searchText = '';
    vm.loadAll = loadAll;
    vm.epdApprovalStatusClass = GLOBAL_CONFIG_CONSTANTS.EPD_APPROVAL_STATUS.LABEL_CLASS;
    vm.userRole = Auth.getUserRole();

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
     * [loadAll (Get All EPD(Early Payment Discount) ApprovalRequests]
     */
    function loadAll(search) {
      if (!search) {
        vm.searchText = '';
      }
      EpdApprovalRequestService.getAllEPDApprovalRequests({
        page: vm.query.page - 1,
        size: vm.query.limit,
        query: vm.searchText,
        sort: sort()
      }).then(function(response) {
        vm.epdApprovalRequests = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      }, function(error) {
        NotificationService.error({
          title: 'EPD Approval Request Listing',
          error: error
        });
      });
    }

    vm.loadAll();

    function deleteEPDApprovalRequest(epdApprovalRequestId) {
      EpdApprovalRequestService.deleteEPDApprovalRequest(epdApprovalRequestId).then(function(response) {
        NotificationService.success({
          title: 'EPD Approval Request',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'EPD Approval Request Deletion',
          error: error
        });
      });
    }

    vm.showConfirm = function(ev, epdApprovalRequest) {
      var confirm = $mdDialog.confirm()
      .title('Are you sure you want to delete EPD Approval Request?')
      .textContent('This action could not be reverted back.')
      .targetEvent(ev)
      .ok('Delete')
      .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        deleteEPDApprovalRequest(epdApprovalRequest.id);
      }, function() {});
    };

  }
})();
