/**
* @Author: Abhay
* @Date:   02-Feb-2020 16:15 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.cybersource-cards.controller.js
* @Last modified by:   Abhay
* @Last modified time: 02-Feb-2020 16:15 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzypaymentCybersourceCardsController', EzypaymentCybersourceCardsController);

  EzypaymentCybersourceCardsController.$inject = ['$stateParams', '$state', '$window', 'CardsService', 'NotificationService', '$mdDialog', 'GLOBAL_CONSTANTS', 'paginationConstants'];

  function EzypaymentCybersourceCardsController($stateParams, $state, $window, CardsService, NotificationService, $mdDialog, GLOBAL_CONSTANTS, paginationConstants) {

    var vm = this;

    vm.deleteCard = deleteCard;
    vm.showConfirm = showConfirm;
    vm.init = init;
    vm.limitOptions = paginationConstants.limitOptions;
    vm.initiallyActiveTab = $stateParams.initiallyActiveTab;
    //cybersource
    vm.cybersourceQuery = {
      limit : paginationConstants.itemsPerPage,
      page : 1
    }
    vm.maskedCardNumber = $stateParams.maskedCardNumber;
    vm.message = $stateParams.message;
    vm.cybersourceCardDetails = [];
    vm.totalCybersourceCards = 0;
    vm.user = 'BUYER';
    vm.setToDefault = setToDefault;
    vm.getAllCybersourceCards = getAllCybersourceCards;

    function init() {
      getAllCybersourceCards();
      showCardAddResponse();
    }

    function showCardAddResponse() {
      if(vm.message) {
        $mdDialog.show(
          $mdDialog.alert()
            .parent(angular.element(document.body))
            .clickOutsideToClose(true)
            .title(vm.message == "1" ? "Card added successfully" : "Error on card process!")
            .textContent("Card Number: " + vm.maskedCardNumber)
            .ariaLabel('Alert Dialog Demo')
            .ok('Got it!')
        ).then(function() {
          $state.go($state.current, {maskedCardNumber:"",message:"",env:""}, {reload: true});
        },
        function() {
          $state.go($state.current, {maskedCardNumber:"",message:"",env:""}, {reload: true});
        });
      }
    }

    function setToDefault(user) {
      vm.user = user;
      if(vm.user) {
        vm.limitOptions = paginationConstants.limitOptions;
        vm.cybersourceQuery = {
          limit : paginationConstants.itemsPerPage,
          page : 1
        }
        getAllCybersourceCards();
      }
    }

    function getAllCybersourceCards() {
      CardsService.getCybersourceCardsByType({
        type: vm.user,
        page: vm.cybersourceQuery.page - 1,
        size: vm.cybersourceQuery.limit,
        query: ''
      }).then(function(response){
        vm.cybersourceCardDetails = response.data;
        vm.totalCybersourceCards = response.headers('X-Total-Count');
      },function(error) {
        NotificationService.error({
          title: 'Cybersource Card Details',
          error: error
        });
      });
    }

    function deleteCard(id) {
      CardsService.deleteCybersourceCardById(id).then(function(response){
        NotificationService.success({
          title: response.data.status,
          message: response.data.message
        });
        getAllCybersourceCards();
      },function(error) {
        NotificationService.error({
          title: 'Delete Credit Card',
          error: error
        });
      });
    }

    // Shows modal before deleting the card
    function showConfirm (id, ev) {
      var confirm = $mdDialog.confirm()
      .title('Are you sure you want to delete this Card Detail?')
      .textContent('This action could not be reverted back.')
      .targetEvent(ev)
      .ok('Delete')
      .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        deleteCard(id);
      }, function() {
      });
    };

  }
})();
