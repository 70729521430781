/*
 * @Author: Lovin 
 * @Date: 2020-06-22 10:56:22 
 * @Last Modified by: Lovin
 * @Last Modified time: 2020-06-25 13:20:17
 */


(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerSupplierMappingAddController', BuyerSupplierMappingAddController);

  BuyerSupplierMappingAddController.$inject = ['$state','$scope', '$translate','$stateParams','toastr', 'Auth', '$mdDialog','BuyerSupplierMappingTableService','SupplierDataService','BuyerDataService'];

  function BuyerSupplierMappingAddController ($state,$scope, $translate,$stateParams,toastr, Auth,$mdDialog,BuyerSupplierMappingTableService,SupplierDataService,BuyerDataService) {

    var vm = this;
    vm.role = $stateParams.role;
    vm.selected = '1';
    var today = new Date();
    vm.details;
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    today = yyyy+'-'+mm+'-'+dd;
    vm.addBuyerOrSuppliers = addBuyerOrSuppliers;
    vm.cancelDetails = cancelDetails;
    vm.userRole = Auth.getUserRole();
    vm.userInfo = {
        "code": "",
        "email": "",
        "name": "",
        "phone": "",
        "username": "",
        "companyName":"",
    }
    vm.onInit = function() {
      vm.role = $stateParams.role;
    };

    function cancelDetails(){
        if(vm.role == 'buyer'){
        $state.go('buyer.mapping')
      }
      else if(vm.role == 'supplier'){
        $state.go('supplier.mapping')
    }
    }
    vm.closeModal = function() {
      $mdDialog.cancel();
    };

  function addBuyerOrSuppliers(userInfoForm) {
    if (userInfoForm.$invalid) {
      toastr.error('Please fill all user info', 'Details Required');
    }
    else{
      if(vm.role == 'buyer'){
        supplierInfo();
      }
      else if(vm.role != 'buyer'){
        buyerInfo();
      
      }
      // emailValidation()
    }
  }

  function emailValidation() {
    BuyerSupplierMappingTableService.emailValidate(vm.userInfo.email,'null')
      .then(function (res) {
        if (res == true) {
          toastr.error('Already existing email or username')
        } 
        else{
        if(vm.role == 'buyer'){
          companySupplierValidation();

          
         }
         else if(vm.role == 'supplier'){
          companyBuyerValidation();
         }
        }
      })
  }


  vm.cancelPopup = function() { $mdDialog.cancel(); }; 

  vm.detailPage = function(userId) { 
    $mdDialog.cancel();
    if(vm.role == 'buyer'){
      $state.go('buyer.mapping.details', { Id:userId,visibility:'ALLOCATED'});
    }
    else if(vm.role == 'supplier'){
      $state.go('supplier.mapping.details', { Id:userId,visibility:'ALLOCATED'});
    }
  }; 

  function showConfiramtion(result){
    vm.details = result;
    $mdDialog.show({
      templateUrl: 'app/common-modules/buyer-supplier-mapping-table/templates/company-details-confirmation.html',
      controller: function() {
        return vm;
      },
      controllerAs: 'vm',
      targetEvent: event,
      clickOutsideToClose: false
    });
  }
  function companySupplierValidation() {
    BuyerSupplierMappingTableService.companySupplierValidation(vm.userInfo.companyName,vm.userInfo.companyNo, 'null')
      .then(function (res) {
        var reader = new FileReader();
        reader.onload = function() {
          // if (reader.result === 'Validated Successfully') {
          //   supplierInfo();
          // } 
           if(JSON.parse(reader.result).isCompanyRegistrationNumber == true) {
            var response = JSON.parse(reader.result);
            showConfiramtion(response);
          } 
          else if(JSON.parse(reader.result).isCompanyName == true){
            toastr.error($translate.instant('ezyprocureApp.buyer.onBoarding.companyNameValidate'))
          }
          else if(JSON.parse(reader.result).isCompanyCode == true){
            toastr.error($translate.instant('ezyprocureApp.buyer.onBoarding.companyCodeValidate'))
          }
          else { 
            supplierInfo();
            // toastr.error(reader.result);
          }
        }
        reader.readAsText(res);
      })
  }

  function companyBuyerValidation() {
    BuyerSupplierMappingTableService.companyValidation(vm.userInfo.companyName,vm.userInfo.companyNo, 'null')
      .then(function (res) {
        var reader = new FileReader();
        reader.onload = function() {
          // if (reader.result === 'Validated Successfully') {
          //   buyerInfo()
          // } 
           if(JSON.parse(reader.result).isCompanyRegistrationNumber == true) {
            var response = JSON.parse(reader.result);
            showConfiramtion(response);
          } 
          else if(JSON.parse(reader.result).isCompanyName == true){
            toastr.error($translate.instant('ezyprocureApp.buyer.onBoarding.companyNameValidate'))
          }
          else if(JSON.parse(reader.result).isCompanyCode == true){
            toastr.error($translate.instant('ezyprocureApp.buyer.onBoarding.companyCodeValidate'))
          }
          else {
            buyerInfo();
            // toastr.error(reader.result)
          }
        }
        reader.readAsText(res);
      })
  }
    function supplierInfo(){
      vm.buyerInfo = {
        "acceptedTimeStamp":today,
        "buyerId":vm.buyerId =  BuyerDataService.getBuyerProfile().buyerUser.buyer.id,
        "code":vm.userInfo.code,
        "email":vm.userInfo.email,
        "inviteAccepted": false,
        "invitedByBuyer": true,
        "invitedTimeStamp":today,
        "isInviteAccepted": false,
        "isInvitedByBuyer": true,
        "name": vm.userInfo.name,
        "phone": vm.userInfo.phone,
        // "username":vm.userInfo.username,
        "companyName":vm.userInfo.companyName,
        "companyRegistrationNumber":vm.userInfo.companyNo,
        "companyAddress":vm.userInfo.companyAddr
    }
      BuyerSupplierMappingTableService.addBUyerOrSupplier(vm.buyerInfo)
      .then(function(response) {
        toastr.success('Supplier Added Successfully')
        $state.go('buyer.mapping')
      }).catch(function (error) {
        toastr.error($translate.instant(error.data.responseList[0].responseType),$translate.instant(error.data.message));
      });
    }

  
    function buyerInfo(){
      vm.supplierInfo = {
        "acceptedTimeStamp":today,
        "code":vm.userInfo.code,
        "email":vm.userInfo.email,
        "inviteAccepted": false,
        "invitedByBuyer": false,
        "invitedTimeStamp":today,
        "isInviteAccepted": false,
        "isInvitedByBuyer": false,
        "name": vm.userInfo.name,
        "phone": vm.userInfo.phone,
        "supplierId": vm.supplierId =  SupplierDataService.getSupplierProfile().supplier.id,
        // "username":vm.userInfo.username,
        "companyName":vm.userInfo.companyName,
        "companyRegistrationNumber":vm.userInfo.companyNo,
        "companyAddress":vm.userInfo.companyAddr
    }
      BuyerSupplierMappingTableService.addBUyerOrSupplier(vm.supplierInfo)
      .then(function(response) {
        toastr.success('Buyer Added Successfully');
        $state.go('supplier.mapping');
      }).catch(function (error) {
        toastr.error($translate.instant(error.data.responseList[0].responseType),$translate.instant(error.data.message));
      });
    }
    
  }
})();
