/**
* @Author: Ajay
* @Date:   14-Dec-2016 12:54 +05:30
* @Project: Ezyprocure
* @Filename: app.state.js
* @Last modified by:   Ajay
* @Last modified time: 31-Mar-2017 18:21 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('app', {
      abstract: true,
      views: {

      },
      resolve: {
        authorize: ['Auth',
          function(Auth) {
            return Auth.authorize();
          }
        ],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
        }]
      }
    });
  }
})();
