/*
 * @Author: Lovin C 
 * @Date: 2021-08-16 12:00:04 
 * @Last Modified by:   Lovin C 
 * @Last Modified time: 2021-08-16 12:00:04 
 */

(function () {
  'use strict';

  angular.module('ezyprocureApp').factory('EddaAccountMappingService', EddaAccountMappingService);

  EddaAccountMappingService.$inject = ['$http', '$q', 'API_CONSTANTS'];

  function EddaAccountMappingService($http, $q, API_CONSTANTS) {

    var EddaAccountMappingService = {
      createEddaAccountMapping: createEddaAccountMapping,
      getEddaAccountMappings: getEddaAccountMappings,
      deleteDDAReference: deleteDDAReference,
      getSupplierEddaAccountMappings: getSupplierEddaAccountMappings,
      getSupplierDetailsById: getSupplierDetailsById,
      existingEddaAccountMapping: existingEddaAccountMapping
    };
    return EddaAccountMappingService;

    // get supplier details by id
    function getSupplierDetailsById(supplierId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers/' + supplierId,
        method: "GET"
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    // create new dda account mapping
    function createEddaAccountMapping(data) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'ocbc/mandate/invite',
        method: "POST",
        data: data,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //get all dda account mapping details of buyer
    function getEddaAccountMappings(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'ocbc/dda/reference/details/buyer',
        method: "GET",
        params: params
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //get all supplier dda account mapping details
    function getSupplierEddaAccountMappings() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'ocbc/dda/reference/details/supplier',
        method: "GET"
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //delete dda reference by id
    function deleteDDAReference(ddaReferenceId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'ocbc/dda/reference/' + ddaReferenceId,
        method: "DELETE"
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //update existing dda account with new barnds
    function existingEddaAccountMapping(data) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'ocbc/dda/reference/existing-mapping',
        method: "POST",
        data: data,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
