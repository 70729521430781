/**
 * @Author: Ajay
 * @Date:   24-Jul-2018 14:52 +05:30
 * @Project: Ezyprocure
 * @Filename: bank.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 27-Jul-2018 10:33 +05:30
 */



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BankService', BankService);

  BankService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function BankService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var bankService = {
      getAllBanks: getAllBanks,
      getBank: getBank,
      createBank: createBank,
      updateBank: updateBank,
      deleteBank: deleteBank,
      getSelectedBank: getSelectedBank
    };

    return bankService;

    /* --  Bank CRUID operations  -- */

    //Get All  banks
    function getAllBanks(urlParams) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BANK_RESOURCE,
        method: "GET",
        params: urlParams,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get bank details using bank id
    function getBank(bankId) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BANK_RESOURCE + bankId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Create a new  bank
    function createBank(bank) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BANK_RESOURCE, bank).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update  bank
    function updateBank(bank) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BANK_RESOURCE, bank).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Soft Delete  bank using bankId
    function deleteBank(bankId) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BANK_RESOURCE + bankId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get bank details using bank id
    function getSelectedBank(banks, bankId) {
      var selectedBank = null;
      if(bankId){
        selectedBank = _.find(banks, function(bank) { return bank.id === bankId; });
      }
      return selectedBank;
    }

  }
})();
