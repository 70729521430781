/**
* @Author: Abhay
* @Date:   12-Jan-2021 16:03 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.supplier.release-notes.state.js
* @Last modified by:   Abhay
* @Last modified time: 12-Jan-2021 16:03 +05:30
*/



(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .config(stateConfig);
  
    stateConfig.$inject = ['$stateProvider'];
  
    function stateConfig($stateProvider) {
      $stateProvider.state('ezypayment.supplier.release-notes', {
        parent: 'ezypayment.supplier',
        url: '/release-notes',
        data: {
          authorities: ['ROLE_SUPPLIER'],
          pageTitle: 'Release Notes'
        },
        views: {
          'content@': {
            templateUrl: 'app/common-modules/release-notes/release-notes.html',
            controller: 'ReleaseNotesController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', function($translate) {
            return $translate.refresh();
          }]
        }
      });
    }
  })();
  