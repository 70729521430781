/*
 * @Author: Lovin 
 * @Date: 2019-06-21 11:50:30 
 * @Project: Ezyprocure
 * @Filename: admin.uen-report.service.js
 * @Last Modified by: Lovin
 * @Last Modified time: 2020-02-26 18:46:25
 */


(function() {
    'use strict';
  
    angular.module('ezyprocureApp').factory('UENReportService', UENReportService);
  
    UENReportService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  
    function UENReportService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {
  
      var uenReportService = {
        getUENReport: getUENReport,
        getUENReportConsolidated: getUENReportConsolidated
      };
  
      return uenReportService;
  
  
      //Get All uen report listview
      function getUENReport(urlParams) {
        var deferred = $q.defer();
        $http({
          url: 'api/' + 'admin/invoices',
          method: "GET",
          params: urlParams,
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

       //Get All uen-report conslidated
       function getUENReportConsolidated(urlParams) {
        var deferred = $q.defer();
        $http({
          url:  API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/'+ 'uen-report',
          method: "GET",
          params: urlParams,
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
  
    }
  })();
  