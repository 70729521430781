/**
 * @Author: Fenn
 * @Date:   28-Feb-2020 15:53 +05:30
 * @Project: Ezyprocure
 * @Filename: alex-world.controller.js
 */
(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('AlexWorldController', AlexWorldController);

  AlexWorldController.$inject = ['$scope', '$rootScope', '$state', '$sce', '$stateParams', 'AlexWorldService', 'SupplierDataService', 'Auth', 'AuthServerProvider', 'BuyerDataService'];

  function AlexWorldController ($scope, $rootScope, $state, $sce, $stateParams, AlexWorldService, SupplierDataService, Auth, AuthServerProvider, BuyerDataService) {
    var vm = this;
    vm.userExitOrNot = false;
    vm.iframeBaseUrl = 'https://alex.world/ezy_procure_registrations/new';
    vm.iframeUrl = trustAsResourceUrl(vm.iframeBaseUrl);
    vm.status;
    vm.id = $stateParams.Id;

    vm.userData = userData;
    vm.trustAsResourceUrl = trustAsResourceUrl;
    vm.lsbUserValidation = lsbUserValidation;

    function userData() {
      var authJWT = AuthServerProvider.getToken();
      var decAuthJWT = jwt_decode(authJWT);
      vm.role = decAuthJWT.auth;

      var email;
      
      if(vm.role == 'ROLE_BUYER'){
        email = BuyerDataService.getBuyerProfile().buyerUser.user.email;
      }
      else if(vm.role == 'ROLE_SUPPLIER'){
        email = SupplierDataService.getSupplierProfile().user.email;
      }
      lsbUserValidation(email)
    }

    function lsbUserValidation(userEmail) {
      AlexWorldService.validatingLbsUser(userEmail).
      then(function(response) {
        if (response == null) {
          vm.userExitOrNot = true;
        } else if(response.token) {
          vm.userExitOrNot = false;
        }
        vm.iframeUrl = trustAsResourceUrl(vm.iframeBaseUrl+'?email='+userEmail+'&new_user='+vm.userExitOrNot);
        
      }, function(error) {
        vm.iframeUrl = vm.trustAsResourceUrl(vm.iframeBaseUrl+'?email='+userEmail+'&new_user=true');
      });
    }

    function trustAsResourceUrl(url) {
      return $sce.trustAsResourceUrl(url);
    }
  }
})();
