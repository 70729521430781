/*
 * @Author: Jayalekshmi 
 * @Date: 2019-09-02 13:02:01 
 * @Project: Ezyprocure
 * @Filename: ezyap.manage-delivery-details.service.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-10-24 10:31:55
 */

(function () {
    'use strict';
  
    angular.module('ezyprocureApp').factory('ManageDeliveryDetailsService', ManageDeliveryDetailsService);
  
    ManageDeliveryDetailsService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  
    function ManageDeliveryDetailsService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {
  
      var ManageDeliveryDetailsService = {
        addDeliveryDetails: addDeliveryDetails,
        getDeliveryDetails: getDeliveryDetails,
        getDeliveryDetailsById: getDeliveryDetailsById,
        deleteDeliveryDetailsById: deleteDeliveryDetailsById,
        editDeliveryDetails: editDeliveryDetails
      };
      return ManageDeliveryDetailsService;
   
      /**
       * [add buyer information]
       * @param {Object} data [buyer information data]
       */
      function addDeliveryDetails(data) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/delivery-details',
          method: "PUT",
          data: data
        }).then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
  
      /**
       * [edit buyer information]
       * @param {Object} data [buyer information data]
       */
      function editDeliveryDetails(data) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/delivery-details',
          method: "POST",
          data: data
        }).then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
  
      /**
       * [get buyer information]
       * @param {String} params [search text] 
       */
      function getDeliveryDetails(params) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/delivery-details',
          method: "GET",
          params: params
        }).then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
  
      /**
       * [get individual delivery information by id]
       * @param {Integer} id [delivery information id]
       */
      function getDeliveryDetailsById(id) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/delivery-details/' + id,
          method: "GET"
        }).then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
  
      /**
       * [delete buyer information by id ]
       * @param {Integer} id [buyer information id]
       */
      function deleteDeliveryDetailsById(id) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/delivery-details/' + id,
          method: "DELETE",
          params: id
        }).then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
    }
  })();