/**
* @Author: Abhay
* @Date:   04-Aug-2020 20:34 IST
* @Project: Ezyprocure
* @Filename: buyer.adhoc-items-promotions.service.js
* @Last modified by:   Abhay
* @Last modified time: 04-Aug-2020 20:34 IST
*/

(function () {
    'use strict';

    angular.module('ezyprocureApp').factory('AdhocPromotionService', AdhocPromotionService);

    AdhocPromotionService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS', 'UTCDateService'];

    function AdhocPromotionService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS, UTCDateService) {

        var promotionService = {
            getAdhocSupplierItems: getAdhocSupplierItems
        }

        return promotionService;

        function getAdhocSupplierItems(params) {
            var deferred = $q.defer();
            $http({
                url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyer/adhoc/promotion/items',
                method: "GET",
                params: params,
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (message) {
                deferred.reject(message);
            });
            return deferred.promise;
        }
    }
})();