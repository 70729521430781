/**
 * @Author: Ajay
 * @Date:   18-Jul-2018 15:19 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.manage-payment-approval-level.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 30-Aug-2018 16:53 +05:30
 */



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BuyerManagePaymentApprovalLevelService', BuyerManagePaymentApprovalLevelService);

  BuyerManagePaymentApprovalLevelService.$inject = ['$http', '$q', 'API_CONSTANTS'];

  function BuyerManagePaymentApprovalLevelService($http, $q, API_CONSTANTS) {

    var BuyerManagePaymentApprovalLevelService = {
      setApprovalLevelFilter: setApprovalLevelFilter,
      getApprovalLevelFilter: getApprovalLevelFilter
    };

    var approvalLevelFilter;

    return BuyerManagePaymentApprovalLevelService;

    /**
     * [getApprovalLevelFilter retrive adhoc invoice filter]
     * @return {[object]} [adhoc invoice object containing buyer list and business outlets]
     */
    function getApprovalLevelFilter() {
      return approvalLevelFilter ? approvalLevelFilter : null;
    }

    /**
     * [setApprovalLevelFilter store adhoc invoice filter]
     * @param {[object]} approvalLevelFilterParam [adhoc invoice object containing buyer list and business outlets]
     */
    function setApprovalLevelFilter(approvalLevelFilterParam) {
      approvalLevelFilter = approvalLevelFilterParam;
    }

  }
})();
