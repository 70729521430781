/**
* @Author: sneha
* @Date:   16-Apr-2018 17:51 +05:30
* @Project: Ezyprocure
* @Filename: supplier.picking-list.item-select.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 11-May-2018 12:39 +05:30
*/

(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierPickingListSelectItemsController', SupplierPickingListSelectItemsController);

  SupplierPickingListSelectItemsController.$inject = ['$scope', '$rootScope', '$state', '$filter', 'NotificationService', '$mdDialog', 'SupplierItemService', 'pagingParams', 'paginationConstants', 'SupplierPickingListService', 'toastr'];

  function SupplierPickingListSelectItemsController($scope, $rootScope, $state, $filter, NotificationService, $mdDialog, SupplierItemService, pagingParams, paginationConstants, SupplierPickingListService, toastr) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.loadAll = loadAll;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.supplierItems = [];
    vm.showMore = showMore;
    vm.addItemsToList = addItemsToList;
    vm.getItems = getItems;
    vm.selectAllItems = selectAllItems;
    vm.deselectAll = deselectAll;

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
    * [loadAll load All supplier items]
    * @param  {[type]} search [search param]
    * @return {[type]}        [description]
    */
    function loadAll (search) {
      if (!search) {
        vm.searchText = '';
      }
      // empty supplierItem object if on first page and if search param is present
      else if (search==='search' && vm.query.page === 1){
        vm.supplierItems = [];
      }
      // empty both search param and supplierItem object if user clicks on refresh button
      else if (search === 'reload'){
        vm.supplierItems = [];
        vm.searchText = '';
      }
      SupplierItemService.getAllSupplierItems({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        buyerId: '',
        query: vm.searchText
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.hasMore = _.gt(parseInt(vm.totalItems), (vm.query.page  * vm.query.limit));
        vm.supplierItems = vm.supplierItems.concat(response.data);
        vm.pageDetails = "Showing " + (vm.supplierItems.length === 0 ? "0" : "1") + " to " + $filter('number')(vm.supplierItems.length) + " of " + $filter('number')(vm.totalItems) + " entries";
        vm.getItems();
      }, function(error) {
        NotificationService.error({
          title: 'Items Listing',
          error: error
        });
      });
    }

    function showMore() {
      vm.query.page += 1;
      if(vm.searchText!='')
       vm.loadAll('search');
      else {
        vm.loadAll();
      }
    }

    /**
    * [selectAllItems select all supplier items]
    * @return {[type]} [description]
    */
    function selectAllItems() {
      vm.selectAll ? vm.selectAll = true : vm.selectAll = false;
      _.map(vm.supplierItems, function(supplierItem) {
        supplierItem.selected = vm.selectAll;
      });
    }

    /**
    * [deselectAll deselect all items on clicking cancel]
    * @param  {[type]} cancel [description]
    * @return {[type]}        [description]
    */
    function deselectAll(cancel){
      SupplierPickingListService.deselectAllItems(vm.supplierItems);
      if(cancel)
       $state.go('supplier.picking-list-management.picking-list', {reload:false});
    }

    /**
    * [addItemsToList store selectedItems in a temporary object in service]
    */
    function addItemsToList(){
      var itemList = SupplierPickingListService.addItemsToList(vm.supplierItems);
      // number of items in picking list template should be greater than 6 and less than or equal to 100
      if(itemList.items.length >= 6){
        if(itemList.items.length > 100)
         toastr.error('Only 100 items are allowed!', 'Too many Items');
        else
         $state.go('supplier.picking-list-management.picking-list.add', {reload:false})
      }
      else {
        toastr.error('Please select atleast 6 items to proceed!', 'Items Required');
      }
    }
    /**
    * [getItems get items from the picking-list object stored in service]
    * @return {[type]} [description]
    */
    function getItems(){
      var listItems = SupplierPickingListService.getListItems();
      //compare the pickingList object with supplier items array and set 'selected' key to true
      _.map(listItems.items, function(item){
        var index = _.findIndex(vm.supplierItems, ['id', item.id]);
        vm.supplierItems[index].selected = true;
      });
    }

  }
})();
