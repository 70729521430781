/**
 * @Author: Ajay
 * @Date:   18-Jul-2018 15:49 +05:30
 * @Project: Ezyprocure
 * @Filename: epd-approval-requests.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 20-Jul-2018 15:25 +05:30
 */



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('EpdApprovalRequestService', EpdApprovalRequestService);

  EpdApprovalRequestService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function EpdApprovalRequestService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var epdApprovalRequestService = {
      getAllEPDApprovalRequests: getAllEPDApprovalRequests,
      getEPDApprovalRequest: getEPDApprovalRequest,
      createEPDApprovalRequest: createEPDApprovalRequest,
      updateEPDApprovalRequest: updateEPDApprovalRequest,
      deleteEPDApprovalRequest: deleteEPDApprovalRequest,
      approveEPDApprovalRequest: approveEPDApprovalRequest,
      rejectEPDApprovalRequest: rejectEPDApprovalRequest
    };

    return epdApprovalRequestService;

    /* --  EPD(Early Payment Discount) ApprovalRequest CRUID operations  -- */

    //Get All ePDApprovalRequests
    function getAllEPDApprovalRequests(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.EPD_APPROVAL_REQUESTS,
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get ePDApprovalRequest details using ePDApprovalRequest id
    function getEPDApprovalRequest(ePDApprovalRequestId) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.EPD_APPROVAL_REQUESTS + ePDApprovalRequestId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Create a new Admin ePDApprovalRequest
    function createEPDApprovalRequest(ePDApprovalRequest) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.EPD_APPROVAL_REQUESTS, ePDApprovalRequest).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update Admin ePDApprovalRequest
    function updateEPDApprovalRequest(ePDApprovalRequest) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.EPD_APPROVAL_REQUESTS, ePDApprovalRequest).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Soft Delete Admin ePDApprovalRequest using ePDApprovalRequestId
    function deleteEPDApprovalRequest(ePDApprovalRequestId) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.EPD_APPROVAL_REQUESTS + ePDApprovalRequestId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //approve a EPD Approval Request
    function approveEPDApprovalRequest(epdApprovalRequest) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.EPD_APPROVAL_REQUESTS + 'approve' , epdApprovalRequest).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //reject a EPD Approval Request
    function rejectEPDApprovalRequest(epdApprovalRequest) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.EPD_APPROVAL_REQUESTS + 'reject' , epdApprovalRequest).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
