/**
* @Author: Ajay
* @Date:   27-May-2017 11:05 +05:30
* @Project: Ezyprocure
* @Filename: buyer.bo-user-mapping.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 07-May-2018 14:41 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerBusinessUserMapController', BuyerBusinessUserMapController);

  BuyerBusinessUserMapController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'NotificationService', 'pagingParams', '$mdDialog', 'BuyerBusinessService', 'BuyerUserManagementService', 'BuyerBusinessUserMapService', 'paginationConstants'];

  function BuyerBusinessUserMapController($scope, $rootScope, $state, $stateParams, NotificationService, pagingParams, $mdDialog, BuyerBusinessService, BuyerUserManagementService, BuyerBusinessUserMapService, paginationConstants) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.sort = sort;
    vm.loadAll = loadAll;
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.getAllBuyerUsers = getAllBuyerUsers;
    vm.isBusinessBuyerUserMapped = isBusinessBuyerUserMapped;
    vm.createBusinessBuyerUserMapping = createBusinessBuyerUserMapping;
    vm.deleteBusinessBuyerUserMapping = deleteBusinessBuyerUserMapping;
    vm.mapping = {};
    vm.mappedItemSearch = '';
    vm.mapping.buyerUserId = $stateParams.buyerUserId;
    vm.isBuyerUserDisabled = $stateParams.buyerUserId ? true : false;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    // Load All supplier items which are mapped
    function loadAll(search) {
      if (!search) {
        vm.mappedItemSearch = '';
      }
      BuyerBusinessUserMapService.getAllBusinessUserMappings({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.businessUserMappingSearch,
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.businessUserMappings = response.data;
      },function(error){
        NotificationService.error({
          title: 'Mapped Buyer User - Business Listing',
          error: error
        });
      });
    }


    vm.loadAll();

    function getAllBuyerBusinessOutlets() {
      if(vm.businessOutlets)
        return false;
      BuyerBusinessService.getAllBuyerBusinesses({
        query: '',
        page: 0,
        size: paginationConstants.maxLimit
      }).then(function(response) {
        vm.businessOutlets = response.data;
      }, function(error) {
      });
    }

    //Load all buyer users
    function getAllBuyerUsers() {
      if(vm.buyerUsers)
        return false;
      BuyerUserManagementService.getAllBuyerUsers({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.buyerUsers = response.data;
      }, function(error) {
      });
    }

    function isBusinessBuyerUserMapped(mapping) {
      if (!mapping.businessOutletId || !mapping.buyerUserId)
        return false;
      BuyerBusinessUserMapService.isBusinessBuyerUserMapped(mapping).then(function(response) {
        vm.isAlreadyMapped = response;
      }, function(error) {
      });
    }

    function createBusinessBuyerUserMapping() {
      // Map selected buyer and supplier
      BuyerBusinessUserMapService.createBusinessBuyerUserMapping(vm.mapping).then(function(response) {
        $scope.$emit('ezyprocureApp:bsMapped', vm.mapping);
        NotificationService.success({
          title: 'Business Outlet - User',
          message: 'Mapped successfully'
        });
        vm.isBusinessBuyerUserMapped(vm.mapping);
      }, function(error) {
        NotificationService.error({
          title: 'Business Outlet - User Mapping',
          error: error
        });
      });
    }

    function deleteBusinessBuyerUserMapping() {
      // Map selected buyer and supplier
      BuyerBusinessUserMapService.deleteBusinessBuyerUserMapping(vm.mapping).then(function(response) {
        $scope.$emit('ezyprocureApp:bsUnmapped', vm.mapping);
        NotificationService.success({
          title: 'Business Outlet - User Mapping',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.isBusinessBuyerUserMapped(vm.mapping);
      }, function(error) {
        NotificationService.error({
          title: 'Business Outlet - User Mapping Deletion',
          error: error
        });
      });
    }
  }
})();
