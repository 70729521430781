/**
* @Author: Allan
* @Date:   01-October-2017 15:12 +05:30
* @Project: Ezyprocure
* @Filename: buyer.dashboard.service.js
 * @Last modified by:   Allan
 * @Last modified time: 01-October-2017 10:26 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('EzypaymentBuyerDashboardService', EzypaymentBuyerDashboardService);

  EzypaymentBuyerDashboardService.$inject = ['$http', '$q', 'API_CONSTANTS'];
  function EzypaymentBuyerDashboardService($http, $q, API_CONSTANTS) {

    var ezypaymentBuyerDashboardService = {
      getEzypaymentBuyerDashboardCount: getEzypaymentBuyerDashboardCount
    };

    return ezypaymentBuyerDashboardService;

    function getEzypaymentBuyerDashboardCount() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'ezypayment/buyers/dashboard',
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
