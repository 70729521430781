/**
 * @Author: Ajay
 * @Date:   27-Apr-2017 12:48 +05:30
 * @Project: Ezyprocure
 * @Filename: supplier.adhoc-invoice.item-selection.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 13-Apr-2018 15:10 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierAdhocInvoiceItemSelectionController', SupplierAdhocInvoiceItemSelectionController);

  SupplierAdhocInvoiceItemSelectionController.$inject = ['$scope', '$state', '$filter', '$q', 'SupplierItemService', 'SupplierAdhocInvoiceService', 'NotificationService'];

  function SupplierAdhocInvoiceItemSelectionController($scope, $state, $filter, $q, SupplierItemService, SupplierAdhocInvoiceService, NotificationService) {
    var vm = this;
    vm.init = init;
    vm.deliveryDate = new Date();
    vm.searchForItems = searchForItems;
    vm.searchText = '';
    vm.adhocInvoiceDTO = {};
    vm.adhocInvoiceDTO.cartItems = [];
    vm.itemSearchQuery = {};
    vm.addNewAdhocInvoiceRow = addNewAdhocInvoiceRow;
    vm.deleteAdhocInvoiceRow = deleteAdhocInvoiceRow;
    vm.itemChanged = itemChanged;
    vm.createNewAdhocInvoice = createNewAdhocInvoice;

    function init() {
      vm.adhocInvoiceFilter = SupplierAdhocInvoiceService.getAdhocInvoiceFilter();
      if(vm.adhocInvoiceFilter === null) {
        $state.go('supplier.tools.adhoc-invoice');
      }
      else {
        vm.itemSearchQuery = angular.copy(vm.adhocInvoiceFilter);
        delete vm.itemSearchQuery.businessList;
        delete vm.itemSearchQuery.buyerList;
        vm.addNewAdhocInvoiceRow();
      }
    }

    function searchForItems(queryType, queryText) {
      var deferred = $q.defer();
      if(!queryText) {
        return;
      }
      else {
        vm.itemSearchQuery.queryField = queryType;
        vm.itemSearchQuery.queryText = queryText;
        SupplierItemService.searchAllItems(vm.itemSearchQuery)
        .then(function(response) {
          deferred.resolve($filter('orderBy')(response.data, 'itemName'));
        }, function(error) {
          deferred.reject(error);
        });
      }
      return deferred.promise;
    }

    function itemChanged(cartItem, index) {
      var temp = angular.copy(cartItem);
      cartItem.items = temp.selectedItem;
      cartItem.itemName = temp.selectedItem.itemName;
      cartItem.itemCode = temp.selectedItem.itemCode;
      cartItem.uom = temp.selectedItem.uom;
      cartItem.price = temp.selectedItem.price;
      cartItem.moq = temp.selectedItem.moq;
      cartItem.itemId = temp.selectedItem.id;
    }

    function getAdhocInvoiceDTO(cartItems) {
      var cartItemList = _.map(cartItems, function(cartItem) { return _.pick(cartItem, 'buyerBusiness', 'items', 'quantity', 'supplierType', 'price'); });
      var cartList = _.map(cartItemList, function(item) {
        return _.extend({}, item, {deliveryDate: vm.deliveryDate});
      });
      return cartList;
    }

    function createNewAdhocInvoice() {
      var adhocInvoiceDTO = {
        buyerId: vm.itemSearchQuery.buyerIdList[0],
        cartItems: getAdhocInvoiceDTO(vm.adhocInvoiceDTO.cartItems),
        hardcopyInvoiceNumber: vm.adhocInvoiceDTO.hardcopyInvoiceNumber
      };
      SupplierAdhocInvoiceService.createNewAdhocInvoice(adhocInvoiceDTO)
      .then(function(response) {
        NotificationService.success({
          title: 'Adhoc Invoice request',
          message: response.data.message
        });
        $state.go('supplier.dashboard');
      }, function(error) {
        NotificationService.error({
          title: 'Adhoc Invoice request',
          error: error
        });
      });
    }

    function addNewAdhocInvoiceRow() {
      vm.adhocInvoiceDTO.cartItems.push({
        buyerBusiness: {
          id: vm.adhocInvoiceFilter.selectedBusiness.businessId
        },
        supplierType: 'allocated'
      });
    }

    function deleteAdhocInvoiceRow(index) {
      vm.adhocInvoiceDTO.cartItems.splice(index, 1);
    }
  }
})();
