/**
* @Author: Ajay
* @Date:   27-Feb-2017 16:21 +05:30
* @Project: Ezyprocure
* @Filename: buyer.items.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 05-Dec-2017 15:22 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerItemListController', BuyerItemListController);

  BuyerItemListController.$inject = ['$scope', '$rootScope', '$state', 'NotificationService', '$mdDialog', 'BuyerItemService', 'pagingParams', 'paginationConstants'];

  function BuyerItemListController($scope, $rootScope, $state, NotificationService, $mdDialog, BuyerItemService, pagingParams, paginationConstants) {
    var vm = this;

    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;

    vm.loadAll = loadAll;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function loadAll (search) {
      if (!search) {
        vm.searchText = '';
      }
      BuyerItemService.getAllBuyerItems({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        supplierId: '',
        query: vm.searchText,
      })
      .then(function(response){
        vm.items = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      },function(error){
        NotificationService.error({
          title: 'Item Listing',
          error: error
        });
      });
    }


    vm.loadAll();

    function deleteBuyerItem(buyerItemId) {
      BuyerItemService.deleteBuyerItem(buyerItemId).then(function(response) {
        NotificationService.success({
          title: 'Item',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'Item Deletion',
          error: error
        });
      });
    }

    vm.showConfirm = function(ev, buyerItem) {
      var confirm = $mdDialog.confirm()
      .title('Are you sure you want to delete the adhoc-buyer Item?')
      .textContent('This action could not be reverted back.')
      .targetEvent(ev)
      .ok('Delete')
      .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        deleteBuyerItem(buyerItem.id);
      }, function() {});
    };
  }
})();
