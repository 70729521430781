/*
 * @Author: Ajay 
 * @Date: 2019-02-21 14:52:47 
 * @Project: Ezyprocure
 * @Filename: ezyap.state.js
 * @Last Modified by: Ajay
 * @Last Modified time: 2019-02-21 14:53:14
 */

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {

    $stateProvider.state('ezyap', {
      abstract: true,
      parent: 'app',
      url: '/ezyap',
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    });
  }
})();
