/**
* @Author: Ajay
* @Date:   27-Feb-2017 16:21 +05:30
* @Project: Ezyprocure
* @Filename: date.filter.js
 * @Last modified by:   Ajay
 * @Last modified time: 26-Mar-2018 13:14 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .filter('stringToDate', stringToDate);

  function stringToDate() {
    return stringToDateFilter;

    function stringToDateFilter(input) {
      if (input !== null) {
        return new Date(input);
      }
    }
  }
})();
