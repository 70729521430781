/**
* @Author: Fenn Saji
* @Date:   27-Feb-2020
* @Project: Ezyprocure
* @Filename: add-card.controller.js
*/



(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('ListCardsMpgsAccountController', ListCardsMpgsAccountController);

  ListCardsMpgsAccountController.$inject = ['$rootScope', '$stateParams', '$mdDialog', 'SubscriptionPlan', '$filter',
  '$state', '$scope', 'NotificationService', 'toastr', 'AuthServerProvider', 'BuyerAccountService', 'SupplierAccountService',
  'Principal'];

  function ListCardsMpgsAccountController($rootScope, $stateParams, $mdDialog, SubscriptionPlan, $filter,
    $state, $scope, NotificationService, toastr, AuthServerProvider, BuyerAccountService, SupplierAccountService,
    Principal) {
    var vm = this;
    vm.onInit = onInit;
    vm.savedList = [];
    vm.defaultCardList = [];
    vm.deleteCard = deleteCard;
    vm.selectPayment = selectPayment;

    function onInit() {
      var authJWT = AuthServerProvider.getCbToken();
      var decAuthJWT = jwt_decode(authJWT);
      vm.userRole = decAuthJWT.auth === 'ROLE_BUYER' ? 'buyer' : 'supplier';
      vm.userId = decAuthJWT.uid;
      if (vm.userRole === 'buyer') {
        BuyerAccountService.getCbBuyerProfile()
          .then(function (buyerInfo) {
            vm.companyId = buyerInfo.buyerUser.buyer.id;
          });
      } else if (vm.userRole === 'supplier') {
        SupplierAccountService.getCbSupplierProfile()
          .then(function (supplierInfo) {
            vm.companyId = supplierInfo.supplier.id;
          });
      }
      fetchAllCards();
    }

    function fetchAllCards() {
      SubscriptionPlan.fetchCards($stateParams.userId)
        .then(function (response) {
          if (response) {
            vm.savedList = response.SAVED;
            vm.defaultCardList = response.DEFAULT;
          }
        }).catch(function () {
          toastr.error('Something went Wrong', 'Error');
        });
    }
    function deleteCard(ev, id, index) {
      var confirm = $mdDialog.confirm()
        .title($filter('translate')('alertMessage.DELETE_CARD_PAYMENT'))
        .textContent('  ')
        .targetEvent(ev)
        .ok('Ok')
        .cancel('Cancel');

      $mdDialog.show(confirm).then(function () {
        deletePaymentCard(id, index);
      }, function () { });
    }
    /* select Payment*/
    function selectPayment(ev, id) {
      var confirm = $mdDialog.confirm()
        .title($filter('translate')('alertMessage.SELECT_CARD_PAYMENT'))
        .textContent('  ')
        .targetEvent(ev)
        .ok('Ok')
        .cancel('Cancel');

      $mdDialog.show(confirm).then(function () {
        completePayment(id);
      }, function () { });
    }

    function deletePaymentCard(id, index) {
      SubscriptionPlan.deleteCard(id)
        .then(function (response) {
          if (response == true) {
            vm.savedList.splice(index, 1);
            toastr.success('Deleted Successfully');
          }
        }).catch(function () {
          toastr.error('Something went Wrong', 'Error');
        });
    }
    
    vm.goBack = function () {
      $state.go($stateParams.fromPage, $stateParams.params);
    }

    function completePayment(tokenId) {
      var object;
      if($rootScope.updateOutlets) {
        object = {
          amount: $rootScope.outletsAmount,
          cardHolderName: null,
          outlet:  $rootScope.numberOfOutlets,
          sessionId: null,
          tokenId: tokenId,
          userPlanId: $rootScope.OutletUserPlanId
        }
        return SubscriptionPlan.updateOutletsAndPay(object)
          .then(function() {
            $rootScope.updateOutlets = false;
            Principal.setUserVerifiedCB(false);
            $state.go(vm.userRole + '.dashboard');
          });
      }
      if ($rootScope.transactionFailed == true) {
        object = {
          sessionId: null,
          tokenId: tokenId,
          userId: vm.userId,
          paymentId: $rootScope.transactionPaymentId,
          companyId: vm.companyId,
          buyer: vm.userRole === 'supplier' ? false : true,
          cardHolderName: null
        }
      } else {
        object = {
          sessionId: null,
          tokenId: tokenId,
          userId: vm.userId,
          planDetailsDTO: $rootScope.subscriptionPlanRequest,
          companyId: vm.companyId,
          buyer: vm.userRole === 'supplier' ? false : true,
          cardHolderName: null
        }
      }
      SubscriptionPlan.addPlanAndPayment(object)
      .then(function(res){
        if(!res){
          return toastr.error($filter('translate')('alertMessage.PLAN_EXISTS'), 'Error');
        }
        if(res && res.length !=0) {
          var success = res.map(function(e){
              return e.paymentFailure;
            })
            .includes(true);
          if (!success){
            toastr.success('Saved Successfully');
            Principal.setUserVerifiedCB(false);
            $state.go(vm.userRole+'.dashboard');
          } else {
            toastr.error($filter('translate')('alertMessage.PAYMENT_FAILED'), 'Error');
          }
        } else {
          toastr.error($filter('translate')('alertMessage.PAYMENT_FAILED'), 'Error');
        }
      }).catch(function() {
        toastr.error('Something went Wrong', 'Error');
      });
  }
  }

})();
