/*
 * @Author: Lovin C 
 * @Date: 2021-08-29 16:12:09 
 * @Last Modified by: Lovin C
 * @Last Modified time: 2021-09-15 10:46:47
 */




(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('CustomInvoiceUploadController', CustomInvoiceUploadController);

  CustomInvoiceUploadController.$inject = ['toastr', 'pagingParams', 'NotificationService', 'paginationConstants', 'GLOBAL_CONSTANTS', 'ExcelUploadService', 'UtilService', 'BuyerPayeeService', 'S3_URL_CONSTANTS', 'CsvUploadService', 'CartService', '$mdDialog', 'CustomPaymentsService', '$state'];

  function CustomInvoiceUploadController(toastr, pagingParams, NotificationService, paginationConstants, GLOBAL_CONSTANTS, ExcelUploadService, UtilService, BuyerPayeeService, S3_URL_CONSTANTS, CsvUploadService, CartService, $mdDialog, CustomPaymentsService, $state) {

    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = true;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.getExcelBase64 = getExcelBase64;
    vm.resetValues = resetValues;
    vm.addPayeeViaExcelUpload = addPayeeViaExcelUpload;
    vm.sort = sort;
    vm.excelUploadData = {};
    vm.PAYMENT_UPLOAD_CSV = S3_URL_CONSTANTS.PAYMENT_UPLOAD_CSV;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.csvUpload = csvUpload;
    vm.fileUpload = fileUpload;
    vm.uploadAttachment = uploadAttachment;
    vm.addInvoicePreview = addInvoicePreview;
    vm.showConfirm = showConfirm;

    function addInvoicePreview() {
      if (validateInvoice()) {
        CustomPaymentsService.setInvoiceData(vm.invoiceData);
        $state.go('ezypayment.buyer.custom-invoice-payments.add-invoice.preview');
      }
    }

    function validateInvoice() {
      var isValid = true
      if(!checkInvoiceIdDuplicates()) {
        isValid = false;
        toastr.error('Duplicate invoice number', 'Error');
        return isValid;
      }
      if(!checkInvoiceDocPresent()) {
        isValid = false;
        toastr.error('Missing invoice Doc', 'Error');
        return isValid;
      }
      return true;
    }

    function checkInvoiceIdDuplicates() {
      var isUnique = true;
      var invoiceIdList = vm.invoiceData.map(function(invoice) {
        return invoice.invoiceNumber;
      });
      invoiceIdList.forEach(function(id, index, arr) {
        isUnique = (arr.indexOf(id) != index) ? false : isUnique;
      });
      return isUnique;
    }

    function checkInvoiceDocPresent() {
      var isPresent = true;
      vm.invoiceData.forEach(function(invoice) {
        if(!invoice.invoiceDoc) {
          isPresent = false;
        }
      })
      return isPresent;
    }

    function showConfirm(ev, index) {
      var confirm = $mdDialog.confirm()
        .title('Are you sure you want to delete?')
        .textContent('This action could not be reverted back.')
        .targetEvent(ev)
        .ok('Delete')
        .cancel('Cancel');

      $mdDialog.show(confirm).then(function () {
        deleteInvoice(index);
      }, function () { });
    }

    function deleteInvoice(index) {
      vm.invoiceData.splice(index, 1);
    }

    function csvUpload() {
      var csvFile = vm.file[0];
      vm.isCsvFile = CsvUploadService.checkFileType(csvFile.lfFile);
      if (vm.isCsvFile) {
        CsvUploadService.getFileBase64(csvFile.lfFile, function(base64CSV) {
          CustomPaymentsService.customInvoiceCsv({
            encodedFile: base64CSV
          })
          .then(function(response) {
            if (Array.isArray(response.data.customCsvInvoiceDTOS)) {
              vm.invoiceData = response.data.customCsvInvoiceDTOS.map(function (data) {
                var invoiceData = {
                  supplierId: data.supplier.id,
                  supplierName: data.supplier.companyName,
                  brandId: data.brand.id,
                  brandName: data.brand.name,
                  invoiceNumber: data.invoiceNumber,
                  invoiceDate: data.invoiceDate,
                  amount: data.amount,
                  invoiceDoc: null,
                  otherDoc: null
                }
                return invoiceData;
              });
            }
          }, function(error) {
            vm.files = [];
            vm.recordError = error.data;
            NotificationService.error({
              title: 'Custom Payment Invoice',
              error: error
            });
          });
        });
      }
    }

    function fileUpload(index, type) {
      vm.index = index;
      vm.type = type;
      $mdDialog.show({
        templateUrl: 'app/ezypayment-modules/buyer-modules/custom-invoice-payments/templates/ezypayement.custom-payment.upload-attachement.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function uploadAttachment() {
      CartService.getBase64(vm.files[0].lfFile, function (base64file) {
        if (vm.type === 'INVOICE_UPLOAD') {
          vm.invoiceData[vm.index].invoiceDoc = {
            'base64String': base64file,
            'extension': vm.files[0].lfFile.name.split('.').pop(),
            'fileName': vm.files[0].lfFile.name.split('.').shift()
          }
          // vm.addInvoice[vm.index].otherDoc = {};
        }
        else {
          vm.invoiceData[vm.index].otherDoc = {
            'base64String': base64file,
            'extension': vm.files[0].lfFile.name.split('.').pop(),
            'fileName': vm.files[0].lfFile.name.split('.').shift()
          }
        }
      });
      $mdDialog.cancel();
    }

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function clearErrorList() {
      vm.translatedError.responseList = [];
    }

    function clearTableData() {
      vm.payees = [];
    }

    /**
     *  getExceBase64 gives the given excel in base64 encoded format
     */
    function getExcelBase64() {
      vm.errorList = null;
      var excelFile = vm.files[0];
      if (!excelFile) {
        NotificationService.simpleErrorToast({
          title: 'alertMessage.INVALID_INPUT',
          message: 'global.pleaseChooseAExcelFile'
        });
        return false;
      }
      clearErrorList();
      ExcelUploadService.getFileBase64(excelFile.lfFile, function (base64Excel) {
        vm.excelUploadData = base64Excel;
        vm.addPayeeViaExcelUpload(vm.excelUploadData);
      });
    }

    /**
     * addPayeeViaExcelUpload upload a payee list to be added
     * @param {uploadData}  
     */
    function addPayeeViaExcelUpload(uploadData) {
      BuyerPayeeService.addPayeeViaExcelUpload({ encodedExcelData: uploadData })
        .then(function (response) {
          vm.files = [];
          clearTableData();
          NotificationService.success({
            title: 'global.addPayeeExcel',
            message: 'alertMessage.UPLOADED_SUCCESSFULLY'
          });
        }, function (error) {
          vm.translatedError = error.data;
          NotificationService.error({
            title: 'alertMessage.ADD_PAYEE_FAILED',
            error: error
          });
        });
    }

    function resetValues() {
      vm.payees = [];
      vm.translatedError = [];
    }

  }
})();
