/**
* @Author: Sandhiya
* @Project: Ezyprocure
* @Filename: admin-calendar-service.js
* @Last modified by:   Sandhiya
* @Last modified time: 17-SEP-2017 14:22 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('AdminCalendarService', AdminCalendarService);

  AdminCalendarService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function AdminCalendarService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var adminCalendarService = {
      getAllAdminCalendars: getAllAdminCalendars,
      getAdminCalendar: getAdminCalendar,
      createAdminCalendar: createAdminCalendar,
      updateAdminCalendar: updateAdminCalendar,
      deleteAdminCalendar: deleteAdminCalendar,
      calendarBulkUploadCSV: calendarBulkUploadCSV
    };

    return adminCalendarService;

    /* --  SupplierCalendar CRUID operations  -- */

    /**
    * [getAllSupplierCalendars Get All supplierCalendars]
    * @param  {[Object]} urlParams [urlParams]
    * @return {[List]}           [SupplierCalendars]
    */
    function getAllAdminCalendars(urlParams) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin-calendars-list/',
        method: "GET",
        params: urlParams,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [getSupplierCalendar Get supplierCalendar details using supplierCalendar id]
    * @param  {[Integer]} supplierCalendarId [supplierCalendarId]
    * @return {[Object]}                    [supplierCalendar]
    */
    function getAdminCalendar(supplierCalendarId) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin-calendars/' + supplierCalendarId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [createSupplierCalendar Create a new supplierCalendar]
    * @param  {[Object]} supplierCalendar [supplierCalendar Object]
    * @return {[Object]}                  [response object - success of error]
    */
    function createAdminCalendar(supplierCalendar) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin-calendars/', supplierCalendar).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update supplierCalendar
    /**
    * [updateSupplierCalendar Update supplier calendar]
    * @param  {[Object]} supplierCalendar [supplierCalendar Object]
    * @return {[Object]}                  [response object - success of error]
    */
    function updateAdminCalendar(supplierCalendar) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin-calendars/', supplierCalendar).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [deleteSupplierCalendar Soft Delete supplierCalendar using supplierCalendarId]
    * @param  {[Integer]} supplierCalendarId [supplierCalendarId]
    * @return {[Object]}                  [response object - success of error]
    */
    function deleteAdminCalendar(supplierCalendarId) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin-calendars/' + supplierCalendarId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function calendarBulkUploadCSV(uploadData) {
          var deferred = $q.defer();
          $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin-calendars/bulk-upload', uploadData).then(function(data) {
            deferred.resolve(data.data);
          }).catch(function(message) {
            deferred.reject(message);
          });
          return deferred.promise;
        }
  }
})();
