/**
 * @Author: Jayalekshmi 
 * @Date: 2019-01-03 11:02:02 
 * @Project: Ezyprocure
 * @Filename: supplier.sats.state.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-03-11 17:00:46
 */



(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .config(stateConfig);
  
    stateConfig.$inject = ['$stateProvider'];
  
    function stateConfig($stateProvider) {
      $stateProvider
      .state('supplier.sats', {
        parent: 'supplier',
        url: '/sats',
        data: {
          authorities: ['ROLE_SUPPLIER'],
          pageTitle: 'SATS'
        },
        views: {
          'content@': {
            templateUrl: 'app/common-modules/sats/sats.html',
            controller: 'SatsController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,asc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          hasModuleAccess: ['Auth','$q', '$state', function(Auth, $q, $state) {
            if(Auth.hasPermission('accessForSATS')){
              return true;
            }else {
              return $q.reject('Unauthorized');
            }
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('items');
            return $translate.refresh();
          }]
        }
      })
      .state('supplier.sats.po-confirm', {
        parent: 'supplier.sats',
        url: '/po/confirm/:poJobId',
        data: {
          authorities: ['ROLE_SUPPLIER'],
        },
        views: {
          'content@': {
            templateUrl: 'app/common-modules/sats/po/confirm/sats.po.confirm.html',
            controller: 'SatsPOConfirmController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,desc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
              $translatePartialLoader.addPart('items');
            return $translate.refresh();
          }]
        }
      })
      .state('supplier.sats.sr-confirm', {
        parent: 'supplier.sats',
        url: '/sr/confirm/:srJobId',
        data: {
          authorities: ['ROLE_SUPPLIER'],
        },
        views: {
          'content@': {
            templateUrl: 'app/common-modules/sats/sr/confirm/sats.sr.confirm.html',
            controller: 'SatsSRConfirmController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,desc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('items');

            return $translate.refresh();
          }]
        }
      })
      .state('supplier.sats.to-confirm', {
        parent: 'supplier.sats',
        url: '/to/confirm/:toJobId',
        data: {
          authorities: ['ROLE_SUPPLIER'],
        },
        views: {
          'content@': {
            templateUrl: 'app/common-modules/sats/to/confirm/sats.to.confirm.html',
            controller: 'SatsTOConfirmController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,desc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('items');
            return $translate.refresh();
          }]
        }
      })
      .state('supplier.sats.logs-history', {
        parent: 'supplier.sats',
        url: '/logs-history',
        data: {
          authorities: ['ROLE_SUPPLIER'],
        },
        views: {
          'content@': {
            templateUrl: 'app/common-modules/sats/log-history/sats.logs-history.html',
            controller: 'SATSLogsHistoryController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,desc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          hasModuleAccess: ['Auth','$q', '$state', function(Auth, $q, $state) {
            if(Auth.hasPermission('accessForSATS')){
              return true;
            }else {
              return $q.reject('Unauthorized');
            }
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
          }]
        }
      });
    }
  
  })();
  