/**
* @Author: Allan
* @Date:   2018-09-13 13:00:30
* @Filename: ezypayment.sip-management.controller.js
* @Last modified by:   Allan
* @Last modified time: 2018-09-22T11:07:23+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzypaymentSIPManagementController', EzypaymentSIPManagementController);

  EzypaymentSIPManagementController.$inject = ['toastr', 'EzypaymentSupplierSupplierInitiatePaymentService', '$filter', 'NotificationService', 'GLOBAL_CONSTANTS'];

  function EzypaymentSIPManagementController(toastr, EzypaymentSupplierSupplierInitiatePaymentService, $filter, NotificationService, GLOBAL_CONSTANTS) {

    var vm = this;

    vm.getBrandModelMappingList = getBrandModelMappingList;
    vm.sendRequest = sendRequest;
    vm.brandModelMappingList = [];
    vm.init = init;
    vm.sipStatus = GLOBAL_CONSTANTS.approvalStatus;
    vm.filter = {};

    function init(){
      vm.getBrandModelMappingList();
    }

    function getBrandModelMappingList(){
      EzypaymentSupplierSupplierInitiatePaymentService.getBrandList({
        "eAuthorizationRequestStatus": !vm.filter.sipStatus ? "" : vm.filter.sipStatus
      }).then(function(response){
        vm.brandModelMappingList = response.data;
      },function(error){
        NotificationService.error({
          title: 'Brand Model Mapping List',
          error: error
        });
      });
    }

    function sendRequest(id){
      var filter = $filter("filter")(vm.brandModelMappingList, {id: id});
      var brand = filter[0].brand;
      EzypaymentSupplierSupplierInitiatePaymentService.sendRequest(brand).then(function(response){
        vm.getBrandModelMappingList();
        NotificationService.success({
          title: "SIP Approval Request",
          message: response.data.approvalStatus
        });
      },function(error){
        NotificationService.error({
          title: 'SIP Approval Request',
          error: error
        });
      });
    }

  }
})();
