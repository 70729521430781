/**
* @Author: Ajay
* @Date:   07-Mar-2017 15:17 +05:30
* @Project: Ezyprocure
* @Filename: supplier.email.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 01-Jun-2017 13:16 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('supplier.send-email', {
      parent: 'supplier',
      url: '/send-email',
      data: {
        authorities: ['ROLE_SUPPLIER']
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/email/supplier.email.html',
          controller: 'SupplierEmailController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          return $translate.refresh();
        }]
      }
    });
  }
})();
