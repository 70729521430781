/**
* @Author: Akhil Jose
* @Date:   01-Feb-2020
* @Project: Ezyprocure
* @Filename: plan-card.controller.js
*/



(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .controller('PlanCardController', PlanCardController);
  
    PlanCardController.$inject = ['$scope', '$rootScope'];
  
    function PlanCardController($scope, $rootScope) {
      var vm = this;
      vm.selTextColor = '';
      vm.selBgColor = '';

      vm.planCardColor = '';
      vm.planTextColor = '';
      vm.planFooterColor = '';

      vm.subscribePlan = subscribePlan;

      vm.priceFontSize = {
        'font-size': '60px' 
      }

      vm.cardInit = function(plan) {
        if ($rootScope.planColorAvail.length == 0) {
          $rootScope.planColorAvail = JSON.parse(JSON.stringify($rootScope.planColors));
        }
        var planClass = choosePlanColor($rootScope.planColorAvail);
        if (plan.value && plan.value.toString().length >= 5) {
          vm.priceFontSize = {
            'font-size': '45px' 
          }
        }
        vm.selTextColor = planClass.selTextColor;
        vm.selBgColor = planClass.selBgColor;
        vm.planFooterColor = vm.selBgColor;
        vm.planTextColor = planClass.selTextColor;
      };

      $scope.$on('selectPlan', function(event, data) {
        var plan = data.plan;
        if (plan.type === $scope.plan.type) {
          if (plan.id == $scope.plan.id &&
            data.select) {
            vm.planCardColor = vm.selBgColor;
            vm.planTextColor = '';
            vm.planSelected = true;
          } else {
            vm.planSelected = false;
            vm.planCardColor = '';
            vm.planTextColor = vm.selTextColor;
          }
        }        
      });

      function choosePlanColor(array) {
        return selectRandomValue(array);
      }

      function selectRandomValue(array) {
        var index = Math.abs(Math.floor(Math.random() * array.length));
        var data = array[index];
        $rootScope.planColorAvail.splice(index, 1);
        return data;
      }

      function subscribePlan() {
        $scope.$emit('changedSelectedPlan', {plan:$scope.plan, select: vm.planSelected});
      }
    }
  })();
  