/**
 * @Author: Ajay
 * @Date:   06-Apr-2017 10:21 +05:30
 * @Project: Ezyprocure
 * @Filename: admin.manage-limits.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 01-Jun-2017 13:07 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('admin.managelimits', {
      parent: 'admin',
      url: '/managelimits',
      data: {
        authorities: ['ROLE_ADMIN'],
        pageTitle: 'Manage Limits'
      },
      views: {
        'content@': {
          templateUrl: 'app/admin-modules/manage-limits/admin.manage-limits.html',
          controller: 'AdminManageLimitsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          return $translate.refresh();
        }]
      }
    });
  }
})();
