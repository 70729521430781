/**
* @Author: Ajay
* @Date:   03-Mar-2017 15:12 +05:30
* @Project: Ezyprocure
* @Filename: buyer.unable-meet-demands.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 27-Feb-2018 13:59 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerUnableMeetDemandsController', BuyerUnableMeetDemandsController);

  BuyerUnableMeetDemandsController.$inject = ['$stateParams', '$scope', '$state', 'toastr', 'BuyerUnableMeetDemandsService', 'pagingParams', 'GLOBAL_CONFIG_CONSTANTS', 'paginationConstants', 'GLOBAL_CONSTANTS'];

  function BuyerUnableMeetDemandsController($stateParams, $scope, $state, toastr, BuyerUnableMeetDemandsService, pagingParams, GLOBAL_CONFIG_CONSTANTS, paginationConstants, GLOBAL_CONSTANTS) {

    var vm = this;

    vm.init = init;
    vm.loadAll = loadAll;
    vm.searchItem = '';
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    vm.sumdClass = GLOBAL_CONFIG_CONSTANTS.SUMD_CONSTANTS;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;

    /**
     * [sort to sort by PO date]
     */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /*Load all unable to meet demands by supplier*/
    function loadAll(search) {
      if (!search) {
        vm.searchText = '';
      }
      vm.processOrderStatus = 'SUPPLIER_UNABLE_TO_MEET_DEMANDS';
      BuyerUnableMeetDemandsService.supplierUnableMeetDemandRequirements(vm.processOrderStatus, {
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.searchText
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.sumdRequirements = response.data;
      }, function(error) {
      });
    }

    function init() {
      vm.loadAll();
    }


  }
})();
