/**
* @Author: Vaibhavi
* @Date:   24-Jun-2018 16:35 +05:30
* @Project: Ezyprocure
* @Filename: buyer.new-item-log.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 02-Nov-2018 12:46 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('newItemLogController', newItemLogController);

  newItemLogController.$inject = ['$scope', '$rootScope', '$q', 'ItemGroupService', 'ReportService','pagingParams', 'UTCDateService','BusinessReportService', 'paginationConstants', 'MainItemGroupService', 'CSVExportService', 'EXPORT_CONSTANTS', 'NotificationService', 'GLOBAL_CONSTANTS'];

  function newItemLogController($scope, $rootScope, $q , ItemGroupService, ReportService, pagingParams, UTCDateService, BusinessReportService, paginationConstants, MainItemGroupService, CSVExportService, EXPORT_CONSTANTS, NotificationService, GLOBAL_CONSTANTS) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.getAllSuppliers = getAllSuppliers;
    vm.loadMainItemGroups = loadMainItemGroups;
    vm.getAllGroupedItemGroups = getAllGroupedItemGroups;
    vm.mainItemGroupSelectionChanged = mainItemGroupSelectionChanged;
    vm.itemGroupSelectionChanged = itemGroupSelectionChanged;
    vm.resetFilters = resetFilters;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.filter = {
      mainItemGroupIdList: [],
      itemGroupIdList: [],
      supplierIdList: []
    };
    vm.loadAll = loadAll;
    vm.sort=sort;

    vm.todaysDate = new Date();
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };

    vm.limitOptions = paginationConstants.limitOptions;
    //CSV Header
    vm.newItemLogHeader = EXPORT_CONSTANTS.CSV.newItemLogHeader;
    //CSV Function Declaration
    vm.getNewItemLogCSVData = getNewItemLogCSVData;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;

    //Getting CSV DATA
    function getNewItemLogCSVData(){
      var deferred = $q.defer();
      if (!vm.newItemLogs) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getNewItemLogCSVData(vm.newItemLogs);
    }

    /**
    * [sort to sort by supplier details]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
    * [loadMainItemGroups Gets all Main Item Groups]
    */
   function loadMainItemGroups() {
    if (vm.mainItemGroups){
      return false;
    }
    MainItemGroupService.getAllMainItemGroups({
      page: 0,
      size: paginationConstants.maxLimit,
      query: ''
    }).then(function(response) {
      vm.mainItemGroups = response.data;
    }, function(error) {
    });
  }

  /**
  * [getAllGroupedItemGroups Gets all ItemGroups Grouped by Main Item Groups]
  */
  function getAllGroupedItemGroups(mainItemGroupIdList) {
    if (vm.itemGroups && !vm.filter.mainItemGroupChanged){
      return false;
    }
    vm.filter.mainItemGroupChanged = false;
    ItemGroupService.getAllGroupedItemGroups(mainItemGroupIdList, {
      page: 0,
      size: paginationConstants.maxLimit,
      query: ''
    }).then(function(response) {
      vm.itemGroups = response.data;
    }, function(error) {
    });
  }

  /**
  * [getAllSuppliers Get all suppliers which satisfy the following]
  * 1. should be mapped with current logged in buyer
  * 2. who are selling items of selected main item groups and item groups
  */
  function getAllSuppliers() {
    if (vm.supplierList && !vm.filter.itemGroupChanged) {
      return false;
    }
    vm.filter.itemGroupChanged = false;
    ReportService.getAllSuppliers({
      mainItemGroupIdList: vm.filter.mainItemGroupIdList,
      itemGroupIdList: vm.filter.itemGroupIdList
    }, {
      page: 0,
      size: paginationConstants.maxLimit
    }).then(function(response) {
      vm.supplierList = response.data;
    }, function(error) {
    });
  }

  function mainItemGroupSelectionChanged() {
    vm.filter.mainItemGroupChanged = true;
    vm.filter.itemGroupIdList = [];
    vm.filter.supplierIdList = [];
  }

  function itemGroupSelectionChanged() {
    vm.filter.itemGroupChanged = true;
    vm.filter.supplierIdList = [];
  }

  function resetFilters() {
    vm.filter = {};
    vm.filter.fromDate = vm.todaysDate;
    vm.filter.toDate = vm.todaysDate;
    vm.newItemLogs = null;
  }

  /**
   * [selectAll Selects all from the list]
   * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, mainItemGroups or itemGroups]
   */
  function selectAll(dropdown) {
    switch (dropdown) {
    case 'OUTLETS':
      vm.filter.buyerBusinessIdList = _.map(vm.businessOutlets, _.property('id'));
      break;
    case 'SUPPLIERS':
      vm.filter.supplierIdList = _.map(vm.supplierList, _.property('id'));
      break;
    case 'MAIN_ITEMGROUPS':
      vm.mainItemGroupSelectionChanged();
      vm.filter.mainItemGroupIdList = _.map(vm.mainItemGroups, _.property('id'));
      break;
    case 'ITEMGROUPS':
      vm.itemGroupSelectionChanged();
      vm.filter.itemGroupIdList = _.map(_.flatten(_.without(_.map(vm.itemGroups, _.property('itemGroupDTOList')), undefined, null)), _.property('itemGroupId'));
      break;
    }
  }

  /**
   * [deselectAll Deselect all from the list]
   * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, mainItemGroups or itemGroups]
   */
  function deselectAll(dropdown) {
    switch (dropdown) {
    case 'OUTLETS':
      vm.filter.buyerBusinessIdList = [];
      break;
    case 'SUPPLIERS':
      vm.filter.supplierIdList = [];
      break;
    case 'MAIN_ITEMGROUPS':
      vm.filter.mainItemGroupIdList = [];
      vm.mainItemGroupSelectionChanged();
      break;
    case 'ITEMGROUPS':
      vm.filter.itemGroupIdList = [];
      vm.itemGroupSelectionChanged();
      break;
    }
  }

    /**
    * [loadAll Loads all the user input and returns the response]
    */
    function loadAll() {
      BusinessReportService.getNewItemLogReport({
        supplierIdList: !vm.filter.supplierIdList ? [] : vm.filter.supplierIdList,
        mainItemGroupIdList: !vm.filter.mainItemGroupIdList ? [] : vm.filter.mainItemGroupIdList,
        itemGroupIdList: !vm.filter.itemGroupIdList ? [] : vm.filter.itemGroupIdList,
        //If dates are selected, they are converted to UTC format
        fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
        toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate)
      },{
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort()
      }).then(function(response){
        vm.newItemLogs = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      },function(error){
        NotificationService.error({
          title:"Failed to fetch New Item Log Report",
          error:error
        });
      });
    }

  }
})();
