/**
 * @Author: Vaibhavi
 * @Date:   30-Nov-2018 17:54 +05:30
 * @Project: Ezyprocure
 * @Filename: excel.upload.service.js
 * @Last modified by:   Vaibhavi
 * @Last modified time: 30-Nov-2018 17:54 +05:30
 */

(function() {
    'use strict';
    angular.module('ezyprocureApp').factory('ExcelUploadService', ExcelUploadService);
    ExcelUploadService.$inject = ['GLOBAL_CONSTANTS', 'NotificationService'];
  
    function ExcelUploadService(GLOBAL_CONSTANTS, NotificationService) {
  
      var excelUploadService = {
        checkFileType: checkFileType,
        checkFileSize: checkFileSize,
        getFileBase64: getFileBase64
      };
      return excelUploadService;
  
      /**
       * checkFileType checks the file type depending upon the extension
       * @param {attType}  
       */
      function checkFileType(attType) {
        //Check for valid excel extensions
        var validCsvType = _.includes(GLOBAL_CONSTANTS.EXCEL_UPLOAD.VALID_EXCEL_EXTENSIONS, attType.type);
        if (validCsvType && attType.name.split(".").pop() === 'xlsx') {
          return true;
        } else {
            NotificationService.simpleErrorToast({
              title: 'alertMessage.INVALID_INPUT',
              message: 'global.pleaseChooseAExcelFile'
            });
          return false;
        }
      }
      
      /**
       * checkFileSize checks the file size
       * @param {fileSize}  
       */
      function checkFileSize(fileSize) {
        if (fileSize > GLOBAL_CONSTANTS.EXCEL_UPLOAD.FILE_SIZE_LIMIT) {
          NotificationService.simpleErrorToast({
            title: 'alertMessage.INVALID_INPUT',
            message: 'global.excelFileSizeExceded'
          });
          return false;
        } else return true;
      }
      
      /**
       * getFileBase64 gives the given file in base64 encoded format
       * @param {inputFile}  
       */
      function getFileBase64(inputFile, callback) {
        if (this.checkFileType(inputFile)) {
          var base64;
          var fileReader = new FileReader();
          fileReader.onload = function(event) {
            base64 = event.target.result;
            callback(base64);
          };
          fileReader.readAsDataURL(inputFile);
        }
      }
    }
  })();
  