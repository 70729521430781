/*
 * @Author: Lovin C 
 * @Date: 2021-11-03 11:12:57 
 * @Last Modified by:   Lovin C 
 * @Last Modified time: 2021-11-03 11:12:57 
 */




(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzypaymentCustomManageCardsController', EzypaymentCustomManageCardsController);

  EzypaymentCustomManageCardsController.$inject = ['$stateParams', '$state', '$window', 'toastr', 'CardsService', 'NotificationService', '$mdDialog', 'GLOBAL_CONSTANTS', 'paginationConstants'];

  function EzypaymentCustomManageCardsController($stateParams, $state, $window, toastr, CardsService, NotificationService, $mdDialog, GLOBAL_CONSTANTS, paginationConstants) {

    var vm = this;

    vm.deleteCard = deleteCard;
    vm.showConfirm = showConfirm;
    vm.init = init;
    vm.limitOptions = paginationConstants.limitOptions;
    vm.initiallyActiveTab = $stateParams.initiallyActiveTab;
    vm.worldPayQuery = {
      limit : paginationConstants.itemsPerPage,
      page : 1
    }
    vm.maskedCardNumber = $stateParams.maskedCardNumber;
    vm.message = $stateParams.message;
    vm.worldPayQueryCardList = [];
    vm.totalCardsCards = 0;
    vm.user = 'BUYER';
    vm.setToDefault = setToDefault;
    vm.getWorldPayCardsList = getWorldPayCardsList;

    function init() {
      getWorldPayCardsList();
    }

    function setToDefault(user) {
      vm.user = user;
      if(vm.user) {
        vm.limitOptions = paginationConstants.limitOptions;
        vm.worldPayQuery = {
          limit : paginationConstants.itemsPerPage,
          page : 1
        }
        getWorldPayCardsList();
      }
    }

    function getWorldPayCardsList() {
      CardsService.getWorldPayCardsByRole({
        type: vm.user,
        page: vm.worldPayQuery.page - 1,
        size: vm.worldPayQuery.limit,
        query: ''
      }).then(function(response){
        vm.worldPayQueryCardList = response.data;
        vm.totalCardsCards = response.headers('X-Total-Count');
      },function(error) {
        NotificationService.error({
          title: 'WorldPay Card Details',
          error: error
        });
      });
    }

    function deleteCard(id) {
      CardsService.deleteWorldPayCardById(id).then(function(response){
        toastr.success('Card deleted successfully', 'WorldPay Card');
        getWorldPayCardsList();
      },function(error) {
        toastr.error('Card delete failed', 'WorldPay Card');
      });
    }

    // Shows modal before deleting the card
    function showConfirm(id, ev) {
      var confirm = $mdDialog.confirm()
        .title('Are you sure you want to delete this Card Detail?')
        .textContent('This action could not be reverted back.')
        .targetEvent(ev)
        .ok('Delete')
        .cancel('Cancel');

      $mdDialog.show(confirm).then(function () {
        deleteCard(id);
      }, function () {
      });
    };

  }
})();
