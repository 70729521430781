/**
 * @Author: Jayalekshmi 
 * @Date: 2019-01-03 10:40:23 
 * @Project: Ezyprocure
 * @Filename: sats.sr.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-07-12 10:18:06
 */



(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .controller('SatsSRController', SatsSRController);

    SatsSRController.$inject = ['UtilService', 'SatsManagementService', 'NotificationService', 'UTCDateService', 'BuyerSupplierMappingService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'Auth', '$mdDialog', 'EXPORT_CONSTANTS', '$q', 'CSVExportService'];
  
    function SatsSRController(UtilService, SatsManagementService, NotificationService, UTCDateService, BuyerSupplierMappingService, GLOBAL_CONSTANTS, paginationConstants, Auth, $mdDialog, EXPORT_CONSTANTS, $q, CSVExportService) {
      
      var vm = this;
      vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
      vm.loadAll = loadAll;
      vm.todaysDate = new Date();
      vm.query = {
        limit: paginationConstants.itemsPerPage,
        page: 1
      };
      vm.srFilter = {};
      vm.userRole = Auth.getUserRole();
      vm.limitOptions = paginationConstants.limitOptions;
      vm.getAllJobs = getAllJobs;
      vm.getAllSupplierWithSatsAccess = getAllSupplierWithSatsAccess;
      vm.statusList = GLOBAL_CONSTANTS.SATS_STATUS;
      vm.documentType = 'SR';
      vm.srFilter.fromDate = UTCDateService.utcFromDate(vm.todaysDate);
      vm.srFilter.toDate = UTCDateService.utcToDate(vm.todaysDate);
      vm.selectAllJobs = selectAllJobs;
      vm.postAllJobs = postAllJobs;
      vm.rejectAllJobs = rejectAllJobs;
      vm.postJob = postJob;
      vm.rejectJob = rejectJob;
      vm.getSelectedJobsIds = getSelectedJobsIds;
      vm.addRejectionReason = addRejectionReason;
      vm.cancel = cancel;
      vm.rejectByType = rejectByType;
      vm.validateJobsForRejection = validateJobsForRejection;
      vm.reset = reset;
      vm.deleteJob = deleteJob;
      vm.confirmDelete = confirmDelete;

      // CSV
      vm.buyerSRDataHeader = EXPORT_CONSTANTS.CSV.buyerSRDataHeader;
      vm.getBuyerSRCSVData = getBuyerSRCSVData;

      /**
       * [getBuyerSRCSVData Gets CSV data]
       */
      function getBuyerSRCSVData() {
        var deferred = $q.defer();
        if (vm.srJobList.length<1) {
          NotificationService.simpleErrorToast({
            title: 'global.table.noRecordsFound',
            message: 'alertMessage.SEARCH_AND_SUBMIT'
          });
          return deferred.promise;
        }
        return CSVExportService.getBuyerSRCSVData(vm.srJobList);
      }

       /**
       * [rejectByType Decides which reject function to be called]
       */
      function rejectByType(){
        if(vm.rejectType == 'SINGLE_JOB'){
          vm.rejectJob();
        }else if(vm.rejectType == 'BULK_JOB'){
          vm.rejectAllJobs();
        }
      }

      /**
      * [cancel to close the modal]
      */
      function cancel() {
        $mdDialog.cancel();
      }

      /**
       * [addRejectionReason Shows a modal to enter rejection reason]
       * @param {Object} event 
       * @param {String} type 
       * @param {Integer} id 
       */
      function addRejectionReason(event, type, id) {
        vm.rejectType = type;
        vm.rejectedJobId = id;
        $mdDialog.show({
          templateUrl: 'app/common-modules/sats/templates/sats.reject-reason.tmpl.html',
          controller: function() {
            return vm;
          },
          controllerAs: 'vm',
          targetEvent: event,
          clickOutsideToClose: false
        });
      }  

      /**
       * [getAllSupplierWithSatsAccess Gets all supplier ]
       */
      function getAllSupplierWithSatsAccess() {
        if(vm.suppliers)
          return false;
        BuyerSupplierMappingService.getAllSupplierWithSatsAccess()
        .then(function(response){
          vm.suppliers = response.data;
        },function(error){
        });
      }

      /**
       * [getAllJobs Gets All SR jobs]
       */
      function getAllJobs(){
        vm.srFilter.fromDate = !vm.srFilter.fromDate?null: UTCDateService.utcFromDate(vm.srFilter.fromDate);
        vm.srFilter.toDate = !vm.srFilter.toDate?null: UTCDateService.utcToDate(vm.srFilter.toDate);
        vm.srFilter.page = vm.query.page-1;
        vm.srFilter.size = vm.query.limit;
        vm.selectAll = false;
        SatsManagementService.getAllJobs(vm.documentType, vm.srFilter)
        .then(function(response){
            vm.srJobList = response.data;
            _.map(vm.srJobList, function(job){
              if(job.returnQuantity || job.returnQuantity == 0){
                var variance = job.quantity - job.returnQuantity;
                variance == 0? job.variance = 'No': job.variance = 'Yes';
              }
            });
            vm.totalSRItems = response.headers('X-Total-Count');
        } ,function(error){
          NotificationService.error({
            'title': 'global.sats.title.'+vm.userRole.pretty,
            'error':error
          });
        });
      }
  
      /**
      * [loadAll Loads all jobs]
      */
      function loadAll() {
        var isValidDateRange = UtilService.isValidDateRange(vm.srFilter.fromDate, vm.srFilter.toDate);
        if(!isValidDateRange){
          NotificationService.simpleErrorToast({
            title: 'global.table.noRecordsFound',
            message: 'alertMessage.SELECT_VALID_DATE_RANGE'
          });
          return false;
        }
        vm.getAllJobs();
      }
      vm.loadAll();

      /**
       * [postAllJobs Posts all Selected Jobs]
       */
      function postAllJobs() {
        var selectedJobIds = vm.getSelectedJobsIds();
        if (selectedJobIds.length < 1) {
          NotificationService.simpleErrorToast({
            title: 'global.sats.jobPlan',
            message: 'alertMessage.SELECT_ITEMS_TO_CONTINUE'
          });
        } else {
          SatsManagementService.postAllJobs(selectedJobIds)
            .then(function (response) {
              NotificationService.success({
                'title': 'SR Job Plan',
                'message': 'Successfully Posted All Jobs.'
              });
              vm.getAllJobs();
            }, function (error) {
              NotificationService.error({
                'title': 'SR Job Plan',
                'error': error
              });
            })
        }
      }

      /**
       * [rejectAllJobs Rejects all Selected SR Jobs]
       */
      function rejectAllJobs(){
        SatsManagementService.rejectAllJobs(vm.rejectedJobIds,{'remark': vm.rejectionReason})
          .then(function (response) {
            NotificationService.success({
              'title': 'SR Job Plan',
              'message': 'Successfully Rejected All Jobs.'
            });
            vm.getAllJobs();
            vm.rejectionReason = null;
            vm.cancel();
          }, function (error) {
            NotificationService.error({
              'title': 'SR Job Plan',
              'error': error
            });
          });
          vm.rejectionReason = null;
          vm.cancel();
      }

      /**
       * [validateJobsForRejection  Validates the conditions to Rejects all Selected SR Jobs]
       */
      function validateJobsForRejection(event) {
        vm.rejectedJobIds = vm.getSelectedJobsIds();
        if (vm.rejectedJobIds.length < 1) {
          NotificationService.simpleErrorToast({
            title: 'global.sats.jobPlan',
            message: 'alertMessage.SELECT_ITEMS_TO_CONTINUE'
          });
        } else {
          vm.addRejectionReason(event, 'BULK_JOB')
        }
      }
  
      /**
       * [postJob Posts the corresponding Job]
       * @param {Integer} id 
       */
      function postJob(id) {
        SatsManagementService.postJob(id)
          .then(function (response) {
            NotificationService.success({
              'title': 'SR Job Plan',
              'message': 'Job Posted Successfully.'
            });
            vm.getAllJobs();
          }, function (error) {
            NotificationService.error({
              title: 'SR Job Plan',
              error: error
            });
          });
      }

      /**
       * [rejectJob Rejects Corresponding Job]
       * @param {Integer} id 
       */
      function rejectJob() {
        SatsManagementService.rejectJob(vm.rejectedJobId, {
          'remark':vm.rejectionReason
        })
          .then(function (response) {
            NotificationService.success({
              'title': 'SR Job Plan',
              'message': 'Job Rejected Successfully.'
            });
            getAllJobs();
            vm.rejectionReason = null;
            vm.cancel();
          }, function (error) {
            NotificationService.error({
              title: 'SR Job Plan',
              error: error
            });
            vm.rejectionReason = null;
            vm.cancel();
          });
      }

       /**
       * [selectAllPO select all Pending Process Orders]
       */
      function selectAllJobs() {
        vm.selectAll ? vm.selectAll = true : vm.selectAll = false;
        _.map(vm.srJobList, function (job) {
          if (vm.userRole.pretty == 'buyer') {
            if (job.jobStatus === 'PROCESSED' || job.jobStatus === 'FAILED') {
              job.selected = vm.selectAll;
            }
          } else if (vm.userRole.pretty == 'supplier') {
            if (job.jobStatus === 'RELEASED' || job.jobStatus === 'REJECTED') {
              job.selected = vm.selectAll;
            }
          }
        });
      }

      /**
       * [getSelectedJobsIds Returns Selected Job ids]
       */
      function getSelectedJobsIds() {
        var selectedJobs = SatsManagementService.getSelectedJobs(vm.srJobList);
        var selectedJobIds =_.map( _.uniqBy(selectedJobs, 'jobId'), _.property('jobId'));
        return selectedJobIds;
      }
      
      /**
       * [reset reset sr filter]
       */
      function reset(){
        vm.srFilter.fromDate = UTCDateService.utcFromDate(vm.todaysDate);
        vm.srFilter.toDate = UTCDateService.utcToDate(vm.todaysDate);
        vm.srFilter.warehouse = "";
        vm.srFilter.status = "";
        vm.srFilter.variance = "";
        vm.getAllJobs();
      }
       
      /**
       * [deleteJob Deletes specific line item]
       * @param {*} jobDocId 
       */
      function deleteJob(jobDocId) {
        SatsManagementService.deleteJob(jobDocId).then(function(response) {
          NotificationService.success({
            title: 'SO Job',
            message: 'alertMessage.DELETED_SUCCESSFULLY'
          });
          vm.loadAll();
        }, function(error) {
          NotificationService.error({
            title: 'Job Deletion',
            error: error
          });
        });
      }

      /**
       * [confirmDelete Confirmation box for deletion]
       * @param {*} ev 
       * @param {*} srJob 
       */
      function confirmDelete (ev, srJob) {
        if(srJob.status==='POSTED'){
          NotificationService.simpleErrorToast({
            title: 'alertMessage.ERR_VALIDATION',
            message: 'error.CAN_NOT_DELETE_POSTED_JOB'
          });
          return false;
        }
        var confirm = $mdDialog.confirm()
        .title('Are you sure you want to delete Item - ' + srJob.jobDocumentNumber + ' ? ')
        .textContent('This action could not be reverted back.')
        .targetEvent(ev)
        .ok('Delete')
        .cancel('Cancel');
  
        $mdDialog.show(confirm).then(function() {
          vm.deleteJob(srJob.id);
        }, function() {});
      }
  
      function cancel () {
        $mdDialog.cancel();
      }
    }
  })();
  