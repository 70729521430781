/**
* @Author:Gayathri.J.L
* @Date:2018-12-26 11:46:35
* @Project: Ezyprocure
* @Filename: buyer.order-template-user-mapping.controller.js
* @Last modified by:Ajay
* @Last modified time:2019-02-04 15:31:10 
*/

(function () {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerOrderTemplateMappingController', BuyerOrderTemplateMappingController);

  BuyerOrderTemplateMappingController.$inject = ['$scope', 'NotificationService', 'pagingParams', 'BuyerUserManagementService', 'paginationConstants', 'BuyerOrderTemplateService', 'BuyerOrderTemplateMappingService'];

  function BuyerOrderTemplateMappingController($scope, NotificationService, pagingParams, BuyerUserManagementService, paginationConstants, BuyerOrderTemplateService, BuyerOrderTemplateMappingService) {
    var vm = this;
    vm.getAllOrderTemplate = getAllOrderTemplate;
    vm.getAllBuyerUsers = getAllBuyerUsers;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.reset = reset;
    vm.createTemplateBuyerUserMapping = createTemplateBuyerUserMapping;
    vm.limitOptions = paginationConstants.limitOptions;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.getAllTemplateBuyerUserMappings = getAllTemplateBuyerUserMappings;
    vm.deleteTemplateBuyerUserMapping = deleteTemplateBuyerUserMapping;
    vm.mapping = {};
    vm.resetForm=resetForm;
    vm.mapping.isDefault=false;
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }
   
    //get all order templates available
    function getAllOrderTemplate() {
      if(vm.templateList)
      return false;
      BuyerOrderTemplateService.getAllOrderTemplates().then(function (response) {
        vm.templateList = response.data;
      });
    }
    
    //get all users buyer users
    function getAllBuyerUsers() {
      if (vm.buyerUsers)
      return false;
      BuyerUserManagementService.getAllBuyerUsers({
        page: 0,
        size: paginationConstants.maxLimit,
        sort: sort(),
        query: ''
      }).then(function (response) {
        vm.buyerUsers = response.data;
      }, function (error) {
      });
    }

    //create template -buyeruser mapping 
    function createTemplateBuyerUserMapping() {
      BuyerOrderTemplateMappingService.createTemplateBuyerUserMapping({
        "templateId":vm.mapping.templateId,
        "buyerUserId":vm.mapping.buyerUserId,
        "isDefault":vm.mapping.isDefault
      }).then(function (response) {
        NotificationService.success({
          title: 'Order Template- User',
          message: 'Mapped successfully'
        });
      }, function (error) {
        NotificationService.error({
          title: 'Order Template- User Mapping',
          error: error
        });
      });
    }

    //get all template buyer user mapping
    function getAllTemplateBuyerUserMappings() {
      BuyerOrderTemplateMappingService.getAllTemplateBuyerUserMappings({ templateId: vm.templateId }).then(function (response) {
        vm.mappingList = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      });
    }

    //unmap the mapped buyeruser
    function deleteTemplateBuyerUserMapping(mapping) {
      BuyerOrderTemplateMappingService.deleteTemplateBuyerUserMapping(mapping).then(function (response) {
        vm.getAllTemplateBuyerUserMappings()
        NotificationService.success({
          title: 'Order Template - User Mapping',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
      }, function (error) {
        NotificationService.error({
          title: 'Order Template - User Mapping Deletion',
          error: error
        });
      });
    }

    //reset templateForm
    function reset(templateForm) {
      vm.templateId = null;
      vm.mappingList = null;
      $scope.templateForm.$setPristine();
      $scope.templateForm.$setUntouched();
    }

    //reset buyerOrderTemplateMappingForm
    function resetForm(buyerOrderTemplateMappingForm){
      vm.mapping.templateId = null;
      vm.mapping.buyerUserId=null;
      $scope.buyerOrderTemplateMappingForm.$setPristine();
      $scope.buyerOrderTemplateMappingForm.$setUntouched();
    }
  }
})();
