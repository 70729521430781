/**
 * @Author: Ajay
 * @Date:   06-Apr-2017 10:21 +05:30
 * @Project: Ezyprocure
 * @Filename: admin.item-groups.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 29-Jan-2018 10:51 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('ItemGroupsController', ItemGroupsController);

  ItemGroupsController.$inject = ['$scope', '$rootScope', '$state', '$mdDialog', 'ItemGroupService', 'MainItemGroupService', 'pagingParams', 'paginationConstants', 'NotificationService'];

  function ItemGroupsController($scope, $rootScope, $state, $mdDialog, ItemGroupService, MainItemGroupService, pagingParams, paginationConstants, NotificationService) {
    var vm = this;

    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;

    vm.loadAll = loadAll;
    vm.showConfirm = showConfirm;
    vm.cancel = cancel;
    vm.showAddItemGroup = showAddItemGroup;
    vm.addItemGroup = addItemGroup;
    vm.showEditItemGroup = showEditItemGroup;
    vm.updateItemGroup = updateItemGroup;
    vm.loadMainItemGroups = loadMainItemGroups;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function loadAll(search) {
      if (!search) {
        vm.searchText = '';
      }
      ItemGroupService.getAllItemGroups({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.searchText
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.itemGroups = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'ItemGroup Listing',
          error: error
        });
      });
    }

    vm.loadAll();
    vm.loadMainItemGroups();

    function deleteItemGroup(itemGroupId) {
      ItemGroupService.deleteItemGroup(itemGroupId).then(function(response) {
        NotificationService.success({
          title: 'Item Group',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'Item Group Deletion',
          error: error
        });
      });
    }

    function showConfirm(ev, itemGroup) {
      var confirm = $mdDialog.confirm()
      .title('Are you sure you want to delete Item Group - ' + itemGroup.name + ' ? ')
      .textContent('This action could not be reverted back.')
      .targetEvent(ev)
      .ok('Delete')
      .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        deleteItemGroup(itemGroup.id);
      }, function() {});
    }

    function cancel() {
      $mdDialog.cancel();
    }

    //Add Item Group Starts here

    function showAddItemGroup(event) {
      vm.newItemGroup = {};
      $mdDialog.show({
        templateUrl: 'app/admin-modules/item-groups/admin.item-group.add.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function addItemGroup(newItemGroup) {
      ItemGroupService.createItemGroup(newItemGroup).then(function(response) {
        $scope.$emit('ezyprocureApp:itemGroupUpdate', response);
        vm.cancel();
        NotificationService.success({
          title: 'global.navbar.itemGroups',
          message: 'alertMessage.CREATED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'Item Group Creation',
          error: error
        });
      });
    }

    //Add ItemGroup Ends here


    //Edit ItemGroup Starts here

    function showEditItemGroup(event, oldItemGroup) {
      vm.editItemGroup = angular.copy(oldItemGroup);
      $mdDialog.show({
        templateUrl: 'app/admin-modules/item-groups/admin.item-group.edit.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function updateItemGroup(oldItemGroup) {
      ItemGroupService.updateItemGroup(oldItemGroup).then(function(response) {
        $scope.$emit('ezyprocureApp:itemGroupUpdate', response);
        vm.cancel();
        NotificationService.success({
          title: 'Item Group',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'Item Group Updation',
          error: error
        });
      });
    }

    //Edit ItemGroup Ends here

    function loadMainItemGroups() {
      MainItemGroupService.getAllMainItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.mainItemGroups = response.data;
      }, function(error) {
      });
    }

  }


})();
