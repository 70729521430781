/**
 * @Author: Ajay
 * @Date:   22-Jan-2017 19:42 +05:30
 * @Project: Ezyprocure
 * @Filename: supplier.su-bo-mapping.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 22-May-2018 12:14 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierSUBOMapController', SupplierSUBOMapController);

  SupplierSUBOMapController.$inject = ['$scope', '$state', '$stateParams', 'toastr', '$element', 'NotificationService', 'pagingParams', '$mdDialog' , 'SupplierDataService', 'SupplierUserManagementService', 'BuyerBusinessService', 'SupplierSUBOMapService', 'BuyerSupplierMappingService', 'paginationConstants'];

  function SupplierSUBOMapController($scope, $state, $stateParams, toastr, $element, NotificationService, pagingParams, $mdDialog, SupplierDataService, SupplierUserManagementService, BuyerBusinessService, SupplierSUBOMapService, BuyerSupplierMappingService, paginationConstants) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.sort = sort;
    vm.loadAll = loadAll;
    vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.getAllSupplierUsers = getAllSupplierUsers;
    vm.createSupplierUserBusinessMapping = createSupplierUserBusinessMapping;
    vm.deleteSupplierUserBusinessMapping = deleteSupplierUserBusinessMapping;
    vm.mapping = {};
    vm.userOutletMappingIdList = {};
    vm.mappedBuyerSearch = '';
    vm.BuyerSearchText = '';
    vm.mapping.supplierUserId = $stateParams.supplierUserId;
    vm.isSupplierUserDisabled = $stateParams.supplierUserId ? true : false;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.clearBulkUploadWarnings = clearBulkUploadWarnings;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
    * [selectAll Select all business outlets from the list and create separate string array of outlets]
    */
    function selectAll(selectParam) {
      if(selectParam === 'OUTLETS')
        vm.mapping.businessOutletIdList = _.map(vm.businessOutlets, _.property('id'));
      if(selectParam === 'USERS')
        vm.mapping.supplierUserIdList = _.map(vm.supplierUsers, _.property('supplierUserId'));
    }

    /**
    * [deselectAll Deselect all business outlets from the list]
    */
    function deselectAll(selectParam) {
      if(selectParam === 'OUTLETS')
        vm.mapping.businessOutletIdList = [];
      if(selectParam === 'USERS')
        vm.mapping.supplierUserIdList = [];
    }

    // Load All supplier items which are mapped
    function loadAll(search) {
      if (!search) {
        vm.mappedBuyerSearch = '';
      }
      SupplierSUBOMapService.getAllMappedBuyerBO({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.mappedBuyerSearch,
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.mappedBuyers = response.data;
      },function(error){
      });
    }

    function getAllSupplierMappedBuyers() {
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
      };
      if(vm.buyers)
        return false;
      var supplierId = SupplierDataService.getSupplierProfile().supplier.id;
      BuyerSupplierMappingService.getAllSupplierMappedBuyers(supplierId, params)
      .then(function(response){
        vm.buyers = response.data;
      },function(error){
      });
    }

    function getAllBuyerBusinessOutlets(buyerId) {
      BuyerBusinessService.getAllBuyerBusinessOutlets(buyerId, {
        page: 0,
        size: paginationConstants.maxLimit
      }).then(function(response){
        vm.businessOutlets = response.data;
        vm.mapping.businessOutletIdList = [];
      },function(error){
      });
    }

    //Load all supplier users
    function getAllSupplierUsers() {
      if(vm.supplierUsers)
        return false;
      SupplierUserManagementService.getAllSupplierUsers({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      })
      .then(function(response){
        vm.supplierUsers = response.data;
        vm.mapping.supplierUserIdList = [];
      },function(error){
      });
    }

    function createSupplierUserBusinessMapping() {
      // Map selected buyer and supplier
      SupplierSUBOMapService.createSupplierUserBusinessMapping(vm.mapping).then(function(response){
        $scope.$emit('ezyprocureApp:bsMapped', vm.mapping);
        if(response.length > 0) {
          vm.bulkUploadWarnings = response;
          toastr.warning('Completed with some errors!', 'Supplier User - Business Outlet Mapping');
        }
        else {
          vm.clearBulkUploadWarnings();
          NotificationService.success({
            title: 'global.supplierUserBusinessOUtlet',
            message: 'alertMessage.MAPPED_SUCCESSFULLY'
          });
        }
      },function(error) {
        NotificationService.error({
          title: 'Supplier User - Business Outlet Mapping',
          error: error
        });
      });
    }

    function deleteSupplierUserBusinessMapping(mappedBuyer) {
      vm.userOutletMappingIdList.supplierUserId = mappedBuyer.supplierUser.id;
      vm.userOutletMappingIdList.businessOutletId = mappedBuyer.buyerBusiness.id;

      // Map selected buyer and supplier
      SupplierSUBOMapService.deleteSupplierUserBusinessMapping(vm.userOutletMappingIdList).then(function(response){
        $scope.$emit('ezyprocureApp:bsUnmapped', vm.mapping);
        NotificationService.success({
          title: 'Supplier User - Business Outlet Mapping',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      },function(error) {
        NotificationService.error({
          title: 'Supplier User - Business Outlet Mapping Deletion',
          error: error
        });
      });
    }

    function clearBulkUploadWarnings() {
      vm.bulkUploadWarnings = null;
    }

    vm.loadAll();
  }
})();
