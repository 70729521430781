/**
 * @Author: Ajay
 * @Date:   06-Apr-2017 10:21 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.supplier-management.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 25-Jul-2018 10:18 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('buyer.suppliers', {
      parent: 'buyer',
      url: '/suppliers/:visibilityType?page&sort&search',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'ezyprocureApp.supplier.home.title'
      },
      views: {
        'content@': {
          templateUrl: 'app/common-modules/manage-suppliers/manage-suppliers.list.html',
          controller: 'ManageSupplierListController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('user-management');
          return $translate.refresh();
        }]
      }
    })
    .state('buyer.suppliers.add', {
      parent: 'buyer.suppliers',
      url: '/add',
      data: {
        authorities: ['ROLE_BUYER']
      },
      views: {
        'content@': {
          templateUrl: 'app/common-modules/manage-suppliers/add/manage-suppliers.add.html',
          controller: 'ManageSupplierAddController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('paymentTerms');
          $translatePartialLoader.addPart('paymentTypes');
          $translatePartialLoader.addPart('bank');
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    })
    .state('buyer.suppliers.bulk-upload', {
      parent: 'buyer.suppliers',
      url: '/bulk-upload',
      data: {
        authorities: ['ROLE_BUYER']
      },
      views: {
        'content@': {
          templateUrl: 'app/common-modules/manage-suppliers/bulk-upload/manage-suppliers.bulk-upload.html',
          controller: 'ManageSupplierBulkUploadController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    })
    .state('buyer.suppliers.edit', {
      parent: 'buyer.suppliers',
      url: '/edit/:supplierId',
      data: {
        authorities: ['ROLE_BUYER']
      },
      views: {
        'content@': {
          templateUrl: 'app/common-modules/manage-suppliers/edit/manage-suppliers.edit.html',
          controller: 'ManageSupplierEditController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('paymentTerms');
          $translatePartialLoader.addPart('paymentTypes');
          $translatePartialLoader.addPart('bank');
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    });
  }

})();
