/**
* @Author: Ajay
* @Date:   27-Feb-2017 16:21 +05:30
* @Project: Ezyprocure
* @Filename: buyer.checkout-confirm.controller.js
* @Last modified by:   Ajay
* @Last modified time: 21-Mar-2017 17:00 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerCheckoutConfirmController', BuyerCheckoutConfirmController);

  BuyerCheckoutConfirmController.$inject = ['$scope', '$timeout', '$state', 'toastr', '$mdDialog', '$filter', 'BuyerBuyNowService','BuyerBusinessService', 'BuyerDataService'];

  function BuyerCheckoutConfirmController ($scope, $timeout, $state, toastr, $mdDialog, $filter, BuyerBuyNowService, BuyerBusinessService,  BuyerDataService) {

    var vm = this;
  }

})();
