/**
* @Author: Ajay
* @Date:   17-May-2017 17:52 +05:30
* @Project: Ezyprocure
* @Filename: buyer.gl-code.service.js
* @Last modified by:   Ajay
* @Last modified time: 17-May-2017 17:59 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('GlCodeService', GlCodeService);

  GlCodeService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function GlCodeService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var glCodeService = {
      getAllGlCodes: getAllGlCodes,
      getGlCode: getGlCode,
      createGlCode: createGlCode,
      updateGlCode: updateGlCode,
      deleteGlCode: deleteGlCode
    };

    return glCodeService;

    /* --  Buyer GL Code CRUD operations  -- */

    //Get All glCodes
    function getAllGlCodes(urlParams) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.GL_CODE_RESOURCE,
        method: "GET",
        params: urlParams,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get glCode details using glCode id
    function getGlCode(glCodeId) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.GL_CODE_RESOURCE + glCodeId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Create a new glCode
    function createGlCode(glCode) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.GL_CODE_RESOURCE, glCode).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update glCode
    function updateGlCode(glCode) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.GL_CODE_RESOURCE, glCode).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Soft Delete glCode using glCodeId
    function deleteGlCode(glCodeId) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.GL_CODE_RESOURCE + glCodeId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
