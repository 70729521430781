/**
* @Author: Ajay
* @Date:   11-May-2017 16:10 +05:30
* @Project: Ezyprocure
* @Filename: admin.purchase-order-type.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 25-Jan-2018 15:21 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('PurchaseOrderTypeService', PurchaseOrderTypeService);

  PurchaseOrderTypeService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function PurchaseOrderTypeService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var purchaseOrderTypeService = {
      getAllPurchaseOrderTypes: getAllPurchaseOrderTypes,
      getPurchaseOrderType: getPurchaseOrderType,
      createPurchaseOrderType: createPurchaseOrderType,
      updatePurchaseOrderType: updatePurchaseOrderType,
      deletePurchaseOrderType: deletePurchaseOrderType
    };

    return purchaseOrderTypeService;

    /* --  ItemGroup CRUID operations  -- */

    //Get All purchaseOrderTypes
    function getAllPurchaseOrderTypes(urlParams) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_TYPES_RESOURCE,
        method: "GET",
        params: urlParams,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get purchaseOrderType details using purchaseOrderType id
    function getPurchaseOrderType(purchaseOrderTypeId) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_TYPES_RESOURCE + purchaseOrderTypeId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Create a new purchaseOrderType
    function createPurchaseOrderType(purchaseOrderType) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_TYPES_RESOURCE, purchaseOrderType).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update purchaseOrderType
    function updatePurchaseOrderType(purchaseOrderType) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_TYPES_RESOURCE, purchaseOrderType).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Soft Delete purchaseOrderType using purchaseOrderTypeId
    function deletePurchaseOrderType(purchaseOrderTypeId) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_TYPES_RESOURCE + purchaseOrderTypeId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
