/**
* @Author: Abhay
* @Date:   03-Aug-2020 17:11 IST
* @Project: Ezyprocure
* @Filename: buyer.adhoc-items-promotions.edit.controller.js
* @Last modified by:   Abhay
* @Last modified time: 03-Aug-2020 17:11 IST
*/

(function () {
    'use strict';

    angular
        .module('ezyprocureApp')
        .controller('AdhocPromotionEditController', AdhocPromotionEditController);

    AdhocPromotionEditController.$inject = ['$scope', '$rootScope', '$state', '$filter', '$stateParams', '$q', 'NotificationService', '$mdDialog', 'AdhocPromotionService', 'BuyerDataService', 'PromotionService', 'BuyerItemService', 'BuyerBusinessService', 'BrandManagementService', 'pagingParams', 'paginationConstants', 'GLOBAL_CONSTANTS', 'SupplierItemPriceUpdateService'];

    function AdhocPromotionEditController($scope, $rootScope, $state, $filter, $stateParams, $q, NotificationService, $mdDialog, AdhocPromotionService, BuyerDataService, PromotionService, BuyerItemService, BuyerBusinessService, BrandManagementService, pagingParams, paginationConstants, GLOBAL_CONSTANTS, SupplierItemPriceUpdateService) {

        var vm = this;
        vm.promotionId = $stateParams.promotionId;
        vm.buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;

        vm.promotionDTO = {};
        vm.itemSearchQuery = {};
        vm.getSupplierItemPromotionById = getSupplierItemPromotionById;
        vm.itemChanged = itemChanged;
        vm.searchForItems = searchForItems;
        vm.update = update;
        vm.setPromotionStartDateWithTime = setPromotionStartDateWithTime;
        vm.setPromotionEndDateWithTime = setPromotionEndDateWithTime;
        vm.getAllBuyersBusinessOutlets = getAllBuyersBusinessOutlets;
        vm.init = init;
        vm.deselectAll = deselectAll;
        vm.selectAll = selectAll;
        vm.getItemPricePerOutlet = getItemPricePerOutlet;
        vm.showPricePerBO = showPricePerBO;
        vm.getPricePerBusiness = getPricePerBusiness;
        vm.cancel = cancel;
    
    
        vm.init();
    
        function getItemPricePerOutlet(event){
          SupplierItemPriceUpdateService.getItemPricePerOutlet({
            'itemId': vm.promotionDTO.promotionItem.id,
            'businessIdList': vm.promotionDTO.businessIdList
          }).then(function(response){
            vm.outletList = response.data;
            vm.showPricePerBO(event);
          }, function(error){
            NotificationService.error({
              title: 'Item Promotion',
              error: error
            });
          })
        }
    
        /**
         * [showPrice details SHows the detailed Price of BO's]
         * @param {Object} event
         */
        function showPricePerBO(event) {
          $mdDialog.show({
            templateUrl: 'app/supplier-modules/item-management/promotions/supplier.promotion.tmpl.html',
            controller: function() {
              return vm;
            },
            controllerAs: 'vm',
            targetEvent: event,
            clickOutsideToClose: true
          });
        }
    
        function getPricePerBusiness(eachOutlet){
          return eachOutlet.price ? eachOutlet.price : eachOutlet.items.price;
        }
    
        /**
         * [cancel Cancels the modal]
         */
        function cancel() {
          $mdDialog.cancel();
        }
    
        function init() {
            vm.promotionDTO.buyerIdList = [vm.buyerId];
            vm.getSupplierItemPromotionById();
        }

        function getSupplierItemPromotionById() {
            PromotionService.getSupplierItemPromotionById(vm.promotionId).then(function (response) {
                vm.promotionDTO = response;
                vm.getAllBuyersBusinessOutlets();
                vm.oldEffectiveStartDate = new Date(response.effectiveStartDate);
                vm.selectedItem = response.freeItem;

            }, function (error) {
            });
        }

        function searchForItems(queryType, queryText) {
            var deferred = $q.defer();
            if (!queryText) {
                return;
            } else {
                vm.itemSearchQuery.itemType = 'SUPPLIER';
                vm.itemSearchQuery.queryField = queryType;
                vm.itemSearchQuery.queryText = queryText;
                vm.itemSearchQuery.supplierId = vm.promotionDTO.promotionItem.supplier.id;
                BuyerItemService.searchAdhocItems(vm.itemSearchQuery)
                    .then(function (response) {
                        deferred.resolve($filter('orderBy')(response.data, 'itemName'));
                    }, function (error) {
                        deferred.reject(error);
                    });
            }
            return deferred.promise;
        }

        function itemChanged(selectedItem) {
            if (selectedItem)
                vm.promotionDTO.freeItem = angular.copy(selectedItem);
        }

        function setPromotionStartDateWithTime() {
            vm.promotionDTO.effectiveStartDate = PromotionService.getPromotionStartDateWithTime(vm.promotionDTO.effectiveStartDate);
        }

        /**
         * [setPromotionEndDate Update promotion end date with time]
         */
        function setPromotionEndDateWithTime() {
            vm.promotionDTO.effectiveEndDate = PromotionService.getPromotionEndDateWithTime(vm.promotionDTO.effectiveEndDate);
        }

        function update() {
            vm.promotionDTO.brandIdList = null;
            PromotionService.updateSupplierItemPromotion(vm.promotionDTO).then(function (response) {
                $scope.$emit('ezyprocureApp:promotionUpdate', response);
                NotificationService.success({
                    title: 'Item Promotion',
                    message: 'alertMessage.UPDATED_SUCCESSFULLY'
                });
                $state.go('buyer.adhoc-tools.items.promotions', {
                    reload: false
                });
            }, function (error) {
                NotificationService.error({
                    title: 'Item Promotion Updation',
                    error: error
                });
            });
        }

        //get all business outlets of the selected buyer
        function getAllBuyersBusinessOutlets() {
            if (vm.promotionDTO.buyerIdList.length < 1) {
                return false;
            }
            var params = {
                page: 0,
                size: paginationConstants.maxLimit,
                groupBy: 'buyer'
            };

            BuyerBusinessService.getAllBuyersBusinessOutlets(vm.promotionDTO.buyerIdList, params).then(function (response) {
                vm.businessOutlets = response.data;
            }, function (error) {
            });
        }

        function selectAll(dropdown) {
            switch (dropdown) {
                case 'BUYERS':
                    vm.promotionDTO.buyerIdList = _.map(vm.buyers, _.property('id'));
                    vm.getAllBuyersBusinessOutlets();
                    break;
                case 'BUSINESSOUTLETS':
                    vm.promotionDTO.businessIdList = _.map(_.flatten(_.without(_.map(vm.businessOutlets, _.property('businessDTOList')), undefined, null)), _.property('businessId'));
                    break;
            }
        }

        /**
         * [deselectAll Deselect all from the list]
         * @param  {[type]} dropdown [Active dropdown - outlets or brands]
         */
        function deselectAll(dropdown) {
            switch (dropdown) {
                case 'BUYERS':
                    vm.promotionDTO.buyerIdList = [];
                    break;
                case 'BUSINESSOUTLETS':
                    vm.promotionDTO.businessIdList = [];
                    break;
            }
        }
    }
})();