/**
* @Author: Abhay
* @Date:   01-02-2022 12:15 +05:30
* @Project: Ezyprocure
* @Filename: truncate.filter.js
 * @Last modified by:   Abhay
 * @Last modified time: 01-02-2022 12:15 +05:30
*/



(function () {
    'use strict';

    angular
        .module('ezyprocureApp')
        .filter('truncate', truncate);

    function truncate() {
        return truncateByValue;
        function truncateByValue(input, value) {
            if (input.length > value) {
                input = input.substring(0, value) + "...";
            }
            return input;
        }
    }
})();
