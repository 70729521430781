/**
* @Author: Elsa
* @Date:   10-Mar-2017 12:51 +05:30
* @Project: Ezyprocure
* @Filename: supplier.goods-return-notice.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 12-Oct-2018 14:39 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierGoodsReturnNoticeController', SupplierGoodsReturnNoticeController);

  SupplierGoodsReturnNoticeController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', '$stateParams', '$mdDialog','pagingParams', 'UTCDateService', 'PurchaseOrderService', 'NotificationService', 'ItemGroupService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'CSVExportService', 'EXPORT_CONSTANTS'];

  function SupplierGoodsReturnNoticeController($scope, $rootScope, $q, $filter, $state, $stateParams, $mdDialog,pagingParams, UTCDateService, PurchaseOrderService, NotificationService, ItemGroupService, GLOBAL_CONSTANTS, paginationConstants, CSVExportService, EXPORT_CONSTANTS) {

    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.todaysDate = new Date();
    vm.loadAll = loadAll;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.debitnoteStatuses = GLOBAL_CONSTANTS.DEBIT_NOTE_STATUSES;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.limitOptions = paginationConstants.limitOptions;
    vm.loadItemGroups=loadItemGroups;
    //Header
    vm.GoodsReturnNoticeHeader = EXPORT_CONSTANTS.CSV.SupplierGoodsReturnNoticeReport;
    //Function Declaration
    vm.getGoodsReturnDataCSV = getGoodsReturnDataCSV;
    vm.resetFilters = resetFilters;

    //Getting CSV DATA
    function getGoodsReturnDataCSV(){
      var deferred = $q.defer();
      if (!vm.goodsReturnNotes) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getSupplierGoodsReturnNoticeReportCSV(vm.goodsReturnNotes);
    }

    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function loadItemGroups() {
      if(vm.itemGroups)
        return false;
      ItemGroupService.getAllItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.itemGroups = response.data;
      }, function(error) {
      });
    }
    /**
    * [loadAll Load All Receiving Order data]
    * @param  {[string]} search [search text]
    */
    function loadAll() {
      PurchaseOrderService.getAllPurchaseOrders({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: '',
        supplierId: '',
        debitNoteStatus: vm.filter.debitnoteStatus,
        grnNumber: !vm.filter.grnNumber ? '': vm.filter.grnNumber,
        buyerName: !vm.filter.buyerName ? '' : vm.filter.buyerName,
        poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber,
        buyerIdList: '',
        purchaseOrderStatus: '' ,
        invoiceStatus: '',
        invoiceNumber: '',
        businessOutletId: '' ,
        businessOutletName: '' ,
        creditNoteStatus: '' ,
        supplierVisibilityType: '',
        //If dates are selected, they are converted to UTC format
        poFromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
        poToDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
        deliveryFromDate: '',
        deliveryToDate: '',
        grnFromDate : '',
        grnToDate :'',
        itemGroupId: !vm.filter.itemGroupId ? '' :vm.filter.itemGroupId,
        xeroExported: '',
        module : 0
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.goodsReturnNotes = response.data;
      },function(error){
        NotificationService.error({
          title: 'Goods Return Notice',
          error: error
        });
      });
    }
    function resetFilters() {
        vm.filter = {};
        vm.filter.debitnoteStatus = "ALL";
        vm.filter.fromDate = vm.todaysDate;
        vm.filter.toDate = vm.todaysDate;
        vm.goodsReturnNotes = [];
    }
  }
})();
