/**
* @Author: Ajay
* @Date:   27-Feb-2017 16:21 +05:30
* @Project: Ezyprocure
* @Filename: buyer.data.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 11-Apr-2018 11:22 +05:30
*/



(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .factory('BuyerDataService', BuyerDataService);

  BuyerDataService.$inject = ['$localStorage', '$state', 'Principal'];

  function BuyerDataService($localStorage, $state, Principal) {
    var service = {
      getBuyerProfile: getBuyerProfile,
      setBuyerProfile: setBuyerProfile,
      isAdminBuyer: isAdminBuyer,
      getCbBuyerProfile: getCbBuyerProfile,
      setCbBuyerProfile: setCbBuyerProfile
    };
    var buyerProfileInfo = null;
    var cbBuyerProfileInfo = null;

    return service;

    // Return buyer profile information
    function getBuyerProfile() {
      if (!buyerProfileInfo) {
        buyerProfileInfo = angular.fromJson($localStorage["buyer-profile-info"]);
      }
      return buyerProfileInfo;
    }

    // store buyer profile information
    function setBuyerProfile(buyerProfile) {
      buyerProfileInfo = buyerProfile;
      $localStorage["buyer-profile-info"] = angular.toJson(buyerProfile);
    }

    // Return buyer profile information
    function getCbBuyerProfile() {
      if (!cbBuyerProfileInfo) {
        cbBuyerProfileInfo = angular.fromJson($localStorage["cb-buyer-profile-info"]);
      }
      return cbBuyerProfileInfo;
    }

    // store buyer profile information
    function setCbBuyerProfile(buyerProfile) {
      cbBuyerProfileInfo = buyerProfile;
      $localStorage["cb-buyer-profile-info"] = angular.toJson(buyerProfile);
      Principal.setUserVerifiedKYC(buyerProfile.buyerUser.user.kycStatus);
      if (Principal.isUserVerifiedKYC() == false) {
        $state.go('buyer.contactadmin', { from: 'kyc' });
      }
    }

    function isAdminBuyer() {
      return this.getBuyerProfile().buyerUser.userRoles.level === 1 ? true : false;
    }
  }

})();
