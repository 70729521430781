/**
 * @Author: Elsa
 * @Date:   2017-05-25T13:08:25+05:30
 * @Project: Ezyprocure
 * @Filename: supplier.adhoc-credit-note.state.js
 * @Last modified by:   Elsa
 * @Last modified time: 28-06-2017 14:45:11
 */


 (function() {
   'use strict';

   angular
   .module('ezyprocureApp')
   .config(stateConfig);

   stateConfig.$inject = ['$stateProvider'];

   function stateConfig($stateProvider) {
     $stateProvider.state('supplier.reports.invoice-report.adhoc-credit-note', {
       parent: 'supplier.reports.invoice-report',
       url: '/:purchaseOrderNumber/:deliveryDate/adhoc-credit-note',
       data: {
         authorities: ['ROLE_SUPPLIER'],
         pageTitle: 'Adhoc Credit Note'
       },
       views: {
         'content@': {
           templateUrl: 'app/supplier-modules/reports/invoice-report/adhoc-credit-note/supplier.adhoc-credit-note.html',
           controller: 'SupplierCreateAdhocCreditNoteController',
           controllerAs: 'vm'
         }
       },
       resolve: {
         translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
           $translatePartialLoader.addPart('global');
           $translatePartialLoader.addPart('invoice');
           $translatePartialLoader.addPart('items');
           $translatePartialLoader.addPart('buyerBusiness');
           $translatePartialLoader.addPart('processOrder');
           return $translate.refresh();
         }]
       }
     })
     .state('supplier.reports.invoice-report.adhoc-credit-note.tax-preview', {
       parent: 'supplier.reports.invoice-report.adhoc-credit-note',
       url: '/tax-preview',
       data: {
         authorities: ['ROLE_SUPPLIER'],
         pageTitle: 'Adhoc Credit Note'
       },
       views: {
         'content@': {
           templateUrl: 'app/supplier-modules/reports/invoice-report/adhoc-credit-note/preview/supplier.adhoc-credit-note.tax-invoice-preview.html',
           controller: 'SupplierCreateAdhocTaxInvoicePreviewController',
           controllerAs: 'vm'
         }
       },
       resolve: {
         translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
           $translatePartialLoader.addPart('global');
           $translatePartialLoader.addPart('invoice');
           $translatePartialLoader.addPart('items');
           $translatePartialLoader.addPart('supplier');
           $translatePartialLoader.addPart('buyerBusiness');
           $translatePartialLoader.addPart('processOrder');
           return $translate.refresh();
         }]
       }
     })
     .state('supplier.reports.invoice-report.adhoc-credit-note.preview', {
       parent: 'supplier.reports.invoice-report.adhoc-credit-note',
       url: '/preview',
       data: {
         authorities: ['ROLE_SUPPLIER'],
         pageTitle: 'Adhoc Credit Note'
       },
       views: {
         'content@': {
           templateUrl: 'app/common-modules/reports-preview/supplier.adhoc-credit-note.preview.html',
           controller: 'SupplierCreateAdhocCreditNoteController',
           controllerAs: 'vm'
         }
       },
       resolve: {
         translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
           $translatePartialLoader.addPart('global');
           $translatePartialLoader.addPart('invoice');
           $translatePartialLoader.addPart('items');
           $translatePartialLoader.addPart('supplier');
           $translatePartialLoader.addPart('buyerBusiness');
           $translatePartialLoader.addPart('processOrder');
           return $translate.refresh();
         }]
       }
     });
   }
 })();
