/**
 * @Author: Ajay
 * @Date:   29-Jan-2018 15:35 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.good-receive-data.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 12-Oct-2018 14:53 +05:30
 */



(function() {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('BuyerGoodReceiveDataController', BuyerGoodReceiveDataController);

  BuyerGoodReceiveDataController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', '$stateParams', 'toastr', '$mdDialog', 'pagingParams', 'BuyerDataService', 'UTCDateService', 'BuyerBusinessService', 'BusinessReportService', 'BuyerSupplierMappingService', 'ItemGroupService', 'MainItemGroupService', 'paginationConstants', 'BuyerBusinessUserMapService', 'ReportService', 'CSVExportService', 'EXPORT_CONSTANTS', 'NotificationService', 'GLOBAL_CONSTANTS'];

  function BuyerGoodReceiveDataController($scope, $rootScope, $q, $filter, $state, $stateParams, toastr, $mdDialog, pagingParams, BuyerDataService, UTCDateService, BuyerBusinessService, BusinessReportService, BuyerSupplierMappingService, ItemGroupService, MainItemGroupService, paginationConstants, BuyerBusinessUserMapService, ReportService, CSVExportService, EXPORT_CONSTANTS, NotificationService, GLOBAL_CONSTANTS) {
    var vm = this;
    vm.predicate = JSON.parse(localStorage.getItem("report_goods_receive_predicate")) || pagingParams.predicate;
    vm.reverse = JSON.parse(localStorage.getItem("report_goods_receive_reverse")) || pagingParams.ascending;
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.getAllSuppliers = getAllSuppliers;
    vm.goodReceiveDataHeader = EXPORT_CONSTANTS.CSV.BuyerGoodReceiveData;
    vm.goodReceiveDataOutletHeader = EXPORT_CONSTANTS.CSV.BuyerGoodReceiveDataOutletLevel;
    vm.limitOptions = paginationConstants.limitOptions;
    vm.getGoodReceiveDataCSV = getGoodReceiveDataCSV;
    vm.loadMainItemGroups = loadMainItemGroups;
    vm.getAllGroupedItemGroups = getAllGroupedItemGroups;
    vm.mainItemGroupSelectionChanged = mainItemGroupSelectionChanged;
    vm.itemGroupSelectionChanged = itemGroupSelectionChanged;
    vm.loadAll = loadAll;
    vm.resetFilters = resetFilters;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.getBusinessOutletsForCSV = getBusinessOutletsForCSV;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.listViews = ['Consolidated','outletLevel'];
    vm.todaysDate = new Date();
    var getGoodsFilterItems = {};
    vm.filter = {
      buyerBusinessIdList: [],
      mainItemGroupIdList: [],
      itemGroupIdList: [],
      supplierIdList: [],
      fromDate:vm.todaysDate,
      toDate:vm.todaysDate,
      itemName:'',
      listView:vm.listViews[0]
    };
    vm.dateRangeChanged = dateRangeChanged;


    vm.query = JSON.parse(localStorage.getItem("report_goods_receive_query")) || {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.init = init();
    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function getBusinessOutletsForCSV() {
      var selectedOutlets = _.filter(vm.businessOutlets, function(outlet) {
        return _.includes(vm.filter.buyerBusinessIdList, outlet.id);
      });
      return _.join(_.map(selectedOutlets, 'businessOutletName'), ',');
    }

    //Getting CSV DATA
    function getGoodReceiveDataCSV() {
      var deferred = $q.defer();
      var exportMetaData = {
        dateRange: vm.dateRange,
        businessOutlets: vm.getBusinessOutletsForCSV()
      };
      if (!vm.poItems) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getBuyerGoodReceiveDataReportCSV(vm.filter.listView,vm.poItems, exportMetaData);
    }

    /**
    * [getAllBuyerBusinessOutlets Gets all business outlets of buyer]
    */
    function getAllBuyerBusinessOutlets() {
      if (vm.businessOutlets){
        return false;
      }
      BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser().
        then(function(response) {
          vm.businessOutlets = response;
        }, function(error) {
        });
    }

    /**
    * [loadMainItemGroups Gets all Main Item Groups]
    */
    function loadMainItemGroups() {
      if (vm.mainItemGroups){
        return false;
      }
      MainItemGroupService.getAllMainItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.mainItemGroups = response.data;
      }, function(error) {
      });
    }

    /**
    * [getAllGroupedItemGroups Gets all ItemGroups Grouped by Main Item Groups]
    */
    function getAllGroupedItemGroups(mainItemGroupIdList) {
      if (vm.itemGroups && !vm.filter.mainItemGroupChanged){
        return false;
      }
      vm.filter.mainItemGroupChanged = false;
      ItemGroupService.getAllGroupedItemGroups(mainItemGroupIdList, {
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.itemGroups = response.data;
      }, function(error) {
      });
    }

    /**
    * [getAllSuppliers Get all suppliers which satisfy the following]
    * 1. should be mapped with current logged in buyer
    * 2. who are selling items of selected main item groups and item groups
    */
    function getAllSuppliers() {
      if (vm.supplierList && !vm.filter.itemGroupChanged) {
        return false;
      }
      vm.filter.itemGroupChanged = false;
      ReportService.getAllSuppliers({
        mainItemGroupIdList: vm.filter.mainItemGroupIdList,
        itemGroupIdList: vm.filter.itemGroupIdList
      }, {
        page: 0,
        size: paginationConstants.maxLimit
      }).then(function(response) {
        vm.supplierList = response.data;
      }, function(error) {
      });
    }

    function mainItemGroupSelectionChanged() {
      vm.filter.mainItemGroupChanged = true;
      vm.filter.itemGroupIdList = [];
      vm.filter.supplierIdList = [];
    }

    function itemGroupSelectionChanged() {
      vm.filter.itemGroupChanged = true;
      vm.filter.supplierIdList = [];
    }

    /**
     * [selectAll Selects all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, mainItemGroups or itemGroups]
     */
    function selectAll(dropdown) {
      switch (dropdown) {
      case 'OUTLETS':
        vm.filter.buyerBusinessIdList = _.map(vm.businessOutlets, _.property('id'));
        break;
      case 'SUPPLIERS':
        vm.filter.supplierIdList = _.map(vm.supplierList, _.property('id'));
        break;
      case 'MAIN_ITEMGROUPS':
        vm.mainItemGroupSelectionChanged();
        vm.filter.mainItemGroupIdList = _.map(vm.mainItemGroups, _.property('id'));
        break;
      case 'ITEMGROUPS':
        vm.itemGroupSelectionChanged();
        vm.filter.itemGroupIdList = _.map(_.flatten(_.without(_.map(vm.itemGroups, _.property('itemGroupDTOList')), undefined, null)), _.property('itemGroupId'));
        break;
      }
    }

    /**
     * [deselectAll Deselect all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, mainItemGroups or itemGroups]
     */
    function deselectAll(dropdown) {
      switch (dropdown) {
      case 'OUTLETS':
        vm.filter.buyerBusinessIdList = [];
        break;
      case 'SUPPLIERS':
        vm.filter.supplierIdList = [];
        break;
      case 'MAIN_ITEMGROUPS':
        vm.filter.mainItemGroupIdList = [];
        vm.mainItemGroupSelectionChanged();
        break;
      case 'ITEMGROUPS':
        vm.filter.itemGroupIdList = [];
        vm.itemGroupSelectionChanged();
        break;
      }
    }

    function loadAll() {
      localStorage.setItem('report_goods_receive_filter',JSON.stringify(vm.filter))
      localStorage.setItem('report_goods_receive_predicate',JSON.stringify(vm.predicate));
      localStorage.setItem('report_goods_receive_reverse',JSON.stringify(vm.reverse));
      localStorage.setItem('report_goods_receive_query',JSON.stringify(vm.query));
      BusinessReportService.getGoodReceivedData({
        buyerBusinessIdList: !vm.filter.buyerBusinessIdList ? [] : vm.filter.buyerBusinessIdList,
        supplierIdList: !vm.filter.supplierIdList ? [] : vm.filter.supplierIdList,
        itemGroupIdList: !vm.filter.itemGroupIdList ? [] : vm.filter.itemGroupIdList,
        //If dates are selected, they are converted to UTC format
        fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(new Date(vm.filter.fromDate)),
        toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(new Date(vm.filter.toDate)),
        query: !vm.filter.itemName ? '' : vm.filter.itemName,
        viewType: vm.filter.listView
      }, {
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
      }).then(function(response) {
        vm.poItems = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      }, function(error) {
        NotificationService.error({
          title: 'Fetching Good Received Data',
          error: error
        });
      });
    }

    function resetFilters() {
      vm.filter = {
        buyerBusinessIdList: [],
        mainItemGroupIdList: [],
        itemGroupIdList: [],
        supplierIdList: [],
        fromDate:vm.todaysDate,
        toDate:vm.todaysDate,
        itemName:'',
        listView:vm.listViews[0]
      };
      localStorage.removeItem("report_goods_receive_query");
      localStorage.removeItem("report_goods_receive_filter");
      localStorage.removeItem("report_goods_receive_predicate");
      localStorage.removeItem("report_goods_receive_reverse");
      vm.poItems = null;
    }

    function dateRangeChanged() {
      vm.dateRange = $filter('date')(vm.filter.fromDate, "dd/MM/yyyy") + '-' + $filter('date')(vm.filter.toDate, "dd/MM/yyyy");
    }

    function init() {
      dateRangeChanged();
      var getGoodsItems = localStorage.getItem("report_goods_receive_filter");
      if (getGoodsItems) {
        getGoodsFilterItems = JSON.parse(getGoodsItems);
        if (getGoodsFilterItems.itemGroupIdList) {
          getAllGroupedItemGroups(vm.filter.mainItemGroupIdList);
        }
        if(getGoodsFilterItems.buyerBusinessIdList){
          getAllBuyerBusinessOutlets();
        }
        if(getGoodsFilterItems.supplierIdList){
          getAllSuppliers();
        }
        vm.filter = getGoodsFilterItems;
        loadAll();
      }
    }
  }
})();
