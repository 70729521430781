/**
 * @Author: Jayalekshmi
 * @Date:   2018-06-28T14:03:06+05:30
 * @Filename: buyer.brand-supplier-mapping.state.js
 * @Last modified by:   Jayalekshmi
 * @Last modified time: 2018-06-28T15:25:30+05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('buyer.tools.brand-supplier-mapping', {
      parent: 'buyer.tools',
      url: '/brand-supplier-mapping',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'Brand - Supplier Mapping'
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/tools/brand-supplier-mapping/buyer.brand-supplier-mapping.html',
          controller: 'BuyerBrandSupplierMapController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },

      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('brandSupplierMapping');
          return $translate.refresh();
        }]
      }
    })
    .state('buyer.tools.brand-supplier-mapping.add', {
      parent: 'buyer.tools.brand-supplier-mapping',
      url: '/add',
      data: {
        authorities: ['ROLE_BUYER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/tools/brand-supplier-mapping/add/buyer.brand-supplier-mapping.add.html',
          controller: 'BuyerBrandSupplierMapAddController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('brandSupplierMapping');

          return $translate.refresh();
        }]
      }
    })
    ;
  }
})();
