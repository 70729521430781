/**
* @Author: elsa
* @Date:   2017-05-23T12:17:23+05:30
* @Project: Ezyprocure
* @Filename: supplier.create-credit-note.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 07-Nov-2017 15:22 +05:30
*/


(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierCreateCreditNoteController', SupplierCreateCreditNoteController);

  SupplierCreateCreditNoteController.$inject = ['$scope', '$rootScope', '$timeout', '$state', 'NotificationService', '$mdDialog', '$filter', '$stateParams', 'pagingParams', 'paginationConstants', 'SupplierReturnOrderService', 'GLOBAL_CONSTANTS'];

  function SupplierCreateCreditNoteController($scope, $rootScope, $timeout, $state, NotificationService, $mdDialog, $filter, $stateParams, pagingParams, paginationConstants, SupplierReturnOrderService, GLOBAL_CONSTANTS) {

    var vm = this;
    vm.init = init;
    vm.loadAll = loadAll;
    vm.individualTotalPrice = individualTotalPrice;
    vm.goodsReturnNumber = $stateParams.goodsReturnNumber;
    vm.totalCreditAmount = totalCreditAmount;
    vm.createCreditNote = createCreditNote;
    vm.grandTotalCredit = grandTotalCredit;
    vm.gstAmount = gstAmount;
    vm.cancel = cancel;
    vm.showModalToEnterInvoiceNumber = showModalToEnterInvoiceNumber;
    vm.checkAlreadyGeneratedCreditNote = checkAlreadyGeneratedCreditNote;
    vm.returnedWeight = returnedWeight;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;

    /**
     * [init calls initially]
     */
    function init() {
      vm.loadAll();
    }

    vm.init();

    /**
     * [loadAll loads return order details by goods return number]
     */
    function loadAll() {
      SupplierReturnOrderService.getDebitNotesByGRNumber(vm.goodsReturnNumber).then(function(response) {
        vm.checkAlreadyGeneratedCreditNote(response.data);
        vm.debitNoteDetails = response.data;
      }, function(error) {
      });
    }

    function returnedWeight(p_index) {
      var returnedWeight = 0;
      returnedWeight = vm.debitNoteDetails[p_index].goodsReturnNotice.rejectedQuantity;
      returnedWeight =  parseFloat(returnedWeight.toFixed(2));
      return returnedWeight;
    }

    // Redirect back to return order Listing page if credit note already generated*
    function checkAlreadyGeneratedCreditNote(debitNoteDetails) {
      if (debitNoteDetails[0].goodsReturnNotice.debitNoteStatus !== 'CREDIT_NOTE_PENDING') {
        $state.go('supplier.return-order', {}, { reload: false});
      }
    }

    /**
     * [individualTotalPrice finds individual credit amount]
     * @param  {[number]} p_index [index of the item]
     * @return {[number]}         [individual amount]
     */
    function individualTotalPrice(p_index) {
      var details = vm.debitNoteDetails[p_index];
      var inTotalPrice;
      if (details.processOrder.items.uom.weightRequired) {
        if(details.processOrder.items.uom.moqPrice === true){
            inTotalPrice = (details.goodsReturnNotice.creditNoteWeight/details.processOrder.items.moq) * details.processOrder.price;
        }
        else {
            inTotalPrice = details.goodsReturnNotice.creditNoteWeight * details.processOrder.price;
        }
      }
      else {
       if(details.processOrder.items.uom.moqPrice === true){
           inTotalPrice = (details.goodsReturnNotice.creditNoteQuantity/details.processOrder.items.moq)  * details.processOrder.price;
       }
       else {
           inTotalPrice = details.goodsReturnNotice.creditNoteQuantity * details.processOrder.price;
       }
      }
      vm.debitNoteDetails[p_index].goodsReturnNotice.creditNoteAmount = inTotalPrice;

      return inTotalPrice;
    }

    /**
     * [totalCreditAmount finds total credit amount]
     * @return {[number]} [total credit amount]
     */
    function totalCreditAmount() {
      var totalCreditAmount = 0;
      _.forEach(vm.debitNoteDetails, function(response) {
        totalCreditAmount += _.round(response.goodsReturnNotice.creditNoteAmount,2)
      });
      return _.round(totalCreditAmount,2);
    }

    /**
     * [gstAmount finds gst amount]
     * @return {[number]} [gst amount]
     */
    function gstAmount() {
      var subTotal = totalCreditAmount();
      var gstAmount = 0;
      gstAmount = (vm.debitNoteDetails[0].processOrder.items.supplier.gst / 100) * subTotal;
      if(vm.debitNoteDetails[0].adhocPOGstEnabled == false)
      {
        gstAmount = 0;
      }
      return gstAmount;
    }

    /**
     * [grandTotalCredit find grand total credit amount]
     * @return {[number]} [grand total credit amount]
     */
    function grandTotalCredit() {
      var totalCredit = 0;
      var subTotal = vm.totalCreditAmount();
      if(vm.debitNoteDetails[0].processOrder.items.supplier.tax === 'Active' && vm.debitNoteDetails[0].processOrder.items.supplier.gst !== null) {
        var gstAmount = vm.gstAmount();
        totalCredit += subTotal;
        totalCredit += gstAmount;
      }
      else {
        totalCredit += subTotal;
      }
      return (totalCredit);
    }

    /**
    * [cancel to close the modal]
    */
    function cancel () {
      $mdDialog.cancel();
    }

    /**
     * [showModalToEnterInvoiceNumber opens modal to enter custom invoice number]
     */
    function showModalToEnterInvoiceNumber(event) {
      $mdDialog.show({
        templateUrl: 'app/supplier-modules/return-order/create-credit-note/supplier.custom-invoice-number-modal.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    /**
     * [createCreditNote creates credit note amount]
     */
    function createCreditNote() {
      cancel();
      vm.debitNoteDetails[0].goodsReturnNotice.hardcopyInvoiceNumber = vm.hardcopyInvoiceNumber;
      vm.debitNoteDetails[0].goodsReturnNotice.goodsReturnNumber = vm.creditNoteNumber;
      // vm.debitNoteDetails[0].goodsReturnNotice.creditNoteAmount = vm.totalCreditAmount();
      vm.debitNoteDetails[0].goodsReturnNotice.creditNoteTaxAmount = vm.gstAmount();
      SupplierReturnOrderService.createCreditNote(vm.debitNoteDetails)
      .then(function(response) {
        NotificationService.success({
          title: 'Credit Note',
          message: 'alertMessage.CREATED_SUCCESSFULLY'
        });
        $state.go('supplier.return-order', {reload: false});
      }, function(error) {
        NotificationService.error({
          title: 'Credit Note Creation',
          error: error
        });
      });
    }
  }
})();
