/**
* @Author: Ajay
* @Date:   05-Apr-2017 16:58 +05:30
* @Project: Ezyprocure
* @Filename: buyer.promotions.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 07-Nov-2017 15:33 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerPromotionsController', BuyerPromotionsController);

  BuyerPromotionsController.$inject = ['$scope', '$rootScope', '$state', 'toastr', '$mdDialog', 'PromotionService', 'pagingParams', 'paginationConstants', 'GLOBAL_CONSTANTS'];

  function BuyerPromotionsController($scope, $rootScope, $state, toastr, $mdDialog, PromotionService, pagingParams, paginationConstants, GLOBAL_CONSTANTS) {
    var vm = this;

    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;

    vm.loadAll = loadAll;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };


    /**
    * [sort description]
    * @return {[type]} [description]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
    * [loadAll  Get All Buyer Mapped Items Promotions]
    * @return {[list]}        [Promotions]
    */
    function loadAll(search) {
      if (!search) {
        vm.searchText = '';
      }
      PromotionService.getAllPromotionItemsForBuyer({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        searchTerm: vm.searchText,
        startDate: '',
        endDate: '',
        flag:"BUYER_PROMO"
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.promotions = response.data;
      }, function(error) {
      });
    }

    vm.loadAll();


  }
})();
