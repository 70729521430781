/**
* @Author: Shibi
* @Date:   17-Nov-2021 12:45 +05:30
* @Project: Ezyprocure
* @Filename: multiple-email-validation.directive.js
*/



(function() {
    'use strict';

    angular
    .module('ezyprocureApp')
    .directive('dnMultipleEmailValidator', dnMultipleEmailValidatorImpl);

    function dnMultipleEmailValidatorImpl() {
        return {
            require: 'ngModel',
            link: dnMultipleEmailValidatorLinkImpl
        };
    }
    var EMAIL_PATTERN =  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    function dnMultipleEmailValidatorLinkImpl(scope, element,  attributes, controller) {
        controller.$validators.dnMultipleEmailValidator = function(modelValue, viewValue) {
         var errorFlag = true;
         if (!controller.$isEmpty(viewValue)) {
         var emailIdsArr = viewValue.split(/,|;/g);
             angular.forEach( emailIdsArr, function( value, key ) {
               if (!EMAIL_PATTERN.test(value.trim())) {
                  errorFlag = false;
               }
             });
        }
          return errorFlag;
        };
      }



    })();
