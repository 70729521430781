/**
 * @Author: Elsa
 * @Date:   2017-06-14T14:35:10+05:30
 * @Project: Ezyprocure
 * @Filename: supplier.goods-return-notice.preview.state.js
 * @Last modified by:   Elsa
 * @Last modified time: 2017-06-14T14:38:45+05:30
 */


 (function() {
   'use strict';

   angular
   .module('ezyprocureApp')
   .config(stateConfig);

   stateConfig.$inject = ['$stateProvider'];

   function stateConfig($stateProvider) {
     $stateProvider.state('supplier.reports.goods-return-notice.preview', {
       parent: 'supplier.reports.goods-return-notice',
       url: '/preview/:goodsReturnNumber',
       data: {
         authorities: ['ROLE_SUPPLIER'],
         pageTitle: 'Goods Return Notice Preview'
       },
       views: {
         'content@': {
           templateUrl: 'app/common-modules/reports-preview/goods-return-notice-report.preview.html',
           controller: 'SupplierGoodReturnNoticeDetailsController',
           controllerAs: 'vm'
         }
       },
       resolve: {
         translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
           $translatePartialLoader.addPart('supplier');
           $translatePartialLoader.addPart('buyer');
           $translatePartialLoader.addPart('global');
           $translatePartialLoader.addPart('invoice');
           $translatePartialLoader.addPart('items');
           return $translate.refresh();
         }]
       }
     });
   }
 })();
