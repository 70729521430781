/**
* @Author: Allan
* @Date:   31-October-2018 11:59 +05:30
* @Project: Ezyprocure
* @Filename: admin.manage-emails.state.js
* @Last modified by:   Allan
* @Last modified time: 31-October-2018 12:02 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('admin.manage-emails', {
      parent: 'admin',
      url: '/emails',
      data: {
        authorities: ['ROLE_ADMIN'],
        pageTitle: 'ezyprocureApp.purchaseOrder.home.title'
      },
      views: {
        'content@': {
          templateUrl: 'app/admin-modules/manage-emails/admin.manage-emails.html',
          controller: 'AdminManageEmailsController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('user-management');
          return $translate.refresh();
        }]
      }
    })
  }

})();
