/*
 * @Author: Lovin 
 * @Date: 2019-06-21 11:50:55 
 * @Project: Ezyprocure
 * @Filename: admin.audit-log-report.controller.js
 * @Last Modified by: Lovin
 * @Last Modified time: 2019-06-27 09:40:56
 */


(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .controller('AuditLogReportController', AuditLogReportController);
  
    AuditLogReportController.$inject = ['$scope', '$rootScope', '$state', 'pagingParams', 'paginationConstants', 'NotificationService','$filter', 'AuditLogReportService', 'UTCDateService', 'GLOBAL_CONSTANTS'];
  
    function AuditLogReportController($scope, $rootScope, $state, pagingParams, paginationConstants, NotificationService, $filter, AuditLogReportService, UTCDateService, GLOBAL_CONSTANTS) {
      var vm = this;

      vm.todaysDate = new Date();
      vm.tomorrow = new Date();
      vm.tomorrow.setDate(vm.tomorrow.getDate() + 1);
      vm.tomorrow = UTCDateService.utcToDate(vm.tomorrow);
      vm.predicate = pagingParams.predicate;
      vm.reverse = pagingParams.ascending;
      vm.getAllBrands = getAllBrands;
      vm.loadAll = loadAll;
      vm.defaultDateTimeFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_TIME_FORMAT;
 
      vm.query = {
        limit: paginationConstants.itemsPerPage,
        page: 1
      };
      vm.limitOptions = paginationConstants.limitOptions;
  
      function sort() {
        var result = ['name' + ',' + (vm.reverse ? 'asc' : 'desc')];
        return result;
      }
      
    // Get all logs 
      function loadAll() {
        AuditLogReportService.getAuditlogReport({
          fromDate: vm.filter.fromDate? UTCDateService.utcFromDate(vm.filter.fromDate):'',
          toDate: vm.filter.toDate? UTCDateService.utcFromDate(vm.filter.toDate):'',
          brandId: vm.filter.brand?vm.filter.brand : '',
          transactionType: vm.filter.transactionType?vm.filter.transactionType :'' ,
          page: vm.query.page - 1,
          size: vm.query.limit,
          sort: sort()
        }).then(function(response) {
          vm.auditLogs = response.data;
          vm.totalItems = response.headers('X-Total-Count');
        }, function(error) {
          NotificationService.error({
            title: 'Audit Log',
            error: error
          });
        });
      }
  
    /**
    * [getAllBrands Get list of brands]
    */
    function getAllBrands() {
      if(vm.brands)
        return false;
        AuditLogReportService.getAllBrands({
          query: '',
          size:paginationConstants.maxLimit
        }).then(function (response) {
          vm.brands = response.data;
        }, function (error) {
        });
      }
    }
  
  })();
  