/*
 * @Author: Lovin C 
 * @Date: 2021-07-29 15:29:32 
 * @Last Modified by: Lovin C
 * @Last Modified time: 2021-08-17 09:34:20
 */


(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
      .state('ezypayment.admin.tools.e-giro-fund-transfer', {
        parent: 'ezypayment.admin.tools',
        url: '/egiro-fund-transfer',
        data: {
          authorities: ['ROLE_ADMIN'],
          pageTitle: 'eGiro Fund Transfer'
        },
        views: {
          'content@': {
            templateUrl: 'app/ezypayment-modules/admin-modules/tools/e-giro-fund-transfer/ezypayment.admin.e-giro-fund-transfer.html',
            controller: 'EzypaymentEGiroFundTransfer',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,asc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('brand');
            $translatePartialLoader.addPart('purchaseOrder');
            $translatePartialLoader.addPart('bank');
            $translatePartialLoader.addPart('paymentTerms');
            $translatePartialLoader.addPart('buyer');
            return $translate.refresh();
          }]
        }
      })
  }
})();
