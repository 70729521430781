/*
 * @Author: Jayalekshmi 
 * @Date: 2018-11-14 13:07:52 
 * @Project: Ezyprocure
 * @Filename: buyer.adhoc-supplier-buyer-mapping.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2018-11-28 17:39:20
 */



(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .controller('BuyerAdhocSupplierMappingController', BuyerAdhocSupplierMappingController);
  
    BuyerAdhocSupplierMappingController.$inject = ['$scope' ,'$state', '$stateParams', 'pagingParams', '$mdDialog', 'paginationConstants', 'NotificationService','BuyerAdhocSupplierMappingService', '$filter'];
  
    function BuyerAdhocSupplierMappingController($scope, $state, $stateParams, pagingParams, $mdDialog, paginationConstants, NotificationService, BuyerAdhocSupplierMappingService, $filter) {
      var vm = this;
      vm.predicate = pagingParams.predicate;
      vm.reverse = pagingParams.ascending;
      vm.sort = sort;
      vm.loadAll = loadAll;
      vm.deleteAdhocSupplierBuyerMapping = deleteAdhocSupplierBuyerMapping;
      vm.showConfirm = showConfirm;
  
      vm.query = {
        limit: paginationConstants.itemsPerPage,
        page: 1
      };
      vm.limitOptions = paginationConstants.limitOptions;
      
      /**
       * [sort sorts the response with provided data]
       */
      function sort() {
        var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
        return result;
      }
  
      /**
       * [Load All Adhoc suppliers which are mapped]
       * @param {String} search 
       */
      function loadAll(search) {
        if (!search) {
          vm.searchText = '';
        }
        BuyerAdhocSupplierMappingService.getAllAdhocSupplierBuyerMappings({
          page: vm.query.page - 1,
          size: vm.query.limit,
          sort: sort(),
          query: vm.searchText,
        }).then(function(response){
          vm.totalItems = response.headers('X-Total-Count');
          vm.adhocSupplierMappings = response.data;
        },function(error){
          NotificationService.error({
            title: 'global.navbar.adhocSupplierBuyerMapping',
            error: error
          });
        });
      }
  
      /**
       * [showConfirm shows confirmation modal before deletes]
       * @param {Object} event 
       * @param {Object} adhocSupplierBuyerMapping 
       */
      function showConfirm(event, adhocSupplierBuyerMapping) {
        var confirm = $mdDialog.confirm()
        .title($filter('translate')('alertMessage.CONFIRM_DELETE_MAPPING'))
        .textContent($filter('translate')('alertMessage.COULD_NOT_BE_REVERTED_BACK'))
        .targetEvent(event)
        .ok($filter('translate')('global.buttons.delete'))
        .cancel($filter('translate')('global.buttons.cancel'));
  
        $mdDialog.show(confirm).then(function() {
          deleteAdhocSupplierBuyerMapping(adhocSupplierBuyerMapping);
        }, function() {});
      }
  
      /**
       * [Deletes selected Adhoc supplier and Buyer]
       * @param {Object} adhocSupplierBuyerMapping 
       */
      function deleteAdhocSupplierBuyerMapping(adhocSupplierBuyerMapping) {
        BuyerAdhocSupplierMappingService.deleteAdhocSupplierBuyerMapping({
          buyerSupplierMappingId:adhocSupplierBuyerMapping.id
        }).then(function(response){
          NotificationService.success({
            title: 'global.navbar.adhocSupplierBuyerMapping',
            message: 'alertMessage.DELETED_SUCCESSFULLY'
          });
          vm.loadAll();
        },function(error) {
          NotificationService.error({
            title: 'global.navbar.adhocSupplierBuyerMapping',
            error: error
          });
        });
      }
      vm.loadAll();
    }
  })();
  