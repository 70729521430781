/**
* @Author: Hareesh
* @Date:   07-Oct-2017 11:17 +05:30
* @Project: Ezyprocure
* @Filename: supplier.good-receipt-note.consolidated-details.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-06-25T09:51:50+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierGoodReceiptNoteConsolidatedDetailsController', SupplierGoodReceiptNoteConsolidatedDetailsController);

  SupplierGoodReceiptNoteConsolidatedDetailsController.$inject = ['$scope', '$rootScope', '$filter', '$state', '$stateParams', 'toastr', '$mdDialog', 'PurchaseOrderService', 'UTCDateService', 'NotificationService', 'PDFExportService', 'EXPORT_CONSTANTS', 'CSVExportService', 'pagingParams', 'GLOBAL_CONSTANTS', 'Auth'];

  function SupplierGoodReceiptNoteConsolidatedDetailsController($scope, $rootScope, $filter, $state, $stateParams, toastr, $mdDialog, PurchaseOrderService, UTCDateService, NotificationService, PDFExportService, EXPORT_CONSTANTS, CSVExportService, pagingParams, GLOBAL_CONSTANTS, Auth) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.businessOutletName = $stateParams.businessOutletName;
    vm.buyerIdList = $stateParams.buyer;
    vm.businessOutletId = $stateParams.businessIdList;
    vm.itemGroupId = $stateParams.itemGroupId;
    vm.poNumber = $stateParams.poNumber;
    vm.reverse = pagingParams.ascending;
    vm.init = init;
    vm.poFromDate =!$stateParams.poFromDate ? '' : new Date($stateParams.poFromDate);
    vm.poToDate =!$stateParams.poToDate? '': new Date($stateParams.poToDate);
    vm.deliveryFromDate =!$stateParams.deliveryFromDate ? '' : new Date($stateParams.deliveryFromDate);
    vm.deliveryToDate =!$stateParams.deliveryToDate ? '' : new Date($stateParams.deliveryToDate);
    vm.grnFromDate =!$stateParams.grnFromDate ? '' : new Date($stateParams.grnFromDate);
    vm.grnToDate =!$stateParams.grnToDate? '': new Date($stateParams.grnToDate);
    vm.invoiceStatus = $stateParams.invoiceStatus;
    vm.getConsolidatedPurchaseOrderPDF = getConsolidatedPurchaseOrderPDF;
    vm.getGrnConsolidatedCSV = getGrnConsolidatedCSV;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.defaultDateTimeFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_TIME_FORMAT;
    vm.accessForPriceDisplay = Auth.hasPermission('accessForPriceDisplay');


    /**
    * [init loads initilally]
    */
    function init(){
      if(vm.accessForPriceDisplay)
       vm.consolidatedGoodsReceiptNoteHeader = EXPORT_CONSTANTS.CSV.ConsolidatedGoodsReceiptNoteHeader;
      else {
        vm.consolidatedGoodsReceiptNoteHeader = _.without(EXPORT_CONSTANTS.CSV.ConsolidatedGoodsReceiptNoteHeader, 'unit_price' , 'PO_amount', 'accepted_amount');
      }
      loadAll();
    }

    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
    * [loadAll Gets Purchase Order Details]
    */
    function loadAll() {
      PurchaseOrderService.getGRNByPONumberAll({
        page: 1 - 1,
        size: 1000,
        sort: sort(),
        invoiceStatus: vm.invoiceStatus,
        businessOutletId: vm.businessOutletId ? vm.businessOutletId: '',
        businessOutletName: vm.businessOutletName ? vm.businessOutletName : '',
        poFromDate: !vm.poFromDate ? '' : UTCDateService.utcFromDate(vm.poFromDate),
        poToDate: !vm.poToDate ? '' : UTCDateService.utcToDate(vm.poToDate),
        deliveryFromDate: !vm.deliveryFromDate ? '' : UTCDateService.utcFromDate(vm.deliveryFromDate),
        deliveryToDate: !vm.deliveryToDate ? '' : UTCDateService.utcToDate(vm.deliveryToDate),
        grnFromDate : !vm.grnFromDate ? '' : UTCDateService.utcFromDate(vm.grnFromDate),
        grnToDate : !vm.grnToDate ? '' : UTCDateService.utcToDate(vm.grnToDate),
        supplierId: '',
        itemGroupId: vm.itemGroupId ? vm.itemGroupId : '',
        buyerIdList: vm.buyerIdList ? vm.buyerIdList : '',
        poNumber: vm.poNumber ? vm.poNumber : ''
      }).then(function(response){
        vm.consolidatedPODetails = response.data;
      },function(error){
      });
    }

    function downloadURI(uri, name)
    {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = name;
      link.href = uri;
      link.click();
    }

    function getConsolidatedPurchaseOrderPDF() {
      PDFExportService.getConsolidatedGrnPDF({
        poFromDate: !vm.poFromDate ? '' : UTCDateService.utcFromDate(vm.poFromDate),
        poToDate: !vm.poToDate ? '' : UTCDateService.utcToDate(vm.poToDate),
        deliveryFromDate: !vm.deliveryFromDate ? '' : UTCDateService.utcFromDate(vm.deliveryFromDate),
        deliveryToDate: !vm.deliveryToDate ? '' : UTCDateService.utcToDate(vm.deliveryToDate),
        grnFromDate : !vm.grnFromDate ? '' : UTCDateService.utcFromDate(vm.grnFromDate),
        grnToDate : !vm.grnToDate ? '' : UTCDateService.utcToDate(vm.grnToDate),
        reportType: "PO",
        businessOutletId: vm.businessOutletId ? vm.businessOutletId: '',
        businessOutletName: vm.businessOutletName ? vm.businessOutletName : '',
        supplierId: '',
        itemGroupId: vm.itemGroupId ? vm.itemGroupId : '',
        poNumber: vm.poNumber ? vm.poNumber : '',
        invoiceStatus: vm.invoiceStatus,
        buyerIdList: vm.buyerIdList ? vm.buyerIdList : '',
        paymentStatus: ''
      })
      .then(function(response){
        downloadURI(response.data.message, "PDF_" + vm.purchaseOrderNumber);
      },function(error) {
        NotificationService.error({
          title: 'Consolidated PO PDF Export',
          error: error
        });
      });
    }

    function getGrnConsolidatedCSV() {
      return CSVExportService.grnConsolidatedCSV(vm.consolidatedPODetails);
    }

  }
})();
