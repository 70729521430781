/**
* @Author: Ajay
* @Date:   25-May-2017 11:30 +05:30
* @Project: Ezyprocure
* @Filename: buyer.reports.state.js
* @Last modified by:   Mufsir
* @Last modified time: 14-Jun-2017 02:28 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('buyer.reports', {
      abstract: true,
      parent: 'buyer',
      url: '/reports',
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('promotion');
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('buyer');
          return $translate.refresh();
        }],
        hasModuleAccess: ['Auth','$q', '$state', function(Auth, $q, $state) {
          if(Auth.hasPermission('accessForReports')){
            return true;
          }else {
            return $q.reject('Unauthorized');
          }
        }]
      }
    });
  }
})();
