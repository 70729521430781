/**
* @Author: Ajay
* @Date:   20-Jan-2017 19:36 +05:30
* @Project: Ezyprocure
* @Filename: supplier.user-management.edit.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 06-Nov-2017 14:58 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierUserEditController', SupplierUserEditController);

  SupplierUserEditController.$inject = ['$scope', '$state', '$stateParams', 'SupplierUserManagementService', 'SupplierDataService', 'UserRoleService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'NotificationService'];

  function SupplierUserEditController ($scope, $state, $stateParams, SupplierUserManagementService, SupplierDataService, UserRoleService, GLOBAL_CONSTANTS, paginationConstants, NotificationService) {
    var vm = this;

    vm.supplierUserId = $stateParams.supplierUserId;
    vm.supplierUser = {};
    vm.supplierUser.user = {};
    vm.save = save;
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.getUserRoles = getUserRoles;
    vm.getSupplierUser = getSupplierUser;
    vm.updateSupplierUserActivatedKey = updateSupplierUserActivatedKey;

    function save () {
      vm.supplierProfile = SupplierDataService.getSupplierProfile();
      vm.supplierUser.supplierId = vm.supplierProfile.supplier.id;
      // Call Supplier user management service to update with supplier user object as parameter
      SupplierUserManagementService.updateSupplierUser(vm.supplierUser).then(function(response){
        $scope.$emit('ezyprocureApp:supplierBusinessCreated', response);
        NotificationService.success({
          title: 'Supplier User',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        $state.go('supplier.tools.user-management', {reload: false});
      },function(error) {
        NotificationService.error({
          title: 'Supplier User Updation',
          error: error
        });
      });
    }

    function updateSupplierUserActivatedKey(supplierUserId, activated) {
      SupplierUserManagementService.updateSupplierUserActivatedKey(supplierUserId, {
        activated: activated
      }).then(function(response){
        NotificationService.success({
          title: 'Supplier User',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
      },function(error) {
        NotificationService.error({
          title: 'Supplier User Updation',
          error: error
        });
      });
    }

    function getSupplierUser() {
      //fetch supplier user details using bueyerUserId
      SupplierUserManagementService.getSupplierUser(vm.supplierUserId).
      then(function(response) {
        if (response) {
          vm.supplierUser = response;
        }
      }, function(error) {
      });
    }

    function getUserRoles() {
      // Call UserRoleService to get all the roles created by the supplier
      UserRoleService.getAllUserRoles({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response){
        vm.userRoles = response.data;
      },function(error) {
        vm.userRoles = null;
      });
    }

    getUserRoles();
    getSupplierUser();
  }
})();
