/**
* @Author: Ajay
* @Date:   23-Apr-2017 20:57 +05:30
* @Project: Ezyprocure
* @Filename: decimal.filter.js
 * @Last modified by:   Ajay
 * @Last modified time: 26-Mar-2018 13:14 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .filter('capitalizeFirstLetter', capitalizeFirstLetter);

  function capitalizeFirstLetter() {
    return capitalizeFirstLetter;
    function capitalizeFirstLetter(text) {
      return (!!text) ? text.charAt(0).toUpperCase() + text.substr(1) : '';
    }
  }
})();
