/**
 * @Author: Jayalekshmi R
 * @Date:   2018-06-11T15:32:51+05:30
 * @Project: Ezyprocure
 * @Filename: buyer.brands.state.js
 * @Last modified by: Jayalekshmi R
 * @Last modified time: 2018-06-14T15:52:04+05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
      .state('buyer.tools.brands', {
        parent: 'buyer.tools',
        url: '/brands',
        data: {
          authorities: ['ROLE_BUYER'],
        },
        views: {
          'content@': {
            templateUrl: 'app/buyer-modules/tools/brands/buyer.brands.html',
            controller: 'BrandListController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,asc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('buyer');
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('brand');
            return $translate.refresh();
          }]
        }
      })
      .state('buyer.tools.brands.add', {
        parent: 'buyer.tools.brands',
        url: '/add',
        data: {
          authorities: ['ROLE_BUYER'],
        },
        views: {
          'content@': {
            templateUrl: 'app/buyer-modules/tools/brands/add/buyer.brands.add.html',
            controller: 'BrandAddController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('buyer');
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('brand');

            return $translate.refresh();
          }]
        }
      })
      .state('buyer.tools.brands.edit', {
        parent: 'buyer.tools.brands',
        url: '/edit/:brandId',
        data: {
          authorities: ['ROLE_BUYER'],
        },
        views: {
          'content@': {
            templateUrl: 'app/buyer-modules/tools/brands/edit/buyer.brands.edit.html',
            controller: 'BrandEditController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('buyer');
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('brand');
            return $translate.refresh();
          }]
        }
      });
  }
})();
