/**
* @Author: sneha
* @Date:   10-Jan-2018 11:49 +05:30
* @Project: Ezyprocure
* @Filename: admin.transaction-volume.state.js
 * @Last modified by:   sneha
 * @Last modified time: 16-Jan-2018 15:36 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('admin.transaction-volume', {
      parent: 'admin',
      url: '/transaction-volume',
      data: {
        authorities: ['ROLE_ADMIN'],
        pageTitle: 'Transaction Volume Summary'
      },
      views: {
        'content@': {
          templateUrl: 'app/admin-modules/transaction-volume/admin.transaction-volume.html',
          controller: 'AdminTransactionVolumeController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    });
  }

})();
