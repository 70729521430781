/*
 * @Author: Ajay
 * @Date: 2019-01-31 10:18:02 
 * @Project: Ezyprocure
 * @Filename: ezyap.edit-buyer-information.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-10-24 10:39:38
 */
(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('EzyapEditBuyerInfoController', EzyapEditBuyerInfoController);

  EzyapEditBuyerInfoController.$inject = ['$state', 'NotificationService', 'ManageBuyerInfoService', '$stateParams', 'GLOBAL_CONSTANTS', 'EZYAP_CONSTANTS', 'Auth'];

  function EzyapEditBuyerInfoController($state, NotificationService, ManageBuyerInfoService, $stateParams, GLOBAL_CONSTANTS, EZYAP_CONSTANTS, Auth) {

    var vm = this;
    vm.editBuyerInformation = editBuyerInformation;
    vm.buyerId = $stateParams.id;
    vm.getBuyerInformationById = getBuyerInformationById;
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.init = init;
    vm.getAppendedValues = getAppendedValues;
    vm.countryCodes = EZYAP_CONSTANTS.ezyap.countryCodes;
    vm.userRole = Auth.getUserRole();

    function init() {
      vm.getBuyerInformationById(vm.buyerId);
    }

    /**
     * [edit buyer information]
     */
    function editBuyerInformation() {
      ManageBuyerInfoService.editBuyerInformation({
        addressAdditionalStreetName: vm.buyerDetails.addressAdditionalStreetName?vm.buyerDetails.addressAdditionalStreetName:'',
        addressCityName: vm.buyerDetails.addressCityName?vm.buyerDetails.addressCityName:'',
        addressCompanyId: vm.buyerDetails.addressCompanyId?vm.buyerDetails.addressCompanyId:'',
        addressContactEmail: vm.buyerDetails.addressContactEmail?vm.buyerDetails.addressContactEmail:'',
        addressContactTelephone: vm.buyerDetails.addressContactTelephone?vm.buyerDetails.addressContactTelephone:'',
        addressContactName: vm.buyerDetails.addressContactName?vm.buyerDetails.addressContactName:'',
        addressCountryCode: vm.buyerDetails.addressCountryCode,
        addressOrganisationName: vm.buyerDetails.addressOrganisationName,
        addressPostalZone: vm.buyerDetails.addressPostalZone?vm.buyerDetails.addressPostalZone:'',
        addressStreetName: vm.buyerDetails.addressStreetName?vm.buyerDetails.addressStreetName:'',
        isoCode: vm.buyerDetails.isoCode,
        receiverKey: vm.buyerDetails.receiverKey,
        id: vm.buyerId,
        addressId: vm.buyerDetails.addressId
      }).
        then(function (responseData) {
          $state.go('ezyap.'+vm.userRole.pretty+'.buyer-info');
          NotificationService.success({
            title: 'global.customerInformation',
            message: 'alertMessage.UPDATED_SUCCESSFULLY'
          });
        }, function (error) {
          NotificationService.error({
            title: 'global.customerInformation',
            error: error
          });
        });
    }

    /**
     * [get buyer information by id]
     * @param {Integer} buyerId [id of the buyer]
     */
    function getBuyerInformationById(buyerId) {
      ManageBuyerInfoService.getBuyerInformationById(buyerId).
        then(function (response) {
          vm.buyerDetails = response.data;
          vm.isoCode = getAppendedValues();
        }, function (error) {
          NotificationService.error({
            title: 'global.customerInformation',
            error: error
          });
        });
    }

    /**
     * [getAppendedValues Returns concantinated values]
     */
    function getAppendedValues(){
      return vm.buyerDetails.isoCode + ' : ' + vm.buyerDetails.receiverKey;
    }
  }
})();
