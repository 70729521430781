/*
 * @Author: Jayalekshmi 
 * @Date: 2020-02-24 15:05:10 
 * @Project: Ezyprocure
 * @Filename: buyer.item.allocations.controller.js
 * @Last Modified by: chenjie
 * @Last Modified time: 2020-02-28 15:46:38
 */




(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerItemAllocationController', BuyerItemAllocationController);

  BuyerItemAllocationController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'NotificationService', '$mdDialog', 'pagingParams', 'BuyerItemMapService', 'paginationConstants'];

  function BuyerItemAllocationController($scope, $rootScope, $state, $stateParams, NotificationService, $mdDialog, pagingParams, BuyerItemMapService, paginationConstants) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.itemId = $stateParams.itemId;
    vm.init = init;
    vm.save = save;
    vm.loadAll = loadAll;
    vm.buyerLimitedChanged = buyerLimitedChanged;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function loadAll() {
      BuyerItemMapService.getAdhocItemBuyerMappingsByItemId({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort()
      }, vm.itemId).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.itemBuyerMappings = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'Item Buyer Mappings By ItemId Fetching',
          error: error
        });
      });
    }

    function save(itemBuyerMapping) {
      var itemBuyerMappingDTO = {
        supplierId: itemBuyerMapping.supplierId,
        itemBusinessMappingId : itemBuyerMapping.itemBusinessMappingId,
        buyerBusinessId:itemBuyerMapping.buyerBusinessId,
        id :vm.itemId,
        buyerLimited : itemBuyerMapping.buyerLimited,
        maximumAllocation: itemBuyerMapping.maximumAllocation,
        price : itemBuyerMapping.price,
      }
      
      BuyerItemMapService.updateAdhocItemBuyerMapping(itemBuyerMappingDTO).then(function(response) {
        $scope.$emit('ezyprocureApp:supplierItemBuyerAllocationUpdate', response);
        NotificationService.success({
          title: 'Item Buyer Allocation',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'Item Buyer Allocation',
          error: error
        });
      });
    }

    function init() {
      vm.loadAll();
    }
    
    /**
     * [buyerLimitedChanged Changed the buyer Limite]
     * @param {Object} buyerLimited 
     */
    function buyerLimitedChanged(itemBuyerMapping){
      if(!itemBuyerMapping.buyerLimited){
        itemBuyerMapping.maximumAllocation = null;
      }
      
    }


  }
})();
