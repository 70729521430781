/**
 * @Author: Ajay
 * @Date:   05-May-2017 11:36 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.user-management.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 01-Jun-2017 13:02 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('buyer.tools.user-management', {
      parent: 'buyer.tools',
      url: '/user-management?page&sort&search',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'ezyprocureApp.buyerUser.home.title'
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/tools/user-management/buyer.user-management.html',
          controller: 'BuyerUserListController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('buyerUser');
          $translatePartialLoader.addPart('user-management');
          return $translate.refresh();
        }]
      }
    })
    .state('buyer.tools.user-management.add', {
      parent: 'buyer.tools.user-management',
      url: '/add',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'ezyprocureApp.buyerUser.home.createLabel'
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/tools/user-management/add/buyer.user-management.add.html',
          controller: 'BuyerUserAddController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    })
    .state('buyer.tools.user-management.edit', {
      parent: 'buyer.tools.user-management',
      url: '/edit/:buyerUserId',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'ezyprocureApp.buyerUser.home.editLabel'
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/tools/user-management/edit/buyer.user-management.edit.html',
          controller: 'BuyerUserEditController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('user-management');
          return $translate.refresh();
        }]
      }
    });
  }
})();
