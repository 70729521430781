/**
* @Author: Ajay
* @Date:   27-Mar-2017 13:16 +05:30
* @Project: Ezyprocure
* @Filename: manage-suppliers.service.js
 * @Last modified by:   Jayalekshmi R
 * @Last modified time: 2018-06-20T16:22:05+05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('ManageSupplierService', ManageSupplierService);

  ManageSupplierService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS', 'AuthServerProvider'];

  function ManageSupplierService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS, AuthServerProvider) {

    var manageSupplierService = {
      getAllAdminSuppliers: getAllAdminSuppliers,
      getAdminSuppliers: getAdminSuppliers,
      getAllAdminSuppliersWithUserDetails: getAllAdminSuppliersWithUserDetails,
      getAdminSupplier: getAdminSupplier,
      createAdminSupplier: createAdminSupplier,
      createBuyerSupplier: createBuyerSupplier,
      updateAdminSupplier: updateAdminSupplier,
      updateSupplierActivatedKey: updateSupplierActivatedKey,
      updateASVisibilityType: updateASVisibilityType,
      deleteAdminSupplier: deleteAdminSupplier,
      supplierBulkUploadCSV: supplierBulkUploadCSV,
      supplierKycUpload : supplierKycUpload,
      getPdfUrl : getPdfUrl,
      updateKycStatus : updateKycStatus,
      getAllAdhocSuppliers : getAllAdhocSuppliers,
      isContractedSupplierForBuyer : isContractedSupplierForBuyer,
      getAllSuppliersByBusinessOutlets : getAllSuppliersByBusinessOutlets,
      enableDisabledBuyerSupplier:enableDisabledBuyerSupplier,
      getTransactionalCurrency: getTransactionalCurrency,
      getConsolidatedGiroAccessSetSuppliers: getConsolidatedGiroAccessSetSuppliers
    };
    return manageSupplierService;

    /* --  AdminSupplier CRUID operations  -- */

    function getConsolidatedGiroAccessSetSuppliers(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'axs/suppliers/all',
        method: "GET",
        params: params,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get All Admin suppliers
    function getAllAdminSuppliers(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers/all/visibility-type',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get Admin suppliers - Paginated
    function getAdminSuppliers(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers/visibility-type',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get All Admin suppliers with User Details
    function getAllAdminSuppliersWithUserDetails(urlParams) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers',
        method: "GET",
        params: urlParams,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get supplier details using supplier id
    function getAdminSupplier(supplierId) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers/' + supplierId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Create a new Admin supplier
    function createAdminSupplier(supplier) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers', supplier).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function createBuyerSupplier(supplier,visibilityType) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers/' + visibilityType, supplier).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update Admin supplier
    function updateAdminSupplier(supplier) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers/', supplier).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //update Admin Supplier Activated Key
    function updateSupplierActivatedKey(supplierId, isActivate) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers/'+supplierId+'/'+isActivate).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update Admin supplier Visibility Type
    function updateASVisibilityType(selectedSupplier) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers/visibility-type/', selectedSupplier).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Soft Delete Admin supplier using supplierId
    function deleteAdminSupplier(supplierId) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers/' + supplierId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Bulk Upload of Suppliers
    function supplierBulkUploadCSV(uploadData) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers/upload', uploadData).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function supplierKycUpload(data, params){
      var deferred = $q.defer();
      $http({
        url:API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'kyc-upload',
        method:'POST',
        params:params,
        data:data
      }).then(function(data){
        deferred.resolve(data.data);
      }).catch(function(message){
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get Url for Pdf Preview
    function getPdfUrl(params){
      var deferred = $q.defer();
      $http({
          url:API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'kyc-search-report',
          method:'POST',
          params:params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message){
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //updates Kyc Status
    function updateKycStatus(params){
      var deferred = $q.defer();
      $http({
          url:API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'update-kyc-status',
          method:'POST',
          params:params,
      }).then(function(data){
       deferred.resolve(data);
      }).catch(function(message){
       deferred.reject(message);
      });
      return deferred.promise;
    }

    //check whether the logged in supplier is a contracted supplier
    function isContractedSupplierForBuyer(requestData,choice) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion +  RESOURCE_URL_CONSTANTS.SUPPLIER_RESOURCE+'is-contracted-supplier/'+choice,
        method: "POST",
        data: requestData,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * Get Adhoc suppliers - Paginated
     * @param {Object} params 
     */
    function getAllAdhocSuppliers(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.SUPPLIER_RESOURCE +'adhoc',
        method: "GET",
        params: params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getAllSuppliersByBusinessOutlets Gets all Suppliers based on business Outlets]
     */
    function getAllSuppliersByBusinessOutlets(businessOutlets) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUSINESS_OUTLETS_RESOURCE + 'supplier',
        method: "POST",
        data: businessOutlets,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getTransactionalCurrency(id) {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http.get(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.TRANSACTIONAL_CURRENCIES +'/'+id,
      {
        headers: { Authorization: 'Bearer ' + cbAuthToken }
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function enableDisabledBuyerSupplier(buyerId,supplierId) {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http.get(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ENABLEDDISABLEDBUYERSUPPLIER +buyerId+'/supplier/'+supplierId,
      {
        headers: { Authorization: 'Bearer ' + cbAuthToken }
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
