/**
 * @Author: Ajay
 * @Date:   04-Dec-2017 10:19 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.item.edit.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 05-Apr-2018 14:43 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerItemEditController', BuyerItemEditController);

  BuyerItemEditController.$inject = ['$scope', '$state', '$stateParams', 'ImageUploadService', 'BuyerItemService', 'UomService', 'ItemGroupService', 'paginationConstants', 'NotificationService', 'GLOBAL_CONSTANTS','BuyerDataService'];

  function BuyerItemEditController($scope, $state, $stateParams, ImageUploadService, BuyerItemService, UomService, ItemGroupService, paginationConstants, NotificationService, GLOBAL_CONSTANTS,BuyerDataService) {
    var vm = this;

    vm.itemId = $stateParams.itemId;
    vm.init = init;
    vm.loadUoms = loadUoms;
    vm.loadItemGroups = loadItemGroups;
    vm.getItemData = getItemData;
    vm.save = save;
    vm.setCompanyLogo = setCompanyLogo;
    vm.uploadLogo = uploadLogo;
    vm.currencies = GLOBAL_CONSTANTS.CURRENCIES;
    vm.validateMOQ = validateMOQ;
    vm.checkWeightRequired = checkWeightRequired;
    vm.isWeightRequired;
    vm.accessForBuyerItemCodeFeatures = BuyerDataService.getBuyerProfile().accessDTO.accessForBuyerItemCodeFeatures;
    vm.getBuyerAdhocItemsById = getBuyerAdhocItemsById;

    function save() {
        vm.itemBuyerDTOObj = {
            buyerItemCode: vm.itemDTOObj.buyerItemCode,
            buyerItemName: vm.itemDTOObj.buyerItemName,
            buyerItemGroup: vm.itemDTOObj.buyerItemGroup,
        }
      //  vm.itemDTOObjchange = _.omit(vm.itemDTOObj,['buyerItemCode','buyerItemName','buyerItemGroup']);
      BuyerItemService.updateBuyerItem(vm.itemDTOObj.item,vm.itemBuyerDTOObj).then(function(response) {
        $scope.$emit('ezyprocureApp:buyerItemUpdate', response);
        NotificationService.success({
          title: 'Item',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        $state.go('buyer.adhoc-tools.items', {
          reload: false
        });
      }, function(error) {
        NotificationService.error({
          title: 'Item Updation',
          error: error
        });
      });
    }

    function getItemData() {
      BuyerItemService.getBuyerItem(vm.itemId).
      then(function(response) {
        if (response) {
          response.price = response.price ? response.price.toFixed(4) : response.price;
          vm.itemDTOObj = response;
          vm.uomId = vm.itemDTOObj.uom.id
          vm.validateMOQ();
        }
      }, function(error) {
      });
    }

    function getBuyerAdhocItemsById() {
        BuyerItemService.getBuyerAdhocItemsById(vm.itemId).
        then(function(response) {
          if (response) {
            response.item.price = response.item.price ? response.item.price.toFixed(4) : response.item.price;
            vm.itemDTOObj = response;
            vm.uomId = vm.itemDTOObj.item.uom.id
            vm.validateMOQ();
          }
        }, function(error) {
        });
      }

    //IMAGE UPLOAD

    function setCompanyLogo(flowFile) {
      ImageUploadService.getImageBase64(flowFile.file, function(base64Img) {
        vm.uploadLogo(base64Img);
      });
    }

    function uploadLogo(base64Img) {
      vm.uploadingInProgress = true;
      ImageUploadService.uploadImage(base64Img).
      then(function(responseData) {
        vm.itemDTOObj.item.image = responseData.url;
        $scope.editItemForm.$pristine = false;
        NotificationService.success({
          title: 'Item Image uploaded successfully',
          message: 'Please update to save changes!'
        });
        vm.uploadingInProgress = false;
      }, function(error) {
        vm.uploadingInProgress = false;
        NotificationService.error({
          title: 'Item Image Upload',
          error: error
        });
      });
    }

    function loadUoms() {
      UomService.getAllUoms({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.uoms = response.data;
        vm.checkWeightRequired(vm.uomId);
      }, function(error) {
      });
    }

    function loadItemGroups() {
      ItemGroupService.getAllItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.itemGroups = response.data;
      }, function(error) {
      });
    }

    function init() {
      //vm.getItemData();
      vm.getBuyerAdhocItemsById();
      vm.loadUoms();
      vm.loadItemGroups();
    }

    /**
     * [validateMOQ Validates the MOQ with Multiplier]
     */
    function validateMOQ(){
      if(vm.itemDTOObj.item.multiplierFactor >0){
        if (((vm.itemDTOObj.item.moq*100) % (vm.itemDTOObj.item.multiplierFactor*100))/100 != 0) {
            $scope.editItemForm.moq.$setTouched();
            $scope.editItemForm.moq.$setValidity("moq", false);
        } else {
            $scope.editItemForm.moq.$setValidity("moq", true);
        }
      }
      else {
        $scope.editItemForm.moq.$setValidity("moq", true);
      }
    }
    function checkWeightRequired(uom){
        vm.isWeightRequired = _.find(vm.uoms, function(uomelement) {
            if(uomelement.id === uom) {
                return uomelement.weightRequired;
            }
        });
    }
  }
})();
