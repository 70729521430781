/**
 * @Author: Ajay
 * @Date:   29-Jun-2018 10:47 +05:30
 * @Project: Ezyprocure
 * @Filename: ezypayment.admin.brand-supplier-payment-model-mapping.controller.js
 * @Last modified by:   Allan
 * @Last modified time: 09-October-2018 12:10 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzypaymentAdminBrandSupplierPaymentModelMapController', EzypaymentAdminBrandSupplierPaymentModelMapController);

  EzypaymentAdminBrandSupplierPaymentModelMapController.$inject = ['$scope', '$rootScope' ,'$state', '$stateParams', 'toastr', 'pagingParams', 'EzypaymentBrandSupplierPaymentModelMapService', 'paginationConstants', 'NotificationService', 'GLOBAL_CONSTANTS'];

  function EzypaymentAdminBrandSupplierPaymentModelMapController($scope, $rootScope, $state, $stateParams, toastr, pagingParams, EzypaymentBrandSupplierPaymentModelMapService, paginationConstants, NotificationService, GLOBAL_CONSTANTS) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.sort = sort;
    vm.loadAll = loadAll;
    vm.mappedItemSearch = '';
    vm.deleteBrandSupplierPaymentModelMapping = deleteBrandSupplierPaymentModelMapping;
    vm.paymentModels = GLOBAL_CONSTANTS.PAYMENT_MODELS;
    vm.mapping = {};

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    // Load All supplier items which are mapped
    function loadAll(search) {
      if (!search) {
        vm.mappedItemSearch = '';
      }
      EzypaymentBrandSupplierPaymentModelMapService.getAllBrandSupplierPaymentModelMappings({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.mappedItemSearch,
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.brandSupplierPaymentModelMappings = response.data;
      },function(error){
        NotificationService.error({
          title: 'Mapped Brand Supplier Payment Model Listing',
          error: error
        });
      });
    }

    vm.loadAll();

    function deleteBrandSupplierPaymentModelMapping(mapping) {
      EzypaymentBrandSupplierPaymentModelMapService.deleteBrandSupplierPaymentModelMapping(mapping).then(function(response){
        $scope.$emit('ezyprocureApp:bsUnmapped', mapping);
        NotificationService.success({
          title: 'Brand Supplier Payment Model',
          message: 'Mapping deleted successfully'
        });
        vm.loadAll();
      },function(error) {
        NotificationService.error({
          title: 'Brand Supplier Payment Model - Delete Mapping',
          error: error
        });
      });
    }

  }
})();
