/**
* @Author: Allan
* @Date:   09-October-2018 13:10 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.admin.navbar.controller.js
* @Last modified by:   allan
* @Last modified time: 09-October-2018 13:10 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzyPaymentAdminNavbarController', EzyPaymentAdminNavbarController);

  EzyPaymentAdminNavbarController.$inject = ['$rootScope', '$window', 'Auth'];

  function EzyPaymentAdminNavbarController ($rootScope, $window, Auth) {
    var vm = this;
    vm.logout = logout;
    $rootScope.back = back;

    function logout() {
      Auth.logout();
    }

    function back() {
      $window.history.back();
    }

  }
})();
