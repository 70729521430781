/*
 * @Author: Gayathri.J.L 
 * @Date: 2019-06-26 10:09:26 
 * *@Project: Ezyprocure
 * @Filename: ezyap.invoice.service.js
 * @Last Modified by: Gayathri.J.L
 * @Last Modified time: 2019-07-01 15:02:05
 */

(function () {
    'use strict';
  
    angular.module('ezyprocureApp').factory('EzyapInvoiceService', EzyapInvoiceService);
  
    EzyapInvoiceService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
    function EzyapInvoiceService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {
      var EzyapInvoiceService = {
        getInvoiceDetails : getInvoiceDetails
      }
      return EzyapInvoiceService;
  
      
      /**
       * [get Invoice Details]
       * @param {Integer} invoiceId [Id of Invoice]
       */
      function getInvoiceDetails(invoiceId) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/ezyprocure/invoices/'+invoiceId,
          method: "GET"
        }).then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
    }
})();    