/**
 * @Author: sneha
 * @Date:   2019-07-22T15:21:18+05:30
 * @Project: Ezyprocure
 * @Filename: ezypayment.sip-upload-invoices.state.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-12-09T16:23:23+05:30
 */


 (function() {
   'use strict';

   angular
   .module('ezyprocureApp')
   .config(stateConfig);

   stateConfig.$inject = ['$stateProvider'];

   function stateConfig($stateProvider) {
     $stateProvider.state('ezypayment.supplier.supplier-invoice-upload', {
       parent: 'ezypayment.supplier',
       url: '/sip-invoice-upload',
       data: {
         authorities: ['ROLE_SUPPLIER']
       },
       views: {
         'content@': {
           templateUrl: 'app/ezypayment-modules/supplier-modules/sip-upload-invoices/ezypayment.sip-upload-invoices.html',
           controller: 'EzypaymentSIPInvoiceUploadController',
           controllerAs: 'vm'
         }
       },
       resolve: {
         translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
           $translatePartialLoader.addPart('global');
           $translatePartialLoader.addPart('buyerDashboard');
           $translatePartialLoader.addPart('buyerBusiness');
           $translatePartialLoader.addPart('items');
           return $translate.refresh();
         }]
       }
     })
   }
 })();
