/**
 * @Author: elsa
 * @Date:   19-Apr-2017 18:33 +05:30
 * @Project: Ezyprocure
 * @Filename: supplier.requirement-pending-po.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 18-Dec-2017 10:51 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierRequirementPendingPOController', SupplierRequirementPendingPOController);

  SupplierRequirementPendingPOController.$inject = ['$scope', '$rootScope', '$timeout', '$state', 'toastr', '$mdDialog', '$filter', 'SupplierProcessOrderService', 'pagingParams', 'paginationConstants', 'GLOBAL_CONSTANTS'];

  function SupplierRequirementPendingPOController($scope, $rootScope, $timeout, $state, toastr, $mdDialog, $filter, SupplierProcessOrderService, pagingParams, paginationConstants, GLOBAL_CONSTANTS) {

    var vm = this;
    vm.loadAll = loadAll;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;

    /**
     * [sort to sort by PO date]
     */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
     * [loadAll loads all process orders with status REQUIREMENT_PENDING_PO]
     * @param  {[search ]} search [search]
     */
    function loadAll(search) {
      if (!search) {
        vm.searchText = '';
      }
      SupplierProcessOrderService.getAllProcessOrdersByStatus({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        status: 'REQUIREMENT_PENDING_PO',
        query: vm.searchText,
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.requirementPendingPOs = response.data;
      }, function(error) {
      });
    }

    vm.loadAll();

  }
})();
