/*
 * @Author: Lovin Chacko 
 * @Date: 2021-01-06 14:36:29 
 * @Last Modified by: Lovin Chacko
 * @Last Modified time: 2021-01-22 15:43:36
 */



(function() {
    'use strict';

    angular
    .module('ezyprocureApp')
    .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
      $stateProvider
      .state('ezypayment.admin.axs-payments', {
        parent: 'ezypayment.admin',
        url: '/axs-payments',
        data: {
          authorities: ['ROLE_ADMIN'],
          pageTitle: 'AXS Payments'
        },
        views: {
          'content@': {
            templateUrl: 'app/ezypayment-modules/admin-modules/axs-payments/ezypayment.admin.axs-payments.html',
            controller: 'EzypaymentAdminAXSPayments',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,asc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('brand');
            $translatePartialLoader.addPart('purchaseOrder');
            $translatePartialLoader.addPart('bank');
            $translatePartialLoader.addPart('paymentTerms');
            $translatePartialLoader.addPart('buyer');
            return $translate.refresh();
          }]
        }
      })

    }

  })();
