/**
 * @Author: Ajay
 * @Date:   15-May-2018 13:04 +05:30
 * @Project: Ezyprocure
 * @Filename: ezypayment.buyer.epd-approval-request.state.js
 * @Last modified by:   Allan
 * @Last modified time: 27-August-2018 15:28 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('ezypayment.buyer.epd-approval-request', {
      parent: 'ezypayment.buyer',
      url: '/epd-approval-request',
      data: {
        authorities: ['ROLE_BUYER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/ezypayment-modules/buyer-modules/epd-approval-request/ezypayment.buyer.epd-approval-request.html',
          controller: 'EzypaymentBuyerEpdApprovalRequestController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,desc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('epdApprovalRequest');
          $translatePartialLoader.addPart('epdApprovalStatus');
          return $translate.refresh();
        }],
        hasModuleAccess: ['Auth','$q', '$state', function(Auth, $q, $state) {
          if(Auth.hasPermission('accessForPayViaUOBVPS')){
            return true;
          }else {
            return $q.reject('Unauthorized');
          }
        }]
      }
    });

  }

})();
