/**
 * @Author: Ajay
 * @Date:   21-Apr-2017 17:54 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.cart-checkout.state.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-04-08T15:06:31+05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('buyer.cart-checkout', {
      parent: 'buyer',
      url: '/cart-checkout/:purchaseMethod/:templateId',
      data: {
        authorities: ['ROLE_BUYER']
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/cart-checkout/buyer.cart-checkout.html',
          controller: 'BuyerCartCheckoutController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('cart');
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('currency');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('supplier');
          return $translate.refresh();
        }]
      }
    })
    .state('buyer.cart-checkout-mobile', {
      parent: 'buyer',
      url: '/cart-checkout-mobile',
      data: {
        authorities: ['ROLE_BUYER']
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/cart-checkout/cart-checkout-mobile/buyer.cart-checkout-mobile.html',
          controller: 'BuyerCartCheckoutController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('cart');
          $translatePartialLoader.addPart('currency');
          $translatePartialLoader.addPart('glCodeMappings');
          return $translate.refresh();
        }]
      }
    })
  }
})();
