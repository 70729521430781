/**
* @Author: sneha
* @Date:   10-Jan-2018 17:35 +05:30
* @Project: Ezyprocure
* @Filename: admin.transaction-volume.service.js
 * @Last modified by:   sneha
 * @Last modified time: 16-Jan-2018 15:36 +05:30
*/


(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('AdminTransactionVolumeService', AdminTransactionVolumeService);

  AdminTransactionVolumeService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function AdminTransactionVolumeService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var adminTransactionVolumeService = {
      getTransactionVolumeSummary: getTransactionVolumeSummary,
    };

    return adminTransactionVolumeService;

    /* --  Admin Transaction Volume Summary -- */

    //Get Buyer Transaction Volume Summary
    function getTransactionVolumeSummary(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' +  API_CONSTANTS.baseVersion + 'admin/transaction-volume',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
