/**
* @Author: sneha
* @Date:   2019-01-24T16:55:49+05:30
* @Project: Ezyprocure
* @Filename: supplier.sftp-details.manage.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-02-21T15:22:39+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SFTPDetailsController', SFTPDetailsController);

  SFTPDetailsController.$inject = ['$scope', 'Auth', 'BuyerDataService', 'paginationConstants', 'NotificationService', 'SupplierDataService', 'SupplierSFTPDetailsService', 'BuyerSupplierMappingService','$stateParams','$state'];

  function SFTPDetailsController($scope, Auth, BuyerDataService, paginationConstants, NotificationService, SupplierDataService, SupplierSFTPDetailsService, BuyerSupplierMappingService,$stateParams, $state) {
    var vm = this;
    vm.init = init;
    vm.userRole = Auth.getUserRole();
    vm.supplierId = vm.userRole.pretty === 'supplier'? SupplierDataService.getSupplierProfile().supplier.id : null;
    vm.buyerId = vm.userRole.pretty === 'buyer'? BuyerDataService.getBuyerProfile().buyerUser.buyer.id: null;
    vm.getAllSFTPDetails = getAllSFTPDetails;
    vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
    vm.filterUser = filterUser;
    vm.directtosftp=directtosftp;
    vm.redirecttosftp=redirecttosftp;
    vm.redirectToHome=redirectToHome;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.module=$stateParams.module;
    vm.getAllBuyerMappedSuppliers = getAllBuyerMappedSuppliers;

    function init(){
      if(vm.userRole.pretty == 'supplier'){
        vm.getAllSupplierMappedBuyers();
      }else if(vm.userRole.pretty == 'buyer'){
        vm.getAllBuyerMappedSuppliers();
      }
    }

    /**
    * [getAllSFTPDetails get SFTP details by suppleirID]
    * @return {[type]} [description]
    */
    function getAllSFTPDetails(){
      SupplierSFTPDetailsService.getAllSFTPDetails({
        page: vm.query.page - 1,
        size: vm.query.limit},{
        supplierId: vm.supplierId,
        buyerId: vm.buyerId,
        module:vm.module,
        accountType : vm.userRole.pretty.toUpperCase()
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.sftpDetails = response.data;
        //add key buyerName for adding BuyerName to be displayed in the SFTP details table
        if(vm.module!='PEPPOL'){
        _.each(vm.sftpDetails, function(element, index) {
          _.extend(element,
            vm.userRole.pretty == 'supplier'?
            {buyerName: vm.filterUser(element.buyerId, vm.buyers)}:{supplierName: vm.filterUser(element.supplierId, vm.suppliers)}
            )
          });
        }
        }, function(error) {
          NotificationService.error({
            title: 'global.navbar.SFTPDetails',
            error: error
          });
        });
      }

      /**
      * [getAllSupplierMappedBuyers get all buyers mapped to the logged in supplier]
      * @return {[type]} [description]
      */
      function getAllSupplierMappedBuyers(){
        var params = {
          page: 0,
          size: paginationConstants.maxLimit,
        };
        if(vm.buyers){
          return false;
        }
        BuyerSupplierMappingService.getAllSupplierMappedBuyers(vm.supplierId, params).then(function(response) {
          vm.buyers = response.data;
          vm.getAllSFTPDetails();
        }, function(error) {
        });
      }

      /**
      * [getAllBuyerMappedSuppliers get all suppliers mapped to the logged in buyer]
      * @return {[type]} [description]
      */
     function getAllBuyerMappedSuppliers(){
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
      };
      if(vm.suppliers){
        return false;
      }
      BuyerSupplierMappingService.getAllBuyerMappedSuppliers(vm.buyerId, params).then(function(response) {
        vm.suppliers = response.data;
        vm.getAllSFTPDetails();
      }, function(error) {
      });
    }

      /**
      * [filterUser filter out the buyer according to buyerId]
      * @param  {[Integer]} userId [description]
      */
      function filterUser(userId, userDetails){
        var user = _.find(userDetails, ['id', userId]);
        return user.companyName;
      }

      function directtosftp(){
        switch(vm.module){
          case 'PEPPOL':
          $state.go('ezyap.'+ vm.userRole.pretty +'.sftp-details.add');
          break;
        case 'EZYPROCURE':
          $state.go(vm.userRole.pretty + '.tools.sftp-details.add');
          break;
        }
      }

      /**
       * [redirecttosftp Redirects to edit sftp]
       * @param {Integer} sftpId 
       */
      function redirecttosftp(sftpId){
        switch(vm.module){
          case 'PEPPOL':
            $state.go('ezyap.'+ vm.userRole.pretty +'.sftp-details.edit',{sftpId:sftpId});
            break;
          case 'EZYPROCURE':
            $state.go(vm.userRole.pretty + '.tools.sftp-details.edit',{sftpId:sftpId});
            break;
        }
      }

      /**
       * [redirectToHome Redirects to Home]
       */
      function redirectToHome(){
        switch(vm.module){
          case 'PEPPOL':
            $state.go('ezyap.'+ vm.userRole.pretty +'.dashboard');
            break;
          case 'EZYPROCURE':
            $state.go(vm.userRole.pretty + '.dashboard');
            break;      
        }
      }
    }
  })();
