/**
 * @Author: Elsa
 * @Date:   2017-05-03T09:29:55+05:30
 * @Project: Ezyprocure
 * @Filename: buyer.cancel-po-request.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 07-Nov-2017 15:33 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerCancelPORequestController', BuyerCancelPORequestController);

  BuyerCancelPORequestController.$inject = ['$scope', '$rootScope', '$timeout', '$state', 'toastr', '$mdDialog', '$filter', 'pagingParams', 'paginationConstants', 'BuyerCancelPORequestService', 'GLOBAL_CONSTANTS'];

  function BuyerCancelPORequestController ($scope, $rootScope, $timeout, $state, toastr, $mdDialog, $filter, pagingParams, paginationConstants, BuyerCancelPORequestService, GLOBAL_CONSTANTS) {

    var vm = this;

    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.init = init;
    vm.loadAll = loadAll;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;

    /**
     * [sort to sort by PO date]
     */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function init() {
      vm.loadAll();
    }

    function loadAll() {
      BuyerCancelPORequestService.getCancelPORequests({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.searchText,
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.cancelPORequests = response.data;
      },function(error){
      });
    }
  }
})();
