/**
* @Author: Ajay
* @Date:   24-Feb-2017 12:40 +05:30
* @Project: Ezyprocure
* @Filename: buyer.user-management.edit.controller.js
 * @Last modified by:   allan
 * @Last modified time: 10-October-2018 10:14 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerUserEditController', BuyerUserEditController);

  BuyerUserEditController.$inject = ['$scope', '$state', '$stateParams', 'BuyerUserManagementService', 'UserAccessService', 'BuyerDataService', 'UserRoleService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'NotificationService','NotificationSettingsService'];

  function BuyerUserEditController ($scope, $state, $stateParams, BuyerUserManagementService, UserAccessService, BuyerDataService, UserRoleService, GLOBAL_CONSTANTS, paginationConstants, NotificationService,NotificationSettingsService) {
    var vm = this;

    vm.buyerUserId = $stateParams.buyerUserId;
    vm.buyerUser = {};
    vm.buyerUser.user = {};
    vm.save = save;
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.getUserRoles = getUserRoles;
    vm.getBuyerUser = getBuyerUser;
    vm.getUserAccessDetails = getUserAccessDetails;
    vm.updateBuyerUserActivatedKey = updateBuyerUserActivatedKey;

    vm.accessForEmailService = BuyerDataService.getBuyerProfile().accessDTO.accessForEmailService;
    vm.notificationTypes = GLOBAL_CONSTANTS.NOTIFICATION_SETTINGS_TYPES;
    vm.addNotificationType = addNotificationType;
    vm.notificationSettingsAdd = [];
    vm.updateNotificationType = updateNotificationType;
    vm.notificationSettingsUpdate = [];
    vm.getNotificationSettings = getNotificationSettings;
    vm.updateNotificationCheckboxUpdate = updateNotificationCheckboxUpdate;
    vm.buyerProfile = BuyerDataService.getBuyerProfile();
    function save () {
      vm.buyerUser.buyerId = vm.buyerProfile.buyerUser.buyer.id;
      // Call Buyer user management service to update with buyer user object as parameter
      BuyerUserManagementService.updateBuyerUser(vm.buyerUser).then(function(response){
        $scope.$emit('ezyprocureApp:buyerBusinessCreated', response);
        NotificationService.success({
          title: 'Buyer User',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        $state.go('buyer.tools.user-management', {reload: false});
      },function(error) {
        NotificationService.error({
          title: 'Buyer User Updation',
          error: error
        });
      });
    }

    function updateBuyerUserActivatedKey(buyerUserId, activated) {
      BuyerUserManagementService.updateBuyerUserActivatedKey(buyerUserId, {
        activated: activated
      }).then(function(response){
        NotificationService.success({
          title: 'Buyer User',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
      },function(error) {
        NotificationService.error({
          title: 'Buyer User Updation',
          error: error
        });
      });
    }

    function getBuyerUser() {
      //fetch buyer user details using bueyerUserId
      BuyerUserManagementService.getBuyerUser(vm.buyerUserId).
      then(function(response) {
        if (response) {
          vm.buyerUser = response;
          getUserAccessDetails(response);
          getNotificationSettings(response)
        }
      }, function(error) {
      });
    }

    function getUserRoles() {
      // Call UserRoleService to get all the roles created by the buyer
      UserRoleService.getAllUserRoles({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response){
        vm.userRoles = response.data;
      },function(error) {
        vm.userRoles = null;
      });
    }

    getUserRoles();
    getBuyerUser();

    //Retrieve selectes user's access details
    function getUserAccessDetails(buyerUser) {
      UserAccessService.getUserAccess(buyerUser.user.id).then(function(response) {
        vm.selectedUserAccess = response;
      }, function(error) {
        NotificationService.error({
          title: 'User Access Details',
          error: error
        });
      });
    }

    function getNotificationSettings(buyerUser){
     NotificationSettingsService.getNotificationSettings(buyerUser.user.id).
      then(function(response) {
        if (response) {
            vm.notificationSettingsAdd = response;
            vm.notificationSettingsAdd.forEach(function(nType,index){
                vm.notificationTypes = vm.notificationTypes.filter(function(e) { return e !== nType.notificationType})
            })
        }
      }, function(error) {
      });
    }


    function addNotificationType(notificationType, status){
       var notificationTypeIndex = vm.notificationTypes.indexOf(notificationType);
       if(notificationTypeIndex > -1){
        vm.notificationTypes.splice(notificationTypeIndex, 1);
       }

       vm.notificationSettingsForm.notificationType = null;
       vm.notificationSettingsForm.typeStatus = null;
        vm.notificationSettingsAdd.push({
            'notificationType': notificationType,
            'active': status,
            'user':vm.buyerUser.user
        });
    }

    function updateNotificationCheckboxUpdate(notificationSettings){
        if(notificationSettings){
            vm.notificationSettingsAdd.find(function(element) {
                return element.notificationType == notificationSettings.notificationType;
              }).active = notificationSettings.active;
            }
    }

    function updateNotificationType(){
        vm.notificationSettingsAdd.forEach(function(item,index){
            if(item.id){
            delete item.id
            }
        });
        NotificationSettingsService.updateNotificationSettings(vm.notificationSettingsAdd).
      then(function(response) {
        if (response) {
            vm.notificationSettingsAdd = response;
        }
      }, function(error) {
      });
    }

  }


})();
