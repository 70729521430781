/**
* @Author: Ajay
* @Date:   14-Dec-2016 12:54 +05:30
* @Project: Ezyprocure
* @Filename: admin.dashboard.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 01-Jun-2017 13:08 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('admin.dashboard', {
      parent: 'admin',
      url: '/dashboard',
      data: {
        authorities: ['ROLE_ADMIN']
      },
      views: {
        'content@': {
          templateUrl: 'app/admin-modules/dashboard/admin.dashboard.html',
          controller: 'AdminDashboardController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('adminDashboard');
          return $translate.refresh();
        }]
      }
    });
  }
})();
