/**
 * @Author: Jayalekshmi 
 * @Date: 2019-01-03 10:40:51 
 * @Project: Ezyprocure
 * @Filename: sats.to.confirm.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-07-04 14:45:38
 */



(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .controller('SatsTOConfirmController', SatsTOConfirmController);
  
    SatsTOConfirmController.$inject = ['paginationConstants','GLOBAL_CONSTANTS', '$q', '$state', '$stateParams', 'SatsManagementService', 'CsvUploadService', 'UtilService', 'NotificationService', 'CSVExportService', 'EXPORT_CONSTANTS', 'Auth'];
  
    function SatsTOConfirmController(paginationConstants, GLOBAL_CONSTANTS, $q , $state, $stateParams, SatsManagementService, CsvUploadService, UtilService, NotificationService, CSVExportService, EXPORT_CONSTANTS, Auth) {
      var vm = this;
      vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
      vm.loadAll = loadAll;
      vm.userRole = Auth.getUserRole();
      vm.toJobId = $stateParams.toJobId;
      vm.downloadCSVTOData = downloadCSVTOData;
      vm.uploadJobTO = uploadJobTO;
      vm.TOCSVHeader = EXPORT_CONSTANTS.CSV.supplierTOCSVHeader
      vm.resetValues = resetValues;
      vm.confirmTOJob = confirmTOJob;
      vm.getDateFormat = getDateFormat;

      /**
       * [getDateFormat gets the date string and converts]
       * @param {dateString} dateString 
       */
      function getDateFormat(dateString){
        var formatedDate = SatsManagementService.getDateFormat(dateString);
        return formatedDate;
      }

      /**
       * [Resets csv data and error data once click on browse button]
       */
      function resetValues(){
        vm.itemDetails = [];
        vm.recordError = [];
      }

      /**
       * [downloadCSVTOData Downloads the TO  to csv]
       */
      function downloadCSVTOData() {
        var deferred = $q.defer();
        if (vm.toDetails.lineItems.length < 1) {
          NotificationService.simpleErrorToast({
            title: 'global.table.noRecordsFound',
            message: 'alertMessage.SEARCH_AND_SUBMIT'
          });
          return deferred.promise;
        }
        return CSVExportService.getWarehouseTOCSVData(vm.toDetails);
      }

      /**
       * [uploadJobTO Uploads the job with details added]
       */
      function uploadJobTO(){
        vm.recordError = [];
        var csvFile = vm.files[0];
        if (!csvFile) {
          NotificationService.simpleErrorToast({
            title: 'alertMessage.INVALID_INPUT',
            message: 'global.pleaseChooseACsvFile'
          });
          return false;
        }
        vm.isCsvFile = CsvUploadService.checkFileType(csvFile.lfFile);
        if (vm.isCsvFile) {
          UtilService.csvToJson(csvFile.lfDataUrl)
          .then(function (response) {
            vm.itemDetails = response;
            if(vm.itemDetails.length <1){
              NotificationService.simpleErrorToast({
                title: 'alertMessage.INVALID_INPUT',
                message: 'alertMessage.FILL_CSV'
              });
            }
          })
         }
      }

      /**
       * [confirmTOJob Confirms the Job]
       */
      function confirmTOJob(){
          SatsManagementService.confirmTOJob(vm.files[0].lfFile, vm.toDetails.job.id)
          .then(function(response){
            NotificationService.success({
              'title': 'TO Job Confirmation',
              'message': 'alertMessage.SUCCESSFULLY_CONFIRMED'
            });
            $state.go(vm.userRole.pretty+'.sats', {
              reload: false
            });
          },function(error){
            if(error.data.message == 'error.validation'){
              vm.recordError = error.data;
            }
            NotificationService.error({
              title: 'error.validation',
              error: error
            });
          })
      }
  
      /**
      * [loadAll Gets Purchase Order Details]
      */
      function loadAll() {
        getJobDetailsById();
      }
      vm.loadAll();
      
      /**
       * [getJobDetailsById Gets TO details]
       */
      function getJobDetailsById() {
        SatsManagementService.getJobDetailsById(vm.toJobId,{
            page: 0,
            size: paginationConstants.maxLimit,
          }).then(function(response){
          vm.toDetails = response.data;
          vm.totalItems = response.headers('X-Total-Count');
        },function(error){
          NotificationService.error({
            title: 'global.sats.jobConfirmation',
            error: error
          });
        });
      }
    }
  })();
  