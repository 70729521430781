/**
* @Author: Elsa
* @Date:   08-Mar-2017 10:32 +05:30
* @Project: Ezyprocure
 * @Last modified by:   Ajay
 * @Last modified time: 18-Dec-2017 14:54 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BuyerGoodsReturnNoticeService', BuyerGoodsReturnNoticeService);

  BuyerGoodsReturnNoticeService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  function BuyerGoodsReturnNoticeService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var buyerGoodsReturnNoticeService = {
      getDebitNotesByGRNNumber : getDebitNotesByGRNNumber
    };
    return buyerGoodsReturnNoticeService;

    function getDebitNotesByGRNNumber(goodsReturnNumber) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE + 'debit-note/'+goodsReturnNumber,
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
