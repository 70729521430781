/**
* @Author: Mufsir
* @Date:   07-Feb-2017 19:12 +05:30
* @Project: Ezyprocure
* @Filename: admin.account.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 01-Jun-2017 13:10 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('admin.account', {
      abstract: true,
      parent: 'admin',
      url: '/account',
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          return $translate.refresh();
        }]
      }
    })
    .state('admin.account.change-password', {
      parent: 'admin.account',
      url: '/change-password',
      data: {
        authorities: ['ROLE_ADMIN']
      },
      views: {
        'content@': {
          templateUrl: 'app/admin-modules/account/admin.account.change-password.html',
          controller: 'AdminAccountController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('user-management');
          return $translate.refresh();
        }]
      }
    });
  }
})();
