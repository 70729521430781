/**
 * @Author: elsa
 * @Date:   2017-05-23T12:16:58+05:30
 * @Project: Ezyprocure
 * @Filename: supplier.return-order.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 07-Nov-2017 15:37 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierReturnOrderController', SupplierReturnOrderController);

  SupplierReturnOrderController.$inject = ['$scope', '$state', 'toastr', 'pagingParams', 'SupplierReturnOrderService', 'paginationConstants', 'GLOBAL_CONFIG_CONSTANTS', 'GLOBAL_CONSTANTS'];

  function SupplierReturnOrderController($scope, $state, toastr, pagingParams, SupplierReturnOrderService, paginationConstants, GLOBAL_CONFIG_CONSTANTS, GLOBAL_CONSTANTS) {

    var vm = this;
    vm.loadAll = loadAll;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.searchText = '';
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.invStatusClass = GLOBAL_CONFIG_CONSTANTS.INVOICE_STATUS.LABEL_CLASS;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;

    /**
     * [sort to sort by PO date]
     */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
     * [loadAll loads all return orders]
     * @param  {[search]} search [searchItem]
     */
    function loadAll(search) {
      if (!search) {
        vm.searchText = '';
      }
      SupplierReturnOrderService.getAllReturnOrders({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.searchText,
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.debitNotes = response.data;
      }, function(error) {
      });
    }

    vm.loadAll();

  }
})();
