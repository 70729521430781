/**
 * @Author: Ajay
 * @Date:   28-Jul-2017 15:53 +05:30
 * @Project: Ezyprocure
 * @Filename: login.controller.js
 * @Last modified by:   Allan
 * @Last modified time: 07-Aug-2017 15:19 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('ActivateController', ActivateController);

  ActivateController.$inject = ['Auth', '$stateParams','$state'];

  function ActivateController (Auth, $stateParams,  $state) {
    var vm = this;
    vm.initLanding = initLanding;

    function initLanding() {
      Auth.activateAccount($stateParams.key);
      $state.go('login');
    }

  }
})();
