/**
* @Author: Abhay
* @Date:   27-05-2021 08:48 IST
* @Project: Ezyprocure
* @Filename: ng-form-commit.directive.js
* @Last modified by:   Abhay
* @Last modified time: 27-05-2021 08:48 IST
*/

(function () {
    'use strict';

    angular
        .module('ezyprocureApp')
        .directive('ngFormCommit', commit);

    commit.$inject = [];

    function commit() {
        var directive = {
            require: "form",
            link: linkFunction
        };

        return directive;

        function linkFunction($scope, $el, $attr, $form) {
            $form.commit = function () {
                $el[0].submit();
            };
        }
    }
})();