/**
 * @Author: Allan
 * @Date:   Dec-2017 12:23 +05:30
 * @Filename: callback-resource.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 18-Jul-2018 11:00 +05:30
 */



 (function() {
   'use strict';

   angular.module('ezyprocureApp').factory('CallbackResourceService', CallbackResourceService);

   CallbackResourceService.$inject = ['$window','$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
   function CallbackResourceService($window, $http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {
    var xeroConnectionParams = {}; 
    var CallbackResourceService = {
       redirectUrl : redirectUrl,
       getAuthTimeout : getAuthTimeout, // initial check of token
       getTokenExpiry : getTokenExpiry,
       disconnectToXero : disconnectToXero,
       xeroCallback:xeroCallback,
       getXeroConnectionParams:getXeroConnectionParams,
       xeroConnect: xeroConnect
     };
     return CallbackResourceService;

     /**
     * [getAllPurchaseOrders gets PO list]
     * @param  {[type]} params [filter params]
     * @return {[object]}        [success/error message]
     * status = Success
     * status = failure
     * message = Xero's session expired. Please re-authorize to continue, Oauth Problem
     */
      function xeroConnect(params){
          var deferred = $q.defer(); 
          $http({
            url: API_CONSTANTS.serviceBase + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.XERO_RESOURCE + 'access-token/organization',
            method: "POST",
            params: params
          }).then(function(data) {
            var xero = {};
            if(data.data.status === 'Success' && data.data.message === "alertMessage.XERO.SUCCESS") {
              xero.conXero = false;
              xero.disconXero = true;
              xero.exportXero = true;
              xero.errors = data.data.errors[0];
              xero.data = data.data;
            } else {
              xero.conXero = true;
              xero.disconXero = false;
              xero.exportXero = false;
            }
            data = xero;
            deferred.resolve(data);
          }).catch(function(message) {
            deferred.reject(message);
          });

          return deferred.promise;
      }


     function redirectUrl(params) {

       var deferred = $q.defer();
       $http({
         url: API_CONSTANTS.serviceBase + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.XERO_RESOURCE + 'xero',
         method: "GET",
         responseType : "json",
         params : params,
       }).then(function(data) {
         deferred.resolve(data);
         var url = data.data['message'];
         window.location.href = url;
       }).catch(function(message) {
         deferred.reject(message);
       });

       return deferred.promise;
     }

     function getAuthTimeout(){
       var deferred = $q.defer(); 
       $http({
         url: API_CONSTANTS.serviceBase + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.XERO_RESOURCE + 'token',
         method: "GET",
         responseType : "json",
       }).then(function(data) {

         var xero = {};
         if(data.data.status === 'Success' && data.data.message === "alertMessage.XERO.TOKEN_VALID") {
           xero.conXero = false;
           xero.disconXero = true;
           xero.exportXero = true;
           xero.errors = data.data.errors[0];
         } else {
           xero.conXero = true;
           xero.disconXero = false;
           xero.exportXero = false;
         }
         data = xero;
         deferred.resolve(data);
       }).catch(function(message) {
         deferred.reject(message);
       });

       return deferred.promise;
     }

     function getTokenExpiry(){
       var deferred = $q.defer();
       $http({
         url: API_CONSTANTS.serviceBase + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.XERO_RESOURCE + 'token',
         method: "GET",
         responseType : "json",
       }).then(function(data) {

         var xero = {};
         if(data.data.status === 'failure' && (data.data.message === "alertMessage.XERO.TOKEN_EXPIRED" || data.message === "Oauth Problem")){

           xero.conXero = true;
           xero.disconXero = false;
           xero.exportXero = false;

         } else {
           xero.conXero = false;
           xero.disconXero = true;
           xero.exportXero = true;
         }

         data = xero;
         deferred.resolve(data);
       }).catch(function(message) {
         deferred.reject(message);
       });

       return deferred.promise;
     }

     function disconnectToXero() {
       var deferred = $q.defer();
       $http({
         url: API_CONSTANTS.serviceBase + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.XERO_RESOURCE + 'disconnect-xero',
         method: "GET",
         responseType : "json",
       }).then(function(data) {

         var xero = {};
         xero.conXero = true;
         xero.disconXero = false;
         xero.exportXero = false;

         data['xero'] = xero;
         deferred.resolve(data);
       }).catch(function(message) {
         deferred.reject(message);
       });

       return deferred.promise;
     }

     function xeroCallback(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.XERO_RESOURCE + 'access-token',
        method: "POST",
        params : params,
        responseType : "json"
      }).then(function(data) {
        var xero = {};
        if(data.data.status === 'Success' && data.data.message === "alertMessage.XERO.SUCCESS") {
          xero.conXero = false;
          xero.disconXero = true;
          xero.exportXero = true;
          xero.data = data;
          xero.errors = data.data.errors[0];
        } else {
          xero.conXero = true;
          xero.disconXero = false;
          xero.exportXero = false;
          xero.data = data;
        }
        data = xero;
        deferred.resolve(data);
      }).catch(function(message) {
        xeroConnectionParams.conXero = true;
        xeroConnectionParams.disconXero = false;
        xeroConnectionParams.exportXero = false;
        deferred.reject(message);
      });

      return deferred.promise;
    }

    /**
     * [getXeroConnectionParams Returns xero connection Parameters : conXero ,disconXero, exportXero]
     */
    function getXeroConnectionParams(){
      return xeroConnectionParams;
    }

   }
 })();
