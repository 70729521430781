/**
 * @Author: Ajay
 * @Date:   30-May-2017 10:43 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.price-update-request.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 01-Jun-2017 12:48 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('buyer.price-update-request', {
      parent: 'buyer',
      url: '/price-update-request',
      data: {
        authorities: ['ROLE_BUYER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/price-update-request/buyer.price-update-request.html',
          controller: 'BuyerPriceUpdateRequestController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,desc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('priceUpdates');
          return $translate.refresh();
        }],
        hasModuleAccess: ['Auth','$q', '$state', function(Auth, $q, $state) {
          if(Auth.hasPermission('accessForPriceUpdate')){
            return true;
          }else {
            return $q.reject('Unauthorized');
          }
        }]
      }
    });

  }

})();
