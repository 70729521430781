/**
* @Author: sneha
* @Date:   16-Apr-2018 12:19 +05:30
* @Project: Ezyprocure
* @Filename: supplier.picking-list.edit-template.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 11-May-2018 12:39 +05:30
*/


(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierPickingListEditController', SupplierPickingListEditController);

  SupplierPickingListEditController.$inject = ['$scope', '$state', '$stateParams', '$mdDialog', 'toastr', 'SupplierPickingListService', 'NotificationService'];

  function SupplierPickingListEditController($scope, $state , $stateParams, $mdDialog, toastr, SupplierPickingListService, NotificationService) {
    var vm = this;
    vm.templateId = $stateParams.templateId; //id of the template to be edited
    vm.getListItems = getListItems;
    vm.reset = reset;
    vm.saveTemplate = saveTemplate;
    vm.deleteItem = deleteItem;
    vm.editTemplateForm = {};
    vm.getPickingListById = getPickingListById;
    //defining callbacks for picking list container
    vm.pickingListSortOptions = {
      containment: '#horizontal-container',
      containerPositioning: 'relative',
      orderChanged: function (event){
        vm.editTemplateForm.$pristine = false;
      }
    };
    /**
    * [getListItems get selected items for picking list template from service]
    */
    function getListItems(){
      vm.templateList = SupplierPickingListService.getListItems();
      vm.originalTemplateList= angular.copy(vm.templateList);
      vm.getPickingListById();
    }

    /**
    * [reset resets all items to original position]
    */
    function reset(){
      vm.templateList = angular.copy(vm.originalTemplateList);
    }

    /**
    * [getPickingListById get picking list by id and select all corresponding supplier items]
    * @return {[type]} [description]
    */
    function getPickingListById(){
      SupplierPickingListService.getPickingListById(vm.templateId).then(function(response) {
        var originalList = response;
        if(originalList.items.length != vm.templateList.items)
         vm.editTemplateForm.$pristine = false;
      }, function(error) {
        NotificationService.error({
          title: 'Picking List',
          error: error
        });
      });
    }

    /**
    * [saveTemplate save picking list template]
    */

    function saveTemplate(){
      //minimum number of items should be greater than 6
      if(vm.templateList.items.length < 6)
       toastr.error('Please select atleast 6 items to proceed!', 'Items Required');
       else {
      //set as default template
      vm.templateList.pickingList.isDefault = vm.templateList.pickingList.isDefault ? vm.templateList.pickingList.isDefault : false;
      vm.templateList.items = _.map(vm.templateList.items,  function(item){
        return {
          'id' : item.id,
          'itemName': item.itemName,
        }
      });
      SupplierPickingListService.editPickingList(vm.templateList).then(function(response) {
        NotificationService.success({
          title: 'Picking List',
          message: 'Changed successfully'
        });
        $state.go('supplier.picking-list-management.picking-list', {reload:false});
      }, function(error) {
        NotificationService.error({
          title: 'Picking List',
          error: error
        });
      });
    }
    }

    /**
    * [deleteItem delete item from picking list template]
    * @param  {[type]} itemId [id of item to be deleted from template list]
    */
    function deleteItem(itemId){
      vm.templateList.items= angular.copy( _.reject(vm.templateList.items, ['id', itemId]));
      vm.originalTemplateList.items=angular.copy(vm.templateList.items);
      var itemList = SupplierPickingListService.addItemsToList(vm.originalTemplateList);
    }

  }
})();
