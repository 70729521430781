/*
 * @Author: Ajay 
 * @Date: 2019-02-11 11:33:37 
 * @Project: Ezyprocure
 * @Filename: ezyap.supplier.dashboard.state.js
 * @Last Modified by: Ajay
 * @Last Modified time: 2019-02-21 11:17:45
 */

(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('ezyap.supplier.dashboard', {
      parent: 'ezyap.supplier',
      url: '/dashboard',
      data: {
        authorities: ['ROLE_SUPPLIER']
      },
      views: {
        'content@': {
          templateUrl: 'app/ezyap-modules/supplier-modules/dashboard/ezyap.supplier.dashboard.html',
          controller: 'EzyapSupplierDashboardController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('buyerDashboard');
          return $translate.refresh();
        }],
        hasModuleAccess: ['Auth','$q', '$state', function(Auth, $q, $state) {
          if(Auth.hasPermission('accessForPEPPOL') || Auth.hasPermission('accessForOnlyEzyAP')){
            return true;
          }else {
            return $q.reject('Unauthorized');
          }
        }]
      }
    })
  }
})();
