(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BuyerCancelPORequestService', BuyerCancelPORequestService);

  BuyerCancelPORequestService.$inject = ['$http', '$q', 'API_CONSTANTS'];
  function BuyerCancelPORequestService($http, $q, API_CONSTANTS) {

    var buyerCancelPORequestService = {
      getCancelPORequests : getCancelPORequests,
      cancelPORequest : cancelPORequest
    };
    return buyerCancelPORequestService;

    function getCancelPORequests(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'purchaseOrder/cancelled',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function cancelPORequest(poNumber,params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'purchaseOrder/cancel/' + poNumber,
        method: "PUT",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
