/*
 * @Author: Ajay
 * @Date: 2019-02-11 11:37:23
 * @Project: Ezyprocure
 * @Filename: ezyap.upload-invoice.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2020-01-23 16:54:57
 */

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('EzyapUploadInvoiceController', EzyapUploadInvoiceController);

  EzyapUploadInvoiceController.$inject = ['NotificationService', 'CsvUploadService', 'UtilService', 'UploadInvoiceService', 'ManageBuyerInfoService', 'S3_URL_CONSTANTS', 'toastr', '$state', '$filter', 'Auth', 'SatsManagementService'];

  function EzyapUploadInvoiceController(NotificationService, CsvUploadService, UtilService, UploadInvoiceService, ManageBuyerInfoService, S3_URL_CONSTANTS, toastr, $state, $filter, Auth, SatsManagementService) {

    var vm = this;
    vm.invoiceUploadCSV = invoiceUploadCSV;
    vm.csvToJson = csvToJson;
    vm.getBuyerInformation = getBuyerInformation;
    vm.EZYAP_INVOICE_UPLOAD_CSV = S3_URL_CONSTANTS.EZYAP_INVOICE_UPLOAD_CSV;
    vm.resetValues = resetValues;
    vm.userRole = Auth.getUserRole();
    vm.getDateFormat = getDateFormat;

    /**
     * [Upload the invoice csv file]
     * @param {Object} file [file to be uploaded]
     */
    function invoiceUploadCSV(file) {
      UploadInvoiceService.invoiceUploadCSV(file, { buyerInformationId: vm.buyerInfoId })
        .then(function (response) {
          $state.go('ezyap.'+ vm.userRole.pretty +'.peppol-invoices-summary');
          NotificationService.success({
            title: 'global.menu.entities.uploadInvoice',
            message: 'alertMessage.UPLOADED_SUCCESSFULLY'
          });
        }, function (error) {
          if (error.data.responseList[0].index != null) {
            vm.recordError = error.data;
          }
          else if (error.data.responseList[0].index == null) {
            toastr.error(error.data.responseList[0].reason, $filter('translate')('global.menu.entities.uploadInvoice'));
          }
          else {
            NotificationService.error({
              title: 'global.menu.entities.uploadInvoice',
              error: error
            });
          }
        });
    }

    /**
     * [convert csv file to json for preview]
     */
    function csvToJson() {
      var csvFile = vm.files[0]
      vm.isCsvFile = CsvUploadService.checkFileType(csvFile.lfFile);
      if (vm.isCsvFile) {
        UtilService.csvToJson(csvFile.lfDataUrl)
          .then(function (response) {
            vm.invoiceData = response;
          })
      }
    }

    /**
     * get all buyer information
     */
    function getBuyerInformation() {
      if (vm.buyerDetails) {
        return false;
      }
      ManageBuyerInfoService.getBuyerInformation({ query: "" }).
        then(function (response) {
          vm.buyerDetails = response.data;
        }, function (error) {
          NotificationService.error({
            title: '',
            error: error
          });
        });
    }

    /**
     * resets error and csv data
     */
    function resetValues() {
      vm.invoiceData = [];
      vm.recordError = [];
    }

    /**
     * [getDateFormat gets the date string and converts]
     * @param {dateString} dateString
     */
    function getDateFormat(dateString){
    var formatedDate = SatsManagementService.getDateFormat(dateString);
    return formatedDate;
    }
  }
})();
