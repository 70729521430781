/**
* @Author: hareesh
* @Date:   13-Aug-2017 09:11 +05:30
* @Project: Ezyprocure
* @Filename: supplier.top-buyers.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 12-Oct-2018 14:41 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierTopBuyersController', SupplierTopBuyersController);

  SupplierTopBuyersController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', 'pagingParams', '$stateParams', 'NotificationService', '$mdDialog', 'UTCDateService','SupplierPriceUpdateHistoryService', 'ItemGroupService', 'paginationConstants', 'CSVExportService', 'EXPORT_CONSTANTS'];

  function SupplierTopBuyersController($scope, $rootScope, $q, $filter, $state, pagingParams, $stateParams, NotificationService, $mdDialog, UTCDateService, SupplierPriceUpdateHistoryService, ItemGroupService, paginationConstants, CSVExportService, EXPORT_CONSTANTS ) {
    var vm = this;
    vm.todaysDate = new Date();
    vm.loadAll = loadAll;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.itemGroupId = null ;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.pieChart=pieChart;
    vm.loadItemGroups =  loadItemGroups;
    //Header
    vm.topBuyersHeader = EXPORT_CONSTANTS.CSV.TopBuyersReport;
    //Function Declaration
    vm.getTopBuyersCSVData = getTopBuyersCSVData;
    vm.resetFilters = resetFilters;

    //Getting CSV DATA
    function getTopBuyersCSVData(){
      var deferred = $q.defer();
      if (!vm.priceUpdateItems) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getTopBuyersCSV(vm.priceUpdateItems);
    }
    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function loadItemGroups() {
      if(vm.itemGroups)
        return false;
      ItemGroupService.getAllItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.itemGroups = response.data;
      }, function(error) {
      });
    }

    function pieChart() {
      var priceUpdateItem=vm.priceUpdateItems;
      var arr;
      var data = new google.visualization.DataTable();
      data.addColumn('string', 'Buyer');
      data.addColumn('number', 'values');
      for (priceUpdateItem in vm.priceUpdateItems){
        arr = (vm.priceUpdateItems[priceUpdateItem]);
        data.addRow([arr[1],arr[7]]);
      }
      if(data.getNumberOfRows()==0){}
      else {
        var options = {
          fontName: 'Roboto',
          height: 300,
          width: 500,
          chartArea: {
            left: 50,
            width: '90%',
            height: '90%'
          }
        };
        var chart = new google.visualization.PieChart(document.getElementById('Piechart_material1'));
        chart.draw(data, options);
      }
    }
    google.charts.load('current', {packages: ['corechart']});
    google.charts.setOnLoadCallback(pieChart);
    /**
    * [loadAll loads all price update history]
    */
    function loadAll() {
      SupplierPriceUpdateHistoryService.getTopBuyers({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        fromDate: !vm.fromDate ? '' : UTCDateService.utcFromDate(vm.fromDate),
        toDate: !vm.toDate ? '' : UTCDateService.utcToDate(vm.toDate),
        query: !vm.buyer ? '' : vm.buyer,
        itemName: !vm.itemName ? '' : vm.itemName,
        supplierId: !vm.supplierId ? '' : vm.supplierId,
        itemGroupId: !vm.itemGroupId ? '' : vm.itemGroupId
      }).then(function(response){
        vm.priceUpdateItems = response.data;
        vm.totalItems = response.headers('X-Total-Count');
        vm.pieChart=pieChart();
      },function(error){
      });
    }
    function resetFilters() {
        vm.toDate = vm.todaysDate;
        vm.fromDate = vm.todaysDate;
        vm.itemGroupId = null;
        vm.itemName = null;
        vm.priceUpdateItems = null;
    }
  }
})();
