/**
* @Author: Ajay
* @Date:   01-Dec-2016 10:46 +05:30
* @Project: Ezyprocure
* @Filename: translation.handler.js
* @Last modified by:   Ajay
* @Last modified time: 27-Mar-2017 18:57 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .factory('translationHandler', translationHandler);

  translationHandler.$inject = ['$rootScope', '$window', '$state', '$translate'];

  function translationHandler($rootScope, $window, $state, $translate) {
    return {
      initialize: initialize,
      updateTitle: updateTitle
    };

    function initialize() {
      // if the current translation changes, update the window title
      var translateChangeSuccess = $rootScope.$on('$translateChangeSuccess', function() {
        updateTitle();
      });

      $rootScope.$on('$destroy', function() {
        if(angular.isDefined(translateChangeSuccess) && translateChangeSuccess !== null){
          translateChangeSuccess();
        }
      });
    }

    // update the window title using params in the following
    // precedence
    // 1. titleKey parameter
    // 2. $state.$current.data.pageTitle (current state page title)
    // 3. 'global.title'
    function updateTitle(titleKey) {
      if (!titleKey && $state.$current.data && $state.$current.data.pageTitle) {
        titleKey = $state.$current.data.pageTitle;
      }
      $translate(titleKey || 'global.title').then(function(title) {
        $window.document.title = title;
      });
    }
  }
})();
