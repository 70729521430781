/**
* @Author: JayalekshmiR
* @Date:   2018-09-17T13:23:29+05:30
* @Filename: ezypayment.buyer.payee.bulk-upload.controller.js
 * @Last modified by:   JayalekshmiR
 * @Last modified time: 2018-09-17T15:41:20+05:30
*/



(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .controller('PayeeBulkUploadController', PayeeBulkUploadController);
  
    PayeeBulkUploadController.$inject = ['toastr','pagingParams', 'NotificationService', 'paginationConstants', 'ExcelUploadService', 'UtilService', 'BuyerPayeeService', 'S3_URL_CONSTANTS'];
  
    function PayeeBulkUploadController(toastr, pagingParams, NotificationService, paginationConstants, ExcelUploadService, UtilService, BuyerPayeeService, S3_URL_CONSTANTS) {
  
      var vm = this;
      vm.predicate = pagingParams.predicate;
      vm.reverse = true;
      vm.query = {
        limit: paginationConstants.itemsPerPage,
        page: 1
      };
      vm.getExcelBase64 = getExcelBase64;
      vm.resetValues = resetValues;
      vm.addPayeeViaExcelUpload = addPayeeViaExcelUpload;
      vm.sort = sort;
      vm.excelUploadData = {};
      vm.ADD_PAYEE_UPLOAD_EXCEL = S3_URL_CONSTANTS.ADD_PAYEE_UPLOAD_EXCEL;
  
      function sort() {
        var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
        return result;
      }

      function clearErrorList() {
        vm.translatedError.responseList = [];
      }

      function clearTableData() {
        vm.payees = [];
      }
      
      /**
       *  getExceBase64 gives the given excel in base64 encoded format
       */
      function getExcelBase64() {
        vm.errorList = null;
        var excelFile = vm.files[0];
        if (!excelFile) {
          NotificationService.simpleErrorToast({
            title: 'alertMessage.INVALID_INPUT',
            message: 'global.pleaseChooseAExcelFile'
          });
          return false;
        }
        clearErrorList();
        ExcelUploadService.getFileBase64(excelFile.lfFile, function (base64Excel) {
          vm.excelUploadData = base64Excel;
          vm.addPayeeViaExcelUpload(vm.excelUploadData);
        });
      }
  
      /**
       * addPayeeViaExcelUpload upload a payee list to be added
       * @param {uploadData}  
       */
      function addPayeeViaExcelUpload(uploadData) {
        BuyerPayeeService.addPayeeViaExcelUpload({encodedExcelData : uploadData})
        .then(function (response) {
            vm.files = [];
            clearTableData();
            NotificationService.success({
              title: 'global.addPayeeExcel',
              message: 'alertMessage.UPLOADED_SUCCESSFULLY'
            });
        }, function (error) {
          vm.translatedError = error.data;
          NotificationService.error({
            title: 'alertMessage.ADD_PAYEE_FAILED',
            error: error
          });
        });
      }
  
      function resetValues(){
        vm.payees = [];
        vm.translatedError = [];
      }

    }
  })();
  