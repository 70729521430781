/**
 * @Author: Ajay
 * @Date:   27-Oct-2017 13:29 +05:30
 * @Filename: buyer.adhoc-po.details.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 06-Nov-2017 15:00 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerAdhocPoDetailsController', BuyerAdhocPoDetailsController);

  BuyerAdhocPoDetailsController.$inject = ['$scope', '$state', '$filter', '$stateParams', 'BuyerUnableMeetDemandsService', 'SupplierRequirementPendingPOService', 'paginationConstants', 'NotificationService', 'GLOBAL_CONSTANTS'];

  function BuyerAdhocPoDetailsController($scope, $state, $filter, $stateParams, BuyerUnableMeetDemandsService, SupplierRequirementPendingPOService, paginationConstants, NotificationService, GLOBAL_CONSTANTS) {

    var vm= this;
    vm.init = init;
    vm.requirementNumber = $stateParams.requirementNumber;
    vm.loadAll = loadAll;
    vm.todaysDate = new Date();
    vm.processPendingPO = processPendingPO;
    vm.checkAlreadyProcessed = checkAlreadyProcessed;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;

    function init() {
      loadAll();
    }

    /**
    * [loadAll loads all pending POs]
    */
    function loadAll() {
        BuyerUnableMeetDemandsService.getProcessOrderByRequirementNumberAdhocPo(vm.requirementNumber).then(function(response) {
        vm.checkAlreadyProcessed(response.data);
        vm.processOrderDetails = response.data;
      }, function(error) {
      });
    }

    // Redirect back to Pending Orders Listing page if order already processed*
        function checkAlreadyProcessed(processOrderDetails) {
          var generated = false;
          _.forEach(processOrderDetails.items, function(item){
            _.forEach(item.supplierResponses, function(supplierResponse){
              if (supplierResponse.processOrderStatus === 'SUPPLIER_APPROVED') {
                generated = true;
            }
            });
                   });
                   if (generated === true) {
                           $state.go('supplier.requirement-pending-po', {}, { reload: false});
                         }
    }

    /**
     * [processPendingPO Process the Pending PO]
     */
    function processPendingPO() {
      SupplierRequirementPendingPOService.processPendingPOAdhocOnBoard(vm.requirementNumber).then(function(response) {
        vm.processOrderCreatedDetails=response.data;
        NotificationService.success({
          title: 'Requirement Pending PO',
          message: 'alertMessage.PROCESSED_SUCCESSFULLY'
        });
                if(vm.processOrderCreatedDetails == null||vm.processOrderCreatedDetails ==""){
                        $state.go('supplier.requirement-pending-po', {reload: false});
                        }else{
                        $state.go('supplier.reports.purchase-order.individual-details',{'poNumber':vm.processOrderCreatedDetails[0].purchaseOrderNumber}, {
                                reload: false
                                });
                        }
      }, function(error) {
        NotificationService.error({
          title: 'Process Pending PO',
          error: error
        });
      });
    }
  }
})();
