/**
* @Author: Ajay
* @Date:   29-Mar-2017 19:21 +05:30
* @Project: Ezyprocure
* @Filename: supplier.calendar.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 07-Nov-2017 15:37 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierCalendarController', SupplierCalendarController);

  SupplierCalendarController.$inject = ['$scope', '$mdDialog', 'pagingParams', 'SupplierCalendarService', 'paginationConstants', 'NotificationService', 'GLOBAL_CONSTANTS'];

  function SupplierCalendarController($scope, $mdDialog, pagingParams, SupplierCalendarService, paginationConstants, NotificationService, GLOBAL_CONSTANTS) {
    var vm = this;

    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;

    vm.loadSupplierCalendars = loadSupplierCalendars;
    vm.showConfirm = showConfirm;
    vm.cancel = cancel;
    vm.showAddSupplierCalendar = showAddSupplierCalendar;
    vm.addSupplierCalendar = addSupplierCalendar;
    vm.showEditSupplierCalendar = showEditSupplierCalendar;
    vm.updateSupplierCalendar = updateSupplierCalendar;

    vm.currentDate = new Date();

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
    * [loadSupplierCalendars load all supplier calendars]
    */
    function loadSupplierCalendars() {
      SupplierCalendarService.getAllSupplierCalendars({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: ''
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.supplierCalendars = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'Calendar Listing',
          error: error
        });
      });
    }

    vm.loadSupplierCalendars();

    /**
    * [deleteSupplierSupplierCalendar soft delete supplier calendar]
    * @param  {[Integer]} supplierCalendarId [supplierCalendarId]
    */
    function deleteSupplierSupplierCalendar(supplierCalendarId) {
      SupplierCalendarService.deleteSupplierCalendar(supplierCalendarId).then(function(response) {
        NotificationService.success({
          title: 'Calendar',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadSupplierCalendars();
      }, function(error) {
        NotificationService.error({
          title: 'Calendar Deletion',
          error: error
        });
      });
    }

    /**
    * [showConfirm showConfirm mdDialog]
    * @param  {[Object]} ev               [event]
    * @param  {[Object]} supplierCalendar [supplierCalendar Object]
    */
    function showConfirm(ev, supplierCalendar) {
      var confirm = $mdDialog.confirm()
      .title('Are you sure you want to delete Supplier Calendar - ' + supplierCalendar.description + ' ? ')
      .textContent('This action could not be reverted back.')
      .targetEvent(ev)
      .ok('Delete')
      .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        deleteSupplierSupplierCalendar(supplierCalendar.id);
      }, function() {});
    }

    /**
    * [cancel close mdDialog]
    */
    function cancel() {
      $mdDialog.cancel();
    }

    //Add Supplier Calendar Starts here

    /**
    * [showAddSupplierCalendar showAddSupplierCalendar mdDialog]
    * @param  {[Object]} event [event]
    */
    function showAddSupplierCalendar(event) {
      vm.newSupplierCalendar = {};
      $mdDialog.show({
        templateUrl: 'app/supplier-modules/tools/manage-calendar/supplier.calendar.add.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    /**
    * [addSupplierCalendar Add a new SupplierCalendar]
    * @param {[Object]} newSupplierCalendar [newSupplierCalendar Object]
    * @return {[Object]}                     [response/success message object]
    */
    function addSupplierCalendar(newSupplierCalendar) {
      SupplierCalendarService.createSupplierCalendar(newSupplierCalendar).then(function(response) {
        $scope.$emit('ezyprocureApp:supplierCalendarUpdate', response);
        
        vm.cancel();
        NotificationService.success({
          title: 'Calendar',
          message: 'alertMessage.CREATED_SUCCESSFULLY'
        });
        vm.loadSupplierCalendars();
      }, function(error) {
        NotificationService.error({
          title: 'Calendar Creation',
          error: error
        });
      });
    }

    //Add Supplier Calendar Ends here


    //Edit Supplier Calendar Starts here

    /**
    * [showEditSupplierCalendar Edit a Supplier Calendar]
    * @param  {[Object]} event               [description]
    * @param  {[Object]} supplierCalendar [supplierCalendar Object]
    */
    function showEditSupplierCalendar(event, supplierCalendar) {
      vm.editSupplierCalendar = angular.copy(supplierCalendar);
      $mdDialog.show({
        templateUrl: 'app/supplier-modules/tools/manage-calendar/supplier.calendar.edit.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    /**
    * [updateSupplierCalendar Update a supplier calendar]
    * @param  {[Object]} supplierCalendar [supplierCalendar Object]
    * @return {[Object]}                     [response/success message object]
    */
    function updateSupplierCalendar(supplierCalendar) {
      SupplierCalendarService.updateSupplierCalendar(supplierCalendar).then(function(response) {
        $scope.$emit('ezyprocureApp:supplierCalendarUpdate', response);
        vm.cancel();
        NotificationService.success({
          title: 'Calendar',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        vm.loadSupplierCalendars();
      }, function(error) {
        NotificationService.error({
          title: 'Calendar Updation',
          error: error
        });
      });
    }

    //Edit Supplier Calendar Ends here

  }


})();
