/**
* @Author: Abhay
* @Date:   06-Jan-2021 15:12 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.card-security.service.js
 * @Last modified by:   Abhay
 * @Last modified time: 06-Jan-2021 15:12 +05:30
*/



(function () {
    'use strict';

    angular.module('ezyprocureApp').factory('CardSecurityService', CardSecurityService);

    CardSecurityService.$inject = ['$http', '$q', 'API_CONSTANTS'];
    function CardSecurityService($http, $q, API_CONSTANTS) {

        var cardSecurityService = {
            requestFields: undefined,
            setRequestFields: setRequestFields,
            getRequestFields: getRequestFields,
            sign: sign
        };

        return cardSecurityService;

        function setRequestFields(formData) {
            cardSecurityService.requestFields = formData;
        }

        function getRequestFields() {
            return cardSecurityService.requestFields;
        }

        function buildDataToSign(formJson) {
            var signedFieldNames = formJson["signed_field_names"].split(",");
            var dataToSign = [];
            signedFieldNames.forEach(function (field) {
                dataToSign.push(field + "=" + formJson[field]);
            });
            return commaSeparate(dataToSign);
        }

        function commaSeparate(dataToSign) {
            return dataToSign.join(",");
        }

        function sign(secretKey) {
            return signData(buildDataToSign(cardSecurityService.requestFields), secretKey);
        }

        function signData(data, secretKey) {
            var hash = CryptoJS.HmacSHA256(data, secretKey);
            var hashInBase64 = CryptoJS.enc.Base64.stringify(hash);
            return hashInBase64;
        }

    }
})();
