/**
* @Author: hareesh
* @Date:   10-Aug-2017 05:54 +05:30
* @Project: Ezyprocure
* @Filename: buyer.top-suppliers.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 12-Oct-2018 14:58 +05:30
*/



(function() {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('BuyerTopSuppliersController', BuyerTopSuppliersController);

  BuyerTopSuppliersController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', '$stateParams', 'toastr', '$mdDialog', 'pagingParams', 'BuyerDataService', 'UTCDateService', 'BuyerPriceUpdateHistoryService','BusinessReportService', 'BuyerSupplierMappingService', 'ItemGroupService', 'paginationConstants', 'BuyerBuyNowService', 'CSVExportService', 'EXPORT_CONSTANTS', 'BuyerBusinessUserMapService','MainItemGroupService', 'NotificationService', 'GLOBAL_CONSTANTS'];

  function BuyerTopSuppliersController($scope, $rootScope, $q, $filter, $state, $stateParams, toastr, $mdDialog, pagingParams, BuyerDataService, UTCDateService, BuyerPriceUpdateHistoryService, BusinessReportService, BuyerSupplierMappingService,ItemGroupService, paginationConstants, BuyerBuyNowService, CSVExportService, EXPORT_CONSTANTS, BuyerBusinessUserMapService, MainItemGroupService, NotificationService, GLOBAL_CONSTANTS) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.todaysDate = new Date();
    vm.getAllAllocatedSuppliersForBusiness = getAllAllocatedSuppliersForBusiness;
    vm.loadItemGroups = loadItemGroups;
    vm.loadAll = loadAll;
    vm.supplier = '';
    vm.pieChart = pieChart;
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.filter = {};
    vm.loadMainItemGroups = loadMainItemGroups;
    vm.getAllGroupedItemGroups = getAllGroupedItemGroups;
    vm.mainItemGroupSelectionChanged = mainItemGroupSelectionChanged;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.view=false;
    vm.options={};
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };

    vm.limitOptions = paginationConstants.limitOptions;
    //Header
    vm.topSuppliersHeader = EXPORT_CONSTANTS.CSV.TopSuppliersReport;
    //Function Declaration
    vm.getTopSuppliersCSVData = getTopSuppliersCSVData;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    //filter reset
    vm.resetFilters = resetFilters;
    //Getting CSV DATA
    function getTopSuppliersCSVData() {
      var deferred = $q.defer();
      if (!vm.topSupplierItems) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getTopSuppliersCSV(vm.topSupplierItems,vm.fromDate,vm.toDate);
    }

    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
    * [getAllBuyerBusinessOutlets Gets all business outlets of buyer]
    */
    function getAllBuyerBusinessOutlets() {
      if (vm.businessOutlets)
        return false;
      vm.businessOutlets = null;
      BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser().
        then(function(response) {
          vm.businessOutlets = response;
          //if only one business outlet set it as default
          if (vm.businessOutlets.length === 1) {
            vm.businessId = vm.businessOutlets[0].id;
          }
        }, function(error) {
          NotificationService.error({
            title:"Failed to load business outlets!",
            message:error
          });
        });
    }

    /**
    * [getAllAllocatedSuppliersForBusiness Get all allocated suppliers of buyer business]
    */
    function getAllAllocatedSuppliersForBusiness(){
      if(vm.supplierList)
        return false;
      var params = {'businessId': ''};
      BuyerBuyNowService.getAllAllocatedSuppliersForBusiness(params).then(function(response){
        vm.supplierList = $filter('orderBy')(response.data, 'companyName');
      },function(error){
        NotificationService.error({
          title:"Failed to load suppliers!",
          message:error
        });
      });
    }
    /**
    *[loadItemGroups loads all the item group]
    */
    function loadItemGroups() {
      ItemGroupService.getAllItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.itemGroups = response.data;
      }, function(error) {
        NotificationService.error({
          title:"Failed to load item groups!",
          message:error
        });
      });
    }

    /**
    *pieChart views a pie chart representing the top suppliers and respective percentage
    */
    function pieChart(){
      vm.options={
        chart: {
          type: 'pieChart',
          height: 670,
          x: function(d){return d.key;},
          y: function(d){return d.value;},
          showLabels: false,
          duration: 500,
          labelThreshold: 0.01,
          labelSunbeamLayout: true,
          legend: {
            margin: {
              top: 5,
              right: 35,
              bottom: 5,
              left: 0
            }
          }
        }
      };
      vm.data=[];

      vm.topSupplierItems.forEach(function(element){
        var item={};
        item.key=element.supplier;
        item.value=element.percentage;
        vm.data.push(item);
      });

    }

    /**
    * [loadAll loads all top suppliers]
    */
    function loadAll() {
      BusinessReportService.getTopSuppliers({
        fromDate: !vm.fromDate ? '' : UTCDateService.utcFromDate(vm.fromDate),
        toDate: !vm.toDate ? '' : UTCDateService.utcToDate(vm.toDate),
        buyerBusinessIdList: !vm.filter.buyerBusinessIdList ? [] : vm.filter.buyerBusinessIdList ,
        supplierIdList: !vm.filter.supplierIdList ? []: vm.filter.supplierIdList,
        mainItemGroupIdList: !vm.filter.mainItemGroupIdList ? []: vm.filter.mainItemGroupIdList,
        itemGroupIdList: !vm.filter.itemGroupIdList ? [] :vm.filter.itemGroupIdList,
        query: ''
      },
      {
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort()
      }).then(function(response) {
        vm.topSupplierItems = response.data;
        vm.totalItems = response.headers('X-Total-Count');
        vm.pieChart = pieChart();
      }, function(error) {
        NotificationService.error({
          title:"Failed to retrieve the top suppliers report!",
          message:error
        });
      });
    }
    /**
    * [loadMainItemGroups Gets all Main Item Groups]
    */
    function loadMainItemGroups() {
      if (vm.mainItemGroups) {
        return false;
      }
      MainItemGroupService.getAllMainItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.mainItemGroups = response.data;
      }, function(error) {
        NotificationService.error({
          title:"Failed to load main item groups!",
          message:error
        });
      });
    }
    /**
    * [mainItemGroupChanged changes the status of mainItemGroup and empties the existing itemGroupList]
    */
    function mainItemGroupSelectionChanged() {
      vm.filter.mainItemGroupChanged = true;
      vm.filter.itemGroupIdList = [];
    }
    /**
    * [selectAll Selects all from the list]
    * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, mainItemGroups, itemGroups ]
    */
    function selectAll(dropdown) {
      switch (dropdown) {
      case 'OUTLETS':
        vm.filter.buyerBusinessIdList = _.map(vm.businessOutlets, _.property('id'));
        break;
      case 'SUPPLIERS':
        vm.filter.supplierIdList = _.map(vm.supplierList, _.property('id'));
        break;
      case 'MAIN_ITEMGROUPS':
        vm.mainItemGroupSelectionChanged();
        vm.filter.mainItemGroupIdList = _.map(vm.mainItemGroups, _.property('id'));
        break;
      case 'ITEMGROUPS':
        vm.filter.itemGroupIdList = _.map(_.flatten(_.without(_.map(vm.itemGroups, _.property('itemGroupDTOList')), undefined, null)), _.property('itemGroupId'));
        break;
      }
    }
    /**
    * [deselectAll Deselect all from the list]
    * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, mainItemGroups, itemGroups ]
    */
    function deselectAll(dropdown) {
      switch (dropdown) {
      case 'OUTLETS':
        vm.filter.buyerBusinessIdList = [];
        break;
      case 'SUPPLIERS':
        vm.filter.supplierIdList = [];
        break;
      case 'MAIN_ITEMGROUPS':
        vm.filter.mainItemGroupIdList = [];
        vm.mainItemGroupSelectionChanged();
        break;
      case 'ITEMGROUPS':
        vm.filter.itemGroupIdList = [];
        break;

      }
    }
    /**
    * [getAllGroupedItemGroups Gets all ItemGroups Grouped by Main Item Groups]
    */
    function getAllGroupedItemGroups(mainItemGroupIdList) {
      if (vm.itemGroups && !vm.filter.mainItemGroupChanged){
        return false;
      }
      vm.filter.mainItemGroupChanged = false;
      ItemGroupService.getAllGroupedItemGroups(mainItemGroupIdList, {
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.itemGroups = response.data;
      }, function(error) {
        NotificationService.error({
          title:"Failed to load item groups grouped by main item group!",
          message:error
        });
      });
    }
    // filter reset
    function resetFilters() {
        vm.fromDate = vm.todaysDate;
        vm.toDate = vm.todaysDate;
        vm.filter.buyerBusinessIdList = '';
        vm.filter.supplierIdList = '';
        vm.topSupplierItems = null;
    }
  }

}) ();
