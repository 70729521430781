/**
* @Author: Ajay
* @Date:   01-Dec-2016 10:46 +05:30
* @Project: Ezyprocure
* @Filename: state.handler.js
 * @Last modified by:   Ajay
 * @Last modified time: 17-Nov-2017 11:55 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .factory('stateHandler', stateHandler);

  stateHandler.$inject = ['$rootScope', '$state', '$sessionStorage', '$translate', 'LanguageService', 'translationHandler', '$window',
    'Auth', 'Principal', 'VERSION'
  ];

  function stateHandler($rootScope, $state, $sessionStorage, $translate, LanguageService, translationHandler, $window,
  Auth, Principal, VERSION) {
    return {
      initialize: initialize
    };

    function initialize() {
      $rootScope.VERSION = VERSION;

      var stateChangeStart = $rootScope.$on('$stateChangeStart', function(event, toState, toStateParams, fromState) {
        $rootScope.toState = toState;
        $rootScope.toStateParams = toStateParams;
        $rootScope.fromState = fromState;

        // Redirect to a state with an external URL (http://stackoverflow.com/a/30221248/1098564)
        if (toState.external) {
          event.preventDefault();
          $window.open(toState.url, '_self');
        }

        if (Principal.isIdentityResolved()) {
          Auth.authorize();
        }

        // Update the language
        LanguageService.getCurrent().then(function(language) {
          $translate.use(language);
        });
      });

      var stateChangeSuccess = $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
        var titleKey = 'global.title';

        // Set the page title key to the one configured in state or use default one
        if (toState.data.pageTitle) {
          titleKey = toState.data.pageTitle;
        }
        translationHandler.updateTitle(titleKey);
        Auth.storePreviousState($rootScope.toState.name, $rootScope.toStateParams);
      });

      $rootScope.$on('$destroy', function() {
        if (angular.isDefined(stateChangeStart) && stateChangeStart !== null) {
          stateChangeStart();
        }
        if (angular.isDefined(stateChangeSuccess) && stateChangeSuccess !== null) {
          stateChangeSuccess();
        }
      });
    }
  }
})();
