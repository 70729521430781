/**
* @Author: Ajay
* @Date:   21-Jan-2017 20:13 +05:30
* @Project: Ezyprocure
* @Filename: supplier.data.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 11-Apr-2018 11:22 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .factory('SupplierDataService', SupplierDataService);

  SupplierDataService.$inject = ['$localStorage', '$state', 'Principal'];

  function SupplierDataService($localStorage, $state, Principal) {
    var service = {
      getSupplierProfile: getSupplierProfile,
      setSupplierProfile: setSupplierProfile,
      getCbSupplierProfile: getCbSupplierProfile,
      setCbSupplierProfile: setCbSupplierProfile
    };
    var supplierProfileInfo = null;
    var cbSupplierProfileInfo = null;

    return service;

    // Return supplier profile information
    function getSupplierProfile() {
      if (!supplierProfileInfo){
        supplierProfileInfo = angular.fromJson($localStorage["supplier-profile-info"]);
      }
      return supplierProfileInfo;
    }

    // store supplier profile information
    function setSupplierProfile(supplierProfile) {
      supplierProfileInfo = supplierProfile;
      $localStorage["supplier-profile-info"] = angular.toJson(supplierProfile);
    }

    // Return cb supplier profile information
    function getCbSupplierProfile() {
      if (!cbSupplierProfileInfo){
        cbSupplierProfileInfo = angular.fromJson($localStorage["cb-supplier-profile-info"]);
      }
      return cbSupplierProfileInfo;
    }

    function setCbSupplierProfile(supplierProfile) {
      cbSupplierProfileInfo = supplierProfile;
      $localStorage["cb-supplier-profile-info"] = angular.toJson(supplierProfile);
      Principal.setUserVerifiedKYC(supplierProfile.supplierUser.user.kycStatus);
      if(Principal.isUserVerifiedKYC() == false) {
        $state.go('supplier.contactadmin', {from: 'kyc'});
      }
    }
  }

})();
