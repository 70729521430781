/**
 * @Author: Ajay
 * @Date:   08-Sep-2017 12:54 +05:30
 * @Project: Ezyprocure
 * @Filename: access-denied.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 08-Sep-2017 12:57 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('ContactAdminController', ContactAdminController);

  ContactAdminController.$inject = ['$scope', '$state', '$stateParams', 'Auth'];

  function ContactAdminController($scope, $state, $stateParams, Auth) {

    var vm = this;
    vm.userRole = Auth.getUserRole();
    vm.from = $stateParams.from;

  }
})();
