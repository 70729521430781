/**
* @Author: Midhun Mohan <MidhunMohan>
* @Date:   28-Apr-2017 11:01 +05:30
* @Project: Ezyprocure
* @Filename: buyer.ocr.service.js
 * @Last modified by:   Abhay
 * @Last modified time: 09-Sept-2020 17:32 +05:30
*/



(function () {
  'use strict';

  angular.module('ezyprocureApp').factory('BuyerOCRService', BuyerOCRService);

  BuyerOCRService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function BuyerOCRService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {
    var buyerOCRService = {
      getPurchaseOrderDetails: getPurchaseOrderDetails,
      getAllPurchaseOrders: getAllPurchaseOrders,
      getAllExportCSVDetails: getAllExportCSVDetails,
      invoiceImgUpload: invoiceImgUpload,
      invoiceMicroService: invoiceMicroService,
      getInvoiceStatus: getInvoiceStatus,
      acceptInvoice: acceptInvoice,
      updateInvoice: updateInvoice,
      rejectInvoice: rejectInvoice,
      grnByPONumber: grnByPONumber,
      getGoodReceiptNotes: getGoodReceiptNotes,
      toOCRDateString: toOCRDateString,
      getStaffInterfaceFilter: getStaffInterfaceFilter,
      setStaffInterfaceFilter: setStaffInterfaceFilter,
      getAllExportGeoDetails: getAllExportGeoDetails,
      getExportGeoDetails: getExportGeoDetails,
      getExportAXDetails: getExportAXDetails,
      getAllExportAXDetails: getAllExportAXDetails,
      webserviceinvoice: webserviceinvoice,
      webservicesupplier: webservicesupplier,
      massAcceptPO: massAcceptPO,
      getXMLData: getXMLData,
      updatePrice: updatePrice,
      exportToMillionOrder: exportToMillionOrder,
      getARMillionExportData: getARMillionExportData,
      updateXeroCSVExported: updateXeroCSVExported,
      exportToAccpac: exportToAccpac,
      saveNewItemsOCR: saveNewItemsOCR,
      getAllPurchaseOrdersCollection:getAllPurchaseOrdersCollection,
      exportToSAGA50: exportToSAGA50,
      getSAGA50ExportData: getSAGA50ExportData,
      exportToSunSystem: exportToSunSystem,
      updateOcrVerifyStatus: updateOcrVerifyStatus
    };

    var staffInterfaceFilter = null;

    return buyerOCRService;

    function updateOcrVerifyStatus(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE + 'ocrVerified',
        method: "PUT",
        params: params
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * Save new items added from OCR item details screen
    * @param  {[Object]} params [newItemsDTOList]
    *
    */

    function saveNewItemsOCR(newItemsList) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'ocr/addItem',
        method: "PUT",
        data: newItemsList
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [getPurchaseOrderDetails gets PO details]
    * @param  {[type]} params [filter params]
    * @return {[object]}        [success/error message]
    */
    function getPurchaseOrderDetails(poNumber, deliveryDate) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE + 'view/' + poNumber + '?deliveryDate=' + deliveryDate,
        method: "GET"
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    /**
     * [update status of manually exported xero PO]
     * @param {[type]} params [filter params]
     */
    function updateXeroCSVExported(xeroCSVDTO) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'invoice/xero/export',
        method: "POST",
        data: xeroCSVDTO,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    /**
    * [invoiceImgUpload description]
    * @param  {[type]} item [description]
    * @return {[type]}      [description]
    */
    function invoiceImgUpload(file) {
      var fd = new FormData();
      fd.append('file', file);
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/ocr' + API_CONSTANTS.baseVersion + '/upload', fd, {
        transformRequest: angular.identity,
        headers: { 'Content-Type': undefined }
      }).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    /**
    * [invoiceMicroService description]
    * @param  {[type]} data [description]
    * @return {[type]}      [description]
    */
    function invoiceMicroService(item) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/ocr' + API_CONSTANTS.baseVersion + '/invoice',
        method: "POST",
        data: item,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    /**
    * [getAllPurchaseOrders description]
    * @return {[object]} [success/error message]
    */
    function getAllPurchaseOrdersCollection(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE + 'ocr/collection-staff-interface',
        method: "GET",
        params: params,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
        * [getAllPurchaseOrders description]
        * @return {[object]} [success/error message]
        */
        function getAllPurchaseOrders(params) {
          var deferred = $q.defer();
          $http({
            url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE + 'ocr',
            method: "GET",
            params: params,
          }).then(function (data) {
            deferred.resolve(data);
          }).catch(function (message) {
            deferred.reject(message);
          });
          return deferred.promise;
        }

    /**
    * [getAllPurchaseOrders description]
    * @return {[object]} [success/error message]
    */
    function getAllExportCSVDetails(params, data) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE + 'ocr/csv-export',
        method: "POST",
        params: params,
        data: data,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }


    /**
    * [grnByPONumber description]
    * @param  {[type]} poNumber [description]
    * @return {[type]}          [description]
    */
    function grnByPONumber(poNumber, deliveryDate) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE + 'good-receipt-status/' + poNumber + '?deliveryDate=' + deliveryDate,
        method: "GET"
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    /**
    * [acceptInvoice description]
    * @param  {[type]} params [description]
    * @return {[type]}        [description]
    */
    function acceptInvoice(ocrResponseDTO) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/ocr' + API_CONSTANTS.baseVersion + '/invoice/accept',
        method: "PUT",
        data: ocrResponseDTO
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
   /**
    * [updateInvoice description]
    * @param  {[type]} params [description]
    * @return {[type]}        [description]
    */

    function updateInvoice(ocrResponseDTO) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/ocr' + API_CONSTANTS.baseVersion + '/invoice/update',
        method: "PUT",
        data: ocrResponseDTO
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [rejectInvoice description]
    * @param  {[type]} params [description]
    * @return {[type]}        [description]
    */
    function rejectInvoice(ocrResponseDTO) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/ocr' + API_CONSTANTS.baseVersion + '/invoice/reject',
        method: "PUT",
        data: ocrResponseDTO,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [getInvoiceStatus description]
    * @param  {[type]} poArray [description]
    * @return {[type]}         [description]
    */
    function getInvoiceStatus(poArray) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/ocr' + API_CONSTANTS.baseVersion + '/invoice/list',
        method: "POST",
        data: poArray,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getGoodReceiptNotes(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE + 'buyer-receiving-orders/PROCESSED',
        method: "GET",
        params: params,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function toOCRDateString(dDate) {
      var date = new Date(dDate);
      // GET YYYY, MM AND DD FROM THE DATE OBJECT
      var yyyy = date.getUTCFullYear().toString();
      var mm = (date.getUTCMonth() + 1).toString();
      var dd = date.getUTCDate().toString();

      // CONVERT mm AND dd INTO chars
      var mmChars = mm.split('');
      var ddChars = dd.split('');

      // CONCAT THE STRINGS IN YYYY-MM-DD FORMAT
      var ocrString = yyyy + '-' + (mmChars[1] ? mm : "0" + mmChars[0]) + '-' + (ddChars[1] ? dd : "0" + ddChars[0]);
      return ocrString;
    }

    // Return Staff Interface Filter
    function getStaffInterfaceFilter() {
      return staffInterfaceFilter ? staffInterfaceFilter : null;
    }

    // store Staff Interface Filter
    function setStaffInterfaceFilter(filter) {
      staffInterfaceFilter = filter;
    }

    /**
    * [getExportGeoDetails getExportGeoDetails based on selection]
    * @param  {[type]} params [PO ids]
    * @return {[object]}        [success/error message]
    */
    function getExportGeoDetails(ocrGeoExportDTO) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'invoices/ocr/batch',
        method: "GET",
        params: ocrGeoExportDTO
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [getAllExportGeoDetails getAllExportGeoDetails based on search query]
    * @param  {[type]} params [PO ids]
    * @return {[object]}        [success/error message]
    */
    function getAllExportGeoDetails(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'invoices/ocr/batch/all',
        method: "GET",
        params: params
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [getExportGeoDetails getExportGeoDetails based on selection]
    * @param  {[type]} params [PO ids]
    * @return {[object]}        [success/error message]
    */
    function getExportAXDetails(ocrGeoExportDTO) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'invoices/ocr/AXbatch',
        method: "GET",
        params: ocrGeoExportDTO
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getAllExportAXDetails(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'invoices/ocr/AXbatch/all',
        method: "GET",
        params: params
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function webserviceinvoice(purchaseOrderIdList, params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/ocr' + API_CONSTANTS.baseVersion + '/invoice/insertList',
        method: "POST",
        data: purchaseOrderIdList,
        params: params
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function webservicesupplier(purchaseOrderIdList) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/ocr' + API_CONSTANTS.baseVersion + '/invoice/insertSupplierList',
        method: "POST",
        data: purchaseOrderIdList,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    /**
    *  massAcceptPO accepts DTO of PO's to be accepted
    * @param {DTO} ocrInvoiceDTOList
    */
    function massAcceptPO(ocrInvoiceDTOList) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/ocr/invoices/mass-accept',
        method: "POST",
        data: ocrInvoiceDTOList,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getXMLData(ocrInvoiceDTOList) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/purchase-orders/oci-export',
        method: "POST",
        data: ocrInvoiceDTOList
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function updatePrice(itemDetails) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE + 'ocr-grn-edit',
        method: "PUT",
        data: itemDetails
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [exportToMillionOrder exports PO for million order template]
    * @param  {[object]} poArray [description]
    */
    function exportToMillionOrder(poArray) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE + 'million-export',
        method: "POST",
        data: poArray,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * AR million export data
     */
    function getARMillionExportData(requestJSON) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE + 'ar/million-export',
        method: "POST",
        data: requestJSON,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [exportToAccpac exports PO for accpac template]
    * @param  {[object]} purchaseOrderFetchDTOList [description]
    */
    function exportToAccpac(purchaseOrderFetchDTOList) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE + 'accpac-export',
        method: "POST",
        data: purchaseOrderFetchDTOList,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [exportToSAGA50 for SAGA50 template]
    * @param  {[object]} purchaseOrderFetchDTOList [description]
    */

    function exportToSAGA50(purchaseOrderFetchDTOList) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE + 'sage/AP-export',
          method: "POST",
          data: purchaseOrderFetchDTOList,
        }).then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
        return deferred.promise;
    }

    /**
     * SAGA 50 export data Supplier
     */
    function getSAGA50ExportData(requestJSON) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE + 'sage/AR-export',
          method: "POST",
          data: requestJSON,
        }).then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }


      /**
    * [exportToSunSystem for SAGA50 template]
    * @param  {[object]} purchaseOrderFetchDTOList [description]
    */

    function exportToSunSystem(purchaseOrderFetchDTOList, type) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE + 'sun-system/export' + (type != null ? '?type=' + type : ''),
          method: "POST",
          data: purchaseOrderFetchDTOList,
        }).then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
        return deferred.promise;
    }
  }
})();
