/**
* @Author: elsa
* @Date:   2017-05-23T12:17:02+05:30
 * @Last modified by:   Ajay
 * @Last modified time: 07-Nov-2017 15:23 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierReturnOrderDetailsController', SupplierReturnOrderDetailsController);

  SupplierReturnOrderDetailsController.$inject = ['$scope', '$mdDialog', '$state', 'NotificationService', '$filter', '$stateParams', 'PurchaseOrderService', 'SupplierReturnOrderService', 'paginationConstants', 'GLOBAL_CONSTANTS'];

  function SupplierReturnOrderDetailsController ($scope, $mdDialog, $state, NotificationService, $filter, $stateParams, PurchaseOrderService, SupplierReturnOrderService, paginationConstants, GLOBAL_CONSTANTS) {

    var vm= this;
    vm.init = init;
    vm.goodsReturnNumber = $stateParams.goodsReturnNumber;
    vm.loadAll = loadAll;
    vm.todaysDate = new Date();
    vm.totalDNAmount = totalDNAmount;
    vm.showCreditNoteRejectModal = showCreditNoteRejectModal;
    vm.supplierRejectCreditNote = supplierRejectCreditNote;
    vm.goodsReturnAmount = goodsReturnAmount;
    vm.goodsReturnNoticeDTO = {};
    vm.goodsReturnNoticeDTO.goodsReturnNumber = vm.goodsReturnNumber;
    vm.cancel = cancel;
    vm.checkAlreadyRejectedCreditNote = checkAlreadyRejectedCreditNote;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;

    /**
    * [init loads initially]
    */
    function init() {
      loadAll();
    }

    /**
    * [loadAll loads all pending POs]
    */
    function loadAll() {
      SupplierReturnOrderService.getDebitNotesByGRNumber(vm.goodsReturnNumber).then(function(response) {
        vm.checkAlreadyRejectedCreditNote(response.data);
        vm.debitNoteDetails = response.data;
      }, function(error) {
      });

    }

    // Redirect back to return order Listing page if credit note already generated*
    function checkAlreadyRejectedCreditNote(debitNoteDetails) {
      if (debitNoteDetails[0].goodsReturnNotice.debitNoteStatus !== 'CREDIT_NOTE_PENDING') {
        $state.go('supplier.return-order', {}, { reload: false});
      }
    }

    /**
     * [goodsReturnAmount return amount per item]
     * @param  {[number]} p_index [index]
     * @return {[number]}         [amount per item]
     */
    function goodsReturnAmount(p_index) {
          var goodsReturnAmount = 0;
          if(vm.debitNoteDetails[p_index].processOrder.items.uom.moqPrice === true)
          {
                goodsReturnAmount += (vm.debitNoteDetails[p_index].goodsReturnNotice.rejectedQuantity / vm.debitNoteDetails[p_index].processOrder.items.moq) * vm.debitNoteDetails[p_index].processOrder.price;
          }
          else
          {
                goodsReturnAmount += vm.debitNoteDetails[p_index].goodsReturnNotice.rejectedQuantity * vm.debitNoteDetails[p_index].processOrder.price;
          }
          return goodsReturnAmount;
        }

        function totalDNAmount() {
          var totalDNAmount = 0;
          _.forEach(vm.debitNoteDetails, function(details){
            if(details.processOrder.items.uom.moqPrice === true)
            {
                totalDNAmount += _.round((details.goodsReturnNotice.rejectedQuantity / details.processOrder.items.moq) * details.processOrder.price,2);
            }
            else
            {
                totalDNAmount += _.round(details.goodsReturnNotice.rejectedQuantity * details.processOrder.price,2);

            }
          });
          return _.round(totalDNAmount,2);
        }

    /**
    * [showCreditNoteRejectModal opens modal to reject credit note]
    * @param  {[type]} event [description]
    */
    function showCreditNoteRejectModal(event) {
      $mdDialog.show({
        templateUrl: 'app/supplier-modules/return-order/details/supplier.reject-credit-note-modal.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    /**
    * [cancel to close the modal]
    */
    function cancel () {
      $mdDialog.cancel();
    }

    /**
    * [supplierRejectCreditNote rejects credit note]
    */
    function supplierRejectCreditNote() {
      cancel();
      SupplierReturnOrderService.supplierRejectCreditNote(vm.goodsReturnNoticeDTO).then(function(response) {
        NotificationService.success({
          title: 'Credit Note',
          message: 'alertMessage.REJECTED_SUCCESSFULLY'
        });
        $state.go('supplier.return-order', {}, {
          reload: false
        });
      }, function(error) {
        NotificationService.error({
          title: 'Credit Note Rejection',
          error: error
        });
      });
    }


  }
})();
