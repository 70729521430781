/**
* @Author: Allan
* @Date:   31-October-2018 11:59 +05:30
* @Project: Ezyprocure
* @Filename: admin.manage-emails.controller.js
* @Last modified by:   Allan
* @Last modified time: 31-October-2018 11:34 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('AdminManageEmailsController', AdminManageEmailsController);

  AdminManageEmailsController.$inject = ['$scope', 'AdminEmailService', 'NotificationService', '$mdDialog', 'GLOBAL_CONSTANTS', '$filter'];

  function AdminManageEmailsController($scope, AdminEmailService, NotificationService, $mdDialog, GLOBAL_CONSTANTS, $filter) {
    var vm = this;

    vm.init = init;
    vm.getEmails = getEmails;
    vm.showAddEmailModal = showAddEmailModal;
    vm.cancel = cancel;
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.departments = GLOBAL_CONSTANTS.DEPARTMENTS;
    vm.addEmail = addEmail;
    vm.showEditEmailModal = showEditEmailModal;
    vm.updateEmail = updateEmail;
    vm.deleteEmail = deleteEmail;
    vm.showDeleteConfirm = showDeleteConfirm;

    function init(){
      getEmails();
    }

    function getEmails(){
      AdminEmailService.getEmails().then(function(response) {
        vm.emails = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'Email Listing',
          error: error
        });
      });
    }

    function showAddEmailModal(event){
      // reset previous data if any
      vm.details = {};
      $mdDialog.show({
        templateUrl: 'app/admin-modules/manage-emails/templates/admin.manage-emails.add-modal.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: false
      });
    }

    function cancel(){
      $mdDialog.cancel();
    }

    function addEmail(details){
      AdminEmailService.addEmail(details).then(function(response) {
        vm.cancel();
        NotificationService.success({
          title: 'Admin User Email Creation',
          message: 'alertMessage.CREATED_SUCCESSFULLY'
        });
        vm.getEmails();
      }, function(error) {
        NotificationService.error({
          title: 'Admin User Email Creation',
          error: error
        });
      });
    }

    function showEditEmailModal(event, emailId){
      // get email record by Id
      AdminEmailService.getEmailById(emailId).then(function(response) {
        vm.details = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'Fetch User Email Data',
          error: error
        });
      });

      $mdDialog.show({
        templateUrl: 'app/admin-modules/manage-emails/templates/admin.manage-emails.edit-modal.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: false
      });
    }

    function updateEmail(details){
      AdminEmailService.updateEmail(details).then(function(response) {
        vm.cancel();
        NotificationService.success({
          title: 'Admin User Email Updation',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        vm.getEmails();
      }, function(error) {
        NotificationService.error({
          title: 'Admin User Email Updation',
          error: error
        });
      });
    }

    function deleteEmail(emailId){
      AdminEmailService.deleteEmail(emailId).then(function() {
        NotificationService.success({
          title: 'Admin User Email Deletion',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.getEmails();
      }, function(error) {
        NotificationService.error({
          title: 'Fetch User Email Data',
          error: error
        });
      });
    }

    function showDeleteConfirm(event, emailId){
      var confirm = $mdDialog.confirm()
      .title($filter('translate')('alertMessage.ADMIN_EMAIL_DELETION'))
      .textContent('This action could not be reverted back.')
      .targetEvent(event)
      .ok('Delete')
      .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        vm.deleteEmail(emailId);
      }, function() {
      });
    }

  }


})();
