/**
* @Author: Mufsir
* @Date:   18-May-2017 01:57 +05:30
* @Project: Ezyprocure
 * @Last modified by:   Elsa
 * @Last modified time: 2017-06-09T15:05:59+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('supplier.reports.good-receipt-note.preview', {
      parent: 'supplier.reports.good-receipt-note',
      url: '/preview/:poNumber/:deliveryDate',
      data: {
        authorities: ['ROLE_SUPPLIER'],
        pageTitle: 'Goods Receipt Note Preview'
      },
      views: {
        'content@': {
          templateUrl: 'app/common-modules/reports-preview/goods-receipt-note-report.preview.html',
          controller: 'SupplierGoodReceiptNoteDetailsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('invoice');
          $translatePartialLoader.addPart('items');
          return $translate.refresh();
        }]
      }
    });
  }
})();
