/**
* @Author: Mufsir
* @Date:   14-Dec-2016 12:54 +05:30
* @Project: Ezyprocure
* @Filename: alert.directive.js
* @Last modified by:   Ajay
* @Last modified time: 27-Mar-2017 18:51 +05:30
*/



(function() {
  'use strict';

  var jhiAlert = {
    template: '<div class="alerts" ng-cloak="">' +
    '<div ng-repeat="alert in $ctrl.alerts" ng-class="[alert.position, {\'toast\': alert.toast}]">' +
    '<uib-alert ng-cloak="" type="{{alert.type}}" close="alert.close($ctrl.alerts)"><pre ng-bind-html="alert.msg"></pre></uib-alert>' +
    '</div>' +
    '</div>',
    controller: jhiAlertController
  };

  angular
  .module('ezyprocureApp')
  .component('jhiAlert', jhiAlert);

  jhiAlertController.$inject = ['$scope', 'AlertService'];

  function jhiAlertController($scope, AlertService) {
    var vm = this;

    vm.alerts = AlertService.get();
    $scope.$on('$destroy', function() {
      vm.alerts = [];
    });
  }
})();
