/*
 * @Author: Lovin Chacko
 * @Date: 2020-08-13 13:23:11
 * @Last Modified by: Lovin Chacko
 * @Last Modified time: 2021-03-30 17:49:18
 */


(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('AdhocPriceUpdateItemSelectionController', AdhocPriceUpdateItemSelectionController);

  AdhocPriceUpdateItemSelectionController.$inject = [ '$state',  '$q', '$filter', 'AdhocItemPriceUpdateService', 'BuyerDataService', 'NotificationService',  'SupplierItemPriceUpdateService', 'UTCDateService', 'GLOBAL_CONSTANTS', '$mdDialog', 'CsvUploadService', 'UtilService', 'S3_URL_CONSTANTS','toastr', 'paginationConstants', 'ItemGroupService'];

  function AdhocPriceUpdateItemSelectionController( $state,  $q, $filter, AdhocItemPriceUpdateService, BuyerDataService, NotificationService, SupplierItemPriceUpdateService, UTCDateService, GLOBAL_CONSTANTS, $mdDialog, CsvUploadService, UtilService, S3_URL_CONSTANTS,toastr, paginationConstants, ItemGroupService) {
    var vm = this;
    vm.init = init;
    vm.todaysDate = new Date();
    vm.tomorrow = new Date();
    vm.tomorrow.setDate(vm.tomorrow.getDate() + 1);
    vm.tomorrow = UTCDateService.utcToDate(vm.tomorrow);
    vm.priceUpdateNumber = SupplierItemPriceUpdateService.getPriceUpdateNumber();
    vm.searchForItems = searchForItems;
    vm.searchText = '';
    vm.remarkList = ['Price Increase', 'Price Decrease', 'Price Update'];
    vm.priceUpdateItems = [];
    vm.itemSearchQuery = {};
    vm.addNewPriceUpdate = addNewPriceUpdate;
    vm.loadAllItems = loadAllItems;
    vm.loadItems = false;
    vm.deletePriceUpdate = deletePriceUpdate;
    vm.priceUpdateDateChanged = priceUpdateDateChanged;
    vm.itemChanged = itemChanged;
    vm.remarkChanged = remarkChanged;
    vm.proceedToPriceUpdate = proceedToPriceUpdate;
    vm.errorList = null;
    vm.isPerpetual = false;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.showPricePerBO = showPricePerBO;
    vm.cancel = cancel;
    vm.getItemPricePerOutlet = getItemPricePerOutlet;
    vm.getPricePerBusiness = getPricePerBusiness;
    vm.uploadCSV = uploadCSV;
    vm.sampleCSV = S3_URL_CONSTANTS.ADHOC_PRICE_UPDATE;
    vm.cancel = cancel;
    vm.deleteList = deleteList;
    vm.previewPriceUpdateCSVUpload = previewPriceUpdateCSVUpload;
    vm.priceUpdateCSVUpload = priceUpdateCSVUpload;
    vm.recordError = {};
    vm.priceUpdateDateChangedList = priceUpdateDateChangedList;
    vm.limitOptions = paginationConstants.limitOptions;
    vm.query = {
        limit: paginationConstants.itemsPerPage,
        page: 1
      };
    vm.allItemsList = [];
    vm.saveItemList = [];
    vm.addIteamList = addIteamList;
    vm.getItemPricePerOutletList = getItemPricePerOutletList;
    vm.filter = {};
    vm.confirmPopup = confirmPopup;
    vm.proceedToPriceUpdateList = proceedToPriceUpdateList;
    vm.cancelPopUp = cancelPopUp;
    vm.loadItemGroups = loadItemGroups;
    vm.keyUpSaveList = keyUpSaveList;
    vm.searchForItemsFilter = searchForItemsFilter;
    vm.isUpdate = isUpdate;

    function loadItemGroups() {
        if(vm.itemGroups)
          return false;
        ItemGroupService.getAllItemGroups({
          page: 0,
          size: paginationConstants.maxLimit,
          query: ''
        }).then(function(response) {
          vm.itemGroups = response.data;
        }, function(error) {
        });
      }

    function addIteamList(index) {
        var check = vm.saveItemList.filter(function (item) {
            return vm.allItemsList[index].itemId === item.itemId;
        })
        if(check.length === 0){
            if(vm.allItemsList[index].modifiedPrice && vm.allItemsList[index].remark) {
                vm.saveItemList.push(vm.allItemsList[index]);
                toastr.success('Item Added Successfully.', 'Item Added');
            } else {
                toastr.error('Please enter remark and new price!', 'Remark and New Price Required');
            }
        } else{
            toastr.success('Item Updated Successfully.', 'Item Updated');
        }
    }
    function keyUpSaveList(indexM,key) {
        var ind;
        vm.saveItemList.filter(function (item,index) {
            if(item.itemId == vm.allItemsList[indexM].itemId) {
                ind = index;
            }
        });
        vm.saveItemList[ind] = vm.allItemsList[indexM];
    }
    function deleteList(index) {
        var check = vm.saveItemList.filter(function (item) {
            return vm.allItemsList[index].itemId !== item.itemId;
        });
        vm.saveItemList = check;
        vm.allItemsList.splice(index,1);
    }
    /*
    *[price update via csv upload --- csv upload popup]
    */
    function uploadCSV(){
      vm.priceUpdateCSVItems=[];
      vm.recordError.responseList = [];
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/adhoc-tools/price-update/item-selection/buyer.price-update-csv.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }
    function loadAllItems() {
        vm.loadItems = true;
           var body = {
          'businessIdList': _.map(vm.priceUpdateFilter.businessList, _.property('id')),
          'brandIdlIst': _.map(vm.priceUpdateFilter.brandList, _.property('id')),
          'supplierId': vm.priceUpdateFilter.supplier.id
        }
        if(vm.filter.itemCode) {
            body['itemCode'] = vm.filter.itemCode;
        }
        if(vm.filter.itemName) {
            body['itemName'] = vm.filter.itemName;
        }
        if(vm.filter.itemGroup) {
            var grpList = []
            grpList.push(vm.filter.itemGroup)
            body['itemGroupIdList'] = grpList;
        }
        AdhocItemPriceUpdateService.loadAllListIteams(body, {page: vm.query.page-1, size: vm.query.limit}).then(function(response) {
            vm.totalItems = response.headers('X-Total-Count');
            vm.allItemsList = [];
            _.forEach(response.data, function (item) {
                var itemVar = item;
                itemVar['endDate'] = vm.tomorrow;
                itemVar['perpetual'] = vm.isPerpetual;
                itemVar['startDate'] = vm.todaysDate;
                _.forEach(vm.saveItemList, function (saveItem) {
                    saveItem['id'] = saveItem.itemId;
                    if(itemVar.itemId === saveItem.itemId){
                        itemVar = saveItem;
                    }
                  });
                if(itemVar.buyerItemCode){
                    vm.allItemsList.push(itemVar);
                } else {
                    itemVar['buyerItemCode'] = '';
                    vm.allItemsList.push(itemVar);
                }
              });
          }, function(error) {
            NotificationService.error({
              title: 'Load All List',
              error: error
            });
          });
    }
    /*
    *[cancel popup window]
    */
    function cancel(){
      vm.files=[];
      vm.priceUpdateCSVItems=[];
      vm.recordError=null;
      vm.priceUpdateList=false;
      $mdDialog.cancel();
    }

    function isUpdate(index) {
        var check = vm.saveItemList.filter(function (item) {
            return vm.allItemsList[index].itemId === item.itemId;
        })
        return check.length === 0;
    }
    function confirmPopup(){
        $mdDialog.show({
            templateUrl:'app/core/common-templates/price-update-popup.tmpl.html',
            controller:function(){
              return vm;
            },
            controllerAs: 'vm',
            targetEvent: event,
            clickOutsideToClose: true
          });
    }
    /**
     * [previewPriceUpdateCSVUpload Preview of Price Update using CSV]
    */
    function previewPriceUpdateCSVUpload(){
      var csvFile = vm.files[0];
      vm.isCsvFile = CsvUploadService.checkFileType(csvFile.lfFile);
      if (vm.isCsvFile) {
        UtilService.csvToJson(csvFile.lfDataUrl)
        .then(function (response) {
          vm.priceUpdateCSVItems = response;
          vm.recordError.responseList = [];
        });
      }
    }

    /**
     * [priceUpdateCSVUpload Price update using CSV]
     */
    function priceUpdateCSVUpload() {
      vm.errorList = null;
      var csvFile = vm.files[0];
      vm.recordError.responseList = [];
      //If no CSV selected show error
      if(!csvFile){
        toastr.error('Please upload a .csv file!', 'CSV File Required');
        return false;
      }

      //If CSV selected convert that to base64
      CsvUploadService.getFileBase64(csvFile.lfFile, function(base64CSV) {
        vm.csvUploadData = {

        };
        //Upload price update CSV
        AdhocItemPriceUpdateService.priceUpdateCSV({
          encodedFile: base64CSV,
          buyerIds: [BuyerDataService.getBuyerProfile().buyerUser.buyer.id],
          businessIdList: _.map(vm.priceUpdateFilter.businessList, _.property('id')),
          supplierId: vm.priceUpdateFilter.supplier.id
        })
        .then(function(response) {
          vm.cancel();
          vm.files = [];
          NotificationService.success({
            title: 'Price Update Request',
            message: 'alertMessage.SENT_SUCCESSFULLY'
          });
        }, function(error) {
          vm.files = [];
          vm.recordError = error.data;
          NotificationService.error({
            title: 'Price Update Request',
            error: error
          });
        });
      });

    }

    function init() {
      vm.priceUpdateFilter = AdhocItemPriceUpdateService.getPriceObject();
      vm.priceUpdateItems.push({});
      if (!vm.priceUpdateFilter)
        $state.go('buyer.adhoc-tools.price-update');
    }

    /**
     * [searchForItems Search items by query]
     */
    function searchForItems(queryType, queryText) {
      var deferred = $q.defer();
      if (!queryText) {
        return;
      }
      else {
        vm.itemSearchQuery = {
          'queryField': queryType,
          'queryText': queryText,
          'businessIdList': _.map(vm.priceUpdateFilter.businessList, _.property('id')),
          'brandIdlIst': _.map(vm.priceUpdateFilter.brandList, _.property('id')),
          'supplierId': vm.priceUpdateFilter.supplier.id
        }
        AdhocItemPriceUpdateService.searchAllItems(vm.itemSearchQuery)
          .then(function (response) {
              var dataRes = [];
              _.forEach(response.data, function (item) {
                if(item.buyerItemCode){
                    dataRes.push(item);
                } else {
                    item['buyerItemCode'] = ' ';
                    dataRes.push(item);
                }
              });
            deferred.resolve($filter('orderBy')(dataRes, 'itemName'));
          }, function (error) {
            deferred.reject(error);
          });
      }
      return deferred.promise;
    }

    function searchForItemsFilter(queryType, queryText) {
        var deferred = $q.defer();
        if (!queryText) {
          return;
        }
        else {
          vm.itemSearchQuery = {
            'queryField': queryType,
            'queryText': queryText,
            'businessIdList': _.map(vm.priceUpdateFilter.businessList, _.property('id')),
            'brandIdlIst': _.map(vm.priceUpdateFilter.brandList, _.property('id')),
            'supplierId': vm.priceUpdateFilter.supplier.id
          }
          AdhocItemPriceUpdateService.searchAllItems(vm.itemSearchQuery)
            .then(function (response) {
                var dataRes = [];
                _.forEach(response.data, function (item) {
                  if(item.buyerItemCode){
                      dataRes.push(item);
                  } else {
                      item['buyerItemCode'] = ' ';
                      dataRes.push(item);
                  }
                });
              deferred.resolve($filter('orderBy')(dataRes, queryType));
            }, function (error) {
              deferred.reject(error);
            });
        }
        return deferred.promise;
      }

    function itemChanged(priceUpdateItem, index) {
      var temp = angular.copy(priceUpdateItem);
      priceUpdateItem.itemName = temp.selectedItem.itemName;
      priceUpdateItem.itemCode = temp.selectedItem.itemCode;
      priceUpdateItem.uom = temp.selectedItem.uomName;
      priceUpdateItem.price = temp.selectedItem.price;
      priceUpdateItem.itemId = temp.selectedItem.id;
      priceUpdateItem.uomName = temp.selectedItem.uomName;
      priceUpdateItem.itemGroup = temp.selectedItem.itemGroup;
      priceUpdateItem.buyerItemCode = temp.selectedItem.buyerItemCode;
    }
    function remarkChanged(priceUpdateItem, index) {
        var temp = angular.copy(priceUpdateItem);
        priceUpdateItem.remark = temp.remark;
      }

    function priceUpdateDateChanged(date, type, index) {
      date = UTCDateService.utcToDate(date);
      //reset end date if it is before start date
      var isafter = moment(vm.priceUpdateItems[index].startDate).isAfter(moment(vm.priceUpdateItems[index].endDate));
      if (type === 'startDate' && isafter) {
        vm.priceUpdateItems[index].endDate = null;
      }
    }

    function priceUpdateDateChangedList(date, type, index) {
        date = UTCDateService.utcToDate(date);
        //reset end date if it is before start date
        var isafter = moment(vm.allItemsList[index].startDate).isAfter(moment(vm.allItemsList[index].endDate));
        if (type === 'startDate' && isafter) {
          vm.allItemsList[index].endDate = null;
        }
      }

    function proceedToPriceUpdate() {
      vm.errorList = null;
      vm.priceUpdateDTO = {
        "approvalRequiredFromBuyer": false,
        "businessIdList": _.map(vm.priceUpdateFilter.businessList, _.property('id')),
        "buyerIdList": [BuyerDataService.getBuyerProfile().buyerUser.buyer.id],
        "itemType": "SUPPLIER",
        "supplierId": vm.priceUpdateFilter.supplier.id,
        items: []
      };
      _.forEach(vm.priceUpdateItems, function (item) {
        vm.priceUpdateDTO.items.push({
          'endDate':  item.perpetual ? '' :item.endDate,
          'itemId': item.selectedItem.itemId,
          'modifiedPrice': item.modifiedPrice,
          'perpetual': item.perpetual,
          'remark': item.remark,
          'startDate': item.startDate,
          'needsUpdateToItem': true
        });
      });
      AdhocItemPriceUpdateService.newPriceUpdateItems(vm.priceUpdateDTO)
        .then(function (response) {
          NotificationService.success({
            title: 'Price Update Request',
            message: 'alertMessage.SENT_SUCCESSFULLY'
          });
          $state.go('buyer.adhoc-tools.price-update');
        }, function (error) {
          vm.translatedError = error.data;
          NotificationService.error({
            title: 'Price Update Request',
            error: error
          });
        });
    }

    function cancelPopUp() {
        $mdDialog.cancel();
    }
    function proceedToPriceUpdateList() {
        vm.errorList = null;
        vm.priceUpdateDTO = {
          "approvalRequiredFromBuyer": false,
          "businessIdList": _.map(vm.priceUpdateFilter.businessList, _.property('id')),
          "buyerIdList": [BuyerDataService.getBuyerProfile().buyerUser.buyer.id],
          "itemType": "SUPPLIER",
          "supplierId": vm.priceUpdateFilter.supplier.id,
          items: []
        };
        _.forEach(vm.saveItemList, function (item) {
          vm.priceUpdateDTO.items.push({
            'endDate':  item.perpetual ? '' :item.endDate,
            'itemId': item.itemId,
            'modifiedPrice': item.modifiedPrice,
            'perpetual': item.perpetual,
            'remark': item.remark,
            'startDate': item.startDate,
            'needsUpdateToItem': true
          });
        });
        AdhocItemPriceUpdateService.newPriceUpdateItems(vm.priceUpdateDTO)
          .then(function (response) {
            NotificationService.success({
              title: 'Price Update Request',
              message: 'alertMessage.SENT_SUCCESSFULLY'
            });
            $state.go('buyer.adhoc-tools.price-update');
          }, function (error) {
            vm.translatedError = error.data;
            NotificationService.error({
              title: 'Price Update Request',
              error: error
            });
          });
      }

    function addNewPriceUpdate() {
      vm.priceUpdateItems.push({});
    }

    function deletePriceUpdate(index) {
      vm.priceUpdateItems.splice(index, 1);
    }

    /**
     * [showPrice details SHows the detailed Price of BO's]
     * @param {Object} event
     */
    function showPricePerBO(event) {
      $mdDialog.show({
        templateUrl: 'app/supplier-modules/item-management/price-update/item-selection/supplier.price-update.price-per-bo.tmpl.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function getItemPricePerOutletList(priceUpdateItem, event) {
        SupplierItemPriceUpdateService.getItemPricePerOutlet({
          'itemId': priceUpdateItem.itemId,
          'businessIdList': _.map(vm.priceUpdateFilter.businessList, _.property('id'))
        }).then(function (response) {
          vm.outletList = response.data;
          vm.showPricePerBO(event);
        }, function (error) {
          NotificationService.error({
            title: 'Price Update Request',
            error: error
          });
        })
      }

    function getItemPricePerOutlet(priceUpdateItem, event) {
      SupplierItemPriceUpdateService.getItemPricePerOutlet({
        'itemId': priceUpdateItem.selectedItem.itemId,
        'businessIdList': _.map(vm.priceUpdateFilter.businessList, _.property('id'))
      }).then(function (response) {
        vm.outletList = response.data;
        vm.showPricePerBO(event);
      }, function (error) {
        NotificationService.error({
          title: 'Price Update Request',
          error: error
        });
      })
    }

    /**
     * [cancel Cancels the modal]
     */
    function cancel() {
      vm.priceUpdateCSVItems = [];
      vm.recordError.responseList = [];
      $mdDialog.cancel();
    }

    function getPricePerBusiness(eachOutlet) {
      return eachOutlet.price ? eachOutlet.price : eachOutlet.items.price;
    }
  }
})();
