/*
 * @Author: Lovin
 * @Date: 2020-06-22 23:58:25
 * @Last Modified by: Lovin
 * @Last Modified time: 2020-06-23 01:43:20
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('AdhocSupplierMapping', AdhocSupplierMapping);

  AdhocSupplierMapping.$inject = ['$q', '$http','$stateParams', '$timeout', 'Auth', 'API_CONSTANTS'];

  function AdhocSupplierMapping($q, $http, $stateParams, $timeout, Auth, API_CONSTANTS) {
    var vm = this;

   vm.id = $stateParams.id;
   vm.init = init();

    function init() {
      var deferred = $q.defer();
      $http.post(
        API_CONSTANTS.serviceBase  + 'api/' + API_CONSTANTS.baseVersion + 'buyer-supplier-mappings/supplier/invite/' +vm.id
      ).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
     }
    }

})();
