/**
 * @Author: Ajay
 * @Date:   18-Jul-2018 15:19 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.manage-payment-approval-level.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 30-Aug-2018 16:57 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerManagePaymentApprovalLevelController', BuyerManagePaymentApprovalLevelController);

  BuyerManagePaymentApprovalLevelController.$inject = ['$scope', '$state', '$element', 'toastr', 'NotificationService', 'BrandManagementService', 'paginationConstants', 'BuyerManagePaymentApprovalLevelService', 'BuyerDataService'];

  function BuyerManagePaymentApprovalLevelController($scope, $state, $element, toastr, NotificationService, BrandManagementService, paginationConstants, BuyerManagePaymentApprovalLevelService, BuyerDataService) {
    var vm = this;
    vm.getAllBuyerBrands = getAllBuyerBrands;
    vm.proceedToManagePaymentApprovalLevelSelection = proceedToManagePaymentApprovalLevelSelection;

    //Load all buyer business outlets
    function getAllBuyerBrands() {
      var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      if(vm.businessOutlets)
        return false;
      BrandManagementService.getAllBrandsByBuyerId({
        page: 0,
        size: paginationConstants.maxLimit,
        query: vm.searchText,
        buyerId : buyerId
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.brands = response.data;
      },function(error){
        NotificationService.error({
          title: 'Brand Listing',
          error: error
        });
      });
    }

    /**
     * [proceedToManagePaymentApprovalLevelSelection Proceed to level selection page]
     */
    function proceedToManagePaymentApprovalLevelSelection() {
      vm.approvalLevelFilter.brandId = vm.approvalLevelFilter.selectedBrand.id;
      BuyerManagePaymentApprovalLevelService.setApprovalLevelFilter(vm.approvalLevelFilter);
      $state.go('ezypayment.buyer.manage-payment-approval-level.level-selection',{});
    }

  }
})();
