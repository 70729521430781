/**
* @Author: Ajay
* @Date:   20-Mar-2017 11:56 +05:30
* @Project: Ezyprocure
* @Filename: buyer.order-history.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 25-Jan-2018 16:37 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerOrderHistoryController', BuyerOrderHistoryController);

  BuyerOrderHistoryController.$inject = ['$scope', '$rootScope', '$filter', '$state', '$stateParams', 'toastr', '$mdDialog', 'pagingParams', 'GLOBAL_CONSTANTS', 'UTCDateService', 'BuyerOrderHistoryService', 'paginationConstants', 'BuyerBuyNowService', 'BuyerBusinessUserMapService'];

  function BuyerOrderHistoryController($scope, $rootScope, $filter, $state, $stateParams, toastr, $mdDialog, pagingParams, GLOBAL_CONSTANTS, UTCDateService, BuyerOrderHistoryService, paginationConstants, BuyerBuyNowService, BuyerBusinessUserMapService) {

    var vm = this;
    vm.loadAll = loadAll;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.todaysDate = new Date();
    vm.filter = {};
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.responseStatuses = GLOBAL_CONSTANTS.RESPONSE_STATUSES;
    vm.filter.responseStatus = vm.responseStatuses[0];
    vm.days = GLOBAL_CONSTANTS.DAYS;
    vm.limitOptions = paginationConstants.limitOptions;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.getAllAllocatedSuppliersForBusiness =  getAllAllocatedSuppliersForBusiness;
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;

    /**
     * [sort to sort by PO date]
     */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
     * [loadAll loads all history]
     */
    function loadAll() {
      BuyerOrderHistoryService.getOrderHistory({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        //If dates are selected, they are converted to UTC format
        fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
        toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
        responseStatus: vm.filter.responseStatus !== "ALL" ? vm.filter.responseStatus : '',
        businessOutletName: !vm.filter.businessOutletName ? '' : vm.filter.businessOutletName,
        requirementNumber: !vm.filter.requirementNumber ? '' : vm.filter.requirementNumber,
        supplier: !vm.filter.supplier ? '' : vm.filter.supplier,
        day: !vm.filter.day ? '' : vm.filter.day
      }).then(function(response) {
        vm.historyItems = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      }, function(error) {
      });
    }
    
    
    function getAllAllocatedSuppliersForBusiness() {
      var params = {'businessId': !vm.filter.businessOutletId ? '' : vm.filter.businessOutletId};
      BuyerBuyNowService.getAllAllocatedSuppliersForBusiness(params).then(function(response){
        vm.supplierList = $filter('orderBy')(response.data, 'companyName');
      },function(error){
      });
    }

    /**
    * [getAllBuyerBusinessOutlets Gets all business outlets of buyer]
    */
   function getAllBuyerBusinessOutlets() {
    if (vm.businessOutlets){
      return false;
    }
    BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser().
      then(function(response) {
        vm.businessOutlets = response;
      }, function(error) {
      });
  }

  }
})();
