/*
 * @Author: Jayalekshmi 
 * @Date: 2019-05-28 16:55:02 
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-06-14 12:17:57
 * @Project: Ezyprocure
 * @Filename: supplier.delivery-conditions.service.js
 */



(function() {
    'use strict';
  
    angular.module('ezyprocureApp').factory('SupplierDeliveryConditionService', SupplierDeliveryConditionService);
  
    SupplierDeliveryConditionService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  
    function SupplierDeliveryConditionService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {
  
      var supplierDeliveryConditionService = {
        getAllSupplierDeliveryConditions: getAllSupplierDeliveryConditions,
        getSupplierDeliveryConditionById: getSupplierDeliveryConditionById,
        createSupplierDeliveryCondition: createSupplierDeliveryCondition,
        updateSupplierDeliveryCondition: updateSupplierDeliveryCondition,
        deleteSupplierDeliveryCondition: deleteSupplierDeliveryCondition
      };
  
      return supplierDeliveryConditionService;
  
      /* --  supplierDeliveryCondition CRUID operations  -- */
  
      /**
      * [getAllSupplierDeliveryConditions Gets All supplierDeliveryConditions]
      * @param  {[Object]} urlParams [urlParams]
      */
      function getAllSupplierDeliveryConditions(urlParams) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.DELIVERY_CONDITIONS_RESOURCE,
          method: "GET",
          params: urlParams,
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
  
      /**
      * [getSupplierDeliveryConditionById Get supplierDeliveryCondition details using supplierDeliveryCondition id]
      * @param  {[Integer]} supplierDeliveryConditionId [supplierDeliveryConditionId]
      * @return {[Object]}                    [supplierDeliveryCondition]
      */
      function getSupplierDeliveryConditionById(supplierDeliveryConditionId) {
        var deferred = $q.defer();
        $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.DELIVERY_CONDITIONS_RESOURCE + supplierDeliveryConditionId).then(function(data) {
          deferred.resolve(data.data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
  
      /**
      * [createSupplierDeliveryCondition Create a new supplierDeliveryCondition]
      * @param  {[Object]} supplierDeliveryCondition [supplierDeliveryCondition Object]
      * @return {[Object]}                  [response object - success of error]
      */
      function createSupplierDeliveryCondition(supplierDeliveryCondition) {
        var deferred = $q.defer();
        $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.DELIVERY_CONDITIONS_RESOURCE, supplierDeliveryCondition).then(function(data) {
          deferred.resolve(data.data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
  
      //Update supplierDeliveryCondition
      /**
      * [updateSupplierDeliveryCondition Update supplier Delivery condition details]
      * @param  {[Object]} supplierDeliveryCondition [supplierDeliveryCondition Object]
      * @return {[Object]}                  [response object - success of error]
      */
      function updateSupplierDeliveryCondition(supplierDeliveryCondition) {
        var deferred = $q.defer();
        $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.DELIVERY_CONDITIONS_RESOURCE, supplierDeliveryCondition).then(function(data) {
          deferred.resolve(data.data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
  
      /**
      * [deleteSupplierDeliveryCondition Soft Delete supplierDeliveryCondition using supplierDeliveryConditionId]
      * @param  {[Integer]} supplierDeliveryConditionId [supplierDeliveryConditionId]
      * @return {[Object]}                  [response object - success of error]
      */
      function deleteSupplierDeliveryCondition(supplierDeliveryConditionId) {
        var deferred = $q.defer();
        $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.DELIVERY_CONDITIONS_RESOURCE + supplierDeliveryConditionId).then(function(data) {
          deferred.resolve(data.data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
    }
  })();
  