/**
* @Author: Ajay
* @Date:   18-May-2017 15:17 +05:30
* @Project: Ezyprocure
* @Filename: buyer.gl-mapping.service.js
* @Last modified by:   Ajay
* @Last modified time: 30-May-2017 12:41 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('GlMappingService', GlMappingService);

  GlMappingService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function GlMappingService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var glMappingService = {
      getAllGlMappings: getAllGlMappings,
      createGLMapping : createGLMapping,
      deleteGLMapping : deleteGLMapping,
      isGlMapped : isGlMapped,
      getGlmappingById : getGlmappingById,
      modifyGlMapping : modifyGlMapping
    };

    return glMappingService;

    //get all gl mappings of logged in buyer. pagination as parameter.
    function getAllGlMappings(params,data) {
     var deferred = $q.defer();
     $http({
       url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.GL_CODE_MAPPING_RESOURCE + 'all',
       method: "POST",
       params: params,
       data: data
     }).then(function(data) {
       deferred.resolve(data);
     }).catch(function(message) {
       deferred.reject(message);
     });
     return deferred.promise;
    }

    function createGLMapping(glMapping) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.GL_CODE_MAPPING_RESOURCE, glMapping).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }


    function deleteGLMapping(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.GL_CODE_MAPPING_RESOURCE,
        method: "DELETE",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function isGlMapped(mapping) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.GL_CODE_MAPPING_RESOURCE +'is-mapped/',
        method: "GET",
        params: mapping,
      }).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

   /**
    * gets glMapping by passing the mappingId
    * @param {*} mappingId 
   */
    function getGlmappingById(mappingId){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.GL_CODE_MAPPING_RESOURCE + '/' + mappingId,
        method: "GET"
      }).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
      * edits the existing mapping
      * @param {*} glMapping 
    */
   function modifyGlMapping(glMapping) {
    var deferred = $q.defer();
    $http({
      url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.GL_CODE_MAPPING_RESOURCE ,
      method: "PUT",
      data: glMapping
    }).then(function(data) {
      deferred.resolve(data);
    }).catch(function(message) {
      deferred.reject(message);
    });
    return deferred.promise;
  }
  }
})();
