/**
* @Author: Ajay
* @Date:   01-Jun-2017 14:09 +05:30
* @Project: Ezyprocure
* @Filename: user-role.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 11-Apr-2018 11:25 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('UserRoleService', UserRoleService);

  UserRoleService.$inject = ['$http', '$q', 'API_CONSTANTS'];

  function UserRoleService($http, $q, API_CONSTANTS) {

    var userRoleService = {
      getAllUserRoles: getAllUserRoles,
      getUserRole: getUserRole,
      createUserRole: createUserRole,
      updateUserRole: updateUserRole,
      deleteUserRole: deleteUserRole
    };

    return userRoleService;

    /* --  UserRole CRUD operations  -- */

    //Get All  userRoles created by currently logged in user
    function getAllUserRoles(urlParams) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'user-roles',
        method: "GET",
        params: urlParams,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get userRole details using userRole id
    function getUserRole(userRoleId) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'user-roles/' + userRoleId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Create a new  userRole
    function createUserRole(userRole) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'user-roles', userRole).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update  userRole
    function updateUserRole(userRole) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'user-roles/', userRole).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Soft Delete  userRole using userRoleId
    function deleteUserRole(userRoleId) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'user-roles/' + userRoleId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
