/**
* @Author: Allan
* @Project: Ezyprocure
* @Created: 31-October-2018 11:34 +05:30
* @Filename: admin.manage-emails.service.js
* @Last modified by:   Allan
* @Last modified time: 31-October-2018 11:34 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('AdminEmailService', AdminEmailService);

  AdminEmailService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function AdminEmailService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var adminEmailService = {
      getEmails: getEmails,
      addEmail: addEmail,
      getEmailById: getEmailById,
      updateEmail: updateEmail,
      deleteEmail: deleteEmail
    };

    return adminEmailService;

    function getEmails() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.MANAGE_EMAILS,
        method: "GET"
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function addEmail(data){
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.MANAGE_EMAILS, data).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getEmailById(emailId){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.MANAGE_EMAILS + emailId,
        method: "GET"
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function updateEmail(data){
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.MANAGE_EMAILS, data).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function deleteEmail(emailId){
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.MANAGE_EMAILS + emailId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }


  }
})();
