/**
* @Author: Ajay
* @Date:   08-Mar-2017 15:58 +05:30
* @Project: Ezyprocure
* @Filename: manage-suppliers.bulk-upload.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 06-Nov-2017 15:43 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('ManageSupplierBulkUploadController', ManageSupplierBulkUploadController);

  ManageSupplierBulkUploadController.$inject = ['$scope', '$rootScope', '$state', 'toastr', '$mdDialog', 'Auth', 'ManageSupplierService', 'BuyerSupplierMappingService', 'CsvUploadService', 'NotificationService', 'S3_URL_CONSTANTS'];

  function ManageSupplierBulkUploadController($scope, $rootScope, $state, toastr, $mdDialog, Auth, ManageSupplierService, BuyerSupplierMappingService, CsvUploadService, NotificationService, S3_URL_CONSTANTS) {
    var vm = this;
    vm.getCSVBase64 = getCSVBase64;
    vm.supplierBulkUploadCSV = supplierBulkUploadCSV;
    vm.userRole = Auth.getUserRole();
    vm.ADMIN_SUPPLIERS_UPLOAD_CSV = S3_URL_CONSTANTS.ADMIN_SUPPLIERS_UPLOAD_CSV;

    function getCSVBase64() {
      var csvFile = vm.files[0];
      if (!csvFile) {
        toastr.error('Please upload a .csv file!', 'CSV File Required');
        return false;
      }
      CsvUploadService.getFileBase64(csvFile.lfFile, function(base64CSV) {
        vm.csvUploadData = {
          encodedCsvFile: base64CSV
        };
        vm.supplierBulkUploadCSV(vm.csvUploadData);
      });
    }

    function supplierBulkUploadCSV(uploadData) {
      ManageSupplierService.supplierBulkUploadCSV(uploadData).then(function(response) {
        vm.files = [];
        NotificationService.success({
          title: 'Supplier List',
          message: 'alertMessage.UPLOADED_SUCCESSFULLY'
        });
      }, function(error) {
        vm.recordError = error.data;
        NotificationService.error({
          title: 'Supplier List Upload',
          error: error
        });
      });
    }
  }
})();
