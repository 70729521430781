/**
* @Author: Ajay
* @Date:   24-Feb-2017 12:40 +05:30
* @Project: Ezyprocure
* @Filename: buyer.user-management.add.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 06-Nov-2017 14:52 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerUserAddController', BuyerUserAddController);

  BuyerUserAddController.$inject = ['$scope', '$state', 'BuyerUserManagementService', 'BuyerDataService', 'UserRoleService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'NotificationService'];

  function BuyerUserAddController ($scope, $state, BuyerUserManagementService, BuyerDataService, UserRoleService, GLOBAL_CONSTANTS, paginationConstants, NotificationService) {
    var vm = this;

    vm.buyerUser = {};
    vm.buyerUser.user = {};
    vm.getUserRoles = getUserRoles;
    vm.save = save;
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.getUserRoles = getUserRoles;

    function save () {
      vm.buyerProfile = BuyerDataService.getBuyerProfile();
      vm.buyerUser.buyerId = vm.buyerProfile.buyerUser.buyer.id;
      // Call Buyer user management service with buyer user object as parameter
      BuyerUserManagementService.createBuyerUser(vm.buyerUser).then(function(response){
        $scope.$emit('ezyprocureApp:buyerBusinessCreated', response);
        NotificationService.success({
          title: 'Buyer User',
          message: 'alertMessage.CREATED_SUCCESSFULLY'
        });
        $state.go('buyer.tools.user-management', {reload: false});
      },function(error) {
        NotificationService.error({
          title: 'Buyer User Creation',
          error: error
        });
      });
    }

    function getUserRoles() {
      if(vm.userRoles)
        return false;
      UserRoleService.getAllUserRoles({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response){
        vm.userRoles = response.data;
      },function(error) {
        vm.userRoles = null;
      });
    }

  }
})();
