/*
 * @Author: Ajay 
 * @Date: 2019-02-11 11:37:07 
 * @Project: Ezyprocure
 * @Filename: ezyap.peppol-invoices-summary.service.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-10-24 10:40:07
 */

(function () {
  'use strict';

  angular.module('ezyprocureApp').factory('ExtenalPeppolInvoicesService', ExtenalPeppolInvoicesService);

  ExtenalPeppolInvoicesService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function ExtenalPeppolInvoicesService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var ExtenalPeppolInvoicesService = {
      sendInvoiceById: sendInvoiceById,
      sendAllInvoice: sendAllInvoice,
      updateExportFlag:updateExportFlag
    };
    return ExtenalPeppolInvoicesService;

    /**
     * [send invoice by invoice id]
     * @param {Integer} invoiceId [id of the invoice to be send]
     */
    function sendInvoiceById(invoiceId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/invoices/upload/' + invoiceId,
        method: "POST"
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [sendAllInvoice sends all invoices]
     * @param {Integer} invoiceIdList [id of the invoice to be send]
     */
    function sendAllInvoice(invoiceIdList) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/invoices/upload-ap/bulk',
        method: "PUT",
        data: invoiceIdList
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

     /**
     * [updateExportFlag Updates the export flag]
     * @param {List} invoiceIdList [id of the invoice exported]
     */
    function updateExportFlag(invoiceIdList) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/invoices/export',
        method: "PUT",
        data: invoiceIdList
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();