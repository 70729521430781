/*
 * @Author: Jayalekshmi 
 * @Date: 2019-02-19 17:38:54 
 * @Project: Ezyprocure
 * @Filename: buyer.sats-logs-history.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-04-10 11:20:21
 */



(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .controller('SATSLogsHistoryController', SATSLogsHistoryController);
  
    SATSLogsHistoryController.$inject = ['pagingParams',  'UTCDateService', 'NotificationService', 'SatsManagementService', 'SupplierUserManagementService', 'paginationConstants', 'Auth'];
  
    function SATSLogsHistoryController(pagingParams, UTCDateService, NotificationService, SatsManagementService, SupplierUserManagementService, paginationConstants,  Auth) {
      var vm = this;
      vm.userRole = Auth.getUserRole();
      vm.predicate = pagingParams.predicate;
      vm.reverse = pagingParams.ascending;
      vm.todaysDate = new Date();
      vm.loadAll = loadAll;
      vm.getAllSupplierUsers = getAllSupplierUsers;
      vm.selectedTransactionType = null;
      vm.types = ['PO', 'SO', 'SR', 'TO']
      vm.query = {
        limit: paginationConstants.itemsPerPage,
        page: 1
      };
  
      vm.limitOptions = paginationConstants.limitOptions;
      
      /**
      * [sort to sort by parameters]
      */
      function sort() {
        var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
        return result;
      }
  
      /**
       * [getAllSupplierUsers Gets al supplier users]
       */
      function getAllSupplierUsers(){
        if(vm.supplierUsers)
          return false;
        SupplierUserManagementService.getAllSupplierUsers({
          page: 0,
          size: paginationConstants.maxLimit,
          sort: sort(),
          query: ''
        }).then(function(response){
          vm.supplierUsers = response.data;
        },function(error){
        });
      }

      /**
      * [loadAll loads all SATS history]
      */
      function loadAll() {
        SatsManagementService.getLogReports({
          page: vm.query.page - 1,
          size: vm.query.limit,
          sort: sort(),
          fromDate: !vm.fromDate ? null : UTCDateService.utcFromDate(vm.fromDate),
          toDate: !vm.toDate ? null : UTCDateService.utcToDate(vm.toDate),
          type: !vm.selectedType ? null : vm.selectedType
        }).then(function(response) {
          vm.logs = response.data;
          vm.totalItems = response.headers('X-Total-Count');
        }, function(error) {
          NotificationService.error({
            title: 'Audit Transaction Data',
            error: error
          });
        });
      }
    }
  })();
  