/**
* @Author: Abhay
* @Date:   06-Jan-2021 12:32 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.card-tokenize.controller.js
* @Last modified by:   Abhay
* @Last modified time: 06-Jan-2021 12:32 +05:30
*/



(function () {
    'use strict';

    angular
        .module('ezyprocureApp')
        .controller('CardTokenizeController', CardTokenizeController);

    CardTokenizeController.$inject = ["CardSecurityService", "$state", "$stateParams", "$rootScope", "$mdDialog", "uuid", "paginationConstants", "CardsService", "BuyerSupplierMappingService", "BuyerDataService", "BrandManagementService", "NotificationService", "GLOBAL_CONSTANTS"];

    function CardTokenizeController(CardSecurityService, $state, $stateParams, $rootScope, $mdDialog, uuid, paginationConstants, CardsService, BuyerSupplierMappingService, BuyerDataService, BrandManagementService, NotificationService, GLOBAL_CONSTANTS) {

        var vm = this;
        vm.userRole = $stateParams.userrole;
        vm.secretKey = GLOBAL_CONSTANTS.CYBERSOURCE.SECRET_KEY;
        vm.requestFields = {
            access_key: GLOBAL_CONSTANTS.CYBERSOURCE.ACCESS_KEY,
            profile_id: GLOBAL_CONSTANTS.CYBERSOURCE.PROFILE_ID,
            signed_date_time: new Date().toJSON().split(".")[0] + "Z",
            transaction_uuid : uuid.v4(),
            transaction_type: "create_payment_token",
            reference_number: new Date().getTime() + "",
            signed_field_names: "access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency",
            locale: "en",
            unsigned_field_names: "",
            amount: 0,
            currency: "SGD",
            merchant_id: "gpsgh065006777701"
        }
        vm.agreeTermsAndConditions = false;
        vm.brandIds = [];
        vm.getBrands = getBrands;
        vm.showTermsDialog = showTermsDialog;
        vm.cancel = cancel;
        vm.saveTransactionUuid = saveTransactionUuid;
        vm.loadAllBuyerMappedSuppliers = loadAllBuyerMappedSuppliers;


        signFields();

        function loadAllBuyerMappedSuppliers() {
            var params = {
                page: 0,
                size: paginationConstants.maxLimit
            };
            var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
            BuyerSupplierMappingService.getAllBuyerMappedSuppliers(buyerId, params).then(function (response) {
                vm.suppliers = response.data;
            }, function (error) {
            });
        }

        function saveTransactionUuid() {
            if(vm.userRole == 'SUPPLIER' && !vm.supplierId) {
                return;
            }
            CardsService.saveTransactionUuid({
                transactionUuid: vm.requestFields.transaction_uuid,
                referenceNumber: vm.requestFields.reference_number,
                brandId: vm.brandId,
                supplierId: vm.supplierId,
            }).then(function (response) {
                
            }, function (error) {
                NotificationService.error({
                    title: 'Cybersource Card Details',
                    error: error
                });
            });
        }

        function getBrands() {
            var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
            BrandManagementService.getAllBrandsByBuyerId({
                page: 0,
                size: paginationConstants.maxLimit,
                query: '',
                supplierId: [],
                buyerId: buyerId
            }).then(function (response) {
                vm.brands = response.data;
            });
        }

        function cancel() {
            $mdDialog.cancel();
        }

        function showTermsDialog(event) {
            event.preventDefault();
            $mdDialog.show({
                templateUrl: 'app/ezypayment-modules/buyer-modules/card-tokenize/ezypayment.card-tokenize-terms-conditions.tmpl.html',
                controller: function () {
                    return vm;
                },
                controllerAs: 'vm',
                targetEvent: event,
                clickOutsideToClose: false,
            }).then(function () {

            });
        }

        function signFields() {
            CardSecurityService.setRequestFields(vm.requestFields);
            vm.requestFields.signature = CardSecurityService.sign(vm.secretKey);
        }

        function generateUniqId() {
            var c = (new Date().getTime()) / 1000;
            var d = c.toString(16).split(".").join("");
            while (d.length < 14) d += "0";
            return d;
        }
    }
})();
