/**
 * @Author: Ajay
 * @Date:   21-Apr-2017 17:54 +05:30
 * @Project: Ezyprocure
 * @Filename: supplier-mapping.state.js
 * @Last modified by:   Liya
 * @Last modified time: 12-Feb-2020 18:40
 */



(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .config(stateConfig);
  
    stateConfig.$inject = ['$stateProvider'];
  
   
    function stateConfig($stateProvider) {
      $stateProvider.state('supplier.mapping', {
        parent: 'supplier',
        url: '/buyer-directory',
        data: {
          authorities: ['ROLE_SUPPLIER']
        },
        views: {
          'content@': {
            templateUrl: 'app/common-modules/buyer-supplier-mapping-table/buyer.supplier-mapping.html',
            controller: 'BuyerSupplierMappingTableController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('buyer');
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('supplier');
            $translatePartialLoader.addPart('user-management');
            return $translate.refresh();
          }]
        }
      }).state('supplier.mapping.add', {
        parent: 'supplier.mapping',
        url: '/add/:role',
        data: {
          authorities: ['ROLE_SUPPLIER'],
          pageTitle: 'Add new Supplier Mapping'
        },
        views: {
          'content@': {
            templateUrl: 'app/common-modules/buyer-supplier-mapping-table/templates/buyer-supplier.add.tmpl.html',
            controller: 'BuyerSupplierMappingAddController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('supplier');
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('itemBuyerMapping');
            $translatePartialLoader.addPart('items');
            return $translate.refresh();
          }]
        }
      })
      .state('supplier.mapping.details', {
        parent: 'supplier.mapping',
        url: '/details/:visibility/:Id/:relate/:requestId/:value',
        data: {
          authorities: ['ROLE_SUPPLIER'],
          pageTitle: 'Buyer detail page '
        },
        params: {
          relate: { squash: true, value: null }
        },
        views: {
          'content@': {
            templateUrl: 'app/common-modules/buyer-supplier-mapping-table/edit/buyer-supplier-edit.html',
            controller: 'BuyerSupplierEditController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('supplier');
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('paymentTypes');
            $translatePartialLoader.addPart('itemBuyerMapping');
            $translatePartialLoader.addPart('items');
            return $translate.refresh();
          }]
        }
      });
    }
  })();
  