/*
 * @Author: Ajay 
 * @Date: 2019-03-15 10:14:39 
 * @Project: Ezyprocure
 * @Filename: ezyap.supplier.manage-delivery-details.state.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-09-17 15:41:27
 */
 
(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
      .state('ezyap.supplier.manage-delivery-details', {
        parent: 'ezyap.supplier',
        url: '/manage-delivery-details',
        data: {
          authorities: ['ROLE_SUPPLIER']
        },
        views: {
          'content@': {
            templateUrl: 'app/common-modules/ezyap-tab-templates/manage-delivery-details/ezyap.manage-delivery-details.html',
            controller: 'EzyapDeliveryDetailsController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,desc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort)
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
          }]
        }
      })
      .state('ezyap.supplier.manage-delivery-details.add', {
        parent: 'ezyap.supplier.manage-delivery-details',
        url: '/manage-delivery-details/add',
        data: {
          authorities: ['ROLE_SUPPLIER']
        },
        views: {
          'content@': {
            templateUrl: 'app/common-modules/ezyap-tab-templates/manage-delivery-details/add/ezyap.manage-delivery-details.add.html',
            controller: 'EzyapAddDeliveryDetailsController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
            return true;
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
          }]
        }
      })
      .state('ezyap.supplier.manage-delivery-details.edit', {
        parent: 'ezyap.supplier.manage-delivery-details',
        url: '/manage-delivery-details/edit/:id',
        data: {
          authorities: ['ROLE_SUPPLIER']
        },
        views: {
          'content@': {
            templateUrl: 'app/common-modules/ezyap-tab-templates/manage-delivery-details/edit/ezyap.manage-delivery-details.edit.html',
            controller: 'EzyapEditDeliveryDetailsController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
            return true;
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
          }]
        }
      })
  }
})();
