/**
 * @Author: Jayalekshmi
 * @Date:   2018-06-28T13:51:21+05:30
 * @Filename: buyer.brand-buyer-user-mapping.controller.js
 * @Last modified by:   Jayalekshmi
 * @Last modified time: 2018-06-28T16:00:33+05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BrandBuyerUserMapController', BrandBuyerUserMapController);

  BrandBuyerUserMapController.$inject = ['$scope', '$rootScope' ,'$state', 'pagingParams', 'paginationConstants', 'NotificationService', 'BrandBuyerUserMapService'];

  function BrandBuyerUserMapController($scope, $rootScope, $state, pagingParams, paginationConstants, NotificationService, BrandBuyerUserMapService) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.sort = sort;
    vm.loadAll = loadAll;
    vm.brandBuyerUserMappingSearch = '';
    vm.deleteBrandBuyerUserMapping = deleteBrandBuyerUserMapping;
    vm.mapping = {};
   

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
     * [loadAll Load All which are mapped]
     * @param  {[String]} search [Search parameter]
     */
    function loadAll(search) {
      if (!search) {
        vm.brandBuyerUserMappingSearch = '';
      }
      BrandBuyerUserMapService.getAllBrandBuyerUserMappings({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.brandBuyerUserMappingSearch,
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.brandBuyerUserMappings = response.data;
      },function(error){
        NotificationService.error({
          title: 'Brand - Buyer User Mapping List',
          error: error
        });
      });
    }

    vm.loadAll();

    /**
     * [deleteBrandBuyerUserMapping Softdeletes selected brand and buyer-user]
     * @param  {[Integer]} mappingId [Id of the mapping]
     */
    function deleteBrandBuyerUserMapping(mappingId) {
      BrandBuyerUserMapService.deleteBrandBuyerUserMapping(mappingId).then(function(response){
        NotificationService.success({
          title: 'Brand - Buyer User',
          message: 'Mapping deleted successfully'
        });
        vm.loadAll();
      },function(error) {
        NotificationService.error({
          title: 'Brand - Buyer User - Delete Mapping',
          error: error
        });
      });
    }
  }
})();
