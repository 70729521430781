/**
* @Author: Ajay
* @Date:   21-Apr-2017 11:37 +05:30
* @Project: Ezyprocure
* @Filename: buyer.invoice-report.individual-details.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-11-29T13:34:42+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerInvoiceReportIndividualDetailsController', BuyerInvoiceReportIndividualDetailsController);

  BuyerInvoiceReportIndividualDetailsController.$inject = ['$scope','$mdDialog', '$state', '$stateParams', 'toastr', 'BuyerReceivingOrderService', 'NotificationService', 'UTCDateService','EzypaymentSipInvoiceAuthorizationRequestService', 'PDFExportService', 'EXPORT_CONSTANTS', 'CSVExportService', 'Auth', 'GLOBAL_CONSTANTS', 'BuyerDataService', 'EzyapInvoiceService', 'BuyerAdhocPOFormService'];

  function BuyerInvoiceReportIndividualDetailsController($scope,$mdDialog, $state, $stateParams, toastr, BuyerReceivingOrderService, NotificationService, UTCDateService,EzypaymentSipInvoiceAuthorizationRequestService, PDFExportService, EXPORT_CONSTANTS, CSVExportService, Auth, GLOBAL_CONSTANTS, BuyerDataService, EzyapInvoiceService, BuyerAdhocPOFormService) {
    var vm = this;
    vm.init = init;
    vm.purchaseOrderNumber = $stateParams.purchaseOrderNumber;
    vm.deliveryDate = new Date($stateParams.deliveryDate);
    vm.invoiceId = $stateParams.id;
    vm.approvalStatus = $stateParams.status;
    vm.xeroInvoiceHeader = EXPORT_CONSTANTS.CSV.xeroInvoiceHeader;
    vm.geoInvoiceHeader = EXPORT_CONSTANTS.CSV.geoInvoiceHeader;
    vm.getXEROInvoiceCSVData = getXEROInvoiceCSVData;
    vm.getGEOInvoiceCSVData = getGEOInvoiceCSVData;
    vm.getIndividualInvoicePDF = getIndividualInvoicePDF;
    vm.userRole = Auth.getUserRole();
    vm.reportType = "INVOICE";
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.defaultDateTimeFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_TIME_FORMAT;
    vm.accessForPriceDisplay = Auth.hasPermission('accessForPriceDisplay');
    vm.getSipInvoiceAuthorizationDetailsById =getSipInvoiceAuthorizationDetailsById;
    vm.updateInvoiceApprovalStatus = updateInvoiceApprovalStatus;
    vm.sipApproveOrReject = sipApproveOrReject;
    vm.cancel = cancel;
    vm.printInvoiceReport = printInvoiceReport;
    vm.getInvoiceDetails = getInvoiceDetails;
    vm.downloadURI = downloadURI;

    /**
     * [init loads the function initially]
     */
    function init(){
      BuyerAdhocPOFormService.gstConfigDetails().then(function (res) {
        localStorage.setItem("gst-config", JSON.stringify(res[0].configValue));
      });
      if(vm.invoiceId !="" && vm.invoiceId != undefined){
        vm.getSipInvoiceAuthorizationDetailsById(vm.invoiceId);
      }
      else{
        loadAll();
      }
    }

     /**
      * [sipApproveOrReject Updates the Invoice approval status and add remarks]
      * @param {*} action
      */

    function sipApproveOrReject(action){
      vm.action = action;
      $mdDialog.show({
        templateUrl: 'app/ezypayment-modules/buyer-modules/sip-invoice-authorization-request/ezypayment.sip-invoice-authorization-request.temp.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }
    function cancel(){
      $mdDialog.cancel();
    }

     /**
      * [updateInvoiceApprovalStatus Updates the Invoice approval status]
      * @param {*} action
      */
     function updateInvoiceApprovalStatus() {
       vm.cancel();

      vm.approvalIdList = [vm.invoiceId];
      EzypaymentSipInvoiceAuthorizationRequestService.updateSIPApprovalWithStatus(vm.approvalIdList, vm.action, vm.remarks).then(function(response) {
        NotificationService.success({
          title: 'global.sipInvoiceApproval',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });

        $state.go('ezypayment.buyer.sip-invoice-authorization-request',{reload: false, initialActive: true})

      }, function(error) {
        NotificationService.error({
          title: 'global.sipInvoiceApproval',
          error: error
        });
      });
    }
   /**
     * [Load all items by id]
     */
    function getSipInvoiceAuthorizationDetailsById(id){
      EzypaymentSipInvoiceAuthorizationRequestService.getSipInvoiceAuthorizationDetails(id)
      .then(function(response){
        vm.purchaseOrderDetails = response.data;
        vm.purchaseOrderNumber = vm.purchaseOrderDetails.purchaseOrderNumber;
        vm.deliveryDate = vm.purchaseOrderDetails.items[0].deliveryDate;
        vm.requirementNumber = vm.purchaseOrderDetails.items[0].requirementNumber;
        vm.selectedDeliveryDate = vm.purchaseOrderDetails.items[0].deliveryDate;

      },function(error) {
       NotificationService.error({
         title: 'SIP Authorization Request',
         error: error
       });
     });
    }
    /**
     * [loadAll Gets GRN item Details by PO number]
     */
     function loadAll() {
       BuyerReceivingOrderService.getGRNItemDetails(vm.purchaseOrderNumber, {
         deliveryDate: !vm.deliveryDate ? '' : vm.deliveryDate,
       }).then(function(response){
         vm.purchaseOrderDetails = response.data;
        // vm.purchaseOrderDetails.invoice.deliveryCharge = vm.purchaseOrderDetails.deliveryCharges;
         vm.userDetails =  vm.purchaseOrderDetails.supplier;
         vm.requirementNumber = vm.purchaseOrderDetails.items[0].requirementNumber;
         vm.selectedDeliveryDate = vm.purchaseOrderDetails.items[0].deliveryDate;
         vm.purchaseOrderNumber = vm.purchaseOrderDetails.purchaseOrderNumber;
         vm.invoiceType = vm.purchaseOrderDetails.invoice.type;
         if (vm.purchaseOrderDetails.invoice.otherCharges){
          vm.purchaseOrderDetails.invoice.otherCharges = JSON.parse(vm.purchaseOrderDetails.invoice.otherCharges);
          vm.keys = Object.keys(vm.purchaseOrderDetails.invoice.otherCharges)
        }
        vm.gstPercentage = (vm.purchaseOrderDetails.invoice.addedGst * 100) / vm.purchaseOrderDetails.invoice.invoiceTotal;
         vm.getInvoiceDetails();
       },function(error){
         NotificationService.error({
           title: 'Invoice Report Details',
           error: error
         });
       });
     }

    function getXEROInvoiceCSVData() {
      return CSVExportService.getXEROInvoiceCSV(vm.purchaseOrderDetails);
    }

    function getGEOInvoiceCSVData() {
      if (!vm.purchaseOrderDetails.items[0].glCodeMappings) {
        toastr.error('NO GL Data!', 'GEO CSV Export');
      }
      return CSVExportService.getGEOInvoiceCSV(vm.purchaseOrderDetails);
    }

    function downloadURI(uri, name)
    {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = name;
      link.href = uri;
      link.click();
    }

    function getIndividualInvoicePDF() {
      PDFExportService.getInvoicePDF(vm.purchaseOrderNumber,{
        deliveryDate: !vm.deliveryDate ? '' : vm.deliveryDate,
      }).then(function(response){
        downloadURI(response.data.message, "PDF_" + vm.purchaseOrderNumber);
      },function(error) {
        NotificationService.error({
          title: 'PDF Export',
          error: error
        });
      });
    }

    /**
     * [printInvoiceReport Prints the purchase request details]
     */
    function printInvoiceReport(id) {
      PDFExportService.printPDF(id);
    }

    //get Invoice Details to export xml
    function getInvoiceDetails() {
        EzyapInvoiceService.getInvoiceDetails(vm.purchaseOrderDetails.invoice.id).then(function(response){
          vm.invoiceDetails = response.data;
        },function(error){
          NotificationService.error({
            title: 'global.navbar.invoiceReport',
            error: error
          });
        });
      }
  }
})();
