/*
 * @Author: Lovin Chacko
 * @Date: 2021-01-06 14:36:45
 * @Last Modified by: Lovin C
 * @Last Modified time: 2021-11-12 12:23:25
 */



(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('EzypaymentEGiroFundTransfer', EzypaymentEGiroFundTransfer);

  EzypaymentEGiroFundTransfer.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', '$mdDialog', 'toastr', 'pagingParams', 'UTCDateService', 'NotificationService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'BuyerBusinessService', 'ManageBuyerService', 'ManageSupplierService', 'EXPORT_CONSTANTS', 'CSVExportService', 'EzypaymentEGiroFundTransferService', 'GLOBAL_CONFIG_CONSTANTS'];

  function EzypaymentEGiroFundTransfer($scope, $rootScope, $q, $filter, $state, $mdDialog, toastr, pagingParams, UTCDateService, NotificationService, GLOBAL_CONSTANTS, paginationConstants, BuyerBusinessService, ManageBuyerService, ManageSupplierService, EXPORT_CONSTANTS, CSVExportService, EzypaymentEGiroFundTransferService, GLOBAL_CONFIG_CONSTANTS) {

    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.todaysDate = new Date();
    vm.loadAll = loadAll;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.listViews = GLOBAL_CONSTANTS.LIST_VIEWS;
    vm.limitOptions = paginationConstants.limitOptions;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.defaultDateTimeFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_TIME_FORMAT;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.getAllBuyersBusinessOutlets = getAllBuyersBusinessOutlets;
    vm.loadDropdowns = loadDropdowns;
    vm.getAllSuppliersMappedToPayment = getAllSuppliersMappedToPayment;
    vm.filter = {};
    vm.transactionPayouts = transactionPayouts;
    vm.checkAllRows = checkAllRows;
    vm.getAllBuyersMappedToPayment = getAllBuyersMappedToPayment;
    vm.reset = reset;
    vm.getBrands = getBrands;
    vm.getSuppliers = getSuppliers;
    vm.systemTypes = GLOBAL_CONSTANTS.SYSTEM_TYPES;
    //vm.systemTypesConsolidated = ['INTERNAL', 'EZYDDA'];
    vm.paymentStatus = GLOBAL_CONSTANTS.OCBC_PAYMENT_STATUSES;
    vm.paymentList = [];
    vm.eGiroFundTransferCSVHeader = EXPORT_CONSTANTS.CSV.eGiroFundTransferCSVHeader;
    vm.eGiroFundTransferCSV = eGiroFundTransferCSV;
    vm.statusClass = GLOBAL_CONFIG_CONSTANTS.eGIRO_FUND_TRANSFER.LABEL_CLASS;
    vm.paymentType = ['FAST', 'NORMAL'];
    vm.adminAccessPassword = GLOBAL_CONSTANTS.eGIRO_ADMIN_ACCESS_PASSWORD;
    vm.init = init;
    vm.showEnterCodeModal = showEnterCodeModal;
    vm.enableUpdate = enableUpdate;
    vm.isAccess = false;
    vm.cancel = cancel;
    vm.calculateSum = calculateSum;
    vm.triggerH2hCollection = triggerH2hCollection;
    vm.triggerH2hPayment = triggerH2hPayment;
    vm.readGiroResponseFiles = readGiroResponseFiles;

    function init() {
      vm.showEnterCodeModal();
      checkPasswordAccess();
    }

    function triggerH2hCollection(isCollection, isGenerate) {
      var selectedFunds = vm.egiroFunds.filter(function (egiro) {
        return egiro.selected;
      });
      if (!selectedFunds.length) {
        toastr.error('Please select some data');
        return;
      }
      var pendingCollections = _.filter(selectedFunds, _.matches({ 'paymentStatus': 'PENDING' }));
      if (pendingCollections.length != selectedFunds.length) {
        toastr.error('Please select pending invoices', 'H2H Collection Failed!', NotificationService.getToastrOptions());
        return;
      }
      var idList = selectedFunds.map(function (egiro) {
        return egiro.id;
      });
      var params = {
        giroList: idList.join(),
        isCollection: isCollection,
        paymentType: vm.filter.paymentType,
        externalSystem: vm.filter.systemType,
        uploadH2H: !isGenerate
      }
      EzypaymentEGiroFundTransferService.h2hCollection(params).then(function (res) {
        toastr.success('H2H' + (isCollection ? ' Collection' : ' Payment') + ' Successful');
        loadAll();
        downloadFiles(res.data);
      }, function (error) {
        toastr.error('H2H collection Failed');
      })
    }

    function downloadFiles(urls) {
      if(!urls || !urls.length) { return; }
      vm.downloadInterval = setInterval(processUrls, 300, urls);
    }

    function processUrls(urls) {
      var url = urls.pop();
      download(url, url.split("/").pop());
      if (urls.length === 0) {
        clearInterval(vm.downloadInterval);
      }
    }

    function download(url, name) {
      var a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('download', name);
      a.setAttribute('target', '_blank');
      a.click();
    }

    function triggerH2hPayment() {
      vm.selectedData = _.filter(vm.egiroFunds, _.matches({ 'selected': true }));
      if (vm.selectedData.length == 0) {
        toastr.error('Please select atleast one Invoice', 'H2H Payment Failed!', NotificationService.getToastrOptions());
        return;
      }
      var pendingPayments = _.filter(vm.selectedData, _.matches({ 'paymentStatus': 'PAID' }));
      if (pendingPayments.length != vm.selectedData.length) {
        toastr.error('Please select invoices with \'PAID\' payment status', 'H2H Payment Failed!', NotificationService.getToastrOptions());
        return;
      }
      var giroStatus = 0;
      _.forEach(vm.selectedData, function (each) {
        if (each.egiroStatus === 'TRUE') {
          giroStatus += 1;
        }
      });
      if (giroStatus > 0) {
        toastr.error('Cannot select eGiro success Invoices', 'H2H Payment Failed!', NotificationService.getToastrOptions());
        return;
      }
      var h2hPaymentRequestDTO = [];
      _.forEach(vm.selectedData, function (each) {
        h2hPaymentRequestDTO.push({
          'transactionAmount': each.amount,
          'transactionPayoutsId': each.payoutList
        });
      });
      EzypaymentEGiroFundTransferService.h2hPayment({
        'externalSystem': vm.filter.systemType,
        'paymentType': vm.filter.paymentType ? vm.filter.paymentType : ''
      }, h2hPaymentRequestDTO).then(function (response) {
        NotificationService.success({
          title: 'eGiro Fund Transfer',
          message: 'H2H Payment Successful'
        });
        downloadFiles(response.data);
        loadAll();
      },
        function (error) {
          NotificationService.error({
            title: 'eGiro Fund Transfer',
            error: error
          });
          loadAll();
        })
    }

    function readGiroResponseFiles(){
      EzypaymentEGiroFundTransferService.readGiroResponseFiles()
      .then(function(response){
        NotificationService.success({
          title: 'global.buttons.readGiroResponseFiles',
          message: 'alertMessage.COMPLETED_SUCCESSFULLY'
        });
      },function(error){
        NotificationService.error({
          title: 'global.buttons.readGiroResponseFiles',
          error: error
        });
      });
    }

    function downloadURI(uri, name) {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = name;
      link.href = uri;
      link.click();
    }

    function checkPasswordAccess() {
      if (vm.isAccess) {
        loadAll();
      }
    }

    function showEnterCodeModal(event) {
      $mdDialog.show({
        templateUrl: 'app/ezypayment-modules/admin-modules/tools/e-giro-fund-transfer/templates/ezypayment.admin.e-giro-fund-transfer.password-modal.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: false
      });
    }

    function enableUpdate(passcode) {
      if (vm.adminAccessPassword === passcode) {
        NotificationService.success({
          title: 'eGiro Fund Transfer',
          message: 'Authenticated SuccessFully'
        });
        vm.isAccess = true;
        closeEnterCodeModal();
        checkPasswordAccess();
      } else {
        NotificationService.simpleErrorToast({
          title: 'eGiro Fund Transfer',
          message: 'alertMessage.MERCHANT_CODE_INCORRECT'
        });
      }
    }

    function closeEnterCodeModal() {
      vm.passcode = '';
      $mdDialog.cancel();
    }

    function cancel() {
      $state.go('ezypayment.admin.statement-of-account-report');
      $mdDialog.cancel();
    }

    function calculateSum(data){
      return _.sumBy(data, 'amount');
    }

    function eGiroFundTransferCSV() {
      if (!vm.egiroFunds) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return;
      }
      var deferred = $q.defer();
      vm.selectedEGiroFundTransfer = _.filter(vm.egiroFunds, _.matches({ 'selected': true }));
      if (!vm.egiroFunds) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.reject;
      } else {
        var getegiroFundsCSVData = (vm.selectedEGiroFundTransfer.length < 1) ? vm.egiroFunds : vm.selectedEGiroFundTransfer;
        return CSVExportService.getEGiroFundTransferCSV(getegiroFundsCSVData);
      }
    }

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }


    function getAllSuppliersMappedToPayment() {
      if (vm.suppliers) { return false }
      ManageSupplierService.getAllAdminSuppliersWithUserDetails({
        page: 0,
        size: paginationConstants.maxLimit,
        search: '',
        visibilityType: 'ALLOCATED',
        sort: sort()
      }).then(function (response) {
        vm.suppliers = response.data;
      }, function (error) {
        NotificationService.error({
          title: 'Supplier Listing',
          error: error
        });
      });
    }

    function selectAll(dropdown) {
      switch (dropdown) {
        case 'BRANDS':
          vm.filter.brandIdList = _.map(vm.brands, _.property('brandId'));
          break;
        case 'SUPPLIERS':
          vm.filter.supplierIdList = _.map(vm.suppliers, _.property('supplierId'));
          break;
        case 'PAYMENT_STATUS':
          vm.paymentList = _.map(vm.paymentStatus, _.property('value'));
          break;
      }
    }

    function deselectAll(dropdown) {
      switch (dropdown) {
        case 'BRANDS':
          vm.filter.brandIdList = [];
          break;
        case 'SUPPLIERS':
          vm.filter.supplierIdList = [];
          break;
        case 'PAYMENT_STATUS':
          vm.paymentList = [];
          break;
      }
    }

    function getAllBuyersBusinessOutlets(buyerIdList) {
      if (!buyerIdList || buyerIdList.length === 0) return false;
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        groupBy: 'buyer'
      };
      BuyerBusinessService.getAllBuyersBusinessOutlets(buyerIdList, params).then(function (response) {
        vm.buyerBusinessOutlets = response.data;
        vm.filter.businessOutletIdList = [];
      }, function (error) {
        NotificationService.error({
          title: 'Business Outlet Listing',
          error: error
        });
      });
    }

    function getAllBuyersMappedToPayment() {
      if (vm.buyers) { return false }
      ManageBuyerService.getAllAdminBuyersWithUserDetails({
        page: 0,
        size: paginationConstants.maxLimit,
        search: '',
        sort: sort()
      }).then(function (response) {
        vm.buyers = response.data;
      }, function (error) {
        NotificationService.error({
          title: 'Buyer Listing',
          error: error
        });
      });
    }

    function getBrands() {
      EzypaymentEGiroFundTransferService.getBrands().then(function (response) {
        vm.brands = response.data;
      },
        function (error) {
          NotificationService.error({
            title: 'Brand Listing',
            error: error
          });
        })
    }

    function getSuppliers() {
      EzypaymentEGiroFundTransferService.getSuppliers().then(function (response) {
        vm.suppliers = response.data;
      },
        function (error) {
          NotificationService.error({
            title: 'Supplier Listing',
            error: error
          });
        })
    }

    function loadDropdowns() {
      vm.getAllBuyersBusinessOutlets(vm.filter.buyerIdList);
      vm.getAllBrandsOfBuyerList(vm.filter.buyerIdList)
    }

    function loadAll() {
      var transactionPayOutsRequest = {
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        paymentStatusList: !vm.paymentList ? [] : vm.paymentList,
        payerIdList: !vm.filter.brandIdList ? [] : String(vm.filter.brandIdList),
        payeeIdList: vm.filter.supplierIdList ? String(vm.filter.supplierIdList) : [],
        fromDate: !vm.filter.fromDate ? UTCDateService.utcFromDate(vm.todaysDate) : UTCDateService.utcFromDate(vm.filter.fromDate),
        toDate: !vm.filter.toDate ? UTCDateService.utcToDate(vm.todaysDate) : UTCDateService.utcToDate(vm.filter.toDate),
        eGiroInitiatedFromDate: !vm.filter.eGiroFromDate ? '' : UTCDateService.utcFromDate(vm.filter.eGiroFromDate),
        eGiroInitiatedToDate: !vm.filter.eGiroToDate ? '' : UTCDateService.utcToDate(vm.filter.eGiroToDate),
        eGiroStatus: (vm.filter.giroStatus === 'ALL') ? '' : vm.filter.giroStatus,
        paymentReferenceNumber: vm.filter.paymentReferenceNumber ? vm.filter.paymentReferenceNumber : '',
        creditRefNumber: vm.filter.creditRefNumber ? vm.filter.creditRefNumber : '',
        systemType: vm.filter.systemType ? vm.filter.systemType : 'ALL',
        ocbcPaymentType: vm.filter.paymentType ? vm.filter.paymentType : 'FAST',
        isConsolidated: vm.filter.listView == undefined ? false : vm.filter.listView === 'Individual' ? false : true
      }
      // if (transactionPayOutsRequest.systemType === 'ALL' && vm.filter.listView === 'Consolidated') {
      //   transactionPayOutsRequest.systemType = 'INTERNAL';
      // }
      EzypaymentEGiroFundTransferService.getEGiroFundTransfer(transactionPayOutsRequest).then(function (response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.egiroFunds = response.data;
        vm.egiroFunds = formatResponseData(vm.egiroFunds);
      }, function (error) {
        NotificationService.error({
          title: 'eGiro Fund Transfer',
          error: error
        });
      });
    }

    function formatResponseData(responseData) {
      responseData.forEach(function(data) {
        data.payeeAccountNum = data.payeeAccountNum ? (data.payeeAccountNum.slice(0, data.payeeAccountNum.length - 4) + 'XXXX') : 'NA';
      });
      return responseData;
    }

    function reset() {
      vm.filter.brandIdList = [];
      vm.filter.paymentReferenceNumber = '';
      vm.filter.creditRefNumber = '';
      vm.filter.listView = 'Individual';
      vm.filter.giroStatus = '';
      vm.filter.buyerIdList = [];
    }

    function checkAllRows() {
      angular.forEach(vm.egiroFunds, function (item) {
        item.selected = vm.selectAllRows;
      });
    }

    function transactionPayouts() {
      vm.selectedData = _.filter(vm.egiroFunds, _.matches({ 'selected': true }));
      if (vm.selectedData.length == 0) {
        toastr.error('Please select atleast one Invoice', 'Fast Payment Failed!', NotificationService.getToastrOptions());
        return;
      }
      var giroStatus = 0;
      _.forEach(vm.selectedData, function (each) {
        if (each.egiroStatus === 'TRUE') {
          giroStatus += 1;
        }
      });
      if (giroStatus > 0) {
        toastr.error('Cannot select eGiro success Invoices', 'Fast Payment Failed!', NotificationService.getToastrOptions());
        return;
      }
      var FastPaymentRequestDTO = [];
      _.forEach(vm.selectedData, function (each) {
        FastPaymentRequestDTO.push({
          'transactionAmount': each.amount,
          'transactionPayoutsId': each.payoutList
        });
      });
      EzypaymentEGiroFundTransferService.transactionPayouts({
        'externalSystem': vm.filter.systemType == 'EZYDDA' ? 'EZYDDA' : '',
        'ocbcPaymentType': vm.filter.paymentType ? vm.filter.paymentType : ''
      }, FastPaymentRequestDTO).then(function (response) {
        NotificationService.success({
          title: 'eGiro Fund Transfer',
          message: 'Fast Payment Successful'
        });
        loadAll();
      },
        function (error) {
          NotificationService.error({
            title: 'eGiro Fund Transfer',
            error: error
          });
          loadAll();
        })
    }

  }
})();
