/**
 * @Author: Jayalekshmi 
 * @Date: 2019-01-03 10:40:23 
 * @Project: Ezyprocure
 * @Filename: sats.po.details.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-06-26 10:14:59
 */



(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .controller('SatsPODetailsController', SatsPODetailsController);
  
    SatsPODetailsController.$inject = ['paginationConstants', 'GLOBAL_CONSTANTS', '$state', '$stateParams', 'SatsManagementService', 'NotificationService', 'Auth', '$mdDialog', 'BuyerSupplierMappingService'];
  
    function SatsPODetailsController(paginationConstants, GLOBAL_CONSTANTS, $state, $stateParams, SatsManagementService, NotificationService, Auth, $mdDialog, BuyerSupplierMappingService) {
      var vm = this;
      vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
      vm.init = init;
      vm.loadAll = loadAll;
      vm.userRole = Auth.getUserRole();
      vm.poJobId = $stateParams.poJobId;
      vm.postJob = postJob;
      vm.rejectJob = rejectJob;
      vm.addRejectionReason = addRejectionReason;
      vm.cancel = cancel;
      vm.rejectByType = rejectByType;
      vm.getAllSupplierWithSatsAccess = getAllSupplierWithSatsAccess;
      vm.updateJob = updateJob;
      vm.query = {
        limit: paginationConstants.itemsPerPage,
        page: 1
      };
      vm.limitOptions = paginationConstants.limitOptions;
      vm.init();

      /**
       * [rejectByType Decides which reject function to be called]
       */
      function rejectByType(){
        if(vm.rejectType == 'SINGLE_JOB'){
          vm.rejectJob();
        }
      }

      /**
      * [cancel to close the modal]
      */
      function cancel() {
        $mdDialog.cancel();
      }
      /**
       * [updateJob Updates the details for the po job]
       * @param {*} job 
       */
      function updateJob(job){
        SatsManagementService.updateJob(job)
        .then(function(response){
          vm.loadAll();
          NotificationService.success({
            'title': 'PO Job Plan',
            'message': 'Job Updated Successfully'
          });
        },function(error){
          NotificationService.error({
            title: 'PO Job Plan',
            error: error
          });
        });
      }

      /**
       * [getAllSupplierWithSatsAccess Gets all suppliers have sats access]
       */
      function getAllSupplierWithSatsAccess() {
        if(vm.suppliers)
          return false;
        BuyerSupplierMappingService.getAllSupplierWithSatsAccess()
        .then(function(response){
          vm.suppliers = response.data;
        },function(error){
        });
      }

      /**
       * [addRejectionReason Shows a modal to enter rejection reason]
       * @param {Object} event 
       * @param {String} type 
       * @param {Integer} id 
       */
      function addRejectionReason(event, type, id) {
        vm.rejectType = type;
        vm.rejectedJobId = id;
        $mdDialog.show({
          templateUrl: 'app/common-modules/sats/templates/sats.reject-reason.tmpl.html',
          controller: function() {
            return vm;
          },
          controllerAs: 'vm',
          targetEvent: event,
          clickOutsideToClose: true
        });
      }

      function init(){
        vm.loadAll();
        vm.getAllSupplierWithSatsAccess();
      }

      /**
       * [rejectJob Rejects the corresponding Job]
       * @param {Integer} id Job Id
       */
      function rejectJob(){
        SatsManagementService.rejectJob(vm.rejectedJobId,{'remark':vm.rejectionReason})
        .then(function(response){
          NotificationService.success({
            'title': 'PO Job Plan',
            'message': 'Job Rejected Successfully'
          });
          vm.rejectionReason = null;
          vm.cancel();
          $state.go(vm.userRole.pretty+'.sats',{reload:false});
        },function(error){
          NotificationService.error({
            title: 'PO Job Plan',
            error: error
          });
          vm.rejectionReason = null;
          vm.cancel();
        });
      }

      /**
       * [postJob Posts the corresponding Job]
       * @param {Integer} id Job Id
       */
      function postJob(id){
        SatsManagementService.postJob(id)
        .then(function(response){
          NotificationService.success({
            'title': 'PO Job Plan',
            'message': 'Job Posted Successfully'
          });
          vm.rejectionReason = null;
          vm.cancel();
          $state.go(vm.userRole.pretty+'.sats',{reload:false});
        },function(error){
          NotificationService.error({
            title: 'PO Job Plan',
            error: error
          });
        });
      }

      /**
      * [loadAll Gets Purchase Order Job Details]
      */
      function loadAll() {
        SatsManagementService.getJobDetailsById(vm.poJobId, {
          page: vm.query.page - 1,
          size: vm.query.limit
        }).then(function(response){
          vm.totalPOItems = response.headers('X-Total-Count');
          vm.poDetails = response.data;
        }, function(error){
          NotificationService.error({
            'title': 'PO Job Plan',
            'error': error
          })
        })
      }
    }
  })();
  