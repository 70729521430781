/**
* @Author: Ajay
* @Date:   20-Jan-2017 18:37 +05:30
* @Project: Ezyprocure
* @Filename: buyer.user-management.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 10-Nov-2017 11:38 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerUserListController', BuyerUserListController);

  BuyerUserListController.$inject = ['$scope', '$rootScope', '$state', 'NotificationService', '$mdDialog', 'BuyerUserManagementService', 'pagingParams', 'paginationConstants'];

  function BuyerUserListController ($scope, $rootScope, $state, NotificationService, $mdDialog, BuyerUserManagementService, pagingParams, paginationConstants) {
    var vm = this;

    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.deleteBuyerUser = deleteBuyerUser;
    vm.loadAll = loadAll;
    vm.showConfirm = showConfirm;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    //Sorting of buyer users
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    //Load all buyer users
    function loadAll (search) {
      if (!search) {
        vm.searchText = '';
      }
      BuyerUserManagementService.getAllBuyerUsers({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.searchText
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.buyerUsers = response.data;
      },function(error){
        NotificationService.error({
          title: 'Buyer User Listing',
          error: error
        });
      });
    }

    // Delete a buyer user. Function accepts buyerUserId as param
    function deleteBuyerUser(buyerUserId) {
      BuyerUserManagementService.deleteBuyerUser(buyerUserId).then(function(response){
        NotificationService.success({
          title: 'Buyer User',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      },function(error){
        NotificationService.error({
          title: 'Buyer User Deletion',
          error: error
        });
      });
    }

    function showConfirm (ev, buyerUser) {
      var confirm = $mdDialog.confirm()
            .title('Deleting user will unmap all the existing mappings for the user?')
            .textContent('This action could not be reverted back.')
            .targetEvent(ev)
            .ok('Delete')
            .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        deleteBuyerUser(buyerUser.buyerUserId);
      }, function() {
      });
    }

    vm.loadAll();

  }
})();
