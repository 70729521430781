/*
 * @Author: Lovin Chacko 
 * @Date: 2021-03-31 12:28:50 
 * @Last Modified by: Lovin Chacko
 * @Last Modified time: 2021-05-06 09:18:33
 */

(function () {
    'use strict';

    angular
        .module('ezyprocureApp')
        .controller('AddBlanketOrderController', AddBlanketOrderController);

    AddBlanketOrderController.$inject = ['$scope', '$rootScope', '$state', '$q', '$filter', 'NotificationService', 'Auth', 'paginationConstants', 'SupplierDataService', 'BuyerSupplierMappingService', 'BuyerDataService', 'BuyerItemService', 'BlanketOrderService', 'SupplierItemService'];

    function AddBlanketOrderController($scope, $rootScope, $state, $q, $filter, NotificationService, Auth, paginationConstants, SupplierDataService, BuyerSupplierMappingService, BuyerDataService, BuyerItemService, BlanketOrderService, SupplierItemService) {
        var vm = this;
        vm.todaysDate = new Date();
        vm.query = {
            limit: paginationConstants.itemsPerPage,
            page: 1
        };
        vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
        vm.getAllBuyerMappedSuppliers = getAllBuyerMappedSuppliers;
        vm.userRole = Auth.getUserRole();
        vm.items = [];
        vm.blanketOrderItemsList = [];
        vm.blanketOrderItems = [{}];
        vm.addNewItemRow = addNewItemRow;
        vm.deleteItemRow = deleteItemRow;
        vm.searchForItems = searchForItems;
        vm.itemSearchQuery = {};
        vm.itemChanged = itemChanged;
        vm.createBlanketOrder = createBlanketOrder;
        vm.init = init;
        vm.cancel = cancel;
     
        function init() {
            if (!Auth.hasPermission('accessForManageBlanketOrder'))
                $state.go(vm.userRole.pretty + '.dashboard');
        }

        function cancel(){
            $state.go(vm.userRole.pretty + '.tools.manage-blanket-order');
        }

        //Get all buyers mapped to the supplier
        function getAllSupplierMappedBuyers() {
            var params = {
                page: 0,
                size: paginationConstants.maxLimit,
            };
            if (vm.buyers)
                return false;
            var supplierId = SupplierDataService.getSupplierProfile().supplier.id;
            BuyerSupplierMappingService.getAllSupplierMappedBuyers(supplierId, params).then(function (response) {
                vm.buyers = response.data;
            }, function (error) {
            });
        }

        // get all suppliers mapped to the buyer
        function getAllBuyerMappedSuppliers() {
            if (vm.suppliers)
                return false;
            var params = {
                page: 0,
                size: paginationConstants.maxLimit
            };
            var buyer = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
            BuyerSupplierMappingService.getAllBuyerMappedSuppliers(buyer, params).then(function (response) {
                vm.suppliers = _.filter(response.data, function(supplier){
                    return supplier.visibilityType != 'ADHOC_OFF_BOARD_SUPPLIER';
                });
            }, function (error) {
                NotificationService.error({
                    title: 'global.addBlanketOrder',
                    error: error
                });
            });
        }

        //Get all buyers mapped to the supplier
        function getAllSupplierMappedBuyers() {
            var params = {
                page: 0,
                size: paginationConstants.maxLimit,
            };
            if (vm.buyers)
                return false;
            var supplierId = SupplierDataService.getSupplierProfile().supplier.id;
            BuyerSupplierMappingService.getAllSupplierMappedBuyers(supplierId, params).then(function (response) {
                vm.buyers = response.data;
            }, function (error) {
            });
        }

        function addNewItemRow() {
            vm.blanketOrderItems.push({});
        }

        function deleteItemRow(index) {
            vm.blanketOrderItems.splice(index, 1);
        }

        // search items by business outlet and supplier id
        function searchForItems(queryType, queryText) {
            var deferred = $q.defer();
            if (!queryText) {
                return;
            }
            else {
                if (vm.userRole.pretty == 'buyer') {
                    vm.itemSearchQuery.queryField = queryType;
                    vm.itemSearchQuery.queryText = queryText;
                    vm.itemSearchQuery.businessIdList = null;
                    vm.itemSearchQuery.buyerIdList = [BuyerDataService.getBuyerProfile().buyerUser.buyer.id];
                    vm.itemSearchQuery.supplierId = vm.filter.supplier.id;
                    BuyerItemService.searchAllItems(vm.itemSearchQuery)
                        .then(function (response) {
                            deferred.resolve($filter('orderBy')(response.data, 'itemName'));
                        }, function (error) {
                            deferred.reject(error);
                        });
                }
                else {
                    vm.itemSearchQuery.queryField = queryType;
                    vm.itemSearchQuery.queryText = queryText;
                    vm.itemSearchQuery.itemType = 'BUYER';
                    vm.itemSearchQuery.buyerIdList = [vm.filter.buyer.id];
                    SupplierItemService.searchAllItems(vm.itemSearchQuery)
                        .then(function (response) {
                            deferred.resolve($filter('orderBy')(response.data, 'itemName'));
                        }, function (error) {
                            deferred.reject(error);
                        });
                }

            }
            return deferred.promise;
        }

        function itemChanged(blanketOrderItem, index) {
            var temp = angular.copy(blanketOrderItem);
            blanketOrderItem.itemName = temp.items.itemName;
            blanketOrderItem.itemCode = temp.items.itemCode;
            blanketOrderItem.uom = temp.items.uom.name;
            blanketOrderItem.currency = temp.items.currency;
            blanketOrderItem.price = _.round(temp.items.price, 3);
            blanketOrderItem.itemId = temp.items.id;
        }

        function createBlanketOrder() {
            var blanketOrder = {};
            blanketOrder = {
                blanketOrderItemsMappings: vm.blanketOrderItems,
                blanketOrderNumber: vm.filter.blanketOrderNumber,
                validityStartDate: vm.filter.validityStartDate,
                validityEndDate: vm.filter.validityEndDate,
            }
            if (vm.userRole.pretty == 'buyer') {
                blanketOrder.supplier = vm.filter.supplier;
            }
            else {
                blanketOrder.buyer = vm.filter.buyer;
            }
            BlanketOrderService.createNewBlanketOrder(blanketOrder).then(function (res) {
                NotificationService.success({
                    title: 'global.addBlanketOrder',
                    message: 'alertMessage.CREATED_SUCCESSFULLY'
                });
                $state.go(vm.userRole.pretty + '.tools.manage-blanket-order');
            },
                function (error) {
                    NotificationService.error({
                        title: 'global.manageBlanketOrder',
                        error: error
                    });
                });
        }

    }
})();