/*
 * @Author: Ajay 
 * @Date: 2019-02-11 11:37:07 
 * @Project: Ezyprocure
 * @Filename: ezyap.buyer.external-peppol-invoices.service.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-10-24 10:36:11
 */


(function () {
    'use strict';
  
    angular.module('ezyprocureApp').factory( 'EzyapGetInvoicesService', EzyapGetInvoicesService);
  
    EzyapGetInvoicesService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  
    function EzyapGetInvoicesService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {
  
      var EzyapGetInvoicesService = {
        getAllInvoices: getAllInvoices,
        getInvoiceDetailsById: getInvoiceDetailsById
      };
      return EzyapGetInvoicesService;
  
      /**
       * [getAllInvoices Gets all invoices]
       * @param {*} params 
       */
      function getAllInvoices(params) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/invoices',
          method: "GET",
          params: params
        }).then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
  
      /**
       * [getInvoiceDetailsById Gets invoice details by id]
       * @param {*} invoiceId 
       */
      function getInvoiceDetailsById(invoiceId) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/invoices/' + invoiceId,
          method: "GET"
        }).then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
  
    }
  })();