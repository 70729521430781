/**
* @Author: Ajay
* @Date:   19-Jan-2017 18:23 +05:30
* @Project: Ezyprocure
* @Filename: buyer.business-management.state.js
* @Last modified by:   Ajay
* @Last modified time: 06-Apr-2017 11:47 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('buyer.tools.business-management', {
      parent: 'buyer.tools',
      url: '/business-management?page&sort&search',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'Business Outlet Management'
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/tools/business-management/buyer.business-management.html',
          controller: 'BuyerBusinessListController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('buyerBusiness');
          return $translate.refresh();
        }]
      }
    })
    .state('buyer.tools.business-management.add', {
      parent: 'buyer.tools.business-management',
      url: '/add',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'Business Outlet Management'
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/tools/business-management/add/buyer.business-management.add.html',
          controller: 'BuyerBusinessAddController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    })
    .state('buyer.tools.business-management.edit', {
      parent: 'buyer.tools.business-management',
      url: '/edit/:businessOutletId',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'Business Outlet Management'
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/tools/business-management/edit/buyer.business-management.edit.html',
          controller: 'BuyerBusinessEditController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    });
  }
})();
