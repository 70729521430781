/*
 * @Author: Lovin C 
 * @Date: 2021-08-29 11:47:31 
 * @Last Modified by: Lovin C
 * @Last Modified time: 2021-09-13 14:44:37
 */



(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('CustomInvoicePaymentsController', CustomInvoicePaymentsController);

  CustomInvoicePaymentsController.$inject = ['$scope', '$stateParams', '$rootScope', '$q', '$filter', '$state', '$mdDialog', 'toastr', 'BuyerDataService', 'GLOBAL_CONSTANTS', 'pagingParams', 'NotificationService', 'paginationConstants', 'ManageSupplierService', 'BuyerSupplierMappingService', 'UTCDateService', 'CustomPaymentsService'];

  function CustomInvoicePaymentsController($scope, $stateParams, $rootScope, $q, $filter, $state, $mdDialog, toastr, BuyerDataService, GLOBAL_CONSTANTS, pagingParams, NotificationService, paginationConstants, ManageSupplierService, BuyerSupplierMappingService, UTCDateService, CustomPaymentsService) {

    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = true;
    vm.loadAll = loadAll;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    
    vm.paymentStatus = GLOBAL_CONSTANTS.WORLDPAY_PAYMENT_STATUSES;
    vm.limitOptions = paginationConstants.limitOptions;
    vm.visibilityType = $stateParams.visibilityType ? $stateParams.visibilityType : 'ALLOCATED';
    vm.showConfirm = showConfirm;
    vm.filter = {};
    vm.customPaymentsList = [];
    vm.loadBrandsBasedOnSupplierId = loadBrandsBasedOnSupplierId;
    vm.getSuppliers = getSuppliers;
    vm.getAllBuyerMappedSupplierList = getAllBuyerMappedSupplierList;
    vm.deselectAll = deselectAll;
    vm.selectAll = selectAll;
    vm.deleteInvoice = deleteInvoice;

    init();

    function init() {
      vm.loadAll();
      CustomPaymentsService.setInvoiceData([]);
    }
    

    function loadAll() {
      CustomPaymentsService.customPaymentsList({
        fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
        toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
        supplierIds: vm.filter.supplierIds ? vm.filter.supplierIds : '',
        brandIds: vm.filter.brandIds ? vm.filter.brandIds : '',
        paymentStatus: vm.filter.paymentList ? vm.filter.paymentList : '',
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort()
      }).then(function (response) {
        vm.customPaymentsList = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      }, function (error) {
        NotificationService.error({
          title: 'Custom Payments',
          error: error
        });
      });
    }

    function deleteInvoice(index) {
      var params = {
        invoiceId: vm.customPaymentsList[index].invoiceId
      }
      CustomPaymentsService.deleteInvoiceById(params).then(function() {
        toastr.success('Deleted Successfully', 'Invoice');
        loadAll();
      }, function() {
        toastr.error('Deleted Failed', 'Invoice');
      });
    }

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function selectAll(dropdown) {
      switch (dropdown) {
        case 'BRANDS':
          vm.filter.brandIds = _.map(vm.brands, _.property('id'));
          break;
        case 'SUPPLIERS':
          vm.filter.supplierIdList = _.map(vm.supplierList, _.property('supplierId'));
          break;
        case 'PAYMENT_STATUS':
          vm.filter.paymentList = _.map(vm.paymentStatus, _.property('value'));
          break;
      }
    }

    function deselectAll(dropdown) {
      switch (dropdown) {
        case 'PAYMENT_STATUS':
          vm.filter.paymentList = [];
          break;
        case 'BRANDS':
          vm.filter.brandIds = [];
          break;
        case 'SUPPLIERS':
          vm.filter.supplierIdList = [];
          break;
      }
    }

    function getSuppliers() {
      CustomPaymentsService.getSuppliers().then(function (response) {
        vm.supplierList = response.data;
      },function (error) {
        NotificationService.error({
          title: 'Supplier Listing..',
          error: error
        });
      });
    }

    function getAllBuyerMappedSupplierList() {
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        brandIds: ''
      };
      var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      BuyerSupplierMappingService.getAllBuyerMappedSupplierListWithParam(buyerId, params).then(function (response) {
        vm.supplierList = response.data;
      }, function (error) {
      });
    }

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function loadBrandsBasedOnSupplierId(supplierId) {
      if(!supplierId) { 
        toastr.error('Please choose a supplier', 'Brand Selection');
        return; 
      }
      CustomPaymentsService.getBrands({
        supplierIds: supplierId
      }).then(function (response) {
        vm.brands = response.data;
      }, function (error) {
        NotificationService.error({
          title: 'Brand Listing',
          error: error
        });
      });
    }


    function showConfirm(ev, index) {
      var confirm = $mdDialog.confirm()
        .title('Are you sure you want to delete?')
        .textContent('This action could not be reverted back.')
        .targetEvent(ev)
        .ok('Delete')
        .cancel('Cancel');

      $mdDialog.show(confirm).then(function () {
        deleteInvoice(index);
      }, function () { });
    }

    function deleteAdminSupplier(supplierId) {
      BuyerSupplierMappingService.deleteBSMapping({ supplierId: supplierId, buyerId: vm.suppliers[0].buyer.id }).then(function (response) {
        NotificationService.success({
          title: 'Admin Supplier',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function (error) {
        NotificationService.error({
          title: 'Admin Supplier Deletion',
          error: error
        });
      });
    }
    
  }
})();
