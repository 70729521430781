/**
 * @Author: lovinc
 * @Date:   2018-11-26T17:05:03+05:30
 * @Project: Ezyprocure
 * @Filename: supplier.item.item-termination.state.js
 * @Last modified by:   lovinc
 * @Last modified time: 2018-11-27T11:08:06+05:30
 */



(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
      .state('supplier.items.termination', {
        parent: 'supplier',
        url: '/item-termination',
        data: {
          authorities: ['ROLE_SUPPLIER'],
        },
        views: {
          'content@': {
            templateUrl: 'app/supplier-modules/item-management/termination/supplier.item.termination.html',
            controller: 'SupplierItemTerminationController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,desc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('items');
            $translatePartialLoader.addPart('priceUpdates');
            return $translate.refresh();
          }]
        }
      });

  }

})();
