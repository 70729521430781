/**
 * @Author: Jayalekshmi 
 * @Date: 2019-01-03 10:40:23 
 * @Project: Ezyprocure
 * @Filename: sats.service.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-06-26 10:23:33
 */



(function() {
    'use strict';

    angular.module('ezyprocureApp').factory('SatsManagementService', SatsManagementService);

    SatsManagementService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS', 'GLOBAL_CONSTANTS', '$filter'];
    function SatsManagementService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS, GLOBAL_CONSTANTS, $filter) {
      var types = GLOBAL_CONSTANTS.SATS_VALID_FILE_EXTENSIONS;
      var SatsManagementService = {
        getAllJobs: getAllJobs,
        getJobDetailsById: getJobDetailsById,
        uploadPOAttachments: uploadPOAttachments,
        checkFileType : checkFileType,
        confirmPOJob: confirmPOJob,
        createPOJob: createPOJob,
        sequenceGenerator: sequenceGenerator,
        postJob : postJob,
        rejectJob : rejectJob,
        downloadAttachments: downloadAttachments,
        createJobSO: createJobSO,
        getSelectedJobs : getSelectedJobs,
        postAllJobs : postAllJobs,
        rejectAllJobs : rejectAllJobs,
        createSOJob: createSOJob,
        createSRJob : createSRJob,
        confirmSRJob : confirmSRJob,
        createTOJob: createTOJob,
        confirmTOJob: confirmTOJob,
        getPendingJobsDashboardCount: getPendingJobsDashboardCount,
        getLogReports: getLogReports,
        getDateFormat: getDateFormat,
        updateJob : updateJob,
        deleteJob : deleteJob
      };
      return SatsManagementService;


      /**
       * [updateJob Updates the job details]
       * @param {Object} job 
       */
      function updateJob(job){
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.satsServiceBase + RESOURCE_URL_CONSTANTS.SATS + 'api/job/',
          method: "PUT",
          data:job
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      /**
       * [deleteJob Deletes the job details]
       * @param {Object} queryParams 
       */
      function deleteJob(deleteJobId){
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.satsServiceBase + RESOURCE_URL_CONSTANTS.SATS + 'api/job/delete',
          method: "POST",
          data:deleteJobId
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      /**
       * [getLogReports Gets all SATS log reports]
       * @param {Object} queryParams 
       */
      function getLogReports(queryParams){
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.satsServiceBase + RESOURCE_URL_CONSTANTS.SATS + 'api/audit-logs/user',
          method: "GET",
          params:queryParams
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      /**
       * [downloadAttachments Downloads attachment]
       * @param {Object} attachment 
       */
      function downloadAttachments(attachment){
        var deferred = $q.defer();
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.href = attachment;
        a.download = 'attachment';
        a.click();
        document.body.removeChild(a);
        return deferred.promise;
      }

      /**
       * [rejectJob Rejects a single job]
       * @param {Integer} id 
       * @param {Object} queryParams 
       */
      function rejectJob(id, queryParams){
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.satsServiceBase + RESOURCE_URL_CONSTANTS.SATS + 'api/'+ RESOURCE_URL_CONSTANTS.JOB_RESOURCE + id +'/reject',
          method: "POST",
          params: queryParams
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
      
      /**
       * [postJob Posts a single job]
       * @param {Integer} id 
       */
      function postJob(id){
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.satsServiceBase + RESOURCE_URL_CONSTANTS.SATS + 'api/'+ RESOURCE_URL_CONSTANTS.JOB_RESOURCE + id +'/post',
          method: "POST"
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      /**
       * [sequenceGenerator Generates a unique sequence number]
       * @param {Object} documentType 
       */
      function sequenceGenerator(documentType){
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.satsServiceBase + RESOURCE_URL_CONSTANTS.SATS + 'api/sequence',
          method: "GET",
          params:documentType
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      /**
       * [createPOJob Creates Job PO]
       * @param {Object} poData
       */
      function createPOJob(poData){
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.satsServiceBase + RESOURCE_URL_CONSTANTS.SATS + 'api/purchase-order',
          method: "POST",
          data: poData
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
      
      /**
       * [getAllJobs Gets all job details]
       * @param {Object} type 
       * @param {Object} queryParams 
       */
      function getAllJobs(type, queryParams) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.satsServiceBase + RESOURCE_URL_CONSTANTS.SATS + 'api/'+ RESOURCE_URL_CONSTANTS.JOB_RESOURCE +'document/'+type,
          method: "GET",
          params:queryParams
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      /**
       * [confirmPOJob Confirms The job PO]
       * @param {Object} file 
       * @param {Integer} id 
       */
      function confirmPOJob(file, id){
        var poCsvFormData = new FormData();
        poCsvFormData.append('document', file);
        var deferred = $q.defer();
        $http.post(API_CONSTANTS.satsServiceBase + RESOURCE_URL_CONSTANTS.SATS + 'api/purchase-order/'+id+'/upload', poCsvFormData,   {
          transformRequest: angular.identity,
          headers: {'Content-Type': undefined}
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
        
      }

      /**
       * [checkFileType Validatesthe file type for sats]
       */
      function checkFileType(files){
        var valid = [];
        angular.forEach(files, function(file){
          var validType = _.includes(types , file.lfFile.type);
          var validTypes = ['csv', 'pdf', 'png', 'jpeg', 'xlsx', 'xlsx', 'gz', 'zip']
          if (validType && _.includes(validTypes, file.lfFile.name.split(".").pop())) {
            valid.push(true);
          }else{
            valid.push(false);
          }
        });
        if(_.includes(valid, false)){
          return false;
        }
        return true;
      }

      /**
       * [uploadPOAttachments Upload attachments]
       * @param {Object} files 
       * @param {Integer} id 
       */
      function uploadPOAttachments(files, id) {
        var deferred = $q.defer();
        angular.forEach(files, function(file){
          var formData = new FormData();
          formData.append('files', file.lfFile);
          $http.post(API_CONSTANTS.satsServiceBase + RESOURCE_URL_CONSTANTS.SATS + 'api/upload/'+id, formData,   {
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
          }).then(function(data) {
            deferred.resolve(data);
          }).catch(function(message) {
            deferred.reject(message);
          });
        });
        return deferred.promise;
      }

      /**
       * [getJobDetailsById Gets individual Job details]
       * @param {Integer} id 
       */
      function getJobDetailsById(id, params) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.satsServiceBase + RESOURCE_URL_CONSTANTS.SATS + 'api/'+ RESOURCE_URL_CONSTANTS.JOB_RESOURCE +id,
          method: "GET",
          params: params
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      /**
       * [getSelectedJobs Gets selected jobs]
       * @param {Object} jobList 
       */
      function getSelectedJobs(jobList) {
        var selectedJobs = _.filter(jobList, function(job) {
          return job.selected === true;
        });
        return selectedJobs;
      }

      /**
       * [postAllJobs Posts all jobs]
       * @param {Array} jobIds 
       */
      function postAllJobs(jobIds){
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.satsServiceBase + RESOURCE_URL_CONSTANTS.SATS + 'api/'+ RESOURCE_URL_CONSTANTS.JOB_RESOURCE + 'post',
          method: "POST",
          data: jobIds
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      /**
       * [rejectAllJobs Rejects all jobs]
       * @param {Array} jobIds 
       * @param {Object} remarks 
       */
      function rejectAllJobs(jobIds, remarks){
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.satsServiceBase + RESOURCE_URL_CONSTANTS.SATS + 'api/'+ RESOURCE_URL_CONSTANTS.JOB_RESOURCE +'reject',
          method: "POST",
          data: jobIds,
          params: remarks
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }


      // ---------SO----------------
      
      /**
       * [createJobSO Creates job SO ]
       * @param {Object} file 
       * @param {Object} wave 
       */
      function createJobSO(file, wave){
        var soFormData = new FormData();
        soFormData.append('document', file);
        var deferred = $q.defer();
        $http.post(API_CONSTANTS.satsServiceBase + RESOURCE_URL_CONSTANTS.SATS + 'api/sales-order', soFormData,   {
          transformRequest: angular.identity,
          headers: {'Content-Type': undefined},
          params: wave
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      /**
       * [createSOJob Confirms SO job]
       * @param {Object} file 
       */
      function createSOJob(file){
        var soCsvFormData = new FormData();
        soCsvFormData.append('document', file);
        var deferred = $q.defer();
        $http.put(API_CONSTANTS.satsServiceBase + RESOURCE_URL_CONSTANTS.SATS + 'api/sales-order', soCsvFormData,   {
          transformRequest: angular.identity,
          headers: {'Content-Type': undefined}
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      // ------------------SR-------------------------

      /**
       * [createSRJob Creates SR job]
       * @param {Object} file 
       */
      function createSRJob(file){
        var srFormData = new FormData();
        srFormData.append('document', file);
        var deferred = $q.defer();
        $http.post(API_CONSTANTS.satsServiceBase + RESOURCE_URL_CONSTANTS.SATS + 'api/sales-return', srFormData,   {
          transformRequest: angular.identity,
          headers: {'Content-Type': undefined}
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      /**
       * [confirmSRJob Confirms job SO]
       * @param {Object} file 
       */
      function confirmSRJob(file){
        var srCsvFormData = new FormData();
        srCsvFormData.append('document', file);
        var deferred = $q.defer();
        $http.put(API_CONSTANTS.satsServiceBase + RESOURCE_URL_CONSTANTS.SATS + 'api/sales-return', srCsvFormData,   {
          transformRequest: angular.identity,
          headers: {'Content-Type': undefined}
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      // --------------TO------------------

      /**
       * [createTOJob Creates job TO]
       * @param {Object} file 
       */
      function createTOJob(file){
        var toFormData = new FormData();
        toFormData.append('document', file);
        var deferred = $q.defer();
        $http.post(API_CONSTANTS.satsServiceBase + RESOURCE_URL_CONSTANTS.SATS + 'api/transfer-order', toFormData,   {
          transformRequest: angular.identity,
          headers: {'Content-Type': undefined}
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      /**
       * [confirmTOJob Confirms the job TO]
       * @param {Object} file 
       * @param {Integer} id 
       */
      function confirmTOJob(file, id){
        var toCsvFormData = new FormData();
        toCsvFormData.append('document', file);
        var deferred = $q.defer();
        $http.put(API_CONSTANTS.satsServiceBase + RESOURCE_URL_CONSTANTS.SATS + 'api/transfer-order/'+id, toCsvFormData,   {
          transformRequest: angular.identity,
          headers: {'Content-Type': undefined}
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      /**
       * [getPendingJobsDashboardCount Gets the dashboard count]
       */
      function getPendingJobsDashboardCount(){
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.satsServiceBase +RESOURCE_URL_CONSTANTS.SATS + 'api/'+ RESOURCE_URL_CONSTANTS.JOB_RESOURCE +'pending',
          method: 'GET',
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      /**
       * [getDateFormat gets the date string and converts]
       * @param {dateString} dateString 
       */
      function getDateFormat(dateString){
        var pattern = /(\d{4})(\d{2})(\d{2})/;
        var newDate = new Date(dateString.replace(pattern, '$1-$2-$3'));
        return $filter('date') (newDate, "yyyy/MM/dd");
      }
    }
  })();
