/**
* @Author: Ajay
* @Date:   03-Mar-2017 15:12 +05:30
* @Project: Ezyprocure
* @Filename: supplier.dashboard.service.js
* @Last modified by:   Ajay
* @Last modified time: 03-Apr-2017 18:33 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('SupplierDashboardService', SupplierDashboardService);

  SupplierDashboardService.$inject = ['$http', '$q', 'API_CONSTANTS'];
  function SupplierDashboardService($http, $q, API_CONSTANTS) {

    var supplierDashboardService = {
      getSupplierDashboardCount: getSupplierDashboardCount
    };

    return supplierDashboardService;

    /* --  Supplier Dashboard APIs  -- */

    //Get Supplier Dashboard Count
    function getSupplierDashboardCount() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers/dashboard/',
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
