/**
* @Author: Ajay
* @Date:   31-Jan-2017 10:45 +05:30
* @Project: Ezyprocure
* @Filename: supplier.item.allocations.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 07-Nov-2017 15:36 +05:30
*/



(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('SupplierItemAllocationController', SupplierItemAllocationController);

  SupplierItemAllocationController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'NotificationService', '$mdDialog', 'pagingParams', 'BuyerDataService', 'SupplierDataService', 'BuyerSupplierMappingService', 'BrandManagementService', 'SupplierItemMapService', 'SupplierItemService', 'paginationConstants'];

  function SupplierItemAllocationController($scope, $rootScope, $state, $stateParams, NotificationService, $mdDialog, pagingParams, BuyerDataService, SupplierDataService, BuyerSupplierMappingService, BrandManagementService, SupplierItemMapService, SupplierItemService, paginationConstants) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.itemId = $stateParams.itemId;
    vm.init = init;
    vm.save = save;
    vm.validateMOQ = validateMOQ;
    vm.loadAllPerOutlet = loadAllPerOutlet;
    vm.savePerOutlet = savePerOutlet

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1,
      maxLimit: paginationConstants.maxLimit
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.getBusinessOutlet = getBusinessOutlet;
    vm.getAllBrandsOfBuyerList = getAllBrandsOfBuyerList;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.buyerBrandFilter = buyerBrandFilter;
    vm.getBuyersMappedByItem = getBuyersMappedByItem;
    vm.itemDTOObj = {
      buyerIdList: [],
      brandIdList : []
    }
    vm.updateAllItemBuyerMappingPerItem = updateAllItemBuyerMappingPerItem;

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
     * [loadAllPerOutlet Loads all allocations per outlet]
     */
    function loadAllPerOutlet() {
        SupplierItemMapService.getAllMappedItemsPerOutlet({
          page: vm.query.page - 1,
          size: vm.query.limit,
          sort: sort()
        }, vm.itemId).then(function(response) {
          vm.totalItems = response.headers('X-Total-Count');
          vm.itemBuyerMappings = response.data;
        }, function(error) {
          NotificationService.error({
            title: 'Item Buyer Mappings By ItemId Fetching',
            error: error
          });
        });
      }

    //get all buyers mapped to the item
    function getBuyersMappedByItem(){
      vm.itemDTOObj.brandIdList = [];
      SupplierItemMapService.getAllMappedItemsPerOutlet({
        page: vm.query.page - 1,
        size: vm.query.maxLimit,
        sort: sort()
      }, vm.itemId).then(function(response) {
       vm.itemBuyers = response.data;
       vm.buyersList = _.uniqBy(vm.itemBuyers, 'buyerId');
      }, function (error) {
        NotificationService.error({
          title: 'Item Buyer Mappings By ItemId Fetching',
          error: error
        });
      });
    }

    function getBusinessOutlet() {
      var itemBusinessMappingDTO= {
        'id': vm.itemId
      }
      SupplierItemService.getBusinessOutlet(itemBusinessMappingDTO).then(function (response) {
        vm.totalItem = response.headers('X-Total-Count');
        vm.businessOutlet = response.data;
      }, function (error) {
        NotificationService.error({
          title: 'Item Buyer Mappings By ItemId Fetching',
          error: error
        });
      });
    }

    /**
     *[savePerOutlet Updates the data per outlet]
     * @param {Object} itemBuyerMapping
     */
    function savePerOutlet(itemBuyerMapping) {
      var itemMappingDTO = {
        id: vm.itemId,
        buyerBusinessId : itemBuyerMapping.buyerBusinessId,
        buyerLimited : itemBuyerMapping.buyerLimited,
        maximumAllocation : itemBuyerMapping.maximumAllocation,
        buyerId : itemBuyerMapping.buyerId,
        moq : itemBuyerMapping.moq,
        multiplierFactor : itemBuyerMapping.multiplierFactor,
        price : itemBuyerMapping.price
      }
      SupplierItemMapService.updateItemBuyerMappingPerOutlet(itemMappingDTO).then(function(response) {
        $scope.$emit('ezyprocureApp:supplierItemBuyerAllocationUpdate', response);
        NotificationService.success({
          title: 'Item Buyer Allocation',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        vm.loadAllPerOutlet();
      }, function(error) {
        NotificationService.error({
          title: 'Item Buyer Allocation',
          error: error
        });
      });
    }

    /**
     * [getAllBrandsOfBuyerList Gets all brands of selected buyers]
     * @param {Array} buyerIdList
     */
    function getAllBrandsOfBuyerList(buyerIdList){
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        query:'',
        buyerId : buyerIdList
      };
      BrandManagementService.getAllBrandsOfBuyerList(params)
      .then(function(response){
        vm.brands = response;
      },function(error){
        NotificationService.error({
          title: 'Brand Listing',
          error: error
        });
      });
    }

    /**
     * [selectAll Selects all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, buyers or brands]
     */
    function selectAll(dropdown) {
      switch (dropdown) {
        case 'BUYERS':
          vm.itemDTOObj.buyerIdList = _.map(vm.buyers, _.property('id'));
          break;
        case 'BRANDS':
          vm.itemDTOObj.brandIdList = _.map(vm.brands, _.property('id'));
          break;
      }
    }

    /**
     * [deselectAll Deselect all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, buyers or brands]
     */
    function deselectAll(dropdown) {
      switch (dropdown) {
        case 'BUYERS':
          vm.itemDTOObj.buyerIdList = [];
          break;
        case 'BRANDS':
          vm.itemDTOObj.brandIdList = [];
          break;
      }
    }

    function buyerBrandFilter(){
        var itemBusinessMappingDTO = {
          'buyerIdList': vm.itemDTOObj.buyerIdList,
          'brandIdList': vm.itemDTOObj.brandIdList,
          'id': vm.itemId,
          'buyerLimited': vm.itemDTOObj.buyerLimited ,
          'maximumAllocation': vm.itemDTOObj.maximumAllocation
        }
        SupplierItemService.getBusinessOutlet(itemBusinessMappingDTO).then(function (response) {
          vm.totalItems = response.headers('X-Total-Count');
          vm.itemBuyerMappings = response.data;
        }, function (error) {
          NotificationService.error({
            title: 'Item Buyer Mappings By ItemId Fetching',
            error: error
          });
        });
    }

    function save(itemBuyerMapping) {
      itemBuyerMapping.id = vm.itemId;
      SupplierItemMapService.updateItemBuyerMapping(itemBuyerMapping).then(function (response) {
        $scope.$emit('ezyprocureApp:supplierItemBuyerAllocationUpdate', response);
        NotificationService.success({
          title: 'Item Buyer Allocation',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        // vm.loadAll();
        vm.loadAllPerOutlet();
      }, function(error) {
        NotificationService.error({
          title: 'Item Buyer Allocation',
          error: error
        });
      });
    }

    function init() {
    vm.loadAllPerOutlet();
      vm.getBusinessOutlet();
    }

    /**
     * [validateMOQ Validates the MOQ with Multiplier]
     */
    function validateMOQ(itemBuyerMapping, index, form){
      switch(form){
        case 'manageItemAllocationForm':
          if(itemBuyerMapping.multiplierFactor > 0){
            if (((itemBuyerMapping.moq*100) % (itemBuyerMapping.multiplierFactor*100))/100 != 0) {
              $scope.manageItemAllocationForm['moq_'+index].$setTouched();
              $scope.manageItemAllocationForm['moq_'+index].$setValidity("moq", false);
            }else{
             $scope.manageItemAllocationForm['moq_'+index].$setValidity("moq", true);
            }
          }
          break;
        case 'saveAllForm':
          if(vm.itemDTOObj.multiplierFactor > 0){
            if (((vm.itemDTOObj.moq*100) % (vm.itemDTOObj.multiplierFactor*100))/100 != 0) {
              $scope.saveAllForm['moq'].$setTouched();
              $scope.saveAllForm['moq'].$setValidity("moq", false);
            }else{
             $scope.saveAllForm['moq'].$setValidity("moq", true);
            }
          }
        
          break;
      }
    }

    /**
     * updateAllItemBuyerMappingPerItem Updates all.
     */
    function updateAllItemBuyerMappingPerItem(){
      var itemBuyerMapping ={
        id : vm.itemId,
        moq : vm.itemDTOObj.moq,
        multiplierFactor : vm.itemDTOObj.multiplierFactor,
        buyerLimited : vm.itemDTOObj.buyerLimited? vm.itemDTOObj.buyerLimited:null,
        maximumAllocation : vm.itemDTOObj.maximumAllocation,
        itemBusinessMappingIdList:_.map(vm.itemBuyerMappings, _.property('itemBusinessMappingId'))
      }
      SupplierItemMapService.updateAllItemBuyerMappingPerItem(itemBuyerMapping).then(function (response) {
        $scope.$emit('ezyprocureApp:supplierItemBuyerAllocationUpdate', response);
        NotificationService.success({
          title: 'Item Buyer Allocation',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        vm.loadAllPerOutlet();
      }, function(error) {
        NotificationService.error({
          title: 'Item Buyer Allocation',
          error: error
        });
      });
    }
  }
})();
