/**
 * @Author: Ajay
 * @Date:   08-Mar-2018 12:18 +05:30
 * @Project: Ezyprocure
 * @Filename: util.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 08-Mar-2018 14:38 +05:30
 */


(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('UtilService', UtilService);

  UtilService.$inject = ['$http', '$q', 'toastr', 'fCsv', 'TIME_CONSTANTS'];

  function UtilService($http, $q, toastr, fCsv, TIME_CONSTANTS) {

    var utcDateService = {
      padLeadingZero: padLeadingZero,
      swapArrayValueToEnd: swapArrayValueToEnd,
      generateDays: generateDays,
      csvToJson: csvToJson,
      getTimeAbbr12: getTimeAbbr12,
      isValidDateRange : isValidDateRange
    };
    return utcDateService;

    /**
   * [padLeadingZero Show a leading zero if a number is less than 10]
   * @param  {[type]} str [String]
   * @param  {[type]} max [max]
   * @return {[type]}     [Converted string number array]
   */
    function padLeadingZero(str, max) {
      return str.length < max ? padLeadingZero("0" + str, max) : str;
    }

    /**
     * [swapArrayValueToEnd swapArrayValueToEnd]
     * @param  {[Array]} stringArray [stringArray]
     * @param  {[String]} stringValue [stringValues]
     * @return {[Array]}             [swappedArray]
     */
    function swapArrayValueToEnd(stringArray, stringValue) {
      for (var index in stringArray) {
        stringArray[index] === stringValue ? stringArray.push(stringArray.splice(index, 1)[0]) : 0;
      }
      return stringArray;
    }

    /**
     * [generateDays Generated date array from date range]
     * @param  {[Date]} startDate [startDate]
     * @param  {[Date]} endDate   [endDate]
     * @return {[Array]}           [Generated Days]
     */
    function generateDays(startDate, endDate) {
      var startDay = moment(startDate).date();
      var endDay = moment(endDate).date();
      var dayArray = _.range(startDay, endDay + 1, 1);
      var dayStringArray = dayArray.map(String);
      for (var i = 0; i < dayStringArray.length; i++) {
        dayStringArray[i] = this.padLeadingZero(dayStringArray[i], 2);
      }
      dayStringArray.push("Total");
      return dayStringArray;
    }

    /**
     * [csvToJson Convert CSV to JSON]
     * @param  {[String]} csvFilePath [csvFilePath]
     * @return {[JSON]}           [JSOn Object]
     */
    function csvToJson(csvFilePath) {
      var jsonString, jsonObj;
      var deferred = $q.defer();
      $http({
        url: csvFilePath,
        method: "GET"
      }).then(function(resp) {
        jsonString = fCsv.toJson(resp.data);
        jsonObj = JSON.parse(jsonString.replace(/\\n|\\r/g, ''));
        deferred.resolve(jsonObj);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getTimeAbbr12(value) {
      var timeConstants = angular.copy(TIME_CONSTANTS.timeConstants);
      var timeObj = _.find(timeConstants, function(time) { return time.value === value; });
      return timeObj.abbr12;
    }

    /**
     * [isValidDateRange Checks if the date range is valid or not - Provided from or toDate can be null]
     * @param {Date} fromDate 
     * @param {Date} toDate 
     */
    function isValidDateRange(fromDate, toDate){
      var isValidRange = true;
      if(fromDate && toDate){
        if(fromDate.getTime() === toDate.getTime()){
          isValidRange = true;
        }
        else if(fromDate < toDate){
          isValidRange = true;
        }
        else if(fromDate > toDate){
          isValidRange = false;
        }
      }
      return isValidRange;
    }

  }
})();
