/**
* @Author: sneha
* @Date:   16-Apr-2018 12:19 +05:30
* @Project: Ezyprocure
* @Filename: supplier.picking-list.add-template.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 11-May-2018 12:39 +05:30
*/


(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierPickingListCreateController', SupplierPickingListCreateController);

  SupplierPickingListCreateController.$inject = ['$scope', '$state',  '$mdDialog', 'toastr', 'SupplierPickingListService', 'NotificationService'];

  function SupplierPickingListCreateController($scope, $state , $mdDialog, toastr, SupplierPickingListService, NotificationService) {
    var vm = this;
    vm.getListItems = getListItems;
    vm.reset = reset;
    vm.saveTemplate = saveTemplate;
    vm.deleteItem = deleteItem;
    //defining callbacks for picking list container
    vm.pickingListSortOptions = {
      containment: '#horizontal-container',
      containerPositioning: 'relative'
    };

    /**
    * [getListItems get selected items for picking list template from service]
    */
    function getListItems(){
      vm.templateList=SupplierPickingListService.getListItems();       //temporary item list object
      vm.originalTemplateList= angular.copy(vm.templateList);  //original copy of the item list object
    }

    /**
    * [reset resets all items to original position]
    */
    function reset(){
      vm.templateList = angular.copy(vm.originalTemplateList);
    }

    /**
    * [saveTemplate save picking list template]
    */

    function saveTemplate(){
      //minimum number of items should be greater than 6
      if(vm.templateList.items.length < 6)
       toastr.error('Please select atleast 6 items to proceed!', 'Items Required');
      else {
        //set as default template
        vm.templateList.pickingList.isDefault = vm.templateList.pickingList.isDefault ? vm.templateList.pickingList.isDefault : false;
        vm.templateList.items = _.map(vm.templateList.items,  function(item){
          return {
            'id' : item.id,
            'itemName': item.itemName,
          }
        });
        SupplierPickingListService.createPickingList(vm.templateList).then(function(response) {
          NotificationService.success({
            title: 'Picking List',
            message: 'alertMessage.CREATED_SUCCESSFULLY'
          });
          $state.go('supplier.picking-list-management.picking-list', {reload:false});
        }, function(error) {
          NotificationService.error({
            title: 'Picking List',
            error: error
          });
        });
      }
    }

    /**
    * [deleteItem delete item from picking list template]
    * @param  {[type]} itemId [id of item to be deleted from template list]
    */
    function deleteItem(itemId){
      vm.templateList.items= angular.copy( _.reject(vm.templateList.items, ['id', itemId]));
      vm.originalTemplateList.items=angular.copy(vm.templateList.items);
      vm.originalTemplateList.pickingList=angular.copy(vm.templateList.pickingList);
      var itemList = SupplierPickingListService.addItemsToList(vm.originalTemplateList);
    }

  }
})();
