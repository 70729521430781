/*
 * @Author: Ajay 
 * @Date: 2019-02-18 08:59:20 
 * @Project: Ezyprocure
 * @Filename: ezyap.buyer.navbar.controller.js
 * @Last Modified by: Lovin
 * @Last Modified time: 2020-07-23 12:36:36
 */


(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('EzyapBuyerNavbarController', EzyapBuyerNavbarController);

  EzyapBuyerNavbarController.$inject = ['$rootScope', '$window', 'Auth', 'BuyerAccountService', 'BuyerDataService', 'Redirect'];

  function EzyapBuyerNavbarController($rootScope, $window, Auth, BuyerAccountService, BuyerDataService, Redirect) {

    var vm = this;
    vm.init = init;
    vm.getBuyerProfile = getBuyerProfile;
    vm.logout = logout;
    vm.ezyap = Redirect.getHostMapping();
    $rootScope.back = back;

    function init() {
      vm.getBuyerProfile();
    }

    // Gets the buyers profile
    function getBuyerProfile() {
      BuyerAccountService.getBuyerProfile().
        then(function (response) {
          if (response) {
            vm.buyerProfile = response;
            vm.buyerAccess = vm.buyerProfile.accessDTO;
            BuyerDataService.setBuyerProfile(vm.buyerProfile);
          }
        }, function (error) {
        });
    }

    function logout() {
      Auth.logout();
    }

    function back() {
      $window.history.back();
    }
  }
})();
