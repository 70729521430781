/**
* @Author: hareesh
* @Date:   10-Aug-2017 05:54 +05:30
* @Project: Ezyprocure
* @Filename: buyer.top-suppliers.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 12-Oct-2018 14:54 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerPriceTrendsController', BuyerPriceTrendsController);

  BuyerPriceTrendsController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', '$stateParams', 'NotificationService', '$mdDialog', 'pagingParams', 'BuyerDataService', 'UTCDateService', 'BuyerPriceUpdateHistoryService', 'BuyerSupplierMappingService', 'ItemGroupService', 'paginationConstants', 'BuyerBuyNowService', 'CSVExportService', 'BuyerBusinessUserMapService','EXPORT_CONSTANTS', 'GLOBAL_CONSTANTS'];

  function BuyerPriceTrendsController($scope, $rootScope, $q, $filter, $state, $stateParams, NotificationService, $mdDialog, pagingParams, BuyerDataService, UTCDateService, BuyerPriceUpdateHistoryService, BuyerSupplierMappingService, ItemGroupService, paginationConstants, BuyerBuyNowService, CSVExportService, BuyerBusinessUserMapService, EXPORT_CONSTANTS, GLOBAL_CONSTANTS) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.todaysDate = new Date();
    vm.getAllAllocatedSuppliersForBusiness = getAllAllocatedSuppliersForBusiness;
    vm.loadItemGroups = loadItemGroups;
    vm.loadAll = loadAll;
    vm.supplier = '';
    //vm.lineChart1=lineChart1;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.query = {
      limit: 20,
      page: 1
    };
    vm.filter = {
        buyerBusinessIdList: [],
        mainItemGroupIdList: []
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.getAllGroupedItemGroups = getAllGroupedItemGroups;

    //Header
    vm.PriceTrendsHeader = EXPORT_CONSTANTS.CSV.priceTrendsHeader;
    //Function Declaration
    vm.getPriceTrendsCSVData = getPriceTrendsCSVData;
    vm.setPreviousValue = setPreviousValue;
    vm.resetFilters = resetFilters;

    //Getting CSV DATA
    function getPriceTrendsCSVData(){
      var deferred = $q.defer();
      if (!vm.priceUpdateItems) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getPriceTrendsCSV(vm.priceUpdateItems);
    }

        /**
        * [getAllBuyerBusinessOutlets Gets all business outlets of buyer]
        */
       function getAllBuyerBusinessOutlets() {
        if (vm.businessOutlets)
            return false;
        vm.businessOutlets = null;
        BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser().
            then(function (response) {
                vm.businessOutlets = response;
                //if only one business outlet set it as default
                if (vm.businessOutlets.length === 1) {
                    vm.businessId = vm.businessOutlets[0].id;
                }
            }, function (error) {
            });
    }

    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
    * [getAllAllocatedSuppliersForBusiness Get all allocated suppliers of buyer business]
    */
    function getAllAllocatedSuppliersForBusiness() {
      if(vm.supplierList)
        return false;
      var params = {'businessId': !vm.businessOutletId ? '' : vm.businessOutletId};
      BuyerBuyNowService.getAllAllocatedSuppliersForBusiness(params).then(function(response){
        vm.supplierList = $filter('orderBy')(response.data, 'companyName');
        //if only one allocated supplier set it as default
        if(vm.supplierList.length === 1) {
          vm.supplierId = vm.supplierList[0].id;
        }
      },function(error){
      });
    }

    function loadItemGroups() {
      if(vm.itemGroups)
        return false;
      ItemGroupService.getAllItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.itemGroups = response.data;
      }, function(error) {
      });
    }
    function lineChart1() {
      var priceUpdateItem = vm.priceUpdateItems;
      var arr2;
      var arr3 = [];
      for (priceUpdateItem in vm.priceUpdateItems) {
        arr2 = (vm.priceUpdateItems[priceUpdateItem]);
        arr3.push( [arr2[1],  $filter('date')(arr2[7] , "MMM d, y" ),arr2[2],arr2[13]]);
      }
      if(arr3.length==0){
        var emptyData = new google.visualization.DataTable({
            cols: [
              {label: 'Performance', type: 'string'},
              {label: '', type: 'number'},
              {role: 'style', type: 'string'},
              {role: 'annotation', type: 'string'}
            ]
         });
        emptyData.addRows([['', 0, '#b87333', 'No Data Copy']]);
        var chart = new google.charts.Line(document.getElementById('Linechart_year'));
        var options = {
            chartArea: {
              left: '5%',
              width: '90%',
              height: 50
            },
            vAxis: {
              minValue: 0
            },
            legend: {
              position: 'none',
              alignment: 'center'
            }
          };
        chart.draw(emptyData, options);
      }
     else{
        // Options
        var options = {
          fontName: 'Roboto',
          height: 400,
          curveType: 'function',
          fontSize: 12,
          pointsVisible: true,
          chartArea: {
            left: '5%',
            width: '90%',
            height: 350
          },
          pointSize: 4,
          tooltip: {
            textStyle: {
              fontName: 'Roboto',
              fontSize: 30
            }
          },
          vAxis: {
            title: 'Performance',
            titleTextStyle: {
              fontSize: 13,
              italic: false
            },
            gridlines:{
              color: '#e5e5e5',
              count: 10
            },
            minValue: 0
          },
          legend: {
            position: 'top',
            alignment: 'center',
            textStyle: {
              fontSize: 12
            }
          }
        };

        var yearWiseData = google.visualization.arrayToDataTable(getPivotArray(arr3, 1, 0, 2));

        // price trends graph value in decimal
        if(yearWiseData){
            var formatNumber = new google.visualization.NumberFormat({
                pattern: '0.00'
              });
            yearWiseData.ng.forEach(function (item, index) {
                if(index > 0){
                    formatNumber.format(yearWiseData, index);
                }
            });
        }
        // price trends graph value in decimal

        var chart = new google.charts.Line(document.getElementById('Linechart_year'));
        chart.draw(yearWiseData, options);
      }
    }
    function getPivotArray(dataArray, rowIndex, colIndex, dataIndex) {
      vm.dataArrayset = dataArray;
      var result = {}, ret = [];
      var newCols = [];
      for (var i = 0; i < dataArray.length; i++) {

        // if (dataArray.indexOf(dataArray[i][colIndex]) == -1) {
        //     newCols.push(dataArray[i][colIndex]);
        //   }

        if (!result[dataArray[i][rowIndex]]) {
          result[dataArray[i][rowIndex]] = {};
        }
        if(result[dataArray[i][rowIndex]]){
            result[dataArray[i][rowIndex]][dataArray[i][colIndex]] = dataArray[i][dataIndex];
        }
        else{
            result[dataArray[i][rowIndex]][dataArray[i][colIndex]] = dataArray[i][3];
        }
        if (dataArray.indexOf(dataArray[i][colIndex]) == -1) {
          if (newCols.indexOf(dataArray[i][colIndex]) == -1) {
            newCols.push(dataArray[i][colIndex]);
          }
        }

      }

      newCols.sort();
      var item = [];
      //Add Header Row
      item.push('Performance');
      item.push.apply(item, newCols);
      ret.push(item);
      //Add content
      for (var key in result) {
        item = [];
        item.push(key);
        for (var i = 0; i < newCols.length; i++) {
          item.push(result[key][newCols[i]] || 0);
        }
        ret.push(item);
      }

     // vm.retData = [];
      vm.retData = ret;
      var ret1 = vm.setPreviousValue();
      return ret1;
    }

    /**
    * [getAllGroupedItemGroups Gets all ItemGroups Grouped by Main Item Groups]
    */
   function getAllGroupedItemGroups(mainItemGroupIdList) {
    if (vm.itemGroups && !vm.filter.mainItemGroupChanged){
      return false;
    }
    vm.filter.mainItemGroupChanged = false;
    ItemGroupService.getAllGroupedItemGroups(mainItemGroupIdList, {
      page: 0,
      size: paginationConstants.maxLimit,
      query: ''
    }).then(function(response) {
      vm.itemGroups = response.data;
    }, function(error) {
    });
  }

    /**
    * [loadAll loads all price update history]
    */
    function loadAll() {
      google.charts.load('current', {packages: ['line']});
      google.charts.setOnLoadCallback(lineChart1);
      document.getElementById('Linechart_year').style.display = "block";
      BuyerPriceUpdateHistoryService.getPriceTrends({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        fromDate: !vm.fromDate ? '' : UTCDateService.utcFromDate(vm.fromDate),
        toDate: !vm.toDate ? '' : UTCDateService.utcToDate(vm.toDate),
        supplierId: !vm.supplierId ? '' : vm.supplierId,
        itemGroupId: !vm.itemGroupId ? '' : vm.itemGroupId,
        buyerId: !vm.buyerId ? '' : vm.buyerId,
        itemName: !vm.itemName ? '' : vm.itemName,
        buyerBusinessId : !vm.filter.buyerBusinessIdList ? [] : vm.filter.buyerBusinessIdList,
        query:''
      }).then(function(response) {
        vm.priceUpdateItems = response.data;
        vm.priceUpdateItems.sort(function(a,b){
            return new Date(a[7]) - new Date(b[7]);
          });
        vm.totalItems = response.headers('X-Total-Count');
        vm.lineChart1=lineChart1();
      }, function(error) {
          console.log('error',error)
      });
    }

    function setPreviousValue(){
        var dateVal;
        for(var i = 1; i <= vm.retData.length; i++){
            if(vm.retData[i] !==undefined){
            for(var j=0; j< vm.retData[i].length; j++) {
                dateVal = vm.retData[i][0];
                if(vm.retData[i][j] == 0){
                    for(var k=0; k<vm.dataArrayset.length; k++){
                        if(vm.dataArrayset[k].indexOf(vm.retData[0][j]) !== -1) {
                            if(Date.parse(dateVal) < Date.parse(vm.dataArrayset[k][1])){
                                vm.retData[i][j] = vm.dataArrayset[k][3];
                           }else{
                            vm.retData[i][j] = vm.dataArrayset[k][2];
                           }
                        }
                    }
                }
            }
        }
        }
        return vm.retData;
    }

    function resetFilters() {
        vm.filter = {}
        vm.fromDate = vm.todaysDate;
        vm.toDate = vm.todaysDate;
        vm.itemName = '';
        vm.itemGroupId = '';
        vm.supplierId = '';
        vm.filter.buyerBusinessIdList = '';
        vm.priceUpdateItems = null;
    }

  }
})();
