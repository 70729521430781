/**
* @Author: Allan
* @Date:   22-September-2018 16:15 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.initiate-payment.details.controller.js
* @Last modified by:   Allan
* @Last modified time: 24-September-2018 14:45 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzypaymentInitiatePaymentDetailsController', EzypaymentInitiatePaymentDetailsController);

  EzypaymentInitiatePaymentDetailsController.$inject = ['paginationConstants','$state', '$q', '$timeout', '$mdDialog', 'GLOBAL_CONSTANTS', 'EzypaymentInitiatePaymentService', 'NotificationService','$stateParams','CardsService', 'toastr', 'PurchaseOrderService'];

  function EzypaymentInitiatePaymentDetailsController(paginationConstants,$state, $q, $timeout, $mdDialog, GLOBAL_CONSTANTS, EzypaymentInitiatePaymentService, NotificationService,$stateParams,CardsService, toastr, PurchaseOrderService) {

    var vm = this;
    vm.init = init;
    vm.filter = {};
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.getDuePaymentSummary = getDuePaymentSummary;
    vm.confirmAndPay = confirmAndPay;
    vm.isProceed = false;
    vm.loadTransactionReport = loadTransactionReport;
    vm.cancelPopup = cancelPopup;
    vm.calculateSum=calculateSum;
    vm.duePaymentSummaryDetails = [];
    vm.cardTokenByBrands=cardTokenByBrands;
    vm.getBrandIds=getBrandIds;
    vm.brandIds = [];
    vm.tokenCards = [];
    vm.getExternalDuePaymentSummary=getExternalDuePaymentSummary;
    vm.selectedInvoiceIdsList = $stateParams.selectedInvoiceIds;
    var selectedInvoiceIds = EzypaymentInitiatePaymentService.getSelectedInvoiceIds();
    vm.type=$stateParams.type;
    vm.selectedRow = {};
    // 2FA changes
    vm.otp = undefined;
    vm.isOtpValid = true;
    vm.counterMilliSeconds = undefined;
    vm.counterSeconds = undefined;
    vm.counterMinutes = undefined;
    vm.validateOTP = validateOTP;
    vm.resendOTP = resendOTP;
    vm.showPrompt = showPrompt;

    function init(){
      if (vm.type == 'EXTERNALSIP' || vm.type === 'EXTERNAL_SIP_WITH_CREDIT_NOTE') {
        vm.getExternalDuePaymentSummary();
      } else {
        vm.getDuePaymentSummary();
      }
    }

    function getExternalDuePaymentSummary() {
        if(vm.type === 'EXTERNAL_SIP_WITH_CREDIT_NOTE') {
            EzypaymentInitiatePaymentService.getDuePaymentSummaryWithCredit({
                invoiceIdList: String(vm.selectedInvoiceIdsList),
                paymentType: vm.type
              }).then(function (response) {
                vm.duePaymentSummaryDetails = response.data;
                if(vm.duePaymentSummaryDetails.length > 0) {
                    vm.getBrandIds(response.data);
                    vm.cardTokenByBrands();
                }
              }, function (error) {
                NotificationService.error({
                  title: 'Fetching External Due Payment Summary',
                  error: error
                });
              });
          } else {
            EzypaymentInitiatePaymentService.getExternalDuePaymentSummary({
        invoiceIdList: String(vm.selectedInvoiceIdsList),
        hasPaymentApproval: false,
        paymentType: vm.type
            }).then(function (response) {
        vm.duePaymentSummaryDetails = response.data;
        vm.getBrandIds(response.data);
        vm.cardTokenByBrands();
      }, function (error) {
        NotificationService.error({
          title: 'Fetching External Due Payment Summary',
          error: error
        });
           });
          }
    }

    // timer for otp resend
    function counter() {
      vm.stopped = $timeout(function () {
        vm.counterMilliSeconds -= 1000;
        if (vm.counterMilliSeconds) {
          var seconds = vm.counterMilliSeconds / 1000;
          vm.counterMinutes = Math.floor(seconds / 60);
          if(vm.counterMinutes > 0) {
            vm.counterSeconds = seconds - (vm.counterMinutes * 60);
          }
          else {
            vm.counterSeconds = seconds;
          }
          counter();
        }
        else {
          vm.counterSeconds = 0;
          vm.counterMinutes = 0;
          stopCounter();
        }
      }, 1000);
    };

    function stopCounter(){
      vm.isOtpValid = false;
      $timeout.cancel(vm.stopped);
    }

    function cancelPopup() {
        $mdDialog.cancel();
    }

    function showPrompt(index) {
        PurchaseOrderService.getPurchaseOrderByPaymentId(index.paymentId, {
            deliveryDate: !vm.deliveryDate ? '' : vm.deliveryDate,
          }).then(function(response){
            vm.selectedRow = response.data;
            // show prompt
            $mdDialog.show({
              templateUrl: 'app/core/common-templates/statement-view-popup.tmpl.html',
              controller: function () {
                return vm;
              },
              controllerAs: 'vm',
              targetEvent: event,
              clickOutsideToClose: true
            });
          },function(error){
            NotificationService.error({
              title: 'Credit Note',
              error: error
            });
          });
      }
    function showOtpPrompt() {
      // clear otp
      vm.otp = undefined;
      vm.isOtpValid = true;
      vm.counterSeconds = 0;
      vm.counterMinutes = 0;
      vm.counterMilliSeconds = GLOBAL_CONSTANTS.OTP_EXPIRY_MINUTES * 60 * 1000;
      counter();
      // show prompt
      $mdDialog.show({
        templateUrl: 'app/core/common-templates/otp-templates/otp-capture.tmpl.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function validateOTP() {
      if (!vm.otp) {
        toastr.error('Invalid OTP!', 'Please check the OTP entered');
        return;
      }

      if (vm.otp && (vm.otp.length < 5 || vm.otp.length > 8)) {
        toastr.error('Invalid OTP!', 'Please check the OTP entered');
        return;
      }
      confirmAndPay(vm.otp);
    }

    function resendOTP() {
      confirmAndPay();
    }

   /**
     * [calculateSum shows the sum of key in list]
    */
    function calculateSum(duePaymentSummaryDetailsList, key) {
      var sum = 0;
      if(duePaymentSummaryDetailsList && duePaymentSummaryDetailsList.length > 0) {
        duePaymentSummaryDetailsList.forEach( function (invoice) {
          sum += Number(Number(invoice[key]).toFixed(2));
        });
      }
      return sum;
    }

    function getBrandIds(params) {
      _.filter(params, function (poList) {
        if (vm.brandIds.indexOf(poList.brandId) === -1) {
          vm.brandIds.push(poList.brandId);
        }
      });
    }

    function cardTokenByBrands() {
      CardsService.getAllCards({
        brandIds: vm.brandIds,
        page: 0,
        size: paginationConstants.maxLimit
      }).then(function (response) {
        vm.tokenCards = response.data;
      }, function (error) {
        NotificationService.error({
          title: 'Fetching Token By Cards',
          error: error
        });
      });
    }

    function getDuePaymentSummary(){
      if(!vm.selectedInvoiceIdsList){
        NotificationService.simpleErrorToast({
          title: 'global.fetchingInvoiceDetails',
          message: 'global.pleaseSelectInvoiceToPay'
        });
        $state.go('ezypayment.supplier.initiate-payment');
        return false;
      }
      EzypaymentInitiatePaymentService.getDuePaymentSummary({
          purchaseOrderIdList: String(vm.selectedInvoiceIdsList),
          hasPaymentApproval: false,
          approvalSeqNo: '',
          hasOcrAccess: false,
          paymentType: vm.type
        }).then(function(response){
          vm.duePaymentSummaryDetails = response.data;
        },function(error) {
          NotificationService.error({
            title: 'Fetching Due Payment Summary',
            error: error
          });
        });
    }

    function confirmAndPay(otp){
      var flagRemit = false;
      var flagNegative = false;
      vm.duePaymentSummaryDetails.forEach(function (elem) {
        if(!elem.remitanceAmountValid) {
          flagRemit = true;
        }
        if(elem.totalPayable < 0 ){
          flagNegative = true;
        }
      });
      if(flagNegative && vm.type == 'EXTERNAL_SIP_WITH_CREDIT_NOTE'){
        toastr.error('Net amount should be greater then 0', 'VALIDATION FAILED');
        return;
      }
      if(flagRemit && vm.type == 'EXTERNAL_SIP_WITH_CREDIT_NOTE'){
        toastr.error('Remitance amount should be valid', 'VALIDATION FAILED');
        return;
      }
      if (vm.type == 'EXTERNALSIP' || vm.type == 'EXTERNAL_SIP_WITH_CREDIT_NOTE') {
        _.each(vm.duePaymentSummaryDetails, function (detail, index) {
            if(vm.tokenCards.length > 0) {
                vm.duePaymentSummaryDetails[index].tokenId = vm.tokenCards.filter(function(e){
                    return e.brandId === vm.duePaymentSummaryDetails[index].brandId;
                })[0].token;
            }else{
                vm.duePaymentSummaryDetails[index].tokenId = null;
            }
        });
      }
      if(vm.type == 'EXTERNAL_SIP_WITH_CREDIT_NOTE'){

        EzypaymentInitiatePaymentService.confirmPaymentOctoValidate(
            {
              paymentType: vm.type
            },
            vm.duePaymentSummaryDetails).then(function(response) {
                var listNotValid = [];
                response.data.forEach(function(elem){
                    if(!elem.transactionValid) {
                        elem.octopusInvoicesDTO.forEach(function(el) {
                            if(!el.isValidTransaction) {
                                listNotValid.push(el.invoiceNumber ? el.invoiceNumber : el.creditNoteNumber)
                            }
                        })
                    }
                });
                if(listNotValid.length > 0) {
                    toastr.error('Below list of Invoice and Credit note are already processed. \n' + listNotValid.toString(), 'VALIDATION FAILED');
                    return;
                } else {
                      EzypaymentInitiatePaymentService.confirmPaymentOcto(
            {
              paymentType: vm.type
            },
            vm.duePaymentSummaryDetails, otp).then(function(response) {
              if (response.headers('authorization-otp') == 'OTP_VERIFICATION_REQUIRED') {
                showOtpPrompt();
              }
              else if(GLOBAL_CONSTANTS.OTP_EXCEPTIONS.includes(response.headers('authorization-otp'))) {
                toastr.error('Invalid OTP', 'AUTHENTICATION FAILED');
              }
              else {
                NotificationService.success({
                  title: 'Processed',
                  message: 'Payment For Invoices'
                });
                $mdDialog.hide();
                vm.paymentResponse = response;
                EzypaymentInitiatePaymentService.setPaymentResponse(vm.paymentResponse);
                $state.go('ezypayment.supplier.initiate-payment.details.transaction-report');
              }
            },function(error) {
              if (error.status === 524) {
                error.data = {message: 'Your payments are processing. Please wait for few minutes and check.'};
                NotificationService.error({
                  title: 'Payment For Invoices',
                  error: error
                });
              } else {
                NotificationService.error({
                  title: 'Payment For Invoices',
                  error: error
                });
              }
            });
                }
            },function(error) {
              if (error.status === 524) {
                error.data = {message: 'Your Validation are processing. Please wait for few minutes and check.'};
                NotificationService.error({
                  title: 'Validation For Invoices',
                  error: error
                });
              } else {
                NotificationService.error({
                  title: 'Validation For Invoices',
                  error: error
                });
              }
            });

      } else {
      EzypaymentInitiatePaymentService.confirmPayment(
        {
          hasPaymentApproval: false,
          approvalSeqNo: '',
          paymentType: vm.type
        },
        vm.duePaymentSummaryDetails, otp).then(function(response) {
          if (response.headers('authorization-otp') == 'OTP_VERIFICATION_REQUIRED') {
            showOtpPrompt();
          }
          else if(GLOBAL_CONSTANTS.OTP_EXCEPTIONS.includes(response.headers('authorization-otp'))) {
            toastr.error('Invalid OTP', 'AUTHENTICATION FAILED');
          }
          else {
            NotificationService.success({
              title: 'Processed',
              message: 'Payment For Invoices'
            });
            $mdDialog.hide();
            vm.paymentResponse = response;
            EzypaymentInitiatePaymentService.setPaymentResponse(vm.paymentResponse);
            $state.go('ezypayment.supplier.initiate-payment.details.transaction-report');
          }
        },function(error) {
          if (error.status === 524) {
            error.data = {message: 'Your payments are processing. Please wait for few minutes and check.'};
            NotificationService.error({
              title: 'Payment For Invoices',
              error: error
            });
          } else {
            NotificationService.error({
              title: 'Payment For Invoices',
              error: error
            });
          }
        });
      }
      }

      function loadTransactionReport(){
        vm.paymentResponse = EzypaymentInitiatePaymentService.getPaymentResponse();
        vm.duePaymentSummaryDetails = vm.paymentResponse.data;
      }



    }
  })();
