/**
 * @Author: Ajay
 * @Date:   18-Jul-2018 15:49 +05:30
 * @Project: Ezyprocure
 * @Filename: manage-epd.add.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-07-17T13:39:21+05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('ManageEPDAddController', ManageEPDAddController);

  ManageEPDAddController.$inject = ['$scope', '$state', '$stateParams', 'Auth', 'EpdApprovalRequestService', 'GLOBAL_CONSTANTS', 'NotificationService', 'BrandManagementService', 'ManageSupplierService', 'paginationConstants', 'EzypaymentBrandSupplierPaymentModelMapService', 'SupplierDataService'];

  function ManageEPDAddController($scope, $state, $stateParams, Auth, EpdApprovalRequestService, GLOBAL_CONSTANTS, NotificationService, BrandManagementService, ManageSupplierService, paginationConstants, EzypaymentBrandSupplierPaymentModelMapService, SupplierDataService) {
    var vm = this;

    vm.epdApprovalRequest = {
      brand: {},
      supplier: {},
      creditTerms: GLOBAL_CONSTANTS.DEFAULT_CREDIT_TERM
    };

    vm.save = save;
    vm.userRole = Auth.getUserRole();
    if(vm.userRole.pretty === 'supplier'){
      vm.epdApprovalRequest.supplier.id = SupplierDataService.getSupplierProfile().supplier.id;
    }
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.getAllAdhocSuppliers = getAllAdhocSuppliers;
    vm.getAllSupplierMappedPaymentModelBrands = getAllSupplierMappedPaymentModelBrands;
    vm.supplierSelectionChanged = supplierSelectionChanged;

    /**
     * [getAllAdhocSuppliers Get list of ADHOC suppliers]
     */
    function getAllAdhocSuppliers() {
      ManageSupplierService.getAllAdminSuppliersWithUserDetails({
        search: '',
        visibilityType: '',
        page: 0,
        size: paginationConstants.maxLimit
      }).then(function(response){
        vm.suppliers = response.data;
        vm.epdApprovalRequest.brand.id=[];
      },function(error){
      });
    }

    function supplierSelectionChanged() {
      vm.brands = undefined;
      vm.epdApprovalRequest.brand.id = undefined;
    }

    /**
     * [getAllSupplierMappedPaymentModelBrands Get list of all brands of selected supplier]
     */
    function getAllSupplierMappedPaymentModelBrands(){
      if(vm.brands)
        return false;
      EzypaymentBrandSupplierPaymentModelMapService.getAllSupplierMappedPaymentModelBrands({
        supplierId : vm.epdApprovalRequest.supplier.id,
        paymentModel: 'BUYER_FUNDED_WITH_REBATES'
      }).then(function(response){
        vm.brands = response.data;
      });
    }

    function save() {
      EpdApprovalRequestService.createEPDApprovalRequest(vm.epdApprovalRequest).then(function(response) {
        $scope.$emit('ezyprocureApp:epdApprovalRequestUpdate', response);
        NotificationService.success({
          title: 'EPD Approval Request',
          message: 'alertMessage.CREATED_SUCCESSFULLY'
        });
        $state.go('ezypayment.'+vm.userRole.pretty+'.epd-approval-requests', {
          reload: false
        });
      }, function(error) {
        NotificationService.error({
          title: 'EPD Approval Request Creation',
          error: error
        });
      });
    }

  }
})();
