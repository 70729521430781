/**
* @Author: Liya
* @Date:   06-09-2021 20:57 +05:30
* @Project: Ezyprocure
* @Filename: decimal.filter.js
 * @Last modified by:   Liya
 * @Last modified time: 06-09-2021 13:14 +05:30
*/



(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .filter('totalRoundOfDecimal', totalRoundOfDecimal);
  
    function totalRoundOfDecimal() {
      return totalRoundOfDecimal;

      function totalRoundOfDecimal(amount) {
        var value = amount !== null ? amount : 0;
        return (Math.round(value.toFixed(4) * 1000 /10) / 100).toFixed(2);
      }
    }
  })();
  