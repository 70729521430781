/*
 * @Author: Lovin C 
 * @Date: 2021-09-13 13:18:38 
 * @Last Modified by:   Lovin C 
 * @Last Modified time: 2021-09-13 13:18:38 
 */


(
    function () {
        'use strict';

        angular.module('ezyprocureApp').config(stateConfig);

        stateConfig.$inject = ['$stateProvider'];

        function stateConfig($stateProvider) {
            $stateProvider
                .state('ezypayment.admin.tools.custom-payments', {
                    parent: 'ezypayment.admin.tools',
                    url: '/custom-payments',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/ezypayment-modules/admin-modules/tools/custom-payments/ezypayment.custom-payments.html',
                            controller: 'AdminCustomPaymentsController',
                            controllerAs: 'vm'
                        }
                    },
                    params: {
                        page: {
                            value: '1',
                            squash: true
                        },
                        sort: {
                            value: 'id,asc',
                            squash: true
                        },
                        search: null
                    },
                    resolve: {
                        pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage($stateParams.page),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate($stateParams.sort),
                                ascending: PaginationUtil.parseAscending($stateParams.sort),
                                search: $stateParams.search
                            };
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('global');
                            $translatePartialLoader.addPart('brand');
                            return $translate.refresh();
                        }]
                    }
                })
        }
    }
)();