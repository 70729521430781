/*
 * @Author: Lovin 
 * @Date: 2019-10-15 19:15:49 
 * @Filename: ezypayment.admin.account-details.edit.controller.js
 * @Last Modified by: Lovin
 * @Last Modified time: 2019-12-05 15:24:16
 */


(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .controller('EzyPaymentAccountDetailsEditController', EzyPaymentAccountDetailsEditController);
  
    EzyPaymentAccountDetailsEditController.$inject = ['$state', '$stateParams','Auth','EzypaymentAccountDetailsService','BuyerDataService','SupplierDataService','BuyerSupplierMappingService', 'GLOBAL_CONSTANTS', 'NotificationService', 'ManageSupplierService','BankService','paginationConstants','ManageBuyerService','BrandManagementService','BrandSupplierMapService'];
  
    function EzyPaymentAccountDetailsEditController($state, $stateParams, Auth, EzypaymentAccountDetailsService,BuyerDataService,SupplierDataService, BuyerSupplierMappingService,  GLOBAL_CONSTANTS, NotificationService, ManageSupplierService, BankService, paginationConstants,ManageBuyerService, BrandManagementService, BrandSupplierMapService) {
  
      var vm = this;
      vm.userRole = Auth.getUserRole();
      vm.brandId = $stateParams.brandId;
      vm.updateBankAccountDetails = updateBankAccountDetails;
      vm.gatewayTypes = GLOBAL_CONSTANTS.GATEWAY_TYPES;
      vm.init = init;
      vm.getAccountDetailsById = getAccountDetailsById;
      vm.getAllBanks = getAllBanks;
      vm.selectedBankChanged = selectedBankChanged;
      vm.getAllBuyerMappedSuppliers = getAllBuyerMappedSuppliers;
      vm.getAllBuyersMappedToPayment = getAllBuyersMappedToPayment;
      vm.getAllBrandsOfBuyerList = getAllBrandsOfBuyerList;
      vm.loadDropdowns = loadDropdowns;
      vm.paymentTerms = {};
      vm.init();

      function init(){
       vm.getAllBanks();
       vm.getAccountDetailsById();
      }

      /**
      * [loadDropdowns The function executes when the dropdown closes]
      */
    function loadDropdowns(){
      vm.getAllBuyersMappedToPayment();
      if(vm.accountDetails.brand != null ){
      vm.accountDetails.brand.id = '';
      vm.accountDetails.supplier.id = '';
      }
    }
     
    /**
     * [getAllBuyers Gets all buyers mapped with the payment model]
     */
    function getAllBuyersMappedToPayment(){
      if(vm.userRole.pretty == 'supplier'){
        var params = {
          page: 0,
          size: paginationConstants.maxLimit,
        };
        var supplierId = SupplierDataService.getSupplierProfile().supplier.id;
        BuyerSupplierMappingService.getAllSupplierMappedBuyers(supplierId, params).then(function(response) {
          vm.buyers = response.data;
        }, function(error) {
        });
      }
      else {
       ManageBuyerService.getAllAdminBuyers({
        page: 0,
        size: paginationConstants.maxLimit,
        search: ''
       }).then(function(response) {
        vm.buyers = response.data;
       }, function(error) {
        NotificationService.error({
          title: 'Buyer Listing',
          error: error
        });
       });
      }
    }

     /**
     * [getAllBrandsOfBuyerList Gets all brands of selected buyers]
     * @param {Array} buyerId
     */
    function getAllBrandsOfBuyerList(buyerId){
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        query:'',
        buyerId: !buyerId ? BuyerDataService.getBuyerProfile().buyerUser.buyer.id: buyerId
      };
      BrandManagementService.getAllBrandsOfBuyerList(params)
      .then(function(response){
        vm.brands = response;
      },function(error){
        NotificationService.error({
          title: 'Brand Listing',
          error: error
        });
      });
    }

     /**
     * [getAccountDetailsById Gets all account details by id]
     * @param {Array} brandId
     */
    function getAccountDetailsById() {
      EzypaymentAccountDetailsService.getAccountDetailsById(vm.brandId).then(function(response) {
        vm.accountDetails = response.data;
        if(vm.accountDetails.brand != null ){
        vm.buyerId = vm.accountDetails.brand.buyer.id;
        vm.brandId = vm.accountDetails.brand.id;
        vm.userRole.pretty != 'buyer'? vm.getAllBuyersMappedToPayment():'';
        vm.getAllBrandsOfBuyerList(vm.buyerId);
        vm.getAllBuyerMappedSuppliers(vm.buyerId);
        }
        if(vm.accountDetails.brand == null && vm.userRole.pretty != 'admin') {
          vm.buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
          vm.getAllBuyerMappedSuppliers(vm.buyerId);
        }
      }, function(error) {
        vm.buyerId = 
        NotificationService.error({
          title: ' Account Detail',
          error: error
        });
      });
    } 
  
    /**
     * [updateBankAccountDetails Creates new account details]
     */
    function updateBankAccountDetails(){
      if(vm.userRole.pretty == 'supplier'){
        vm.accountDetails.supplier.id = SupplierDataService.getSupplierProfile().supplier.id;;
      }
      vm.paymentTermsDTO = {
        'id':vm.accountDetails.id,
        'accountName': vm.accountDetails.accountName,
        'accountNumber': vm.accountDetails.accountNumber,
        'paymentType':"BANK_TRANSFER",
        'isActive':true,
        'bank': {
          id: vm.accountDetails.bank.id,
          bankCode: vm.accountDetails.bank.bankCode,
          bankName: vm.accountDetails.bank.bankName,
          swiftCode: vm.accountDetails.bank.swiftCode
        },
        'brand':{
          'id':vm.accountDetails.brand.id
        },
        'supplier':{
          'id':vm.accountDetails.supplier.id}
        }
      EzypaymentAccountDetailsService.updateAccountDetail(vm.paymentTermsDTO).then(function(response){
        NotificationService.success({
          title: ' Account Details',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        $state.go('ezypayment.'+vm.userRole.pretty+'.account-details',{reload: false, initialActive: true})
      }, function(error) {
        NotificationService.error({
          title: ' Account Detail',
          error: error
        });
      });
    }
  
    /**
   * [getAllBrandMappedSuppliers Get list of mapped suppliers to the selected buyer]
   */
  function getAllBuyerMappedSuppliers(buyerId) {
    var params = {
      page: 0,
      size: paginationConstants.maxLimit
    }
    BuyerSupplierMappingService.getAllBuyerMappedSuppliers(buyerId,params)
    .then(function(response){
      vm.suppliers = response.data;
    });
  }
  
  /**
   * [getAllBanks Gets all banks]
   */
  function getAllBanks() {
    BankService.getAllBanks({
      page: 0,
      size: paginationConstants.maxLimit,
      query: ''
    }).then(function(response) {
      vm.banks = response.data;
    }, function(error) {
    });
  }
  
  /**
  * [selectedBankChanged Get all the details of selected bank]
  */
  function selectedBankChanged(bankId) {
    var banks = angular.copy(vm.banks);
    vm.selectedBank = BankService.getSelectedBank(banks, bankId);
    vm.accountDetails.bank.bankCode = vm.selectedBank.bankCode;
    vm.accountDetails.bank.swiftCode = vm.selectedBank.swiftCode;
    vm.accountDetails.bank.bankName = vm.selectedBank.bankName;
  }
 }
})();
  