/**
* @Author: Mufsir
* @Date:   14-Dec-2016 12:54 +05:30
* @Project: Ezyprocure
* @Filename: admin.navbar.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-10-29T10:05:09+05:30
*/



(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('AdminNavbarController', AdminNavbarController);

  AdminNavbarController.$inject = ['$rootScope', '$window', '$stateParams', '$timeout', '$state', '$mdDialog', 'ReleaseNotesMasterService', 'WhatsNewService', 'Auth', 'AuthServerProvider', 'Principal', 'MobileUserService', 'API_CONSTANTS'];

  function AdminNavbarController($rootScope, $window, $stateParams, $timeout, $state, $mdDialog, ReleaseNotesMasterService, WhatsNewService, Auth, AuthServerProvider, Principal, MobileUserService, API_CONSTANTS) {
    var vm = this;
    vm.logout = logout;
    $rootScope.back = back;
    vm.hasCbPlatform = hasCbPlatform;
    // whats new dialog
    vm.whatsNew = {};
    vm.hideDialog = hideDialog;
    vm.cancelDialog = cancelDialog;

    checkWhatsNewStatus();

    function checkWhatsNewStatus() {
      ReleaseNotesMasterService.getActiveReleaseNotes().then(function(response) {
        vm.whatsNew = response.data[0];
        if(Array.isArray(response.data) && WhatsNewService.getHasReadValue(response.data[0].releaseVersion)) {
          popWhatsNewDialog();
        }
      }, function(error) {

      });
    }

    function popWhatsNewDialog() {
      $mdDialog.show({
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        templateUrl: 'app/common-modules/whats-new/whats-new-tmpl.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false
      }).then(function () {
        WhatsNewService.updateHasReadValue(true);
      }, function () {
        WhatsNewService.updateHasReadValue(false);
      });
    }

    function hideDialog() {
      $mdDialog.hide();
    }

    function cancelDialog() {
      $mdDialog.cancel();
    }

    /**
    * [logout logout method]
    */
    function logout() {
      if (Principal.isCBEnabled() && Principal.hasCbPlatform()) {
        Auth.checkCbHealth().then(function () {
          Auth.logoutBothPlatform()
            .then(function () {
              $window.location.href = API_CONSTANTS.cbBase + '#/autologin/delete/null/'
            });
        }).catch(function () {
          Auth.logout();
        });
      } else {
        Auth.logout();
      }
    }

    function back() {
      $window.history.back();
    }

    function hasCbPlatform() {
      return Principal.hasCbPlatform() && Principal.isCBEnabled();
    }

    vm.redirectToCbPlatform = function () {
      Auth.checkCbHealth().then(function () {
        var token = AuthServerProvider.getCbToken();
        var domToken = AuthServerProvider.getToken();
        if (token) {
          $window.location.href = API_CONSTANTS.cbBase + '#/autologin/save/' + token + '/' + domToken;
        } else {
          $window.location.href = API_CONSTANTS.cbBase;
        }
      }).catch(function () {
        toastr.error('Something went wrong');
      });
    }
  }
})();
