/**
* @Author: Ajay
* @Date:   29-Dec-2016 15:05 +05:30
* @Project: Ezyprocure
* @Filename: rolebaserouting.constants.js
* @Last modified by:   Mufsir
* @Last modified time: 28-Mar-2017 21:25 +05:30
*/



"use strict";
angular.module('ezyprocureApp')
.constant('ROLE_CONSTANTS', {
  "routingData": {
    "ROLE_ADMIN" : 'admin.dashboard',
    "ROLE_BUYER" : 'buyer.dashboard',
    "ROLE_SUPPLIER" : 'supplier.dashboard'
  },
  "roleNames": {
    "ROLE_ADMIN" : 'admin',
    "ROLE_BUYER" : 'buyer',
    "ROLE_SUPPLIER" : 'supplier'
  }
});
