(function () {
  'use strict';

  angular.module('ezyprocureApp').factory('BuyerSupplierMappingTableService', BuyerSupplierMappingTableService);

  BuyerSupplierMappingTableService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS', 'AuthServerProvider'];
  function BuyerSupplierMappingTableService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS, AuthServerProvider) {
    var BuyerSupplierMappingTableService = {
      getBuyerOrSupplierList: getBuyerOrSupplierList,
      addBUyerOrSupplier: addBUyerOrSupplier,
      fetchInvitedUsers: fetchInvitedUsers,
      mapSupplier: mapSupplier,
      resendEmail: resendEmail,
      viewMappedUsers: viewMappedUsers,
      getExistingUsersList: getExistingUsersList,
      unmappedUsers: unmappedUsers,
      unmappedSuppliers: unmappedSuppliers,
      getCurrencyList: getCurrencyList,
      getCurrencyMapped: getCurrencyMapped,
      mapCurrencyWithBuyer: mapCurrencyWithBuyer,
      confirmCurrencyMapping: confirmCurrencyMapping,
      rejectCurrencyMapping: rejectCurrencyMapping,
      currencyMappingRequest: currencyMappingRequest,
      companyValidation: companyValidation,
      companySupplierValidation: companySupplierValidation,
      emailValidate: emailValidate,
      buyerSupplierMapping: buyerSupplierMapping,
      getSelectedBrands: getSelectedBrands,
      getCountry: getCountry
    };
    return BuyerSupplierMappingTableService;
    function getBuyerOrSupplierList(query) {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http.post(
        API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + 'filter/buyer-supplier', query,
        {
          headers: { Authorization: 'Bearer ' + cbAuthToken }
        }
      ).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function buyerSupplierMapping(buyerId, supplierId, userType, params){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyer-supplier-mappings/' + buyerId + '/' + supplierId + '/' + userType,
        method: "POST",
        params: params
      })
     .then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getCountry(){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'country-code',
        method: "GET"
      })
     .then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getExistingUsersList(query) {
      var deferred = $q.defer();
      $http.post(
        API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + '/buyer-supplier-mappings/allocatedlist', query
      ).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }


    function addBuyerSupplier(request) {
      var deferred = $q.defer();
      $http.post(
        API_CONSTANTS.serviceBase  + 'api/' + API_CONSTANTS.baseVersion + 'invites', request
      ).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function unmappedUsers(request) {
      var deferred = $q.defer();
      $http.post(
        API_CONSTANTS.serviceBase  + 'api/' + API_CONSTANTS.baseVersion + 'buyer-supplier-mappings/non-allocated', request
      ).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    function unmappedSuppliers(buyerId) {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http.post(
        API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.EXISTINGMAPPEDSUPPLIERS + '?supplierId=' + buyerId,
        {
          headers: { Authorization: 'Bearer ' + cbAuthToken }
        }
      ).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function fetchInvitedUsers(query) {
      var deferred = $q.defer();
      $http.post(
        API_CONSTANTS.serviceBase  + 'api/' + API_CONSTANTS.baseVersion + 'invites/invitedlist', query
      ).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getSelectedBrands(buyerId){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'selected-brands' + '/' + buyerId,
        method: "GET",
      })
     .then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function mapSupplier(buyerId, supplierId,userType,brandListDTO) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyer-supplier-mappings/request-mapping/buyer/' + buyerId + '/supplier/' + supplierId + '/userType/' + userType,
        method: "POST",
        data: brandListDTO
      })
     .then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function resendEmail(emailId) {
      var deferred = $q.defer();
      $http.post(
        API_CONSTANTS.serviceBase  + 'api/' + API_CONSTANTS.baseVersion + 'invites/resendinvite',emailId
        ).then(function (data) {
          deferred.resolve(data.data);
        }).catch(function (message) {
          deferred.reject(message);
        });
      return deferred.promise;
    }

    function viewMappedUsers(buyerid, supplierId) {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http.post(
        API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.MAPSUPPLIER + 'buyer/' + buyerid + '/supplier/' + supplierId + '/true', {},
        {
          headers: { Authorization: 'Bearer ' + cbAuthToken }
        }
      ).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    function addBUyerOrSupplier(userInfo) {
      var deferred = $q.defer();
      $http.post(
        API_CONSTANTS.serviceBase  + 'api/' + API_CONSTANTS.baseVersion + 'invites',userInfo    
      ).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getCurrencyList() {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http.get(
        API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.CURRENCYLIST,
        {
          headers: { Authorization: 'Bearer ' + cbAuthToken }
        }
      ).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getCurrencyMapped(buyerId, supplierId) {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http.get(
        API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.CURRENCYMAPPED + buyerId +
        RESOURCE_URL_CONSTANTS.SUPPLIER + supplierId,
        {
          headers: { Authorization: 'Bearer ' + cbAuthToken }
        }
      ).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function mapCurrencyWithBuyer(buyerId, supplierId, ccc) {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http.post(
        API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.CURRENCYMAPPED + buyerId +
        RESOURCE_URL_CONSTANTS.SUPPLIER + supplierId + RESOURCE_URL_CONSTANTS.CURRENCY + ccc, {},
        {
          headers: { Authorization: 'Bearer ' + cbAuthToken }
        }
      ).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function currencyMappingRequest(query) {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http.post(
        API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.TRANSACTIONAL_CURRENCIES, query,
        {
          headers: { Authorization: 'Bearer ' + cbAuthToken }
        }
      ).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function confirmCurrencyMapping(transactionalId) {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http.put(
        API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.TRANSACTIONAL_CURRENCIES_UPDATE
        + transactionalId,
        {
          headers: { Authorization: 'Bearer ' + cbAuthToken }
        }
      ).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function rejectCurrencyMapping(transactionalId) {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http.put(
        API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.TRANSACTIONAL_CURRENCIES_DELETE
        + transactionalId,
        {
          headers: { Authorization: 'Bearer ' + cbAuthToken }
        }
      ).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function companyValidation(companyName, regNo, code) {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http.post(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYERCOMPANY + companyName + '/registrationnumber/' + regNo + '/companycode/' + code
        , {}, { responseType: 'blob', Authorization: 'Bearer ' + cbAuthToken }).then(function (data) {
          deferred.resolve(data.data);
        }).catch(function (message) {
          deferred.reject(message);
        });
      return deferred.promise;
    }

    function companySupplierValidation(companyName, regNo, code) {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http.post(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.SUPPLIERCOMPANY + companyName + '/registrationnumber/' + regNo + '/companycode/' + code
        , {}, { responseType: 'blob', Authorization: 'Bearer ' + cbAuthToken }).then(function (data) {
          deferred.resolve(data.data);
        }).catch(function (message) {
          deferred.reject(message);
        });
      return deferred.promise;
    }

    function emailValidate(email, username) {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http.post(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + 'users/validate-signup/', { eMail: email, userName: username },
        {
          headers: { Authorization: 'Bearer ' + cbAuthToken }
        }).then(function (data) {
          deferred.resolve(data.data);
        }).catch(function (message) {
          deferred.reject(message);
        });
      return deferred.promise;
    }
  }
})();
