/**
 * @Author: Ajay
 * @Date:   27-Dec-2017 16:11 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.purchase-approval-request.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-09-30T15:24:51+05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerPurchaseApprovalRequestController', BuyerPurchaseApprovalRequestController);

  BuyerPurchaseApprovalRequestController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'NotificationService', 'BuyerBusinessService', 'BuyerSupplierMappingService', '$mdDialog', 'BuyerPurchaseApprovalRequestService', 'BuyerDataService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'pagingParams', 'Auth','toastr','$filter'];

  function BuyerPurchaseApprovalRequestController($scope, $rootScope, $state, $stateParams, NotificationService, BuyerBusinessService, BuyerSupplierMappingService, $mdDialog, BuyerPurchaseApprovalRequestService, BuyerDataService, GLOBAL_CONSTANTS, paginationConstants, pagingParams, Auth,toastr,$filter) {
    var vm = this;
    vm.init = init;
    vm.loadAll = loadAll;

    vm.approvalStatus = $stateParams.approvalStatus;
    vm.approvalStatusCap=vm.approvalStatus.toUpperCase();
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.buyerId = BuyerDataService.getBuyerProfile().buyerUser.id;
    vm.selectAllPR = selectAllPR;
    vm.checkForSameBusinessOutlets = checkForSameBusinessOutlets;
    vm.mergePurchaseRequest = mergePurchaseRequest;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.accessForPriceDisplay = Auth.hasPermission('accessForPriceDisplay');
    vm.accessForApprover = BuyerDataService.getBuyerProfile().accessDTO.accessForApprover;
    vm.purchaseApprovalAllRequests = purchaseApprovalAllRequests;
    vm.getSelectedPR = getSelectedPR;
    vm.showRejectModal = showRejectModal;
    vm.cancel = cancel;
    vm.purchaseApprovalValidation = purchaseApprovalValidation;
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.getAllBuyerMappedSuppliers = getAllBuyerMappedSuppliers;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.filter = {
      buyerBusinessIdList : [],
      supplierIdList : []
    };
    vm.deliveryCondition = [];
    vm.prnoPresentArray = [];
    vm.prnoPresentArrayElements = '';
    vm.handleApprovalPending = handleApprovalPending;
    vm.todaysDate = new Date();
    function init() {
      vm.loadAll();
    }

    //get all selected purchase approval request
    function getSelectedPR(){
      var selectedPR =  _.filter(vm.purchaseApprovalRequests, function(eachRequest){
          return eachRequest.selected == true;
      });
      return selectedPR;
     }

    // validate pr request
    function purchaseApprovalValidation(status){
      vm.status = status;
      vm.approvalNumberList=[];
      vm.approvalIdList=[]
      vm.selectedPR =  vm.getSelectedPR();
      if(vm.selectedPR.length < 1){
        NotificationService.simpleErrorToast({
          title: 'global.purchaseApprovalRequest',
          message: 'alertMessage.PLEASE_SELECT_PURCHASE_APPROVAL_REQUEST'
        });
        return false;
      }
      else {
        _.forEach(vm.selectedPR, function(prRequest) {
          if(prRequest.approver){
            vm.approvalNumberList.push(prRequest.approvalRequestNumber);
            vm.approvalIdList = vm.approvalIdList.concat(prRequest.itemApprovalRequestIdList);
          } else {
            prRequest.selected = false;
          }
        });
        if (vm.approvalNumberList.length > 0){
          if(vm.status == 'APPROVE')
            vm.purchaseApprovalAllRequests();
          if(vm.status == 'REJECT')
            vm.showRejectModal();
        }
      }
    }

    // approve or reject all selected purchase approval requests
    function purchaseApprovalAllRequests(){
      var params = {
        approvalRequestNumber :vm.approvalNumberList,
        status :vm.status,
        remark : vm.prRemark ? vm.prRemark : '',
      }
      var orderAllTime = [];
      var deliveryConditionData = [];
      var deliveryCondition = [];
      var orderTimeForToday;
      vm.validTomorrowDeliveryDateCheckArray = [];
      BuyerPurchaseApprovalRequestService.getDeliveryConditionsForPRApproval(vm.approvalNumberList).then(function(response) {
        var canProceedToApproveOrReject = true;
        if (response.data.length > 0) {
          var now         = new Date();
          var hours       = now.getHours();
          var minutes     = now.getMinutes();
          var timetoCheck = ('0000' + (hours * 100 + minutes)).slice(-4);
          deliveryCondition = response.data;
          deliveryCondition.map(function (ordertime) {
            if ((ordertime.buyerDeliveryConditions != null) && (ordertime.buyerDeliveryConditions.orderTimeForToday != null)) {
              deliveryConditionData.push({
                buyerDeliveryConditions:ordertime.buyerDeliveryConditions,
                itemApprovalRequestNumber:ordertime.itemApprovalRequestNumber
              });
            } else if ((ordertime.supplierDeliveryCondition != null) && (ordertime.supplierDeliveryCondition.orderTimeForToday != null)) {
              deliveryConditionData.push({
                supplierDeliveryCondition:ordertime.supplierDeliveryCondition,
                itemApprovalRequestNumber:ordertime.itemApprovalRequestNumber
              });
            }
          });

          deliveryConditionData.map(function (deliveryConditionTime) {
            orderTimeForToday = deliveryConditionTime.buyerDeliveryConditions != null ? deliveryConditionTime.buyerDeliveryConditions.orderTimeForToday : deliveryConditionTime.supplierDeliveryCondition.orderTimeForToday;
            // Delivery Date Validation
            if(orderTimeForToday!=null){
              _.map(vm.selectedPR, function(itemRequest){
                _.map(itemRequest.deliveryDateList, function(itemRequestDeliveryDate){
                    var localDeliveryDate = moment(itemRequestDeliveryDate).local(true).format("YYYY-MM-DD HH:mm:ss");
                    var localTodaysDate = moment(vm.todaysDate).local(true).format("YYYY-MM-DD HH:mm:ss");
                    if(moment(localDeliveryDate,"YYYY-MM-DD").diff(moment(localTodaysDate,"YYYY-MM-DD"),'days') <= 1){
                        vm.validTomorrowDeliveryDateCheckArray.push(itemRequestDeliveryDate);
                    }
                });
             });
            }
            // Delivery Date Validation
            if(vm.validTomorrowDeliveryDateCheckArray.length > 0){
            if (timetoCheck > orderTimeForToday) {
              canProceedToApproveOrReject = false;
              NotificationService.error({
                title: 'Selected items with order time after '+orderTimeForToday+' cannot be approved',
                error: {
                  data:{
                    approvalerror:"cannot approve"
                  }
                }
              });
            }
          }
          });
        }
        if (canProceedToApproveOrReject) {
          BuyerPurchaseApprovalRequestService.validateApprovalRequest({ 'itemApprovalRequestNos': vm.approvalNumberList }).then(function (response) {
            var resData = _.uniq(Object.values(response.data));
            if (resData.length == 1 && resData[0] == null) {
              approveOrReject(params);
            }
            else if (resData.length >= 1 && !_.includes(resData, null)) {
              NotificationService.simpleErrorToast({
                title: 'global.purchaseApprovalRequest',
                message: 'alertMessage.SELECTED_APPROVAL_REQUESTS_APPROVED_ALREADY'
              });
              vm.cancel();
              return false;
            }
            else {
              var filteredArray = [];
              _.forEach(response.data, function (each, index) {
                if (each == null) {
                  filteredArray.push(_.filter(vm.selectedPR, { 'approvalRequestNumber': index }))
                }
                else{
                    vm.prnoPresentArray.push(index);
                }
              });
              vm.approvalNumberList = [];
              vm.approvalIdList = [];
              _.forEach(filteredArray, function (prRequest) {
                if (prRequest[0].approver) {
                  vm.approvalNumberList.push(prRequest[0].approvalRequestNumber);
                  vm.approvalIdList = vm.approvalIdList.concat(prRequest[0].itemApprovalRequestIdList);
                } else {
                  prRequest[0].selected = false;
                }
              });
              params.approvalRequestNumber = vm.approvalNumberList;
              approveOrReject(params);
            }
          },
          function(error){
            NotificationService.error({
              title: 'global.purchaseApprovalRequest',
              error: error
            });
          });
        }
      }, function(error) {
        NotificationService.error({
          title: 'global.purchaseApprovalRequest',
          error: error
        });
      });
    }

    function approveOrReject(params){
      BuyerPurchaseApprovalRequestService.approveOrRejectAllPrRequest(params, vm.approvalIdList).then(function(response) {
        NotificationService.success({
          title: 'global.purchaseApprovalRequest',
          message: vm.status +' SUCCESSFULLY'
        });
        if( vm.prnoPresentArray.length > 0){
            vm.prnoPresentArrayElements = vm.prnoPresentArray.join(", ");
            toastr.error('PR already created for - '+ vm.prnoPresentArrayElements,$filter('translate')('global.purchaseApprovalRequest'));
        }
        vm.cancel();
        vm.loadAll();
      }, function(error) {
        if (error.status == 524) {
          NotificationService.error({
            title: 'global.purchaseApprovalRequest',
            message: 'Processing will take time'
          });
        } else {
          NotificationService.error({
            title: 'global.purchaseApprovalRequest',
            error: error
          });
        }
      });
    }

   //getAllBuyerBusinessOutlets Gets all business outlets of buyer
   function getAllBuyerBusinessOutlets() {
     if(vm.businessOutlets)
          return false;
     var params ={
        page: 0,
        size: paginationConstants.maxLimit
     };
    var buyer = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      BuyerPurchaseApprovalRequestService.getAllBuyerBusinessOutlets(buyer,params).
    then(function(response){
      vm.businessOutlets = response.data;
    },function(error){
      NotificationService.error({
        title: 'global.purchaseApprovalRequest',
        error: error
      });
    });
    }

    // get all suppliers mapped to the buyer
    function getAllBuyerMappedSuppliers(){
      if(vm.suppliers)
          return false;
      var params ={
        page: 0,
        size: paginationConstants.maxLimit
      };
      var buyer = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      BuyerSupplierMappingService.getAllBuyerMappedSuppliers(buyer,params).then(function(response){
        vm.suppliers = response.data;
      },function(error){
        NotificationService.error({
          title: 'global.purchaseApprovalRequest',
          error: error
        });
      });
    }

  //Show Modal for entering the reason for Reject PR
  function showRejectModal() {
    $mdDialog.show({
      templateUrl: 'app/buyer-modules/purchase-approval-request/buyer.purchase-approval-request-reject-tmpl.html',
      controller: function() {
        return vm;
      },
      controllerAs: 'vm',
      targetEvent: event,
      clickOutsideToClose: true
    });
  }

    function cancel() {
      $mdDialog.cancel();
    }

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
    * [selectAllPR select all Purchase Requests]
    * @return {[type]} [description]
    */
   function selectAllPR() {
    vm.selectAll ? vm.selectAll = true : vm.selectAll = false;
    angular.forEach(vm.purchaseApprovalRequests, function(eachRequest) {
      if(eachRequest.approver) {
        eachRequest.selected = vm.selectAll;
      }
    });
  }

   /**
     * checkForSameBusinessOutlets filters out the selected Outlet id's  and checks whether any odd id exists
     */
    function checkForSameBusinessOutlets(){
      vm.uniqueSupplier = true;
      var approvalRequestNumberList = [];
      var purchaseRequestOutletIdList=[];
      var purchaseRequestSupplierIdList=[];
      _.filter(vm.purchaseApprovalRequests,function(eachRequest){
        if(eachRequest.selected==true){
          purchaseRequestOutletIdList.push(eachRequest.buyerBusinesses[0].id);
          purchaseRequestSupplierIdList.push(eachRequest.suppliers);
          approvalRequestNumberList.push(eachRequest.approvalRequestNumber);
        }
      });
      var duplicateOutletId = _.uniq(purchaseRequestOutletIdList, 'buyerBusinesses[0].id');
      _.filter(purchaseRequestSupplierIdList, function(suppliers){
        var isEqual = _.isEqual(_.sortBy(purchaseRequestSupplierIdList[0]), _.sortBy(suppliers));
        if(!isEqual){
          vm.uniqueSupplier = false;
        }
      });
      vm.approvalRequestNumberList=approvalRequestNumberList;
      vm.purchaseRequestOutletIdList=duplicateOutletId;
      return vm.purchaseRequestOutletIdList;
    }

    /**
     * mergePurchaseRequest merges Purchase Request with same Business Outlet id
     */
    function mergePurchaseRequest(){
      var purchaseRequestList = vm.checkForSameBusinessOutlets();
      if(vm.approvalRequestNumberList.length > 1 && vm.approvalRequestNumberList.length != 0){
        // Checkes the selected BO and the supplier for the merging approval requests are same.
        if(purchaseRequestList.length == 1 && vm.uniqueSupplier){
          BuyerPurchaseApprovalRequestService.mergePurchaseApprovalRequest({
            approvalNumberList:vm.approvalRequestNumberList = _.join(vm.approvalRequestNumberList, ',')
          }).then(function(response) {
           vm.loadAll();
            NotificationService.success({
              title: 'global.purchaseApprovalRequest',
              message: 'alertMessage.MERGED_SUCCESSFULLY'
          });
        },
        function(error) {
          NotificationService.error({
              title: 'global.purchaseApprovalRequest',
              error: error
            });
          });
        }
        else{
          NotificationService.simpleErrorToast({
            title: 'global.purchaseApprovalRequest',
            message: 'alertMessage.BUSINESS_OUTLET_OR_SUPPLIER_SHOULD_BE_SAME_FOR_ALL'
          });
        }
      }
      else{
        NotificationService.simpleErrorToast({
          title: 'global.purchaseApprovalRequest',
          message: 'alertMessage.ATLEAST_TWO_REQUIRED'
        });
       }
    }

    //Get all item approval requests of the logged in buyer
    function loadAll() {
      vm.approvalPendingStatus = (localStorage.getItem('approvalPendingStatus')== 'true') ? true : false;
      BuyerPurchaseApprovalRequestService.getAllPurchaseApprovalRequest({
        businessOutletIdList: vm.filter.buyerBusinessIdList ? vm.filter.buyerBusinessIdList : '',
        supplierIdList: vm.filter.supplierIdList ? vm.filter.supplierIdList : '',
        needsMyApproval : (vm.approvalStatus=='pending') ? (localStorage.getItem('approvalPendingStatus') ? localStorage.getItem('approvalPendingStatus') : false) : false,
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        approvalStatus: GLOBAL_CONSTANTS.approvalStatus[vm.approvalStatus]
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.purchaseApprovalRequests = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'Purchase Approval Requests Listing',
          error: error
        });
      });
    }



    /**
     * [selectAll Selects all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets, suppliers]
     */
    function selectAll(dropdown) {
      switch (dropdown) {
      case 'OUTLETS':
        vm.filter.buyerBusinessIdList = _.map(vm.businessOutlets, _.property('id'));
        break;
      case 'SUPPLIERS':
        vm.filter.supplierIdList = _.map(vm.suppliers, _.property('id'));
        break;
      }
    }

    /**
     * [deselectAll Deselect all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets, suppliers]
     */
    function deselectAll(dropdown) {
      switch (dropdown) {
      case 'OUTLETS':
        vm.filter.buyerBusinessIdList = [];
        break;
      case 'SUPPLIERS':
        vm.filter.supplierIdList = [];
        break;
      }
    }

    //Delete Item Approval Request details by approval Request Number
    function deletePurchaseApprovalRequest(approvalRequestNumber) {
      BuyerPurchaseApprovalRequestService.deletePurchaseApprovalRequest(approvalRequestNumber).then(function(response) {
        NotificationService.success({
          title: 'Purchase Approval Request',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'Purchase Approval Request Deletion',
          error: error
        });
      });
    }


    vm.showConfirm = function(ev, approvalRequestNumber) {
      var confirm = $mdDialog.confirm()
      .title('Are you sure you want to dismiss Request - ' + approvalRequestNumber + ' ? ')
      .textContent('This action could not be reverted back.')
      .targetEvent(ev)
      .ok('Delete')
      .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        deletePurchaseApprovalRequest(approvalRequestNumber);
      }, function() {});
    };

    vm.cancel = function() {
      $mdDialog.cancel();
    };

    function handleApprovalPending(event){
        vm.purchaseApprovalRequests = [];
        localStorage.setItem("approvalPendingStatus",vm.approvalPendingStatus);
        vm.loadAll();
    }
  }
})();
