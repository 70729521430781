/**
* @Author: Ajay
* @Date:   03-Mar-2017 15:12 +05:30
* @Project: Ezyprocure
* @Filename: buyer.waiting-supplier-reply.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 22-Jan-2018 17:01 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerWaitingSupplierReplyController', BuyerWaitingSupplierReplyController);

  BuyerWaitingSupplierReplyController.$inject = ['$scope', '$rootScope', '$timeout', '$state', 'toastr', '$mdDialog', '$filter', 'BuyerWaitingSupplierReplyService', 'BuyerBusinessService', 'pagingParams', 'paginationConstants', 'GLOBAL_CONSTANTS'];

  function BuyerWaitingSupplierReplyController($scope, $rootScope, $timeout, $state, toastr, $mdDialog, $filter, BuyerWaitingSupplierReplyService, BuyerBusinessService, pagingParams, paginationConstants, GLOBAL_CONSTANTS) {

    var vm = this;
    vm.loadAll = loadAll;
    vm.searchText = '';
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.query1 = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.getPendingList = getPendingList;
    vm.getRequirementPendingList = getRequirementPendingList;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;

    /**
     * [sort to sort by PO date]
     */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    //Get all items of the selected Supplier
    function loadAll() {
      vm.getPendingList();
      vm.getRequirementPendingList();
    }

    function getPendingList(search){
      if (!search) {
        vm.searchText = '';
      }

      BuyerWaitingSupplierReplyService.getWaitingSupplierReply({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.searchText
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.pendingRequirements = response.data;
      }, function(error) {
      });
    }

    function getRequirementPendingList(search){
      if (!search) {
        vm.searchText = '';
      }

      BuyerWaitingSupplierReplyService.getSupplierReplyReq_PO({
        page: vm.query1.page - 1,
        size: vm.query1.limit,
        sort: sort(),
        query: vm.searchText
      }).then(function(response) {
        vm.totalRequirementItems = response.headers('X-Total-Count');
        vm.pendingRequirements1 = response.data;
      }, function(error) {
      });
    }


  }
})();
