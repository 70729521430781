/*
 * @Author: Lovin C 
 * @Date: 2021-09-28 13:45:40 
 * @Last Modified by: Lovin C
 * @Last Modified time: 2021-09-28 13:47:46
 */


(function () {
    'use strict';

    angular
        .module('ezyprocureApp')
        .filter('search', search);

    function search() {
        return search;

        function search(list, query, fields) {
            if (!query) {
                return list;
            }
            query = query.toLowerCase().split(' ');
            if (!angular.isArray(fields)) {
                fields = [fields.toString()];
            }
            return list.filter(function (item) {
                return query.every(function (needle) {
                    return fields.some(function (field) {
                        var content = item[field] != null ? item[field] : '';
                        if (!angular.isString(content)) {
                            content = '' + content;
                        }
                        return content.toLowerCase().indexOf(needle) > -1;
                    });
                });
            });
        }
    }
})();
