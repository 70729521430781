/**
* @Author: JayalekshmiR
* @Date:   2018-09-14T11:53:12+05:30
* @Filename: ezypayment.buyer.payee-list.controller.js
 * @Last modified by:   JayalekshmiR
 * @Last modified time: 2018-09-17T15:40:41+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('PayeeListController', PayeeListController);

  PayeeListController.$inject = ['$scope', '$stateParams', '$rootScope', '$q', '$filter', '$state','$mdDialog', 'pagingParams','NotificationService', 'paginationConstants','ManageSupplierService', 'BuyerSupplierMappingService', 'BuyerPayeeService'];

  function PayeeListController($scope, $stateParams, $rootScope, $q, $filter, $state,$mdDialog, pagingParams,NotificationService, paginationConstants,ManageSupplierService, BuyerSupplierMappingService, BuyerPayeeService) {

    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = true;
    vm.loadAll = loadAll;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.visibilityType = $stateParams.visibilityType ? $stateParams.visibilityType : 'ALLOCATED';
    vm.showConfirm = showConfirm;
    vm.isCustom = $stateParams.isCustom === 'custom';
    BuyerPayeeService.setIsCustom(vm.isCustom);

    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
    * [loadAll Load All Receiving Order data]
    * @param  {[string]} search [search text]
    */
    function loadAll(search) {
      if (!search) {
        vm.searchText = '';
      }
      BuyerSupplierMappingService.getAllMappedSupplierBuyers({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.searchText,
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.suppliers = response.data;
      },function(error){
      });
    }
    function showConfirm(ev, supplier) {
      var confirm = $mdDialog.confirm()
        .title('Are you sure you want to delete Supplier?')
        .textContent('This action could not be reverted back.')
        .targetEvent(ev)
        .ok('Delete')
        .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        deleteAdminSupplier(supplier);
      }, function() {});
    }

    function deleteAdminSupplier(supplierId) {
      BuyerSupplierMappingService.deleteBSMapping({supplierId: supplierId, buyerId: vm.suppliers[0].buyer.id}).then(function(response) {
        NotificationService.success({
          title: 'Admin Supplier',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'Admin Supplier Deletion',
          error: error
        });
      });
    }
    vm.loadAll();
  }
})();
