/**
 * @Author: Ajay
 * @Date:   28-Feb-2018 11:00 +05:30
 * @Project: Ezyprocure
 * @Filename: supplier.ax-report.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-01-22T11:43:27+05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierAXInvoiceReportController', SupplierAXInvoiceReportController);

  SupplierAXInvoiceReportController.$inject = ['$scope','$q', '$rootScope', '$filter', '$state', '$stateParams', 'pagingParams', 'toastr', '$mdDialog', 'Auth', 'UTCDateService', 'PurchaseOrderService', 'InvoiceService', 'ItemGroupService', 'BuyerOCRService',  'GLOBAL_CONSTANTS', 'paginationConstants', 'CSVExportService', 'EXPORT_CONSTANTS', 'NotificationService', 'GLOBAL_CONFIG_CONSTANTS'];

  function SupplierAXInvoiceReportController($scope, $q,$rootScope, $filter, $state, $stateParams, pagingParams, toastr, $mdDialog, Auth, UTCDateService, PurchaseOrderService, InvoiceService, ItemGroupService, BuyerOCRService, GLOBAL_CONSTANTS, paginationConstants, CSVExportService, EXPORT_CONSTANTS, NotificationService, GLOBAL_CONFIG_CONSTANTS) {
    var vm = this;
    vm.init =  init;
    vm.loadItemGroups=loadItemGroups;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.todaysDate = new Date();
    vm.loadAll = loadAll;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.listViews = GLOBAL_CONSTANTS.LIST_VIEWS;
    vm.invStatusClass = GLOBAL_CONFIG_CONSTANTS.INVOICE_STATUS.LABEL_CLASS;
    vm.accessForPriceDisplay = Auth.hasPermission('accessForPriceDisplay');
    vm.checkAllRows = checkAllRows;
    vm.getSelectedPOs = getSelectedPOs;
    vm.getSelectedPOIds = getSelectedPOIds;
    vm.pickAllPOIdsFromGroupedPOItems = pickAllPOIdsFromGroupedPOItems;
    vm.exportData = exportData;
    vm.exportToAX = exportToAX;
    vm.updateGeoExported = updateGeoExported;
    vm.updateGeoExportedFromList = updateGeoExportedFromList;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;

    //Function Declaration
    vm.getInvoiceReportCSVData = getInvoiceReportCSVData;
    vm.setConsolidatedInvoiceFilters = setConsolidatedInvoiceFilters;
    vm.getConsolidatedInvoice = getConsolidatedInvoice;
    vm.resetFilters = resetFilters;

    function init(){
      if(vm.accessForPriceDisplay)
        vm.InvoiceReportHeader = EXPORT_CONSTANTS.CSV.SupplierInvoiceReport;
      else {
        vm.InvoiceReportHeader = _.without(EXPORT_CONSTANTS.CSV.SupplierInvoiceReport, 'Unit Price' , 'Amount');
      }
    }

    function getConsolidatedInvoice() {
      var deferred = $q.defer();
      InvoiceService.getConsolidatedInvoiceDetails({
        poFromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
        poToDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
        invoiceFromDate: !vm.filter.invoiceFromDate ? '' : UTCDateService.utcFromDate(vm.filter.invoiceFromDate),
        invoiceToDate: !vm.filter.invoiceToDate ? '' : UTCDateService.utcToDate(vm.filter.invoiceToDate),
        deliveryFromDate: !vm.filter.deliveryFromDate ? '' : UTCDateService.utcFromDate(vm.filter.deliveryFromDate),
        deliveryToDate: !vm.filter.deliveryToDate ? '' : UTCDateService.utcToDate(vm.filter.deliveryToDate),
        grnFromDate: !vm.filter.grnFromDate ? '' : UTCDateService.utcFromDate(vm.filter.grnFromDate),
        grnToDate: !vm.filter.grnToDate ? '' : UTCDateService.utcToDate(vm.filter.grnToDate),
        reportType: "INVOICE",
        page: vm.query.page - 1,
        size: paginationConstants.maxLimit,
        poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber,
        purchaseOrderStatus: '',
        invoiceStatus: 'PROCESSED',
        invoiceNumber: !vm.filter.invoiceNumber ? '' : vm.filter.invoiceNumber,
        businessOutletId: !vm.filter.businessIdList ? '' : vm.filter.businessIdList,
        businessOutletName: !vm.filter.businessOutletName ? '' : vm.filter.businessOutletName,
        itemGroupId: !vm.filter.itemGroupId ? '' : vm.filter.itemGroupId,
        buyerId: vm.filter.buyerIdList ? vm.filter.buyerIdList : '',
        hardCopyInvoiceNumber: vm.filter.hardcopyInvoiceNumber ? vm.filter.hardcopyInvoiceNumber : '',
        xeroExported: vm.filter.xeroExported ? vm.filter.xeroExported : '',
      }).then(function (response) {
        deferred.resolve(response.data);
      }, function (error) {
        NotificationService.error({
          title: 'Invoice Report Details',
          error: error
        });
      }
      );
      return deferred.promise;
    }

    //Getting CSV DATA
    function getInvoiceReportCSVData() {
      vm.invoiceData = [];
      var deferred = $q.defer();
      vm.getConsolidatedInvoice().then(function (response) {
        if (_.isEmpty(response)) {
          toastr.error('No records found', 'Invoice Report Details!');
          return deferred.promise;
        } else {
          vm.invoiceData = CSVExportService.getSupplierInvoiceReportCSV(response);
          deferred.resolve(vm.invoiceData);
        }
      }, function (error) {
        NotificationService.error({
          title: 'Invoice Report Details',
          error: error
        });
        deferred.reject(error);
      });
      return deferred.promise;
    }

    /**
     * [sort to sort by PO date]
     */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function loadItemGroups() {
      if(vm.itemGroups)
        return false;
      ItemGroupService.getAllItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.itemGroups = response.data;
      }, function(error) {
      });
    }
    /**
     * [loadAll Get all Purchase Order Details]
     */
    function loadAll() {
      if(vm.filter.listView === "Individual"){
        getIndividualPODetails();
      }
    }

    /**
     * [getIndividualPODetails  gets PO details for each PO Number]
     */
    function getIndividualPODetails() {
      PurchaseOrderService.getAllPurchaseOrders({
        poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber,
        purchaseOrderStatus: '',
        invoiceStatus: 'PROCESSED',
        invoiceNumber: !vm.filter.invoiceNumber ? '' : vm.filter.invoiceNumber,
        businessOutletId: '',
        businessOutletName: !vm.filter.businessOutletName ? '' : vm.filter.businessOutletName,
        //If dates are selected, they are converted to UTC format
        poFromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
        poToDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
        deliveryFromDate: '',
        deliveryToDate: '',
        grnFromDate : '',
        grnToDate : '',
        query: '',
        supplierId: '',
        itemGroupId: !vm.filter.itemGroupId ? '' : vm.filter.itemGroupId,
        buyerIdList: '',
        debitNoteStatus: '',
        grnNumber: '',
        buyerName: '',
        creditNoteStatus: '',
        supplierVisibilityType: '',
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        xeroExported: '',
        module : 0
      }).then(function(response){
        vm.poList = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      },function(error){
        NotificationService.error({
          title: 'Purchase Order Details',
          error: error
        });
      });
    }

    function checkAllRows() {
      angular.forEach(vm.poList, function(item) {
        item.purchaseOrder.selected = vm.selectAllRows;
      });
    }

    function pickAllPOIdsFromGroupedPOItems(selectedPOs) {
      var poItems = _.flatten(_.without(_.map(selectedPOs, _.property('purchaseOrder.items')), undefined, null));
      var purchaseOrderIdList = _.map(poItems, _.property('purchaseOrder.purchaseOrderId'));
      return purchaseOrderIdList;
    }

    /**
    * [updateGeoExported Mark GeoExported flag for the list of purchase orders in DB]
    * @param  {[type]} selectedPOs [description]
    * @return {[type]}             [description]
    */
    function updateGeoExported(selectedPOs) {
      var purchaseOrderIdList = vm.pickAllPOIdsFromGroupedPOItems(selectedPOs);
      PurchaseOrderService.updateGeoExported(purchaseOrderIdList).then(function(response){
        vm.updateGeoExportedFromList(purchaseOrderIdList);
      },function(error){
        NotificationService.error({
          title: 'Updating Exported Status',
          error: error
        });
      });
    }

    /**
    * [updateGeoExportedFromList Update the status from the PO List]
    * @param  {[type]} purchaseOrderIdList [List of purchase order id]
    * @return {[type]}                     [description]
    */
    function updateGeoExportedFromList(purchaseOrderIdList) {
      _.forEach(vm.poList, function(po, key) {
        if(_.includes(purchaseOrderIdList, po.purchaseOrder.id)){
          vm.poList[key].purchaseOrder.geoExported = true;
        }
      });
    }

    function getSelectedPOs(poList) {
      var selectedPOs = _.filter(poList, function(po) {
        return po.purchaseOrder.selected === true;
      });
      return selectedPOs;
    }

    function getSelectedPOIds(selectedPOs) {
      var purchaseOrderIdList = _.map(selectedPOs, _.property('purchaseOrder.id'));
      return purchaseOrderIdList;
    }

    function exportData(actionType) {
      var deferred = $q.defer();
      var purchaseOrderIdList = [];
      var selectedPOs = vm.getSelectedPOs(vm.poList);
      purchaseOrderIdList = vm.getSelectedPOIds(selectedPOs);
      /**
      * [if selection is made getExportGeoDetails for selected records]
      */
      if(purchaseOrderIdList.length > 0) {
        var ocrGeoExportDTO = {
          purchaseOrderIdList : purchaseOrderIdList
        };
        BuyerOCRService.getExportAXDetails(ocrGeoExportDTO).then(function(response){
          deferred.resolve(response.data);
        },function(error){
          NotificationService.error({
            title: 'Fetching '+actionType+' Export Details',
            error: error
          });
          deferred.reject(error);
        });
        return deferred.promise;
      }
      /**
      * [if no selection is made getAllExportGeoDetails based on search query]
      */
      else if (_.isEmpty(purchaseOrderIdList) && selectedPOs.length === 0) {
        BuyerOCRService.getAllExportAXDetails({
          page: 0,
          size: paginationConstants.maxLimit,
          query: !vm.filter.poNumber?'':vm.filter.poNumber,
          fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
          toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
          ocrInvoiceStatus: 'ACCEPTED',
          ocrAcceptanceStatus: 'ACCEPTED',
          invoiceStatus: '',
          geoExported: !vm.filter.geoExported ? '':vm.filter.geoExported,
          businessOutletIds: (!vm.filter.businessOutletIds || vm.filter.businessOutletIds.length < 1) ? '':vm.filter.businessOutletIds,
          supplierId: (!vm.filter.supplierIds || vm.filter.supplierIds.length < 1) ? '':vm.filter.supplierIds,
          sort: sort(),
        }).then(function(response){
          deferred.resolve(response.data);
        },function(error){
          NotificationService.error({
            title: 'Fetching '+actionType+' Export Details',
            error: error
          });
          deferred.reject(error);
        });
        return deferred.promise;
      }
      else {
        return deferred.promise;
      }
    }

    //Get Export To AX
    function exportToAX() {
      var deferred = $q.defer();
      vm.exportData('AX').then(function(exportDataResponse){
        var purchaseOrderIdList = vm.pickAllPOIdsFromGroupedPOItems(exportDataResponse);
        vm.updateGeoExported(exportDataResponse);
        BuyerOCRService.webservicesupplier(purchaseOrderIdList).then(function(response) {
          NotificationService.success({
            title: 'Web service',
            message: 'alertMessage.UPDATED_SUCCESSFULLY'
          });
        },function(error) {
          NotificationService.error({
            title: 'Could not connect Web service',
            error: error
          });
        });
      },function(error){
        NotificationService.error({
          title: 'Export AX',
          error: error
        });
        deferred.reject(error);
      });
      return deferred.promise;
    }

    function setConsolidatedInvoiceFilters() {
      InvoiceService.setConsolidatedInvoiceFilters(vm.filter);
      $state.go('supplier.reports.invoice-report.consolidated-details', {
        reload: false
        });
    }
    function resetFilters() {
        vm.filter = {};
        vm.filter.fromDate = vm.todaysDate;
        vm.filter.toDate = vm.todaysDate;
        vm.filter.listView = vm.listViews[0];
        vm.poList = null;
    }
  }
})();
