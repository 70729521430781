/*
 * @Author: Lovin C 
 * @Date: 2021-07-30 17:18:02 
 * @Last Modified by: Lovin C
 * @Last Modified time: 2021-08-30 13:20:39
 */


(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('EzypaymentAddEddaAccountMappingController', EzypaymentAddEddaAccountMappingController);

  EzypaymentAddEddaAccountMappingController.$inject = ['$stateParams', '$state', 'NotificationService', 'BuyerDataService', 'BrandManagementService', 'EddaAccountMappingService', 'paginationConstants', 'BuyerSupplierMappingService'];

  function EzypaymentAddEddaAccountMappingController($stateParams, $state, NotificationService, BuyerDataService, BrandManagementService, EddaAccountMappingService, paginationConstants, BuyerSupplierMappingService) {

    var vm = this;
    vm.init = init;
    vm.userRole = $stateParams.userRole;
    vm.addNewDDA = addNewDDA;
    vm.loadAllBuyerMappedSuppliers = loadAllBuyerMappedSuppliers;
    vm.cancel = cancel;
    vm.filter = {
      supplier: [],
      brand: []
    };
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.getEmail = getEmail;

    //init
    function init() {
      getBrands();
    }

    //get default email
    function getEmail(supplier) {
      vm.userRole == 'buyer' ? getBuyerEmail() : getSupplierEmail(supplier);
    }

    //get Supplier email
    function getSupplierEmail(supplier) {
      EddaAccountMappingService.getSupplierDetailsById(supplier.id).then(function (response) {
        vm.filter.email = response.data.user.email;
      },
        function (error) {
          NotificationService.error({
            title: 'Supplier Email',
            error: error
          });
        })
    }

    //get buyer email
    function getBuyerEmail() {
      vm.filter.email = BuyerDataService.getBuyerProfile().buyerUser.user.email;
    }

    //get all buyer mapped suppliers
    function loadAllBuyerMappedSuppliers() {
      var params = {
        page: 0,
        size: paginationConstants.maxLimit
      };
      var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      BuyerSupplierMappingService.getAllBuyerMappedSuppliers(buyerId, params).then(function (response) {
        vm.suppliers = response.data;
      }, function (error) {
        NotificationService.error({
          title: 'Buyer Mapped Suppliers',
          error: error
        });
      });
    }

    //get all brands mapped with buyers
    function getBrands() {
      var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      BrandManagementService.getAllBrandsByBuyerId({
        page: 0,
        size: paginationConstants.maxLimit,
        query: '',
        buyerId: buyerId
      }).then(function (response) {
        vm.brands = response.data;
      },
        function (error) {
          NotificationService.error({
            title: 'Brands',
            error: error
          });
        });
    }

    // create new DDA Mapping with brands
    function addNewDDA() {
      var mandateInviteRequest = [];
      _.forEach(vm.filter.brand, function (each) {
        mandateInviteRequest.push({
          brand: each,
          email: vm.filter.email,
          supplier: vm.filter.supplier.length != 0 ? vm.filter.supplier : null
        });
      })
      EddaAccountMappingService.createEddaAccountMapping(mandateInviteRequest).then(function (response) {
        NotificationService.success({
          title: response.data.status,
          message: response.data.message
        });
        $state.go('ezypayment.buyer.edda-account-mapping');
      },
        function (error) {
          NotificationService.error({
            title: 'DDA Account Mapping',
            error: error
          });
        });
    }

    //cancel
    function cancel() {
      $state.go('ezypayment.buyer.edda-account-mapping');
    }

    //select all
    function selectAll(dropdown) {
      switch (dropdown) {
        case 'SUPPLIERS':
          vm.filter.supplier = _.map(vm.suppliers, function (each) { return each });
          break;
        case 'BRANDS':
          vm.filter.brand = _.map(vm.brands, function (each) { return each });
          break;
      }
    }

    //deselect all
    function deselectAll(dropdown) {
      switch (dropdown) {
        case 'SUPPLIERS':
          vm.filter.supplier = [];
          break;
        case 'BRANDS':
          vm.filter.brand = [];
          break;
      }
    }

  }
})();
