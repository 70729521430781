/**
 * @Author: elsa
 * @Date:   19-Apr-2017 18:35 +05:30
 * @Project: Ezyprocure
 * @Filename: supplier.requirement-pending-po.details.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 18-Dec-2017 10:52 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierRequirementPendingPODetailsController', SupplierRequirementPendingPODetailsController);

  SupplierRequirementPendingPODetailsController.$inject = ['$scope', '$state', 'NotificationService', '$filter', '$stateParams', 'BuyerUnableMeetDemandsService', 'SupplierRequirementPendingPOService', 'paginationConstants', 'GLOBAL_CONSTANTS'];

  function SupplierRequirementPendingPODetailsController ($scope, $state, NotificationService, $filter, $stateParams, BuyerUnableMeetDemandsService, SupplierRequirementPendingPOService, paginationConstants, GLOBAL_CONSTANTS) {

    var vm= this;
    vm.init = init;
    vm.requirementNumber = $stateParams.requirementNumber;
    vm.loadAll = loadAll;
    vm.todaysDate = new Date();
    vm.processPendingPO = processPendingPO;
    vm.checkAlreadyProcessed = checkAlreadyProcessed;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;

    function init() {
      loadAll();
    }

    /**
     * [loadAll loads all pending POs]
     */
    function loadAll() {
      BuyerUnableMeetDemandsService.getProcessOrderByRequirementNumber(vm.requirementNumber).then(function(response) {
        vm.checkAlreadyProcessed(response.data);
        vm.processOrderDetails = response.data;
      }, function(error) {
      });
    }

    // Redirect back to Pending Orders Listing page if order already processed*
    function checkAlreadyProcessed(processOrderDetails) {
      var generated = false;
      _.forEach(processOrderDetails.items, function(item){
        _.forEach(item.supplierResponses, function(supplierResponse){
          if (supplierResponse.processOrderStatus === 'SUPPLIER_APPROVED') {
            generated = true;
          }
        });
      });
      if (generated === true) {
        $state.go('supplier.requirement-pending-po', {}, { reload: false});
      }
    }

    /**
     * [processPendingPO Process the Pending PO]
     */
    function processPendingPO() {
      SupplierRequirementPendingPOService.processPendingPO(vm.requirementNumber).then(function(response) {
        vm.processOrderCreatedDetails=response.data;
        NotificationService.success({
          title: 'Requirement Pending PO',
          message: 'alertMessage.PROCESSED_SUCCESSFULLY'
        });
        if(vm.processOrderCreatedDetails == null||vm.processOrderCreatedDetails==""){
                $state.go('supplier.requirement-pending-po', {reload: false});
                }else{
                $state.go('supplier.reports.purchase-order.individual-details',{'poNumber':vm.processOrderCreatedDetails[0].purchaseOrderNumber}, {
                        reload: false
                        });
                }
      }, function(error) {
        NotificationService.error({
          title: 'Requirement Pending PO Process',
          error: error
        });
      });
    }
  }
})();
