/**
 * @Author: Ajay
 * @Date:   24-Oct-2017 12:55 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.adhoc-po-form.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 16-Jan-2018 10:09 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider

    .state('buyer.adhoc-po-form', {
      parent: 'buyer',
      url: '/adhoc-po-form',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'Adhoc PO Form'
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/adhoc-po-form/buyer.adhoc-po-form.html',
          controller: 'BuyerAdhocPOFormController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('itemBuyerMapping');
          $translatePartialLoader.addPart('items');
          return $translate.refresh();
        }],
        hasModuleAccess: ['Auth','$q', '$state', function(Auth, $q, $state) {
          if(Auth.hasPermission('accessForAdhocPoForm')){
            return true;
          }else {
            return $q.reject('Unauthorized');
          }
        }]
      }
    })
    .state('buyer.adhoc-po-form.item-selection', {
      parent: 'buyer.adhoc-po-form',
      url: '/item-selection',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'Adhoc PO Form'
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/adhoc-po-form/item-selection/buyer.adhoc-po-form.item-selection.html',
          controller: 'BuyerAdhocPOFormItemSelectionController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('invoice');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('itemBuyerMapping');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('processOrder');
          $translatePartialLoader.addPart('glCodeMappings');
          $translatePartialLoader.addPart('promotion');
          return $translate.refresh();
        }]
      }
    })
    .state('buyer.adhoc-po-form.bulk-upload', {
      parent: 'buyer.adhoc-po-form',
      url: '/bulk-upload/tabIndex=:tabIndex',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'Adhoc PO form Item Bulk Upload'
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/adhoc-po-form/bulk-upload/buyer.adhoc-po-form.bulk-upload-csv.html',
          controller: 'BuyerPOItemBulkUploadController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('items');
          return $translate.refresh();
        }]
      }
    });
  }
})();
