/**
* @Author: Ajay
* @Date:   22-May-2017 10:43 +05:30
* @Project: Ezyprocure
* @Filename: buyer.manage-supplier-identification-code.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 07-Nov-2017 15:35 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerSupplierIdentificationCodeController', BuyerSupplierIdentificationCodeController);

  BuyerSupplierIdentificationCodeController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'pagingParams', 'BuyerSupplierMappingService', 'paginationConstants', 'NotificationService'];

  function BuyerSupplierIdentificationCodeController($scope, $rootScope, $state, $stateParams, pagingParams, BuyerSupplierMappingService, paginationConstants, NotificationService) {
    var vm = this;
    
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.getAllBuyerSupplierMappings = getAllBuyerSupplierMappings;
    vm.updateSupplierIdentificationCode = updateSupplierIdentificationCode;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    /**
    * [sort description]
    * @return {[type]} [description]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    vm.getAllBuyerSupplierMappings();


    /**
    * [getAllBuyerSupplierMappings Get list of buyer supplier mappings]
    */
    function getAllBuyerSupplierMappings(searchText) {
      if(!searchText){
       vm.searchText = "";
      }
     BuyerSupplierMappingService.getAllBuyerSupplierMappings({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.searchText
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.buyerSupplierMappings = response.data;
      }, function(error) {
      });
    }

    function updateSupplierIdentificationCode(buyerSupplierMapping) {
      BuyerSupplierMappingService.updateSupplierIdentificationCode(buyerSupplierMapping).then(function(response) {
        NotificationService.success({
          title: 'Supplier Identification Code',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        vm.getAllBuyerSupplierMappings();
      }, function(error) {
        vm.getAllBuyerSupplierMappings();
        NotificationService.error({
          title: 'Supplier Identification Code Updation',
          error: error
        });
      });
    }

  }
})();
