/*
 * @Author: Jayalekshmi 
 * @Date: 2019-09-02 12:55:53 
 * @Project: Ezyprocure
 * @Filename: ezyap.manage-delivery-details.add.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-10-24 10:35:41
 */

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('EzyapAddDeliveryDetailsController', EzyapAddDeliveryDetailsController);

  EzyapAddDeliveryDetailsController.$inject = ['NotificationService', 'CsvUploadService', 'UtilService', 'ManageDeliveryDetailsService', '$state', '$filter', 'ManageBuyerInfoService', 'EZYAP_CONSTANTS', 'Auth'];

  function EzyapAddDeliveryDetailsController(NotificationService, CsvUploadService, UtilService, ManageDeliveryDetailsService, $state, $filter, ManageBuyerInfoService, EZYAP_CONSTANTS, Auth) {

    var vm = this;
    vm.addDeliveryDetails = addDeliveryDetails;
    vm.getBuyerInformation = getBuyerInformation;
    vm.countryCodes = EZYAP_CONSTANTS.ezyap.countryCodes;
    vm.userRole = Auth.getUserRole();

    /**
     * [add buyer information]
     */
    function addDeliveryDetails() {
      ManageDeliveryDetailsService.addDeliveryDetails({
        address: {
          additionalStreetName: vm.deliveryDetails.addressAdditionalStreetName ? vm.deliveryDetails.addressAdditionalStreetName : '',
          cityName: vm.deliveryDetails.addressCityName ? vm.deliveryDetails.addressCityName : '',
          companyId: vm.deliveryDetails.addressCompanyId ? vm.deliveryDetails.addressCompanyId : '',
          contactEmail: vm.deliveryDetails.addressContactEmail ? vm.deliveryDetails.addressContactEmail : '',
          contactTelephone: vm.deliveryDetails.addressContactTelephone ? vm.deliveryDetails.addressContactTelephone : '',
          contactName: vm.deliveryDetails.addressContactName ? vm.deliveryDetails.addressContactName : '',
          countryCode: vm.deliveryDetails.addressCountryCode,
          organisationName: vm.deliveryDetails.addressOrganisationName,
          postalZone: vm.deliveryDetails.addressPostalZone ? vm.deliveryDetails.addressPostalZone : '',
          streetName: vm.deliveryDetails.addressStreetName ? vm.deliveryDetails.addressStreetName : ''
        }, buyerInformation: {
          id: vm.buyerInfoId
        }
      }).
        then(function (responseData) {
          $state.go('ezyap.' + vm.userRole.pretty + '.manage-delivery-details');
          NotificationService.success({
            title: 'global.deliveryDetails',
            message: 'alertMessage.ADDED_SUCCESSFULLY'
          });
        }, function (error) {
          NotificationService.error({
            title: 'global.deliveryDetails',
            error: error
          });
        });
    }

    /**
    * get all buyer information
    */
    function getBuyerInformation() {
      if (vm.buyerDetails) {
        return false;
      }
      ManageBuyerInfoService.getBuyerInformation({ query: "" }).
        then(function (response) {
          vm.buyerDetails = response.data;
        }, function (error) {
          NotificationService.error({
            title: '',
            error: error
          });
        });
    }
  }
})();
