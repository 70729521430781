/**
 * @Author: Ajay
 * @Date:   28-Jul-2017 15:53 +05:30
 * @Filename: mobileuser.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 21-Nov-2017 16:21 +05:30
 */



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('MobileUserService', MobileUserService);

  MobileUserService.$inject = ['$http', '$q', 'API_CONSTANTS'];
  function MobileUserService($http, $q, API_CONSTANTS) {

    var mobileUserService = {
      getDeviceToken: getDeviceToken,
      setDeviceToken: setDeviceToken,
      updateNotification: updateNotification
    };

    return mobileUserService;

    /* --  Mobile Device operations  -- */

    /**
     * [getDeviceToken getDeviceToken from localStorage]
     * @return {[type]} [description]
     */
    function getDeviceToken() {
      var deviceToken = localStorage.getItem("pushId") ? localStorage.getItem("pushId") : null;
      return deviceToken;
    }

    /**
     * [setDeviceToken setDeviceToken to localStorage]
     * @param {[type]} deviceToken [description]
     */
    function setDeviceToken(deviceToken) {
      if(deviceToken)
        localStorage.setItem("pushId",deviceToken);
    }

    //Update  Notification
    function updateNotification(deviceToken) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'notifications/update', deviceToken).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
