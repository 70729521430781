/**
 * @Author: Ajay
 * @Date:   20-Mar-2017 12:11 +05:30
 * @Project: Ezyprocure
 * @Filename: price-update-history.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 14-Aug-2017 13:22 +05:30
 */



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('PriceUpdateHistoryService', PriceUpdateHistoryService);

  PriceUpdateHistoryService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  function PriceUpdateHistoryService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var priceUpdateHistoryService = {
      getAllPriceUpdatesHistory : getAllPriceUpdatesHistory
    };
    return priceUpdateHistoryService;

    function getAllPriceUpdatesHistory(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PRICE_UPDATES_RESOURCE + 'history',
        method: "GET",
        params:params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
