/**
* @Author: Allan
* @Date:   19-Feb-2019 17:54 +05:30
* @Project: Ezyprocure
* @Filename: buyer.buy-now-mobile.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-05-20T17:26:13+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyNowMobileFilterController', BuyNowMobileFilterController);

  BuyNowMobileFilterController.$inject = ['$rootScope', '$filter', '$state', 'pagingParams', 'Auth', '$mdDialog', 'BuyerDataService', 'BuyerBusinessUserMapService', 'BuyerBuyNowService', 'PurchaseOrderTypeService', 'paginationConstants', 'NotificationService', 'PromotionService', 'BuyerOrderTemplateService', 'CartService'];

  function BuyNowMobileFilterController($rootScope, $filter, $state, pagingParams, Auth, $mdDialog, BuyerDataService, BuyerBusinessUserMapService, BuyerBuyNowService, PurchaseOrderTypeService, paginationConstants, NotificationService, PromotionService, BuyerOrderTemplateService, CartService) {
    var vm = this;
    vm.init = init;
    vm.todaysDate = new Date();
    vm.buyerProfile = BuyerDataService.getBuyerProfile();
    vm.haveAccessForGLModule = Auth.hasPermission('accessForGlModule');

    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.getSuppliersByBusinessOutlet = getSuppliersByBusinessOutlet;
    vm.loadPurchaseOrderTypes = loadPurchaseOrderTypes;
    vm.getBuyerPromotions = getBuyerPromotions;
    vm.setDefaultPurchaseOrderType = setDefaultPurchaseOrderType;
    vm.filterQueryChanged = filterQueryChanged;
    vm.getAllPublicSuppliers = getAllPublicSuppliers;
    vm.supplierVisibilityTypeChanged = supplierVisibilityTypeChanged;

    vm.proceedToBuyNow = proceedToBuyNow;
    vm.hidePromotions = hidePromotions;
    vm.showDeleteCartConfirmationModal = showDeleteCartConfirmationModal;

    vm.changePurchaseMethod = changePurchaseMethod;
    vm.clearCart = clearCart;

    // order template filter methods
    vm.getTemplatesByOutlet = getTemplatesByOutlet;
    vm.getTemplateSuppliers = getTemplateSuppliers;

    vm.predicate = 'items.itemName';
    vm.reverse = pagingParams.ascending;
    vm.query = {
      limit: 20,
      page: 1
    }
    vm.searchText = '';
    vm.filter = {};
    vm.filter.purchaseOrderType = undefined;

    vm.getBusinessOutletName = getBusinessOutletName;
    vm.getSupplierName = getSupplierName;
    vm.isOutletSelected = isOutletSelected;
    vm.validateSupplierList = validateSupplierList;
    vm.validateMOQ = validateMOQ;


    function init(){
      vm.filter.visibilityType = 'allocated'
      vm.filter.purchaseMethod = 'PURCHASE_BY_ITEM';
      vm.getBuyerPromotions();
    }

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }


    //----------------------- FILTER PARAMS METHODS - Common to both template and item purchase method ------------------------------------//

    /**
    * [getAllBuyerBusinessOutlets get logged-in buyer's business outlets]
    * @return {[type]} [description]
    */
    function getAllBuyerBusinessOutlets(){
      if(vm.businessOutlets)
      return false;
      BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser().
      then(function(response){
        vm.businessOutlets = response;
        //if only one business outlet set it as default
        if(vm.businessOutlets.length === 1) {
          vm.filter.businessOulet = vm.businessOutlets[0].id;
          vm.setDefaultPurchaseOrderType();
        }
      },function(error){
      });
    }

    /**
    * [setDefaultPurchaseOrderType - invoked when there is only one business outlet, so as to automatically set purchase order type]
    */
    function setDefaultPurchaseOrderType() {
      var selectedOutlet = _.find(vm.businessOutlets, ['id', vm.filter.businessOutletId]);
      vm.filter.purchaseOrderType = selectedOutlet.defaultPurchaseOrderType ? selectedOutlet.defaultPurchaseOrderType.id : undefined;
    }

    /**
    * [changePurchaseMethod change the filter param values when method is changed and reset validation if any]
    * @return {[type]} [description]
    */
    function changePurchaseMethod() {
      if(vm.filter.purchaseMethod ==='PURCHASE_BY_TEMPLATE'){
        vm.items=undefined;
        vm.supplierVisibilityType = 'allocated';
        vm.getTemplatesByOutlet();
        CartService.getCartDetails().then(function (response) {
          var count = response.cartCount ? response.cartCount : 0;
          if (count > 0) {
            vm.showDeleteCartConfirmationModal();
          }
        }, function (error) {
          NotificationService.error({
            title: 'Buyer Cart',
            error: error
          });
        });
      }
      else{
        vm.supplierList = [];
        vm.init();
      }
    }


    /**
    * [showDeleteCartConfirmationModal delete cart confirmation modal]
    * @param  {[type]} ev           [description]
    * @param  {[type]} supplierItem [description]
    * @return {[type]}              [description]
    */
    function showDeleteCartConfirmationModal(ev, supplierItem) {
      var confirm = $mdDialog.confirm()
      .title($filter('translate')('alertMessage.CONFIRM_CLEAR_CART'))
      .textContent($filter('translate')('alertMessage.COULD_NOT_BE_REVERTED_BACK'))
      .targetEvent(ev)
      .ok($filter('translate')('global.buttons.clear'))
      .cancel($filter('translate')('global.buttons.cancel'));

      $mdDialog.show(confirm).then(function () {
        vm.clearCart();
      }, function () {
        vm.filter.purchaseMethod='PURCHASE_BY_ITEM';
      });
    }

    /**
    * [clearCart resets cart if purchase by template]
    * @return {[type]} [description]
    */
    function clearCart() {
      BuyerBuyNowService.resetCart().then(function (response) {
        NotificationService.success({
          title: 'global.menu.entities.cart',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        $rootScope.$broadcast('refresh-cart');
      }, function (error) {
        NotificationService.error({
          title: 'Order Templates',
          error: error
        });
      });
    }

    //----------------------- FILTER PARAMS METHODS - Common to both template and item purchase method- end ------------------------------------//

    // ------------------- FILTER PARAMS METHODS - Item purchase method ----------------------------------------//

    /**
    * [filterQueryChanged reset the filters accordingly]
    * @return {[type]} [description]
    */
    function filterQueryChanged() {
      if(vm.filter.businessOutletId && !vm.filter.supplierId) {
        vm.supplierVisibilityTypeChanged();
      }
      if(vm.filter.supplierId) {
        vm.selectedSupplier = _.find(vm.supplierList, function(o) {
          return o.id === vm.filter.supplierId;
        });
      }
    }

    /**
    * [getAllPublicSuppliers Get all public suppliers]
    * @return {[type]} [description]
    */
    function getAllPublicSuppliers() {
      BuyerBuyNowService.getAllPublicSuppliers().then(function(response){
        vm.supplierList = $filter('orderBy')(response.data, 'companyName');
      },function(error){
      });
    }

    /**
    * [supplierVisibilityTypeChanged change the supplier type]
    * @return {[type]} [description]
    */
    function supplierVisibilityTypeChanged() {
      //clear supplierId from itemListQuery used for filter
      vm.filter.supplierId = null;
      //clear existing supplier list
      vm.supplierList = null;
      var visibilityType = angular.copy(vm.filter.visibilityType);
      if(vm.filter && vm.filter.businessOutletId) {
        if(vm.filter.visibilityType === 'PUBLIC') {
          vm.getAllPublicSuppliers();
        }
        else if(vm.filter.purchaseMethod === 'PURCHASE_BY_ITEM'){
          vm.getSuppliersByBusinessOutlet();
        }
      }
    }

    /**
    * [getSuppliersByBusinessOutlet get suppliers according to chosen businessOutletId and supplierVisibilityType]
    * @return {[type]} [description]
    */
    function getSuppliersByBusinessOutlet(){
      // get business outlet ID
      var params = {'businessId': vm.filter.businessOutletId, 'supplierVisibilityType': vm.filter.visibilityType};
      BuyerBuyNowService.getAllAllocatedSuppliersForBusiness1(params).then(function(response){
        vm.supplierList = $filter('orderBy')(response.data, 'companyName');
        //if only one allocated supplier set it as default
        if(vm.supplierList.length === 1) {
          vm.supplier = vm.supplierList[0].id;
          vm.filterQueryChanged();
        }
      },function(error){
      });
    }

/**
 * [validateSupplierList validates whether supplier list is present or not - else the user will be prompted to select another outlet]
 * @return {[type]} [description]
 */
 function validateSupplierList(){
   if(!vm.supplierList || vm.supplierList.length <= 0){
     if(!vm.filter.businessOutletId){
       NotificationService.simpleErrorToast({
         title: 'global.table.noRecordsFound',
         message: 'global.pleaseSelectABusinessOutletToStart'
       });
       return false;
     }
     else{
       NotificationService.simpleErrorToast({
         title: 'global.table.noRecordsFound',
         message: 'alertMessage.PLEASE_SELECT_ANOTHER_OUTLET'
       });
       return false;
     }
   }
 }

    /**
    * [isOutletSelected if business outlet is not selected - displays toastr and returns false so that accordion does not expand to display blank block]
    * @return {Boolean} [description]
    */
    function isOutletSelected(){
      if(!vm.filter.businessOutletId){
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'global.pleaseSelectABusinessOutletToStart'
        });
        return false;
      }
      else{
        return true;
      }
    }

    /**
    * [getBusinessOutletName get name of business outlet for passing as url state params]
    * @return {[type]} [description]
    */
    function getBusinessOutletName(){
      var businessOutlet = _.find(vm.businessOutlets, ['id', vm.filter.businessOutletId]);
      return businessOutlet.businessOutletName;
    }

    /**
    * [getSupplierName get name of supplier for passing as url state params]
    * @return {[type]} [description]
    */
    function getSupplierName(){
      if(vm.supplierList && vm.filter.supplierId){
        var supplier = _.find(vm.supplierList, ['id', vm.filter.supplierId]);
        return supplier.companyName;
      }
      else {
        return false;
      }
    }

    /**
    * [loadPurchaseOrderTypes load all purchase order types]
    * @return {[type]} [description]
    */
    function loadPurchaseOrderTypes() {
      if(vm.purchaseOrderTypes){
        return false;
      }
      PurchaseOrderTypeService.getAllPurchaseOrderTypes({
        page: 0,
        size: paginationConstants.maxLimit,
        sort: ''
      }).then(function(response) {
        vm.purchaseOrderTypes = response.data;
      }, function(error) {
      });
    }



    /**
    * [getBuyerPromotions Get all promotions for the buyer starting from today]
    * @return {[type]} [description]
    */
    function getBuyerPromotions() {
      if(vm.promotions)
      return false;
      PromotionService.getAllPromotionItemsForBuyer({
        page: 0,
        size: 10,
        sort: 'effectiveStartDate,asc',
        searchTerm: '',
        startDate: new Date(),
        endDate: '',
        flag:"BUY_NOW",
        buyerBusinessId: vm.itemListQuery.businessId
      }).then(function(response) {
        vm.showPromotionTicker = true;
        vm.promotions = response.data;
      }, function(error) {
        vm.promotions = null;
      });
    }

    /**
    * [hidePromotions hide promotion - may not be required for mobile view]
    * @return {[type]} [description]
    */
    function hidePromotions() {
      vm.showPromotionTicker = !vm.showPromotionTicker;
    }


    //-------------------------ORDER TEMPLATE -----------------------------//

    /**
    * [getTemplatesByOutlet get template list by outlet id]
    * @return {[type]} [description]
    */
    function getTemplatesByOutlet(){
      if(vm.filter.purchaseMethod === 'PURCHASE_BY_TEMPLATE' && vm.filter.businessOutletId){
        BuyerOrderTemplateService.getAllOrderTemplateByOutlets({'buyerBusinessId' :vm.filter.businessOutletId}).then(function (response) {
          if (response.data.length === 0) {
            NotificationService.simpleErrorToast({
              title: 'global.purchaseMethod',
              message: 'alertMessage.ORDER_TEMPLATE_NOT_MAPPED'
            });
            vm.templateList = [];
            vm.filter.supplierList = []; // order template can be created for multiple suppliers
          }
          else {
            vm.templateList = response.data;
          }
        }, function (error) {
          NotificationService.error({
            title: 'Order Templates',
            error: error
          });
        });
      }

    }

    /**
    * [getTemplateSuppliers get template details and extract supplier list and purchaseOrder type (no need for items in filter page)]
    * @return {[type]} [description]
    */
    function getTemplateSuppliers(){
      vm.recordError=[];
      vm.items = undefined;
      if(vm.filter.templateId){
        BuyerBuyNowService.getAllItemBuyerMappingsByTemplateId(vm.filter.templateId, {
          view: vm.itemListType,
          outletId: vm.filter.businessOutletId,
          query: vm.searchText,
          page: vm.query.page - 1,
          size: vm.query.limit,
          sort: sort()
        }).then(function (response) {
          vm.items = response.data.items;
          vm.orderTemplateDetails = response.data.orderTemplate;
          vm.itemListLoaded = true;
          vm.filter.supplierList =_.uniqBy(_.map(vm.items, function(item){
            return {
              'id': item.supplier.id,
              'supplierName': item.supplier.companyName,
              'selected': true
            };
          }), 'id');  //uniqBy is used since lodash map adds a key 'hashkey' and hence uniq cannot be used

          vm.purchaseOrderTypes = vm.orderTemplateDetails.purchaseOrderType ? [vm.orderTemplateDetails.purchaseOrderType] : null;
          vm.filter.purchaseOrderType = vm.purchaseOrderTypes ? vm.purchaseOrderTypes[0].id : undefined;
        }, function (error) {
          NotificationService.error({
            title: 'Order Templates',
            error: error
          });
        });
      }
    }

    //---------------------------- REDIRECTION AND STATE PARAMS ASSIGNMENTS ----------------------//

    /**
    * [proceedToBuyNow proceed to buy now items page if required params are selected]
    * @return {[type]} [description]
    */
    function proceedToBuyNow(){
      switch(vm.filter.purchaseMethod){
        case 'PURCHASE_BY_ITEM':
        BuyerBuyNowService.setBuyNowParams(vm.filter);
        if(vm.filter.purchaseOrderType){
          $state.go('buyer.buy-now-mobile-filter.list-view',{
            'visibilityType': vm.filter.visibilityType,
            'businessOutletId':vm.filter.businessOutletId,
            'businessOutletName':vm.getBusinessOutletName(),
            'supplierId':vm.filter.supplierId ? vm.filter.supplierId : undefined,
            'supplierName':vm.getSupplierName() ? vm.getSupplierName() : undefined,
            'purchaseOrderType': vm.filter.purchaseOrderType,
            'purchaseMethod': vm.filter.purchaseMethod
          },
          {reload: false});
        }
        else{
          NotificationService.simpleErrorToast({
            title: 'global.buttons.buyNow',
            message: 'global.PURCHASE_ORDER_TYPE_NOT_FOUND'
          });
        }
        break;

        case 'PURCHASE_BY_TEMPLATE':
        $state.go('buyer.buy-now-mobile-filter.list-view',{
          'visibilityType': vm.filter.visibilityType,
          'businessOutletId':vm.filter.businessOutletId,
          'businessOutletName':vm.getBusinessOutletName(),
          'templateId': vm.filter.templateId,
          'purchaseMethod': vm.filter.purchaseMethod
        },
        {reload: false});
        break;
      }
    }

    /**
     * [validateMOQ Validates the MOQ with Multiplier]
     */
    function validateMOQ(multiplierFactor, quantity, index){
        if(multiplierFactor > 0){
          if (((quantity*100) % (multiplierFactor*100))/100 != 0) {
            vm.buyNowListForm['quantity_'+index].$setTouched();
            vm.buyNowListForm['quantity_'+index].$setValidity("moq", false);
          }else{
            vm.buyNowListForm['quantity_'+index].$setValidity("moq", true);
          }
        }
      }

  }


})();
