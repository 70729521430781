/**
 * @Author: Ajay
 * @Date:   23-May-2017 15:48 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.manage-supplier-identification-code.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 01-Jun-2017 13:02 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('buyer.tools.manage-supplier-identification-code', {
      parent: 'buyer.tools',
      url: '/manage-supplier-identification-code',
      data: {
        authorities: ['ROLE_BUYER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/tools/manage-supplier-identification-code/buyer.manage-supplier-identification-code.html',
          controller: 'BuyerSupplierIdentificationCodeController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('buyerSupplierMapping');
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('user-management');
          return $translate.refresh();
        }]
      }
    });
  }

})();
