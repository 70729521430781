/**
 * @Author: Allan
 * @Date:   Dec-2017
 * @Filename: xero-resource.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 14-Jun-2018 14:46 +05:30
 */



 (function() {
   'use strict';

   angular.module('ezyprocureApp').factory('XeroResourceService', XeroResourceService);

   XeroResourceService.$inject = ['$window','$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
   function XeroResourceService($window, $http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

     var XeroResourceService = {
       exportToXero : exportToXero,
       exportInvoiceToXero : exportInvoiceToXero, // OCR
       exportSupplierInvoiceToXero : exportSupplierInvoiceToXero, // Supplier
       exportInvoiceToXeroAccount : exportInvoiceToXeroAccount
     };
     return XeroResourceService;

     /**
     * [getAllPurchaseOrders gets PO list]
     * @param  {[type]} params [filter params]
     * @return {[object]}        [success/error message]
     */

     function exportToXero(params) {
       var deferred = $q.defer();
       $http({
         url: 'api/' + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE + 'xero',
         method: "GET",
         params:params,
         responseType : "json",
       }).then(function(data) {
         deferred.resolve(data);
       }).catch(function(message) {
         deferred.reject(message);
       });
       return deferred.promise;
     }

     function exportInvoiceToXeroAccount(xeroInvoiceDTO){
        var deferred = $q.defer();
        $http({
          url:  API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'payments/xero',
          method: "POST",
          data:xeroInvoiceDTO,
          responseType : "json",
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
     }

     function exportInvoiceToXero(params) {
       var deferred = $q.defer();
       $http({
         url: 'api/' + RESOURCE_URL_CONSTANTS.INVOICE_RESOURCE + 'ocr/xero',
         method: "GET",
         params:params,
         responseType : "json",
       }).then(function(data) {
         deferred.resolve(data);
       }).catch(function(message) {
         deferred.reject(message);
       });
       return deferred.promise;
     }

     function exportSupplierInvoiceToXero(params) {
       var deferred = $q.defer();
       $http({
         url: 'api/' + RESOURCE_URL_CONSTANTS.INVOICE_RESOURCE + 'xero',
         method: "GET",
         params: params,
         responseType : "json",
       }).then(function(data) {
         deferred.resolve(data);
       }).catch(function(message) {
         deferred.reject(message);
       });
       return deferred.promise;
     }


   }
 })();
