/**
* @Author: Allan
* @Date:   26-October-2018 12:18 +05:30
* @Project: Ezyprocure
* @Filename: ezp-image-zoom.service.js
* @Last modified by:   Allan
* @Last modified time: 27-November-2018 12:18 +05:30
*/


(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('EzyImageZoomService', EzyImageZoomService);

  EzyImageZoomService.$inject = ['$http', '$q', 'toastr', 'fCsv', 'TIME_CONSTANTS'];

  function EzyImageZoomService($http, $q, toastr, fCsv, TIME_CONSTANTS) {

    var ezyImageZoomService = {
      imageZoom: imageZoom,
      clearLens: clearLens,
      userClicked: false,
      lens: {}
    };
    return ezyImageZoomService;

    function imageZoom(imgID, resultID) {
      var img, lens, result, cx, cy;
      img = document.getElementById(imgID);
      result = document.getElementById(resultID);
      /*create lens:*/
      ezyImageZoomService.lens = lens = document.createElement("DIV");
      lens.setAttribute("class", "img-zoom-lens");
      /*insert lens:*/
      img.parentElement.insertBefore(lens, img);
      /*calculate the ratio between result DIV and lens:*/
      cx = result.offsetWidth / lens.offsetWidth;
      cy = result.offsetHeight / lens.offsetHeight;
      /*set background properties for the result DIV:*/
      result.style.backgroundImage = "url('" + img.src + "')";
      result.style.backgroundSize = (img.width * cx) + "px " + (img.height * cy) + "px";
      /*execute a function when someone moves the cursor over the image, or the lens:*/
      lens.addEventListener("mousemove", moveLens);
      img.addEventListener("mousemove", moveLens);
      /*and also for touch screens:*/
      lens.addEventListener("touchmove", moveLens);
      img.addEventListener("touchmove", moveLens);
      lens.addEventListener("click", userClicked);
      img.addEventListener("click", userClicked);
      function moveLens(e) {
        var pos, x, y;
        /*prevent any other actions that may occur when moving over the image:*/
        e.preventDefault();
        /*get the cursor's x and y positions:*/
        if(!ezyImageZoomService.userClicked){
            pos = getCursorPos(e);
            /*calculate the position of the lens:*/
            x = pos.x - (lens.offsetWidth / 2);
            y = pos.y - (lens.offsetHeight / 2);
            /*prevent the lens from being positioned outside the image:*/
            if (x > img.width - lens.offsetWidth) {x = img.width - lens.offsetWidth;}
            if (x < 0) {x = 0;}
            if (y > img.height - lens.offsetHeight) {y = img.height - lens.offsetHeight;}
            if (y < 0) {y = 0;}
            var imageMargin = parseFloat(getElementPropertyValue(img, 'margin-left'));
            /*set the position of the lens:*/
            lens.style.left = x + imageMargin + "px";
            lens.style.top = y + "px";
            /*display what the lens "sees":*/
            result.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
        }
      }
      function getCursorPos(e) {
        var a, x = 0, y = 0;
        e = e || window.event;
        /*get the x and y positions of the image:*/
        a = img.getBoundingClientRect();
        /*calculate the cursor's x and y coordinates, relative to the image:*/
        x = e.pageX - a.left;
        y = e.pageY - a.top;
        /*consider any page scrolling:*/
        x = x - window.pageXOffset;
        y = y - window.pageYOffset;
        return {x : x, y : y};
      }

      function userClicked(e){
        if(!ezyImageZoomService.userClicked){
            ezyImageZoomService.userClicked = true;
        } else {
            ezyImageZoomService.userClicked = false;
        }
      }

      function getElementPropertyValue(element, property) {
        return getComputedStyle(element, null).getPropertyValue(property);
      }

    }

    function clearLens() {
      ezyImageZoomService.lens.remove();
    }

  }
})();
