/**
* @Author: sneha
* @Date:   15-Mar-2018 11:32 +05:30
* @Project: Ezyprocure
* @Filename: supplier.item-pricing.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 12-Oct-2018 14:43 +05:30
*/


(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierItemPricingController', SupplierItemPricingController);

  SupplierItemPricingController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', '$stateParams', 'toastr', '$mdDialog','pagingParams', 'UTCDateService', 'paginationConstants', 'CSVExportService', 'EXPORT_CONSTANTS', 'BusinessSupplierMapService', 'BrandManagementService', 'BusinessReportService', 'NotificationService', 'BuyerSupplierMappingService', 'SupplierDataService','BuyerBusinessService'];

  function SupplierItemPricingController($scope, $rootScope,$q ,$filter, $state, $stateParams, toastr, $mdDialog, pagingParams, UTCDateService, paginationConstants, CSVExportService, EXPORT_CONSTANTS, BusinessSupplierMapService, BrandManagementService,  BusinessReportService, NotificationService, BuyerSupplierMappingService, SupplierDataService, BuyerBusinessService) {
    var vm = this;
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.getPriceLists= getPriceLists;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.todaysDate = new Date();
    vm.filter = {};
    vm.query = {
      limit: paginationConstants.maxLimit,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    //CSV Header
    vm.ItemPricingHeader = EXPORT_CONSTANTS.CSV.supplierItemPricingReport;
    //CSV Function Declaration
    vm.getItemPricingCSVData = getItemPricingCSVData;
    vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
    vm.getAllBrandsOfBuyerList = getAllBrandsOfBuyerList;
    vm.getAllBrandMappedsBusinessOutlets = getAllBrandMappedsBusinessOutlets;
    vm.buyersSelectCheck = buyersSelectCheck;
    vm.filter.businessIdList = [];
    vm.resetFilters = resetFilters;

    /**
    * [getAllBuyerBusinessOutlets get all business outlets]
    * @return {[boolean]} [false if business outler already present]
    */
    function getAllBuyerBusinessOutlets() {
      BusinessSupplierMapService.getBuyerBusinessOutlets().then(function(response) {
        vm.businessOutlets = response.data;
      }, function(error) {
      });
    }

    /**
    * [getAllSupplierMappedBuyers get all buyers mapped to the supplier]
    */
    function getAllSupplierMappedBuyers() {
      vm.brandIdList = [];
      vm.filter.businessIdList = [];
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
      };
      if(vm.buyers)
       return false;
      var supplierId = SupplierDataService.getSupplierProfile().supplier.id;
      BuyerSupplierMappingService.getAllSupplierMappedBuyers(supplierId, params).then(function(response) {
        vm.buyers = response.data;
      }, function(error) {
      });
    }

    /**
     * [getAllBrandsOfBuyerList Gets all brands of selected buyers]
     * @param {Array} buyerIdList
     */
    function getAllBrandsOfBuyerList(buyerIdList){
      vm.filter.businessIdList = [];
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        query:'',
        buyerId : buyerIdList
      };
      BrandManagementService.getAllBrandsOfBuyerList(params)
      .then(function(response){
        vm.brands = response;
      },function(error){
        NotificationService.error({
          title: 'Brand Listing',
          error: error
        });
      });
    }

    /**
     * [selectAll Selects all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets,  buyers or brands]
     */
    function selectAll(dropdown) {
      switch (dropdown) {
        case 'BRANDS':
          vm.brandIdList = _.map(vm.brands, _.property('id'));
          break;
        case 'USERS':
          vm.filter.buyerIdList  = _.map(vm.buyers, _.property('id'));
           break;
        case 'OUTLETS':
          vm.filter.businessIdList = _.map(vm.brandMappedBusinessOutlets, _.property('id'));
           break;
      }

    }

    /**
     * [deselectAll Deselect all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets, buyers or brands]
     */
    function deselectAll(dropdown) {
      switch (dropdown) {
        case 'BRANDS':
          vm.brandIdList = [];
          break;
        case 'USERS':
          vm.filter.buyerIdList = [];
          break;
        case 'OUTLETS':
          vm.filter.businessIdList = [];
          break;
      }
    }

    // get all business outlets mapped with brand
    function getAllBrandMappedsBusinessOutlets(brandIdList) {
      if (!brandIdList || brandIdList.length === 0) return false;
      var itemTerminationDTO = {
        'brandIdList': brandIdList,
        'buyerIdList': vm.filter.buyerIdList
      }
      var params = {
        page: 0,
        size: paginationConstants.maxLimit
      }
      BuyerBusinessService.getAllBrandMappedsBusinessOutlets(itemTerminationDTO, params).then(function(response) {
        vm.brandMappedBusinessOutlets = response.data;
      }, function(error) {
      });
    }

    /**
    * [getPriceLists  get price listing details]
    */
    function getPriceLists() {
      BusinessReportService.getSupplierPriceListingReport({
        buyerBusinessIdList: !vm.filter.businessIdList ? [] : vm.filter.businessIdList ,
        query: !vm.filter.itemName ? '' : vm.filter.itemName,
        itemCode: !vm.filter.itemCode ? '' : vm.filter.itemCode,
        buyerIdList: !vm.filter.buyerIdList ? [] : vm.filter.buyerIdList,
        businessIdList: !vm.filter.businessIdList ? [] : vm.filter.businessIdList,
        brandIdList : !vm.brandIdList ? [] : vm.brandIdList
      },
      {
        page: vm.query.page - 1,
        size: vm.query.limit,
      }
    ).then(function(response){
      vm.priceList = response.data;
       _.forEach(vm.priceList, function(each){
        _.forEach(each.items, function(item){
         var priceArr = _.uniqBy(item.itemBusinessMappingDTOS, 'price');
         if(priceArr.length == 1){
            item.uniqPrice = priceArr[0].price;
         }
          else {
            item.uniqPrice = null;
          }
       });
      });
      vm.totalItems = response.headers('X-Total-Count');
    },function(error){
      NotificationService.error({
        title: 'Price Listing',
        error: error
      });
    });
  }

  /**
  * [getItemPricingCSVData CSV forr price listing report]
  */
  function getItemPricingCSVData(){
    var deferred = $q.defer();
    if (!vm.priceList) {
      NotificationService.simpleErrorToast({
        title: 'global.table.noRecordsFound',
        message: 'alertMessage.SEARCH_AND_SUBMIT'
      });
      return deferred.promise;
    }
    return CSVExportService.getSupplierItemPricingCSV(vm.priceList);
  }

  function buyersSelectCheck(){
     vm.filter.buyerIdList = vm.filter.buyerIdList;
  }

  function resetFilters() {
    vm.filter = {};
    vm.brandIdList = [];
    vm.priceList = null;
    vm.searchBrandTerm = {};
    vm.searchBuyerTerm = {};
    vm.searchOutletTerm= {};
}

}
})();
