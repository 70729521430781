/**
* @Author: Ajay
* @Date:   27-Mar-2017 13:16 +05:30
* @Project: Ezyprocure
* @Filename: manage-suppliers.edit.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-01-29T12:46:23+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('ManageSupplierEditController', ManageSupplierEditController);

  ManageSupplierEditController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$mdDialog', 'Auth', 'ManageSupplierService', 'ImageUploadService', 'TIME_CONSTANTS', 'GLOBAL_CONSTANTS', 'NotificationService', 'PdfUploadService', 'toastr', 'BankService', 'paginationConstants', 'EzyCompanyService'];

  function ManageSupplierEditController($scope, $rootScope, $state, $stateParams, $mdDialog, Auth, ManageSupplierService, ImageUploadService, TIME_CONSTANTS, GLOBAL_CONSTANTS, NotificationService, PdfUploadService, toastr, BankService, paginationConstants, EzyCompanyService) {
    var vm = this;

    vm.supplierId = $stateParams.supplierId;
    vm.adhocView = $stateParams.view;
    /*vm.visibilityType = $stateParams.visibilityType;*/
    vm.supplier = $rootScope.supplierToEdit;
    vm.save = save;
    vm.updateSupplierActivatedKey = updateSupplierActivatedKey;
    vm.checkDeliveryConditions = checkDeliveryConditions;
    vm.populateDeliveryConditions = populateDeliveryConditions;
    vm.checkPaymentTerms = checkPaymentTerms;
    vm.populatePaymentTerms = populatePaymentTerms;
    vm.paymentTermChanged = paymentTermChanged;
    vm.setCompanyLogo = setCompanyLogo;
    vm.uploadLogo = uploadLogo;
    vm.getSupplierdata = getSupplierdata;
    vm.deliveryConditions = {};
    vm.paymentTermsOptions = {};
    vm.paymentTerms = {};
    vm.paymentTerms.BANK_TRANSFER = {};
    vm.paymentTerms.PAYPAL = {};
    vm.paymentTerms.CHEQUE = {};
    vm.paymentTerms.OTHER_PAYMENT = {};
    vm.orderTimes = TIME_CONSTANTS.timeConstants;
    vm.userRole = Auth.getUserRole();
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.visibilityType = $stateParams.visibilityType ? $stateParams.visibilityType : 'ALLOCATED';
    vm.showKycUpload = showKycUpload;
    vm.getPdfFileBase64 = getPdfFileBase64;
    vm.supplierKycUpload = supplierKycUpload;
    vm.cancel = cancel;
    // To check supplier management state
    vm.mst = vm.visibilityType === 'ADHOC' ? 'adhoc-tools.adhoc-suppliers' : 'suppliers';
    vm.getAllBanks = getAllBanks;
    vm.selectedBankChanged = selectedBankChanged;
    vm.updateLeadTime = updateLeadTime;
    vm.supplier = {}
    vm.bankDetails = [{
      bank:{
        id:null,
        bankCode:'',
        swiftCode:''
      },
      branchCode:'',
      accountBank:'',
      accountType:'',
      accountName:'',
      accountNumber:''
    }];
    vm.addBankDetails = addBankDetails;
    vm.deleteBankDetails = deleteBankDetails;
    vm.updateIdentifier=updateIdentifier;
    vm.getIdentifier=getIdentifier;
    vm.createIdentifier=createIdentifier;
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.deleteIsoCodeAndIdentifier = deleteIsoCodeAndIdentifier;
    vm.initiateSMPRegistration = initiateSMPRegistration;
    vm.showPopup = showPopup;
    vm.cancel = cancel;
    vm.checkStatus = checkStatus;
    vm.customDutyTypes = GLOBAL_CONSTANTS.CUSTOM_DUTY_TYPE;

    /**
     * [showKycUpload opens modal to upload KYC file]
     * @param {Object} event
     */
    function showKycUpload(event, supplier){
      vm.selectedSupplier = angular.copy(supplier);
      $mdDialog.show({
        templateUrl:'app/common-modules/manage-suppliers/manage-suppliers.kyc-upload.html',
        controller:function(){
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    /**
     * [supplierKycUpload updates the KYC status]
     * @param {Object} data
     * @param {Object} params
     */
    function supplierKycUpload(data,supplierId){
      vm.cancel();
      var params = {supplierId: supplierId};
      ManageSupplierService.supplierKycUpload(data, params).then(function(response){
        vm.files = '';
        NotificationService.success({
          title: 'Supplier KYC update',
          message: 'KYC Updated successfully'
        });
      },function(error){
        NotificationService.error({
          title: 'Supplier KYC update',
          error: error
        });
      }
      );
    }

    /**
     * [addBankDetails Add bank Details]
     */
    function addBankDetails(){
      vm.bankDetails.push({});
    }

    /**
     * [deleteBankDetails Deletes the Bank details]
     * @param {Integer} index
     */
    function deleteBankDetails(index){
      vm.bankDetails.splice(index, 1);
    }
    function selectedBankChanged(bankId, index) {
      var banks = angular.copy(vm.banks);
      vm.selectedBank = BankService.getSelectedBank(banks, bankId);
      vm.bankDetails[index].bank.id = vm.selectedBank.id;
      vm.bankDetails[index].bank.bankCode = vm.selectedBank.bankCode;
      vm.bankDetails[index].bank.swiftCode = vm.selectedBank.swiftCode;
    }
    /**
     * [getPdfFileBase64 converts the uploaded file into base64]
     * @param {Integer} supplierId
     */
    function getPdfFileBase64(supplierId) {
      var file = vm.files[0];
      if (!file) {
        toastr.error('Please upload a file!', 'File Required');
        return false;
      }
      PdfUploadService.getPdfFileBase64(file.lfFile, function(base64PDF) {
        vm.fileUploadData = {
          kycReport: base64PDF
        };
        vm.supplierKycUpload(vm.fileUploadData,supplierId);
      });
    }

    /**
    * [cancel to close the modal]
    */
    function cancel() {
      $mdDialog.cancel();
    }

    function getSupplierdata() {
      ManageSupplierService.getAdminSupplier(vm.supplierId).
        then(function(response) {
          if (response) {
            vm.supplier = response;
            vm.populateDeliveryConditions();
            vm.populatePaymentTerms();
            if(vm.supplier.accessForPEPPOL || vm.userRole.pretty == 'admin'){
              vm.getIdentifier();
            }
          }
        }, function(error) {
        });
    }

    vm.getAllBanks();

    function showDuplicateDialog(ev, error) {
      var headerError = error.headers('X-ezyprocureApp-error') || '';
      var errorList = ['alertMessage.COMPANY_NAME_EXIST', 'alertMessage.COMPANY_REGISTRATION_NUMBER_EXIST'];
      if (errorList.includes(headerError)) {
        var confirm = $mdDialog.confirm()
          .title('A similar ADHOC supplier already exists in the system.')
          .textContent('Please go to \'Adhoc-Supplier Buyer Mapping\' to map')
          .ariaLabel('confirmation dialog')
          .targetEvent(ev)
          .ok('Take me!')
          .cancel('Cancel');

        $mdDialog.show(confirm).then(function () {
          $state.go('buyer.adhoc-tools.adhoc-supplier-buyer-mapping');
        }, function () {
        });
      }
    }

    function save(ev) {
      checkPaymentTerms();
      if((vm.supplier.accessForPEPPOL || vm.userRole.pretty == 'admin') && vm.ezyCompanyDetails){
        if(vm.ezyCompanyDetailsId){
        vm.ezyCompanyDetails.isoCode == ''|| vm.ezyCompanyDetails.identifier =='' ? vm.deleteIsoCodeAndIdentifier() : vm.updateIdentifier();
      }
      else{
         vm.createIdentifier();
        }
     }
      // Call Admin supplier service with supplier object as parameter to update
      ManageSupplierService.updateAdminSupplier(vm.supplier).then(function(response) {
        $scope.$emit('ezyprocureApp:supplierUpdate', response);
        NotificationService.success({
          title: 'Admin Supplier',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        $state.go(vm.userRole.pretty + '.' + vm.mst, {
          reload: false
        });
      }, function(error) {
        NotificationService.error({
          title: 'Admin Supplier Updation',
          error: error
        });
        // if duplicate found, show dialog
        showDuplicateDialog(ev, error);
      });
    }

    // delete iso code and identifier
    function deleteIsoCodeAndIdentifier(){
      EzyCompanyService.deleteIsoCodeAndIdentfier(vm.ezyCompanyDetails.id).then(function(response){
        NotificationService.success({
          title: 'global.IsoCodeAndIdentifier',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
      },
      function(error){
      });
    }

    function updateSupplierActivatedKey(supplierId, isActivate) {
      ManageSupplierService.updateSupplierActivatedKey(supplierId, isActivate).then(function(response) {
        vm.supplier.user.activated = response.data.user.activated;
        $scope.$emit('ezyprocureApp:supplierUpdate', response);
        NotificationService.success({
          title: 'Admin Supplier',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
      }, function(error) {
        NotificationService.error({
          title: 'Admin Supplier Updation',
          error: error
        });
      });
    }

    //Check for delivery conditions
    function checkDeliveryConditions() {
      if (!vm.deliveryConditions.today) {
        vm.supplier.orderTimeForToday = null;
      }
      if (!vm.deliveryConditions.friday) {
        vm.supplier.orderTimeForFriday = null;
      }
      if (!vm.deliveryConditions.saturday) {
        vm.supplier.orderTimeForSaturday = null;
      }
      if (!vm.deliveryConditions.minimum) {
        vm.supplier.minimumOrderForFreeDelivery = null;
        vm.supplier.belowMinimumPurchaseCondition = null;
      }
    }

    //Populate delivery conditions in the form
    function populateDeliveryConditions() {
      if (vm.supplier.orderTimeForToday) {
        vm.deliveryConditions.today = true;
      }
      if (vm.supplier.orderTimeForFriday) {
        vm.deliveryConditions.friday = true;
      }
      if (vm.supplier.orderTimeForSaturday) {
        vm.deliveryConditions.saturday = true;
      }
      if (vm.supplier.minimumOrderForFreeDelivery) {
        vm.deliveryConditions.minimum = true;
      }
    }

    //call when a payment term is selected or deselected
    function paymentTermChanged(paymentType, active, paymentTerm) {
      paymentTerm.active = active;
      if (paymentTerm.active === false && !paymentTerm.id) {
        vm.paymentTerms[paymentType] = {};
      }
    }

    //check payment terms
    function checkPaymentTerms() {
      vm.supplier.paymentTermsList = [];
      _.forEach(vm.paymentTerms, function(paymentTerm, key) {
        paymentTerm.paymentType = key;
        if (paymentTerm.active === true || paymentTerm.active === false) {
          vm.supplier.paymentTermsList.push(paymentTerm);
        }
      });
    }

    //populate payment terms in the form
    function populatePaymentTerms() {
      vm.paymentTermsList = angular.copy(vm.supplier.paymentTermsList);
      _.forEach(vm.paymentTermsList, function(paymentTerm) {
        var key = angular.copy(paymentTerm.paymentType);
        if (paymentTerm.active === true) {
          vm.paymentTermsOptions[key] = true;
          vm.paymentTerms[key] = paymentTerm;
        }
      });
    }

    vm.getSupplierdata();

    /**IMAGE UPLOAD**/

    function setCompanyLogo(flowFile) {
      ImageUploadService.getImageBase64(flowFile.file, function(base64Img) {
        vm.uploadLogo(base64Img);
      });
    }

    function uploadLogo(base64Img) {
      vm.uploadingInProgress = true;
      ImageUploadService.uploadImage(base64Img).
        then(function(responseData) {
          vm.supplier.companyLogo = responseData.url;
          $scope.editSupplierForm.$pristine = false;
          NotificationService.success({
            title: 'Supplier Logo uploaded successfully',
            message: 'Please update to save changes!'
          });
          vm.uploadingInProgress = false;
        }, function(error) {
          vm.uploadingInProgress = false;
          NotificationService.error({
            title: 'Supplier Logo Updation',
            error: error
          });
        });
    }

    function getAllBanks() {
      BankService.getAllBanks({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.banks = response.data;
      }, function(error) {
      });
    }




    /**
    * [updateLeadTime update lead time value based on noSameDayDelivery condition]
    * @param  {[type]} noSameDayDelivery [no same day delivery condition for supplier]
    * @return {[type]}                   [description]
    */
    function updateLeadTime(noSameDayDelivery){
      if(noSameDayDelivery){
        vm.supplier.leadTime = 1;
      }
      else{
        vm.supplier.leadTime = 0;
      }
    }

    /**
     * [getIdentifier Gets supplier identifier details]
     */
    function getIdentifier() {
      EzyCompanyService.getIdentifier(vm.supplierId,'ROLE_SUPPLIER').then(function(response) {
       vm.ezyCompanyDetails=response.data;
       vm.ezyCompanyDetailsId=vm.ezyCompanyDetails.id;
      }, function(error) {
        NotificationService.simpleErrorToast({
          title: 'Admin Supplier Updation',
          message: error.data.message
        });
      });
    }

    /**
     * [updateIdentifier Updates supplier identifier details]
     */
    function updateIdentifier() {
      EzyCompanyService.updateIdentifier(
        {ezpCompanyId: vm.ezyCompanyDetails.ezpCompanyId,
         identifier:vm.ezyCompanyDetails.identifier,
         isoCode:vm.ezyCompanyDetails.isoCode,
         userRole:'ROLE_SUPPLIER',
         id:vm.ezyCompanyDetails.id}).then(function(response) {
      }, function(error) {
        NotificationService.simpleErrorToast({
          title: 'Admin Supplier Updation',
          message: error.data.message
        });
      });
    }

    /**
     * [createIdentifier Creates supplier identifier details]
     */
    function createIdentifier() {
      EzyCompanyService.createIdentifier(
        {ezpCompanyId:vm.supplierId,
         identifier:vm.ezyCompanyDetails.identifier,
         isoCode:vm.ezyCompanyDetails.isoCode,
         userRole:'ROLE_SUPPLIER',
         id:''}).then(function(response) {
      }, function(error) {
        NotificationService.simpleErrorToast({
          title: 'Admin Supplier Updation',
          message: error.data.message
        });
      });
    }

    function showPopup(event) {
      $mdDialog.show({
        templateUrl: 'app/core/common-templates/smp-participant-edit.popup.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: false
      });
    }

    function cancel(){
      $mdDialog.cancel();
    }

    function initiateSMPRegistration(){
      vm.cancel();
      var data = {
        identifierValue: vm.supplier.companyRegistrationNumber,
        additionalInformation: vm.additionalInformation ? vm.additionalInformation : '',
        contactEmail: vm.supplier.user.email,
        contactName: vm.supplier.contactPersonName ? vm.supplier.contactPersonName : '',
        phoneNumber: vm.supplier.phone ? vm.supplier.phone : '',
        contactType: 'Primary',
        countryCode: vm.supplier.user.userCountry,
        geographicalInformation: vm.geographicalInformation ? vm.geographicalInformation :'',
        language: vm.supplier.user.langKey,
        userName: vm.supplier.user.firstName + ' ' + vm.supplier.user.lastName,
        websiteUri: vm.websiteUri ? vm.websiteUri : '',
        pdActivation: true,
        smlActivation: true,
        userId:vm.supplier.user.id ,
        role: 'ROLE_SUPPLIER',
        companyName: vm.supplier.companyName,
        companyId: vm.supplier.id
      }
      EzyCompanyService.initiateSMPRegistration(data).then(function(response){
        NotificationService.success({
          title: 'Initiate SMP Registration',
          message: 'Success'
        });
        vm.ezyCompanyDetails.status = 'Success';
        vm.checkStatus();
      },
      function(error){
        NotificationService.error({
          title: 'Initiate SMP Registration',
          error: error
        });
      })
    }

    function checkStatus(){
      var status = ['FAILED',null];
      return _.includes(status,vm.ezyCompanyDetails.status);
    }

  }
})();
