/**
* @Author: Elsa
* @Date:   10-Mar-2017 12:52 +05:30
* @Project: Ezyprocure
 * @Last modified by:   Elsa
 * @Last modified time: 2017-06-22T11:12:21+05:30
*/


(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('supplier.reports.goods-return-notice', {
      parent: 'supplier.reports',
      url: '/goods-return-notice',
      data: {
        authorities: ['ROLE_SUPPLIER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/reports/goods-return-notice/supplier.goods-return-notice.html',
          controller: 'SupplierGoodsReturnNoticeController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,desc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('user-management');
          $translatePartialLoader.addPart('purchaseOrder');
          $translatePartialLoader.addPart('invoiceOCR');
          $translatePartialLoader.addPart('goodsReturnNotice');
          $translatePartialLoader.addPart('debitNoteStatus');
          return $translate.refresh();
        }]
      }
    })
    .state('supplier.reports.goods-return-notice.details', {
      parent: 'supplier.reports.goods-return-notice',
      url: '/details/:goodsReturnNumber',


      data: {
        authorities: ['ROLE_SUPPLIER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/reports/goods-return-notice/details/supplier.goods-return-notice.details.html',
          controller: 'SupplierGoodReturnNoticeDetailsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('items');
          return $translate.refresh();
        }]
      }
    });
  }

})();
