/**
* @Author: Abhay
* @Date:   06-Jan-2021 12:32 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.card-tokenize.transaction-response.controller.js
* @Last modified by:   Abhay
* @Last modified time: 06-Jan-2021 12:32 +05:30
*/



(function () {
    'use strict';

    angular
        .module('ezyprocureApp')
        .controller('CardTokenizeResponseController', CardTokenizeResponseController);

    CardTokenizeResponseController.$inject = ["CardSecurityService", "$state", "$stateParams", "$rootScope", "$mdDialog", "paginationConstants", "CardsService", "BuyerSupplierMappingService", "BuyerDataService", "BrandManagementService", "NotificationService", "GLOBAL_CONSTANTS"];

    function CardTokenizeResponseController(CardSecurityService, $state, $stateParams, $rootScope, $mdDialog, paginationConstants, CardsService, BuyerSupplierMappingService, BuyerDataService, BrandManagementService, NotificationService, GLOBAL_CONSTANTS) {

        var vm = this;
        vm.transactionResponse = {};
        vm.init = init;

        function init() {
            getCybersourceTransactionResponse();
        }

        function getCybersourceTransactionResponse() {
            CardsService.getCybersourceTransactionResponse()
                .then(function (response) {
                    formatResponse(response);
                }, function (error) {
                    NotificationService.error({
                        title: 'Cybersource Card Details',
                        error: error
                    });
                });
        }

        function formatResponse(response) {
            vm.transactionResponse.status = response.status;
            if (vm.transactionResponse.status == "SUCCESS") {

            }
        }

    }
})();
