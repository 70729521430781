/**
 * @Author: Abhay
 * @Date:   2020-06-09T17:59:47+05:30
 * @Last modified by:   Abhay
 * @Last modified time: 2020-06-09T17:59:47+05:30
 */

(function(){
    'use strict';
    angular
      .module('ezyprocureApp')
      .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider){
      $stateProvider
        .state('buyer.business-charts.demand-forecast-v2',{
          parent: 'buyer.business-charts',
          url: '/demand-forecast-v2',
          data: {
            authorities: ['ROLE_BUYER'],
          },
          views: {
            'content@': {
              templateUrl: 'app/buyer-modules/business-charts/demand-forecast-v2/buyer.demand-forecast-v2.html',
              controller: 'BuyerDemandForecastControllerv2',
              controllerAs: 'vm'
            }
          },
          params: {
            page: {
              value: '1',
              squash: true
            },
            sort: {
              value: 'id,desc',
              squash: true
            },
            search: null
          },
          resolve: {
            pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
              return {
                page: PaginationUtil.parsePage($stateParams.page),
                sort: $stateParams.sort,
                predicate: PaginationUtil.parsePredicate($stateParams.sort),
                ascending: PaginationUtil.parseAscending($stateParams.sort),
                search: $stateParams.search
              };
            }],
            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart('global');
              $translatePartialLoader.addPart('buyer');
              $translatePartialLoader.addPart('priceUpdates');
              $translatePartialLoader.addPart('items');
              $translatePartialLoader.addPart('user-management');
              return $translate.refresh();
            }]
          }
        });
    }

  })();
