/**
* @Author: sneha
* @Date:   14-Mar-2018 11:47 +05:30
* @Project: Ezyprocure
* @Filename: supplier.label-printing.state.js
 * @Last modified by:   sneha
 * @Last modified time: 05-Apr-2018 15:09 +05:30
*/


(function() {
  'use strict';
  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('supplier.picking-list-management.label-printing', {
      parent: 'supplier.picking-list-management',
      url: '/print-label',
      data: {
        authorities: ['ROLE_SUPPLIER'],
        pageTitle: 'Label Printing'
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/picking-list-management/label-printing/supplier.label-printing.html',
          controller: 'SupplierLabelPrintingController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          return $translate.refresh();
        }]
      }
    })
  }
})();
