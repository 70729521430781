/**
* @Author: Ajay
* @Date:   06-Mar-2017 15:47 +05:30
* @Project: Ezyprocure
* @Filename: supplier.process-order.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 07-Nov-2017 15:36 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierAdhocPoController', SupplierAdhocPoController);

  SupplierAdhocPoController.$inject = ['$scope', '$state', 'toastr', 'pagingParams', 'SupplierAdhocPoService', 'paginationConstants'];

  function SupplierAdhocPoController($scope, $state, toastr, pagingParams, SupplierAdhocPoService, paginationConstants) {

    var vm = this;
    vm.loadAll = loadAll;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.searchText = '';
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    /**
     * [sort to sort by PO date]
     */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function loadAll(search) {
      if (!search) {
        vm.searchText = '';
      }
      SupplierAdhocPoService.getAllProcessOrdersByStatus({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        status: 'PENDING',
        query: vm.searchText,
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.purchaseOrder = response.data;
      }, function(error) {
      });
    }

    vm.loadAll();

  }
})();
