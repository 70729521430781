/**
 * @Author: Ajay
 * @Date:   28-Jul-2017 15:53 +05:30
 * @Project: Ezyprocure
 * @Filename: login.controller.js
 * @Last modified by:   Allan
 * @Last modified time: 07-Aug-2017 15:19 +05:30
 */



(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('AutologinController', AutologinController);

  AutologinController.$inject = ['Auth', '$rootScope', '$stateParams', '$state', '$window', 'AuthServerProvider', 'API_CONSTANTS', 'Principal'];

  function AutologinController(Auth, $rootScope, $stateParams, $state, $window, AuthServerProvider, API_CONSTANTS, Principal) {
    var vm = this;
    vm.onInit = onInit;

    function onInit() {
      Principal.checkCBEnabled()
        .then(function() {
          actionAutoLogin();
        })
        .catch(function() {
          actionAutoLogin();
        });
      
    }

    function actionAutoLogin() {
      var action = $stateParams.action;
      if (action === 'save') {
        var token = $stateParams.token;
        var cbToken = $stateParams.cbToken;
        $rootScope.destinationUrl = $stateParams.destinationUrl;
        if (token) {
          AuthServerProvider.storeCbToken(cbToken);
          Auth.loginWithToken(token, false)
            .then(function () {
              Principal.identity(true).then(function() {
                if(Principal.isAuthenticated()) {
                  Principal.setUserVerifiedCB(false);
                  Auth.roleBasedRoute();
                } else {
                  $state.go('login');
                }
              }).catch(function() {
                $state.go('login');
              });
            });
        } else {
          $state.go('login');
        }
      } else if(action === 'delete') {
        AuthServerProvider.clearStorageData();
        Principal.authenticate(null);
        $window.location.href = API_CONSTANTS.cbBase;
      } else if(action == "general") {
        var token = $stateParams.token;
        if (token) {
          Auth.loginWithToken(token, false)
            .then(function () {
              Principal.identity(true).then(function () {
                if (Principal.isAuthenticated()) {
                  Auth.roleBasedRoute();
                } else {
                  $state.go('login');
                }
              }).catch(function () {
                $state.go('login');
              });
            });
        } else {
          $state.go('login');
        }
      }
       else {
        $state.go('login');
      }
    }

  }
})();
