/*
 * @Author: Abhay
 * @Date: 2020-12-18 11:47:49
 * @Filename: ezypayment.admin.nfrnds-account-report-statement.controller.js
 * @Last Modified by: Abhay
 * @Last Modified time: 2020-12-18 11:33:03
 */


(function () {
    'use strict';

    angular
        .module('ezyprocureApp')
        .controller('EzypaymentAdminStatementOfAccountReportNfrnds', EzypaymentAdminStatementOfAccountReportNfrnds);

    EzypaymentAdminStatementOfAccountReportNfrnds.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', 'toastr', 'pagingParams', 'UTCDateService', 'NotificationService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'BuyerBusinessService', 'ManageBuyerService', 'EzypaymentAdminStatementOfAccountService', 'BrandManagementService', 'ManageSupplierService', '$window', 'EXPORT_CONSTANTS', 'CSVExportService', 'EzypaymentAdminManageMerchantDetailsService', '$stateParams'];

    function EzypaymentAdminStatementOfAccountReportNfrnds($scope, $rootScope, $q, $filter, $state, toastr, pagingParams, UTCDateService, NotificationService, GLOBAL_CONSTANTS, paginationConstants, BuyerBusinessService, ManageBuyerService, EzypaymentAdminStatementOfAccountService, BrandManagementService, ManageSupplierService, $window, EXPORT_CONSTANTS, CSVExportService, EzypaymentAdminManageMerchantDetailsService, $stateParams) {
        var vm = this;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.todaysDate = new Date();
        vm.loadAll = loadAll;
        vm.query = {
            limit: paginationConstants.itemsPerPage,
            page: 1
        };
        vm.listViews = GLOBAL_CONSTANTS.LIST_VIEWS;
        vm.limitOptions = paginationConstants.limitOptions;
        vm.filter = {};
        vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
        vm.defaultDateTimeFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_TIME_FORMAT;
        vm.statementOfAccountReportCSVHeader = EXPORT_CONSTANTS.CSV.nfrndsStatementOfAccountReportCSVHeader;
        vm.selectAll = selectAll;
        vm.deselectAll = deselectAll;
        vm.getAllBuyersBusinessOutlets = getAllBuyersBusinessOutlets;
        vm.loadDropdowns = loadDropdowns;
        vm.getAllBuyersMappedToPayment = getAllBuyersMappedToPayment;
        vm.getAllBrandsOfBuyerList = getAllBrandsOfBuyerList;
        vm.getAllSuppliersMappedToPayment = getAllSuppliersMappedToPayment;
        vm.getAllCountryCurrencies = getAllCountryCurrencies;
        vm.generateGiro = generateGiro;
        vm.readGiroResponseFiles = readGiroResponseFiles;
        vm.getStatementOfAccountReportCSV = getStatementOfAccountReportCSV;
        vm.calculateSum = calculateSum;
        vm.calculateTwoSum = calculateTwoSum;
        vm.selectAllInvoices = selectAllInvoices;

        function calculateTwoSum(statementOfAccountReportsList, key1, key2) {
            var sum1 = _.sumBy(statementOfAccountReportsList, key1);
            var sum2 = _.sumBy(statementOfAccountReportsList, key2);
            return sum2 + sum1;
        }

        /**
        * [selectAllInvoices select all Pending Process Orders]
        */
        function selectAllInvoices() {
            vm.selectAll ? vm.selectAll = true : vm.selectAll = false;
            _.map(vm.statementOfAccountReports, function (statementOfAccountReport) {
                statementOfAccountReport.selected = vm.selectAll;
            });
        }

        function getStatementOfAccountReportCSV() {
            var deferred = $q.defer();
            vm.selectedStatementOfAccountReports = _.filter(vm.statementOfAccountReports, _.matches({ 'selected': true }));
            if (!vm.statementOfAccountReports) {
                NotificationService.simpleErrorToast({
                    title: 'global.table.noRecordsFound',
                    message: 'alertMessage.SEARCH_AND_SUBMIT'
                });
                return deferred.reject;
            } else {
                var getStatementOfAccountReportCSVData = (vm.selectedStatementOfAccountReports.length < 1) ? vm.statementOfAccountReports : vm.selectedStatementOfAccountReports;
                return CSVExportService.getStatementOfAccountReportNfrndsCSV(getStatementOfAccountReportCSVData, vm.filter.listView);
            }
        }

        /**
        * [calculateSum shows the sum of key in List]
        */
        function calculateSum(statementOfAccountReportsList, key) {
            var sum = _.sumBy(statementOfAccountReportsList, key)
            return sum;
        }

        /**
        * [loadAll Load All statement of account report]
        * @param  {[string]} search [search text]
        */
        function loadAll(search) {
            if (!search) {
                vm.searchText = '';
            }
            EzypaymentAdminStatementOfAccountService.getAllStatementOfAccountReportsNfrnds({
                page: vm.query.page - 1,
                size: vm.query.limit,
                sort: sort(),
                query: vm.searchText,
                currency: vm.filter.currency,
                poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber,
                invoiceNumber: !vm.filter.invoiceNumber ? '' : vm.filter.invoiceNumber,
                buyerIdList: !vm.filter.buyerIdList ? [] : vm.filter.buyerIdList,
                supplierIdList: !vm.filter.supplierIdList ? [] : vm.filter.supplierIdList,
                brandIdList: !vm.filter.brandIdList ? [] : vm.filter.brandIdList,
                businessOutletIdList: !vm.filter.businessOutletIdList ? [] : vm.filter.businessOutletIdList,
                //If dates are selected, they are converted to UTC format
                reportName: !vm.filter.listView ? '' : vm.filter.listView,
                fromDate: !vm.filter.fromDate ? UTCDateService.utcFromDate(vm.todaysDate) : UTCDateService.utcFromDate(vm.filter.fromDate),
                toDate: !vm.filter.toDate ? UTCDateService.utcToDate(vm.todaysDate) : UTCDateService.utcToDate(vm.filter.toDate),
                paymentGiroStatus: (vm.filter.giroStatus === 'ALL') ? '' : vm.filter.giroStatus,
                accountType: (vm.accountType === 'ALL') ? '' : vm.accountType,
                issuer: (vm.issuer === 'ALL') ? '' : vm.issuer,
            }).then(function (response) {
                vm.totalItems = response.headers('X-Total-Count');
                vm.statementOfAccountReports = response.data;
            }, function (error) {
                NotificationService.error({
                    title: 'Statement of Account Report',
                    error: error
                });
            });
        }

        /**
        * [sort to sort by PO date]
        */
        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            return result;
        }

        function getAllSuppliersMappedToPayment() {
            if (vm.suppliers) { return false }
            ManageSupplierService.getAllAdminSuppliersWithUserDetails({
                page: 0,
                size: paginationConstants.maxLimit,
                search: '',
                visibilityType: 'ALLOCATED',
                sort: sort()
            }).then(function (response) {
                vm.suppliers = response.data;
            }, function (error) {
                NotificationService.error({
                    title: 'Supplier Listing',
                    error: error
                });
            });
        }

        /**
        * [generateGiro creates GIRO files]
        * @param {Array} supplierIdList
        */
        function generateGiro(uploadType) {
            vm.supplierIdList = _.map(_.filter(vm.statementOfAccountReports, _.matches({ 'selected': true })), _.property('supplierId'));
            if (!vm.supplierIdList || vm.supplierIdList.length < 1) {
                toastr.error('Please select atleast one statement', 'No Statement Selected');
                return false;
            }
            vm.brandIdList = _.map(_.filter(vm.statementOfAccountReports, _.matches({ 'selected': true })), _.property('brandId'));

            vm.referenceIdList = _.map(_.filter(vm.statementOfAccountReports, _.matches({ 'selected': true })), _.property('transactionId'));
            var supplierIdList = {
                brandIdList: !vm.brandIdList ? [] : String(vm.brandIdList),
                currency: !vm.filter.currency ? '' : vm.filter.currency,
                supplierId: !vm.supplierIdList ? [] : String(vm.supplierIdList),
                fromDate: !vm.filter.fromDate ? UTCDateService.utcFromDate(vm.todaysDate) : UTCDateService.utcFromDate(vm.filter.fromDate),
                toDate: !vm.filter.toDate ? UTCDateService.utcToDate(vm.todaysDate) : UTCDateService.utcToDate(vm.filter.toDate),
                uploadHostToHost: uploadType,
                fast: vm.fast
            }
            EzypaymentAdminStatementOfAccountService.createGiroFile(supplierIdList)
                .then(function (response) {
                    if (response) {
                        _.each(response, function (url) {
                            $window.open(url);
                        });
                    }
                    NotificationService.success({
                        title: 'Generate GIRO file',
                        message: 'GIRO file is successfully generated'
                    });
                    vm.loadAll();
                }, function (error) {
                    if (error.status == 503) {
                        NotificationService.error({
                            title: 'File upload failed into UOB server',
                            error: error
                        });
                    }
                    else {
                        NotificationService.error({
                            title: 'Could not generate GIRO',
                            error: error
                        });
                    }
                });
        }

        /**
        * [Read Giro Response Files]
        */
        function readGiroResponseFiles() {
            EzypaymentAdminStatementOfAccountService.readGiroResponseFiles()
                .then(function (response) {
                    NotificationService.success({
                        title: 'global.buttons.readGiroResponseFiles',
                        message: 'alertMessage.COMPLETED_SUCCESSFULLY'
                    });
                }, function (error) {
                    NotificationService.error({
                        title: 'global.buttons.readGiroResponseFiles',
                        error: error
                    });
                });
        }

        /**
        * [selectAll Selects all from the list]
        * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, buyers or brands]
        */
        function selectAll(dropdown) {
            switch (dropdown) {
                case 'BUYERS':
                    vm.filter.buyerIdList = _.map(vm.buyers, _.property('id'));
                    break;
                case 'BRANDS':
                    vm.filter.brandIdList = _.map(vm.brands, _.property('id'));
                    break;
                case 'SUPPLIERS':
                    vm.filter.supplierIdList = _.map(vm.suppliers, _.property('id'));
                    break;
            }
        }

        /**
         * [deselectAll Deselect all from the list]
         * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, buyers or brands]
         */
        function deselectAll(dropdown) {
            switch (dropdown) {
                case 'BUYERS':
                    vm.filter.buyerIdList = [];
                    break;
                case 'BRANDS':
                    vm.filter.brandIdList = [];
                    break;
                case 'SUPPLIERS':
                    vm.filter.supplierIdList = [];
                    break;
            }
        }

        /**
        * [getAllBrandsOfBuyerList Gets all brands of selected buyers]
        * @param {Array} buyerIdList
        */
        function getAllBrandsOfBuyerList(buyerIdList) {
            if (!buyerIdList || buyerIdList.length === 0) return false;
            var params = {
                page: 0,
                size: paginationConstants.maxLimit,
                query: '',
                buyerId: buyerIdList,
                sort: sort()
            };
            BrandManagementService.getAllBrandsOfBuyerList(params)
                .then(function (response) {
                    vm.brands = response;
                }, function (error) {
                    NotificationService.error({
                        title: 'Brand Listing',
                        error: error
                    });
                });
        }

        /**
        * [getAllBuyers Gets all buyers mapped with the payment model]
        */
        function getAllBuyersMappedToPayment() {
            if (vm.buyers) { return false }
            ManageBuyerService.getAllAdminBuyersWithUserDetails({
                page: 0,
                size: paginationConstants.maxLimit,
                search: '',
                sort: sort()
            }).then(function (response) {
                vm.buyers = response.data;
            }, function (error) {
                NotificationService.error({
                    title: 'Buyer Listing',
                    error: error
                });
            });
        }

        /**
        * [loadDropdowns The function executes when the dropdown closes]
        */
        function loadDropdowns() {
            vm.getAllBuyersBusinessOutlets(vm.filter.buyerIdList);
            vm.getAllBrandsOfBuyerList(vm.filter.buyerIdList)
        }

        /**
        * [getAllBuyersBusinessOutlets gets all businessoutlets for selected buyers]
        * @param {List} buyerIdList
        */
        function getAllBuyersBusinessOutlets(buyerIdList) {
            if (!buyerIdList || buyerIdList.length === 0) return false;
            var params = {
                page: 0,
                size: paginationConstants.maxLimit,
                groupBy: 'buyer'
            };
            BuyerBusinessService.getAllBuyersBusinessOutlets(buyerIdList, params).then(function (response) {
                vm.buyerBusinessOutlets = response.data;
                vm.filter.businessOutletIdList = [];
            }, function (error) {
                NotificationService.error({
                    title: 'Business Outlet Listing',
                    error: error
                });
            });
        }

        /**
        * [getAllCountryCurrencies gets all country currencies]
        */
        function getAllCountryCurrencies() {
            EzypaymentAdminManageMerchantDetailsService.getAllCountryCurrencies().then(function (response) {
                vm.currencies = response.data;
                vm.currencies.unshift({currency: "IDR"});
                if (vm.chooseCurrency == "USD") {
                    vm.filter.currency = "USD";
                }
                else {
                    vm.filter.currency = vm.currencies[0].currency;
                }
                vm.loadAll();
            }, function (error) {
            });
        }

        vm.getAllCountryCurrencies();
    }
})();