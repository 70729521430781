/**
 * @Author: Ajay
 * @Date:   06-Apr-2017 10:21 +05:30
 * @Filename: user-access.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 30-Nov-2017 12:29 +05:30
 */



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('UserAccessService', UserAccessService);

  UserAccessService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function UserAccessService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var userAccessService = {
      getAllUsers: getAllUsers,
      getUserAccess: getUserAccess,
      updateUserAccess: updateUserAccess
    };

    return userAccessService;

    /* --  User Access Management APIs  -- */

    //Get all Admin Users
    function getAllUsers(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin-users',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get acess details using user Id
    function getUserAccess(userId) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.USER_ACCESS_RESOURCE + userId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update user access details
    function updateUserAccess(userAccessData) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.USER_ACCESS_RESOURCE, userAccessData).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
