/**
 * @Author: Ajay
 * @Date:   31-May-2017 16:27 +05:30
 * @Project: Ezyprocure
 * @Filename: collection-staff-interface.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 04-Aug-2017 09:50 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('buyer.ocr.collection-staff-interface', {
      parent: 'buyer.ocr',
      url: '/collection-staff-interface',
      data: {
        authorities: ['ROLE_BUYER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/ocr/collection-staff-interface/collection-staff-interface.html',
          controller: 'CollectionStaffInterfaceController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,desc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('priceUpdates');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('user-management');
          $translatePartialLoader.addPart('invoiceOCR');
          $translatePartialLoader.addPart('ocrInvoiceStatus');
          return $translate.refresh();
        }],
        hasModuleAccess: ['Auth','$q', '$state', function(Auth, $q, $state) {
          if(Auth.hasPermission('accessForOcrCollectionStaffInterface')){
            return true;
          }else {
            return $q.reject('Unauthorized');
          }
        }]
      }
    })
    .state('buyer.ocr.collection-staff-interface.individual-details', {
      parent: 'buyer.ocr.collection-staff-interface',
      url: '/individual-details/:poNumber/:deliveryDate',
      data: {
        authorities: ['ROLE_BUYER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/ocr/collection-staff-interface/individual-details/collection-staff-interface-individual-details.html',
          controller: 'CSInterfaceIndividualDetailsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('priceUpdates');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('user-management');
          $translatePartialLoader.addPart('invoiceOCR');
          return $translate.refresh();
        }]
      }
    });
  }

})();
