/**
* @Author: Allan
* @Date:   20-June-2018 16:15 +05:30
* @Project: Ezyprocure
* @Filename: due-payment-invoices.service.js
* @Last modified by:   Allan
* @Last modified time: 09-September-2018 14:45 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('DuePaymentInvoicesService', DuePaymentInvoicesService);

  DuePaymentInvoicesService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function DuePaymentInvoicesService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS, CardsService) {

    var DuePaymentInvoicesService = {
      getDuePaymentSummary: getDuePaymentSummary,
      getOdbcDuePaymentSummary: getOdbcDuePaymentSummary,
      getOdbcExternalDuePaymentSummary: getOdbcExternalDuePaymentSummary,
      setSelectedPOIds: setSelectedPOIds,
      getSelectedPOIds: getSelectedPOIds,
      confirmPayment: confirmPayment,
      odbcConfirmPayment: odbcConfirmPayment,
      externalConfirmPayment: externalConfirmPayment,
      setPaymentResponse: setPaymentResponse,
      getPaymentResponse: getPaymentResponse,
      getExternalInvoices: getExternalInvoices,
      setInvoiceType: setInvoiceType,
      getInvoiceType: getInvoiceType,
      getExternalDuePaymentSummary: getExternalDuePaymentSummary,
      removeExternalInvoice: removeExternalInvoice,
      getOcbcDuePaymentSummary: getOcbcDuePaymentSummary,
      getDDAReference: getDDAReference,
      ocbcConfirmPayment: ocbcConfirmPayment

    };

    var selectedPOIds = null;
    var paymentResponse = null;
    var invoiceType = null;
    return DuePaymentInvoicesService;

    function getOdbcDuePaymentSummary(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'cybersource/due-payment-summary/',
        method: "GET",
        params: params,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getOdbcExternalDuePaymentSummary(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'cybersource/external-payments-invoices/due-payment-summary/',
        method: "GET",
        params: params,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getOcbcDuePaymentSummary(params){
      var deferred = $q.defer();

      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'fast/collection/due-payment-summary',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });

      return deferred.promise;
    }

    function getDuePaymentSummary(params){
      var deferred = $q.defer();

      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'mpgs/due-payment-summary/',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });

      return deferred.promise;
    }

    function setSelectedPOIds(params){
      selectedPOIds = params;
    }

    function getSelectedPOIds(){
      return selectedPOIds ? selectedPOIds : null;
    }

    function ocbcConfirmPayment(params, duePaymentSummaryDetails, otp){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'fast/transaction/collection',
        method: "POST",
        data: duePaymentSummaryDetails,
        params: params,
        headers: { 'authorization-otp' : otp }
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });

      return deferred.promise;
    }

    function confirmPayment(params, duePaymentSummaryDetails, otp){
      var deferred = $q.defer();

      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'mpgs/payment/',
        method: "POST",
        data: duePaymentSummaryDetails,
        params: params,
        headers: { 'authorization-otp' : otp }
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });

      return deferred.promise;
    }

    function odbcConfirmPayment(params, duePaymentSummaryDetails, otp){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'cybersource/payment',
        method: "POST",
        data: duePaymentSummaryDetails,
        params: params,
        headers: { 'authorization-otp' : otp }
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [externalConfirmPayment Pays invoices externally]
     */
    function externalConfirmPayment(externalPaymentDetails, otp){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'mpgs/'+ RESOURCE_URL_CONSTANTS.PAYMENT + 'externally-paid-invoices',
        method: "POST",
        params: externalPaymentDetails,
        headers: { 'authorization-otp' : otp }
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function setPaymentResponse(params){
      paymentResponse = params;
    }

    function getPaymentResponse(){
      return paymentResponse ? paymentResponse : null;
    }

    function getExternalInvoices(params){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'external-payments-invoices/',
        method: "GET",
        params: params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });

      return deferred.promise;
    }

    function setInvoiceType(params){
      invoiceType = params;
    }

    function getInvoiceType(){
      return invoiceType ? invoiceType : null;
    }

    function getExternalDuePaymentSummary(params){
      var deferred = $q.defer();

      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'mpgs/external-payments-invoices/due-payment-summary/',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });

      return deferred.promise;
    }

    /**
     * removeExternalInvoice 
     * @param {invoiceData}  
     */
    function removeExternalInvoice(invoiceId){
      var deferred=$q.defer();
      $http.put(API_CONSTANTS.serviceBase+'api/'+API_CONSTANTS.baseVersion+RESOURCE_URL_CONSTANTS.EXTERNAL_PAYMENT+'remove-external-invoice',invoiceId)
      .then(function(response){
        deferred.resolve(response);
      }).catch(function(errorMessage){
        deferred.reject(errorMessage);
      });
      return deferred.promise;
    }

    function getDDAReference(brandId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'ocbc/dda/reference',
        method: "GET",
        params: brandId
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });

      return deferred.promise;
    }

  }
})();
