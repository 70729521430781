/*
 * @Author: Ajay 
 * @Date: 2019-02-11 11:37:07 
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-10-24 10:30:55
 */
(function() {
    'use strict';
  
    angular.module('ezyprocureApp').factory('EzyapDashboardService', EzyapDashboardService);
  
    EzyapDashboardService.$inject = ['$http','$q','API_CONSTANTS','RESOURCE_URL_CONSTANTS'];
  
    function EzyapDashboardService($http,$q, API_CONSTANTS,RESOURCE_URL_CONSTANTS) {
  
      var EzyapDashboardService = {
        getPendingInvoicesCount:getPendingInvoicesCount
      };
      return EzyapDashboardService;
         
      /**
       * [getPendingInvoicesCount Gets all pending invoices count]
       */
      function getPendingInvoicesCount() {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/dashboard/counter',
          method: "GET",
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }    
    }
})();