/**
 * @Author: Ajay
 * @Date:   15-Feb-2018 14:15 +05:30
 * @Project: Ezyprocure
 * @Filename: user.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 26-Mar-2018 14:20 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .factory('User', User);

  User.$inject = ['$resource'];

  function User($resource) {
    // Access user using username
    var service = $resource('api/users/:login', {}, {
      'query': {method: 'GET', isArray: true},
      'get': {
        method: 'GET',
        transformResponse: function(data) {
          data = angular.fromJson(data);
          return data;
        }
      },
      'save': { method:'POST' },
      'update': { method:'PUT' },
      'delete':{ method:'DELETE'}
    });

    return service;
  }
})();
