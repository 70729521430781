
(function () {
    'use strict';
  
    angular.module('ezyprocureApp').factory('AlexWorldService', AlexWorldService);
  
    AlexWorldService.$inject = ['$http', '$q', 'API_CONSTANTS','RESOURCE_URL_CONSTANTS'];
  
    function AlexWorldService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {
  
      var AlexWorldService = {
        validatingLbsUser: validatingLbsUser
      };
      return AlexWorldService;
      
      function validatingLbsUser(query) {        
        var deferred = $q.defer();
        $http({
          method: 'POST',
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.LSBS_VALIDATE_USER,
          data: query
        }).then(function(data) {
          deferred.resolve(data.data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
  
    }
  })();