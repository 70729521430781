/**
 * @Author: Ajay
 * @Date:   20-Dec-2016 19:05 +05:30
 * @Project: Ezyprocure
 * @Filename: admin.dashboard.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 16-Jan-2018 10:25 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('AdminDashboardController', AdminDashboardController);

  AdminDashboardController.$inject = ['$state', '$stateParams', 'Auth', 'AdminDashboardService', 'GLOBAL_CONSTANTS', 'NotificationService'];

  function AdminDashboardController($state, $stateParams, Auth, AdminDashboardService, GLOBAL_CONSTANTS, NotificationService) {
    var vm = this;
    vm.getAdminDashboardCounter = getAdminDashboardCounter;
    vm.refreshInterval = GLOBAL_CONSTANTS.DASHBOARD_REFRESH_INTERVAL;
    vm.getTotalTransactionVolume = getTotalTransactionVolume;
    vm.getPaymentTransactionVolume = getPaymentTransactionVolume;
    //Dashboard counter refresh timeout
    vm.incrementInterval = incrementInterval;
    vm.initializeIntervalCount = initializeIntervalCount;
    vm.refreshDashboardCounts = refreshDashboardCounts;
    vm.refreshTimeout = GLOBAL_CONSTANTS.DASHBOARD_REFRESH_TIMEOUT;
    vm.initialRefreshInterval = GLOBAL_CONSTANTS.DASHBOARD_REFRESH_INTERVAL;
    vm.refreshIntervalIncrement = GLOBAL_CONSTANTS.DASHBOARD_INTERVAL_INCREMENT;
    vm.pauseRefreshFlag = false;

    function getAdminDashboardCounter() {
      AdminDashboardService.getAdminDashboardCounter().then(function(response) {
        vm.adminDashboard = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'Admin Dashboard',
          error: error
        });
      });
    }

    /** force refresh counts */
    function refreshDashboardCounts() {
      getAdminDashboardCounter();
      initializeIntervalCount();
    }

    /** increment refresh interval on successfull refresh */
    function incrementInterval() {
      if(vm.refreshInterval == vm.initialRefreshInterval) {
        vm.refreshInterval += vm.refreshIntervalIncrement;
      }
      else if(vm.refreshInterval < vm.refreshTimeout) {
        vm.refreshInterval += vm.refreshIntervalIncrement;
      }
      else if (vm.refreshInterval >= vm.refreshTimeout) {
        vm.pauseRefreshFlag = true;
      }
    }

    function initializeIntervalCount() {
      vm.refreshInterval = vm.initialRefreshInterval;
      vm.pauseRefreshFlag = false;
    }

    function getTotalTransactionVolume() {
      AdminDashboardService.getTotalTransactionVolume().then(function(response) {
        vm.transactionVolume = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'Total Transaction Volume',
          error: error
        });
      });
    }

    function getPaymentTransactionVolume(){
      AdminDashboardService.getPaymentTransactionVolume().then(function(response){
        vm.paymentTransactionVolume = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'Payment Transaction Volume',
          error: error
       });
     });
    }

    getAdminDashboardCounter();

    initializeIntervalCount();
  }
})();
