/**
 * @Author: Fenn
 * @Date:   21-Feb-2020 17:54 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.supplier-mapping.state.js
 * @Last modified by: Liya
 * @Last modified time: 12-Feb-2020 18:38
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('buyer.mapping', {
      parent: 'buyer',
      url: '/supplier-directory',
      data: {
        authorities: ['ROLE_BUYER']
      },
      views: {
        'content@': {
          templateUrl: 'app/common-modules/buyer-supplier-mapping-table/buyer.supplier-mapping.html',
          controller: 'BuyerSupplierMappingTableController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('user-management');
          return $translate.refresh();
        }]
      }
    })
    .state('buyer.mapping.add', {
      parent: 'buyer.mapping',
      url: '/add/:role',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'Add new Buyer Mapping'
      },
      views: {
        'content@': {
          templateUrl: 'app/common-modules/buyer-supplier-mapping-table/templates/buyer-supplier.add.tmpl.html',
          controller: 'BuyerSupplierMappingAddController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('itemBuyerMapping');
          $translatePartialLoader.addPart('items');
          return $translate.refresh();
        }]
      }
    })
    .state('buyer.mapping.details', {
      parent: 'buyer.mapping',
      url: '/details/:visibility/:Id/:relate/:requestId/:value',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'Supplier detail page'
      },
      params: {
        relate: { squash: true, value: null }
      },
      views: {
        'content@': {
          templateUrl: 'app/common-modules/buyer-supplier-mapping-table/edit/buyer-supplier-edit.html',
          controller: 'BuyerSupplierEditController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('paymentTypes');
          $translatePartialLoader.addPart('itemBuyerMapping');
          $translatePartialLoader.addPart('items');
          return $translate.refresh();
        }]
      }
    });
  }

 
})();
