/**
* @Author: Ajay
* @Date:   28-Mar-2017 12:35 +05:30
* @Project: Ezyprocure
* @Filename: buyer.email.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 01-Jun-2017 12:38 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('buyer.send-email', {
      parent: 'buyer',
      url: '/send-email',
      data: {
        authorities: ['ROLE_BUYER']
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/email/buyer.email.html',
          controller: 'BuyerEmailController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('buyer');
          return $translate.refresh();
        }]
      }
    });
  }
})();
