/**
* @Author: Mufsir
* @Date:   01-Dec-2016 10:46 +05:30
* @Project: Ezyprocure
* @Filename: language.filter.js
 * @Last modified by:   Ajay
 * @Last modified time: 15-Dec-2017 12:40 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .filter('findLanguageFromKey', findLanguageFromKey);

  function findLanguageFromKey() {
    return findLanguageFromKeyFilter;

    function findLanguageFromKeyFilter(lang) {
      return {
        'ca': 'Català',
        'cs': 'Český',
        'da': 'Dansk',
        'de': 'Deutsch',
        'el': 'Ελληνικά',
        'en': 'English',
        'es': 'Español',
        'fr': 'Français',
        'gl': 'Galego',
        'hu': 'Magyar',
        'hi': 'हिंदी',
        'it': 'Italiano',
        'ja': '日本語',
        'ko': '한국어',
        'mr': 'मराठी',
        'nl': 'Nederlands',
        'pl': 'Polski',
        'pt-br': 'Português (Brasil)',
        'pt-pt': 'Português',
        'ro': 'Română',
        'ru': 'Русский',
        'sk': 'Slovenský',
        'sv': 'Svenska',
        'ta': 'தமிழ்',
        'tr': 'Türkçe',
        'zh-cn': '中文（简体）',
        'zh-tw': '繁體中文',
        'th': 'ไทย',
        'ms':'Bahasa Malaysia',
        'id':'Indonesia'
      }[lang];
    }
  }
})();
