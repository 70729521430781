/**
* @Author: Abhay
* @Date:   29-Mar-2021 15:05 +05:30
* @Project: Ezyprocure
* @Filename: admin.manage-xborder-consents.service.js
* @Last modified by:   Abhay
* @Last modified time: 29-Mar-2021 15:05 +05:30
*/


(function () {
    'use strict';

    angular.module('ezyprocureApp').factory('CrossBorderConsentsService', CrossBorderConsentsService);

    CrossBorderConsentsService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

    function CrossBorderConsentsService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

        var crossBorderConsentsService = {
            getAllConsents: getAllConsents,
            updateOpsStatus: updateOpsStatus,
        };

        return crossBorderConsentsService;

        function getAllConsents(params) {
            var deferred = $q.defer();
            $http({
                url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.IMPLEMENTATION_NOTE,
                method: "GET",
                params: params,
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (message) {
                deferred.reject(message);
            });
            return deferred.promise;
        }

        function updateOpsStatus(params) {
            var deferred = $q.defer();
            $http({
                url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.IMPLEMENTATION_NOTE,
                method: "PUT",
                params: params,
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (message) {
                deferred.reject(message);
            });
            return deferred.promise;
        }
    }
})();
