/**
 * @Author: Jayalekshmi 
 * @Date: 2019-01-03 10:40:23 
 * @Project: Ezyprocure
 * @Filename: sats.so.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-07-22 08:44:44
 */



(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('SatsSOController', SatsSOController);

  SatsSOController.$inject = ['toastr', '$q', '$filter', '$mdDialog', 'SatsManagementService', 'NotificationService', 'UTCDateService', 'CsvUploadService', 'UtilService', 'BuyerSupplierMappingService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'CSVExportService', 'EXPORT_CONSTANTS', 'Auth'];

  function SatsSOController(toastr, $q, $filter, $mdDialog, SatsManagementService, NotificationService, UTCDateService, CsvUploadService, UtilService, BuyerSupplierMappingService, GLOBAL_CONSTANTS, paginationConstants, CSVExportService, EXPORT_CONSTANTS, Auth) {

    var vm = this;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.loadAll = loadAll;
    vm.todaysDate = new Date();
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.soFilter = {};
    vm.userRole = Auth.getUserRole();
    vm.limitOptions = paginationConstants.limitOptions;
    vm.soFilter.fromDate = UTCDateService.utcFromDate(vm.todaysDate);
    vm.soFilter.toDate = UTCDateService.utcToDate(vm.todaysDate);
    vm.getAllJobs = getAllJobs;
    vm.getAllSupplierWithSatsAccess = getAllSupplierWithSatsAccess;
    vm.statusList = GLOBAL_CONSTANTS.SATS_STATUS;
    vm.documentType = 'SO';
    vm.rejectJob = rejectJob;
    vm.postJob = postJob;
    vm.getSelectedJobsIds = getSelectedJobsIds;
    vm.selectAllJobs = selectAllJobs;
    vm.postAllJobs = postAllJobs;
    vm.rejectAllJobs = rejectAllJobs;
    vm.downloadCSVSOData = downloadCSVSOData;
    vm.soCSVHeader = EXPORT_CONSTANTS.CSV.supplierSOCSVHeader;
    vm.showUpload = showUpload;
    vm.uploadSOCSV = uploadSOCSV;
    vm.confirmSo = confirmSo;
    vm.showConfirm = showConfirm;
    vm.addRejectionReason = addRejectionReason;
    vm.cancel = cancel;
    vm.rejectByType = rejectByType;
    vm.validateJobsForRejection = validateJobsForRejection;
    vm.reset = reset;
    vm.confirmDelete = confirmDelete;
    vm.deleteJob = deleteJob;
    
    // CSV
    vm.buyerSODataHeader = EXPORT_CONSTANTS.CSV.buyerSODataHeader;
    vm.getBuyerSODCSVata = getBuyerSODCSVata;

    /**
     * [getBuyerSODCSVata Gets all data to csv]
     */
    function getBuyerSODCSVata() {
      var deferred = $q.defer();
      if (vm.soJobList.length<1) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getBuyerSODCSVata(vm.soJobList);
    }

    /**
     * [rejectByType Decides which reject function to be called]
     */
    function rejectByType(){
      if(vm.rejectType == 'SINGLE_JOB'){
        vm.rejectJob();
      }else if(vm.rejectType == 'BULK_JOB'){
        vm.rejectAllJobs();
      }
    }

    /**
    * [cancel to close the modal]
    */
    function cancel() {
      $mdDialog.cancel();
    }

    /**
     * [addRejectionReason Shows a modal to enter rejection reason]
     * @param {Object} event 
     * @param {String} type 
     * @param {Integer} id 
     */
    function addRejectionReason(event, type, id) {
      vm.rejectType = type;
      vm.rejectedJobId = id;
      $mdDialog.show({
        templateUrl: 'app/common-modules/sats/templates/sats.reject-reason.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: false
      });
    }  

    /**
     * [showConfirm Shows do you want to continue.]
     * @param {Object} event 
     */
    function showConfirm(event) {
      var confirm = $mdDialog.confirm()
      .title('Do you want to continue?')
      .targetEvent(event)
      .ok($filter('translate')('global.buttons.yes'))
      .cancel($filter('translate')('global.buttons.no'));
      $mdDialog.show(confirm).then(function() {
        vm.confirmSo();
      }, function() {});
    }

    /**
     * [uploadSOCSV Uploads CSV and converts to json data]
     * @param {Object} event 
     */
    function uploadSOCSV(event){
      var csvFile = vm.files[0];
      if (!csvFile) {
        NotificationService.simpleErrorToast({
          title: 'alertMessage.INVALID_INPUT',
          message: 'global.pleaseChooseACsvFile'
        });
        return false;
      }
      vm.isCsvFile = CsvUploadService.checkFileType(csvFile.lfFile);
      if (vm.isCsvFile) {
        UtilService.csvToJson(csvFile.lfDataUrl)
        .then(function (response) {
          vm.soDetails = response;
          if(vm.soDetails.length <1){
            NotificationService.simpleErrorToast({
              title: 'alertMessage.INVALID_INPUT',
              message: 'alertMessage.FILL_CSV'
            });
          }else{
            vm.showConfirm(event);
          }
        });
       }
    }

    /**
     * [confirmSo Confirms SO Job]
     */
    function confirmSo(){
      SatsManagementService.createSOJob(vm.files[0].lfFile)
      .then(function(response){
        vm.cancel();
        NotificationService.success({
          'title': 'SO Job Confirmation',
          'message': 'alertMessage.SUCCESSFULLY_CONFIRMED'
        });
        vm.getAllJobs();
      },function(error){
        vm.recordError = error.data;
        NotificationService.error({
          title: 'SO Job Confirmation',
          error: error
        });
      });
    }

    /**
     * [downloadCSVSOData Downloads the selected jobs in csv format]
     */
    function downloadCSVSOData() {
      var deferred = $q.defer();
      var selectedJobs = SatsManagementService.getSelectedJobs(vm.soJobList);
      if (selectedJobs.length < 1) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }else{
        selectedJobs = _.filter(selectedJobs, function(currentJob) {
          return currentJob.jobStatus === 'RELEASED' || currentJob.jobStatus === 'REJECTED';
      });
      if (selectedJobs.length < 1) {
        toastr.error('Please Select Released Jobs!', 'Validation Error');
      }
      }
      return CSVExportService.getWarehouseSOCSVData(selectedJobs);
    }

    /**
     * [getAllSupplierWithSatsAccess Gets all suppliers with sats Access]
     */
    function getAllSupplierWithSatsAccess() {
      if (vm.suppliers)
        return false;
      BuyerSupplierMappingService.getAllSupplierWithSatsAccess()
      .then(function (response) {
        vm.suppliers = response.data;
      }, function (error) {
      });
    }

    /**
   * [selectAllJobs select all Jobs listed]
   */
    function selectAllJobs() {
      vm.selectAll ? vm.selectAll = true : vm.selectAll = false;
      _.map(vm.soJobList, function (job) {
        if (vm.userRole.pretty == 'buyer') {
          if (job.jobStatus === 'PROCESSED' || job.jobStatus === 'FAILED') {
            job.selected = vm.selectAll;
          }
        } else if (vm.userRole.pretty == 'supplier') {
          if (job.jobStatus === 'RELEASED' || job.jobStatus === 'REJECTED') {
            job.selected = vm.selectAll;
          }
        }
      });
    }

    /**
     * [getSelectedJobsIds Returns selected Jobs Ids]
     */
    function getSelectedJobsIds() {
      var selectedJobs = SatsManagementService.getSelectedJobs(vm.soJobList);
      var selectedJobIds =_.map( _.uniqBy(selectedJobs, 'jobId'), _.property('jobId'));
      return selectedJobIds;
    }

    /**
     * [showUpload Shows Upload jobs for for confirmation]
     * @param {Object} event 
     */
    function showUpload(event) {
      vm.recordError = [];
      $mdDialog.show({
        templateUrl: 'app/common-modules/sats/so/confirm/sats.so.confirm.tmpl.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }
    
    /**
     * [getAllJobs Lists all SO jobs]
     */
    function getAllJobs() {
      vm.recordError = [];
      vm.soFilter.fromDate = !vm.soFilter.fromDate ? null : UTCDateService.utcFromDate(vm.soFilter.fromDate);
      vm.soFilter.toDate = !vm.soFilter.toDate ? null : UTCDateService.utcToDate(vm.soFilter.toDate);
      vm.soFilter.wave = !vm.soFilter.wave ? null : vm.soFilter.wave;
      vm.soFilter.page = vm.query.page - 1;
      vm.soFilter.size = vm.query.limit;
      vm.selectAll = false;
      SatsManagementService.getAllJobs(vm.documentType, vm.soFilter)
        .then(function (response) {
          vm.soJobList = response.data;
          vm.totalSOItems = response.headers('X-Total-Count');
          _.map(vm.soJobList, function(soJob){
            if(soJob.quantityPicked || soJob.quantityPicked == 0){
              var variance = soJob.orderQuantity - soJob.quantityPicked;
              variance === 0 ? soJob.variance = 'No': soJob.variance = 'Yes';
            }
          });
        }, function (error) {
          NotificationService.error({
            'title': 'global.sats.title.' + vm.userRole.pretty,
            'error': error
          });
        });
    }

    /**
     * [postAllJobs Posts all JObs which are selected]
     */
    function postAllJobs() {
      var selectedJobIds = vm.getSelectedJobsIds();
      if (selectedJobIds.length < 1) {
        NotificationService.simpleErrorToast({
          title: 'global.sats.jobPlan',
          message: 'alertMessage.SELECT_ITEMS_TO_CONTINUE'
        });
      } else {
        SatsManagementService.postAllJobs(selectedJobIds)
          .then(function (response) {
            NotificationService.success({
              'title': 'SO Job Plan',
              'message': 'Successfully Posted All Jobs.'
            });
            vm.getAllJobs();
          }, function (error) {
            NotificationService.error({
              'title': 'SO Job Plan',
              'error': error
            });
          });
      }
    }
    
    /**
     * [rejectAllJobs Rejects all selected Jobs]
     */
    function rejectAllJobs(){
      SatsManagementService.rejectAllJobs(vm.rejectedJobIds,{'remark':vm.rejectionReason})
        .then(function (response) {
          NotificationService.success({
            'title': 'SO Job Plan',
            'message': 'Successfully Rejected All Jobs.'
          });
          vm.getAllJobs();
          vm.rejectionReason = null;
          vm.cancel();
        }, function (error) {
          NotificationService.error({
            'title': 'SO Job Plan',
            'error': error
          });
          vm.rejectionReason = null;
          vm.cancel();
        })
    }

    /**
     * [validateJobsForRejection Validates Rejection]
     */
    function validateJobsForRejection(event) {
      vm.rejectedJobIds = vm.getSelectedJobsIds();
      if (vm.rejectedJobIds.length < 1) {
        NotificationService.simpleErrorToast({
          title: 'global.sats.jobPlan',
          message: 'alertMessage.SELECT_ITEMS_TO_CONTINUE'
        });
      } else {
        vm.addRejectionReason(event, 'BULK_JOB')
      }
    }

    /**
     * [postJob Posts the corresponding Job]
     * @param {Integer} id 
     */
    function postJob(id) {
      SatsManagementService.postJob(id)
        .then(function (response) {
          NotificationService.success({
            'title': 'SO Job Plan',
            'message': 'Job Posted Successfully.'
          });
          vm.getAllJobs();
        }, function (error) {
          NotificationService.error({
            title: 'SO Job Plan',
            error: error
          });
        });
    }

    /**
     * [rejectJob Rejects the corresponding Job]
     * @param {Integer} id 
     */
    function rejectJob() {
      SatsManagementService.rejectJob(vm.rejectedJobId,  {'remark': vm.rejectionReason})
        .then(function (response) {
          NotificationService.success({
            'title': 'SO Job Plan',
            'message': 'Job Rejected Successfully.'
          });
          vm.getAllJobs();
          vm.rejectionReason = null;
          vm.cancel();
        }, function (error) {
          NotificationService.error({
            title: 'SO Job Plan',
            error: error
          });
          vm.rejectionReason = null;
          vm.cancel();
        });
    }

    /**
    * [loadAll Gets Purchase Order Details]
    */
    function loadAll() {
      var isValidDateRange = UtilService.isValidDateRange(vm.soFilter.fromDate, vm.soFilter.toDate);
      if(!isValidDateRange){
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SELECT_VALID_DATE_RANGE'
        });
        return false;
      }
      vm.getAllJobs();
    }
    
    /**
     * [reset reset so filter]
     */
    function reset(){
      vm.soFilter.fromDate = UTCDateService.utcFromDate(vm.todaysDate);
      vm.soFilter.toDate = UTCDateService.utcToDate(vm.todaysDate);
      vm.soFilter.wave = "";
      vm.soFilter.warehouse = "";
      vm.soFilter.status = "";
      vm.soFilter.variance = "";
      vm.getAllJobs();
    }
    
      /**
       * [deleteJob Deletes specific line item]
       * @param {*} jobDocId 
       */
      function deleteJob(jobDocId) {
        SatsManagementService.deleteJob(jobDocId).then(function(response) {
          NotificationService.success({
            title: 'SO Job',
            message: 'alertMessage.DELETED_SUCCESSFULLY'
          });
          vm.loadAll();
        }, function(error) {
          NotificationService.error({
            title: 'Job Deletion',
            error: error
          });
        });
      }

      /**
       * [confirmDelete Confirmation box for deletion]
       * @param {*} ev 
       * @param {*} soJob 
       */
      function confirmDelete (ev, soJob) {
        if(soJob.status==='POSTED'){
          NotificationService.simpleErrorToast({
            title: 'alertMessage.ERR_VALIDATION',
            message: 'error.CAN_NOT_DELETE_POSTED_JOBS'
          });
          return false;
        }
        var confirm = $mdDialog.confirm()
        .title('Are you sure you want to delete the item - ' + soJob.jobDocumentNumber + ' ? ')
        .textContent('This action could not be reverted back.')
        .targetEvent(ev)
        .ok('Delete')
        .cancel('Cancel');
  
        $mdDialog.show(confirm).then(function() {
          vm.deleteJob(soJob.id);
        }, function() {});
      }
  
      function cancel () {
        $mdDialog.cancel();
      }

    vm.loadAll();
  }
})();
