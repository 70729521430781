/*
 * @Author: Ajay 
 * @Date: 2019-02-12 08:24:47 
 * @Project: Ezyprocure
 * @Filename: ezyap.peppol-invoices-summary.details.controller.js
 * @Last Modified by: Lovin
 * @Last Modified time: 2020-01-29 16:27:02
 */

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('EzyapInvoicesDetailsController', EzyapInvoicesDetailsController);

  EzyapInvoicesDetailsController.$inject = ['NotificationService', '$stateParams', 'ExtenalPeppolInvoicesService', 'EzyapGetInvoicesService', 'PDFExportService', '$filter', 'Auth'];

  function EzyapInvoicesDetailsController(NotificationService, $stateParams, ExtenalPeppolInvoicesService, EzyapGetInvoicesService, PDFExportService, $filter, Auth) {

    var vm = this;
    vm.invoiceId = $stateParams.id;
    vm.init = init;
    vm.getInvoiceDetailsById = getInvoiceDetailsById;
    vm.sendInvoiceById = sendInvoiceById;
    vm.downloadURI = downloadURI;
    vm.userRole = Auth.getUserRole();
    vm.updateExportFlag = updateExportFlag;
    vm.getInvoiceSummaryPDF = getInvoiceSummaryPDF;
    vm.getUomFromItem = getUomFromItem;
    
    vm.init();
    function init() {
      vm.getInvoiceDetailsById();
    }

    /**
     * [get individual invoice details by invoice id]
     */
    function getInvoiceDetailsById() {
      EzyapGetInvoicesService.getInvoiceDetailsById(vm.invoiceId).
        then(function (response) {
          vm.invoiceDetails = response.data;
          vm.invoiceDetails.invoice.invoiceDueDate = $filter('date')(vm.invoiceDetails.invoice.invoiceDueDate, "dd/MM/yyyy", 'UTC');
          vm.invoiceDetails.invoice.invoiceIssueDate = $filter('date')(vm.invoiceDetails.invoice.invoiceIssueDate, "dd/MM/yyyy", 'UTC');
        }, function (error) {
          NotificationService.error({
            title: '',
            error: error
          });
        });
    }

    function getUomFromItem(item) {
      return !!item.uom ? item.uom : (!!item.unitCode ? item.unitCode.name : null);
    }

    /**
     * [send invoice by invoice id]
     */
    function sendInvoiceById() {
      ExtenalPeppolInvoicesService.sendInvoiceById(vm.invoiceId).
        then(function (response) {
          vm.getInvoiceDetailsById();
          NotificationService.success({
            title: 'global.sendInvoice',
            message: 'alertMessage.INVOICE_SEND_SUCCESSFULLY'
          })
        }, function (error) {
          NotificationService.error({
            title: 'global.sendInvoice',
            error: error
          });
        });
    }

    function getInvoiceSummaryPDF() {
      PDFExportService.getInvoiceSummaryPDF(vm.invoiceId).then(function(response){
        vm.downloadURI(response.data.message, "PDF_" + vm.invoiceId);
      },function(error) {
        NotificationService.error({
          title: 'Individual PDF Export',
          error: error
        });
      });
    }

    /**
     * [updateExportFlag Updates the export flag]
     * @param {Id} invoiceId 
     */
    function updateExportFlag(invoiceId){
      ExtenalPeppolInvoicesService.updateExportFlag(invoiceId)
        .then(function(response){
        },function(error){
      });
    }

    /**
     * 
     * @param {String} uri [uploaded url]
     * @param {String} fileName [the name of the file downloaded file]
     */
    function downloadURI(uri, fileName) {
      updateExportFlag([vm.invoiceId]);
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = fileName;
      window.open(uri,'_blank');
    }
  }
})();
