/**
 * @Author: sneha
 * @Date:   14-Mar-2018 11:40 +05:30
 * @Project: Ezyprocure
 * @Filename: supplier.picking-list-management.state.js
 * @Last modified by:   sneha
 * @Last modified time: 11-May-2018 13:06 +05:30
 */



 (function() {
   'use strict';

   angular
   .module('ezyprocureApp')
   .config(stateConfig);

   stateConfig.$inject = ['$stateProvider'];

   function stateConfig($stateProvider) {
     $stateProvider.state('supplier.picking-list-management', {
       abstract: true,
       parent: 'supplier',
       url: '/picking-list-management',
       resolve: {
         translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
           return $translate.refresh();
         }],
         hasModuleAccess: ['Auth','$q', '$state', function(Auth, $q, $state) {
           if(Auth.hasPermission('accessForPickingList')){
             return true;
           }else {
             return $q.reject('Unauthorized');
           }
         }]
       }
     });
   }
 })();
