/**
 * @Author: Elsa
 * @Date:   08-Mar-2017 10:32 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.receiving-orders.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 22-Mar-2018 16:03 +05:30
 */



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BuyerReceivingOrderService', BuyerReceivingOrderService);

  BuyerReceivingOrderService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  function BuyerReceivingOrderService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var buyerReceivingOrderService = {
      getGoodReceiptNotes : getGoodReceiptNotes,
      getGRNItemDetails : getGRNItemDetails,
      getGRNItemAndBatchDetails: getGRNItemAndBatchDetails,
      checkEzyinventoryAccess: checkEzyinventoryAccess,
      processGoodReceive : processGoodReceive,
      mergeGrnWithSamePo: mergeGrnWithSamePo,
      getInventoryBins : getInventoryBins,
      getInventoryWarehouse: getInventoryWarehouse,
      supplierReceiveNotify: supplierReceiveNotify,
      resendEmailAdhoc:resendEmailAdhoc
    };
    return buyerReceivingOrderService;

    /**
     * [getGoodReceiptNotes gets th e Good Receipt Notes]
     * @param  {[string]} params [search text]
     * @return {[object]}        [Good Receipt Notes]
     */
    function getGoodReceiptNotes(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE + 'buyer-receiving-orders/PENDING',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getGRNItemDetails gets GRN Details by PO Number]
     * @return {[object]}          [GRN Details]
     */
    function getGRNItemDetails(poNumber,params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE + 'view/'+poNumber,
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * new grn details for ezyinventory integration
     * @param {string} poNumber
     * @param {object} params
     */
    function getGRNItemAndBatchDetails(poNumber,params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE + 'grn-view/'+poNumber,
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * @description check for Ezyinventory access
     */
    function checkEzyinventoryAccess() {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'user-access/buyer/admin').then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [processGoodReceive Save Good receiving Orders]
     * @return {[string]}                [Success and error messages]
     */
    function processGoodReceive(goodReceiveDTO) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE + 'good-receipt-status', goodReceiveDTO).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [processGoodReceive Save Good receiving Orders]
     * @return {[string]}                [Success and error messages]
     */
    function mergeGrnWithSamePo(grnList) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE + 'good-receipt-notes/merge', grnList).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /*
    *  get the bins from ezyinventory
    */

    function getInventoryBins(){
        var deferred = $q.defer();
        $http({
            url: API_CONSTANTS.ezyinventoryAccessUrl + 'api/' +'bin',
            method: "GET",
         }).then(function(data) {
            deferred.resolve(data);
            }).catch(function(message) {
            deferred.reject(message);
            });
            return deferred.promise;
    }

    /*
    * get the warehouse from ezyinventory
    */

    function getInventoryWarehouse(){
        var deferred = $q.defer();
        $http({
            url: API_CONSTANTS.ezyinventoryAccessUrl + 'api/' + 'all-warehouses',
            method: "GET",
        }).then(function(data) {
        deferred.resolve(data);
        }).catch(function(message) {
        deferred.reject(message);
        });
        return deferred.promise;
    }

    function supplierReceiveNotify(params, id){
      var deferred = $q.defer();
      $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers/received-notify/' + id,
          method: "PUT",
          params: params
      }).then(function(data) {
      deferred.resolve(data);
      }).catch(function(message) {
      deferred.reject(message);
      });
      return deferred.promise;
  }

  function resendEmailAdhoc(purchaseOrderNumber){
    var deferred = $q.defer();
    $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE + 'adhoc/resend-email?purchaseOrderNumber='+purchaseOrderNumber).then(function(data) {
      deferred.resolve(data);
    }).catch(function(message) {
      deferred.reject(message);
    });
    return deferred.promise;
}


  }
})();
