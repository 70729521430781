/**
 * @Author: Caroline
 * @Date:   07-Apr-2020 13:30 +05:30
 * @Project: Ezyprocure
 * @Filename: ezypayment.supplier.upload-payment.controller.js
 * @Last modified by:   Caroline
 * @Last modified time: 07-Apr-2020 13:30 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzypaymentSupplierPaymentUploadController', EzypaymentSupplierPaymentUploadController);

  EzypaymentSupplierPaymentUploadController.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'toastr', '$mdDialog', 'BuyerUploadPaymentService', 'CsvUploadService', 'NotificationService', 'UtilService', 'S3_URL_CONSTANTS', 'GLOBAL_CONSTANTS'];

  function EzypaymentSupplierPaymentUploadController($scope, $rootScope, $state, $timeout, toastr, $mdDialog, BuyerUploadPaymentService, CsvUploadService, NotificationService, UtilService, S3_URL_CONSTANTS, GLOBAL_CONSTANTS){
    var vm = this;
    vm.creditType = false;
    vm.redirectUrl = redirectUrl;
    vm.getCSVBase64 = getCSVBase64;
    vm.paymentUploadCSV = paymentUploadCSV;
    vm.previewPaymentUpload=previewPaymentUpload;
    vm.clearCSVUpload=clearCSVUpload;
    vm.PAYMENT_UPLOAD_CSV = S3_URL_CONSTANTS.PAYMENT_UPLOAD_CSV;
    vm.SUPPLIER_EXTERNAL_PAYMENT_UPLOAD_CSV=S3_URL_CONSTANTS.SUPPLIER_EXTERNAL_PAYMENT_UPLOAD_CSV;
    vm.SUPPLIER_EXTERNAL_PAYMENT_WITH_CREDIT_NOTE_UPLOAD_CSV=S3_URL_CONSTANTS.SUPPLIER_EXTERNAL_PAYMENT_WITH_CREDIT_NOTE_UPLOAD_CSV;
    vm.loginType = 'Supplier';
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.isUploadSuccess = false;

    function getCSVBase64() {
      var csvFile = vm.files[0];
      vm.recordError= {};
      if (!csvFile) {
        toastr.error('Please upload a .csv file!', 'CSV File Required');
        return false;
      }
      if(!validatePaymentDatahCr()) {
        toastr.error('Credit note amount not valid', 'Payment File Error');
      }
      if(!validatePaymentDataIn()) {
        toastr.error('Invoice amount not valid', 'Payment File Error');
      }
      if(!validatePaymentDataIn() || !validatePaymentDatahCr()) {
        return;
      }
      CsvUploadService.getFileBase64(csvFile.lfFile, function(base64CSV) {
        vm.csvUploadData = {
          encodedCsvFile: base64CSV
        };
        vm.paymentUploadCSV(vm.csvUploadData);
      });
    }

    function validatePaymentDatahCr() {
      var isValid = true;
      if(vm.creditType) {
        vm.paymentUploadItems.forEach(function(data) {
          if(isValid && data.CreditNoteAmount) {
            isValid = +data.CreditNoteAmount > 0;
          }
        });
      }
      return isValid;
    }

    function validatePaymentDataIn() {
        var isValid = true;
        if(vm.creditType) {
          vm.paymentUploadItems.forEach(function(data) {
            if(isValid && data.InvoiceAmount) {
              isValid = +data.InvoiceAmount > 0;
            }
          });
        }
        return isValid;
      }

    function paymentUploadCSV(uploadData) {
      BuyerUploadPaymentService.paymentUploadCSV(uploadData, { loginType: vm.loginType, isWithCreditNote: vm.creditType }).then(function (response) {
        clearCSVUpload();
        vm.isUploadSuccess = true;
        NotificationService.success({
          title: 'Payment File Uploaded successfully',
          message: response.message
        });
      }, function (error) {
        vm.recordError = error.data;
        NotificationService.error({
          title: 'Payment File Upload',
          error: error
        });
      });
    }

    function redirectUrl() {
        localStorage.setItem("type",vm.creditType ? 'EXTERNAL_SIP_WITH_CREDIT_NOTE': "INTERNAL");
        $state.go('ezypayment.supplier.initiate-payment');
    }
    /**
     * [previewPaymentUpload Preview of Payment Upload using CSV]
     */
    function previewPaymentUpload() {
      var csvFile = vm.files[0];
      vm.isCsvFile = CsvUploadService.checkFileType(csvFile.lfFile);
      if (vm.isCsvFile) {
        UtilService.csvToJson(csvFile.lfDataUrl)
          .then(function (response) {
            vm.paymentUploadItems = response;
            if (Array.isArray(vm.paymentUploadItems) && vm.paymentUploadItems.length) {
              vm.creditType = (vm.paymentUploadItems[0]).hasOwnProperty('CreditNoteAmount');
            }
            vm.paymentUploadItems = _.sortBy( vm.paymentUploadItems, 'BrandName' );
          })
      }
    }

    /**
     * [clearCSVUpload resets the file upload and the preview table
     */
    function clearCSVUpload(){
      vm.files=[];
      vm.paymentUploadItems=[];
      vm.recordError=null;
    }

  }

})();
