/**
 * @Author: Fenn
 * @Date:   05-Mar-2020 17:02 +05:30
 * @Project: Ezyprocure
 * @Filename: supplier.myplans.state.js
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('supplier.subscription-plans', {
      parent: 'supplier',
      url: '/my-plans',
      data: {
        authorities: ['ROLE_SUPPLIER']
      },
      views: {
        'content@': {
          templateUrl: 'app/common-modules/plans/my-plans/my-plans.html',
          controller: 'MySubscriptionPlansController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', 
        function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('plan');
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    });
  }
})();
