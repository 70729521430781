/*
 * @Author: Ajay 
 * @Date: 2019-02-11 11:38:11 
 * @Project: Ezyprocure
 * @Filename: ezyap.buyer-information.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-10-15 17:50:57
 */

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('EzyapManageBuyerController', EzyapManageBuyerController);

  EzyapManageBuyerController.$inject = ['NotificationService', 'ManageBuyerInfoService', 'paginationConstants', '$mdDialog', '$filter', 'pagingParams', 'Auth'];

  function EzyapManageBuyerController(NotificationService, ManageBuyerInfoService, paginationConstants, $mdDialog, $filter, pagingParams, Auth) {

    var vm = this;
    vm.init = init;
    vm.getBuyerInformation = getBuyerInformation;
    vm.limitOptions = paginationConstants.limitOptions;
    vm.showDeleteConfirmationModal = showDeleteConfirmationModal;
    vm.deleteBuyerInformationById = deleteBuyerInformationById;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.userRole = Auth.getUserRole();
    
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function init() {
      vm.getBuyerInformation();
    }

    /**
     * [get Buyer Information]
     */
    function getBuyerInformation() {
      ManageBuyerInfoService.getBuyerInformation({ 
        query: vm.searchText ? vm.searchText : "",
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort() }).
        then(function (response) {
          vm.buyerDetails = response.data;
          vm.totalItems = response.headers('X-Total-Count');
        }, function (error) {
          NotificationService.error({
            title: 'global.customerInformation',
            error: error
          });
        });
    }

    /**
     * [delete the buyer information of the given id]
     * @param {String} buyerId [id of the buyer]
     */
    function deleteBuyerInformationById(buyerId) {
      ManageBuyerInfoService.deleteBuyerInformationById(buyerId).
        then(function (responseData) {
          vm.getBuyerInformation();
          NotificationService.success({
            title: 'global.customerInformation',
            message: 'alertMessage.DELETED_SUCCESSFULLY'
          });
        }, function (error) {
          NotificationService.error({
            title: '',
            error: error
          });
        });
    }

    /**
     * [show confirmaton modal to delete buyer information]
     * @param {Integer} buyerinfoId [buyer information id]
     */
    function showDeleteConfirmationModal(buyerinfoId) {
      var confirm = $mdDialog.confirm()
        .title('Are you sure you want to delete the buyer details?')
        .targetEvent(event)
        .ok($filter('translate')('global.buttons.proceed'))
        .cancel($filter('translate')('global.buttons.cancel'));
      $mdDialog.show(confirm).then(function () {
        vm.deleteBuyerInformationById(buyerinfoId);
      }, function () { });
    }
  }
})();
