/**
* @Author: sneha
* @Date:   2019-01-24T17:23:21+05:30
* @Project: Ezyprocure
* @Filename: sftp-details.edit.controller.js
 * @Last modified by:   Jayalekshmi
 * @Last modified time: 2019-02-22T08:43:32+05:30
*/

(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SFTPDetailsEditController', SFTPDetailsEditController);

  SFTPDetailsEditController.$inject = ['$scope', '$state', 'BuyerDataService', 'Auth', '$stateParams', 'paginationConstants', 'NotificationService', 'SupplierDataService', 'BuyerSupplierMappingService', 'SupplierSFTPDetailsService', 'GLOBAL_CONSTANTS'];

  function SFTPDetailsEditController($scope, $state, BuyerDataService, Auth, $stateParams, paginationConstants, NotificationService, SupplierDataService, BuyerSupplierMappingService, SupplierSFTPDetailsService, GLOBAL_CONSTANTS) {
    var vm = this;
    vm.init = init;
    vm.userRole = Auth.getUserRole();
    vm.supplierId = vm.userRole.pretty === 'supplier'? SupplierDataService.getSupplierProfile().supplier.id : null;
    vm.buyerId = vm.userRole.pretty === 'buyer'? BuyerDataService.getBuyerProfile().buyerUser.buyer.id: null;
    vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
    vm.sftpId = $stateParams.sftpId;
    vm.getSFTPDetailsById = getSFTPDetailsById;
    vm.editSFTPDetails = editSFTPDetails;
    vm.fileTypes = GLOBAL_CONSTANTS.FILE_TYPES;
    vm.module=$stateParams.module;
    vm.redirectToHome=redirectToHome;
    vm.redirectToSftpDetails=redirectToSftpDetails;
    vm.getAllBuyerMappedSuppliers = getAllBuyerMappedSuppliers;    

    function init(){
      if(vm.userRole.pretty == 'supplier'){
        vm.getAllSupplierMappedBuyers();
      }else if(vm.userRole.pretty == 'buyer'){
        vm.getAllBuyerMappedSuppliers();
      }
      vm.getSFTPDetailsById();
    }

    /**
      * [getAllBuyerMappedSuppliers get all suppliers mapped to the logged in buyer]
      * @return {[type]} [description]
      */
     function getAllBuyerMappedSuppliers(){
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
      };
      if(vm.suppliers){
        return false;
      }
      BuyerSupplierMappingService.getAllBuyerMappedSuppliers(vm.buyerId, params).then(function(response) {
        vm.suppliers = response.data;
      }, function(error) {
      });
    }

    /**
    * [getSFTPDetailsById get SFTP Details by id]
    * @return {[type]} [description]
    */
    function getSFTPDetailsById(){
      if(vm.buyers){
        return false;
      }
      SupplierSFTPDetailsService.getSFTPDetailsById(vm.sftpId).then(function(response) {
        vm.sftpDetails = response.data;
        vm.confirmPassword = vm.sftpDetails.password
      }, function(error) {
        NotificationService.error({
          title: 'global.navbar.SFTPDetails',
          error: error
        });
      });
    }

    /**
    * [getAllSupplierMappedBuyers get all buyers mapped to logged in supplier]
    * @return {[type]} [description]
    */
    function getAllSupplierMappedBuyers(){
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
      };
      if(vm.buyers)
      return false;
      BuyerSupplierMappingService.getAllSupplierMappedBuyers(vm.supplierId, params).then(function(response) {
        vm.buyers = response.data;
      }, function(error) {
      });
    }

    /**
    * [editSFTPDetails edit sftp details]
    * @return {[type]} [description]
    */
    function editSFTPDetails(){
      if(vm.sftpDetails.password !== vm.confirmPassword){
        NotificationService.simpleErrorToast({
          title: 'global.navbar.SFTPDetails',
          message: 'alertMessage.PASSWORDS_DOES_NOT_MATCH'
        });
      }
      else{
        vm.sftpDetails.accountType = vm.userRole.pretty.toUpperCase();
        SupplierSFTPDetailsService.editSFTPDetails(vm.sftpDetails).then(function(response) {
          vm.sftpDetails = response.data;
          vm.module=='PEPPOL'? $state.go('ezyap.supplier.sftp-details', {
            reload: false
          }):
          $state.go(vm.userRole.pretty + '.tools.sftp-details', {
            reload: false
          });
        }, function(error) {
          NotificationService.error({
            title: 'global.navbar.SFTPDetails',
            error: error
          });
        });
      }
    }
    
    /**
     * [redirectToHome Redirects to home]
     */
    function redirectToHome(){
      switch(vm.module){
        case 'PEPPOL':
          $state.go('ezyap.'+ vm.userRole.pretty +'.dashboard');
          break;
        case 'EZYPROCURE':
          $state.go(vm.userRole.pretty + '.tools.dashboard');
          break;      
      }
    }

    /**
     * [redirectToSftpDetails Redirects to corresponding ]
     */
    function redirectToSftpDetails(){
    switch(vm.module){
      case 'PEPPOL':
        $state.go('ezyap.'+ vm.userRole.pretty +'.sftp-details');
        break;
      case 'EZYPROCURE':
        $state.go(vm.userRole.pretty + '.tools.sftp-details');      
        break;      
    }
  }
  }
})();
