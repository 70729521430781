/**
* @Author: Abhay
* @Date:   27-Aug-2020 11:47 IST
* @Project: Ezyprocure
* @Filename: ezypayment.admin-ntp-actions.controller.js
* @Last modified by:   Abhay
* @Last modified time: 27-Aug-2020 11:47 IST
*/

(function () {
    'use strict';

    angular.module('ezyprocureApp').controller('EzypaymentAdminNTPActions', EzypaymentAdminNTPActions);

    EzypaymentAdminNTPActions.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', '$mdDialog', 'toastr', 'pagingParams', 'UTCDateService', 'NotificationService', 'GLOBAL_CONSTANTS', 'paginationConstants', '$window', 'EzypaymentAdminToolsCompanyListService', 'EXPORT_CONSTANTS', '$stateParams'];

    function EzypaymentAdminNTPActions($scope, $rootScope, $q, $filter, $state, $mdDialog, toastr, pagingParams, UTCDateService, NotificationService, GLOBAL_CONSTANTS, paginationConstants, $window, EzypaymentAdminToolsCompanyListService, EXPORT_CONSTANTS, $stateParams) {
        var vm = this;

        // ntp actions
        vm.actions = {
            action: 'READ',
            selectedCompanyObjectId: undefined,
            selectedFolderId: undefined,
            selectedCompanyList: []
        }
        vm.allCompanyList = [];
        vm.folderList = [];
        vm.ntpCompanyList = [];
        vm.companySearchKey = {
            companyName: ''
        };
        vm.companySearchTerm = {
            companyName: ''
        }
        vm.paymentResponse = {};

        // ntp actions
        vm.getAllCompanies = getAllCompanies;
        vm.selectAllCompanies = selectAllCompanies;
        vm.deselectAllCompanies = deselectAllCompanies;
        vm.searchByCompany = searchByCompany;
        vm.getFoldersById = getFoldersById;
        vm.triggerPayment = triggerPayment;
        vm.convertObjectToArray = convertObjectToArray;
        vm.uploadFile = uploadFile;
        vm.actionChange = actionChange;
        vm.getFoldersForView = getFoldersForView;
        vm.closeModal = closeModal;
        vm.showPaymentResponseModal = showPaymentResponseModal;
        
        // modal close
        function closeModal() {
          $mdDialog.hide();
        }

        // show payment response modal
        function showPaymentResponseModal(responseObject) {
            vm.paymentResponse = responseObject && Object.keys(responseObject).length ? responseObject: undefined;
            $mdDialog.show({
                templateUrl: 'app/ezypayment-modules/admin-modules/tools/ntp-actions/ezypayment.admin-ntp-actions.response.tmpl.html',
                controller: function () {
                    return vm;
                },
                controllerAs: 'vm',
                targetEvent: event,
                clickOutsideToClose: false
            });
        }

        function actionChange() {
            vm.actions.selectedCompanyObjectId = undefined;
            vm.actions.selectedFolderId = undefined;
            vm.actions.selectedCompanyList = [];
            vm.ntpCompanyList = [];
            vm.companySearchKey.companyName = '';
            vm.companySearchTerm.companyName = '';
        }

        function selectAllCompanies() {
            var companyNameList = [];
            vm.allCompanyList.forEach(function (company) {
                companyNameList.push(company.companyName);
            });
            vm.actions.selectedCompanyList = companyNameList;
        }

        function deselectAllCompanies() {
            vm.actions.selectedCompanyList = [];
        }

        function searchByCompany() {
            vm.ntpCompanyList = [];
            if (vm.actions.action == 'READ') {
                if (vm.actions.selectedCompanyList.length > 0) {
                    vm.actions.selectedCompanyList.forEach(function (companyName) {
                        var selectedCompany = vm.allCompanyList.find(function (company) {
                            return company.companyName == companyName;
                        });
                        vm.ntpCompanyList.push(selectedCompany);
                    });
                }
            }
            else if (vm.actions.action == 'VIEW') {
                getFoldersForView();
            }
        }

        function triggerPayment() {
            var companyMap = createCompanyMap();
            EzypaymentAdminToolsCompanyListService.triggerPayment(companyMap).then(function (response) {
                toastr.success('Payment triggered', 'NTP Company List');
                showPaymentResponseModal(response.data);
                resetList();
            }, function (error) {
                NotificationService.error({
                    title: 'NTP Company List',
                    error: error
                });
            });
        }

        function createCompanyMap() {
            var companyMap = {};
            vm.ntpCompanyList.forEach(function (company) {
                companyMap[company.objectId] = company.companyName;
            });
            return companyMap;
        }

        function resetList() {
            vm.ntpCompanyList = [];
        }

        function convertObjectToArray(companyMap) {
            // resetList();
            vm.allCompanyList = [];
            if (companyMap && Object.keys(companyMap).length > 0) {
                for (var objectId in companyMap) {
                    var companyObject = {
                        "objectId": objectId,
                        "companyName": companyMap[objectId],
                        "isSelected": false
                    }
                    vm.allCompanyList.push(companyObject);
                }
            }
        }

        function getAllCompanies() {
            EzypaymentAdminToolsCompanyListService.fetchNTPCompanyList().then(function (response) {
                var companyMap = response.data;
                vm.convertObjectToArray(companyMap);
            }, function (error) {
                NotificationService.error({
                    title: 'NTP Company List',
                    error: error
                });
            });
        }

        function getFoldersById() {
            EzypaymentAdminToolsCompanyListService.fetchFolderListByObjectId(vm.actions.selectedCompanyObjectId).then(function (response) {
                var folderMap = response.data;
                convertFolderMapToList(folderMap);
            }, function (error) {
                NotificationService.error({
                    title: 'NTP Folder List',
                    error: error
                });
            });
        }

        function getFoldersForView() {
            EzypaymentAdminToolsCompanyListService.fetchFolderListByObjectId(vm.actions.selectedFolderId).then(function (response) {
                var folderMap = response.data;
                convertFolderMapToCompanyList(folderMap);
            }, function (error) {
                NotificationService.error({
                    title: 'NTP Folder List',
                    error: error
                });
            });
        }

        function convertFolderMapToCompanyList(folderMap) {
            vm.ntpCompanyList = [];
            if (folderMap && Object.keys(folderMap).length > 0) {
                for (var objectId in folderMap) {
                    var folderObject = {
                        "objectId": objectId,
                        "companyName": folderMap[objectId],
                    }
                    vm.ntpCompanyList.push(folderObject);
                }
            }
        }

        function convertFolderMapToList(folderMap) {
            vm.folderList = [];
            if (folderMap && Object.keys(folderMap).length > 0) {
                for (var objectId in folderMap) {
                    var folderObject = {
                        "folderId": objectId,
                        "folderName": folderMap[objectId],
                    }
                    vm.folderList.push(folderObject);
                }
            }
        }

        function uploadFile(files) {
            var formData = new FormData();
            formData.append('file', files[0].lfFile);
            formData.append('objectId', vm.actions.selectedFolderId);
            EzypaymentAdminToolsCompanyListService.uploadNTPFile(formData).then(function (response) {
                vm.actions.selectedCompanyObjectId = undefined;
                vm.actions.selectedFolderId = undefined;
                vm.files = [];
                toastr.success('Successfully completed', 'NTP File Upload')
            }, function (error) {
                NotificationService.error({
                    title: 'NTP File Upload',
                    error: error
                });
            });
        }

    }
}
)();
