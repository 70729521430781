/**
* @Author: Lovin
* @Date:   11-October-2017 15:12 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.admin.account-details.service.js
 * @Last modified by: lovin
 * @Last modified time: 01-nov-2019 10:26 +05:30
*/



(function() {
    'use strict';
  
    angular.module('ezyprocureApp').factory('EzypaymentAccountDetailsService', EzypaymentAccountDetailsService);
  
    EzypaymentAccountDetailsService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
    function EzypaymentAccountDetailsService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {
  
      var EzypaymentAccountDetailsService = {
        getAccountDetailsById: getAccountDetailsById,
        createAccountDetail: createAccountDetail,
        getAllAccountDetail : getAllAccountDetail,
        updateAccountDetail : updateAccountDetail,
        deleteAccountDetail: deleteAccountDetail
      };
  
      return EzypaymentAccountDetailsService;

      /**
       * [deleteAccountDetail Deletes account details]
       */
      function deleteAccountDetail(id){
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'payment-terms/'+ id,
          method: "PUT",
        }).then(function(response) {
          deferred.resolve(response);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
  
      /**
       * [createAccountDetail Creates multiple account details]
       * @param {Object} paymentTermsDTO 
       */
      function createAccountDetail(paymentTermsDTO){
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'payment-terms-multi-brands/',
          method: "POST",
          data: paymentTermsDTO
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
  
      /**
       * [updateAccountDetail updates account details]
       * @param {Object} paymentTerms 
       */
      function updateAccountDetail(paymentTerms){
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'payment-terms',
          method: "PUT",
          data: paymentTerms
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
  
      /**
       * [getAllAccountDetail getAlls account details]
       */
      function getAllAccountDetail(queryParams){
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion  +'payment-active-terms/',
          method: "GET",
          params: queryParams
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
      /**
       * [getAccountDetails getAlls account details by id]
       */
      
      function getAccountDetailsById(id) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'payment-terms/'+ id,
          method: "GET",
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
  
    }
  })();
  