/*
 * @Author: Jayalekshmi 
 * @Date: 2018-11-14 13:08:10 
 * @Project: Ezyprocure
 * @Filename: buyer.adhoc-supplier-buyer-mapping.service.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2018-11-28 17:28:01
 */



(function() {
    'use strict';
  
    angular.module('ezyprocureApp').factory('BuyerAdhocSupplierMappingService', BuyerAdhocSupplierMappingService);
  
    BuyerAdhocSupplierMappingService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  
    function BuyerAdhocSupplierMappingService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {
  
      var buyerAdhocSupplierMappingService = {
        getAllAdhocSupplierBuyerMappings: getAllAdhocSupplierBuyerMappings,
        getAdhocSupplierBuyerMappingById: getAdhocSupplierBuyerMappingById,
        isMapped: isMapped,
        createAdhocSupplierBuyerMapping: createAdhocSupplierBuyerMapping,
        deleteAdhocSupplierBuyerMapping: deleteAdhocSupplierBuyerMapping,
        updateAdhocSupplierBuyerMapping: updateAdhocSupplierBuyerMapping
      };
  
      return buyerAdhocSupplierMappingService;
  
      /* --  Adhoc supplier Buyer Map CRUD operations  -- */

      /**
       * [createAdhocSupplierBuyerMapping Create a new buyer-Adhoc supplier mapping]
       * @param  {[Object]} mapping [mappingObj]
       * @return {[Object]}         [Success or failure response]
       */
      function createAdhocSupplierBuyerMapping(mapping) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYER_SUPPLIER_MAPPINGS_RESOURCE + 'adhoc',
          method: "POST",
          params:mapping
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
  
      /**
       * [getAdhocSupplierBuyerMappingById gets Mapping by Id] 
       */
      function getAdhocSupplierBuyerMappingById(mappingId){
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYER_SUPPLIER_MAPPINGS_RESOURCE +mappingId,
          method: "GET",
        }).then(function(data){
          deferred.resolve(data);
        }).catch(function(message){
          deferred.reject(message);
        });
        return deferred.promise;
      }

      /**
       * [getAllAdhocSupplierBuyerMappings Buyer Adhoc SupplierMappings]
       * @return {[List]}         [BuyerSupplierMappings]
       */
      function getAllAdhocSupplierBuyerMappings(urlParams) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYER_SUPPLIER_MAPPINGS_RESOURCE + 'adhoc',
          method: "GET",
          params: urlParams,
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
  
      /**
       * [isMapped Check Buyer and Adhoc Supplier is Mapped or not]
       * @param  {[Object]}  mapping [mappingObj]
       * @return {Boolean}         [Check Buyer and Supplier is Mapped or not]
       */
      function isMapped(mapping) {
        var deferred = $q.defer();
        $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYER_SUPPLIER_MAPPINGS_RESOURCE + 'is-adhoc-mapped', {params: mapping}).then(function(data) {
          deferred.resolve(data.data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
  
      /**
       * [deleteAdhocSupplierBuyerMapping Soft Delete existing buyer-Adhoc supplier mapping]
       * @param  {[Object]} mapping [mappingObj]
       * @return {[Object]}         [Success or failure response]
       */
      function deleteAdhocSupplierBuyerMapping(mapping) {
        var deferred = $q.defer();
        $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYER_SUPPLIER_MAPPINGS_RESOURCE + 'adhoc', {params: mapping}).then(function(data) {
          deferred.resolve(data.data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
  
      /**
       * [updateAdhocSupplierBuyerMapping updates teh details in the mapping]
       * @param {[Object]} mapping 
       */
      function updateAdhocSupplierBuyerMapping(mapping) {
        var deferred = $q.defer();
        $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYER_SUPPLIER_MAPPINGS_RESOURCE +'update', mapping).then(function(data) {
          deferred.resolve(data.data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
    }
  })();
  