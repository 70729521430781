/*
 * @Author:Ajay
 * @Date: 2019-01-31 10:18:02
 * @Project: Ezyprocure
 * @Filename: ezyap.add-buyer-information.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2020-01-28 16:09:49
 */

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('EzyapAddBuyerInfoController', EzyapAddBuyerInfoController);

  EzyapAddBuyerInfoController.$inject = ['$rootScope', '$state', 'NotificationService', 'ManageBuyerInfoService', 'GLOBAL_CONSTANTS', 'EZYAP_CONSTANTS', 'Auth', '$q',  '$filter'];

  function EzyapAddBuyerInfoController($rootScope, $state, NotificationService, ManageBuyerInfoService, GLOBAL_CONSTANTS, EZYAP_CONSTANTS, Auth, $q,  $filter) {

    var vm = this;
    vm.addBuyerInformation = addBuyerInformation;
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.countryCodes = EZYAP_CONSTANTS.ezyap.countryCodes;
    vm.userRole = Auth.getUserRole();
    vm.setCustomerInformation = setCustomerInformation;
    vm.searchForPeppolUsers = searchForPeppolUsers;

   /**
     * [add buyer information]
     */
    function addBuyerInformation() {
      ManageBuyerInfoService.addBuyerInformation({
        addressAdditionalStreetName: vm.buyerDetails.additionalStreetName?vm.buyerDetails.additionalStreetName:'',
        addressCityName: vm.buyerDetails.cityName?vm.buyerDetails.cityName:'',
        addressCompanyId: vm.buyerDetails.companyId?vm.buyerDetails.companyId:'',
        addressContactEmail: vm.buyerDetails.email?vm.buyerDetails.email:'',
        addressContactTelephone: vm.buyerDetails.contactNumber?vm.buyerDetails.contactNumber:'',
        addressContactName: vm.buyerDetails.name?vm.buyerDetails.name:'',
        addressCountryCode: vm.buyerDetails.countryCode,
        addressOrganisationName: vm.buyerDetails.organisationName,
        addressPostalZone: vm.buyerDetails.postalCode?vm.buyerDetails.postalCode:'',
        addressStreetName: vm.buyerDetails.streetName?vm.buyerDetails.streetName:'',
        isoCode: vm.buyerDetails.isoCode,
        receiverKey: vm.buyerDetails.receiverKey
      }).
        then(function () {
          $state.go('ezyap.'+vm.userRole.pretty+'.buyer-info');
          NotificationService.success({
            title: 'global.customerInformation',
            message: 'alertMessage.ADDED_SUCCESSFULLY'
          });
        }, function (error) {
          NotificationService.error({
            title: 'global.customerInformation',
            error: error
          });
        });
    }


    /**
     * searchForPeppolUsers serchs the peppol user
     */
    function searchForPeppolUsers(queryText){
        var deferred = $q.defer();
        if(!queryText) {
            return false;
          }
          else {
            var searchQuery = {
              identifier : queryText
              }
            ManageBuyerInfoService.searchAllPeppolUsers(searchQuery)
            .then(function(response) {
                deferred.resolve($filter('orderBy')(response.data, 'identifier'));
            }, function(error) {
                deferred.reject(error);
            });
        }
        return deferred.promise;
        }

    /**
     * setCustomerInfo Auto populates the  user data
     */
    function setCustomerInformation(selectedPeppolUser){
      vm.buyerDetails.organisationName = selectedPeppolUser.name;
      vm.buyerDetails.countryCode = selectedPeppolUser.countryCode;
      vm.buyerDetails.isoCode = selectedPeppolUser.isoCode;
      vm.buyerDetails.receiverKey = selectedPeppolUser.identifier;
    }
  }
})();
