/**
 * @Author: Abhay
 * @Date:   2021-01-19 13:00:30
 * @Filename: xborder-flyer.service.js
 * @Last modified by:   Abhay
 * @Last modified time: 2021-01-19 13:00:30
 */

(function () {
    'use strict';

    angular.module('ezyprocureApp').factory('XBorderFlyerService', XBorderFlyerService);

    XBorderFlyerService.$inject = ['$window', '$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

    function XBorderFlyerService($window, $http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS ) {

        var xBorderFlyerService = {
            getOptInStatus: getOptInStatus,
            updateOptInStatus: updateOptInStatus,
            setOptInStatus: setOptInStatus,
            getSkipCount: getSkipCount,
            setSkipCount: setSkipCount,
            generateScreenshot: generateScreenshot,
            getPromoPopupStatus: getPromoPopupStatus,
            setPromoPopupStatus: setPromoPopupStatus,
        };

        function getPromoPopupStatus() {
            return $window.localStorage["promo-popup-status"];
        }

        function setPromoPopupStatus(shown) {
            $window.localStorage["promo-popup-status"] = shown;
        }

        function getOptInStatus() {
            var deferred = $q.defer();
            var optInStatus = $window.sessionStorage["xborder-opt-in-status"] ? JSON.parse($window.sessionStorage["xborder-opt-in-status"]) : "";
            if (optInStatus)
                deferred.resolve(optInStatus);
            else {
                $http({
                    url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers/opt-in-status',
                    method: "GET"
                }).then(function (response) {
                    optInStatus = response.data.optInStatus;
                    setOptInStatus(optInStatus);
                    deferred.resolve(optInStatus);
                }).catch(function (message) {
                    deferred.reject("");
                });
            }
            return deferred.promise;
        }

        function updateOptInStatus(params) {
            var deferred = $q.defer();
            $http({
                url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'implementation-note/update',
                method: "POST",
                params: params,
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (message) {
                deferred.reject(message);
            });
            return deferred.promise;
        }

        function setOptInStatus(optInStatus) {
            $window.sessionStorage["xborder-opt-in-status"] = JSON.stringify(optInStatus);
        }

        function getSkipCount(property) {
            return $window.localStorage[property] ? +$window.localStorage[property] : 0;
        }

        function setSkipCount(currentCount, property) {
            $window.localStorage[property] = ++currentCount;
        }

        function generateScreenshot() {
            var deferred = $q.defer();
            var screenshotTarget = document.body;
            html2canvas(screenshotTarget, {
                allowTaint : false,
                logging: true,
                useCORS: true,
                onrendered: function (canvas) {
                    var base64image = canvas.toDataURL("image/png");
                    deferred.resolve(base64image);
                }
            });
            return deferred.promise;
        }

        return xBorderFlyerService;

    }
})();
