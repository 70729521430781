/**
* @Author: Mufsir
* @Date:   08-Mar-2017 12:19 +05:30
* @Project: Ezyprocure
 * @Last modified by:   Ajay
 * @Last modified time: 15-Oct-2018 17:10 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp', [
    'ngStorage',
    'tmh.dynamicLocale',
    'pascalprecht.translate',
    'ngResource',
    'ngCookies',
    'ngAria',
    'ngMessages',
    'ngAnimate',
    'ngMaterial',
    'lfNgMdFileInput',
    'ngPassword',
    'md.data.table',
    'ngCacheBuster',
    'ngFileUpload',
    'toastr',
    'flow',
    'ui.bootstrap',
    'ui.bootstrap.datetimepicker',
    'ui.router',
    'infinite-scroll',
    // jhipster-needle-angularjs-add-module JHipster will add new module here
    'angular-loading-bar',
    'blockUI',
    'wt.responsive',
    'signature',
    'xeditable',
    'ngCsv',
    'cc.autorefresh',
    'as.sortable',
    'nvd3',
    'fCsv',
    'ngIdle',
    'ngSanitize',
    'angular-uuid'
  ])
  .run(run);

  run.$inject = ['stateHandler', 'translationHandler', 'idleHandler', 'authHandler'];

  function run(stateHandler, translationHandler, idleHandler, authHandler) {
    stateHandler.initialize();
    translationHandler.initialize();
    //Uncomment the idleHandler.initialize() to start checking idle condition
    //idleHandler.initialize();
    authHandler.initialize();
  }
})();
