/*
 * @Author: Jayalekshmi 
 * @Date: 2019-08-27 19:15:49 
 * @Filename: ezypayment.merchant-details.supplier-merchant-details.add.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-08-27 19:17:41
 */


(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .controller('EzyPaymentSupplierMerchantDetailsAddController', EzyPaymentSupplierMerchantDetailsAddController);
  
    EzyPaymentSupplierMerchantDetailsAddController.$inject = ['$state', '$stateParams', 'EzypaymentAdminManageMerchantDetailsService', 'GLOBAL_CONSTANTS', 'NotificationService', 'ManageSupplierService'];
  
    function EzyPaymentSupplierMerchantDetailsAddController($state, $stateParams, EzypaymentAdminManageMerchantDetailsService, GLOBAL_CONSTANTS, NotificationService, ManageSupplierService) {
  
      var vm = this;
      vm.brandId = $stateParams.brandId;
      vm.save = save;
      vm.gatewayTypes = GLOBAL_CONSTANTS.GATEWAY_TYPES;
      vm.loadAllAdminSuppliers = loadAllAdminSuppliers;
      vm.getAllCountryCurrencies = getAllCountryCurrencies;
      vm.init = init;

      vm.init();

      function init(){
        vm.getAllCountryCurrencies();
      }
  
      /**
       * [getAllCountryCurrencies gets all country currencies]
       */
      function getAllCountryCurrencies(){
        EzypaymentAdminManageMerchantDetailsService.getAllCountryCurrencies().then(function(response){
          vm.currencies = response.data;
        },function(error){
        });
      }
      
      /**
       * [loadAllAdminSuppliers gets all country currencies]
       */
      function loadAllAdminSuppliers() {
        var params = {
          visibilityType:'ALLOCATED'
        };
        ManageSupplierService.getAllAdminSuppliers(params).then(function(response){
          vm.suppliers = response.data;
        },function(error){
        });
      }
  
      /**
       * [save Creates new supplier merchant detail
       */
      function save(){
        vm.merchantDetails.brand = null;
        EzypaymentAdminManageMerchantDetailsService.createSupplierMerchantIdDetail(vm.merchantDetails).then(function(response) {
          NotificationService.success({
            title: 'Create Merchant Detail',
            message: 'alertMessage.CREATED_SUCCESSFULLY'
          });
          $state.go('ezypayment.admin.merchant-management',{reload: false, initialActive: true})
        }, function(error) {
          NotificationService.error({
            title: 'Create Merchant Detail',
            error: error
          });
        });
      }
    }
  })();
  