/**
 * @Author: Ajay
 * @Date:   22-Dec-2017 14:32 +05:30
 * @Project: Ezyprocure
 * @Filename: reports.service.js
 * @Last modified by:   sneha
 * @Last modified time: 29-Mar-2018 12:28 +05:30
 */



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('ReportService', ReportService);

  ReportService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  function ReportService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var reportService = {
      getAllSuppliers: getAllSuppliers
    };
    return reportService;

    function getAllSuppliers(data,params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.SUPPLIER_RESOURCE + 'reports',
        method: "PUT",
        data: data,
        params: params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
