/**
 * @Author: Jayalekshmi
 * @Date:   2018-06-28T13:36:39+05:30
 * @Filename: brand-supplier-mapping.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 10-Jul-2018 14:46 +05:30
 */


(function() {
    'use strict';

    angular.module('ezyprocureApp').factory('BrandSupplierMapService', BrandSupplierMapService);

    BrandSupplierMapService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
    function BrandSupplierMapService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

      var brandSupplierMapService = {
        createBrandSupplierMapping: createBrandSupplierMapping,
        deleteBrandSupplierMapping: deleteBrandSupplierMapping,
        getAllBrandSupplierMappings: getAllBrandSupplierMappings,
        getAllBrandMappedSuppliers: getAllBrandMappedSuppliers
      };
      return brandSupplierMapService;

      /* --   Brand - Supplier Mapping API's -- */
      /**
       * [createBrandSupplierMapping Create a new Brand and Supplier mapping]
       * @param  {[Object]} mapping [Map object to be created]
       */
      function createBrandSupplierMapping(mapping) {
        var deferred = $q.defer();
        $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BRAND_SUPPLIER_MAPPING_RESOURCE, mapping).then(function(data) {
          deferred.resolve(data.data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      /**
       * [deleteBrandSupplierMapping Soft Delete existing Brand and Supplier mapping]
       * @param  {[Integer]} mappingId [Id of the mapping to be deleted]
       */
      function deleteBrandSupplierMapping(mappingId) {
        var deferred = $q.defer();
        $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BRAND_SUPPLIER_MAPPING_RESOURCE+mappingId).then(function(data) {
          deferred.resolve(data.data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      /**
       * [getAllBrandSupplierMappings description Gets all Mapped brand and suppliers]
       * @param  {[object]} params [description]
       */
      function getAllBrandSupplierMappings(params) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BRAND_SUPPLIER_MAPPING_RESOURCE,
          method: "GET",
          params: params,
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      /**
       * [getAllBrandMappedSuppliers Get list of mapped suppliers to the selected brand]
       * @param  {[type]} params [description]
       * @return {[type]}        [description]
       */
      function getAllBrandMappedSuppliers(params) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BRAND_SUPPLIER_MAPPING_RESOURCE + 'suppliers',
          method: "GET",
          params: params,
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }


    }
  })();
