/**
 * @Author: Jayalekshmi
 * @Date:   2018-06-28T13:51:21+05:30
 * @Filename: buyer.brand-supplier-mapping.add.controller.js
 * @Last modified by:   Jayalekshmi
 * @Last modified time: 2018-06-28T16:00:33+05:30
 */



(function() {
    'use strict';

    angular
    .module('ezyprocureApp')
    .controller('BuyerBrandSupplierMapAddController', BuyerBrandSupplierMapAddController);

    BuyerBrandSupplierMapAddController.$inject = ['$scope', '$rootScope' ,'$state', '$stateParams', 'toastr', 'pagingParams', 'BuyerDataService', 'paginationConstants', 'NotificationService', 'BrandSupplierMapService','BuyerSupplierMappingService', 'BrandManagementService'];

    function BuyerBrandSupplierMapAddController($scope, $rootScope, $state, $stateParams, toastr, pagingParams, BuyerDataService, paginationConstants, NotificationService, BrandSupplierMapService, BuyerSupplierMappingService, BrandManagementService) {
      var vm = this;
      vm.getAllBuyerMappedSuppliers = getAllBuyerMappedSuppliers;
      vm.getAllBuyerBrands = getAllBuyerBrands;
      vm.createBrandSupplierMapping = createBrandSupplierMapping;
      vm.supplierSort = ["supplier.companyName,asc"];
      vm.mapping = {};
      vm.deselectAll = deselectAll;
      vm.selectAll = selectAll;
      
      /**
      * [getAllBuyerBrands Get list of brands of the logged in buyer]
      */
      function getAllBuyerBrands() {
        var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
        if(vm.brands)
          return false;
        BrandManagementService.getAllBrandsByBuyerId({
          query: '',
          page: 0,
          size: paginationConstants.maxLimit,
          buyerId: buyerId
        }).then(function(response){
          vm.brands = response.data;
        },function(error){
        });
      }

      /**
       * [getAllBuyerMappedSuppliers Get list of mapped suppliers to the logged in buyer]
       */
      function getAllBuyerMappedSuppliers() {
        if(vm.suppliers)
          return false;
        var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
        BuyerSupplierMappingService.getAllBuyerMappedSuppliers(buyerId,{
          page: 0,
          size: paginationConstants.maxLimit,
          sort: vm.supplierSort
        }).then(function(response){
          vm.suppliers = response.data;
        },function(error){
        });
      }

      /**
       * [createBrandSupplierMapping Map selected buyer and supplier]
       */
      function createBrandSupplierMapping() {
        BrandSupplierMapService.createBrandSupplierMapping(vm.mapping).then(function(response){
          vm.translatedError = response;
          NotificationService.success({
            title: 'Brand - Supplier Mapping',
            message: 'Completed'
          });
          if(response.responseList.length <1){
            $state.go('buyer.tools.brand-supplier-mapping', {
              reload: false
            });
          }
        },function(error) {
          NotificationService.error({
            title: 'Mapping Brand - Supplier',
            error: error
          });
        });
      }

      /**
       * [selectAll Selects all from the list]
       * @param  {[String]} dropdown [Active dropdown - outlets, suppliers, mainItemGroups or itemGroups]
       */
      function selectAll(dropdown) {
        switch (dropdown) {
        case 'SUPPLIERS':
        vm.mapping.supplierList = _.map(vm.suppliers, _.property('id'));
          break;
        }
      }

      /**
       * [deselectAll Deselect all from the list]
       * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, mainItemGroups or itemGroups]
       */
      function deselectAll(dropdown) {
        switch (dropdown) {
        case 'SUPPLIERS':
          vm.mapping.supplierList = [];
          break;
        }
      }
    }
})();
