/**
* @Author: Mufsir
* @Date:   20-Jan-2017 15:09 +05:30
* @Project: Ezyprocure
* @Filename: admin.user-access.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 01-Jun-2017 13:06 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('admin.user-access', {
      parent: 'admin',
      url: '/user-access?page&sort&search',
      data: {
        authorities: ['ROLE_ADMIN'],
        pageTitle: 'ezyprocureApp.userAccess.home.title'
      },
      views: {
        'content@': {
          templateUrl: 'app/admin-modules/user-access/admin.user-access.list.html',
          controller: 'AdminUserAccessController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('userAccess');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('user-management');
          $translatePartialLoader.addPart('buyerDashboard');
          return $translate.refresh();
        }]
      }
    });
  }

})();
