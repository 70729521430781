/**
* @Author: Mufsir
* @Date:   07-Feb-2017 19:12 +05:30
* @Project: Ezyprocure
* @Filename: admin.account.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-11-04T13:54:52+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('AdminAccountController', AdminAccountController);

  AdminAccountController.$inject = ['$scope', '$rootScope', '$state', 'Auth', 'NotificationService', 'Principal'];

  function AdminAccountController($scope, $rootScope, $state, Auth, NotificationService, Principal ) {

    var vm = this;
    vm.changePassword = changePassword;

    function changePassword() {
      if(Principal.isCBEnabled()) {
        Auth.checkCbHealth()
          .then(function() {
            Auth.changePassword(vm.changePwd.newPassword).then(function(response) {
              $scope.$emit('ezyprocureApp:passwordChanged', response);
              NotificationService.success({
                title: 'Account Settings',
                message: 'Password Changed successfully'
              });
              Auth.clearTokenAndRedirectToLogin();
            }, function(error) {
              NotificationService.error({
                title: 'Account Settings - Change Password',
                error: error
              });
            });
          }, function(error) {
            NotificationService.error({
              title: 'Account Settings - Change Password',
              error: error
            });
          })
      } else {
        Auth.changePassword(vm.changePwd.newPassword).then(function(response) {
          $scope.$emit('ezyprocureApp:passwordChanged', response);
          NotificationService.success({
            title: 'Account Settings',
            message: 'Password Changed successfully'
          });
          Auth.clearTokenAndRedirectToLogin();
        }, function(error) {
          NotificationService.error({
            title: 'Account Settings - Change Password',
            error: error
          });
        });
      }
    }

  }
})();
