/**
 * @Author: Ajay
 * @Date:   19-Jul-2018 12:32 +05:30
 * @Project: Ezyprocure
 * @Filename: ezypayment.buyer.epd-management.state.js
 * @Last modified by:   Allan
 * @Last modified time: 27-August-2018 15:30 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('ezypayment.buyer.epd-approval-requests', {
      parent: 'ezypayment.buyer',
      url: '/epd-approval-requests?page&sort&search',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'ezyprocureApp.epdApprovalRequest.home.title'
      },
      views: {
        'content@': {
          templateUrl: 'app/common-modules/manage-epd/manage-epd.list.html',
          controller: 'ManageEPDListController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('epdApprovalRequest');
          $translatePartialLoader.addPart('epdApprovalStatus');
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    })
    .state('ezypayment.buyer.epd-approval-requests.add', {
      parent: 'ezypayment.buyer.epd-approval-requests',
      url: '/add',
      data: {
        authorities: ['ROLE_BUYER']
      },
      views: {
        'content@': {
          templateUrl: 'app/common-modules/manage-epd/add/manage-epd.add.html',
          controller: 'ManageEPDAddController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('epdApprovalRequest');
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    })

    .state('ezypayment.buyer.epd-approval-requests.edit', {
      parent: 'ezypayment.buyer.epd-approval-requests',
      url: '/edit/:epdApprovalRequestId',
      data: {
        authorities: ['ROLE_BUYER']
      },
      views: {
        'content@': {
          templateUrl: 'app/common-modules/manage-epd/edit/manage-epd.edit.html',
          controller: 'ManageEPDEditController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('epdApprovalRequest');
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    });
  }

})();
