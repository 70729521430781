/**
 * @Author: Mufsir
 * @Date:   30-Apr-2017 14:10 +05:30
 * @Project: Ezyprocure
 * @Filename: account.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 11-Apr-2018 11:30 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .factory('Account', Account);

  Account.$inject = ['$resource', 'API_CONSTANTS', '$localStorage'];

  function Account($resource, API_CONSTANTS, $localStorage) {
    // Get logged in user details
    var service = $resource('api/' + API_CONSTANTS.baseVersion + 'account', {}, {
      'get': { method: 'GET', params: {}, isArray: false,
        interceptor: {
          response: function(response) {
            // expose response
            return response;
          }
        }
      }
    });
    return service;
  }
})();
