/**
* @Author: Allan
* @Date:   21-August-2018 16:15 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.buyer.dashboard.controller.js
* @Last modified by:   Allan
* @Last modified time: 29-August-2018 16:15 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzyPaymentBuyerDashboardController', EzyPaymentBuyerDashboardController);

  EzyPaymentBuyerDashboardController.$inject = ["EzypaymentBuyerDashboardService", "$rootScope", 'NotificationService', 'GLOBAL_CONSTANTS'];

  function EzyPaymentBuyerDashboardController(EzypaymentBuyerDashboardService, $rootScope, NotificationService, GLOBAL_CONSTANTS) {

    var vm = this;
    vm.getEzypaymentBuyerDashboardCount = getEzypaymentBuyerDashboardCount;
    vm.showUobBanner = GLOBAL_CONSTANTS.SHOW_UOB_BANNER;
    vm.showEzysourceBanner = GLOBAL_CONSTANTS.SHOW_EZYSOURCE_BANNER;

    function getEzypaymentBuyerDashboardCount() {
      EzypaymentBuyerDashboardService.getEzypaymentBuyerDashboardCount().then(function(response) {
        vm.buyerDashboardCount = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'Ezypayment Dashboard',
          error: error
        });
      });
    }

  }
})();
