/**
 * @Author: Mufsir
 * @Date:   01-Mar-2017 14:19 +05:30
 * @Filename: buyer.business-supplier-mapping.service.js
 * @Last modified by:   sneha
 * @Last modified time: 29-Mar-2018 12:28 +05:30
 */



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BusinessSupplierMapService', BusinessSupplierMapService);

  BusinessSupplierMapService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  function BusinessSupplierMapService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var businessSupplierMapService = {
      isBusinessSupplierMapped: isBusinessSupplierMapped,
      createBusinessSupplierMapping: createBusinessSupplierMapping,
      deleteBusinessSupplierMapping: deleteBusinessSupplierMapping,
      mapBusinessToSupplierCSV: mapBusinessToSupplierCSV,
      getAllMappedSupplierBusinesses: getAllMappedSupplierBusinesses,
      getBuyerBusinessOutlets: getBuyerBusinessOutlets,
      updateBusinessSupplierMapping: updateBusinessSupplierMapping,
      getAllBusinessAreas : getAllBusinessAreas,
      getAllMappedSupplierBusinessesBySupplier : getAllMappedSupplierBusinessesBySupplier
    };

    return businessSupplierMapService;

    /* --   Business Outlet - Supplier Mapping API's -- */

    //Check Business Outlet and Supplier is Mapped or not
    function isBusinessSupplierMapped(mapping) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'map/business/'+ mapping.businessOutletId +'/supplier/'+ mapping.supplierId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Create a new Business Outlet and Supplier mapping
    function createBusinessSupplierMapping(mapping) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'map/business/'+ mapping.businessOutletId +'/supplier/'+ mapping.supplierId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    // Soft Delete existing Business Outlet and Supplier mapping
    function deleteBusinessSupplierMapping(mapping) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'map/business/'+ mapping.businessOutletId +'/supplier/'+ mapping.supplierId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Create a new business-supplier mapping
    function mapBusinessToSupplierCSV(csvMapping) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUSINESS_SUPPLIER_MAPPING_RESOURCE + 'upload',csvMapping).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    
    // Update the area Business Supplier mapping
    function updateBusinessSupplierMapping(mapping) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUSINESS_SUPPLIER_MAPPING_RESOURCE,
        method: "PUT",
        data: mapping,
      }).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    // Get all business areas
    function getAllBusinessAreas(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'business-areas',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getAllMappedSupplierBusinesses(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUSINESS_SUPPLIER_MAPPING_RESOURCE,
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getAllMappedSupplierBusinessesBySupplier(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'supplier/' +RESOURCE_URL_CONSTANTS.BUSINESS_SUPPLIER_MAPPING_RESOURCE,
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get all business outlets mapped under supplier
    function getBuyerBusinessOutlets(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'supplier/business-outlets',
        method: "GET",
        params: params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
