/**
* @Author: Abhay
* @Date:   19-Aug-2020 11:25 IST
* @Project: Ezyprocure
* @Filename: ezypayment.admin.tools.state.js
* @Last modified by:   Abhay
* @Last modified time: 19-Aug-2020 11:25 IST
*/



(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .config(stateConfig);
  
    stateConfig.$inject = ['$stateProvider'];
  
    function stateConfig($stateProvider) {
      $stateProvider.state('ezypayment.admin.tools', {
        abstract: true,
        parent: 'ezypayment.admin',
        url: '/tools',
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
          }]
        }
      });
    }
  })();
  