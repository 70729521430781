/**
 * @Author: Ajay
 * @Date:   18-Jul-2018 15:19 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.manage-erp-item.bulk-upload.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 08-Aug-2018 14:41 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('ManageERPItemBulkUploadController', ManageERPItemBulkUploadController);

  ManageERPItemBulkUploadController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'toastr', 'ItemBuyerMappingService', 'CsvUploadService', 'paginationConstants', 'NotificationService'];

  function ManageERPItemBulkUploadController($scope, $rootScope, $state, $stateParams, toastr, ItemBuyerMappingService, CsvUploadService, paginationConstants, NotificationService) {
    var vm = this;

    vm.init = init;
    vm.getCSVBase64 = getCSVBase64;
    vm.itemBulkUploadCSV = itemBulkUploadCSV;

    function init() {
      vm.csvUploadData = {};
    }

    function getCSVBase64() {
      vm.errorList = null;
      var csvFile = vm.files[0];
      if (!csvFile) {
        toastr.error('Please upload a .csv file!', 'CSV File Required');
        return false;
      }
      CsvUploadService.getFileBase64(csvFile.lfFile, function(base64CSV) {
        vm.csvUploadData.encodedCsvFile = base64CSV;
        vm.itemBulkUploadCSV(vm.csvUploadData);
      });
    }

    function itemBulkUploadCSV(uploadData) {
      ItemBuyerMappingService.bulkUpdateItemBuyerMapping(uploadData)
      .then(function(response) {
        vm.files = [];
        NotificationService.success({
          title: 'Bulk ERP Item',
          message: 'alertMessage.UPLOADED_SUCCESSFULLY'
        });
        $state.go('buyer.manage-erp-item', {
          reload: false
        });
      }, function(error) {
        vm.recordError = error.data;
        NotificationService.error({
          title: 'ERP Item Bulk Upload',
          error: error
        });
      });
    }

  }

})();
