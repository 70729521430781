/**
* @Author: Abhay
* @Date:   06-Jan-2021 12:32 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.card-tokenize.controller.js
* @Last modified by:   Abhay
* @Last modified time: 06-Jan-2021 12:32 +05:30
*/



(function () {
    'use strict';

    angular
        .module('ezyprocureApp')
        .controller('CustomCardAddController', CustomCardAddController);

        CustomCardAddController.$inject = ["CardSecurityService", "$state", "$stateParams", "$location", "$rootScope", "$mdDialog", "uuid", "paginationConstants", "AuthServerProvider", "CardsService", "BuyerSupplierMappingService", "BuyerDataService", "BrandManagementService", "NotificationService", "GLOBAL_CONSTANTS", "AddCardService"];

    function CustomCardAddController(CardSecurityService, $state, $stateParams, $location, $rootScope, $mdDialog, uuid, paginationConstants, AuthServerProvider, CardsService, BuyerSupplierMappingService, BuyerDataService, BrandManagementService, NotificationService, GLOBAL_CONSTANTS, AddCardService) {

        var vm = this;
        vm.requestFields = {
            transactionUuid : uuid.v4().replace(/-/g, "_"),
            brandId: undefined,
            token: AuthServerProvider.getToken()
        };
        // $location.host()
        vm.requestUrl =  $location.absUrl().split("#")[0] + "api/customs/card/redirect";
        vm.agreeTermsAndConditions = false;
        vm.brandIds = [];
        vm.getBrands = getBrands;
        vm.showTermsDialog = showTermsDialog;
        vm.cancel = cancel;
        vm.saveTransactionUuid = saveTransactionUuid;
        vm.loadAllBuyerMappedSuppliers = loadAllBuyerMappedSuppliers;
        vm.getWorldpayURI = getWorldpayURI;

        function getWorldpayURI(){
            AddCardService.connectWorldPay({
                brandId: vm.brandId,
                transactionUuid: generateUniqId()
            }).then(function(response){
                vm.data = response.data;
            },
            function(err){

            })
        }

        function loadAllBuyerMappedSuppliers() {
            var params = {
                page: 0,
                size: paginationConstants.maxLimit
            };
            var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
            BuyerSupplierMappingService.getAllBuyerMappedSuppliers(buyerId, params).then(function (response) {
                vm.suppliers = response.data;
            }, function (error) {
            });
        }

        function saveTransactionUuid() {
            if(vm.userRole == 'SUPPLIER' && !vm.supplierId) {
                return;
            }
            CardsService.saveTransactionUuid({
                transactionUuid: vm.requestFields.transaction_uuid,
                referenceNumber: vm.requestFields.reference_number,
                brandId: vm.brandId,
                supplierId: vm.supplierId,
            }).then(function (response) {
                
            }, function (error) {
                NotificationService.error({
                    title: 'Cybersource Card Details',
                    error: error
                });
            });
        }

        function getBrands() {
            var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
            BrandManagementService.getAllBrandsByBuyerId({
                page: 0,
                size: paginationConstants.maxLimit,
                query: '',
                supplierId: [],
                buyerId: buyerId
            }).then(function (response) {
                vm.brands = response.data;
            });
        }

        function cancel() {
            $mdDialog.cancel();
        }

        function showTermsDialog(event) {
            event.preventDefault();
            $mdDialog.show({
                templateUrl: 'app/ezypayment-modules/buyer-modules/card-tokenize/ezypayment.card-tokenize-terms-conditions.tmpl.html',
                controller: function () {
                    return vm;
                },
                controllerAs: 'vm',
                targetEvent: event,
                clickOutsideToClose: false,
            }).then(function () {

            });
        }

        function signFields() {
            CardSecurityService.setRequestFields(vm.requestFields);
            vm.requestFields.signature = CardSecurityService.sign(vm.secretKey);
        }

        function generateUniqId() {
            var c = (new Date().getTime()) / 1000;
            var d = c.toString(16).split(".").join("");
            while (d.length < 14) d += "0";
            return d;
        }
    }
})();
