/**
* @Author: Allan
* @Date:   12-September-2018 16:15 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.sip-management.state.js
* @Last modified by:   Allan
* @Last modified time: 22-September-2018 19:03 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('ezypayment.supplier.supplier-initiate-payment', {
      parent: 'ezypayment.supplier',
      url: '/sip',
      data: {
        authorities: ['ROLE_SUPPLIER']
      },
      views: {
        'content@': {
          templateUrl: 'app/ezypayment-modules/supplier-modules/sip-management/ezypayment.sip-management.html',
          controller: 'EzypaymentSIPManagementController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('buyerDashboard');
          $translatePartialLoader.addPart('itemApprovalStatus');
          return $translate.refresh();
        }],
        hasModuleAccess: ['Auth','$q', '$state', function(Auth, $q, $state) {
          if(Auth.hasPermission('accessForPayViaUOBVPS')){
            return true;
          }else {
            return $q.reject('Unauthorized');
          }
        }]
      }
    })
  }
})();
