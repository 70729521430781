/**
* @Author: Allan
* @Date:   07-June-2018 16:15 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.sip-authorization-request.controller.js
* @Last modified by:   Allan
* @Last modified time: 27-September-2018 14:45 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzypaymentSipAuthorizationRequestController', EzypaymentSipAuthorizationRequestController);

  EzypaymentSipAuthorizationRequestController.$inject = ['EzypaymentSipAuthorizationRequestService', 'NotificationService', '$mdDialog', 'BuyerBuyNowService', '$filter', 'GLOBAL_CONSTANTS'];

  function EzypaymentSipAuthorizationRequestController(EzypaymentSipAuthorizationRequestService, NotificationService, $mdDialog, BuyerBuyNowService, $filter, GLOBAL_CONSTANTS) {

    var vm = this;
    vm.init = init;
    vm.getSipRequests = getSipRequests;
    vm.approveSipRequest = approveSipRequest;
    vm.sipAuthorizationRequestList = [];
    vm.getAllAllocatedSuppliersForBusiness = getAllAllocatedSuppliersForBusiness;
    vm.filter = {};
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.sipStatus = GLOBAL_CONSTANTS.approvalStatus;

    function init(){
      getSipRequests();
    }

    function getSipRequests() {
      EzypaymentSipAuthorizationRequestService.getSipRequests({
        status: !vm.filter.sipStatus ? 'PENDING' : vm.filter.sipStatus,
        supplierId: !vm.filter.supplierIds ? [] : vm.filter.supplierIds
      }).then(function(response){
        vm.sipAuthorizationRequestList = response.data;
      },function(error) {
        NotificationService.error({
          title: 'SIP Authorization Request',
          error: error
        });
      });
    }

    function approveSipRequest(id, status){
      EzypaymentSipAuthorizationRequestService.approveSipRequest({
        eAuthorizationRequestStatus: status
      }, id).then(function(response){
        vm.sipAuthorizationRequestList[0] = response.data;
        NotificationService.success({
          title: "SIP Authorization Request",
          message: response.data.approvalStatus
        });
        vm.init();
      },function(error) {
        NotificationService.error({
          title: 'SIP Authorization Request',
          error: error
        });
      });
    }

    function selectAll(dropdown) {
      vm.filter.supplierIds = _.map(vm.supplierList, _.property('id'));
    }

    function deselectAll(dropdown) {
      vm.filter.supplierIds = [];
    }

    function getAllAllocatedSuppliersForBusiness() {
      if(vm.supplierList)
        return false;
      var params = {'businessId': '','brandIds':''};
      BuyerBuyNowService.getAllAllocatedSuppliersForBusiness(params).then(function(response){
        vm.supplierList = $filter('orderBy')(response.data, 'companyName');
      },function(error){
        NotificationService.error({
          title: 'Fetching Allocated Suppliers',
          error: error
        });
      });
    }

  }
})();
