/**
 * @Author: Jayalekshmi 
 * @Date: 2019-01-03 10:40:23 
 * @Project: Ezyprocure
 * @Filename: sats.so.add.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-04-16 15:51:59
 */



(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .controller('SatsSoAddController', SatsSoAddController);
  
    SatsSoAddController.$inject = ['$filter','$state', 'UtilService', 'NotificationService', 'SatsManagementService', 'CsvUploadService', 'Auth'];
  
    function SatsSoAddController($filter, $state, UtilService, NotificationService, SatsManagementService, CsvUploadService, Auth) {
      var vm = this;
      vm.userRole = Auth.getUserRole();
      vm.createJobSO = createJobSO;
      vm.uploadJobSO = uploadJobSO;
      vm.resetValues = resetValues;
      vm.getDateFormat = getDateFormat;
  
      /**
       * [getDateFormat gets the date string and converts]
       * @param {dateString} dateString 
       */
      function getDateFormat(dateString){
        var formatedDate = SatsManagementService.getDateFormat(dateString);
        return formatedDate;
      }
      
      /**
       * [createJobSO Creates Job SO]
       */
      function createJobSO(){
        SatsManagementService.createJobSO(vm.files[0].lfFile, {"wave":vm.soJob.wave})
        .then(function(response){
          NotificationService.success({
            title: 'global.sats.title.'+vm.userRole.pretty,
            message: 'Job Created Succesfully'
          });
          $state.go(vm.userRole.pretty+'.sats', {
            reload: true
          });
        }, function(error){
          if(error.data.message == 'error.validation'){
            vm.recordError = error.data;
          }
          NotificationService.error({
            title: 'global.sats.title.'+vm.userRole.pretty,
            error: error
          });
        })
      }

      /**
       * [uploadJobSO Uploads Job SO]
       */
      function uploadJobSO(){
        vm.recordError = [];
        var csvFile = vm.files[0];
        if (!csvFile) {
          NotificationService.simpleErrorToast({
            title: 'alertMessage.INVALID_INPUT',
            message: 'global.pleaseChooseACsvFile'
          });
          return false;
        }
        vm.isCsvFile = CsvUploadService.checkFileType(csvFile.lfFile);
        if (vm.isCsvFile) {
          UtilService.csvToJson(csvFile.lfDataUrl)
          .then(function (response) {
            vm.soItems = response;
            if(vm.soItems.length <1){
              NotificationService.simpleErrorToast({
                title: 'alertMessage.INVALID_INPUT',
                message: 'alertMessage.FILL_CSV'
              });
            }
          })
         }
      }

      /**
       * [resetValues Resets csv and error data when clicking browse button]
       */
      function resetValues(){
        vm.soItems = [];
        vm.recordError = []
      }
    }
  })();
  