/**
 * @Author: Ajay
 * @Date:   27-May-2017 11:05 +05:30
 * @Filename: buyer.business-supplier-mapping.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 10-Nov-2017 12:34 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerBusinessSupplierMapController', BuyerBusinessSupplierMapController);

  BuyerBusinessSupplierMapController.$inject = ['$scope', '$rootScope' ,'$state', '$filter', 'toastr', 'pagingParams', '$q', 'BuyerDataService', 'BuyerBusinessService', 'BusinessSupplierMapService', 'BuyerSupplierMappingService', 'CsvUploadService', 'paginationConstants', 'NotificationService', 'S3_URL_CONSTANTS'];

  function BuyerBusinessSupplierMapController($scope, $rootScope, $state, $filter, toastr, pagingParams, $q, BuyerDataService, BuyerBusinessService, BusinessSupplierMapService, BuyerSupplierMappingService, CsvUploadService, paginationConstants, NotificationService, S3_URL_CONSTANTS) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.sort = sort;
    vm.loadAll = loadAll;
    vm.mappedItemSearch = '';
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.getAllBuyerMappedSuppliers = getAllBuyerMappedSuppliers;
    vm.isBusinessSupplierMapped = isBusinessSupplierMapped;
    vm.createBusinessSupplierMapping = createBusinessSupplierMapping;
    vm.deleteBusinessSupplierMapping = deleteBusinessSupplierMapping;
    vm.mapBusinessToSupplierCSV = mapBusinessToSupplierCSV;
    vm.getCSVBase64 = getCSVBase64;
    vm.supplierSort = ["supplier.companyName,asc"];
    vm.mapping = {};
    vm.BUSINESS_SUPPLIER_MAPPING_CSV = S3_URL_CONSTANTS.BUSINESS_SUPPLIER_MAPPING_CSV;
    vm.createBusinessArea = createBusinessArea;
    vm.getAllBusinessAreas = getAllBusinessAreas;
    vm.selectionChanged = false;
    vm.getAreas = getAreas;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    // Load All supplier items which are mapped
    function loadAll(search) {
      if (!search) {
        vm.mappedItemSearch = '';
      }
      if(vm.mappedSuppliers){
        _.map(vm.mappedSuppliers, function(each){
          each.searchText = null;
        }) 
      }
      BusinessSupplierMapService.getAllMappedSupplierBusinesses({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.mappedBuyerSearch,
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.mappedSuppliers = response.data;
        _.map(vm.mappedSuppliers, function(each){
          each.searchText = null;
        })   
      },function(error){
        NotificationService.error({
          title: 'Mapped Supplier - Business Listing',
          error: error
        });
      });
    }

    vm.getAllBusinessAreas()

    /**
    * [getAllBuyerBusinessOutlets Get list of Business outlets of the logged in buyer]
    */
    function getAllBuyerBusinessOutlets() {
      if(vm.businessOutlets)
        return false;
      BuyerBusinessService.getAllBuyerBusinesses({
        query: '',
        page: 0,
        size: paginationConstants.maxLimit
      }).then(function(response){
        vm.businessOutlets = response.data;
      },function(error){
      });
    }

    /**
     * [getAllBuyerMappedSuppliers Get list of mapped suppliers to the logged in buyer]
     */
    function getAllBuyerMappedSuppliers() {
      if(vm.suppliers)
        return false;
      var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      BuyerSupplierMappingService.getAllBuyerMappedSuppliers(buyerId,{
        page: 0,
        size: paginationConstants.maxLimit,
        sort: vm.supplierSort
      }).then(function(response){
        vm.suppliers = response.data;
      },function(error){
      });
    }

    function isBusinessSupplierMapped(mapping) {
      if(!mapping.businessOutletId || !mapping.supplierId)
        return false;
      BusinessSupplierMapService.isBusinessSupplierMapped(mapping).then(function(response){
        vm.isAlreadyMapped = response;
      },function(error){
      });
    }

    function createBusinessSupplierMapping() {
      // Map selected buyer and supplier
      BusinessSupplierMapService.createBusinessSupplierMapping(vm.mapping).then(function(response){
        $scope.$emit('ezyprocureApp:bsMapped', vm.mapping);
        NotificationService.success({
          title: 'Business - Supplier',
          message: 'Mapped successfully'
        });
        vm.isBusinessSupplierMapped(vm.mapping);
      },function(error) {
        NotificationService.error({
          title: 'Mapping Business - Supplier',
          error: error
        });
      });
    }

    function deleteBusinessSupplierMapping() {
      // Map selected buyer and supplier
      BusinessSupplierMapService.deleteBusinessSupplierMapping(vm.mapping).then(function(response){
        $scope.$emit('ezyprocureApp:bsUnmapped', vm.mapping);
        NotificationService.success({
          title: 'Business - Supplier',
          message: 'Mapping deleted successfully'
        });
        vm.isBusinessSupplierMapped(vm.mapping);
      },function(error) {
        NotificationService.error({
          title: 'Business - Supplier - Delete Mapping',
          error: error
        });
      });
    }

    function getCSVBase64() {
      var csvFile = vm.files[0];
      if(!csvFile){
        toastr.error('Please upload a .csv file!', 'CSV File Required');
        return false;
      }
      CsvUploadService.getFileBase64(csvFile.lfFile, function(base64CSV) {
        vm.csvUploadData = {
          encodedCsvFile: base64CSV
        };
        vm.mapBusinessToSupplierCSV(vm.csvUploadData);
      });
    }

    function mapBusinessToSupplierCSV(uploadData) {
      BusinessSupplierMapService.mapBusinessToSupplierCSV(uploadData).then(function(response){
        vm.files = [];
        NotificationService.success({
          title: 'Bulk Map Business to Suppliers',
          message: 'Done successfully'
        });
      },function(error){
        vm.recordError = error.data;
        NotificationService.error({
          title: 'Bulk Map Business to Suppliers',
          error: error
        });
      });
    }
    
    // Creating the business area
    function createBusinessArea(mapping){
      mapping.buyerBusinessArea = mapping.buyerBusinessArea ? mapping.buyerBusinessArea : mapping.searchText;
      _.find(vm.businessAreas, function(area){
        if(_.toLower(area) == _.toLower(mapping.buyerBusinessArea)){
          mapping.buyerBusinessArea = area;
        };
      });
      mapping.buyerBusinessArea == '' ? mapping.buyerBusinessArea = null :'';
      BusinessSupplierMapService.updateBusinessSupplierMapping(mapping).then(function(res){
        NotificationService.success({
          title: 'Business Supplier Mapping',
          message: 'Done successfully'
        });
        _.map(vm.mappedSuppliers, function(each){
          each.searchText = null;
        }) 
        vm.getAllBusinessAreas(); 
      }, function(){
      })
    }

    function getAllBusinessAreas(){
      BusinessSupplierMapService.getAllBusinessAreas({
        page: 0,
        size: paginationConstants.maxLimit,
        sort: ''
      })
      .then(function(response){
        vm.mappedBusinessAreas = response.data;
        vm.loadAll();
        _.map(vm.mappedSuppliers, function(each){
          each.searchText = null;
        }) 
      }
      ,function(){
      });
    }

    function getAreas(supplierId, query){
      var deferred = $q.defer();
      var filteredArray = _.filter(vm.mappedBusinessAreas, function(mapping){
        return supplierId == mapping.supplier.id;
      });
      vm.businessAreas = _.without(_.uniq(_.map(filteredArray,_.property('buyerBusinessArea'))),null);
      vm.businessAreas = query ? $filter('filter')(vm.businessAreas, query) : vm.businessAreas
      deferred.resolve(vm.businessAreas);
      return deferred.promise;
    }
  }
})();
