/**
* @Author: Mufsir
* @Date:   06-Jan-2017 16:03 +05:30
* @Project: Ezyprocure
* @Filename: docs.state.js
* @Last modified by:   Ajay
* @Last modified time: 08-Mar-2017 15:25 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('docs', {
      parent: 'app',
      url: '/docs',
      data: {
        authorities: ['ROLE_ADMIN', 'ROLE_BUYER', 'ROLE_SUPPLIER'],
        pageTitle: 'global.menu.admin.apidocs'
      },
      views: {
        'content@': {
          templateUrl: 'app/admin-modules/docs/docs.html'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', function($translate) {
          return $translate.refresh();
        }]
      }
    });
  }
})();
