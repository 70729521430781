/**
* @Author: sneha
* @Date:   16-Apr-2018 17:51 +05:30
* @Project: Ezyprocure
* @Filename: supplier.picking-list.edit-item-select.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 11-May-2018 12:39 +05:30
*/

(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierPickingListManageItemsController', SupplierPickingListManageItemsController);

  SupplierPickingListManageItemsController.$inject = ['$scope', '$rootScope', '$state', '$filter', '$stateParams', 'NotificationService', '$mdDialog', 'SupplierItemService', 'pagingParams', 'paginationConstants', 'SupplierPickingListService', 'toastr'];

  function SupplierPickingListManageItemsController($scope, $rootScope, $state, $filter, $stateParams, NotificationService, $mdDialog, SupplierItemService, pagingParams, paginationConstants, SupplierPickingListService, toastr) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.templateId = $stateParams.templateId; //id of the template to be edited
    vm.showMore = showMore;
    vm.loadAll = loadAll;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.getPickingListById = getPickingListById;
    vm.addItemsToList = addItemsToList;
    vm.supplierItems = [];
    vm.selectAllItems = selectAllItems;
    vm.deselectAll = deselectAll;

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
    * [loadAll load All supplier items]
    * @param  {[type]} search [search param]
    * @return {[type]}        [description]
    */
    function loadAll (search) {
      if (!search) {
        vm.searchText = '';
      }
      // empty supplierItem object if on first page and if search param is present
      else if (search==='search' && vm.query.page === 1){
        vm.supplierItems = [];
      }
      // empty both search param and supplierItem object if user clicks on refresh button
      else if (search === 'reload'){
        vm.supplierItems = [];
        vm.searchText = '';
      }
      SupplierItemService.getAllSupplierItems({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        buyerId: '',
        query: vm.searchText
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.hasMore = _.gt(parseInt(vm.totalItems), (vm.query.page  * vm.query.limit));
        vm.supplierItems = vm.supplierItems.concat(response.data);
        vm.pageDetails = "Showing " + (vm.supplierItems.length === 0 ? "0" : "1") + " to " + $filter('number')(vm.supplierItems.length) + " of " + $filter('number')(vm.totalItems) + " entries";
        vm.getPickingListById();
      }, function(error) {
        NotificationService.error({
          title: 'Items Listing',
          error: error
        });
      });
    }

    vm.loadAll();
    function showMore() {
      vm.query.page += 1;
      if(vm.searchText!='')
       vm.loadAll('search');
      else {
        vm.loadAll();
      }
    }

    /**
    * [selectAllItems select all supplier items]
    * @return {[type]} [description]
    */
    function selectAllItems() {
      vm.selectAll ? vm.selectAll = true : vm.selectAll = false;
      _.map(vm.supplierItems, function(supplierItem) {
        supplierItem.selected = vm.selectAll;
      });
    }

    /**
    * [deselectAll deselect all items on clicking cancel]
    */
    function deselectAll(){
      SupplierPickingListService.deselectAllItems(vm.supplierItems);
    }

    /**
    * [getPickingListById get picking list by id and select all corresponding supplier items]
    * @return {[type]} [description]
    */
    function getPickingListById(){
      SupplierPickingListService.getPickingListById(vm.templateId).then(function(response) {
        vm.templateList = response;
        _.map(vm.templateList.items, function(item){
          var index = _.findIndex(vm.supplierItems, ['id', item.id]);
          vm.supplierItems[index].selected ? true : vm.supplierItems[index].selected= true;
        });
      }, function(error) {
        NotificationService.error({
          title: 'Picking List',
          error: error
        });
      });
    }

    /**
    * [addItemsToList store selectedItems in a temporary object in service]
    */
    function addItemsToList(){
      var selectedList ={};
      selectedList.pickingList = angular.copy(vm.templateList.pickingList)
      selectedList.items = angular.copy(vm.supplierItems);
      var itemList = SupplierPickingListService.addItemsToList(selectedList);
      // number of items in picking list template should be greater than 6 and less than or equal to 100
      if(itemList.items.length>=6){
        if(itemList.items.length > 100)
         toastr.error('Only 100 items are allowed!', 'Too many Items');
        else
         $state.go('supplier.picking-list-management.picking-list.edit', {reload:false,  'templateId':vm.templateId})
      }
      else {
        toastr.error('Please select atleast 6 items to proceed!', 'Items Required');
      }
    }

  }
})();
