/**
* @Author: Ajay
* @Date:   20-Apr-2017 16:40 +05:30
* @Project: Ezyprocure
* @Filename: supplier.invoice-report.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-07-26T15:33:53+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierInvoiceReportController', SupplierInvoiceReportController);

  SupplierInvoiceReportController.$inject = ['$scope', '$q','$rootScope', '$filter', '$translate', '$state', '$stateParams', 'pagingParams', 'toastr', '$mdDialog', 'UTCDateService', 'PurchaseOrderService', 'BuyerOCRService', 'NotificationService', 'ItemGroupService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'CSVExportService', 'EXPORT_CONSTANTS','CallbackResourceService','XeroResourceService','InvoiceService', 'GLOBAL_CONFIG_CONSTANTS', 'SupplierDataService', 'Auth', 'BuyerSupplierMappingService', 'BuyerBusinessService','BuyerAdhocPOFormService'];

  function SupplierInvoiceReportController($scope, $q,$rootScope, $filter, $translate, $state, $stateParams, pagingParams, toastr, $mdDialog, UTCDateService, PurchaseOrderService, BuyerOCRService, NotificationService, ItemGroupService, GLOBAL_CONSTANTS, paginationConstants, CSVExportService, EXPORT_CONSTANTS, CallbackResourceService,XeroResourceService, InvoiceService, GLOBAL_CONFIG_CONSTANTS, SupplierDataService, Auth, BuyerSupplierMappingService, BuyerBusinessService, BuyerAdhocPOFormService) {
    var vm = this;
    vm.init =  init;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.todaysDate = new Date();
    vm.loadItemGroups = loadItemGroups;
    vm.loadAll = loadAll;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.listViews = GLOBAL_CONSTANTS.LIST_VIEWS;
    vm.invStatusClass = GLOBAL_CONFIG_CONSTANTS.INVOICE_STATUS.LABEL_CLASS;
    vm.updateExportedInvoiceStatus=updateExportedInvoiceStatus;

    //Header
    //Function Declaration
    vm.getInvoiceReportCSVData = getInvoiceReportCSVData;
    vm.checkAll = checkAll;
    vm.supplierInvoiceQuickBooksExport = EXPORT_CONSTANTS.CSV.supplierInvoiceQuickBooksCSVHeader;
    vm.supplierInvoiceMillionExport = EXPORT_CONSTANTS.CSV.supplierInvoiceMillionExportCSVHeader;
    vm.connectToXero = connectToXero;
    vm.exportToXero = exportToXero;
    vm.disconnectToXero = disconnectToXero;
    vm.fetchExportToXeroList = fetchExportToXeroList;
    vm.getSelectedPOs = getSelectedPOs;
    vm.validateExportPO = validateExportPO;
    vm.getSelectedPOIds = getSelectedPOIds;
    vm.updateXeroExportFlag = updateXeroExportFlag;
    vm.selectAll = false;
    vm.getConsolidatedInvoice = getConsolidatedInvoice;
    vm.setConsolidatedInvoiceFilters  = setConsolidatedInvoiceFilters;
    vm.exportToQuickBooks=exportToQuickBooks;
    vm.exportData=exportData;
    vm.getAllJurnalExportDetails = getAllJurnalExportDetails;
    vm.getJurnalCSVData = getJurnalCSVData;
    vm.salesInvoiceJurnalHeader = EXPORT_CONSTANTS.CSV.salesInvoiceJurnalCSVHeader;
    vm.exportJurnal = exportJurnal;
    vm.updateJurnalExported = updateJurnalExported;
    vm.accessControls = SupplierDataService.getSupplierProfile().accessDTO;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.exportXeroCSV = exportXeroCSV;
    vm.XeroSalesInvoiceExportCSVHeader = EXPORT_CONSTANTS.CSV.XeroSalesInvoiceExportCSVHeader;
    vm.accessForPriceDisplay = Auth.hasPermission('accessForPriceDisplay');
    vm.getOraganization = getOraganization;
    vm.closeModal = closeModal;
    vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
    vm.getAllBuyersBusinessOutlets = getAllBuyersBusinessOutlets;
    vm.selectAllBuyerAndOutlets = selectAllBuyerAndOutlets;
    vm.deSelectAllBuyerAndOutlets = deSelectAllBuyerAndOutlets;
    vm.exportToMillion = exportToMillion;
    vm.getOcrAcceptedPO = getOcrAcceptedPO;
    vm.millionExportRequestDTO = millionExportRequestDTO;
    vm.updateMillionExportedFromList = updateMillionExportedFromList;
    vm.filter = {
      buyerIdList: [],
      businessIdList: []
    };
    vm.exportToSAGA50 = exportToSAGA50;
    vm.exportSAGA50Request = exportSAGA50Request;
    vm.updateSAGA50ExportedFromList = updateSAGA50ExportedFromList;
    vm.SupplierSAGA50CSVHeader = EXPORT_CONSTANTS.CSV.SupplierSAGA50CSVHeader;
    vm.getProcessedInvoice = getProcessedInvoice;
    vm.resetFilters = resetFilters;
    vm.exportToEzyfinance = exportToEzyfinance;

    //Export invoices to ezy2finance external api
    function exportToEzyfinance(){
      var selectedPos = [];
      var poNumList = [];
      var data = {};
      vm.errorList = [];
      selectedPos = getSelectedPOs(vm.poItems);
      if(!selectedPos || selectedPos.length == 0){
        toastr.error($translate.instant('alertMessage.PLEASE_SELECT_ATLEAST_ONE_RECORD'), 'Export To Ezy2Finance - Failed!');
        return false;
      }
      _.forEach(selectedPos, function(po){
        data = {
          "key":po.purchaseOrder.purchaseOrderNumber,
          "value":po.purchaseOrder.processOrder.deliveryDate
        }
        poNumList.push(data);
      });
      InvoiceService.exportToEzyfinance(poNumList).then(function(response){
        vm.errorList = _.filter(response.data, function(each){
          return each.status == 'FAILED';
        })
        if(!vm.errorList){
          NotificationService.success({
            title: 'Export To Ezy2Finance',
            message: 'success'
          });
        }
        else if(vm.errorList.length == 1){
          toastr.error(vm.errorList[0].message, 'Export To Ezy2Finance - Failed');
        }
      },
      function(error){
        NotificationService.simpleErrorToast({
          title: 'Export To Ezy2Finance',
          message: error
        });
      });
    }

    /**
    * filters out po that is ocr accepted
    * @param {object} selectedPOs
    */
    function getOcrAcceptedPO(selectedPOs) {
      var ocrAcceptedPOs = _.filter(selectedPOs, function (po) {
        if (po.purchaseOrder.ocrAcceptanceStatus == 'ACCEPTED' && po.purchaseOrder.invoiceStatus == 'PROCESSED') {
          return po;
        }
      });
      return ocrAcceptedPOs;
    }

    /**
    * filters out po that is Processed Invoice only
    * @param {object} selectedPOs
    */
   function getProcessedInvoice(selectedPOs) {
    var processedInvoice = _.filter(selectedPOs, function (po) {
      if (po.purchaseOrder.invoiceStatus == 'PROCESSED') {
        return po;
      }
    });
    return processedInvoice;
  }

    /**
    * creates object with deliveryDate and purchaseOrderNumber for million export
    * @param {object} selectedPOs
    */
    function millionExportRequestDTO(selectedPOs) {
      var purchaseOrderIdList = [];
      _.map(selectedPOs, function (item) {
        purchaseOrderIdList.push({
          'deliveryDate': item.purchaseOrder.processOrder.deliveryDate,
          'purchaseOrderNumber': item.purchaseOrder.purchaseOrderNumber,
        });
      });
      return purchaseOrderIdList;
    }

    function updateMillionExportedFromList(purchaseOrderIdList) {
      _.forEach(vm.poItems, function (po, key) {
        if (_.includes(purchaseOrderIdList, po.purchaseOrder.id)) {
          vm.poItems[key].millionExported = true;
        }
      });
    }

    // csv template export to million
    function exportToMillion() {
      // get purchase order id list
      try {
        var purchaseOrderIdList = [];
        var selectedPOs = getSelectedPOs(vm.poItems);
        var ocrAcceptedPO = getOcrAcceptedPO(selectedPOs);
        if (!selectedPOs.length) {
          throw 'NO_ORDER_SELECTED';
        }
        else if (ocrAcceptedPO.length != selectedPOs.length) {
          throw 'SOME_PO_NOT_ACCEPTED';
        }
        purchaseOrderIdList = millionExportRequestDTO(ocrAcceptedPO);
        // get export data
        var deferred = $q.defer();
        BuyerOCRService.getARMillionExportData(purchaseOrderIdList)
          .then(function (response) {
            var exportMillionOrderTemplate = CSVExportService.generateARMillionOrderCSVData(response.data);
            deferred.resolve(exportMillionOrderTemplate);
            NotificationService.success({
              title: 'global.userAccessTypes.millionExport',
              message: 'alertMessage.ACCEPTED_SUCCESSFULLY'
            });
            var selectedPOIds = getSelectedPOIds(ocrAcceptedPO);
            updateMillionExportedFromList(selectedPOIds);
          }, function (error) {
            NotificationService.error({
              title: 'global.userAccessTypes.millionExport',
              error: error
            });
            deferred.reject(error);
          });
        return deferred.promise;
      }
      catch (error) {
        var message;
        if (error == 'NO_ORDER_SELECTED') {
          message = 'global.pleaseSelectedOneFromTheList';
        }
        else if (error == 'SOME_PO_NOT_ACCEPTED') {
          message = 'alertMessage.NO_ACCEPTED_OR_PROCESSED_RECORDS_FOUND';
        }
        // show error toaster
        message && NotificationService.simpleErrorToast({
          title: 'global.userAccessTypes.millionExport',
          message: message
        });
      }
    }

      //Get all buyers mapped to the supplier
      function getAllSupplierMappedBuyers() {
        vm.filter.businessIdList = [];
        vm.bussinessOutlets = [];
        var params = {
          page: 0,
          size: paginationConstants.maxLimit,
        };
        if (vm.buyers)
          return false;
        var supplierId = SupplierDataService.getSupplierProfile().supplier.id;
        BuyerSupplierMappingService.getAllSupplierMappedBuyers(supplierId, params).then(function (response) {
          vm.buyers = response.data;
        }, function (error) {
        });
      }

      //get all business outlets of the selected buyer
      function getAllBuyersBusinessOutlets() {
        if (!vm.filter.buyerIdList) {
          return false;
        }
        if (vm.filter.businessIdList > 0) {
          return false;
        }
        var params = {
          page: 0,
          size: paginationConstants.maxLimit,
          groupBy: 'buyer'
        };
        BuyerBusinessService.getAllBuyersBusinessOutlets(vm.filter.buyerIdList, params).then(function (response) {
          vm.buyerBusinessOutlets = response.data;
          vm.bussinessOutlets = [];
          _.forEach(vm.buyerBusinessOutlets, function (buyer) {
            _.forEach(buyer.businessDTOList, function (outlets) {
              vm.bussinessOutlets.push(outlets);
            });
          });
        }, function (error) {
        });
      }

      function selectAllBuyerAndOutlets(dropdown) {
        switch (dropdown) {
          case 'BUYERS':
            vm.filter.buyerIdList = _.map(vm.buyers, _.property('id'));
            break;
          case 'BUSINESSOUTLETS':
            vm.filter.businessIdList = _.map(vm.bussinessOutlets, _.property('businessId'));
            break;
        }
      }

      /**
       * [deSelectAllBuyerAndOutlets Deselect all from the list]
       * @param  {[type]} dropdown [Active dropdown - outlets or brands]
       */
      function deSelectAllBuyerAndOutlets(dropdown) {
        switch (dropdown) {
          case 'BUYERS':
            vm.filter.buyerIdList = [];
            break;
          case 'BUSINESSOUTLETS':
            vm.filter.businessIdList = [];
            break;
        }
      }


    function init(){
      BuyerAdhocPOFormService.gstConfigDetails().then(function (res) {
        localStorage.setItem("gst-config", JSON.stringify(res[0].configValue));
      });
      if(vm.accessForPriceDisplay)
        vm.InvoiceReportHeader = EXPORT_CONSTANTS.CSV.SupplierInvoiceReport;
      else {
        vm.InvoiceReportHeader = _.without(EXPORT_CONSTANTS.CSV.SupplierInvoiceReport, 'Unit Price' , 'Amount');
      }
    }


    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function loadItemGroups() {
      if(vm.itemGroups)
        return false;
      ItemGroupService.getAllItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.itemGroups = response.data;
      }, function(error) {
      });
    }

    /**
    * [loadAll Get all Purchase Order Details]
    */
    function loadAll() {
      if(vm.filter.listView === "Individual"){
        getIndividualPODetails();
      }
    }

    /**
    * [getIndividualPODetails  gets PO details for each PO Number]
    */
    function getIndividualPODetails() {

      PurchaseOrderService.getAllPurchaseOrders({
        poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber,
        purchaseOrderStatus: '',
        invoiceStatus: 'PROCESSED',
        invoiceNumber: !vm.filter.invoiceNumber ? '' : vm.filter.invoiceNumber,
        businessOutletId: vm.filter.businessIdList.length == 0 ? '': vm.filter.businessIdList,
        businessOutletName: !vm.filter.businessOutletName ? '' : vm.filter.businessOutletName,
        hardcopyInvoiceNumber: !vm.filter.hardcopyInvoiceNumber ? '' : vm.filter.hardcopyInvoiceNumber,
        //If dates are selected, they are converted to UTC format
        poFromDate: !vm.filter.poFromDate ? '' : UTCDateService.utcFromDate(vm.filter.poFromDate),
        poToDate: !vm.filter.poToDate ? '' : UTCDateService.utcToDate(vm.filter.poToDate),
        invoiceFromDate: !vm.filter.invoiceFromDate ? '' : UTCDateService.utcFromDate(vm.filter.invoiceFromDate),
        invoiceToDate: !vm.filter.invoiceToDate ? '' : UTCDateService.utcToDate(vm.filter.invoiceToDate),
        deliveryFromDate: !vm.filter.deliveryFromDate ? '' : UTCDateService.utcFromDate(vm.filter.deliveryFromDate),
        deliveryToDate: !vm.filter.deliveryToDate ? '' : UTCDateService.utcToDate(vm.filter.deliveryToDate),
        grnFromDate : !vm.filter.grnFromDate ? '' : UTCDateService.utcFromDate(vm.filter.grnFromDate),
        grnToDate : !vm.filter.grnToDate ? '' : UTCDateService.utcToDate(vm.filter.grnToDate),
        query: '',
        supplierId: '',
        itemGroupId: !vm.filter.itemGroupId ? '' : vm.filter.itemGroupId,
        buyerIdList: vm.filter.buyerIdList.length ==  0 ? '' : vm.filter.buyerIdList,
        debitNoteStatus: '',
        grnNumber: '',
        buyerName: '',
        creditNoteStatus: '',
        supplierVisibilityType: '',
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        xeroExported: vm.filter.xeroExported ? vm.filter.xeroExported : '',
        module : 3
      }).then(function(response){
        vm.poItems = response.data;
        vm.totalItems = response.headers('X-Total-Count');
        vm.selectAll=false;
      },function(error){
        NotificationService.error({
          title: 'Purchase Order Details',
          error: error
        });
      });
    }

    function checkAll() {
      vm.selectAll ? vm.selectAll = true : vm.selectAll = false;
      angular.forEach(vm.poItems, function(item) {
        item.purchaseOrder.selected = vm.selectAll;
      });
    }

    function getSelectedPOs(poItems) {
      var selectedPOs = _.filter(poItems, function(po) {
        return po.purchaseOrder.selected === true;
      });
      return selectedPOs;
    }

    function getSelectedPOIds(selectedPOs) {
      var purchaseOrderIdList = _.map(selectedPOs, _.property('purchaseOrder.id'));
      return purchaseOrderIdList;
    }

     function connectToXero() {
      CallbackResourceService.redirectUrl({
        module : 3,
      }).then(function(response){
      },function(error){
        NotificationService.error({
          title: 'global.buttons.connectXERO',
          error: error
        });
      });
    }

    function disconnectToXero() {
      vm.organisationName = "";
      CallbackResourceService.disconnectToXero().then(function(response){
        vm.xero = response.xero;

        NotificationService.success({
          title: response.data.status,
          message: response.data.message
        });

      },function(error){
        NotificationService.error({
          title: 'global.buttons.disconnectXERO',
          error: error
        });
      });
    }

    function fetchExportToXeroList() {
      var purchaseOrderIdList = [];
      var selectedPOs = vm.getSelectedPOs(vm.poItems);
      if(vm.validateExportPO()){
        purchaseOrderIdList = vm.getSelectedPOIds(selectedPOs);
      }
      return purchaseOrderIdList;
    }

    function validateExportPO() {
      var validSelection = true;
      return validSelection;
    }


    function exportToXero() {

      var purchaseOrderIdList = fetchExportToXeroList();

      if(vm.validateExportPO()){
        if(angular.isDefined(vm.poItems) && purchaseOrderIdList.length > 0){

          XeroResourceService.exportSupplierInvoiceToXero({
            sort: sort(),
            purchaseOrderStatus : '',
            poNumber : !vm.filter.poNumber ? '' : vm.filter.poNumber,
            fromDate : !vm.filter.poFromDate ? '' : UTCDateService.utcFromDate(vm.filter.poFromDate),
            toDate : !vm.filter.poToDate ? '' : UTCDateService.utcToDate(vm.filter.poToDate),
            businessOutletId: !vm.filter.businessOutletId ? '': vm.filter.businessOutletId,
            businessOutletName : '',
            itemName : '',
            supplierId : '',
            itemGroupId : !vm.filter.itemGroupId ? '' : vm.filter.itemGroupId,
            buyerName : '',
            purchaseOrderIdList : (purchaseOrderIdList.length === 0) ? [''] : purchaseOrderIdList,
            module : 3,
            query : '',
            supplierVisibilityType : '',
            invoiceStart : '',
            invoiceEnd : '',
            invoiceNumber : !vm.filter.invoiceNumber ? '' : vm.filter.invoiceNumber
          }).then(function(response){
            vm.message = response.headers('X-ezyprocureApp-alert')
            NotificationService.success({
              title: 'global.buttons.exportXERO',
              message: vm.message
            });
            vm.updateXeroExportFlag(purchaseOrderIdList);
            vm.selectAll = false;
            setTimeout(function(){
              vm.loadAll();
            },60000);
          },function(error){
            NotificationService.error({
              title: 'global.buttons.exportXERO',
              error: error
            });
            getTokenExpiry();
            disableConnectToXeroBtn();
          });
        } else {
          NotificationService.simpleErrorToast({
            title: 'global.buttons.exportXERO',
            message: 'alertMessage.SEARCH_AND_SELECT'
          });
        }
      } else {
        NotificationService.simpleErrorToast({
          title: 'global.buttons.exportXERO',
          message: 'alertMessage.ACCEPTED_INVOICES'
        });
      }
    }

    function updateXeroExportFlag(purchaseOrderIdList) {
      _.forEach(vm.poItems, function(po, key) {
        if(_.includes(purchaseOrderIdList, po.purchaseOrder.id)){
          vm.poItems[key].purchaseOrder.invoice.xeroInvExported = undefined;
        }
      });
    }

    function getTokenExpiry() {

      CallbackResourceService.getTokenExpiry(
      ).then(function(response){
        vm.xero = response;
      },function(error){
        NotificationService.error({
          title: 'global.buttons.connectXERO',
          error: error
        });
      });

    }

    function disableConnectToXeroBtn() {

      CallbackResourceService.getAuthTimeout(
      ).then(function(response){
        vm.xero = response;
        if(window.location.href.indexOf("?") > -1) {
          var code = window.location.href.split('code=')[1].split('&scope=')[0];
          var scope = window.location.href.split('scope=')[1].split('&session_state=')[0];
          var session_state = window.location.href.split('session_state=')[1].split('&state')[0];
          var state = window.location.href.split('state=')[1];
          CallbackResourceService.xeroCallback({ code: code, scope: scope, sessionState: session_state,state: state })
          .then(function(response){
            vm.xero = response;
            vm.organisationdata = response.data.data;
            if(vm.organisationdata.length == 1){
              vm.tenantId = vm.organisationdata[0].tenantId;
              vm.getOraganization();
            } else {
              $mdDialog.show({
                templateUrl: 'app/core/common-templates/xero-multiple-organization.html',
                controller: function() {
                  return vm;
                },
                controllerAs: 'vm',
                targetEvent: event,
                clickOutsideToClose: true
              });
            }
            code = null;
            scope = null;
            session_state=null;
            state=null;
          }, function(error){
            vm.xero = CallbackResourceService.getXeroConnectionParams();
            NotificationService.error({
              title: 'global.buttons.connectXERO',
              error: error
            });
          });
        }
      },function(error){
        NotificationService.error({
          title: 'global.buttons.connectXERO',
          error: error
        });
      });
    }

    function getOraganization(){
      closeModal();
      var params ={
        tenantId: vm.tenantId
      };
      CallbackResourceService.xeroConnect(params).then(function(response){
        vm.xero = response;
        vm.organisationName = vm.xero.data.responseList[0].reason;
        NotificationService.success({
          title: 'global.buttons.connectXERO',
          message: 'alertMessage.XERO.SUCCESS'
        });
      }, function(error){
        NotificationService.error({
          title: 'global.buttons.connectXERO',
          error: error
        });
      });
     }

     function closeModal() {
      $mdDialog.cancel();
    }


    function getConsolidatedInvoice(){
    var deferred = $q.defer();
    InvoiceService.getConsolidatedInvoiceDetails({
        poFromDate: !vm.filter.poFromDate ? '' : UTCDateService.utcFromDate(vm.filter.poFromDate),
        poToDate: !vm.filter.poToDate ? '' : UTCDateService.utcToDate(vm.filter.poToDate),
        invoiceFromDate: !vm.filter.invoiceFromDate ? '' : UTCDateService.utcFromDate(vm.filter.invoiceFromDate),
        invoiceToDate: !vm.filter.invoiceToDate ? '' : UTCDateService.utcToDate(vm.filter.invoiceToDate),
        deliveryFromDate: !vm.filter.deliveryFromDate ? '' : UTCDateService.utcFromDate(vm.filter.deliveryFromDate),
        deliveryToDate: !vm.filter.deliveryToDate ? '' : UTCDateService.utcToDate(vm.filter.deliveryToDate),
        grnFromDate : !vm.filter.grnFromDate ? '' : UTCDateService.utcFromDate(vm.filter.grnFromDate),
        grnToDate : !vm.filter.grnToDate ? '' : UTCDateService.utcToDate(vm.filter.grnToDate),
        reportType: "INVOICE",
        page: vm.query.page - 1,
        size: paginationConstants.maxLimit,
        poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber,
        purchaseOrderStatus: '',
        invoiceStatus: 'PROCESSED',
        invoiceNumber: !vm.filter.invoiceNumber ? '' : vm.filter.invoiceNumber,
        businessOutletId: !vm.filter.businessIdList ? '': vm.filter.businessIdList,
        businessOutletName: !vm.filter.businessOutletName ? '' : vm.filter.businessOutletName,
        itemGroupId: !vm.filter.itemGroupId ? '' : vm.filter.itemGroupId,
        buyerId: vm.filter.buyerIdList ? vm.filter.buyerIdList : '',
        hardCopyInvoiceNumber: vm.filter.hardcopyInvoiceNumber ? vm.filter.hardcopyInvoiceNumber : '',
        xeroExported: vm.filter.xeroExported ? vm.filter.xeroExported : '',
      }).then(function(response){
        deferred.resolve(response.data);
      },function(error){
        NotificationService.error({
          title: 'Invoice Report Details',
          error: error
        });
    }
  );
  return deferred.promise;

  }

   //Getting CSV DATA
    function getInvoiceReportCSVData() {
      vm.invoiceData = [];
      var deferred = $q.defer();
      vm.getConsolidatedInvoice().then(function (response) {
        if (_.isEmpty(response)) {
          toastr.error('No records found', 'Invoice Report Details!');
          return deferred.promise;
        } else {
          vm.invoiceData = CSVExportService.getSupplierInvoiceReportCSV(response);
          deferred.resolve(vm.invoiceData);
        }
      }, function (error) {
        NotificationService.error({
          title: 'Invoice Report Details',
          error: error
        });
        deferred.reject(error);
      });
      return deferred.promise;
    }


     vm.accessControls.accessForXeroExport ? disableConnectToXeroBtn(): '';


     function setConsolidatedInvoiceFilters() {
      InvoiceService.setConsolidatedInvoiceFilters(vm.filter);
      $state.go('supplier.reports.invoice-report.consolidated-details', {
        reload: false
        });
    }

    /**
     * [exportToQuickBooks   export invoice]
     */
    function exportToQuickBooks() {
      var deferred = $q.defer();
      vm.exportData('QuickBooks').then(function(response){
        if(_.isEmpty(response.data)){
          toastr.error('No accepted records found', 'Export QuickBooks Error!');
          return deferred.promise;
        }else {
          var quickBooksExport = CSVExportService.getBatchQuickBooksCSV(response.data, 'QuickBooks','SUPPLIER');
          vm.invoiceIds=_.map(response.data, _.property('invoiceId'));
          vm.updateExportedInvoiceStatus('SUPPLIER_QUICKBOOK_INVOICE_EXPORT',vm.invoiceIds);
          deferred.resolve(quickBooksExport);
        }
      },function(error){
        NotificationService.error({
          title: 'Export QuickBooks',
          error: error
        });
        deferred.reject(error);
      });
      return deferred.promise;
    }

    /**
     * [updateExportedInvoiceStatus Update export status for data exported]
     * @param  {[List]} invoiceIdList    [Invoice id list of the data exported]
     * @param  {[String]} reportType          [Type of report to be exported]
     */
    function updateExportedInvoiceStatus(reportType,invoiceIdList) {
      InvoiceService.updateExportedInvoiceStatus({invoiceExportTypes:reportType},invoiceIdList).then(function(response){
        vm.poItems = InvoiceService.updateReportExportedFromList(vm.poItems, invoiceIdList, 'SALES', 'OTHERS');
        vm.loadAll();
      },function(error){
        NotificationService.error({
          title: 'Updating QuickBooks Exported Status',
          error: error
        });
      });
    }

    /**
     * [exportData Export data for selected invoice]
     * @param  {[String]} reportType [type of report to be exported]
     * @return {[Object]}            [Data to be exported]
     */
     function exportData(actionType) {
      var deferred = $q.defer();
      var invoiceIdList = [];
      var selectedPOs = vm.getSelectedPOs(vm.poItems);
      invoiceIdList=InvoiceService.getInvoiceIds(selectedPOs);
      if(invoiceIdList.length > 0) {
        if(actionType == 'QuickBooks'){
          if(invoiceIdList.length>100){
          invoiceIdList= _.slice(invoiceIdList, 0, 99);
        }
       }
        var quickbooksExportDTO = {
          invoiceIdList : invoiceIdList
        };
        InvoiceService.getSupplierInvoiceQuickBooksExportData(quickbooksExportDTO).then(function(response){
          deferred.resolve(response.data);
        },function(error){
          deferred.reject(error);
        });
        return deferred.promise;
      }
      /**
      * [if no selection is made QuickBooks based on search query]
      */
      else if (_.isEmpty(invoiceIdList) && selectedPOs.length === 0) {
        InvoiceService.getSupplierInvoiceQuickBooksExportData({
          sort: sort(),
            poNumber : !vm.filter.poNumber ? '' : vm.filter.poNumber,
            fromDate : !vm.filter.poFromDate ? '' : UTCDateService.utcFromDate(vm.filter.poFromDate),
            toDate : !vm.filter.poToDate ? '' : UTCDateService.utcToDate(vm.filter.poToDate),
            invoiceFromDate: !vm.filter.invoiceFromDate ? '' : UTCDateService.utcFromDate(vm.filter.invoiceFromDate),
            invoiceToDate: !vm.filter.invoiceToDate ? '' : UTCDateService.utcToDate(vm.filter.invoiceToDate),
            deliveryFromDate: !vm.filter.deliveryFromDate ? '' : UTCDateService.utcFromDate(vm.filter.deliveryFromDate),
            deliveryToDate: !vm.filter.deliveryToDate ? '' : UTCDateService.utcToDate(vm.filter.deliveryToDate),
            businessOutletName : !vm.filter.businessOutletName?'':vm.filter.businessOutletName,
            itemGroupId :!vm.filter.itemGroupId ? null: vm.filter.itemGroupId,
            invoiceStatus:'PROCESSED',
            invoiceIdList:(invoiceIdList.length==0)?[]:invoiceIdList,
            query : '',
            invoiceNumber : !vm.filter.invoiceNumber ? '' : vm.filter.invoiceNumber,
            exported: vm.filter.xeroExported == true ? true : vm.filter.xeroExported
        }).then(function(response){
          deferred.resolve(response.data);
        },function(error){
          NotificationService.error({
            title: 'Fetching '+actionType+' Export Details',
            error: error
          });
          deferred.reject(error);
        });
        return deferred.promise;
      }
      else {
        return deferred.promise;
      }
    }



    /**
    * [getJurnalCSVData get jurnal CSV data]
    * @return {[type]} [description]
    */
    function getJurnalCSVData() {
      var deferred = $q.defer();
      vm.getAllJurnalExportDetails().then(function(response){
        if(_.isEmpty(response)){
          NotificationService.simpleErrorToast({
            title: 'global.userAccessTypes.exportToJurnal',
            message: 'global.table.NO_RECORDS_FOUND'
          });
          return deferred.promise;
        }else {
          var exportPO = CSVExportService.getSalesInvoicesJurnalCSV(response);
          deferred.resolve(exportPO);
        }
      },function(error){
        NotificationService.simpleErrorToast({
          title: 'global.userAccessTypes.exportToJurnal',
          message: error
        });
        deferred.reject(error);
      });
      return deferred.promise;
    }


    /**
    * [updateJurnalExported Mark jurnal exported flag for the list of purchase orders in DB]
    * @param  {[type]} exportedPOs [exportedPOs after export]
    * @return {[type]}             [description]
    */
    function updateJurnalExported(exportedPOs) {
      var poIdList = InvoiceService.getExportedPOIds(exportedPOs);
      InvoiceService.updateExportedPOStatus({
        invoiceExportTypes: "SALES_JURNAL"
      },poIdList).then(function(response){
        vm.poItems = InvoiceService.updateReportExportedFromList(vm.poItems, poIdList, 'SALES', 'JURNAL');
      },function(error){
        NotificationService.error({
          title: 'Updating Jurnal Exported Status',
          error: error
        });
      });
    }

    /**
    * [exportJurnal export PO to Jurnal]
    * @return {[type]} [description]
    */
    function exportJurnal() {
      var deferred = $q.defer();
      vm.getAllJurnalExportDetails().then(function(response){
        if(_.isEmpty(response)){
          toastr.error('No accepted records found', 'Export Jurnal Error!');
          return deferred.promise;
        }else {
          var invoiceExportJurnal = CSVExportService.getSalesInvoicesJurnalCSV(response);
          vm.updateJurnalExported(response);
          deferred.resolve(invoiceExportJurnal);
        }
      },function(error){
        NotificationService.error({
          title: 'Export Jurnal',
          error: error
        });
        deferred.reject(error);
      });
      return deferred.promise;
    }

    /**
    * [getAllJurnalExportDetails get data for Jurnal Export from DB by passing PO id list]
    * @return {[type]} [description]
    */
    function getAllJurnalExportDetails(){
      var deferred = $q.defer();
      vm.purchaseOrderIdList = [];
      var selectedPOs = vm.getSelectedPOs(vm.poItems);
      vm.purchaseOrderIdList = vm.getSelectedPOIds(selectedPOs);
      PurchaseOrderService.getPOJurnalExportListing({
        jurnalExportType: 'SALES'
      },vm.purchaseOrderIdList).then(function(response){
        deferred.resolve(response.data);
      },function(error){
        NotificationService.simpleErrorToast({
          title: 'global.fetchingCSVExportDetails',
          error: error
        });
        deferred.reject(error);
      });
      return deferred.promise;
    }

    /**
     * [exportXeroCSV Export CSV Data for XERO]
     */
    function exportXeroCSV() {
       var purchaseOrderIdList = vm.fetchExportToXeroList();
       var selectedPOs = vm.getSelectedPOs(vm.poItems);
       var deferred = $q.defer();
        if(_.isEmpty(vm.poItems)){
        NotificationService.simpleErrorToast({
          title: 'global.exportXEROCSV',
          message: 'alertMessage.SEARCH_AND_SELECT'
        });
      }
      else if (_.isEmpty(selectedPOs)) {
        NotificationService.simpleErrorToast({
          title: 'global.exportXEROCSV',
          message: 'entity.validation.mdRequireMatch'
        });
        return deferred.promise;
      }
      else {
      vm.exportData('XERO_CSV').then(function (response) {
        if (_.isEmpty(response)) {
          toastr.error('No accepted records found', 'Export XERO_CSV Error!');
          return deferred.promise;
        } else {
          var salesInvoiceXero = CSVExportService.getBatchQuickBooksCSV(response.data, 'XERO_CSV','SUPPLIER');
          vm.updateXeroExportFlag(purchaseOrderIdList);
          deferred.resolve(salesInvoiceXero);
        }
       }, function (error) {
        NotificationService.error({
          title: 'Export XERO_CSV',
          error: error
        });
        deferred.reject(error);
      });
    }
    return deferred.promise;
  }

  /**
    * creates object with deliveryDate and purchaseOrderNumber for saga50 export
    * @param {object} selectedPOs
    */
   function exportSAGA50Request(selectedPOs) {
    var purchaseOrderIdList = [];
    _.map(selectedPOs, function (item) {
      purchaseOrderIdList.push({
        'deliveryDate': item.purchaseOrder.processOrder.deliveryDate,
        'purchaseOrderNumber': item.purchaseOrder.purchaseOrderNumber,
      });
    });
    return purchaseOrderIdList;
  }

  function updateSAGA50ExportedFromList(purchaseOrderIdList) {
    _.forEach(vm.poItems, function (po, key) {
      if (_.includes(purchaseOrderIdList, po.purchaseOrder.id)) {
        vm.poItems[key].millionExported = true;
      }
    });
  }

    // csv template export to million
    function exportToSAGA50() {
        // get purchase order id list
        try {
        var purchaseOrderIdList = [];
        var selectedPOs = getSelectedPOs(vm.poItems);
        var ocrAcceptedPO = getOcrAcceptedPO(selectedPOs);
        if (!selectedPOs.length) {
            throw 'NO_ORDER_SELECTED';
        }
        else if (ocrAcceptedPO.length != selectedPOs.length) {
            throw 'SOME_PO_NOT_ACCEPTED';
        }
        purchaseOrderIdList = exportSAGA50Request(ocrAcceptedPO);
        // get export data
        var deferred = $q.defer();
        BuyerOCRService.getSAGA50ExportData(purchaseOrderIdList)
            .then(function (response) {
            var exportSAGA50Template = CSVExportService.generateexportSAGACSVData(response.data);
            deferred.resolve(exportSAGA50Template);
            NotificationService.success({
                title: 'global.userAccessTypes.exportSAGA50',
                message: 'alertMessage.ACCEPTED_SUCCESSFULLY'
            });
            var selectedPOIds = getSelectedPOIds(ocrAcceptedPO);
            updateSAGA50ExportedFromList(selectedPOIds);
            }, function (error) {
            NotificationService.error({
                title: 'global.userAccessTypes.exportSAGA50',
                error: error
            });
            deferred.reject(error);
            });
        return deferred.promise;
        }
        catch (error) {
        var message;
        console.log(error)
        if (error == 'NO_ORDER_SELECTED') {
            message = 'global.pleaseSelectedOneFromTheList';
        }
        else if (error == 'SOME_PO_NOT_ACCEPTED') {
            message = 'alertMessage.NO_ACCEPTED_OR_PROCESSED_RECORDS_FOUND';
        }
        else if (error == 'NO_GL_CODE_FOUND') {
            message = 'alertMessage.NO_GL_CODE_FOUND';
        }
        else if (error == 'NO_GL_CODE_MAPPING_FOUND') {
            message = 'alertMessage.NO_GL_CODE_MAPPING_FOUND';
        }
        // show error toaster
        message && NotificationService.simpleErrorToast({
            title: 'global.userAccessTypes.exportSAGA50',
            message: message
        });
        }
    }
    function resetFilters() {
        vm.filter = {};
        vm.filter.listView = vm.listViews[0];
        vm.filter.poFromDate = vm.todaysDate;
        vm.filter.poToDate = vm.todaysDate;
        vm.filter.businessIdList = [];
        vm.filter.buyerIdList = [];
        vm.poItems = [];
    }
}
})();
