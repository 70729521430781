/**
* @Author: Mufsir
* @Date:   01-Dec-2016 10:46 +05:30
* @Project: Ezyprocure
* @Filename: language.constants.js
 * @Last modified by:   Ajay
 * @Last modified time: 04-Apr-2018 13:11 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')

  /*
  Languages codes are ISO_639-1 codes, see http://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
  They are written in English to avoid character encoding issues (not a perfect solution)
  */
  .constant('LANGUAGES', [
    'en',
    'zh-cn',
    'zh-tw',
    'th',
    'ms',
    'id',
    'de',
    'fr'
    // jhipster-needle-i18n-language-constant - JHipster will add/remove languages in this array
  ]);
})();
