/**
 * @Author: Ajay
 * @Date:   27-Dec-2017 16:11 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.adhoc-po-form.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 08-Jan-2018 14:38 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerAdhocPOFormController', BuyerAdhocPOFormController);

  BuyerAdhocPOFormController.$inject = ['$scope', '$state', 'BuyerAdhocPOFormService'];

  function BuyerAdhocPOFormController($scope, $state, BuyerAdhocPOFormService) {
    var vm = this;
    vm.proceedToAdhocPOForm = proceedToAdhocPOForm;
    vm.adhocPOFormFilter = {
      adhocPOFormType : 'ADHOC_PO_ON_BOARDED_SUPPLIER'
    };

    /**
     * [proceedToAdhocPOForm Proceed to item selection page]
     */
    function proceedToAdhocPOForm() {
      //Save adhoc invoice filter
      BuyerAdhocPOFormService.setAdhocPOFormFilter(vm.adhocPOFormFilter);
      $state.go('buyer.adhoc-po-form.item-selection');
    }

  }
})();
