/**
* @Author: Ajay
* @Date:   06-Apr-2017 20:21 +05:30
* @Project: Ezyprocure
* @Filename: ajax-watcher.directive.js
* @Last modified by:   Ajay
* @Last modified time: 06-Apr-2017 20:30 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .directive('ajaxWatcher', ajaxWatcher);

  ajaxWatcher.$inject = ['$http'];

  function ajaxWatcher($http) {
    var directive = {
      restrict: 'A',
      link: linkFunc
    };

    return directive;

    function linkFunc(scope, element, attrs) {
      //isLoading returns boolean, if yes loading http calls, if no http calls completed
      scope.isLoading = function() {
        return $http.pendingRequests.length > 0;
      };
      scope.$watch(scope.isLoading, function(value)
      {
        if(value) element.show();
        else element.hide();
      });
    }
  }
})();
