/**
* @Author: Ajay
* @Date:   12-May-2017 14:27 +05:30
* @Project: Ezyprocure
* @Filename: buyer.gl-specific-item-group.service.js
* @Last modified by:   Ajay
* @Last modified time: 17-May-2017 17:59 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('GlSpecificItemGroupService', GlSpecificItemGroupService);

  GlSpecificItemGroupService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function GlSpecificItemGroupService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var glSpecificItemGroupService = {
      getAllGlSpecificItemGroups: getAllGlSpecificItemGroups,
      getGlSpecificItemGroup: getGlSpecificItemGroup,
      createGlSpecificItemGroup: createGlSpecificItemGroup,
      updateGlSpecificItemGroup: updateGlSpecificItemGroup,
      deleteGlSpecificItemGroup: deleteGlSpecificItemGroup
    };

    return glSpecificItemGroupService;

    /* --  Buyer Gl Specific ItemGroup CRUID operations  -- */

    //Get All glSpecificItemGroups
    function getAllGlSpecificItemGroups(urlParams) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.GL_SPECIFIC_ITEMGROUP_RESOURCE,
        method: "GET",
        params: urlParams,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get glSpecificItemGroup details using glSpecificItemGroup id
    function getGlSpecificItemGroup(glSpecificItemGroupId) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.GL_SPECIFIC_ITEMGROUP_RESOURCE + glSpecificItemGroupId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Create a new glSpecificItemGroup
    function createGlSpecificItemGroup(glSpecificItemGroup) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.GL_SPECIFIC_ITEMGROUP_RESOURCE, glSpecificItemGroup).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update glSpecificItemGroup
    function updateGlSpecificItemGroup(glSpecificItemGroup) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.GL_SPECIFIC_ITEMGROUP_RESOURCE, glSpecificItemGroup).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Soft Delete glSpecificItemGroup using glSpecificItemGroupId
    function deleteGlSpecificItemGroup(glSpecificItemGroupId) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.GL_SPECIFIC_ITEMGROUP_RESOURCE + glSpecificItemGroupId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
