/**
 * @Author: Vaibhavi
 * @Date:   24-Jun-2018 16:35 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.supplierPerformance.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 12-Oct-2018 14:58 +05:30
 */



(function() {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('SupplierPerformanceController', SupplierPerformanceController);

  SupplierPerformanceController.$inject = ['$scope', '$rootScope', '$q', '$filter', 'toastr','pagingParams', 'UTCDateService','BusinessReportService', 'paginationConstants', 'BuyerBuyNowService', 'CSVExportService', 'EXPORT_CONSTANTS', 'NotificationService', 'GLOBAL_CONSTANTS'];

  function SupplierPerformanceController($scope, $rootScope, $q , $filter, toastr, pagingParams, UTCDateService, BusinessReportService, paginationConstants, BuyerBuyNowService, CSVExportService, EXPORT_CONSTANTS, NotificationService, GLOBAL_CONSTANTS) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.getAllAllocatedSuppliers = getAllAllocatedSuppliers;
    vm.filter = {};
    vm.loadAll = loadAll;
    vm.sort=sort;
    vm.selectAllOrDeselectAll = selectAllOrDeselectAll;
    vm.todaysDate = new Date();
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };

    vm.limitOptions = paginationConstants.limitOptions;
    //CSV Header
    vm.supplierPerformanceHeader = EXPORT_CONSTANTS.CSV.supplierPerformanceHeader;
    //CSV Function Declaration
    vm.getSupplierPerformanceCSVData = getSupplierPerformanceCSVData;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.resetFilters = resetFilters;

    //Getting CSV DATA
    function getSupplierPerformanceCSVData(){
      var deferred = $q.defer();
      if (!vm.supplierPerformanceDetails) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getSupplierPerformanceCSVData(vm.supplierPerformanceDetails,vm.filter.fromDate,vm.filter.toDate);
    }

    /**
    * [sort to sort by supplier details]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }
    /**
    * [getAllAllocatedSuppliers Get all allocated suppliers ]
    */
    function getAllAllocatedSuppliers() {
      if(vm.supplierList)
        return false;
      var params = {'businessId': ''};
      BuyerBuyNowService.getAllAllocatedSuppliersForBusiness(params).then(function(response){
        vm.supplierList = $filter('orderBy')(response.data, 'companyName');
      },function(error){
        NotificationService.error({
          title:"Suppliers cannot be fetched",
          error:error
        });
      });
    }

    /**
     * [selectAll Selects or Deselects all from the list]
     * @param  {[type]} dropdown [Active choice - select or deselect]
     */
    function selectAllOrDeselectAll(choice) {
      switch (choice) {
      case 'SELECTALL':
        vm.filter.supplierIdList = _.map(vm.supplierList, _.property('id'));
        break;
      case 'DESELECTALL':
        vm.filter.supplierIdList = [];
        break;
      }
    }
    /**
    * [loadAll Loads all the user input and returns the response]
    */
    function loadAll() {
      BusinessReportService.getSupplierPerformanceReport({
        fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
        toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
        supplierIdList: !vm.filter.supplierIdList ? []: vm.filter.supplierIdList
      },{
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort()
      }).then(function(response){
        vm.supplierPerformanceDetails = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      },function(error){
        NotificationService.error({
          title:"Failed to fetch Suppliers Performance Report",
          error:error
        });
      });
    }

    function resetFilters() {
        vm.filter = {}
        vm.filter.fromDate = vm.todaysDate;
        vm.filter.toDate = vm.todaysDate;
        vm.supplierPerformanceDetails = null;
    }

  }
})();
