(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .factory('PasswordResetInit', PasswordResetInit);

  PasswordResetInit.$inject = ['$resource', 'API_CONSTANTS'];

  function PasswordResetInit($resource, API_CONSTANTS) {
    var service = $resource('api/' + API_CONSTANTS.baseVersion + 'account/password/reset/init', {}, {});

    return service;
  }
})();
