/**
 * @Author: Lovin
 * @Date:   2018-07-05T15:32:18+05:30
 * @Project: Ezyprocure
 * @Filename: supplier.manage-buyer-identification-code.state.js
 * @Last modified by:   Lovin
 * @Last modified time: 2018-07-05T16:15:31+05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('supplier.tools.manage-buyer-identification-code', {
      parent: 'supplier.tools',
      url: '/manage-buyer-identification-code',
      data: {
        authorities: ['ROLE_SUPPLIER']
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/tools/manage-buyer-identification-code/supplier.manage-buyer-identification-code.html',
          controller: 'BuyerIdentificationCodeController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('buyerSupplierMapping');
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('user-management');
          return $translate.refresh();
        }]
      }
    });
  }

})();
