/**
* @Author: Ajay
* @Date:   24-Feb-2017 12:40 +05:30
* @Project: Ezyprocure
* @Filename: supplier.item.add.controller.js
 * @Last modified by:  Lovin
 * @Last modified time: 18-May-2020 13:55 +05:30
*/



(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('SupplierItemAddController', SupplierItemAddController);

  SupplierItemAddController.$inject = ['$scope', '$state', '$stateParams', '$element', 'toastr', 'ImageUploadService', 'SupplierItemService', 'CsvUploadService', 'UomService', 'ItemGroupService', 'BuyerSupplierMappingService', 'BrandManagementService', 'BuyerBusinessService', 'SupplierDataService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'NotificationService', 'S3_URL_CONSTANTS'];

  function SupplierItemAddController($scope, $state, $stateParams, $element, toastr, ImageUploadService, SupplierItemService, CsvUploadService, UomService, ItemGroupService, BuyerSupplierMappingService, BrandManagementService, BuyerBusinessService, SupplierDataService, GLOBAL_CONSTANTS, paginationConstants, NotificationService, S3_URL_CONSTANTS) {
    var vm = this;

    vm.init = init;
    vm.changeItemType = changeItemType;
    vm.loadUoms = loadUoms;
    vm.loadItemGroups = loadItemGroups;
    vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
    vm.getAllBrandMappedsBusinessOutlets = getAllBrandMappedsBusinessOutlets;
    vm.save = save;
    vm.clearBulkUploadWarnings = clearBulkUploadWarnings;
    vm.setCompanyLogo = setCompanyLogo;
    vm.uploadLogo = uploadLogo;
    vm.validateMOQ = validateMOQ;
    vm.tabIndex = $stateParams.tabIndex ? $stateParams.tabIndex : 0;
    vm.changeItemType = changeItemType;
    vm.getAllBuyersBusinessOutlets = getAllBuyersBusinessOutlets;
    vm.getCSVBase64 = getCSVBase64;
    vm.itemBulkUploadCSV = itemBulkUploadCSV;
    vm.imageZipUpload = imageZipUpload;
    vm.SUPPLIER_ITEM_UPLOAD_CSV = S3_URL_CONSTANTS.SUPPLIER_ITEM_UPLOAD_CSV;


    vm.itemTypes = GLOBAL_CONSTANTS.ITEM_TYPES;
    vm.currencies = GLOBAL_CONSTANTS.CURRENCIES;
    vm.defaultCurrency = GLOBAL_CONSTANTS.DEFAULT_CURRENCY;
    vm.getAllBrandsOfBuyerList = getAllBrandsOfBuyerList;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.checkWeightRequired = checkWeightRequired;
    vm.isWeightRequired;
    function save() {
      SupplierItemService.createSupplierItem(vm.itemDTOObj).then(function (response) {
        if (response.data.data.length > 0) {
          vm.bulkUploadWarnings = response.data.data;
          toastr.warning('Completed with some errors!', 'Buyer/Outlet Mapping');
        }
        else {
          NotificationService.success({
            title: 'Item',
            message: 'alertMessage.CREATED_SUCCESSFULLY'
          });
          $state.go('supplier.items', {
            reload: false
          });
        }
      }, function (error) {
        NotificationService.error({
          title: 'Item Creation',
          error: error
        });
      });
    }

    /**IMAGE UPLOAD**/

    function setCompanyLogo(flowFile) {
      ImageUploadService.getImageBase64(flowFile.file, function (base64Img) {
        vm.uploadLogo(base64Img);
      });
    }

    function uploadLogo(base64Img) {
      vm.uploadingInProgress = true;
      ImageUploadService.uploadImage(base64Img).
        then(function (responseData) {
          vm.itemDTOObj.item.image = responseData.url;
          // $scope.addItemForm.$pristine = false;
          NotificationService.success({
            title: 'Item Image',
            message: 'alertMessage.UPLOADED_SUCCESSFULLY'
          });
          vm.uploadingInProgress = false;
        }, function (error) {
          vm.uploadingInProgress = false;
          NotificationService.error({
            title: 'Item Image Upload',
            error: error
          });
        });
    }

    function getAllBuyersBusinessOutlets(buyerIdList) {
      if (!buyerIdList || buyerIdList.length === 0) return false;
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        groupBy: 'buyer',
        supplierId: SupplierDataService.getSupplierProfile().supplier.id
      };
      BuyerBusinessService.getAllBuyersBusinessOutlets(buyerIdList, params).then(function (response) {
        vm.buyerBusinessOutlets = response.data;
        vm.csvUploadData.businessIdList = [];
      }, function (error) {
      });
    }

    function changeItemType(itemType) {
      vm.csvUploadData.itemType = itemType;
      if (itemType !== 'BUYER') {
        vm.csvUploadData.buyerIdList = [];
        vm.csvUploadData.businessIdList = [];
      }
    }

    function getCSVBase64() {
      vm.errorList = null;
      var csvFile = vm.files[0];
      if (!csvFile) {
        toastr.error('Please upload a .csv file!', 'CSV File Required');
        return false;
      }
      CsvUploadService.getFileBase64(csvFile.lfFile, function (base64CSV) {
        vm.csvUploadData.encodedCsvFile = base64CSV;
        vm.csvUploadData.itemType=vm.itemType;
        vm.itemBulkUploadCSV(vm.csvUploadData);
      });
    }

    function itemBulkUploadCSV(uploadData) {
      SupplierItemService.itemBulkUploadCSV(uploadData)
        .then(function (response) {
          vm.files = [];
          NotificationService.success({
            title: 'Items',
            message: 'alertMessage.UPLOADED_SUCCESSFULLY'
          });
          vm.tabIndex = 1;
        }, function (error) {
          vm.recordError = error.data;
          NotificationService.error({
            title: 'Supplier Item Bulk Upload',
            error: error
          });
        });
    }

    function imageZipUpload() {
      if (!vm.imageZip) {
        toastr.error('Please choose a .zip file with compressed images.', 'Item Bulk Upload Failed');
        return false;
      }
      SupplierItemService.itemImageBulkUpload(vm.imageZip)
        .then(function (response) {
          NotificationService.success({
            title: 'Item Image Upload',
            message: 'In Progress. You will receive an email when complete'
          });
          $state.go('supplier.items');
        }, function (error) {
          NotificationService.error({
            title: 'Item Image Upload',
            error: error
          });
        });
    }

    /**
    * [selectAll Selects all from the list]
    * @param  {[type]} dropdown [Active dropdown - outlets,  buyers or brands]
    */
    function selectAll(dropdown) {
      switch (dropdown) {
        case 'BUYERS':
          vm.itemDTOObj.buyerIdList = _.map(vm.buyers, _.property('id'));
          break;
        case 'BRANDS':
          vm.itemDTOObj.brandIdList = _.map(vm.brands, _.property('id'));
          break;
        case 'BUSINESSOUTLETS':
          vm.itemDTOObj.businessIdList = _.map(vm.brandMappedBusinessOutlets, _.property('id'));
          break;
        case 'buyers':
          vm.csvUploadData.buyerIdList = _.map(vm.buyers, _.property('id'));
          break;
        case 'outlets':
          _.forEach(vm.buyerBusinessOutlets, function(outlets){
            _.forEach(outlets.businessDTOList, function(businessOutlets){
              vm.csvUploadData.businessIdList.push(businessOutlets.businessId);
            });
          });
          break;
      }
    }

    /**
     * [deselectAll Deselect all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets, buyers or brands]
     */
    function deselectAll(dropdown) {
      switch (dropdown) {
        case 'BUYERS':
          vm.itemDTOObj.buyerIdList = [];
          break;
        case 'BRANDS':
          vm.itemDTOObj.brandIdList = [];
          break;
        case 'BUSINESSOUTLETS':
          vm.itemDTOObj.businessIdList = [];
          break;
          case 'buyers':
          vm.csvUploadData.buyerIdList = [];
          break;
        case 'outlets':
          vm.csvUploadData.businessIdList = [];
          break;
      }
    }

    function loadUoms() {
      UomService.getAllUoms({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function (response) {
        vm.uoms = response.data;
      }, function (error) {
      });
    }

    function loadItemGroups() {
      ItemGroupService.getAllItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function (response) {
        vm.itemGroups = response.data;
      }, function (error) {
      });
    }

    function getAllSupplierMappedBuyers() {
      vm.itemDTOObj.businessIdList = [];
      vm.itemDTOObj.brandIdList = [];
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
      };
      if (vm.buyers)
        return false;
      var supplierId = SupplierDataService.getSupplierProfile().supplier.id;
      BuyerSupplierMappingService.getAllSupplierMappedBuyers(supplierId, params).then(function (response) {
        vm.buyers = response.data;
      }, function (error) {
      });
    }


    /**
     * [getAllBrandsOfBuyerList Gets all brands of selected buyers]
     * @param {Array} buyerIdList
     */
    function getAllBrandsOfBuyerList() {
      if(!vm.itemDTOObj.buyerIdList || vm.itemDTOObj.buyerIdList.length == 0)
        return false;
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        query: '',
        buyerId: vm.itemDTOObj.buyerIdList
      };
      BrandManagementService.getAllBrandsOfBuyerList(params)
        .then(function (response) {
          vm.brands = response;
        }, function (error) {
          NotificationService.error({
            title: 'Brand Listing',
            error: error
          });
        });
    }

    //get all business outlets mapped from brands
    function getAllBrandMappedsBusinessOutlets(brandIdList) {
      if (!brandIdList || brandIdList.length === 0) return false;
      var itemTerminationDTO = {
        'brandIdList': brandIdList,
        'buyerIdList': vm.itemDTOObj.buyerIdList
      }
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        supplierId: SupplierDataService.getSupplierProfile().supplier.id
      }
      BuyerBusinessService.getAllBrandMappedsBusinessOutlets(itemTerminationDTO, params).then(function (response) {
        vm.brandMappedBusinessOutlets = response.data;
      }, function (error) {
      });
    }

    function init() {
      vm.loadUoms();
      vm.loadItemGroups();
      vm.csvUploadData = {};
    }

    function clearBulkUploadWarnings() {
      vm.bulkUploadWarnings = null;
    }

    function changeItemType(itemType) {
      if (itemType !== 'BUYER') {
        vm.itemDTOObj.buyerIdList = [];
        vm.itemDTOObj.businessIdList = [];
      }
    }

    /**
     * [validateMOQ Validates the MOQ with Multiplier]
     */
    function validateMOQ() {
      if (vm.itemDTOObj.item.multiplierFactor > 0) {
        if (((vm.itemDTOObj.item.moq * 100) % (vm.itemDTOObj.item.multiplierFactor * 100)) / 100 != 0) {
          vm.addItemForm.moq.$setTouched();
          vm.addItemForm.moq.$setValidity("moq", false);
        } else {
          vm.addItemForm.moq.$setValidity("moq", true);
        }
      }
    }

    function checkWeightRequired(uom){
        vm.isWeightRequired = _.find(vm.uoms, function(uomelement) {
            if(uomelement.id === uom) {
                return uomelement.weightRequired;
            }
        });
    }
  }
})();
