/**
 * @Author: Ajay
 * @Date:   18-Jul-2018 15:19 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.manage-payment-approval-level.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 30-Aug-2018 17:34 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider

    .state('ezypayment.buyer.manage-payment-approval-level', {
      parent: 'ezypayment.buyer',
      url: '/manage-payment-approval-level',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'Manage Approval Level'
      },
      views: {
        'content@': {
          templateUrl: 'app/ezypayment-modules/buyer-modules/manage-payment-approval-level/buyer.manage-payment-approval-level.html',
          controller: 'BuyerManagePaymentApprovalLevelController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    })
    .state('ezypayment.buyer.manage-payment-approval-level.level-selection', {
      parent: 'ezypayment.buyer.manage-payment-approval-level',
      url: '/level-selection',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'Manage Approval - Choose Level'
      },
      views: {
        'content@': {
          templateUrl: 'app/ezypayment-modules/buyer-modules/manage-payment-approval-level/level-selection/buyer.manage-payment-approval-level.level-selection.html',
          controller: 'BuyerManagePaymentApprovalLevelSelectionController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('brandBuyerUserMapping');
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    });
  }
})();
