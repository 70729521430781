/**
* @Author: Ajay
* @Date:   09-Feb-2017 18:53 +05:30
* @Project: Ezyprocure
* @Filename: admin.banks.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 01-Jun-2017 13:09 +05:30
*/



(function() {
    'use strict';

    angular
    .module('ezyprocureApp')
    .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
      $stateProvider
      .state('admin.banks', {
        parent: 'admin',
        url: '/banks?page&sort&search',
        data: {
          authorities: ['ROLE_ADMIN'],
          pageTitle: 'ezyprocureApp.bank.home.title'
        },
        views: {
          'content@': {
            templateUrl: 'app/common-modules/manage-banks/manage-banks.list.html',
            controller: 'ManageBankListController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,asc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('bank');
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('user-management');
            return $translate.refresh();
          }]
        }
      })
      .state('admin.banks.add', {
        parent: 'admin.banks',
        url: '/add',
        data: {
          authorities: ['ROLE_ADMIN']
        },
        views: {
          'content@': {
            templateUrl: 'app/common-modules/manage-banks/add/manage-banks.add.html',
            controller: 'ManageBankAddController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('bank');
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('user-management');
            return $translate.refresh();
          }]
        }
      })

    //   .state('admin.banks.bulk-upload', {
    //     parent: 'admin.banks',
    //     url: '/bulk-upload',
    //     data: {
    //       authorities: ['ROLE_ADMIN']
    //     },
    //     views: {
    //       'content@': {
    //         templateUrl: 'app/common-modules/manage-banks/bulk-upload/manage-banks.bulk-upload.html',
    //         controller: 'ManagebankBulkUploadController',
    //         controllerAs: 'vm'
    //       }
    //     },
    //     resolve: {
    //       translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
    //         $translatePartialLoader.addPart('bank');
    //         $translatePartialLoader.addPart('global');
    //         $translatePartialLoader.addPart('user-management');
    //         return $translate.refresh();
    //       }]
    //     }
    //   })

      .state('admin.banks.edit', {
        parent: 'admin.banks',
        url: '/edit/:bankId',
        data: {
          authorities: ['ROLE_ADMIN']
        },
        views: {
          'content@': {
            templateUrl: 'app/common-modules/manage-banks/edit/manage-banks.edit.html',
            controller: 'ManageBankEditController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('bank');
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('user-management');
            return $translate.refresh();
          }]
        }
      });
    }

  })();
