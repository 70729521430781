/*
 * @Author: Lovin Chacko
 * @Date: 2021-03-31 12:28:50
 * @Last Modified by: Lovin Chacko
 * @Last Modified time: 2021-05-10 21:26:58
 */

(function () {
    'use strict';

    angular
        .module('ezyprocureApp')
        .controller('EditBlanketOrderController', EditBlanketOrderController);

    EditBlanketOrderController.$inject = ['$scope', '$rootScope', '$state', '$filter', '$q', '$stateParams', 'NotificationService', '$mdDialog', 'Auth', 'paginationConstants', 'SupplierDataService', 'BuyerSupplierMappingService', 'BuyerDataService', 'BuyerItemService', 'SupplierItemService', 'BlanketOrderService', 'GLOBAL_CONSTANTS', 'GLOBAL_CONFIG_CONSTANTS', 'pagingParams'];

    function EditBlanketOrderController($scope, $rootScope, $state, $filter, $q, $stateParams, NotificationService, $mdDialog, Auth, paginationConstants, SupplierDataService, BuyerSupplierMappingService, BuyerDataService, BuyerItemService,SupplierItemService,BlanketOrderService, GLOBAL_CONSTANTS, GLOBAL_CONFIG_CONSTANTS, pagingParams) {
        var vm = this;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.todaysDate = new Date();
        vm.query = {
            limit: paginationConstants.itemsPerPage,
            page: 1
        };
        vm.limitOptions = paginationConstants.limitOptions;
        vm.init = init;
        vm.id = $stateParams.id;
        vm.userRole = Auth.getUserRole();
        vm.items = [];
        vm.blanketOrdersList = [];
        vm.blanketOrderItems = [{}];
        vm.addNewItemRow = addNewItemRow;
        vm.deleteItemRow = deleteItemRow;
        vm.searchForItems = searchForItems;
        vm.itemSearchQuery = {};
        vm.getBlanketOrderById = getBlanketOrderById;
        vm.updateBlanketOrder = updateBlanketOrder;
        vm.itemChanged = itemChanged;
        vm.cancel = cancel;
        vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;

        function init() {
            vm.getBlanketOrderById();
        }

        function cancel(){
            $state.go(vm.userRole.pretty + '.tools.manage-blanket-order');
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            return result;
          }

        function getBlanketOrderById() {
            BlanketOrderService.getBlanketOrderById(vm.id,{
                page: vm.query.page - 1,
                size: vm.query.limit,
                sort: sort(),
            }).then(function (res) {
                vm.blanketOrderItems = res.data.blanketOrder;
                vm.totalItems = res.data.blanketOrderItemsMappingPage.totalElements;
                vm.blanketOrderItems.blanketOrderItemsMappings = _.forEach(res.data.blanketOrderItemsMappingPage.content, function(item){
                    item['readOnly'] =  true;
                });
            });
        }

        function updateBlanketOrder() {
            var blanketOrder = {};
            blanketOrder = {
                id: vm.id,
                blanketOrderItemsMappings: vm.blanketOrderItems.blanketOrderItemsMappings,
                blanketOrderNumber: vm.blanketOrderItems.blanketOrderNumber,
                validityStartDate: vm.blanketOrderItems.validityStartDate,
                validityEndDate: vm.blanketOrderItems.validityEndDate,
            }
            if (vm.userRole.pretty == 'buyer') {
                blanketOrder.supplier = vm.blanketOrderItems.supplier;
            }
            else {
                blanketOrder.buyer = vm.blanketOrderItems.buyer;
            }
            BlanketOrderService.updateBlanketOrder(blanketOrder).then(function (response) {
                NotificationService.success({
                    title: 'global.editBlanketOrder',
                    message: 'alertMessage.UPDATED_SUCCESSFULLY'
                });
                $state.go(vm.userRole.pretty + '.tools.manage-blanket-order');
            },
                function (error) {
                    NotificationService.error({
                        title: 'Update Blanket Order',
                        error: error
                    });
                })
        }



        function addNewItemRow() {
            vm.blanketOrderItems.blanketOrderItemsMappings.push({});
        }

        function deleteItemRow(index) {
            vm.blanketOrderItems.blanketOrderItemsMappings.splice(index, 1);
        }

        function itemChanged(blanketOrderItem, index) {
            var temp = angular.copy(blanketOrderItem);
            blanketOrderItem.itemName = temp.items.itemName;
            blanketOrderItem.itemCode = temp.items.itemCode;
            blanketOrderItem.uom = temp.items.uom.name;
            blanketOrderItem.currency = temp.items.currency;
            blanketOrderItem.price = temp.items.price;
            blanketOrderItem.itemId = temp.items.id;
        }


        function searchForItems(queryType, queryText) {
            var deferred = $q.defer();
            if (!queryText) {
                return;
            }
            else {
                if (vm.userRole.pretty == 'buyer') {
                    vm.itemSearchQuery.queryField = queryType;
                    vm.itemSearchQuery.queryText = queryText;
                    vm.itemSearchQuery.businessIdList = null;
                    vm.itemSearchQuery.buyerIdList = [BuyerDataService.getBuyerProfile().buyerUser.buyer.id];
                    vm.itemSearchQuery.supplierId = vm.blanketOrderItems.supplier.id;
                    BuyerItemService.searchAllItems(vm.itemSearchQuery)
                        .then(function (response) {
                            deferred.resolve($filter('orderBy')(response.data, 'itemName'));
                        }, function (error) {
                            deferred.reject(error);
                        });
                }
                else {
                    vm.itemSearchQuery.queryField = queryType;
                    vm.itemSearchQuery.queryText = queryText;
                    vm.itemSearchQuery.itemType = 'BUYER';
                    vm.itemSearchQuery.buyerIdList = [vm.blanketOrderItems.buyer.id];
                    SupplierItemService.searchAllItems(vm.itemSearchQuery)
                        .then(function (response) {
                            deferred.resolve($filter('orderBy')(response.data, 'itemName'));
                        }, function (error) {
                            deferred.reject(error);
                        });
                }

            }
            return deferred.promise;
        }

    }
})();
