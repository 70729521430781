/**
* @Author: Allan
* @Date:   21-September-2018 16:15 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.sip-authorization-request.state.js
* @Last modified by:   Allan
* @Last modified time: 21-September-2018 19:03 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('ezypayment.buyer.sip-authorization-request', {
      parent: 'ezypayment.buyer',
      url: '/sip-authorization-request',
      data: {
        authorities: ['ROLE_BUYER']
      },
      views: {
        'content@': {
          templateUrl: 'app/ezypayment-modules/buyer-modules/sip-authorization-request/ezypayment.sip-authorization-request.html',
          controller: 'EzypaymentSipAuthorizationRequestController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return true;
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    })
  }
})();
