/*
 * @Author: Jayalekshmi 
 * @Date: 2018-11-14 13:15:15 
 * @Project: Ezyprocure
 * @Filename: buyer.adhoc-supplier-buyer-mapping.edit.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2018-11-28 18:11:51
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerAdhocSupplierMappingEditController', BuyerAdhocSupplierMappingEditController);

  BuyerAdhocSupplierMappingEditController.$inject = ['$scope', '$state', '$stateParams', 'paginationConstants', 'NotificationService', 'BuyerAdhocSupplierMappingService', 'ManageSupplierService'];

  function BuyerAdhocSupplierMappingEditController($scope, $state, $stateParams, paginationConstants, NotificationService, BuyerAdhocSupplierMappingService, ManageSupplierService) {
    var vm = this;
    vm.adhocMappingId = $stateParams.mappingId;
    vm.getAdhocSupplierBuyerMappingById = getAdhocSupplierBuyerMappingById;
    vm.updateAdhocSupplierBuyerMapping = updateAdhocSupplierBuyerMapping;
    vm.getAllAdhocSuppliers = getAllAdhocSuppliers;
    vm.init = init;

    function init(){
      vm.getAdhocSupplierBuyerMappingById();
      vm.getAllAdhocSuppliers();
    }
    
    /**
     * [updateAdhocSupplierBuyerMapping updates the mapping]
     */
    function updateAdhocSupplierBuyerMapping() {
      BuyerAdhocSupplierMappingService.updateAdhocSupplierBuyerMapping(vm.adhocSupplierMapping).then(function(response) {
        NotificationService.success({
          title: 'Adhoc-Supplier Buyer Mapping',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        $state.go('buyer.adhoc-tools.adhoc-supplier-buyer-mapping', {
          reload: false
        });
      }, function(error) {
        NotificationService.error({
          title: 'global.navbar.adhocSupplierBuyerMapping',
          error: error
        });
      });
    }

    /**
     * [getAllAdhocSuppliers loads all adhoc suppliers]
     */
    function getAllAdhocSuppliers() {
      if(vm.suppliers){
        return false
      }
      ManageSupplierService.getAllAdhocSuppliers({
        page: 0,
        size: paginationConstants.maxLimit,
        sort: vm.supplierSort
      }).then(function(response){
        vm.suppliers = response.data;
      },function(error){});
    }

     /**
    * [getAdhocSupplierBuyerMappingById - Fetches the getAdhocSupplierBuyerMapping using id]
    */
    function getAdhocSupplierBuyerMappingById() {
      BuyerAdhocSupplierMappingService.getAdhocSupplierBuyerMappingById(vm.adhocMappingId).
        then(function (response) {
          if (response) {
            vm.adhocSupplierMapping = response.data;
          }
        }, function (error) {
          NotificationService.error({
            title: 'global.navbar.adhocSupplierBuyerMapping',
            error: error
          });
        });
    }
    vm.init();
  }
})();
