/**
 * @Author: Ajay
 * @Date:   27-Dec-2017 16:11 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.items-for-approval.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 22-May-2018 09:50 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('buyer.items-for-approval', {
      abstract: true,
      parent: 'buyer',
      url: '/items-for-approval',
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          return $translate.refresh();
        }],
        hasModuleAccess: ['Auth','$q', '$state', function(Auth, $q, $state) {
          if(Auth.hasPermission('accessForItemApproval')){
            return true;
          }else {
            return $q.reject('Unauthorized');
          }
        }]
      }
    })

    //Pending
    .state('buyer.items-for-approval.pending', {
      parent: 'buyer.items-for-approval',
      url: '/pending',
      data: {
        authorities: ['ROLE_BUYER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/items-for-approval/buyer.items-for-approval-pending.html',
          controller: 'BuyerItemsForApprovalController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          //$translatePartialLoader.addPart('buyerUserBusinessMapping');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('user-management');
          $translatePartialLoader.addPart('itemsForApproval');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('itemApprovalStatus');

          return $translate.refresh();
        }]
      }
    })


    //Approved
    .state('buyer.items-for-approval.approved', {
      parent: 'buyer.items-for-approval',
      url: '/approved',
      data: {
        authorities: ['ROLE_BUYER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/items-for-approval/buyer.items-for-approval-approved.html',
          controller: 'BuyerItemsForApprovalController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          //$translatePartialLoader.addPart('buyerUserBusinessMapping');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('user-management');
          $translatePartialLoader.addPart('itemsForApproval');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('itemApprovalStatus');
          $translatePartialLoader.addPart('itemApprovalRequest');
          return $translate.refresh();
        }]
      }
    })

    //Rejected
    .state('buyer.items-for-approval.rejected', {
      parent: 'buyer.items-for-approval',
      url: '/rejected',
      data: {
        authorities: ['ROLE_BUYER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/items-for-approval/buyer.items-for-approval-rejected.html',
          controller: 'BuyerItemsForApprovalController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          //$translatePartialLoader.addPart('buyerUserBusinessMapping');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('user-management');
          $translatePartialLoader.addPart('itemsForApproval');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('itemApprovalStatus');
          $translatePartialLoader.addPart('itemApprovalRequest');
          return $translate.refresh();
        }]
      }
    });
  }

})();
