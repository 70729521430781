/**
* @Author: Allan
* @Date:   11-October-2017 15:12 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.admin.manage-merchant-details.service.js
 * @Last modified by:   Allan
 * @Last modified time: 11-October-2017 10:26 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('EzypaymentAdminManageMerchantDetailsService', EzypaymentAdminManageMerchantDetailsService);

  EzypaymentAdminManageMerchantDetailsService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  function EzypaymentAdminManageMerchantDetailsService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var ezypaymentAdminManageMerchantDetailsService = {
      getMerchantDetails: getMerchantDetails,
      saveMerchantDetails: saveMerchantDetails,
      getAllCountryCurrencies: getAllCountryCurrencies,
      createSupplierMerchantIdDetail: createSupplierMerchantIdDetail,
      getAllSupplierMerchantIdDetail : getAllSupplierMerchantIdDetail,
      updateSupplierMerchantIdDetail : updateSupplierMerchantIdDetail,
      getSupplierMerchantIdDetailById : getSupplierMerchantIdDetailById, 
      getSupplierMerchantIdDetail : getSupplierMerchantIdDetail,
      getBsfMidList: getBsfMidList,
      getMerchantDetailsByBsf: getMerchantDetailsByBsf
    };

    return ezypaymentAdminManageMerchantDetailsService;

    function getBsfMidList() {
      var deferred = $q.defer();
      $http({
        url : API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + '/cybersource/bsf-below-threshold',
        method : "GET"
      }).then(function(data){
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getMerchantDetailsByBsf(bsfBelowThreshold) {
      var deferred = $q.defer();
      $http({
        url : API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + '/cybersource/merchant-ids',
        method : "GET",
        params: { "bsfBelowThreshold": bsfBelowThreshold }
      }).then(function(data){
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [createSupplierMerchantIdDetail Creates Supplier merchant details]
     * @param {Object} merchantDetail 
     */
    function createSupplierMerchantIdDetail(merchantDetail){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.MERCHANT_DETAILS+ 'supplier/',
        method: "POST",
        data: merchantDetail
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [updateSupplierMerchantIdDetail updates Supplier merchant details]
     * @param {Object} merchantDetail 
     */
    function updateSupplierMerchantIdDetail(merchantDetail){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.MERCHANT_DETAILS + 'supplier/',
        method: "PUT",
        data: merchantDetail
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getAllSupplierMerchantIdDetail getAlls Supplier merchant details]
     * @param {Object} merchantDetail 
     */
    function getAllSupplierMerchantIdDetail(queryParams){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.MERCHANT_DETAILS +'supplier/',
        method: "GET",
        params: queryParams
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getSupplierMerchantIdDetail Gets Supplier merchant id details]
     * @param {Object} merchantDetail 
     */
    function getSupplierMerchantIdDetail(queryParams){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.MERCHANT_DETAILS +'suppliers/',
        method: "GET",
        params: queryParams
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getSupplierMerchantIdDetailById Gets all details of Supplier merchant by id]
     * @param {Object} merchantDetail 
     */
    function getSupplierMerchantIdDetailById(merchantId){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.MERCHANT_DETAILS + merchantId,
        method: "GET"
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getMerchantDetails(brandId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BRANDS + brandId,
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getAllCountryCurrencies Gets all country currencies]
     */
    function getAllCountryCurrencies(params){
      var deferred = $q.defer();
      $http({
        url : API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + '/country-currencies',
        method : "GET",
        params : params
      }).then(function(data){
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;

    }
    function saveMerchantDetails(merchantDetail){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BRANDS + 'business-service-rate',
        method: "PUT",
        data: merchantDetail
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
