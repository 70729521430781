/**
 * @Author: Ajay
 * @Date:   04-Apr-2018 10:55 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.items-for-approval.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 23-May-2018 14:24 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerItemsForApprovalController', BuyerItemsForApprovalController);

  BuyerItemsForApprovalController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'BuyerItemsForApprovalService', 'paginationConstants', 'NotificationService', 'Auth', '$mdDialog'];

  function BuyerItemsForApprovalController($scope, $rootScope, $state, $stateParams, BuyerItemsForApprovalService, paginationConstants, NotificationService, Auth, $mdDialog) {
    var vm = this;
    vm.searchItem = '';
    vm.itemListType = 'all';
    vm.getPendingItemsForApproval = getPendingItemsForApproval;
    vm.getApprovedItemsForApproval = getApprovedItemsForApproval;
    vm.getRejectedItemsForApproval = getRejectedItemsForApproval;
    vm.approvalRequiredIdList = {};
    vm.checkAllItemsForApproval = checkAllItemsForApproval;
    vm.itemBusinessMappingIdList = [];
    vm.updateItemsApprovalStatus = updateItemsApprovalStatus;
    vm.getSelectedItemIdList = getSelectedItemIdList;
    vm.itemMappingList = [];
    vm.buyerItemCodeFeatureAccess = Auth.hasPermission('accessForBuyerItemCodeFeatures');
    vm.cancel = cancel;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function getPendingItemsForApproval(search) {
      if (!search) {
        vm.searchItem = '';
      }
      BuyerItemsForApprovalService.getPendingItemsForApproval({
        page: vm.query.page - 1,
        size: vm.query.limit,
        query: vm.searchItem,
        view: vm.itemListType,
        sort: sort()
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.approvalItems = response.data;
      }, function(error) {
      });
    }

    function getApprovedItemsForApproval(search) {
      if (!search) {
        vm.searchItem = '';
      }
      BuyerItemsForApprovalService.getApprovedItemsForApproval({
        page: vm.query.page - 1,
        size: vm.query.limit,
        query: vm.searchItem,
        view: vm.itemListType,
        sort: sort()
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.approvedItems = response.data;
      }, function(error) {
      });
    }

    function getRejectedItemsForApproval(search) {
      if (!search) {
        vm.searchItem = '';
      }
      BuyerItemsForApprovalService.getRejectedItemsForApproval({
        page: vm.query.page - 1,
        size: vm.query.limit,
        query: vm.searchItem,
        view: vm.itemListType,
        sort: sort()
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.rejectedItems = response.data;
      }, function(error) {
      });
    }

    //Select all items for approval in Buyer New Items For approval page
    function checkAllItemsForApproval() {
      vm.selectAll ? vm.selectAll = true : vm.selectAll = false;
      angular.forEach(vm.approvalItems, function(approvalRequiredItem) {
        approvalRequiredItem.selected = vm.selectAll;
      });
    }

    /**
    * [getSelectedItemIdList description]
    * @param  {[object]} items [description]
    * @return {[type]}       [description]
    */

    function getSelectedItemIdList(items) {
      var itemIds = [];
      //Fetch all ids of supplierItem in vm.supplierItems where supplierItem.selected = true
      var selectedItems = _.filter(items, {
        'selected': true
      });
      vm.itemBuyerMappingIds = _.uniq(_.map(selectedItems, _.property('itemBuyerMappingId')));
      itemIds = _.map(selectedItems, _.property('itemBusinessMappingId'));
      vm.itemList = [];
      _.forEach(vm.itemBuyerMappingIds, function (id) {
        _.forEach(selectedItems, function(item){
          if(item.itemBuyerMappingId == id){
            vm.itemList.push(item);
            return false;
          }
        })
      });
      return itemIds;
    }


    function updateItemsApprovalStatus(status) {
      vm.itemBusinessMappingIdList = vm.getSelectedItemIdList(vm.approvalItems);
      if(vm.itemBusinessMappingIdList.length<1){
        NotificationService.simpleErrorToast({
          title: 'global.table.itemApprovalStatus',
          message: 'alertMessage.SELECT_ITEMS_TO_CONTINUE'
        });
        return false;
      }
      var itemsBODto = {
        "itemBusinessMappingIdList": vm.itemBusinessMappingIdList,
        "purchaseRequestApprovalStatus": status
      };
      BuyerItemsForApprovalService.updateItemApprovalWithStatus(itemsBODto).then(function(response) {
        NotificationService.success({
          title: 'Item Approval Status',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        if(vm.itemBuyerMappingIds.length == 1 && vm.buyerItemCodeFeatureAccess){
          $state.go('buyer.manage-erp-item.edit',{'itemBuyerMappingId':vm.itemBuyerMappingIds[0]})
        }
        else if(vm.itemBuyerMappingIds.length > 1 && vm.buyerItemCodeFeatureAccess){
          showPopup();
        }
        else {
          vm.getPendingItemsForApproval('search');
        }
      }, function(error) {
        NotificationService.error({
          title: 'Item Approval Status Updation',
          error: error
        });
      });
    }

    function showPopup(event) {
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/items-for-approval/buyer.items.supplier-item-mapping-link.popup.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: false
      });
    }
    
    function cancel(){
      $mdDialog.cancel();
      vm.getPendingItemsForApproval('search');
    }

  }
})();
