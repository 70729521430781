/**
* @Author: Ajay
* @Date:   14-Dec-2016 12:54 +05:30
* @Project: Ezyprocure
* @Filename: auth.interceptor.js
 * @Last modified by:   Ajay
 * @Last modified time: 23-Feb-2018 12:40 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .factory('authInterceptor', authInterceptor);

  authInterceptor.$inject = ['$rootScope', '$q', '$location', '$localStorage', '$sessionStorage'];

  function authInterceptor($rootScope, $q, $location, $localStorage, $sessionStorage) {
    var service = {
      request: request
    };

    return service;

    function request(config) {
      /*jshint camelcase: false */
      config.headers = config.headers || {};
      var token = $localStorage.authenticationToken || $sessionStorage.authenticationToken;
      // if token is available attach it with headers
      if (token && !config.headers.Authorization) {
        config.headers.Authorization = 'Bearer ' + token;
      }
      //Adding Time-Zone to request headers for server to know timezone of the client(user/browser)
      config.headers["Time-Zone"] = moment.tz.guess();
      return config;
    }
  }
})();
