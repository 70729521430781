/**
* @Author: Jayalekshmi R
* @Date:   2018-06-11T15:32:51+05:30
* @Project: Ezyprocure
* @Filename: buyer.brands.add.controller.js
 * @Last modified by: Jayalekshmi R
 * @Last modified time: 2018-06-14T15:43:01+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BrandAddController', BrandAddController);

  BrandAddController.$inject = ['$scope', '$state', 'BrandManagementService', 'GLOBAL_CONSTANTS', 'NotificationService', 'BuyerDataService'];

  function BrandAddController($scope, $state, BrandManagementService, GLOBAL_CONSTANTS, NotificationService, BuyerDataService) {
    var vm = this;

    vm.brands = {};
    vm.save = save;
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.brands = {};
    vm.brands.acraNumber =  BuyerDataService.getBuyerProfile().buyerUser.buyer.companyRegistrationNumber;
    vm.peppolAccess = BuyerDataService.getBuyerProfile().accessDTO.accessForPEPPOL;
    vm.createBrandIsoCodeAndIdentifier =createBrandIsoCodeAndIdentifier;
    vm.customDutyTypes = GLOBAL_CONSTANTS.CUSTOM_DUTY_TYPE;

    /**
    * [save- Creates new brands with the details]
    */
    function save(){
        if(vm.brands.poPrefix == ''){
            vm.brands.poPrefix = null;
        }
      BrandManagementService.createBrand(vm.brands).then(function(response){
        NotificationService.success({
          title: 'Brand',
          message: 'alertMessage.CREATED_SUCCESSFULLY'
        });
        vm.brands = response.data;
        if(vm.identifier != '' && vm.isoCode != '')
           vm.createBrandIsoCodeAndIdentifier();
        $state.go('buyer.tools.brands', {reload: false});
      },function(error) {
        NotificationService.error({
          title: 'Brand Creation',
          error: error
        });
      });
    }

    // create iso code and identifier
    function createBrandIsoCodeAndIdentifier(){
      if(!vm.peppolAccess)
        return false;
      var detailsDTO = {
        'ezpBrandId': vm.brands.id,
        'identifier': vm.identifier,
        'isoCode':vm.isoCode,
        ezyCompanyDetails:{
          'ezpCompanyId':BuyerDataService.getBuyerProfile().buyerUser.buyer.id
        }
      };
      BrandManagementService.createBrandIsoCodeAndIdentifier(detailsDTO).then(function(response){
        NotificationService.success({
          title: 'global.IsoCodeAndIdentifier',
          message: 'alertMessage.CREATED_SUCCESSFULLY'
        });
      },
      function(error){
        NotificationService.error({
          title: 'Brand Creation',
          error: error
        });
      });
    }
  }
})();
