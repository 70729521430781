/**
* @Author: Ajay
* @Date:   06-Mar-2017 15:47 +05:30
* @Project: Ezyprocure
* @Filename: supplier.process-order.service.js
* @Last modified by:   Ajay
* @Last modified time: 21-Mar-2017 18:31 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('SupplierAdhocPoService', SupplierAdhocPoService);

  SupplierAdhocPoService.$inject = ['$http', '$q', 'API_CONSTANTS'];
  function SupplierAdhocPoService($http, $q, API_CONSTANTS) {

    var SupplierAdhocPoService = {
      getAllProcessOrdersByStatus: getAllProcessOrdersByStatus,
      getProcessOrderByRequirementNumber: getProcessOrderByRequirementNumber,
      supplierAcceptOrder: supplierAcceptOrder,
      getReject_PR: getReject_PR
    };

    return SupplierAdhocPoService;

    /* --  Supplier Process Order  APIs-- */

    //Get All ProcessOrders by status
    function getAllProcessOrdersByStatus(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers/process-orders-get-purchaseOrder',
        method: "GET",
        params:params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
     return deferred.promise;
    }

    //Get ProcessOrders by requirementNumber
    function getProcessOrderByRequirementNumber(requirementNumber) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'process-orders/' + requirementNumber +'/details').then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    // Reject_PR processOrderNumber input
        function getReject_PR(requirementNumber) {
              var deferred = $q.defer();
              $http({
                url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'supplier-reject-process-order/'+requirementNumber,
                method: "PUT"
              }).then(function(data) {
                deferred.resolve(data);
              }).catch(function(message) {
                deferred.reject(message);
              });
              return deferred.promise;
        }

    //Supplier Accept Order
    function supplierAcceptOrder(acceptOrderDTO) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers/accept-order', acceptOrderDTO).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
