/**
 * @Author: Jayalekshmi
 * @Date:   2018-06-28T13:36:39+05:30
 * @Filename: brand-buyer-user-mapping.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 30-Aug-2018 18:13 +05:30
 */


(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BrandBuyerUserMapService', BrandBuyerUserMapService);

  BrandBuyerUserMapService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  function BrandBuyerUserMapService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var brandBuyerUserMapService = {
      createBrandBuyerUserMapping: createBrandBuyerUserMapping,
      deleteBrandBuyerUserMapping: deleteBrandBuyerUserMapping,
      getAllBrandBuyerUserMappings: getAllBrandBuyerUserMappings,
      getAllBrandUserMappingsForBrand: getAllBrandUserMappingsForBrand,
      getBrandBuyerUserMappingForPaymentApproval: getBrandBuyerUserMappingForPaymentApproval,
      savePaymentApprovalLevel: savePaymentApprovalLevel,
      deleteAllPaymentApprovalLevels: deleteAllPaymentApprovalLevels
    };
    return brandBuyerUserMapService;

    /* --   Brand - Buyer User Mapping API's -- */
    /**
     * [createBrandBuyerUserMapping Create a new Brand and BUyer-user mapping]
     * @param  {[Object]} mapping [Map object to be created]
     */
    function createBrandBuyerUserMapping(mapping) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BRAND_BUYER_USER_MAPPINGS, mapping).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [deleteBrandBuyerUserMapping Soft Delete existing Brand and buyer-user mapping]
     * @param  {[Integer]} mappingId [Id of the mapping to be deleted]
     */
    function deleteBrandBuyerUserMapping(mappingId) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BRAND_BUYER_USER_MAPPINGS+ mappingId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getAllBrandBuyerUserMappings description Gets all Mapped brand and users]
     * @param  {[object]} params [description]
     */
    function getAllBrandBuyerUserMappings(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BRAND_BUYER_USER_MAPPINGS,
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getAllBrandUserMappingsForBrand(params, brandId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BRAND_BUYER_USER_MAPPINGS + brandId,
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getBrandBuyerUserMappingForPaymentApproval(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BRAND_BUYER_USER_MAPPINGS+'payment-approval-level',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function savePaymentApprovalLevel(brandId, brandBuyerUserMappings) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BRAND_BUYER_USER_MAPPINGS+'payment-approval-level',
        method: "PUT",
        params: {brandId: brandId},
        data: brandBuyerUserMappings
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function deleteAllPaymentApprovalLevels(brandId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BRAND_BUYER_USER_MAPPINGS+'payment-approval-level',
        method: "DELETE",
        params: {brandId: brandId}
      }).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
