/**
* @Author: Allan
* @Date:   12-May-2017 14:27 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.state.js
* @Last modified by:   Allan
* @Last modified time: 29-August-2018 12:34 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig ($stateProvider) {

    $stateProvider.state('ezypayment', {
      abstract: true,
      parent: 'app',
      url: '/ezypayment',
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('user-management');
          $translatePartialLoader.addPart('invoiceOCR');
          return $translate.refresh();
        }]

      }
    });
  }
})();
