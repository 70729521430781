/**
* @Author: Shibi
* @Date:   18-May-2020 11:10 +05:30
* @Project: Ezyprocure
* @Filename: stock-take.state.js
*/

(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .config(stateConfig);
  
    stateConfig.$inject = ['$stateProvider'];  
    function stateConfig($stateProvider) {
      $stateProvider      
      .state('buyer.confirm-stocktake', {         
        parent: 'buyer',
        url: '/confirm-stock-take',  
        data: {
          authorities: ['ROLE_BUYER'],
          pageTitle: 'ezyprocureApp.buyer.home.title'
        },    
        views: {
          'content@': {
            templateUrl: 'app/common-modules/stock-take/confirm-stock-take/confirm-stock-take.html',
            controller: 'ConfirmStockTakeController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,asc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');          
            $translatePartialLoader.addPart('buyer');          
            return $translate.refresh();
          }]
        }
      })
      .state('supplier.confirm-stocktake', {         
        parent: 'supplier',
        url: '/confirm-stock-take',  
        data: {
          authorities: ['ROLE_SUPPLIER'],
          pageTitle: 'ezyprocureApp.supplier.home.title'
        },    
        views: {
          'content@': {
            templateUrl: 'app/common-modules/stock-take/confirm-stock-take/confirm-stock-take.html',
            controller: 'ConfirmStockTakeController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,asc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');          
            $translatePartialLoader.addPart('supplier');          
            return $translate.refresh();
          }]
        }
      })
    }   
  })();
  