/**
* @Author: Ajay
* @Date:   20-Mar-2017 11:56 +05:30
* @Project: Ezyprocure
* @Filename: buyer.order-history.service.js
* @Last modified by:   Ajay
* @Last modified time: 21-Mar-2017 17:04 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BuyerOrderHistoryService', BuyerOrderHistoryService);

  BuyerOrderHistoryService.$inject = ['$http', '$q', 'API_CONSTANTS'];
  function BuyerOrderHistoryService($http, $q, API_CONSTANTS) {

    var buyerOrderHistoryService = {
      getOrderHistory : getOrderHistory
    };
    return buyerOrderHistoryService;

    function getOrderHistory(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyer/order-history',
        method: "GET",
        params:params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
