/**
 * @Author: Ajay
 * @Date:   06-Dec-2017 11:12 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.item-mapping.add.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 08-Dec-2017 16:46 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerItemMappingAddController', BuyerItemMappingAddController);

  BuyerItemMappingAddController.$inject = ['$scope', '$state', '$stateParams', 'toastr', 'pagingParams', 'BuyerDataService', 'BuyerItemService', 'BuyerSupplierMappingService', 'BuyerBusinessService', 'BrandManagementService', 'BuyerItemMapService', 'paginationConstants', 'NotificationService', 'AdhocItemPriceUpdateService'];

  function BuyerItemMappingAddController ($scope, $state, $stateParams, toastr, pagingParams, BuyerDataService, BuyerItemService, BuyerSupplierMappingService, BuyerBusinessService, BrandManagementService, BuyerItemMapService, paginationConstants, NotificationService, AdhocItemPriceUpdateService) {
    var vm = this;
    vm.getAllBuyerMappedAdhocSuppliers = getAllBuyerMappedAdhocSuppliers;
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.getAllBuyerItems = getAllBuyerItems;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.addNewMapping = addNewMapping;
    vm.newMappingObject = {};
    vm.itemSearchText = '';
    vm.clearBulkUploadWarnings = clearBulkUploadWarnings;
    vm.getSelectedItemIdList = getSelectedItemIdList;
    vm.items = [];
    vm.buyerLimitedChanged = buyerLimitedChanged;
    vm.getAllBrandsOfBuyerList = getAllBrandsOfBuyerList;
    vm.buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
    vm.getAllBrandMappedsBusinessOutlets = getAllBrandMappedsBusinessOutlets;
    vm.selectAllItems = selectAllItems;

    //Get all buyers mapped to the supplier
    function getAllBuyerMappedAdhocSuppliers () {
      if(vm.suppliers)
        return false;
      var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      AdhocItemPriceUpdateService.getAllBuyerMappedAdhocSuppliers(buyerId,{
        page: 0,
        size: paginationConstants.maxLimit,
        sort: vm.supplierSort
      }).then(function(response){
        vm.suppliers = response.data;
      },function(error){
      });
    }

    //get all items of a buyer
    function getAllBuyerItems() {
      BuyerItemService.getAllBuyerItems({
        'supplierId':vm.newMappingObject.supplierId ? vm.newMappingObject.supplierId : '',
        'query':'',
        page: 0,
        size: paginationConstants.maxLimit
      })
      .then(function(response){
        vm.items = response.data;
        vm.newMappingObject.itemIdList = [];
      },function(error){
      });
    }

    //old code
    function getAllBuyerBusinessOutlets () {
      BuyerBusinessService.getAllBuyerBusinesses({
        query: '',
        page: 0,
        size: paginationConstants.maxLimit
      }).then(function(response){
        vm.businessOutlets = response.data;
      },function(error){
      });
    }

    /**
     * [selectAll Select all items from the list and create separate array of item ids]
     */
    function selectAllItems() {
      vm.checkAll = vm.checkAll ? true : false;
      angular.forEach(vm.items, function(item) {
        item.selected = vm.checkAll;
      });
    }

         /**
     * [getAllBrandsOfBuyerList Gets all brands of selected buyers]
     * @param {Array} buyerIdList
     */
    function getAllBrandsOfBuyerList(){
      if(vm.brands)
        return false;
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        query:'',
        buyerId : vm.buyerId
      };
      BrandManagementService.getAllBrandsOfBuyerList(params)
      .then(function(response){
        vm.brands = response;
      },function(error){
        NotificationService.error({
          title: 'Brand Listing',
          error: error
        });
      });
    }

             /**
     * [selectAll Selects all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, buyers or brands]
     */
    function selectAll(dropdown) {
      switch (dropdown) {
        case 'BRANDS':
          vm.newMappingObject.brandIdList = _.map(vm.brands, _.property('id'));
          break;
        case 'OUTLETS':
          vm.newMappingObject.businessIdList  = _.map(vm.businessOutlets, _.property('id'));
           break;
      }
    }

    /**
     * [deselectAll Deselect all items from the list]
     */
    function deselectAll(dropdown) {
      switch(dropdown){
        case 'OUTLETS':
            vm.newMappingObject.businessIdList = [];
            break;
        case 'BRANDS':
            vm.newMappingObject.brandIdList = [];
            vm.newMappingObject.businessIdList = [];
            break;
      }
    }

    //add new mapping
    function addNewMapping() {
      vm.newMappingObject.itemIdList = vm.getSelectedItemIdList(vm.items);
      var mappingRequestDTO = {
      'supplierIdList' : vm.newMappingObject.supplierId ? [vm.newMappingObject.supplierId] : [],
      'businessIdList': vm.newMappingObject.businessIdList,
      'brandIdList': vm.newMappingObject.brandIdList,
      'itemBusinessMappingDTO': vm.newMappingObject.itemIdList
      }
      BuyerItemMapService.createNewItemBuyerOutletMapping(mappingRequestDTO).then(function(response){
        if(response.data.data.length > 0) {
          vm.bulkUploadWarnings = response.data.data;
          toastr.warning('Completed with some errors!', 'Buyer/Outlet Mapping');
        }
        else {
          NotificationService.success({
            title: 'Item - Buyer/Outlet',
            message: 'Mapped successfully'
          });
        }
      },function(error){
        NotificationService.error({
          title: 'Item - Buyer/Outlet Mapping',
          error: error
        });
      });
    }

    function clearBulkUploadWarnings() {
      vm.bulkUploadWarnings = null;
    }

    /**
     * [getSelectedItemIdList Get selected items]
     * @param {Object} items 
     */
    function getSelectedItemIdList(items) {
      var pickedItems = _.map(_.filter(items, {'selected': true}), _.partialRight(_.pick, ['id', 'itemCode', 'itemName', 'price','maximumAllocation','buyerLimited']));
      return pickedItems;
    }

    /**
     * [buyerLimitedChanged Changed the buyer Limite]
     * @param {Object} buyerLimited 
     */
    function buyerLimitedChanged(item){
      if(!item.buyerLimited){
        item.maximumAllocation = null;
      }
      
    }

    /**
     * getAllBrandMappedsBusinessOutlets gets all BO of selected brand and buyer
     * @param {List} brandIdList 
     */
    function getAllBrandMappedsBusinessOutlets(brandIdList) {
        if (!brandIdList || brandIdList.length === 0){
          vm.getAllBuyerBusinessOutlets();
          return false;
        }
        var itemTerminationDTO = {
          'brandIdList': brandIdList,
          'buyerIdList': [vm.buyerId]
        }
        var params = {
          page: 0,
          size: paginationConstants.maxLimit
        }
        BuyerBusinessService.getAllBrandMappedsBusinessOutlets(itemTerminationDTO, params).then(function(response) {
          vm.businessOutlets  = response.data;
        }, function(error) {
        });
      }
  }
})();
