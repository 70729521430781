/**
 * @Author: Ajay
 * @Date:   28-Jun-2017 11:12 +05:30
 * @Project: Ezyprocure
 * @Filename: image.ocr.upload.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 17-Jul-2017 15:12 +05:30
 */



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('OCRImageUploadService', OCRImageUploadService);

  OCRImageUploadService.$inject = ['$http', '$q', 'toastr', 'API_CONSTANTS', 'GLOBAL_CONSTANTS'];

  function OCRImageUploadService($http, $q, toastr, API_CONSTANTS, GLOBAL_CONSTANTS) {

    var ocrimageUploadService = {
      checkFileType: checkFileType,
      checkFileSize: checkFileSize,
      getImageBase64: getImageBase64,
      uploadImage: uploadImage,
      createNewImageElement: createNewImageElement
    };

    return ocrimageUploadService;

    function checkFileType(attType) {
      var validImageType = (/(image\/)/g).test(attType) && (attType !== "image/gif");
      if (!validImageType) {
        toastr.error('Please upload .jpg, .jpeg or .png image files!', 'Invalid Image File Type');
        return false;
      } else return true;
    }

    function checkFileSize(fileSize) {
      if (fileSize > GLOBAL_CONSTANTS.IMAGE_UPLOAD.FILE_SIZE_LIMIT) {
        toastr.error('Please upload image files size below 2MB!', 'Image Upload File Size Error');
        return false;
      } else return true;
    }

    function getImageBase64(inputFile, callback) {
      if (this.checkFileType(inputFile.type)) {
        var base64;
        var mimeType = inputFile.type;
        var fileName = inputFile.name;
        var fileReader = new FileReader();
        fileReader.onload = function(event) {
          //createNewImageElement(event.target.result, mimeType, fileName, callback);
          compressImage(event.target.result, 800, 'image/jpeg', callback)
        };
        fileReader.readAsDataURL(inputFile);
      }
    }

    function createNewImageElement(base64String, mimeType, fileName, callback) {
      var width = GLOBAL_CONSTANTS.IMAGE_UPLOAD.RESIZE_PARAMS.WIDTH;
      var convertedDataURL = '';
      var img = new Image();
      img.src = base64String;
      img.onload = function () {
        if (img.width > width) {
          var canvas = document.createElement('canvas');
          var scaleFactor = width / img.width;
          canvas.width = width;
          canvas.height = img.height * scaleFactor;
          var ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
          // get data url from canvas context
          convertedDataURL = ctx.canvas.toDataURL(img, mimeType, GLOBAL_CONSTANTS.IMAGE_UPLOAD.RESIZE_PARAMS.QUALITY).replace(mimeType, "image/octet-stream");
          callback(convertedDataURL);
        }
        else {
          callback(base64String);
        }
      }
    }

    //Upload Image - Function receives a base64Img as parameter
    function uploadImage(base64Img) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'image', base64Img).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function compressImage(base64String, width, mimeType, callback) {
      var img = new Image();
      img.src = base64String;
      if (img.width > width) {
        var canvas = document.createElement('canvas');
        var scaleFactor = width / img.width;
        canvas.width = width;
        canvas.height = img.height * scaleFactor;
        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
        canvas.toBlob(function (blob) {
          callback(blob);
        }, mimeType, GLOBAL_CONSTANTS.IMAGE_UPLOAD.RESIZE_PARAMS.QUALITY);
      } else {
        // Convert base64 string to blob
        var byteString = atob(base64String.split(',')[1]);
        var mimeString = base64String.split(',')[0].split(':')[1].split(';')[0];
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        var blob = new Blob([ab], { type: mimeString });
        callback(blob);
      }
  }

  }
})();
