/**
 * @Author: Ajay
 * @Date:   15-Oct-2018 14:39 +05:30
 * @Project: Ezyprocure
 * @Filename: cookies.config.js
 * @Last modified by:   Ajay
 * @Last modified time: 15-Oct-2018 15:04 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(cookiesServiceConfig);

  cookiesServiceConfig.$inject = ['$cookiesProvider'];

  function cookiesServiceConfig($cookiesProvider) {
    // Setting default options
    $cookiesProvider.defaults.secure = true;
    $cookiesProvider.defaults.samesite = 'lax';
  }
})();
