/**
* @Author: Ajay
* @Date:   27-May-2017 17:49 +05:30
* @Project: Ezyprocure
* @Filename: supplier.adhoc-credit-note.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-06-25T14:51:09+05:30
*/





(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierCreateAdhocCreditNoteController', SupplierCreateAdhocCreditNoteController);

  SupplierCreateAdhocCreditNoteController.$inject = ['$scope', '$rootScope', '$timeout', '$state', 'toastr', '$mdDialog', '$filter', '$stateParams', 'pagingParams', 'paginationConstants', 'SupplierReturnOrderService', 'NotificationService', 'BuyerReceivingOrderService', 'SupplierAdhocCreditNoteService', 'UTCDateService', 'PDFExportService', 'GLOBAL_CONSTANTS', 'Auth'];

  function SupplierCreateAdhocCreditNoteController($scope, $rootScope, $timeout, $state, toastr, $mdDialog, $filter, $stateParams, pagingParams, paginationConstants, SupplierReturnOrderService, NotificationService, BuyerReceivingOrderService, SupplierAdhocCreditNoteService, UTCDateService, PDFExportService, GLOBAL_CONSTANTS, Auth) {

    var vm = this;
    vm.init = init;
    vm.loadAll = loadAll;
    vm.individualTotalPrice = individualTotalPrice;
    vm.purchaseOrderNumber = $stateParams.purchaseOrderNumber;
    vm.deliveryDate = new Date($stateParams.deliveryDate);
    vm.totalCreditAmount = totalCreditAmount;
    vm.gotoAdhocCreditNoteTaxPreview = gotoAdhocCreditNoteTaxPreview;
    vm.grandTotalCredit = grandTotalCredit;
    vm.gstAmount = gstAmount;
    vm.getAdHocCreditNotePDF = getAdHocCreditNotePDF;
    vm.acceptedWeight = acceptedWeight;
    vm.acceptedQuantity = acceptedQuantity;
    vm.checkDeliveryCharge = checkDeliveryCharge;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.accessForPriceDisplay = Auth.hasPermission('accessForPriceDisplay');

    /**
    * [init loads initially]
    */
    function init() {
      vm.loadAll();
    }

    vm.init();

    /**
    * [loadAll Gets Invoice item Details by PO number]
    */
    function loadAll() {
      BuyerReceivingOrderService.getGRNItemDetails(vm.purchaseOrderNumber, {
        deliveryDate: !vm.deliveryDate ? '' : vm.deliveryDate,
      }).then(function(response){
        vm.purchaseOrderDetails = response.data;
        vm.purchaseOrderDetails.invoice.deliveryCharge = vm.purchaseOrderDetails.deliveryCharges;
        vm.purchaseOrderDetails.invoice.modifiedDeliveryCharge = 0;
      },function(error){
      });
    }

    function acceptedQuantity(p_index) {
      var acceptedQuantity = 0;
      acceptedQuantity = vm.purchaseOrderDetails.items[p_index].acceptedQuantity;
      acceptedQuantity =  parseFloat(acceptedQuantity.toFixed(2));
      return acceptedQuantity;
    }

    function checkDeliveryCharge() {
      if(!vm.purchaseOrderDetails.invoice.modifiedDeliveryCharge) vm.purchaseOrderDetails.invoice.modifiedDeliveryCharge = 0;
    }


    function acceptedWeight(p_index) {
      var acceptedWeight = 0;
      acceptedWeight = (vm.purchaseOrderDetails.items[p_index].acceptedWeight);
      acceptedWeight =  parseFloat(acceptedWeight.toFixed(3));
      return acceptedWeight;
    }

    /**
    * [gotoAdhocCreditNoteTaxPreview sets adhoc cedit details into the service]
    */
    function gotoAdhocCreditNoteTaxPreview() {
      if (vm.purchaseOrderDetails.invoice.deliveryCharge !== vm.purchaseOrderDetails.invoice.modifiedDeliveryCharge) {
        vm.purchaseOrderDetails.invoice.invoiceSubTotal = vm.purchaseOrderDetails.invoice.invoiceSubTotal - vm.purchaseOrderDetails.invoice.deliveryCharge + vm.purchaseOrderDetails.invoice.modifiedDeliveryCharge;
      }
      vm.purchaseOrderDetails.invoice.invoiceTotal = vm.purchaseOrderDetails.invoice.invoiceSubTotal - vm.purchaseOrderDetails.invoice.discount;
      if (vm.purchaseOrderDetails.supplier.tax === 'Active' && vm.purchaseOrderDetails.supplier.gst !== null) {
        vm.purchaseOrderDetails.invoice.addedGst = (vm.purchaseOrderDetails.invoice.invoiceTotal * vm.purchaseOrderDetails.supplier.gst)/100;
      }
      vm.purchaseOrderDetails.invoice.grandTotal = vm.purchaseOrderDetails.invoice.invoiceTotal + vm.purchaseOrderDetails.invoice.addedGst;
      SupplierAdhocCreditNoteService.setAdhocCreditNoteItems(vm.purchaseOrderDetails);
      $state.go('supplier.reports.invoice-report.adhoc-credit-note.tax-preview', {
        purchaseOrderNumber: vm.purchaseOrderNumber,
        deliveryDate: vm.purchaseOrderDetails.items[0].deliveryDate
      }, {
        reload: false
      });
    }

    /**
    * [individualTotalPrice finds individual credit amount]
    * @param  {[number]} p_index [index of the item]
    * @return {[number]}         [individual amount]
    */
    function individualTotalPrice(p_index) {
      var item = vm.purchaseOrderDetails.items[p_index];
      var inTotalPrice;
      if (item.uom.weightRequired) {
        if (item.uom.moqPrice === true)
        {
            inTotalPrice = (item.adhocCreditWeight/item.moq) * item.price;
        }
        else
        {
            inTotalPrice = item.adhocCreditWeight * item.price;
        }

        vm.purchaseOrderDetails.items[p_index].adhocCreditQuantity = null;
      } else {
        if (item.uom.moqPrice === true)
        {
            inTotalPrice = (item.adhocCreditQuantity/item.moq) * item.price;
        }
        else
        {
            inTotalPrice = item.adhocCreditQuantity * item.price;
        }
        vm.purchaseOrderDetails.items[p_index].adhocCreditWeight = null;
      }
      vm.purchaseOrderDetails.items[p_index].adhocCreditNoteAmount = inTotalPrice;
      return inTotalPrice;
    }

    /**
    * [totalCreditAmount finds total credit amount]
    * @return {[number]} [total credit amount]
    */
    function totalCreditAmount() {
      var totalCreditAmount = 0;
      _.forEach(vm.purchaseOrderDetails.items, function(item) {
        if (!item.freeItem) {
          totalCreditAmount += item.adhocCreditNoteAmount;
        }
      });
      vm.purchaseOrderDetails.adhocCreditTotal = totalCreditAmount;
      return totalCreditAmount;
    }

    /**
    * [gstAmount finds gst amount]
    * @return {[number]} [gst amount]
    */
    function gstAmount() {
      var subTotal = vm.totalCreditAmount();
      var gstAmount = 0;
      gstAmount = (vm.purchaseOrderDetails.supplier.gst / 100) * subTotal;
      vm.purchaseOrderDetails.adhocCreditGst = gstAmount;
      return gstAmount;
    }

    /**
    * [grandTotalCredit find grand total credit amount]
    * @return {[number]} [grand total credit amount]
    */
    function grandTotalCredit() {
      var totalCredit = 0;
      var subTotal = vm.totalCreditAmount();
      if(vm.purchaseOrderDetails.supplier.tax === 'Active' && vm.purchaseOrderDetails.supplier.gst !== null) {
        var gstAmount = vm.gstAmount();
        totalCredit += subTotal;
        totalCredit += gstAmount;
      }
      else {
        totalCredit += subTotal;
      }
      vm.purchaseOrderDetails.adhocCreditGrandTotal = totalCredit;
      return (totalCredit);
    }

    function downloadURI(uri, name)
    {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = name;
      link.href = uri;
      link.click();
    }

    function getAdHocCreditNotePDF() {
      PDFExportService.getAdHocCreditNotePDF({
        poNumber: vm.purchaseOrderNumber,
        deliveryDate: !vm.deliveryDate ? '' : vm.deliveryDate,
      }).then(function(response){
        downloadURI(response.data.message, "PDF_" + vm.poNumber);
      },function(error) {
        NotificationService.error({
          title: 'AdHoc Credit Note PDF Export',
          error: error
        });
      });
    }

  }
})();
