/**
 * @Author: Ajay
 * @Date:   06-Apr-2017 10:21 +05:30
 * @Project: Ezyprocure
 * @Filename: access-denied.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 08-Sep-2017 12:56 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('supplier.contactadmin', {
      parent: 'supplier',
      url: '/contact/admin',
      data: {
        authorities: []
      },
      params: {
        'from': 'support'
      },
      views: {
        'content@': {
          templateUrl: 'app/layouts/contact-admin/contact-admin.html',
          controller: 'ContactAdminController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate,$translatePartialLoader) {
          $translatePartialLoader.addPart('error');
          return $translate.refresh();
        }]
      }
    })
    .state('buyer.contactadmin', {
      parent: 'buyer',
      url: '/contact/admin',
      data: {
        authorities: []
      },
      params: {
        'from': 'support'
      },
      views: {
        'content@': {
          templateUrl: 'app/layouts/contact-admin/contact-admin.html',
          controller: 'ContactAdminController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate,$translatePartialLoader) {
          $translatePartialLoader.addPart('error');
          return $translate.refresh();
        }]
      }
    });
  }
})();
