/**
* @Author: Vaibhavi
* @Date:   16-Nov-2018 11:05 +05:30
* @Project: Ezyprocure
* @Filename: buyer.tools.contracted-suppliers.controller.js
 * @Last modified by:   Vaibhavi
 * @Last modified time: 27-Nov-2018 11:05 +05:30
*/



(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .controller('ContractedSuppliersController', ContractedSuppliersController);
  
    ContractedSuppliersController.$inject = [ 'NotificationService', 'pagingParams', 'BuyerSupplierMappingService', 'paginationConstants'];
  
    function ContractedSuppliersController(NotificationService, pagingParams, BuyerSupplierMappingService,  paginationConstants) {
      var vm = this;
      vm.predicate = pagingParams.predicate;
      vm.reverse = pagingParams.ascending;
      vm.sort = sort;
      vm.loadAll = loadAll;
      vm.selectContractedSupplier=selectContractedSupplier;
      vm.selectAllContractedSuppliers=selectAllContractedSuppliers;
      vm.buyerSupplierMappingsList = [];
     
      vm.query = {
        limit: paginationConstants.itemsPerPage,
        page: 1
      };
      vm.limitOptions = paginationConstants.limitOptions;
  
      function sort() {
        var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
        return result;
      }
  
      // Load All supplier items which are mapped  -- getAdminSuppliers
      function loadAll(search) {
        vm.selectAll = false;
        vm.selectAllItems = false;
        if (!search) {
          vm.searchText = '';
        }
        BuyerSupplierMappingService.getAllMappedSupplierBuyers({
          page: vm.query.page - 1,
          size: vm.query.limit,
          sort: sort(),
          query :vm.searchText
        }).then(function(response){
          vm.totalItems = response.headers('X-Total-Count');
          vm.buyerSupplierMappings = response.data;
        },function(error){
          NotificationService.error({
            title: 'alertMessage.SUPPLIERS_LIST',
            error: error
          });
        });
      }

      //updates various suppliers as contracted or non-contracted for the logged in buyer
      function selectAllContractedSuppliers() {
        vm.selectAllItems ? vm.selectAllItems = true : vm.selectAllItems = false;
        angular.forEach(vm.buyerSupplierMappings, function(buyerSupplierMapping) {
          buyerSupplierMapping.isContracted = vm.selectAllItems;
        });
        vm.selectContractedSupplier(vm.buyerSupplierMappings);
      }

      function selectContractedSupplier(buyerSupplierMappings) {
        vm.buyerSupplierMappingsList = [];
        if(buyerSupplierMappings instanceof Array){
          vm.buyerSupplierMappingsList = buyerSupplierMappings;
        }
        else {
          vm.buyerSupplierMappingsList =  Array.from([buyerSupplierMappings]);
        }
        BuyerSupplierMappingService.mapContractedSuppliers(vm.buyerSupplierMappingsList).then(function(response) {
          NotificationService.success({
            title: 'global.navbar.contractedSuppliers',
            message: 'alertMessage.UPDATED_SUCCESSFULLY'
          });
        }, function(error) {
          NotificationService.error({
            title: 'alertMessage.CONTRACTED_SUPPLIER_UPDATE_FAILED',
            error: error
          });
        });
      }

      vm.loadAll();
    
    }
  })();
  