/**
 * @Author: Ajay
 * @Date:   16-Oct-2018 15:59 +05:30
 * @Project: Ezyprocure
 * @Filename: auth.handler.js
 * @Last modified by:   Ajay
 * @Last modified time: 16-Oct-2018 17:10 +05:30
 */



(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .factory('authHandler', authHandler);
  
    authHandler.$inject = ['$rootScope', 'Idle'];
  
    function authHandler($rootScope, Idle) {
      return {
        initialize: initialize
      };
  
      function initialize() {
        
        var authenticationSuccess = $rootScope.$on('authenticationSuccess', function() {
          //Uncomment Idle.watch(); to watch idle condition
          //Idle.watch();
        });
  
        var authenticationFailed = $rootScope.$on('authenticationFailed', function() {
          //Uncomment Idle.unwatch(); to unwatch idle condition
          //Idle.unwatch();
        });
  
        $rootScope.$on('$destroy', function() {
          if(angular.isDefined(authenticationSuccess) && authenticationSuccess !== null){
            authenticationSuccess();
          }
          if(angular.isDefined(authenticationFailed) && authenticationFailed !== null){
            authenticationFailed();
          }
        });
  
      }
  
    }
  })();
  