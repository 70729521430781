/*
 * @Author: Ajay 
 * @Date: 2019-02-11 11:32:26 
 * @Project: Ezyprocure
 * @Filename: ezyap.supplier.dashboard.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-10-24 10:45:24
 */




(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('EzyapSupplierDashboardController', EzyapSupplierDashboardController);

  EzyapSupplierDashboardController.$inject = ["EzyapDashboardService", 'GLOBAL_CONSTANTS', 'SupplierAccountService'];

  function EzyapSupplierDashboardController(EzyapDashboardService, GLOBAL_CONSTANTS, SupplierAccountService) {

    var vm = this;
    vm.showUobBanner = GLOBAL_CONSTANTS.SHOW_UOB_BANNER;
    vm.getSupplierProfile = getSupplierProfile;
    vm.getPendingInvoicesCount = getPendingInvoicesCount;
    vm.refreshInterval = GLOBAL_CONSTANTS.DASHBOARD_REFRESH_INTERVAL;
    
    getSupplierProfile();

    // Gets the supplier profile.
    function getSupplierProfile() {
      SupplierAccountService.getSupplierProfile().
        then(function (response) {
          if (response) {
            vm.supplierProfile = response;
          }
        }, function (error) {
        });
    }

    // Gets the pendinig invoices count
    function getPendingInvoicesCount() {
      EzyapDashboardService.getPendingInvoicesCount().
        then(function (response) {
          vm.invoiceCount = response.data;
        }, function (error) {
          NotificationService.error({
            title: 'Ezyap Dashboard',
            error: error
          });
        });
    }
  }
})();
