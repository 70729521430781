/*
 * @Author: Jayalekshmi 
 * @Date: 2019-05-28 16:01:13 
 * @Project: Ezyprocure
 * @Filename: supplier.delivery-conditions.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-06-14 12:17:30
 */




(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .controller('SupplierDeliveryConditionController', SupplierDeliveryConditionController);
  
    SupplierDeliveryConditionController.$inject = ['$scope', '$mdDialog', 'pagingParams', 'SupplierDeliveryConditionService', 'paginationConstants', 'NotificationService', 'GLOBAL_CONSTANTS'];
  
    function SupplierDeliveryConditionController($scope, $mdDialog, pagingParams, SupplierDeliveryConditionService, paginationConstants, NotificationService, GLOBAL_CONSTANTS) {
      var vm = this;
  
      vm.predicate = pagingParams.predicate;
      vm.reverse = pagingParams.ascending;
      vm.getAllSupplierDeliveryConditions = getAllSupplierDeliveryConditions;
      vm.showConfirm = showConfirm;
      vm.cancel = cancel;
      vm.deleteSupplierDeliveryCondition = deleteSupplierDeliveryCondition;
      vm.supplierDeliveryConditions = [];
      vm.query = {
        limit: paginationConstants.itemsPerPage,
        page: 1
      };
      vm.limitOptions = paginationConstants.limitOptions;
      vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
      vm.getAllSupplierDeliveryConditions();
  
      function sort() {
        var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
        return result;
      }
  
      /**
      * [getAllSupplierDeliveryConditions load all supplier Delivery conditions]
      */
      function getAllSupplierDeliveryConditions(search) {
        SupplierDeliveryConditionService.getAllSupplierDeliveryConditions({
          page: vm.query.page - 1,
          size: vm.query.limit,
          sort: sort(),
          query: !search ? '': search
        }).then(function(response) {
          vm.totalItems = response.headers('X-Total-Count');
          vm.supplierDeliveryConditions = response.data;
        }, function(error) {
          NotificationService.error({
            title: 'Delivery Condition Listing',
            error: error
          });
        });
      }
  
      
      /**
      * [deleteSupplierDeliveryCondition soft delete supplier Delivery conditions]
      * @param  {[Integer]} supplierDeliveryConditionId [supplierDeliveryConditionId]
      */
      function deleteSupplierDeliveryCondition(supplierDeliveryConditionId) {
        SupplierDeliveryConditionService.deleteSupplierDeliveryCondition(supplierDeliveryConditionId).then(function(response) {
          NotificationService.success({
            title: 'Delivery Condition',
            message: 'alertMessage.DELETED_SUCCESSFULLY'
          });
          vm.getAllSupplierDeliveryConditions();
        }, function(error) {
          NotificationService.error({
            title: 'Delivery Condition Deletion',
            error: error
          });
        });
      }
  
      /**
      * [showConfirm showConfirm mdDialog]
      * @param  {[Object]} ev               [event]
      * @param  {[Object]} deliveryCondition [deliveryCondition Object]
      */
      function showConfirm(ev, deliveryCondition) {
        var confirm = $mdDialog.confirm()
        .title('Are you sure you want to delete Delivery Condition - ' + deliveryCondition.customIdentifier + ' ? ')
        .textContent('This action could not be reverted back.')
        .targetEvent(ev)
        .ok('Delete')
        .cancel('Cancel');
  
        $mdDialog.show(confirm).then(function() {
          vm.deleteSupplierDeliveryCondition(deliveryCondition.id);
        }, function() {});
      }
  
      /**
      * [cancel close mdDialog]
      */
      function cancel() {
        $mdDialog.cancel();
      }
    }
  
  
  })();
  