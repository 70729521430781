/*
 * @Author: Ajay
 * @Date: 2019-02-11 11:38:34
 * @Project: Ezyprocure
 * @Filename: ezyap.create-adhoc-invoice.controller.js
 * @Last Modified by: Lovin Chacko
 * @Last Modified time: 2021-03-03 09:11:30
 */

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('EzyapCreateAdhocInvoiceController', EzyapCreateAdhocInvoiceController);

  EzyapCreateAdhocInvoiceController.$inject = ['$scope', '$http', 'paginationConstants', 'NotificationService', 'ManageBuyerInfoService', 'GLOBAL_CONSTANTS', 'CreateAdhocInvoiceService', '$state', 'SupplierAccountService', 'SupplierDataService', 'ManageDeliveryDetailsService', 'EZYAP_CONSTANTS', 'BuyerDataService', 'Auth'];

  function EzyapCreateAdhocInvoiceController($scope, $http, paginationConstants, NotificationService, ManageBuyerInfoService, GLOBAL_CONSTANTS, CreateAdhocInvoiceService, $state, SupplierAccountService, SupplierDataService, ManageDeliveryDetailsService, EZYAP_CONSTANTS, BuyerDataService, Auth) {

    var vm = this;
    vm.todaysDate = new Date();
    vm.minDate = new Date(new Date().getTime() - (7 * 24) * 60 * 60 * 1000);
    vm.state = 1;
    vm.switchForm = switchForm;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.adhocInvoice = {};
    vm.getBuyerInformation = getBuyerInformation;
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns
    vm.addNewRow = addNewRow;
    vm.deleteRow = deleteRow;
    vm.createAdhocInvoice = createAdhocInvoice;
    vm.getSupplierProfile = getSupplierProfile;
    vm.calculateAllowanceAmount = calculateAllowanceAmount;
    vm.calculateLineExtensionAmount = calculateLineExtensionAmount;
    vm.calculateTaxAmount = calculateTaxAmount;
    vm.getDeliveryDetails = getDeliveryDetails;
    vm.countryCodes = EZYAP_CONSTANTS.ezyap.countryCodes;
    vm.itemClassificationCode = EZYAP_CONSTANTS.ezyap.itemClassificationCode;
    vm.taxCategoryIds = EZYAP_CONSTANTS.ezyap.taxCategoryIds;
    vm.getDeliveryDetailsById = getDeliveryDetailsById;
    vm.allowanceChargeDetails = {};
    vm.taxDetails = {};
    vm.partyLegalDetails = {};
    vm.adhocInvoice = {
      itemsList: [{
      }],
      moreInformation: [{}]
    }
    vm.userRole = Auth.getUserRole();
    vm.taxDetails.taxCategoryPercent = vm.userRole.pretty == 'supplier' ? SupplierDataService.getSupplierProfile().supplier.gst : 0;
    vm.getBuyerProfile = getBuyerProfile;
    vm.getProfile = getProfile;
    vm.getAllUOMs = getAllUOMs;
    vm.taxAmount = 0;
    vm.taxInclusiveAmount = 0;
    vm.payableAmount = 0;
    vm.chargeTotalAmount = 0;
    vm.getUomName = getUomName;
    vm.returnUomName = returnUomName;
    vm.getCustomerDetails = getCustomerDetails;
    vm.confirmAndSendAdhocInvoice = confirmAndSendAdhocInvoice;
    vm.setDeliveryObj = setDeliveryObj;
    vm.AGDEndpoint = EZYAP_CONSTANTS.AGDEndpoint;
    vm.isForAGD = false;
    vm.paymentTerms = paymentTerms;
    vm.checkCharacterLength = checkCharacterLength;
    vm.count = 0;
    vm.invoiceIssueDate = vm.todaysDate;
    vm.invoiceDueDate = vm.todaysDate;



    function checkCharacterLength(adhocInvoiceAddMoreInfo) {
      vm.count = 0;
      if (vm.isForAGD) {
        _.forEach(adhocInvoiceAddMoreInfo, function (each) {
          if(each.label)
            vm.count += each.label.length;
          if(each.data)
            vm.count += each.data.length;
        });
      }
      if (vm.count < 254) {
        vm.switchForm(6);
      }
    }

    function paymentTerms() {
      CreateAdhocInvoiceService.getPaymentTerms().then(function (responce) {
        vm.paymentTermsList = responce.data;
      });
    }

    //Gets the logged in users profile
    function getProfile() {
      if (vm.userRole.pretty == 'buyer') {
        vm.getBuyerProfile();
      }
      if (vm.userRole.pretty == 'supplier') {
        vm.getSupplierProfile();
      }
    }

    //Gets the customer details by Id
    function getCustomerDetails(id) {
      vm.customerDetails = _.find(vm.buyerDetails, function (obj) {
        if (obj.buyerInformationId == id) {
          isForAGD(obj.receiverKey);
          vm.customerContactName = (!obj.addressContactName || obj.addressContactName.length > 20) && vm.isForAGD;
        }
        return obj.buyerInformationId == id ? obj.addressOrganisationName : '';
      });
    }

    //Gets the logged in buyer profile
    function getBuyerProfile() {
      vm.buyerProfileInfo = BuyerDataService.getBuyerProfile();
      vm.supplierDetails = {
        companyId: vm.buyerProfileInfo.buyerUser.buyer.buyerCode,
        organisationName: vm.buyerProfileInfo.buyerUser.buyer.companyName,
        supplierCompanyRegistrationNumber: vm.buyerProfileInfo.buyerUser.buyer.companyRegistrationNumber,
        contactName: vm.buyerProfileInfo.buyerUser.buyer.contactPersonName,
        contactEmail: vm.buyerProfileInfo.buyerUser.user.email,
        streetName: vm.buyerProfileInfo.buyerUser.buyer.companyAddress,
        additionalStreetName: '',
        cityName: '',
        postalZone: vm.buyerProfileInfo.buyerUser.buyer.companyPostalCode,
        countryCode: 'SG',
        contactTelephone: vm.buyerProfileInfo.buyerUser.phoneNumber
      }

    }

    //Gets the logged in supplier profile
    function getSupplierProfile() {
      vm.supplierProfileInfo = SupplierDataService.getSupplierProfile();
      vm.supplierDetails = {
        companyId: vm.supplierProfileInfo.supplier.supplierCode,
        organisationName: vm.supplierProfileInfo.supplier.companyName,
        supplierCompanyRegistrationNumber: vm.supplierProfileInfo.supplier.companyRegistrationNumber,
        contactName: vm.supplierProfileInfo.supplier.contactPersonName,
        contactEmail: vm.supplierProfileInfo.supplier.contactEmail,
        streetName: vm.supplierProfileInfo.supplier.companyAddress,
        additionalStreetName: '',
        cityName: '',
        postalZone: '',
        countryCode: 'SG',
        contactTelephone: ''
      }
    }

    vm.getProfile();

    /**
     * [create new adhoc invoice]
     */
    function createAdhocInvoice() {
      // vm.allowanceChargeDetails.allowanceChargeIndicator=='NA'?vm.allowanceChargeDetails.allowanceChargeIndicator='false':'';
      vm.allowanceChargeDetails.chargeTotalAmount = parseFloat(vm.allowanceChargeDetails.chargeTotalAmount);
      // if(vm.adhocInvoice.moreInformation){
      var moreInfo = JSON.stringify(vm.adhocInvoice.moreInformation);
      // }
      _.map(vm.adhocInvoice.itemsList, function (item, index) {
        vm.adhocInvoice.itemsList[index].orderLineReference = index + 1;
      })
      vm.adhocInvoiceDTO = {
        deliveryDetails: {
          organisationName: vm.individualDeliveryDetails.address.organisationName,
          contactName: vm.individualDeliveryDetails.address.contactName,
          contactEmail: vm.individualDeliveryDetails.address.contactEmail,
          companyId: vm.individualDeliveryDetails.address.companyId,
          cityName: vm.individualDeliveryDetails.address.cityName,
          streetName: vm.individualDeliveryDetails.address.streetName,
          additionalStreetName: vm.individualDeliveryDetails.address.additionalStreetName,
          postalZone: vm.individualDeliveryDetails.address.postalZone,
          countryCode: vm.individualDeliveryDetails.address.countryCode,
          contactTelephone: vm.individualDeliveryDetails.address.contactTelephone
        },
        actualDeliveryDate: vm.actualDeliveryDate ? vm.actualDeliveryDate : '',
        // allowanceChargeAmount: parseFloat(vm.allowanceChargeAmount),
        // allowanceChargeDetails: vm.allowanceChargeDetails,
        allowanceChargeAmount: vm.allowanceChargeDetails.allowanceChargeAmount,
        allowanceChargeReason: vm.allowanceChargeDetails.allowanceChargeReason,
        chargeReason: vm.allowanceChargeDetails.chargeReason,
        chargeTotalAmount: vm.allowanceChargeDetails.chargeTotalAmount,
        multiplierFactorNumeric: vm.allowanceChargeDetails.multiplierFactorNumeric,
        buyerInformationId: vm.buyerInformationId,
        buyerReference: vm.buyerReference,
        deliveryDetailsId: vm.deliveryInformationId ? vm.deliveryInformationId : null,
        invoiceDueDate: vm.invoiceDueDate,
        invoiceIssueDate: vm.invoiceIssueDate,
        invoiceNumber: vm.invoiceNumber,
        itemsList: vm.adhocInvoice.itemsList,
        lineExtensionAmount: vm.lineExtensionAmount,
        note: moreInfo ? moreInfo : '',
        // partyLegalDetails:
        // {
        //   customerPartyLegalEntityCompanyId: vm.partyLegalDetails.customerPartyLegalEntityCompanyId ? vm.partyLegalDetails.customerPartyLegalEntityCompanyId : '',
        //   customerPartyTaxSchemeCompanyId: vm.partyLegalDetails.customerPartyTaxSchemeCompanyId ? vm.partyLegalDetails.customerPartyTaxSchemeCompanyId : '',
        //   supplierPartyLegalEntityCompanyId: vm.partyLegalDetails.supplierPartyLegalEntityCompanyId ? vm.partyLegalDetails.supplierPartyLegalEntityCompanyId : '',
        //   supplierPartyTaxSchemeCompanyId: vm.partyLegalDetails.supplierPartyTaxSchemeCompanyId ? vm.partyLegalDetails.supplierPartyTaxSchemeCompanyId : ''
        // },
        payableAmount: vm.payableAmount,
        supplierCompanyRegistrationNumber: vm.supplierCompanyRegistrationNumber ? vm.supplierCompanyRegistrationNumber : '',
        supplierDetails:
        {
          companyId: vm.supplierDetails.companyId ? vm.supplierDetails.companyId : '',
          organisationName: vm.supplierDetails.organisationName,
          supplierCompanyRegistrationNumber: vm.supplierDetails.supplierCompanyRegistrationNumber,
          contactName: vm.supplierDetails.contactName ? vm.supplierDetails.contactName : '',
          contactEmail: vm.supplierDetails.contactEmail ? vm.supplierDetails.contactEmail : '',
          streetName: vm.supplierDetails.streetName ? vm.supplierDetails.streetName : '',
          additionalStreetName: vm.supplierDetails.additionalStreetName ? vm.supplierDetails.additionalStreetName : '',
          cityName: vm.supplierDetails.cityName ? vm.supplierDetails.cityName : '',
          postalZone: vm.supplierDetails.postalZone ? vm.supplierDetails.postalZone : '',
          countryCode: vm.supplierDetails.countryCode,
          contactTelephone: vm.supplierDetails.contactTelephone ? vm.supplierDetails.contactTelephone : ''
        },
        taxAmount: parseFloat(vm.taxAmount),
        taxDetails: vm.taxDetails,
        taxExclusiveAmount: vm.taxExclusiveAmount,
        taxInclusiveAmount: parseFloat(vm.taxInclusiveAmount),
        taxableAmount: vm.taxableAmount,
        paymentTerms: vm.paymentTermsNote
      }
      var isDeliveryDetailsEqual = _.isEqual(vm.deliveryObj, vm.adhocInvoiceDTO.deliveryDetails);
      vm.adhocInvoiceDTO.isEdited = !isDeliveryDetailsEqual;
      CreateAdhocInvoiceService.createAdhocInvoice(vm.adhocInvoiceDTO)
        .then(function (response) {
          $state.go('ezyap.' + vm.userRole.pretty + '.peppol-invoices-summary');
          NotificationService.success({
            'title': 'global.navbar.adhocInvoice',
            'message': 'alertMessage.CREATED_SUCCESSFULLY'
          });
        }, function (error) {
          NotificationService.error({
            title: 'global.navbar.adhocInvoice',
            error: error
          });
        });
    }

    function setDeliveryObj(id, form) {
      _.filter(vm.deliveryDetails, function (data) {
        if (data.id == id) {
          vm.deliveryObj = {
            'organisationName': vm.individualDeliveryDetails.address.organisationName,
            'contactName': vm.individualDeliveryDetails.address.contactName,
            'contactEmail': vm.individualDeliveryDetails.address.contactEmail,
            'companyId': vm.individualDeliveryDetails.address.companyId,
            'cityName': vm.individualDeliveryDetails.address.cityName,
            'streetName': vm.individualDeliveryDetails.address.streetName,
            'additionalStreetName': vm.individualDeliveryDetails.address.additionalStreetName,
            'postalZone': vm.individualDeliveryDetails.address.postalZone,
            'countryCode': vm.individualDeliveryDetails.address.countryCode,
            'contactTelephone': vm.individualDeliveryDetails.address.contactTelephone
          }
        };
      });
    }

    /**
     * [switch to the next form]
     * @param {Integer} state [the current active form]
     */
    function switchForm(state, val) {
      switch (state) {
        case 1: vm.state2 = false;
          break;
        case 2: vm.state3 = false;
          break;
        case 3: vm.state4 = false;
          break;
        case 4: vm.state5 = false;
          break;
        case 5: vm.state6 = false;
          break;
        case 6: vm.state7 = false;
          break;
      }
      if (val) {
        vm.state = state;
      } else {
        vm.state = state + 1;
      }
    }

    /**
     * [add new row to the table]
     * @param {String} type [the table to which the row need to be added]
     */
    function addNewRow(type) {
      switch (type) {
        case 'addNewMoreInfoRow':
          if (vm.count < 254 ||  vm.adhocInvoice.moreInformation.length == 0) {
            vm.adhocInvoice.moreInformation.push({});
          }
          break;
        case 'addNewAdhocInvoiceRow':
          vm.adhocInvoice.itemsList.push({});
          // vm.adhocInvoice.itemsList[vm.flag-1].orderLineReference=vm.flag;
          break;
      }
    }

    /**
     * [delete row from the table]
     * @param {String} type [the table to which the row need to be deleted]
     * @param {index} index [the index of the row to be deleted]
     */
    function deleteRow(type, index) {
      switch (type) {
        case 'deleteAdhocInvoiceRow':
          vm.flag--;
          vm.adhocInvoice.itemsList.splice(index, 1);
          break;
        case 'deleteMoreInfoRow':
          vm.count = 0;
          vm.adhocInvoice.moreInformation.splice(index, 1);
          break;
      }
    }
    function isForAGD(reciverKey) {
      vm.isForAGD = vm.AGDEndpoint == reciverKey ? true : false;
      if (vm.isForAGD) {
        vm.state2 = true;
        vm.state3 = true;
        vm.state4 = true;
        vm.state5 = true;
        vm.state6 = true;
        vm.state7 = true;
        CreateAdhocInvoiceService.getBusinessUnit().then(function (responce) {
          vm.businessUnitList = responce.data;
        });
      }
      else {
        vm.state2 = false;
        vm.state3 = false;
        vm.state4 = false;
        vm.state5 = false;
        vm.state6 = false;
        vm.state7 = false;
      }
    }

    /**
     * [get all buyer information]
     */
    function getBuyerInformation() {
      if (vm.buyerDetails) {
        return false;
      }
      ManageBuyerInfoService.getBuyerInformation({ query: "" }).
        then(function (response) {
          vm.buyerDetails = response.data;
        }, function (error) {
          NotificationService.error({
            title: '',
            error: error
          });
        });
    }



    // Calculates Allowance amount
    function calculateAllowanceAmount() {
      // vm.allowanceChargeDetails.allowanceChargeIndicator=='NA'?vm.allowanceChargeAmount = 0:'';
      vm.lineExtensionAmount = _.reduce(vm.adhocInvoice.itemsList, function (sum, cur) {
        return sum + parseFloat(cur.lineExtensionAmount);
      }, 0);
      if (vm.discount) {
        vm.allowanceChargeDetails.allowanceChargeAmount = ((vm.lineExtensionAmount * vm.discount) / 100).toFixed(2);
      }
      else {
        vm.allowanceChargeDetails.allowanceChargeAmount = '';
      }
      if (vm.lineExtensionAmount) {
        if (vm.allowanceChargeDetails.multiplierFactorNumeric == undefined) {
          vm.allowanceChargeDetails.chargeTotalAmount = '';
        }
        else {
          vm.allowanceChargeDetails.chargeTotalAmount = (((vm.lineExtensionAmount * vm.allowanceChargeDetails.multiplierFactorNumeric) / 100).toFixed(2));
        }
      }
      vm.calculateTaxAmount();
    }

    function calculateLineExtensionAmount(price, invoicedQuantity, index) {
      if (invoicedQuantity && price) {
        vm.adhocInvoice.itemsList[index].lineExtensionAmount = (invoicedQuantity * price).toFixed(2);
      }
    }

    /**
     * [getDeliveryDetails Gets the delivery details]
     */
    function getDeliveryDetails() {
      ManageDeliveryDetailsService.getDeliveryDetails({ buyerInformationId: vm.buyerInformationId ? vm.buyerInformationId : "" }).
        then(function (response) {
          vm.deliveryDetails = response.data;
          vm.totalItems = response.headers('X-Total-Count');
        }, function (error) {
          NotificationService.error({
            title: 'global.customerInformation',
            error: error
          });
        });
    }

    /**
     * [getDeliveryDetailsById Gets the delivery details by id]
     * @param {*} deliveryInformationId
     */
    function getDeliveryDetailsById(deliveryInformationId) {
      ManageDeliveryDetailsService.getDeliveryDetailsById(deliveryInformationId).
        then(function (response) {
          vm.individualDeliveryDetails = response.data;
        }, function (error) {
          NotificationService.error({
            title: 'Delivery datails',
            error: error
          });
        });
    }

    /**
     * [getDeliveryDetailsById Gets all UOMs]
     */
    function getAllUOMs() {
      if (vm.uoms) {
        return false;
      }
      var params = {
        page: 0,
        size: paginationConstants.maxLimit
      };
      CreateAdhocInvoiceService.getAllUOMs(params).
        then(function (response) {
          vm.uoms = response.data;
        }, function (error) {
        });
    }

    /**
     * [calculateTaxAmount Calculates tax amount]
     */
    function calculateTaxAmount() {
      if (vm.lineExtensionAmount) {
        if (vm.allowanceChargeDetails.chargeTotalAmount && vm.allowanceChargeDetails.allowanceChargeAmount === "") {
          vm.taxExclusiveAmount = parseFloat(vm.allowanceChargeDetails.chargeTotalAmount) + parseFloat(vm.lineExtensionAmount);
          vm.chargeTotalAmount = vm.allowanceChargeDetails.chargeTotalAmount;
        }
        else if (vm.allowanceChargeDetails.chargeTotalAmount === "" && vm.allowanceChargeDetails.allowanceChargeAmount) {
          vm.taxExclusiveAmount = parseFloat(vm.lineExtensionAmount) - vm.allowanceChargeDetails.allowanceChargeAmount;
          vm.chargeTotalAmount = 0;
        }
        else if (vm.allowanceChargeDetails.chargeTotalAmount && vm.allowanceChargeDetails.allowanceChargeAmount) {
          vm.taxExclusiveAmount = parseFloat(vm.allowanceChargeDetails.chargeTotalAmount) + parseFloat(vm.lineExtensionAmount) - vm.allowanceChargeDetails.allowanceChargeAmount;
          vm.chargeTotalAmount = vm.allowanceChargeDetails.chargeTotalAmount;
        }
        else {
          vm.taxExclusiveAmount = parseFloat(vm.lineExtensionAmount);
          vm.chargeTotalAmount = 0;
        }
        vm.taxableAmount = vm.taxExclusiveAmount;
        vm.taxAmount = ((vm.taxExclusiveAmount * (vm.taxDetails.taxCategoryPercent)) / 100).toFixed(2);
        vm.taxInclusiveAmount = (vm.taxExclusiveAmount + parseFloat(vm.taxAmount)).toFixed(2);
        vm.payableAmount = vm.taxInclusiveAmount;
      }
      else {
        vm.taxExclusiveAmount = 0;
        vm.taxInclusiveAmount = 0;
        vm.taxableAmount = 0;
        vm.lineExtensionAmount = 0;
      }
    }
    /**
     * [getUomName find UOM name by id]
     */
    function getUomName(id) {
      var uom = _.filter(vm.uoms, function (obj) {
        return obj.id == id;
      });
      vm.selectedUom = uom[0].name;
    }

    function returnUomName(id) {
      var uom = _.filter(vm.uoms, function (obj) {
        return obj.id == id;
      });
      return uom[0].name;
    }

    /**
    * [create new adhoc invoice]
    */
    function confirmAndSendAdhocInvoice() {
      vm.allowanceChargeDetails.chargeTotalAmount = parseFloat(vm.allowanceChargeDetails.chargeTotalAmount);
      var moreInfo = JSON.stringify(vm.adhocInvoice.moreInformation);
      _.map(vm.adhocInvoice.itemsList, function (item, index) {
        vm.adhocInvoice.itemsList[index].orderLineReference = index + 1;
      })
      vm.adhocInvoiceDTO = {
        deliveryDetails: {
          organisationName: vm.individualDeliveryDetails.address.organisationName,
          contactName: vm.individualDeliveryDetails.address.contactName,
          contactEmail: vm.individualDeliveryDetails.address.contactEmail,
          companyId: vm.individualDeliveryDetails.address.companyId,
          cityName: vm.individualDeliveryDetails.address.cityName,
          streetName: vm.individualDeliveryDetails.address.streetName,
          additionalStreetName: vm.individualDeliveryDetails.address.additionalStreetName,
          postalZone: vm.individualDeliveryDetails.address.postalZone,
          countryCode: vm.individualDeliveryDetails.address.countryCode,
          contactTelephone: vm.individualDeliveryDetails.address.contactTelephone
        },
        actualDeliveryDate: vm.actualDeliveryDate ? vm.actualDeliveryDate : '',
        allowanceChargeAmount: vm.allowanceChargeDetails.allowanceChargeAmount,
        allowanceChargeReason: vm.allowanceChargeDetails.allowanceChargeReason,
        chargeReason: vm.allowanceChargeDetails.chargeReason,
        chargeTotalAmount: vm.allowanceChargeDetails.chargeTotalAmount,
        multiplierFactorNumeric: vm.allowanceChargeDetails.multiplierFactorNumeric,
        buyerInformationId: vm.buyerInformationId,
        buyerReference: vm.buyerReference,
        deliveryDetailsId: vm.deliveryInformationId ? vm.deliveryInformationId : null,
        invoiceDueDate: vm.invoiceDueDate,
        invoiceIssueDate: vm.invoiceIssueDate,
        invoiceNumber: vm.invoiceNumber,
        itemsList: vm.adhocInvoice.itemsList,
        lineExtensionAmount: vm.lineExtensionAmount,
        note: moreInfo ? moreInfo : '',
        payableAmount: vm.payableAmount,
        supplierCompanyRegistrationNumber: vm.supplierCompanyRegistrationNumber ? vm.supplierCompanyRegistrationNumber : '',
        supplierDetails:
        {
          companyId: vm.supplierDetails.companyId ? vm.supplierDetails.companyId : '',
          organisationName: vm.supplierDetails.organisationName,
          supplierCompanyRegistrationNumber: vm.supplierDetails.supplierCompanyRegistrationNumber,
          contactName: vm.supplierDetails.contactName ? vm.supplierDetails.contactName : '',
          contactEmail: vm.supplierDetails.contactEmail ? vm.supplierDetails.contactEmail : '',
          streetName: vm.supplierDetails.streetName ? vm.supplierDetails.streetName : '',
          additionalStreetName: vm.supplierDetails.additionalStreetName ? vm.supplierDetails.additionalStreetName : '',
          cityName: vm.supplierDetails.cityName ? vm.supplierDetails.cityName : '',
          postalZone: vm.supplierDetails.postalZone ? vm.supplierDetails.postalZone : '',
          countryCode: vm.supplierDetails.countryCode,
          contactTelephone: vm.supplierDetails.contactTelephone ? vm.supplierDetails.contactTelephone : ''
        },
        taxAmount: parseFloat(vm.taxAmount),
        taxDetails: vm.taxDetails,
        taxExclusiveAmount: vm.taxExclusiveAmount,
        taxInclusiveAmount: parseFloat(vm.taxInclusiveAmount),
        taxableAmount: vm.taxableAmount,
        paymentTerms: vm.paymentTermsNote
      }
      var isDeliveryDetailsEqual = _.isEqual(vm.deliveryObj, vm.adhocInvoiceDTO.deliveryDetails);
      vm.adhocInvoiceDTO.isEdited = !isDeliveryDetailsEqual;
      CreateAdhocInvoiceService.confirmAndSendAdhocInvoice(vm.adhocInvoiceDTO)
        .then(function (response) {
          $state.go('ezyap.' + vm.userRole.pretty + '.peppol-invoices-summary');
          NotificationService.success({
            'title': 'global.navbar.adhocInvoice',
            'message': 'alertMessage.CREATED_SUCCESSFULLY'
          });
        }, function (error) {
          NotificationService.error({
            title: 'global.navbar.adhocInvoice',
            error: error
          });
        });
    }

  }
})();
