/**
* @Author: sneha
* @Date:   22-Mar-2018 12:03 +05:30
* @Project: Ezyprocure
* @Filename: supplier.label-printing.service.js
 * @Last modified by:   sneha
 * @Last modified time: 05-Apr-2018 15:32 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('SupplierLabelPrintingService', SupplierLabelPrintingService);

  SupplierLabelPrintingService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  function SupplierLabelPrintingService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var supplierLabelPrintingService = {
      getAllItemsByOutletId: getAllItemsByOutletId,
      createLabel: createLabel
    };

    return supplierLabelPrintingService;

    //Get all items mapped to business outlet
    function getAllItemsByOutletId(params){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.LABEL_PRINTING +'items-mapped',
        method: "GET",
        params: params
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Generate Label for item
    function createLabel(label) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.LABEL_PRINTING, label).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
