/**
* @Author: Mufsir
* @Date:   15-Dec-2016 18:34 +05:30
* @Project: Ezyprocure
* @Filename: sort-by.directive.js
* @Last modified by:   Ajay
* @Last modified time: 27-Mar-2017 18:02 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .directive('jhSortBy', jhSortBy);

  function jhSortBy() {
    var directive = {
      restrict: 'A',
      scope: false,
      require: '^jhSort',
      link: linkFunc
    };

    return directive;

    function linkFunc(scope, element, attrs, parentCtrl) {
      element.bind('click', function() {
        parentCtrl.sort(attrs.jhSortBy);
      });
    }
  }
})();
