/*
 * @Author: Abhay
 * @Date: 19-Aug-2020 10:32 IST
 * @Filename: ezypayment.admin.tools.ntp-users-list.contoller.js
 * @Last Modified by: Abhay
 * @Last Modified time: 19-Aug-2020 10:32 IST
 */

(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .controller('EzypaymentAdminNTPUsersList', EzypaymentAdminNTPUsersList);
  
    EzypaymentAdminNTPUsersList.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', 'toastr','pagingParams', 'UTCDateService', 'NotificationService', 'GLOBAL_CONSTANTS', 'paginationConstants', '$window', 'EzypaymentAdminToolsCompanyListService', 'EXPORT_CONSTANTS', '$stateParams'];
  
    function EzypaymentAdminNTPUsersList($scope, $rootScope, $q, $filter, $state, toastr, pagingParams, UTCDateService, NotificationService, GLOBAL_CONSTANTS, paginationConstants, $window, EzypaymentAdminToolsCompanyListService, EXPORT_CONSTANTS, $stateParams) {
        var vm = this;
        // initialization
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.limitOptions = paginationConstants.limitOptions;
        vm.query = {
            limit: paginationConstants.itemsPerPage,
            page: 1
        };
        vm.selectAll = false;
        vm.ntpCompanyList = [];
        vm.selectedCompanys = [];

        vm.sort = sort;
        vm.loadAll = loadAll;
        vm.selectAllCompany = selectAllCompany;
        vm.filterSelectedCompanies = filterSelectedCompanies;
        vm.triggerPayment = triggerPayment;
        vm.convertObjectToArray = convertObjectToArray;

        vm.loadAll();

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            return result;
        }

        function selectAllCompany() {
            vm.ntpCompanyList.forEach(function(company) {
                company.isSelected = true;
            });
            vm.selectedCompanys = vm.ntpCompanyList;
        }

        function filterSelectedCompanies() {
            vm.selectedCompanys = vm.ntpCompanyList.filter(function(company) {
                return company.isSelected;
            })
        }

        function triggerPayment() {
            var companyMap = createCompanyMap();
            EzypaymentAdminToolsCompanyListService.triggerPayment(companyMap).then(function (response) {
                toastr('Payment triggered', 'NTP Company List')
                loadAll();
            }, function (error) {
                NotificationService.error({
                    title: 'NTP Company List',
                    error: error
                });
            });
        }

        function createCompanyMap() {
            var companyMap = {};
            vm.selectedCompanys.forEach(function(company) {
                companyMap[company.objectId] = company.companyName;
            });
            return companyMap;
        }

        function resetList() {
            vm.selectedCompanys = [];
            vm.ntpCompanyList = [];
        }
 
        function convertObjectToArray(companyMap) {
            resetList();
            if(companyMap && Object.keys(companyMap).length > 0) {
                for(var objectId in companyMap) {
                    var companyObject = {
                        "objectId": objectId,
                        "companyName": companyMap[objectId],
                        "isSelected": false
                    }
                    vm.ntpCompanyList.push(companyObject);
                }
            }
        }

        function loadAll() {
            EzypaymentAdminToolsCompanyListService.fetchNTPCompanyList().then(function (response) {
                var companyMap = response.data;
                vm.convertObjectToArray(companyMap);
            }, function (error) {
                NotificationService.error({
                    title: 'NTP Company List',
                    error: error
                });
            });
        }
    }
})();
