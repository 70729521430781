/**
 * @Author: Ajay
 * @Date:   28-Jul-2017 15:53 +05:30
 * @Project: Ezyprocure
 * @Filename: login.controller.js
 * @Last modified by:   Allan
 * @Last modified time: 07-Aug-2017 15:19 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('LoginController', LoginController);

  LoginController.$inject = ['$rootScope', '$window', '$state', '$cookies', '$mdDialog', 'toastr', '$timeout', 'Auth', 'Principal','$location','MobileUserService','NotificationService', 'GLOBAL_CONSTANTS', '$stateParams', 'API_CONSTANTS'];

  function LoginController ($rootScope, $window, $state, $cookies, $mdDialog, toastr, $timeout, Auth, Principal,  $location, MobileUserService, NotificationService, GLOBAL_CONSTANTS, $stateParams, API_CONSTANTS) {
    var vm = this;
    vm.login = login;
    vm.password = null;
    vm.rememberMe = true;
    vm.username = null;
    vm.idToken = $stateParams.idToken;
    vm.initLogin = initLogin;
    vm.initLanding = initLanding;
    vm.sslCertificateUrl = GLOBAL_CONSTANTS.SSL_CERTIFICATE_URL;
    vm.registerNow = registerNow;
    vm.xBorderActivated = xBorderActivated;

    $timeout(function(){angular.element('#username').focus();});

    function initLogin () {
      vm.xBorderActivated();
      var isAlreadyLogged =   $cookies.get('rememberMe');

      //check if user authenticated and remember me flag set
      Principal.checkCBEnabled();
      if(Principal.isAuthenticated && isAlreadyLogged === "true") {
        Auth.roleBasedRoute();
      }
    }

    function xBorderActivated(){
      Auth.getXborderActivate().then( function(response){
        vm.xBorder = response.data;
      });
    }
    function registerNow(){
      $window.location.href = API_CONSTANTS.cbBase + '#/register/acc-agent-sel' ;
    }
  
    function initLanding() {
      if($location.search().pushId) {
        MobileUserService.setDeviceToken($location.search().pushId);
      }
      Auth.redirectToPreviousState();
    }

    function subscribeToNotification() {
      var deviceToken = MobileUserService.getDeviceToken();
      if(deviceToken){
        MobileUserService.updateNotification(deviceToken).then(function(response) {
          NotificationService.success({
            title: 'global.menu.entities.notification',
            message: 'alertMessage.SUCCESSFULLY_SUBSCRIBED'
          });
        }, function(error) {
          NotificationService.error({
            title: 'Notification subscription',
            error: error
          });
        });
      }
    }

    function login () {
      Auth.login({
        username: vm.username,
        password: vm.password,
        rememberMe: vm.rememberMe,
        idToken: vm.idToken
      }).then(function (data) {
        Principal
          .checkCBEnabled()
          .then(function () {
            Auth.roleBasedRoute();
          })
          .catch(function () {
            Auth.roleBasedRoute();
          });
        subscribeToNotification();
      }).catch(function () {
        toastr.error('Invalid Username or Password!', 'Authentication Failed');
      });
    }

  }
})();
