/**
 * @Author: Jayalekshmi 
 * @Date: 2019-01-03 10:40:23 
 * @Project: Ezyprocure
 * @Filename: sats.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-04-10 11:33:48
 */



(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .controller('SatsController', SatsController);
  
    SatsController.$inject = ['$state', 'Auth', 'GLOBAL_CONSTANTS'];
  
    function SatsController($state, Auth, GLOBAL_CONSTANTS) {
      var vm = this;
      
      vm.userRole = Auth.getUserRole();
      vm.selectedJob = 'po';
      vm.createSelectedJob = createSelectedJob;
      vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;

      /**
       * [createSelectedJob Redirects to the corresponding add job page]
       */
      function createSelectedJob(){
        $state.go(vm.userRole.pretty+'.sats.'+ vm.selectedJob +'-add', {
          reload: false
        });
      }
    }
  })();
  