/**
 * @Author: Ajay
 * @Date:   24-Oct-2017 12:55 +05:30
 * @Project: Ezyprocure
 * @Filename: supplier.requirement-pending-po.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 18-Dec-2017 14:59 +05:30
 */



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('SupplierRequirementPendingPOService', SupplierRequirementPendingPOService);

  SupplierRequirementPendingPOService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function SupplierRequirementPendingPOService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var supplierRequirementPendingPOService = {
      processPendingPO: processPendingPO,
      processPendingPOAdhocOnBoard: processPendingPOAdhocOnBoard
    };
    return supplierRequirementPendingPOService;

    function processPendingPO(requirementNumber) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE + requirementNumber+ '/requirement-pending-po' ).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function processPendingPOAdhocOnBoard(requirementNumber) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE + requirementNumber+ '/requirement-pending-adhoc-po-onboard' ).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
