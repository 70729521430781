/**
* @Author: Ajay
* @Date:   14-Dec-2016 12:54 +05:30
* @Project: Ezyprocure
* @Filename: compile.config.js
* @Last modified by:   Ajay
* @Last modified time: 27-Mar-2017 18:55 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(compileServiceConfig);

  compileServiceConfig.$inject = ['$compileProvider','DEBUG_INFO_ENABLED'];

  function compileServiceConfig($compileProvider,DEBUG_INFO_ENABLED) {
    // disable debug data on prod profile to improve performance
    $compileProvider.debugInfoEnabled(DEBUG_INFO_ENABLED);

    /*
    If you wish to debug an application with this information
    then you should open up a debug console in the browser
    then call this method directly in this console:

    angular.reloadWithDebugInfo();
    */
  }
})();
