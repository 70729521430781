/*
 * @Author: Lovin
 * @Date: 2019-06-21 11:50:55
 * @Project: Ezyprocure
 * @Filename: admin.uen-report.controller.js
 * @Last Modified by: Lovin Chacko
 * @Last Modified time: 2020-05-14 12:16:58
 */


(function() {
    'use strict';

    angular
    .module('ezyprocureApp')
    .controller('UENReportController', UENReportController);

    UENReportController.$inject = ['$scope', '$rootScope', '$state', 'pagingParams', '$q', 'paginationConstants', 'GLOBAL_CONSTANTS', 'EXPORT_CONSTANTS', 'toastr', 'CSVExportService', 'NotificationService','$filter','UENReportService','UTCDateService', 'AuditLogReportService', 'UTCDateService'];

    function UENReportController($scope, $rootScope, $state, pagingParams, $q, paginationConstants, GLOBAL_CONSTANTS, EXPORT_CONSTANTS, toastr, CSVExportService, NotificationService, $filter, UENReportService, UTCDateService) {
      var vm = this;
      vm.todaysDate = new Date();
      vm.tomorrow = new Date();
      vm.tomorrow.setDate(vm.tomorrow.getDate() + 1);
      vm.tomorrow = UTCDateService.utcToDate(vm.tomorrow);
      vm.predicate = pagingParams.predicate;
      vm.reverse = pagingParams.ascending;
      vm.loadAll = loadAll;
      vm.defaultDateTimeFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_TIME_FORMAT;
      vm.listViews = GLOBAL_CONSTANTS.LIST_VIEWS;
      vm.filter = {};

      vm.query = {
        limit: paginationConstants.itemsPerPage,
        page: 1
      };
      vm.limitOptions = paginationConstants.limitOptions;
      vm.getUENReport = getUENReport;
      vm.getUENReportConsolidated = getUENReportConsolidated;
      vm.getUenConsolidatedCSV = getUenConsolidatedCSV;
      vm.UenConsolidatedCSVHeader = EXPORT_CONSTANTS.CSV.UenConsolidatedCSVHeader;

      function sort() {
        var result = ['name' + ',' + (vm.reverse ? 'asc' : 'desc')];
        return result;
      }

    // Get all logs for UEN 
      function loadAll(view) {
        view == 'Individual' ? getUENReport() : getUENReportConsolidated();
      }
        
    // get uen reeport consloidated
    function getUENReportConsolidated() {
      UENReportService.getUENReportConsolidated({
        fromDate: vm.filter.fromDate? UTCDateService.utcFromDate(vm.filter.fromDate):'',
        toDate: vm.filter.toDate? UTCDateService.utcFromDate(vm.filter.toDate):'',
        identifier: vm.filter.uenNumber ? vm.filter.uenNumber : '',
        page: vm.query.page - 1,
        size: vm.query.limit,
        reportType: 'Consolidated'
      }).then(function(response) {
        vm.uenConsolidate = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      }, function(error) {
        NotificationService.error({
          title: 'Audit Log',
          error: error
        });
      });
    }

    // get UEN report for listView
    function getUENReport() {
      UENReportService.getUENReport({
        fromDate: vm.filter.fromDate? UTCDateService.utcFromDate(vm.filter.fromDate):'',
        toDate: vm.filter.toDate? UTCDateService.utcFromDate(vm.filter.toDate):'',
        role :vm.filter.accountType,
        uen: vm.filter.uenNumber,
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort()
      }).then(function(response) {
        vm.uenLogs = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      }, function(error) {
        NotificationService.error({
          title: 'Audit Log',
          error: error
        });
      });
    }

    //download uen consolidataed csv
    function getUenConsolidatedCSV(){
      var deferred = $q.defer();
      if(vm.filter.listView != 'Consolidated'){
        toastr.error('CSV Export Only For Consolidated!', 'CSV Export');
      
          return deferred.promise;
      } 
      if(vm.uenConsolidate)
      var csvData =  CSVExportService.getUenConsolidatedCSV(vm.uenConsolidate);

      return csvData;
    }
    
  }
    

  })();
