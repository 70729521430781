/**
* @Author: Ajay
* @Date:   27-Feb-2017 16:21 +05:30
* @Project: Ezyprocure
* @Filename: buyer.supplier-onboarding.controller.js
* @Last modified by:   Liya
 * @Last modified time: 12-Feb-2020 18:38
*/

(function () {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('MySubscriptionPlansController', MySubscriptionPlansController);

  MySubscriptionPlansController.$inject = ['$scope', '$state', '$filter', 'toastr', 'paginationConstants', 
  'MyPlansService', 'SupplierDataService', 'BuyerDataService', 'AuthServerProvider', '$stateParams', '$mdDialog',
  '$translate'];

  function MySubscriptionPlansController($scope, $state, $filter, toastr, paginationConstants, 
    MyPlansService, SupplierDataService, BuyerDataService, AuthServerProvider, $stateParams, $mdDialog,
    $translate) {
    var vm = this;
    vm.loadAll = loadAll;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.totalItems;
    vm.oldPlansList = [];
    vm.fetchMyPlans = fetchMyPlans;
    vm.oninit = oninit;
    vm.changeOutlet = changeOutlet;
    vm.increaseOutlets = increaseOutlets;
    vm.changeOutletSize = changeOutletSize;

    function oninit(ev) {
      if($stateParams.planid) {
        MyPlansService.getPlanDetailsById($stateParams.planid)
          .then(function(plan) {
            showUnsubscribeModal(ev, plan.plan);
          })
      }
      loadAll();
    }

    function showUnsubscribeModal(ev, plan) {
      var confirm = $mdDialog.confirm()
      .title($filter('translate')('ezyprocureApp.plan.unsubscribe_plan', {planName: plan.name}))
      .textContent('  ')
      .targetEvent(ev)
      .ok('Ok')
      .cancel('Cancel');

      $mdDialog.show(confirm).then(function () {
        MyPlansService.unsubscribePlanById({
          userPlanId: $stateParams.planid,
          subscribeStatus: false
        }).then(function() {
          toastr.success($translate.instant('ezyprocureApp.plan.unsubscribe_success'));
          loadAll();
        }).catch(function() {
          toastr.error('Something went wrong', 'Server Error');
        })
      }, function () {
      });
    }

    function loadAll() {
      var authJWT = AuthServerProvider.getCbToken();
      var decAuthJWT = jwt_decode(authJWT);
      vm.role = decAuthJWT.auth;
      if (vm.role == 'ROLE_SUPPLIER') {
        vm.isBuyer = false;
        vm.userId = SupplierDataService.getCbSupplierProfile().supplier.id;
      }
      else if (vm.role == 'ROLE_BUYER') {
        vm.isBuyer = true;
        vm.userId = BuyerDataService.getCbBuyerProfile().buyerUser.buyer.id;
      }
      vm.oldplans = [];
      fetchMyPlans();
      fetchCurrentFuturePlans();
    }
    function fetchMyPlans() {
      var request = {
        buyer: vm.isBuyer,
        companyId: vm.userId,
        page: vm.query.page,
        size: vm.query.limit
      };
      MyPlansService.fetchOldPlans(request)
        .then(function (response) {
          vm.oldplans = response.content;
          vm.totalItems = response.totalPages;
        }).catch(function () {
          toastr.error('Something went wrong', 'Server Error');
        });
    }
    function fetchCurrentFuturePlans() {
      MyPlansService.fetchCurrentFuturePlans(vm.userId, vm.isBuyer)
        .then(function (response) {
          if (response) {
            vm.currentDomPlans = response.CURRENT.filter(function (p) {
              return p.plan.type == 'DOMESTIC';
            }).map(function(p) {
              return Object.assign(p, {
                plan: {
                  name: p.plan.name || '-',
                  value: p.plan.currency + ' ' + p.amountPostDiscount || '-',
                  desc: '',
                  planType: p.plan.planType,
                  id: p.plan.id
                },
                isOutletsEditable: vm.isBuyer && p.plan.planType == 'subscription',
                promoCode: p.promoCode,
                valid_from: p.createdAt ? $filter('date')(p.createdAt, 'dd/MM/yyyy') : '-',
                valid_to: p.discountEndDate ? $filter('date')(p.discountEndDate, 'dd/MM/yyyy') : '-'
              });
            });
            vm.currentCBPlans = response.CURRENT.filter(function (p) {
              return p.plan.type == 'CROSSBORDER';
            }).map(function(p) {
              var desc;
              if (p.plan.planType == 'transactional') {
                if (p.promoCode) {
                  desc = p.promoCode.description;
                } else {
                  desc = p.plan.desc;
                }
              }
              return Object.assign(p, {
                plan: {
                  name: p.plan.name || '-',
                  value: p.plan.currency + ' ' + p.amountPostDiscount || '-',
                  desc: desc,
                  planType: p.plan.planType,
                  id: p.plan.id
                },
                promoCode: p.promoCode,
                valid_from: p.createdAt ? $filter('date')(p.createdAt, 'dd/MM/yyyy') : '-',
                valid_to: p.discountEndDate ? $filter('date')(p.discountEndDate, 'dd/MM/yyyy') : '-'
              });
            });
            vm.futureDomPlans = response.FUTURE.filter(function (p) {
              return p.plan.type == 'DOMESTIC';
            }).map(function(p) {
              return Object.assign(p, {
                plan: {
                  name: p.plan.name || '-',
                  value: p.plan.currency + ' ' + p.amountPostDiscount || '-',
                  desc: '',
                  planType: p.plan.planType,
                  id: p.plan.id
                },
                isOutletsEditable: vm.isBuyer && p.plan.planType == 'subscription',
                promoCode: p.promoCode,
                valid_from: p.createdAt ? $filter('date')(p.createdAt, 'dd/MM/yyyy') : '-',
                valid_to: p.discountEndDate ? $filter('date')(p.discountEndDate, 'dd/MM/yyyy') : '-'
              });
            });
            vm.futureCBPlans = response.FUTURE.filter(function (p) {
              return p.plan.type == 'CROSSBORDER';
            }).map(function(p) {
              var desc;
              if (p.plan.planType == 'transactional') {
                if (p.promoCode) {
                  desc = p.promoCode.description;
                } else {
                  desc = p.plan.desc;
                }
              }
              return Object.assign(p, {
                plan: {
                  name: p.plan.name || '-',
                  value: p.plan.currency + ' ' + p.amountPostDiscount || '-',
                  desc: desc,
                  planType: p.plan.planType,
                  id: p.plan.id
                },
                promoCode: p.promoCode,
                valid_from: p.createdAt ? $filter('date')(p.createdAt, 'dd/MM/yyyy') : '-',
                valid_to: p.discountEndDate ? $filter('date')(p.discountEndDate, 'dd/MM/yyyy') : '-'
              });
            });
          }
        }).catch(function () {
          toastr.error('Something went wrong', 'Server Error');
        });
    }

    function changeOutlet(size) {
      if (size >= 1) {
        vm.outletSize = size;
      }
    }

    function increaseOutlets(planId, outlet) {
      vm.selectedPlanId = planId;
      vm.currentOutlet = outlet;
      vm.outletSize = outlet;
      $mdDialog.show({
        templateUrl: 'app/common-modules/plans/my-plans/templates/change-outlet.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        clickOutsideToClose: true
      });
    }

    vm.close = function() {
      $mdDialog.cancel();
    }
    
    function changeOutletSize() {
      $mdDialog.cancel();
      if(vm.currentOutlet > vm.outletSize) {
        return $mdDialog.show({
          templateUrl: 'app/common-modules/plans/my-plans/templates/outlet-message.html',
          controller: function() {
            return vm;
          },
          controllerAs: 'vm',
          clickOutsideToClose: true
        });
      }
      $state.go('buyer.addcard', {
        updateOutlets: true,
        numberOfOutlets: vm.outletSize - vm.currentOutlet,
        OutletUserPlanId: vm.selectedPlanId
      });
    }
  }
})();