/**
* @Author: Ajay
* @Date:   14-Dec-2016 12:54 +05:30
* @Project: Ezyprocure
* @Filename: login.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 18-Apr-2018 11:29 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('login', {
      parent: 'account',
      url: '/login',
      data: {
      },
      views: {
        'content@': {
          templateUrl: 'app/account/login/login.html',
          controller: 'LoginController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate,$translatePartialLoader) {
          $translatePartialLoader.addPart('login');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('user-management');
          return $translate.refresh();
        }]
      }
    })
    .state('login.ntp', {
      parent: 'account',
      url: '/login/:idToken',
      data: {
      },
      views: {
        'content@': {
          templateUrl: 'app/account/login/login.html',
          controller: 'LoginController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate,$translatePartialLoader) {
          $translatePartialLoader.addPart('login');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('user-management');
          return $translate.refresh();
        }]
      }
    })
    . state('init', {
      parent: 'account',
      url: '/',
      data: {
      },
      views: {
        'content@': {
          templateUrl: 'app/account/login/landing.html',
          controller: 'LoginController',
          controllerAs: 'vm'
        }
      }
    })
    . state('landing', {
      parent: 'account',
      url: '/landing',
      data: {
      },
      views: {
        'content@': {
          templateUrl: 'app/account/login/landing.html',
          controller: 'LoginController',
          controllerAs: 'vm'
        }
      }
    });
  }
})();
