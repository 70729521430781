/**
* @Author: Jayalekshmi
* @Date:   24-Feb-2017 12:40 +05:30
* @Project: Ezyprocure
* @Filename: supplier.delivery-conditions.add.controller.js
* @Last modified by:   Jayalekshmi
* @Last modified time: 24-Apr-2018 12:08 +05:30
*/



(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .controller('SupplierAddDeliveryConditionController', SupplierAddDeliveryConditionController);
  
    SupplierAddDeliveryConditionController.$inject = ['$scope', '$state', 'TIME_CONSTANTS', 'toastr', 'ImageUploadService', 'SupplierDeliveryConditionService', 'UomService', 'ItemGroupService', 'BuyerSupplierMappingService', 'BuyerBusinessService', 'SupplierDataService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'NotificationService'];
  
    function SupplierAddDeliveryConditionController($scope, $state, TIME_CONSTANTS, toastr, ImageUploadService, SupplierDeliveryConditionService, UomService, ItemGroupService, BuyerSupplierMappingService, BuyerBusinessService, SupplierDataService, GLOBAL_CONSTANTS, paginationConstants, NotificationService) {
      var vm = this;
  
      vm.save = save;
      vm.orderTimes = TIME_CONSTANTS.timeConstants;  
      vm.checkDeliveryConditions = checkDeliveryConditions;
      vm.deliveryConditionsObj = {}
      vm.deliveryConditionsObj.leadTime = 0;
  
      /**
       * [Save -Creates the delivery condition]
       */
      function save() {
        SupplierDeliveryConditionService.createSupplierDeliveryCondition(vm.deliveryConditionsObj).then(function(response) {
          NotificationService.success({
            title: 'DeliveryCondition',
            message: 'alertMessage.CREATED_SUCCESSFULLY'
          });
          $state.go('supplier.tools.managedeliveryconditions', {reload: false});
        }, function(error) {
          NotificationService.error({
            title: 'DeliveryCondition Creation',
            error: error
          });
        });
      }

      //Check for delivery conditions
      function checkDeliveryConditions() {
        if (!vm.deliveryConditions.today) {
          vm.deliveryConditionsObj.orderTimeForToday = null;
        }
        if (!vm.deliveryConditions.friday) {
          vm.deliveryConditionsObj.orderTimeForFriday = null;
        }
        if (!vm.deliveryConditions.saturday) {
          vm.deliveryConditionsObj.orderTimeForSaturday = null;
        }
        if (!vm.deliveryConditions.minimum) {
          vm.deliveryConditionsObj.minimumOrderForFreeDelivery = null;
          vm.deliveryConditionsObj.belowMinimumPurchaseCondition = null;
        }
      }
    }
  })();
  