/**
 * @Author: Ajay
 * @Date:   03-Aug-2018 11:57 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.item-management.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 03-Aug-2018 11:58 +05:30
 */



(function() {
  'use strict';
  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('buyer.item-management', {
      abstract: true,
      parent: 'buyer',
      url: '/item-management',
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          return $translate.refresh();
        }]
      }
    });
  }
})();
