/**
 * @Author: Mufsir
 * @Date:   20-Mar-2017 06:48 +05:30
 * @Project: Ezyprocure
 * @Filename: price-update-request.service.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2020-03-13 17:58:30
 */



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('PriceUpdateRequestService', PriceUpdateRequestService);

  PriceUpdateRequestService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  function PriceUpdateRequestService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var priceUpdateRequestService = {
      getAllPriceUpdates : getAllPriceUpdates,
      updatePriceUpdateRequestStatus: updatePriceUpdateRequestStatus,
      updatePriceUpdateRequestStatusForAll : updatePriceUpdateRequestStatusForAll
    };
    return priceUpdateRequestService;

    //Get pending price update requests
    function getAllPriceUpdates(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PRICE_UPDATES_RESOURCE,
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //approve or reject a price update request
    function updatePriceUpdateRequestStatus(status, priceUpdateItem) {
      var deferred = $q.defer();
      priceUpdateItem.buyerRemark = priceUpdateItem.buyerRemark ? priceUpdateItem.buyerRemark : '';
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyer/' + status + '/price-updates/' + priceUpdateItem.id, {}, {params:{buyerRemark: priceUpdateItem.buyerRemark}}).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [updatePriceUpdateRequestStatusForAll approves or rejects price for all selected items]
     */
    function updatePriceUpdateRequestStatusForAll(status, priceUpdateItems){
      var deferred = $q.defer();
      priceUpdateItems.buyerRemark = priceUpdateItems.buyerRemark ? priceUpdateItems.buyerRemark : '';
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyer/' + status + '/'+ RESOURCE_URL_CONSTANTS.PRICE_UPDATES_RESOURCE +'bulk', priceUpdateItems.priceUpdateIdList, {params:{buyerRemark: priceUpdateItems.buyerRemark}}).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
