/*
 * @Author: Lovin C 
 * @Date: 2021-08-29 11:47:40 
 * @Last Modified by: Lovin C
 * @Last Modified time: 2021-09-13 13:04:02
 */




(function () {
  'use strict';

  angular.module('ezyprocureApp').factory('AdminCustomPaymentsService', AdminCustomPaymentsService);

  AdminCustomPaymentsService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  function AdminCustomPaymentsService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var AdminCustomPaymentsService = {
      customPaymentsList: customPaymentsList,
      approveInvoices: approveInvoices,
      rejectInvoices: rejectInvoices

    };
    return AdminCustomPaymentsService;

    function approveInvoices(data, otp) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'customs/approve',
        method: "POST",
        params: data,
        headers: { 'authorization-otp' : otp }
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function rejectInvoices(data) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'customs/reject',
        method: "POST",
        params: data
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function customPaymentsList(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin/customs-payments-listing',
        method: "GET",
        params: params
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
