/**
* @Author: Ajay
* @Date:   18-Jan-2017 19:03 +05:30
* @Project: Ezyprocure
* @Filename: supplier.tools.state.js
* @Last modified by:   Ajay
* @Last modified time: 15-May-2017 16:07 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('supplier.tools', {
      abstract: true,
      parent: 'supplier',
      url: '/tools',
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          return $translate.refresh();
        }],
        hasModuleAccess: ['Auth','$q', '$state', function(Auth, $q, $state) {
          if(Auth.hasPermission('accessForTools')){
            return true;
          }else {
            return $q.reject('Unauthorized');
          }
        }]
      }
    });
  }
})();
