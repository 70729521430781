/**
 * @Author: lovinc
 * @Date:   2018-11-27T11:07:07+05:30
 * @Project: Ezyprocure
 * @Filename: supplier.item.item-termination.controller.js
 * @Last modified by:   lovinc
 * @Last modified time: 2018-11-29T10:02:23+05:30
 */



(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('SupplierItemTerminationController', SupplierItemTerminationController);

  SupplierItemTerminationController.$inject = ['$scope', '$rootScope', '$filter', '$state', '$stateParams', 'pagingParams', 'paginationConstants', 'SupplierDataService', 'BuyerBusinessService', 'BrandManagementService', 'BuyerSupplierMappingService', 'UTCDateService', 'SupplierItemTerminationService', 'NotificationService'];

  function SupplierItemTerminationController($scope, $rootScope, $filter, $state, $stateParams, pagingParams, paginationConstants, SupplierDataService, BuyerBusinessService, BrandManagementService, BuyerSupplierMappingService, UTCDateService, SupplierItemTerminationService, NotificationService) {
    var vm = this;
    vm.getAllBrandsByBuyerId = getAllBrandsByBuyerId;
    vm.getAllBusinessOutletsByBrandId = getAllBusinessOutletsByBrandId;
    vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
    vm.getAllBuyerBusinessMappedItems = getAllBuyerBusinessMappedItems;
    vm.searchText = '';
    vm.todaysDate = new Date();
    vm.thisDay = UTCDateService.utcToDate(vm.todaysDate);
    vm.tomorrow = new Date();
    vm.newDate=vm.tomorrow.setDate(vm.tomorrow.getDate() + 1);
    vm.newDate = UTCDateService.utcToDate(vm.todaysDate);
    vm.checkAll = checkAll;
    vm.createTerminationList = createTerminationList;
    vm.terminateItems = terminateItems;
    vm.itemTerminationDateChanged = itemTerminationDateChanged;
    vm.nextDay = new Date();
    vm.initializeDatepicker = initializeDatepicker;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.filter = {};
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    vm.initializeDatepicker(vm.thisDay);

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    function initializeDatepicker(date){
      vm.nextDay = new Date(date);
      vm.nextDay.setDate(date.getDate()+1);
    }

    /**
    * selecting all items
    */
    function checkAll() {
      vm.selectAll ? vm.selectAll = true : vm.selectAll = false;
      angular.forEach(vm.items, function (item) {
        item.selected = vm.selectAll;
      });
    }

   /**
     * get all business outlets of the selected brand
     * @param {integer} brandId
     */
    function getAllBusinessOutletsByBrandId(brandId) {
      vm.filter.brandBusinessIdList = [];
      var params = {
        brandId: brandId
      };
      var supplierId = SupplierDataService.getSupplierProfile().supplier.id
      SupplierItemTerminationService.getAllBrandsByBuyerId(params, supplierId).then(function (response) {
        vm.brandBusinessOutlets = response.data;
      }, function (error) {
      });
    }

    /**
     * [selectAll Selects all from the list]
     */
    function selectAll() {
      vm.filter.brandBusinessIdList = _.map(vm.brandBusinessOutlets, _.property('id'));
    }

    /**
     * [deselectAll Deselect all from the list]
     */
    function deselectAll() {
      vm.filter.brandBusinessIdList = [];
    }

    /**
    * [getAllBrandsByBuyerId Get list of brands of the corresponding buyer]
    */
    function getAllBrandsByBuyerId(buyerId) {
      vm.brandId = null;
      BrandManagementService.getAllBrandsByBuyerId({
        query: '',
        page: 0,
        size: paginationConstants.maxLimit,
        buyerId: buyerId
      }).then(function (response) {
        vm.brands = response.data;
      }, function (error) {
      });
    }

    /**
     * getAllSupplierMappedBuyers gets all buyers mapped to the supplier
     */
    function getAllSupplierMappedBuyers() {
      vm.brandId = '';
      vm.filter.brandBusinessIdList = [];
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
      };
      if (vm.buyers)
        return false;
      var supplierId = SupplierDataService.getSupplierProfile().supplier.id;
      BuyerSupplierMappingService.getAllSupplierMappedBuyers(supplierId, params).then(function (response) {
        vm.buyers = response.data;
      }, function (error) {
      });
    }

    /**
     * getAllBuyerBusinessMappedItems gets all buyer and business outlet mapped items
     */
    function getAllBuyerBusinessMappedItems(search) {
      var data = {
        businessOutletIdList: vm.filter.brandBusinessIdList ? vm.filter.brandBusinessIdList : [],
        buyerId: vm.buyers.id,
        query: search ? search : ''
      }
      var params = {
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort()
      }
      SupplierItemTerminationService.getAllBuyerOutletMappedItems(params,data).then(function (response) {
        vm.totalItems = response.headers('X-Total-Count');
        _.each(response.data, function(element, index) {
          _.extend(element, {fromDate: angular.copy(vm.thisDay), endDate:angular.copy(vm.nextDay)});
        });
        vm.items = response.data;
      }, function (error) {
      });
    }

    /**
     * createTerminationList filters out the selected items and creates a list of items to be terminated
     * @param {object} items
     */
    function createTerminationList(items) {

      //Fetch all  items where item.selected = true
      var selectedItems = _.filter(items, {
        'selected': true
      });
      //itemDetailsTerminationList is created to pass as a request for item termination
      var itemDetailsTerminationList = [];
      _.map(selectedItems, function(item){ itemDetailsTerminationList.push({
        'endDate' : item.endDate,
        'fromDate' : item.fromDate,
        'remark' : item.remark,
        'perpetual' : item.perpetual ? item.perpetual:false,
        'item' : item
      })
    })
      return itemDetailsTerminationList;
    }

    /**
    * for converting utc to date
    * @param {date} date
    * @param {string} type
    * @param {integer} index
    */
    function itemTerminationDateChanged(date, type, index) {
      if(date){
        date = UTCDateService.utcToDate(date);
        if (type != 'endDate'){
          vm.initializeDatepicker(date);
        }
        //reset end date if it is before start date
        var isValidDate = moment(vm.items[index].fromDate).isAfter(moment(vm.items[index].endDate));
        if (type === 'startDate' && isValidDate) {
          vm.items[index].endDate = null;
        }
      }
    }

    /**
     * terminateItems terminates the selected items
     */
    function terminateItems() {
      var terminationList = vm.createTerminationList(vm.items);
      if (terminationList.length == 0) {
        NotificationService.simpleErrorToast({
          title: 'error.validation',
          message: 'global.pleaseSelectedOneFromTheList'
        });}
      else {
      var params = {
        businessOutletIdList: vm.filter.brandBusinessIdList ? vm.filter.brandBusinessIdList : [],
        buyerId: vm.buyers.id,
        brandId: vm.brandId,
        itemTerminationDetailsDTOList: terminationList,
      }
      SupplierItemTerminationService.terminateItems(params).then(function (response) {
        vm.recordError = response.data;
        if (vm.recordError.message !== 'failure'){
        NotificationService.success({
          title: 'global.navbar.itemTermination',
          message: 'alertMessage.COMPLETED_SUCCESSFULLY'
        });
        $state.reload();
      }
      }, function (error) {
        NotificationService.error({
          title: 'global.navbar.itemTermination',
          error: error
        });
      });
     }
    }
  }
})();
