/**
* @Author: Mufsir
* @Date:   18-Apr-2017 21:12 +05:30
* @Project: Ezyprocure
* @Last modified by:   Mufsir
* @Last modified time: 18-Apr-2017 21:39 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .directive('fileModel', fileModel);

  fileModel.$inject = ['$parse'];

  function fileModel($parse) {
    var directive = {
      restrict: 'A',
      link: linkFunc
    };

    return directive;

    function linkFunc(scope, element, attrs) {
      var model = $parse(attrs.fileModel);
      var modelSetter = model.assign;
      element.bind('change', function(){
        scope.$apply(function(){
          modelSetter(scope, element[0].files[0]);
        });
      });
    }
  }
})();
