/*
 * @Author: Abhay
 * @Date: 2021-05-05 14:36:29 
 * @Last Modified by: Abhay
 * @Last Modified time: 2021-05-05 14:36:29
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzypaymentAdminAxsPaymentsMasterController', EzypaymentAdminAxsPaymentsMasterController);

  EzypaymentAdminAxsPaymentsMasterController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', 'toastr','pagingParams', 'UTCDateService', 'NotificationService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'BuyerBusinessService', 'ManageBuyerService', 'EzypaymentAdminStatementOfAccountService', 'BrandManagementService', 'ManageSupplierService', '$window', 'EXPORT_CONSTANTS', 'CSVExportService', 'EzypaymentAdminManageMerchantDetailsService', '$stateParams', 'EzypaymentAdminAXSPaymentsService'];

  function EzypaymentAdminAxsPaymentsMasterController($scope, $rootScope, $q, $filter, $state, toastr, pagingParams, UTCDateService, NotificationService, GLOBAL_CONSTANTS, paginationConstants, BuyerBusinessService, ManageBuyerService, EzypaymentAdminStatementOfAccountService, BrandManagementService, ManageSupplierService, $window, EXPORT_CONSTANTS, CSVExportService, EzypaymentAdminManageMerchantDetailsService, $stateParams, EzypaymentAdminAXSPaymentsService) {

    var vm = this;
    vm.currentNavItem = "page1";
    vm.disableInkBar = false;
    vm.goTo = goTo;
    function goTo() {

    }
  }
})();
