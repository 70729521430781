/**
* @Author: Lovin
* @Date:   04-Apr-2020 11:44 +05:30
* @Project: Ezyprocure
* @Filename: supplier.print-picking-list-po.controller.js
 * @Last modified by:   Lovin
 * @Last modified time: 2020-04-15T15:21:31+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierPrintPickingListPOController', SupplierPrintPickingListPOController);

  SupplierPrintPickingListPOController.$inject = ['$scope', '$rootScope','$q', '$filter', '$state', '$stateParams', 'toastr', 'pagingParams', '$mdDialog', 'UTCDateService', 'SupplierPickingListService', 'NotificationService', 'PurchaseOrderService', 'GLOBAL_CONSTANTS', 'paginationConstants','CSVExportService', 'EXPORT_CONSTANTS','CallbackResourceService', 'GLOBAL_CONFIG_CONSTANTS', 'PDFExportService'];

  function SupplierPrintPickingListPOController($scope, $rootScope,$q, $filter, $state, $stateParams, toastr, pagingParams, $mdDialog, UTCDateService, SupplierPickingListService, NotificationService, PurchaseOrderService, GLOBAL_CONSTANTS, paginationConstants, CSVExportService, EXPORT_CONSTANTS,CallbackResourceService, GLOBAL_CONFIG_CONSTANTS, PDFExportService ) {
    var vm = this;
    vm.todaysDate = new Date();
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.loadAll = loadAll;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.poStatuses = GLOBAL_CONSTANTS.PO_STATUSES;
    vm.listViews = GLOBAL_CONSTANTS.LIST_VIEWS;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.limitOptions = paginationConstants.limitOptions;
    vm.poStatusClass = GLOBAL_CONFIG_CONSTANTS.PURCHASE_ORDER_STATUS.LABEL_CLASS;
    vm.checkAll = checkAll;
    vm.getSelectedPOs = getSelectedPOs;
    vm.getSelectedPOIds = getSelectedPOIds;
    vm.selectAll = false;
    vm.getSelectedPOItems = getSelectedPOItems;
    vm.getMassDeliveryOrderPDF = getMassDeliveryOrderPDF;
    vm.showDownloadsTemplate =showDownloadsTemplate;
    vm.printPickingList = printPickingList;
    vm.getAllPickingList = getAllPickingList;
    vm.showConfirmTemplate = showConfirmTemplate;
    vm.cancel = cancel;
    vm.getIndividualPODetails = getIndividualPODetails;

    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
    * [loadAll Get all Purchase Order Details]
    */
    function loadAll() {
        getIndividualPODetails();
    }


    /**
    * [getAllPickingList get all picking list template ids]
    * @return {[type]} [description]
    */
   function getAllPickingList(event){
    vm.processOrderPickingList= getSelectedPOs(vm.poItems);
    if(vm.processOrderPickingList.length>0){
      SupplierPickingListService.getAllPickingList({
        detailedView : false
      }).then(function(response) {
        vm.templateList = response.data;
        vm.showConfirmTemplate(event);
      }, function(error) {
        NotificationService.error({
          title: 'global.menu.entities.pickingList',
          error: error
        });
      });
    }
    else {
      toastr.error('Please select atleast one Process Order', 'Print Picking List!');
    }
  }

  /**
    * [showConfirmTemplate shows modal to confirm Picking List Template]
    */
   function showConfirmTemplate(event) {
    $mdDialog.show({
      templateUrl: 'app/supplier-modules/picking-list-management/print-picking-list-po/templates/supplier.print-picking-list-po.picking-list-template-confirm.tmpl.html',
      controller: function() {
        return vm;
      },
      controllerAs: 'vm',
      targetEvent: event,
      clickOutsideToClose: true
    });
  }

    /**
    * [printPickingList print picking list PDFs]
    * @param  {[type]} templateId [description]
    * @return {[type]}            [description]
    */
   function printPickingList(templateId){
     vm.purchaseOrderPickList = _.map(vm.processOrderPickingList, function(req){
       return {
         'deliveryDate': req.purchaseOrder.processOrder.deliveryDate,
         'requirementNumber': req.purchaseOrder.processOrder.requirementNumber,
         'type':'PO_TYPE'
       }
     });
    SupplierPickingListService.printPickingList(vm.purchaseOrderPickList,{
      pickingListId: templateId
    }).then(function(response) {
      NotificationService.success({
        title: 'global.menu.entities.pickingList',
        message: 'alertMessage.PRINTED_SUCCESSFULLY'
      });
      vm.downloadsList= response.urls;
      vm.showDownloadsTemplate();
      vm.loadAll();   //load pending process order list so as to update print picking list status
    }, function(error) {
      NotificationService.error({
        title: 'global.menu.entities.pickingList',
        error: error
      });
    });
  }


  /**
  * [showDownloadsTemplate shows modal listing PDF links]
  */
  function showDownloadsTemplate(event) {
    $mdDialog.show({
      templateUrl: 'app/supplier-modules/picking-list-management/print-picking-list-po/templates/supplier.print-picking-list-po.downloads.tmpl.html',
      controller: function() {
        return vm;
      },
      controllerAs: 'vm',
      targetEvent: event,
      clickOutsideToClose: false
    });
  }

   /**
    * [cancel closes modal dialog]
    */
   function cancel() {
    $mdDialog.cancel();
  }

    /**
    * [getIndividualPODetails  gets PO details for each PO Number]
    */
    function getIndividualPODetails() {
      PurchaseOrderService.getAllPurchaseOrders({
        poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber,
        purchaseOrderStatus: vm.filter.poStatus,
        invoiceStatus: '',
        invoiceNumber: '',
        businessOutletId: '',
        businessOutletName: !vm.filter.businessOutletName ? '' : vm.filter.businessOutletName,
        //If dates are selected, they are converted to UTC format
        poFromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
        poToDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
        deliveryFromDate: '',
        deliveryToDate: '',
        grnFromDate : '',
        grnToDate : '',
        query: !vm.filter.itemName ? '' : vm.filter.itemName,
        supplierId: '',
        itemGroupId: !vm.filter.itemGroupId ? '' : vm.filter.itemGroupId,
        buyerIdList: '',
        debitNoteStatus: '',
        grnNumber: '',
        buyerName: '',
        creditNoteStatus: '',
        supplierVisibilityType: '',
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        xeroExported: '',
        module:1,
        printStatus: vm.filter.pickingListPrint ? vm.filter.pickingListPrint : ''
      }).then(function(response){
        vm.poItems = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      },function(error){
        NotificationService.error({
          title: 'Print Picking List Po',
          error: error
        });
      });
    }

    // select all the POs
    function checkAll() {
      vm.selectAll ? vm.selectAll = true : vm.selectAll = false;
      angular.forEach(vm.poItems, function(poItem) {
        poItem.purchaseOrder.selected = vm.selectAll;
      });
    }

    // get selected POs from the list
    function getSelectedPOs(poItems) {
      var selectedPOs = _.filter(poItems, function(po) {
        return po.purchaseOrder.selected === true;
      });

      return selectedPOs;
    }

    //get selected POs Id
    function getSelectedPOIds(selectedPOs) {
      var purchaseOrderIdList = _.map(selectedPOs, _.property('purchaseOrder.id'));
      return purchaseOrderIdList;
    }

    // get selected process orders
    function getSelectedPOItems(poItems){
      //Fetch all ids and purchaseOrderNumber Selected PO where selectedPOs.selected = true
      var selectedPOs = vm.getSelectedPOs(poItems);
      vm.deliveryOrder = _.map(selectedPOs,  function(processOrderItem){
        return {
          'deliveryDate': processOrderItem.purchaseOrder.processOrder.deliveryDate,
          'purchaseOrderNumber': processOrderItem.purchaseOrder.purchaseOrderNumber
        };
      });
    }

    /**
     * [downloadURI downloads the PDF]
     * @param {String} uri
     * @param {String} name
     */
    function downloadURI(uri, name)
    {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = name;
      link.href = uri;
      link.click();
    }

    /**
     * [getMassDeliveryOrderPDF get the url of PDF to download]
     */
    function getMassDeliveryOrderPDF() {
      var date = new Date();
      var dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
      vm.getSelectedPOItems(vm.poItems);
      if(vm.deliveryOrder.length>0){
        PDFExportService.printMassDOPDF(vm.deliveryOrder)
         .then(function(response){
          downloadURI(response.data.message, "PDF_" + dateString);
        },function(error) {
          NotificationService.error({
            title: 'alertMessage.MASS_PRINT_DO',
            error: error
          });
        });
      }
      else{
        NotificationService.simpleErrorToast({
          title: 'alertMessage.MASS_PRINT_DO',
          message: 'global.pleaseSelectedOneFromTheList'
        });
      }
    }
  }
})();
