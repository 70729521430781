/**
* @Author: Ajay
* @Date:   27-Feb-2017 16:21 +05:30
* @Project: Ezyprocure
* @Filename: supplier.items-bulk-delete.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 10-Nov-2017 11:22 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('ItemsBulkDeleteController', ItemsBulkDeleteController);

  ItemsBulkDeleteController.$inject = ['$scope', '$rootScope', '$state', 'toastr', '$mdDialog', 'pagingParams', 'SupplierItemService', 'paginationConstants', 'NotificationService'];

  function ItemsBulkDeleteController($scope, $rootScope, $state, toastr, $mdDialog, pagingParams, SupplierItemService, paginationConstants, NotificationService) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;

    vm.loadAll = loadAll;
    vm.bulkDeleteSupplierItems = bulkDeleteSupplierItems;
    vm.checkAll = checkAll;
    vm.showConfirm = showConfirm;
    vm.itemIdList = [];
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }


    function loadAll(search) {
      if (!search) {
        vm.searchText = '';
      }
      vm.selectAll = false;
      SupplierItemService.getAllSupplierItems({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        buyerId: '',
        query: vm.searchText,
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.supplierItems = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'Items Listing',
          error: error
        });
      });
    }

    vm.loadAll();

    function checkAll() {
      vm.selectAll ? vm.selectAll = true : vm.selectAll = false;
      angular.forEach(vm.supplierItems, function(item) {
        item.selected = vm.selectAll;
      });
    }

    function getSelectedItemIdList(items) {
      var itemIds = [];
      //Fetch all ids of supplierItem in vm.supplierItems where supplierItem.selected = true
      var selectedItems = _.filter(items, {
        'selected': true
      });
      itemIds = _.map(selectedItems, _.property('id'));
      return itemIds;
    }

    function showConfirm (ev) {
      var itemIdList = getSelectedItemIdList(vm.supplierItems);
      if (_.isEmpty(itemIdList)) {
        toastr.error('No items selected', 'Bulk Delete Items');
        return false;
      }
      var confirm = $mdDialog.confirm()
      .title('Are you sure you want to delete Supplier Items?')
      .textContent('This action could not be reverted back.')
      .targetEvent(ev)
      .ok('Proceed')
      .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        bulkDeleteSupplierItems(itemIdList);
      }, function() {});
    }

    function bulkDeleteSupplierItems(itemIdList) {
      SupplierItemService.bulkDeleteSupplierItems(itemIdList).
      then(function(responseData) {
        NotificationService.success({
          title: 'Items',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'Items Deletion',
          error: error
        });
      });
    }


  }
})();
