/**
 * @Author: Jayalekshmi
 * @Date:   2018-06-28T13:51:21+05:30
 * @Filename: buyer.brand-buyer-user-mapping.controller.js
 * @Last modified by:   Jayalekshmi
 * @Last modified time: 2018-06-28T16:00:33+05:30
 */



(function() {
    'use strict';

    angular
    .module('ezyprocureApp')
    .controller('BrandBuyerUserMapAddController', BrandBuyerUserMapAddController);

    BrandBuyerUserMapAddController.$inject = ['$scope', '$rootScope' ,'$state', 'BuyerDataService', 'paginationConstants', 'NotificationService', 'BrandManagementService','BuyerUserManagementService', 'BrandBuyerUserMapService'];

    function BrandBuyerUserMapAddController($scope, $rootScope, $state, BuyerDataService, paginationConstants, NotificationService, BrandManagementService, BuyerUserManagementService, BrandBuyerUserMapService) {
      var vm = this;
      
      vm.getAllBuyerBrands = getAllBuyerBrands;
      vm.createBrandBuyerUserMapping = createBrandBuyerUserMapping;
      vm.mapping = {};
      vm.deselectAll = deselectAll;
      vm.selectAll = selectAll;
      vm.getAllBuyerUsers = getAllBuyerUsers;
      
      /**
      * [getAllBuyerBrands Get list of brands of the logged in buyer]
      */
      function getAllBuyerBrands() {
        var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
        if(vm.brands)
          return false;
        BrandManagementService.getAllBrandsByBuyerId({
          query: '',
          page: 0,
          size: paginationConstants.maxLimit,
          buyerId: buyerId
        }).then(function(response){
          vm.brands = response.data;
        },function(error){
        });
      }

      /**
       * [getAllBuyerUsers Load all buyer users]
       */
      function getAllBuyerUsers() {
        if(vm.buyerUsers)
          return false;
        BuyerUserManagementService.getAllBuyerUsers({
          page: 0,
          size: paginationConstants.maxLimit,
          query: ''
        }).then(function(response) {
          vm.buyerUsers = response.data;
        }, function(error) {
        });
      }

      /**
       * [createBrandBuyerUserMapping Map selected buyer and Buyer User]
       */
      function createBrandBuyerUserMapping() {
        BrandBuyerUserMapService.createBrandBuyerUserMapping(vm.mapping).then(function(response){
          vm.translatedError = response;
          NotificationService.success({
            title: 'Brand - Buyer User Mapping',
            message: 'Completed'
          });
          if(response.responseList.length<1){
            $state.go('buyer.tools.brand-buyer-user-mapping', {
              reload: false
            });
          }
        },function(error) {
          NotificationService.error({
            title: 'Brand - Buyer User Mapping',
            error: error
          });
        });
      }

      /**
       * [selectAll Selects all from the list]
       * @param  {[String]} dropdown [Active dropdown - buyer users]
       */
      function selectAll(dropdown) {
        if(dropdown === 'BUYER_USERS'){
          vm.mapping.buyerUserIdList = _.map(vm.buyerUsers, _.property('buyerUserId'));
        }
      }

      /**
       * [deselectAll Deselect all from the list]
       * @param  {[type]} dropdown [Active dropdown - buyer users]
       */
      function deselectAll(dropdown) {
        if(dropdown === 'BUYER_USERS'){
          vm.mapping.buyerUserIdList = [];
        }
      }
    }
})();
