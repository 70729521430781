/*
 * @Author: Lovin C 
 * @Date: 2021-08-29 11:47:54 
 * @Last Modified by: Lovin C
 * @Last Modified time: 2021-09-08 16:05:58
 */


(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
      .state('ezypayment.buyer.custom-invoice-payments', {
        parent: 'ezypayment.buyer',
        url: '/custom-invoice-payments',
        data: {
          authorities: ['ROLE_BUYER'],
        },
        views: {
          'content@': {
            templateUrl: 'app/ezypayment-modules/buyer-modules/custom-invoice-payments/ezypayment.buyer.custom-invoice-payments.html',
            controller: 'CustomInvoicePaymentsController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,desc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('supplier');
            return $translate.refresh();
          }]
        }
      })
      .state('ezypayment.buyer.custom-invoice-payments.add-invoice', {
        parent: 'ezypayment.buyer.custom-invoice-payments',
        url: '/add-invoice',

        data: {
          authorities: ['ROLE_BUYER'],
        },
        views: {
          'content@': {
            templateUrl: 'app/ezypayment-modules/buyer-modules/custom-invoice-payments/add-invoice/ezypayment.buyer.add-invoice.html',
            controller: 'AddInvoiceController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('paymentTerms');
            $translatePartialLoader.addPart('paymentModel');
            $translatePartialLoader.addPart('epdApprovalRequest');
            $translatePartialLoader.addPart('bank');
            $translatePartialLoader.addPart('buyerBusiness');
            $translatePartialLoader.addPart('brandSupplierPaymentModelMapping');
            return $translate.refresh();
          }]
        }
      })
      .state('ezypayment.buyer.custom-invoice-payments.add-invoice.preview', {
        parent: 'ezypayment.buyer.custom-invoice-payments',
        url: '/add-invoice/preview',

        data: {
          authorities: ['ROLE_BUYER'],
        },
        views: {
          'content@': {
            templateUrl: 'app/ezypayment-modules/buyer-modules/custom-invoice-payments/add-invoice/ezypayment.buyer.add-invoice.preview.html',
            controller: 'AddInvoicePreviewController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('paymentTerms');
            $translatePartialLoader.addPart('paymentModel');
            $translatePartialLoader.addPart('epdApprovalRequest');
            $translatePartialLoader.addPart('bank');
            $translatePartialLoader.addPart('buyerBusiness');
            $translatePartialLoader.addPart('brandSupplierPaymentModelMapping');
            return $translate.refresh();
          }]
        }
      })
      .state('ezypayment.buyer.custom-invoice-payments.add-invoice.report', {
        parent: 'ezypayment.buyer.custom-invoice-payments',
        url: '/report',

        data: {
          authorities: ['ROLE_BUYER'],
        },
        views: {
          'content@': {
            templateUrl: 'app/ezypayment-modules/buyer-modules/custom-invoice-payments/add-invoice/ezypayment.custom-payments.report.html',
            controller: 'CustomPaymentsReportController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('paymentTerms');
            $translatePartialLoader.addPart('paymentModel');
            $translatePartialLoader.addPart('epdApprovalRequest');
            $translatePartialLoader.addPart('bank');
            $translatePartialLoader.addPart('buyerBusiness');
            $translatePartialLoader.addPart('brandSupplierPaymentModelMapping');
            return $translate.refresh();
          }]
        }
      })
      .state('ezypayment.buyer.custom-invoice-payments.upload-invoice', {
        parent: 'ezypayment.buyer.custom-invoice-payments',
        url: '/upload-invoice',

        data: {
          authorities: ['ROLE_BUYER'],
        },
        views: {
          'content@': {
            templateUrl: 'app/ezypayment-modules/buyer-modules/custom-invoice-payments/upload-invoice/ezypayment.buyer.upload-invoice.html',
            controller: 'CustomInvoiceUploadController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('paymentTerms');
            $translatePartialLoader.addPart('paymentModel');
            $translatePartialLoader.addPart('epdApprovalRequest');
            $translatePartialLoader.addPart('bank');
            $translatePartialLoader.addPart('buyerBusiness');
            $translatePartialLoader.addPart('brandSupplierPaymentModelMapping');
            return $translate.refresh();
          }]
        }
      })
  }

})();
