/**
* @Author: Ajay
* @Date:   14-Apr-2017 13:34 +05:30
* @Project: Ezyprocure
* @Filename: supplier.invoice-pending.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 12-Oct-2018 12:48 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('supplier.invoice-pending', {
      parent: 'supplier',
      url: '/invoice-pending',
      data: {
        authorities: ['ROLE_SUPPLIER']
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/invoice-pending/supplier.invoice-pending.html',
          controller: 'SupplierInvoicePendingController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,desc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('purchaseOrder');
          $translatePartialLoader.addPart('buyerBusiness');
          $translatePartialLoader.addPart('purchaseOrderStatus');
          $translatePartialLoader.addPart('purchaseOrderType');
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }],
        hasModuleAccess: ['Auth','$q', '$state', function(Auth, $q, $state) {
          if(Auth.hasPermission('accessForInvoice')){
            return true;
          }else {
            return $q.reject('Unauthorized');
          }
        }]
      }
    })
    .state('supplier.invoice-pending.details', {
      parent: 'supplier.invoice-pending',
      url: '/details/:purchaseOrderNumber/:deliveryDate',
      data: {
        authorities: ['ROLE_SUPPLIER'],
        pageTitle: 'View Process Order'
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/invoice-pending/details/supplier.invoice-pending.details.html',
          controller: 'SupplierInvoicePendingDetailsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('invoice');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('buyerBusiness');
          $translatePartialLoader.addPart('processOrder');
          $translatePartialLoader.addPart('promotion');
          return $translate.refresh();
        }]
      }
    });
  }
})();
