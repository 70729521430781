/**
 * @Author: Ajay
 * @Date:   21-Jan-2017 21:55 +05:30
 * @Filename: buyer.business-supplier-mapping.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 2017-10-30T12:45:57+05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('buyer.tools.business-supplier-mapping', {
      parent: 'buyer.tools',
      url: '/business-supplier-mapping?page&sort&search',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'Business - Supplier Mapping'
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/tools/business-supplier-mapping/buyer.business-supplier-mapping.html',
          controller: 'BuyerBusinessSupplierMapController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },

      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          //$translatePartialLoader.addPart('buyerUserBusinessMapping');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('user-management');
          return $translate.refresh();
        }]
      }
    });
  }

})();
