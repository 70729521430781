/**
* @Author: Vaibhavi
* @Date:  06-Jun-2018 11:59 +05:30
* @Project: Ezyprocure
* @Filename: buyer.gr-variance-report.state.js
 * @Last modified by:   Vaibhavi
 * @Last modified time: 06-Jun-2018 15:39 +05:30
*/



(function() {
  'use strict';

  angular
    .module('ezyprocureApp')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
      .state('buyer.business-charts.gr-variance-report', {
        parent: 'buyer.business-charts',
        url: '/gr-variance',
        data: {
          authorities: ['ROLE_BUYER'],
        },
        views: {
          'content@': {
            templateUrl: 'app/buyer-modules/business-charts/gr-variance-report/buyer.gr-variance-report.html',
            controller: 'BuyerGRVarianceController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,desc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('buyer');
            $translatePartialLoader.addPart('priceUpdates');
            $translatePartialLoader.addPart('items');
            $translatePartialLoader.addPart('user-management');
            $translatePartialLoader.addPart('purchaseOrder');
            return $translate.refresh();
          }]
        }
      });

    }

})();
