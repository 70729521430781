/**
* @Author: Ajay
* @Date:   20-Mar-2017 14:43 +05:30
* @Project: Ezyprocure
* @Filename: supplier.promotion.add.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 29-Dec-2017 12:55 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierPromotionAddController', SupplierPromotionAddController);

  SupplierPromotionAddController.$inject = ['$scope', '$state', '$stateParams', '$filter', '$q', '$mdDialog', 'paginationConstants', 'PromotionService', 'SupplierItemService', 'SupplierItemMapService', 'BuyerBusinessService', 'BrandManagementService', 'BuyerSupplierMappingService', 'NotificationService', 'SupplierItemPriceUpdateService','SupplierDataService'];

  function SupplierPromotionAddController($scope, $state, $stateParams, $filter, $q, $mdDialog, paginationConstants, PromotionService, SupplierItemService, SupplierItemMapService, BuyerBusinessService, BrandManagementService, BuyerSupplierMappingService, NotificationService, SupplierItemPriceUpdateService,SupplierDataService) {

    var vm = this;
    vm.itemId = $stateParams.itemId;
    vm.promotionDTO = {};
    vm.itemSearchQuery = {};
    vm.getSupplierItem = getSupplierItem;
    vm.itemChanged = itemChanged;
    vm.searchForItems = searchForItems;
    vm.save = save;
    vm.setPromotionStartDateWithTime = setPromotionStartDateWithTime;
    vm.setPromotionEndDateWithTime = setPromotionEndDateWithTime;

    vm.todaysDate = new Date();
    vm.currentDate = new Date();
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.getAllBrandMappedsBusinessOutlets = getAllBrandMappedsBusinessOutlets;
    // vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
    vm.getAllBrandsOfBuyerList = getAllBrandsOfBuyerList;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.newMappingObject = {};
    vm.brandAndOutletClear = brandAndOutletClear;
    vm.getAllBuyersBusinessOutlets = getAllBuyersBusinessOutlets;
    vm.getItemBuyerMappingsByItemId = getItemBuyerMappingsByItemId;
    vm.getItemPricePerOutlet = getItemPricePerOutlet;
    vm.showPricePerBO = showPricePerBO;
    vm.getPricePerBusiness = getPricePerBusiness;
    vm.cancel = cancel;
    vm.outletList = [];

    function getItemPricePerOutlet(){
      SupplierItemPriceUpdateService.getItemPricePerOutlet({
        'itemId': vm.promotionDTO.promotionItem.id,
        'businessIdList': vm.promotionDTO.businessIdList
      }).then(function(response){
        vm.outletList = response.data;
      }, function(error){
        NotificationService.error({
          title: 'Item Promotion',
          error: error
        });
      })
    }

    /**
     * [showPrice details SHows the detailed Price of BO's]
     * @param {Object} event
     */
    function showPricePerBO(event) {
      $mdDialog.show({
        templateUrl: 'app/supplier-modules/item-management/promotions/supplier.promotion.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function getPricePerBusiness(eachOutlet){
      return eachOutlet.price ? eachOutlet.price : eachOutlet.items.price;
    }

    /**
     * [cancel Cancels the modal]
     */
    function cancel() {
      $mdDialog.cancel();
    }

    /**
    * [getSupplierItem getSupplierItem]
    * @return {[Object]} [SupplierItem]
    */
    function getSupplierItem() {
      SupplierItemService.getSupplierItem(vm.itemId).then(function(response) {
        vm.promotionDTO.promotionItem = response;
      }, function(error) {
      });
    }

    vm.getSupplierItem();


    /**
     * [getAllBrandsOfBuyerList Gets all brands of selected buyers]
     * @param {Array} buyerIdList
     */
    function getAllBrandsOfBuyerList(buyerIdList){
      if(!buyerIdList || buyerIdList.length<1)
        return false;
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        query:'',
        buyerId : buyerIdList
      };
      vm.promotionDTO.businessIdList = [];
      vm.promotionDTO.brandIdList = [];
      BrandManagementService.getAllBrandsOfBuyerList(params)
      .then(function(response){
        vm.brands = response;
      },function(error){
        NotificationService.error({
          title: 'Brand Listing',
          error: error
        });
      });
    }

    /**
     * [selectAll Selects all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets or brands]
     */
    function selectAll(dropdown) {
      switch (dropdown) {
        case 'BUYERS' :
          vm.promotionDTO.buyerIdList = _.map(vm.buyers, _.property('id'));
          vm.getAllBuyersBusinessOutlets();
          vm.getAllBrandMappedsBusinessOutlets(vm.promotionDTO.brandIdList)
          break;
        case 'BUSINESSOUTLETS' :
          vm.promotionDTO.businessIdList = _.map(vm.brandMappedBusinessOutlets, _.property('id'));
            break;
        case 'BRANDS':
          vm.promotionDTO.brandIdList = _.map(vm.brands, _.property('id'));
          break;
      }
    }

    /**
     * [deselectAll Deselect all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets or brands]
     */
    function deselectAll(dropdown) {
      switch (dropdown) {
        case 'BUYERS' :
          vm.promotionDTO.buyerIdList = [];
          break;
        case 'BUSINESSOUTLETS' :
          vm.promotionDTO.businessIdList = [];
          break;
        case 'BRANDS':
          vm.promotionDTO.brandIdList = [];
          break;
      }
    }

    // Get buyer by item id
    function getItemBuyerMappingsByItemId() {
      if(vm.buyers)
        return false;
      SupplierItemMapService.getItemBuyerMappingsByItemId({
        page: 0,
        size: paginationConstants.maxLimit,
        sort: ''
      }, vm.itemId).then(function(response) {
        vm.itemBuyerMappings = response.data;
        vm.buyers = _.map(vm.itemBuyerMappings, _.property('buyer'));
      }, function(error) {
        NotificationService.error({
          title: 'Buyers',
          error: error
        });
      });
    }

    function getAllBrandMappedsBusinessOutlets(brandIdList) {
      if (!brandIdList || brandIdList.length === 0) return false;
      var itemTerminationDTO = {
        'brandIdList': brandIdList,
        'buyerIdList' : vm.promotionDTO.buyerIdList
      }
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        supplierId: SupplierDataService.getSupplierProfile().supplier.id
      }
      BuyerBusinessService.getAllBrandMappedsBusinessOutlets(itemTerminationDTO, params).then(function(response) {
        vm.brandMappedBusinessOutlets = response.data;
      }, function(error) {
      });
    }

    /**
    * [searchForItems searchForItems]
    * @param  {[type]} queryType [queryType]
    * @param  {[type]} queryText [queryText]
    * @return {[List]}           [Search result items]
    */
    function searchForItems(queryType, queryText) {
      var deferred = $q.defer();
      if (!queryText) {
        return;
      } else {
        vm.itemSearchQuery = {
          itemType: 'all',
          queryField: queryType,
          queryText: queryText
        };
        SupplierItemService.searchAllItems(vm.itemSearchQuery)
        .then(function(response) {
          deferred.resolve($filter('orderBy')(response.data, 'itemName'));
        }, function(error) {
          deferred.reject(error);
        });
      }
      return deferred.promise;
    }

    /**
    * [clear brand and Outlet]
    */
    function brandAndOutletClear(brands){
      if(brands == 'BRANDS'){
        vm.newMappingObject.businessIdList = [];
        return false
      }
      vm.newMappingObject.businessIdList = [];
      vm.newMappingObject.brandIdList = [];
    }

    /**
    * [itemChanged calls when itemChanged]
    * @param  {[type]} selectedItem [selectedItem]
    * @return {[type]}              [description]
    */
    function itemChanged(selectedItem) {
      if (selectedItem)
        vm.promotionDTO.freeItem = angular.copy(selectedItem);
    }

    /**
     * [setPromotionStartDate Update promotion start date with time]
     */
    function setPromotionStartDateWithTime() {
      vm.promotionDTO.effectiveStartDate = PromotionService.getPromotionStartDateWithTime(vm.promotionDTO.effectiveStartDate);
    }

    /**
     * [setPromotionEndDate Update promotion end date with time]
     */
    function setPromotionEndDateWithTime() {
      vm.promotionDTO.effectiveEndDate = PromotionService.getPromotionEndDateWithTime(vm.promotionDTO.effectiveEndDate);
    }

    /**
    * [save createSupplierItemPromotion]
    * @return {[Object]} [success/error response message]
    */
    function save() {
      PromotionService.createSupplierItemPromotion(vm.promotionDTO).then(function(response) {
        $scope.$emit('ezyprocureApp:promotionUpdate', response);
        NotificationService.success({
          title: 'Item Promotion',
          message: 'alertMessage.CREATED_SUCCESSFULLY'
        });
        $state.go('supplier.items.promotions', {
          reload: false
        });
      }, function(error) {
        NotificationService.error({
          title: 'Item Promotion Creation',
          error: error
        });
      });
    }

      //get all business outlets of the selected buyer
      function getAllBuyersBusinessOutlets() {
        vm.promotionDTO.businessIdList = [];
        vm.promotionDTO.brandIdList = [];
        if ( vm.promotionDTO.buyerIdList.length<1) {
          return false;
        }
        var params = {
          page: 0,
          size: paginationConstants.maxLimit,
          groupBy: 'buyer'
        };

        BuyerBusinessService.getAllBuyersBusinessOutlets(vm.promotionDTO.buyerIdList, params).then(function(response) {
          vm.businessOutlets = response.data;
          vm.promotionDTO.businessIdList = [];
        }, function(error) {
        });
      }

  }
})();
