/**
* @Author: sneha
* @Date:   14-Mar-2018 12:27 +05:30
* @Project: Ezyprocure
* @Filename: supplier.picking-list.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 11-May-2018 12:48 +05:30
*/


(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierPickingListController', SupplierPickingListController);

  SupplierPickingListController.$inject = ['$scope', '$state',  '$mdDialog', 'toastr', 'SupplierPickingListService', 'NotificationService'];

  function SupplierPickingListController($scope, $state , $mdDialog, toastr, SupplierPickingListService, NotificationService) {
    var vm = this;
    vm.getAllPickingList = getAllPickingList;
    vm.deletePickingListById = deletePickingListById;
    vm.checkListLimit = checkListLimit;

    /**
    * [checkListLimit check selected list array length]
    * @param  {[type]} event [description]
    * @return {[type]}       [description]
    */
    function checkListLimit(event) {
      if(vm.templateList.length < 5){
        $state.go('supplier.picking-list-management.picking-list.select-items', {reload:false})
      }
      else {
        toastr.error('Only 5 templates allowed', 'Template Creation Failed');
      }
    }

    /**
    * [showConfirm modal for delete picking list confirmation]
    * @param  {[type]} ev         [description]
    * @param  {[type]} templateId [description]
    * @return {[type]}            [description]
    */
    vm.showConfirm = function(ev, templateId) {
      var confirm = $mdDialog.confirm()
      .title('Are you sure you want to delete this picking list template?')
      .textContent('This action cannot be reverted back.')
      .targetEvent(ev)
      .ok('Delete')
      .cancel('Cancel');
      $mdDialog.show(confirm).then(function() {
        vm.deletePickingListById(templateId);
      }, function() {});
    }

    /**
    * [getAllPickingList get all picking list templates]
    * @return {[type]} [description]
    */
    function getAllPickingList(){
      SupplierPickingListService.getAllPickingList({
        detailedView : true
      }).then(function(response) {
        vm.templateList = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'Picking List',
          error: error
        })
      });
    }

    /**
    * [deletePickingListById delete picking list by template id]
    * @param  {[type]} templateId [description]
    * @return {[type]}            [description]
    */
    function deletePickingListById(templateId){
      SupplierPickingListService.deletePickingListById(templateId).then(function(response) {
        NotificationService.success({
          title: 'Picking List',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.getAllPickingList();
      }, function(error) {
        NotificationService.error({
          title: 'Picking List',
          error: error
        });
      });
    }

  }
})();
