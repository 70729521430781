/*
 * @Author: Jayalekshmi
 * @Date: 2019-11-22 11:15:56
 * @Project: Ezyprocure
 * @Filename: buyer.stock-transfer.details.controller.js
 * @Last Modified by: Lovin Chacko
 * @Last Modified time: 2020-12-07 16:25:23
 */


(function() {
    'use strict';

    angular
    .module('ezyprocureApp')
    .controller('BuyerStockTransferDetailsController', BuyerStockTransferDetailsController);

    BuyerStockTransferDetailsController.$inject = ['$stateParams', '$state', 'pagingParams', 'BuyerStockTransferService', 'paginationConstants', 'NotificationService', 'GLOBAL_CONSTANTS','BuyerDataService'];

    function BuyerStockTransferDetailsController($stateParams, $state, pagingParams, BuyerStockTransferService, paginationConstants, NotificationService, GLOBAL_CONSTANTS,BuyerDataService) {
      var vm = this;

      vm.stockId = $stateParams.id;
      vm.getStockTransferDetails = getStockTransferDetails;
      vm.query = {
        limit: paginationConstants.itemsPerPage,
        page: 1
      };
      vm.limitOptions = paginationConstants.limitOptions;
      vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
      vm.updateStockTransferDetails = updateStockTransferDetails;
      vm.printStockTransfer = printStockTransfer;
      vm.downloadURI = downloadURI;

      vm.getStockTransferDetails();
      vm.calculateTransferredCost = calculateTransferredCost;
      vm.accessForStockTransferCostingView = BuyerDataService.getBuyerProfile().accessDTO.accessForStockTransferCostingView;

      /**
       * [updateStockTransferDetails Updates the data]
       */
      function updateStockTransferDetails(){
        vm.editable = false;
        BuyerStockTransferService.updateStockTransferDetails(vm.stockTransferDetails)
        .then(function(response){
          NotificationService.success({
            title: 'Stock Transfer Details',
            message: 'Updated Successfully'
          });
          vm.getStockTransferDetails();
        },function(error){
          NotificationService.error({
            title: 'Stock Transfer Details',
            error: error
          });
        });
      }

      /**
       * [getStockTransferDetails Get stock transfer details]
       */
      function getStockTransferDetails(){
        BuyerStockTransferService.getStockTransferDetailsById(vm.stockId)
        .then(function(response){
          vm.stockTransferDetails = response.data.transferRequest;
          _.forEach(vm.stockTransferDetails.items, function(item, index){
            item.items['buyerItemCode'] = response.data.itemList[index].itemCode
          });
        }, function(error){
          NotificationService.error({
            title: 'Stock Transfer Details',
            error: error
          });
        })
      }

      /**
       * [downloadURI Downloads the link in pdf]
       * @param {*} uri
       * @param {*} name
       */
      function downloadURI(uri)
      {
        var link = document.createElement("a");
        document.body.appendChild(link);
        // link.download = name;
        link.href = uri;
        link.click();
      }

      /**
       * [printStockTransfer Print Stock transfer]
       */
      function printStockTransfer(id){
        BuyerStockTransferService.printStockTransfer(id)
        .then(function (response){
          vm.pdfUrl = response.data.data;
          vm.downloadURI(vm.pdfUrl);
        }, function (error){
          NotificationService.error({
            title: 'Stock Transfer Details',
            error: error
          });
        });
      }

      function calculateTransferredCost(stockItem){
        var temp = angular.copy(stockItem);
        stockItem.receivedCost = temp.receivedQuantity * temp.items.price;
        }

    }
  })();
