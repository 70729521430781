/**
* @Author: Ajay
* @Date:   27-Mar-2017 13:16 +05:30
* @Project: Ezyprocure
* @Filename: manage-banks.edit.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 06-Nov-2017 14:54 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('ManageBankEditController', ManageBankEditController);

  ManageBankEditController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'Auth', 'ManageBankService', 'ImageUploadService', 'GLOBAL_CONSTANTS', 'NotificationService', 'EzyCompanyService'];

  function ManageBankEditController($scope, $rootScope, $state, $stateParams, Auth, ManageBankService, ImageUploadService, GLOBAL_CONSTANTS, NotificationService, EzyCompanyService) {
    var vm = this;

    vm.bankId = $stateParams.bankId;
    vm.save = save;
    vm.updateBankActivatedKey = updateBankActivatedKey;
    vm.getBankData = getBankData;
    vm.setCompanyLogo = setCompanyLogo;
    vm.uploadLogo = uploadLogo;
    vm.userRole = Auth.getUserRole();
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.isEzyapActivate = false;
    vm.init = init;

    vm.init();

    function save() {
     // Call Admin supplier service with supplier object as parameter to update
      ManageBankService.updateAdminBank(vm.bank).then(function(response) {
        //$scope.$emit('ezyprocureApp:supplierUpdate', response);
        NotificationService.success({
          title: 'Admin Bank',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        $state.go(vm.userRole.pretty + '.banks', {
          reload: false
        });
      }, function(error) {
        NotificationService.error({
          title: 'Admin Bank Updation',
          error: error
        });
      });
    }

    function updateBankActivatedKey(bankId, isActivate) {
      ManageBankService.updateBankActivatedKey(bankId, isActivate).then(function(response) {
        vm.bank.user.activated = response.data.user.activated;
        //$scope.$emit('ezyprocureApp:supplierUpdate', response);
        NotificationService.success({
          title: 'Admin Bank',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
      }, function(error) {
        NotificationService.error({
          title: 'Admin Bank Updation',
          error: error
        });
      });
    }

    function getBankData() {
      ManageBankService.getAdminBank(vm.bankId).
      then(function(response) {
        if (response) {
          vm.bank = response;
          vm.companyCodeExists = vm.bank.companyCode ? true : false;
          vm.bankCodeExists = vm.bank.bankCode ? true : false;
        }
      }, function(error) {
      });
    }

    function init(){
      vm.getBankData();
    }

    /**IMAGE UPLOAD**/

    function setCompanyLogo(flowFile) {
      ImageUploadService.getImageBase64(flowFile.file, function(base64Img) {
        vm.uploadLogo(base64Img);
      });
    }

    function uploadLogo(base64Img) {
      vm.uploadingInProgress = true;
      ImageUploadService.uploadImage(base64Img).
      then(function(responseData) {
        vm.bank.companyLogo = responseData.url;
        $scope.editBankForm.$pristine = false;
        NotificationService.success({
          title: 'Bank Logo uploaded successfully',
          message: 'Please update to save changes!'
        });
        vm.uploadingInProgress = false;
      }, function(error) {
        vm.uploadingInProgress = false;
        NotificationService.error({
          title: 'Bank Logo Updation',
          error: error
        });
      });
    }
  }
})();
