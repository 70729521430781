/**
 * @Author: Elsa
 * @Date:   10-Mar-2017 12:55 +05:30
 * @Project: Ezyprocure
 * @Filename: supplier.goods-return-notice.details.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-06-25T10:11:09+05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierGoodReturnNoticeDetailsController', SupplierGoodReturnNoticeDetailsController);

  SupplierGoodReturnNoticeDetailsController.$inject = ['$scope', '$rootScope', '$filter', '$state', '$stateParams', 'toastr', '$mdDialog', 'BuyerGoodsReturnNoticeService', 'NotificationService', 'EXPORT_CONSTANTS', 'CSVExportService', 'PDFExportService', 'GLOBAL_CONSTANTS', 'Auth'];

  function SupplierGoodReturnNoticeDetailsController($scope, $rootScope, $filter, $state, $stateParams, toastr, $mdDialog, BuyerGoodsReturnNoticeService, NotificationService, EXPORT_CONSTANTS, CSVExportService, PDFExportService, GLOBAL_CONSTANTS, Auth) {
    var vm = this;
    vm.init = init;
    vm.goodsReturnNumber = $stateParams.goodsReturnNumber;
    vm.totalDNAmount = totalDNAmount;
    vm.goodsReturnAmount = goodsReturnAmount;
    vm.getGoodsReturnCSVData = getGoodsReturnCSVData;
    vm.getGoodsReturnNoticePDF = getGoodsReturnNoticePDF;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.accessForPriceDisplay = Auth.hasPermission('accessForPriceDisplay');


    function init(){
      if(vm.accessForPriceDisplay)
      vm.goodsReturnNoticeHeader = EXPORT_CONSTANTS.CSV.goodsReturnNoticeHeader;
      else {
        vm.goodsReturnNoticeHeader = _.without(EXPORT_CONSTANTS.CSV.goodsReturnNoticeHeader, 'unitPrice' , 'goodsReturnAmount');
      }
      loadAll();
    }

    /**
    * [loadAll loads all pending POs]
    */
    function loadAll() {
      BuyerGoodsReturnNoticeService.getDebitNotesByGRNNumber(vm.goodsReturnNumber).then(function(response) {
        vm.debitNoteDetails = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'Good Return Notice',
          error: error
        });
      });
    }


    function goodsReturnAmount(p_index) {
      var goodsReturnAmount = 0;
      if(vm.debitNoteDetails[p_index].processOrder.items.uom.moqPrice === true)
      {
            goodsReturnAmount += (vm.debitNoteDetails[p_index].goodsReturnNotice.rejectedQuantity / vm.debitNoteDetails[p_index].processOrder.items.moq) * vm.debitNoteDetails[p_index].processOrder.price;
      }
      else
      {
            goodsReturnAmount += vm.debitNoteDetails[p_index].goodsReturnNotice.rejectedQuantity * vm.debitNoteDetails[p_index].processOrder.price;
      }
      return goodsReturnAmount;
    }

    function totalDNAmount() {
      var totalDNAmount = 0;
      _.forEach(vm.debitNoteDetails, function(details){
        if(details.processOrder.items.uom.moqPrice === true)
        {
            totalDNAmount += _.round((details.goodsReturnNotice.rejectedQuantity / details.processOrder.items.moq) * details.processOrder.price,2);
        }
        else
        {
            totalDNAmount += _.round(details.goodsReturnNotice.rejectedQuantity * details.processOrder.price,2);

        }
      });
      return _.round(totalDNAmount,2);
    }

    function getGoodsReturnCSVData() {
      return CSVExportService.getGoodsReturnNoticeCSV(vm.debitNoteDetails);
    }

    function downloadURI(uri, name)
    {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = name;
      link.href = uri;
      link.click();
    }

    function getGoodsReturnNoticePDF() {
      PDFExportService.getGoodsReturnNoticePDF(vm.goodsReturnNumber).then(function(response){
        downloadURI(response.data.message, "PDF_" + vm.goodsReturnNumber);
      },function(error) {
        NotificationService.error({
          title: 'Good Return Notice PDF Export',
          error: error
        });
      });
    }
  }
})();
