/**
 * @Author: Abhay
 * @Date:   03-Aug-2020 15:54 IST
 * @Project: Ezyprocure
 * @Filename: buyer.adhoc-items-promotions.state.js
 * @Last modified by:   Abhay
 * @Last modified time: 03-Aug-2020 15:54 IST
 */

(function () {
   'use strict';

   angular.module('ezyprocureApp')
      .config(stateConfig);

   stateConfig.$inject = ['$stateProvider'];

   function stateConfig($stateProvider) {
      $stateProvider
         .state('buyer.adhoc-tools.items.promotions', {
            parent: 'buyer.adhoc-tools',
            url: '/adhoc-items/promotions?page&sort&search',
            data: {
               authorities: ['ROLE_BUYER'],
               pageTitle: 'Promotions'
            },
            views: {
               'content@': {
                  templateUrl: 'app/buyer-modules/adhoc-tools/adhoc-item-promotions/buyer.adhoc-items-promotions.html',
                  controller: 'BuyerAdhocPromotionsController',
                  controllerAs: 'vm'
               }
            },
            params: {
               page: {
                  value: '1',
                  squash: true
               },
               sort: {
                  value: 'id,asc',
                  squash: true
               },
               search: null
            },
            resolve: {
               pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                  return {
                     page: PaginationUtil.parsePage($stateParams.page),
                     sort: $stateParams.sort,
                     predicate: PaginationUtil.parsePredicate($stateParams.sort),
                     ascending: PaginationUtil.parseAscending($stateParams.sort),
                     search: $stateParams.search
                  };
               }],
               translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                  $translatePartialLoader.addPart('promotion');
                  $translatePartialLoader.addPart('global');
                  $translatePartialLoader.addPart('brand');
                  return $translate.refresh();
               }]
            }
         })
         .state('buyer.adhoc-tools.items.promotions.add', {
            parent: 'buyer.adhoc-tools.items.promotions',
            url: '/add/:itemId',
            data: {
               authorities: ['ROLE_BUYER'],
               pageTitle: 'Add Promotion'
            },
            views: {
               'content@': {
                  templateUrl: 'app/buyer-modules/adhoc-tools/adhoc-item-promotions/add/buyer.adhoc-promotion.add.html',
                  controller: 'AdhocPromotionAddController',
                  controllerAs: 'vm'
               }
            },
            resolve: {
               translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                  $translatePartialLoader.addPart('promotion');
                  $translatePartialLoader.addPart('items');
                  $translatePartialLoader.addPart('global');
                  return $translate.refresh();
               }]
            }
         })
         .state('buyer.adhoc-tools.items.promotions.edit', {
            parent: 'buyer.adhoc-tools.items.promotions',
            url: '/edit/:promotionId',
            data: {
               authorities: ['ROLE_BUYER'],
               pageTitle: 'Edit Promotion'
            },
            views: {
               'content@': {
                  templateUrl: 'app/buyer-modules/adhoc-tools/adhoc-item-promotions/edit/buyer.adhoc-promotion.edit.html',
                  controller: 'AdhocPromotionEditController',
                  controllerAs: 'vm'
               }
            },
            resolve: {
               translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                  $translatePartialLoader.addPart('promotion');
                  $translatePartialLoader.addPart('items');
                  $translatePartialLoader.addPart('global');
                  return $translate.refresh();
               }]
            }
         });
   }

})();