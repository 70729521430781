/**
* @Author: Mufsir
* @Date:   15-Dec-2016 18:34 +05:30
* @Project: Ezyprocure
* @Filename: jhi-item-count.directive.js
* @Last modified by:   Ajay
* @Last modified time: 27-Mar-2017 18:02 +05:30
*/



(function() {
  'use strict';

  var jhiItemCount = {
    template: '<div class="info">' +
    'Showing {{(($ctrl.page - 1) * $ctrl.itemsPerPage) == 0 ? 1 : (($ctrl.page - 1) * $ctrl.itemsPerPage + 1)}} - ' +
    '{{($ctrl.page * $ctrl.itemsPerPage) < $ctrl.queryCount ? ($ctrl.page * $ctrl.itemsPerPage) : $ctrl.queryCount}} ' +
    'of {{$ctrl.queryCount}} items.' +
    '</div>',
    bindings: {
      page: '<',
      queryCount: '<total',
      itemsPerPage: '<'
    }
  };

  angular
  .module('ezyprocureApp')
  .component('jhiItemCount', jhiItemCount);
})();
