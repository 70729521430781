/**
* @Author: Mufsir
* @Date:   25-Jan-2017 18:30 +05:30
* @Project: Ezyprocure
* @Filename: buyer-supplier-mapping.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-09-27T15:17:23+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerSupplierMappingController', BuyerSupplierMappingController);

  BuyerSupplierMappingController.$inject = ['$scope' ,'$state', '$q', 'toastr', 'pagingParams', '$mdDialog', 'ManageBuyerService', 'ManageSupplierService', 'BuyerSupplierMappingService', 'CsvUploadService', 'Auth', 'BuyerDataService', 'SupplierDataService', 'paginationConstants', 'NotificationService', 'S3_URL_CONSTANTS', '$filter', 'BusinessSupplierMapService'];

  function BuyerSupplierMappingController($scope, $state, $q, toastr, pagingParams, $mdDialog, ManageBuyerService, ManageSupplierService, BuyerSupplierMappingService, CsvUploadService, Auth, BuyerDataService, SupplierDataService, paginationConstants, NotificationService, S3_URL_CONSTANTS, $filter, BusinessSupplierMapService) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.sort = sort;
    vm.loadAll = loadAll;
    vm.mappedItemSearch = '';
    vm.loadAllAdminBuyers = loadAllAdminBuyers;
    vm.loadAllAdminSuppliers = loadAllAdminSuppliers;
    vm.isMapped = isMapped;
    vm.createBSMapping = createBSMapping;
    vm.deleteBSMapping = deleteBSMapping;
    vm.clearCSVMappingFields = clearCSVMappingFields;
    vm.getCSVBase64 = getCSVBase64;
    vm.mapBuyerToSuppliersCSV = mapBuyerToSuppliersCSV;
    vm.mapSupplierToBuyersCSV = mapSupplierToBuyersCSV;
    vm.mapping = {};
    vm.userType = 'buyer';
    vm.userRole = Auth.getUserRole();
    vm.loadInitialData = loadInitialData;
    vm.getBuyersMappedWithSelectedSupplier = getBuyersMappedWithSelectedSupplier;
    vm.getSuppliersMappedWithSelectedBuyer = getSuppliersMappedWithSelectedBuyer;
    vm.getAllSupplierBusinessOutlets = getAllSupplierBusinessOutlets;
    vm.selectionChanged = true;
    vm.getAllBusinessAreas = getAllBusinessAreas;
    vm.createBusinessArea = createBusinessArea;
    vm.getAreas = getAreas;

    vm.BUYER_MAP_SUPPLIERS_LIST_CSV = S3_URL_CONSTANTS.BUYER_MAP_SUPPLIERS_LIST_CSV;
    vm.SUPPLIER_MAP_BUYERS_LIST_CSV = S3_URL_CONSTANTS.SUPPLIER_MAP_BUYERS_LIST_CSV;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1,
      mappingPage:1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    // Load All supplier items which are mapped
    function loadAll(search) {
      if (!search) {
        vm.mappedItemSearch = '';
      }
      BuyerSupplierMappingService.getAllMappedSupplierBuyers({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.mappedItemSearch,
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.mappedAdmins = response.data;
      },function(error){
      });
    }

    function loadAllAdminBuyers() {
      ManageBuyerService.getAllAdminBuyers().then(function(response){
        vm.buyers = response.data;
      },function(error){
      });
    }

    function loadAllAdminSuppliers() {
      var params = {visibilityType:'ALL'};
      ManageSupplierService.getAllAdminSuppliers(params).then(function(response){
        vm.suppliers = [];
      _.forEach(response.data, function(supplier){
        if(supplier.visibilityType != 'ADHOC_OFF_BOARD_SUPPLIER'){
          vm.suppliers.push(supplier);
        }
       });
      },function(error){
      });
    }

    vm.loadInitialData();

    function isMapped(mapping) {
      vm.isAlreadyMapped = false;
      if(!mapping.buyerId || !mapping.supplierId)
        return false;
      BuyerSupplierMappingService.isMapped(mapping).then(function(response){
        if(response){
          vm.isAlreadyMapped = response.isActive;
        }
      },function(error){
      });
    }

    function createBSMapping() {
      // Map selected buyer and supplier
      BuyerSupplierMappingService.createBSMapping(vm.mapping).then(function(response){
        $scope.$emit('ezyprocureApp:bsMapped', vm.mapping);
        NotificationService.success({
          title: 'Buyer-Supplier',
          message: 'Mapped successfully'
        });
        vm.isMapped(vm.mapping);
      },function(error) {
        NotificationService.error({
          title: 'Buyer-Supplier Mapping',
          error: error
        });
      });
    }

    function deleteBSMapping() {
      // Map selected buyer and supplier
      BuyerSupplierMappingService.deleteBSMapping(vm.mapping).then(function(response){
        $scope.$emit('ezyprocureApp:bsUnmapped', vm.mapping);
        NotificationService.success({
          title: 'Buyer-Supplier',
          message: 'Mapping deleted successfully'
        });
        vm.isMapped(vm.mapping);
      },function(error) {
        NotificationService.error({
          title: 'Buyer-Supplier Delete Mapping',
          error: error
        });
      });
    }

    function clearCSVMappingFields() {
      vm.searchText = '';
      vm.selectedBuyer = null;
      vm.selectedSupplier = null;
      vm.files = [];
      vm.csvUploadData={};
    }

    function getCSVBase64(userType) {
      var csvFile = vm.files[0];
      if(!csvFile){
        toastr.error('Please upload a .csv file!', 'CSV File Required');
        return false;
      }
      CsvUploadService.getFileBase64(csvFile.lfFile, function(base64CSV) {
        vm.csvUploadData = {
          id: vm.userType === 'buyer'? vm.selectedBuyer.id : vm.selectedSupplier.id,
          encodedCsvFile: base64CSV
        };
        if (userType === 'buyer') vm.mapBuyerToSuppliersCSV(vm.csvUploadData);
        else vm.mapSupplierToBuyersCSV(vm.csvUploadData);
      });
    }

    function mapBuyerToSuppliersCSV(uploadData) {
      BuyerSupplierMappingService.mapBuyerToSuppliersCSV(uploadData).then(function(response){
        vm.files = [];
        NotificationService.success({
          title: 'Bulk Map Buyer to Suppliers',
          message: 'Done successfully'
        });
      },function(error){
        vm.recordError = error.data;
        NotificationService.error({
          title: 'Bulk Map Buyer to Suppliers',
          error: error
        });
      });
    }

    function mapSupplierToBuyersCSV(uploadData) {
      BuyerSupplierMappingService.mapSupplierToBuyersCSV(uploadData).then(function(response){
        vm.files = [];
        NotificationService.success({
          title: 'Bulk Map Supplier to Buyers',
          message: 'Done successfully'
        });
      },function(error){
        vm.recordError = error.data;
        NotificationService.error({
          title: 'Bulk Map Supplier to Buyers',
          error: error
        });
      });
    }

    //Load All Buyers mapped with the selected Supplier
    function getBuyersMappedWithSelectedSupplier(supplierId) {
      BuyerSupplierMappingService.getAllSupplierMappedBuyerList(supplierId)
      .then(function(response){
        vm.buyersMappedWithSelectedSupplier = response.data;
      },function(error) {
        vm.buyersMappedWithSelectedSupplier = null;
      });
    }

    //Load All Suppliers mapped with the selected Buyer
    function getSuppliersMappedWithSelectedBuyer(buyerId) {
      BuyerSupplierMappingService.getAllBuyerMappedSupplierList(buyerId)
      .then(function(response){
        vm.suppliersMappedWithSelectedBuyer = response.data;
      },function(error) {
        vm.suppliersMappedWithSelectedBuyer = null;
      });
    }

    function loadInitialData() {
      if(vm.userRole.pretty === 'admin') {
        vm.loadAllAdminBuyers();
        vm.loadAllAdminSuppliers();
        vm.loadAll();
      }
      else if(vm.userRole.pretty === 'supplier') {
        vm.supplierProfile = SupplierDataService.getSupplierProfile();
        vm.mapping.supplierId = vm.supplierProfile.supplier.id;
        vm.loadAllAdminBuyers();
        vm.loadAll();
      }
      else if(vm.userRole.pretty === 'buyer') {
        vm.buyerProfile = BuyerDataService.getBuyerProfile();
        vm.mapping.buyerId = vm.buyerProfile.buyerUser.buyer.id;
        vm.loadAllAdminSuppliers();
        vm.loadAll();
      }
    }

    /**
    * [showConfirmUnmappingModal modal for delete mapping confirmation]
    * @param  {[type]} ev         [event]
    */
    vm.showConfirmUnmappingModal = function(ev) {
      var confirm = $mdDialog.confirm()
      .title($filter('translate')('alertMessage.DO_YOU_WANT_TO_PROCEED'))
      .textContent($filter('translate')('alertMessage.COULD_NOT_BE_REVERTED_BACK'))
      .targetEvent(ev)
      .ok($filter('translate')('global.buttons.yes'))
      .cancel($filter('translate')('global.buttons.no'));
      $mdDialog.show(confirm).then(function() {
        vm.deleteBSMapping();
      }, function() {});
    }

    // get all business of logged in buyer
    function getAllSupplierBusinessOutlets(search) {
      if(vm.supplierBusinessMappings){
        _.map(vm.supplierBusinessMappings, function(each){
          each.searchText = null;
        }) 
      }
      BusinessSupplierMapService.getAllMappedSupplierBusinessesBySupplier({
        page: vm.query.mappingPage-1,
        size: vm.query.limit,
        sort: sort(),
        query : search ? vm.searchQuery : ''
      }).then(function(response) {
        vm.supplierBusinessMappings = response.data;
        vm.totalSupplierBusinessItems = response.headers('X-Total-Count');
        _.map(vm.supplierBusinessMappings, function(each){
          each.searchText = null;
        }) 
      }, function(error) {
      });
    }

    // Creating the business area
    function createBusinessArea(mapping){
      mapping.buyerBusinessArea = mapping.buyerBusinessArea ? mapping.buyerBusinessArea : mapping.searchText;
      _.find(vm.businessAreas, function(area){
        if(_.toLower(area) == _.toLower(mapping.buyerBusinessArea)){
          mapping.buyerBusinessArea = area;
        };
      });
      mapping.buyerBusinessArea== '' ? mapping.buyerBusinessArea = null :'';
      BusinessSupplierMapService.updateBusinessSupplierMapping(mapping).then(function(res){
        NotificationService.success({
          title: 'Business Supplier Mapping',
          message: 'Done successfully'
        });
        vm.getAllBusinessAreas();
        _.map(vm.supplierBusinessMappings, function(each){
          each.searchText = null;
        }) 
      }, function(){
      })
    }
    getAllBusinessAreas();

    function getAllBusinessAreas(){
      BusinessSupplierMapService.getAllBusinessAreas({
        page: 0,
        size: paginationConstants.maxLimit,
        sort: ''
      }).then(function(response){
        vm.mappedBusinessAreas = response.data;
        vm.getAllSupplierBusinessOutlets();
        _.map(vm.supplierBusinessMappings, function(each){
          each.searchText = null;
        }) 
      }
      ,function(){
      });
    }

    function getAreas(query){
      var supplierId = SupplierDataService.getSupplierProfile().supplier.id;
      var deferred = $q.defer();
      var filteredArray = _.filter(vm.mappedBusinessAreas, function(mapping){
        return supplierId == mapping.supplier.id;
      });
      vm.businessAreas = _.without(_.uniq(_.map(filteredArray,_.property('buyerBusinessArea'))),null);
      vm.businessAreas = query ? $filter('filter')(vm.businessAreas, query) : vm.businessAreas
      deferred.resolve(vm.businessAreas)
      return deferred.promise;
    }
  }
})();
