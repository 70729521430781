/**
 * @Author: Shibi
 * @Date: 01-Oct-2021 10:02 +05:30
 * @Filename: notification-settings.service.js
 * @Last modified by:   Shibi
 * @Last modified time: 01-Oct-2021 10:02 +05:30
 */

(function() {
 'use strict';
 angular.module('ezyprocureApp').factory('NotificationSettingsService', NotificationSettingsService);

 NotificationSettingsService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

 function NotificationSettingsService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var NotificationSettingsService = {
      getNotificationSettings: getNotificationSettings,
      updateNotificationSettings: updateNotificationSettings,
    };

    return NotificationSettingsService;

    function getNotificationSettings(buyerUserId) {
        var deferred = $q.defer();
        $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.NOTIFICATION_SETTINGS + buyerUserId).then(function(data) {
          deferred.resolve(data.data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
    }

    function updateNotificationSettings(notificationSettings) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.NOTIFICATION_SETTINGS,
          method: "PUT",
          data: notificationSettings,
        }).then(function(data) {
          deferred.resolve(data.data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

 }

})();
