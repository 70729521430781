/**
 * @Author: Ajay
 * @Date:   09-Jun-2017 16:11 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.receiving-orders.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 18-May-2018 11:59 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerReceivingOrderController', BuyerReceivingOrderController);

  BuyerReceivingOrderController.$inject = ['$scope', '$rootScope', '$filter', '$state', '$stateParams', 'toastr', '$mdDialog', 'pagingParams', 'BuyerReceivingOrderService', 'paginationConstants', 'NotificationService', 'GLOBAL_CONSTANTS'];

  function BuyerReceivingOrderController($scope, $rootScope, $filter, $state, $stateParams, toastr, $mdDialog, pagingParams, BuyerReceivingOrderService, paginationConstants, NotificationService, GLOBAL_CONSTANTS) {
    var vm = this;
    vm.init = init;
    vm.searchText = '';
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.loadAll = loadAll;
    vm.selectAll = selectAll;
    vm.mergeGoodsRecivesWithSamePO = mergeGoodsRecivesWithSamePO;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.supplierReciveNotifyPopup = supplierReciveNotifyPopup;
    vm.supplierReceiveNotify = supplierReceiveNotify;
    vm.cancel = cancel;
    vm.resendEmailAdhoc = resendEmailAdhoc;

    /**
     * [init loads the function initially]
     */
    function init(){
      loadAll();
    }

    /**
     * [sort to sort by PO date]
     */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
     * [loadAll Load All Receiving Order data]
     * @param  {[string]} search [search text]
     */
    function loadAll (search) {
      if (!search) {
        vm.searchText = '';
      }
      BuyerReceivingOrderService.getGoodReceiptNotes({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.searchText,
        fromDate: '',
        toDate: '',
        type: 'GOODS_RECEIVE'
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.goodReceiptNotes = response.data;
      },function(error){
      });
    }

    /**
    * [selectAllPR select all Purchase Requests]
    * @return {[type]} [description]
    */
   function selectAll() {
    vm.selectAll ? vm.selectAll = true : vm.selectAll = false;
    angular.forEach(vm.purchaseApprovalRequests, function(eachRequest) {
      eachRequest.selected = vm.selectAll;
    });
  }

  /**
   * mergeGoodsRecivesWithSamePO merges goods receving data wuth same po number
   */
  function mergeGoodsRecivesWithSamePO(){
    var grnList = [];
    var grnPONumberList = [];
    var grnDeliveryDateList = [];
    grnList = _.filter(vm.goodReceiptNotes,function(eachRequest){
       if(eachRequest.selected==true){
         grnPONumberList.push(eachRequest.purchaseOrderNumber);
         grnDeliveryDateList.push(eachRequest.processOrder.deliveryDate);
         return eachRequest;
       }
     });
     grnList = _.map(grnList, function(eachRequest){
      return {
        'deliveryDate':eachRequest.processOrder.deliveryDate,
        'purchaseOrderNumber': eachRequest.purchaseOrderNumber
      };
     })
    if(grnList.length > 1 ){
       var poNumberList =[];
       poNumberList = _.uniq(grnPONumberList, function(items){
        return items;
       });
       if(poNumberList.length == 1){
        BuyerReceivingOrderService.mergeGrnWithSamePo(grnList).
        then(function(response){
          NotificationService.success({
            title: 'alertMessage.RECEIVING_ORDER',
            message: 'alertMessage.MERGED_SUCCESSFULLY'
        });
        vm.loadAll();
        }, function(error){
          NotificationService.error({
            title: 'alertMessage.RECEIVING_ORDER',
            error: error
          });
        });

       }
       else {
         NotificationService.simpleErrorToast({
           title: 'alertMessage.RECEIVING_ORDER',
           message: 'alertMessage.PO_NUMBER_SHOULD_BE_SAME_FOR_ALL'
         });
       }
    }
    else{
       NotificationService.simpleErrorToast({
         title: 'alertMessage.RECEIVING_ORDER',
         message: 'alertMessage.ATLEAST_TWO_REQUIRED'
       });
    }
  }

  function supplierReciveNotifyPopup(goodReceiptNote){
    vm.poNum = goodReceiptNote.purchaseOrderNumber;
    vm.supplierNotifyStaus = goodReceiptNote.processOrder.supplierReceivedNotify;
    vm.processOrderId = goodReceiptNote.processOrder.id;
    $mdDialog.show({
      templateUrl: 'app/buyer-modules/receiving-orders/buyer.receiving-orders.supplier-notify.popup.html',
      controller: function () {
        return vm;
      },
      controllerAs: 'vm',
      targetEvent: event,
      clickOutsideToClose: false
    });
  }

  function supplierReceiveNotify(){
    var params = {
      supplierReceivedNotify: vm.supplierNotifyStaus
    }
    BuyerReceivingOrderService.supplierReceiveNotify(params,  vm.processOrderId).then(function(response){
      NotificationService.success({
        title: 'alertMessage.RECEIVING_ORDER',
        message: 'alertMessage.UPDATED_SUCCESSFULLY'
    });
      vm.cancel();
      vm.loadAll();
    },
    function(error){
      NotificationService.error({
        title: 'alertMessage.RECEIVING_ORDER',
        error: error
      });
      vm.cancel();
      vm.loadAll();
    });
  }

  function cancel(){
    $mdDialog.cancel()
  }

    function resendEmailAdhoc(purchaseOrderNumber){
        BuyerReceivingOrderService.resendEmailAdhoc(purchaseOrderNumber).then(function(response){
            NotificationService.success({
            title: 'alertMessage.RECEIVING_ORDER',
            message: 'Resend Email'
        });
        },
        function(error){
            toastr.error(error.data.responseList[0].reason, $filter('translate')('alertMessage.RECEIVING_ORDER'));
        });
    }

  }
})();
