/**
* @Author: Ajay
* @Date:   12-May-2017 14:27 +05:30
* @Project: Ezyprocure
* @Filename: supplier.price-update.item-selection.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 10-Nov-2017 11:26 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierPriceUpdateItemSelectionController', SupplierPriceUpdateItemSelectionController);

  SupplierPriceUpdateItemSelectionController.$inject = ['$scope', '$state', '$element', '$q', '$filter', 'NotificationService', 'SupplierItemService', 'SupplierItemPriceUpdateService', 'UTCDateService', 'GLOBAL_CONSTANTS', '$mdDialog'];

  function SupplierPriceUpdateItemSelectionController($scope, $state, $element, $q, $filter, NotificationService, SupplierItemService, SupplierItemPriceUpdateService, UTCDateService, GLOBAL_CONSTANTS, $mdDialog) {
    var vm = this;
    vm.init = init;
    vm.todaysDate = new Date();
    vm.tomorrow = new Date();
    vm.tomorrow.setDate(vm.tomorrow.getDate() + 1);
    vm.tomorrow = UTCDateService.utcToDate(vm.tomorrow);
    vm.priceUpdateNumber = SupplierItemPriceUpdateService.getPriceUpdateNumber();
    vm.searchForItems = searchForItems;
    vm.searchText = '';
    vm.priceUpdateItems = [];
    vm.itemSearchQuery = {};
    vm.addNewPriceUpdate = addNewPriceUpdate;
    vm.deletePriceUpdate = deletePriceUpdate;
    vm.priceUpdateDateChanged = priceUpdateDateChanged;
    vm.itemChanged = itemChanged;
    vm.proceedToPriceUpdate = proceedToPriceUpdate;
    vm.errorList = null;
    vm.isPerpetual = false;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.showPricePerBO = showPricePerBO;
    vm.cancel = cancel;
    vm.getItemPricePerOutlet = getItemPricePerOutlet;
    vm.getPricePerBusiness = getPricePerBusiness;

    function init() {
      vm.priceUpdateFilter = SupplierItemPriceUpdateService.getPriceUpdateFilter();
      if(vm.priceUpdateFilter === null) {
        $state.go('supplier.items.price-update');
      }
      else {
        vm.priceUpdateItems.push({});
        vm.itemSearchQuery = angular.copy(vm.priceUpdateFilter);
        delete vm.itemSearchQuery.businessList;
        delete vm.itemSearchQuery.buyerList;
        delete vm.itemSearchQuery.brandList;
      }
    }

    function searchForItems(queryType, queryText) {
      var deferred = $q.defer();
      if(!queryText) {
        return;
      }
      else {
        vm.itemSearchQuery.queryField = queryType;
        vm.itemSearchQuery.queryText = queryText;
        SupplierItemService.searchAllItems(vm.itemSearchQuery)
        .then(function(response) {
          deferred.resolve($filter('orderBy')(response.data, 'itemName'));
        }, function(error) {
          deferred.reject(error);
        });
      }
      return deferred.promise;
    }

    function itemChanged(priceUpdateItem, index) {
      var temp = angular.copy(priceUpdateItem);
      priceUpdateItem.itemName = temp.selectedItem.itemName;
      priceUpdateItem.itemCode = temp.selectedItem.itemCode;
      priceUpdateItem.uom = temp.selectedItem.uom.name;
      priceUpdateItem.price = temp.selectedItem.price;
      priceUpdateItem.itemId = temp.selectedItem.id;
    }

    function priceUpdateDateChanged(date, type, index) {
      date = UTCDateService.utcToDate(date);
      //reset end date if it is before start date
      var isafter = moment(vm.priceUpdateItems[index].startDate).isAfter(moment(vm.priceUpdateItems[index].endDate));
      if (type === 'startDate' && isafter) {
        vm.priceUpdateItems[index].endDate = null;
      }
    }

    function proceedToPriceUpdate() {
      vm.errorList = null;
      vm.priceUpdateDTO = angular.copy(vm.itemSearchQuery);
      vm.priceUpdateDTO.items = vm.priceUpdateItems;
      SupplierItemPriceUpdateService.newPriceUpdateItems(vm.priceUpdateDTO)
      .then(function(response) {
        NotificationService.success({
          title: 'Price Update Request',
          message: 'alertMessage.SENT_SUCCESSFULLY'
        });
        $state.go('supplier.dashboard');
      }, function(error) {
        vm.translatedError = error.data;
        NotificationService.error({
          title: 'Price Update Request',
          error: error
        });
      });
    }
    function addNewPriceUpdate() {
      vm.priceUpdateItems.push({});
    }

    function deletePriceUpdate(index) {
      vm.priceUpdateItems.splice(index, 1);
    }

    /**
     * [showPrice details SHows the detailed Price of BO's]
     * @param {Object} event
     */
    function showPricePerBO(event) {
      $mdDialog.show({
        templateUrl: 'app/supplier-modules/item-management/price-update/item-selection/supplier.price-update.price-per-bo.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function getItemPricePerOutlet(priceUpdateItem, event){
      SupplierItemPriceUpdateService.getItemPricePerOutlet({
        'itemId': priceUpdateItem.itemId,
        'businessIdList': vm.priceUpdateFilter.businessIdList
      }).then(function(response){
        vm.outletList = response.data;
        vm.showPricePerBO(event);
      }, function(error){
        NotificationService.error({
          title: 'Price Update Request',
          error: error
        });
      })
    }

    /**
     * [cancel Cancels the modal]
     */
    function cancel() {
      $mdDialog.cancel();
    }

    function getPricePerBusiness(eachOutlet){
      return eachOutlet.price ? eachOutlet.price : eachOutlet.items.price;
    }
  }
})();
