/**
 * @Author: Ajay
 * @Date:   21-Apr-2017 17:54 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.checkout-confirm.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 01-Jun-2017 12:38 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('buyer.checkout-confirm', {
      parent: 'buyer',
      url: '/checkout-confirm',
      data: {
        authorities: ['ROLE_BUYER']
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/checkout-confirm/buyer.checkout-confirm.html',
          controller: 'BuyerCheckoutConfirmController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('user-management');
          return $translate.refresh();
        }]
      }
    });
  }
})();
