/**
* @Author: Ajay
* @Date:   27-Apr-2017 12:48 +05:30
* @Project: Ezyprocure
* @Filename: supplier.adhoc-invoice.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 27-Jun-2017 12:40 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider

    .state('supplier.tools.adhoc-invoice', {
      parent: 'supplier.tools',
      url: '/adhoc-invoice',
      data: {
        authorities: ['ROLE_SUPPLIER'],
        pageTitle: 'Adhoc Invoice'
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/tools/adhoc-invoice/supplier.adhoc-invoice.html',
          controller: 'SupplierAdhocInvoiceController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('itemBuyerMapping');
          $translatePartialLoader.addPart('items');
          return $translate.refresh();
        }],
        hasModuleAccess: ['Auth','$q', '$state', function(Auth, $q, $state) {
          if(Auth.hasPermission('accessForAdhocInvoice')){
            return true;
          }else {
            return $q.reject('Unauthorized');
          }
        }]
      }
    })
    .state('supplier.tools.adhoc-invoice.item-selection', {
      parent: 'supplier.tools.adhoc-invoice',
      url: '/item-selection',
      data: {
        authorities: ['ROLE_SUPPLIER'],
        pageTitle: 'Adhoc Invoice'
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/tools/adhoc-invoice/item-selection/supplier.adhoc-invoice.item-selection.html',
          controller: 'SupplierAdhocInvoiceItemSelectionController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('invoice');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('itemBuyerMapping');
          $translatePartialLoader.addPart('items');
          return $translate.refresh();
        }]
      }
    });
  }
})();
