/**
* @Author: Ajay
* @Date:   15-Dec-2016 18:34 +05:30
* @Project: Ezyprocure
* @Filename: uib-pagination.config.js
* @Last modified by:   Ajay
* @Last modified time: 27-Mar-2017 18:55 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(paginationConfig);

  paginationConfig.$inject = ['uibPaginationConfig', 'paginationConstants'];

  function paginationConfig(uibPaginationConfig, paginationConstants) {
    uibPaginationConfig.itemsPerPage = paginationConstants.itemsPerPage;
    uibPaginationConfig.maxSize = 5;
    uibPaginationConfig.boundaryLinks = true;
    uibPaginationConfig.firstText = '<<';
    uibPaginationConfig.previousText = '<';
    uibPaginationConfig.nextText = '>';
    uibPaginationConfig.lastText = '>>';
  }
})();
