/**
* @Author: Ajay
* @Date:   21-Apr-2017 17:54 +05:30
* @Project: Ezyprocure
* @Filename: buyer.bo-user-mapping.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 07-May-2018 15:06 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('buyer.tools.bo-user-mapping', {
      parent: 'buyer.tools',
      url: '/bo-user-mapping?page&sort&search',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'Business - User Mapping'
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/tools/business-user-mapping/buyer.bo-user-mapping.html',
          controller: 'BuyerBusinessUserMapController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          //$translatePartialLoader.addPart('buyerUserBusinessMapping');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('user-management');
          return $translate.refresh();
        }]
      }
    });
  }

})();
