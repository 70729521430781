/**
* @Author: Abhay
* @Project: Ezyprocure
* @Filename: ezypayment.admin.manage-merchant-details.cybersource-form.controller.js
* @Last modified by:   Abhay
* @Last modified time: 2021-01-29T16:59:23+05:30
*/


(function () {
    'use strict';
    angular
        .module('ezyprocureApp')
        .controller('EzypaymentAdminManageCybersourceMerchantDetailsController', EzypaymentAdminManageCybersourceMerchantDetailsController);

    EzypaymentAdminManageCybersourceMerchantDetailsController.$inject = ['$stateParams', '$state', '$scope', 'paginationConstants', 'pagingParams', 'NotificationService', 'ManageSupplierService', 'ManageBuyerService', 'BrandManagementService', '$mdDialog', 'GLOBAL_CONSTANTS', 'EzypaymentAdminManageMerchantDetailsService']

    function EzypaymentAdminManageCybersourceMerchantDetailsController($stateParams, $state, $scope, paginationConstants, pagingParams, NotificationService, ManageSupplierService, ManageBuyerService, BrandManagementService, $mdDialog, GLOBAL_CONSTANTS, EzypaymentAdminManageMerchantDetailsService) {

        var vm = this;
        vm.cybersourceMerchantDetailsForm = {};
        vm.user = $stateParams.user;
        vm.id = $stateParams.id;
        vm.bsfMidList = [];
        vm.loadAllBuyers = loadAllBuyers;
        vm.loadAllSuppliers = loadAllSuppliers;
        vm.loadBrandsByBuyer = loadBrandsByBuyer;
        vm.submitCybersourceForm = submitCybersourceForm;
        vm.updateCybersourceForm = updateCybersourceForm;
        vm.getAllCountryCurrencies = getAllCountryCurrencies;
        vm.getCybersourceDetailsById = getCybersourceDetailsById;
        vm.loadMerchantDetailByBsf = loadMerchantDetailByBsf;
        vm.loadBsfMidList = loadBsfMidList;

        init();

        function init() {
            getCybersourceDetailsById();
        }

        function loadBsfMidList() {
            EzypaymentAdminManageMerchantDetailsService.getBsfMidList().then(function (response) {
                if (Array.isArray(response.data)) {
                    vm.bsfMidList = response.data.map(function (id) {
                        return { "bsfBelowThreshold": id };
                    })
                }
            }, function (error) {
            });
        }

        function loadMerchantDetailByBsf() {
            EzypaymentAdminManageMerchantDetailsService.getMerchantDetailsByBsf(vm.cybersourceMerchantDetailsForm.bsfBelowThreshold).then(function (response) {
                var merchantDetail = response.data[0];
                vm.cybersourceMerchantDetailsForm.bsfAboveThreshold = merchantDetail.bsfAboveThreshold;
                vm.cybersourceMerchantDetailsForm.securityKeyPath = merchantDetail.securityKeyPath;
                vm.cybersourceMerchantDetailsForm.bsfMid = merchantDetail.bsfMid;
                vm.cybersourceMerchantDetailsForm.mdrBT = merchantDetail.mdrBelowThreshold;
                vm.cybersourceMerchantDetailsForm.mdrAT = merchantDetail.mdrAboveThreshold;
                vm.cybersourceMerchantDetailsForm.merchantIdBT = merchantDetail.midBelowThreshold;
                vm.cybersourceMerchantDetailsForm.merchantIdAT = merchantDetail.midAboveThreshold;
                vm.cybersourceMerchantDetailsForm.pipsAboveThreshold = merchantDetail.pipsAboveThreshold;
                vm.cybersourceMerchantDetailsForm.pipsBelowThreshold = merchantDetail.pipsBelowThreshold;
            }, function (error) {
            });
        }

        function loadAllBuyers() {
            ManageBuyerService.getAllAdminBuyers().then(function (response) {
                vm.buyers = response.data;
            }, function (error) {
            });
        }

        function loadAllSuppliers() {
            var params = {
                visibilityType: 'ALLOCATED'
            };
            ManageSupplierService.getAllAdminSuppliers(params).then(function (response) {
                vm.suppliers = response.data;
            }, function (error) {
            });
        }

        function loadBrandsByBuyer(buyerId) {
            BrandManagementService.getAllBrandsByBuyerId({
                query: '',
                buyerId: buyerId || vm.cybersourceMerchantDetailsForm.buyerId
            }).then(function (response) {
                vm.brands = response.data;
            });
        }

        function updateCybersourceForm() {
            var formData = getFormData();
            BrandManagementService.updateCyberSourceForm(formData)
                .then(function (response) {
                    $state.go('ezypayment.admin.merchant-management');
                    NotificationService.success({
                        title: 'Cybersource Merchant Details',
                        message: 'alertMessage.UPDATED_SUCCESSFULLY'
                    });
                },
                    function (error) {
                        NotificationService.error({
                            title: 'Cybersource Merchant Details',
                            error: error
                        });
                    });
        }

        function submitCybersourceForm() {
            var formData = getFormData();
            BrandManagementService.submitCyberSourceForm(formData)
                .then(function (response) {
                    $state.go('ezypayment.admin.merchant-management');
                    NotificationService.success({
                        title: 'Cybersource Merchant Details',
                        message: 'alertMessage.CREATED_SUCCESSFULLY'
                    });
                },
                    function (error) {
                        NotificationService.error({
                            title: 'Cybersource Merchant Details',
                            error: error
                        });
                    });
        }

        function getCybersourceDetailsById() {
            if (vm.id) {
                BrandManagementService.fetchCyberSourceDetails({
                    id: vm.id
                }).then(function (response) {
                    setFormData(response.data);
                },
                    function (error) {
                        NotificationService.error({
                            title: 'Cybersource Merchant Details',
                            error: error
                        });
                    });
            }
        }

        function getFormData() {
            var requestData = {};
            if (vm.user == 'BUYER') {
                requestData.brand = {
                    id: vm.cybersourceMerchantDetailsForm.brandId,
                    buyer: {
                        id: vm.cybersourceMerchantDetailsForm.buyerId
                    }
                };
            }
            else {
                requestData.supplier = {
                    id: vm.cybersourceMerchantDetailsForm.supplierId,
                };
            }
            requestData.countryCurrency = {
                id: vm.cybersourceMerchantDetailsForm.currencyId
            }
            requestData.id = vm.cybersourceMerchantDetailsForm.id;
            requestData.mdrAboveThreshold = vm.cybersourceMerchantDetailsForm.mdrAT;
            requestData.mdrBelowThreshold = vm.cybersourceMerchantDetailsForm.mdrBT;
            requestData.merchantId = vm.cybersourceMerchantDetailsForm.merchantId;
            requestData.midAboveThreshold = vm.cybersourceMerchantDetailsForm.merchantIdAT;
            requestData.midBelowThreshold = vm.cybersourceMerchantDetailsForm.merchantIdBT;
            requestData.threshold = vm.cybersourceMerchantDetailsForm.threshold;
            requestData.securityKeyPath = vm.cybersourceMerchantDetailsForm.securityKeyPath;
            requestData.bsfBelowThreshold = vm.cybersourceMerchantDetailsForm.bsfBelowThreshold;
            requestData.bsfAboveThreshold = vm.cybersourceMerchantDetailsForm.bsfAboveThreshold;
            requestData.pipsAboveThreshold = vm.cybersourceMerchantDetailsForm.pipsAboveThreshold;
            requestData.pipsBelowThreshold = vm.cybersourceMerchantDetailsForm.pipsBelowThreshold;
            requestData.bsfMid = vm.cybersourceMerchantDetailsForm.bsfMid;
            requestData.createdDate = vm.cybersourceMerchantDetailsForm.createdDate;

            return requestData;
        }

        function setFormData(response) {
            if (vm.user == 'BUYER') {
                vm.cybersourceMerchantDetailsForm.brandId = response.brand.id;
                vm.cybersourceMerchantDetailsForm.buyerId = response.brand.buyer.id;
                loadAllBuyers();
                loadBrandsByBuyer(vm.cybersourceMerchantDetailsForm.buyerId);
            }
            else {
                vm.cybersourceMerchantDetailsForm.supplierId = response.supplier.id;
                loadAllSuppliers();
            }
            getAllCountryCurrencies();
            loadBsfMidList();
            vm.cybersourceMerchantDetailsForm.id = response.id;
            vm.cybersourceMerchantDetailsForm.currencyId = response.countryCurrency.id
            vm.cybersourceMerchantDetailsForm.mdrAT = response.mdrAboveThreshold;
            vm.cybersourceMerchantDetailsForm.mdrBT = response.mdrBelowThreshold;
            vm.cybersourceMerchantDetailsForm.merchantId = response.merchantId;
            vm.cybersourceMerchantDetailsForm.merchantIdAT = response.midAboveThreshold;
            vm.cybersourceMerchantDetailsForm.merchantIdBT = response.midBelowThreshold;
            vm.cybersourceMerchantDetailsForm.threshold = response.threshold;
            vm.cybersourceMerchantDetailsForm.securityKeyPath = response.securityKeyPath;
            vm.cybersourceMerchantDetailsForm.bsfBelowThreshold = response.bsfBelowThreshold;
            vm.cybersourceMerchantDetailsForm.bsfAboveThreshold = response.bsfAboveThreshold;
            vm.cybersourceMerchantDetailsForm.pipsAboveThreshold = response.pipsAboveThreshold;
            vm.cybersourceMerchantDetailsForm.pipsBelowThreshold = response.pipsBelowThreshold;
            vm.cybersourceMerchantDetailsForm.bsfMid = response.bsfMid;
            vm.cybersourceMerchantDetailsForm.createdDate = response.createdDate;
        }

        function getAllCountryCurrencies() {
            EzypaymentAdminManageMerchantDetailsService.getAllCountryCurrencies().then(function (response) {
                vm.currencies = response.data;
            }, function (error) {
            });
        }
    }
})();
