/**
* @Author: Ajay
* @Date:   11-May-2017 16:10 +05:30
* @Project: Ezyprocure
* @Filename: admin.purchase-order-types.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 07-Nov-2017 15:31 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('PurchaseOrderTypesController', PurchaseOrderTypesController);

  PurchaseOrderTypesController.$inject = ['$scope', '$rootScope', '$state', '$mdDialog', 'PurchaseOrderTypeService', 'pagingParams', 'paginationConstants', 'NotificationService'];

  function PurchaseOrderTypesController($scope, $rootScope, $state, $mdDialog, PurchaseOrderTypeService, pagingParams ,paginationConstants, NotificationService) {
    var vm = this;

    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;

    vm.loadPurchaseOrderTypes = loadPurchaseOrderTypes;
    vm.showConfirm = showConfirm;
    vm.cancel = cancel;
    vm.showAddPurchaseOrderType = showAddPurchaseOrderType;
    vm.addPurchaseOrderType = addPurchaseOrderType;
    vm.showEditPurchaseOrderType = showEditPurchaseOrderType;
    vm.updatePurchaseOrderType = updatePurchaseOrderType;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    /**
    * [sort description]
    * @return {[type]} [description]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function loadPurchaseOrderTypes() {
      PurchaseOrderTypeService.getAllPurchaseOrderTypes({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort()
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.purchaseOrderTypes = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'Purchase Order Type Listing',
          error: error
        });
      });
    }

    vm.loadPurchaseOrderTypes();

    function deletePurchaseOrderType(purchaseOrderTypeId) {
      PurchaseOrderTypeService.deletePurchaseOrderType(purchaseOrderTypeId).then(function(response) {
        NotificationService.success({
          title: 'Purchase Order Type',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadPurchaseOrderTypes();
      }, function(error) {
        NotificationService.error({
          title: 'Purchase Order Type Deletion',
          error: error
        });
      });
    }

    function showConfirm(ev, purchaseOrderType) {
      var confirm = $mdDialog.confirm()
      .title('Are you sure you want to delete Purchase Order Type - ' + purchaseOrderType.name + ' ? ')
      .textContent('This action could not be reverted back.')
      .targetEvent(ev)
      .ok('Delete')
      .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        deletePurchaseOrderType(purchaseOrderType.id);
      }, function() {});
    }

    function cancel() {
      $mdDialog.cancel();
    }

    //Add Purchase Order Type Starts here

    function showAddPurchaseOrderType(event) {
      vm.newPurchaseOrderType = {};
      $mdDialog.show({
        templateUrl: 'app/admin-modules/purchase-order-types/admin.purchase-order-type.add.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function addPurchaseOrderType(newPurchaseOrderType) {
      PurchaseOrderTypeService.createPurchaseOrderType(newPurchaseOrderType).then(function(response) {
        $scope.$emit('ezyprocureApp:purchaseOrderTypeUpdate', response);
        vm.cancel();
        NotificationService.success({
          title: 'Purchase Order Type',
          message: 'alertMessage.CREATED_SUCCESSFULLY'
        });
        vm.loadPurchaseOrderTypes();
      }, function(error) {
        NotificationService.error({
          title: 'Purchase Order Type Creation',
          error: error
        });
      });
    }

    //Add PurchaseOrderType Ends here


    //Edit PurchaseOrderType Starts here

    function showEditPurchaseOrderType(event, oldPurchaseOrderType) {
      vm.editPurchaseOrderType = angular.copy(oldPurchaseOrderType);
      $mdDialog.show({
        templateUrl: 'app/admin-modules/purchase-order-types/admin.purchase-order-type.edit.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function updatePurchaseOrderType(oldPurchaseOrderType) {
      PurchaseOrderTypeService.updatePurchaseOrderType(oldPurchaseOrderType).then(function(response) {
        $scope.$emit('ezyprocureApp:purchaseOrderTypeUpdate', response);
        vm.cancel();
        NotificationService.success({
          title: 'Purchase Order Type',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        vm.loadPurchaseOrderTypes();
      }, function(error) {
        NotificationService.error({
          title: 'Purchase Order Type Updation',
          error: error
        });
      });
    }

    //Edit PurchaseOrderType Ends here

  }


})();
