/**
 * @Author: Elsa
 * @Date:   2017-05-30T11:56:30+05:30
 * @Project: Ezyprocure
 * @Filename: supplier.cancel-po-request.details.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 07-Nov-2017 15:10 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierCancelPORequestDetailsController', SupplierCancelPORequestDetailsController);

  SupplierCancelPORequestDetailsController.$inject = ['$scope', '$rootScope', '$stateParams', '$timeout', '$state', '$mdDialog', '$filter', 'PurchaseOrderService', 'SupplierCancelPORequestService', 'paginationConstants', 'NotificationService', 'GLOBAL_CONSTANTS'];

  function SupplierCancelPORequestDetailsController ($scope, $rootScope, $stateParams, $timeout, $state, $mdDialog, $filter, PurchaseOrderService, SupplierCancelPORequestService, paginationConstants, NotificationService, GLOBAL_CONSTANTS) {

    var vm = this;
    vm.poNumber = $stateParams.poNumber;
    vm.cancel = cancel;
    vm.supplierRejectCancelPORequest = supplierRejectCancelPORequest;
    vm.supplierAcceptCancelPORequest = supplierAcceptCancelPORequest;
    vm.showCancelPORejectModal = showCancelPORejectModal;
    vm.sumRequired = sumRequired;
    vm.sumSupplier = sumSupplier;
    vm.init = init;
    vm.checkAlreadyGeneratedCancelPO = checkAlreadyGeneratedCancelPO;
    vm.checkAlreadyRejectedCancelPO = checkAlreadyRejectedCancelPO;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.defaultDateTimeFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_TIME_FORMAT;
    /**
    * [init loads inititally]
    */
    function init(){
      loadAll();
    }
    /**
    * [loadAll Gets Purchase Order Details by PO number]
    */
    function loadAll() {
      PurchaseOrderService.getPurchaseOrderByPurchaseOrderNumber(vm.poNumber
      ).then(function(response){
        vm.checkAlreadyGeneratedCancelPO(response.data);
        vm.checkAlreadyRejectedCancelPO(response.data);
        vm.poDetails = response.data;
        vm.totalAmountRequired = sumRequired(vm.poDetails.items);
        vm.totalAmountSupplier = sumSupplier(vm.poDetails.items);
      },function(error){
      });
    }

    // Redirect back to PO Listing page if cancel po already generated*
    function checkAlreadyGeneratedCancelPO(poDetails) {
      if (poDetails.items[0].purchaseOrderStatus === 'PO_CANCELLED') {
        $state.go('supplier.cancel-po-request', {}, { reload: false});
      }
    }

    // Redirect back to PO Listing page if cancel po already rejected*
    function checkAlreadyRejectedCancelPO(poDetails) {
      if (poDetails.items[0].purchaseOrderStatus === 'PENDING') {
        $state.go('supplier.cancel-po-request', {}, { reload: false});
      }
    }

    /**
    * [cancel to close the modal]
    */
    function cancel () {
      $mdDialog.cancel();
    }

    /**
     * [sumRequired Grand total amount of buyer Requirement]
     * @param  {[object]} poItems [PO Details]
     * @return {[number]}         [total amount]
     */
    function sumRequired(poItems) {
      var totalAmount = 0;
      _.forEach(poItems, function(item) {
        if(item.buyerRequirement.items.uom.weightRequired === false && !item.buyerRequirement.freeItem) {
            if(item.buyerRequirement.items.uom.moqPrice === true)
            {
                totalAmount += (item.buyerRequirement.quantity/item.buyerRequirement.item.moq) * item.buyerRequirement.items.price;
            }
            else
            {
                totalAmount += item.buyerRequirement.quantity * item.buyerRequirement.items.price;
            }
        }
      });
      return totalAmount;
    }

    /**
     * [sumSupplier Grand total amount of supplier response]
     * @param  {[object]} poItems [PO Details]
     * @return {[number]}         [total amount]
     */
    function sumSupplier(poItems) {
      var totalAmount = 0;
      _.forEach(poItems, function(item) {
        //skip caluclations for free item
        if (!item.buyerRequirement.freeItem) {
          if(item.buyerRequirement.items.uom.weightRequired === false) {
            if(item.buyerRequirement.items.uom.moqPrice === true)
            {
              totalAmount += (item.buyerRequirement.quantity/item.buyerRequirement.items.moq) * item.buyerRequirement.items.price;
            }
            else
            {
              totalAmount += item.buyerRequirement.quantity * item.buyerRequirement.items.price;
            }
          }
          else {
            if(item.supplierResponses === null) {
              if(item.buyerRequirement.items.uom.moqPrice === true)
              {
                totalAmount += (item.buyerRequirement.weight/item.buyerRequirement.items.moq) * item.buyerRequirement.items.price;
              }
              else
              {
                totalAmount += item.buyerRequirement.weight * item.buyerRequirement.items.price;
              }
            }
            else {
              if(item.buyerRequirement.items.uom.moqPrice === true)
              {
                totalAmount += (item.supplierResponses.weight/item.buyerRequirement.items.moq) * item.buyerRequirement.items.price;
              }
              else
              {
                totalAmount += item.supplierResponses.weight * item.buyerRequirement.items.price;
              }
            }
          }
        }
      });
      return totalAmount;
    }

    function supplierRejectCancelPORequest() {
      cancel();
      SupplierCancelPORequestService.supplierRejectCancelPORequest(vm.poNumber, {
        supplierRejectCancelpoRemark : vm.rejectCancelPOReason
      }).then(function(response) {
        NotificationService.success({
          title: 'Cancel PO Request',
          message: 'alertMessage.REJECTED_SUCCESSFULLY'
        });
        $state.go('supplier.cancel-po-request', {}, {
          reload: false
        });
      }, function(error) {
        NotificationService.error({
          title: 'Cancel PO Request Rejection',
          error: error
        });
      });
    }

    function supplierAcceptCancelPORequest() {
      SupplierCancelPORequestService.supplierAcceptCancelPORequest(vm.poNumber)
      .then(function(response) {
        NotificationService.success({
          title: 'Purchase Order',
          message: 'alertMessage.CANCELLED_SUCCESSFULLY'
        });
        $state.go('supplier.cancel-po-request', {}, {
          reload: false
        });
      }, function(error) {
        NotificationService.error({
          title: 'Purchase Order Cancellation',
          error: error
        });
      });
    }

    function showCancelPORejectModal(event) {
      $mdDialog.show({
        templateUrl: 'app/supplier-modules/cancel-po-request/cancel-po-request.details/supplier.cancel-po-request.details.cancel-po-reject-dialog.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

  }
})();
