/**
 * @Author: Ajay
 * @Date:   15-Dec-2016 18:34 +05:30
 * @Project: Ezyprocure
 * @Filename: app.general.constants.js
 * @Last modified by:   Lovin
 * @Last modified time: 2020-05-26T16:39:04+05:30
 */



"use strict";
angular.module('ezyprocureApp')
.constant('API_CONSTANTS', {
  "baseVersion": '',
  "serviceBase": '',
  "ezyProcureBase": 'https://enterprise.ezyprocure.com/',
  "cbBase": 'https://cb.ezyprocure.com/',
  "itemImageBulkUploadServiceBase": 'https://v2.ezyprocure.com/',
  "peppolServiceBase": "https://peppol-prod.ezyprocure.com/",
  "satsServiceBase":"https://v2.ezyprocure.com/",
  "MPGS": {
    "BASE_URL": "https://ap-gateway.mastercard.com/",
    "SANDBOX_MODULE_VERSION": 49
  },
  "ezysourcingRedirectUrl": "https://www.ezysource.com/#/home?token=",
  "ezyinventoryAccessUrl":"https://ezyinventory.ezyprocure.com/",
  "ezyinventoryDomainName":"https://ezyinventory.ezyprocure.com/",
  "ezyinventoryUrl":"https://ezyinventory.ezyprocure.com/",
  "hostToLandingPages": {
    "ezyap.ezyprocure.com": { "url": "ezyap", "accessFlag": "accessForOnlyEzyAP", "allowAdmin": false },
    "peppol.ezyprocure.com": { "url": "ezyap", "accessFlag": "accessForOnlyEzyAP", "allowAdmin": false },
    // "ntpuat.ezypayment.com": { "url": "ezypayment", "accessFlag": "accessForPayViaUOBVPS", "allowAdmin": true }
  },
  "ezyfinanceUrl":"https://dev-ezy2finance-api.ezyprocure.com/"
});
