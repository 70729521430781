/**
* @Author: Hareesh
* @Date:   02-Aug-2017 12:43 +05:30
* @Project: Ezyprocure
* @Filename: buyer.purchase-request.controller.js
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerPurchaseRequestController', BuyerPurchaseRequestController);

  BuyerPurchaseRequestController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', '$stateParams', 'toastr', '$mdDialog','pagingParams', 'BuyerDataService', 'NotificationService', 'UTCDateService', 'BuyerBusinessService','PurchaseOrderService', 'BuyerSupplierMappingService', 'ItemGroupService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'BuyerBusinessUserMapService', 'BuyerBuyNowService', 'CSVExportService', 'EXPORT_CONSTANTS', 'GLOBAL_CONFIG_CONSTANTS'];

  function BuyerPurchaseRequestController($scope, $rootScope,$q ,$filter, $state, $stateParams, toastr, $mdDialog, pagingParams, BuyerDataService, NotificationService, UTCDateService, BuyerBusinessService, PurchaseOrderService, BuyerSupplierMappingService, ItemGroupService, GLOBAL_CONSTANTS, paginationConstants, BuyerBusinessUserMapService, BuyerBuyNowService, CSVExportService, EXPORT_CONSTANTS, GLOBAL_CONFIG_CONSTANTS) {
    var vm = this;
    vm.predicate = JSON.parse(localStorage.getItem("purchase-request-predicate")) || pagingParams.predicate;
    vm.reverse = JSON.parse(localStorage.getItem("purchase-request-reverse")) || pagingParams.ascending;
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.getAllAllocatedSuppliersForBusiness = getAllAllocatedSuppliersForBusiness;
    vm.prevSelectedOutletId=0;
    vm.loadItemGroups = loadItemGroups;
    vm.prStatuses = GLOBAL_CONSTANTS.PR_STATUSES;
    vm.listViews = GLOBAL_CONSTANTS.LIST_VIEWS;
    vm.prStatusClass = GLOBAL_CONFIG_CONSTANTS.PROCESS_ORDER_STATUS.LABEL_CLASS;
    vm.loadAll = loadAll;
    vm.todaysDate = new Date();
    vm.query = JSON.parse(localStorage.getItem("purchase-request-paginate")) || {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };

    vm.limitOptions = paginationConstants.limitOptions;
    //CSV Header
    vm.purchaseOrderHeader = EXPORT_CONSTANTS.CSV.BuyerPurchaseOrderReport;
    //CSV Function Declaration
    vm.getPurchaseOrderCSVData = getPurchaseOrderCSVData;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    //filter reset
    vm.resetFilters = resetFilters;
    vm.filter = JSON.parse(localStorage.getItem("purchase-request-filter")) || {
        listView: vm.listViews[0],
        prStatus: vm.prStatuses[0],
        fromDate: vm.todaysDate,
        toDate: vm.todaysDate
    };
    vm.init = init();    
    vm.selectAlll = selectAlll;
    vm.deselectAll = deselectAll;

    function init() {
        vm.filter.businessOutletId = '';
        if(vm.filter.businessOutletId) {
            vm.getAllBuyerBusinessOutlets();
          }
          if(vm.filter.supplierId) {
            vm.getAllAllocatedSuppliersForBusiness();
          }

          if(vm.filter.itemGroupId) {
            vm.loadItemGroups();
          }
          vm.loadAll();
    }
    //Getting CSV DATA
    function getPurchaseOrderCSVData(){
      var deferred = $q.defer();
      if (!vm.poItems) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      else{
          return CSVExportService.getBuyerPurchaseOrderReportCSV(vm.poItems);
      }
    }

    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
    * [getAllBuyerBusinessOutlets Gets all business outlets of buyer]
    */
    function getAllBuyerBusinessOutlets() {
      if(vm.businessOutlets)
        return false;

      vm.businessOutlets = null;
      BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser().
      then(function(response){
        vm.businessOutlets = response;
        //if only one business outlet set it as default
        if(vm.businessOutlets.length === 1) {
          vm.businessId = vm.businessOutlets[0].id;
        }
      },function(error){
      });
    }


    /**
    * [getAllAllocatedSuppliersForBusiness Get all allocated suppliers of buyer business]
    */
    function getAllAllocatedSuppliersForBusiness() {
      if(vm.supplierList && vm.prevSelectedOutletId === vm.filter.businessOutletId)
        return false;
      vm.prevSelectedOutletId = vm.filter.businessOutletId;
      var params = {'businessId': !vm.filter.businessOutletId ? '' : vm.filter.businessOutletId};
      BuyerBuyNowService.getAllAllocatedSuppliersForBusiness(params).then(function(response){
        vm.supplierList = $filter('orderBy')(response.data, 'companyName');
        //if only one allocated supplier set it as default
        if(vm.supplierList.length === 1) {
          vm.filter.supplierId = vm.supplierList[0].id;
        }
      },function(error){
      });
    }

    function loadItemGroups() {
      if(vm.itemGroups)
        return false;
      ItemGroupService.getAllItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.itemGroups = response.data;
      }, function(error) {
      });
    }

    /**
    * [loadAll Gets Purchase Order Details]
    */
    function loadAll() {
      localStorage.setItem("purchase-request-filter", JSON.stringify(vm.filter));
      localStorage.setItem("purchase-request-predicate", JSON.stringify(vm.predicate));
      localStorage.setItem("purchase-request-reverse", JSON.stringify(vm.reverse));
      localStorage.setItem("purchase-request-paginate", JSON.stringify(vm.query));
      if(vm.filter.listView === "Individual") {
        getIndividualPODetails();
      }
    }

    /**
    * [getIndividualPODetails gets PO details for each PO Number]
    */
    function getIndividualPODetails() {
      PurchaseOrderService.getAllPurchaseRequests({
        prNumber: !vm.filter.prNumber ? '' : vm.filter.prNumber,
        purchaseRequestStatus: vm.filter.prStatus ,
        invoiceStatus: '',
        invoiceNumber: '',
        businessOutletId: !vm.filter.businessOutletId ? '' : vm.filter.businessOutletId ,
        businessOutletName: '' ,
        //If dates are selected, they are converted to UTC format
        fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(new Date(vm.filter.fromDate)),
        toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(new Date(vm.filter.toDate)),
        query: !vm.filter.itemName ? '' : vm.filter.itemName,
        supplierId: !vm.filter.supplierId ? '': vm.filter.supplierId,
        itemGroupId: !vm.filter.itemGroupId ? '' :vm.filter.itemGroupId,
        approvalRequestNumber: !vm.filter.approvalRequestNumber ? '' :vm.filter.approvalRequestNumber,
        buyerIdList: '',
        debitNoteStatus: '',
        grnNumber: '',
        buyerName: '',
        creditNoteStatus: '',
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
      }).then(function(response){
        vm.poItems = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      },function(error){
        NotificationService.error({
          title: 'Purchase Order Details',
          error: error
        });
      });
    }

    // filter reset
    function resetFilters() {
        vm.filter.prStatus = vm.prStatuses[0];
        vm.filter.prNumber = '';
        vm.filter.fromDate = vm.todaysDate;
        vm.filter.toDate = vm.todaysDate;
        vm.filter.businessOutletId = '';
        vm.filter.itemName = '';
        vm.filter.supplierId = '';
        vm.filter.itemGroupId = '';
        vm.filter.approvalRequestNumber = '';
        vm.filter.listView = vm.listViews[0];
        vm.poItems = [];
        localStorage.setItem("purchase-request-filter", JSON.stringify(vm.filter));
    }

    function selectAlll(dropdown) {
      switch (dropdown) {
        case 'OUTLETS':
          vm.filter.businessOutletId = _.map(vm.businessOutlets, _.property('id'));
          break;
      }
    }

    function deselectAll(dropdown) {
      switch (dropdown) {
        case 'OUTLETS':
          vm.filter.businessOutletId = [];
          break;
      }
    }

  }
})();
