/*
 * @Author: Lovin 
 * @Date: 2019-06-21 11:51:05 
 * @Project: Ezyprocure
 * @Filename: admin.transaction-audit-log-report.state.js
 * @Last Modified by: Lovin
 * @Last Modified time: 2020-02-26 11:27:15
 */

(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .config(stateConfig);
  
    stateConfig.$inject = ['$stateProvider'];
  
    function stateConfig($stateProvider) {
      $stateProvider
      .state('ezyap.admin.transaction-audit-log', {
        parent: 'ezyap.admin',
        url: '/transaction-audit-log',
        data: {
          authorities: ['ROLE_ADMIN'],
          pageTitle: 'global.navbar.transactionAuditLog'
        },
        views: {
          'content@': {
            templateUrl: 'app/ezyap-modules/admin-modules/reports/transaction-audit-log/admin.transaction-audit-log.html',
            controller: 'TransactionAuditLogController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,asc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
          }]
        }
      });
    }
  
  })();
  