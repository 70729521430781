/**
* @Author: Ajay
* @Date:   09-Feb-2017 18:53 +05:30
* @Project: Ezyprocure
* @Filename: buyer.business-management.edit.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 03-May-2018 11:26 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerBusinessEditController', BuyerBusinessEditController);

  BuyerBusinessEditController.$inject = ['$scope', '$state', '$stateParams', 'BuyerBusinessService', 'BuyerDataService', 'ImageUploadService', 'GLOBAL_CONSTANTS', 'PurchaseOrderTypeService', 'paginationConstants', 'NotificationService', 'BrandManagementService', 'pagingParams', 'EzyCompanyService', 'Auth'];

  function BuyerBusinessEditController($scope, $state, $stateParams, BuyerBusinessService, BuyerDataService, ImageUploadService, GLOBAL_CONSTANTS, PurchaseOrderTypeService, paginationConstants, NotificationService, BrandManagementService, pagingParams, EzyCompanyService, Auth) {
    var vm = this;

    vm.businessOutletId = $stateParams.businessOutletId;
    vm.businessOutlet = {};
    vm.businessOutlet.buyer = {};
    vm.businessOutlet.defaultPurchaseOrderType = {};
    vm.save = save;
    vm.getBusinessOutletData = getBusinessOutletData;
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.setBusinessOutletLogo = setBusinessOutletLogo;
    vm.uploadLogo = uploadLogo;
    vm.loadPurchaseOrderTypes = loadPurchaseOrderTypes;
    vm.loadBrands = loadBrands;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.getBrandbyId = getBrandbyId;
    vm.getBuyerBusinessIdentifier = getBuyerBusinessIdentifier;
    vm.updateBuyerBusinessIdentifier = updateBuyerBusinessIdentifier;
    vm.buyerProfile = BuyerDataService.getBuyerProfile();
    vm.init = init;
    vm.supplierVisibilityTypes = GLOBAL_CONSTANTS.SUPPLIER_VISIBILITY_TYPES;
    if(!BuyerDataService.getBuyerProfile().accessDTO.accessForAdhocPo){
      vm.supplierVisibilityTypes = ['ALLOCATED'];
    }

    vm.init();

    //Sorting of buyer business outlets
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }
    /**
     * [loadBrands loads all brands]
     */
    function loadBrands(){
      var buyerId = vm.buyerProfile.buyerUser.buyer.id;
      if(vm.brands){
        return false;
      }
      BrandManagementService.getAllBrandsByBuyerId({
        page: 0,
        size: paginationConstants.maxLimit,
        sort: sort(),
        query: '',
        buyerId : buyerId
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.brands = response.data;
      },function(error){
        NotificationService.error({
          title: 'Brand Listing',
          error: error
        });
      });
    }
    function getBrandbyId(){
      if(!vm.businessOutlet.brand.id){
        return false;
      }
      BrandManagementService.getBrandById(vm.businessOutlet.brand.id)
      .then(function(response){
        vm.brand = response;
        vm.businessOutlet.brand = vm.brand;
        vm.businessOutlet.brandName = vm.brand.name;
      }
    );
    }
    function loadPurchaseOrderTypes() {
      PurchaseOrderTypeService.getAllPurchaseOrderTypes({
        page: 0,
        size: paginationConstants.maxLimit
      }).then(function(response) {
        vm.purchaseOrderTypes = response.data;
      }, function(error) {
      });
    }

    /**
     * [getBuyerBusinessIdentifier Gets Buyer business identifier details]
     */
    function getBuyerBusinessIdentifier() {
    //   vm.businessOutlet.companyRegistrationNumber = vm.businessOutlet.companyRegistrationNumber ? vm.businessOutlet.companyRegistrationNumber : vm.buyerProfile.buyerUser.buyer.companyRegistrationNumber;
      EzyCompanyService.getBuyerBusinessIdentifier(vm.buyerProfile.buyerUser.buyer.id,vm.businessOutletId).then(function(response) {
       vm.ezyCompanyDetails = response.data;
      }, function(error) {
        NotificationService.simpleErrorToast({
          title: 'Buyer Business',
          message: error.data.message
        });
      });
    }

    /**
     * [updateBuyerBusinessIdentifier Updates Buyer business identifier details]
     */
    function updateBuyerBusinessIdentifier() {
      EzyCompanyService.updateBuyerBusinessIdentifier({
        businessId:vm.businessOutlet.id,
        ezpCompanyId:vm.ezyCompanyDetails.ezpCompanyId,
        isoCode:vm.ezyCompanyDetails.isoCode?vm.ezyCompanyDetails.isoCode:null,
        receiverKey:vm.ezyCompanyDetails.receiverKey?vm.ezyCompanyDetails.receiverKey:null,
        id:vm.ezyCompanyDetails.id
        }).then(function(response) {
      }, function(error) {
        NotificationService.simpleErrorToast({
          title: 'Buyer Business Creation',
          message: error.data.message
        });
      });
    }

    function save () {
      vm.businessOutlet.companyRegistrationNumber = vm.tempCompanyRegistrationNumber;
      if(vm.buyerProfile.accessDTO.accessForPEPPOL){
        if(vm.ezyCompanyDetails.isoCode && vm.ezyCompanyDetails.receiverKey || vm.ezyCompanyDetails.id )
        vm.updateBuyerBusinessIdentifier();
      }
      vm.businessOutlet.buyer = vm.buyerProfile.buyerUser.buyer;
      if(!vm.businessOutlet.defaultPurchaseOrderType || !vm.businessOutlet.defaultPurchaseOrderType.id) {
        vm.businessOutlet.defaultPurchaseOrderType = undefined;
      }
      vm.businessOutlet.businessOutletCode = vm.businessOutlet.businessOutletCode  ? vm.businessOutlet.businessOutletCode : null ;

    //   vm.businessOutlet.companyRegistrationNumber = vm.businessOutlet.companyRegistrationNumber ? vm.businessOutlet.companyRegistrationNumber : (vm.businessOutlet.brand.acraNumber?vm.businessOutlet.brand.acraNumber:vm.businessOutlet.brand.buyer.companyRegistrationNumber);
      // Call Buyer business service with business outlet object as parameter
      BuyerBusinessService.updateBuyerBusiness(vm.businessOutlet).then(function(response){
        $scope.$emit('ezyprocureApp:buyerBusinessCreated', response);
        NotificationService.success({
          title: 'Buyer Business',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        $state.go('buyer.tools.business-management', {reload: false});
      },function(error) {
        NotificationService.error({
          title: 'Buyer Business Updation',
          error: error
        });
      });
    }

    function getBusinessOutletData() {
      //fetch buyer business outlet details using outlet id
      BuyerBusinessService.getBuyerBusiness(vm.businessOutletId).
      then(function(response) {
        if (response) {
          vm.businessOutlet = response;
          vm.tempCompanyRegistrationNumber = vm.businessOutlet.companyRegistrationNumber;
          vm.loadBrands();
        }
      }, function(error) {
      });
    }

    //convert image to base64
    function setBusinessOutletLogo (flowFile) {
      ImageUploadService.getImageBase64(flowFile.file, function(base64Img) {
        vm.uploadLogo(base64Img);
      });
    }

    //Upload Business outlet logo
    function uploadLogo (base64Img) {
      vm.uploadingInProgress = true;
      ImageUploadService.uploadImage(base64Img).
      then(function(responseData) {
        vm.businessOutlet.businessOutletLogo = responseData.url;
        $scope.editBusinessForm.$pristine = false;
        NotificationService.success({
          title: 'Business Outlet Logo uploaded successfully',
          message: 'Please update to save changes!'
        });
        vm.uploadingInProgress = false;
      }, function(error) {
        vm.uploadingInProgress = false;
        NotificationService.error({
          title: 'Business Outlet Logo Updation',
          error: error
        });
      });
    }

    function init(){
      getBusinessOutletData();
      loadBrands();
      vm.loadPurchaseOrderTypes();
      if(vm.buyerProfile.accessDTO.accessForPEPPOL){
      vm.getBuyerBusinessIdentifier();
      }
    }
  }
})();
