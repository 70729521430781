/**
* @Author: Ajay
* @Date:   17-Jan-2017 17:22 +05:30
* @Project: Ezyprocure
* @Filename: buyer.account.service.js
* @Last modified by:   Ajay
* @Last modified time: 03-Apr-2017 18:32 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BuyerAccountService', BuyerAccountService);

  BuyerAccountService.$inject = ['$http', '$q', 'API_CONSTANTS', 'AuthServerProvider', 'Principal', 'BuyerDataService'];

  function BuyerAccountService($http, $q, API_CONSTANTS, AuthServerProvider, Principal, BuyerDataService) {

    var buyerAccountService = {
      getBuyerProfile: getBuyerProfile,
      getBuyerAccount: getBuyerAccount,
      updateBuyerAccount: updateBuyerAccount,
      getCbBuyerProfile: getCbBuyerProfile
    };

    return buyerAccountService;

    /* --  Buyer Account APIs  -- */

    //Get buyer profile account details with access rights
    function getBuyerProfile() {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyers/profile').then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get buyer profile account details with access rights
    function getCbBuyerProfile() {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http.get(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + 'buyers/profile',
        {
          headers: { Authorization: 'Bearer ' + cbAuthToken }
        }
      ).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        Principal.setHasCbPlatform(false);
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get buyer profile account details
    function getBuyerAccount() {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyers/account').then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update Buyer Account
    function updateBuyerAccount(buyer) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyers/account', buyer).then(function(data) {
        if(Principal.isCBEnabled()) {
          updateCBBuyerAccount(buyer);
        }
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function updateCBBuyerAccount(buyer) {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      var buyerInfo = BuyerDataService.getCbBuyerProfile();
      if(buyerInfo) {
        buyer.id = buyerInfo.buyerUser.buyer.id;
        buyer.buyerUserId = buyerInfo.buyerUser.id;
        buyer.user.id = buyerInfo.buyerUser.user.id;
      }
      $http.put(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + 'buyers/account', buyer,
      {
        headers: { Authorization: 'Bearer ' + cbAuthToken }
      }).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
