/**
* @Author: sneha
* @Date:   14-Mar-2018 12:28 +05:30
* @Project: Ezyprocure
* @Filename: supplier.picking-list.state.js
 * @Last modified by:   sneha
 * @Last modified time: 11-May-2018 12:50 +05:30
*/

(function() {
  'use strict';
  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('supplier.picking-list-management.picking-list', {
      parent: 'supplier.picking-list-management',
      url: '/picking-list',
      data: {
        authorities: ['ROLE_SUPPLIER'],
        pageTitle: 'Picking List'
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/picking-list-management/picking-list/supplier.picking-list.html',
          controller: 'SupplierPickingListController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    })
    .state('supplier.picking-list-management.picking-list.add', {
      parent: 'supplier.picking-list-management.picking-list',
      url: '/add',
      data: {
        authorities: ['ROLE_SUPPLIER'],
        pageTitle: 'Create Picking List'
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/picking-list-management/picking-list/add/add-template/supplier.picking-list.add-template.html',
          controller: 'SupplierPickingListCreateController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    })
    .state('supplier.picking-list-management.picking-list.select-items', {
      parent: 'supplier.picking-list-management.picking-list',
      url: '/select-items',
      data: {
        authorities: ['ROLE_SUPPLIER'],
        pageTitle: 'Select Items'
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/picking-list-management/picking-list/add/item-select/supplier.picking-list.item-select.html',
          controller: 'SupplierPickingListSelectItemsController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('supplier');
          return $translate.refresh();
        }]
      }
    })
    .state('supplier.picking-list-management.picking-list.edit', {
      parent: 'supplier.picking-list-management.picking-list',
      url: '/edit/:templateId',
      data: {
        authorities: ['ROLE_SUPPLIER'],
        pageTitle: 'Edit Picking List'
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/picking-list-management/picking-list/edit/edit-template/supplier.picking-list.edit-template.html',
          controller: 'SupplierPickingListEditController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    })
    .state('supplier.picking-list-management.picking-list.manage-items', {
      parent: 'supplier.picking-list-management.picking-list',
      url: '/manage-items/:templateId',
      data: {
        authorities: ['ROLE_SUPPLIER'],
        pageTitle: 'Manage Item List'
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/picking-list-management/picking-list/edit/edit-item-select/supplier.picking-list.edit-item-select.html',
          controller: 'SupplierPickingListManageItemsController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('supplier');
          return $translate.refresh();
        }]
      }
    })
  }
})();
