/**
* @Author: sneha
* @Date:   2019-01-24T17:25:12+05:30
* @Project: Ezyprocure
* @Filename: supplier.sftp-details.state.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-02-21T15:19:10+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('supplier.tools.sftp-details', {
      parent: 'supplier.tools',
      url: '/sftp-details?page&sort&search',
      data: {
        authorities: ['ROLE_SUPPLIER'],
        pageTitle: 'SFTP Details'
      },
      views: {
        'content@': {
          templateUrl: 'app/common-modules/sftp-details/sftp-details.manage.html',
          controller: 'SFTPDetailsController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null,
        module:'EZYPROCURE'
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }],
        hasModuleAccess: ['Auth','$q', '$state', function(Auth, $q, $state) {
          if(Auth.hasPermission('accessForSFTP')){
            return true;
          }else {
            return $q.reject('Unauthorized');
          }
        }]
      }
    })
    .state('supplier.tools.sftp-details.add', {
      parent: 'supplier.tools.sftp-details',
      url: '/add',
      data: {
        authorities: ['ROLE_SUPPLIER'],
        pageTitle: 'Add SFTP Details'
      },
      views: {
        'content@': {
          templateUrl: 'app/common-modules/sftp-details/add/sftp-details.add.html',
          controller: 'SFTPDetailsAddController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    })
    .state('supplier.tools.sftp-details.edit', {
      parent: 'supplier.tools.sftp-details',
      url: '/edit/:sftpId',
      data: {
        authorities: ['ROLE_SUPPLIER'],
        pageTitle: 'Edit SFTP Details'
      },
      views: {
        'content@': {
          templateUrl: 'app/common-modules/sftp-details/edit/sftp-details.edit.html',
          controller: 'SFTPDetailsEditController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    })
  }

})();
