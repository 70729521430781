/**
* @Author: Ajay
* @Date:   06-Apr-2017 14:35 +05:30
* @Project: Ezyprocure
* @Filename: rbac.directive.js
* @Last modified by:   Ajay
* @Last modified time: 05-May-2017 13:48 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .directive('rbctrl', rbctrl);

  rbctrl.$inject = ['Auth'];

  function rbctrl(Auth) {
    var directive = {
      restrict: 'A',
      scope: {
        rbctrl_id: '=',
      },
      link: linkFunc
    };

    return directive;

    function linkFunc(scope, element, attrs) {
      if(!Auth.hasPermission(attrs.rbctrl)) element.remove();
    }
  }
})();
