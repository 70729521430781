/**
* @Author: Ajay
* @Date:   29-Mar-2017 19:29 +05:30
* @Project: Ezyprocure
* @Filename: supplier.calendar.service.js
* @Last modified by:   Ajay
* @Last modified time: 05-Apr-2017 15:29 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('SupplierCalendarService', SupplierCalendarService);

  SupplierCalendarService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function SupplierCalendarService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var supplierCalendarService = {
      getAllSupplierCalendars: getAllSupplierCalendars,
      getSupplierCalendar: getSupplierCalendar,
      createSupplierCalendar: createSupplierCalendar,
      updateSupplierCalendar: updateSupplierCalendar,
      deleteSupplierCalendar: deleteSupplierCalendar
    };

    return supplierCalendarService;

    /* --  SupplierCalendar CRUID operations  -- */

    /**
    * [getAllSupplierCalendars Get All supplierCalendars]
    * @param  {[Object]} urlParams [urlParams]
    * @return {[List]}           [SupplierCalendars]
    */
    function getAllSupplierCalendars(urlParams) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.CALENDAR_RESOURCE,
        method: "GET",
        params: urlParams,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [getSupplierCalendar Get supplierCalendar details using supplierCalendar id]
    * @param  {[Integer]} supplierCalendarId [supplierCalendarId]
    * @return {[Object]}                    [supplierCalendar]
    */
    function getSupplierCalendar(supplierCalendarId) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.CALENDAR_RESOURCE + supplierCalendarId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [createSupplierCalendar Create a new supplierCalendar]
    * @param  {[Object]} supplierCalendar [supplierCalendar Object]
    * @return {[Object]}                  [response object - success of error]
    */
    function createSupplierCalendar(supplierCalendar) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.CALENDAR_RESOURCE, supplierCalendar).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update supplierCalendar
    /**
    * [updateSupplierCalendar Update supplier calendar]
    * @param  {[Object]} supplierCalendar [supplierCalendar Object]
    * @return {[Object]}                  [response object - success of error]
    */
    function updateSupplierCalendar(supplierCalendar) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.CALENDAR_RESOURCE, supplierCalendar).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [deleteSupplierCalendar Soft Delete supplierCalendar using supplierCalendarId]
    * @param  {[Integer]} supplierCalendarId [supplierCalendarId]
    * @return {[Object]}                  [response object - success of error]
    */
    function deleteSupplierCalendar(supplierCalendarId) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.CALENDAR_RESOURCE + supplierCalendarId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
