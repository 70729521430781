/**
* @Author: Midhun Mohan <MidhunMohan>
* @Date:   07-Apr-2017 15:34 +05:30
* @Project: Ezyprocure
* @Filename: collection-staff-interface.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 20-Jul-2018 12:49 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('CollectionStaffInterfaceController', CollectionStaffInterfaceController);

  CollectionStaffInterfaceController.$inject = ['$scope', '$rootScope', '$filter', '$state', '$stateParams', 'NotificationService', '$mdDialog', 'OCRImageUploadService', 'BuyerOCRService', 'BuyerDataService', 'UTCDateService', 'pagingParams', 'paginationConstants', 'GLOBAL_CONSTANTS'];

  function CollectionStaffInterfaceController($scope, $rootScope, $filter, $state, $stateParams, NotificationService, $mdDialog, OCRImageUploadService, BuyerOCRService, BuyerDataService, UTCDateService, pagingParams, paginationConstants, GLOBAL_CONSTANTS) {
    var vm = this;

    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.selectedPO = {};
    vm.uploadImgModal = showModal;
    vm.closeModal = closeModal;
    vm.testImage = testImage;
    vm.getAllPurchaseOrders = getAllPO;
    vm.init = init;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.filter = {};
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };

    /**
     * [sort description]
     * @return {[type]} [description]
     */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }


    /**
    * [init description]
    * @return {[type]} [description]
    */
    function init() {
      getAllPO();
    }
    /**
    * [testImage description]
    * @param  {[type]} file [description]
    * @return {[type]}      [description]
    */
    function testImage(file) {
      OCRImageUploadService.getImageBase64(file[0].lfFile, function(base64Img) {
        BuyerOCRService.invoiceImgUpload(base64Img).then(function(response) {
          closeModal();
          invoiceToMicroService(vm.selectedPO, response.data);
        }, function(error) {
        });
      });
    }

    function invoiceToMicroService(poData, invoiceImage) {
      var item = {
        "poNumber": poData.purchaseOrderNumber,
        "invoiceUrl": invoiceImage,
        "invoiceAmount": poData.totalAmountRequired.toFixed(2),
        "user": BuyerDataService.getBuyerProfile().buyerUser.user.login,
        "deliveryDate":BuyerOCRService.toOCRDateString(poData.processOrder.deliveryDate)
      };
      BuyerOCRService.invoiceMicroService(item).then(function(response) {
        NotificationService.success({
          title: 'OCR Invoice',
          message: 'alertMessage.UPLOADED_SUCCESSFULLY'
        });
        init();
      }, function(error) {
      });
    }

    function closeModal() {
      $mdDialog.hide();
    }

    //Add Image to PO Starts Here

    function showModal(event, poData) {
      var selectedPO = poData;
      BuyerOCRService.grnByPONumber(poData.purchaseOrderNumber,poData.processOrder.deliveryDate).then(function(response) {
        var poDetails = response.data;
        selectedPO.totalAmountRequired = poDetails.grnamount;
      }, function(error) {});
      vm.selectedPO = selectedPO;
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/ocr/buyer.ocr.uploadInvoice.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }
    /**
    * [getAllPO Get all Purchase orders]
    * @return {[List<object>]} [Array of PO]
    */
    function getAllPO() {
      BuyerOCRService.getAllPurchaseOrdersCollection({
        page: vm.query.page - 1,
        size: vm.query.limit,
        query: !vm.filter.poNumber?'':vm.filter.poNumber,
        sort: sort()
      }).then(function(response) {
        vm.poList = response.data;
        var poArray = [];
        vm.poList.forEach(function(item) {
          var deliveryOrder={};
          deliveryOrder.poNumber=item.purchaseOrder.purchaseOrderNumber;
          deliveryOrder.deliveryDate=BuyerOCRService.toOCRDateString(item.purchaseOrder.processOrder.deliveryDate);
          poArray.push(deliveryOrder);
        });
        BuyerOCRService.getInvoiceStatus({
          'deliveryOrders': poArray
        }).then(function(response) {
          var invoiceArray=response.data.data;
          vm.poList.forEach(function(item) {
            for (var i = 0; i < invoiceArray.length; i++) {
              if (invoiceArray[i].poNumber === item.purchaseOrder.purchaseOrderNumber&&invoiceArray[i].deliveryDate===BuyerOCRService.toOCRDateString(item.purchaseOrder.processOrder.deliveryDate)) {
                item.invoiceOCRStatus=invoiceArray[i].invoiceStatus.replace(/_/g,' ');
                item.invoiceUrl=invoiceArray[i].invoiceUrl;
              }
            }
            item.purchaseOrder.ocrAcceptanceStatus=!item.purchaseOrder.ocrAcceptanceStatus?'':item.purchaseOrder.ocrAcceptanceStatus.replace(/_/g,' ');
          });
        }, function(error) {});
        vm.totalItems = response.headers('X-Total-Count');
      }, function(error) {
      });
    }

  }
})();
