/**
* @Author: Ajay
* @Date:   24-Feb-2017 12:40 +05:30
* @Project: Ezyprocure
* @Filename: supplier.account.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-11-26T15:50:37+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierAccountController', SupplierAccountController);

  SupplierAccountController.$inject = ['$scope', '$rootScope', '$state', 'SupplierAccountService', 'ImageUploadService', 'Auth', 'TIME_CONSTANTS', 'GLOBAL_CONSTANTS', 'NotificationService','BankService', 'paginationConstants', 'EzyCompanyService', 'SupplierDataService', 'API_CONSTANTS', '$window', 'Principal'];

  function SupplierAccountController($scope, $rootScope, $state, SupplierAccountService, ImageUploadService, Auth, TIME_CONSTANTS, GLOBAL_CONSTANTS, NotificationService, BankService, paginationConstants, EzyCompanyService, SupplierDataService, API_CONSTANTS, $window, Principal) {

    var vm = this;
    vm.getSupplierAccount = getSupplierAccount;
    vm.update = update;
    vm.checkDeliveryConditions = checkDeliveryConditions;
    vm.populateDeliveryConditions = populateDeliveryConditions;
    vm.deliveryConditions = {};
    vm.paymentTermsOptions = {};
    vm.paymentTerms = {};
    vm.paymentTerms.BANK_TRANSFER = {};
    vm.paymentTerms.PAYPAL = {};
    vm.paymentTerms.CHEQUE = {};
    vm.paymentTerms.OTHER_PAYMENT = {};
    vm.checkPaymentTerms = checkPaymentTerms;
    vm.populatePaymentTerms = populatePaymentTerms;
    vm.paymentTermChanged = paymentTermChanged;
    vm.changePassword = changePassword;
    vm.setCompanyLogo = setCompanyLogo;
    vm.uploadLogo = uploadLogo;
    vm.orderTimes = TIME_CONSTANTS.timeConstants;
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.disabled = $rootScope.toState.name === 'supplier.account.edit' ? false : true;
    vm.getAllBanks = getAllBanks;
    vm.selectedBankChanged = selectedBankChanged;
    vm.updateLeadTime = updateLeadTime;
    vm.supplierId =  SupplierDataService.getSupplierProfile().supplier.id;
    vm.getIdentifier = getIdentifier;
    vm.updateIdentifier = updateIdentifier;
    vm.createIdentifier = createIdentifier;
    vm.accessForPEPPOL = Auth.hasPermission("accessForPEPPOL");
    vm.isEditable = false;
    vm.init = init;
    vm.deleteIsoCodeAndIdentifier = deleteIsoCodeAndIdentifier;

    vm.init();

    function getSupplierAccount() {
      SupplierAccountService.getSupplierAccount().
      then(function(response) {
        if (response) {
          vm.supplierAccount = response;
          vm.populateDeliveryConditions();
          vm.populatePaymentTerms();
        }
      }, function(error) {
        NotificationService.error({
          title: 'Account Details',
          error: error
        });
      });
    }

    function init(){
      vm.getAllBanks();
      if(SupplierDataService.getSupplierProfile().accessDTO.accessForPEPPOL){
        vm.getIdentifier();
      }
      vm.isEditable = ($state.current.name === 'supplier.account.edit') ? true : false;
    }

    function update() {
      checkPaymentTerms();
      if(SupplierDataService.getSupplierProfile().accessDTO.accessForPEPPOL && vm.ezyCompanyDetails){
        if(vm.ezyCompanyDetailsId){
        vm.ezyCompanyDetails.isoCode == '' || vm.ezyCompanyDetails.identifier == '' ? vm.deleteIsoCodeAndIdentifier() : vm.updateIdentifier();
      }
      else{
         vm.createIdentifier();
        }
     }
      // Call Admin supplier service with supplier object as parameter to update
      SupplierAccountService.updateSupplierAccount(vm.supplierAccount).then(function(response) {
        $scope.$emit('ezyprocureApp:accountUpdated', response);
        NotificationService.success({
          title: 'Account Settings',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        $state.go('supplier.account.view', {reload: false});
      }, function(error) {
        NotificationService.error({
          title: 'Update Account',
          error: error
        });
      });
    }

    //delete iso code and identifier
    function deleteIsoCodeAndIdentifier(){
      EzyCompanyService.deleteIsoCodeAndIdentfier(vm.ezyCompanyDetails.id).then(function(response){
        NotificationService.success({
          title: 'global.IsoCodeAndIdentifier',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
      },
      function(error){
      });
    }

    function checkDeliveryConditions() {
      if(!vm.deliveryConditions.today) {
        vm.supplierAccount.orderTimeForToday = null;
      }
      if(!vm.deliveryConditions.friday) {
        vm.supplierAccount.orderTimeForFriday = null;
      }
      if(!vm.deliveryConditions.saturday) {
        vm.supplierAccount.orderTimeForSaturday = null;
      }
      if(!vm.deliveryConditions.minimum) {
        vm.supplierAccount.minimumOrderForFreeDelivery = null;
        vm.supplierAccount.belowMinimumPurchaseCondition = null;
      }
    }

    function populateDeliveryConditions() {
      if(vm.supplierAccount.orderTimeForToday) {
        vm.deliveryConditions.today = true;
      }
      if(vm.supplierAccount.orderTimeForFriday) {
        vm.deliveryConditions.friday = true;
      }
      if(vm.supplierAccount.orderTimeForSaturday) {
        vm.deliveryConditions.saturday = true;
      }
      if(vm.supplierAccount.minimumOrderForFreeDelivery) {
        vm.deliveryConditions.minimum = true;
      }
    }

    //call when a payment term is selected or deselected
    function paymentTermChanged(paymentType, active, paymentTerm) {
      paymentTerm.active = active;
      if(paymentTerm.active === false && !paymentTerm.id) {
        vm.paymentTerms[paymentType] = {};
      }
    }

    //check payment terms
    function checkPaymentTerms() {
      vm.supplierAccount.paymentTermsList = [];
      _.forEach(vm.paymentTerms, function(paymentTerm, key) {
        paymentTerm.paymentType = key;
        if (paymentTerm.active === true || paymentTerm.active === false) {
          vm.supplierAccount.paymentTermsList.push(paymentTerm);
        }
      });
    }

    //populate payment terms in the form
    function populatePaymentTerms() {
      vm.paymentTermsList = angular.copy(vm.supplierAccount.paymentTermsList);
      _.forEach(vm.paymentTermsList, function(paymentTerm) {
        var key = angular.copy(paymentTerm.paymentType);
        if(paymentTerm.active === true) {
          vm.paymentTermsOptions[key] = true;
          vm.paymentTerms[key] = paymentTerm;
        }
      });
    }

    function changePassword() {
      if(Principal.isCBEnabled()) {
        Auth.checkCbHealth()
        .then(function() {
          Auth.changePassword(vm.changePwd.newPassword).then(function(response) {
            $scope.$emit('ezyprocureApp:passwordChanged', response);
            NotificationService.success({
              title: 'Account Settings',
              message: 'Password Changed successfully'
            });
            Auth.clearToken();
            clearCbToken();
          }, function(error) {
            NotificationService.error({
              title: 'Account Settings - Change Password',
              error: error
            });
          });
        }, function(error) {
          NotificationService.error({
            title: 'Account Settings - Change Password',
            error: error
          });
        })
      } else {
        Auth.changePassword(vm.changePwd.newPassword).then(function(response) {
          $scope.$emit('ezyprocureApp:passwordChanged', response);
          NotificationService.success({
            title: 'Account Settings',
            message: 'Password Changed successfully'
          });
          Auth.clearTokenAndRedirectToLogin();
        }, function(error) {
          NotificationService.error({
            title: 'Account Settings - Change Password',
            error: error
          });
        });
      }
    }

    function clearCbToken() {
      $window.location.href = API_CONSTANTS.cbBase + '#/autologin/delete/null/'
    }
    /**IMAGE UPLOAD**/

    function setCompanyLogo (flowFile) {
      ImageUploadService.getImageBase64(flowFile.file, function(base64Img) {
        vm.uploadLogo(base64Img);
      });
    }

    function uploadLogo (base64Img) {
      vm.uploadingInProgress = true;
      ImageUploadService.uploadImage(base64Img).
      then(function(responseData) {
        vm.supplierAccount.companyLogo = responseData.url;
        $scope.supplierDetailsForm.$pristine = false;
        NotificationService.success({
          title: 'Account Settings - Logo Uploaded',
          message: 'Please update to save changes'
        });
        vm.uploadingInProgress = false;
      }, function(error) {
        vm.uploadingInProgress = false;
        NotificationService.error({
          title: 'Account Settings - Logo Upload',
          error: error
        });
      });
    }

    function getAllBanks() {
      BankService.getAllBanks({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.banks = response.data;
      }, function(error) {
      });
    }

    function selectedBankChanged(bankId) {
      var banks = angular.copy(vm.banks);
      vm.paymentTerms.BANK_TRANSFER.bank = BankService.getSelectedBank(banks, bankId);
    }


    /**
    * [updateLeadTime update lead time value based on noSameDayDelivery condition]
    * @param  {[type]} noSameDayDelivery [no same day delivery condition for supplier]
    * @return {[type]}                   [description]
    */
    function updateLeadTime(noSameDayDelivery){
      if(noSameDayDelivery){
        vm.supplierAccount.leadTime = 1;
      }
      else{
        vm.supplierAccount.leadTime = 0;
      }
    }

    /**
     * [getIdentifier Gets the identifier details]
     */
    function getIdentifier() {
      EzyCompanyService.getIdentifier(vm.supplierId,'ROLE_SUPPLIER').then(function(response) {
       vm.ezyCompanyDetails=response.data;
       vm.ezyCompanyDetailsId=vm.ezyCompanyDetails.id;
      }, function(error) {
        NotificationService.simpleErrorToast({
          title: 'Admin Supplier Updation',
          message: error.data.message
        });
      });
    }

    /**
     * [updateIdentifier Updates the identifier]
     */
    function updateIdentifier() {
      EzyCompanyService.updateIdentifier(
        {ezpCompanyId: vm.ezyCompanyDetails.ezpCompanyId,
         identifier:vm.ezyCompanyDetails.identifier,
         isoCode:vm.ezyCompanyDetails.isoCode,
         userRole:'ROLE_SUPPLIER',
         id:vm.ezyCompanyDetails.id}).then(function(response) {
      }, function(error) {
        NotificationService.simpleErrorToast({
          title: 'Admin Supplier Updation',
          message: error.data.message
        });
      });
    }

    /**
     * [createIdentifier Creates the identifier]
     */
    function createIdentifier() {
      EzyCompanyService.createIdentifier(
        {ezpCompanyId:vm.supplierId,
         identifier:vm.ezyCompanyDetails.identifier,
         isoCode:vm.ezyCompanyDetails.isoCode,
         userRole:'ROLE_SUPPLIER',
         id:''}).then(function(response) {
      }, function(error) {
        NotificationService.simpleErrorToast({
          title: 'Admin Supplier Updation',
          message: error.data.message
        });
      });
    }
  }
})();
