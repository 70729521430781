/**
* @Author: Ajay
* @Date:   09-Mar-2017 14:58 +05:30
* @Project: Ezyprocure
* @Filename: supplier.item.price-update.service.js
* @Last modified by:   Ajay
* @Last modified time: 29-Mar-2017 12:18 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('SupplierItemPriceUpdateService', SupplierItemPriceUpdateService);

  SupplierItemPriceUpdateService.$inject = ['$http', '$q', 'API_CONSTANTS','RESOURCE_URL_CONSTANTS'];

  function SupplierItemPriceUpdateService($http, $q, API_CONSTANTS,RESOURCE_URL_CONSTANTS) {

    var supplierItemPriceUpdateService = {
      newPriceUpdateItems: newPriceUpdateItems,
      setPriceUpdateFilter: setPriceUpdateFilter,
      getPriceUpdateFilter: getPriceUpdateFilter,
      getPriceUpdateNumber: getPriceUpdateNumber,
      getUserAccess: getUserAccess,
      priceUpdateCSVUpload: priceUpdateCSVUpload,
      getItemPricePerOutlet : getItemPricePerOutlet
    };

    var priceUpdateFilter;

    return supplierItemPriceUpdateService;

    //New price updates
    function newPriceUpdateItems(itemsForPriceUpdate) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'supplier/price-updates/', itemsForPriceUpdate).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [setPriceUpdateFilter store price update filter]
     * @param {[object]} priceUpdateFilterParam [price update object containing buyer list and business outlets]
     */
    function setPriceUpdateFilter(priceUpdateFilterParam) {
      priceUpdateFilter = priceUpdateFilterParam;
    }

    /**
     * [getPriceUpdateFilter retrive price update filter]
     * @return {[object]} [price update object containing buyer list and business outlets]
     */
    function getPriceUpdateFilter() {
      return priceUpdateFilter ? priceUpdateFilter : null;
    }

    /**
     * [getPriceUpdateNumber returns a random price update number]
     * @return {[int]} [random price update number]
     */
    function getPriceUpdateNumber() {
      return Math.floor(Math.random()*89999999999+10000000000);
    }

    /**
     * [priceUpdateCSVUpload Price Update using CSV]
     * @param  {[Object]} itemsForPriceUpdateCSV [contains base64 of price update CSV]
     * @return {[Object]}                        [success/error response message]
     */
    function priceUpdateCSVUpload(itemsForPriceUpdateCSV) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers/price-updates/upload', itemsForPriceUpdateCSV)
      .then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getUserAccess(userId) {
    alert(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'user/user-access/' + userId);
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'user/user-access/' + userId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getItemPricePerOutlet Gets the item price per outlet]
     */
    function getItemPricePerOutlet(data){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ITEM_BUSINESS_MAPPING+'/'+ RESOURCE_URL_CONSTANTS.BUYER_BUSINESS_RESOURCE +'price-update',
        method: "PUT",
        data:data,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    }
})();
