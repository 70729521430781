/**
 * @Author: Elsa
 * @Date:   2017-05-30T11:44:49+05:30
 * @Project: Ezyprocure
 * @Filename: supplier.cancel-po-request.service.js
 * @Last modified by:   Elsa
 * @Last modified time: 2017-05-30T11:57:33+05:30
 */



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('SupplierCancelPORequestService', SupplierCancelPORequestService);

  SupplierCancelPORequestService.$inject = ['$http', '$q', 'API_CONSTANTS'];
  function SupplierCancelPORequestService($http, $q, API_CONSTANTS) {

    var supplierCancelPORequestService = {
      supplierAcceptCancelPORequest : supplierAcceptCancelPORequest,
      supplierRejectCancelPORequest : supplierRejectCancelPORequest,
      getCancelPORequests : getCancelPORequests
    };
    return supplierCancelPORequestService;

    function supplierAcceptCancelPORequest(poNumber) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'purchaseOrder/accept/' + poNumber,
        method: "PUT",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function supplierRejectCancelPORequest(poNumber,params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'purchaseOrder/reject/' + poNumber,
        method: "PUT",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getCancelPORequests(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'purchaseOrder/cancelled',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
