/**
 * @Author: Ajay
 * @Date:   05-May-2017 11:36 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.order-history.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 01-Jun-2017 12:49 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('buyer.order-history', {
      parent: 'buyer',
      url: '/order-history',
      data: {
        authorities: ['ROLE_BUYER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/purchase-management/order-history/buyer.order-history.html',
          controller: 'BuyerOrderHistoryController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,desc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('user-management');
          $translatePartialLoader.addPart('processOrder');
          $translatePartialLoader.addPart('buyerBusiness');
          $translatePartialLoader.addPart('purchaseOrderStatus');
          return $translate.refresh();
        }]
      }
    })
    .state('buyer.order-history.details', {
      parent: 'buyer.order-history',
      url: '/details/:requirementNumber/:processOrderStatus',

      data: {
        authorities: ['ROLE_BUYER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/purchase-management/order-history/details/buyer.order-history.details.html',
          controller: 'BuyerOrderHistoryDetailsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('processOrder');
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('buyerBusiness');
          return $translate.refresh();
        }]
      }
    });
  }

})();
