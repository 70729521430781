/**
* @Author: elsa
* @Date:   2017-05-23T12:16:53+05:30
* @Project: Ezyprocure
* @Filename: supplier.return-order.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 18-Dec-2017 15:00 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('SupplierReturnOrderService', SupplierReturnOrderService);

  SupplierReturnOrderService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  function SupplierReturnOrderService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var supplierReturnOrderService = {
      getAllReturnOrders: getAllReturnOrders,
      createCreditNote: createCreditNote,
      getDebitNotesByGRNumber: getDebitNotesByGRNumber,
      supplierRejectCreditNote: supplierRejectCreditNote
    };

    return supplierReturnOrderService;

    /* --  Supplier Debit Note  APIs-- */

    function getAllReturnOrders(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'purchaseOrder/debit-note',
        method: "GET",
        params:params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getDebitNotesByGRNumber(goodsReturnNumber) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PURCHASE_ORDER_RESOURCE + 'debit-note/'+goodsReturnNumber,
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }


    function supplierRejectCreditNote(goodsReturnNoticeDTO) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'goods-return-notices/reject',
        method: "POST",
        data:  goodsReturnNoticeDTO
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function createCreditNote(creditNoteDetails) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'goods-return-notices/credit-note',
        method: "PUT",
        data: creditNoteDetails
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
