/**
* @Author: Ajay
* @Date:   27-Feb-2017 16:21 +05:30
* @Project: Ezyprocure
* @Filename: buyer.buy-now.service.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-05-20T17:13:54+05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BuyerBuyNowService', BuyerBuyNowService);

  BuyerBuyNowService.$inject = ['$http', '$q', 'API_CONSTANTS','RESOURCE_URL_CONSTANTS'];
  function BuyerBuyNowService($http, $q, API_CONSTANTS,RESOURCE_URL_CONSTANTS) {

    var buyerBuyNowService = {
      getAllItemBuyerMappingsByBuyer: getAllItemBuyerMappingsByBuyer,
      getAllAllocatedSuppliersForBusiness1: getAllAllocatedSuppliersForBusiness1,
      getAllPublicSuppliers: getAllPublicSuppliers,
      addToFavorites: addToFavorites,
      removeFromFavorites : removeFromFavorites,
      getBuyerFavorites: getBuyerFavorites,
      setDefaultView: setDefaultView,
      getCartDetails: getCartDetails,
      getMaximumAllocation: getMaximumAllocation,
      getAllAllocatedSuppliersForBusiness: getAllAllocatedSuppliersForBusiness,
      getAllItemBuyerMappingsByTemplateId: getAllItemBuyerMappingsByTemplateId,
      resetCart:resetCart,
      setBuyNowParams: setBuyNowParams,
      getBuyNowParams: getBuyNowParams,
      getDeliveryConditionsForOutlet: getDeliveryConditionsForOutlet,
      getAllBuyerItems: getAllBuyerItems
    };

    var buyNowFilterParams = {};
    
    return buyerBuyNowService;

    /* --  SupplierItem CRUID operations  -- */

    //Get All Item Buyer Mappings By Loggedin Buyer
    function getAllItemBuyerMappingsByBuyer(itemListQuery, params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buy-now/items',
        method: "GET",
        params:params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    // get specific delivery condition mapped to buyer outlet from all the mapped suppliers
    function getDeliveryConditionsForOutlet(outletId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyer-delivery-conditions/outlets/' + outletId,
        method: "GET"
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get All Allocated Suppliers For Business
    function getAllAllocatedSuppliersForBusiness(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buy-now/suppliers/allocated',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getAllAllocatedSuppliersForBusiness1(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buy-now/suppliers/allocated1',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get All Public Suppliers
    function getAllPublicSuppliers() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buy-now/suppliers/public',
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Add an item to favourites. Accepts item object as param
    function addToFavorites(favItem) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buy-now/buyer-favorite-items',
        method: "POST",
        data: favItem,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get the favourite items of a buyer
    function getBuyerFavorites() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buy-now/buyer-favorite-items',
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Remove an item from favorites. Accepts itemId as param
    function removeFromFavorites(itemId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buy-now/buyer-favorite-items/' + itemId,
        method: "DELETE",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Change default view of buynow for buyer. Accepts viewtype as param
    function setDefaultView(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buy-now/buyers/default-view',
        method: "PUT",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get All Items in the Cart
    function getCartDetails() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'cart',
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get maximum allocation of an item for the logged in buyer
    function getMaximumAllocation(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'item-buyer-mapping/maximum-allocation',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getAllItemBuyerMappingsByTemplateId(templateId, params){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ORDER_TEMPLATES +'/buy-now/'+ templateId,
        method: "GET",
        params:params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Reset Cart
    function resetCart() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'cart/buyer',
        method: "DELETE",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

/**
 * [setBuyNowParams set params for passing from filters page to items page in buy now]
 * @param {[type]} buyNowFilter [description]
 */
    function setBuyNowParams(buyNowFilter){
      buyNowFilterParams = buyNowFilter;
    }

    /**
     * [getBuyNowParams get params for passing from filters page to items page in buy now]
     * @param {[type]} buyNowFilter [description]
     */
    function getBuyNowParams(){
      return buyNowFilterParams;
    }

    function getAllBuyerItems(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyer-item-buying/items',
        method: "GET",
        params:params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }


  }
})();
