/**
* @Author: Ajay
* @Date:   06-Nov-2017 16:39 +05:30
* @Project: Ezyprocure
* @Filename: buyer.good-receipt-note.details.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-06-26T15:47:59+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerGoodReceiptNoteDetailsController', BuyerGoodReceiptNoteDetailsController);

  BuyerGoodReceiptNoteDetailsController.$inject = ['$scope', '$rootScope', '$filter', '$state', '$stateParams', 'toastr', '$mdDialog', 'PurchaseOrderService','Auth','BuyerDataService', 'UserAccessService','UTCDateService', 'EXPORT_CONSTANTS', 'CSVExportService', 'PDFExportService', 'NotificationService', 'GLOBAL_CONSTANTS'];

  function BuyerGoodReceiptNoteDetailsController($scope, $rootScope, $filter, $state, $stateParams, toastr, $mdDialog, PurchaseOrderService, Auth, BuyerDataService, UserAccessService, UTCDateService, EXPORT_CONSTANTS, CSVExportService, PDFExportService, NotificationService, GLOBAL_CONSTANTS) {
    var vm = this;
    vm.init = init;
    vm.poNumber = $stateParams.poNumber;
    vm.deliveryDate = new Date($stateParams.deliveryDate);
    vm.accessforDeliveryDateEdit = Auth.hasPermission('accessForAmendGrnDeliveryDate');
    vm.showReturnItems= false;
    vm.startReturnGoods = startReturnGoods;
    vm.addMore = addMore;
    vm.checkMaximumAcceptedQuantity = checkMaximumAcceptedQuantity;
    vm.deleteRow = deleteRow;
    vm.itemSelected = itemSelected;
    vm.returnGoods = returnGoods;
    vm.getGoodsReceiptNoteCSVData = getGoodsReceiptNoteCSVData;
    vm.getGoodReceiptNotePDF = getGoodReceiptNotePDF;
    vm.poTotalAmount=poTotalAmount;
    vm.hasAccessForGRNEditable = Auth.hasPermission('accessForGRNEditable');
    vm.updateGRN = updateGRN;
    vm.userRole = Auth.getUserRole();
    vm.reportType = "GOOD-RECEIPT-NOTE";
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.defaultDateTimeFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_TIME_FORMAT;
    vm.calculateDiscount = calculateDiscount;
    vm.calculateSubtotal = calculateSubtotal;
    vm.calTotalAmount = calTotalAmount;
    vm.roundOfPrice = roundOfPrice;
    vm.accessForPriceDisplay = Auth.hasPermission('accessForPriceDisplay');
    vm.inclusiveOfTax = GLOBAL_CONSTANTS.inclusiveOfTax;
    // negative buying
    vm.accessForNegativeBuyingBuyerView = Auth.hasPermission('accessForNegativeBuyingBuyerView');
    vm.calculateOtherCharges = calculateOtherCharges;
    vm.HAOMART_BUYER_ID = GLOBAL_CONSTANTS.HAOMART_BUYER_ID;
    vm.buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
    vm.accessForAdhocDiscount = Auth.hasPermission('accessForAdhocDiscount');
    vm.otherChargesAmount = vm.otherChargesPerCent = 0;


    function init(){
      if(vm.accessForPriceDisplay)
        vm.goodsReceiptNoteHeader = EXPORT_CONSTANTS.CSV.goodsReceiptNoteHeader;
      else {
        vm.goodsReceiptNoteHeader = _.without(EXPORT_CONSTANTS.CSV.goodsReceiptNoteHeader, 'unitPrice', 'amountPO', 'amountAccepted');
      }
      loadAll();
    }


    function calculateOtherCharges(type){
      switch(type){
        case 'AMOUNT':
          vm.otherChargesPerCent = +(angular.copy(Math.abs((vm.otherChargesAmount*100)/(vm.grnDetails.acceptTotalAmount )).toFixed(2)));;
          vm.calculateSubtotal('DISCARD');
         // vm.grandTotalAfterDiscount(subtotal);
          break;
        case 'PERCENT':
          var amount = (vm.otherChargesPerCent/100)*(vm.grnDetails.acceptTotalAmount);
          vm.otherChargesAmount = +(angular.copy(amount).toFixed(2));
          vm.calculateSubtotal('DISCARD');
         // vm.grandTotalAfterDiscount(subtotal);
          break;
      }
    }

    function poTotalAmount() {
      var poTotalAmount = 0;
      _.forEach(vm.grnDetails.items, function(item){
        if(!item.freeItem){
          if(item.uom.weightRequired === false) {
            if(item.uom.moqPrice === true ){
              poTotalAmount += _.round(((item.quantity /item.moq) * item.price),2);
            }
            else{
              poTotalAmount += _.round((item.quantity * item.price),2);
            }
          }else {
            if(item.uom.moqPrice === true ){
              poTotalAmount += _.round(((item.weight / item.moq )* item.price),2);
            }
            else{
              poTotalAmount += _.round(vm.roundOfPrice(item.weight * item.price),2);
            }
          }
        }else{
          item.poTotalAmount +=0;
        }
      });
      return _.round(poTotalAmount,2);
    }

    /**
    * function calculateSubtotal calculates the subTotal
    * @param {string} type
    */
    function calculateSubtotal(type) {
      var subTotal = 0;
      for (var i = 0; i < vm.grnDetails.items.length; i++) {
        if (!vm.grnDetails.items[i].freeItem) {
          if (!vm.grnDetails.items[i].uom.weightRequired) {
            if (vm.grnDetails.items[i].uom.moqPrice) {
              vm.grnDetails.items[i].totalPrice = (((vm.grnDetails.items[i].acceptedQuantity != null ? vm.grnDetails.items[i].acceptedQuantity : vm.grnDetails.items[i].quantity) / vm.grnDetails.items[i].moq) * vm.grnDetails.items[i].price);
            } else {
              vm.grnDetails.items[i].totalPrice = ((vm.grnDetails.items[i].acceptedQuantity != null ? vm.grnDetails.items[i].acceptedQuantity : vm.grnDetails.items[i].quantity) * vm.grnDetails.items[i].price);
            }
          } else {
            if (vm.grnDetails.items[i].uom.moqPrice) {
              vm.grnDetails.items[i].totalPrice = (((vm.grnDetails.items[i].acceptedWeight != null ? vm.grnDetails.items[i].acceptedWeight : vm.grnDetails.items[i].weight) / vm.grnDetails.items[i].moq) * vm.grnDetails.items[i].price);
            } else {
              vm.grnDetails.items[i].totalPrice = (vm.roundOfPrice((vm.grnDetails.items[i].acceptedWeight != null ? vm.grnDetails.items[i].acceptedWeight : vm.grnDetails.items[i].weight) * vm.grnDetails.items[i].price));
            }
          }
          subTotal += _.round(vm.grnDetails.items[i].totalPrice,2);
        }
      }
      //update subTotal used to calculate discount when quantity or weight is changed
      if (type == 'STORE') {
        vm.subTotalForDiscount = subTotal;
      }
      vm.calTotalAmount(subTotal);
    }

    /**
     * Calculates the GST and GrandTotal
     * @param {number} subTotal
     */
    function calTotalAmount(subTotal) {
      var gst;
      var grn;
      vm.grnDetails.acceptTotalAmount = subTotal;
      subTotal =  vm.grnDetails.acceptTotalAmount + vm.otherChargesAmount;
      var beforeDiscount = subTotal;
      vm.subTotalForDiscount = subTotal;
      vm.tempAcceptTotalAmount = beforeDiscount - (vm.grnDetails.discount ? vm.grnDetails.discount : 0);
      if(vm.grnDetails.supplier.visibilityType == 'ADHOC_OFF_BOARD_SUPPLIER' && vm.grnDetails.adhocPOGstEnabled){
        vm.grnDetails.grandTotal = vm.tempAcceptTotalAmount;
        vm.grnDetails.addedGST = vm.grnDetails.grandTotal - (vm.grnDetails.grandTotal/ (1 + (vm.grnDetails.gst / 100)));
        vm.grnDetails.subTotal = vm.grnDetails.grandTotal -  vm.grnDetails.addedGST;
        return false;
      }
      if(vm.grnDetails.supplier.tax === 'Active' && vm.grnDetails.gst !== null){
        gst=vm.grnDetails.addedGST = ( vm.grnDetails.gst / 100) * (vm.tempAcceptTotalAmount + vm.grnDetails.deliveryCharges);
        vm.grnDetails.addedGST=gst;
      }
      if( vm.grnDetails.adhocPOGstEnabled == false){
        vm.grnDetails.addedGST =  0;
      }
      vm.grnDetails.subTotal=vm.tempAcceptTotalAmount;
      vm.grnDetails.grandTotal=grn= _.round(vm.grnDetails.addedGST + vm.grnDetails.subTotal + vm.grnDetails.deliveryCharges, 2);
      if(vm.grnDetails.supplier.tax !== 'Active' && vm.grnDetails.gst !== null){
        vm.grnDetails.subTotal=vm.tempAcceptTotalAmount;
        vm.grnDetails.grandTotal = vm.tempAcceptTotalAmount + vm.grnDetails.deliveryCharges;
      }
    }

    //WeightUpdate
    function updateGRN(){
      vm.otherChargesAmount = vm.otherChargesAmount == null? 0 : vm.otherChargesAmount;
      var otherCharges = { };
      otherCharges[vm.otherChargesLabel] = vm.otherChargesAmount;
      var GoodReceiptStatus = {
        GoodReceiptStatusDTO : 'hhh',
        goodWeightUpdate : vm.grnDetails.items,
        discount : vm.grnDetails.discount,
        otherCharges: JSON.stringify(otherCharges)
      };
      PurchaseOrderService.setUpdateWeight_grn(GoodReceiptStatus).then(function(response){
        NotificationService.success({
          title: 'GRN Changes',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        /* $state.go('buyer.receiving-orders', {
        reload: false
      });*/
      });
    }

  function startReturnGoods() {
    vm.showReturnItems= true;
    vm.rejectedItems=[];
    vm.addMore();

  }
  function addMore() {
    vm.rejectedItems.push({});
  }
  function checkMaximumAcceptedQuantity(r_index,acceptedQuantity,itemId) {
    _.forEach(vm.returnItemList, function(item) {
      if(item.itemId === itemId) {
        if(acceptedQuantity > item.acceptedQuantity) {
          toastr.error('The input exceeds on the total quantity of the item.', 'Please check your input');
          vm.rejectedItems[r_index].rejectedQuantity = 0;
        }
      }
    });
  }

  function deleteRow(r_index) {
    vm.rejectedItems.splice(r_index, 1);
  }

  function itemSelected(selectedItemId) {
    for(var i=0; i<=vm.rejectedItems.length; i++) {
      if(vm.rejectedItems[i].items.itemId === selectedItemId) {
        toastr.error('Item Already Selected for rejection.', 'Please check your input');

      }
    }
  }

  //Get GRN Details by PO number
  function loadAll() {
    PurchaseOrderService.getGRNByPONumber(vm.poNumber, {
      deliveryDate: !vm.deliveryDate ? '' : vm.deliveryDate,
    }).then(function(response){
      vm.grnDetails = response.data;
      orderItemsByPurchaseOrderId(vm.grnDetails.items)
      vm.purchaseOrderNumber = vm.grnDetails.purchaseOrderNumber;
      vm.requirementNumber = vm.grnDetails.requirementNumber;
      vm.selectedDeliveryDate = vm.grnDetails.items[0].deliveryDate;
      vm.invoiceStatus = vm.grnDetails.items[0].invoiceStatus;
      vm.minAcceptedDate = new Date(vm.grnDetails.purchaseOrderDate);
      vm.subTotalForDiscount = vm.grnDetails.poTotalAmount;
      vm.tempAbsoluteDiscount = vm.grnDetails.discount;
      vm.otherChargesLabel = Object.keys(vm.grnDetails.otherCharges);
      vm.otherChargesAmount = vm.grnDetails.otherCharges ? vm.grnDetails.otherCharges[Object.keys(vm.grnDetails.otherCharges)] : 0;
      vm.calculateOtherCharges('AMOUNT');
      vm.calculateDiscount('ABSOLUTE')
      getItemArray();
    },function(error){
      NotificationService.error({
        title: 'Good Receipt Note Information',
        error: error
      });
    });
  }

  function getItemArray() {
    vm.returnItemList  = [];
    _.forEach(vm.grnDetails.items, function(item) {
      if (!item.freeItem) {
        if (item.uom.weightRequired === false) {
          vm.returnItemList.push({'itemName':item.itemName, 'itemId':item.itemId, 'acceptedQuantity':item.acceptedQuantity});
        }
        else {
          vm.returnItemList.push({'itemName':item.itemName, 'itemId':item.itemId, 'acceptedQuantity':item.acceptedWeight});
        }
      }
    });
  }

  function returnGoods() {
    var repeatItems = false;
    for (var i = 0; i < vm.returnItemList.length; i++) {
      var id= vm.returnItemList[i].itemId;
      var repeat = 0;

      for (var j = 0; j < vm.rejectedItems.length; j++) {
        if(vm.rejectedItems[j].items.id === id) {
          repeat++;
        }
        if(repeat>1) {
          repeatItems = true;
        }
      }
    }
    if(repeatItems === true) {
      toastr.error('Cannot Repeat Items for rejection.', 'Please check your input');
    }
    else {
      var returnOrderDTO = {
        purchaseOrderNumber: vm.poNumber,
        deliveryDate:vm.deliveryDate,
        rejectedItems: vm.rejectedItems
      };
      PurchaseOrderService.processGoodReturn(returnOrderDTO).then(function(response) {
        NotificationService.success({
          title: 'Goods Returned',
          message: 'successfully'
        });
        $state.go('buyer.reports.good-receipt-note', {reload: false});
      }, function(error) {
        NotificationService.error({
          title: 'Goods Return',
          error: error
        });
      });
    }
  }

  function getGoodsReceiptNoteCSVData() {
    return CSVExportService.getGoodsReceiptNoteCSV(vm.grnDetails);
  }

  function downloadURI(uri, name)
  {
    var link = document.createElement("a");
    document.body.appendChild(link);
    link.download = name;
    link.href = uri;
    link.click();
  }

  function getGoodReceiptNotePDF() {
    PDFExportService.getGoodReceiptNotePDF(vm.poNumber, {
      deliveryDate: !vm.deliveryDate ? '' : vm.deliveryDate,
    }).then(function(response){
      downloadURI(response.data.message, "PDF_" + vm.poNumber);
    },function(error) {
      NotificationService.error({
        title: 'PDF Export',
        error: error
      });
    });
  }
  //To handle large decimal values round of to 4 and then to 2
  function roundOfPrice(amount){
      var totalAmount;
      totalAmount = $filter('totalRoundOfDecimal')(amount);
      return totalAmount;
  }

  /**
  * function calculateDiscount calculates the % of discount when type is 'absolute' and calculates the discount's absolute value when type is 'percentage'
  * @param {string} type
  */
  function calculateDiscount(inputType){
    switch(inputType) {
      case 'PERCENTAGE':
      vm.grnDetails.discount = (vm.tempPercentDiscount/100)* (vm.grnDetails.acceptTotalAmount + vm.grnDetails.deliveryCharges || 0) ;
      vm.tempAbsoluteDiscount = +(angular.copy(vm.grnDetails.discount).toFixed(2));
      vm.calculateSubtotal('DISCARD');
      break;
      case 'ABSOLUTE':
      vm.tempPercentDiscount = +(angular.copy(Math.abs((vm.tempAbsoluteDiscount*100)/(vm.grnDetails.acceptTotalAmount  + vm.grnDetails.deliveryCharges || 0)).toFixed(2)));
      //setting value in *vm.grnDetails.discount* in order to use in calculateInvoiceTotal();
      vm.grnDetails.discount = vm.tempAbsoluteDiscount;
      vm.calculateSubtotal('DISCARD');
      break;
    }
  }

//   sorting items for same order on cart
function orderItemsByPurchaseOrderId(itemsList) {
    if(Array.isArray(itemsList)) {
      return itemsList.sort(function(a, b) {
        return a.purchaseOrderId - b.purchaseOrderId;
      });
    }
    else {
      return [];
    }
}

}
})();
