/**
 * @Author: lovinc
 * @Date:   2018-08-28T18:35:30+05:30
 * @Project: Ezyprocure
 * @Filename: buyer.item-business-mapping.service.js
 * @Last modified by:   lovinc
 * @Last modified time: 2018-08-29T13:19:20+05:30
 */



(function() {
    'use strict';

    angular.module('ezyprocureApp').factory('BuyerItemOutletMapService', BuyerItemOutletMapService);

    BuyerItemOutletMapService.$inject = ['$http', '$q', 'API_CONSTANTS','RESOURCE_URL_CONSTANTS'];
    function BuyerItemOutletMapService($http, $q, API_CONSTANTS,RESOURCE_URL_CONSTANTS) {

      var BuyerItemOutletMapService = {
        getSupplierBusinessOutlets: getSupplierBusinessOutlets,
        getBusinessOutlets: getBusinessOutlets,
        deleteMapping: deleteMapping,
        createNewItemSupplierOutletMapping: createNewItemSupplierOutletMapping,
        getAllItemBuyerMappings : getAllItemBuyerMappings,
        getItemDetailsById:getItemDetailsById,
        deleteMappingByItemBusinessMappingId: deleteMappingByItemBusinessMappingId,
        deleteMappingByItemBusinessMappingIds : deleteMappingByItemBusinessMappingIds,
        unmapMultipleItems: unmapMultipleItems
      };

      return BuyerItemOutletMapService;

      function getItemDetailsById(itemBusinessMappingdto) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'item/business/mapping/list',
          method: "POST",
          data: itemBusinessMappingdto
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      /**
       * [Get all bussiness outlets for the selected supplier.]
       */
      function getSupplierBusinessOutlets(params) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.SUPPLIER_USER_BUSINESS_MAPPING_RESOURCE+'buyer-business',
          method: "GET",
          params: params
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      /**
       * [getAllItemBuyerMappings description]
       * @param  {[type]} urlParams [description]
       * @return {[type]}           [description]
       */
      function getAllItemBuyerMappings(params) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ITEM_BUYER_MAPPINGS + 'listing',
          method: "GET",
          params: params,
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      /**
       * [Delete a buyer-item mapping using item id]
       */
      function deleteMapping(mappingId,outlets) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ITEM_BUSINESS_MAPPING + '/delete/' + mappingId +'/' +outlets,
          method: "DELETE",
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

       /**
        *[Get all bussiness outlets for the selected item. pagination,page,size and itemId as parameter.]
        */
       function getBusinessOutlets(params) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ITEM_BUSINESS_MAPPING + '/' + RESOURCE_URL_CONSTANTS.BUYER_BUSINESS_RESOURCE,
          method: "GET",
          params: params
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      /**
       * [Create new item mapping between supplier and business outlet]
       */
    function createNewItemSupplierOutletMapping(mappingObject) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ITEM_BUSINESS_MAPPING + '/buyer/items/mapping', mappingObject).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
       * [Delete a buyer-item mapping using item bussiness mapping id]
       */
      function deleteMappingByItemBusinessMappingId(itemBusinessMappingId) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'item-business-mappings/' + itemBusinessMappingId,
          method: "DELETE",
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      // Delete mapping by Ids
      function deleteMappingByItemBusinessMappingIds(itemBusinessMappingId, buyerId, itemId, accType) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'item/business/mappings/delete/' + itemBusinessMappingId+'/'+buyerId+'/'+ itemId + '/' + accType,
          method: "DELETE",
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      function unmapMultipleItems(itemDetailsList) {
        var deferred = $q.defer();
        $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'item/business/mappings/mass/delete', itemDetailsList).then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
    }
  })();
