/**
* @Author: Mufsir
* @Date:   18-Apr-2017 17:20 +05:30
* @Project: Ezyprocure
* @Last modified by:   Mufsir
* @Last modified time: 27-Apr-2017 13:03 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('UTCDateService', UTCDateService);

  UTCDateService.$inject = ['$http', '$q', 'toastr', 'API_CONSTANTS', 'GLOBAL_CONSTANTS'];

  function UTCDateService($http, $q, toastr, API_CONSTANTS, GLOBAL_CONSTANTS) {
    var utcDateService = {
      utcFromDate : utcFromDate,
      utcToDate : utcToDate,
      getCurrentDateWithTime: getCurrentDateWithTime,
      setCurrentTimeToDate: setCurrentTimeToDate
    };
    return utcDateService;
  }

  /**
   * [utcFromDate sets the utc fromDate]
   * @return {[date]} [utc fromDate]
   */
  function utcFromDate(fromDate) {
    fromDate = new Date(fromDate.setHours(0,0,0,0));
    // fromDate = moment.utc(fromDate).format();
    return fromDate;
  }

  /**
   * [utcToDate sets the utc toDate]
   * @return {[date]} [utc toDate]
   */
  function utcToDate(toDate) {
    toDate = new Date(toDate.setHours(23,59,58,999));
    // toDate = moment.utc(toDate).format();
    return toDate;
  }

  function getCurrentDateWithTime() {
    return new Date();
  }

  function setCurrentTimeToDate(date) {
    date = new Date(date);
    date.toUTCString(); // Sets the current time in UTC.
    // date = moment(date);
    // date = moment(date).set({hour: 0, minute: 0, second: 0}).toDate();
    return date;
  }

})();
