/**
 * @Author: Ajay
 * @Date:   05-May-2017 19:03 +05:30
 * @Project: Ezyprocure
 * @Filename: supplier.requirement-pending-po.state.js
 * @Last modified by:   sneha
 * @Last modified time: 2018-07-12T14:32:25+05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('supplier.requirement-pending-po', {
      parent: 'supplier',
      url: '/requirement-pending-po',
      data: {
        authorities: ['ROLE_SUPPLIER']
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/requirement-pending-po/supplier.requirement-pending-po.html',
          controller: 'SupplierRequirementPendingPOController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,desc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('supplierDashboard');
          $translatePartialLoader.addPart('purchaseOrder');
          $translatePartialLoader.addPart('processOrder');
          return $translate.refresh();
        }]
      }
    })
    .state('supplier.requirement-pending-po.details', {
      parent: 'supplier.requirement-pending-po',
      url: '/view/:requirementNumber',
      data: {
        authorities: ['ROLE_SUPPLIER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/requirement-pending-po/details/supplier.requirement-pending-po.details.html',
          controller: 'SupplierRequirementPendingPODetailsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('supplierDashboard');
          $translatePartialLoader.addPart('purchaseOrder');
          $translatePartialLoader.addPart('processOrder');
          $translatePartialLoader.addPart('invoiceOCR');
          $translatePartialLoader.addPart('promotion');
          $translatePartialLoader.addPart('processOrderStatus');
          return $translate.refresh();
        }]
      }
    });
  }
})();
