/**
* @Author: Allan
* @Date:   14-September-2018 16:15 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.sip-management.service.js
* @Last modified by:   Allan
* @Last modified time: 22-September-2018 14:45 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('EzypaymentSupplierSupplierInitiatePaymentService', EzypaymentSupplierSupplierInitiatePaymentService);

  EzypaymentSupplierSupplierInitiatePaymentService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function EzypaymentSupplierSupplierInitiatePaymentService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS, CardsService) {

    var EzypaymentSupplierSupplierInitiatePaymentService = {
      getBrandList: getBrandList,
      sendRequest: sendRequest
    };

    return EzypaymentSupplierSupplierInitiatePaymentService;

    function getBrandList(params){
      var deferred = $q.defer();

      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'brand-supplier-payment-model-mappings/supplier/active-models',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function sendRequest(brand){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'e-authorization-requests/',
        method: "POST",
        data: brand,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
