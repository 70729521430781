/**
 * @Author: Ajay
 * @Date:   15-Oct-2018 15:37 +05:30
 * @Project: Ezyprocure
 * @Filename: idle.config.js
 * @Last modified by:   Ajay
 * @Last modified time: 15-Oct-2018 15:41 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(idleServiceConfig);

  idleServiceConfig.$inject = ['KeepaliveProvider', 'IdleProvider', 'GLOBAL_CONSTANTS'];

  function idleServiceConfig(KeepaliveProvider, IdleProvider, GLOBAL_CONSTANTS) {
    IdleProvider.idle(GLOBAL_CONSTANTS.NG_IDLE.IDLE_SECONDS);
    IdleProvider.timeout(GLOBAL_CONSTANTS.NG_IDLE.IDLE_TIMEOUT_SECONDS);
    KeepaliveProvider.interval(GLOBAL_CONSTANTS.NG_IDLE.KEEP_ALIVE_INTERVAL_SECONDS);
  }
})();
