/**
 * @Author: Jayalekshmi 
 * @Date: 2019-01-03 10:40:23 
 * @Project: Ezyprocure
 * @Filename: sats.po.confirm.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-06-26 11:37:15
 */



(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .controller('SatsPOConfirmController', SatsPOConfirmController);
  
    SatsPOConfirmController.$inject = ['paginationConstants', 'toastr', 'CSVExportService' ,'$q', 'EXPORT_CONSTANTS', 'GLOBAL_CONSTANTS', '$state', '$stateParams', 'UtilService', 'NotificationService', 'CsvUploadService', 'Auth', 'S3_URL_CONSTANTS', 'SatsManagementService'];
  
    function SatsPOConfirmController(paginationConstants, toastr, CSVExportService, $q, EXPORT_CONSTANTS, GLOBAL_CONSTANTS, $state, $stateParams, UtilService, NotificationService, CsvUploadService, Auth, S3_URL_CONSTANTS, SatsManagementService) {
      var vm = this;
      vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
      vm.loadAll = loadAll;
      vm.userRole = Auth.getUserRole();
      vm.PO_JOB_CSV = S3_URL_CONSTANTS.PO_JOB_CSV;
      vm.getJobDetailsById = getJobDetailsById;
      vm.uploadJobPO = uploadJobPO;
      vm.resetValues = resetValues;
      vm.poJobId = $stateParams.poJobId;
      vm.confirmPOJob = confirmPOJob;
      vm.downloadAttachments = downloadAttachments;
      vm.getDateFormat = getDateFormat;
      vm.getWarehousePOCSVData = getWarehousePOCSVData;
      vm.warehousePODataHeader = EXPORT_CONSTANTS.CSV.warehousePOCSVHeader;

      /**
       * [getWarehousePOCSVData Gets the data in a csv format]
       */
      function getWarehousePOCSVData() {
        var deferred = $q.defer();
        if (vm.poDetails.lineItems.length <= 0 && (vm.poDetails.job.status !== 'RELEASED' && vm.poDetails.job.status !== 'REJECTED')) {
          toastr.error('No Line items are available!', 'Validation Error');          
          return deferred.promise;
        }
        return CSVExportService.getWarehousePOCSVData(vm.poDetails);
      }

      /**
       * [getDateFormat gets the date string and converts]
       * @param {dateString} dateString 
       */
      function getDateFormat(dateString){
        var formatedDate = SatsManagementService.getDateFormat(dateString);
        return formatedDate;
      }
    
      /**
       * [downloadAttachments Downloads attachments uploaded by the SATS]
       */
      function downloadAttachments(){
        var index = 0;
        var sample = setInterval(function(){
        if(index< vm.poDetails.attachments.length){
            SatsManagementService.downloadAttachments(vm.poDetails.attachments[index]);
        }
          index +=1;
          if(index==vm.poDetails.attachments.length){
            clearInterval(sample);
          }
        }, 3000);
      }

      /**
       * [resetValues Resets csv data and error data once click on browse button]
       */
      function resetValues(){
        vm.itemDetails = [];
        vm.recordError = [];
      }

      /**
       * [confirmPOJob Confirms Job]
       */
      function confirmPOJob(){
          SatsManagementService.confirmPOJob(vm.files[0].lfFile, vm.poJobId)
          .then(function(response){
            NotificationService.success({
              'title': 'PO Job Confirmation',
              'message': 'alertMessage.SUCCESSFULLY_CONFIRMED'
            });
            $state.go(vm.userRole.pretty+'.sats', {
              reload: false
            });
          },function(error){
            if(error.data.message == 'error.validation'){
              vm.recordError = error.data;
            }
            NotificationService.error({
              title: 'error.validation',
              error: error
            });
          })
      }

      /**
       * [uploadJobPO Uploads Job by CSV]
       */
      function uploadJobPO(){
        vm.recordError = [];
        var csvFile = vm.files[0];
        if (!csvFile) {
          NotificationService.simpleErrorToast({
            title: 'alertMessage.INVALID_INPUT',
            message: 'global.pleaseChooseACsvFile'
          });
          return false;
        }
        vm.isCsvFile = CsvUploadService.checkFileType(csvFile.lfFile);
        if (vm.isCsvFile) {
          UtilService.csvToJson(csvFile.lfDataUrl)
          .then(function (response) {
            vm.itemDetails = response;
            if(vm.itemDetails.length <1){
              NotificationService.simpleErrorToast({
                title: 'alertMessage.INVALID_INPUT',
                message: 'alertMessage.FILL_CSV'
              });
            }
          })
         }
      }

      /**
      * [loadAll Gets Purchase Order Details]
      */
      function loadAll() {
          getJobDetailsById();
      }
  
      /**
      * [getIndividualPODetails gets PO Job details]
      */
      function getJobDetailsById() {
        SatsManagementService.getJobDetailsById(vm.poJobId,{
          page: 0,
          size: paginationConstants.maxLimit,
        }).then(function(response){
          vm.poDetails = response.data;
        },function(error){
          NotificationService.error({
            title: 'global.sats.jobConfirmation',
            error: error
          });
        });
      }
      vm.loadAll();
    }
  })();
  