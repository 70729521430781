/**
 * @Author: Lovin
 * @Date:   2018-07-05T16:35:03+05:30
 * @Project: Ezyprocure
 * @Filename: buyer.place-your-order.state.js
 * @Last modified by:   Lovin
 * @Last modified time: 2018-07-05T16:37:28+05:30
 */



  (function() {
      'use strict';

      angular
      .module('ezyprocureApp')
      .config(stateConfig);

      stateConfig.$inject = ['$stateProvider'];

      function stateConfig($stateProvider) {
        $stateProvider
        .state('buyer.place-your-order', {
          parent: 'buyer',
          url: '/place-your-order',
          data: {
            authorities: ['ROLE_BUYER'],
          },
          views: {
            'content@': {
              templateUrl: 'app/buyer-modules/place-your-order/buyer.place-your-order.html',
              controller: 'PlaceYourOrderCsvUploadController',
              controllerAs: 'vm'
            }
          },
          params: {
            page: {
              value: '1',
              squash: true
            },
            sort: {
              value: 'id,desc',
              squash: true
            },
            search: null
          },
          resolve: {
            pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
              return {
                page: PaginationUtil.parsePage($stateParams.page),
                sort: $stateParams.sort,
                predicate: PaginationUtil.parsePredicate($stateParams.sort),
                ascending: PaginationUtil.parseAscending($stateParams.sort),
                search: $stateParams.search
              };
            }],
            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart('global');
              $translatePartialLoader.addPart('buyerDashboard');
              return $translate.refresh();
            }]
          }
        });

      }

    })();
