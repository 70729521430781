/*
 * @Author: Abhay
 * @Date: 20 Aug 2020 17:21 IST
 * @Filename: ezypayment.admin.tools.ntp-users-list.service.js
 * @Last modified by:   Abhay
 * @Last modified time: 20 Aug 2020 17:21 IST
 */

(function(){
    'use strict';
    angular.module('ezyprocureApp').factory('EzypaymentAdminToolsCompanyListService', EzypaymentAdminToolsCompanyListService);
  
    EzypaymentAdminToolsCompanyListService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  
    function EzypaymentAdminToolsCompanyListService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS){
        var adminToolsUserListService = {
            fetchNTPCompanyList: fetchNTPCompanyList,
            fetchFolderListByObjectId: fetchFolderListByObjectId,
            triggerPayment: triggerPayment,
            uploadNTPFile: uploadNTPFile
        };
        return adminToolsUserListService;

        function fetchNTPCompanyList() {
            var deffered = $q.defer();
            $http({
                url: API_CONSTANTS.serviceBase + 'api/' + 'ntp/data/coys',
                method: 'GET'
            }).then(function (data) {
                deffered.resolve(data);
            }).catch(function (message) {
                deffered.reject(message);
            });
            return deffered.promise;
        }

        function fetchFolderListByObjectId(objectId) {
            var deffered = $q.defer();
            $http({
                url: API_CONSTANTS.serviceBase + 'api/' + 'ntp/data/coys/objects',
                method: 'GET',
                params: {
                    'objectId': objectId
                }
            }).then(function (data) {
                deffered.resolve(data);
            }).catch(function (message) {
                deffered.reject(message);
            });
            return deffered.promise;
        }

        function triggerPayment(companyMap) {
            var deffered = $q.defer();
            $http({
                url: API_CONSTANTS.serviceBase + 'api/' + 'ntp/data/coys/process',
                method: 'POST',
                data: companyMap
            }).then(function (data) {
                deffered.resolve(data);
            }).catch(function (message) {
                deffered.reject(message);
            });
            return deffered.promise;
        }

        function uploadNTPFile(requestBody) {
            var deffered = $q.defer();
            $http.post(API_CONSTANTS.serviceBase + 'api/' + 'ntp/data/file', requestBody,   {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
              }).then(function (data) {
                deffered.resolve(data);
            }).catch(function (message) {
                deffered.reject(message);
            });
            return deffered.promise;
        }
    }
})();