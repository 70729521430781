/*
 * @Author: Jayalekshmi 
 * @Date: 2019-06-14 12:13:04 
 * @Project: Ezyprocure
 * @Filename: supplier.buyer-delivery-condition-mapping.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-06-14 12:38:08
 */



 (function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierBuyerDeliveryConditionsMapController', SupplierBuyerDeliveryConditionsMapController);

  SupplierBuyerDeliveryConditionsMapController.$inject = ['$scope', '$state', '$stateParams', 'toastr', 'SupplierDeliveryConditionService', 'NotificationService', 'pagingParams', '$mdDialog' , 'SupplierDataService', 'SupplierUserManagementService', 'BuyerBusinessService', 'SupplierBuyerDeliveryConditionMapService', 'BuyerSupplierMappingService', 'paginationConstants'];

  function SupplierBuyerDeliveryConditionsMapController($scope, $state, $stateParams, toastr, SupplierDeliveryConditionService, NotificationService, pagingParams, $mdDialog, SupplierDataService, SupplierUserManagementService, BuyerBusinessService, SupplierBuyerDeliveryConditionMapService, BuyerSupplierMappingService, paginationConstants) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.sort = sort;
    vm.loadAll = loadAll;
    vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.mapBuyerDeliveryConditionMapping = mapBuyerDeliveryConditionMapping;
    vm.unMapBuyerDeliveryConditionMapping = unMapBuyerDeliveryConditionMapping;
    vm.mapping = {};
    vm.userOutletMappingIdList = {};
    vm.mappedBuyerSearch = '';
    vm.BuyerSearchText = '';
    vm.mapping.supplierUserId = $stateParams.supplierUserId;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.clearBulkUploadWarnings = clearBulkUploadWarnings;
    vm.getAllSupplierDeliveryConditions = getAllSupplierDeliveryConditions;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
  
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
      * [getAllSupplierDeliveryConditions load all supplier delivery conditions]
      */
     function getAllSupplierDeliveryConditions(search) {
      if(vm.supplierDeliveryConditions){
        return false;
      }
      SupplierDeliveryConditionService.getAllSupplierDeliveryConditions({
        page: 0,
        size: paginationConstants.maxLimit,
        sort: sort(),
        query: !search ? '': search
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.supplierDeliveryConditions = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'Delivery Condition Listing',
          error: error
        });
      });
    }

    /**
    * [selectAll Select all business outlets from the list and create separate string array of outlets]
    */
    function selectAll(selectParam) {
      if(selectParam === 'OUTLETS')
        vm.mapping.outletId = _.map(vm.businessOutlets, _.property('id'));
    }

    /**
    * [deselectAll Deselect all business outlets from the list]
    */
    function deselectAll(selectParam) {
      if(selectParam === 'OUTLETS')
        vm.mapping.outletId = [];
      }

    // Load All supplier delivery conditions which are mapped
    function loadAll(search) {
      if (!search) {
        vm.mappedBuyerSearch = '';
      }
      SupplierBuyerDeliveryConditionMapService.getAllMappedBuyerDeliveryCondition({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.mappedBuyerSearch,
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.mappedBuyerDeliveryConditions = response.data;
      },function(error){
      });
    }

    /**
     * [getAllSupplierMappedBuyers Gets all suppliers mapped to buyer]
     */
    function getAllSupplierMappedBuyers() {
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
      };
      if(vm.buyers)
        return false;
      var supplierId = SupplierDataService.getSupplierProfile().supplier.id;
      BuyerSupplierMappingService.getAllSupplierMappedBuyers(supplierId, params)
      .then(function(response){
        vm.buyers = response.data;
      },function(error){
      });
    }

    /**
     * [getAllBuyerBusinessOutlets Get all business outlets of the logged in buyer]
     * @param {Integer} buyerId 
     */
    function getAllBuyerBusinessOutlets(buyerId) {
      BuyerBusinessService.getAllBuyerBusinessOutlets(buyerId, {
        page: 0,
        size: paginationConstants.maxLimit
      }).then(function(response){
        vm.businessOutlets = response.data;
      },function(error){
      });
    }

    /**
     * [mapBuyerDeliveryConditionMapping Maps buyer and delivery condition]
     */
    function mapBuyerDeliveryConditionMapping() {
      var mapping = [];
      _.map(vm.mapping.outletId,function(obj){
        mapping.push({
          'deliveryCondition':vm.mapping.deliveryCondition,
          'outletId':obj
        })
      });
      // Map selected buyer and deliverycondition
      SupplierBuyerDeliveryConditionMapService.mapBuyerDeliveryConditionMapping(mapping).then(function(response){
        if(response.length > 0) {
          vm.bulkUploadWarnings = response;
          toastr.warning('Completed with some errors!', 'Buyer - Delivery Condition Mapping');
        }
        else {
          NotificationService.success({
            title: 'Buyer - Delivery Condition',
            message: 'Mapped successfully'
          });
          vm.loadAll();
        }
      },function(error) {
        NotificationService.error({
          title: 'Buyer - Delivery Condition Mapping',
          error: error
        });
      });
    }

    /**
     * [unMapBuyerDeliveryConditionMapping Unmaps the delivery condition from buyer]
     * @param {Object} mapping 
     */
    function unMapBuyerDeliveryConditionMapping(mapping) {
      // Unap selected buyer and delievry conditions
      SupplierBuyerDeliveryConditionMapService.unMapBuyerDeliveryConditionMapping([mapping.id]).then(function(response){
        NotificationService.success({
          title: 'Buyer - Delivery Condition Mapping',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      },function(error) {
        NotificationService.error({
          title: 'Buyer - Delivery Condition Mapping Deletion',
          error: error
        });
      });
    }

    /**
     * [clearBulkUploadWarnings Clears the bulk upload errors]
     */
    function clearBulkUploadWarnings() {
      vm.bulkUploadWarnings = null;
    }

    vm.loadAll();
  }
})();
