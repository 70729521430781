/*
 * @Author: Jayalekshmi
 * @Date: 2018-08-02 16:44:37
 * File: transaction-summary-report.details.controller.js
 * @Last Modified by: Lovin
 * @Last Modified time: 2019-03-06 10:02:19
 */

(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('TransactionPurchaseOrderDetailsController', TransactionPurchaseOrderDetailsController);

  TransactionPurchaseOrderDetailsController.$inject = ['$scope', '$rootScope', '$filter', '$state', '$stateParams', 'PurchaseOrderService', 'NotificationService', 'EXPORT_CONSTANTS', 'CSVExportService', 'PDFExportService', 'Auth', 'GLOBAL_CONSTANTS'];

  function TransactionPurchaseOrderDetailsController($scope, $rootScope, $filter, $state, $stateParams, PurchaseOrderService, NotificationService, EXPORT_CONSTANTS, CSVExportService, PDFExportService, Auth, GLOBAL_CONSTANTS) {
    var vm = this;
    vm.init = init;
    vm.userRole = Auth.getUserRole();
    vm.poNumber = $stateParams.poNumber;
    vm.crNumber = $stateParams.crNumber;
    vm.previewType = $stateParams.type;
    vm.sumRequired = sumRequired;
    vm.sumSupplier = sumSupplier;
    vm.purchaseOrderHeader = EXPORT_CONSTANTS.CSV.purchaseOrderHeader;
    vm.getPurchaseOrderCSVData = getPurchaseOrderCSVData;
    vm.getIndividualPurchaseOrderPDF = getIndividualPurchaseOrderPDF;
    vm.deliveryDate = $stateParams.deliveryDate ? $stateParams.deliveryDate : '';
    vm.deliveryDate = vm.deliveryDate? new Date($stateParams.deliveryDate) : '';
    vm.groupPODetailsByDate = groupPODetailsByDate;
    vm.formatPrice = formatPrice;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.defaultDateTimeFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_TIME_FORMAT;

    /**
     * [init loads initilally]
     */
    function init(){
      loadAll();
    }

    /**
     * [loadAll Gets Purchase Order Details by PO number]
     */
     function loadAll() {
        if(vm.crNumber){
            PurchaseOrderService.getPurchaseOrderByPaymentId(vm.crNumber, {
                deliveryDate: !vm.deliveryDate ? '' : vm.deliveryDate,
              }).then(function(response){
                  console.log(response)
                vm.poDetails = response.data;
                vm.poDetailGroup = vm.groupPODetailsByDate(vm.poDetails.items);
              },function(error){
                NotificationService.error({
                  title: 'Purchase Order',
                  error: error
                });
              });
        } else {
            PurchaseOrderService.getPurchaseOrderByPurchaseOrderNumber(vm.poNumber, {
              deliveryDate: !vm.deliveryDate ? '' : vm.deliveryDate,
            }).then(function(response){
              vm.poDetails = response.data;
              vm.poDetailGroup = vm.groupPODetailsByDate(vm.poDetails.items);
            },function(error){
              NotificationService.error({
                title: 'Purchase Order',
                error: error
              });
            });
        }
     }
    /**
     * [groupPODetailsByDate description]
     * @param  {[objectArray]} itemsData [PODetails to be grouped according to delivery date]
     * @return {[objectArray]}           [description]
     */
     function groupPODetailsByDate(itemsData){
       var poDetailsGroup= _.groupBy(itemsData, function(purchaseOrder){
         if(purchaseOrder.supplierResponses)
         var deliveryDate = moment(purchaseOrder.supplierResponses.deliveryDate).format('DD-MM-YYYY');
         else
         var deliveryDate = moment(purchaseOrder.buyerRequirement.deliveryDate).format('DD-MM-YYYY');
         return deliveryDate;
       });
       var orderDetailsGroup = _.map(poDetailsGroup, function(purchaseOrder, key){
         purchaseOrder['sumRequired'] = vm.sumRequired(purchaseOrder);
         purchaseOrder['sumSupplier'] = vm.sumSupplier(purchaseOrder);
         purchaseOrder['deliveryDate'] = purchaseOrder[0].supplierResponses? purchaseOrder[0].supplierResponses.deliveryDate : purchaseOrder[0].buyerRequirement.deliveryDate;
         return purchaseOrder;
       });
       var purchaseOrders = _.values(orderDetailsGroup);
       return purchaseOrders;
     }

    /**
     * [sumRequired Grand total amount of buyer Requirement]
     * @param  {[object]} poItems [PO Details]
     * @return {[number]}         [total amount]
     */
    function sumRequired(poItems) {
      var totalAmount = 0;
      _.forEach(poItems, function(item) {
        if(item.buyerRequirement.items.uom.weightRequired === false && !item.buyerRequirement.freeItem) {
          if(item.buyerRequirement.items.uom.moqPrice === true)
          {
            totalAmount += Math.round(((item.buyerRequirement.quantity/item.buyerRequirement.items.moq) * item.buyerRequirement.price)*1000)/1000;
          }
          else
          {
            totalAmount += Math.round((item.buyerRequirement.quantity * item.buyerRequirement.price)*1000)/1000;
          }
        }
      });
      return totalAmount;
    }

    /**
     * [sumSupplier Grand total amount of supplier response]
     * @param  {[object]} poItems [PO Details]
     * @return {[number]}         [total amount]
     */
    function sumSupplier(poItems) {
      var totalAmount = 0;
      _.forEach(poItems, function(item) {
        if (!item.buyerRequirement.freeItem) {
          if(item.buyerRequirement.items.uom.weightRequired === false) {
            if(item.supplierResponses === null) {
              if(item.buyerRequirement.items.uom.moqPrice === true)
              {
                totalAmount += Math.round(((item.buyerRequirement.quantity/item.buyerRequirement.items.moq) * item.buyerRequirement.price)*1000)/1000;
              }
              else
              {
                totalAmount += Math.round((item.buyerRequirement.quantity * item.buyerRequirement.price)*1000)/1000;
              }
            }
            else {
              if(item.buyerRequirement.items.uom.moqPrice === true)
              {
                totalAmount += Math.round(((item.supplierResponses.quantity/item.buyerRequirement.items.moq) * item.buyerRequirement.price)*1000)/1000;
              }
              else
              {
                totalAmount += Math.round((item.supplierResponses.quantity * item.buyerRequirement.price)*1000)/1000;
              }
            }
          }
          else {
            if(item.supplierResponses === null) {
              if(item.buyerRequirement.items.uom.moqPrice === true)
              {
                totalAmount += Math.round(((item.buyerRequirement.weight/item.buyerRequirement.items.moq) * item.buyerRequirement.price)*1000)/1000;
              }
              else
              {
                totalAmount += Math.round(((item.buyerRequirement.weight * item.buyerRequirement.price)*1000))/1000;
              }
            }
            else {
              if(item.buyerRequirement.items.uom.moqPrice === true)
              {
                totalAmount += Math.round(((item.supplierResponses.weight/item.buyerRequirement.items.moq) * item.buyerRequirement.price)*1000)/1000;
              }
              else
              {
                totalAmount += Math.round((item.supplierResponses.weight * item.buyerRequirement.price)*1000)/1000;
              }
            }
          }
        }
      });
      return totalAmount;
    }

    function getPurchaseOrderCSVData() {
      return CSVExportService.getPurchaseOrderCSV(vm.poDetails);
    }

    function downloadURI(uri, name)
    {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = name;
      link.href = uri;
      link.click();
    }

    function getIndividualPurchaseOrderPDF() {
      PDFExportService.getPurchaseOrderPDF(vm.poNumber)
      .then(function(response){
        downloadURI(response.data.message, "PDF_" + vm.poNumber);
      },function(error) {
        NotificationService.error({
          title: 'Individual PO PDF Export',
          error: error
        });
      });
    }

    //round off price to two decimal places
    function formatPrice(amount){
      return (Math.round((amount)*1000)/1000);
    }
  }
})();
