/**
* @Author: Fenn Saji 
* @Date:   27-Feb-2020
* @Project: Ezyprocure
* @Filename: add-card.state.js
*/



(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('buyer.listcards', {
      parent: 'buyer',
      url: '/cards/list/:type',
      data: {
      },
      params: {
        userId: undefined,
        params: {},
        fromPage: undefined
      },
      views: {
        'content@': {
          templateUrl: 'app/common-modules/plans/list-cards/list-cards.html',
          controller: 'ListCardsMpgsAccountController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('register');
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }

    });
  }
})();
