/*
 * @Author: Lovin C 
 * @Date: 2021-07-29 15:30:03 
 * @Last Modified by: Lovin C
 * @Last Modified time: 2021-09-01 20:08:25
 */


(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('EzypaymentCollectionsDetailsController', EzypaymentCollectionsDetailsController);

  EzypaymentCollectionsDetailsController.$inject = ['paginationConstants', '$state', '$q', '$timeout', '$mdDialog', 'GLOBAL_CONSTANTS', 'EzypaymentCollectionsService', 'NotificationService', '$stateParams', 'CardsService', 'toastr', 'EzypaymentInitiatePaymentService'];

  function EzypaymentCollectionsDetailsController(paginationConstants, $state, $q, $timeout, $mdDialog, GLOBAL_CONSTANTS, EzypaymentCollectionsService, NotificationService, $stateParams, CardsService, toastr, EzypaymentInitiatePaymentService) {

    var vm = this;
    vm.init = init;
    vm.filter = {};
    vm.getDuePaymentSummary = getDuePaymentSummary;
    vm.confirmAndPay = confirmAndPay;
    vm.loadTransactionReport = loadTransactionReport;
    vm.calculateSum = calculateSum;
    vm.duePaymentSummaryDetails = [];
    vm.cardTokenByBrands = cardTokenByBrands;
    vm.getBrandIds = getBrandIds;
    vm.brandIds = [];
    vm.tokenCards = [];
    vm.getExternalDuePaymentSummary = getExternalDuePaymentSummary;
    vm.selectedInvoiceIdsList = $stateParams.selectedInvoiceIds;
    var selectedInvoiceIds = EzypaymentCollectionsService.getSelectedInvoiceIds();
    vm.type = $stateParams.type;
    vm.ocbcPaymentType = $stateParams.ocbcPaymentType;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;

    // 2FA changes
    vm.otp = undefined;
    vm.isOtpValid = true;
    vm.counterMilliSeconds = undefined;
    vm.counterSeconds = undefined;
    vm.counterMinutes = undefined;
    vm.validateOTP = validateOTP;
    vm.resendOTP = resendOTP;

    function init() {
      if (vm.type == 'EXTERNALSIP') {
        vm.getExternalDuePaymentSummary();
      } else {
        vm.getDuePaymentSummary();
      }
    }

    function stopCounter() {
      vm.isOtpValid = false;
      $timeout.cancel(vm.stopped);
    }
    
    function showOtpPrompt() {
      // clear otp
      vm.otp = undefined;
      vm.isOtpValid = true;
      vm.counterSeconds = 0;
      vm.counterMinutes = 0;
      vm.counterMilliSeconds = GLOBAL_CONSTANTS.OTP_EXPIRY_MINUTES * 60 * 1000;
      counter();
      // show prompt
      $mdDialog.show({
        templateUrl: 'app/core/common-templates/otp-templates/otp-capture.tmpl.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    // timer for otp resend
    function counter() {
      vm.stopped = $timeout(function () {
        vm.counterMilliSeconds -= 1000;
        if (vm.counterMilliSeconds) {
          var seconds = vm.counterMilliSeconds / 1000;
          vm.counterMinutes = Math.floor(seconds / 60);
          if (vm.counterMinutes > 0) {
            vm.counterSeconds = seconds - (vm.counterMinutes * 60);
          }
          else {
            vm.counterSeconds = seconds;
          }
          counter();
        }
        else {
          vm.counterSeconds = 0;
          vm.counterMinutes = 0;
          stopCounter();
        }
      }, 1000);
    };

    function getExternalDuePaymentSummary() {
      EzypaymentCollectionsService.getExternalDuePaymentSummary({
        invoiceIdList: String(vm.selectedInvoiceIdsList),
        hasPaymentApproval: false,
        paymentType: vm.type
      }).then(function (response) {
        vm.duePaymentSummaryDetails = response.data;
        vm.getBrandIds(response.data);
      }, function (error) {
        NotificationService.error({
          title: 'Fetching External Due Payment Summary',
          error: error
        });
      });
    }

    function validateOTP() {
      if (!vm.otp) {
        toastr.error('Invalid OTP!', 'Please check the OTP entered');
        return;
      }

      if (vm.otp && (vm.otp.length < 5 || vm.otp.length > 8)) {
        toastr.error('Invalid OTP!', 'Please check the OTP entered');
        return;
      }
      confirmAndPay(vm.otp);
    }

    function resendOTP() {
      confirmAndPay();
    }

    /**
      * [calculateSum shows the sum of key in list]
     */
    function calculateSum(duePaymentSummaryDetailsList, key) {
      var sum = 0;
      if (duePaymentSummaryDetailsList && duePaymentSummaryDetailsList.length > 0) {
        duePaymentSummaryDetailsList.forEach(function (invoice) {
          sum += Number(Number(invoice[key]).toFixed(2));
        });
      }
      return sum;
    }

    function getBrandIds(params) {
      _.filter(params, function (poList) {
        if (vm.brandIds.indexOf(poList.brandId) === -1) {
          vm.brandIds.push(poList.brandId);
        }
      });
    }

    function cardTokenByBrands() {
      CardsService.getAllCards({
        brandIds: vm.brandIds,
        page: 0,
        size: paginationConstants.maxLimit
      }).then(function (response) {
        vm.tokenCards = response.data;
      }, function (error) {
        NotificationService.error({
          title: 'Fetching Token By Cards',
          error: error
        });
      });
    }


    function getDuePaymentSummary() {
      if (!vm.selectedInvoiceIdsList) {
        NotificationService.simpleErrorToast({
          title: 'global.fetchingInvoiceDetails',
          message: 'global.pleaseSelectInvoiceToPay'
        });
        $state.go('ezypayment.supplier.collections');
        return false;
      }
      EzypaymentCollectionsService.getDuePaymentSummary({
        purchaseOrderIdList: String(vm.selectedInvoiceIdsList),
        hasPaymentApproval: false,
        approvalSeqNo: '',
        hasOcrAccess: false,
        paymentType: vm.type
      }).then(function (response) {
        vm.duePaymentSummaryDetails = response.data;
      }, function (error) {
        NotificationService.error({
          title: 'Fetching Due Payment Summary',
          error: error
        });
      });
    }

    function confirmAndPay(otp) {
      EzypaymentCollectionsService.confirmPayment(
        {
          hasPaymentApproval: false,
          approvalSeqNo: '',
          paymentType: vm.type,
          ocbcPaymentType: vm.ocbcPaymentType
        },
        vm.duePaymentSummaryDetails, otp).then(function (response) {
          if (response.headers('authorization-otp') == 'OTP_VERIFICATION_REQUIRED') {
            showOtpPrompt();
          }
          else if (GLOBAL_CONSTANTS.OTP_EXCEPTIONS.includes(response.headers('authorization-otp'))) {
            toastr.error('Invalid OTP', 'AUTHENTICATION FAILED');
          }
          else {
            NotificationService.success({
              title: 'Processed',
              message: 'Payment For Invoices'
            });
            $mdDialog.hide();
            vm.paymentResponse = response;
            EzypaymentCollectionsService.setPaymentResponse(vm.paymentResponse);
            $state.go('ezypayment.supplier.collections.details.transaction-report');
          }
        }, function (error) {
          if (error.status === 524) {
            error.data = { message: 'Your payments are processing. Please wait for few minutes and check.' };
            NotificationService.error({
              title: 'Payment For Invoices',
              error: error
            });
          } else {
            NotificationService.error({
              title: 'Payment For Invoices',
              error: error
            });
          }
        });
    }

    function loadTransactionReport() {
      vm.paymentResponse = EzypaymentCollectionsService.getPaymentResponse();
      vm.duePaymentSummaryDetails = vm.paymentResponse.data.paymentDTOList;
    }

  }
})();
