/*
 * @Author: Lovin C 
 * @Date: 2021-07-29 15:29:55 
 * @Last Modified by: Lovin C
 * @Last Modified time: 2021-09-01 19:56:51
 */



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('EzypaymentCollectionsService', EzypaymentCollectionsService);

  EzypaymentCollectionsService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function EzypaymentCollectionsService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS, CardsService) {

    var EzypaymentCollectionsService = {
      getInitiatePayment: getInitiatePayment,
      setSelectedInvoiceIds: setSelectedInvoiceIds,
      getSelectedInvoiceIds: getSelectedInvoiceIds,
      getDuePaymentSummary: getDuePaymentSummary,
      getOdbcDuePaymentSummary: getOdbcDuePaymentSummary,
      getOdbcExternalDuePaymentSummary: getOdbcExternalDuePaymentSummary,
      confirmPayment: confirmPayment,
      odbcConfirmPayment: odbcConfirmPayment,
      setPaymentResponse: setPaymentResponse,
      getPaymentResponse: getPaymentResponse,
      getExternalDuePaymentSummary:getExternalDuePaymentSummary,
      sipRequest: sipRequest,
      sendForBuyerApproval: sendForBuyerApproval
    };

    var selectedInvoiceIds = null;
    var paymentResponse = null;
    return EzypaymentCollectionsService;

    function sendForBuyerApproval(invoiceIdList) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'invoice-approval-requests/external',
        method: "POST",
        data: invoiceIdList,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getInitiatePayment(params){
      var deferred = $q.defer();

      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'mpgs/due-payment-summary/',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });

      return deferred.promise;
    }

    function getOdbcDuePaymentSummary(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'cybersource/due-payment-summary/',
        method: "GET",
        params: params,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getOdbcExternalDuePaymentSummary(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'cybersource/external-payments-invoices/due-payment-summary/',
        method: "GET",
        params: params,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function setSelectedInvoiceIds(params){
      selectedInvoiceIds = params;
    }

    function getSelectedInvoiceIds(){
      return  selectedInvoiceIds;
    }

    function getDuePaymentSummary(params){
      var deferred = $q.defer();

      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'ocbc/fast/collection/due-payment-summary',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });



      return deferred.promise;
    }

    function confirmPayment(params, duePaymentSummaryDetails, otp) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'ocbc/fast/transaction/collection',
        method: "POST",
        data: duePaymentSummaryDetails,
        params: params,
        headers: { 'authorization-otp': otp }
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function odbcConfirmPayment(params, duePaymentSummaryDetails, otp) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'cybersource/payment',
        method: "POST",
        data: duePaymentSummaryDetails,
        params: params,
        headers: { 'authorization-otp': otp }
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getExternalDuePaymentSummary(params){
          var deferred = $q.defer();

          $http({
            url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'ocbc/external-payments-invoices/due-payment-summary',
            method: "GET",
            params: params,
          }).then(function(data) {
            deferred.resolve(data);
          }).catch(function(message) {
            deferred.reject(message);
          });

          return deferred.promise;
        }

    function setPaymentResponse(params){
      paymentResponse = params;
    }

    function getPaymentResponse(){
      return paymentResponse ? paymentResponse : null;
    }

    /**
     * [updateExportedInvoiceStatus update exported invoice statuses]
     * @param  {[type]} params        [report type]
     * @param  {[type]} invoiceIdList [invoice id list from exported data obtained from API]
     * @return {[type]}               [description]
     */
        function sipRequest(invoiceIdList){
          var deferred = $q.defer();
          $http({
            url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'invoice-approval-requests',
            method: "POST",
            data: invoiceIdList,
          }).then(function(data) {
            deferred.resolve(data);
          }).catch(function(message) {
            deferred.reject(message);
          });
          return deferred.promise;
        }

  }
})();
