/*
 * @Author: Lovin 
 * @Date: 2018-11-13 18:57:42 
 * @Last Modified by: Lovin Chacko
 * @Last Modified time: 2020-06-05 00:07:58
 */
(function () {
    'use strict';

    angular
        .module('ezyprocureApp')
        .controller('GlMappingAddController', GlMappingAddController);

    GlMappingAddController.$inject = ['$scope', '$rootScope', '$state', 'toastr', '$mdDialog', 'GlMappingService', 'pagingParams', 'PurchaseOrderTypeService', 'GlSpecificItemGroupService', 'GlCodeService', 'ItemGroupService', 'paginationConstants', 'NotificationService'];

    function GlMappingAddController($scope, $rootScope, $state, toastr, $mdDialog, GlMappingService, pagingParams, PurchaseOrderTypeService, GlSpecificItemGroupService, GlCodeService, ItemGroupService, paginationConstants, NotificationService) {
        var vm = this;
        vm.init = init;
        vm.initializeMapper = initializeMapper;
        vm.loadPurchaseOrderTypes = loadPurchaseOrderTypes;
        vm.loadItemGroups = loadItemGroups;
        vm.loadGlSpecificItemGroups = loadGlSpecificItemGroups;
        vm.loadGlCodes = loadGlCodes;
        vm.showAddGlCode = showAddGlCode;
        vm.addGlCode = addGlCode;
        vm.showAddGlSpecificItemGroup = showAddGlSpecificItemGroup;
        vm.addGlSpecificItemGroup = addGlSpecificItemGroup;
        vm.cancel = cancel;
        vm.createGlMapping = createGlMapping;
        vm.isGlMapped = isGlMapped;
        vm.resetForm=resetForm;
    
        function init() {
         vm.initializeMapper();
         vm.loadPurchaseOrderTypes();
         vm.loadItemGroups();
         vm.loadGlSpecificItemGroups();
         vm.loadGlCodes();
        }

        vm.init();

        function initializeMapper() {
         vm.glMapping = {
          purchaseOrderTypeId: {},
          itemGroupIds: {},
          glCodeId: {},
          glSpecificItemGroupId: {}
         };
        }

        /**
         * loadPurchaseOrderTypes gets all the Purchase Order Types
         */
        function loadPurchaseOrderTypes() {
          PurchaseOrderTypeService.getAllPurchaseOrderTypes({
              page: 0,
              size: paginationConstants.maxLimit
          }).then(function (response) {
              vm.purchaseOrderTypes = response.data;
          }, function (error) {
          });
        }

        /**
         * loadItemGroups gets all Item Groups 
         */
        function loadItemGroups() {
          ItemGroupService.getAllItemGroups({
              page: 0,
              size: paginationConstants.maxLimit,
              query: ''
          }).then(function (response) {
              vm.itemGroups = response.data;
          }, function (error) {
         });
        }

        /**
         * loadGlSpecificItemGroups gets all Gl Specific Item Groups
         */
        function loadGlSpecificItemGroups() {
          GlSpecificItemGroupService.getAllGlSpecificItemGroups({
            page: 0,
            size: paginationConstants.maxLimit,
            query: ''
          }).then(function (response) {
            vm.glSpecificItemGroups = response.data;
          }, function (error) {
          });
      }

      /**
       * loadGlCodes gets all Gl-Codes
       */
        function loadGlCodes() {
          GlCodeService.getAllGlCodes({
            page: 0,
            size: paginationConstants.maxLimit,
            query: ''
          }).then(function (response) {
            vm.glCodes = response.data;
          }, function (error) {
         });
        }

        //Add GL Code Starts here
        /**
         * Show modal to add Gl Code
         * @param {*} event 
         */
        function showAddGlCode(event) {
          vm.newGlCode = {};
          $mdDialog.show({
            templateUrl: 'app/buyer-modules/gl-module/gl-codes/buyer.gl-code.add.tmpl.html',
            controller: function () {
              return vm;
            },
            controllerAs: 'vm',
            targetEvent: event,
            clickOutsideToClose: true
          });
        }

        /**
         * addGlCode adds new GL-code
         * @param {*} newGlCode 
         */
        function addGlCode(newGlCode) {
          GlCodeService.createGlCode(newGlCode).then(function (response) {
            $scope.$emit('ezyprocureApp:glCodeUpdate', response);
            vm.cancel();
            vm.glCodes.push(response);
            vm.glMapping.glCodeId = response.id;
            vm.isGlMapped(vm.glMapping);
          }, function (error) {
            NotificationService.error({
                title: 'GL Code Creation',
                error: error
            });
          });
        }

        //Add GlCode Ends here

        //Add Gl Specific Item Group Starts here
        /**
         * Show modal to add Gl Specific Item Group
         * @param {*} event 
         */
        function showAddGlSpecificItemGroup(event) {
          vm.newGlSpecificItemGroup = {};
          $mdDialog.show({
            templateUrl: 'app/buyer-modules/gl-module/gl-specific-item-groups/buyer.gl-specific-item-group.add.tmpl.html',
            controller: function () {
              return vm;
            },
            controllerAs: 'vm',
            targetEvent: event,
            clickOutsideToClose: true
          });
        }
        /**
         * addGlSpecificItemGroup adds new Gl Specific Item Group
         * @param {*} newGlSpecificItemGroup 
         */
        function addGlSpecificItemGroup(newGlSpecificItemGroup) {
          GlSpecificItemGroupService.createGlSpecificItemGroup(newGlSpecificItemGroup).then(function (response) {
            $scope.$emit('ezyprocureApp:glSpecificItemGroupUpdate', response);
            vm.cancel();
            vm.glSpecificItemGroups.push(response);
            vm.glMapping.glSpecificItemGroupId = response.id;
            vm.isGlMapped(vm.glMapping);
          },function (error) {
            NotificationService.error({
                title: 'GL Specific Item Group Creation',
                error: error
            });
          });
        }

        function cancel() {
            $mdDialog.cancel();
        }

        //Add GlSpecificItemGroup Ends here

        /**
         * isGlMapped returns true when the Gl Mapping exists 
         * @param {*} glMapping 
         */
        function isGlMapped(glMapping) {
          if (!glMapping.purchaseOrderTypeId || !glMapping.itemGroupIds || !glMapping.glCodesId || !glMapping.glSpecificItemGroupsId)
            return false;
          GlMappingService.isGlMapped({
            purchaseOrderTypeId: vm.glMapping.purchaseOrderTypeId,
            glSpecificItemGroupId: vm.glMapping.glSpecificItemGroupsId,
            itemGroupIds: vm.glMapping.itemGroupIds,
            glCodeId: vm.glMapping.glCodesId
          }).then(function (response) {
            vm.isAlreadyMapped = response;
          }, function (error) {
            NotificationService.error({
              title: 'GL Mapping isGlMapped',
              error: error
            });
          });
        }

        /**
         * createGlMapping adds new gl-code mapping
         */
        function createGlMapping() {
          GlMappingService.createGLMapping(vm.glMapping).then(function (response) {
            $scope.$emit('ezyprocureApp:bsMapped', vm.glMapping);
            NotificationService.success({
                title: 'GL Mapping',
                message: 'Mapped successfully'
            });
            vm.resetForm();
          },function (error) {
            vm.translatedError = error.data;
            NotificationService.error({
              title: 'GL Mapping Creation',
              error: error
            });
          });
        }
        
        function resetForm(){
          vm.glMapping={};
          $scope.glMappingForm.$setPristine();
          $scope.glMappingForm.$setUntouched();
        }
    }
})();