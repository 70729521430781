/**
 * @Author: sneha
 * @Date:   2019-10-28T11:17:17+05:30
 * @Project: Ezyprocure
 * @Filename: logout.service.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-10-28T11:18:34+05:30
 */


 (function() {
   'use strict';

   angular
   .module('ezyprocureApp')
   .factory('Logout', Logout);

   Logout.$inject = ['$resource', 'API_CONSTANTS'];

   function Logout($resource, API_CONSTANTS) {
     var service = $resource('api/' + API_CONSTANTS.baseVersion + 'logout', {}, {});

     return service;
   }
 })();
