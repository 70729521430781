/*
 * @Author: Ajay 
 * @Date: 2019-02-18 09:00:38  
 * @Project: Ezyprocure
 * @Filename: ezyap.buyer.dashboard.controller.js
 * @Last Modified by: Ajay
 * @Last Modified time: 2019-02-28 16:13:07
 */

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('EzyapBuyerDashboardController', EzyapBuyerDashboardController);

  EzyapBuyerDashboardController.$inject = ['NotificationService', 'GLOBAL_CONSTANTS', 'EzyapDashboardService', 'BuyerAccountService'];

  function EzyapBuyerDashboardController(NotificationService, GLOBAL_CONSTANTS, EzyapDashboardService, BuyerAccountService) {

    var vm = this;
    vm.showUobBanner = GLOBAL_CONSTANTS.SHOW_UOB_BANNER;
    vm.getPendingInvoicesCount = getPendingInvoicesCount;
    vm.refreshInterval = GLOBAL_CONSTANTS.DASHBOARD_REFRESH_INTERVAL;

    getBuyerProfile();

    /**
      *[get the count of pending invoices  for buyer]
      */
    function getPendingInvoicesCount() {
      EzyapDashboardService.getPendingInvoicesCount().
        then(function (response) {
          vm.invoiceCount = response.data;
        }, function (error) {
          NotificationService.error({
            title: 'global.navbar.ezyapBuyerDashboard',
            error: error
          });
        });
    }

    function getBuyerProfile() {
      BuyerAccountService.getBuyerProfile().
        then(function (response) {
          if (response) {
            vm.buyerProfile = response;
          }
        }, function (error) {
        });
    }
  }
})();
