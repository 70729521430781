/**
* @Author: Ajay
* @Date:   20-Mar-2017 12:11 +05:30
* @Project: Ezyprocure
* @Filename: supplier.price-update-history.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 14-Aug-2017 12:32 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('supplier.reports.price-update-history', {
      parent: 'supplier.reports',
      url: '/price-update-history',
      data: {
        authorities: ['ROLE_SUPPLIER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/reports/price-update-history/supplier.price-update-history.html',
          controller: 'SupplierPriceUpdateHistoryController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,desc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('priceUpdates');
          $translatePartialLoader.addPart('priceUpdateStatus');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('user-management');
          $translatePartialLoader.addPart('brand');
          $translatePartialLoader.addPart('purchaseOrder');
          return $translate.refresh();
        }]
      }
    });

  }

})();
