/**
* @Author: Ajay
* @Date:   12-Jan-2017 16:15 +05:30
* @Project: Ezyprocure
* @Filename: buyer.account.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-11-04T13:53:46+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerAccountController', BuyerAccountController);

  BuyerAccountController.$inject = ['$scope', '$rootScope', '$state', 'BuyerDataService', 'BuyerAccountService', 'ImageUploadService', 'Auth', 'GLOBAL_CONSTANTS', 'NotificationService','EzyCompanyService', 'Principal', '$window', 'API_CONSTANTS','NotificationSettingsService'];

  function BuyerAccountController($scope, $rootScope, $state, BuyerDataService, BuyerAccountService, ImageUploadService, Auth, GLOBAL_CONSTANTS, NotificationService, EzyCompanyService, Principal, $window, API_CONSTANTS,NotificationSettingsService) {

    var vm = this;
    vm.getBuyerAccount = getBuyerAccount;
    vm.update = update;
    vm.changePassword = changePassword;
    vm.setCompanyLogo = setCompanyLogo;
    vm.uploadLogo = uploadLogo;
    vm.isAdminBuyer = BuyerDataService.isAdminBuyer();
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
    vm.getIdentifier = getIdentifier;
    vm.updateIdentifier = updateIdentifier;
    vm.createIdentifier = createIdentifier;
    vm.accessForPEPPOL = Auth.hasPermission("accessForPEPPOL");
    vm.init = init;
    vm.deleteIsoCodeAndIdentifier = deleteIsoCodeAndIdentifier;
    vm.accessForEmailService = BuyerDataService.getBuyerProfile().accessDTO.accessForEmailService;
    vm.getNotificationSettings = getNotificationSettings;

    vm.init();

    function init(){
      if(BuyerDataService.getBuyerProfile().accessDTO.accessForPEPPOL){
       vm.getIdentifier();
      }
    }

    function getBuyerAccount() {
      BuyerAccountService.getBuyerAccount().
      then(function(response) {
        if (response) {
          vm.buyerAccount = response;
          getNotificationSettings(vm.buyerAccount.user.id);
        }
      }, function(error) {
      });
    }

    function update() {
      if(BuyerDataService.getBuyerProfile().accessDTO.accessForPEPPOL && vm.ezyCompanyDetails){
        if(vm.ezyCompanyDetailsId){
        vm.ezyCompanyDetails.isoCode == '' || vm.ezyCompanyDetails.identifier == '' ? vm.deleteIsoCodeAndIdentifier() : vm.updateIdentifier();
      }
      else{
         vm.createIdentifier();
        }
     }
      // Call Admin supplier service with supplier object as parameter to update
      BuyerAccountService.updateBuyerAccount(vm.buyerAccount).then(function(response) {
        $scope.$emit('ezyprocureApp:accountUpdated', response);
        NotificationService.success({
          title: 'Account Settings',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        $state.go('buyer.account.view', {
          reload: false
        });
      }, function(error) {
        NotificationService.error({
          title: 'Update Account',
          error: error
        });
      });
    }

    //delete iso code and identifier
    function deleteIsoCodeAndIdentifier(){
      EzyCompanyService.deleteIsoCodeAndIdentfier(vm.ezyCompanyDetails.id).then(function(response){
        NotificationService.success({
          title: 'global.IsoCodeAndIdentifier',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
      },
      function(error){});
    }

    function changePassword() {
      if(Principal.isCBEnabled()) {
        Auth.checkCbHealth()
        .then(function() {
          Auth.changePassword(vm.changePwd.newPassword).then(function(response) {
            $scope.$emit('ezyprocureApp:passwordChanged', response);
            NotificationService.success({
              title: 'Account Settings',
              message: 'Password Changed successfully'
            });
            Auth.clearToken();
-           clearCbToken();
          }, function(error) {
            NotificationService.error({
              title: 'Account Settings - Change Password',
              error: error
            });
          });
        }, function(error) {
          NotificationService.error({
            title: 'Account Settings - Change Password',
            error: error
          });
        })
      } else {
        Auth.changePassword(vm.changePwd.newPassword).then(function(response) {
          $scope.$emit('ezyprocureApp:passwordChanged', response);
          NotificationService.success({
            title: 'Account Settings',
            message: 'Password Changed successfully'
          });
          Auth.clearTokenAndRedirectToLogin();
        }, function(error) {
          NotificationService.error({
            title: 'Account Settings - Change Password',
            error: error
          });
        });
      }
    }


    function clearCbToken() {
      $window.location.href = API_CONSTANTS.cbBase + '#/autologin/delete/null/'
    }

    /**IMAGE UPLOAD**/

    function setCompanyLogo(flowFile) {
      ImageUploadService.getImageBase64(flowFile.file, function(base64Img) {
        vm.uploadLogo(base64Img);
      });
    }

    function uploadLogo(base64Img) {
      vm.uploadingInProgress = true;
      ImageUploadService.uploadImage(base64Img).
      then(function(responseData) {
        vm.buyerAccount.companyLogo = responseData.url;
        $scope.editBuyerAccountForm.$pristine = false;
        NotificationService.success({
          title: 'Account Settings - Logo Uploaded',
          message: 'Please update to save changes'
        });
        vm.uploadingInProgress = false;
      }, function(error) {
        vm.uploadingInProgress = false;
        NotificationService.error({
          title: 'Account Settings - Logo Upload',
          error: error
        });
      });
    }

    /**
     * [getIdentifier Gets the identifier details]
     */
    function getIdentifier() {
      EzyCompanyService.getIdentifier(vm.buyerId,'ROLE_BUYER').then(function(response) {
       vm.ezyCompanyDetails=response.data;
       vm.ezyCompanyDetailsId=vm.ezyCompanyDetails.id;
      }, function(error) {
        NotificationService.simpleErrorToast({
          title: 'Admin Buyer Updation',
          message: error.data.message
        });
      });
    }

    /**
     * [updateIdentifier Updates the identifier details]
     */
    function updateIdentifier() {
      EzyCompanyService.updateIdentifier(
        {ezpCompanyId: vm.ezyCompanyDetails.ezpCompanyId,
         identifier:vm.ezyCompanyDetails.identifier,
         isoCode:vm.ezyCompanyDetails.isoCode,
         userRole:'ROLE_BUYER',
         id:vm.ezyCompanyDetails.id}).then(function(response) {
      }, function(error) {
        NotificationService.simpleErrorToast({
          title: 'Admin Buyer Updation',
          message: error.data.message
        });
      });
    }

    /**
     * [createIdentifier Creates the identifier details]
     */
    function createIdentifier() {
      EzyCompanyService.createIdentifier(
        {ezpCompanyId:vm.buyerId,
         identifier:vm.ezyCompanyDetails.identifier,
         isoCode:vm.ezyCompanyDetails.isoCode,
         userRole:'ROLE_BUYER',
         id:''}).then(function(response) {
      }, function(error) {
        NotificationService.simpleErrorToast({
          title: 'Admin Buyer Updation',
          message: error.data.message
        });
      });
    }

    function getNotificationSettings(userId){
        NotificationSettingsService.getNotificationSettings(userId).
         then(function(response) {
           if (response) {
               vm.notificationSettingsAdd = response;
           }
         }, function(error) {
         });
    }


  }
})();
