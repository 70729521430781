/**
* @Author: Ajay
* @Date:   31-Jan-2017 10:45 +05:30
* @Project: Ezyprocure
* @Filename: supplier.items.mapping.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 29-Dec-2017 12:50 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider

    .state('supplier.items.mapping', {
      parent: 'supplier',
      url: '/mapping',
      data: {
        authorities: ['ROLE_SUPPLIER'],
        pageTitle: 'Item Mapping'
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/item-management/mapping/supplier.item.mapping.html',
          controller: 'SupplierItemMappingController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('itemBuyerMapping');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('brand');
          return $translate.refresh();
        }]
      }
    })
    .state('supplier.items.mapping.add', {
      parent: 'supplier.items.mapping',
      url: '/add',
      data: {
        authorities: ['ROLE_SUPPLIER'],
        pageTitle: 'Add new Item Mapping'
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/item-management/mapping/add/supplier.item.mapping.add.html',
          controller: 'SupplierItemMappingAddController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('itemBuyerMapping');
          $translatePartialLoader.addPart('items');
          return $translate.refresh();
        }]
      }
    })

    .state('supplier.items.mapping.upload', {
      parent: 'supplier.items.mapping',
      url: '/upload',
      data: {
        authorities: ['ROLE_SUPPLIER'],
        pageTitle: 'bulk Item Mapping'
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/item-management/mapping/upload/supplier.item.mapping.upload.html',
          controller: 'BulkItemMappingCsvUploadController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    });
  }
})();
