/**
* @Author: Ajay
* @Date:   14-Dec-2016 12:54 +05:30
* @Project: Ezyprocure
* @Filename: auth-expired.interceptor.js
* @Last modified by:   Ajay
* @Last modified time: 27-Mar-2017 18:57 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .factory('authExpiredInterceptor', authExpiredInterceptor);

  authExpiredInterceptor.$inject = ['$rootScope', '$q', '$injector', '$localStorage', '$sessionStorage', 'GLOBAL_CONSTANTS'];

  function authExpiredInterceptor($rootScope, $q, $injector, $localStorage, $sessionStorage, GLOBAL_CONSTANTS) {
    var service = {
      responseError: responseError
    };

    return service;

    function responseError(response) {
      // If unauthorized delete tokens, if any
      if (response.status == 401 && GLOBAL_CONSTANTS.OTP_EXCEPTIONS.includes(response.headers('authorization-otp'))) {
        return $q.resolve(response);
      }
      else if(response.status == 401 && response.data.status == 'OTP_INCORRECT') {
        return $q.reject(response);
      }
      else if(response.status === 401) {
        delete $localStorage.authenticationToken;
        delete $sessionStorage.authenticationToken;
        var Principal = $injector.get('Principal');
        if (Principal.isAuthenticated()) {
          var Auth = $injector.get('Auth');
          Auth.authorize(true);
        }
      }
      return $q.reject(response);
    }
  }
})();
