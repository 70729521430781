/*
 * @Author: Lovin Chacko 
 * @Date: 2021-01-29 12:20:43 
 * @Last Modified by: Lovin Chacko
 * @Last Modified time: 2021-02-02 18:22:10
 */
(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .directive('isAscii', isAscii);

  isAscii.$inject = [];

  function isAscii() {
    return {
      require: 'ngModel',
      link: linkfunction
    }

    function linkfunction($scope, element, attr, ngModel) {
      if (attr.isAscii == "true") {
        var ASCIILIMITED = [34, 35, 37, 38, 39, 43, 60, 62, 63];
        $scope.$watch(attr.ngModel, function (newValue) {
          if (newValue) {
            for (var i = 0; i < newValue.length; i++) {
              if (newValue.charCodeAt(i) > 127 || newValue.charCodeAt(i) < 32 || _.includes(ASCIILIMITED, newValue.charCodeAt(i))) {
                return ngModel.$setValidity('is-ascii', false);
              }
              ngModel.$setValidity('is-ascii', true);
            }
          }
        });
      }
    }
  }
})();
