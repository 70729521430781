/**
* @Author: Ajay
* @Date:   09-Mar-2017 14:58 +05:30
* @Project: Ezyprocure
* @Filename: supplier.items.price-update.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 29-Dec-2017 12:51 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider

    .state('supplier.items.price-update', {
      parent: 'supplier.items',
      url: '/price-update',
      data: {
        authorities: ['ROLE_SUPPLIER'],
        pageTitle: 'Item Price Update'
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/item-management/price-update/supplier.item.price-update.html',
          controller: 'SupplierPriceUpdateController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('itemBuyerMapping');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('itemType');
          $translatePartialLoader.addPart('brand');
          return $translate.refresh();
        }]
      }
    })
    .state('supplier.items.price-update.item-selection', {
      parent: 'supplier.items.price-update',
      url: '/price-update/item-selection',
      data: {
        authorities: ['ROLE_SUPPLIER'],
        pageTitle: 'Item Price Update'
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/item-management/price-update/item-selection/supplier.price-update.item-selection.html',
          controller: 'SupplierPriceUpdateItemSelectionController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('itemBuyerMapping');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('priceUpdates');
          return $translate.refresh();
        }]
      }
    });
  }
})();
