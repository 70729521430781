/**
 * @Author: Ajay
 * @Date:   15-Sep-2017 11:51 +05:30
 * @Filename: buyer.purchase-request.consolidated-details.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-06-19T11:41:25+05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerPurchaseRequestConsolidatedDetailsController', BuyerPurchaseRequestConsolidatedDetailsController);

  BuyerPurchaseRequestConsolidatedDetailsController.$inject = ['$scope', '$rootScope', '$filter', 'Auth', '$state', '$stateParams', '$mdDialog', 'PurchaseOrderService', 'UTCDateService', 'PDFExportService', 'EXPORT_CONSTANTS', 'CSVExportService', 'pagingParams', 'NotificationService', 'GLOBAL_CONFIG_CONSTANTS', 'GLOBAL_CONSTANTS'];

  function BuyerPurchaseRequestConsolidatedDetailsController($scope, $rootScope, $filter, Auth, $state, $stateParams, $mdDialog, PurchaseOrderService, UTCDateService, PDFExportService, EXPORT_CONSTANTS, CSVExportService, pagingParams, NotificationService, GLOBAL_CONFIG_CONSTANTS, GLOBAL_CONSTANTS) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.loadAll = loadAll;
    vm.fromDate = new Date($stateParams.fromDate);
    vm.toDate = new Date($stateParams.toDate);
    vm.prStatusClass = GLOBAL_CONFIG_CONSTANTS.PROCESS_ORDER_STATUS.LABEL_CLASS;
    vm.accessForPriceDisplay = Auth.hasPermission('accessForPriceDisplay');
    vm.inclusiveOfTax = GLOBAL_CONSTANTS.inclusiveOfTax;
    vm.purchaseOrderHeader = EXPORT_CONSTANTS.CSV.purchaseOrderHeader;
    vm.getConsolidatedPurchaseRequestCSVData = getConsolidatedPurchaseRequestCSVData;


    function loadAll() {
      PurchaseOrderService.getConsolidatedPRDetails({
        fromDate: !vm.fromDate ? '' : UTCDateService.utcFromDate(vm.fromDate),
        toDate: !vm.toDate ? '' : UTCDateService.utcToDate(vm.toDate),
        reportType: "PR",
        businessOutletId: $stateParams.businessOutletId,
        itemGroupId: $stateParams.itemGroupId,
        prNumber: $stateParams.prNumber,
        supplierId: $stateParams.supplierId,
        query: $stateParams.itemName,
        purchaseRequestStatus: $stateParams.prStatus
      }).then(function(response){
        vm.consolidatedPRDetails = response.data;
      },function(error){
        NotificationService.error({
          title: 'Fetching Consolidated PR Details',
          error: error
        });
      });
    }

    vm.loadAll();

    function getConsolidatedPurchaseRequestCSVData() {
        return CSVExportService.getConsolidatedPurchaseRequestCSV(vm.consolidatedPRDetails);
    }

  }
})();
