/*
 * @Author: Ajay 
 * @Date: 2019-02-11 11:37:07 
 * @Project: Ezyprocure
 * @Filename: ezyap.create-invoice.serice.js
 * @Last Modified by: Lovin Chacko
 * @Last Modified time: 2021-01-28 11:31:49
 */

(function () {
  'use strict';

  angular.module('ezyprocureApp').factory('CreateAdhocInvoiceService', CreateAdhocInvoiceService);

  CreateAdhocInvoiceService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function CreateAdhocInvoiceService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var CreateAdhocInvoiceService = {
      createAdhocInvoice: createAdhocInvoice,
      getAllUOMs: getAllUOMs,
      confirmAndSendAdhocInvoice: confirmAndSendAdhocInvoice,
      getBusinessUnit: getBusinessUnit,
      getPaymentTerms: getPaymentTerms
    };
    return CreateAdhocInvoiceService;

    function getPaymentTerms() {
      var deferred = $q.defer();
      $http.get(RESOURCE_URL_CONSTANTS.JSON_DATA + 'paymentTerms.json').
        then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
      return deferred.promise;
    }

    function getBusinessUnit() {
      var deferred = $q.defer();
      $http.get(RESOURCE_URL_CONSTANTS.JSON_DATA + 'businessUnit.json').
        then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
      return deferred.promise;
    }

    //create adhoc invoice manually
    function createAdhocInvoice(adhocInvoiceDTO) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/invoices/adhoc/',
        method: "PUT",
        data: adhocInvoiceDTO
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //get all peppol uoms
    function getAllUOMs(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + '/api/unit-codes',
        method: "GET",
        params: params
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    // create and send invoice
    function confirmAndSendAdhocInvoice(adhocInvoiceDTO) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/invoices/adhoc/upload',
        method: "PUT",
        data: adhocInvoiceDTO
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();