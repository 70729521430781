/**
* @Author: Abhay
* @Date:   28-April-2021 16:03 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.buyer.axs-pay-bills-fines.state.js
* @Last modified by:   Abhay
* @Last modified time: 28-April-2021 16:03 +05:30
*/

(function () {
    'use strict';

    angular
        .module('ezyprocureApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('ezypayment.buyer.axs-pay-bills', {
            parent: 'ezypayment.buyer',
            url: '/axs-pay-bills-fines',
            data: {
                authorities: ['ROLE_BUYER'],
                pageTitle: 'AXS Pay Bills/Fines'
            },
            views: {
                'content@': {
                    templateUrl: 'app/common-modules/ezypayment/axs-pay-bills-fine/ezypayment.axs-pay-bills-fines.html',
                    controller: 'AxsPayBillsFinesController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('ezypayment.buyer.axs-transaction-details', {
            parent: 'ezypayment.buyer.axs-pay-bills',
            url: '/axs-transaction-details/:transactionFlag/:referenceNumber',
            data: {
                authorities: ['ROLE_BUYER'],
                pageTitle: 'AXS Transaction Details'
            },
            views: {
                'content@': {
                    templateUrl: 'app/common-modules/ezypayment/axs-transaction-detail/ezypayment.axs-transaction-detail.html',
                    controller: 'AxsTransactionDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });
    }
})();