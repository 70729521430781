/**
 * @Author: lovinc
 * @Date:   2018-11-26T17:05:03+05:30
 * @Project: Ezyprocure
 * @Filename: supplier.item.item-termination.service.js
 * @Last modified by:   lovinc
 * @Last modified time: 2018-11-29T10:47:51+05:30
 */



(function () {
  'use strict';

  angular.module('ezyprocureApp').factory('SupplierItemTerminationService', SupplierItemTerminationService);

  SupplierItemTerminationService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function SupplierItemTerminationService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var SupplierItemTerminationService = {
      getAllBuyerOutletMappedItems: getAllBuyerOutletMappedItems,
      terminateItems: terminateItems,
      getAllBrandsByBuyerId: getAllBrandsByBuyerId
    };
    return SupplierItemTerminationService;
    /**
    * returns buyer and outlet mapped items
    * @param {integer} params
    */
    function getAllBuyerOutletMappedItems(params,data) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ITEM_BUSINESS_MAPPING + '/items',
        method: "POST",
        data: data,
        params: params
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * terminateItems terminates selected item with respect to the constraints passed
     * @param {object} params
     */
    function terminateItems(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'item-terminations/items',
        method: "POST",
        data: params
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * getAllBrandsByBuyerId gets all businessOutlets under a brand
    */
    function getAllBrandsByBuyerId(params,supplierId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYER_BUSINESS_RESOURCE + 'brand/outlets/?supplierId='+supplierId,
        method: "POST",
        data:params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
