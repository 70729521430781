/**
* @Author: Ajay
* @Date:   03-Mar-2017 15:12 +05:30
* @Project: Ezyprocure
* @Filename: buyer.waiting-supplier-reply.details.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-06-18T10:30:49+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerWaitingSupplierReplyDetailsController', BuyerWaitingSupplierReplyDetailsController);

  BuyerWaitingSupplierReplyDetailsController.$inject = ['$scope', '$state', '$stateParams', 'Auth', 'BuyerWaitingSupplierReplyService', '$filter', 'NotificationService', 'GLOBAL_CONSTANTS'];

  function BuyerWaitingSupplierReplyDetailsController($scope, $state, $stateParams, Auth, BuyerWaitingSupplierReplyService, $filter, NotificationService, GLOBAL_CONSTANTS) {

    var vm = this;
    vm.requirementNumber = $stateParams.requirementNumber;
    vm.loadAll = loadAll;
    vm.processOrderDateCompare = processOrderDateCompare;
    vm.cancelProcessOrder = cancelProcessOrder;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.accessForPriceDisplay = Auth.hasPermission('accessForPriceDisplay');

    vm.sum = sum;

    vm.loadAll();

    function processOrderDateCompare(tempDate){
      var temDate = $filter('date')(tempDate, 'dd/MM/yyyy');
      var todayDate = $filter('date')(new Date(), 'dd/MM/yyyy');
      if(temDate==todayDate){
        return true;
      }else{
        return false;
      }
    }
    //If the ProcessOrder-Cancel is Clicked
    function cancelProcessOrder(){
      BuyerWaitingSupplierReplyService.getCancel_PO(vm.requirementNumber).then(function(response) {
        NotificationService.success({
          title: 'global.navbar.purchaseRequest',
          message: 'alertMessage.CANCELLED_SUCCESSFULLY'
        });
        $state.go('buyer.waiting-supplier-reply', {
          reload: false
        });
      }, function(error) {
        NotificationService.error({
          title: 'This PR already accepted by Supplier, Cannot cancel now. Please do cancel PO instead',
          error: error
        });
      });
    }
    //Get Item Approval Request details by approval Request Number
    function loadAll() {
      BuyerWaitingSupplierReplyService.getPendingRequestByRequirementNumber(vm.requirementNumber).then(function(response) {
        vm.processOrderDetails = response;
        vm.totalAmount = sum(vm.processOrderDetails);
      }, function(error) {
        NotificationService.error({
          title: 'Pending Request Details Fetching',
          error: error
        });
      });

    }

    function sum(processOrderDetails) {
      var totalAmount = 0;
      _.forEach(processOrderDetails.processOrders, function(processOrder) {
        //skip calculation for free item
        if(!processOrder.freeItem) {
          totalAmount += processOrder.quantity * processOrder.price;
        }
      });
      return totalAmount;
    }
  }
})();
