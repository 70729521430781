/**
 * @Author: Ajay
 * @Date:   21-Apr-2017 17:54 +05:30
 * @Project: Ezyprocure
 * @Filename: admin.uoms.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 25-Jan-2018 14:56 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('UomsController', UomsController);

  UomsController.$inject = ['$scope', '$rootScope', '$state', '$mdDialog', 'UomService', 'pagingParams', 'paginationConstants', 'NotificationService'];

  function UomsController($scope, $rootScope, $state, $mdDialog, UomService, pagingParams, paginationConstants, NotificationService) {
    var vm = this;

    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.loadAll = loadAll;
    vm.showConfirm = showConfirm;
    vm.cancel = cancel;
    vm.showAddUom = showAddUom;
    vm.addUom = addUom;
    vm.showEditUom = showEditUom;
    vm.updateUom = updateUom;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    function sort() {
      var result = ['name' + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function loadAll(search) {
      if (!search) {
        vm.searchText = '';
      }
      UomService.getAllUoms({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.searchText
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.uoms = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'UOM Listing',
          error: error
        });
      });
    }

    vm.loadAll();

    function deleteUom(uomId) {
      UomService.deleteUom(uomId).then(function(response) {
        NotificationService.success({
          title: 'UOM',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'UOM Deletion',
          error: error
        });
      });
    }

    function showConfirm(ev, uom) {
      var confirm = $mdDialog.confirm()
      .title('Are you sure you want to delete Uom - ' + uom.name + ' ? ')
      .textContent('This action could not be reverted back.')
      .targetEvent(ev)
      .ok('Delete')
      .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        deleteUom(uom.id);
      }, function() {});
    }

    function cancel() {
      $mdDialog.cancel();
    }

    //Add UOM Starts here

    function showAddUom(event) {
      vm.newUom = {
        'name': '',
        'weightRequired': false
      };
      $mdDialog.show({
        templateUrl: 'app/admin-modules/uoms/admin.uom.add.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function addUom(newUom) {
      UomService.createUom(newUom).then(function(response) {
        $scope.$emit('ezyprocureApp:uomUpdate', response);
        vm.cancel();
        NotificationService.success({
          title: 'UOM',
          message: 'alertMessage.CREATED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'UOM Creation',
          error: error
        });
      });
    }

    //Add UOM Ends here


    //Edit UOM Starts here

    function showEditUom(event, oldUom) {
      if(oldUom.weightRequired === null)
        oldUom.weightRequired = false;
      vm.editUom = angular.copy(oldUom);
      $mdDialog.show({
        templateUrl: 'app/admin-modules/uoms/admin.uom.edit.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function updateUom(oldUom) {
      UomService.updateUom(oldUom).then(function(response) {
        $scope.$emit('ezyprocureApp:uomUpdate', response);
        vm.cancel();
        NotificationService.success({
          title: 'UOM',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'UOM Updation',
          error: error
        });
      });
    }

    //Edit UOM Ends here

  }


})();
