/**
* @Author: Ajay
* @Date:   03-Mar-2017 15:12 +05:30
* @Project: Ezyprocure
* @Filename: buyer.unable-meet-demands.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 11-Sep-2017 14:53 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BuyerUnableMeetDemandsService', BuyerUnableMeetDemandsService);

  BuyerUnableMeetDemandsService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function BuyerUnableMeetDemandsService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var BuyerUnableMeetDemandsService = {
      supplierUnableMeetDemandRequirements: supplierUnableMeetDemandRequirements,
      getProcessOrderByRequirementNumber: getProcessOrderByRequirementNumber,
      buyerReplyToSupplierResponse: buyerReplyToSupplierResponse,
      buyerDeleteProcessOrderItem: buyerDeleteProcessOrderItem,
      getProcessOrderByRequirementNumberAdhocPo: getProcessOrderByRequirementNumberAdhocPo
    };
    return BuyerUnableMeetDemandsService;

    //Get Supplier Unable Meet DemandList
    function supplierUnableMeetDemandRequirements(processOrderStatus, params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'waiting-supplier-reply/' + processOrderStatus,
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get Purchase Order Details By Requirement Number
    function getProcessOrderByRequirementNumber(requirementNumber) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PROCESS_ORDER_RESOURCE + requirementNumber + '/supplier-response').then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get Purchase Order Details By Requirement Number
    function getProcessOrderByRequirementNumberAdhocPo(requirementNumber) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'process-orders/' + requirementNumber + '/supplier-response-adhoc-po').then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //buyer Reply To Supplier Response - BUYER_APPROVED or BUYER_REJECTED
    function buyerReplyToSupplierResponse(processOrder) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PROCESS_ORDER_RESOURCE, processOrder).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Buyer Delete Process Order Item
    function buyerDeleteProcessOrderItem(requirementNumber, processOderId) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyer/process-order/requirementNumber/' + requirementNumber + '/' + processOderId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
