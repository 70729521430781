/*
 * @Author: Lovin 
 * @Date: 2019-06-21 11:50:55 
 * @Project: Ezyprocure
 * @Filename: admin.transaction-audit-log-report.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2020-02-21 10:48:45
 */


(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .controller('TransactionAuditLogController', TransactionAuditLogController);
  
    TransactionAuditLogController.$inject = ['$scope', '$rootScope', '$state', 'pagingParams', 'paginationConstants', 'NotificationService','$filter', 'TransactionAuditLogService', 'UTCDateService', 'GLOBAL_CONSTANTS'];
  
    function TransactionAuditLogController($scope, $rootScope, $state, pagingParams, paginationConstants, NotificationService, $filter, TransactionAuditLogService, UTCDateService, GLOBAL_CONSTANTS) {
      var vm = this;

      vm.todaysDate = new Date();
      vm.tomorrow = new Date();
      vm.tomorrow.setDate(vm.tomorrow.getDate() + 1);
      vm.tomorrow = UTCDateService.utcToDate(vm.tomorrow);
      vm.predicate = pagingParams.predicate;
      vm.reverse = pagingParams.ascending;
      vm.loadAll = loadAll;
      vm.defaultDateTimeFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
 
      vm.query = {
        limit: paginationConstants.itemsPerPage,
        page: 1
      };
      vm.limitOptions = paginationConstants.limitOptions;
      vm.downloadURI = downloadURI;
      
      function sort() {
        var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
        return result;
      }
      
    // Get all logs 
      function loadAll() {
        TransactionAuditLogService.getTransactionAuditlog({
          fromDate: vm.filter.fromDate? UTCDateService.utcFromDate(vm.filter.fromDate):'',
          toDate: vm.filter.toDate? UTCDateService.utcFromDate(vm.filter.toDate):'',
          invoiceNumber: vm.filter.invoiceNumber?vm.filter.invoiceNumber : '',
          identifier:vm.filter.identifier?vm.filter.identifier :'' ,
          page: vm.query.page - 1,
          size: vm.query.limit,
          sort: sort()
        }).then(function(response) {
          vm.transactionAuditLogs = response.data;
          vm.totalItems = response.headers('X-Total-Count');
        }, function(error) {
          NotificationService.error({
            title: 'Audit Log',
            error: error
          });
        });
      }

      function downloadURI(uri, name)
      {
        var link = document.createElement("a");
        document.body.appendChild(link);
        // link.download = name;
        link.href = uri;
        link.target = "_blank";
        link.click();
      }
  
    }
  
  })();
  