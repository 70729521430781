/*
 * @Author: Ajay 
 * @Date: 2019-02-11 11:37:30 
 * @Project: Ezyprocure
 * @Filename: ezyap.upload-invoice.service.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-09-02 13:30:53
 */

(function () {
  'use strict';

  angular.module('ezyprocureApp').factory('UploadInvoiceService', UploadInvoiceService);

  UploadInvoiceService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function UploadInvoiceService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var UploadInvoiceService = {
      invoiceUploadCSV: invoiceUploadCSV
    };
    return UploadInvoiceService;

    /**
     * [Upload the invoice csv file]
     * @param {Object} file         [CSV file to be uploaded]
     * @param {Integer} buyerInfoId [Buyer Information Id]
     */
    function invoiceUploadCSV(file, buyerInfoId) {
      var deferred = $q.defer();
      var fd = new FormData();
      fd.append('file', file);
      $http.post(API_CONSTANTS.peppolServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/invoices/upload/', fd, {
        transformRequest: angular.identity,
        headers: { 'Content-Type': undefined },
        params: buyerInfoId
      })
        .then(function (data) {
          deferred.resolve(data.data);
        }).catch(function (message) {
          deferred.reject(message);
        });
      return deferred.promise;
    }
  }
})();