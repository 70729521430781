/**
* @Author: Ajay
* @Date:   27-Feb-2017 16:21 +05:30
* @Project: Ezyprocure
* @Filename: supplier.item.service.js
 * @Last modified time: 2018-10-17T16:07:19+05:30
 * @Last modified by:   sneha
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('SupplierItemService', SupplierItemService);

  SupplierItemService.$inject = ['$http', '$q', 'API_CONSTANTS'];

  function SupplierItemService($http, $q, API_CONSTANTS) {

    var supplierItemService = {
      searchAllItems: searchAllItems,
      getAllSupplierItems: getAllSupplierItems,
      getSupplierItem: getSupplierItem,
      createSupplierItem: createSupplierItem,
      updateSupplierItem: updateSupplierItem,
      deleteSupplierItem: deleteSupplierItem,
      itemBulkUploadCSV: itemBulkUploadCSV,
      itemImageBulkUpload: itemImageBulkUpload,
      getItemsByQuery: getItemsByQuery,
      bulkDeleteSupplierItems: bulkDeleteSupplierItems,
      getBusinessOutlet : getBusinessOutlet,
      getAllBrandMappedsBusinessOutlets : getAllBrandMappedsBusinessOutlets,
    };

    return supplierItemService;



    function getAllBrandMappedsBusinessOutlets(brandIdList) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'items/search', searchFilter)
      .then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /* --  SupplierItem CRUID operations  -- */

    //Get all supplier items which are mapped to a buyer business outlets. Search query as parameter.
    function searchAllItems(searchFilter) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'items/search', searchFilter)
      .then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getBusinessOutlet(itemBusinessMappingDTO) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'item/business/mappings/allocation',
        method: "POST",
        data: itemBusinessMappingDTO,
      })
      .then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get All supplier items
    function getAllSupplierItems(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'supplier/items/',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get supplier item details using supplierItemId
    function getSupplierItem(supplierItemId) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'supplier/items/' + supplierItemId)
      .then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Create a new Supplier Item
    function createSupplierItem(supplierItem) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'supplier/items/', supplierItem).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update Supplier Item
    function updateSupplierItem(supplierItem) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'supplier/items/', supplierItem).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Soft Delete Supplier Item using supplierItemId
    function deleteSupplierItem(supplierItemId) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'supplier/items/' + supplierItemId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Bulk Upload Supplier Item using CSV
    function itemBulkUploadCSV(uploadData) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'supplier/items/upload', uploadData).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Bulk Upload of Item images
    function itemImageBulkUpload(file) {
      var deferred = $q.defer();
      var fd = new FormData();
      fd.append('file', file);
      $http.post(API_CONSTANTS.itemImageBulkUploadServiceBase + 'ezyprocuremicroservice/api/upload', fd, {
        transformRequest: angular.identity,
        headers: {'Content-Type': undefined}
      })
      .then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Bulk Delete Supplier Items using itemIdList
    function bulkDeleteSupplierItems(itemIdList) {
      var bulkDeleteItemsDTO = {
        "itemIdList": itemIdList
      };
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'supplier/items/bulk/',
        method: "PUT",
        data: bulkDeleteItemsDTO,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getItemsByQuery(data, params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'items/supplier-item-group',
        method: "POST",
        data: data,
        params: params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
