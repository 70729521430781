/**
* @Author: Abhay
* @Date:   03-March-2021 16:03 +05:30
* @Project: Ezyprocure
* @Filename: admin.customer-engage-report.controller.js
 * @Last modified by:   Abhay
 * @Last modified time: 03-March-2021 16:03 +05:30
*/



(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('CustomerEngageReportController', CustomerEngageReportController);

  CustomerEngageReportController.$inject = ['$stateParams', '$scope', '$timeout', '$state', '$filter', 'Auth', 'GLOBAL_CONSTANTS', 'CustomerEngageReportService', 'NotificationService'];

  function CustomerEngageReportController($stateParams, $scope, $timeout, $state, $filter, Auth, GLOBAL_CONSTANTS, CustomerEngageReportService, NotificationService) {

    var vm = this;

    vm.todaysDate = new Date();
    vm.weeklyCountFilter = {
      fromDate: new Date(vm.todaysDate.getFullYear(), vm.todaysDate.getMonth(), 1),
      toDate: vm.todaysDate,
      userType: "BUYER",
      loaded: true
    }
    vm.monthlyCountFilter = {
      fromDate: new Date(vm.todaysDate.getFullYear(), vm.todaysDate.getMonth(), 1),
      toDate: vm.todaysDate,
      userType: "BUYER",
      loaded: false
    }
    vm.weeklyVolumefilter = {
      fromDate: new Date(vm.todaysDate.getFullYear(), vm.todaysDate.getMonth(), 1),
      toDate: vm.todaysDate,
      userType: "BUYER",
      loaded: false
    }
    vm.monthlyVolumefilter = {
      fromDate: new Date(vm.todaysDate.getFullYear(), vm.todaysDate.getMonth(), 1),
      toDate: vm.todaysDate,
      userType: "BUYER",
      loaded: false
    }
    vm.months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    // graph options
    vm.options = {
      chart: {
        type: 'lineChart',
        height: 450,
        margin: {
          top: 20,
          right: 20,
          bottom: 40,
          left: 80
        },
        x: function (d) { return d.x; },
        y: function (d) { return d.y; },
        useInteractiveGuideline: true,
        xAxis: {
          axisLabel: "Weeks",
        },
        yAxis: {
          axisLabel: "Count",
          axisLabelDistance: 20,
        }
      },
      title: {
        enable: true,
        text: 'PO Count Weekly'
      }
    };
    // table lists
    vm.weeklyCountTableList = [];
    vm.monthlyCountTableList = [];
    vm.weeklyVolumeTableList = [];
    vm.monthlyVolumeTableList = [];
    // csv list
    vm.weeklyCountCsv = {
      "header": ["PO Count Weekly Report - " + vm.weeklyCountFilter.userType],
      "fileName": "PO Count Weekly",
      "values": [],
      "columnOrder": []
    }
    vm.monthlyCountCsv = {
      "header": ["PO Count Monthly Report - " + vm.monthlyCountFilter.userType],
      "fileName": "PO Count Monthly",
      "values": [],
      "columnOrder": []
    }
    vm.weeklyVolumeCsv = {
      "header": ["PO Volume Weekly Report - " + vm.weeklyVolumefilter.userType],
      "fileName": "PO Volume Weekly",
      "values": [],
      "columnOrder": []
    }
    vm.monthlyVolumeCsv = {
      "header": ["PO Volume Monthly Report - " + vm.monthlyVolumefilter.userType],
      "fileName": "PO Volume Monthly",
      "values": [],
      "columnOrder": []
    }
    vm.loadWeeklyCountReport = loadWeeklyCountReport;
    vm.loadMonthlyCountReport = loadMonthlyCountReport;
    vm.loadWeeklyVolumeReport = loadWeeklyVolumeReport;
    vm.loadMonthlyVolumeReport = loadMonthlyVolumeReport;

    init();

    function init() {
      loadWeeklyCountReport("BUYER");
    }


    function loadMonthlyCountReport(userType) {
      vm.monthlyCountFilter.loaded = true;
      vm.monthlyCountFilter.userType = userType ? userType : vm.monthlyCountFilter.userType;
      var params = {
        userType: vm.monthlyCountFilter.userType,
        reportType: "MONTHLY",
        recordType: "COUNT",
        fromDate: vm.monthlyCountFilter.fromDate,
        toDate: vm.monthlyCountFilter.toDate
      }
      getMonthlyData(params);
    }

    function loadWeeklyVolumeReport(userType) {
      vm.weeklyVolumefilter.loaded = true;
      vm.weeklyVolumefilter.userType = userType ? userType : vm.weeklyVolumefilter.userType;
      var params = {
        userType: vm.weeklyVolumefilter.userType,
        reportType: "WEEKLY",
        recordType: "VOLUME",
        fromDate: vm.weeklyVolumefilter.fromDate,
        toDate: vm.weeklyVolumefilter.toDate
      }
      getWeeklyData(params);
    }

    function loadMonthlyVolumeReport(userType) {
      vm.monthlyVolumefilter.loaded = true;
      vm.monthlyVolumefilter.userType = userType ? userType : vm.monthlyVolumefilter.userType;
      var params = {
        userType: vm.monthlyVolumefilter.userType,
        reportType: "MONTHLY",
        recordType: "VOLUME",
        fromDate: vm.monthlyVolumefilter.fromDate,
        toDate: vm.monthlyVolumefilter.toDate
      }
      getMonthlyData(params);
    }

    function loadWeeklyCountReport(userType) {
      vm.weeklyCountFilter.userType = userType ? userType : vm.weeklyCountFilter.userType;
      var params = {
        userType: vm.weeklyCountFilter.userType,
        reportType: "WEEKLY",
        recordType: "COUNT",
        fromDate: vm.weeklyCountFilter.fromDate,
        toDate: vm.weeklyCountFilter.toDate
      }
      getWeeklyData(params);
    }

    function getWeeklyData(params) {
      var weeklyList = [];
      CustomerEngageReportService.getPoDetails(params).then(function (response) {
        var usersList = getUsers(response.data);
        var monthWeekList = getMonthWeekList(params.recordType);

        weeklyList = usersList.map(function (user) {
          user.monthWeekList = [];
          monthWeekList.forEach(function (monthWeek) {

            var monthWeekData = {
              month: monthWeek.month,
              year: monthWeek.year,
              week: monthWeek.week
            };

            var index = response.data.findIndex(function (value) {
              return (value.userName == user.userDetail.userName) && (value.outletName == user.userDetail.outletName) && ((monthWeek.week - 1) == value.week) && (monthWeek.year == value.year);
            });
            if (index > -1) {
              monthWeekData.totalValue = +response.data[index].totalValue;
            }
            else {
              monthWeekData.totalValue = 0;
            }
            if (user.monthWeekList.length) {
              var percentage = ((monthWeekData.totalValue - user.monthWeekList[user.monthWeekList.length - 1].totalValue) * 100) / user.monthWeekList[user.monthWeekList.length - 1].totalValue;
              if (user.monthWeekList[user.monthWeekList.length - 1].totalValue == 0) {
                percentage = monthWeekData.totalValue;
              }
              monthWeekData.percentage = percentage.toFixed(2);
            }
            else {
              monthWeekData.percentage = (0).toFixed(2);
            }
            user.monthWeekList.push(monthWeekData);
          });
          return user;
        });

        if (params.recordType == "COUNT") {
          vm.weeklyCountTableList = weeklyList;
        }
        else {
          vm.weeklyVolumeTableList = weeklyList;
        }
        // set csv values
        setWeeklyCsvValues(params.recordType);
      },
        function () {

        });
    }

    function setWeeklyCsvValues(recordType) {
      var data = recordType == "COUNT" ? vm.weeklyCountTableList : vm.weeklyVolumeTableList;
      var firstRow = {
        "userName": ""
      }
      var secondRow = {
        "userName": ""
      }
      var thirdRow = {
        "userName": ""
      }
      var columnOrder = ["userName"];
      data[0].monthWeekList.forEach(function (monthWeek, index) {
        // blank row after header
        firstRow[index] = "";
        firstRow["_" + index] = "";
        // months row
        secondRow[index] = monthWeek.month;
        secondRow["_" + index] = "";
        // year week row
        thirdRow[index] = monthWeek.year + "/" + monthWeek.week;
        thirdRow["_" + index] = "";
        // generate column order for csv
        columnOrder.push(index);
        columnOrder.push("_" + index);
      });
      var csvList = [firstRow, secondRow, thirdRow];
      data.forEach(function (value) {
        var row = {
          "userName": value.userDetail.userName
        }
        if (recordType == "COUNT" && vm.weeklyCountFilter.userType != "SUPPLIER") {
          row.userName = row.userName + " -- " + value.userDetail.outletName;
        }
        else if(recordType != "COUNT" && vm.weeklyVolumefilter.userType != "SUPPLIER") {
          row.userName = row.userName + " -- " + value.userDetail.outletName;
        }
        value.monthWeekList.forEach(function (monthWeek, index) {
          row[index] = monthWeek.totalValue;
          if (index) {
            var percentage = ((monthWeek.totalValue - value.monthWeekList[index - 1].totalValue) * 100) / value.monthWeekList[index - 1].totalValue;
            if (value.monthWeekList[index - 1].totalValue == 0) {
              percentage = monthWeek.totalValue;
            }
            percentage = percentage.toFixed(2);
          }
          else {
            percentage = (0).toFixed(2);
          }
          row["_" + index] = percentage + "%";
        })
        csvList.push(row);
      });
      if (recordType == "COUNT") {
        vm.weeklyCountCsv.values = csvList;
        vm.weeklyCountCsv.columnOrder = columnOrder;
        vm.weeklyCountCsv.header = ["PO Count Weekly Report - " + vm.weeklyCountFilter.userType];
      }
      else {
        vm.weeklyVolumeCsv.values = csvList;
        vm.weeklyVolumeCsv.columnOrder = columnOrder;
        vm.weeklyCountCsv.header = ["PO Volume Weekly Report - " + vm.weeklyVolumefilter.userType];
      }
    }

    function setMonthlyCsvValues(recordType) {
      var data = recordType == "COUNT" ? vm.monthlyCountTableList : vm.monthlyVolumeTableList;
      var firstRow = {
        "userName": ""
      }
      var secondRow = {
        "userName": ""
      }
      var thirdRow = {
        "userName": ""
      }
      var columnOrder = ["userName"];
      data[0].monthList.forEach(function (monthYear, index) {
        // blank row after header
        firstRow[index] = "";
        firstRow["_" + index] = "";
        // months row
        secondRow[index] = monthYear.month;
        secondRow["_" + index] = "";
        // year week row
        thirdRow[index] = monthYear.year + "/" + (vm.months.indexOf(monthYear.month) + 1);
        thirdRow["_" + index] = "";
        // generate column order for csv
        columnOrder.push(index);
        columnOrder.push("_" + index);
      });
      var csvList = [firstRow, secondRow, thirdRow];
      data.forEach(function (value) {
        var row = {
          "userName": value.userDetail.userName
        }
        if (recordType == "COUNT" && vm.monthlyCountFilter.userType != "SUPPLIER") {
          row.userName = row.userName + " -- " + value.userDetail.outletName;
        }
        else if(recordType != "COUNT" && vm.monthlyVolumefilter.userType != "SUPPLIER") {
          row.userName = row.userName + " -- " + value.userDetail.outletName;
        }
        value.monthList.forEach(function (monthYear, index) {
          row[index] = monthYear.totalValue;
          if (index) {
            var percentage = ((monthYear.totalValue - value.monthList[index - 1].totalValue) * 100) / value.monthList[index - 1].totalValue;
            if (value.monthList[index - 1].totalValue == 0) {
              percentage = monthYear.totalValue;
            }
            percentage = percentage.toFixed(2);
          }
          else {
            percentage = (0).toFixed(2);
          }
          row["_" + index] = percentage + "%";
        })
        csvList.push(row);
      });
      if (recordType == "COUNT") {
        vm.monthlyCountCsv.values = csvList;
        vm.monthlyCountCsv.columnOrder = columnOrder;
        vm.monthlyCountCsv.header = ["PO Count Monthly Report - " + vm.monthlyCountFilter.userType];
      }
      else {
        vm.monthlyVolumeCsv.values = csvList;
        vm.monthlyVolumeCsv.columnOrder = columnOrder;
        vm.monthlyCountCsv.header = ["PO Volume Monthly Report - " + vm.monthlyVolumefilter.userType];
      }
    }

    function getMonthlyData(params) {
      var monthlyList = [];
      CustomerEngageReportService.getPoDetails(params).then(function (response) {
        var usersList = getUsers(response.data);
        var monthYearList = getMonthList(params.recordType);

        monthlyList = usersList.map(function (user) {
          user.monthList = [];
          monthYearList.forEach(function (monthYear) {

            var monthYearData = {
              month: monthYear.month,
              year: monthYear.year,
              week: monthYear.week
            };

            var index = response.data.findIndex(function (value) {
              return value.userName == user.userDetail.userName && value.outletName == user.userDetail.outletName && (vm.months.indexOf(monthYear.month) + 1) == value.month && monthYear.year == value.year;
            });
            if (index > -1) {
              monthYearData.totalValue = +response.data[index].totalValue;
            }
            else {
              monthYearData.totalValue = 0;
            }
            if (user.monthList.length) {
              var percentage = ((monthYearData.totalValue - user.monthList[user.monthList.length - 1].totalValue) * 100) / user.monthList[user.monthList.length - 1].totalValue;
              if (user.monthList[user.monthList.length - 1].totalValue == 0) {
                percentage = monthYearData.totalValue;
              }
              monthYearData.percentage = percentage.toFixed(2);
            }
            else {
              monthYearData.percentage = (0).toFixed(2);
            }
            user.monthList.push(monthYearData);
          });
          return user;
        });

        if (params.recordType == "COUNT") {
          vm.monthlyCountTableList = monthlyList;
        }
        else {
          vm.monthlyVolumeTableList = monthlyList;
        }
        // set csv values
        setMonthlyCsvValues(params.recordType);
      },
        function () {

        });
    }

    function getUsers(response) {
      var users = {};
      var usersList = [];
      response.forEach(function (value) {
        var userDetail = {};
        var outletAndUser = value.userName + "-" + value.outletName;
        if (!users[outletAndUser]) {
          userDetail.userName = value.userName;
          userDetail.userCode = value.userCode;
          userDetail.brandName = value.brandName;
          userDetail.outletName = value.outletName;
          usersList.push({ "userDetail": userDetail });
          users[outletAndUser] = value.userName + " -- " + value.outletName;
        }
      });
      return usersList;
    }

    function getMonthWeekList(recordType) {
      var momentFromDate = moment(recordType == "COUNT" ? vm.weeklyCountFilter.fromDate : vm.weeklyVolumefilter.fromDate);
      var momentToDate = moment(recordType == "COUNT" ? vm.weeklyCountFilter.toDate : vm.weeklyVolumefilter.toDate);
      var monthWeekList = [];
      while (momentFromDate.year() != momentToDate.year() || momentFromDate.week() != momentToDate.week() + 1) {
        var monthWeek = {
          month: vm.months[momentFromDate.month()],
          week: momentFromDate.week(),
          year: momentFromDate.year()
        }
        monthWeekList.push(monthWeek);
        momentFromDate.add(1, "week");
      }
      return monthWeekList;
    }

    function getMonthList(recordType) {
      var momentFromDate = moment(recordType == "COUNT" ? vm.monthlyCountFilter.fromDate : vm.monthlyVolumefilter.fromDate);
      var momentToDate = moment(recordType == "COUNT" ? vm.monthlyCountFilter.toDate : vm.monthlyVolumefilter.toDate);
      var monthsList = [];
      while (momentFromDate.year() != momentToDate.year() || momentFromDate.month() != momentToDate.month() + 1) {
        var month = {
          month: vm.months[momentFromDate.month()],
          week: momentFromDate.week(),
          year: momentFromDate.year()
        }
        monthsList.push(month);
        momentFromDate.add(1, "month");
      }
      return monthsList;
    }
  }
})();
