/*
 * @Author: Jayalekshmi 
 * @Date: 2018-07-16 12:29:15
 * @Filename: pdf.upload.service.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2018-07-20 15:26:57
 */


(function() {
    'use strict';
  
    angular.module('ezyprocureApp').factory('PdfUploadService', PdfUploadService);
  
    PdfUploadService.$inject = ['$http', '$q', 'toastr'];
  
    function PdfUploadService($http, $q, toastr) {
  
      var pdfUploadService = {
        checkFileType: checkFileType,
        getPdfFileBase64: getPdfFileBase64
      };
  
      return pdfUploadService;
  
      function checkFileType(attType) {
        //Check for valid pdf extensions
        if (attType.name.split(".").pop() === 'pdf') {
          return true;
        } else {
          toastr.error('Please upload .pdf files!', 'Invalid File Type');
          return false;
        }
      }
  
      function getPdfFileBase64(inputFile, callback) {
        if (this.checkFileType(inputFile)) {
          var base64;
          var fileReader = new FileReader();
          fileReader.onload = function(event) {
            base64 = event.target.result;
            callback(base64);
          };
          fileReader.readAsDataURL(inputFile);
        }
      }
    }
  })();
  