/**
* @Author: Ajay
* @Date:   17-May-2017 17:52 +05:30
* @Project: Ezyprocure
* @Filename: buyer.gl-codes.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 07-Nov-2017 15:33 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('GlCodeController', GlCodeController);

  GlCodeController.$inject = ['$scope', '$rootScope', '$state', 'toastr', '$mdDialog', 'GlCodeService', 'pagingParams', 'paginationConstants', 'NotificationService'];

  function GlCodeController($scope, $rootScope, $state, toastr, $mdDialog, GlCodeService, pagingParams, paginationConstants, NotificationService) {
    var vm = this;

    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;

    vm.loadAll = loadAll;
    vm.showConfirm = showConfirm;
    vm.cancel = cancel;
    vm.showAddGlCode = showAddGlCode;
    vm.addGlCode = addGlCode;
    vm.showEditGlCode = showEditGlCode;
    vm.updateGlCode = updateGlCode;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    /**
    * [sort description]
    * @return {[type]} [description]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function loadAll(search) {
      if (!search) {
        vm.searchText = '';
      }
      GlCodeService.getAllGlCodes({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.searchText
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.glCodes = response.data;
      }, function(error) {
      });
    }

    vm.loadAll();

    function deleteGlCode(glCodeId) {
      GlCodeService.deleteGlCode(glCodeId).then(function(response) {
        NotificationService.success({
          title: 'GL Code',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'GL Code Deletion',
          error: error
        });
      });
    }

    function showConfirm(ev, glCode) {
      var confirm = $mdDialog.confirm()
      .title('Are you sure you want to delete GL Code - ' + glCode.code + ' ? ')
      .textContent('This action could not be reverted back.')
      .targetEvent(ev)
      .ok('Delete')
      .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        deleteGlCode(glCode.id);
      }, function() {});
    }

    function cancel() {
      $mdDialog.cancel();
    }

    //Add GL Code Starts here

    function showAddGlCode(event) {
      vm.newGlCode = {};
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/gl-module/gl-codes/buyer.gl-code.add.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function addGlCode(newGlCode) {
      GlCodeService.createGlCode(newGlCode).then(function(response) {
        $scope.$emit('ezyprocureApp:glCodeUpdate', response);
        vm.cancel();
        NotificationService.success({
          title: 'GL Code',
          message: 'alertMessage.CREATED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'GL Code Creation',
          error: error
        });
      });
    }

    //Add GlCode Ends here


    //Edit GlCode Starts here

    function showEditGlCode(event, oldGlCode) {
      vm.editGlCode = angular.copy(oldGlCode);
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/gl-module/gl-codes/buyer.gl-code.edit.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function updateGlCode(oldGlCode) {
      GlCodeService.updateGlCode(oldGlCode).then(function(response) {
        $scope.$emit('ezyprocureApp:glCodeUpdate', response);
        vm.cancel();
        NotificationService.success({
          title: 'GL Code',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'GL Code Updation',
          error: error
        });
      });
    }

    //Edit GlCode Ends here

  }


})();
