/**
* @Author: Sandhiya
* @Project: Ezyprocure
* @Filename: manage-calendars.bulk-upload-controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 06-Nov-2017 14:37 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('ManageCalendarBulkUploadController', ManageCalendarBulkUploadController);

  ManageCalendarBulkUploadController.$inject = ['$scope', '$rootScope', '$state', 'toastr', 'Auth', 'AdminCalendarService', 'CsvUploadService', 'NotificationService', 'S3_URL_CONSTANTS'];

  function ManageCalendarBulkUploadController($scope, $rootScope, $state, toastr, Auth, AdminCalendarService, CsvUploadService, NotificationService, S3_URL_CONSTANTS) {
    var vm = this;

    vm.getCSVBase64 = getCSVBase64;
    vm.calendarBulkUploadCSV = calendarBulkUploadCSV;
    vm.userRole = Auth.getUserRole();
    vm.ADMIN_CALENDAR_UPLOAD_CSV = S3_URL_CONSTANTS.ADMIN_CALENDAR_UPLOAD_CSV;

    function getCSVBase64() {
      var csvFile = vm.files[0];
      if (!csvFile) {
        toastr.error('Please upload a .csv file!', 'CSV File Required');
        return false;
      }
      CsvUploadService.getFileBase64(csvFile.lfFile, function(base64CSV) {
        vm.csvUploadData = {
          encodedCsvFile: base64CSV
        };
        vm.calendarBulkUploadCSV(vm.csvUploadData);

      });
    }

    function calendarBulkUploadCSV(uploadData) {
      AdminCalendarService.calendarBulkUploadCSV(uploadData).then(function(response) {
        vm.files = [];
        NotificationService.success({
          title: 'Calendar List',
          message: 'alertMessage.UPLOADED_SUCCESSFULLY'
        });
      }, function(error) {
        vm.recordError = error.data;
        NotificationService.error({
          title: 'Calendar List Upload',
          error: error
        });
      });
    }

  }

})();
