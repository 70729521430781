/**
* @Author: Ajay
* @Date:   20-Apr-2017 16:40 +05:30
* @Project: Ezyprocure
* @Filename: supplier.invoice-report.individual-details.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-06-25T14:21:04+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierCreditNoteDetailsController', SupplierCreditNoteDetailsController);

  SupplierCreditNoteDetailsController.$inject = ['$scope', '$state', '$stateParams', 'toastr', 'BuyerReceivingOrderService', 'EXPORT_CONSTANTS', 'CSVExportService', 'NotificationService', 'PDFExportService', 'UTCDateService', 'Auth'];

  function SupplierCreditNoteDetailsController($scope, $state, $stateParams, toastr, BuyerReceivingOrderService, EXPORT_CONSTANTS, CSVExportService, NotificationService, PDFExportService, UTCDateService, Auth) {
    var vm = this;
    vm.init = init;
    vm.purchaseOrderNumber = $stateParams.purchaseOrderNumber;
    vm.creditNoteType = $stateParams.creditNoteType;
    vm.deliveryDate = new Date($stateParams.deliveryDate);
    vm.getCreditNotePDF = getCreditNotePDF;
    vm.getAdHocCreditNotePDF = getAdHocCreditNotePDF;
    vm.getCreditNoteCSVData = getCreditNoteCSVData;
    vm.accessForPriceDisplay = Auth.hasPermission('accessForPriceDisplay');

    /**
     * [init loads the function initially]
     */
    function init(){
      if(vm.accessForPriceDisplay)
        vm.creditNoteHeader = EXPORT_CONSTANTS.CSV.creditNoteHeader;
      else {
        vm.creditNoteHeader = _.without(EXPORT_CONSTANTS.CSV.creditNoteHeader, 'unitPrice' , 'amount');
      }
      loadAll();
    }

    /**
     * [loadAll Gets GRN item Details by PO number]
     */
     function loadAll() {
       BuyerReceivingOrderService.getGRNItemDetails(vm.purchaseOrderNumber, {
         deliveryDate: !vm.deliveryDate ? '' : vm.deliveryDate,
       }).then(function(response){
         vm.purchaseOrderDetails = response.data;
         vm.purchaseOrderDetails.invoice.deliveryCharge = vm.purchaseOrderDetails.deliveryCharges;
       },function(error){
         NotificationService.error({
           title: 'Credit Note Information',
           error: error
         });
       });
     }

    function downloadURI(uri, name)
    {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = name;
      link.href = uri;
      link.click();
    }

    function getCreditNotePDF() {
      PDFExportService.getCreditNotePDF({
        poNumber: vm.purchaseOrderNumber,
        deliveryDate: !vm.deliveryDate ? '' : vm.deliveryDate,
      }).then(function(response){
        downloadURI(response.data.message, "PDF_" + vm.poNumber);
      },function(error) {
        NotificationService.error({
          title: 'Credit Note PDF Export',
          error: error
        });
      });
    }

    function getAdHocCreditNotePDF() {
      PDFExportService.getAdHocCreditNotePDF({
        poNumber: vm.purchaseOrderNumber,
        deliveryDate: !vm.deliveryDate ? '' : vm.deliveryDate,
      }).then(function(response){
        downloadURI(response.data.message, "PDF_" + vm.poNumber);
      },function(error) {
        NotificationService.error({
          title: 'AdHoc Credit Note PDF Export',
          error: error
        });
      });
    }

    function getCreditNoteCSVData() {
      return CSVExportService.getCreditNoteCSV(vm.purchaseOrderDetails, vm.creditNoteType);
    }
  }
})();
