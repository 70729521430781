/**
* @Author: sneha
* @Date:   14-Mar-2018 11:57 +05:30
* @Project: Ezyprocure
* @Filename: supplier.label-printing.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 05-Apr-2018 15:36 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierLabelPrintingController', SupplierLabelPrintingController);

  SupplierLabelPrintingController.$inject = ['$scope', '$state', '$element', 'paginationConstants', 'SupplierLabelPrintingService', 'NotificationService', 'BusinessSupplierMapService'];

  function SupplierLabelPrintingController($scope, $state, $element, paginationConstants, SupplierLabelPrintingService, NotificationService, BusinessSupplierMapService) {
    var vm = this;
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.todaysDate = new Date();
    vm.getAllItemsByOutletId = getAllItemsByOutletId;
    vm.generateLabel = generateLabel;

    /**
    * [getAllBuyerBusinessOutlets get all business outlets]
    * @return {[type]} [boolean]
    */
    function getAllBuyerBusinessOutlets() {
      if(vm.businessOutlets)
        return false;
      BusinessSupplierMapService.getBuyerBusinessOutlets({
        page: 0,
        size: paginationConstants.maxLimit,
        sort: '',
      }).then(function(response) {
        vm.businessOutlets = response.data;
      }, function(error) {
      });
    }

    /**
    * [getAllItemsByOutletId get all items mapped to selected business outlets]
    */
    function getAllItemsByOutletId(){
      SupplierLabelPrintingService.getAllItemsByOutletId({
        outletId: vm.label.outletDetails.id,
        labelPrintingEnabled : true
      }).then(function(response) {
        vm.itemList = response.data;
      }, function(error) {
      });
    }


    /**
    * [generateLabel generate label]
    */
    function generateLabel() {
      SupplierLabelPrintingService.createLabel(vm.label).then(function(response) {
        downloadURI(response.message, "LABEL_PDF_" + vm.label.item.itemName + "_" + vm.label.item.itemCode);
        NotificationService.success({
          title: 'Label Printing',
          message: 'alertMessage.CREATED_SUCCESSFULLY'
        });
      }, function(error) {
        NotificationService.error({
          title: 'Label Printing',
          error: error
        });
      });
    }

    function downloadURI(uri, name)
    {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = name;
      link.href = uri;
      link.click();
    }

  }
})();
