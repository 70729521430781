/**
* @Author: Ajay
* @Date:   20-Apr-2017 16:40 +05:30
* @Project: Ezyprocure
* @Filename: supplier.invoice-report.state.js
 * @Last modified by:   sneha
 * @Last modified time: 22-May-2018 16:12 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('supplier.reports.invoice-report', {
      parent: 'supplier.reports',
      url: '/invoice-report',
      data: {
        authorities: ['ROLE_SUPPLIER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/reports/invoice-report/supplier.invoice-report.html',
          controller: 'SupplierInvoiceReportController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,desc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('invoice');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('buyerBusiness');
          $translatePartialLoader.addPart('processOrder');
          $translatePartialLoader.addPart('purchaseOrder');
          $translatePartialLoader.addPart('invoiceOCR');
          $translatePartialLoader.addPart('invoiceStatus');
          return $translate.refresh();
        }]
      }
    })
    .state('supplier.reports.invoice-report.individual-details', {
      parent: 'supplier.reports.invoice-report',
      url: '/individual-details/:purchaseOrderNumber/:deliveryDate/:fromSIP',

      data: {
        authorities: ['ROLE_SUPPLIER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/reports/invoice-report/individual-details/supplier.invoice-report.individual-details.html',
          controller: 'SupplierInvoiceReportIndividualDetailsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('invoice');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('buyerBusiness');
          $translatePartialLoader.addPart('processOrder');
          $translatePartialLoader.addPart('purchaseOrder');
          return $translate.refresh();
        }]
      }
    })
    .state('supplier.reports.invoice-report.consolidated-details', {
      parent: 'supplier.reports.invoice-report',
      url: '/consolidated-details',

      data: {
        authorities: ['ROLE_SUPPLIER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/reports/invoice-report/consolidated-details/supplier.invoice-report.consolidated-details.html',
          controller: 'SupplierInvoiceReportConsolidatedDetailsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('invoice');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('buyerBusiness');
          $translatePartialLoader.addPart('processOrder');
          $translatePartialLoader.addPart('purchaseOrder');
          return $translate.refresh();
        }]
      }
    });
  }

})();
