/**
* @Author: Mufsir
* @Date:   17-Jan-2017 17:22 +05:30
* @Project: Ezyprocure
* @Filename: admin.manage-limits.service.js
* @Last modified by:   Ajay
* @Last modified time: 03-Apr-2017 18:35 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('AdminManageLimitsService', AdminManageLimitsService);

  AdminManageLimitsService.$inject = ['$http', '$q', 'API_CONSTANTS'];

  function AdminManageLimitsService($http, $q, API_CONSTANTS) {

    var adminManageLimitsService = {
      updateBuyerLimitForSupplier: updateBuyerLimitForSupplier,
      updateSupplierLimitForBuyer: updateSupplierLimitForBuyer
    };

    return adminManageLimitsService;

    //Update buyer limit for supplier
    //accepts supplier id and new count
    function updateBuyerLimitForSupplier(limitData) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers/limit-buyers', limitData).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update supplier limit for buyer
    //accepts buyer id and new count
    function updateSupplierLimitForBuyer(limitData) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyers/limit-suppliers', limitData).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
