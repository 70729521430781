/**
* @Author: Ajay
* @Date:   03-Mar-2017 15:12 +05:30
* @Project: Ezyprocure
* @Filename: buyer.unable-meet-demands.details.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 27-Feb-2018 13:59 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerUnableMeetDemandsDetailsController', BuyerUnableMeetDemandsDetailsController);

  BuyerUnableMeetDemandsDetailsController.$inject = ['$scope', '$state', '$stateParams', '$mdDialog', '$filter','BuyerUnableMeetDemandsService', 'GLOBAL_CONFIG_CONSTANTS', 'NotificationService', 'GLOBAL_CONSTANTS'];

  function BuyerUnableMeetDemandsDetailsController($scope, $state, $stateParams, $mdDialog, $filter, BuyerUnableMeetDemandsService, GLOBAL_CONFIG_CONSTANTS, NotificationService, GLOBAL_CONSTANTS) {

    var vm = this;
    vm.init = init;
    vm.loadAll = loadAll;
    vm.buyerReplyToSupplierResponse = buyerReplyToSupplierResponse;
    vm.buyerDeleteProcessOrderItem = buyerDeleteProcessOrderItem;
    vm.checkAlreadyProcessedByBuyer = checkAlreadyProcessedByBuyer;
    vm.showRejectPOConfirmationModal = showRejectPOConfirmationModal;

    vm.requirementNumber = $stateParams.requirementNumber;
    vm.sumdClass = GLOBAL_CONFIG_CONSTANTS.SUMD_CONSTANTS;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;

    function init() {
      vm.loadAll();
    }

    //Get Process Order Details By Requirement Number
    function loadAll() {
      BuyerUnableMeetDemandsService.getProcessOrderByRequirementNumber(vm.requirementNumber).then(function(response) {
        vm.checkAlreadyProcessedByBuyer(response.data);
        vm.purchaseOrderDetails = response.data;
      }, function(error) {
      });
    }

    /**
     * [checkAlreadyProcessedByBuyer Redirect back to Listing page if  po already generated]
     * @param  {[type]} purchaseOrderDetails [description]
     * If any of the supplierResponses has processOrderStatus = REQUIREMENT_PENDING_PO, then this means PO is already generated by buyer
     * If all of the supplierResponses has processOrderStatus = BUYER_REJECTED, then this means PO is already rejected by buyer
     * So should redirect to listing page
     */
      function checkAlreadyProcessedByBuyer(purchaseOrderDetails) {
          var flattenResult = _.flatten(_.without(_.map(purchaseOrderDetails.items, _.property('supplierResponses')), undefined, null));
          var poAlreadyGeneratedByBuyer = _.some(flattenResult, ['processOrderStatus', 'REQUIREMENT_PENDING_PO']);
          var poAlreadyRejectedByBuyer = flattenResult.length ? _.every(flattenResult, ['processOrderStatus', 'BUYER_REJECTED']) : false;
          if (poAlreadyGeneratedByBuyer || poAlreadyRejectedByBuyer) {
              $state.go('buyer.unable-meet-demands', {}, { reload: false });
          }
      }

    //Buyer Reaction to Supplier's response
    function buyerReplyToSupplierResponse(requirementNumber, processOrderStatus) {
      BuyerUnableMeetDemandsService.buyerReplyToSupplierResponse({
        requirementNumber: requirementNumber,
        processOrderStatus: processOrderStatus
      })
      .then(function(response) {
        NotificationService.success({
          title: 'global.buyerReplyToSupplierResponse',
          message: 'alertMessage.SENT_SUCCESSFULLY'
        });
        $state.go('buyer.unable-meet-demands', {}, {
          reload: false
        });
      }, function(error) {
        NotificationService.error({
          title: 'global.buyerReplyToSupplierResponse',
          error: error
        });
      });
    }

    //Buyer Delete Process Order Item
    function buyerDeleteProcessOrderItem(requirementNumber, processOderId) {
      BuyerUnableMeetDemandsService.buyerDeleteProcessOrderItem(requirementNumber, processOderId)
      .then(function(response) {
        NotificationService.success({
          title: 'global.processOrderItem',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'global.processOrderItem',
          error: error
        });
      });
    }

    //Show reject PO Confirmation
    function showRejectPOConfirmationModal(event) {
      var confirm = $mdDialog.confirm()
      .title($filter('translate')('alertMessage.REJECT_PO_CONFIRMATION_MESSAGE'))
      .targetEvent(event)
      .ok($filter('translate')('global.buttons.proceed'))
      .cancel($filter('translate')('global.buttons.cancel'));
      $mdDialog.show(confirm).then(function() {
        vm.buyerReplyToSupplierResponse(vm.requirementNumber, 'BUYER_REJECTED');
      }, function() {});
    }
  }
})();
