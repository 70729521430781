/**
 * @Author: Ajay
 * @Date:   30-May-2017 13:08 +05:30
 * @Project: Ezyprocure
 * @Filename: supplier.return-order.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 01-Jun-2017 13:53 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('supplier.return-order', {
      parent: 'supplier',
      url: '/return-order',
      data: {
        authorities: ['ROLE_SUPPLIER']
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/return-order/supplier.return-order.html',
          controller: 'SupplierReturnOrderController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,desc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('purchaseOrder');
          $translatePartialLoader.addPart('processOrder');
          $translatePartialLoader.addPart('invoiceOCR');
          $translatePartialLoader.addPart('buyerDashboard');
          $translatePartialLoader.addPart('invoiceStatus');
          return $translate.refresh();
        }]
      }
    })
    .state('supplier.return-order.details', {
      parent: 'supplier.return-order',
      url: '/details/:goodsReturnNumber',
      data: {
        authorities: ['ROLE_SUPPLIER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/return-order/details/supplier.return-order.details.html',
          controller: 'SupplierReturnOrderDetailsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('invoice');
          $translatePartialLoader.addPart('goodsReturnStatus');
          return $translate.refresh();
        }]
      }
    })
    .state('supplier.return-order.create-credit-note', {
      parent: 'supplier.return-order',
      url: '/create-credit-note/:goodsReturnNumber',
      data: {
        authorities: ['ROLE_SUPPLIER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/return-order/create-credit-note/supplier.create-credit-note.html',
          controller: 'SupplierCreateCreditNoteController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('invoice');
          $translatePartialLoader.addPart('supplierDashboard');
          $translatePartialLoader.addPart('buyerBusiness');
          $translatePartialLoader.addPart('purchaseOrder');
          return $translate.refresh();
        }]
      }
    });
  }
})();
