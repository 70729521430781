/**
 * @Author: Ajay
 * @Date:   21-Apr-2017 17:54 +05:30
 * @Project: Ezyprocure
 * @Filename: supplier.buyer-mapping.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 01-Jun-2017 13:15 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('supplier.buyer-supplier-mapping', {
      parent: 'supplier',
      url: '/buyer-supplier-mapping?page&sort&search',
      data: {
        authorities: ['ROLE_SUPPLIER'],
        pageTitle: 'ezyprocureApp.buyerSupplierMapping.home.title'
      },
      views: {
        'content@': {
          templateUrl: 'app/common-modules/buyer-supplier-mapping/buyer-supplier-mapping.html',
          controller: 'BuyerSupplierMappingController',
          controllerAs: 'vm'
        }
      },
        params: {
              page: {
                value: '1',
                squash: true
              },
              sort: {
                value: 'id,asc',
                squash: true
              },
              search: null
            },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                return {
                  page: PaginationUtil.parsePage($stateParams.page),
                  sort: $stateParams.sort,
                  predicate: PaginationUtil.parsePredicate($stateParams.sort),
                  ascending: PaginationUtil.parseAscending($stateParams.sort),
                  search: $stateParams.search
                };
              }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('buyerSupplierMapping');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('user-management');
          return $translate.refresh();
        }]
      }
    });
  }

})();
