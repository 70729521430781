/*
 * @Author: Lovin C 
 * @Date: 2021-08-29 11:48:02 
 * @Last Modified by: Lovin C
 * @Last Modified time: 2021-09-13 14:50:04
 */

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('AddInvoiceController', AddInvoiceController);

  AddInvoiceController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', 'toastr', '$mdDialog', 'pagingParams', 'NotificationService', 'paginationConstants', 'BuyerPayeeService', 'Auth', 'GLOBAL_CONSTANTS', '$stateParams', 'BankService', 'BuyerDataService', 'BrandManagementService', 'CustomPaymentsService', 'CartService'];

  function AddInvoiceController($scope, $rootScope, $q, $filter, $state, toastr, $mdDialog, pagingParams, NotificationService, paginationConstants, BuyerPayeeService, Auth, GLOBAL_CONSTANTS, $stateParams, BankService, BuyerDataService, BrandManagementService, CustomPaymentsService, CartService) {

    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = true;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.supplier = {};
    vm.paymentTerms = {};
    vm.paymentTerms.BANK_TRANSFER = {};
    vm.userRole = Auth.getUserRole();
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.visibilityType = $stateParams.visibilityType ? $stateParams.visibilityType : 'ALLOCATED';
    vm.paymentModels = GLOBAL_CONSTANTS.PAYMENT_MODELS;
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.addInvoice = [{
      supplierId: '',
      brandId: '',
      invoiceNumber: '',
      invoiceDate: undefined,
      amount: '',
      purposeCode: '9311',
      invoiceDoc: null,
      otherDoc: null,
      otherDocRequired: false
    }];
    vm.brandIds = [];
    vm.purposes = [];
    vm.addMore = addMore;
    vm.getSuppliers = getSuppliers;
    vm.loadBrandsBasedOnSupplierId = loadBrandsBasedOnSupplierId;
    vm.fileUpload = fileUpload;
    vm.uploadAttachment = uploadAttachment;
    vm.deleteInvoice = deleteInvoice;
    vm.cancel = cancel;
    vm.addInvoicePreview = addInvoicePreview;
    vm.showConfirm = showConfirm;
    vm.loadInvoiceData = loadInvoiceData;
    vm.loadSupplierByBrandId = loadSupplierByBrandId;
    vm.checkBrandSupplierCustomDutyType = checkBrandSupplierCustomDutyType;

    init();

    function init() {
      getPurposes();
      getAllBrands();
      loadInvoiceData();
    }

    function getPurposes() {
      CustomPaymentsService.getPurposes().then(function (response) {
        vm.purposes = response.data;
      }, function (error) {
        NotificationService.error({
          title: 'Purposes',
          error: error
        });
      });
    }

    function loadInvoiceData() {
      var backupList = CustomPaymentsService.getInvoiceData();
      if(backupList.length) {
        vm.addInvoice = backupList;
        var brandIdList = vm.addInvoice.map(function(invoice) {
          return invoice.brandId;
        });
        loadSupplierByBrandId(brandIdList);
      }
    }

    function getAllBrands() {
      CustomPaymentsService.getAllBrands().then(function (response) {
        vm.brands = response.data;
      }, function (error) {
        NotificationService.error({
          title: 'Brand Listing',
          error: error
        });
      });
    }

    function loadSupplierByBrandId(brandIds) {
      vm.supplierList = [];
      if (!brandIds || brandIds.length === 0 || brandIds[0] === '') {
        toastr.error('Please choose a brand', 'Supplier Selection');
        return;
      }
      CustomPaymentsService.getSupplierByBrandId({
        brandIds: brandIds
      }).then(function (response) {
        vm.supplierList = response.data;
      }, function (error) {
        NotificationService.error({
          title: 'Supplier Listing',
          error: error
        });
      });
    }

    function validateInvoice() {
      var isValid = true
      if(!checkInvoiceIdDuplicates()) {
        isValid = false;
        toastr.error('Duplicate invoice number', 'Error');
        return isValid;
      }
      if(!checkInvoiceDocPresent()) {
        isValid = false;
        toastr.error('Missing Cargo Clearance Permit', 'Error');
        return isValid;
      }
      if(checkOtherDocRequired()) {
        isValid = false;
        toastr.error('Missing LOA', 'Error');
        return isValid;
      }
      return true;
    }

    function checkInvoiceIdDuplicates() {
      var isUnique = true;
      var invoiceIdList = vm.addInvoice.map(function(invoice) {
        return invoice.invoiceNumber;
      });
      invoiceIdList.forEach(function(id, index, arr) {
        isUnique = (arr.indexOf(id) != index) ? false : isUnique;
      });
      return isUnique;
    }

    function checkInvoiceDocPresent() {
      var isPresent = true;
      vm.addInvoice.forEach(function(invoice) {
        if(!invoice.invoiceDoc) {
          isPresent = false;
        }
      })
      return isPresent;
    }

    function checkOtherDocRequired() {
      var isRequired = false;
      vm.addInvoice.forEach(function(invoice) {
        if(invoice.otherDocRequired && !invoice.otherDoc) {
          isRequired = true;
        }
      })
      return isRequired;
    }

    function addInvoicePreview() {
      if (validateInvoice()) {
        CustomPaymentsService.setInvoiceData(vm.addInvoice);
        $state.go('ezypayment.buyer.custom-invoice-payments.add-invoice.preview');
      }
    }

    function cancel() {
      $mdDialog.cancel();
    }

    function showConfirm(ev, index) {
      var confirm = $mdDialog.confirm()
        .title('Are you sure you want to delete?')
        .textContent('This action could not be reverted back.')
        .targetEvent(ev)
        .ok('Delete')
        .cancel('Cancel');

      $mdDialog.show(confirm).then(function () {
        deleteInvoice(index);
      }, function () { });
    }

    function deleteInvoice(index) {
      vm.addInvoice.splice(index, 1);
    }

    function fileUpload(index, type) {
      vm.index = index;
      vm.type = type;
      $mdDialog.show({
        templateUrl: 'app/ezypayment-modules/buyer-modules/custom-invoice-payments/templates/ezypayement.custom-payment.upload-attachement.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function uploadAttachment() {
      var extension = vm.files[0].lfFile.name.split('.').pop();
      var extensionNotAllowed = ['csv', 'xlsx', 'xls'];
      if(extensionNotAllowed.includes(extension)) {
        toastr.error('Please choose files other than sheets', 'File Extension');
        return;
      }
      CartService.getBase64(vm.files[0].lfFile, function (base64file) {
        if (vm.type === 'INVOICE_UPLOAD') {
          vm.addInvoice[vm.index].invoiceDoc = {
            'base64String': base64file,
            'extension': extension,
            'fileName': vm.files[0].lfFile.name.split('.').shift()
          }
          // vm.addInvoice[vm.index].otherDoc = {};
        }
        else {
          vm.addInvoice[vm.index].otherDoc = {
            'base64String': base64file,
            'extension': extension,
            'fileName': vm.files[0].lfFile.name.split('.').shift()
          }
        }
      });
      $mdDialog.cancel();
    }

    function addMore() {
      vm.addInvoice.push({
        supplierId: '',
        brandId: '',
        invoiceNumber: '',
        invoiceDate: undefined,
        amount: '',
        purposeCode: '9311',
        invoiceDoc: null,
        otherDoc: null,
        otherDocRequired: false
      });
    }

    function getSuppliers() {
      CustomPaymentsService.getSuppliers().then(function (response) {
        vm.supplierList = response.data;
      }, function (error) {
        NotificationService.error({
          title: 'Supplier Listing..',
          error: error
        });
      });
    }

    function loadBrandsBasedOnSupplierId(supplierIdList) {
      if(!supplierIdList || supplierIdList.length === 0 || supplierIdList[0] === '') {
        toastr.error('Please choose a supplier', 'Brand Selection');
        return;
      }
      CustomPaymentsService.getBrands({
        supplierIds: supplierIdList
      }).then(function (response) {
        vm.brands = response.data;
      }, function (error) {
        NotificationService.error({
          title: 'Brand Listing',
          error: error
        });
      });
    }

    function checkBrandSupplierCustomDutyType($index) {
      if (vm.addInvoice[$index].brandId && vm.addInvoice[$index].supplierId) {
        var selectedBrand = vm.brands.filter(function(brand) {
          return brand.id == vm.addInvoice[$index].brandId;
        });
        var selectedSupplier = vm.supplierList.filter(function(supplier) {
          return supplier.id == vm.addInvoice[$index].supplierId;
        });
        if (selectedBrand.length > 0 && selectedSupplier.length > 0) {
          if (selectedBrand[0].customDutyType && selectedBrand[0].customDutyType == 'Freight Forwarder' && selectedSupplier[0].customDutyType && selectedSupplier[0].customDutyType == 'Declaring Agent') {
            vm.addInvoice[$index].otherDocRequired = true;
          } else {
            vm.addInvoice[$index].otherDocRequired = false;
          }
        } else {
          vm.addInvoice[$index].otherDocRequired = false;
        }
      } else {
        vm.addInvoice[$index].otherDocRequired = false;
      }
    }

  }
})();
