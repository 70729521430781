/**
* @Author: Mufsir
* @Date:   14-Dec-2016 12:54 +05:30
* @Project: Ezyprocure
* @Filename: reset.finish.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 18-Apr-2018 11:49 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('finishReset', {
      parent: 'account',
      url: '/reset/finish?key',
      data: {
        authorities: []
      },
      views: {
        'content@': {
          templateUrl: 'app/account/reset/finish/reset.finish.html',
          controller: 'ResetFinishController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('reset');
          $translatePartialLoader.addPart('login');
          $translatePartialLoader.addPart('user-management');
          return $translate.refresh();
        }]
      }
    });
  }
})();
