/**
* @Author: Ajay
* @Date:   20-Mar-2017 12:11 +05:30
* @Project: Ezyprocure
* @Filename: buyer.price-update-history.service.js
 * @Last modified by:
 * @Last modified time: 2017-09-17T17:17:31+05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BuyerPriceUpdateHistoryService', BuyerPriceUpdateHistoryService);

  BuyerPriceUpdateHistoryService.$inject = ['$http', '$q', 'API_CONSTANTS'];
  function BuyerPriceUpdateHistoryService($http, $q, API_CONSTANTS) {

    var buyerPriceUpdateHistoryService = {
      getPriceUpdatesList : getPriceUpdatesList,
      getTopSuppliers : getTopSuppliers,
      getTopPurchaseItems : getTopPurchaseItems,
      getAuditTransactionData : getAuditTransactionData,
      getPriceTrends : getPriceTrends,
      getTopSuppliersDetails : getTopSuppliersDetails,
      approvalPRPORelationReport : approvalPRPORelationReport
    };
    return buyerPriceUpdateHistoryService;

    function getPriceUpdatesList(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'price-updates-history',
        method: "GET",
        params:params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    function getTopSuppliers(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'top-suppliers',
        method: "GET",
        params:params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    function getTopPurchaseItems(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'top-purchase-items',
        method: "GET",
        params:params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getAuditTransactionData(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'audit-transaction-data',
        method: "GET",
        params:params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  
    function getPriceTrends(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'price-trends',
        method: "GET",
        params:params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getTopSuppliersDetails(supplierId,fromDate,toDate,businessOutletId, params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'top-suppliers/top-suppliers-details/' + supplierId +'/' +fromDate +'/' +toDate + '/' +businessOutletId,
        method: "GET",
        params:params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function approvalPRPORelationReport(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'item-approval-requests/Approval-PR-PO-Relation-Report',
        method: "POST",
        params:params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
