/**
* @Author: Allan
* @Date:   07-June-2018 16:15 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.buyer.due-payment-summary.details.controller.js
* @Last modified by:   Allan
* @Last modified time: 17-October-2018 14:45 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzypaymentODBCDuePaymentSummaryDetailsController', EzypaymentODBCDuePaymentSummaryDetailsController);

  EzypaymentODBCDuePaymentSummaryDetailsController.$inject = ['paginationConstants','$state', '$q', '$timeout', 'DuePaymentInvoicesService', 'NotificationService', 'CardsService', 'Auth', '$mdDialog', '$filter', 'GLOBAL_CONSTANTS', 'toastr'];

  function EzypaymentODBCDuePaymentSummaryDetailsController(paginationConstants,$state, $q, $timeout, DuePaymentInvoicesService, NotificationService, CardsService, Auth, $mdDialog, $filter, GLOBAL_CONSTANTS, toastr) {

    var vm = this;
    vm.init = init;
    vm.getBrandIds = getBrandIds;
    var selectedPOIds = DuePaymentInvoicesService.getSelectedPOIds();
    vm.getOdbcDuePaymentSummary = getOdbcDuePaymentSummary;
    vm.cardTokenByBrands = cardTokenByBrands;
    vm.brandIds = [];
    vm.tokenCards = [];
    vm.duePaymentSummaryDetails = [];
    vm.brandTokens = {};
    vm.loadTransactionReport = loadTransactionReport;
    vm.confirmAndPay = confirmAndPay;
    vm.paymentResponse = [];
    vm.accessForOcr = Auth.hasPermission("accessForOcr");
    vm.invoiceType = DuePaymentInvoicesService.getInvoiceType();
    vm.getOdbcExternalDuePaymentSummary = getOdbcExternalDuePaymentSummary;
    vm.showDuePaymentMessage = showDuePaymentMessage;
    vm.getCardType = getCardType;
    vm.calculateSum=calculateSum;
    // 2FA changes
    vm.otp = undefined;
    vm.isOtpValid = true;
    vm.counterMilliSeconds = undefined;
    vm.counterSeconds = undefined;
    vm.counterMinutes = undefined;
    vm.validateOTP = validateOTP;
    vm.resendOTP = resendOTP;

    function init() {
      if(vm.invoiceType === 'INTERNAL'){
        vm.getOdbcDuePaymentSummary();
      } else {
        vm.getOdbcExternalDuePaymentSummary();
      }
    }

    // timer for otp resend
    function counter() {
      vm.stopped = $timeout(function () {
        vm.counterMilliSeconds -= 1000;
        if (vm.counterMilliSeconds) {
          var seconds = vm.counterMilliSeconds / 1000;
          vm.counterMinutes = Math.floor(seconds / 60);
          if(vm.counterMinutes > 0) {
            vm.counterSeconds = seconds - (vm.counterMinutes * 60);
          }
          else {
            vm.counterSeconds = seconds;
          }
          counter();
        }
        else {
          vm.counterSeconds = 0;
          vm.counterMinutes = 0;
          stopCounter();
        }
      }, 1000);
    };

    function stopCounter(){
      vm.isOtpValid = false;
      $timeout.cancel(vm.stopped);
    } 

    function showOtpPrompt() {
      // clear otp
      vm.otp = undefined;
      vm.isOtpValid = true;
      vm.counterSeconds = 0;
      vm.counterMinutes = 0;
      vm.counterMilliSeconds = GLOBAL_CONSTANTS.OTP_EXPIRY_MINUTES * 60 * 1000;
      counter();
      // show prompt
      $mdDialog.show({
        templateUrl: 'app/core/common-templates/otp-templates/otp-capture.tmpl.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function validateOTP() {
      if (!vm.otp) {
        toastr.error('Invalid OTP!', 'Please check the OTP entered');
        return;
      }

      if (vm.otp && (vm.otp.length < 5 || vm.otp.length > 8)) {
        toastr.error('Invalid OTP!', 'Please check the OTP entered');
        return;
      }
      confirmAndPay(vm.otp);
    }

    function resendOTP() {
      confirmAndPay();
    }

    function getBrandIds(params) {
      _.filter(params, function(poList) {
        if(vm.brandIds.indexOf(poList.brandId) === -1){
          vm.brandIds.push(poList.brandId);
        }
      });
    }

    function cardTokenByBrands() {
      CardsService.getOdbcCards({
        brandIds: vm.brandIds,
        page: 0,
        size: paginationConstants.maxLimit
      }).then(function(response){
        vm.tokenCards = [];
        if(Array.isArray(response.data)) {
          response.data.forEach(function(card) {
            var tokenCard = {};
            tokenCard.brandId = card.brand.id;
            tokenCard.id = card.id;
            tokenCard.cardNumber = card.cardNumber;
            vm.tokenCards.push(tokenCard);
          });
        }
        assignCardByBrand();
      },function(error) {
        NotificationService.error({
          title: 'Fetching Token By Cards',
          error: error
        });
      });
    }

    function assignCardByBrand() {
      vm.duePaymentSummaryDetails.forEach(function(invoice) {
        invoice.tokenCards = vm.tokenCards.filter(function(card) {
          return card.brandId == invoice.brandId;
        });
        vm.brandTokens[invoice.brandId] = invoice.tokenCards[0].id;
      });
    }

    function getOdbcDuePaymentSummary() {
      DuePaymentInvoicesService.getOdbcDuePaymentSummary({
        purchaseOrderIdList: selectedPOIds,
        hasPaymentApproval: false,
        approvalSeqNo: '',
        hasOcrAccess: !vm.accessForOcr ? false : true
      }).then(function (response) {
        vm.duePaymentSummaryDetails = response.data;
        if (vm.duePaymentSummaryDetails[0].message !== null) {
          showDuePaymentMessage(vm.duePaymentSummaryDetails[0].message);
        }
        vm.getBrandIds(response.data);
        vm.cardTokenByBrands();
      }, function (error) {
        NotificationService.error({
          title: 'Fetching Due Payment Summary',
          error: error
        });
      });
    }

    /**
     * Get all card types
     */
    function getCardType() {
      CardsService.getCardType({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
       }).then(function(response){
          vm.cardType = response.data;
      });
     }

   /**
    * [calculateSum shows the sum of key in list]
    */
    function calculateSum(duePaymentSummaryDetailsList, key){
      var sum = 0;
      if(duePaymentSummaryDetailsList && duePaymentSummaryDetailsList.length > 0) {
        duePaymentSummaryDetailsList.forEach( function (invoice) {
          sum += Number(Number(invoice[key]).toFixed(2));
        });
      }
      return sum;
    }

    function confirmAndPay(otp){
      _.each(vm.duePaymentSummaryDetails, function(detail, index){
        vm.duePaymentSummaryDetails[index].tokenId = vm.brandTokens[detail.brandId];
      });
      DuePaymentInvoicesService.odbcConfirmPayment(
        {
          hasPaymentApproval: false,
          approvalSeqNo: '',
          paymentType: vm.invoiceType
        },
        vm.duePaymentSummaryDetails, otp).then(function(response){
          if(response.headers('authorization-otp') == 'OTP_VERIFICATION_REQUIRED') {
            showOtpPrompt();
          }
          else if(GLOBAL_CONSTANTS.OTP_EXCEPTIONS.includes(response.headers('authorization-otp'))) {
            toastr.error('Invalid OTP', 'AUTHENTICATION FAILED');
          }
          else {
            vm.paymentResponse = response;
            NotificationService.success({
              title: 'Processed',
              message: 'Payment For Invoices'
            });
            DuePaymentInvoicesService.setInvoiceType(vm.invoiceType);
            DuePaymentInvoicesService.setPaymentResponse(vm.paymentResponse);
            $mdDialog.hide();
            $state.go('ezypayment.buyer.ocbc-due-payment-summary.details.transaction-report');
          }
        },function(error) {
          NotificationService.error({
            title: 'Payment For Invoices',
            error: error
          });
          return error;
        });
      }

      function loadTransactionReport(){
        vm.paymentResponse = DuePaymentInvoicesService.getPaymentResponse();
        vm.duePaymentSummaryDetails = vm.paymentResponse.data;
        // vm.paymentResponse.then(function(response) {
        //   vm.duePaymentSummaryDetails = response.data;
        // });
      }

      function getOdbcExternalDuePaymentSummary(){
        DuePaymentInvoicesService.getOdbcExternalDuePaymentSummary({
          invoiceIdList: String(selectedPOIds),
          hasPaymentApproval: false
        }).then(function(response){
          vm.duePaymentSummaryDetails = response.data;
          vm.getBrandIds(response.data);
          vm.cardTokenByBrands();
        },function(error) {
          NotificationService.error({
            title: 'Fetching External Due Payment Summary',
            error: error
          });
        });
      }

      function showDuePaymentMessage(message){
        var confirm = $mdDialog.confirm()
        .title($filter('translate')('global.duePaymentInvoices'))
        .textContent(message)
        .ok($filter('translate')('global.buttons.proceedToPay'))
        .cancel($filter('translate')('global.buttons.cancel'));

        $mdDialog.show(confirm).then(function() {
          // close  dialog to proceed
        }, function() {
          $state.go("ezypayment.buyer.due-payment-summary");
        });
      }



    }
  })();
