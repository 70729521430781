/**
 * @Author: Jayalekshmi 
 * @Date: 2019-01-03 10:40:23 
 * @Project: Ezyprocure
 * @Filename: sats.to.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-07-31 13:06:15
 */



(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .controller('SatsTOController', SatsTOController);
  
    SatsTOController.$inject = ['UtilService', 'GLOBAL_CONSTANTS', 'SatsManagementService', 'NotificationService', 'UTCDateService', 'BuyerSupplierMappingService', '$q', 'paginationConstants', 'Auth', '$mdDialog', 'EXPORT_CONSTANTS', 'CSVExportService', 'SupplierDataService'];
  
    function SatsTOController(UtilService, GLOBAL_CONSTANTS, SatsManagementService, NotificationService, UTCDateService, BuyerSupplierMappingService, $q, paginationConstants, Auth, $mdDialog, EXPORT_CONSTANTS, CSVExportService, SupplierDataService) {
      
      var vm = this;
      vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
      vm.loadAll = loadAll;
      vm.todaysDate = new Date();
      vm.query = {
        limit: paginationConstants.itemsPerPage,
        page: 1
      };
      vm.toFilter = {};
      vm.userRole = Auth.getUserRole();
      vm.limitOptions = paginationConstants.limitOptions;
      vm.getAllJobs = getAllJobs;
      vm.toFilter.fromDate = UTCDateService.utcFromDate(vm.todaysDate);
      vm.toFilter.toDate = UTCDateService.utcToDate(vm.todaysDate);
      vm.getAllSupplierWithSatsAccess = getAllSupplierWithSatsAccess;
      vm.statusList = ['RELEASED', 'PROCESSED', 'POSTED', 'FAILED', 'REJECTED', 'TRANSFERRED'];
      vm.documentType = 'TO';
      vm.selectAllJobs = selectAllJobs;
      vm.postAllJobs = postAllJobs;
      vm.rejectAllJobs = rejectAllJobs;
      vm.postJob = postJob;
      vm.rejectJob = rejectJob;
      vm.getSelectedJobsIds = getSelectedJobsIds;
      vm.validateJobsForRejection = validateJobsForRejection;
      vm.addRejectionReason = addRejectionReason;
      vm.cancel = cancel;
      vm.rejectByType = rejectByType;
      vm.toType = 'Transfer';
      vm.reset = reset;
      // CSV
      vm.buyerTODataHeader = EXPORT_CONSTANTS.CSV.buyerTODataHeader;
      vm.getBuyerTOCSVData = getBuyerTOCSVData;

      vm.confirmDelete = confirmDelete;
      vm.deleteJob = deleteJob;
      /**
       * [getBuyerTOCSVData Gets the csv data]
       */
      function getBuyerTOCSVData() {
        var deferred = $q.defer();
        if (vm.toJobList.length<1 ) {
          NotificationService.simpleErrorToast({
            title: 'global.table.noRecordsFound',
            message: 'alertMessage.SEARCH_AND_SUBMIT'
          });
          return deferred.promise;
        }
        return CSVExportService.getBuyerTOCSVData(vm.toJobList);
      }

      /**
       * [getAllSupplierWithSatsAccess Gets all supplier with sats access]
       */
      function getAllSupplierWithSatsAccess() {
        if(vm.suppliers)
          return false;
        BuyerSupplierMappingService.getAllSupplierWithSatsAccess()
        .then(function(response){
          vm.suppliers = response.data;
        },function(error){
        });
      }
      
      /**
       * [postAllJobs Posts All TO Job selected]
       */
      function postAllJobs() {
        var selectedJobIds = vm.getSelectedJobsIds();
        if (selectedJobIds.length < 1) {
          NotificationService.simpleErrorToast({
            title: 'global.sats.jobPlan',
            message: 'alertMessage.SELECT_ITEMS_TO_CONTINUE'
          });
        } else {
          SatsManagementService.postAllJobs(selectedJobIds)
            .then(function (response) {
              NotificationService.success({
                'title': 'TO Job Plan',
                'message': 'Successfully Posted All Jobs.'
              });
              getAllJobs();
            }, function (error) {
              NotificationService.error({
                'title': 'TO Job Plan',
                'error': error
              });
            })
        }
      }

      /**
       * [addRejectionReason Shows a modal to enter rejection reason]
       * @param {Object} event 
       * @param {String} type 
       * @param {Integer} id 
       */
      function addRejectionReason(event, type, id) {
        vm.rejectType = type;
        vm.rejectedJobId = id;
        $mdDialog.show({
          templateUrl: 'app/common-modules/sats/templates/sats.reject-reason.tmpl.html',
          controller: function() {
            return vm;
          },
          controllerAs: 'vm',
          targetEvent: event,
          clickOutsideToClose: false
        });
      }  

      /**
       * [rejectByType Decides which reject function to be called]
       */
      function rejectByType(){
        if(vm.rejectType == 'SINGLE_JOB'){
          vm.rejectJob();
        }else if(vm.rejectType == 'BULK_JOB'){
          vm.rejectAllJobs();
        }
      }
      
      /**
      * [cancel to close the modal]
      */
      function cancel() {
        $mdDialog.cancel();
      }
      
      /**
       * [rejectAllJobs Rejects all selected TO jobs]
       */
      function rejectAllJobs(){
        SatsManagementService.rejectAllJobs(vm.rejectedJobIds, {
          'shipped': vm.shipped,
          'remark': vm.rejectionReason
        })
          .then(function (response) {
            NotificationService.success({
              'title': 'TO Job Plan',
              'message': 'Successfully Rejected All Jobs.'
            });
            getAllJobs();
            vm.rejectionReason = null;
            vm.cancel();
          }, function (error) {
            NotificationService.error({
              'title': 'TO Job Plan',
              'error': error
            });
            vm.rejectionReason = null;
            vm.cancel();
          });
      }

      /**
       * [validateJobsForRejection Validates selected TO jobs]
       */
      function validateJobsForRejection(event) {
        vm.rejectedJobIds = vm.getSelectedJobsIds();
        if (vm.rejectedJobIds.length < 1) {
          NotificationService.simpleErrorToast({
            title: 'global.sats.jobPlan',
            message: 'alertMessage.SELECT_ITEMS_TO_CONTINUE'
          });
        } else {
          if(_.uniq(vm.rejectedJobIds).length==1){
            vm.addRejectionReason(event, 'BULK_JOB');
          }else{
            NotificationService.simpleErrorToast({
              title: 'global.sats.jobPlan',
              message: 'alertMessage.SELECT_SAME_JOB'
            });
          }
        }
      }

      /**
       * [getSelectedJobsIds Returns selected Job ids]
       */
      function getSelectedJobsIds() {
        var selectedJobs = SatsManagementService.getSelectedJobs(vm.toJobList);
        var selectedJobIds =_.map( _.uniqBy(selectedJobs, 'jobId'), _.property('jobId'));
        return selectedJobIds;
      }

      /**
       * [postJob Posts corresponding job by id]
       */
      function postJob(id) {
        SatsManagementService.postJob(id)
          .then(function (response) {
            NotificationService.success({
              'title': 'TO Job Plan',
              'message': 'Job Posted Successfully.'
            });
            getAllJobs();
          }, function (error) {
            NotificationService.error({
              title: 'TO Job Plan',
              error: error
            });
          });
      }

      /**
       * [rejectJob Rejects job by id]
       * @param {Integer} id 
       */
      function rejectJob() {
        SatsManagementService.rejectJob(vm.rejectedJobId, {'remark': vm.rejectionReason, 'shipped': vm.shipped})
          .then(function (response) {
            NotificationService.success({
              'title': 'TO Job Plan',
              'message': 'Job Rejected Successfully.'
            });
            getAllJobs();
            vm.rejectionReason = null;
            vm.cancel();
          }, function (error) {
            NotificationService.error({
              title: 'TO Job Plan',
              error: error
            });
            vm.rejectionReason = null;
            vm.cancel();
          });
      }

      /**
       * [selectAllJobs Selects all jobs]
       */
      function selectAllJobs() {
        vm.selectAll ? vm.selectAll = true : vm.selectAll = false;
        _.map(vm.toJobList, function (job) {
          if (vm.userRole.pretty == 'buyer') {
            if (job.jobStatus === 'PROCESSED' || job.jobStatus === 'FAILED') {
              job.selected = vm.selectAll;
            }
          } else if (vm.userRole.pretty == 'supplier') {
            if (job.jobStatus === 'RELEASED' || job.jobStatus === 'REJECTED') {
              job.selected = vm.selectAll;
            }
          }
        });
      }

      /**
       * [getAllJobs Gets all TO job lists]
       */
      function getAllJobs(){
        vm.loggedInSupplier = vm.userRole.pretty == 'supplier' ? SupplierDataService.getSupplierProfile().supplier: null;
        if(vm.toType == 'Transfer' && vm.userRole.pretty == 'supplier'){
          vm.toFilter.toWarehouse = vm.warehouseId ? vm.warehouseId: null;
          vm.toFilter.fromWarehouse = vm.loggedInSupplier ? vm.loggedInSupplier.id : null;
        }else if(vm.toType == 'Receive' && vm.userRole.pretty == 'supplier'){
          vm.toFilter.toWarehouse = vm.loggedInSupplier ? vm.loggedInSupplier.id : null;
          vm.toFilter.fromWarehouse = vm.warehouseId ? vm.warehouseId: null;
        }else{
          vm.toFilter.warehouse = vm.warehouseId;
        }
        vm.toFilter.fromDate = !vm.toFilter.fromDate?null: UTCDateService.utcFromDate(vm.toFilter.fromDate);
        vm.toFilter.toDate = !vm.toFilter.toDate?null: UTCDateService.utcToDate(vm.toFilter.toDate);
        vm.toFilter.page = vm.query.page-1;
        vm.toFilter.size = vm.query.limit;
        vm.selectAll = false;
        SatsManagementService.getAllJobs(vm.documentType, vm.toFilter)
        .then(function(response){
            vm.toJobList = response.data;
            _.map(vm.toJobList, function(job){
              if((job.transferredQuantity && job.receivedQuantity) || (job.transferredQuantity == 0)){
                var variance = job.receivedQuantity - job.transferredQuantity;
                variance == 0? job.variance = 'No': job.variance = 'Yes';
              }
            });
            vm.totalTOItems = response.headers('X-Total-Count');
        } ,function(error){
          NotificationService.error({
            'title': 'global.sats.title.'+vm.userRole.pretty,
            'error':error
          });
        });
      }
  
      /**
      * [loadAll Gets Purchase Order Details]
      */
      function loadAll() {
        var isValidDateRange = UtilService.isValidDateRange(vm.toFilter.fromDate, vm.toFilter.toDate);
        if(!isValidDateRange){
          NotificationService.simpleErrorToast({
            title: 'global.table.noRecordsFound',
            message: 'alertMessage.SELECT_VALID_DATE_RANGE'
          });
          return false;
        }
        getAllJobs();
      }
    
      /**
       * [reset reset to filter]
       */
      function reset(){
        vm.toFilter.fromDate = UTCDateService.utcFromDate(vm.todaysDate);
        vm.toFilter.toDate = UTCDateService.utcToDate(vm.todaysDate);
        vm.warehouseId = "";
        vm.toType = "Transfer";
        vm.toFilter.status = "";
        vm.toFilter.variance = "";
        vm.getAllJobs();
      }

      /**
       * [deleteJob Deletes specific line Item]
       * @param {*} jobDocId 
       */
      function deleteJob(jobDocId) {
        SatsManagementService.deleteJob(jobDocId).then(function(response) {
          NotificationService.success({
            title: 'SO Job',
            message: 'alertMessage.DELETED_SUCCESSFULLY'
          });
          vm.loadAll();
        }, function(error) {
          NotificationService.error({
            title: 'Job Deletion',
            error: error
          });
        });
      }

      /**
       * [confirmDelete Confirmation box for deletion]
       * @param {*} ev 
       * @param {*} toJob 
       */
      function confirmDelete (ev, toJob) {
        if(toJob.status==='POSTED'){
          NotificationService.simpleErrorToast({
            title: 'alertMessage.ERR_VALIDATION',
            message: 'error.CAN_NOT_DELETE_POSTED_JOB'
          });
          return false;
        }
        var confirm = $mdDialog.confirm()
        .title('Are you sure you want to delete Item - ' + toJob.jobDocumentNumber + ' ? ')
        .textContent('This action could not be reverted back.')
        .targetEvent(ev)
        .ok('Delete')
        .cancel('Cancel');
  
        $mdDialog.show(confirm).then(function() {
          vm.deleteJob(toJob.id);
        }, function() {});
      }
  
      function cancel () {
        $mdDialog.cancel();
      }
      
      vm.loadAll();
    }
  })();
  