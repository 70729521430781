/**
 * @Author: sneha
 * @Date:   2019-10-03T16:30:55+05:30
 * @Project: Ezyprocure
 * @Filename: ezypayment.sip-invoice-authorization-request.service.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-10-14T15:41:47+05:30
 */





 (function() {
   'use strict';

   angular.module('ezyprocureApp').factory('EzypaymentSipInvoiceAuthorizationRequestService', EzypaymentSipInvoiceAuthorizationRequestService);

   EzypaymentSipInvoiceAuthorizationRequestService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

   function EzypaymentSipInvoiceAuthorizationRequestService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

     var EzypaymentSipInvoiceAuthorizationRequestService = {
       getSipInvoiceAuthorizationRequests: getSipInvoiceAuthorizationRequests,
       updateSIPApprovalWithStatus: updateSIPApprovalWithStatus,
       getSipInvoiceAuthorizationDetails:getSipInvoiceAuthorizationDetails
     };

     return EzypaymentSipInvoiceAuthorizationRequestService;


     /**
      * [getSipInvoiceAuthorizationRequests Get SIP Invoice authorization requests]
      * @param {*} params 
      */
     function getSipInvoiceAuthorizationDetails(id) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'invoice-approval-requests/'+id,
          method: "GET",
        }).then(function(response) {
          deferred.resolve(response);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
     }

     /**
      * [getSipInvoiceAuthorizationRequests Get SIP Invoice authorization requests]
      * @param {*} params 
      */
     function getSipInvoiceAuthorizationRequests(params) {
       var deferred = $q.defer();
       $http({
         url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'invoice-approval-requests',
         method: "GET",
         params: params
       }).then(function(data) {
         deferred.resolve(data);
       }).catch(function(message) {
         deferred.reject(message);
       });
       return deferred.promise;
     }

     /**
      * [updateSIPApprovalWithStatus Updates the status of approval id list]
      * @param {*} approvalIdList 
      * @param {*} action 
      */
     function updateSIPApprovalWithStatus(approvalIdList, action, remarks){
       var deferred = $q.defer();
       $http({
         url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'invoice-approval-requests/' + action,
         method: "PUT",
         data: approvalIdList,
         params: {"remarks": remarks}
       }).then(function(data) {
         deferred.resolve(data);
       }).catch(function(message) {
         deferred.reject(message);
       });
       return deferred.promise;
     }


   }
 })();
