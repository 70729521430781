/*
 * @Author: Gayathri
 * @Date:2018-12-26 11:46:35
 * @Project: Ezyprocure
 * @Filename: buyer.order-template-user-mapping.service.js
 * @Last Modified by: Ajay
 * @Last Modified time: 2019-02-05 10:43:53
 */
(function () {
  'use strict';

  angular.module('ezyprocureApp').factory('BuyerOrderTemplateMappingService', BuyerOrderTemplateMappingService);

  BuyerOrderTemplateMappingService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  function BuyerOrderTemplateMappingService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var BuyerOrderTemplateMappingService = {
      createTemplateBuyerUserMapping: createTemplateBuyerUserMapping,
      getAllTemplateBuyerUserMappings: getAllTemplateBuyerUserMappings,
      deleteTemplateBuyerUserMapping: deleteTemplateBuyerUserMapping
    };
    return BuyerOrderTemplateMappingService;

    //Create Template and User mapping
    function createTemplateBuyerUserMapping(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ORDER_TEMPLATE_USER_MAPPING_RESOURCE,
        method: "POST",
        params: params,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    
    //Get Template - User mapping
    function getAllTemplateBuyerUserMappings(templateId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ORDER_TEMPLATE_USER_MAPPING_RESOURCE,
        method: "GET",
        params: templateId,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    // Delete existing Template and User mapping
    function deleteTemplateBuyerUserMapping(mapping) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.ORDER_TEMPLATE_USER_MAPPING_RESOURCE + '/' + mapping).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
