/*
 * @Author: Jayalekshmi 
 * @Date: 2018-08-08 11:37:40 
 * @File: checkDecimal.filter.js 
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2018-08-08 16:04:32
 */

(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .filter('displayDecimal', displayDecimal);
  
    function displayDecimal() {
      return displayDecimal;
      function displayDecimal(number, fractionSize) {
        if(!number){
          return 0;
        }
        return number % 1 === 0 ? number.toFixed(0): number.toFixed(fractionSize);
      }
    }
  })();
  
