/**
 * @Author: sneha
 * @Date:   2019-10-03T16:30:40+05:30
 * @Project: Ezyprocure
 * @Filename: ezypayment.sip-invoice-authorization-request.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-10-15T14:13:17+05:30
 */



 (function() {
   'use strict';

   angular
   .module('ezyprocureApp')
   .controller('EzypaymentSipInvoiceAuthorizationRequestController', EzypaymentSipInvoiceAuthorizationRequestController);

   EzypaymentSipInvoiceAuthorizationRequestController.$inject = ['EzypaymentSipInvoiceAuthorizationRequestService', 'NotificationService', '$mdDialog', 'BuyerBuyNowService', '$filter', 'GLOBAL_CONSTANTS', 'paginationConstants', 'UTCDateService'];

   function EzypaymentSipInvoiceAuthorizationRequestController(EzypaymentSipInvoiceAuthorizationRequestService, NotificationService, $mdDialog, BuyerBuyNowService, $filter, GLOBAL_CONSTANTS, paginationConstants, UTCDateService) {

     var vm = this;
     vm.init = init;
     vm.getSipInvoiceRequests = getSipInvoiceRequests;
     vm.filter = {};
     vm.todaysDate = new Date();
     vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
     vm.checkAllInvoicesForApproval = checkAllInvoicesForApproval;
     vm.query = {
       limit: paginationConstants.itemsPerPage,
       page: 1
     };
     vm.limitOptions = paginationConstants.limitOptions;
     vm.updateInvoiceApprovalStatus = updateInvoiceApprovalStatus;
     vm.approvalIdList = [];
     vm.getSelectedApprovalIdList = getSelectedApprovalIdList;
     vm.approvalRequestTypes = GLOBAL_CONSTANTS.SIP_APPROVAL_STATUSES;
     vm.sipApproveOrReject = sipApproveOrReject;
     vm.cancel = cancel;
     
     function init(){
       vm.getSipInvoiceRequests();
     }
    /*
    *[sipApproveOrReject call the remark popup]
    */
     function sipApproveOrReject(action){
      vm.approvalIdList = vm.getSelectedApprovalIdList(vm.sipInvoiceRequestList);
      if(vm.approvalIdList.length<1){
        NotificationService.simpleErrorToast({
          title: 'global.sipInvoiceApproval',
          message: 'alertMessage.SELECT_ITEMS_TO_CONTINUE'
        });
        return false;
      }
      vm.action = action;
      $mdDialog.show({
        templateUrl: 'app/ezypayment-modules/buyer-modules/sip-invoice-authorization-request/ezypayment.sip-invoice-authorization-request.temp.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }
    function cancel(){
      $mdDialog.cancel();
    }

    function sortByStatus(a, b) {
      if (a.approvalStatus < b.approvalStatus) {
        return 1;
      }
      if (a.approvalStatus > b.approvalStatus) {
        return -1;
      }
      return 0;
    }

     /**
      * [getSipInvoiceRequests Get all SIP invoice requests]
      */
     function getSipInvoiceRequests(){
       EzypaymentSipInvoiceAuthorizationRequestService.getSipInvoiceAuthorizationRequests({
         approvalRequestNumber: !vm.filter.approvalRequestNumber? '' : vm.filter.approvalRequestNumber,
         search: !vm.filter.search? '': vm.filter.search,
         status: !vm.filter.status? '': vm.filter.status,
         fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
         toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
         page: vm.query.page - 1,
         size: vm.query.limit,
         sort: ["approvalStatus,desc"]
       }).then(function(response){
        if(Array.isArray(response.data) && response.data.length) {
          response.data.sort(sortByStatus);
        }
         vm.sipInvoiceRequestList = response.data;
         vm.totalItems = response.headers('X-Total-Count');
       },function(error) {
         NotificationService.error({
           title: 'SIP Authorization Request',
           error: error
         });
       });
     }

     //Select all items for approval in Buyer New Items For approval page
     function checkAllInvoicesForApproval() {
       vm.selectAll ? vm.selectAll = true : vm.selectAll = false;
       angular.forEach(vm.sipInvoiceRequestList, function(approvalRequiredItem) {
         approvalRequiredItem.selected = vm.selectAll;
       });
     }

     /**
      * [updateInvoiceApprovalStatus Updates the Invoice approval status]
      * @param {*} action 
      */
     function updateInvoiceApprovalStatus() {
      vm.cancel();
       
       EzypaymentSipInvoiceAuthorizationRequestService.updateSIPApprovalWithStatus(vm.approvalIdList, vm.action, vm.remarks).then(function(response) {
         NotificationService.success({
           title: 'global.sipInvoiceApproval',
           message: 'alertMessage.UPDATED_SUCCESSFULLY'
         });
         vm.filter.fromDate = '';
         vm.filter.toDate = '';
         vm.getSipInvoiceRequests();
       }, function(error) {
         NotificationService.error({
           title: 'global.sipInvoiceApproval',
           error: error
         });
       });
     }

     /**
     * [getSelectedItemIdList description]
     * @param  {[object]} items [description]
     * @return {[type]}       [description]
     */

     function getSelectedApprovalIdList(items) {
       var itemIds = [];
       //Fetch all ids of supplierItem in vm.supplierItems where supplierItem.selected = true
       var selectedItems = _.filter(items, {
         'selected': true
       });
       itemIds = _.map(selectedItems, _.property('id'));
       return itemIds;
     }
   }
 })();
