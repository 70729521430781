/**
* @Author: Abhay
* @Date:   28-April-2021 16:03 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.axs-transaction-details.service.js
* @Last modified by:   Abhay
* @Last modified time: 28-April-2021 16:03 +05:30
*/

 (function () {
    'use strict';

    angular.module('ezyprocureApp').factory('AxsTransactionDetailService', AxsTransactionDetailService);

    AxsTransactionDetailService.$inject = ['$window', '$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS', 'GLOBAL_CONSTANTS'];

    function AxsTransactionDetailService($window, $http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS, GLOBAL_CONSTANTS) {

        var axsTransactionDetailService = {
            axsUpdate: axsUpdate,
            axsSummary: axsSummary,
            getPaymentDetails: getPaymentDetails
        };

        function axsUpdate(params) {
            var deferred = $q.defer();
            $http({
                url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'axs/update/test',
                method: "GET",
                params: params
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (message) {
                deferred.reject(message);
            });
            return deferred.promise;
        }

        function axsSummary(params) {
            var deferred = $q.defer();
            $http({
                url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'axs/summary/test',
                method: "GET",
                params: params
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (message) {
                deferred.reject(message);
            });
            return deferred.promise;
        }

        function getPaymentDetails(params, otp) {
            var deferred = $q.defer();
            $http({
                url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'axs/auth/capture',
                method: "POST",
                params: params,
                headers: { 'authorization-otp' : otp }
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (message) {
                deferred.reject(message);
            });
            return deferred.promise;
        }

        return axsTransactionDetailService;

    }
})();