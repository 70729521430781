/**
 * @Author: Ajay
 * @Date:   27-Dec-2017 16:11 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.adhoc-tools.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 04-Jan-2018 13:12 +05:30
 */



(function() {
  'use strict';
  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('buyer.adhoc-tools', {
      abstract: true,
      parent: 'buyer',
      url: '/adhoc-tools',
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          return $translate.refresh();
        }],
        hasModuleAccess: ['Auth','$q', '$state', function(Auth, $q, $state) {
          if(Auth.hasPermission('accessForAdhocTools')){
            return true;
          }else {
            return $q.reject('Unauthorized');
          }
        }]
      }
    });
  }
})();
