/*
 * @Author: Lovin Chacko 
 * @Date: 2021-03-31 11:46:16 
 * @Last Modified by: Lovin Chacko
 * @Last Modified time: 2021-05-06 00:27:45
 */


(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider

      .state('buyer.tools.manage-blanket-order', {
        parent: 'buyer.tools',
        url: '/manage-blanket-order',
        data: {
          authorities: ['ROLE_BUYER'],
          pageTitle: 'Manage Blanket Order'
        },
        views: {
          'content@': {
            templateUrl: 'app/common-modules/manage-blanket-order/manage-blanket-order.html',
            controller: 'ManageBlanketOrderController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,desc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
          }],
          hasModuleAccess: ['Auth', '$q', '$state', function (Auth, $q, $state) {
            if (Auth.hasPermission('accessForManageBlanketOrder')) {
              return true;
            } else {
              return $q.reject('Unauthorized');
            }
          }]
        }
      })
      .state('buyer.tools.manage-blanket-order.add', {
        parent: 'buyer.tools.manage-blanket-order',
        url: '/add',
        data: {
          authorities: ['ROLE_BUYER'],
          pageTitle: 'Add Blanket Order'
        },
        views: {
          'content@': {
            templateUrl: 'app/common-modules/manage-blanket-order/add/add.blanket-order.html',
            controller: 'AddBlanketOrderController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('businessUserMapping');
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
          }]
        }
      })
      .state('buyer.tools.manage-blanket-order.edit', {
        parent: 'buyer.tools.manage-blanket-order',
        url: '/edit/:id',
        data: {
          authorities: ['ROLE_BUYER'],
          pageTitle: 'Edit Blanket Order'
        },
        views: {
          'content@': {
            templateUrl: 'app/common-modules/manage-blanket-order/edit/edit.blanket-order.html',
            controller: 'EditBlanketOrderController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,desc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('businessUserMapping');
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
          }]
        }
      })
  }
})();
