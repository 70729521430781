/**
* @Author: Aravindan
* @Date:   18-Aug-2017 15:47 +05:30
* @Project: Ezyprocure
* @Filename: supplier.adhoc-po.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 01-Jun-2017 13:18 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
 .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];
  function stateConfig($stateProvider) {
    $stateProvider.state('buyer.adhoc-po', {
      parent: 'buyer',
      url: '/adhoc-po',
     data: {
        authorities: ['ROLE_BUYER']
      },
     views: {
       'content@': {
          templateUrl: 'app/buyer-modules/adhoc-po/buyer.adhoc-po.html',
         controller: 'BuyerAdhocPoController',
          controllerAs: 'vm'
       }
      },
      params: {
        page: {
          value: '1',
         squash: true
        },
       sort: {
          value: 'id,desc',
          squash: true
       },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('processOrder');
          $translatePartialLoader.addPart('buyerBusiness');
          return $translate.refresh();
        }]
      }
    })
    .state('buyer.adhoc-po.details', {
      parent: 'buyer.adhoc-po',
      url: '/details/:requirementNumber',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'View Adhoc PO'
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/adhoc-po/details/buyer.adhoc-po.details.html',
          controller: 'BuyerAdhocPoDetailsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('global');
         $translatePartialLoader.addPart('processOrder');
          $translatePartialLoader.addPart('promotion');
          $translatePartialLoader.addPart('items');
          return $translate.refresh();
        }]
      }
    });
  }
})();
