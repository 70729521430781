/**
 * @Author: Ajay
 * @Date:   21-Apr-2017 17:54 +05:30
 * @Project: Ezyprocure
 * @Filename: principal.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 26-Mar-2018 14:19 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .factory('Principal', Principal);

  Principal.$inject = ['$q', 'Account', '$localStorage', '$http', 'API_CONSTANTS'];

  function Principal($q, Account, $localStorage, $http, API_CONSTANTS) {
    var _identity,_authenticated = false;

    var service = {
      authenticate: authenticate,
      hasAnyAuthority: hasAnyAuthority,
      hasAuthority: hasAuthority,
      identity: identity,
      isAuthenticated: isAuthenticated,
      isIdentityResolved: isIdentityResolved,
      setUserHaveValidPlan: setUserHaveValidPlan,
      isUserHaveValidPlan: isUserHaveValidPlan,
      setUserVerifiedKYC: setUserVerifiedKYC,
      isUserVerifiedKYC: isUserVerifiedKYC,
      hasCbPlatform: hasCbPlatform,
      setHasCbPlatform: setHasCbPlatform,
      setCBEnabled: setCBEnabled,
      isCBEnabled: isCBEnabled,
      checkCBEnabled: checkCBEnabled,
      isUserVerifiedCB: isUserVerifiedCB,
      setUserVerifiedCB: setUserVerifiedCB
    };

    return service;

    function authenticate(identity) {
      _identity = identity;
      _authenticated = identity !== null;
    }

    // check if the user have any authority
    function hasAnyAuthority(authorities) {
      if (!_authenticated || !_identity || !_identity.authorities) {
        return false;
      }

      for (var i = 0; i < authorities.length; i++) {
        if (_identity.authorities.indexOf(authorities[i]) !== -1) {
          return true;
        }
      }

      return false;
    }

    // check if the user have authority to access the state
    function hasAuthority(authority) {
      if (!_authenticated) {
        return $q.when(false);
      }

      return this.identity().then(function(_id) {
        return _id.authorities && _id.authorities.indexOf(authority) !== -1;
      }, function(){
        return false;
      });
    }

    function identity(force) {
      var deferred = $q.defer();

      if (force === true) {
        _identity = undefined;
      }

      // check and see if we have retrieved the identity data from the server.
      // if we have, reuse it by immediately resolving
      if (angular.isDefined(_identity)) {
        deferred.resolve(_identity);

        return deferred.promise;
      }

      // retrieve the identity data from the server, update the identity object, and then resolve.
      Account.get().$promise
      .then(getAccountThen)
      .catch(getAccountCatch);

      return deferred.promise;

      function getAccountThen(account) {
        _identity = account.data;
        _authenticated = true;
        deferred.resolve(_identity);
      }

      function getAccountCatch() {
        _identity = null;
        _authenticated = false;
        deferred.resolve(_identity);
      }
    }

    function isAuthenticated() {
      return _authenticated;
    }

    function isIdentityResolved() {
      return angular.isDefined(_identity);
    }

    function setUserHaveValidPlan(isValid) {
      $localStorage["valid-user-plan"] = isValid;
    }

    function isUserHaveValidPlan() {
      return $localStorage["valid-user-plan"];
    }

    function setUserVerifiedKYC(isVerified) {
      $localStorage["verified-kyc"] = isVerified;
    }

    function isUserVerifiedKYC() {
      return $localStorage["verified-kyc"];
    }

    function setHasCbPlatform(hasCb) {
      $localStorage["has-cb-platform"] = hasCb;
    }

    function hasCbPlatform() {
      return $localStorage["has-cb-platform"];
    }

    function setCBEnabled(cbEnabled) {
      $localStorage["is-cb-Enabled"] = !!cbEnabled;
    }

    function isCBEnabled() {
      return $localStorage["is-cb-Enabled"];
    }

    function setUserVerifiedCB(cbVerified) {
      $localStorage["is-cb-Verified"] = !!cbVerified;
    }

    function isUserVerifiedCB() {
      return $localStorage["is-cb-Verified"];
    }

    function checkCBEnabled() {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'xborder/activated', {
        responseType: 'blob'
      })
        .then(function(data) {
          var reader = new FileReader();
          reader.onload = function() {
            var enabled;
            if(reader.result == 'true') {
              enabled = true;
            } else if(reader.result == 'false') {
              enabled = false;
            }
            setCBEnabled(enabled);
            deferred.resolve(enabled);
          }
          reader.readAsText(data.data);
        }).catch(function (message) {
          deferred.reject(message);
        });
      return deferred.promise;
    }
  }
})();
