/*
 * @Author: Lovin C 
 * @Date: 2021-07-29 15:28:00 
 * @Last Modified by: Lovin C
 * @Last Modified time: 2021-10-04 17:11:15
 */

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('EzypaymentPayInvoicesController', EzypaymentPayInvoicesController);

  EzypaymentPayInvoicesController.$inject = ['$mdDialog', '$state', 'PayInvoicesService', 'DuePaymentInvoicesService', '$filter', '$timeout', 'BuyerOCRService', 'BuyerBuyNowService', 'BuyerBusinessUserMapService', 'paginationConstants', 'pagingParams', 'UTCDateService', 'NotificationService', 'toastr', 'BrandManagementService', 'Auth', 'GLOBAL_CONSTANTS', 'BuyerDataService'];

  function EzypaymentPayInvoicesController($mdDialog, $state, PayInvoicesService, DuePaymentInvoicesService, $filter, $timeout, BuyerOCRService, BuyerBuyNowService, BuyerBusinessUserMapService, paginationConstants, pagingParams, UTCDateService, NotificationService, toastr, BrandManagementService, Auth, GLOBAL_CONSTANTS, BuyerDataService) {

    var vm = this;
    vm.init = init;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.filter = {};
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.selectAllRows = false;
    vm.checkAllRows = checkAllRows;
    vm.checkPaymentModel = checkPaymentModel;
    vm.getAllAllocatedSuppliersForBusiness = getAllAllocatedSuppliersForBusiness;
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.getInvoices = getInvoices;
    vm.getDuePaymentSummary = getDuePaymentSummary;
    vm.getSelectedPOs = getSelectedPOs;
    vm.getSelectedPOIds = getSelectedPOIds;
    vm.accessForOcr = Auth.hasPermission("accessForOcr");
    vm.invoiceTypes = GLOBAL_CONSTANTS.EZYPAYMENT_INVOICE_TYPES;
    vm.paymentMethods = GLOBAL_CONSTANTS.PAYMENT_METHODS;
    vm.checkAllExternalRows = checkAllExternalRows;
    vm.selectAllExternalRows = false;
    vm.getInternalInvoices = getInternalInvoices;
    vm.todaysDate = new Date();
    vm.exQuery = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    }
    vm.limitOptions = paginationConstants.limitOptions;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.filter.paymentMethod = vm.paymentMethods[0].value;
    vm.getExternalInvoices = getExternalInvoices;
    vm.confirmAndPay = confirmAndPay;
    vm.getAllBrandsOfBuyerList = getAllBrandsOfBuyerList;
    vm.paymentType = ['FAST'];
    vm.confirmRemoveExternalInvoiceModal = confirmRemoveExternalInvoiceModal;
    vm.removeExternalInvoice = removeExternalInvoice;
    vm.cancel = cancel;

    function init() {
      vm.getInternalInvoices();
      getExternalInvoices();
    }

    vm.init();

    function selectAll(dropdown) {
      switch (dropdown) {
        case 'SUPPLIERS':
          vm.filter.supplierIds = _.map(vm.supplierList, _.property('id'));
          break;
        case 'OUTLETS':
          vm.filter.businessOutletIds = _.map(vm.businessOutlets, _.property('id'));
          break;
        case 'BRANDS':
          vm.filter.brandIds = _.map(vm.brands, _.property('id'));
          break;
      }
    }

    function deselectAll(dropdown) {
      switch (dropdown) {
        case 'SUPPLIERS':
          vm.filter.supplierIds = [];
          break;
        case 'OUTLETS':
          vm.filter.businessOutletIds = [];
          break;
        case 'BRANDS':
          vm.filter.brandIds = [];
          break;
      }
    }

    function confirmRemoveExternalInvoiceModal(event, invoiceId) {
      var confirm = $mdDialog.confirm()
      .title($filter('translate')('alertMessage.REMOVE_INVOICE_CONFIRMATION'))
      .targetEvent(event)
      .ok('Delete')
      .cancel('Cancel');
  
      $mdDialog.show(confirm).then(function() {
        removeExternalInvoice(invoiceId);
      }, function() {});
    }

    function removeExternalInvoice(invoiceId){
      vm.cancel();
      DuePaymentInvoicesService.removeExternalInvoice(invoiceId).then(function(response) {
        NotificationService.success({
          title: 'External Invoice',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.filter.fromDate='';
        vm.filter.toDate='';
        vm.getExternalInvoices();
      }, function(error) {
        NotificationService.error({
          title: 'External Invoice',
          error: error
        });
      });
    }

    function cancel() {
      $mdDialog.cancel();
    }

    function getAllBrandsOfBuyerList() {
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        query: '',
        buyerId: BuyerDataService.getBuyerProfile().buyerUser.buyer.id,
        sort: sort()
      };
      BrandManagementService.getAllBrandsOfBuyerList(params)
        .then(function (response) {
          vm.brands = response;
        }, function (error) {
          NotificationService.error({
            title: 'Brand Listing',
            error: error
          });
        });
    }

    function checkAllRows() {
      vm.selectAllRows ? vm.selectAllRows = true : vm.selectAllRows = false;
      angular.forEach(vm.invoices, function (invoice) {
        invoice.selected = vm.selectAllRows;
      });
    }

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function getSelectedPOs(poItems) {
      var selectedPOs = _.filter(poItems, function (poItem) {
        return poItem.selected === true;
      });
      return selectedPOs;
    }

    function getSelectedPOIds(selectedPOs) {
      var purchaseOrderIdList = (vm.filter.invoiceType === 'INTERNAL') ? _.map(selectedPOs, _.property('purchaseOrder.id')) : _.map(selectedPOs, _.property('id'));
      return purchaseOrderIdList;
    }

    function getAllAllocatedSuppliersForBusiness() {
      var params = {
        businessId: !vm.filter.businessOutletIds ? '' : vm.filter.businessOutletIds,
        brandIds: !vm.filter.brandIds ? '' : vm.filter.brandIds,
      };
      BuyerBuyNowService.getAllAllocatedSuppliersForBusiness(params).then(function (response) {
        vm.supplierList = response.data;
      }, function (error) {
        NotificationService.error({
          title: 'Fetching Allocated Suppliers',
          error: error
        });
      });
    }

    function getAllBuyerBusinessOutlets() {
      BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser({
        brandIds: !vm.filter.brandIds ? [] : vm.filter.brandIds
      }).then(function (response) {
        vm.businessOutlets = response;
        vm.filter.supplierIds = [];
      }, function (error) {
        NotificationService.error({
          title: 'Buyer Business Outlets',
          error: error
        });
      });
    }

    function getInternalInvoices() {
      BuyerOCRService.getAllPurchaseOrders({
        page: vm.query.page - 1,
        size: vm.query.limit,
        query: !vm.filter.poNumber ? '' : vm.filter.poNumber,
        fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
        toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
        ocrInvoiceStatus: !vm.accessForOcr ? '' : 'ACCEPTED',
        ocrAcceptanceStatus: !vm.accessForOcr ? '' : 'ACCEPTED',
        invoiceStatus: !vm.accessForOcr ? 'PROCESSED' : '',
        geoExported: '',
        businessOutletIds: !vm.filter.businessOutletIds ? '' : vm.filter.businessOutletIds,
        brandIds: !vm.filter.brandIds ? '' : String(vm.filter.brandIds),
        supplierId: !vm.filter.supplierIds ? '' : String(vm.filter.supplierIds),
        paymentStatus: "'UNPAID','FAILED','VOID','CANCELLED'",
        kycStatus: 'SUCCESSFUL',
        module: 'payment',
        paymentMethod: vm.filter.paymentMethod,
        sort: sort(),
      }).then(function (response) {
        vm.invoices = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      }, function (error) {
        NotificationService.error({
          title: 'Fetching Invoices',
          error: error
        });
      });
    }

    function checkPaymentModel() {
      checkDdaAuthorization();
    }

    function confirmAndPay(event) {
      var selectedPOs = (vm.filter.invoiceType === 'INTERNAL') ? vm.getSelectedPOs(vm.invoices) : vm.getSelectedPOs(vm.externalInvoices);
      var selectedPOIds = vm.getSelectedPOIds(selectedPOs);
      if (selectedPOIds.length <= 0) {
        NotificationService.simpleErrorToast({
          title: 'global.fetchingInvoiceDetails',
          message: 'global.pleaseSelectInvoiceToPay'
        });
        return false;
      }
      $mdDialog.show({
        templateUrl: 'app/common-modules/ezypayment/ezypayment.payment.confirm-pay.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function checkDdaAuthorization() {
      var selectedPOs = (vm.filter.invoiceType === 'INTERNAL') ? vm.getSelectedPOs(vm.invoices) : vm.getSelectedPOs(vm.externalInvoices);
      getDuePaymentSummary(selectedPOs);

    }

    function getDuePaymentSummary(selectedPOs) {
      if (vm.filter.invoiceType === 'INTERNAL') {
        var data = _.map(selectedPOs, function (each) {
          return {
            'brandId': each.purchaseOrder.processOrder.buyerBusiness.brand.id,
            'invoiceAmount': each.purchaseOrder.ocrInvoiceAmount,
            'supplierId': null
          }
        });
      }
      else {
        var data = _.map(selectedPOs, function (each) {
          return {
            'brandId': each.brand.id,
            'invoiceAmount': each.invoiceAmount,
            'supplierId': null
          }
        });
      }
      PayInvoicesService.ddaValidation({ 'ocbcPaymentType': vm.ocbcPaymentType}, data).then(function (response) {
        var selectedPOIds = vm.getSelectedPOIds(selectedPOs);
        if (selectedPOIds.length > 0) {
          PayInvoicesService.setSelectedPOIds(selectedPOIds);
          PayInvoicesService.setInvoiceType(vm.filter.invoiceType);
          $state.go('ezypayment.buyer.edda-pay-invoices.details', { 'ocbcPaymentType':vm.ocbcPaymentType });
        } else {
          toastr.error('Please select invoices to pay.', 'Fetching Invoice Details');
        }
      },
        function (error) {
          NotificationService.error({
            title: 'DDA Authorization Required!',
            error: error
          });
        });
    }

    function checkAllExternalRows() {
      vm.selectAllExternalRows ? vm.selectAllExternalRows = true : vm.selectAllExternalRows = false;
      angular.forEach(vm.externalInvoices, function (invoice) {
        invoice.selected = vm.selectAllExternalRows;
      });
    }

    function getInvoices() {
      (vm.filter.invoiceType === 'INTERNAL') ? getInternalInvoices() : getExternalInvoices();
    }

    function getExternalInvoices() {
      DuePaymentInvoicesService.getExternalInvoices({
        page: vm.exQuery.page - 1,
        size: vm.exQuery.limit,
        poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber,
        fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
        toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
        brandIds: !vm.filter.brandIds ? '' : String(vm.filter.brandIds),
        supplierId: !vm.filter.supplierIds ? '' : String(vm.filter.supplierIds),
        paymentStatus: "'UNPAID','FAILED','VOID','CANCELLED'",
        kycStatus: 'SUCCESSFUL',
        paymentMethod: vm.filter.paymentMethod,
        sort: sort()
      }).then(function (response) {
        vm.externalInvoices = response.data;
        vm.externalTotalItems = response.headers('X-Total-Count');
      }, function (error) {
        NotificationService.error({
          title: 'Fetching External Invoices',
          error: error
        });
      });
    }

  }
})();
