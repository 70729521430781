/**
* @Author: Ajay
* @Date:   27-Feb-2017 16:21 +05:30
* @Project: Ezyprocure
* @Filename: supplier.items.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 10-Nov-2017 11:23 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierItemListController', SupplierItemListController);

  SupplierItemListController.$inject = ['$scope', '$rootScope', '$state', 'NotificationService', '$mdDialog', 'SupplierItemService', 'pagingParams', 'paginationConstants','$filter', 'EXPORT_CONSTANTS', 'CSVExportService', 'SupplierDataService'];

  function SupplierItemListController($scope, $rootScope, $state, NotificationService, $mdDialog, SupplierItemService, pagingParams, paginationConstants,$filter, EXPORT_CONSTANTS, CSVExportService, SupplierDataService) {
    var vm = this;

    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;

    vm.loadAll = loadAll;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.exportCSV = exportCSV;
    vm.exportCSVForInventory = exportCSVForInventory;
    vm.SupplierManageItemsCSVHeader = EXPORT_CONSTANTS.CSV.SupplierManageItemsCSVHeader;
    vm.SupplierManageItemsCSVHeaderForInv = EXPORT_CONSTANTS.CSV.SupplierManageItemsCSVHeaderForInv;
    vm.accessForEzyinventory = SupplierDataService.getSupplierProfile().accessDTO.accessForEzyinventory;

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function loadAll (search) {
      if (!search) {
        vm.searchText = '';
      }
      SupplierItemService.getAllSupplierItems({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        buyerId: '',
        query: vm.searchText
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.supplierItems = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'Items Listing',
          error: error
        });
      });
    }

    vm.loadAll();

    function exportCSV(){
      return CSVExportService.manageItemsCSV(vm.supplierItems);
    }

    function exportCSVForInventory(){
      return CSVExportService.manageItemsCSVForInventory(vm.supplierItems);
    }

    function deleteSupplierItem(supplierItemId) {
      SupplierItemService.deleteSupplierItem(supplierItemId).then(function(response) {
        NotificationService.success({
          title: 'Item',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'Item Deletion',
          error: error
        });
      });
    }

    vm.showConfirm = function(ev, supplierItem) {
      var confirm = $mdDialog.confirm()
      .title($filter('translate')('alertMessage.SUPPLIER_ITEM_DELETION_APPROVAL'))
      .textContent($filter('translate')('alertMessage.COULD_NOT_BE_REVERTED_BACK'))
      .targetEvent(ev)
      .ok($filter('translate')('global.buttons.delete'))
      .cancel($filter('translate')('global.buttons.cancel'));

      $mdDialog.show(confirm).then(function() {
        deleteSupplierItem(supplierItem.id);
      }, function() {});
    };
  }
})();
