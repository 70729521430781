/**
* @Author: Allan
* @Date:   21-August-2018 16:15 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.buyer.state.js
* @Last modified by:   Allan
* @Last modified time: 23-August-2018 19:03 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('ezypayment.buyer', {
      parent: 'ezypayment',
      url: '/buyer',
      data: {
        authorities: ['ROLE_BUYER']
      },
      views: {
        'navbar@': {
          templateUrl: 'app/ezypayment-modules/buyer-modules/common/ezypayment.buyer.navbar.html',
          controller: 'EzyPaymentBuyerNavbarController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('buyerDashboard');
          return $translate.refresh();
        }],
        hasModuleAccess: ['Auth','$q', '$state', function(Auth, $q, $state) {
          if(Auth.hasPermission('accessForPayViaUOBVPS')){
            return true;
          }else {
            return $q.reject('Unauthorized');
          }
        }]
      }
    })
  }
})();
