/**
 * @Author: Ajay
 * @Date:   18-Apr-2018 10:13 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.manage-approval-level.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 07-May-2018 15:08 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider

    .state('buyer.tools.manage-approval-level', {
      parent: 'buyer.tools',
      url: '/manage-approval-level',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'Manage Approval Level'
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/tools/manage-approval-level/buyer.manage-approval-level.html',
          controller: 'BuyerManageApprovalLevelController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }],
        hasModuleAccess: ['Auth','$q', '$state', function(Auth, $q, $state) {
          if(Auth.hasPermission('accessForManageApprovalLevel')){
            return true;
          }else {
            return $q.reject('Unauthorized');
          }
        }]
      }
    })
    .state('buyer.tools.manage-approval-level.level-selection', {
      parent: 'buyer.tools.manage-approval-level',
      url: '/level-selection',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'Manage Approval - Choose Level'
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/tools/manage-approval-level/level-selection/buyer.manage-approval-level.level-selection.html',
          controller: 'BuyerManageApprovalLevelSelectionController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('businessUserMapping');
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    });
  }
})();
