/*
 * @Author: Jayalekshmi
 * @Date: 2018-07-23 11:47:30
 * @Filename: admin.statement-of-account-report.service.js
 * @Last modified by:   allan
 * @Last modified time: 2018-10-09T17:59:37+05:30
 */


(function(){
  'use strict';
  angular.module('ezyprocureApp').factory('EzypaymentAdminStatementOfAccountService', EzypaymentAdminStatementOfAccountService);

  EzypaymentAdminStatementOfAccountService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function EzypaymentAdminStatementOfAccountService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS){
    var adminStatementOfAccountService = {
      getAllStatementOfAccountReports : getAllStatementOfAccountReports,
      getAllStatementOfAccountReportsNfrnds : getAllStatementOfAccountReportsNfrnds,
      createGiroFile: createGiroFile,
      readGiroResponseFiles : readGiroResponseFiles,
      getAllMerchantList: getAllMerchantList
    };
    return adminStatementOfAccountService;


    function getAllMerchantList(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin/merchant/id',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    
    function getAllStatementOfAccountReports(params){
      var deffered = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + RESOURCE_URL_CONSTANTS.PAYMENT + 'giro-statement-report',
        method: 'GET',
        params: params
      }).then(function(data){
        deffered.resolve(data);
      }).catch(function(message){
        deffered.reject(message);
      });
      return deffered.promise;
    }

    function getAllStatementOfAccountReportsNfrnds(params) {
      var deffered = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + RESOURCE_URL_CONSTANTS.PAYMENT + 'nfrnd-statement-report',
        method: 'GET',
        params: params
      }).then(function(data){
        deffered.resolve(data);
      }).catch(function(message){
        deffered.reject(message);
      });
      return deffered.promise;
    }

    function createGiroFile(params) {
      var deferred = $q.defer();
      $http({
        url:API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PAYMENT + 'generate-giro',
        method: 'GET',
        params: params
      }).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  
    function readGiroResponseFiles() {
      var deferred = $q.defer();
      $http({
        url:API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PAYMENT + 'reading-host-to-host-response-files',
        method: 'GET'
        }).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    }
})();
