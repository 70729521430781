/**
* @Author: Sneha
* @Date:   10-Aug-2017 05:54 +05:30
* @Project: Ezyprocure
* @Filename: buyer.top-suppliers.state.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-02-25T15:54:43+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerPriceTrendsNewController', BuyerPriceTrendsNewController);

  BuyerPriceTrendsNewController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', '$stateParams', 'UTCDateService', 'ItemGroupService', 'paginationConstants', 'BuyerBuyNowService', 'CSVExportService', 'EXPORT_CONSTANTS','GLOBAL_CONSTANTS', 'SupplierItemService', 'NotificationService', 'BusinessReportService', 'MainItemGroupService', 'UomService'];

  function BuyerPriceTrendsNewController($scope, $rootScope, $q, $filter, $state, $stateParams, UTCDateService, ItemGroupService, paginationConstants, BuyerBuyNowService, CSVExportService, EXPORT_CONSTANTS, GLOBAL_CONSTANTS, SupplierItemService, NotificationService, BusinessReportService, MainItemGroupService, UomService) {
    var vm = this;
    vm.todaysDate = new Date(); // sets date filter

    // for loading dropdown for search filter
    vm.getAllAllocatedSuppliers = getAllAllocatedSuppliers;
    vm.loadItemGroups = loadItemGroups;
    vm.loadMainItemGroups = loadMainItemGroups;
    vm.selectAll = selectAll;
    vm.deSelectAll = deSelectAll;
    vm.viewTypes = GLOBAL_CONSTANTS.PRICE_TRENDS_VIEWS;
    vm.loadItemsByQuery = loadItemsByQuery;
    vm.loadUoms = loadUoms;
    vm.loadFilters = loadFilters;
    vm.validateDateFilter = validateDateFilter;
    vm.defaultBusinessReportDateFormat = GLOBAL_CONSTANTS.DEFAULT_BUSINESS_REPORT_DATE_FORMAT;

    //filter params
    vm.filter={
      supplierIdList: [],
      mainItemGroupIdList: [],
      itemGroupIdList: []
    };
    vm.query = {
      limit: paginationConstants.maxLimit,
      page: 1
    };

    //methods for retrieving price trends data
    vm.createPriceTrendDTO = createPriceTrendDTO;
    vm.getPriceTrendsReportsByMonth = getPriceTrendsReportsByMonth;
    vm.getPriceTrendsReportsByQuarter = getPriceTrendsReportsByQuarter;
    vm.getPriceTrendsReportsByDay = getPriceTrendsReportsByDay;
    vm.getPriceTrendsRawDataReports = getPriceTrendsRawDataReports;

    vm.months = GLOBAL_CONSTANTS.MONTHS;
    vm.getMonthsInSelectedOrder = getMonthsInSelectedOrder;
    vm.getQuarterlyMonthsInSelectedOrder = getQuarterlyMonthsInSelectedOrder;
    vm.tabIndex = 0; // set view tab index

    // methods for graph data
    vm.generateData = generateData;
    vm.getLineChart = getLineChart;

    //CSV table headers
    vm.priceTrendsRawDataHeader = EXPORT_CONSTANTS.CSV.priceTrendsRawDataHeader; // Raw Data CSV table header
    vm.priceTrendsViewHeader = EXPORT_CONSTANTS.CSV.priceTrendsViewHeader; //CSV table header for monthly and quarterly view
    vm.priceTrendsDayViewHeader = EXPORT_CONSTANTS.CSV.priceTrendsDayViewHeader; //CSV table header for daily view

    //CSV export methods
    vm.getQuarterlyPriceTrendsReportsCSV = getQuarterlyPriceTrendsReportsCSV; // CSV for quarterly view
    vm.getRawDataReportsCSV = getRawDataReportsCSV; // CSV for raw data view
    vm.getMonthlyPriceTrendsReportsCSV = getMonthlyPriceTrendsReportsCSV; // CSV for monthly view
    vm.getDailyPriceTrendsReportsCSV = getDailyPriceTrendsReportsCSV; //CSV for day view

    vm.getYear = getYear; // dynamic year calculation for reports view
    vm.getDateChangeArray = getDateChangeArray;

    vm.transformChip = transformChip;
    vm.autocompleteDemoRequireMatch = true;
    vm.selectedItem = null;
    vm.searchText = null;
    vm.filter.itemIdList = [];
    vm.supplierItems = [];

    vm.selectedItemList = []; //selected items in item chips autocomplete
    vm.addItemIdList = addItemIdList;
    vm.resetFilter = resetFilter;


function resetFilter(){
  vm.filter={
    toDate : vm.todaysDate,
    fromDate : vm.todaysDate,
    listView : '',
    itemGroupIdList: [],
    mainItemGroupIdList: [],
    supplierIdList: [],
    uoms : []
  };
}
    /**
    * [getYear get year for each row in month/quarterly view table according to row index]
    * @param  {[type]} index    [row index]
    * @param  {[type]} viewType [view selected by user]
    * @return {[type]}          [year]
    */
    function getYear(index, viewType){
      switch (vm.filter.listView) {
        case 'VIEW_BY_MONTH':
        if(index <= (12- ((vm.filter.fromDate).getMonth()+1))){
          vm.priceTrendsYear = vm.filter.fromDate.getFullYear();
        }
        else {
          vm.priceTrendsYear = vm.filter.toDate.getFullYear();
        }
        return vm.priceTrendsYear;
        break;

        case 'VIEW_BY_QUARTER':
        if(index <= (4 - vm.graphScale[0])){
          vm.priceTrendsYear = vm.filter.fromDate.getFullYear();
        }
        else {
          vm.priceTrendsYear = vm.filter.toDate.getFullYear();
        }
        return vm.priceTrendsYear;
      }
    }

    /**
    * [loadFilters loads filters according to selected view type for reports]
    * @param  {[type]} selectParam [view type]
    * @return {[type]}             [description]
    */
    function loadFilters(selectParam){
      vm.priceTrendItems = {};  //empty price trends object when view type filter is changed
      vm.tabIndex = 0;  // md-tab index to 0

      if(selectParam){
        if(selectParam === 'VIEW_BY_DAY'){
          vm.uoms = [];
        }
        else {
          vm.supplierItems = [];
          if (vm.filter.supplierIdList.length >0 || vm.filter.itemGroupIdList.length >0){
            vm.uoms = [];
            vm.loadUoms();
          }
        }
      }
    }

    /**
    * [getAllAllocatedSuppliers loads all suppliers mapped to the current buyer]
    * @return {[type]} [description]
    */
    function getAllAllocatedSuppliers() {
      if(vm.supplierList){
        return false;
      }
      var params = {'businessId': !vm.businessOutletId ? '' : vm.businessOutletId};
      BuyerBuyNowService.getAllAllocatedSuppliersForBusiness(params,
        {
          page: 0,
          size: paginationConstants.maxLimit,
          query: ''
        }).then(function(response){
          vm.supplierList = $filter('orderBy')(response.data, 'companyName');
          //if only one allocated supplier set it as default
          if(vm.supplierList.length === 1) {
            vm.supplierId = vm.supplierList[0].id;
          }
          vm.filter.supplierIdList = [];
        },function(error){
        });
      }

      /**
      * [loadMainItemGroups load main item groups]
      * @return {[type]} [description]
      */
      function loadMainItemGroups() {
        if (vm.mainItemGroups) {
          return false;
        }
        MainItemGroupService.getAllMainItemGroups({
          page: 0,
          size: paginationConstants.maxLimit,
          query: ''
        }).then(function(response) {
          vm.mainItemGroups = response.data;
          vm.filter.mainItemGroupIdList = [];

        }, function(error) {
          NotificationService.error({
            title:"Failed to load main item groups!",
            error: error
          });
        });
      }



      /**
      * [loadItemGroups loads all item groups]
      * @return {[type]} [description]
      */
      function loadItemGroups() {
        if(vm.itemGroupList){
          return false;
        }
        ItemGroupService.getAllItemGroups({
          page: 0,
          size: paginationConstants.maxLimit,
          query: ''
        }).then(function(response) {
          vm.itemGroupList = response.data;
          vm.filter.itemGroupIdList = [];
        }, function(error) {
        });
      }

      /**
      * [loadItemsByQuery gets items by searchQuery]
      * @param  {[type]} searchItemText [item text entered in items autocomplete]
      * @return {[type]}                [description]
      */
      function loadItemsByQuery(searchItemText) {
        var deferred = $q.defer();
        if(!searchItemText) {
          return false;
        }
        else {
          SupplierItemService.getItemsByQuery({
            supplierIdList: !vm.filter.supplierIdList ? [] : vm.filter.supplierIdList,
            mainItemGroupIdList: !vm.filter.mainItemGroupIdList ? [] : vm.filter.mainItemGroupIdList,
            itemGroupIdList: !vm.filter.itemGroupIdList ? [] : vm.filter.itemGroupIdList,
            query: searchItemText
          }
        ).then(function(response) {
          deferred.resolve($filter('orderBy')(response.data, 'itemName'));
        }, function(error) {
          deferred.reject(error);
        });
      }
      return deferred.promise;
    }


    /**
    * Return the proper object when the append is called.
    */
    /**
    * [transformChip -- Return the proper object when the append is called - md-chips]
    * @param  {[type]} chip [selected chip in items autocomplete]
    * @return {[type]}      [description]
    */
    function transformChip(chip) {
      // If it is an object, it's already a known chip
      if (angular.isObject(chip)) {
        return chip;
      }
    }

    /**
    * [addItemIdList adds itemIds selected in items autocomplete]
    */
    function addItemIdList(){
      vm.filter.itemIdList=  _.map(vm.selectedItemList, _.property('id'));
    }

    /**
    * [loadUoms loads all UOMS for selected itemGroups and suppliers]
    * @return {[type]} [description]
    */
    function loadUoms() {
      UomService.getQueryBasedUoms({
        itemGroupIdList: !vm.filter.itemGroupIdList ? [] : vm.filter.itemGroupIdList,
        supplierIdList: !vm.filter.supplierIdList ? [] : vm.filter.supplierIdList
      }).then(function(response) {
        vm.uoms = response.data;
      }, function(error) {
      });
    }

    /**
    * [selectAll select all values on click of Select All Button]
    * @param  {[type]} selectParam [dropdown for which Select All button was clicked]
    * @return {[type]}             [description]
    */
    function selectAll(selectParam) {
      switch (selectParam) {
        case 'SUPPLIERS':
        vm.filter.supplierIdList = _.map(vm.supplierList, _.property('id'));
        break;
        case 'MAIN ITEM GROUPS':
        vm.filter.mainItemGroupIdList =  _.map(vm.mainItemGroups, _.property('id'));
        break;
        case 'ITEM GROUPS':
        vm.filter.itemGroupIdList =  _.map(vm.itemGroupList, _.property('id'));
        break;
        case 'ITEMS':
        vm.filter.itemIdList =  _.map(vm.supplierItems, _.property('id'));
        break;
      }
    }

    /**
    * [deSelectAll deselect all values on click of Select All Button]
    * @param  {[type]} selectParam [dropdown for which Select All button was clicked]
    * @return {[type]}             [description]
    */
    function deSelectAll(selectParam) {
      switch (selectParam) {
        case 'SUPPLIERS':
        vm.filter.supplierIdList = [];
        break;
        case 'MAIN ITEM GROUPS':
        vm.filter.mainItemGroupIdList = [];
        break;
        case 'ITEM GROUPS':
        vm.filter.itemGroupIdList = [];
        break;
        case 'ITEMS':
        vm.filter.itemIdList = [];
        break;
      }
    }

    /**
    * [getMonthsInSelectedOrder arranges the selected month numbers in order of selected date range]
    * @return {[array]} [array of month numbers in order]
    */
    function getMonthsInSelectedOrder(){
      vm.monthsInOrder = [];
      if(moment(vm.filter.fromDate).isBefore(vm.filter.toDate, 'year')){ // fromDate.year < toDate.year

        // _.range(startMonth, 13, increment by 1) --> start filling from fromDate.month to 12th month (of last year)
        vm.monthsInOrder = vm.monthsInOrder.concat(_.range((moment(vm.filter.fromDate).month()+1), 13, 1));

        //  _.range(1, endMonth, increment by 1) --> start filling from 1 to toDate.month of next year
        vm.monthsInOrder = vm.monthsInOrder.concat(_.range(1, (moment(vm.filter.toDate).month()+2), 1));
      }
      else{  // fromDate.year === toDate.year (ie., only months of one year is selected)
        vm.monthsInOrder = vm.monthsInOrder.concat(_.range((moment(vm.filter.fromDate).month()+1), (moment(vm.filter.toDate).month()+2), 1)); //  _.range(startMonth, endMonth, increment by 1) --> start filling from fromDate.month to toDate.month of next year
      }
      return vm.monthsInOrder;
    }

    /**
    * [getQuarterlyMonthsInSelectedOrder arrange the selected months in order of date range and groups them according to financial quarter of the corresponding year]
    * @param  {[type]} months [the selected order of month numbers in order of selected date range obtained from getMonthsInSelectedOrder()]
    * @return {[array]}        [array of quarter-indices according to selected date year]
    */
    function getQuarterlyMonthsInSelectedOrder(months){
      vm.quartersInOrder = [];
      _.map(months, function(month){

        // check whether the quarter index has already been pushed into quarter array
        if(vm.quartersInOrder[vm.quartersInOrder.length-1] !== _.ceil(month/3)){

          // store quarter index according to the month index order
          vm.quartersInOrder = vm.quartersInOrder.concat(_.ceil(month/3));
        }
      })
      return vm.quartersInOrder;
    }

    /**
    * [createPriceTrendDTO creates a request DTO for retrieving price trend reports]
    * @param  {[type]} viewType [view type]
    * @return {[type]}          [boolean]
    */
    function createPriceTrendDTO(viewType){
      if(!vm.validateDateFilter()){ //validate whether date range selected is valid
        return false;
      }
      vm.tabIndex = 0; // change tab back to index 0

      // priceTrendDTO - request DTO containing all the params commonly required by all views
      vm.priceTrendDTO = {
        priceTrendStatus: vm.filter.listView,
        fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
        toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
        itemGroupIdList: !vm.filter.itemGroupIdList ? [] : vm.filter.itemGroupIdList,
        mainItemGroupIdList: !vm.filter.mainItemGroupIdList ? [] : vm.filter.mainItemGroupIdList,
        supplierIdList: !vm.filter.supplierIdList ? [] : vm.filter.supplierIdList
      }
      vm.priceTrendsViewHeader = []; // empty the CSV header
      vm.subItemGroupList = []; // item group list for table/csv header
      vm.graphData = [];
      vm.dateChangeArray = [];
      vm.graphScale = [];

      // add in params that are required specifically to selected view types
      switch (viewType) {
        case 'VIEW_BY_DAY':
        if(vm.filter.itemIdList.length>0 && vm.filter.itemIdList.length<10){
          vm.priceTrendDTO.itemIdList = !vm.filter.itemIdList ? [] : vm.filter.itemIdList;
          vm.graphTitle = "Price Trends- Day View";
          vm.getPriceTrendsReportsByDay(vm.priceTrendDTO);
        }
        else if(vm.filter.itemIdList.length === 0){
          NotificationService.simpleErrorToast({
            title: 'global.table.noRecordsFound',
            message: 'alertMessage.SELECT_ITEMS_TO_CONTINUE'
          });
        }
        else if(vm.filter.itemIdList.length > 10){
          NotificationService.simpleErrorToast({
            title: 'global.table.noRecordsFound',
            message: 'alertMessage.CANNOT_SELECT_MORE_THAN_TEN_ITEMS'
          });
        }
        break;

        case 'VIEW_BY_MONTH':
        vm.priceTrendDTO['uom'] = {
          'id': vm.filter.uom
        }
        vm.priceTrendsViewHeader = EXPORT_CONSTANTS.CSV.priceTrendsViewHeader;
        vm.getPriceTrendsReportsByMonth(vm.priceTrendDTO);
        vm.graphTitle = "Price Trends- Month View";
        break;

        case 'VIEW_BY_QUARTER':
        vm.priceTrendDTO['uom'] = {
          'id': vm.filter.uom
        };
        vm.priceTrendsViewHeader = EXPORT_CONSTANTS.CSV.priceTrendsViewHeader;
        vm.getPriceTrendsReportsByQuarter(vm.priceTrendDTO);
        vm.graphTitle = "Price Trends- Quarter View";
        break;
      }
    }


    /**
    * [getPriceTrendsReportsByMonth get price trends reports for month view]
    * @param  {[type]} priceTrendDTO [priceTrendDTO]
    * @return {[type]}               [description]
    */
    function getPriceTrendsReportsByMonth(priceTrendDTO){
      BusinessReportService.getPriceTrendsReportsByMonth(priceTrendDTO
      ).then(function(response) {
        vm.priceTrendItems = response.data;

        // array of sub item groups obtained in response - to be used as table column headers
        vm.subItemGroupList = Object.keys(vm.priceTrendItems);

        // array of month indices in order of selected date range
        vm.graphAxisScale = vm.getMonthsInSelectedOrder();

        // vm.graphScale - array of month names in order of selected date range
        vm.graphScale = _.map(vm.graphAxisScale,  function(scale){
          return vm.months[scale-1]
        });
        vm.generateData();

        // concat sub-item groups as table column headers dynamically for csv
        vm.priceTrendsViewHeader = vm.priceTrendsViewHeader.concat(vm.subItemGroupList);
      }, function(error) {
        NotificationService.error({
          title:"Price Trends",
          error: error
        });
      });
    }

    /**
    * [getMonthlyPriceTrendsReportsCSV get CSV report for monthly price trends]
    * @return {[type]} [description]
    */
    function getMonthlyPriceTrendsReportsCSV(){
      var deferred = $q.defer();
      var exportMetaData = {
        toDate : vm.filter.toDate,
        fromDate : vm.filter.fromDate,
        time : vm.graphScale // array of month names
      }
      if (!vm.priceTrendItems) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getMonthlyPriceTrendsReportsCSV(vm.priceTrendItems, exportMetaData);
    }


    /**
    * [getPriceTrendsReportsByQuarter get price trends reports for quarterly view]
    * @param  {[type]} priceTrendDTO [priceTrendDTO]
    * @return {[type]}               [description]
    */
    function getPriceTrendsReportsByQuarter(priceTrendDTO){
      BusinessReportService.getPriceTrendsReportsByQuarter(priceTrendDTO
      ).then(function(response) {
        vm.priceTrendItems = response.data;
        // array of sub item groups obtained in response - to be used as table column headers
        vm.subItemGroupList = Object.keys(vm.priceTrendItems);

        // concat sub-item groups as table column headers dynamically for csv
        vm.priceTrendsViewHeader = vm.priceTrendsViewHeader.concat(vm.subItemGroupList);

        // array of month indices in order of selected date range so as to compute quarter indices
        vm.graphAxisScale = vm.getMonthsInSelectedOrder();

        // array of quarter indices based on the month indices within the selected date range
        vm.graphScale = vm.getQuarterlyMonthsInSelectedOrder(vm.graphAxisScale);
        vm.generateData();
      }, function(error) {
        NotificationService.error({
          title:"Price Trends",
          error: error
        });
      });
    }


    /**
    * [getQuarterlyPriceTrendsReportsCSV get csv report for quarterly price trends]
    * @return {[type]} [description]
    */
    function getQuarterlyPriceTrendsReportsCSV(){
      var deferred = $q.defer();
      var exportMetaData = {
        toDate : vm.filter.toDate,
        fromDate : vm.filter.fromDate,
        time : vm.graphScale
      }
      if (!vm.priceTrendItems) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getQuarterlyPriceTrendsReportsCSV(vm.priceTrendItems, exportMetaData);
    }


    /**
    * [getPriceTrendsReportsByDay get price trends reports for day view]
    * @param  {[type]} priceTrendDTO [priceTrendDTO]
    * @return {[type]}               [description]
    */
    function getPriceTrendsReportsByDay(priceTrendDTO){
      BusinessReportService.getPriceTrendsReportsByDay(priceTrendDTO)
      .then(function(response) {
        vm.priceTrendItems = response.data;
        if(!_.size(vm.priceTrendItems)){
          NotificationService.simpleErrorToast({
            title: 'global.navbar.priceTrends',
            message: 'global.table.noRecordsFound'
          });
        }
        var ordered = {};
        _(vm.priceTrendItems).keys().sort().each(function (key) {
          ordered[key] = vm.priceTrendItems[key];
        });
        vm.priceTrendItems = ordered;
        vm.getDateChangeArray();
      }, function(error) {
        NotificationService.error({
          title:"Price Trends Raw Data",
          error: error
        });
      });
    }


    /**
    * [getDailyPriceTrendsReportsCSV get csv report for daily price trends]
    * @return {[type]} [description]
    */
    function getDailyPriceTrendsReportsCSV(){
      var deferred = $q.defer();
      var exportMetaData = {
        viewType: vm.filter.listView,
        toDate : vm.filter.toDate,
        fromDate : vm.filter.fromDate,
        time : vm.graphScale
      }
      if (!vm.priceTrendItems) {
        toastr.error('Please search & Submit to Continue', 'No Data Found');
        return deferred.promise;
      }
      return CSVExportService.getDailyPriceTrendsReportsCSV(vm.priceTrendItems, exportMetaData);
    }


    /**
    * [getPriceTrendsRawDataReports get price trends reports for raw data]
    * @return {[type]} [description]
    */
    function getPriceTrendsRawDataReports(){
      BusinessReportService.getPriceTrendsRawDataReports(
        {
          priceTrendStatus: vm.filter.listView,
          fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
          toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
          itemGroupIdList: !vm.filter.itemGroupIdList ? [] : vm.filter.itemGroupIdList,
          mainItemGroupIdList: !vm.filter.mainItemGroupIdList ? [] : vm.filter.mainItemGroupIdList,
          supplierIdList: !vm.filter.supplierIdList ? [] : vm.filter.supplierIdList,
          uom : {
            'id': vm.filter.uom
          }
        },
        {
          page: 0,
          size: paginationConstants.maxLimit,
          query: ''
        }
      ).then(function(response) {
        vm.rawDataItems = _.orderBy(response.data, ['itemGroup'], ['asc']); // arrange response data by order of itemgroups
      }, function(error) {
        NotificationService.error({
          title:"Price Trends Raw Data",
          error: error
        });
      });
    }

    /**
    * [getRawDataReportsCSV get price trends reports csv for raw data]
    * @return {[type]} [description]
    */
    function getRawDataReportsCSV(){
      var deferred = $q.defer();
      if (!vm.rawDataItems) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getPriceTrendsRawDataCSV(vm.rawDataItems);
    }


    /**
    * [getLineChart plot line chart for price trends]
    * @return {[type]} [description]
    */
    function getLineChart(){
      vm.options = {
        chart: {
          type: 'lineChart',
          height: 450,
          margin : {
            top: 20,
            right: 20,
            bottom: 90,
            left: 80
          },
          x: function(d){ return d.x; },
          y: function(d){ return d.y; },
          showLegend: true,

          xAxis: {
            axisLabel: 'Time',
            showMaxMin: false,
            axisLabelDistance: 10,
            rotateLabels: 0,
            reduceXTicks: false,
            height: 140,
            tickFormat: function(d){
              //tick format for x-axis varies according to selected view
              switch (vm.filter.listView) {
                case 'VIEW_BY_MONTH':
                if(d <= (12-(moment(vm.filter.fromDate).month()+1))){
                  return vm.graphScale[d] + ' ' + moment(vm.filter.fromDate).year();
                }
                else {
                  return vm.graphScale[d]  + ' ' + moment(vm.filter.toDate).year();
                }
                break;

                case 'VIEW_BY_QUARTER':
                if(d <= (4 - vm.graphScale[0])){
                  return 'Q'+ vm.graphScale[d] + ' ' + moment(vm.filter.fromDate).year();
                }
                else {
                  return 'Q'+ vm.graphScale[d]  + ' ' + moment(vm.filter.toDate).year();
                }
                break;

                case 'VIEW_BY_DAY':
                return moment(vm.graphScale[d], 'DD-MM-YYYY').format('DD-MMMM-YYYY')
                // return vm.graphScale[d]
                break;
              }
            }
          },
          yAxis: {
            axisLabel: 'Value',
            axisLabelDistance: 5,
            tickFormat: function(d){
              return d3.format('.2f')(d);
            }
          }
        },
        title: {
          enable: true,
          text: vm.graphTitle
        }
      };
    }


    /**
    * [generateData rearrange the price trends data according selected view type for graph]
    * @return {[type]} [description]
    */
    function generateData() {
      vm.graphData = _.map(vm.priceTrendItems, function(itemObj, key){
        return{
          key : key, // sub-item group
          values :
          _.map(vm.graphScale, function(time, index){
            // returns an array of objects with 'x' as index is based on the indices of month/quarter array rendered for x-axis in line graph and 'y' as price
            switch (vm.filter.listView) {
              case 'VIEW_BY_MONTH':
              return {
                x: index,
                y: (index <= (12-(moment(vm.filter.fromDate).month()+1)))? itemObj[moment(vm.filter.fromDate).year()][time] : itemObj[moment(vm.filter.toDate).year()][time]
              }
              break;
              case 'VIEW_BY_QUARTER':
              return {
                x: index,
                y: (index <= (4- vm.graphScale[0]))? (itemObj[moment(vm.filter.fromDate).year()][time] ? itemObj[moment(vm.filter.fromDate).year()][time] : 0 ) : (itemObj[moment(vm.filter.toDate).year()][time] ? itemObj[moment(vm.filter.toDate).year()][time] : 0)
              }
              break;

              case 'VIEW_BY_DAY':
              // vm.api.update();
              return {
                x: index,
                y: (_.findIndex(itemObj[moment(time, 'DD-MM-YYYY').year()], ['dateChange', moment(time, 'DD-MM-YYYY').format('YYYY-MM-DD')])>=0) ? itemObj[moment(time, 'DD-MM-YYYY').year()][_.findIndex(itemObj[moment(time, 'DD-MM-YYYY').year()], ['dateChange', moment(time, 'DD-MM-YYYY').format('YYYY-MM-DD')])]['price'] : 0
              }
              break;
            }
          })

        }

      });
      vm.getLineChart();
    }

/**
 * [getDateChangeArray arrange day data object according to day dates before passing to nvd3 data generator]
 * @return {[type]} [description]
 */
    function getDateChangeArray(){
      vm.dateChangeArray = _.uniq(_.flattenDeep(_.map(vm.priceTrendItems,function(items){
        return _.map(items, function(yearObj){
          return _.map(yearObj, function(item){
            return moment(item.dateChange).format('DD-MM-YYYY')
          })
        })
      })
    ));
    vm.graphScale = vm.dateChangeArray.sort(function(a, b){return (moment(a, "DD-MM-YYYY")).diff((moment(b, "DD-MM-YYYY")));});
    vm.generateData();
  }


  /**
  * [validateDateFilter validates whether the selected date range is one year or not]
  * @return {[type]} [description]
  */
  function validateDateFilter() {
    var isValidSelection = true;
    var isSameMonth = moment(vm.filter.fromDate).isSame(vm.filter.toDate, 'month');
    var interval = moment(vm.filter.toDate).diff(moment(vm.filter.fromDate), 'years');
    if(moment(vm.filter.toDate).isBefore(vm.filter.fromDate)){
      isValidSelection = false;
      NotificationService.simpleErrorToast({
        title: 'global.navbar.priceTrends',
        message: 'alertMessage.SELECT_VALID_DATE_RANGE'
      });
    }
    if(isSameMonth  === false && interval >= 1){
      isValidSelection = false;
      NotificationService.simpleErrorToast({
        title: 'global.navbar.priceTrends',
        message: 'alertMessage.SELECT_MAX_ONE_YEAR'
      });
    }else if(interval > 1){
      isValidSelection = false;
      NotificationService.simpleErrorToast({
        title: 'global.navbar.priceTrends',
        message: 'alertMessage.SELECT_VALID_DATE_RANGE'
      });
    }
    return isValidSelection;
  }


}
})();
