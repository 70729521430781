/*
 * @Author: Lovin C 
 * @Date: 2021-07-29 15:28:35 
 * @Last Modified by: Lovin C
 * @Last Modified time: 2021-10-04 17:07:24
 */

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
      .state('ezypayment.buyer.edda-pay-invoices', {
        parent: 'ezypayment.buyer',
        url: '/edda-pay-invoices',
        data: {
          authorities: ['ROLE_BUYER']
        },
        views: {
          'content@': {
            templateUrl: 'app/ezypayment-modules/buyer-modules/edda-pay-invoices/ezypayment.edda-pay-invoices.html',
            controller: 'EzypaymentPayInvoicesController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,asc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('purchaseOrder');
            $translatePartialLoader.addPart('invoiceOCR');
            $translatePartialLoader.addPart('externalPayment');
            return $translate.refresh();
          }]
        }
      })
      .state('ezypayment.buyer.edda-pay-invoices.details', {
        parent: 'ezypayment.buyer.edda-pay-invoices',
        url: '/details?ocbcPaymentType?paymentType',
        data: {
          authorities: ['ROLE_BUYER']
        },
        views: {
          'content@': {
            templateUrl: 'app/ezypayment-modules/buyer-modules/edda-pay-invoices/details/ezypayment.edda-pay-invoices.details.html',
            controller: 'EzypaymentPayInvoicesDetailsController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
            return true;
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('purchaseOrder');
            $translatePartialLoader.addPart('invoiceOCR');
            $translatePartialLoader.addPart('externalPayment');
            return $translate.refresh();
          }]
        }
      })
      .state('ezypayment.buyer.edda-pay-invoices.details.transaction-report', {
        parent: 'ezypayment.buyer.edda-pay-invoices.details',
        url: '/transaction-report',
        data: {
          authorities: ['ROLE_BUYER']
        },
        views: {
          'content@': {
            templateUrl: 'app/ezypayment-modules/buyer-modules/edda-pay-invoices/details/ezypayment.edda-pay-invoices.details.transaction-report.html',
            controller: 'EzypaymentPayInvoicesDetailsController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
            return true;
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
          }]
        }
      })
  }
})();
