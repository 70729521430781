/**
* @author: Abhay
* @date:   29-Mar-2021 15:05 +05:30
* @Project: Ezyprocure
* @Filename: admin.manage-xborder-consents.state.js
* @Last modified by:   Abhay
* @Last modified time: 29-Mar-2021 15:05 +05:30
*/



(function () {
    'use strict';

    angular
        .module('ezyprocureApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('admin.crossborder-consents', {
                parent: 'admin',
                url: '/crossborder-consents?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'Crossborder Consents'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin-modules/manage-xborder-consents/admin.manage-xborder-consents.html',
                        controller: 'CrossBorderConsentsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'optInDate,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('user-management');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
