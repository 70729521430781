/**
 * @Author: sneha
 * @Date:   2019-10-03T16:31:03+05:30
 * @Project: Ezyprocure
 * @Filename: ezypayment.sip-invoice-authorization-request.state.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-10-14T12:10:55+05:30
 */



 (function() {
   'use strict';

   angular
   .module('ezyprocureApp')
   .config(stateConfig);

   stateConfig.$inject = ['$stateProvider'];

   function stateConfig($stateProvider) {
     $stateProvider
     .state('ezypayment.buyer.sip-invoice-authorization-request', {
       parent: 'ezypayment.buyer',
       url: '/sip-invoice-authorization-request',
       data: {
         authorities: ['ROLE_BUYER']
       },
       views: {
         'content@': {
           templateUrl: 'app/ezypayment-modules/buyer-modules/sip-invoice-authorization-request/ezypayment.sip-invoice-authorization-request.html',
           controller: 'EzypaymentSipInvoiceAuthorizationRequestController',
           controllerAs: 'vm'
         }
       },
       resolve: {
         pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
           return true;
         }],
         translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
           $translatePartialLoader.addPart('global');
           $translatePartialLoader.addPart('invoice');
           $translatePartialLoader.addPart('invoiceOCR');

           return $translate.refresh();
         }]
       }
     })
   }
 })();
