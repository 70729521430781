/**
 * @Author: Abhay
 * @Date:   2021-01-19 13:00:30
 * @Filename: whats-new.service.js
 * @Last modified by:   Abhay
 * @Last modified time: 2021-01-19 13:00:30
 */

(function () {
    'use strict';

    angular.module('ezyprocureApp').factory('WhatsNewService', WhatsNewService);

    WhatsNewService.$inject = ['$window', 'GLOBAL_CONSTANTS'];

    function WhatsNewService($window, GLOBAL_CONSTANTS) {

        var whatsNewService = {
            getHasReadValue: getHasReadValue,
            updateHasReadValue: updateHasReadValue
        };

        return whatsNewService;

        function getHasReadValue(currentReleaseVersion) {
            var releaseVersion = $window.localStorage.getItem("release-version");
            $window.localStorage.setItem("release-version", currentReleaseVersion);
            var isSkipped = $window.sessionStorage.getItem("skip-whats-new") == "true";
            var isRead = $window.localStorage.getItem("read-whats-new") == "true";
            return (releaseVersion != currentReleaseVersion) || (!isRead || isSkipped);
        }

        function updateHasReadValue(hasRead) {
            if (hasRead) {
                $window.localStorage.setItem("read-whats-new", hasRead);
                $window.sessionStorage.setItem("skip-whats-new", !hasRead);
            }
            else {
                $window.sessionStorage.setItem("skip-whats-new", !hasRead);
                $window.localStorage.setItem("read-whats-new", hasRead);
            }
        }

    }
})();
