/**
* @Author: Abhay
* @Date:   19-Aug-2020 10:19 IST
* @Project: Ezyprocure
* @Filename: ezypayment.admin.tools.state.js
* @Last modified by:   Abhay
* @Last modified time: 19-Aug-2020 10:19 IST
*/

(function() {
    'use strict';

    angular
    .module('ezyprocureApp')
    .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
      $stateProvider
      .state('ezypayment.admin.tools.ntp-users-list', {
        parent: 'ezypayment.admin.tools',
        url: '/ntp-users',
        data: {
          authorities: ['ROLE_ADMIN'],
          pageTitle: 'NTP Users List'
        },
        views: {
          'content@': {
            templateUrl: 'app/ezypayment-modules/admin-modules/tools/ntp-users/ezypayment.admin.tools.ntp-users-list.html',
            controller: 'EzypaymentAdminNTPUsersList',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,asc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
          }]
        }
      })

    }

  })();
