/**
* @Author: Ajay
* @Date:   24-Feb-2017 12:40 +05:30
* @Project: Ezyprocure
* @Filename: supplier.user-access.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 30-Nov-2017 11:34 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierUserAccessController', SupplierUserAccessController);

  SupplierUserAccessController.$inject = ['$scope', '$state', '$filter', 'pagingParams', '$mdDialog', 'SupplierUserManagementService', 'UserAccessService', 'paginationConstants', 'NotificationService', 'GLOBAL_CONSTANTS', 'Auth'];

  function SupplierUserAccessController ($scope, $state, $filter, pagingParams, $mdDialog, SupplierUserManagementService, UserAccessService, paginationConstants, NotificationService, GLOBAL_CONSTANTS, Auth) {
    var vm = this;

    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.sort = sort;
    vm.loadAll = loadAll;
    vm.showAccessModal = showAccessModal;
    vm.updateUserAccess = updateUserAccess;
    vm.cancel = cancel;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    // Load All supplier user's access data
    function loadAll (searchText) {
      if (!searchText) {
        vm.searchText = '';
      }
      SupplierUserManagementService.getAllSupplierUsers({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.searchText
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.supplierUsers = response.data;
      },function(error){
        NotificationService.error({
          title: 'User Access Listing',
          error: error
        });
      });
    }

    /** filter access control data */
    function filterAccessControl() {
      vm.accessControlData.forEach(function(category) {
        if(category.value && category.value.length > 0) {
          category.value = category.value.filter(function (access) {
            return Auth.hasPermission(access.accessControl) && (access.authorization == 'BOTH' || access.authorization == 'ADMIN_SUPPLIER');
          });
        }
      });
    }

    //Show modal to update user's access
    function showAccessModal (event, index, userAccess) {
      UserAccessService.getUserAccess(userAccess.user.id).then(function(response) {
        vm.selectedUserAccess = response;
        vm.accessControlData = GLOBAL_CONSTANTS.accessControlData;
        _.map(vm.accessControlData, function(each){
          _.map(each.value, function(value){
            value.model = vm.selectedUserAccess[value.accessControl];
            value.serchText = $filter('translate')(value.translation);
          });
        });
        filterAccessControl();
        $mdDialog.show({
          templateUrl: 'app/supplier-modules/tools/user-access/supplier.user-access.details.tmpl.html',
          controller: function() {
            return vm;
          },
          controllerAs: 'vm',
          targetEvent: event,
          clickOutsideToClose: true
        });
      }, function(error) {
        NotificationService.error({
          title: 'User Access Details',
          error: error
        });
      });
    }

    //Update a supplier user's access data by key
    function updateUserAccess (userId,key,value) {
      var userAccessData = {
        "userId": userId,
        "key": key,
        "value": value
      };
      UserAccessService.updateUserAccess(userAccessData)
      .then(function(response) {
        //Show updated value
        vm.selectedUserAccess[key] = response.value;
      }, function(error) {
        NotificationService.error({
          title: 'User Access Updation',
          error: error
        });
        vm.selectedUserAccess[key] = error.data.value;
      });
    }

    function cancel () {
      $mdDialog.cancel();
    }


    vm.loadAll();

  }
})();
