/**
* @Author: JayalekshmiR
* @Date:   2018-09-14T12:38:44+05:30
* @Filename: ezypayment.buyer.payee-list.service.js
 * @Last modified by:   JayalekshmiR
 * @Last modified time: 2018-09-17T15:38:41+05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BuyerPayeeService', BuyerPayeeService);

  BuyerPayeeService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  function BuyerPayeeService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var buyerPayeeService = {
      addPayeeViaExcelUpload: addPayeeViaExcelUpload,
      addPayee: addPayee,
      getIsCustom: getIsCustom,
      setIsCustom: setIsCustom
    };
    var isCustom = null;
    return buyerPayeeService;

    function addPayee(addPayeeDTO) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PAYMENT + 'add-payee',
        method: "POST",
        data:addPayeeDTO
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function setIsCustom(val){
       isCustom = val;
    }

    function getIsCustom(){
       return isCustom ? isCustom : false;
    }

    /**
     * addPayeeViaExcelUpload
     * @param {file}
     */
    function addPayeeViaExcelUpload(file) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'add-payee/upload',
        method: "POST",
        data:file
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
