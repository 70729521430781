/**
* @Author: Ajay
* @Date:   14-Dec-2016 12:54 +05:30
* @Project: Ezyprocure
* @Filename: notification.interceptor.js
* @Last modified by:   Ajay
* @Last modified time: 27-Mar-2017 18:57 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .factory('notificationInterceptor', notificationInterceptor);

  notificationInterceptor.$inject = ['$q', 'AlertService'];

  function notificationInterceptor ($q, AlertService) {
    var service = {
      response: response
    };

    return service;

    function response (response) {
      var alertKey = response.headers('X-ezyprocureApp-alert');
      if (angular.isString(alertKey)) {
        AlertService.success(alertKey, { param : response.headers('X-ezyprocureApp-params')});
      }
      return response;
    }
  }
})();
