/**
* @Author: Allan
* @Date:   01-October-2018 16:15 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.supplier.dashboard.controller.js
* @Last modified by:   Allan
* @Last modified time: 01-October-2018 16:15 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzyPaymentSupplierDashboardController', EzyPaymentSupplierDashboardController);

  EzyPaymentSupplierDashboardController.$inject = ["EzypaymentSupplierDashboardService", "$rootScope", 'NotificationService', 'GLOBAL_CONSTANTS', 'SupplierAccountService'];

  function EzyPaymentSupplierDashboardController(EzypaymentSupplierDashboardService, $rootScope, NotificationService, GLOBAL_CONSTANTS, SupplierAccountService) {

    var vm = this;
    vm.showEzysourceBanner = GLOBAL_CONSTANTS.SHOW_EZYSOURCE_BANNER;
    vm.getEzypaymentSupplierDashboardCount = getEzypaymentSupplierDashboardCount;
    vm.showUobBanner = GLOBAL_CONSTANTS.SHOW_UOB_BANNER;
    vm.getSupplierProfile = getSupplierProfile;

    getSupplierProfile();

    function getEzypaymentSupplierDashboardCount() {
      EzypaymentSupplierDashboardService.getEzypaymentSupplierDashboardCount().then(function(response) {
        vm.supplierDashboardCount = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'Ezypayment Dashboard',
          error: error
        });
      });
    }

    function getSupplierProfile() {
      SupplierAccountService.getSupplierProfile().
      then(function(response) {
        if (response) {
          vm.supplierProfile = response;
        }
      }, function(error) {
      });
    }

  }
})();
