/**
* @Author: Ajay
* @Date:   20-Mar-2017 12:11 +05:30
* @Project: Ezyprocure
* @Filename: buyer.price-update-history.state.js
* @Last modified by:   Ajay
* @Last modified time: 25-May-2017 11:53 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('buyer.reports.logs-history', {
      parent: 'buyer.reports',
      url: '/logs-history',
      data: {
        authorities: ['ROLE_BUYER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/reports/logs-history/buyer.logs-history.html',
          controller: 'BuyerLogsHistoryController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,desc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('priceUpdates');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('user-management');
          $translatePartialLoader.addPart('purchaseOrderStatus');
          $translatePartialLoader.addPart('auditlogTransactionTypes');
          $translatePartialLoader.addPart('auditlogStatus');
          return $translate.refresh();
        }]
      }
    });

  }

})();
