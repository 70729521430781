/**
* @Author: Ajay
* @Date:   14-Dec-2016 12:54 +05:30
* @Project: Ezyprocure
* @Filename: alert.config.js
* @Last modified by:   Ajay
* @Last modified time: 27-Mar-2017 18:55 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(alertServiceConfig);

  alertServiceConfig.$inject = ['AlertServiceProvider'];

  function alertServiceConfig(AlertServiceProvider) {
    // set below to true to make alerts look like toast
    AlertServiceProvider.showAsToast(false);
  }
})();
