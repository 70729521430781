/**
 * @Author: Ajay
 * @Date:   18-Jul-2018 15:49 +05:30
 * @Project: Ezyprocure
 * @Filename: manage-epd.edit.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 20-Jul-2018 15:25 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('ManageEPDEditController', ManageEPDEditController);

  ManageEPDEditController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'Auth', 'EpdApprovalRequestService', 'GLOBAL_CONSTANTS', 'NotificationService'];

  function ManageEPDEditController($scope, $rootScope, $state, $stateParams, Auth, EpdApprovalRequestService, GLOBAL_CONSTANTS, NotificationService) {
    var vm = this;

    vm.epdApprovalRequestId = $stateParams.epdApprovalRequestId;
    vm.save = save;
    vm.getEPDApprovalRequest = getEPDApprovalRequest;
    vm.userRole = Auth.getUserRole();
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;

    function save() {
      EpdApprovalRequestService.updateEPDApprovalRequest(vm.epdApprovalRequest).then(function(response) {
        $scope.$emit('ezyprocureApp:epdApprovalRequestUpdate', response);
        NotificationService.success({
          title: 'EPD Approval Request',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        $state.go('ezypayment.'+vm.userRole.pretty+'.epd-approval-requests', {
          reload: false
        });
      }, function(error) {
        NotificationService.error({
          title: 'EPD Approval Request Updation',
          error: error
        });
      });
    }


    function getEPDApprovalRequest() {
      EpdApprovalRequestService.getEPDApprovalRequest(vm.epdApprovalRequestId).
      then(function(response) {
        if (response) {
          vm.epdApprovalRequest = response;
        }
      }, function(error) {
      });
    }

    vm.getEPDApprovalRequest();
  }
})();
