/**
 * @Author: Ajay
 * @Date:   21-Apr-2017 17:54 +05:30
 * @Project: Ezyprocure
 * @Filename: csv.upload.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 26-Mar-2018 14:20 +05:30
 */



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('CsvUploadService', CsvUploadService);

  CsvUploadService.$inject = ['$http', '$q', 'toastr', 'API_CONSTANTS', 'GLOBAL_CONSTANTS'];

  function CsvUploadService($http, $q, toastr, API_CONSTANTS, GLOBAL_CONSTANTS) {

    var csvUploadService = {
      checkFileType: checkFileType,
      checkFileSize: checkFileSize,
      getFileBase64: getFileBase64
    };

    return csvUploadService;

    function checkFileType(attType) {
      //Check for valid csv extensions
      var validCsvType = _.includes(GLOBAL_CONSTANTS.CSV_UPLOAD.VALID_CSV_EXTENSIONS, attType.type);
      if (validCsvType && attType.name.split(".").pop() === 'csv') {
        return true;
      } else {
        toastr.error('Please upload .csv files!', 'Invalid File Type');
        return false;

      }

    }

    function checkFileSize(fileSize) {
      if (fileSize > GLOBAL_CONSTANTS.CSV_UPLOAD.FILE_SIZE_LIMIT) {
        toastr.error('Please upload csv files size below 2MB!', 'Csv Upload File Size Error');
        return false;
      } else return true;
    }

    function getFileBase64(inputFile, callback) {
      if (this.checkFileType(inputFile)) {
        var base64;
        var fileReader = new FileReader();
        fileReader.onload = function(event) {
          base64 = event.target.result;
          callback(base64);
        };
        fileReader.readAsDataURL(inputFile);
      }
    }
  }
})();
