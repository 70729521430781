/**
* @Author: sneha
* @Date:   10-Jan-2018 11:49 +05:30
* @Project: Ezyprocure
* @Filename: admin.transaction-volume.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 12-Oct-2018 14:51 +05:30
*/




(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('AdminTransactionVolumeController', AdminTransactionVolumeController);

  AdminTransactionVolumeController.$inject = ['$scope', '$rootScope', '$q', '$state','$filter', 'paginationConstants', 'pagingParams', 'AdminTransactionVolumeService', 'NotificationService', 'UTCDateService', 'CSVExportService', 'EXPORT_CONSTANTS', 'GLOBAL_CONSTANTS'];

  function AdminTransactionVolumeController($scope, $rootScope, $q, $state,$filter, paginationConstants, pagingParams, AdminTransactionVolumeService, NotificationService, UTCDateService, CSVExportService, EXPORT_CONSTANTS, GLOBAL_CONSTANTS) {
    var vm = this;   
    vm.todaysDate = new Date();
    vm.filter = {};
    vm.buyerQuery = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.supplierQuery = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.buyerTransactionReportHeader = EXPORT_CONSTANTS.CSV.buyerTransactionSummaryReport;
    vm.supplierTransactionReportHeader = EXPORT_CONSTANTS.CSV.supplierTransactionSummaryReport

    vm.loadAll = loadAll;
    vm.getBuyerTransactionVolumeSummary = getBuyerTransactionVolumeSummary;
    vm.getSupplierTransactionVolumeSummary = getSupplierTransactionVolumeSummary;
    vm.getBuyerTransactionVolumeSummaryCSV = getBuyerTransactionVolumeSummaryCSV;
    vm.getSupplierTransactionVolumeSummaryCSV = getSupplierTransactionVolumeSummaryCSV;
    vm.accountTypes = GLOBAL_CONSTANTS.ACCOUNT_TYPE;
    vm.tabIndex =0;

    function loadAll(){
      if(vm.tabIndex==0)
        vm.getBuyerTransactionVolumeSummary();
      else
        vm.getSupplierTransactionVolumeSummary();
    }

    //Get Buyer Transaction Volume Details
    function getBuyerTransactionVolumeSummary() {
      AdminTransactionVolumeService.getTransactionVolumeSummary({
        role: 'ROLE_BUYER',
        fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
        toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
        accountType: vm.filter.accountType,
        page: vm.buyerQuery.page - 1,
        size: vm.buyerQuery.limit,
      }).then(function(response) {
        vm.totalBuyerDataItems = response.headers('X-Total-Count');
        vm.buyerTransactionData = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'Buyer Transaction Volume Summary',
          error: error
        });
      });
    }


    // Get Supplier Transaction Volume Summary Details
    function getSupplierTransactionVolumeSummary() {
      AdminTransactionVolumeService.getTransactionVolumeSummary({
        role: 'ROLE_SUPPLIER',
        fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
        toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
        accountType: vm.filter.accountType,
        page: vm.supplierQuery.page - 1,
        size: vm.supplierQuery.limit,
      }).then(function(response) {
        vm.totalSupplierDataItems = response.headers('X-Total-Count');
        vm.supplierTransactionData = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'Supplier Transaction Volume Summary',
          error: error
        });
      });
    }

    //Getting BUYER CSV DATA
    function getBuyerTransactionVolumeSummaryCSV(){
      var deferred = $q.defer();
      if(!vm.buyerTransactionData) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getBuyerTransactionVolumeSummaryCSV(vm.buyerTransactionData.buyers);
    }


    //Getting Suppplier CSV Data
    function getSupplierTransactionVolumeSummaryCSV(){
      var deferred = $q.defer();
      if(!vm.buyerTransactionData) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getSupplierTransactionVolumeSummaryCSV(vm.supplierTransactionData.suppliers);
    }
  }

})();
