/*
 * @Author: Ajay 
 * @Date: 2019-03-26 14:59:02 
 * @Project: Ezyprocure
 * @Filename: ezyap.manage-delivery-details.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-10-24 10:33:10
 */

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('EzyapDeliveryDetailsController', EzyapDeliveryDetailsController);

  EzyapDeliveryDetailsController.$inject = ['NotificationService', 'ManageDeliveryDetailsService', 'paginationConstants', '$mdDialog', '$filter', 'pagingParams', 'Auth'];

  function EzyapDeliveryDetailsController(NotificationService, ManageDeliveryDetailsService, paginationConstants, $mdDialog, $filter, pagingParams, Auth) {

    var vm = this;
    vm.limitOptions = paginationConstants.limitOptions;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.getDeliveryDetails = getDeliveryDetails;
    vm.init = init;
    vm.deleteDeliveryDetailsById = deleteDeliveryDetailsById;
    vm.showDeleteConfirmationModal = showDeleteConfirmationModal;
    vm.userRole = Auth.getUserRole();

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function init() {
      vm.getDeliveryDetails();
    }

    /**
    * [get Buyer Information]
    */
    function getDeliveryDetails() {
      ManageDeliveryDetailsService.getDeliveryDetails({
        query: vm.searchText ? vm.searchText : "",
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
      }).then(function (response) {
        vm.deliveryDetails = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      }, function (error) {
        NotificationService.error({
          title: 'Delivery Details',
          error: error
        });
      });
    }

    /**
     * [delete the buyer information of the given id]
     * @param {String} buyerId [id of the buyer]
     */
    function deleteDeliveryDetailsById(buyerId) {
      ManageDeliveryDetailsService.deleteDeliveryDetailsById(buyerId).
        then(function (responseData) {
          vm.getDeliveryDetails();
          NotificationService.success({
            title: 'Delivery Details',
            message: 'alertMessage.DELETED_SUCCESSFULLY'
          });
        }, function (error) {
          NotificationService.error({
            title: 'Delivery Details',
            error: error
          });
        });
    }

    /**
     * [show confirmaton modal to delete buyer information]
     * @param {Integer} buyerinfoId [buyer information id]
     */
    function showDeleteConfirmationModal(buyerinfoId) {
      var confirm = $mdDialog.confirm()
        .title('Are you sure you want to delete the buyer details?')
        .targetEvent(event)
        .ok($filter('translate')('global.buttons.proceed'))
        .cancel($filter('translate')('global.buttons.cancel'));
      $mdDialog.show(confirm).then(function () {
        vm.deleteDeliveryDetailsById(buyerinfoId);
      }, function () { });
    }
  }
})();