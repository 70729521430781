/*
 * @Author: Lovin 
 * @Date: 2018-07-27 10:25:03 
 * @Project: Ezyprocure
 * @Filename: supplier.item.mapping.upload.controller.js
 * @Last Modified by: Lovin
 * @Last Modified time: 2018-07-27 12:35:34
 */




(function () {
    'use strict';

    angular
      .module('ezyprocureApp')
      .controller('BulkItemMappingCsvUploadController', BulkItemMappingCsvUploadController);

      BulkItemMappingCsvUploadController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'toastr', 'CsvUploadService', 'NotificationService', 'UtilService', 'SupplierItemMapService', 'S3_URL_CONSTANTS'];

    function BulkItemMappingCsvUploadController($scope, $rootScope, $state, $stateParams, toastr, CsvUploadService, NotificationService, UtilService, SupplierItemMapService, S3_URL_CONSTANTS) {
      var vm = this;

      vm.init = init;
      vm.getCSVBase64 = getCSVBase64;
      vm.mapItemUploadCsv = mapItemUploadCsv;
      vm.itemUploadCSV = itemUploadCSV;
      vm.resetValues = resetValues;
      vm.SUPPLIER_ITEM_MAPPING_CSV = S3_URL_CONSTANTS.SUPPLIER_ITEM_MAPPING_CSV;

      function init() {
        vm.csvUploadData = {};
      }

      function getCSVBase64() {
        vm.errorList = null;
        var csvFile = vm.files[0];
        if (!csvFile) {
          toastr.error('Please upload a .csv file!', 'CSV File Required');
          return false;
        }
        CsvUploadService.getFileBase64(csvFile.lfFile, function (base64CSV) {
          vm.csvUploadData = base64CSV;
          vm.mapItemUploadCsv(vm.csvUploadData);
        });
      }

      function mapItemUploadCsv(uploadData) {
        SupplierItemMapService.createNewItemSupplierCsvMapping(uploadData)
          .then(function (response) {
            vm.files = [];
            NotificationService.success({
              title: 'Bulk Mapping Request',
              message: 'alertMessage.UPLOADED_SUCCESSFULLY'
            });
            $state.go('supplier.items.mapping', {
              reload: false
            });
          }, function (error) {
            vm.recordError = error.data;
            NotificationService.error({
              title: 'Cannot Map Items',
              error: error
            });
          });
      }

      function itemUploadCSV() {
        var csvFile = vm.files[0]
        vm.isCsvFile = CsvUploadService.checkFileType(csvFile.lfFile);
        if (vm.isCsvFile) {
          UtilService.csvToJson(csvFile.lfDataUrl)
          .then(function (response) {
            vm.mapItems = response;
          })
         }
      }

      function resetValues(){
        vm.mapItems = [];
        vm.recordError = [];
      }
  }

})();
