/**
* @Author: Ajay
* @Date:   23-Apr-2017 20:57 +05:30
* @Project: Ezyprocure
* @Filename: range.filter.js
 * @Last modified by:   Ajay
 * @Last modified time: 26-Mar-2018 13:14 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .filter('range', range);

  function range() {
    return filteredRange;
    function filteredRange(input, min, max) {
      min = parseInt(min);
      max = parseInt(max);
      for (var i=min; i<=max; i++)
        input.push(i);
      return input;
    }
  }
})();
