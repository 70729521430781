/**
* @Author: Shibi
* @Date:   18-May-2020 12:30 +05:30
* @Project: Ezyprocure
* @Filename: stock-take.service.js
*/

(function() {
  'use strict';
  angular.module('ezyprocureApp').factory('StockTakeService', StockTakeService);
  StockTakeService.$inject = ['$http', '$q', 'API_CONSTANTS','RESOURCE_URL_CONSTANTS'];
  function StockTakeService($http, $q, API_CONSTANTS,RESOURCE_URL_CONSTANTS) {
    var stockTakeService = {
      getAllStockTakeItems: getAllStockTakeItems,
      getAllStockTakeCategoryItems: getAllStockTakeCategoryItems,
      getAllStockTakeLocationsItems: getAllStockTakeLocationsItems,
      getAllStockTakeItemNames: getAllStockTakeItemNames,
      getAllStockTakeSubCategoryItems: getAllStockTakeSubCategoryItems,
      getAllStockTakeWarehouseItems: getAllStockTakeWarehouseItems,
      setSelectedStockTake : setSelectedStockTake,
      getSelectedStockTake : getSelectedStockTake,
      submitSelectedStockTake : submitSelectedStockTake
    };
    var stockTakeFilterParams = {}; 
    var selectedStockTake = null;       
    return stockTakeService;    
    function getAllStockTakeItems(itemListQuery, params) { 
      var deferred = $q.defer();
      var filterOption = '&page='+params.page+'&size='+params.size+'&sort=createdDate,DESC';
      if(params.categoryId){
        filterOption += '&categoryId='+params.categoryId;
      }
      if(params.subcategoryId){
        filterOption += '&subcategoryId='+params.subcategoryId;
      }
      if(params.locationId){
        filterOption += '&locationId='+params.locationId;
      }
      if(params.warehouseId){
        filterOption += '&warehouseId='+params.warehouseId;
      }
      if(params.itemId){
        filterOption += '&itemId='+params.itemId;
      }
      filterOption += '&transactionType=STOCKTAKE'
      
      $http({
       url: API_CONSTANTS.ezyinventoryUrl+'api/stock/filter?'+filterOption,
       method: "GET",        
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    function getAllStockTakeCategoryItems() { 
      var deferred = $q.defer();
      $http({      
        url: API_CONSTANTS.ezyinventoryUrl+'api/categories',
        method: "GET"       
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    function getAllStockTakeLocationsItems() { 
      var deferred = $q.defer();
      $http({      
        url: API_CONSTANTS.ezyinventoryUrl+'api/locations',
        method: "GET"       
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    function getAllStockTakeItemNames() { 
      var deferred = $q.defer();
      $http({      
        url: API_CONSTANTS.ezyinventoryUrl+'/api/item/all',
        method: "GET"       
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    function getAllStockTakeSubCategoryItems(categoryId) { 
      var deferred = $q.defer();
      $http({      
        url: API_CONSTANTS.ezyinventoryUrl+'api/subcategories?categoryId='+categoryId,
        method: "GET"       
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    function getAllStockTakeWarehouseItems(locationId) { 
      var deferred = $q.defer();
      $http({      
        url: API_CONSTANTS.ezyinventoryUrl+'api/warehouse/all?locationId='+locationId,
        method: "GET"       
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    function setSelectedStockTake(stockTakeItems){  
      selectedStockTake = stockTakeItems;               
    }
    function getSelectedStockTake(){
      return selectedStockTake ? selectedStockTake:null;
    }
    function submitSelectedStockTake(stockTakeItem){
      var deferred = $q.defer();
      $http({      
        url: API_CONSTANTS.ezyinventoryUrl+'api/stock/instock',
        method: "PUT",
        data:stockTakeItem       
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
   
  }
})();