/**
* @Author: Ajay
* @Date:   07-Mar-2017 15:17 +05:30
* @Project: Ezyprocure
* @Filename: supplier.su-bo-mapping.service.js
 * @Last modified by:   sneha
 * @Last modified time: 08-Feb-2018 15:52 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('SupplierSUBOMapService', SupplierSUBOMapService);

  SupplierSUBOMapService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  function SupplierSUBOMapService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var supplierSUBOMapService = {
      createSupplierUserBusinessMapping: createSupplierUserBusinessMapping,
      deleteSupplierUserBusinessMapping: deleteSupplierUserBusinessMapping,
      getAllMappedBuyerBO: getAllMappedBuyerBO
    };

    return supplierSUBOMapService;

    //Create a new SupplierUser and Business Outlet mapping
    function createSupplierUserBusinessMapping(mapping) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.SUPPLIER_USER_BUSINESS_MAPPING_RESOURCE, mapping).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    // Soft Delete existing SupplierUser and Business Outlet mapping
    function deleteSupplierUserBusinessMapping(mapping) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'map/supplier/'+ mapping.supplierUserId +'/business/'+ mapping.businessOutletId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getAllMappedBuyerBO(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.SUPPLIER_USER_BUSINESS_MAPPING_RESOURCE,
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
