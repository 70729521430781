/*
 * @Author: Lovin Chacko 
 * @Date: 2021-01-06 14:36:38 
 * @Last Modified by: Lovin Chacko
 * @Last Modified time: 2021-02-03 16:34:57
 */


(function () {
  'use strict';
  angular.module('ezyprocureApp').factory('EzypaymentAdminAXSPaymentsService', EzypaymentAdminAXSPaymentsService);

  EzypaymentAdminAXSPaymentsService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function EzypaymentAdminAXSPaymentsService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {
    var EzypaymentAdminAXSPaymentsService = {
      getAllStatementOfAccountReports: getAllStatementOfAccountReports,
      createGiroFile: createGiroFile,
      axsPayAnyOnecreateGiroFile: axsPayAnyOnecreateGiroFile,
      readGiroResponseFiles: readGiroResponseFiles,
      generateProcess: generateProcess,
      axsFinalResponse: axsFinalResponse,
      getAxsBillFinesReport: getAxsBillFinesReport,
      getSettlementFile: getSettlementFile,
      getTransactionViewDetails: getTransactionViewDetails
    };
    return EzypaymentAdminAXSPaymentsService;

    function getSettlementFile(params) {
      var deffered = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + RESOURCE_URL_CONSTANTS.PAYMENT + 'axs-generate-settlement-file',
        method: 'GET',
        params: params
      }).then(function (data) {
        deffered.resolve(data);
      }).catch(function (message) {
        deffered.reject(message);
      });
      return deffered.promise;
    }

    function getTransactionViewDetails(params) {
      var deffered = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + RESOURCE_URL_CONSTANTS.PAYMENT + 'axs-statement-report/view',
        method: 'GET',
        params: params
      }).then(function (data) {
        deffered.resolve(data);
      }).catch(function (message) {
        deffered.reject(message);
      });
      return deffered.promise;
    }

    function axsFinalResponse() {
      var deffered = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/mpgs/' + RESOURCE_URL_CONSTANTS.PAYMENT + 'axs-final-response',
        method: 'POST',
      }).then(function (data) {
        deffered.resolve(data);
      }).catch(function (message) {
        deffered.reject(message);
      });
      return deffered.promise;
    }

    function generateProcess() {
      var deffered = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/mpgs/' + RESOURCE_URL_CONSTANTS.PAYMENT + 'giro-process',
        method: 'POST',
      }).then(function (data) {
        deffered.resolve(data);
      }).catch(function (message) {
        deffered.reject(message);
      });
      return deffered.promise;
    }

    function getAllStatementOfAccountReports(params) {
      var deffered = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/mpgs/' + RESOURCE_URL_CONSTANTS.PAYMENT + 'giro-process-details',
        method: 'GET',
        params: params
      }).then(function (data) {
        deffered.resolve(data);
      }).catch(function (message) {
        deffered.reject(message);
      });
      return deffered.promise;
    }

    function getAxsBillFinesReport(params) {
      var deffered = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + RESOURCE_URL_CONSTANTS.PAYMENT + 'axs-statement-report',
        method: 'GET',
        params: params
      }).then(function (data) {
        deffered.resolve(data);
      }).catch(function (message) {
        deffered.reject(message);
      });
      return deffered.promise;
    }

    function createGiroFile(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'axs/' + RESOURCE_URL_CONSTANTS.PAYMENT + 'generate-giro',
        method: 'GET',
        params: params
      }).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function axsPayAnyOnecreateGiroFile(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PAYMENT + 'generate-giro-external',
        method: 'POST',
        params: params
      }).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function readGiroResponseFiles() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PAYMENT + 'reading-host-to-host-response-files',
        method: 'GET'
      }).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
