/**
 * @Author: Ajay
 * @Date:   12-May-2017 14:27 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 01-Jun-2017 12:34 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig ($stateProvider) {

    $stateProvider.state('buyer', {
      abstract: true,
      parent: 'app',
      url: '/buyer',
      views: {
        'navbar@': {
          templateUrl: 'app/buyer-modules/common/buyer.navbar.html',
          controller: 'BuyerNavbarController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('user-management');
          $translatePartialLoader.addPart('invoiceOCR');
          return $translate.refresh();
        }]
      }
    });
  }
})();
