/**
* @Author: Mufsir
* @Date:   30-Apr-2017 15:03 +05:30
* @Project: Ezyprocure
 * @Last modified by:   Ajay
 * @Last modified time: 06-Nov-2017 15:02 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerCancelPORequestDetailsController', BuyerCancelPORequestDetailsController);

  BuyerCancelPORequestDetailsController.$inject = ['$scope', '$rootScope', '$timeout', '$stateParams', '$state', 'toastr', '$mdDialog', '$filter', 'PurchaseOrderService', 'paginationConstants', 'UTCDateService', 'GLOBAL_CONSTANTS'];

  function BuyerCancelPORequestDetailsController ($scope, $rootScope, $timeout, $stateParams, $state, toastr, $mdDialog, $filter, PurchaseOrderService, paginationConstants, UTCDateService, GLOBAL_CONSTANTS) {

    var vm = this;
    vm.init = init;
    vm.poNumber = $stateParams.poNumber;
    vm.deliveryDate = new Date($stateParams.deliveryDate);
    vm.sumRequired = sumRequired;
    vm.sumSupplier = sumSupplier;
    vm.init = init;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;

    /**
    * [init loads inititally]
    */
    function init(){
      loadAll();
    }
    /**
    * [loadAll Gets Purchase Order Details by PO number]
    */
    function loadAll() {
      PurchaseOrderService.getPurchaseOrderByPurchaseOrderNumber(vm.poNumber).then(function(response){
        vm.poDetails = response.data;
        vm.totalAmountRequired = sumRequired(vm.poDetails.items);
        vm.totalAmountSupplier = sumSupplier(vm.poDetails.items);
      },function(error){
      });
    }

    /**
     * [sumRequired Grand total amount of buyer Requirement]
     * @param  {[object]} poItems [PO Details]
     * @return {[number]}         [total amount]
     */
    function sumRequired(poItems) {
      var totalAmount = 0;
      _.forEach(poItems, function(item) {
        if(item.buyerRequirement.items.uom.weightRequired === false && !item.buyerRequirement.freeItem) {
            if(item.buyerRequirement.items.uom.moqPrice === true)
            {
                totalAmount += (item.buyerRequirement.quantity/item.buyerRequirement.items.moq) * item.buyerRequirement.price;
            }
            else
            {
                totalAmount += item.buyerRequirement.quantity * item.buyerRequirement.price;
            }
        }
      });
      return totalAmount;
    }

    /**
     * [sumSupplier Grand total amount of supplier response]
     * @param  {[object]} poItems [PO Details]
     * @return {[number]}         [total amount]
     */
    function sumSupplier(poItems) {
      var totalAmount = 0;
      _.forEach(poItems, function(item) {
        //skip caluclations for free item
        if (!item.buyerRequirement.freeItem) {
          if(item.buyerRequirement.items.uom.weightRequired === false) {
            if(item.buyerRequirement.items.uom.moqPrice === true)
            {
                totalAmount += (item.buyerRequirement.quantity/item.buyerRequirement.items.moq) * item.buyerRequirement.price;
            }
            else
            {
                totalAmount += item.buyerRequirement.quantity * item.buyerRequirement.price;
            }
          }
          else {
            if(item.supplierResponses === null) {
               if(item.buyerRequirement.items.uom.moqPrice === true)
               {
                  totalAmount += (item.buyerRequirement.weight/item.buyerRequirement.items.moq) * item.buyerRequirement.price;
               }
               else{
                 totalAmount += item.buyerRequirement.weight * item.buyerRequirement.price;
               }
            }
            else {
            if(item.buyerRequirement.items.uom.moqPrice === true)
            {
                totalAmount += (item.supplierResponses.weight/item.buyerRequirement.items.moq) * item.buyerRequirement.price;
            }
            else{
              totalAmount += item.supplierResponses.weight * item.buyerRequirement.price;
              }
            }
          }
        }
      });
      return totalAmount;
    }
  }
})();
