/**
* @Author: Mufsir
* @Date:   14-Dec-2016 12:54 +05:30
* @Project: Ezyprocure
* @Filename: language.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 11-Apr-2018 11:59 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('LanguageController', LanguageController);

  LanguageController.$inject = ['$rootScope','$translate', '$localStorage', 'LanguageService', 'tmhDynamicLocale', 'NotificationService'];

  function LanguageController($rootScope,$translate, $localStorage, LanguageService, tmhDynamicLocale, NotificationService) {
    var vm = this;

    vm.changeLanguage = changeLanguage;
    vm.languages = null;
    vm.currentLanguage = null;

    //Call service to get current language used in the app
    LanguageService.getCurrent().then(function(language) {
      vm.currentLanguage = language;
    });

    //Call service to get all languages used in the app
    LanguageService.getAll().then(function(languages) {
      vm.languages = languages;
    });

    // Trigger when user changes the language
    function changeLanguage(languageKey) {
      if(languageKey === vm.currentLanguage)
        return false;
      var accountDetails = {};
      accountDetails.langKey = languageKey;
      LanguageService.update(accountDetails).then(function(response) {
        $translate.use(languageKey);
        tmhDynamicLocale.set(languageKey);
        vm.currentLanguage = languageKey;
        $rootScope.$broadcast('language-update',vm.currentLanguage);
      }, function(error) {
        NotificationService.error({
          title: 'User Language Updation',
          error: error
        });
      });
    }
  }
})();
