/**
* @Author: Ajay
* @Date:   27-Feb-2017 16:21 +05:30
* @Project: Ezyprocure
* @Filename: supplier.items.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 29-Dec-2017 15:35 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('supplier.items', {
      parent: 'supplier',
      url: '/items?page&sort&search',
      data: {
        authorities: ['ROLE_SUPPLIER'],
        pageTitle: 'Item Management'
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/item-management/items/supplier.items.html',
          controller: 'SupplierItemListController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('currency');
          return $translate.refresh();
        }]
      }
    })
    .state('supplier.items.add', {
      parent: 'supplier.items',
      url: '/add',
      data: {
        authorities: ['ROLE_SUPPLIER'],
        pageTitle: 'Add Item'
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/item-management/items/add/supplier.item.add-tab.html',
          controller: 'SupplierItemAddController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('glCodeMappings');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('itemType');
          $translatePartialLoader.addPart('currency');
          $translatePartialLoader.addPart('brand');
          return $translate.refresh();
        }]
      }
    })
    .state('supplier.items.edit', {
      parent: 'supplier.items',
      url: '/edit/:itemId',
      data: {
        authorities: ['ROLE_SUPPLIER'],
        pageTitle: 'Edit Item'
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/item-management/items/edit/supplier.item.edit.html',
          controller: 'SupplierItemEditController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('glCodeMappings');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('currency');
          return $translate.refresh();
        }]
      }
    })
    .state('supplier.items.allocations', {
      parent: 'supplier.items',
      url: '/item/:itemId/allocations',
      data: {
        authorities: ['ROLE_SUPPLIER'],
        pageTitle: 'Edit Item'
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/item-management/items/allocations/supplier.item.allocations.html',
          controller: 'SupplierItemAllocationController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('itemBuyerMapping');
          $translatePartialLoader.addPart('brand');
          return $translate.refresh();
        }]
      }
    })
    .state('supplier.items.bulk-delete', {
      parent: 'supplier.items',
      url: '/bulk-delete?page&sort&search',
      data: {
        authorities: ['ROLE_SUPPLIER'],
        pageTitle: 'Bulk Delete Item'
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/item-management/items/bulk-delete/supplier.items-bulk-delete.html',
          controller: 'ItemsBulkDeleteController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('items');
          return $translate.refresh();
        }]
      }
    });
  }
})();
