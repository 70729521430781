/**
 * @Author: sneha
 * @Date:   2019-07-22T15:06:01+05:30
 * @Project: Ezyprocure
 * @Filename: ezypayment.sip-upload-invoices.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-10-15T14:15:44+05:30
 */



 (function() {
   'use strict';

   angular
   .module('ezyprocureApp')
   .controller('EzypaymentSIPInvoiceUploadController', EzypaymentSIPInvoiceUploadController);

   EzypaymentSIPInvoiceUploadController.$inject = ['$state', '$timeout', 'NotificationService', 'GLOBAL_CONSTANTS', 'S3_URL_CONSTANTS', 'CsvUploadService', 'UtilService', 'EzypaymentSupplierSIPUploadInvoices'];

   function EzypaymentSIPInvoiceUploadController($state, $timeout, NotificationService, GLOBAL_CONSTANTS, S3_URL_CONSTANTS, CsvUploadService, UtilService, EzypaymentSupplierSIPUploadInvoices) {

     var vm = this;
     vm.SIP_INVOICE_UPLOAD_CSV = S3_URL_CONSTANTS.SIP_INVOICE_UPLOAD_CSV;
     vm.invoiceUpload = invoiceUpload;
     vm.getCSVBase64 = getCSVBase64;
     vm.supplierSIPUploadInvoiceCSV = supplierSIPUploadInvoiceCSV;

     /**
      * [invoiceUpload Uploads the invoice]
      */
     function invoiceUpload() {
       var csvFile = vm.files[0]
       vm.isCsvFile = CsvUploadService.checkFileType(csvFile.lfFile);
       if (vm.isCsvFile) {
         UtilService.csvToJson(csvFile.lfDataUrl)
         .then(function (response) {
           vm.invoiceItems = response;
         })
        }
     }

     /**
      * [getCSVBase64 Get base csv base 64]
      */
     function getCSVBase64() {
       vm.errorList = null;
       var csvFile = vm.files[0];
       if (!csvFile) {
         NotificationService.simpleErrorToast({
           title: 'alertMessage.INVALID_INPUT',
           message: 'global.pleaseChooseACsvFile'
         });
         return false;
       }
       CsvUploadService.getFileBase64(csvFile.lfFile, function (base64CSV) {
         vm.csvUploadData = base64CSV;
         vm.supplierSIPUploadInvoiceCSV(vm.files[0].lfFile);
       });
     }

     /**
      * [supplierSIPUploadInvoiceCSV Uploads the invoice]
      * @param {*} uploadData 
      */
     function supplierSIPUploadInvoiceCSV(uploadData) {
       EzypaymentSupplierSIPUploadInvoices.uploadSipInvoices(uploadData)
         .then(function (response) {
          clearViewOnUpload();
           NotificationService.success({
             title: 'ezyprocureApp.supplierDashboard.uploadSIPInvoices',
             message: 'alertMessage.UPLOADED_SUCCESSFULLY'
           });
           $state.go('ezypayment.supplier.dashboard', {
             reload: false
           });
         }, function (error) {
           vm.translatedError = error.data;
           NotificationService.error({
             title: 'Cannot Upload Invoice',
             error: error
           });
         });
     }

     function clearViewOnUpload() {
       vm.files = [];
       vm.invoiceItems = [];
     }

   }
 })();
