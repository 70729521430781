/**
* @Author: Abhay
* @Date:   03-Aug-2020 17:11 IST
* @Project: Ezyprocure
* @Filename: buyer.adhoc-items-promotions.add.controller.js
* @Last modified by:   Abhay
* @Last modified time: 03-Aug-2020 17:11 IST
*/

(function () {
    'use strict';

    angular
        .module('ezyprocureApp')
        .controller('AdhocPromotionAddController', AdhocPromotionAddController);

    AdhocPromotionAddController.$inject = ['$scope', '$rootScope', '$state', '$filter', '$stateParams', '$q', 'NotificationService', '$mdDialog', 'AdhocPromotionService', 'BuyerDataService', 'PromotionService', 'BuyerItemService', 'BuyerBusinessService', 'BrandManagementService', 'pagingParams', 'paginationConstants', 'GLOBAL_CONSTANTS', 'SupplierItemPriceUpdateService'];

    function AdhocPromotionAddController($scope, $rootScope, $state, $filter, $stateParams, $q,  NotificationService, $mdDialog, AdhocPromotionService, BuyerDataService, PromotionService, BuyerItemService, BuyerBusinessService, BrandManagementService, pagingParams, paginationConstants, GLOBAL_CONSTANTS, SupplierItemPriceUpdateService) {
        var vm = this;
        vm.itemId = $stateParams.itemId;
        vm.promotionDTO = {};
        vm.itemSearchQuery = {};
        vm.todaysDate = new Date();
        vm.currentDate = new Date();
        vm.buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
        vm.getAdhocSupplierItem = getAdhocSupplierItem;
        vm.getAllBrandMappedsBusinessOutlets = getAllBrandMappedsBusinessOutlets;
        vm.getAllBrandsOfBuyerList = getAllBrandsOfBuyerList;
        vm.itemChanged = itemChanged;
        vm.searchForItems = searchForItems;
        vm.save = save;
        vm.setPromotionStartDateWithTime = setPromotionStartDateWithTime;
        vm.setPromotionEndDateWithTime = setPromotionEndDateWithTime;
        vm.selectAll = selectAll;
        vm.deselectAll = deselectAll;
        vm.init = init;
        vm.getItemPricePerOutlet = getItemPricePerOutlet;
        vm.showPricePerBO = showPricePerBO;
        vm.getPricePerBusiness = getPricePerBusiness;
        vm.cancel = cancel;
        vm.outletList = [];

        vm.init();

        function getItemPricePerOutlet(){
          SupplierItemPriceUpdateService.getItemPricePerOutlet({
            'itemId': vm.promotionDTO.promotionItem.id,
            'businessIdList': vm.promotionDTO.businessIdList
          }).then(function(response){
            vm.outletList = response.data;
          }, function(error){
            NotificationService.error({
              title: 'Item Promotion',
              error: error
            });
          })
        }
    
        /**
         * [showPrice details SHows the detailed Price of BO's]
         * @param {Object} event
         */
        function showPricePerBO(event) {
          $mdDialog.show({
            templateUrl: 'app/supplier-modules/item-management/promotions/supplier.promotion.tmpl.html',
            controller: function() {
              return vm;
            },
            controllerAs: 'vm',
            targetEvent: event,
            clickOutsideToClose: true
          });
        }
    
        function getPricePerBusiness(eachOutlet){
          return eachOutlet.price ? eachOutlet.price : eachOutlet.items.price;
        }
    
        /**
         * [cancel Cancels the modal]
         */
        function cancel() {
          $mdDialog.cancel();
        }

       

        /**
        * get adhoc supplier item details
        */
        function getAdhocSupplierItem() {
            BuyerItemService.getBuyerItem(vm.itemId).then(function (response) {
                vm.promotionDTO.promotionItem = response;
            }, function (error) {
            });
        }

        function init() {
            vm.getAdhocSupplierItem();
            vm.promotionDTO.buyerIdList = [vm.buyerId];
            getAllBrandsOfBuyerList(vm.promotionDTO.buyerIdList);
        }

        /**
        * [getAllBrandsOfBuyerList Gets all brands of selected buyers]
        * @param {Array} buyerIdList
        */
        function getAllBrandsOfBuyerList(buyerIdList) {
            if (!buyerIdList || buyerIdList.length < 1)
                return false;
            var params = {
                page: 0,
                size: paginationConstants.maxLimit,
                query: '',
                buyerId: buyerIdList
            };
            vm.promotionDTO.businessIdList = [];
            vm.promotionDTO.brandIdList = [];
            BrandManagementService.getAllBrandsOfBuyerList(params)
                .then(function (response) {
                    vm.brands = response;
                }, function (error) {
                    NotificationService.error({
                        title: 'Brand Listing',
                        error: error
                    });
                });
        }

        function getAllBrandMappedsBusinessOutlets(brandIdList) {
            if (!brandIdList || brandIdList.length === 0) return false;
            var itemTerminationDTO = {
                'brandIdList': brandIdList,
                'buyerIdList': vm.promotionDTO.buyerIdList
            }
            var params = {
                page: 0,
                size: paginationConstants.maxLimit
            }
            BuyerBusinessService.getAllBrandMappedsBusinessOutlets(itemTerminationDTO, params).then(function (response) {
                vm.brandMappedBusinessOutlets = response.data;
            }, function (error) {
            });
        }

        /**
        * [selectAll Selects all from the list]
        * @param  {[type]} dropdown [Active dropdown - outlets or brands]
        */
        function selectAll(dropdown) {
            switch (dropdown) {
                case 'BUYERS':
                    vm.promotionDTO.buyerIdList = _.map(vm.buyers, _.property('id'));
                    vm.getAllBuyersBusinessOutlets();
                    vm.getAllBrandMappedsBusinessOutlets(vm.promotionDTO.brandIdList)
                    break;
                case 'BUSINESSOUTLETS':
                    vm.promotionDTO.businessIdList = _.map(vm.brandMappedBusinessOutlets, _.property('id'));
                    break;
                case 'BRANDS':
                    vm.promotionDTO.brandIdList = _.map(vm.brands, _.property('id'));
                    break;
            }
        }

        /**
        * [deselectAll Deselect all from the list]
        * @param  {[type]} dropdown [Active dropdown - outlets or brands]
        */
        function deselectAll(dropdown) {
            switch (dropdown) {
                case 'BUYERS':
                    vm.promotionDTO.buyerIdList = [];
                    break;
                case 'BUSINESSOUTLETS':
                    vm.promotionDTO.businessIdList = [];
                    break;
                case 'BRANDS':
                    vm.promotionDTO.brandIdList = [];
                    break;
            }
        }

        function itemChanged(selectedItem) {
            if (selectedItem)
                vm.promotionDTO.freeItem = angular.copy(selectedItem);
        }

        function searchForItems(queryType, queryText) {
            var deferred = $q.defer();
            if (!queryText) {
                return;
            } else {
                vm.itemSearchQuery = {
                    itemType: 'SUPPLIER',
                    queryField: queryType,
                    queryText: queryText,
                    supplierId: vm.promotionDTO.promotionItem.supplier.id
                };
                BuyerItemService.searchAdhocItems(vm.itemSearchQuery)
                    .then(function (response) {
                        deferred.resolve($filter('orderBy')(response.data, 'itemName'));
                    }, function (error) {
                        deferred.reject(error);
                    });
            }
            return deferred.promise;
        }

        function setPromotionStartDateWithTime() {
            vm.promotionDTO.effectiveStartDate = PromotionService.getPromotionStartDateWithTime(vm.promotionDTO.effectiveStartDate);
        }

        function setPromotionEndDateWithTime() {
            vm.promotionDTO.effectiveEndDate = PromotionService.getPromotionEndDateWithTime(vm.promotionDTO.effectiveEndDate);
        }

        function save() {
            PromotionService.createSupplierItemPromotion(vm.promotionDTO).then(function (response) {
                $scope.$emit('ezyprocureApp:promotionUpdate', response);
                NotificationService.success({
                    title: 'Item Promotion',
                    message: 'alertMessage.CREATED_SUCCESSFULLY'
                });
                $state.go('buyer.adhoc-tools.items.promotions', {
                    reload: false
                });
            }, function (error) {
                NotificationService.error({
                    title: 'Item Promotion Creation',
                    error: error
                });
            });
        }
    }
})();