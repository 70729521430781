/**
 * @Author: Jayalekshmi 
 * @Date: 2019-01-03 10:40:23 
 * @Project: Ezyprocure
 * @Filename: sats.sr.add.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-04-16 15:52:04
 */



(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .controller('SatsSrAddController', SatsSrAddController);
  
    SatsSrAddController.$inject = ['$filter', 'GLOBAL_CONSTANTS','$state', 'UtilService', 'SatsManagementService', 'CsvUploadService','pagingParams', 'NotificationService', 'Auth'];
  
    function SatsSrAddController($filter, GLOBAL_CONSTANTS, $state, UtilService, SatsManagementService, CsvUploadService, pagingParams, NotificationService, Auth) {
      var vm = this;
      vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
      vm.predicate = pagingParams.predicate;
      vm.reverse = pagingParams.ascending;
      vm.filter = {};
      vm.userRole = Auth.getUserRole();
      vm.createSRJob = createSRJob;
      vm.uploadJobSR = uploadJobSR;
      vm.resetValues = resetValues;
      vm.getDateFormat = getDateFormat;
      
      /**
       * [getDateFormat gets the date string and converts]
       * @param {dateString} dateString 
       */
      function getDateFormat(dateString){
        var formatedDate = SatsManagementService.getDateFormat(dateString);
        return formatedDate;
      }
      /**
       * [createSRJob Creates a job SR]
       */
      function createSRJob(){
        SatsManagementService.createSRJob(vm.files[0].lfFile)
        .then(function(response){
          NotificationService.success({
            title: 'global.sats.title.'+vm.userRole.pretty,
            message: 'Job Created Succesfully'
          });
          $state.go(vm.userRole.pretty+'.sats', {
            reload: true
          });
        }, function(error){
          if(error.data.message == 'error.validation'){
            vm.recordError = error.data;
          }
          NotificationService.error({
            title: 'global.sats.title.'+vm.userRole.pretty,
            error: error
          });
        }); 
      }

      /**
       * [uploadJobSR Uploads Job through csv and converts to json data]
       */
      function uploadJobSR(){
        vm.recordError = [];
        var csvFile = vm.files[0];
        if (!csvFile) {
          NotificationService.simpleErrorToast({
            title: 'alertMessage.INVALID_INPUT',
            message: 'global.pleaseChooseACsvFile'
          });
          return false;
        }
        vm.isCsvFile = CsvUploadService.checkFileType(csvFile.lfFile);
        if (vm.isCsvFile) {
          UtilService.csvToJson(csvFile.lfDataUrl)
          .then(function (response) {
            vm.srItems = response;
            if(vm.srItems.length <1){
              NotificationService.simpleErrorToast({
                title: 'alertMessage.INVALID_INPUT',
                message: 'alertMessage.FILL_CSV'
              });
            }
          });
         } 
      }

      /**
       * [resetValues Resets the CSV data and error data when clicking browse button]
       */
      function resetValues(){
        vm.srItems = [];
        vm.recordError = [];
      }
    }
  })();
  