/**
 * @Author: Ajay
 * @Date:   25-Apr-2017 11:08 +05:30
 * @Project: Ezyprocure
 * @Filename: admin.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 01-Jun-2017 13:06 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('admin', {
      abstract: true,
      parent: 'app',
      url: '/admin',
      views: {
        'navbar@': {
          templateUrl: 'app/admin-modules/common/admin.navbar.html',
          controller: 'AdminNavbarController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    });
  }
})();
