/**
* @Author: Ajay
* @Date:   14-Dec-2016 12:54 +05:30
* @Project: Ezyprocure
* @Filename: localstorage.config.js
* @Last modified by:   Ajay
* @Last modified time: 27-Mar-2017 18:55 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(localStorageConfig);

  localStorageConfig.$inject = ['$localStorageProvider', '$sessionStorageProvider'];

  function localStorageConfig($localStorageProvider, $sessionStorageProvider) {
    $localStorageProvider.setKeyPrefix('ezy-');
    $sessionStorageProvider.setKeyPrefix('ezy-');
  }
})();
