/*
 * @Author: Jayalekshmi
 * @Date: 2019-11-15 10:38:51
 * @Project: Ezyprocure
 * @Filename: buyer.stock-transfer.controller.js
 * @Last Modified by: Lovin Chacko
 * @Last Modified time: 2020-12-02 16:50:15
 */


(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('BuyerStockTransferController', BuyerStockTransferController);

  BuyerStockTransferController.$inject = ['UTCDateService', 'BuyerBusinessService', '$mdDialog', '$filter', 'pagingParams', 'BuyerStockTransferService', 'BuyerDataService', 'paginationConstants', 'NotificationService', 'GLOBAL_CONSTANTS','EXPORT_CONSTANTS','CSVExportService'];

  function BuyerStockTransferController(UTCDateService, BuyerBusinessService, $mdDialog, $filter, pagingParams, BuyerStockTransferService, BuyerDataService, paginationConstants, NotificationService, GLOBAL_CONSTANTS,EXPORT_CONSTANTS,CSVExportService) {
    var vm = this;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.sockTransferStatuses = GLOBAL_CONSTANTS.SOCK_TRANSFER_STATUSES;
    vm.loadAll = loadAll;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.reset = reset;
    vm.stockTransferFilter = {};
    vm.todaysDate = new Date();
    vm.stockTransferDelete = stockTransferDelete;
    vm.confirmDelete = confirmDelete;
    vm.accessForStockTransferCostingView = BuyerDataService.getBuyerProfile().accessDTO.accessForStockTransferCostingView;
    vm.stockTransferReportHeader        = EXPORT_CONSTANTS.CSV.stockTransferReportHeader;
    vm.stockTransferReportHeaderNoCost  = EXPORT_CONSTANTS.CSV.stockTransferReportHeaderNoCost;
    vm.loadAll();
    vm.getStockTransferCSVData = getStockTransferCSVData;
    function reset() {
      vm.stockTransferFilter = {
      };
    }

    /**
     * [Loads all stock transfer]
     */
    function loadAll() {
      BuyerStockTransferService.getAllStockTransfers({
        page: vm.query.page - 1,
        size: vm.query.limit,
        fromDate: vm.stockTransferFilter.fromDate ? UTCDateService.utcFromDate(vm.stockTransferFilter.fromDate) : '',
        toDate: vm.stockTransferFilter.toDate ? UTCDateService.utcToDate(vm.stockTransferFilter.toDate) : '',
        fromOutlet: vm.stockTransferFilter.fromOutlet ? vm.stockTransferFilter.fromOutlet : '',
        status: vm.stockTransferFilter.status ? vm.stockTransferFilter.status : '',
        documentNumber : vm.stockTransferFilter.documentNo ? vm.stockTransferFilter.documentNo : ''
      }).then(function (response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.stockTransfer = response.data;
      }, function (error) {
        NotificationService.error({
          title: 'Stock Transfer Listing',
          error: error
        });
      });
    }

    /**
    * [getAllBuyerBusinessOutlets Get list of Business outlets of the logged in buyer]
    */
    function getAllBuyerBusinessOutlets() {
      if (vm.businessOutlets)
        return false;
      BuyerBusinessService.getAllBuyerBusinesses({
        query: '',
        page: 0,
        size: paginationConstants.maxLimit
      }).then(function (response) {
        vm.businessOutlets = response.data;
      }, function (error) {
      });
    }

    function stockTransferDelete(id) {
      BuyerStockTransferService.deleteStockTransfer(id).then(function (response) {
        NotificationService.success({
          title: 'Stock Transfer',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      },
        function (error) {
          NotificationService.error({
            title: 'Stock Transfer Delete',
            error: error
          });
        });
    }

    function confirmDelete(ev,id) {
      var confirm = $mdDialog.confirm()
      .title('Are you sure you want to delete this record?')
      .targetEvent(ev)
      .ok($filter('translate')('global.buttons.yes'))
      .cancel($filter('translate')('global.buttons.no'));
      $mdDialog.show(confirm).then(function() {
        vm.stockTransferDelete(id);
      }, function() {});
    }

    //Getting CSV DATA
    function getStockTransferCSVData() {
    if (!vm.stockTransfer) {
        NotificationService.simpleErrorToast({
        title: 'global.table.noRecordsFound',
        message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
    }
    var csvData = CSVExportService.getStockTransferCSVData(vm.stockTransfer,vm.accessForStockTransferCostingView);
    return csvData;
    }

  }
})();
