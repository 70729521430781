/*
 * @Author: Lovin C 
 * @Date: 2021-08-29 11:47:40 
 * @Last Modified by: Lovin C
 * @Last Modified time: 2021-11-03 14:10:58
 */




(function () {
  'use strict';

  angular.module('ezyprocureApp').factory('AddCardService', AddCardService);

  AddCardService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  function AddCardService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var AddCardService = {
      getSuppliers: getSuppliers,
      getBrands: getBrands,
      addInvoiceNext: addInvoiceNext,
      customPaymentsList: customPaymentsList,
      getInvoiceData: getInvoiceData,
      setInvoiceData: setInvoiceData,
      confirmAndPay: confirmAndPay,
      setReportData: setReportData,
      getReportData: getReportData,
      connectWorldPay: connectWorldPay
    };
    var invData = [];
    var reportData = [];
    return AddCardService;

    function setReportData(data) {
      reportData = data;
    }

    function getReportData() {
      return reportData;
    }

    function setInvoiceData(data) {
      invData = data;
    }

    function getInvoiceData() {
      return invData;
    }

    function connectWorldPay(params) {
      var deferred = $q.defer();
      $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'pay/card/redirect',
          method: "GET",
          params: params
      }).then(function (data) {
          deferred.resolve(data);
      }).catch(function (message) {
          deferred.reject(message);
      });
      return deferred.promise;

  }

    function confirmAndPay(data) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'customs/payment',
        method: "POST",
        data: data
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function customPaymentsList(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'customs-payments-listing',
        method: "GET",
        params: params
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getSuppliers() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'customs/supplier/list',
        method: "GET"
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getBrands(param) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'customs/brand/list',
        method: "GET",
        params: param
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function addInvoiceNext(data) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'customs/due-payment-summary',
        method: "POST",
        data: data
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
