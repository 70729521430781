/*
 * @Author: Lovin C 
 * @Date: 2021-07-30 17:18:02 
 * @Last Modified by: Lovin C
 * @Last Modified time: 2021-08-17 14:50:26
 */


(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('EzypaymentExistingMappingController', EzypaymentExistingMappingController);

  EzypaymentExistingMappingController.$inject = ['$stateParams', '$state', 'NotificationService', 'BuyerDataService', 'BrandManagementService', 'EddaAccountMappingService', 'paginationConstants', 'BuyerSupplierMappingService'];

  function EzypaymentExistingMappingController($stateParams, $state, NotificationService, BuyerDataService, BrandManagementService, EddaAccountMappingService, paginationConstants, BuyerSupplierMappingService) {

    var vm = this;
    vm.init = init;
    vm.userRole = $stateParams.userRole;
    vm.addNewDDA = addNewDDA;
    vm.cancel = cancel;
    vm.filter = {
      supplier: [],
      brand: []
    };
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.getDDAAccounts = getDDAAccounts;
    vm.loadAllBuyerMappedSuppliers = loadAllBuyerMappedSuppliers;

    // init
    function init() {
      getBrands();
      getDDAAccounts()
    }

    //get all DDA Account details of buyer or supplier
    function getDDAAccounts() {
      if(vm.ddaData && vm.ddaData != undefined)
        return;
      if (vm.userRole == 'buyer') {
        EddaAccountMappingService.getEddaAccountMappings().then(function (response) {
          vm.ddaData = response.data;
        },
          function (error) {
            NotificationService.error({
              title: 'eDDA Account Mapping',
              error: error
            });
          });
      }
      else {
        EddaAccountMappingService.getSupplierEddaAccountMappings().then(function (response) {
          vm.ddaData = response.data;
        },
          function (error) {
            NotificationService.error({
              title: 'eDDA Account Mapping',
              error: error
            });
          });
      }
    }

    //get all buyer mapped suppliers
    function loadAllBuyerMappedSuppliers() {
      var params = {
        page: 0,
        size: paginationConstants.maxLimit
      };
      var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      BuyerSupplierMappingService.getAllBuyerMappedSuppliers(buyerId, params).then(function (response) {
        vm.suppliers = response.data;
      }, function (error) {
        NotificationService.error({
          title: 'Buyer Mapped Suppliers',
          error: error
        });
      });
    }

    // get all brands mapped by buyer
    function getBrands() {
      var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      BrandManagementService.getAllBrandsByBuyerId({
        page: 0,
        size: paginationConstants.maxLimit,
        query: '',
        buyerId: buyerId
      }).then(function (response) {
        vm.brands = response.data;
      });
    }

    //Map brands with existing DDA Account number
    function addNewDDA() {
      EddaAccountMappingService.existingEddaAccountMapping({
        'authorizationId': vm.filter.accountObj.id,
        'brandIds': _.map(vm.filter.brand, _.property('id')),
        'supplier': vm.filter.supplier.length !== 0 ? vm.filter.supplier : null
      }).then(function (response) {
        NotificationService.success({
          title: 'eDDA Account Mappings',
          message: 'New Account Mapped SuccessFully'
        });
        $state.go('ezypayment.buyer.edda-account-mapping');
      },
        function (error) {
          NotificationService.error({
            title: 'eDDA Account Mapping',
            error: error
          });
        });
    }

    //cancel
    function cancel() {
      $state.go('ezypayment.buyer.edda-account-mapping');
    }

    //select all 
    function selectAll(dropdown) {
      switch (dropdown) {
        case 'SUPPLIERS':
          vm.filter.supplier = _.map(vm.suppliers, function (each) { return each });
          break;
        case 'BRANDS':
          vm.filter.brand = _.map(vm.brands, function (each) { return each });
          break;
      }
    }

    // deselect all
    function deselectAll(dropdown) {
      switch (dropdown) {
        case 'SUPPLIERS':
          vm.filter.supplier = [];
          break;
        case 'BRANDS':
          vm.filter.brand = [];
          break;
      }
    }

  }
})();
