/*
 * @Author: Gayathri
 * @Date: 2018-12-27 11:46:35
 * @Project: Ezyprocure
 * @Filename: buyer.order-template.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-05-15T10:07:08+05:30
 */
(function () {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('OrderTemplateController', OrderTemplateController);

  OrderTemplateController.$inject = ['$scope', '$rootScope', '$q', '$state', '$mdDialog', 'NotificationService', 'BuyerOrderTemplateService', 'paginationConstants', 'EXPORT_CONSTANTS', 'CSVExportService'];
  function OrderTemplateController($scope, $rootScope, $q, $state, $mdDialog, NotificationService, BuyerOrderTemplateService, paginationConstants, EXPORT_CONSTANTS, CSVExportService) {
    var vm = this;
    vm.getAllOrderTemplate = getAllOrderTemplate;
    vm.init = init;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.deleteOrderTemplateById = deleteOrderTemplateById;
    vm.getOrderTemplateById=getOrderTemplateById;
    vm.templateIdList=[];
    vm.orderTemplateItemsHeaders = EXPORT_CONSTANTS.CSV.OrderTemplateItemsCSVHeaders
    vm.todaysDate = new Date();
    vm.getOrderTemplateCSVDataById = getOrderTemplateCSVDataById;

    function init() {
      vm.getAllOrderTemplate();
    }

    //get all order templates
    function getAllOrderTemplate() {
      BuyerOrderTemplateService.getAllOrderTemplates().then(function (response) {
        vm.templateList = response.data;
        if(vm.templateList.length>0){
          vm.getOrderTemplateById(vm.templateList[0].id);
        }
      }, function (error) {
        NotificationService.error({
          title: 'Order Template',
          error: error
        })
      });
    }

    //get all order template by id
    function getOrderTemplateById(id){
      BuyerOrderTemplateService.getOrderTemplateById(id).then(function (response) {
        vm.templateIdList.push(id);
        vm.template = response;
        if(response.orderTemplate.buyerBusiness){
          vm.businessOutlets = response.orderTemplate.buyerBusiness.businessOutletName;
        } else {
          vm.businessOutlets = _.map(response.buyerBusinesses, _.property('businessOutletName'));
        }
        _.map(vm.templateList, function (item) {
          if (item.id == id) {
            item.items = vm.template.items
          }
        })
        vm.supplierList = _.uniq(_.map(response.items, _.property('supplier.companyName')));
      })
    }

    //show confirmation modal for deleting the template
    vm.showConfirm = function (ev, templateId) {
      var confirm = $mdDialog.confirm()
      .title('Are you sure you want to delete this Order template?')
      .textContent('This action cannot be reverted back.')
      .targetEvent(ev)
      .ok('Delete')
      .cancel('Cancel');
      $mdDialog.show(confirm).then(function () {
        vm.deleteOrderTemplateById(templateId);
      }, function () { });
    }

    //delete order template
    function deleteOrderTemplateById(templateId) {
      BuyerOrderTemplateService.deleteOrderTemplateById(templateId).then(function (response) {
        NotificationService.success({
          title: 'Order Template',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.getAllOrderTemplate();
        vm.templateIdList=[];
      }, function (error) {
        NotificationService.error({
          title: 'Order Template',
          error: error
        });
      });
    }

    /**
    * [getOrderTemplateCSVDataById get CSV template Data by template]
    * @param  {[type]} templateItem [template details]
    * @return {[type]}              [description]
    */
    function getOrderTemplateCSVDataById(templateItem){
      var deferred = $q.defer();
      if (!templateItem) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.PLEASE_SELECT_ANOTHER_TEMPLATE'
        });
        return deferred.promise;
      }
      return CSVExportService.getOrderTemplateCSVDataById(templateItem);
    }


  }
}
)();
