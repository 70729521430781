/**
 * @Author: Jayalekshmi
 * @Date:   2018-07-12 13:00:30
 * @Filename: transaction-summary-report.service.js
 * @Last modified by:   Jayalekshmi
 * @Last modified time: 2018-07-13T11:07:38+05:30
 */



(function() {
    'use strict';

    angular.module('ezyprocureApp').factory('SupplierTransactionSummaryReportService', SupplierTransactionSummaryReportService);

    SupplierTransactionSummaryReportService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
    function SupplierTransactionSummaryReportService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

      var supplierTransactionSummaryReportService = {
        getAllTransactionSummaryReports:getAllTransactionSummaryReports,
        getXeroAccountType: getXeroAccountType,
      };
      return supplierTransactionSummaryReportService;

      function getAllTransactionSummaryReports(params) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PAYMENT + 'transaction-summary-report',
          method: "GET",
          params:params
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      /*
      *[getXeroAccountType return xero account type]
      */
      function getXeroAccountType() {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'accounts/xero',
          method: "GET"
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
    }
  })();
