/**
* @Author: Ajay
* @Date:   12-May-2017 14:27 +05:30
* @Project: Ezyprocure
* @Filename: buyer.gl-specific-item-groups.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 07-Nov-2017 15:33 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('GlSpecificItemGroupController', GlSpecificItemGroupController);

  GlSpecificItemGroupController.$inject = ['$scope', '$rootScope', '$state', '$mdDialog', 'GlSpecificItemGroupService', 'pagingParams', 'paginationConstants', 'NotificationService'];

  function GlSpecificItemGroupController($scope, $rootScope, $state, $mdDialog, GlSpecificItemGroupService, pagingParams, paginationConstants, NotificationService) {
    var vm = this;

    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;

    vm.loadAll = loadAll;
    vm.showConfirm = showConfirm;
    vm.cancel = cancel;
    vm.showAddGlSpecificItemGroup = showAddGlSpecificItemGroup;
    vm.addGlSpecificItemGroup = addGlSpecificItemGroup;
    vm.showEditGlSpecificItemGroup = showEditGlSpecificItemGroup;
    vm.updateGlSpecificItemGroup = updateGlSpecificItemGroup;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    /**
    * [sort description]
    * @return {[type]} [description]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function loadAll(search) {
      if (!search) {
        vm.searchText = '';
      }
      GlSpecificItemGroupService.getAllGlSpecificItemGroups({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.searchText
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.glSpecificItemGroups = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'GL Specific Item Group Listing',
          error: error
        });
      });
    }

    vm.loadAll();

    function deleteGlSpecificItemGroup(glSpecificItemGroupId) {
      GlSpecificItemGroupService.deleteGlSpecificItemGroup(glSpecificItemGroupId).then(function(response) {
        NotificationService.success({
          title: 'GL Specific Item Group',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'GL Specific Item Group Deletion',
          error: error
        });
      });
    }

    function showConfirm(ev, glSpecificItemGroup) {
      var confirm = $mdDialog.confirm()
      .title('Are you sure you want to delete Gl Specific Item Group - ' + glSpecificItemGroup.name + ' ? ')
      .textContent('This action could not be reverted back.')
      .targetEvent(ev)
      .ok('Delete')
      .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        deleteGlSpecificItemGroup(glSpecificItemGroup.id);
      }, function() {});
    }

    function cancel() {
      $mdDialog.cancel();
    }

    //Add Gl Specific Item Group Starts here

    function showAddGlSpecificItemGroup(event) {
      vm.newGlSpecificItemGroup = {};
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/gl-module/gl-specific-item-groups/buyer.gl-specific-item-group.add.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function addGlSpecificItemGroup(newGlSpecificItemGroup) {
      GlSpecificItemGroupService.createGlSpecificItemGroup(newGlSpecificItemGroup).then(function(response) {
        $scope.$emit('ezyprocureApp:glSpecificItemGroupUpdate', response);
        vm.cancel();
        NotificationService.success({
          title: 'GL Specific Item Group',
          message: 'alertMessage.CREATED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'GL Specific Item Group Creation',
          error: error
        });
      });
    }

    //Add GlSpecificItemGroup Ends here


    //Edit GlSpecificItemGroup Starts here

    function showEditGlSpecificItemGroup(event, oldGlSpecificItemGroup) {
      vm.editGlSpecificItemGroup = angular.copy(oldGlSpecificItemGroup);
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/gl-module/gl-specific-item-groups/buyer.gl-specific-item-group.edit.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function updateGlSpecificItemGroup(oldGlSpecificItemGroup) {
      GlSpecificItemGroupService.updateGlSpecificItemGroup(oldGlSpecificItemGroup).then(function(response) {
        $scope.$emit('ezyprocureApp:glSpecificItemGroupUpdate', response);
        vm.cancel();
        NotificationService.success({
          title: 'GL Specific Item Group',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'GL Specific Item Group Updation',
          error: error
        });
      });
    }

    //Edit GlSpecificItemGroup Ends here

  }


})();
