/**
 * @Author: Ajay
 * @Date:   02-May-2018 16:22 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.manage-approval-level.level-selection.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 16-May-2018 13:16 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerManageApprovalLevelSelectionController', BuyerManageApprovalLevelSelectionController);

  BuyerManageApprovalLevelSelectionController.$inject = ['$scope', '$state', '$filter', '$q', '$mdDialog', 'SupplierItemService', 'BuyerManageApprovalLevelService', 'NotificationService', 'BuyerBusinessUserMapService', 'paginationConstants', 'BuyerUserManagementService', 'BuyerDataService'];

  function BuyerManageApprovalLevelSelectionController($scope, $state, $filter, $q, $mdDialog, SupplierItemService, BuyerManageApprovalLevelService, NotificationService, BuyerBusinessUserMapService, paginationConstants, BuyerUserManagementService, BuyerDataService) {
    var vm = this;
    vm.init = init;
    vm.getAllBusinessUserMappingsForBusiness = getAllBusinessUserMappingsForBusiness;
    vm.getAllBuyerUserApprovers = getAllBuyerUserApprovers;
    vm.searchText = '';
    vm.manageApprovalLevelDTO = {};
    vm.manageApprovalLevelDTO.mappingLevels = [];
    vm.approvalLevelFilter = {};
    vm.getBusinessUserMappingForApproval = getBusinessUserMappingForApproval;
    vm.saveApprovalLevel = saveApprovalLevel;
    vm.addRow = addRow;
    vm.showConfirm = showConfirm;
    vm.showSelectedApprovalConfirm = showSelectedApprovalConfirm;
    vm.accessForNegativebuying = BuyerDataService.getBuyerProfile().accessDTO.accessForNegativeBuyingBuyerView;


    function init() {
      vm.approvalLevelFilter = BuyerManageApprovalLevelService.getApprovalLevelFilter();
      if(vm.approvalLevelFilter === null) {
        $state.go('buyer.tools.manage-approval-level');
      }
      else {
        vm.buyerBusinessId = vm.approvalLevelFilter.buyerBusinessId;
        vm.getAllBusinessUserMappingsForBusiness();
      }
    }

    // Load All supplier items which are mapped
    function getAllBusinessUserMappingsForBusiness() {
      BuyerBusinessUserMapService.getAllBusinessUserMappingsForBusiness({
        query: '',
      },vm.buyerBusinessId).then(function(response){
        vm.businessUserMappings = response.data;
        vm.addApprovalInProgress = false;
      },function(error){
        NotificationService.error({
          title: 'Buyer User - Business Mapping Listing',
          error: error
        });
      });
    }

    function maxApprovalLevel() {
      var maxApprovalLevel = 1;
      if(vm.businessUserMappings.length > 0){
        maxApprovalLevel = _.maxBy(vm.businessUserMappings, 'approvalLevel').approvalLevel+1;
      }
      return maxApprovalLevel;
    }

    function addRow() {
      vm.approvalLevels = _.range(1, maxApprovalLevel()+1, 1);
      vm.businessUserMappings.push({
        id: null,
        buyerBusiness:{
          id: vm.buyerBusinessId
        },
        type: 'DRAFT',
        approvalLevel: maxApprovalLevel()
      });
      vm.addApprovalInProgress = true;
    }

    function getAllBuyerUserApprovers(){
      BuyerUserManagementService.getAllBuyerUserApprovers({
        page: 0,
        size: paginationConstants.maxLimit,
        query: '',
        buyerBusinessId: vm.buyerBusinessId
      }).then(function(response){
        vm.buyerUsers = response.data;
      },function(error){
      });
    }

    function getBusinessUserMappingForApproval(mapping,index) {
      if (!vm.buyerBusinessId || !mapping.approvalLevel || !mapping.buyerUser.id)
        return false;
      BuyerBusinessUserMapService.getBusinessUserMappingForApproval({
        buyerBusinessId:vm.buyerBusinessId,
        approvalLevel: mapping.approvalLevel,
        buyerUserId:mapping.buyerUser.id
      }).then(function(response) {
        if(response.data){
          vm.businessUserMappings[index] = response.data;
          vm.businessUserMappings[index].type = 'DRAFT';
        }
      }, function(error) {
      });
    }

    function saveApprovalLevel(businessUserMapping) {
      BuyerBusinessUserMapService.saveApprovalLevel(vm.buyerBusinessId,businessUserMapping)
      .then(function(response) {
        NotificationService.success({
          title: 'Mapping Level',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        vm.getAllBusinessUserMappingsForBusiness();
      }, function(error) {
        NotificationService.error({
          title: 'Manage Mapping Level request',
          error: error
        });
      });
    }

    function deleteAllApprovalLevels(buyerBusinessId) {
      BuyerBusinessUserMapService.deleteAllApprovalLevels(buyerBusinessId).then(function(response) {
        NotificationService.success({
          title: 'Approval Levels',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.getAllBusinessUserMappingsForBusiness();
      }, function(error) {
        NotificationService.error({
          title: 'Approval Levels Deletion',
          error: error
        });
      });
    }

    function showConfirm(ev, buyerBusinessId) {
      var confirm = $mdDialog.confirm()
      .title('Are you sure you want to delete all approval levels of this outlet ?')
      .textContent('This action could not be reverted back.')
      .targetEvent(ev)
      .ok('Delete')
      .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        deleteAllApprovalLevels(buyerBusinessId);
      }, function() {});
    }

    function showSelectedApprovalConfirm(ev, buyerBusinessId) {
      var confirm = $mdDialog.confirm()
      .title('Are you sure you want to delete selected approval level of this outlet ?')
      .textContent('This action could not be reverted back.')
      .targetEvent(ev)
      .ok('Delete')
      .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        deleteSelectedApprovalLevel(buyerBusinessId);
      }, function() {});
    }

    function deleteSelectedApprovalLevel(buyerBusinessId) {
      BuyerBusinessUserMapService.deleteSelectedApprovalLevel(buyerBusinessId).then(function(response) {
        NotificationService.success({
          title: 'Approval Levels',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.getAllBusinessUserMappingsForBusiness();
      }, function(error) {
        NotificationService.error({
          title: 'Approval Levels Deletion',
          error: error
        });
      });
    }
  }
})();
