/**
* @Author: Ajay
* @Date:   18-Jan-2017 16:33 +05:30
* @Project: Ezyprocure
* @Filename: buyer.business-management.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 03-May-2018 11:04 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerBusinessListController', BuyerBusinessListController);

  BuyerBusinessListController.$inject = ['$scope', '$rootScope', '$state', 'NotificationService', '$mdDialog', 'BuyerBusinessService', 'pagingParams', 'paginationConstants', 'AuthServerProvider', 'BuyerDataService', 'Principal', 'Auth'];

  function BuyerBusinessListController($scope, $rootScope, $state, NotificationService, $mdDialog, BuyerBusinessService, pagingParams, paginationConstants, AuthServerProvider, BuyerDataService, Principal, Auth) {
    var vm = this;

    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.deleteBusinessOutlet = deleteBusinessOutlet;
    vm.loadAll = loadAll;
    vm.showConfirm = showConfirm;
    vm.availableOutlets;
    vm.totalOutlets;
    vm.onInit = onInit;
    vm.buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.initMappings = initMappings;

    function onInit() {
      var authJWT = AuthServerProvider.getToken();
      var decAuthJWT = jwt_decode(authJWT);
      vm.userId = decAuthJWT.uid;
      if (Principal.isCBEnabled()) {
        var profile = BuyerDataService.getCbBuyerProfile();
        vm.xbId = (profile && profile.buyerUser && profile.buyerUser.buyer) ? profile.buyerUser.buyer.id : null;
        if (!!vm.xbId) {
          Auth.checkCbHealth().then(function () {
            BuyerBusinessService.getOutletsData(vm.buyerId, vm.xbId).then(
              function (data) {
                vm.availableOutlets = data.Available;
                vm.totalOutlets = data.Maximum;
              }
            );
          });
        }
      }
    }

    //create outlet mappings for all suppliers, and all supplier items
    function initMappings(businessOutletId){
      BuyerBusinessService.initMappings(businessOutletId).then(function(response){
        NotificationService.success({
          title: 'Business Outlet',
          message: 'alertMessage.MAPPED_SUCCESSFULLY'
        });
      },
      function(error){
        NotificationService.error({
          title: 'Business Outlet Mapping',
          error: error
        });
      });
    }

    //Sorting of buyer business outlets
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    //Load all buyer business outlets
    function loadAll(search) {
      if (!search) {
        vm.searchText = '';
      }
      BuyerBusinessService.getAllBuyerBusinesses({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.searchText,
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.businessOutlets = response.data;
      },function(error){
        NotificationService.error({
          title: 'Business Outlet Listing',
          error: error
        });
      });
    }

    // delete a buyer business outlet. function accepts business outlet id as param
    function deleteBusinessOutlet(businessOutletId) {
      BuyerBusinessService.deleteBuyerBusiness(businessOutletId).then(function(response){
        NotificationService.success({
          title: 'Business Outlet',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      },function(error){
        NotificationService.error({
          title: 'Business Outlet Deletion',
          error: error
        });
      });
    }

    function showConfirm (ev,businessOutlet) {
      var confirm = $mdDialog.confirm()
            .title('Are you sure you want to delete this Business Outlet?')
            .textContent('This action could not be reverted back.')
            .targetEvent(ev)
            .ok('Delete')
            .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        deleteBusinessOutlet(businessOutlet.id);
      }, function() {
      });
    }

    vm.loadAll();

  }
})();
