/*
 * @Author: Lovin C 
 * @Date: 2021-07-29 15:29:22 
 * @Last Modified by: Lovin C
 * @Last Modified time: 2021-10-11 10:31:06
 */

(function () {
  'use strict';
  angular.module('ezyprocureApp').factory('EzypaymentEGiroFundTransferService', EzypaymentEGiroFundTransferService);

  EzypaymentEGiroFundTransferService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function EzypaymentEGiroFundTransferService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {
    var EzypaymentEGiroFundTransferService = {
      getEGiroFundTransfer: getEGiroFundTransfer,
      transactionPayouts: transactionPayouts,
      getBrands: getBrands,
      getSuppliers: getSuppliers,
      h2hCollection: h2hCollection,
      h2hPayment: h2hPayment,
      readGiroResponseFiles: readGiroResponseFiles
    };
    return EzypaymentEGiroFundTransferService;

    function h2hCollection(params) {
      var deffered = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/ocbc/generate-collection-file',
        method: 'POST',
        params: params,
        data: {},
      }).then(function (data) {
        deffered.resolve(data);
      }).catch(function (message) {
        deffered.reject(message);
      });
      return deffered.promise;
    }

    function h2hPayment(params, data) {
      var deffered = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/ocbc/generate-remittance-file',
        method: 'POST',
        params: params,
        data: data,
      }).then(function (data) {
        deffered.resolve(data);
      }).catch(function (message) {
        deffered.reject(message);
      });
      return deffered.promise;
    }

    function readGiroResponseFiles() {
      var deferred = $q.defer();
      $http({
        url:API_CONSTANTS.serviceBase + 'api/ocbc/read-giro-response-file',
        method: 'POST',
        data: {}
        }).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getSuppliers() {
      var deffered = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/ocbc/supplier/list',
        method: 'GET'
      }).then(function (data) {
        deffered.resolve(data);
      }).catch(function (message) {
        deffered.reject(message);
      });
      return deffered.promise;
    }

    function getBrands() {
      var deffered = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/ocbc/brand/list',
        method: 'GET'
      }).then(function (data) {
        deffered.resolve(data);
      }).catch(function (message) {
        deffered.reject(message);
      });
      return deffered.promise;
    }

    function getEGiroFundTransfer(params) {
      var deffered = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/transaction/payouts',
        method: 'GET',
        params: params,
      }).then(function (data) {
        deffered.resolve(data);
      }).catch(function (message) {
        deffered.reject(message);
      });
      return deffered.promise;
    }

    function transactionPayouts(params, data) {
      var deffered = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/ocbc/fast/transaction/payment',
        method: 'POST',
        params: params,
        data: data,
      }).then(function (data) {
        deffered.resolve(data);
      }).catch(function (message) {
        deffered.reject(message);
      });
      return deffered.promise;
    }
  }

})();
