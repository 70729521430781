/*
 * @Author: Lovin C 
 * @Date: 2021-08-29 11:47:40 
 * @Last Modified by: Lovin C
 * @Last Modified time: 2021-09-08 16:36:17
 */




(function () {
  'use strict';

  angular.module('ezyprocureApp').factory('CustomPaymentsService', CustomPaymentsService);

  CustomPaymentsService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  function CustomPaymentsService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var CustomPaymentsService = {
      getSuppliers: getSuppliers,
      getBrands: getBrands,
      addInvoiceNext: addInvoiceNext,
      customPaymentsList: customPaymentsList,
      getInvoiceData: getInvoiceData,
      setInvoiceData: setInvoiceData,
      confirmAndPay: confirmAndPay,
      setReportData: setReportData,
      getReportData: getReportData,
      getAllCards: getAllCards,
      customInvoiceCsv: customInvoiceCsv,
      deleteInvoiceById: deleteInvoiceById,
      getAllBrands: getAllBrands,
      getSupplierByBrandId: getSupplierByBrandId,
      getPurposes: getPurposes
    };

    var invData = [];
    var reportData = [];
    
    return CustomPaymentsService;

    function getPurposes() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'custom/purpose-code',
        method: "GET"
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getAllBrands() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'customs/brand/list',
        method: "GET"
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getSupplierByBrandId(param) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'customs/supplier/list',
        method: "GET",
        params: param
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function customInvoiceCsv(csvUploadData) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'customs/payment-upload',
        method: "POST",
        data: csvUploadData,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function deleteInvoiceById(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'customs/remove-invoice',
        method: "PUT",
        params: params
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function setReportData(data) {
      reportData = data;
    }

    function getReportData() {
      return reportData;
    }

    function setInvoiceData(data) {
      invData = data;
    }

    function getInvoiceData() {
      return invData;
    }

    function getAllCards(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'customs/cards/brand',
        method: "GET",
        params: params,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function confirmAndPay(data, otp) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'customs/payment',
        method: "POST",
        data: data,
        headers: { 'authorization-otp' : otp }
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function customPaymentsList(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'customs-payments-listing',
        method: "GET",
        params: params
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getSuppliers() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'customs/supplier/list',
        method: "GET"
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getBrands(param) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'customs/brand/list',
        method: "GET",
        params: param
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function addInvoiceNext(data) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'customs/due-payment-summary',
        method: "POST",
        data: data
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
