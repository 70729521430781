/**
* @Author: Abhay
* @Date:   05-February-2021 16:15 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.odbc-due-payment-summary.state.js
* @Last modified by:   Abhay
* @Last modified time: 05-February-2021 16:15 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('ezypayment.buyer.ocbc-due-payment-summary', {
      parent: 'ezypayment.buyer',
      url: '/ocbc-due-payment-summary',
      data: {
        authorities: ['ROLE_BUYER']
      },
      views: {
        'content@': {
          templateUrl: 'app/ezypayment-modules/buyer-modules/ocbc-pay-due-invoices/ezypayment.ocbc-due-payment-summary.html',
          controller: 'EzypaymentODBCDuePaymentSummaryController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return true;
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('purchaseOrder');
          $translatePartialLoader.addPart('invoiceOCR');
          $translatePartialLoader.addPart('externalPayment');
          return $translate.refresh();
        }]
      }
    })
    .state('ezypayment.buyer.ocbc-due-payment-summary.details', {
      parent: 'ezypayment.buyer.ocbc-due-payment-summary',
      url: '/details',
      data: {
        authorities: ['ROLE_BUYER']
      },
      views: {
        'content@': {
          templateUrl: 'app/ezypayment-modules/buyer-modules/ocbc-pay-due-invoices/details/ezypayment.ocbc-due-payment-summary.details.html',
          controller: 'EzypaymentODBCDuePaymentSummaryDetailsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return true;
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    })
    .state('ezypayment.buyer.ocbc-due-payment-summary.details.transaction-report', {
      parent: 'ezypayment.buyer.ocbc-due-payment-summary.details',
      url: '/details/transaction-report',
      data: {
        authorities: ['ROLE_BUYER']
      },
      views: {
        'content@': {
          templateUrl: 'app/ezypayment-modules/buyer-modules/ocbc-pay-due-invoices/details/ezypayment.ocbc-due-payment-summary.details.transaction-report.html',
          controller: 'EzypaymentODBCDuePaymentSummaryDetailsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return true;
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    })
  }
})();
