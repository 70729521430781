/**
* @Author: Ajay
* @Date:   20-Mar-2017 14:43 +05:30
* @Project: Ezyprocure
* @Filename: supplier.promotions.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 20-Jul-2017 15:47 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('PromotionService', PromotionService);

  PromotionService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS', 'UTCDateService'];

  function PromotionService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS, UTCDateService) {

    var promotionService = {
      getAllPromotionItemsForSupplier: getAllPromotionItemsForSupplier,
      getAllPromotionItemsForBuyer: getAllPromotionItemsForBuyer,
      getSupplierItemPromotionById: getSupplierItemPromotionById,
      createSupplierItemPromotion: createSupplierItemPromotion,
      updateSupplierItemPromotion: updateSupplierItemPromotion,
      deleteSupplierItemPromotion: deleteSupplierItemPromotion,
      getPromotionStartDateWithTime: getPromotionStartDateWithTime,
      getPromotionEndDateWithTime : getPromotionEndDateWithTime,
      getPromotionItemsForBuyerByPromotionItemId : getPromotionItemsForBuyerByPromotionItemId
    };

    return promotionService;

    /* --  Promotion CRUID operations  -- */

    /**
    * [getAllPromotionItemsForSupplier Get All Supplier Items with Promotions]
    * @param  {[Object]} params [contains searchTerm, pagination params]
    * @return {[list]}        [Supplier Item Promotions]
    */
    function getAllPromotionItemsForSupplier(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'supplier/' + RESOURCE_URL_CONSTANTS.PROMOTION_RESOURCE,
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [getAllPromotionItemsForBuyer Get All Buyer Mapped Item's Promotions]
    * @param  {[Object]} params [contains searchTerm, pagination params]
    * @return {[list]}        [Buyer Item Promotions]
    */
    function getAllPromotionItemsForBuyer(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyer/' + RESOURCE_URL_CONSTANTS.PROMOTION_RESOURCE,
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [getSupplierItemPromotionById Get Supplier Item Promotion details By PromotionId]
    * @param  {[type]} promotionId [description]
    * @return {[type]}             [description]
    */
    function getSupplierItemPromotionById(promotionId) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PROMOTION_RESOURCE + promotionId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [createSupplierItemPromotion Create Supplier Item Promotion]
    * @param  {[type]} promotionDTO [description]
    * @return {[type]}              [description]
    */
    function createSupplierItemPromotion(promotionDTO) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PROMOTION_RESOURCE, promotionDTO).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [updateSupplierItemPromotion Update Supplier Item Promotion]
    * @param  {[type]} promotionDTO [description]
    * @return {[type]}              [description]
    */
    function updateSupplierItemPromotion(promotionDTO) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PROMOTION_RESOURCE, promotionDTO).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [deleteSupplierItemPromotion Delete Supplier Item Promotion]
    * @param  {[type]} promotionId [promotionId]
    * @return {[type]}             [description]
    */
    function deleteSupplierItemPromotion(promotionId) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PROMOTION_RESOURCE + promotionId).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getPromotionStartDateWithTime startDate with time]
     * @param  {[Date]} startDate [startDate]
     * @return {[Date]}           [startDate with time]
     */
    function getPromotionStartDateWithTime(startDate) {
      var updatedDate;
      var currentDate = new Date();
      var startDateToday = moment(currentDate).isSame(startDate, 'day');
      if(startDateToday)
        updatedDate = new Date(startDate.setHours(currentDate.getHours(),currentDate.getMinutes() + 2,currentDate.getSeconds(),currentDate.getMilliseconds()));
      else
        updatedDate = UTCDateService.utcFromDate(startDate);
      return updatedDate;
    }

    /**
     * [getPromotionEndDateWithTime endDate with time]
     * @param  {[Date]} endDate [endDate]
     * @return {[Date]}           [endDate with time]
     */
    function getPromotionEndDateWithTime(endDate) {
      return UTCDateService.utcToDate(endDate);
    }

    function getPromotionItemsForBuyerByPromotionItemId(promotionItemId, params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyer/' + RESOURCE_URL_CONSTANTS.PROMOTION_RESOURCE + 'item-approval-request/' + promotionItemId,
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
