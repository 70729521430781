/**
 * @Author: Ajay
 * @Date:   09-Mar-2017 18:51 +05:30
 * @Project: Ezyprocure
 * @Filename: cart.service.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-10-15T09:50:29+05:30
 */



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('CartService', CartService);

  CartService.$inject = ['$http', '$q', 'API_CONSTANTS', 'NotificationService','$filter'];
  function CartService($http, $q, API_CONSTANTS, NotificationService,$filter) {

    var cartService = {
      addToCart: addToCart,
      updateCart: updateCart,
      removeFromCart: removeFromCart,
      getCartDetails: getCartDetails,
      updateLocalCart: updateLocalCart,
      validateCheckout: validateCheckout,
      checkout: checkout,
      checkItemInCart: checkItemInCart,
      checkDeliveryQuantity: checkDeliveryQuantity,
      checkOrderedTime : checkOrderedTime,
      getCartItemTotal: getCartItemTotal,
      getCartTotal: getCartTotal,
      createCartByTemplate:createCartByTemplate,
      checkLeadTime: checkLeadTime,
      cartCount : cartCount,
      fileUpload: fileUpload,
      getBase64: getBase64,
      checkNextpossibleDeliveryDate : checkNextpossibleDeliveryDate,
      checkSelectedDateisInHoliday : checkSelectedDateisInHoliday,
      getAllSupplierCalendarsHoliday: getAllSupplierCalendarsHoliday,
      otherDaysCheckAvailable : otherDaysCheckAvailable
    };

    var cart = {};

    return cartService;

    function fileUpload(files){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'file/upload',
        method: "POST",
        data: files,
      }).then(function(response) {
        //update cart variable
        deferred.resolve(response);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getBase64(inputFile, callback) {

        var base64;
        var fileReader = new FileReader();
        fileReader.onload = function(event) {
          base64 = event.target.result;
          callback(base64);
        };
        fileReader.readAsDataURL(inputFile);

    }

    function cartCount(cart){
      return cart;
    }
    //Add an item to cart. Accepts item object as param
    function addToCart(cartItem, params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'cart',
        method: "POST",
        params : params,
        data: cartItem,
      }).then(function(response) {
        //update cart variable
        updateLocalCart(response, cartItem);
        deferred.resolve(cart);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update an item in cart. Accepts item object as param
    function updateCart(cartItem) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'cart',
        method: "PUT",
        data: cartItem,
      }).then(function(response) {
        //update cart variable
        updateLocalCart(response, cartItem);
        deferred.resolve(cart);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Remove an item from cart. Accepts cartId as param
    function removeFromCart(cartId) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'cart/' + cartId,
        method: "DELETE",
      }).then(function(response) {
        _.remove(cart.cartItems, {id: cartId});
        cart.cartCount = response.data;
        deferred.resolve(response);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get All Items in the Cart
    function getCartDetails(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'cart',
        method: "GET",
        params: params,
      }).then(function(data) {
        cart.cartItems = data.data;
        cart.cartCount = data.headers('X-Total-Count');
        cartCount(cart.cartCount);
        deferred.resolve(cart);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update local cart. Accepts updated cart item and all cart items as params.
    function updateLocalCart(cartResponse, cartItem) {
      //check if item exists in cart
      var index = _.findIndex(cart.cartItems, function(element) {
        // double check, since undefined === undefined
        return element['id'] !== "undefined" && element['id'] === cartItem['id'];
      });
      //if item exists, update it with new value
      if (index > -1) {
        cart.cartItems[index] = cartResponse.data.cart;
        cart.cartItems[index].multiplierFactor = cartResponse.data.multiplierFactor;
        cart.cartItems[index].moq = cartResponse.data.moq;
        cart.cartItems[index].freePromotionItem = cartResponse.data.freePromotionItem;
        cart.cartItems[index].freeItem = cartResponse.data.freeItem;
      }
      //if item doesn't exists, increase counter and add cart item
      else {
        cart.cartItems.push(cartResponse.data.cart);
        cart.cartCount = cartResponse.data.cartCount;
      }
      return cart;
    }

    //validate cart contents before checkout
    function validateCheckout(params, data) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'validate-checkout',
        method: "POST",
        params: params,
        data: data
      }).then(function(response) {
        deferred.resolve(cart);
        cart.purchaseOrderList = response.data;
        cart.cartItems = [];
        cart.cartCount = 0;
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Checkout cart contents
    function checkout(params, data) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'checkout',
        method: "POST",
        params: params,
        data: data
      }).then(function(response) {
        deferred.resolve(cart);
        cart.purchaseOrderList = response.data;
        cart.cartItems = [];
        cart.cartCount = 0;
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Checkout cart contents
    function createCartByTemplate(data) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'cart/template',
        method: "POST",
        data:data
      }).then(function(response) {
        deferred.resolve(response);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //check if item is in cart and returns the cart id of the item
    function checkItemInCart(item) {
      var cartItemId = _.result(_.find(cart.cartItems, function(cartItem) {
        return cartItem.items['id'] === item.itemId;
      }), 'id');

      //if item exists, return cartItemId
      if (cartItemId) {
        return cartItemId;
      }
      //if item doesn't exists, retun false
      else {
        return false;
      }
    }

    //check if entered quantity is deliverable
    function checkDeliveryQuantity(itemToBuy) {
      if(itemToBuy.quantity && itemToBuy.quantity >= itemToBuy.moq) {
        // return true;
        if(itemToBuy.maxAllocation === null || (itemToBuy.maxAllocation !== null && itemToBuy.quantity <= itemToBuy.maxAllocation)) {
          return true;
        }
        else {
          NotificationService.simpleErrorToast({
            title: 'error.validation',
            message: 'alertMessage.MAXIMUM_ALLOCATION_VALIDATION',
            params: {value: itemToBuy.maxAllocation}
          });
          return false;
        }
      }
      else {
        NotificationService.simpleErrorToast({
          title: 'error.validation',
          message: 'alertMessage.QUANTITY_LESS_THAN_MOQ'
        });
        return false;
      }
    }

    /**
    * [isSupplier check whether supplier data is present or not]
    * @param  {[type]}  itemToBuy [selectedItem]
    * @return {object}           [supplier object]
    */

    function isSupplier(itemToBuy){
      if(itemToBuy.items || itemToBuy.supplier){
        var itemSupplier = itemToBuy.items? (itemToBuy.items.supplier ? itemToBuy.items.supplier : null): (itemToBuy.supplier ? itemToBuy.supplier : null) ;
        return itemSupplier;
      }
    }


    /**
    * [checkOrderedTime check if chosen time is within supplier condition order time]
    * @param  {[type]} itemToBuy [buyer chosen item]
    * @return {[boolean]}           [description]
    */
    function checkOrderedTime(itemToBuy, itemListQuery){
      var chosenDayOfWeek = moment(itemToBuy.deliveryDate).isoWeekday();
      var currentDayOfWeek = moment(new Date()).isoWeekday();
      var currentHour = moment(new Date(),'hhmm').format('HHmm');
      var isValidTime = false;
      var currentWeek = moment(new Date()).isoWeek();
      var chosenWeek = moment(itemToBuy.deliveryDate).isoWeek();
      var itemSupplier = isSupplier(itemToBuy);
      var deliveryCond = itemToBuy.buyerDeliveryConditions ? itemToBuy.buyerDeliveryConditions.deliveryConditions : itemSupplier; // get specific delivery condition, otherwise get supplier default conditions
      var chosenYear = moment(itemToBuy.deliveryDate).weekYear();
      var currentYear = moment().weekYear();
      var nextpossibleDeliveryDate = checkNextpossibleDeliveryDate(itemToBuy.deliveryDate, deliveryCond);
      if(itemSupplier){
        if(checkDeliveryDate(itemToBuy, itemListQuery)) {
          if(chosenDayOfWeek === 1 && currentDayOfWeek === 5 && chosenWeek - currentWeek === 1 ) {
            isValidTime = checkChosenDate(currentHour, deliveryCond.orderTimeForFriday, nextpossibleDeliveryDate);
            return isValidTime;
          }
          else if(chosenDayOfWeek === 1 && currentDayOfWeek === 6 && chosenWeek - currentWeek === 1) {
            isValidTime = checkChosenDate(currentHour, deliveryCond.orderTimeForSaturday, nextpossibleDeliveryDate);
            return isValidTime;
          }
          else if(chosenDayOfWeek - currentDayOfWeek === 1 && chosenWeek - currentWeek === 0 && chosenYear === currentYear) {
            isValidTime = checkChosenDate(currentHour, deliveryCond.orderTimeForToday, nextpossibleDeliveryDate);
            return isValidTime;
          }
          else{
            isValidTime = true;
            return isValidTime;
          }
        }
      }
      else{
        isValidTime = true;
        return isValidTime;
      }
    }

    /**
    * [checkDeliveryDate Check if the delivery date is acceptable for the supplier]
    * @param  {[date]} deliveryDate [delivery date for an item]
    * @return {[boolean]}              [description]
    */
    function checkDeliveryDate(itemToBuy, itemListQuery) {
      var isDeliveryValid = true;
      var currentDayOfYear= moment(new Date()).dayOfYear();
      var chosenDayOfyear = moment(itemToBuy.deliveryDate).dayOfYear();
      var chosenYear = moment(itemToBuy.deliveryDate).weekYear();
      var currentYear = moment().weekYear();      

      var selectedSupplier = itemToBuy.items? itemToBuy.items.supplier : itemToBuy.supplier;
      var deliveryCond = itemToBuy.buyerDeliveryConditions ? itemToBuy.buyerDeliveryConditions.deliveryConditions : selectedSupplier; // get specific delivery condition, otherwise get supplier default conditions
      deliveryCond.holidaylist = itemToBuy.supplier ? itemToBuy.supplier.holidaylist : itemToBuy.items.supplier.holidaylist;
      var nextpossibleDeliveryDate    = checkNextpossibleDeliveryDate(itemToBuy.deliveryDate, deliveryCond);
      if(itemToBuy.deliveryDate) {
        var dayOfWeek = moment(itemToBuy.deliveryDate).isoWeekday(); 
        var checkSelectedDateisInHolidayVal = checkSelectedDateisInHoliday(itemToBuy.deliveryDate, deliveryCond);
        if(deliveryCond.noDeliveryOnHolidays && checkSelectedDateisInHolidayVal){            
          NotificationService.simpleErrorToast({
            title: 'error.validation',
            message: 'alertMessage.NO_DELIVERY_HOLIDAY',
            params: {time: nextpossibleDeliveryDate}
          })
          isDeliveryValid = false;
        }        
        if(deliveryCond.noDeliveryOnSaturday && dayOfWeek === 6) {                 
          NotificationService.simpleErrorToast({
            title: 'error.validation',
            message: 'alertMessage.SUPPLIER_DOES_NOT_PROVIDE_DELIVERY_ON_SATURDAY',
            params: {time: nextpossibleDeliveryDate}
          });
          isDeliveryValid = false;
        }
        if(deliveryCond.noDeliveryOnSunday && dayOfWeek === 7) { 
          NotificationService.simpleErrorToast({
            title: 'error.validation',
            message: 'alertMessage.SUPPLIER_DOES_NOT_PROVIDE_DELIVERY_ON_SUNDAY',
            params: {time: nextpossibleDeliveryDate}
          });
          isDeliveryValid = false;
        }
        if(deliveryCond.noDeliveryOnMonday && dayOfWeek === 1) {          
          NotificationService.simpleErrorToast({
            title: 'error.validation',
            message: 'alertMessage.SUPPLIER_DOES_NOT_PROVIDE_DELIVERY_ON_MONDAY',
            params: {time: nextpossibleDeliveryDate}
          });
          isDeliveryValid = false;
        }
      }
      else {
        isDeliveryValid = false;
        NotificationService.simpleErrorToast({
          title: 'error.validation',
          message: 'global.invalidDeliveryDate'
        });
      }
      return isDeliveryValid;
    }

    function checkLeadTime(itemToBuy){
      var isDeliveryValid = true;
      var currentDay = moment(new Date()).startOf('day');
      var tomorrow = moment(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
      var chosenDay = moment(itemToBuy.deliveryDate).startOf('day');
      var selectedSupplier = itemToBuy.items? itemToBuy.items.supplier : itemToBuy.supplier;
      selectedSupplier = setSupplierDeliveryConditions(selectedSupplier);
      var deliveryCond = itemToBuy.buyerDeliveryConditions ? itemToBuy.buyerDeliveryConditions.deliveryConditions : selectedSupplier.deliveryCondition; // get specific delivery condition, otherwise get supplier default conditions
      var leadTime = deliveryCond.leadTime;
      var dayDiff = chosenDay.diff(currentDay, 'days');
      var nextpossibleDeliveryDate    = checkNextpossibleDeliveryDate(itemToBuy.deliveryDate, deliveryCond);
      /**
       * Note:
       * noSameDayDelivery flag is now applyLeadTime flag
       * it no longer specifies same day delivery
       * but instead it says whether lead time is applicable or not
      */
      var isLeadTimeApplicable = deliveryCond.noSameDayDelivery;
      if (isLeadTimeApplicable && dayDiff < leadTime) {
        isDeliveryValid = false;
        NotificationService.simpleErrorToast({
          title: 'error.validation',
          message: 'alertMessage.SUPPLIER_LEAD_TIME_LESS',
          params: {time: nextpossibleDeliveryDate}
        });
      } 
      // else if (!isLeadTimeApplicable && leadTime > 0 && dayDiff === 0) {
      //   isDeliveryValid = false;
      //   NotificationService.simpleErrorToast({
      //     title: 'error.validation',
      //     message: 'alertMessage.SUPPLIER_DOES_NOT_PROVIDE_DELIVERY_TODAY',
      //     params: {time: nextpossibleDeliveryDate}
      //   });
      // }
      return isDeliveryValid;
    }

    function setSupplierDeliveryConditions(supplier) {
      if (!supplier.deliveryCondition) {
        supplier.deliveryCondition = {
          orderTimeForToday: supplier.orderTimeForToday,
          orderTimeForFriday: supplier.orderTimeForFriday,
          orderTimeForSaturday: supplier.orderTimeForSaturday,
          noDeliveryOnHolidays: supplier.noDeliveryOnHolidays,
          noDeliveryOnSaturday: supplier.noDeliveryOnSaturday,
          noDeliveryOnSunday: supplier.noDeliveryOnSunday,
          noDeliveryOnMonday: supplier.noDeliveryOnMonday,
          minimumOrderForFreeDelivery: supplier.minimumOrderForFreeDelivery,
          deliveryCharge: supplier.deliveryCharge,
          belowMinimumPurchaseCondition: supplier.belowMinimumPurchaseCondition,
          noSameDayDelivery: supplier.noSameDayDelivery,
          leadTime: supplier.leadTime
        }
      }
      return supplier;
    }

    /**
    * [checkChosenDate checks if currentHour is within supplierHour of chosenDay]
    * @param  {[type]} currentHour  [currentHour of currentDay]
    * @param  {[type]} supplierHour [supplier conditioned time]
    * @return {[boolean]}              [description]
    */
    function checkChosenDate(currentHour, supplierHour, nextpossibleDeliveryDate){
      if (supplierHour == null) {
        return true;
      } else if(currentHour < supplierHour)
        return true;
      else{
        NotificationService.simpleErrorToast({
          title: 'error.validation',
          message: 'global.supplierDontTakeOrdersTime',
          params: {time: supplierHour, nextpossibleDeliveryDate: nextpossibleDeliveryDate}
        });
        return false;
      }
    }

    /**
    * [getCartItemTotal get Cart Item Total]
    * @param  {[type]} cartItem              [item in cart]
    * @param  {[type]} omitWeightedItemPrice [Omit Price of weighted required item from calulations]
    */
    function getCartItemTotal(cartItem, omitWeightedItemPrice) {
      var totalAmount = 0;
      var suppliervisibilityType = cartItem.items.supplier.visibilityType;
      if (omitWeightedItemPrice && cartItem.items.uom.weightRequired) {
        return _.round(totalAmount,2);
      }
      else {
        if(cartItem.items.uom.moqPrice === true)
        {
          totalAmount = ((cartItem.quantity/cartItem.items.moq) * cartItem.price);
        }
        else
        {
          var total = cartItem.quantity * cartItem.price;
          totalAmount = $filter('totalRoundOfDecimal')(total);
        }
        return _.round(totalAmount,2);
      }
    }

    /**
    * [getCartTotal getCartTotal]
    * @param  {[type]} cartItems             [list of items in cart]
    * @param  {[type]} omitWeightedItemPrice [Omit Price of weighted required item from calulations]
    */
    function getCartTotal(cartItems, omitWeightedItemPrice) {
      var cartTotal = 0;
      var selectedCartItems = _.filter(cartItems, 'selected');
      _.forEach(selectedCartItems, function(cartItem) {
        cartTotal = (Number(cartTotal) + Number(getCartItemTotal(cartItem, omitWeightedItemPrice)));
      });

      return _.round(cartTotal,2);
    }


    function checkNextpossibleDeliveryDate(deliveryDate, deliveryCondition){    
      var nextpossibleDeliveryDate = moment(deliveryDate).add('days', 1).format("Do MMMM YYYY"); 
     var nextpossibleDeliveryDate = moment(deliveryDate).add('days', 1).format("Do MMMM YYYY");     
      var nextpossibleDeliveryDate = moment(deliveryDate).add('days', 1).format("Do MMMM YYYY"); 
      var dayOfWeek = moment(deliveryDate).isoWeekday();
      var isHolidayArray  = [];
      if(deliveryCondition.noDeliveryOnHolidays){       
        var holidayListData = deliveryCondition.holidaylist;
        var nextDateAfterHoliday = nextpossibleDeliveryDate;  
        var checkDate = deliveryDate;         
        _.map(holidayListData, function (holidays, index) {  
          var checknextDateAfterHoliday  =  moment(checkDate).format();      
          var sDate     = moment(holidayListData[index].date).format();
          var eDate     = moment(holidayListData[index].toDate).format();          
          var isHoliday = moment(checknextDateAfterHoliday).isBetween(sDate, eDate, undefined, '[]'); 
          if(isHoliday && holidayListData[index].toDate!=null){            
            isHolidayArray.push(holidayListData[index]);
            nextDateAfterHoliday = moment(holidayListData[index].toDate).add('days', 1).format("Do MMMM YYYY");  
            checkDate = moment(holidayListData[index].toDate).add('days', 1);  
           }          
        })
        nextpossibleDeliveryDate = nextDateAfterHoliday;
        var formatDateCheck =  moment(checkDate).format();
        var checkweekDayHoliday = moment(formatDateCheck).isoWeekday();
        if( checkweekDayHoliday == 5 || checkweekDayHoliday == 6 || checkweekDayHoliday == 7){
          nextpossibleDeliveryDate = otherDaysCheckAvailable(formatDateCheck, deliveryCondition);
        }
      }
      else{
        nextpossibleDeliveryDate = otherDaysCheckAvailable(deliveryDate, deliveryCondition);
      }

    return nextpossibleDeliveryDate;

    }

    function otherDaysCheckAvailable(deliveryDate, deliveryCondition){
      var nextpossibleDeliveryDate = moment(deliveryDate).format(); 
      var dayOfWeek = moment(deliveryDate).isoWeekday();
      if(deliveryCondition.noDeliveryOnSaturday && deliveryCondition.noDeliveryOnSunday && deliveryCondition.noDeliveryOnMonday) {        
        if(dayOfWeek == 5){
          nextpossibleDeliveryDate = moment(deliveryDate).add('days', 4).format("Do MMMM YYYY");
        }
       else if(dayOfWeek == 6){
          nextpossibleDeliveryDate = moment(deliveryDate).add('days', 3).format("Do MMMM YYYY");
        }
        else if(dayOfWeek==7){
          nextpossibleDeliveryDate = moment(deliveryDate).add('days', 2).format("Do MMMM YYYY");
        }
        else {
          nextpossibleDeliveryDate = moment(deliveryDate).add('days', 1).format("Do MMMM YYYY");
        }
      }   
      else if(deliveryCondition.noDeliveryOnSaturday && deliveryCondition.noDeliveryOnSunday && !deliveryCondition.noDeliveryOnMonday) {        
        if(dayOfWeek == 5){
          nextpossibleDeliveryDate = moment(deliveryDate).add('days', 3).format("Do MMMM YYYY");
        }
        else if(dayOfWeek==6){
          nextpossibleDeliveryDate = moment(deliveryDate).add('days', 2).format("Do MMMM YYYY");
        }
        else if(dayOfWeek==7){
          nextpossibleDeliveryDate = moment(deliveryDate).add('days', 1).format("Do MMMM YYYY");
        }       
      }
      else if(deliveryCondition.noDeliveryOnSaturday && deliveryCondition.noDeliveryOnMonday && !deliveryCondition.noDeliveryOnSunday) {        
        if(dayOfWeek == 5){
          nextpossibleDeliveryDate = moment(deliveryDate).add('days', 2).format("Do MMMM YYYY");
        }        
        else if(dayOfWeek == 6 || dayOfWeek == 1){
          nextpossibleDeliveryDate = moment(deliveryDate).add('days', 1).format("Do MMMM YYYY");
        }             
      } 
      else if(!deliveryCondition.noDeliveryOnSaturday && deliveryCondition.noDeliveryOnMonday && deliveryCondition.noDeliveryOnSunday) {        
        if(dayOfWeek == 5 || dayOfWeek == 6){
          nextpossibleDeliveryDate = moment(deliveryDate).add('days', 2).format("Do MMMM YYYY");
        }        
        else if(dayOfWeek == 1){
          nextpossibleDeliveryDate = moment(deliveryDate).add('days', 2).format("Do MMMM YYYY");
        }       
      }     
      else {        
        nextpossibleDeliveryDate = moment(deliveryDate).format("Do MMMM YYYY");
      }  
      return nextpossibleDeliveryDate;
    }

    function checkSelectedDateisInHoliday(deliveryDate, deliveryCondition){
      var isHoliday = false;
      if(deliveryCondition.noDeliveryOnHolidays){       
        var holidayListData = deliveryCondition.holidaylist;   
        var checkDate = deliveryDate;  
        if(holidayListData && holidayListData.length > 0){
        _.map(holidayListData, function (holidays, index) { 
          if (isHoliday == false) {   
            var checknextDateAfterHoliday  =  moment(checkDate).format();      
            var sDate     = moment(holidayListData[index].date).format();
            var eDate     = moment(holidayListData[index].toDate).format();          
            isHoliday     = moment(checknextDateAfterHoliday).isBetween(sDate, eDate, undefined, '[]');    
          }        
        })  
      }
      return isHoliday;
      }
    }

    function getAllSupplierCalendarsHoliday(supplierId) {
      var deferred = $q.defer();      
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + "admin-supplier-calendars-holiday-list/",
        method: "GET",
        params: {supplierId: supplierId},
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
