/**
 * @Author: Ajay
 * @Date:   15-May-2018 13:04 +05:30
 * @Project: Ezyprocure
 * @Filename: ezypayment.admin.brand-supplier-payment-model-mapping.state.js
 * @Last modified by:   Allan
 * @Last modified time: 09-October-2018 12:04 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('ezypayment.admin.brand-supplier-payment-model-mapping', {
      parent: 'ezypayment.admin',
      url: '/brand-supplier-payment-model-mapping?page&sort&search',
      data: {
        authorities: ['ROLE_ADMIN'],
        pageTitle: 'ezyprocureApp.brandSupplierPaymentModelMapping.home.title'
      },
      views: {
        'content@': {
          templateUrl: 'app/ezypayment-modules/admin-modules/brand-supplier-payment-model-mapping/ezypayment.admin.brand-supplier-payment-model-mapping.html',
          controller: 'EzypaymentAdminBrandSupplierPaymentModelMapController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('brandSupplierPaymentModelMapping');
          $translatePartialLoader.addPart('paymentModel');
          $translatePartialLoader.addPart('kycStatus');
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    })
    .state('ezypayment.admin.brand-supplier-payment-model-mapping.add', {
      parent: 'ezypayment.admin.brand-supplier-payment-model-mapping',
      url: '/add',
      data: {
        authorities: ['ROLE_ADMIN'],
        pageTitle: 'Add new Mapping'
      },
      views: {
        'content@': {
          templateUrl: 'app/ezypayment-modules/admin-modules/brand-supplier-payment-model-mapping/add/ezypayment.admin.brand-supplier-payment-model-mapping.add.html',
          controller: 'EzypaymentAdminBrandSupplierPaymentModelMapAddController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },

      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('brandSupplierPaymentModelMapping');
          $translatePartialLoader.addPart('paymentModel');
          $translatePartialLoader.addPart('kycStatus');
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    });
  }

})();
