/**
* @Author: Fenn
* @Date:   29-Jan-2020
* @Project: Ezyprocure
* @Filename: buyer.recharge.plan.state.js
*/



(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .config(stateConfig);
  
    stateConfig.$inject = ['$stateProvider'];
  
    function stateConfig($stateProvider) {
        $stateProvider.state('buyer.subscription', {
            parent: 'buyer',
            url: '/subscription-plan',
            data: {
            },
            params: {
            },
            views: {
              'content@': {
                templateUrl: 'app/common-modules/plans/recharge/subscription.plan.html',
                controller: 'SubscriptionController',
                controllerAs: 'vm'
              }
            },
            resolve: {
              translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('register');
                $translatePartialLoader.addPart('global');
                return $translate.refresh();
              }]
            }
        });
    }
  })();
  