/**
 * @Author: Ajay
 * @Date:   06-Apr-2017 10:21 +05:30
 * @Project: Ezyprocure
 * @Filename: supplier.account.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 25-Jul-2018 10:19 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('supplier.account', {
      abstract: true,
      parent: 'supplier',
      url: '/account',
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          return $translate.refresh();
        }]
      }
    })
    .state('supplier.account.view', {
      parent: 'supplier.account',
      url: '/view',
      data: {
        authorities: ['ROLE_SUPPLIER']
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/account/supplier.account.view.html',
          controller: 'SupplierAccountController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('paymentTerms');
          $translatePartialLoader.addPart('paymentTypes');
          $translatePartialLoader.addPart('bank');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('user-management');
          return $translate.refresh();
        }],
        hasModuleAccess: ['Auth','$q', '$state', function(Auth, $q, $state) {
          if(Auth.hasPermission('accessForMaster')){
            return true;
          }else {
            return $q.reject('Unauthorized');
          }
        }]
      }
    })
    .state('supplier.account.edit', {
      parent: 'supplier.account',
      url: '/edit',
      data: {
        authorities: ['ROLE_SUPPLIER']
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/account/supplier.account.edit.html',
          controller: 'SupplierAccountController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('paymentTerms');
          $translatePartialLoader.addPart('paymentTypes');
          $translatePartialLoader.addPart('bank');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('user-management');
          return $translate.refresh();
        }],
        hasModuleAccess: ['Auth','$q', '$state', function(Auth, $q, $state) {
          if(Auth.hasPermission('accessForMaster')){
            return true;
          }else {
            return $q.reject('Unauthorized');
          }
        }]
      }
    })
    .state('supplier.account.change-password', {
      parent: 'supplier.account',
      url: '/change-password',
      data: {
        authorities: ['ROLE_SUPPLIER']
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/account/supplier.account.change-password.html',
          controller: 'SupplierAccountController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('user-management');
          return $translate.refresh();
        }],
        hasModuleAccess: ['Auth','$q', '$state', function(Auth, $q, $state) {
          if(Auth.hasPermission('accessForMaster')){
            return true;
          }else {
            return $q.reject('Unauthorized');
          }
        }]
      }
    });
  }
})();
