/**
 * @Author: Ajay
 * @Date:   15-May-2018 13:04 +05:30
 * @Project: Ezyprocure
 * @Filename: ezypayment.brand-supplier-payment-model-mapping.service.js
 * @Last modified by:   Allan
 * @Last modified time: 09-October-2018 14:55 +05:30
 */



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('EzypaymentBrandSupplierPaymentModelMapService', EzypaymentBrandSupplierPaymentModelMapService);

  EzypaymentBrandSupplierPaymentModelMapService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  function EzypaymentBrandSupplierPaymentModelMapService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var brandSupplierPaymentModelMapService = {
      createBrandSupplierPaymentModelMapping: createBrandSupplierPaymentModelMapping,
      deleteBrandSupplierPaymentModelMapping: deleteBrandSupplierPaymentModelMapping,
      getAllBrandSupplierPaymentModelMappings: getAllBrandSupplierPaymentModelMappings,
      getAllSupplierMappedPaymentModelBrands: getAllSupplierMappedPaymentModelBrands
    };

    return brandSupplierPaymentModelMapService;

    /* --   Brand Supplier Payment Model Mapping API's -- */

    //Create a new Brand Supplier Payment Model mapping
    function createBrandSupplierPaymentModelMapping(mapping) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BRAND_SUPPLIER_PAYMENT_MODEL_MAPPING_RESOURCE, mapping).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    // Soft Delete existing Brand Supplier Payment Model mapping
    function deleteBrandSupplierPaymentModelMapping(mapping) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BRAND_SUPPLIER_PAYMENT_MODEL_MAPPING_RESOURCE + mapping.id).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getAllBrandSupplierPaymentModelMappings(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BRAND_SUPPLIER_PAYMENT_MODEL_MAPPING_RESOURCE,
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getAllSupplierMappedPaymentModelBrands Get list of mapped brands to the selected supplier]
     * @param  {[type]} params [description]
     * @return {[type]}        [description]
     */
    function getAllSupplierMappedPaymentModelBrands(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BRAND_SUPPLIER_PAYMENT_MODEL_MAPPING_RESOURCE + 'brands',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
