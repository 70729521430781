/**
* @Author: Ajay
* @Date:   29-Aug-2018 11:56 +05:30
* @Project: Ezyprocure
* @Filename: buyer.upload-sales-order.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 13-Sep-2018 16:56 +05:30
*/





(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('UploadSalesOrderCsvUploadController', UploadSalesOrderCsvUploadController);

  UploadSalesOrderCsvUploadController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'toastr', 'CsvUploadService', 'NotificationService', 'UtilService', 'PurchaseOrderService', 'S3_URL_CONSTANTS'];

  function UploadSalesOrderCsvUploadController($scope, $rootScope, $state, $stateParams, toastr, CsvUploadService, NotificationService, UtilService, PurchaseOrderService, S3_URL_CONSTANTS) {
    var vm = this;

    vm.init = init;
    vm.getCSVBase64 = getCSVBase64;
    vm.purchaseRequestUploadCSV = purchaseRequestUploadCSV;
    vm.itemUploadCSV = itemUploadCSV;
    vm.resetValues = resetValues;

    vm.SALES_ORDER_CSV = S3_URL_CONSTANTS.SALES_ORDER_CSV;

    function init() {
      vm.csvUploadData = {};
    }

    function getCSVBase64() {
      vm.errorList = null;
      var csvFile = vm.files[0];
      if (!csvFile) {
        toastr.error('Please upload a .csv file!', 'CSV File Required');
        return false;
      }
      CsvUploadService.getFileBase64(csvFile.lfFile, function(base64CSV) {
        vm.csvUploadData = base64CSV;
        vm.purchaseRequestUploadCSV(vm.csvUploadData);
      });
    }

    function purchaseRequestUploadCSV(uploadData) {
      PurchaseOrderService.createSalesOrderCsv(uploadData)
      .then(function(response) {
        vm.files = [];
        NotificationService.success({
          title: 'Sales Order',
          message: 'alertMessage.UPLOADED_SUCCESSFULLY'
        });
        $state.go('buyer.cart-checkout', {
          reload: false
        });
      }, function(error) {
        vm.recordError = error.data;
        NotificationService.error({
          title: 'Cannot Place Order',
          error: error
        });
      });
    }

    function itemUploadCSV() {
      var csvFile = vm.files[0];
      vm.isCsvFile = CsvUploadService.checkFileType(csvFile.lfFile);
      if (vm.isCsvFile) {
        UtilService.csvToJson(csvFile.lfDataUrl)
        .then(function(response) {
          vm.poItems = response;
        });
      }
    }

    function resetValues(){
      vm.poItems = [];
      vm.recordError = undefined;
    }
  }

})();
