/**
 * @Author: Ajay
 * @Date:   15-Jan-2018 11:57 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.food-cost-data.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 12-Oct-2018 14:52 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerFoodCostDataController', BuyerFoodCostDataController);

  BuyerFoodCostDataController.$inject = ['$scope', '$rootScope', '$q', 'toastr', '$filter', '$state', '$stateParams', 'pagingParams', 'UTCDateService', 'BuyerBusinessUserMapService', 'GlSpecificItemGroupService', 'BusinessReportService', 'paginationConstants', 'NotificationService', 'UtilService', 'CSVExportService'];

  function BuyerFoodCostDataController($scope, $rootScope,$q ,toastr, $filter, $state, $stateParams, pagingParams, UTCDateService, BuyerBusinessUserMapService, GlSpecificItemGroupService, BusinessReportService, paginationConstants, NotificationService, UtilService, CSVExportService) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.limitOptions = paginationConstants.limitOptions;
    vm.loadAll = loadAll;
    vm.filter = {};
    vm.filter.fromDate = new Date();
    vm.filter.toDate = new Date();
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.loadAllGlSpecificItemGroups = loadAllGlSpecificItemGroups;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.getItemGroups = getItemGroups;
    vm.getSupplierColumns = getSupplierColumns;
    vm.validDateFilter = validDateFilter;
    vm.getFoodCostCSVHeader = getFoodCostCSVHeader;
    vm.foodCostCSVData = foodCostCSVData;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.resetFilters = resetFilters;

    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
    * [getAllBuyerBusinessOutlets Gets all business outlets of buyer]
    */
    function getAllBuyerBusinessOutlets() {
      if(vm.businessOutlets)
        return false;
      BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser().
      then(function(response){
        vm.businessOutlets = response;
      },function(error){
      });
    }

    function loadAllGlSpecificItemGroups() {
      if(vm.glGroups)
        return false;
      GlSpecificItemGroupService.getAllGlSpecificItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.glGroups = response.data;
      }, function(error) {
      });
    }

    /**
     * [selectAll Selects all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets or glGroups]
     */
    function selectAll(dropdown) {
      switch (dropdown) {
      case 'OUTLETS':
        vm.filter.buyerBusinessIdList = _.map(vm.businessOutlets, _.property('id'));
        break;
      case 'GLGROUPS':
        vm.filter.glSpecificItemGroupIdList = _.map(vm.glGroups, _.property('id'));
        break;
      }
    }

    /**
     * [deselectAll Deselect all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets or glGroups]
     */
    function deselectAll(dropdown) {
      switch (dropdown) {
      case 'OUTLETS':
        vm.filter.buyerBusinessIdList = [];
        break;
      case 'GLGROUPS':
        vm.filter.glSpecificItemGroupIdList = [];
        break;
      }
    }

    function loadAll() {
      if(!vm.validDateFilter()){
        return false;
      }
      vm.days = UtilService.generateDays(vm.filter.fromDate, vm.filter.toDate);
      BusinessReportService.getFoodCostData({
        buyerBusinessIdList: !vm.filter.buyerBusinessIdList ? [] : vm.filter.buyerBusinessIdList,
        glSpecificItemGroupIdList: !vm.filter.glSpecificItemGroupIdList ? [] : vm.filter.glSpecificItemGroupIdList,
        fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
        toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
        query: !vm.filter.itemName ? '' : vm.filter.itemName
      }, {
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
      }).then(function(response){
        vm.foodCostData = response.data;
        vm.outlets = Object.keys(vm.foodCostData);
        vm.selectedDateRange = {
          fromDate: vm.filter.fromDate,
          toDate: vm.filter.toDate
        };
      },function(error){
        NotificationService.error({
          title: 'Fetching Good Received Data',
          error: error
        });
      });
    }

    /**
     * [getItemGroups description]
     * @param  {[type]} outlet [description]
     * @return {[type]}           [description]
     */
    function getItemGroups(outlet) {
      var itemGroups = Object.keys(vm.foodCostData[outlet]);
      var sortedItemGroups = _.orderBy(itemGroups, [], ['asc']);
      return sortedItemGroups;
    }

    /**
     * [getSupplierColumns description]
     * @param  {[type]} itemGroup [description]
     * @return {[type]}           [description]
     */
    function getSupplierColumns(outlet, itemGroup) {
      var columnKeys = Object.keys(vm.foodCostData[outlet][itemGroup]);
      var sortedColumnKeys = _.orderBy(columnKeys, [], ['asc']);
      return UtilService.swapArrayValueToEnd(sortedColumnKeys, "Total");
    }

    /**
     * [validDateFilter description]
     * @return {[type]} [description]
     */
    function validDateFilter() {
      var validSelection = true;
      var sameMonth = moment(vm.filter.fromDate).isSame(vm.filter.toDate, 'month');
      var sameYear = moment(vm.filter.fromDate).isSame(vm.filter.toDate, 'year');
      if(moment(vm.filter.toDate).isBefore(vm.filter.fromDate)){
        validSelection = false;
        toastr.error('Please select valid date range!', 'Food Cost Data');
      }
      if(sameMonth  === false || sameYear === false){
        validSelection = false;
        toastr.error('Please choose dates from same month!', 'Food Cost Data');
      }
      return validSelection;
    }

    function getFoodCostCSVHeader(outlet, itemGroup) {
      var csvHeaders = vm.getSupplierColumns(outlet, itemGroup);
      csvHeaders.unshift("Date");
      csvHeaders.push("Currency");
      return csvHeaders;
    }

    function foodCostCSVData(outlet, itemGroup) {
      var deferred = $q.defer();
      if (!vm.foodCostData) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      vm.foodCostCSVHeader = vm.getFoodCostCSVHeader(outlet, itemGroup);
      var metaData = {
        outlet: outlet,
        itemGroup: itemGroup,
        rows: UtilService.generateDays(vm.filter.fromDate, vm.filter.toDate),
        columns: vm.foodCostCSVHeader
      };
      return CSVExportService.getFoodCostCSVData(vm.foodCostData, metaData);
    }

    function resetFilters() {
        vm.filter = {}
        vm.filter.fromDate = new Date();
        vm.filter.toDate = new Date();
        vm.foodCostData = null;
        vm.outlets = null;
    }

  }
})();
