/*
 * @Author: Ajay 
 * @Date: 2019-04-16 10:00:53 
 * @Project: Ezyprocure
 * @Filename: ezypayment.admin.visa-payment.state.js
 * @Last Modified by: Ajay
 * @Last Modified time: 2019-04-16 14:59:13
 */

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
      .state('ezypayment.admin.visa-payment', {
        parent: 'ezypayment.admin',
        url: '/visa-payment',
        data: {
          authorities: ['ROLE_ADMIN']
        },
        views: {
          'content@': {
            templateUrl: 'app/ezypayment-modules/admin-modules/visa-payment/ezypayment.admin.visa-payment.html',
            controller: 'EzypaymentAdminVISAPaymentController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,asc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
          }]
        }
      })
  }

})();
