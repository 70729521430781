/**
* @Author: Kishan
* @Date:   08-Apr-20222 12:20 +05:30
* @Project: Ezyprocure
* @Filename: buyer.approval-pr-po-relation-report.controller.js
* @Last modified by:   Kishan
* @Last modified time: 08-Apr-20222 12:20 +05:30
*/

(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerPRPOReportController', BuyerPRPOReportController);

  BuyerPRPOReportController.$inject = ['BuyerBusinessUserMapService', 'BuyerBuyNowService', '$filter', '$mdDialog', 'UTCDateService', 'BuyerPriceUpdateHistoryService', 'toastr'];

  function BuyerPRPOReportController(BuyerBusinessUserMapService, BuyerBuyNowService, $filter, $mdDialog, UTCDateService, BuyerPriceUpdateHistoryService, toastr) {
    var vm = this;
    vm.email = "";
    vm.filter = {};
    vm.todaysDate = new Date();
    vm.prevSelectedOutletId = 0;
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.getAllAllocatedSuppliersForBusiness = getAllAllocatedSuppliersForBusiness;
    vm.showEmailModal = showEmailModal;
    vm.submit = submit;
    vm.cancel = cancel;

    /**
    * [getAllBuyerBusinessOutlets Gets all business outlets of buyer]
    */
    function getAllBuyerBusinessOutlets() {
      if(vm.businessOutlets)
        return false;

      vm.businessOutlets = null;
      BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser().
      then(function(response){
        vm.businessOutlets = response;
        //if only one business outlet set it as default
        if(vm.businessOutlets.length === 1) {
          vm.businessId = vm.businessOutlets[0].id;
        }
      },function(error){
      });
    }

    /**
    * [getAllAllocatedSuppliersForBusiness Get all allocated suppliers of buyer business]
    */
    function getAllAllocatedSuppliersForBusiness() {
      if(vm.supplierList && vm.prevSelectedOutletId === vm.filter.businessOutletId)
        return false;
      vm.prevSelectedOutletId = vm.filter.businessOutletId;
      var params = {'businessId': !vm.filter.businessOutletId ? '' : vm.filter.businessOutletId};
      BuyerBuyNowService.getAllAllocatedSuppliersForBusiness(params).then(function(response){
        vm.supplierList = $filter('orderBy')(response.data, 'companyName');
        //if only one allocated supplier set it as default
        if(vm.supplierList.length === 1) {
          vm.filter.supplierId = vm.supplierList[0].id;
        }
      },function(error){
      });
    }

    function showEmailModal() {
      // reset previous data if any
      vm.details = {email: ''};
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/reports/approval-pr-po-relation-report/buyer.approval-email-modal.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        // targetEvent: event,
        clickOutsideToClose: false
      });
    }

    function submit(details) {
      if (details.email) {
        $mdDialog.cancel();
        vm.email = details.email;
        const payload = {
          lastApprovalDate: !vm.filter.fromDate ? '' : UTCDateService.utcToDate(vm.filter.fromDate),
          buyerBusinessId: !vm.filter.businessOutletId ? '' : vm.filter.businessOutletId,
          supplierId: !vm.filter.supplierId ? '': vm.filter.supplierId,
          mailReceiver: vm.email,
        }
        BuyerPriceUpdateHistoryService.approvalPRPORelationReport(payload).then(function(response) {
          if (response.data && response.data[0].status && response.data[0].status == 'Success') {
            toastr.success(response.data[0].message || 'Report is being generated, please check your email after few minutes');
          } else {
            toastr.error(response.data[0].message || 'Item Apprroval Data Not Found');
          }
        }, function(error) {
          toastr.error(error.data.message ? error.data.message : 'Unable to generate report');
        });
      } else {
        toastr.error('', 'Please enter email!');
      }
    }

    function cancel() {
      $mdDialog.cancel();
    }
  }
})();
