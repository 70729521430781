/**
* @Author: Allan
* @Date:   27-August-2018 12:49 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.buyer.navbar.controller.js
* @Last modified by:   allan
* @Last modified time: 29-August-2018 13:10 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzyPaymentBuyerNavbarController', EzyPaymentBuyerNavbarController);

  EzyPaymentBuyerNavbarController.$inject = ['$rootScope', '$window', 'Auth', 'BuyerAccountService', 'BuyerDataService','$state'];

  function EzyPaymentBuyerNavbarController ($rootScope, $window, Auth, BuyerAccountService, BuyerDataService,$state) {
    var vm = this;

    vm.init = init;
    vm.getBuyerProfile = getBuyerProfile;
    vm.logout = logout;
    $rootScope.back = back;
    vm.redirectToAXSPayBillsFine = redirectToAXSPayBillsFine;
    function init() {
      vm.getBuyerProfile();
    }

    function getBuyerProfile () {
      BuyerAccountService.getBuyerProfile().
      then(function(response) {
        if (response) {
          vm.buyerProfile = response;
          vm.buyerAccess = vm.buyerProfile.accessDTO;
          BuyerDataService.setBuyerProfile(vm.buyerProfile);
        }
      }, function(error) {
      });
    }

    function logout() {
      Auth.logout();
    }

    function back() {
      $window.history.back();
    }

    function redirectToAXSPayBillsFine(){
        $state.go('ezypayment.buyer.axs-pay-bills', {
            reload: false
          });
    }

  }
})();
