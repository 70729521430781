/**
* @Author: Ajay
* @Date:   21-Jan-2017 13:34 +05:30
* @Project: Ezyprocure
* @Filename: buyer.user-management.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 30-Aug-2018 17:42 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BuyerUserManagementService', BuyerUserManagementService);

  BuyerUserManagementService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  function BuyerUserManagementService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var buyerUserManagementService = {
      getAllBuyerUsers: getAllBuyerUsers,
      getAllBuyerUserApprovers: getAllBuyerUserApprovers,
      getAllBuyerUserPaymentApprovers: getAllBuyerUserPaymentApprovers,
      getBuyerUser: getBuyerUser,
      getBuyerUserByCompanyId: getBuyerUserByCompanyId,
      createBuyerUser: createBuyerUser,
      updateBuyerUser: updateBuyerUser,
      deleteBuyerUser: deleteBuyerUser,
      updateBuyerUserActivatedKey: updateBuyerUserActivatedKey
    };
    return buyerUserManagementService;

    /* --  Buyer User CRUID operations  -- */

    //Get All Buyer Users of logged in buyer
    function getAllBuyerUsers(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYER_USER_RESOURCE,
        method: "GET",
        params:params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get All Buyer Users of logged in buyer
    function getAllBuyerUserApprovers(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYER_USER_RESOURCE +'approver',
        method: "GET",
        params:params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getAllBuyerUserPaymentApprovers(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYER_USER_RESOURCE +'payment-approver',
        method: "GET",
        params:params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get buyer user details using buyeruser id
    function getBuyerUser(buyerUserId) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYER_USER_RESOURCE + buyerUserId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    
    function getBuyerUserByCompanyId(buyerCompanyId) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyers/' + buyerCompanyId).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Create a new buyer user
    function createBuyerUser(buyerUser) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYER_USER_RESOURCE, buyerUser).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update a buyer user
    function updateBuyerUser(buyerUser) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYER_USER_RESOURCE, buyerUser).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Soft Delete a buyer user details using buyeruser id
    function deleteBuyerUser(buyerUserId) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYER_USER_RESOURCE + buyerUserId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update a buyer user
    function updateBuyerUserActivatedKey(buyerUserId, params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYER_USER_RESOURCE + buyerUserId + '/account',
        method: "PUT",
        params: params,
      }).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
