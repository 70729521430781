/**
 * @Author: lovinc
 * @Date:   2018-08-28T20:13:57+05:30
 * @Project: Ezyprocure
 * @Filename: buyer.item-business-mapping.add.controller.js
 * @Last modified by:   lovinc
 * @Last modified time: 2018-08-29T13:20:13+05:30
 */


(function() {
    'use strict';

    angular
    .module('ezyprocureApp')
    .controller('AddBuyerItemOutletMappingController', AddBuyerItemOutletMappingController);

    AddBuyerItemOutletMappingController.$inject = ['$scope', '$state', '$stateParams', '$element', 'toastr', 'pagingParams', 'BuyerSupplierMappingService', 'paginationConstants','BuyerDataService', 'NotificationService', 'BrandManagementService',  'BuyerItemOutletMapService','ItemBuyerMappingService', 'BuyerBusinessUserMapService','ManageSupplierService','$filter', 'BuyerBusinessService'];

    function AddBuyerItemOutletMappingController ($scope, $state, $stateParams, $element, toastr, pagingParams, BuyerSupplierMappingService, paginationConstants,BuyerDataService, NotificationService, BrandManagementService, BuyerItemOutletMapService, ItemBuyerMappingService, BuyerBusinessUserMapService, ManageSupplierService, $filter, BuyerBusinessService) {
      var vm = this;
      vm.predicate = pagingParams.predicate;
      vm.reverse = pagingParams.ascending;
      vm.sort = sort;
      vm.supplierSelectionChanged = supplierSelectionChanged;
      vm.getAllItemSupplier = getAllItemSupplier;
      vm.checkAll = checkAll;
      vm.getSelectedItemIdList = getSelectedItemIdList;
      vm.addNewMaping = addNewMaping;
      vm.getAllBuyerMappedSuppliers = getAllBuyerMappedSuppliers;
      vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
      vm.getAllSuppliersByBusinessOutlets = getAllSuppliersByBusinessOutlets;
      vm.newMappingObject = {
        supplierId: [],
        businessOutletList: [],
        itemIdList: [],
        brandIdList: []
      };
      vm.itemDetails = [];
      vm.supplierSort = ["supplier.companyName,asc"];

      vm.query = {
        limit: paginationConstants.itemsPerPage,
        page: 1
      };
      vm.limitOptions = paginationConstants.limitOptions;
      vm.getItemDetailsById = getItemDetailsById;
      vm.getAllBrandsOfBuyerList = getAllBrandsOfBuyerList;
      vm.buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      vm.selectAll = selectAll;
      vm.deselectAll = deselectAll;
      vm.getAllBrandMappedBusinessOutlets = getAllBrandMappedBusinessOutlets;

      function sort() {
        var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
        return result;
      }

    function getItemDetailsById(id, index){
      var itemDTO = {
        'businessIdList': vm.newMappingObject.businessOutletList,
        'id' : id,
        'buyerId': BuyerDataService.getBuyerProfile().buyerUser.buyer.id
    }
      BuyerItemOutletMapService.getItemDetailsById(itemDTO).then(function(response){
        vm.itemDetails[index] = response.data;
      },function(error){
        NotificationService.error({
          title: 'global.menu.entities.itemBusinessMapping',
          error: error
        });
      });
    }

     /**
     * [getAllBuyerMappedSuppliers Get list of mapped suppliers to the logged in buyer]
     */
    function getAllBuyerMappedSuppliers() {
        if(vm.suppliers)
          return false;
        var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
        BuyerSupplierMappingService.getAllBuyerMappedSuppliers(buyerId,{
          page: 0,
          size: paginationConstants.maxLimit,
          sort: vm.supplierSort
        }).then(function(response){
          vm.suppliers = response.data;
        },function(error){
        });
      }

      /**
       * [selectAll Selects all from the list]
       * @param  {[type]} dropdown [Active dropdown - outlets or brands]
       */
      function selectAll(dropdown) {
        switch (dropdown) {
          case 'BRANDS':
            vm.newMappingObject.brandIdList = _.map(vm.brands, _.property('id'));
            break;
          case 'OUTLETS':
            vm.newMappingObject.businessOutletList  = _.map(vm.businessOutlets, _.property('id'));
            break;
        }
      }

      /**
       * [deselectAll Deselect all from the list]
       * @param  {[type]} dropdown [Active dropdown - outlets or brands]
       */
      function deselectAll(dropdown) {
        switch (dropdown) {
          case 'BRANDS':
            vm.newMappingObject.brandIdList = [];
            vm.newMappingObject.businessOutletList = [];
            break;
          case 'OUTLETS':
            vm.newMappingObject.businessOutletList = [];
            break;
        }
      }

      /**
       * [getAllBrandsOfBuyerList Gets all brands of selected buyers]
       * @param {Array} buyerIdList
       */
      function getAllBrandsOfBuyerList(){
        if(vm.brands)
          return false;
        var params = {
          page: 0,
          size: paginationConstants.maxLimit,
          query:'',
          buyerId : vm.buyerId
        };
        BrandManagementService.getAllBrandsOfBuyerList(params)
        .then(function(response){
          vm.brands = response;
        },function(error){
          NotificationService.error({
            title: 'Brand Listing',
            error: error
          });
        });
      }

      // Load All items with buyer mapped price
      function getAllItemSupplier(supplierIdList, search) {
        var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
        if(!buyerId) return false;
        if(!search){
          search ='';
        }
        ItemBuyerMappingService.getAllItemBuyerMappings({
          page: 0,
          size: paginationConstants.maxLimit,
          sort: sort(),
          query: search,
          supplierIdList:supplierIdList,
          buyerId: buyerId,
        }).then(function(response){
          vm.items = response.data;
          vm.selectAll = false;
        },function(error){
        });
      }

      function checkAll() {
        vm.selectAll ? vm.selectAll = true : vm.selectAll = false;
        angular.forEach(vm.items, function(item) {
          item.selected = vm.selectAll;
        });
      }

      function getSelectedItemIdList(items) {
        var pickedItems = _.map(_.filter(items, {'selected': true}), function(item){
          return item.items.id;
        });
        return pickedItems;
      }

      //add new mapping
      function addNewMaping() {
        vm.newMappingObject.itemIdList = vm.getSelectedItemIdList(vm.items);
        BuyerItemOutletMapService.createNewItemSupplierOutletMapping(vm.newMappingObject).then(function(response){
            if(response.data.responseList.length > 0) {
              vm.translatedError = response.data;
              vm.flag = true;
              NotificationService.simpleErrorToast({
                title: 'global.menu.entities.itemBusinessMapping',
                message:  'error.COMPLETED_WITH_ERRORS'
              });
            }
          else {
            vm.flag = false;
            NotificationService.success({
              title: 'global.menu.entities.itemBusinessMapping',
              message:  'alertMessage.CREATED_SUCCESSFULLY'
            });
          }
        },function(error){
          NotificationService.error({
            title: 'global.menu.entities.itemBusinessMapping',
            error: error
          });
        });
      }

      function supplierSelectionChanged(supplierId) {
        vm.newMappingObject.supplierId = supplierId;
        vm.getAllItemSupplier(supplierId);
        vm.translatedError = [];
      }

      //Get all business outlets of buyer
      function getAllBuyerBusinessOutlets() {
        BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser().
        then(function(response){
          vm.businessOutlets = response;
          //if only one business outlet set it as default
          if(vm.businessOutlets.length === 1) {
            vm.newMappingObject.businessOutletList = vm.businessOutlets[0].id;
          }
        },function(error){
        });
      }

      /**
       * [get all suppliers by business outlets]
       * @param {List} businessIdList [list of business-outlet id]
       */
      function getAllSuppliersByBusinessOutlets(businessIdList) {
        vm.supplierList = [];
        ManageSupplierService.getAllSuppliersByBusinessOutlets(businessIdList).then(function (response) {
          vm.supplierList = $filter('orderBy')(response.data, 'companyName');
          //if only one allocated supplier set it as default
          if(vm.supplierList.length === 1) {
          vm.supplierId = vm.supplierList[0].id;
          vm.newMappingObject.supplierId = vm.supplierId;
          vm.getAllItemSupplier(vm.supplierId);
          }
        }, function (error) {
        });
      }

      /**
     * getAllBrandMappedsBusinessOutlets gets all BO of selected brand and buyer
     * @param {List} brandIdList 
     */
    function getAllBrandMappedBusinessOutlets(brandIdList) {
      if (!brandIdList || brandIdList.length === 0){
        vm.getAllBuyerBusinessOutlets();
        return false;
      }
      var itemTerminationDTO = {
        'brandIdList': brandIdList,
        'buyerIdList': [vm.buyerId]
      }
      var params = {
        page: 0,
        size: paginationConstants.maxLimit
      }
      BuyerBusinessService.getAllBrandMappedsBusinessOutlets(itemTerminationDTO, params).then(function(response) {
        vm.businessOutlets  = response.data;
        if(vm.businessOutlets.length === 1) {
          vm.newMappingObject.businessOutletList = vm.businessOutlets[0].id;
        }
      }, function(error) {
      });
    }

    }

  })();
