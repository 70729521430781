/**
* @Author: Ajay
* @Date:   21-Dec-2016 18:48 +05:30
* @Project: Ezyprocure
* @Filename: time.constant.js
 * @Last modified by:   Sneha
 * @Last modified time: 18-Oct-2017 10:52 +05:30
*/



"use strict";
angular.module('ezyprocureApp')
.constant('TIME_CONSTANTS', {
  timeConstants: [
      {"abbr24":"00:00", "value":"0000", "abbr12":"00:00 AM"},
      {"abbr24":"00:30", "value":"0030", "abbr12":"00:30 AM"},
      {"abbr24":"01:00", "value":"0100", "abbr12":"01:00 AM"},
      {"abbr24":"01:30", "value":"0130", "abbr12":"01:30 AM"},
      {"abbr24":"02:00", "value":"0200", "abbr12":"02:00 AM"},
      {"abbr24":"02:30", "value":"0230", "abbr12":"02:30 AM"},
      {"abbr24":"03:00", "value":"0300", "abbr12":"03:00 AM"},
      {"abbr24":"03:30", "value":"0330", "abbr12":"03:30 AM"},
      {"abbr24":"04:00", "value":"0400", "abbr12":"04:00 AM"},
      {"abbr24":"04:30", "value":"0430", "abbr12":"04:30 AM"},
      {"abbr24":"05:00", "value":"0500", "abbr12":"05:00 AM"},
      {"abbr24":"05:30", "value":"0530", "abbr12":"05:30 AM"},
      {"abbr24":"06:00", "value":"0600", "abbr12":"06:00 AM"},
      {"abbr24":"06:30", "value":"0630", "abbr12":"06:30 AM"},
      {"abbr24":"07:00", "value":"0700", "abbr12":"07:00 AM"},
      {"abbr24":"07:30", "value":"0730", "abbr12":"07:30 AM"},
      {"abbr24":"08:00", "value":"0800", "abbr12":"08:00 AM"},
      {"abbr24":"08:30", "value":"0830", "abbr12":"08:30 AM"},
      {"abbr24":"09:00", "value":"0900", "abbr12":"09:00 AM"},
      {"abbr24":"09:30", "value":"0930", "abbr12":"09:30 AM"},
      {"abbr24":"10:00", "value":"1000", "abbr12":"10:00 AM"},
      {"abbr24":"10:30", "value":"1030", "abbr12":"10:30 AM"},
      {"abbr24":"11:00", "value":"1100", "abbr12":"11:00 AM"},
      {"abbr24":"11:30", "value":"1130", "abbr12":"11:30 AM"},
      {"abbr24":"12:00", "value":"1200", "abbr12":"12:00 PM"},
      {"abbr24":"12:30", "value":"1230", "abbr12":"12:30 PM"},
      {"abbr24":"13:00", "value":"1300", "abbr12":"01:00 PM"},
      {"abbr24":"13:30", "value":"1330", "abbr12":"01:30 PM"},
      {"abbr24":"14:00", "value":"1400", "abbr12":"02:00 PM"},
      {"abbr24":"14:30", "value":"1430", "abbr12":"02:30 PM"},
      {"abbr24":"15:00", "value":"1500", "abbr12":"03:00 PM"},
      {"abbr24":"15:30", "value":"1530", "abbr12":"03:30 PM"},
      {"abbr24":"16:00", "value":"1600", "abbr12":"04:00 PM"},
      {"abbr24":"16:30", "value":"1630", "abbr12":"04:30 PM"},
      {"abbr24":"17:00", "value":"1700", "abbr12":"05:00 PM"},
      {"abbr24":"17:30", "value":"1730", "abbr12":"05:30 PM"},
      {"abbr24":"18:00", "value":"1800", "abbr12":"06:00 PM"},
      {"abbr24":"18:30", "value":"1830", "abbr12":"06:30 PM"},
      {"abbr24":"19:00", "value":"1900", "abbr12":"07:00 PM"},
      {"abbr24":"19:30", "value":"1930", "abbr12":"07:30 PM"},
      {"abbr24":"20:00", "value":"2000", "abbr12":"08:00 PM"},
      {"abbr24":"20:30", "value":"2030", "abbr12":"08:30 PM"},
      {"abbr24":"21:00", "value":"2100", "abbr12":"09:00 PM"},
      {"abbr24":"21:30", "value":"2130", "abbr12":"09:30 PM"},
      {"abbr24":"22:00", "value":"2200", "abbr12":"10:00 PM"},
      {"abbr24":"22:30", "value":"2230", "abbr12":"10:30 PM"},
      {"abbr24":"23:00", "value":"2300", "abbr12":"11:00 PM"},
      {"abbr24":"23:30", "value":"2330", "abbr12":"11:30 PM"}
  ]
});
