/**
* @Author: Abhay
* @Date:   03-March-2021 16:03 +05:30
* @Project: Ezyprocure
* @Filename: admin.customer-engage-report.state.js
* @Last modified by:   Abhay
* @Last modified time: 03-March-2021 16:03 +05:30
*/



(function () {
    'use strict';

    angular
        .module('ezyprocureApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('admin.customer-engage-report', {
            parent: 'admin',
            url: '/customer-engage-report',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'Customer Engage Report'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin-modules/customer-engage-reports/admin.customer-engage-report.html',
                    controller: 'CustomerEngageReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
