/**
* @Author: Ajay
* @Date:   07-Mar-2017 15:17 +05:30
* @Project: Ezyprocure
* @Filename: supplier.email.service.js
* @Last modified by:   Mufsir
* @Last modified time: 18-Apr-2017 19:40 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('SupplierEmailService', SupplierEmailService);

  SupplierEmailService.$inject = ['$http', '$q', 'API_CONSTANTS'];

  function SupplierEmailService($http, $q, API_CONSTANTS) {

    var supplierEmailService = {
      sendEmailToBuyer: sendEmailToBuyer
    };

    return supplierEmailService;

    /**
     * Supplier Email APIs
     */

    /**
     * [sendEmailToBuyer Send Email to buyer]
     * @param  {[type]} emailDTO [Email object with recipients[],subject,body]
     * @return {[JSON]}          [message to be displayed]
     */
    function sendEmailToBuyer(emailDTO) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers/email-buyer',
        data: emailDTO,
        method: "PUT",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
