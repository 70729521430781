/**
* @Author: Ajay
* @Date:   20-Mar-2017 14:43 +05:30
* @Project: Ezyprocure
* @Filename: supplier.promotions.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 10-Nov-2017 11:31 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierPromotionsController', SupplierPromotionsController);

  SupplierPromotionsController.$inject = ['$scope', '$rootScope', '$state', 'NotificationService', '$mdDialog', 'PromotionService', 'BuyerSupplierMappingService', 'BrandManagementService', 'SupplierDataService', 'pagingParams', 'paginationConstants', 'GLOBAL_CONSTANTS'];

  function SupplierPromotionsController($scope, $rootScope, $state, NotificationService, $mdDialog, PromotionService, BuyerSupplierMappingService, BrandManagementService, SupplierDataService, pagingParams, paginationConstants, GLOBAL_CONSTANTS) {
    var vm = this;

    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;

    vm.loadAll = loadAll;
    vm.showConfirm = showConfirm;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
    vm.getAllBrandsOfBuyerList = getAllBrandsOfBuyerList;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.itemDTOObj ={
       brandIdList: [],
       buyerIdList: []
    };
    vm.buyerBrandFilter = buyerBrandFilter;
    vm.brandListClear = brandListClear;

    /**
    * [sort description]
    * @return {[type]} [description]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
    * [loadAll getAllPromotionItemsForSupplier]
    * @param  {[type]} search [description]
    * @return {[list]}        [SupplierItemPromotions]
    */
    function loadAll(search) {
      if (!search) {
        vm.searchText = '';
      }
      PromotionService.getAllPromotionItemsForSupplier({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        searchTerm: vm.searchText,
        startDate: '',
        endDate: ''
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.supplierPromotions = response.data;
      }, function(error) {
      });
    }

    /**
     * [getAllBrandsOfBuyerList Gets all brands of selected buyers]
     * @param {Array} buyerIdList
     */
    function getAllBrandsOfBuyerList(buyerIdList){
      if(vm.brands)
        return false;
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        query:'',
        buyerId : buyerIdList
      };
      BrandManagementService.getAllBrandsOfBuyerList(params)
      .then(function(response){
        vm.brands = response;
      },function(error){
        NotificationService.error({
          title: 'Brand Listing',
          error: error
        });
      });
    }

    function buyerBrandFilter(){
      PromotionService.getAllPromotionItemsForSupplier({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        searchTerm: vm.searchText,
        startDate: '',
        endDate: '',
        buyerIdList: vm.itemDTOObj.buyerIdList,
        brandIdList: vm.itemDTOObj.brandIdList
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.supplierPromotions = response.data;
      }, function(error) {
      });
  }

      /**
     * [selectAll Selects all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, buyers or brands]
     */
    function selectAll(dropdown) {
      switch (dropdown) {
        case 'BUYERS' :
          vm.itemDTOObj.buyerIdList = _.map(vm.buyers, _.property('id'));
            break;
        case 'BRANDS':
          vm.itemDTOObj.brandIdList = _.map(vm.brands, _.property('id'));
          break;
      }
    }

    //clear brand field
    function brandListClear(){
      vm.itemDTOObj.brandIdList = []
    }

    /**
     * [deselectAll Deselect all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, buyers or brands]
     */
    function deselectAll(dropdown) {
      switch (dropdown) {
        case 'BUYERS' :
          vm.itemDTOObj.buyerIdList = [];
          break;
        case 'BRANDS':
          vm.itemDTOObj.brandIdList = [];
          break;
      }
    }

    //Get all buyers mapped to the supplier
    function getAllSupplierMappedBuyers() {
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
      };
      if(vm.buyers)
        return false;
      var supplierId = SupplierDataService.getSupplierProfile().supplier.id;
      BuyerSupplierMappingService.getAllSupplierMappedBuyers(supplierId, params).then(function(response){
        vm.buyers = response.data;
      },function(error){
      });
    }
    vm.loadAll();

    /**
    * [deleteSupplierItemPromotion deleteSupplierItemPromotion]
    * @param  {[type]} promotionId [promotionId]
    * @return {[Object]}             [success/error response message]
    */
    function deleteSupplierItemPromotion(promotionId) {
      PromotionService.deleteSupplierItemPromotion(promotionId).then(function(response) {
        NotificationService.success({
          title: 'Promotion',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'Promotion Deletion',
          error: error
        });
      });
    }

    /**
    * [showConfirm showConfirmPrompt]
    * @param  {[type]} ev                [description]
    * @param  {[type]} supplierPromotion [description]
    * @return {[type]}                   [description]
    */
    function showConfirm(ev, supplierPromotion) {
      var confirm = $mdDialog.confirm()
      .title('Are you sure you want to delete Supplier Promotion?')
      .textContent('This action could not be reverted back.')
      .targetEvent(ev)
      .ok('Delete')
      .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        deleteSupplierItemPromotion(supplierPromotion.promotionId);
      }, function() {});
    }

  }
})();
