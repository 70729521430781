/**
* @Author: Allan
* @Date:   22-September-2018 16:15 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.initiate-payment.service.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-10-11T12:23:52+05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('EzypaymentInitiatePaymentService', EzypaymentInitiatePaymentService);

  EzypaymentInitiatePaymentService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function EzypaymentInitiatePaymentService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS, CardsService) {

    var EzypaymentInitiatePaymentService = {
      getInitiatePayment: getInitiatePayment,
      setSelectedInvoiceIds: setSelectedInvoiceIds,
      getSelectedInvoiceIds: getSelectedInvoiceIds,
      getDuePaymentSummary: getDuePaymentSummary,
      getDuePaymentSummaryWithCredit: getDuePaymentSummaryWithCredit,
      getOdbcDuePaymentSummary: getOdbcDuePaymentSummary,
      getOdbcExternalDuePaymentSummary: getOdbcExternalDuePaymentSummary,
      confirmPayment: confirmPayment,
      odbcConfirmPayment: odbcConfirmPayment,
      setPaymentResponse: setPaymentResponse,
      getPaymentResponse: getPaymentResponse,
      getExternalDuePaymentSummary:getExternalDuePaymentSummary,
      sipRequest: sipRequest,
      sendForBuyerApproval: sendForBuyerApproval,
      confirmPaymentOcto: confirmPaymentOcto,
      confirmPaymentOctoValidate: confirmPaymentOctoValidate
    };

    var selectedInvoiceIds = null;
    var paymentResponse = null;
    return EzypaymentInitiatePaymentService;

    function sendForBuyerApproval(invoiceIdList) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'invoice-approval-requests/external',
        method: "POST",
        data: invoiceIdList,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getInitiatePayment(params){
      var deferred = $q.defer();

      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'mpgs/due-payment-summary/',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });

      return deferred.promise;
    }

    function getOdbcDuePaymentSummary(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'cybersource/due-payment-summary/',
        method: "GET",
        params: params,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getOdbcExternalDuePaymentSummary(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'cybersource/external-payments-invoices/due-payment-summary/',
        method: "GET",
        params: params,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function setSelectedInvoiceIds(params){
      selectedInvoiceIds = params;
    }

    function getSelectedInvoiceIds(){
      return  selectedInvoiceIds;
    }

    function getDuePaymentSummaryWithCredit(params){
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/mpgs/octopus/external/due-payment-summary',
          method: "GET",
          params: params,
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
    }

    function getDuePaymentSummary(params){
      var deferred = $q.defer();

      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'mpgs/due-payment-summary/',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });



      return deferred.promise;
    }

    function confirmPayment(params, duePaymentSummaryDetails, otp) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'mpgs/payment/',
        method: "POST",
        data: duePaymentSummaryDetails,
        params: params,
        headers: { 'authorization-otp': otp }
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function confirmPaymentOcto(params, duePaymentSummaryDetails, otp) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/mpgs/octopus/external/payment',
          method: "POST",
          params: params,
          data: duePaymentSummaryDetails,
          headers: { 'authorization-otp': otp }
        //   headers: { 'authorization-otp': otp }
        }).then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

    function confirmPaymentOctoValidate(params, duePaymentSummaryDetails) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/mpgs/octopus/external/invoiceValidation',
          method: "POST",
          params: params,
          data: duePaymentSummaryDetails,
        //   headers: { 'authorization-otp': otp }
        }).then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
        return deferred.promise;
    }

    function odbcConfirmPayment(params, duePaymentSummaryDetails, otp) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'cybersource/payment',
        method: "POST",
        data: duePaymentSummaryDetails,
        params: params,
        headers: { 'authorization-otp': otp }
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getExternalDuePaymentSummary(params){
          var deferred = $q.defer();

          $http({
            url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'mpgs/external-payments-invoices/due-payment-summary/',
            method: "GET",
            params: params,
          }).then(function(data) {
            deferred.resolve(data);
          }).catch(function(message) {
            deferred.reject(message);
          });

          return deferred.promise;
        }

    function setPaymentResponse(params){
      paymentResponse = params;
    }

    function getPaymentResponse(){
      return paymentResponse ? paymentResponse : null;
    }

    /**
     * [updateExportedInvoiceStatus update exported invoice statuses]
     * @param  {[type]} params        [report type]
     * @param  {[type]} invoiceIdList [invoice id list from exported data obtained from API]
     * @return {[type]}               [description]
     */
        function sipRequest(invoiceIdList){
          var deferred = $q.defer();
          $http({
            url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'invoice-approval-requests',
            method: "POST",
            data: invoiceIdList,
          }).then(function(data) {
            deferred.resolve(data);
          }).catch(function(message) {
            deferred.reject(message);
          });
          return deferred.promise;
        }

  }
})();
