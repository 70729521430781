/**
 * @Author: Ajay
 * @Date:   25-Jul-2017 14:50 +05:30
 * @Project: Ezyprocure
 * @Filename: supplier.price-update-request.controller.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2020-03-10 08:31:24
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierPriceUpdateRequestController', SupplierPriceUpdateRequestController);

  SupplierPriceUpdateRequestController.$inject = ['$scope', '$rootScope', '$filter', '$state', '$stateParams', 'toastr', '$mdDialog', 'pagingParams', 'PriceUpdateRequestService', 'paginationConstants', 'GLOBAL_CONSTANTS'];

  function SupplierPriceUpdateRequestController($scope, $rootScope, $filter, $state, $stateParams, toastr, $mdDialog, pagingParams, PriceUpdateRequestService, paginationConstants, GLOBAL_CONSTANTS) {
    var vm = this;
    vm.init = init;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.loadAll = loadAll;
    vm.searchText = '';
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    function init() {
      loadAll();
    }
    /**
     * [sort to sort by PO date]
     */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    //Get all Price update requests
    function loadAll(search) {
      if (!search) {
        vm.searchText = '';
      }
      PriceUpdateRequestService.getAllPriceUpdates({
        isApprovalRequired: true,
        query: vm.searchText,
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        itemName : '',
        supplierName : ''
      }).then(function(response){
        vm.priceUpdateItems = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      },function(error){
      });
    }

  }
})();
