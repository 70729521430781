/**
* @Author: Ajay
* @Date:   27-May-2017 11:05 +05:30
* @Project: Ezyprocure
* @Filename: buyer.good-receipt-note.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 12-Oct-2018 14:59 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerGoodReceiptNoteController', BuyerGoodReceiptNoteController);

  BuyerGoodReceiptNoteController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', '$stateParams', 'toastr', '$mdDialog','pagingParams', 'BuyerDataService', 'NotificationService', 'UTCDateService', 'BuyerBusinessService', 'PurchaseOrderService', 'BuyerSupplierMappingService', 'ItemGroupService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'BuyerBusinessUserMapService', 'BuyerBuyNowService', 'CSVExportService', 'BuyerReceivingOrderService', 'EXPORT_CONSTANTS'];

  function BuyerGoodReceiptNoteController($scope, $rootScope, $q, $filter, $state, $stateParams, toastr, $mdDialog,pagingParams, BuyerDataService, NotificationService, UTCDateService, BuyerBusinessService, PurchaseOrderService, BuyerSupplierMappingService, ItemGroupService, GLOBAL_CONSTANTS, paginationConstants, BuyerBusinessUserMapService, BuyerBuyNowService, CSVExportService, BuyerReceivingOrderService, EXPORT_CONSTANTS) {

    var vm = this;
    vm.predicate = JSON.parse(localStorage.getItem("good-receipt-note-predicate")) || pagingParams.predicate;
    vm.reverse = JSON.parse(localStorage.getItem("good-receipt-note-reverse")) || pagingParams.ascending;
    vm.todaysDate = new Date();
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.getAllAllocatedSuppliersForBusiness = getAllAllocatedSuppliersForBusiness;
    vm.prevSelectedOutletId= 0;
    vm.loadItemGroups = loadItemGroups;
    vm.listViews = GLOBAL_CONSTANTS.LIST_VIEWS;
    vm.loadAll = loadAll;
    vm.query = JSON.parse(localStorage.getItem("good-receipt-note-paginate")) || {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.receivingStatuses = GLOBAL_CONSTANTS.RECEIVING_STATUSES;
    vm.limitOptions = paginationConstants.limitOptions;
    //Header
    vm.goodsReceiptNoticeReport = EXPORT_CONSTANTS.CSV.BuyerGoodsReceiptNoticeReport;
    //Function Declaration
    vm.getGoodsReceiptNote = getGoodsReceiptNote;
    vm.getSelectedBusinessOutlet = getSelectedBusinessOutlet;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.mergeGoodsRecivesWithSamePO = mergeGoodsRecivesWithSamePO;
    vm.selectAll = selectAll;
    //filter reset
    vm.resetFilters = resetFilters;
    vm.filter = JSON.parse(localStorage.getItem("good-receipt-note-filter")) || {
        grnFromDate: vm.todaysDate,
        grnToDate: vm.todaysDate,
        receivingStatus: vm.receivingStatuses[0],
        listView: vm.listViews[0]
    };
    vm.init = init();    
    vm.selectAlll = selectAlll;
    vm.deselectAll = deselectAll;

    function init() {
        vm.filter.businessOutletId = '';
        if(vm.filter.businessOutletId) {
            vm.getAllBuyerBusinessOutlets();
          }
          if(vm.filter.supplierId) {
            vm.getAllAllocatedSuppliersForBusiness();
          }

          if(vm.filter.itemGroupId) {
            vm.loadItemGroups();
          }
          vm.loadAll();
    }
    //Getting CSV DATA
    function getGoodsReceiptNote(){
      var deferred = $q.defer();
      if (!vm.goodReceivingNotes) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getBuyerGoodsReceiptNoteReportCSV(vm.goodReceivingNotes);
    }

    /**
    * [getAllBuyerBusinessOutlets Gets all business outlets of buyer]
    */
    function getAllBuyerBusinessOutlets() {
      if(vm.businessOutlets)
        return false;
      vm.businessOutlets = null;
      BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser().
      then(function(response){
        vm.businessOutlets = response;
        //if only one business outlet set it as default
        if(vm.businessOutlets.length === 1) {
          vm.businessId = vm.businessOutlets[0].id;
        }
      },function(error){
      });
    }

    /**
    * [getAllAllocatedSuppliersForBusiness Get all allocated suppliers of buyer business]
    */
    function getAllAllocatedSuppliersForBusiness() {
      if(vm.supplierList && vm.prevSelectedOutletId === vm.filter.businessOutletId)
        return false;
      vm.prevSelectedOutletId = vm.filter.businessOutletId;
      var params = {'businessId': !vm.filter.businessOutletId ? '' : vm.filter.businessOutletId};
      BuyerBuyNowService.getAllAllocatedSuppliersForBusiness(params).then(function(response){
        vm.supplierList = $filter('orderBy')(response.data, 'companyName');
        //if only one allocated supplier set it as default
        if(vm.supplierList.length === 1) {
          vm.filter.supplierId = vm.supplierList[0].id;
        }
      },function(error){
      });
    }

    function loadItemGroups() {
      if(vm.itemGroups)
        return false;
      ItemGroupService.getAllItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.itemGroups = response.data;
      }, function(error) {
      });
    }

    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function getSelectedBusinessOutlet() {
     vm.selectedOutlet =  _.filter(vm.businessOutlets, function(outlet){
        return vm.filter.businessOutletId == outlet.id;
      })
    }

    /**
    * [loadAll Gets all GRN]
    */
    function loadAll() {
        localStorage.setItem("good-receipt-note-filter", JSON.stringify(vm.filter));
        localStorage.setItem("good-receipt-note-predicate", JSON.stringify(vm.predicate));
        localStorage.setItem("good-receipt-note-reverse", JSON.stringify(vm.reverse));
        localStorage.setItem("good-receipt-note-paginate", JSON.stringify(vm.query));
      if(vm.filter.listView === "Individual"){
        PurchaseOrderService.getGRNDetails({
          page: vm.query.page - 1,
          size: vm.query.limit,
          sort: sort(),
          invoiceStatus: vm.filter.receivingStatus !== "ALL" ? vm.filter.receivingStatus : '' ,
          businessOutletId: !vm.filter.businessOutletId ? '' : vm.filter.businessOutletId,
          businessOutletName: !vm.filter.businessOutletName ? '' : vm.filter.businessOutletName,
          poFromDate: !vm.filter.poFromDate ? '' : UTCDateService.utcFromDate(new Date(vm.filter.poFromDate)),
          poToDate: !vm.filter.poToDate ? '' : UTCDateService.utcToDate(new Date(vm.filter.poToDate)),
          deliveryFromDate: !vm.filter.deliveryFromDate ? '' : UTCDateService.utcFromDate(new Date(vm.filter.deliveryFromDate)),
          deliveryToDate: !vm.filter.deliveryToDate ? '' : UTCDateService.utcToDate(new Date(vm.filter.deliveryToDate)),
          grnFromDate : !vm.filter.grnFromDate ? '' : UTCDateService.utcFromDate(new Date(vm.filter.grnFromDate)),
          grnToDate : !vm.filter.grnToDate ? '' : UTCDateService.utcToDate(new Date(vm.filter.grnToDate)),
          supplierId: !vm.filter.supplierId ? '': vm.filter.supplierId,
          itemGroupId: !vm.filter.itemGroupId ? '' :vm.filter.itemGroupId,
          poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber
        }).then(function(response){
          vm.goodReceivingNotes = response.data;
          vm.totalItems = response.headers('X-Total-Count');
        },function(error){
          NotificationService.error({
            title: 'Goods Receipt Notes Details',
            error: error
          });
        });
      }
    }

  /**
    * [selectAllPR select all Purchase Requests]
    * @return {[type]} [description]
    */
   function selectAll() {
    vm.selectAll ? vm.selectAll = true : vm.selectAll = false;
    angular.forEach(vm.purchaseApprovalRequests, function(eachRequest) {
      eachRequest.selected = vm.selectAll;
    });
  }
      /**
   * mergeGoodsRecivesWithSamePO merges goods receving data wuth same po number
   */
  function mergeGoodsRecivesWithSamePO(){
    var grnList = [];
    var grnPONumberList = [];
    var grnRecevingStatus = [];
    var grnDeliveryDateList = [];
    grnList = _.filter(vm.goodReceivingNotes, function(eachRequest){
       if(eachRequest.selected==true){
         grnPONumberList.push(eachRequest.purchaseOrderNumber);
         grnDeliveryDateList.push(eachRequest.processOrder.deliveryDate);
         grnRecevingStatus.push(eachRequest.purchaseOrderStatus);
         return eachRequest;
       }
     });
     grnList = _.map(grnList, function(eachRequest){
      return {
        'deliveryDate':eachRequest.processOrder.deliveryDate,
        'purchaseOrderNumber': eachRequest.purchaseOrderNumber
      };
     })
    if(grnList.length > 1 ){
       var poNumberList =[];
       poNumberList = _.uniq(grnPONumberList, function(items){
        return items;
       });
       grnRecevingStatus = _.uniq(grnRecevingStatus, function(items){
         return items;
       })
       if(poNumberList.length == 1 && grnRecevingStatus.length == 1){
        BuyerReceivingOrderService.mergeGrnWithSamePo(grnList).
        then(function(response){
          NotificationService.success({
            title: 'alertMessage.MERGING_POS',
            message: 'alertMessage.MERGED_SUCCESSFULLY'
        });
        vm.loadAll();
        }, function(error){
          NotificationService.error({
            title: 'alertMessage.MERGING_POS',
            error: error
          });
        });

       }
       else {
         NotificationService.simpleErrorToast({
           title: 'alertMessage.MERGING_POS',
           message: 'alertMessage.PO_NUMBER_OR_RECEVING_STATUS_SHOULD_BE_SAME_FOR_ALL'
         });
       }
    }
    else{
       NotificationService.simpleErrorToast({
         title: 'alertMessage.MERGING_POS',
         message: 'alertMessage.ATLEAST_TWO_REQUIRED'
       });
    }
  }
   // filter reset
   function resetFilters() {
    vm.filter.poNumber = ''
    vm.filter.receivingStatus = vm.receivingStatuses[0]
    vm.filter.supplierId = '';
    vm.filter.itemGroupId = '';
    vm.filter.businessOutletId = '';
    vm.filter.listView = vm.listViews[0];
    vm.filter.poFromDate = null;
    vm.filter.poToDate = null
    vm.filter.deliveryFromDate = null;
    vm.filter.deliveryToDate = null;
    vm.filter.grnFromDate = vm.todaysDate;
    vm.filter.grnToDate = vm.todaysDate;
    vm.goodReceivingNotes = [];
    localStorage.setItem("good-receipt-note-filter", JSON.stringify(vm.filter));
}

function selectAlll(dropdown) {
  switch (dropdown) {
    case 'OUTLETS':
      vm.filter.businessOutletId = _.map(vm.businessOutlets, _.property('id'));
      break;
  }
}

function deselectAll(dropdown) {
  switch (dropdown) {
    case 'OUTLETS':
      vm.filter.businessOutletId = [];
      break;
  }
}
  }
})();
