/**
 * @Author: Ajay
 * @Date:   24-Jan-2018 16:25 +05:30
 * @Project: Ezyprocure
 * @Filename: admin.main-item-groups.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 25-Jan-2018 12:42 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('MainItemGroupsController', MainItemGroupsController);

  MainItemGroupsController.$inject = ['$scope', '$rootScope', '$state', '$mdDialog', 'MainItemGroupService', 'pagingParams', 'paginationConstants', 'NotificationService','$filter'];

  function MainItemGroupsController($scope, $rootScope, $state, $mdDialog, MainItemGroupService, pagingParams, paginationConstants, NotificationService,$filter) {
    var vm = this;

    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;

    vm.loadAll = loadAll;
    vm.showConfirm = showConfirm;
    vm.cancel = cancel;
    vm.showAddMainItemGroup = showAddMainItemGroup;
    vm.addMainItemGroup = addMainItemGroup;
    vm.showEditMainItemGroup = showEditMainItemGroup;
    vm.updateMainItemGroup = updateMainItemGroup;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;

    function sort() {
      var result = ['name' + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function loadAll(search) {
      if (!search) {
        vm.searchText = '';
      }
      MainItemGroupService.getAllMainItemGroups({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        query: vm.searchText
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.mainItemGroups = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'Main ItemGroup Listing',
          error: error
        });
      });
    }

    vm.loadAll();

    function deleteMainItemGroup(mainItemGroupId) {
      MainItemGroupService.deleteMainItemGroup(mainItemGroupId).then(function(response) {
        NotificationService.success({
          title: 'global.navbar.mainItemGroups',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'Main Item Group Deletion',
          error: error
        });
      });
    }

    function showConfirm(ev, itemGroup) {
      var confirm = $mdDialog.confirm()
      .title($filter('translate')('ezyprocureApp.mainItemGroup.delete.question') + itemGroup.name + ' ? ')
      .textContent($filter('translate')('alertMessage.COULD_NOT_BE_REVERTED_BACK'))
      .targetEvent(ev)
      .ok($filter('translate')('global.buttons.delete'))
      .cancel($filter('translate')('global.buttons.cancel'));

      $mdDialog.show(confirm).then(function() {
        deleteMainItemGroup(itemGroup.id);
      }, function() {});
    }

    function cancel() {
      $mdDialog.cancel();
    }

    //Add Item Group Starts here

    function showAddMainItemGroup(event) {
      vm.newMainItemGroup = {};
      $mdDialog.show({
        templateUrl: 'app/admin-modules/main-item-groups/admin.main-item-group.add.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function addMainItemGroup(newMainItemGroup) {
      MainItemGroupService.createMainItemGroup(newMainItemGroup).then(function(response) {
        $scope.$emit('ezyprocureApp:mainItemGroupUpdate', response);
        vm.cancel();
        NotificationService.success({
          title: 'global.navbar.mainItemGroups',
          message: 'alertMessage.CREATED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'Main Item Group Creation',
          error: error
        });
      });
    }

    //Add Main ItemGroup Ends here


    //Edit Main ItemGroup Starts here

    function showEditMainItemGroup(event, oldMainItemGroup) {
      vm.editMainItemGroup = angular.copy(oldMainItemGroup);
      $mdDialog.show({
        templateUrl: 'app/admin-modules/main-item-groups/admin.main-item-group.edit.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function updateMainItemGroup(oldMainItemGroup) {
      MainItemGroupService.updateMainItemGroup(oldMainItemGroup).then(function(response) {
        $scope.$emit('ezyprocureApp:mainItemGroupUpdate', response);
        vm.cancel();
        NotificationService.success({
          title: 'global.navbar.mainItemGroups',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'Main Item Group Updation',
          error: error
        });
      });
    }

    //Edit Main ItemGroup Ends here

  }


})();
