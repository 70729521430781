/*
 * @Author: Lovin Chacko 
 * @Date: 2020-08-10 12:36:40 
 * @Last Modified by: Lovin Chacko
 * @Last Modified time: 2020-08-13 11:07:51
 */


(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider

      .state('buyer.adhoc-tools.price-update', {
        parent: 'buyer.adhoc-tools',
        url: '/price-update',
        data: {
          authorities: ['ROLE_BUYER'],
          pageTitle: 'Item Price Update'
        },
        views: {
          'content@': {
            templateUrl: 'app/buyer-modules/adhoc-tools/price-update/buyer.item.price-update.html',
            controller: 'AdhocPriceUpdateController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('supplier');
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('itemBuyerMapping');
            $translatePartialLoader.addPart('items');
            $translatePartialLoader.addPart('itemType');
            $translatePartialLoader.addPart('brand');
            $translatePartialLoader.addPart('priceUpdates');
            return $translate.refresh();
          }]
        }
      })
      .state('buyer.adhoc-tools.price-update.add', {
        parent: 'buyer.adhoc-tools.price-update',
        url: '/price-update/item-selection',
        data: {
          authorities: ['ROLE_BUYER'],
          pageTitle: 'Item Price Update'
        },
        views: {
          'content@': {
            templateUrl: 'app/buyer-modules/adhoc-tools/price-update/item-selection/buyer.price-update.item-selection.html',
            controller: 'AdhocPriceUpdateItemSelectionController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('supplier');
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('itemBuyerMapping');
            $translatePartialLoader.addPart('items');
            $translatePartialLoader.addPart('priceUpdates');
            return $translate.refresh();
          }]
        }
      })
      .state('buyer.adhoc-tools.price-update.edit', {
        parent: 'buyer.adhoc-tools.price-update',
        url: '/price-update/edit/:id',
        data: {
          authorities: ['ROLE_BUYER'],
          pageTitle: 'Item Price Update'
        },
        views: {
          'content@': {
            templateUrl: 'app/buyer-modules/adhoc-tools/price-update/edit/buyer.price-update.edit.html',
            controller: 'AdhocItemPriceEditController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('supplier');
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('itemBuyerMapping');
            $translatePartialLoader.addPart('items');
            $translatePartialLoader.addPart('priceUpdates');
            return $translate.refresh();
          }]
        }
      });

  }
})();
