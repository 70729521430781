/*
 * @Author: Lovin Chacko 
 * @Date: 2020-08-12 16:07:09 
 * @Last Modified by: Lovin Chacko
 * @Last Modified time: 2020-08-26 16:32:30
 */



(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('AdhocItemPriceEditController', AdhocItemPriceEditController);

  AdhocItemPriceEditController.$inject = [ '$state', 'AdhocItemPriceUpdateService', 'NotificationService', 'SupplierItemPriceUpdateService', 'UTCDateService'];

  function AdhocItemPriceEditController( $state, AdhocItemPriceUpdateService, NotificationService, SupplierItemPriceUpdateService, UTCDateService ) {
    var vm = this;
    vm.init = init;
    vm.todaysDate = new Date();
    vm.proceedToPriceUpdate = proceedToPriceUpdate;
    vm.priceUpdateNumber = SupplierItemPriceUpdateService.getPriceUpdateNumber();
    vm.dateChange = dateChange;

    function init() {
      vm.priceUpdateFilter = AdhocItemPriceUpdateService.getPriceObject();
      vm.priceUpdate = AdhocItemPriceUpdateService.getEditablePriceObj();
      if (!vm.priceUpdateFilter || !vm.priceUpdate)
        $state.go('buyer.adhoc-tools.price-update');
    }

    /**
     * [proceedToPriceUpdate Proceed to item price update request]
     */
    function proceedToPriceUpdate() {
      AdhocItemPriceUpdateService.editPriceUpdate({
        priceUpdateId: vm.priceUpdate.id,
        startDate: vm.priceUpdate.effectiveStartDate,
        endDate: vm.priceUpdate.perpetual ? '': vm.priceUpdate.effectiveEndDate,
        updatePrice: vm.priceUpdate.updatedPrice,
        perpetual: vm.priceUpdate.perpetual ?  vm.priceUpdate.perpetual : 'false',
        remark: vm.priceUpdate.remark
      })
        .then(function (response) {
          NotificationService.success({
            title: 'Price Update Request',
            message: 'alertMessage.SENT_SUCCESSFULLY'
          });
          $state.go('buyer.adhoc-tools.price-update');
        }, function (error) {
          vm.translatedError = error.data;
          NotificationService.error({
            title: 'Price Update Request',
            error: error
          });
        });
    }

    function dateChange(date){
      return UTCDateService.utcToDate(date);
    }

  }
})();
