/**
* @Author: Fenn Saji 
* @Date:   27-Feb-2020
* @Project: Ezyprocure
* @Filename: add-card.state.js
*/



(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .config(stateConfig);
  
    stateConfig.$inject = ['$stateProvider'];
  
    function stateConfig($stateProvider) {
        $stateProvider.state('supplier.addcard', {
            parent: 'supplier',
            url: '/recharge/add/card',            
            data: {
            },
            params: {
              country: undefined,
              planType: undefined,
              transactionFailed: false,
              transactionAmount: 0,
              transactioncurrencyCode: null,
              transactionPaymentId: null
            },
            views: {
              'content@': {
                templateUrl: 'app/common-modules/plans/recharge/add-card/add-card.html',
                controller: 'AddCardMpgsAccountController',
                controllerAs: 'vm'
              }
            },
            resolve: {
              translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('register');
                $translatePartialLoader.addPart('global');
                return $translate.refresh();
              }]
            }
            
        })
    }
  })();
  