/*
 * @Author: Jayalekshmi 
 * @Date: 2019-11-15 10:38:51 
 * @Project: Ezyprocure
 * @Filename: buyer.stock-transfer.state.js
 * @Last Modified by: Jayalekshmi
 * @Last Modified time: 2019-11-18 16:19:12
 */


(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .config(stateConfig);
  
    stateConfig.$inject = ['$stateProvider'];
  
    function stateConfig($stateProvider) {
      $stateProvider
      .state('buyer.tools.stock-transfer', {
        parent: 'buyer.tools',
        url: '/stock-transfer',
        data: {
          authorities: ['ROLE_BUYER'],
          pageTitle: 'Stock Transfer'
        },
        views: {
          'content@': {
            templateUrl: 'app/buyer-modules/tools/stock-transfer/buyer.stock-transfer.html',
            controller: 'BuyerStockTransferController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,asc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
          }]
        }
      })
      .state('buyer.tools.stock-transfer.details', {
        parent: 'buyer.tools.stock-transfer',
        url: '/details/:id',
        data: {
          authorities: ['ROLE_BUYER'],
          pageTitle: 'Role Management'
        },
        views: {
          'content@': {
            templateUrl: 'app/buyer-modules/tools/stock-transfer/details/buyer.stock-transfer.details.html',
            controller: 'BuyerStockTransferDetailsController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,asc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
          }]
        }
      })
      .state('buyer.tools.stock-transfer.add', {
        parent: 'buyer.tools.stock-transfer',
        url: '/add',
        data: {
          authorities: ['ROLE_BUYER'],
          pageTitle: 'Role Management'
        },
        views: {
          'content@': {
            templateUrl: 'app/buyer-modules/tools/stock-transfer/add/buyer.stock-transfer.add.html',
            controller: 'BuyerStockTransferAddController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,asc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
          }]
        }
      });
    }
  
  })();
  