/**
 * @Author: Mufsir
 * @Date:   29-Dec-2016 15:05 +05:30
 * @Project: Ezyprocure
 * @Filename: role-based-routing.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 08-Sep-2017 13:13 +05:30
 */



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('RoleBasedRoutingService', RoleBasedRoutingService);

  RoleBasedRoutingService.$inject = ['ROLE_CONSTANTS'];
  function RoleBasedRoutingService(ROLE_CONSTANTS) {

    var RoleBasedRoutingService = {
      getRoleBasedRoute: getRoleBasedRoute
    };

    return RoleBasedRoutingService;

    //accepts user role and returns role based route
    function getRoleBasedRoute(role) {
      var route = ROLE_CONSTANTS.routingData[role];
      if(!route){
        route = 'login';
      }
      return route;
    }

  }
})();
