/**
* @Author: Ajay
* @Date:   27-Mar-2017 13:16 +05:30
* @Project: Ezyprocure
* @Filename: manage-buyers.service.js
* @Last modified by:   Mufsir
* @Last modified time: 07-Apr-2017 05:31 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('ManageBankService', ManageBankService);

  ManageBankService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS', 'AuthServerProvider'];

  function ManageBankService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS, AuthServerProvider) {

    var manageBankService = {
      getAllAdminBanks: getAllAdminBanks,
      getAllAdminBanksWithUserDetails: getAllAdminBanksWithUserDetails,
      getAdminBank: getAdminBank,
      createAdminBank: createAdminBank,
      updateAdminBank: updateAdminBank,
      updateBankActivatedKey: updateBankActivatedKey,
      deleteAdminBank: deleteAdminBank,
      buyerBulkUploadCSV: buyerBulkUploadCSV,
      getBankDetails:getBankDetails,
      getAllocatedBankDetails:getAllocatedBankDetails
    };

    return manageBankService;

    /* --  AdminBank CRUID operations  -- */

    //Get All Admin buyers
    function getAllAdminBanks() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyers/all',
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get All Admin buyers with User Details
    function getAllAdminBanksWithUserDetails(urlParams) {
      if (!urlParams) {
        urlParams = {};
        urlParams.search = '';
      }
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'finance-banks',
        method: "GET",
        params: urlParams,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get buyer details using buyer id
    function getAdminBank(bankId) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'finance-bank/' + bankId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Create a new Admin buyer
    function createAdminBank(bank) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'finance-banks', bank).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update Admin bank
    function updateAdminBank(bank) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'finance-banks/', bank).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //update Admin Bank Activated Key
    function updateBankActivatedKey(bankId, isActivate) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'finance-banks/'+bankId+'/'+isActivate).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Soft Delete Admin bank using bankId
    function deleteAdminBank(bankId) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'finance-bank/' + bankId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function buyerBulkUploadCSV(uploadData) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyers/upload', uploadData).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getBankDetails(buyerId) {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http.get(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYER_GETBUYER + buyerId,
      {
        headers: { Authorization: 'Bearer ' + cbAuthToken }
      }).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    function getAllocatedBankDetails(buyerId) {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http.get(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.BUYER_GETBUYERDETAILS + buyerId,
      {
        headers: { Authorization: 'Bearer ' + cbAuthToken }
      }).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
