/**
 * @Author: Ajay
 * @Date:   04-Dec-2017 10:19 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.items.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 18-Jan-2018 12:40 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('buyer.adhoc-tools.items', {
      parent: 'buyer.adhoc-tools',
      url: '/items?page&sort&search',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'Item Management'
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/adhoc-tools/items/buyer.items.html',
          controller: 'BuyerItemListController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('currency');
          return $translate.refresh();
        }]
      }
    })
    .state('buyer.adhoc-tools.items.add', {
      parent: 'buyer.adhoc-tools.items',
      url: '/add',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'Add Item'
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/adhoc-tools/items/add/buyer.item.add-tab.html',
          controller: 'BuyerItemAddController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('itemType');
          $translatePartialLoader.addPart('currency');
          $translatePartialLoader.addPart('brand');
          return $translate.refresh();
        }]
      }
    })
    .state('buyer.adhoc-tools.items.edit', {
      parent: 'buyer.adhoc-tools.items',
      url: '/edit/:itemId',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'Edit Item'
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/adhoc-tools/items/edit/buyer.item.edit.html',
          controller: 'BuyerItemEditController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('currency');
          return $translate.refresh();
        }]
      }
    })
    .state('buyer.adhoc-tools.items.bulk-upload', {
      parent: 'buyer.adhoc-tools.items',
      url: '/bulk-upload/tabIndex=:tabIndex',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'Item Bulk Upload'
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/adhoc-tools/items/bulk-upload/buyer.item.bulk-upload.html',
          controller: 'BuyerItemBulkUploadController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('itemType');
          return $translate.refresh();
        }]
      }
    })
    .state('buyer.adhoc-tools.items.bulk-delete', {
      parent: 'buyer.adhoc-tools.items',
      url: '/bulk-delete?page&sort&search',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'Bulk Delete Item'
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/adhoc-tools/items/bulk-delete/buyer.items-bulk-delete.html',
          controller: 'AdhocItemsBulkDeleteController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('supplier');
          return $translate.refresh();
        }]
      }
    })
    .state('buyer.adhoc-tools.items.allocations', {
      parent: 'buyer.adhoc-tools.items',
      url: '/:itemId/allocations',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'Edit Item'
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/adhoc-tools/items/allocations/buyer.item.allocations.html',
          controller: 'BuyerItemAllocationController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,asc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplier');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('itemBuyerMapping');
          return $translate.refresh();
        }]
      }
    });
  }
})();
