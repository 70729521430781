/**
 * @Author: Ajay
 * @Date:   12-Sep-2018 15:25 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.payment-approval-request.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 25-Sep-2018 14:49 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('buyer.payment-approval-request', {
      parent: 'buyer',
      url: '/payment-approval-request/:paymentApprovalStatus',
      data: {
        authorities: ['ROLE_BUYER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/payment-approval-request/buyer.payment-approval-request.html',
          controller: 'BuyerPaymentApprovalRequestController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,desc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('paymentApprovalRequest');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('user-management');
          $translatePartialLoader.addPart('glCodeMappings');
          $translatePartialLoader.addPart('paymentApprovalStatus');
          return $translate.refresh();
        }]
      }
    })
    .state('buyer.payment-approval-request.details', {
      parent: 'buyer.payment-approval-request',
      url: '/:paymentApprovalRequestNumber/details',
      data: {
        authorities: ['ROLE_BUYER'],
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/payment-approval-request/details/buyer.payment-approval-request.details.html',
          controller: 'BuyerPaymentApprovalRequestDetailsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('paymentApprovalRequest');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('promotion');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('paymentApprovalStatus');
          $translatePartialLoader.addPart('paymentStatus');
          return $translate.refresh();
        }]
      }
    });
  }

})();
