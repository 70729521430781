/**
 * @Author: Ajay
 * @Date:   26-Jul-2017 10:45 +05:30
 * @Project: Ezyprocure
 * @Filename: decimal.directive.js
 * @Last modified by:   Ajay
 * @Last modified time: 26-Jul-2017 11:39 +05:30
 */

/**
 Apply below attributes to text input to activate the directive
 ************************************************************************************************
 sb-precision="2"       - will set decimal points. For eg. will ensure 2 decimal points always
 sb-min="0"             - will set min value.  For eg. sets 0 as min value
 sb-max="100"           - will set max value.  For eg. sets 100 as max value
 sb-max-precision="2"   - will set max decimal points to 2. For eg. sets max 2 decimal points
 sb-number              - will allow only whole numbers or decimal numbers
 ************************************************************************************************
 */

 (function() {
   'use strict';

   function countDecimalPlaces(value){
     var decimalPos = String(value).indexOf('.');
     if(decimalPos === -1){
       return 0;
     }else{
       return String(value).length - decimalPos -1;
     }
   }

   /**
    Apply below attribute to text input to activate the directive
    ************************************************************************************************
    sb-precision="2"       - will set decimal points. For eg. will ensure 2 decimal points always
    ************************************************************************************************
    */

   angular
   .module('ezyprocureApp')
   .directive('sbPrecision', sbPrecision);

   sbPrecision.$inject = ['$timeout'];

   function sbPrecision($timeout) {
     var directive = {
       restrict: 'A',
       require: 'ngModel',
       link: linkFunc
     };

     return directive;

     function linkFunc(scope, element, attributes, ngModel) {
       var precision = attributes.sbPrecision;
       function setPrecision(){
         var value = ngModel.$modelValue;
         //since this is just a mask, don't hide decimal values that
         //go beyond our precision and don't format empty values
         if(value && !isNaN(value) && countDecimalPlaces(value) <= precision){
           var formatted = Number(value).toFixed(precision);
           ngModel.$viewValue = formatted;
           ngModel.$render();
         }
       }
       element.bind('blur', setPrecision);
       $timeout(setPrecision, 0); //after initial page render
     }
   }

   /**
    Apply below attribute to text input to activate the directive
    ************************************************************************************************
    sb-min="0"             - will set min value.  For eg. sets 0 as min value
    ************************************************************************************************
    */

   angular
   .module('ezyprocureApp')
   .directive('sbMin', sbMin);

   sbMin.$inject = [];

   function sbMin() {
     var directive = {
       restrict: 'A',
       require: 'ngModel',
       link: linkFunc
     };

     return directive;

     function linkFunc(scope, element, attributes, ngModel) {
       function minimum(value){
         if(!isNaN(value)){
           var validity = Number(value) >= attributes.sbMin;
           ngModel.$setValidity('min-value', validity);
         }
         return value;
       }
       ngModel.$parsers.push(minimum);
       ngModel.$formatters.push(minimum);
     }
   }

   /**
    Apply below attribute to text input to activate the directive
    ************************************************************************************************
    sb-max="100"           - will set max value.  For eg. sets 100 as max value
    ************************************************************************************************
    */


   angular
   .module('ezyprocureApp')
   .directive('sbMax', sbMax);

   sbMax.$inject = [];

   function sbMax() {
     var directive = {
       restrict: 'A',
       require: 'ngModel',
       link: linkFunc
     };

     return directive;

     function linkFunc(scope, element, attributes, ngModel) {
       function maximum(value){
         if(!isNaN(value)){
           var validity = Number(value) <= attributes.sbMax;
           ngModel.$setValidity('max-value', validity);
         }
         return value;
       }
       ngModel.$parsers.push(maximum);
       ngModel.$formatters.push(maximum);
     }
   }

   /**
    Apply below attribute to text input to activate the directive
    ************************************************************************************************
    sb-number              - will allow only whole numbers or decimal numbers
    ************************************************************************************************
    */

   angular
   .module('ezyprocureApp')
   .directive('sbNumber', sbNumber);

   sbNumber.$inject = [];

   function sbNumber() {
     var directive = {
       restrict: 'A',
       require: 'ngModel',
       link: linkFunc
     };

     return directive;

     function linkFunc(scope, element, attributes, ngModel) {
       function validateNumber(value){
         var validity = !isNaN(value);
         ngModel.$setValidity('number', validity);
         return value;
       }
       ngModel.$parsers.push(validateNumber);
       ngModel.$formatters.push(validateNumber);
     }
   }

   /**
    Apply below attribute to text input to activate the directive
    ************************************************************************************************
    sb-max-precision="2"   - will set max decimal points to 2. For eg. sets max 2 decimal points
    ************************************************************************************************
    */

   angular
   .module('ezyprocureApp')
   .directive('sbMaxPrecision', sbMaxPrecision);

   sbMaxPrecision.$inject = [];

   function sbMaxPrecision() {
     var directive = {
       restrict: 'A',
       require: 'ngModel',
       link: linkFunc
     };

     return directive;

     function linkFunc(scope, element, attributes, ngModel) {
       function maxPrecision(value){
         if(!isNaN(value)){
           var validity = countDecimalPlaces(value) <= attributes.sbMaxPrecision;
           ngModel.$setValidity('max-precision', validity);
         }
         return value;
       }
       ngModel.$parsers.push(maxPrecision);
       ngModel.$formatters.push(maxPrecision);
     }
   }

 })();
