/**
* @Author: Allan
* @Date:   11-October-2018 16:15 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.merchant-details.edit.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2018-10-30T18:46:07+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzyPaymentMerchantDetailsEditController', EzyPaymentMerchantDetailsEditController);

  EzyPaymentMerchantDetailsEditController.$inject = ['$state', '$stateParams', 'EzypaymentAdminManageMerchantDetailsService', 'GLOBAL_CONSTANTS', 'NotificationService'];

  function EzyPaymentMerchantDetailsEditController($state, $stateParams, EzypaymentAdminManageMerchantDetailsService, GLOBAL_CONSTANTS, NotificationService) {

    var vm = this;
    vm.brandId = $stateParams.brandId;
    vm.getMerchantDetails = getMerchantDetails;
    vm.save = save;
    vm.gatewayTypes = GLOBAL_CONSTANTS.GATEWAY_TYPES;
    vm.getBrandDetails = getBrandDetails;
    vm.getAllCountryCurrencies = getAllCountryCurrencies;

    getMerchantDetails();

    /**
     * [getAllCountryCurrencies gets all country currencies]
     */
    function getAllCountryCurrencies(){
      EzypaymentAdminManageMerchantDetailsService.getAllCountryCurrencies().then(function(response){
        vm.currencies = response.data;
      },function(error){
      });
    }

    function getMerchantDetails() {
      EzypaymentAdminManageMerchantDetailsService.getMerchantDetails(vm.brandId).then(function(response) {
        vm.merchantDetails = response.data;
        vm.merchantDetails.merchantDetails = vm.merchantDetails.merchantDetails? vm.merchantDetails.merchantDetails: [];
        vm.brandDetails = getBrandDetails(response.data);
        vm.getAllCountryCurrencies();
      }, function(error) {
        NotificationService.error({
          title: 'Manage Merchant Detail',
          error: error
        });
      });
    }

    function save(){
      vm.merchantDetails.merchantDetails[0]['brand'] = vm.brandDetails;
      EzypaymentAdminManageMerchantDetailsService.saveMerchantDetails(vm.merchantDetails).then(function(response) {
        NotificationService.success({
          title: 'Update Merchant Detail',
          message: 'alertMessage.MERCHANT_DETAIL_UPDATED'
        });
        $state.go('ezypayment.admin.merchant-management',{reload: false, initialActive : false})
      }, function(error) {
        NotificationService.error({
          title: 'Update Merchant Detail',
          error: error
        });
      });
    }

    function getBrandDetails(brandDetails) {
      var details = {};
      _.each(brandDetails, function(detail, key){
        if(key !== 'merchantDetails'){
          details[key] = detail;
        }
      });
      return details;
    }



  }
})();
