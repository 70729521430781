/**
 * @Author: shibi
 * @Date:   2020-06-05T17:59:47+05:30
 * @Last modified by:   shibi
 * @Last modified time: 2020-06-05T17:59:47+05:30
 */

(function(){
  'use strict';
  angular
    .module('ezyprocureApp')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider){
    $stateProvider
      .state('buyer.business-charts.budget-forecast-v2',{
        parent: 'buyer.business-charts',
        url: '/budget-forecast-v2',
        data: {
          authorities: ['ROLE_BUYER'],
        },
        views: {
          'content@': {
            templateUrl: 'app/buyer-modules/business-charts/budget-forecast-v2/budget-forecast-v2.html',
            controller: 'BuyerBudgetForecastControllerv2',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '1',
            squash: true
          },
          sort: {
            value: 'id,desc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('buyer');
            $translatePartialLoader.addPart('priceUpdates');
            $translatePartialLoader.addPart('items');
            $translatePartialLoader.addPart('user-management');
            return $translate.refresh();
          }]
        }
      });
  }

})();
