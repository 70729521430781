/**
 * @Author: Abhay
 * @Date:   03-March-2021 16:03 +05:30
 * @Filename: admin.customer-engage-report.service.js
 * @Last modified by:   Abhay
 * @Last modified time: 03-March-2021 16:03 +05:30
 */

(function () {
    'use strict';

    angular.module('ezyprocureApp').factory('CustomerEngageReportService', CustomerEngageReportService);

    CustomerEngageReportService.$inject = ['$window', '$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS', 'GLOBAL_CONSTANTS'];

    function CustomerEngageReportService($window, $http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS, GLOBAL_CONSTANTS) {

        var customerEngageReportService = {
            getPoDetails: getPoDetails
        };

        function getPoDetails(params) {
            var deferred = $q.defer();
            $http({
                url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'admin/purchase-order/count-volumn',
                method: "GET",
                params: params
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (message) {
                deferred.reject(message);
            });
            return deferred.promise;
        }

        return customerEngageReportService;



    }
})();
