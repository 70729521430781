/*
 * @Author: Lovin 
 * @Date: 2019-06-21 11:50:30 
 * @Project: Ezyprocure
 * @Filename: admin.audit-log-report.service.js
 * @Last Modified by: Lovin
 * @Last Modified time: 2019-06-27 09:41:32
 */


(function() {
    'use strict';
  
    angular.module('ezyprocureApp').factory('AuditLogReportService', AuditLogReportService);
  
    AuditLogReportService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  
    function AuditLogReportService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {
  
      var AuditLogReportService = {
        getAuditlogReport: getAuditlogReport,
        getAllBrands: getAllBrands
      };
  
      return AuditLogReportService;
  
  
      //Get All auditlog report
      function getAuditlogReport(urlParams) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + 'admin/audit-log',
          method: "GET",
          params: urlParams,
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
  
      //Get brand details 
      function getAllBrands(urlParams) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + RESOURCE_URL_CONSTANTS.BRANDS,
          method: "GET",
          params: urlParams,
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }
    }
  })();
  