/*
 * @Author: Gayathri
 * @Date: 2018-12-26 11:46:35
 * @Project: Ezyprocure
 * @Filename: buyer.order-template.state.js
 * @Last Modified by: Ajay
 * @Last Modified time: 2019-02-07 07:00:17
 */
(function () {
  'use strict';
  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('buyer.tools.order-template', {
      parent: 'buyer.tools',
      url: '/order-template',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'Order Template'
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/order-template/buyer.order-template.html',
          controller: 'OrderTemplateController',
          controllerAs: 'vm'
        }
      }
    })
    .state('buyer.tools.order-template.add', {
      parent: 'buyer.tools.order-template',
      url: '/add',

      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'Create Order Template'
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/order-template/add/buyer.order-template.add.html',
          controller: 'OrderTemplateCreateController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,desc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('glCodeMappings');
          return $translate.refresh();
        }]
      }
    })
    .state('buyer.tools.order-template.upload', {
      parent: 'buyer.tools.order-template',
      url: '/upload',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'Create Order Template'
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/order-template/bulk-upload/buyer.order-template.bulk-upload.html',
          controller: 'OrderTemplateItemBulkUploadController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('glCodeMappings');
          return $translate.refresh();
        }]
      }
    })
    .state('buyer.tools.order-template.edit', {
      parent: 'buyer.tools.order-template',
      url: '/edit/:templateId',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'Edit Order Template'
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/order-template/edit/buyer.order-template.edit.html',
          controller: 'OrderTemplateEditController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,desc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('glCodeMappings');
          return $translate.refresh();
        }],
        hasModuleAccess: ['Auth','$q', '$state', function(Auth, $q, $state) {
          if(Auth.hasPermission('accessForManageOrderTemplate')){
            return true;
          }else {
            return $q.reject('Unauthorized');
          }
        }]
      }
    })
  }
})();
