/**
* @Author: Mufsir
* @Date:   14-Dec-2016 12:54 +05:30
* @Project: Ezyprocure
* @Filename: account.state.js
* @Last modified by:   Ajay
* @Last modified time: 08-Mar-2017 15:22 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('account', {
      abstract: true,
      parent: 'app'
    });
  }
})();
