/**
* @Author: Ajay
* @Date:   21-Jan-2017 20:13 +05:30
* @Project: Ezyprocure
* @Filename: supplier.su-bo-mapping.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 01-Jun-2017 13:54 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('supplier.tools.su-bo-mapping', {
      parent: 'supplier.tools',
      url: '/su-bo-mapping?page&sort&search',
      data: {
        authorities: ['ROLE_SUPPLIER'],
        pageTitle: 'ezyprocureApp.supplierUserBusinessMapping.home.title'
      },
      views: {
        'content@': {
          templateUrl: 'app/supplier-modules/tools/su-bo-mapping/supplier.su-bo-mapping.html',
          controller: 'SupplierSUBOMapController',
          controllerAs: 'vm'
        }
      },

       params: {
              page: {
                value: '1',
                squash: true
              },
              sort: {
                value: 'id,asc',
                squash: true
              },
              search: null
            },
      resolve: {
      pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                return {
                  page: PaginationUtil.parsePage($stateParams.page),
                  sort: $stateParams.sort,
                  predicate: PaginationUtil.parsePredicate($stateParams.sort),
                  ascending: PaginationUtil.parseAscending($stateParams.sort),
                  search: $stateParams.search
                };
              }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('supplierUserBusinessMapping');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('user-management');
          $translatePartialLoader.addPart('buyerBusiness');
          return $translate.refresh();
        }]
      }
    });
  }

})();
