/**
* @Author: Ajay
* @Date:   09-May-2017 12:28 +05:30
* @Project: Ezyprocure
* @Filename: buyer.invoice-report.preview.state.js
 * @Last modified by:   Elsa
 * @Last modified time: 2017-05-30T14:12:29+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('buyer.reports.invoice-report.preview', {
      parent: 'buyer.reports.invoice-report',
      url: '/preview/:purchaseOrderNumber/:deliveryDate/:status/:id',
      data: {
        authorities: ['ROLE_BUYER'],
        pageTitle: 'Invoice Preview'
      },
      views: {
        'content@': {
          templateUrl: 'app/common-modules/reports-preview/invoice-report.preview.html',
          controller: 'BuyerInvoiceReportIndividualDetailsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('invoice');
          $translatePartialLoader.addPart('items');
          $translatePartialLoader.addPart('buyerBusiness');
          $translatePartialLoader.addPart('processOrder');
          return $translate.refresh();
        }]
      }
    });
  }
})();
