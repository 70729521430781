/**
* @Author: Ajay
* @Date:   17-Jan-2017 17:22 +05:30
* @Project: Ezyprocure
* @Filename: supplier.account.service.js
* @Last modified by:   Ajay
* @Last modified time: 03-Apr-2017 18:32 +05:30
*/



(function () {
  'use strict';

  angular.module('ezyprocureApp').factory('SupplierAccountService', SupplierAccountService);

  SupplierAccountService.$inject = ['$http', '$q', 'API_CONSTANTS', 'AuthServerProvider', 'Principal', 'SupplierDataService'];

  function SupplierAccountService($http, $q, API_CONSTANTS, AuthServerProvider, Principal, SupplierDataService) {

    var supplierAccountService = {
      getSupplierProfile: getSupplierProfile,
      getSupplierAccount: getSupplierAccount,
      updateSupplierAccount: updateSupplierAccount,
      getCbSupplierProfile: getCbSupplierProfile
    };

    return supplierAccountService;

    /* --  Supplier Account APIs  -- */

    //Get supplier profile account details with access rights
    function getSupplierProfile() {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers/profile').then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get supplier profile account details with access rights
    function getCbSupplierProfile() {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      $http.get(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers/profile',
        {
          headers: { Authorization: 'Bearer ' + cbAuthToken }
        }).then(function (data) {
          deferred.resolve(data.data);
        }).catch(function (message) {
          Principal.setHasCbPlatform(false);
          deferred.reject(message);
        });
      return deferred.promise;
    }

    //Get supplier profile account details
    function getSupplierAccount() {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers/account').then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update Supplier Account
    function updateSupplierAccount(supplier) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers/account', supplier).then(function (data) {
        if(Principal.isCBEnabled()) {
          updateCBSupplierAccount(supplier);
        }
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function updateCBSupplierAccount(supplier) {
      var deferred = $q.defer();
      var cbAuthToken = AuthServerProvider.getCbToken();
      var supplierInfo = SupplierDataService.getCbSupplierProfile();
      if(supplierInfo) {
        supplier.id = supplierInfo.supplier.id;
        supplier.supplierUserId = supplierInfo.supplierUser.id;
        supplier.user.id = supplierInfo.user.id;
      }
      $http.put(API_CONSTANTS.cbBase + 'api/' + API_CONSTANTS.baseVersion + 'suppliers/account', supplier,
      {
        headers: { Authorization: 'Bearer ' + cbAuthToken }
      }).then(function (data) {
        deferred.resolve(data.data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
