/**
* @Author: Ajay
* @Date:   03-Mar-2017 15:12 +05:30
* @Project: Ezyprocure
* @Filename: buyer.unable-meet-demands.state.js
 * @Last modified by:   sneha
 * @Last modified time: 22-Jan-2018 11:19 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('buyer.unable-meet-demands', {
      parent: 'buyer',
      url: '/unable-meet-demands',
      data: {
        authorities: ['ROLE_BUYER']
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/unable-meet-demands/buyer.unable-meet-demands.html',
          controller: 'BuyerUnableMeetDemandsController',
          controllerAs: 'vm'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        },
        sort: {
          value: 'id,desc',
          squash: true
        },
        search: null
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return {
            page: PaginationUtil.parsePage($stateParams.page),
            sort: $stateParams.sort,
            predicate: PaginationUtil.parsePredicate($stateParams.sort),
            ascending: PaginationUtil.parseAscending($stateParams.sort),
            search: $stateParams.search
          };
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('buyerDashboard');
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('purchaseOrder');
          $translatePartialLoader.addPart('processOrder');
          $translatePartialLoader.addPart('glCodeMappings');
          $translatePartialLoader.addPart('supplierFeedbackConstants');
          return $translate.refresh();
        }]
      }
    })
    .state('buyer.unable-meet-demands.details', {
      parent: 'buyer.unable-meet-demands',
      url: '/:requirementNumber/details',
      data: {
        authorities: ['ROLE_BUYER']
      },
      views: {
        'content@': {
          templateUrl: 'app/buyer-modules/unable-meet-demands/details/buyer.unable-meet-demands.details.html',
          controller: 'BuyerUnableMeetDemandsDetailsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('buyer');
          $translatePartialLoader.addPart('promotion');
          $translatePartialLoader.addPart('supplierFeedbackConstants');
          return $translate.refresh();
        }]
      }
    });
  }
})();
