/**
 * @Author: Hareesh
 * @Date:   02-Aug-2017 4:21 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.purchase-request.individual-details.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-08-13T12:36:22+05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerPurchaseRequestIndividualDetailsController', BuyerPurchaseRequestIndividualDetailsController);

  BuyerPurchaseRequestIndividualDetailsController.$inject = ['$scope', '$rootScope', '$filter', '$state', '$stateParams', 'toastr', '$mdDialog', 'SupplierProcessOrderService', 'BuyerCancelPORequestService', 'EXPORT_CONSTANTS', 'CSVExportService', 'PDFExportService', 'BuyerWaitingSupplierReplyService', 'NotificationService', 'GLOBAL_CONFIG_CONSTANTS', 'Auth', 'GLOBAL_CONSTANTS'];

  function BuyerPurchaseRequestIndividualDetailsController($scope, $rootScope, $filter, $state, $stateParams, toastr, $mdDialog, SupplierProcessOrderService, BuyerCancelPORequestService, EXPORT_CONSTANTS, CSVExportService, PDFExportService, BuyerWaitingSupplierReplyService, NotificationService, GLOBAL_CONFIG_CONSTANTS, Auth, GLOBAL_CONSTANTS) {
    var vm = this;
    vm.init = init;
    vm.loadAll = loadAll;
    vm.getBuyerSupplierMappings = getBuyerSupplierMappings;
    vm.getPurchaseOrdersByRequirementNumber = getPurchaseOrdersByRequirementNumber;
    vm.poNumber = $stateParams.poNumber;
    vm.deliveryDate = $stateParams.deliveryDate;
    vm.deliveryDate = new Date($stateParams.deliveryDate);
    vm.previewType = $stateParams.type;
    vm.userRole = Auth.getUserRole();
    vm.todaysDate = $filter('date')(new Date(), 'dd/MM/yyyy');
    vm.sumRequired = sumRequired;
    vm.sumSupplier = sumSupplier;
    vm.showCancelPOModal = showCancelPOModal;
    vm.cancel = cancel;
    vm.cancelPORequest = cancelPORequest;
    vm.purchaseOrderHeader = EXPORT_CONSTANTS.CSV.purchaseOrderHeader;
    vm.getPurchaseOrderCSVData = getPurchaseOrderCSVData;
    vm.getIndividualPurchaseOrderPDF = getIndividualPurchaseOrderPDF;
    vm.checkAlreadyRequstedCancelPO = checkAlreadyRequstedCancelPO;
    vm.showOthers = showOthers;
    //Testing
    vm.requirementNumber = $stateParams.requirementNumber;
    vm.formatPrice = formatPrice;
    vm.invStatusClass = GLOBAL_CONFIG_CONSTANTS.INVOICE_STATUS.LABEL_CLASS;
    vm.poStatusClass = GLOBAL_CONFIG_CONSTANTS.PURCHASE_ORDER_STATUS.LABEL_CLASS;
    vm.prStatusClass = GLOBAL_CONFIG_CONSTANTS.PROCESS_ORDER_STATUS.LABEL_CLASS;
    vm.defaultDateTimeFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_TIME_FORMAT;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.accessForPriceDisplay = Auth.hasPermission('accessForPriceDisplay');
    vm.printPurchaseRequest = printPurchaseRequest;
    vm.inclusiveOfTax = GLOBAL_CONSTANTS.inclusiveOfTax;


    /**
     * [init loads inititally]
     */
    function init(){
      vm.loadAll();
      vm.getPurchaseOrdersByRequirementNumber();
    }

    /**
    * [loadAll Gets Purchase Order Details by PO number]
    */
    function loadAll() {
      BuyerWaitingSupplierReplyService.getPurchaseRequestByRequirementNumber(vm.requirementNumber)
      .then(function(response) {
        vm.poDetails = response;
        if(vm.poDetails && vm.poDetails.items[0].supplierResponse.item.supplier.visibilityType === "ADHOC"){
          vm.getBuyerSupplierMappings();
        }
      }, function(error) {
        NotificationService.error({
          title: 'Purchase Request Information',
          error: error
        });
      });
    }

    function getBuyerSupplierMappings(){
      BuyerWaitingSupplierReplyService.getBuyerSupplierMappingDetails(vm.poDetails.buyerBusiness.buyer.id,vm.poDetails.items[0].supplierResponse.item.supplier.id)
      .then(function(response) {
        vm.attentionTo = response.supplierEmail != null ? response.supplierEmail : (response.alternativeEmail1 ? response.alternativeEmail1 : response.alternativeEmail2)
      }, function(error) {
        NotificationService.error({
          title: 'Buyer Supplier Mappings',
          error: error
        });
      });
    }
    /**
     * [printPurchaseRequest Prints the purchase request details]
     */
    function printPurchaseRequest(id) {
      PDFExportService.printPDF(id);
   }

    // Redirect back to PO Listing page if cancel po already requested*
    function checkAlreadyRequstedCancelPO(poDetails) {
      if (poDetails.items[0].cancelPoStatus === 'CANCEL_PO_REQUESTED') {
        $state.go('buyer.reports.purchase-order', {}, { reload: false});
      }
    }
    /**
     * [sumRequired Grand total amount of buyer Requirement]
     * @param  {[object]} poItems [PO Details]
     * @return {[number]}         [total amount]
     */
    function sumRequired(poItems) {
          var totalAmount = 0;
          _.forEach(poItems, function(item) {
            if(item.buyerRequirement.items.uom.weightRequired === false && !item.buyerRequirement.freeItem) {
              //totalAmount += item.buyerRequirement.quantity * item.buyerRequirement.price;
              if(item.buyerRequirement.items.uom.moqPrice === true)
              {
                totalAmount += (item.buyerRequirement.quantity/item.buyerRequirement.items.moq) * item.buyerRequirement.price;
              }
              else
              {
                totalAmount += item.buyerRequirement.quantity * item.buyerRequirement.price;
              }
            }
          });
          return totalAmount;
        }
    /**
     * [sumSupplier Grand total amount of supplier response]
     * @param  {[object]} poItems [PO Details]
     * @return {[number]}         [total amount]
     */
    function sumSupplier(poItems) {
      var totalAmount = 0;
      _.forEach(poItems, function(item) {
        //skip caluclations for free item
        if (!item.buyerRequirement.freeItem) {
                  if(item.buyerRequirement.items.uom.weightRequired === false) {
                    if(item.supplierResponses === null) {
                      if(item.buyerRequirement.items.uom.moqPrice === true)
                      {
                        totalAmount += (item.buyerRequirement.quantity/item.buyerRequirement.items.moq) * item.buyerRequirement.price;
                      }
                      else
                      {
                        totalAmount += item.buyerRequirement.quantity * item.buyerRequirement.price;
                      }
                    }
                    else {
                      if(item.buyerRequirement.items.uom.moqPrice === true)
                      {
                        totalAmount += (item.supplierResponses.quantity/item.buyerRequirement.items.moq) * item.buyerRequirement.price;
                      }
                      else
                      {
                        totalAmount += item.supplierResponses.quantity * item.buyerRequirement.price;
                      }
                    }
                  }
                  else {
                    if(item.supplierResponses === null) {
                      //totalAmount += item.buyerRequirement.weight * item.buyerRequirement.price;
                      if(item.buyerRequirement.items.uom.moqPrice === true)
                      {
                        totalAmount += (item.buyerRequirement.weight/item.buyerRequirement.items.moq) * item.buyerRequirement.price;
                      }
                      else
                      {
                        totalAmount += item.buyerRequirement.weight * item.buyerRequirement.price;
                      }
                    }
                    else {
                      //totalAmount += item.supplierResponses.weight * item.buyerRequirement.price;
                      if(item.buyerRequirement.items.uom.moqPrice === true)
                      {
                        totalAmount += (item.supplierResponses.weight/item.buyerRequirement.items.moq) * item.buyerRequirement.price;
                      }
                      else
                      {
                        totalAmount += item.supplierResponses.weight * item.buyerRequirement.price;
                      }
                    }
                  }
                }
              });
      return totalAmount;
    }
    /**
     * [cancel to close the modal]
     */
    function cancel () {
      $mdDialog.cancel();
    }

    /**
     * [showAccessModal shows modal to Cancel PO]
     */
    function showCancelPOModal (event) {
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/reports/purchase-order/individual-details/buyer.purchase-order.individual-details.cancel-po-dialog.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function cancelPORequest() {
      cancel();
      BuyerCancelPORequestService.cancelPORequest(vm.poNumber, {
        buyerCancelpoReason : vm.cancelPOReason
      }).then(function(response){
        NotificationService.success({
          title: 'Cancel PO',
          message: 'Requested successfully'
        });
        $state.go('buyer.reports.purchase-order', {reload: false});
      },function(error) {
        NotificationService.error({
          title: 'Cancel PO Request',
          error: error
        });
      });
    }

    function getPurchaseOrderCSVData() {
      return CSVExportService.getPurchaseOrderCSV(vm.poDetails);
    }

    function downloadURI(uri, name)
    {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = name;
      link.href = uri;
      link.click();
    }

    function getIndividualPurchaseOrderPDF() {
      PDFExportService.getPurchaseOrderPDF(vm.poNumber).then(function(response){
        downloadURI(response.data.message, "PDF_" + vm.poNumber);
      },function(error) {
        NotificationService.error({
          title: 'Purchase Order PDF Export',
          error: error
        });
      });
    }

    function showOthers(){
      this.others = false;
      var checkOthers = vm.cancelPO.reason;
      if(checkOthers == 'Others')
      {
        vm.cancelPOReason="";
        this.others = true
      }else if(checkOthers=='Supplier_cannot_deliver'){
        vm.cancelPOReason="Supplier cannot deliver";
        this.others = false;
      }else if(checkOthers=='Approver_cancelled'){
        vm.cancelPOReason="Approver cancelled";
        this.others = false;
      }else{
        vm.cancelPOReason="Customer cancelled the event";
      }
    }

//round off price to two decimal places
    function formatPrice(amount){
      return (Math.round((amount)*1000)/1000);
    }

    function getPurchaseOrdersByRequirementNumber() {
      SupplierProcessOrderService.getPurchaseOrdersByRequirementNumber(vm.requirementNumber).then(function(response) {
        vm.purchaseOrders = response.data;
      }, function(error) {
      });
    }

  }
})();
